import * as React from 'react';
import Grid from '@mui/material/Grid2';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';
import {NavigationButtons} from '../buttons/NavigationButtons';
import {useFormContext} from '../providers/FormProvider';
import useCfar from '../../hooks/useCfar';

interface FooterContentThemeProps {
    content: JSX.Element;
    formProps: FormikProps<IFormValues>;
    url: string;
}

export const FooterContentTheme = (props: FooterContentThemeProps) => {

    const {
        content,
        formProps,
        url
    } = props;

    const {
        applicationSubmitted,
        bookingCounter
    } = useFormContext();

    const {t} = useTranslation();
    const {applicationIsValid} = useCfar();
    const theme = useTheme();
    const isSmAndAbove = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            {!isSmAndAbove &&
                <>
                    <Grid container justifyContent='center'
                          sx={{
                              pt: '10px',
                              textAlign: 'center',
                              fontSize: '0.8em'
                          }}>
                        {content}
                    </Grid>
                    <Grid container
                          sx={{
                              position: 'fixed',
                              pt: '20px',
                              pb: '20px',
                              backgroundColor: 'white',
                              width: '100%',
                              bottom: 0,
                                  ...(bookingCounter > 1 && !applicationSubmitted) && {
                                      boxShadow: '0 0 4px 1px #D0D0D0'
                                  }
                          }}>
                        {bookingCounter > 1 && !applicationSubmitted && applicationIsValid &&
                            <NavigationButtons
                                formProps={formProps}
                                url={url}
                            />
                        }
                    </Grid>
                </>
            }

            {isSmAndAbove &&
                <Grid container
                      sx={{
                          position: 'relative',
                          pt: '30px',
                          backgroundColor: 'white',
                          width: '100%',
                          bottom: 0
                      }}>
                    {bookingCounter > 1 && !applicationSubmitted && applicationIsValid &&
                        <NavigationButtons
                            formProps={formProps}
                            url={url}
                        />
                    }
                    <Grid container justifyContent='center' size={{sm: 12, md: 12}}
                          sx={{
                              textAlign: 'center',
                              pb: '50px',
                              pt: '60px',
                              fontSize: '0.8em'
                          }}>
                        {content}
                    </Grid>
                </Grid>
            }
        </>
    );
}