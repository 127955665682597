export default {
    headingAdverseWeather: "Anda mengalami keadaan cuaca buruk yang teruk yang menjadikannya tidak selamat untuk pergi ke acara tersebut dan ini telah diberi mandat oleh Badan Kerajaan (contohnya Pihak Berkuasa Tempatan, Polis, dll). Dan kau punya bukti untuk membuktikan ini?",
    headingAdverseWeatherDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    tooltipRefundReasonConfirm: "Ya atau tidak",
    labelBookingCancelledByOperator: "Tiket-tiket tersebut telah dibatalkan oleh Pengendali/Penganjur.",
    labelBookingRescheduledByOperator: "Tarikh telah diubah oleh Operator/Penganjur dan saya tidak dapat menghadiri tarikh baru.",
    labelBookedWrongDate: "Saya atau ahli tempahan, membuat kesilapan semasa membeli tiket dan menempah tarikh yang salah.",
    labelBookedWrongTickets: "Saya atau ahli tempahan, membuat kesilapan semasa membeli tiket dan menempah tiket yang salah.",
    labelBookedTooManyTickets: "Saya atau ahli tempahan, membuat kesilapan semasa membeli tiket dan menempah terlalu banyak tiket.",
    labelCOVID19TravelRestrictions: "Saya atau ahli tempahan, tidak dapat hadir kerana larangan perjalanan COVID-19.",
    labelADWrongSelection: "Anda mungkin telah memilih sebab yang salah untuk bayaran balik. Sila semak senarai juntai bawah Sebab Bayaran Balik sekali lagi.",
    labelAdverseWeather1: "Mengapa cuaca buruk berarti Anda tidak dapat menghadiri acara itu?",
    tooltipAdverseWeather1: "Sila terperinci",
    labelAdverseWeather2: "Tarikh kejadian",
    tooltipAdverseWeather2: "Sila pilih",
    headingArmedForces: "Anda diminta oleh Majikan anda yang mana Angkatan Tentera atau Perkhidmatan Kecemasan untuk bekerja apabila anda tidak dijadualkan pada asalnya untuk berbuat demikian dan ini menghalang anda daripada menghadiri acara tersebut?",
    headingArmedForcesDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelArmedForces1: "Apakah kuasa atau Perkhidmatan Kecemasan yang anda gunakan?",
    tooltipArmedForces1: "Sila pilih",
    labelArmedForcesOther1: "Sila nyatakan",
    tooltipArmedForcesOther1: "Sila nyatakan",
    labelArmedForces2: "Tarikh kejadian",
    tooltipArmedForces2: "Sila pilih",
    headingChangesToExam: "Tarikh peperiksaan anda telah diubah oleh sekolah / kolej anda, dll? Ini bukan ujian yang gagal yang mesti anda resit?",
    headingChangesToExamDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelExam1: "Apakah tarikh peperiksaan asal?",
    tooltipExam1: "Sila pilih",
    labelExam2: "Apakah tarikh peperiksaan baru?",
    tooltipExam2: "Sila pilih",
    labelExam3: "Mengapa tarikh peperiksaan berubah?",
    tooltipExam3: "Sila masukkan butiran",
    labelExam4: "Adakah anda gagal peperiksaan sebelumnya?",
    tooltipExam4: "Ya atau tidak",
    headingCourtSummons: "Anda telah dipanggil untuk hadir di mahkamah sebagai saksi pada tarikh yang sama dengan acara itu?",
    headingCourtSummonsDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelCourtSummons1: "Apakah sebab saman mahkamah anda?",
    tooltipCourtSummons1: "Sila pilih",
    labelCourtSummonsOther1: "Sila nyatakan",
    tooltipCourtSummonsOther1: "Sila nyatakan",
    labelCourtSummons2: "Bilakah saman mahkamah dikeluarkan?",
    tooltipCourtSummons2: "Sila pilih",
    headingCOVID19Test: "Saya, ahli keluarga saya atau ahli kumpulan yang menghadiri acara itu telah didiagnosis dengan COVID-19?",
    headingCOVID19TestDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelCOVID19Test1: "Siapa yang mempunyai COVID-19?",
    tooltipCOVID19Test1: "Sila pilih",
    labelCOVID19TestOther1a: "Sila nyatakan",
    labelCOVID19TestOther1b: "Nyatakan nama",
    tooltipCOVID19TestOther1a: "Sila nyatakan",
    tooltipCOVID19TestOther1b: "Nyatakan nama",
    labelCOVID19Test2: "Apakah jenis ujian COVID-19 yang anda ada?",
    tooltipCOVID19Test2: "Sila pilih",
    labelCOVID19TestOther2: "Sila nyatakan",
    tooltipCOVID19TestOther2: "Sila nyatakan",
    labelCOVID19Test3: "Apakah tarikh ujian COVID-19 anda?",
    tooltipCOVID19Test3: "Sila pilih",
    headingDeath: "Terdapat kematian ahli keluarga terdekat saya dan ia menghalang saya daripada menghadiri acara itu?",
    headingDeathDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelDeath1: "Anggota keluargamu mana yang telah meninggal?",
    tooltipDeath1: "Sila pilih",
    labelDeathOther1: "Sila nyatakan",
    tooltipDeathOther1: "Sila nyatakan",
    labelDeath2: "Bilakah tarikh kematian?",
    tooltipDeath2: "Sila pilih",
    labelDeath3: "Nama si mati",
    tooltipDeath3: "Sila nyatakan",
    headingEmergency: "Saya mempunyai kecemasan yang tulen yang berada di luar kawalan saya dan tidak dijangka yang saya boleh memberikan bukti untuk?",
    headingEmergencyDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelEmergency1: "Sila jelaskan jenis kecemasan?",
    tooltipEmergency1: "Sila terperinci",
    labelEmergency2: "Bilakah ini berlaku?",
    tooltipEmergency2: "Sila pilih",
    labelEmergency3: "Mengapa keadaan ini menghalang anda menghadiri acara itu?",
    tooltipEmergency3: "Sila terperinci",
    labelEmergency4: "Apakah Anda memiliki bukti keadaan darurat ini?",
    tooltipEmergency4: "Ya atau tidak",
    headingHomeEmergency: "Saya mempunyai kecemasan rumah iaitu Rompakan, Banjir, Kebakaran, Vandalisme, Penderaan Domestik yang menghalang saya daripada menghadiri acara itu?",
    headingHomeEmergencyDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelHomeEmergency1: "Apakah kecemasan rumah?",
    tooltipHomeEmergency1: "Sila pilih",
    labelHomeEmergencyOther1: "Sila nyatakan",
    tooltipHomeEmergencyOther1: "Sila nyatakan",
    labelHomeEmergency2: "Bilakah ini berlaku?",
    tooltipHomeEmergency2: "Sila pilih",
    labelHomeEmergency3: "Mengapa hal itu menghentikan Anda menghadiri acara?",
    tooltipHomeEmergency3: "Sila terperinci",
    headingInjury: "Saya, ahli keluarga saya atau ahli kumpulan yang menghadiri acara itu telah didiagnosis dengan keadaan perubatan atau kecederaan yang kini menghalang kita daripada pergi ke acara itu?",
    headingInjuryDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelInjury1: "Apakah sifat Penyakit / Kecederaan / Kemalangan?",
    tooltipInjury1: "Sila terperinci",
    labelInjury2: "Bilakah ini berlaku?",
    tooltipInjury2: "Sila pilih",
    labelInjury3: "Pernahkah anda menghadiri kemudahan perubatan untuk penyakit/kecederaan ini?",
    tooltipInjury3: "Ya atau tidak",
    labelInjury4: "Adakah ini keadaan perubatan yang sedia ada?",
    tooltipInjury4: "Ya atau tidak",
    labelInjury5: "Bilakah ini pertama kali didiagnosis?",
    tooltipInjury5: "Sila pilih",
    labelInjury6: "Mengapa ini bermakna anda tidak boleh menghadiri acara itu?",
    tooltipInjury6: "Sila terperinci",
    labelInjury7: "Siapa yang telah mengalami penyakit / kecederaan?",
    tooltipInjury7: "Sila pilih",
    headingJuryService: "Saya atau ahli tempahan telah dipanggil ke Perkhidmatan Juri?",
    headingJuryServiceDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelJuryService1: "Bilakah anda diberitahu tentang Perkhidmatan Juri?",
    tooltipJuryService1: "Sila pilih",
    headingMechanicalBreakdown: "Kenderaan kami mengalami kerosakan mekanikal atau mengalami kemalangan dan ini menghalang kami daripada menghadiri acara tersebut?",
    headingMechanicalBreakdownDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelMechanicalBreakdown1: "Apa yang berlaku dengan kenderaan itu?",
    tooltipMechanicalBreakdown1: "Sila pilih",
    labelMechanicalBreakdown2: "Bilakah ini berlaku?",
    tooltipMechanicalBreakdown2: "Sila pilih",
    labelMechanicalBreakdown3: "Mengapa anda tidak dapat mengambil pengangkutan alternatif?",
    tooltipMechanicalBreakdown3: "Sila terperinci",
    headingPregnancyComplication: "Saya atau ahli tempahan mempunyai komplikasi kehamilan yang telah didiagnosis oleh profesional perubatan dan oleh itu menghalang kehadiran di acara tersebut?",
    headingPregnancyComplicationDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelPregnancyComplication1: "Apakah sifat Komplikasi Kehamilan?",
    tooltipPregnancyComplication1: "Sila terperinci",
    labelPregnancyComplication2: "Bilakah ini berlaku?",
    tooltipPregnancyComplication2: "Sila pilih",
    labelPregnancyComplication3: "Adakah anda mendapatkan rawatan perubatan?",
    tooltipPregnancyComplication3: "Ya atau tidak",
    labelPregnancyComplication4: "Mengapa ini bermakna anda tidak boleh menghadiri acara itu?",
    tooltipPregnancyComplication4: "Sila terperinci",
    headingPublicTransportFailure: "Kaedah Pengangkutan Awam (Latihan, Bas, Tram, Bot, dan lain-lain) tiba-tiba gagal bermakna tidak ada cara lain saya boleh pergi ke acara ini?",
    headingPublicTransportFailureDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelPublicTransportFailure1: "Apakah jenis Pengangkutan Awam yang anda merancang untuk menggunakan?",
    tooltipPublicTransportFailure1: "Sila pilih",
    labelPublicTransportFailure2: "Bilakah anda merancang untuk melancong?",
    tooltipPublicTransportFailure2: "Sila pilih",
    labelPublicTransportFailure3: "Apa yang berlaku?",
    tooltipPublicTransportFailure3: "Sila terperinci",
    labelPublicTransportFailure4: "Mengapa anda tidak boleh mengambil pengangkutan alternatif?",
    tooltipPublicTransportFailure4: "Sila terperinci",
    headingScheduledAirlineFailure: "Penerbangan pra-tempahan saya dibatalkan tanpa diduga dan saya tidak dapat mencari alternatif yang sesuai yang bermaksud saya tidak dapat menghadiri acara tersebut?",
    headingScheduledAirlineFailureDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelScheduledAirlineFailure1: "Nama Syarikat Penerbangan",
    tooltipScheduledAirlineFailure1: "Nama Syarikat Penerbangan",
    labelScheduledAirlineFailure2: "Nombor penerbangan",
    tooltipScheduledAirlineFailure2: "Nombor penerbangan",
    labelScheduledAirlineFailure3: "Perjalanan dari",
    tooltipScheduledAirlineFailure3: "Perjalanan dari",
    labelScheduledAirlineFailure4: "Melancong ke",
    tooltipScheduledAirlineFailure4: "Melancong ke",
    labelScheduledAirlineFailure5: "Tarikh kejadian?",
    tooltipScheduledAirlineFailure5: "Sila pilih",
    labelScheduledAirlineFailure6: "Kenapa kau tidak bisa mengambil penerbangan alternatif?",
    tooltipScheduledAirlineFailure6: "Sila terperinci",
    headingTheftOfDocuments: "Dokumen saya dicuri dan tidak mungkin untuk mengatur penggantian dalam jangka masa yang diperlukan sehingga saya tidak dapat menghadiri acara",
    headingTheftOfDocumentsDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelTheftOfDocuments1: "Apakah jenis Dokumen yang dicuri?",
    tooltipTheftOfDocuments1: "Sila pilih",
    labelTheftOfDocuments2: "Bilakah ini berlaku?",
    tooltipTheftOfDocuments2: "Sila pilih",
    labelTheftOfDocuments3: "Adakah anda cuba untuk mengeluarkan semula dokumen?",
    tooltipTheftOfDocuments3: "Ya atau tidak",
    headingWorkRelocation: "Majikan saya telah meminta saya untuk menukar lokasi kerja saya selama sekurang-kurangnya tiga bulan dan jaraknya adalah penting bermakna saya kini tidak dapat menghadiri acara itu?",
    headingWorkRelocationDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelWorkRelocation1: "Adakah ini penempatan semula sementara atau kekal kerja anda?",
    tooltipWorkRelocation1: "Sila pilih",
    labelWorkRelocation2: "Berapa lama Anda akan berada di alamat baru?",
    tooltipWorkRelocation2: "Sila terperinci",
    labelWorkRelocation3: "Apakah alamat kerja semasa anda?",
    tooltipWorkRelocation3: "Sila terperinci",
    labelWorkRelocation4: "Apakah alamat kerja baru anda?",
    tooltipWorkRelocation4: "Sila terperinci",
    labelWorkRelocation5: "Bilakah anda dimaklumkan tentang penempatan semula ini?",
    tooltipWorkRelocation5: "Sila pilih",
    headingRedundancy: "Saya atau ahli tempahan telah dibuat berlebihan oleh majikan mereka bermakna saya tidak lagi boleh menghadiri tempahan?",
    headingRedundancyDecline: "Anda tidak boleh meneruskan permohonan ini kerana bayaran balik kemungkinan besar akan ditolak.",
    labelRedundancy1: "Jenis pekerjaan?",
    tooltipRedundancy1: "Sila pilih",
    labelRedundancy2: "Berapa lamakah anda telah diambil bekerja oleh syarikat ini?",
    tooltipRedundancy2: "Sila pilih",
    labelRedundancy3: "Adakah redundansi sukarela?",
    tooltipRedundancy3: "Sila pilih",
    labelRedundancy4: "Bilakah anda diberitahu tentang redundansi anda?",
    tooltipRedundancy4: "Sila pilih",
    labelTestOption: "Pilih Ya untuk meneruskan",
}