import * as React from 'react';
import {TextInput} from '../formInputs';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../Interfaces/IFormValues';
import {FormikProps} from 'formik';
import {bankBeforeAccountCurrencyCodes} from '../utils/constants';
import usePaymentLabels from '../../hooks/usePaymentLabels';

interface AccountCaptureFieldsConfirmControlProps {
    formProps: FormikProps<IFormValues>;
    isFieldHidden: (code: string) => boolean;
}

export const AccountCaptureFieldsConfirmControl = (accountFieldsConfirmProps: AccountCaptureFieldsConfirmControlProps) => {

    const {
        formProps,
        isFieldHidden
    } = accountFieldsConfirmProps;

    const paymentLabels = usePaymentLabels(formProps.values.currency);

    const BankAccountNumberConfirm = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.bankAccountNumberConfirm)}
                placeholder={paymentLabels.labelBankAccountNumber}
                label={paymentLabels.labelBankAccountNumber}
                controlId={nameof<IFormValues>(x => x.bankAccountNumberConfirm)}
                hidden={isFieldHidden(nameof<IFormValues>(x => x.bankAccountNumber))}
                tooltip={paymentLabels.tooltipBankAccountNumber}
            />
        )
    }

    const CardNumberConfirm = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.cardNumberConfirm)}
                placeholder={paymentLabels.labelCardNumber}
                label={paymentLabels.labelCardNumber}
                controlId={nameof<IFormValues>(x => x.cardNumberConfirm)}
                hidden={isFieldHidden(nameof<IFormValues>(x => x.cardNumber))}
                tooltip={paymentLabels.tooltipCardNumber}
            />)
    }

    const SortCodeConfirm = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.sortCodeConfirm)}
                placeholder={paymentLabels.labelSortCode}
                label={paymentLabels.labelSortCode}
                controlId={nameof<IFormValues>(x => x.sortCodeConfirm)}
                hidden={isFieldHidden(nameof<IFormValues>(x => x.sortCode))}
                tooltip={paymentLabels.tooltipSortCode}
            />)
    }

    const DefaultDisplay = () => {
        return (
            <>
                <BankAccountNumberConfirm/>
                <CardNumberConfirm/>
                <SortCodeConfirm/>
            </>
        )
    }

    const BankBeforeAccountDisplay = () => {
        return (
            <>
                <SortCodeConfirm/>
                <BankAccountNumberConfirm/>
                <CardNumberConfirm/>
            </>
        )
    }

    return (<>
            {bankBeforeAccountCurrencyCodes.some(x => x !== formProps.values.currency) &&
                <DefaultDisplay/>
            }
            {bankBeforeAccountCurrencyCodes.some(x => x === formProps.values.currency) &&
                <BankBeforeAccountDisplay/>
            }
        </>
    );
}