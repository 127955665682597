export default {
    labelBookingCancelledPostponedHeading: "هل تم إلغاء حجزك أو تأجيله؟ اقرأ المزيد",
    labelBookingCancelledPostponedText1: "انقر",
    labelBookingCancelledPostponedText2: "لمزيد من المعلومات",
    labelNewRefundHeading: "تطبيق استرداد جديد",
    labelNewRefundText: "أدخل مرجع الحجز الخاص بك هنا",
    placeholderBookingReference: "معلومات الحجز",
    validationBookingReference: "مرجع الحجز مطلوب",
    validationEmailAsBookingReference: "للا يتم قبول عنوان البريد الإلكتروني كمرجع للحجز",
    buttonFindBooking: "البحث عن الحجز",
    labelEmailHeading: "بدأت بالفعل؟",
    labelEmailText: "إرسال بريد إلكتروني تذكير لاسترداد التطبيق الخاص بك",
    placeholderEmail: "بريد الالكتروني",
    buttonEmailReminder: "تذكير بالبريد الإلكتروني",
    labelCustomerName: "اسم الزبون",
    validationCustomerName: "اسم العميل مطلوب",
    labelTypeOfBooking: "نوع الحجز",
    validationTypeOfBooking: "نوع الحجز مطلوب",
    labelEventName:"اسم الحدث",
    labelVenueName:"اسم المكان",
    labelFlightNumber:"رقم الرحلة الجوية",
    labelOriginAirport: "مطار الأصل",
    labelHotelName: "اسم السكن",
    labelHotelLocation: "مكان الإقامة",
    labelCurrency: "عملة",
    validationCurrency: "العملة مطلوبة",
    validationCountryCurrency: "يجب أن تكون العملة المحددة هي عملة موطنك",
    validationOfflinePayment: "لا يمكننا معالجة المبالغ المستردة للعملة والبلد الذي اخترته، يرجى تحديد البدائل",
    labelBookingValue: "قيمة الحجز",
    validationBookingValue: "قيمة الحجز مطلوبةd",
    validationBookingValueAmount: "يجب أن يكون أكبر من ۰",
    labelDateOfPurchase: "تاريخ الشراء",
    validationDateOfPurchase: "تاريخ الشراء مطلوب",
    labelEmailAddress: "عنوان بريد الكتروني",
    validationEmailAddress: "عنوان البريد الإلكتروني مطلوب",
    validationEmailAddressFormat: "تنسيق بريد إلكتروني غير صحيح",
    labelConfirmEmailAddress: "أكد عنوانك الإلكتروني",
    validationConfirmEmailAddressFormat: "تنسيق بريد إلكتروني غير صحيح",
    validationConfirmEmailAddress: "مطلوب عنوان البريد الإلكتروني للتأكيد",
    validationConfirmEmailAddressMatch: "عناوين البريد الإلكتروني غير متطابقة",
    validationEmailDomain: "لم يتم قبول مزود البريد الإلكتروني هذا ، يرجى استخدام بديل",
    validationDateOfEvent: "تاريخ الحدث مطلوب",
    labelHomeCountry: "الوطن",
    validationHomeCountry: "البلد الأصلي مطلوب",
    labelContactNumberCountryCode: "رقم الاتصال رمز البلد",
    validationContactNumberCountryCode: "مطلوب رمز الاتصال بالبلد",
    labelContactNumber: "رقم الاتصال",
    validationContactNumber: "رقم الاتصال مطلوب",
    labelAddressLine1: "العنوان سطر ۱",
    labelAddressLine2: "سطر العنوان ۲",
    labelCity: "مدينة",
    labelZipCode: "الرمز البريدي",
    labelRegion: "منطقة",
    validationRegion: "المنطقة مطلوبة",
    labelNumberOfTicketsToRefund: "عدد التذاكر المراد استردادها",
    validationNumberOfTicketsToRefund: "عدد التذاكر المطلوب استردادها",
    validationNumberOfTicketsToRefundValue: "يجب أن يكون أكبر من ۰",
    labelCostPerTicket: "تكلفة كل تذكرة",
    validationCostPerTicket: "التكلفة لكل تذكرة مطلوبة",
    validationCostPerTicketValue: "يجب أن يكون أكبر من ۰",
    labelTotalRefundAmount: "إجمالي المبلغ المسترد",
    validationTotalRefundAmount: "المبلغ الإجمالي المسترد مطلوب",
    validationTotalRefundAmountValue: "يجب أن يكون أكبر من ۰",
    validationTotalRefundGreaterThanBookingValue: "لا يمكن أن يكون إجمالي مبلغ الاسترداد أكبر من قيمة الحجز أعلاه",
    labelBookingConfirmationFiles: "تحميل تأكيد الحجز",
    validationBookingConfirmationFiles: "أرفق ملفًا واحدًا على الأقل أو انقر فوق حفظ والعودة لاحقًا عندما تكون لديك المستندات",
    validationEvidenceFilesPopup: "الأدلة مطلوبة ليتم تحميلها قبل التقديم. إذا لم يكن لديك الدليل الآن ، يرجى حفظ الطلب والعودة بمجرد حصولك. نقبل النسخ الإلكترونية ونسخ الماسح الضوئي والصور.",
    validationBookingConfirmationFilesPopup: "تأكيد الحجز مطلوب ليتم تحميله قبل التقديم. إذا لم يكن لديك تأكيد الحجز الآن ، فيرجى حفظ التطبيق والعودة بمجرد حصولك. نقبل النسخ الإلكترونية ونسخ الماسح الضوئي والصور.",
    alertUploadDocuments: "يرجى تحميل الوثائق ذات الصلة المفصلة في البريد الإلكتروني ثم المتابعة إلى الخطوة ۳.",
    labelRefundReasonCategory: "سبب الاسترداد",
    validationRefundReasonCategory: "مطلوب سبب رد الأموال",
    labelDateOfIncident: "تاريخ الحادث",
    labelAdditionalComments: "تعليقات اضافية",
    tooltipAdditionalComments: "تعليقات اضافية",
    validationAdditionalComments: "مطلوب تعليقات إضافية",
    labelUnableToNotifyWithinTimeLimit: "غير قادر على الإعلام في غضون ٦٠ يومًا من",
    labelReasonForNotificationOutsideTimeLimit: "إخطار خارج شرح المهلة الزمنية",
    validationReasonForNotificationOutsideTimeLimit: "مطلوب سبب للإخطار بعد ٦٠ يومًا",
    labelRefundReasonFiles: "تحميل الدليل",
    validationRefundReasonFiles: "أرفق ملفًا واحدًا على الأقل أو انقر فوق حفظ والعودة لاحقًا عندما تكون لديك المستندات",
    validationTextScript: "يجب أن يتطابق مع نص لاتيني",
    optionTypeOfBooking1: "هدف",
    optionTypeOfBooking2: "رحلة",
    optionTypeOfBooking3: "رحلة جوية",
    optionTypeOfBooking4: "إقامة",
    optionTypeOfBooking5: "تحويل",
    optionTypeOfBooking6: "موقف سيارات",
    optionTypeOfBooking7: "سفر أخرى",
    labelEventTypeDate: "تاريخ الحدث",
    labelEventTypeTour: "تاريخ الرحلة",
    labelEventTypeFlight: "تاريخ الرحلة",
    labelEventTypeCheckIn: "تاريخ تسجيل الوصول",
    labelEventTypeTransfer: "تاريخ التحويل",
    labelEventTypeParking: "تاريخ الوقوف",
    labelEventTypeOtherTravel: "تاريخ السفر",
    optionRefundReason11: "الطقس المعاكس",
    optionRefundReason10: "استدعاء القوات المسلحة وخدمات الطوارئ",
    optionRefundReason15: "التغييرات في مواعيد الامتحان",
    optionRefundReason18: "استدعاء المحكمة",
    optionRefundReason17: "قيود السفر لـ COVID-19",
    optionRefundReason19: "اختبار COVID-19 الإيجابي",
    optionRefundReason20: "ألغى المشغل / المنظم الحجز",
    optionRefundReason21: "أعاد المشغل / المنظم جدولة الحجز",
    optionRefundReason22: "تاريخ الحجز خاطئ",
    optionRefundReason23: "حجز تذاكر خاطئة",
    optionRefundReason24: "حجزت تذاكر كثيرة جدًا",
    optionRefundReason4: "موت",
    optionRefundReason16: "ظروف الطوارئ",
    optionRefundReason14: "حظر السفر الحكومي",
    optionRefundReason9: "الصفحة الرئيسية الطوارئ",
    optionRefundReason1: "مرض / إصابة / حادث",
    optionRefundReason8: "خدمة هيئة المحلفين",
    optionRefundReason7: "الانهيار الميكانيكي",
    optionRefundReason2: "الحالة الطبية الموجودة مسبقًا",
    optionRefundReason3: "مضاعفات الحمل",
    optionRefundReason5: "فشل النقل العام",
    optionRefundReason6: "اضطراب الرحلة",
    optionRefundReason13: "سرقة الوثيقة (الوثائق)",
    optionRefundReason12: "نقل العمل",
    optionRefundReason25: "التكرار في مكان العمل",
    optionRefundReason26: "خيار الاختبار",
    optionRefundReasonConfirm1: "لا",
    optionRefundReasonConfirm2: "نعم",
    optionRefundReasonArmedForces1: "جيش",
    optionRefundReasonArmedForces2: "بحرية",
    optionRefundReasonArmedForces3: "القوات الجوية",
    optionRefundReasonArmedForces4: "القوات الخاصة",
    optionRefundReasonArmedForces5: "مشاه البحريه",
    optionRefundReasonArmedForces6: "النار",
    optionRefundReasonArmedForces7: "سيارة إسعاف",
    optionRefundReasonArmedForces8: "طبيب/ممرض",
    optionRefundReasonArmedForces9: "طبيب الأسنان",
    optionRefundReasonArmedForces10: "آخر",
    optionRefundReasonCourtSummons1: "الملاحقة الجنائية",
    optionRefundReasonCourtSummons2: "القضية القانونية المدنية",
    optionRefundReasonCourtSummons3: "الشاهد في الادعاء",
    optionRefundReasonCourtSummons4: "شاهد في قضية مدنية",
    optionRefundReasonCourtSummons5: "مدع",
    optionRefundReasonCourtSummons6: "مدعى عليه",
    optionRefundReasonCourtSummons7: "آخر",
    optionRefundReasonDeath1: "زوج",
    optionRefundReasonDeath2: "زوجة",
    optionRefundReasonDeath3: "صوت",
    optionRefundReasonDeath4: "ابنة",
    optionRefundReasonDeath5: "أب",
    optionRefundReasonDeath6: "أم",
    optionRefundReasonDeath7: "أخ",
    optionRefundReasonDeath8: "أخت",
    optionRefundReasonDeath9: "جد",
    optionRefundReasonDeath10: "جدة",
    optionRefundReasonDeath11: "آخر",
    optionRefundReasonHomeEmergency1: "النار",
    optionRefundReasonHomeEmergency2: "فيضان",
    optionRefundReasonHomeEmergency3: "السرقه",
    optionRefundReasonHomeEmergency4: "التخريب",
    optionRefundReasonHomeEmergency5: "العنف المنزلي",
    optionRefundReasonHomeEmergency6: "خطف",
    optionRefundReasonHomeEmergency7: "آخر",
    optionRefundReasonCOVID19Person1: "",
    optionRefundReasonCOVID19Person2: "شخص آخر في الحجز",
    optionRefundReasonCOVID19Person3: "شخص في منزلي",
    optionRefundReasonCOVID19Person4: "آخر",
    optionRefundReasonCOVID19TestType1: "اختبار PCR",
    optionRefundReasonCOVID19TestType2: "اختبار RAT",
    optionRefundReasonCOVID19TestType3: "اختبار التدفق الجانبي",
    optionRefundReasonCOVID19TestType4: "اختبار ذاتي الإدارة",
    optionRefundReasonCOVID19TestType5: "لا يوجد اختبار ولكن لدي أعراض",
    optionRefundReasonCOVID19TestType6: "آخر",
    optionRefundReasonBreakdown1: "انهيار",
    optionRefundReasonBreakdown2: "حادث",
    optionRefundReasonBreakdown3: "النار",
    optionRefundReasonBreakdown4: "سرقة",
    optionRefundReasonPublicTransportType1: "قطار",
    optionRefundReasonPublicTransportType2: "باص",
    optionRefundReasonPublicTransportType3: "ترام",
    optionRefundReasonPublicTransportType4: "زورق",
    optionRefundReasonDocumentType1: "جواز سفر",
    optionRefundReasonDocumentType2: "تصريح السفر",
    optionRefundReasonDocumentType3: "بطاقة الرقم القومي",
    optionRefundReasonDocumentType4: "تذاكر",
    optionRefundReasonWorkRelocationType1: "مؤقت",
    optionRefundReasonWorkRelocationType2: "دائم",
    optionRefundReasonWorkRelocationTemporary1: "۰-۳ أشهر",
    optionRefundReasonWorkRelocationTemporary2:  "۳-٦ أشهر",
    optionRefundReasonWorkRelocationTemporary3: "أكثر من ٦ أشهر",
    optionRefundReasonBookingRelationshipType1: "شخص ما في الحجز",
    optionRefundReasonBookingRelationshipType2: "قريب مباشر لشخص ما في الحجز",
    optionEmploymentType1: "دوام كامل",
    optionEmploymentType2: "دوام جزئي",
    optionEmploymentType3: "عقد",
    optionEmploymentLengthType1: "۰-۱ سنة",
    optionEmploymentLengthType2: "۱-۲ سنوات",
    optionEmploymentLengthType3: "أكثر من ۲ سنوات",
    textRefundEvidenceHeading: "يرجى تقديم الأدلة التالية:",
    textRefundEvidence1: "تأكيد شركة الطيران",
    textRefundEvidence2: "أي أدلة داعمة مناسبة",
    textRefundEvidence3: "خدمة الأعطال استدعاء تأكيد",
    textRefundEvidence4: "شهادة الوفاة",
    textRefundEvidence5: "تأكيد الإلغاء من شركة الطيران",
    textRefundEvidence6: "تقرير الطقس",
    textRefundEvidence7: "مراسلات من وكيل الحجز تؤكد عدم القدرة على إعادة إصدار التذاكر",
    textRefundEvidence8: "رسالة من المحكمة",
    textRefundEvidence9: "شهادة طبية / مذكرة الطبيب",
    textRefundEvidenceDescription9: "تأكيد تفاصيل المرض أو الإصابة، وتاريخ حدوثها لأول مرة، وأنها تمنعك من حضور الحجز.",
    textRefundEvidence10: "تأكيد خطاب صاحب العمل",
    textRefundEvidence11: "مراسلات مطالبات التأمين",
    textRefundEvidence12: "رسالة من صاحب العمل",
    textRefundEvidence13: "تأكيد موقع الحكومة الوطنية على شبكة الإنترنت",
    textRefundEvidence14: "إشعار تغيير الامتحان",
    textRefundEvidence15: "تقرير الشرطة",
    textRefundEvidence16: "تقرير الشرطة والرقم المرجعي",
    textRefundEvidence17: "تأكيد مزود خدمة النقل",
    textRefundEvidence18: "تحذير السفر",
    textRefundEvidence20: "شهادة اختبار COVID-19",
    textRefundEvidence24: "إشعار العزل الحكومي",
    textRefundEvidence25: "رسالة من الضابط القائد",
    textRefundEvidence26: "رسالة من شركات التأمين",
    textRefundEvidence27: "إثبات العنوان",
    textRefundEvidence28: "إثبات العلاقة بالمتوفى",
    textRefundEvidence29: "إثبات العلاقة بالشخص المصاب / المريض",
    textRefundEvidence30: "قسيمة الدفع",
    tooltipBookingReference: "لتحديد مرجع الحجز الخاص بك، تحقق من تأكيد الحجز الخاص بك واطلع على القسم الخاص بخدمتنا الموسعة.",
    tooltipCustomerName: "الاسم الأول والأخير الذي تم استخدامه لإجراء الحجز.",
    tooltipTypeOfBooking: "نوع الحجز الذي تريد استرداده.",
    tooltipEventName: "اسم الحدث.",
    tooltipVenueName: "اسم المكان.",
    tooltipFlightNumber: "رقم الرحلة.",
    tooltipOriginAirport: "مطار المغادرة.",
    tooltipHotelName: "اسم السكن.",
    tooltipHotelLocation: "موقع السكن.",
    tooltipCurrency: "العملة المستخدمة لدفع تكاليف الحجز.",
    tooltipBookingValue: "السعر الإجمالي الذي دفعته للحجز.",
    tooltipDateOfPurchase: "التاريخ الذي تم فيه الحجز.",
    tooltipEmailAddress: "يرجى استخدام عنوان بريد إلكتروني صالح. نستخدم هذا البريد الإلكتروني للمراسلات أثناء تطبيق استرداد الأموال.",
    tooltipConfirmEmailAddress: "استخدم نفس عنوان البريد الإلكتروني المذكور أعلاه ، هذا للتحقق.",
    tooltipHomeCountry: "سيساعدنا هذا في عرض المعلومات المصرفية الصحيحة في الخطوة الأخيرة.",
    tooltipContactNumberCountryCode: "رقم الاتصال رمز البلد.",
    tooltipContactNumber: "الرجاء استخدام رقم صحيح. نستخدم هذا للمراسلات أثناء تطبيق استرداد الأموال.",
    tooltipAddressLine1: "يرجى ملاحظة أنه سيتم استخدام عنوانك عند التحقق من صحة حسابك المصرفي.",
    tooltipAddressLine2: "السطر الثاني من العنوان.",
    tooltipCity: "مدينتك ، يمكن استخدام التفاصيل للتحقق من صحة التفاصيل المصرفية.",
    tooltipZipCode: "الرمز البريدي / الرمز البريدي.",
    tooltipRegion: "منطقة مدينتك ، يمكن استخدام التفاصيل للتحقق من صحة التفاصيل المصرفية.",
    tooltipNumberOfTicketsToRefund: "عدد التذاكر التي تود استردادها.",
    tooltipTotalRefundAmount: "لا يمكن أن يكون هذا أكثر من إجمالي قيمة الحجز ، فلن تتمكن من تقديم الطلب إذا كان الأمر كذلك.",
    tooltipBookingConfirmationFiles: "لقطة شاشة / مرفق لتأكيد الحجز الكامل الخاص بك والذي يمكن العثور عليه عادةً في رسائل البريد الإلكتروني الخاصة بك.",
    tooltipRefundReasonCategory: "الرجاء تحديد سبب استرداد الأموال.",
    tooltipDateOfIncident: "تاريخ وقوع الحادث الذي أثر على حجزك.",
    tooltipRefundExplanation: "يرجى تقديم أكبر قدر ممكن من التفاصيل التي تدعم مطالبة استرداد الأموال.",
    tooltipUnableToNotifyWithinTimeLimit: "يتعذر الإعلام في غضون ٦٠ يومًا كحد زمني.",
    tooltipReasonForNotificationOutsideTimeLimit: "إعلام خارج توضيح المهلة الزمنية.",
    tooltipRefundReasonFiles: "يرجى تحميل أي من المستندات المطلوبة.",
    tooltipEventTypeDate: "تاريخ الحدث الفعلي / الحجز.",
    tooltipEventTypeTour: "تاريخ الجولة الفعلية.",
    tooltipEventTypeFlight: "تاريخ الرحلة الفعلية.",
    tooltipEventTypeCheckIn: "تاريخ تسجيل الوصول الفعلي.",
    tooltipEventTypeTransfer: "تاريخ التحويل الفعلي.",
    tooltipEventTypeParking: "تاريخ الموقف الفعلي.",
    tooltipEventTypeOtherTravel: "تاريخ بدء الرحلة الفعلية."
};