import * as React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {CloseButton} from '../buttons/CloseButton';
import {ModalHolder} from './ModalHolder';

export const BookingTermsModal = () => {

    const {t} = useTranslation();

    const [showBookingTermsModal, setShowBookingTermsModal] = useState<boolean>(false);

    const showBookingTermsModalHandler = () => {
        setShowBookingTermsModal(true);
    }

    const closeBookingTermsModalHandler = () => {
        setShowBookingTermsModal(false);
    }

    const Content = () => {
        return (<>
            <Box sx={{
                p: {
                    xs: 0,
                    sm: 5,
                    md: 5
                }
            }}>
                <Box pb={4}>
                    <b>{t('common:messageWarningBookingHeading1')} </b>
                    <hr/>
                    {t('common:messageWarningBookingBody1')}<br/><br/>
                    <b>{t('common:messageWarningBookingHeading2')}</b>
                    <hr/>
                    <span>{t('common:messageWarningBookingBody2')}</span><br/><br/>
                    <span><i>{t('common:messageWarningBookingBody3')}</i></span><br/><br/>
                    {t('common:messageWarningBookingBody4')}
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonClose')}
                        handleOnClick={closeBookingTermsModalHandler}
                    />
                </Box>
            </Box>
        </>);
    }

    return (
        <>
            <Grid container justifyContent='center'
                  sx={{
                      pt: '20px',
                      pb: '50px',
                      textAlign: 'center'
                  }}>
                <Box sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                    onClick={showBookingTermsModalHandler}>
                    {t('form:labelBookingCancelledPostponedHeading')}
                </Box>
            </Grid>

            <ModalHolder
                content={<Content/>}
                showModal={showBookingTermsModal}
                handleClose={closeBookingTermsModalHandler}
                width='md'
            />
        </>
    );
}