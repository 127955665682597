import * as React from 'react';
import {
    FormGroup,
    FormControl
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {
    Field,
    FieldProps,
    useField,
    useFormikContext
} from 'formik';
import dayjs from 'dayjs';
import {IInputProps} from './IInputProps';
import {IFormValues} from '../Interfaces/IFormValues';
import './dateLocales';
import {useDateLocale} from '../../../languages/locales/languageSettings';
import {ErrorMessage} from '../ui/ErrorMessage';
import {isInvalid} from '../utils/validationHelpers';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {BorderRadiusInputField} from '../utils/constants-styling';

export const DateInput = (props: IInputProps) => {

    const {
        controlId,
        hidden,
        label,
        required,
        tooltip
    } = props;

    const dateLocale = useDateLocale();
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props.controlId);

    return (
        <>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues>) => (
                        <FormGroup hidden={hidden}>
                            <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
                                <FormLabelHolder
                                    label={label}
                                    required={required}
                                    tooltip={tooltip}
                                />
                                <Grid size={{xs: 12, sm: 4, md: 3}}>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dateLocale}>
                                            <DatePicker
                                                name={controlId}
                                                onChange={(value) => {
                                                    void setFieldValue(field.name, value);
                                                }}
                                                value={dayjs(fieldProps.form.getFieldProps(controlId).value)}
                                                sx={{
                                                    'fieldset': {
                                                        borderRadius: BorderRadiusInputField
                                                    },
                                                    '.MuiInputBase-input': {
                                                        height: '0.7em',
                                                        alignItems: 'center'
                                                    }
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        error: isInvalid(fieldProps)
                                                    },
                                                }}
                                            />
                                            <ErrorMessage fieldProps={fieldProps}/>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    )}
                </Field>
            }
        </>
    );
}