export default {
    nonAutoApprovedLabelThankYou: "شكرا لك على طلب استرداد الأموال الخاص بك.",
    nonAutoApprovedLabelReview: "سيقوم فريقنا بمراجعة طلبك في غضون يومي العمل المقبلين والرد عليك بالرد.",
    nonAutoApprovedLabelExperience: "كيف فعلنا؟",
    nonAutoApprovedLabelExperienceIntroduction: "نحن نقدر حقا أن تأخذ الوقت الكافي لتقييمنا وترك مراجعة لأننا نقدر رأي عملائنا.",
    feedbackExcellent: "ممتازة",
    feedbackGreat: "عظيم",
    feedbackAverage: "متوسط",
    feedbackPoor: "فقير",
    feedbackBad: "سيئ",
    labelThankYouForFeedback: "شكرا لك على ملاحظاتك.",
    autoApprovedMessage1: "يسعدنا أن",
    autoApprovedMessage2: "صدق",
    autoApprovedMessage3: "استرداد أموالك!",
    autoApprovedPayment1Day: "تمت معالجة دفعتك بالفعل ويجب أن تكون في حسابك في يوم عمل 1 فقط.",
    autoApprovedPayment2to4Days: "تمت معالجة دفعتك بالفعل ويجب أن تكون في حسابك في غضون ۲-٤ أيام عمل فقط.",
    autoApprovedPayment7to10Days: "تمت معالجة دفعتك بالفعل ويجب أن تكون في حسابك في غضون ۷-۱۰ أيام عمل فقط.",
    autoApprovedLabelExperience: "كيف فعلنا؟",
    autoApprovedLabelExperienceIntroduction: "نحن نقدر حقا أن تأخذ الوقت الكافي لتقييمنا وترك مراجعة لأننا نقدر رأي عملائنا.",
    modalLabelNegative: "ملاحظاتك مهمة حقا بالنسبة لنا ، أخبرنا إذا كنت ترغب أيضا في أن نتواصل معك.",
    placeholderFeedback: "ردود الفعل",
    messageFeedbackSubmitted: "التعليقات المقدمة",
    reviewInstructionMessage: "۵ نجوم ممتازة ، ٤ نجوم رائعة ، ۳ نجوم متوسطة ، ۲ نجوم فقيرة ، ۱ نجمة سيئة."
}