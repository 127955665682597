import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {AlertHolder} from './AlertHolder';
import {EvidenceResubmissionState} from '../../state/activity';

const UploadDocumentsMessage = () => {

    const {t} = useTranslation();

    return (
        <Box>{t('form:alertUploadDocuments')}</Box>
    );
}
export const EvidenceResubmissionAlert = () => {

    const evidenceResubmission = useRecoilValue(EvidenceResubmissionState);

    return (<>
            {evidenceResubmission &&
                <AlertHolder
                    severity='warning'
                    content={<UploadDocumentsMessage/>}
                />
            }
        </>
    );
}