import * as React from 'react';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ButtonTextThemeProps {
    buttonText: string;
}

export const ButtonTextTheme = (props: ButtonTextThemeProps) => {

    const {
        buttonText
    } = props;

    const theme = useTheme();
    const isSmAndAbove = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            {!isSmAndAbove &&
                null
            }

            {isSmAndAbove &&
                <Box component='span' sx={{
                    paddingLeft: '5px',
                    paddingRight: '5px'
                }}>
                    {buttonText}
                </Box>
            }
        </>
    );
}