export default {
    messageUnableToMakePaymentToCurrency: "К сожалению, мы не можем получить банковские реквизиты для выбранной валюты. Убедитесь, что валюта и страна проживания выбраны на",
    messageOnlyBankAccountsAccepted: "Обратите внимание: в настоящее время мы можем осуществлять платежи только на банковские счета (кредитные и дебетовые карты не принимаются).",
    labelAccountName: "имя владельца счета",
    labelBankAccountName: "Полное имя владельца счета",
    labelBankCardHolderName: "Имя владельца карты",
    validationBankAccountName: "Требуется полное имя владельца счета",
    labelBankCountry: "Страна банка",
    labelBankName: "Название банка",
    labelPaymentType: "Способ оплаты",
    labelInstitutionNumber: "Номер учреждения",
    labelBankAddress: "Адрес банка",
    labelBankAddress2: "Адрес банка",
    labelBankCity: "Банк Сити",
    labelBankState: "Состояние банка",
    labelBankAccountNumber: "Номер банковского счета",
    labelBankAccountNumberCBU: "Номер счета CBU",
    labelBankAccountNumberCCI: "Номер счета CCI",
    labelBankAccountNumberZAR: "Номер банковского счета (7-11 цифр)",
    labelCardNumber: "Номер карты",
    labelUnionpay: "Номер карты UnionPay",
    labelAlipay: "Alipay ID (номер мобильного телефона или электронная почта)",
    labelBankAccountType: "тип аккаунта",
    optionBankAccountTypeCURRENT: "Текущий аккаунт",
    optionBankAccountTypeCHECKING: "Проверка аккаунта",
    optionBankAccountTypeSAVING: "Сберегательный счет",
    optionBankAccountTypeSAVINGS: "Сберегательный счет",
    optionBankAccountTypeCUENTA_VISTA: "Основная учетная запись",
    optionBankAccountType1: "Проверка аккаунта",
    optionBankAccountType2: "Сберегательный счет",
    optionBankAccountType3: "Основная учетная запись",
    optionBankAccountType4: "Учетная запись Vista",
    optionBankAccountType5: "Учетная запись РУТ",
    labelSortCode: "Сортировать код",
    labelRoutingCode: "Код маршрутизации",
    labelBankRoutingCodeNationalID: "Банковский маршрутный код / национальный идентификатор",
    labelBankTransitCodeSwift: "Транзитный номер",
    labelCNAPSCode: "Код маршрутизации / код CNAPS (Код национальной усовершенствованной платежной системы Китая)",
    labelABAFedwireRoutingCode: "Маршрутный номер ACH",
    labelIFSCCode: "Кодекс IFSC",
    labelBankCode: "Код банка",
    labelBIKCode: "Код БИК",
    labelBranchCode: "Номер отдела",
    labelBranchCodeUniversal: "Универсальный 6-значный код филиала",
    labelRemittanceLine3: "Контактное имя и номер телефона получателя",
    labelBeneficiaryContactNameandTelephoneNumber: "Контактное имя получателя и номер телефона",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Бенефициар Дата рождения и место рождения",
    labelRemittanceLine4: "Индивидуальный налоговый номер CUIL (9–12 цифр)",
    labelCUILNumber: "Идентификатор налога: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Идентификационный номер налогоплательщика RFC 13 цифр или CURP 18 цифр",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Индивидуальный налоговый номер CPF Cadastro de Pessoa Física (11-14 цифр), код филиала",
    labelCFPNumber: "Номер CPF (11-14 цифр)",
    labelBranchNameorBranchCode7digits: "Название отделения или код отделения (7 цифр)",
    labelCedulaJuridica910digitsTaxID: "Юридический сертификат(идентификационный номер налогоплательщика 9-10 цифр)",
    labelTaxIDRUT89digits: "Налоговый ID RUT (8-9 цифр)",
    labelJob: "Название работы",
    labelDateOfBirth: "Дата рождения",
    labelNationality: "Национальность",
    labelDocumentType: "тип документа",
    labelDocumentNumber: "Номер документа",
    labelRutNumber: "Номер RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Идентификационная карта (идентификационный номер налогоплательщика, 8–11 цифр)",
    labelCIIndividualTaxIDBranchLocation: "CI (индивидуальный налоговый номер), местонахождение отделения",
    labelTaxIDRUCorCI: "Идентификационный номер налогоплательщика (RUC или CI)",
    labelSortCodeHelp: "Помощь?",
    labelBranchCodeHelp: "Помощь?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Помощь?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT BIC",
    labelBeneficiarySWIFTBICHelp: "Помощь?",
    labelRoutingCodeBSB: "Код маршрутизации / BSB",
    labelBSB: "Государственный филиал банка (BSB)",
    labelSKNCode: "Код СКН",
    labelAcceptanceFlights: "Я/мы соглашаемся с тем, что если заявка на возврат будет успешной, бронирование, включая все рейсы и дополнительные продукты, будет отменено.",
    labelAcceptanceTickets: "Я/мы соглашаемся с тем, что если заявка на возврат средств будет успешной, бронирование, включая все билеты и дополнительные продукты, будет отменено.",
    labelTermsAndConditions1: "Я / мы согласились на",
    labelTermsAndConditions2: "Условия и положения",
    labelFraudWarning1: "Я считаю, что факты, представленные в этой форме заявления на возмещение, являются подлинными и правдивыми. Я понимаю, что любая ложная информация или доказательства могут быть расценены как уголовное преступление и переданы в полицию или другие правоохранительные органы для расследования.",
    labelFraudWarning2: "Если мы подозреваем мошенничество в отношении подтверждающей документации, этот вопрос будет немедленно передан в местные органы полиции. Я принимаю и понимаю, что мои данные будут переданы этим властям, если мое заявление будет сочтено мошенническим и это может стать уголовным расследованием.",
    validationTermsAndConditions: "Условия должны быть согласованы с",
    messageFailbankName: "Название банка не может быть пустым",
    messageFailcustomerName: "Имя клиента не может быть пустым",
    messageFailbankAccountNumber: "Обязательное поле",
    messageFailbankAccountNumberFormat: "Должен быть формат IBAN",
    messageFailsortCode: "Обязательное поле",
    messageFailbankSwiftBic: "SWIFT BIC получателя не может быть пустым.",
    messageFailbankSwiftBicFormat: "Должен быть в формате SWIFT",
    messageFailbeneficiaryAddress1: "Адрес 1 не может быть пустым",
    messageFailbeneficiaryCity: "Город не может быть пустым",
    messageFailbeneficiaryPostCode: "Почтовый индекс не может быть пустым",
    messageFailremittanceLine3: "Обязательное поле",
    messageFailremittanceLine4: "Обязательное поле",
    modalHeading: "Подтвердите банковские реквизиты",
    modalLabelPleaseConfirmYourDetails: "Пожалуйста, проверьте свои банковские реквизиты и подтвердите.",
    modalValidationPleaseConfirm: "Пожалуйста подтвердите",
    modalValidationMustMatch: "Должен соответствовать предыдущей записи",
    tooltipBankAccountName: "Ваше имя, как оно указано на вашем банковском счете / выписках из банка / банковской карте.",
    tooltipBankAccountNameRecipient: "Имя и фамилия получателя. И имя, и фамилия должны содержать более одного символа.",
    tooltipBankCountry: "Страна, в которой находится ваш банк.",
    tooltipBankName: "Название вашего банка.",
    tooltipPaymentType: "Тип оплаты, который вам нужен.",
    tooltipInstitutionNumber: "Это 3 цифры, которые обычно можно найти в ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, спросите свой банк.",
    tooltipBankAccountNumber: "Номер вашего индивидуального счета. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipBankAccountNumberCBU: "22-значный номер, используемый для идентификации вашей учетной записи. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipBankAccountNumberCCI: "20-значный номер, используемый для идентификации вашей учетной записи. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipBankAccountNumberKRW: "Номер из 11–16 цифр, используемый для идентификации вашей учетной записи. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipCardNumber: "Номер вашей карты. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipUnionpay: "Номер вашей карты UnionPay.",
    tooltipAlipay: "Ваш номер мобильного телефона или адрес электронной почты, связанный с вашей учетной записью Alipay.",
    tooltipBankAccountType: "Это либо проверка, либо сбережение.",
    tooltipSortCode: "Это идентифицирует отделение вашего банка, в котором открыт ваш счет. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipRoutingCode: "Это идентифицирует отделение вашего банка, в котором открыт ваш счет. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipBeneficiarySWIFTBIC: "Состоит из 8-11 символов, используемых для идентификации банка при международной транзакции. Обычно указывается в вашей ежемесячной выписке или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBSB: "Номер государственного отделения банка (BSB) - это код сортировки в Австралии, состоящий из 6 цифр. Обычно указывается в вашей ежемесячной выписке или, если вы все еще не уверены, спросите в своем банке.",
    tooltipRoutingCodeBSB: "Это идентифицирует отделение вашего банка, в котором открыт ваш счет. Обычно указывается в вашей ежемесячной выписке или на банковской карте, или, если вы все еще не уверены, обратитесь в свой банк.",
    tooltipABAFedwireRoutingCode: "Девятизначный номер, используемый для идентификации банков в Америке. Обычно указывается в вашей ежемесячной выписке или, если вы все еще не уверены, спросите в своем банке.",
    tooltipIFSCCode: "Обычно этот код можно найти в ежемесячной выписке или в сберегательной книжке, или, если вы все еще не уверены, спросите свой банк.",
    tooltipTermsAndConditions: "Принимая Условия использования, вы подтверждаете, что прочитали и согласны с нашими Условиями использования.",
    tooltipIBAN: "Номер вашего международного банковского счета, который вы можете использовать при совершении или получении международных платежей. Обычно указывается в вашей ежемесячной выписке или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Контактное имя и номер телефона получателя.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Бенефициар Дата рождения и место рождения.",
    tooltipBankRoutingCodeNationalID: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankTransitCodeSwift: "Это 5 цифр, и обычно их можно найти в ежемесячной банковской выписке или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankAddress: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankAddress2: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankCity: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankState: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBranchName: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBankCode: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBIKCode: "9 цифр для идентификации вашего банка.",
    tooltipBranchCode: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBranchCodeUniversal: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipCNAPSCode: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipRemittanceLine4: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipCUILNumber: "Это 11-значное число, состоящее из двух цифр, дефиса, девяти цифр и однозначной контрольной суммы.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipCFPNumber: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipBranchNameorBranchCode7digits: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipCedulaJuridica910digitsTaxID: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipTaxIDRUT89digits: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipJob: "Ваше текущее занятие.",
    tooltipDateOfBirth: "Ваша дата рождения.",
    tooltipNationality: "Ваша национальность.",
    tooltipDocumentType: "Требуемый тип документа.",
    tooltipDocumentNumber: "Это номер документа, связанный с выбранным вами типом документа.",
    tooltipRutNumber: "Ваш номер RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipCIIndividualTaxIDBranchLocation: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    tooltipTaxIDRUCorCI: "Обычно ее можно найти в ежемесячной выписке по счету или, если вы все еще не уверены, спросите в своем банке.",
    errorCPFCode: "На данный момент мы принимаем только CPF в качестве ключа PIX."
};