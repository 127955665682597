export default {
    nonAutoApprovedLabelThankYou: "Дякуємо за вашу заявку на повернення коштів.",
    nonAutoApprovedLabelReview: "Наша команда розгляне вашу заявку протягом наступних двох робочих днів і зв'яжеться з вами з відповіддю.",
    nonAutoApprovedLabelExperience: "Як ми це зробили?",
    nonAutoApprovedLabelExperienceIntroduction: "Ми дуже вдячні, що ви знайшли час, щоб оцінити нас і залишити відгук, оскільки ми цінуємо думку наших клієнтів.",
    feedbackExcellent: "Відмінна",
    feedbackGreat: "Чудовий",
    feedbackAverage: "Середній",
    feedbackPoor: "Бідний",
    feedbackBad: "Поганий",
    labelThankYouForFeedback: "Дякуємо за ваш відгук.",
    autoApprovedMessage1: "Ми в захваті від",
    autoApprovedMessage2: "Затвердити",
    autoApprovedMessage3: "ваше повернення коштів!",
    autoApprovedPayment1Day: "Ваш платіж вже оброблений і повинен з'явитися у вашому обліковому записі всього за 1 робочий день.",
    autoApprovedPayment2to4Days: "Ваш платіж вже оброблено і він повинен з'явитися у вашому обліковому записі всього за 2-4 робочих дні.",
    autoApprovedPayment7to10Days: "Ваш платіж вже оброблено і він повинен з'явитися у вашому обліковому записі всього за 7-10 робочих днів.",
    autoApprovedLabelExperience: "Як ми це зробили?",
    autoApprovedLabelExperienceIntroduction: "Ми дуже вдячні, що ви знайшли час, щоб оцінити нас і залишити відгук, оскільки ми цінуємо думку наших клієнтів.",
    modalLabelNegative: "Ваш відгук дійсно важливий для нас, повідомте нам, якщо ви також хочете, щоб ми зв'язалися з вами.",
    placeholderFeedback: "Зворотний зв'язок",
    messageFeedbackSubmitted: "Надіслано відгук",
    reviewInstructionMessage: "5 зірок - це відмінно, 4 зірки - відмінно, 3 зірки - середнє, 2 зірки - погано, 1 зірка - погано."
}