export default {
    messageUnableToMakePaymentToCurrency: "Tyvärr kan vi inte hämta bankuppgifter för vald valuta. Se till att Valuta och Hemland är markerade på",
    messageOnlyBankAccountsAccepted: "Obs! Vi kan bara göra betalningar till bankkonton just nu (kredit- och betalkort accepteras inte)",
    labelAccountName: "Kontoinnehavarens namn",
    labelBankAccountName: "Kontoinnehavarens fullständiga namn",
    labelBankCardHolderName: "Kortinnehavarens namn",
    validationBankAccountName: "Kontoinnehavarens fullständiga namn krävs",
    labelBankCountry: "Bankland",
    labelBankName: "Bank namn",
    labelPaymentType: "Betalnings typ",
    labelInstitutionNumber: "Institutionsnummer",
    labelBankAddress: "Bankadress",
    labelBankAddress2: "Bankadress",
    labelBankCity: "Bank City",
    labelBankState: "Bankstat",
    labelBankAccountNumber: "Bankknontonummer",
    labelBankAccountNumberCBU: "Kontonummer CBU",
    labelBankAccountNumberCCI: "Kontonummer CCI",
    labelBankAccountNumberZAR: "Bankkontonummer (7-11 siffror)",
    labelCardNumber: "Kortnummer",
    labelUnionpay: "UnionPay-kortnummer",
    labelAlipay: "Alipay ID (mobilnummer eller e-post)",
    labelBankAccountType: "Kontotyp",
    optionBankAccountTypeCURRENT: "Nuvarande konto",
    optionBankAccountTypeCHECKING: "Checkkonto",
    optionBankAccountTypeSAVING: "Sparkonto",
    optionBankAccountTypeSAVINGS: "Sparkonto",
    optionBankAccountTypeCUENTA_VISTA: "Huvudkonto",
    optionBankAccountType1: "Checkkonto",
    optionBankAccountType2: "Sparkonto",
    optionBankAccountType3: "Huvudkonto",
    optionBankAccountType4: "Vista konto",
    optionBankAccountType5: "RUT-konto",
    labelSortCode: "Clearing Nummer",
    labelRoutingCode: "Ruttkodd",
    labelBankRoutingCodeNationalID: "Bankkod/nationellt ID",
    labelBankTransitCodeSwift: "Transiteringsnummer",
    labelCNAPSCode: "Ruttkod/CNAPS Kod (Kina Nationell Avancerad Betalning Systemkod)",
    labelABAFedwireRoutingCode: "ACH-routingnummer",
    labelIFSCCode: "IFSC-kod",
    labelBankCode: "Bankkod",
    labelBIKCode: "BIK-kod",
    labelBranchCode: "Gren-kod ",
    labelBranchCodeUniversal: "Universal 6-siffrig filialkod",
    labelRemittanceLine3: "Mottagarens Kontaktnamn och telefonnummer",
    labelBeneficiaryContactNameandTelephoneNumber: "Mottagarens Kontaktnamn och telefonnummer",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Mottagarrens Födelsedatum och Fodelseört",
    labelRemittanceLine4: "Individuellt Skattenummer CUIL (9-12 Siffror)",
    labelCUILNumber: "Skatte-id: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Skatte- ID RFC 13 siffror eller CURP 18 siffror",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuelle skate-ID CPF Cadastro de Pessoa Física (11-14 siffror), filiakkod",
    labelCFPNumber: "CPF-nummer (11-14 siffror)",
    labelBranchNameorBranchCode7digits: "Filialnamn eller filialkod (7 siffror)",
    labelCedulaJuridica910digitsTaxID: "Certado lagligt (skatte-ID för 9-10 siffror)",
    labelTaxIDRUT89digits: "Skatte-ID RUT (8-9 siffror)",
    labelJob: "Jobbtitel",
    labelDateOfBirth: "Födelsedatum",
    labelNationality: "Nationalitet",
    labelDocumentType: "Dokumenttyp",
    labelDocumentNumber: "Dokumentnummer",
    labelRutNumber: "RUT-nummer (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Id-kort (skatte-ID individuella 8-11 siffror)",
    labelCIIndividualTaxIDBranchLocation: "CI (individuellt skatte-ID), filialplats",
    labelTaxIDRUCorCI: "Skatte-ID (RUC eller CI)",
    labelSortCodeHelp: "Hjälp?",
    labelBranchCodeHelp: "Hjälp?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Hjälp?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Hjälp?",
    labelRoutingCodeBSB: "Ruttkod /BSB",
    labelBSB: "Bank State Branch (BSB)",
    labelSKNCode: "SKN-Kod",
    labelAcceptanceFlights: "Jag/vi accepterar att om återbetalningsansökan godkänns kommer bokningen inklusive alla flyg och tillhörande produkter att annulleras.",
    labelAcceptanceTickets: "Jag/vi accepterar att om återbetalningsansökan godkänns kommer bokningen inklusive alla biljetter och tillhörande produkter att annulleras.",
    labelTermsAndConditions1: "Jag/vi har gått med på",
    labelTermsAndConditions2: "Villkor",
    labelFraudWarning1: "Jag tror att fakta i denna återbetalningsansökningsblankett är äkta och sanna. Jag förstår att alla falska uppgifter eller bevis kan betraktas som en brottslig handling och vidarebefordras till polisen eller andra brottsbekämpande myndigheter.",
    labelFraudWarning2: "Om vi misstänker bedrägeri i samband med stödjande dokumentation kommer detta ärende omedelbart att hänskjutas till de lokala polismyndigheterna. Jag accepterar och förstår att mina uppgifter kommer att skickas till dessa myndigheter om min ansökan anses vara bedräglig och det kan bli en brottsutredning.",
    validationTermsAndConditions: "Allmänna villkor måste godkännas",
    messageFailbankName: "Banknamn kan inte vara tomt",
    messageFailcustomerName: "Kundens namn kan inte vara tomt",
    messageFailbankAccountNumber: "Obligatoriskt Fält",
    messageFailbankAccountNumberFormat: "Måste vara i IBAN format",
    messageFailsortCode: "Obligatoriskt Fält",
    messageFailbankSwiftBic: "Stòdmottagaren SWIFT BIC kan inte vara tom",
    messageFailbankSwiftBicFormat: "Mäste vara SWIFT-format",
    messageFailbeneficiaryAddress1: "Adress 1 kan inte vara tom",
    messageFailbeneficiaryCity: "Staden kan vara tom",
    messageFailbeneficiaryPostCode: "Postnummer kan inte vara tomt",
    messageFailremittanceLine3: "Obligatoriskt Fält",
    messageFailremittanceLine4: "Obligatoriskt Fält",
    modalHeading: "Bekräfta Bankuppgifter",
    modalLabelPleaseConfirmYourDetails: "Kontroller dina bankuppgifter och bekräfta.",
    modalValidationPleaseConfirm: "Var god bekräfta",
    modalValidationMustMatch: "Måste matcha föregående inlägg",
    tooltipBankAccountName: "Ditt namn som det visas på ditt bankkonto / kontoutdrag / bankkort.",
    tooltipBankAccountNameRecipient: "Mottagarens för- och efternamn. Både för- och efternamn måste ha mer än ett tecken.",
    tooltipBankCountry: "Det land där din bank är belägen.",
    tooltipBankName: "Namnet på din bank.",
    tooltipPaymentType: "Den typ av betalning du behöver.",
    tooltipInstitutionNumber: "Detta är tre siffror och finns vanligtvis på ditt månatliga kontoutdrag eller bankkort, eller om du fortfarande är osäker, fråga din bank.",
    tooltipBankAccountNumber: "Ditt individuella kontonummer. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipBankAccountNumberCBU: "Ett 22-siffrigt nummer som används för att identifiera ditt konto. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipBankAccountNumberCCI: "Ett 20-siffrigt nummer som används för att identifiera ditt konto. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipBankAccountNumberKRW: "Ett 11–16 siffrigt nummer som används för att identifiera ditt konto. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipCardNumber: "Ditt kortnummer. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipUnionpay: "Ditt UnionPay-kortnummer.",
    tooltipAlipay: "Ditt mobilnummer eller e-postadress som är kopplad till ditt Alipay-konto.",
    tooltipBankAccountType: "Detta är antingen kontroll eller besparingar.",
    tooltipSortCode: "Detta identifierar den filial på din bank där ditt konto finns. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipRoutingCode: "Detta identifierar den filial på din bank där ditt konto finns. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipBeneficiarySWIFTBIC: "Består av 8-11 tecken som används för att identifiera en bank i en internationell transaktion. Vanligtvis finns det på ditt månadsutdrag eller om du fortfarande är osäker, fråga din bank.",
    tooltipBSB: "En bankstatsfilial (BSB) nummer är en australisk sorteringskod som har 6 siffror. Vanligtvis finns det på ditt månadsutdrag eller om du fortfarande är osäker, fråga din bank.",
    tooltipRoutingCodeBSB: "Detta identifierar den filial på din bank där ditt konto finns. Vanligtvis finns på ditt månatliga kontoutdrag eller bankkort eller om du fortfarande är osäker, fråga din bank.",
    tooltipABAFedwireRoutingCode: "Ett 9-siffrigt nummer som används för att identifiera banker i Amerika. Vanligtvis finns det på ditt månadsutdrag eller om du fortfarande är osäker, fråga din bank.",
    tooltipIFSCCode: "Vanligtvis finns den här koden på ditt månadsutdrag eller i din lösenbok eller om du fortfarande är osäker, fråga din bank.",
    tooltipTermsAndConditions: "Genom att acceptera villkoren bekräftar du att du har läst och godkänner våra allmänna villkor.",
    tooltipIBAN: "Ditt internationella bankkontonummer, som du kan använda när du gör eller tar emot internationella betalningar. Vanligtvis finns det på ditt månadsutdrag eller om du fortfarande är osäker, fråga din bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Mottagarens kontaktnamn och telefonnummer.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Mottagarens födelsedatum och födelseort.",
    tooltipBankRoutingCodeNationalID: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBankTransitCodeSwift: "Detta är fem siffror och kan vanligtvis hittas på ditt månatliga kontoutdrag eller om du fortfarande är osäker, fråga din bank.",
    tooltipBankAddress: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBankAddress2: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBankCity: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBankState: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBranchName: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBankCode: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBIKCode: "9 siffror för att identifiera din bank.",
    tooltipBranchCode: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBranchCodeUniversal: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipCNAPSCode: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipRemittanceLine4: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipCUILNumber: "Detta är ett 11-siffrigt nummer som består av två siffror, bindestreck, nio siffror och en kontrollsumma med en siffra.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipCFPNumber: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipBranchNameorBranchCode7digits: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipCedulaJuridica910digitsTaxID: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipTaxIDRUT89digits: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipJob: "Din nuvarande yrke.",
    tooltipDateOfBirth: "Ditt födelsedatum.",
    tooltipNationality: "Din nationalitet.",
    tooltipDocumentType: "Den dokumenttyp som krävs.",
    tooltipDocumentNumber: "Detta är dokumentnumret som är kopplat till ditt val av dokumenttyp.",
    tooltipRutNumber: "Ditt RUT-nummer (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipCIIndividualTaxIDBranchLocation: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    tooltipTaxIDRUCorCI: "Kan vanligtvis hittas på ditt månatliga kontoutdrag eller fråga din bank om du fortfarande är osäker.",
    errorCPFCode: "För närvarande accepterar vi endast CPF som en PIX-nyckel."
};