import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {AlertHolder} from './AlertHolder';

export const ApplicationDeclinedAlert = () => {

    const {
        applicationDeclined
    } = useFormContext();

    const {t} = useTranslation();

    const ApplicationDeclinedMessage = () => {
        return (
            <p>{t('common:applicationDeclinedMessage')}</p>
        );
    }

    return (<>
            {applicationDeclined &&
                <AlertHolder
                    severity='error'
                    content={<ApplicationDeclinedMessage/>}
                />
            }
        </>
    );
}