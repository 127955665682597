export default {
    messageUnableToMakePaymentToCurrency: "दुर्भाग्य से हम चयनित मुद्रा के लिए बैंक विवरण प्राप्त करने में असमर्थ हैं। कृपया सुनिश्चित करें कि मुद्रा और गृह देश को चुना गया है",
    messageOnlyBankAccountsAccepted: "कृपया ध्यान दें: हम इस समय केवल बैंक खातों में भुगतान कर सकते हैं (क्रेडिट और डेबिट कार्ड स्वीकार नहीं किए जाते हैं)",
    labelAccountName: "खाता धारक का नाम",
    labelBankAccountName: "खाताधारक का पूरा नाम",
    labelBankCardHolderName: "कार्डधारक का नाम",
    validationBankAccountName: "खाता धारक का पूरा नाम आवश्यक है",
    labelBankCountry: "बैंक देश",
    labelBankName: "बैंक का नाम",
    labelPaymentType: "भुगतान के प्रकार",
    labelInstitutionNumber: "संस्थान संख्या",
    labelBankAddress: "बैंक पता",
    labelBankAddress2: "बैंक पता",
    labelBankCity: "बैंक सिटी",
    labelBankState: "बैंक राज्य",
    labelBankAccountNumber: "बैंक खाता संख्या",
    labelBankAccountNumberCBU: "खाता संख्या सीबीयू",
    labelBankAccountNumberCCI: "खाता संख्या सीसीआई",
    labelBankAccountNumberZAR: "बैंक खाता संख्या (7-11 अंक)",
    labelCardNumber: "कार्ड संख्या",
    labelUnionpay: "यूनियनपे कार्ड नंबर",
    labelAlipay: "Alipay आईडी (मोबाइल नंबर या ईमेल)",
    labelBankAccountType: "खाते का प्रकार",
    optionBankAccountTypeCURRENT: "चालू खाता",
    optionBankAccountTypeCHECKING: "खाते की जांच",
    optionBankAccountTypeSAVING: "बचत खाता",
    optionBankAccountTypeSAVINGS: "बचत खाता",
    optionBankAccountTypeCUENTA_VISTA: "मास्टर खाता",
    optionBankAccountType1: "खाते की जांच",
    optionBankAccountType2: "बचत खाता",
    optionBankAccountType3: "मास्टर खाता",
    optionBankAccountType4: "विस्टा खाता",
    optionBankAccountType5: "आरयूटी खाता",
    labelSortCode: "क्रमबद्ध कोड",
    labelRoutingCode: "मार्ग कोड",
    labelBankRoutingCodeNationalID: "बैंक रूटिंग कोड / राष्ट्रीय आईडी",
    labelBankTransitCodeSwift: "ट्रांजिट नंबर",
    labelCNAPSCode: "रूटिंग कोड / CNAPS कोड (चीन राष्ट्रीय उन्नत भुगतान प्रणाली कोड)",
    labelABAFedwireRoutingCode: "ACH रूटिंग नंबर",
    labelIFSCCode: "आईएफएससी कोड",
    labelBankCode: "बैंक कोड",
    labelBIKCode: "बीआईके कोड",
    labelBranchCode: "शाखा क्र्मांक",
    labelBranchCodeUniversal: "यूनिवर्सल 6 अंक शाखा कोड",
    labelRemittanceLine3: "लाभार्थी संपर्क नाम और टेलीफोन नंबर",
    labelBeneficiaryContactNameandTelephoneNumber: "लाभार्थी संपर्क नाम और टेलीफोन नंबर",
    labelBeneficiaryDateofBirthandPlaceofBirth: "लाभार्थी की जन्म तिथि और जन्म स्थान",
    labelRemittanceLine4: "व्यक्तिगत कर संख्या CUIL (9-12 अंक)",
    labelCUILNumber: "टैक्स आईडी: CUIL / COOKED",
    labelTaxIDRFC13DigitsORCURP18digits: "टैक्स आईडी RFC 13 अंक या CURP 18 अंक",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "व्यक्तिगत कर आईडी सीपीएफ व्यक्तिगत करदाता पंजीकरण (11-14 अंक), शाखा कोड",
    labelCFPNumber: "सीपीएफ संख्या (11-14 अंक)",
    labelBranchNameorBranchCode7digits: "शाखा का नाम या शाखा कोड (7 अंक)",
    labelCedulaJuridica910digitsTaxID: "कानूनी प्रमाणपत्र (9-10 अंक टैक्स आईडी)",
    labelTaxIDRUT89digits: "टैक्स आईडी आरयूटी (8-9 अंक)",
    labelJob: "नौकरी का नाम",
    labelDateOfBirth: "जन्म की तारीख",
    labelNationality: "राष्ट्रीयता",
    labelDocumentType: "दस्तावेज़ का प्रकार",
    labelDocumentNumber: "दस्तावेज़ संख्या",
    labelRutNumber: "आरयूटी संख्या (अद्वितीय कर भूमिका)",
    labelCedulaTaxIDIndividual811digits: "सेडुला (कर आईडी व्यक्तिगत 8-11 अंक)",
    labelCIIndividualTaxIDBranchLocation: "सीआई (व्यक्तिगत कर आईडी), शाखा स्थान",
    labelTaxIDRUCorCI: "टैक्स आईडी (आरयूसी या सीआई)",
    labelSortCodeHelp: "मदद?",
    labelBranchCodeHelp: "मदद?",
    labelIBAN: "IBAN",
    labelIBANHelp: "मदद?",
    labelCLABE: "क्लैबे",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "मदद?",
    labelRoutingCodeBSB: "रूटिंग कोड/बीएसबी",
    labelBSB: "बैंक राज्य शाखा (बीएसबी)",
    labelSKNCode: "एसकेएन कोड",
    labelAcceptanceFlights: "मैं/हम स्वीकार करते हैं कि यदि रिफंड आवेदन सफल होता है तो सभी उड़ानों और सहायक उत्पादों सहित बुकिंग रद्द कर दी जाएगी।",
    labelAcceptanceTickets: "मैं/हम स्वीकार करता हूं/करते हैं कि यदि रिफंड आवेदन सफल होता है तो सभी टिकटों और सहायक उत्पादों सहित बुकिंग रद्द कर दी जाएगी।",
    labelTermsAndConditions1: "मैं/हम सहमत हैं",
    labelTermsAndConditions2: "नियम और शर्तें",
    labelFraudWarning1: "मेरा मानना ​​है कि इस धनवापसी आवेदन पत्र में दिए गए तथ्य वास्तविक और सत्य हैं। मैं समझता हूं कि किसी भी झूठी सूचना या सबूत को एक आपराधिक कृत्य माना जा सकता है और पुलिस या अन्य कानून प्रवर्तन एजेंसियों को आगे बढ़ाने के लिए भेजा जा सकता है।",
    labelFraudWarning2: "जहां हमें सहायक दस्तावेजों के संबंध में धोखाधड़ी का संदेह है, मामले को तुरंत स्थानीय पुलिस अधिकारियों को भेजा जाएगा। मैं स्वीकार करता हूं और समझता हूं कि मेरा विवरण इन अधिकारियों को दिया जाएगा यदि मेरे आवेदन को धोखाधड़ी माना जाता है और यह एक आपराधिक जांच का गठन कर सकता है।",
    validationTermsAndConditions: "नियम और शर्तें सहमत होनी चाहिए",
    messageFailbankName: "बैंक का नाम खाली नहीं हो सकता",
    messageFailcustomerName: "ग्राहक का नाम खाली नहीं हो सकता",
    messageFailbankAccountNumber: "आवश्यक क्षेत्र",
    messageFailbankAccountNumberFormat: "IBAN प्रारूप होना चाहिए",
    messageFailsortCode: "आवश्यक क्षेत्र",
    messageFailbankSwiftBic: "लाभार्थी स्विफ्ट बीआईसी खाली नहीं हो सकता",
    messageFailbankSwiftBicFormat: "स्विफ्ट प्रारूप होना चाहिए",
    messageFailbeneficiaryAddress1: "पता 1 खाली नहीं हो सकता",
    messageFailbeneficiaryCity: "शहर खाली नहीं हो सकता",
    messageFailbeneficiaryPostCode: "पोस्टल कोड खाली नहीं हो सकता",
    messageFailremittanceLine3: "आवश्यक क्षेत्र",
    messageFailremittanceLine4: "आवश्यक क्षेत्र",
    modalHeading: "विवरण की पुष्टि करें",
    modalLabelPleaseConfirmYourDetails: "कृपया अपने विवरण की पुष्टि करें",
    modalValidationPleaseConfirm: "कृपया पुष्टि करें",
    modalValidationMustMatch: "पिछली प्रविष्टि से मेल खाना चाहिए",
    tooltipBankAccountName: "आपका नाम जैसा कि आपके बैंक खाते/बैंक स्टेटमेंट/बैंक कार्ड पर दिखाई देता है।",
    tooltipBankAccountNameRecipient: "प्राप्तकर्ता का पहला और अंतिम नाम। प्रथम और अंतिम नाम दोनों में एक से अधिक वर्ण होने चाहिए।",
    tooltipBankCountry: "जिस देश में आपका बैंक स्थित है।",
    tooltipBankName: "आपके बैंक का नाम।",
    tooltipPaymentType: "आपको जिस प्रकार के भुगतान की आवश्यकता है।",
    tooltipInstitutionNumber: "यह 3 अंक है और आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAccountNumber: "आपका व्यक्तिगत खाता संख्या। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAccountNumberCBU: "आपके खाते की पहचान करने के लिए उपयोग की जाने वाली 22 अंकों की संख्या। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAccountNumberCCI: "आपके खाते की पहचान करने के लिए उपयोग की जाने वाली 20 अंकों की संख्या। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAccountNumberKRW: "आपके खाते की पहचान करने के लिए उपयोग की जाने वाली 11-16 अंकों की संख्या। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipCardNumber: "आपका कार्ड नंबर। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipUnionpay: "आपका UnionPay कार्ड नंबर।",
    tooltipAlipay: "आपका मोबाइल नंबर या ईमेल पता आपके Alipay खाते से जुड़ा है।",
    tooltipBankAccountType: "यह या तो चेकिंग है या बचत।",
    tooltipSortCode: "यह आपके बैंक की उस शाखा की पहचान करता है जहां आपका खाता है। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipRoutingCode: "यह आपके बैंक की उस शाखा की पहचान करता है जहां आपका खाता है। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBeneficiarySWIFTBIC: "एक अंतरराष्ट्रीय लेनदेन में एक बैंक की पहचान करने के लिए उपयोग किए जाने वाले 8-11 वर्णों से मिलकर बनता है। आमतौर पर आपके मासिक विवरण में पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBSB: "एक बैंक स्टेट ब्रांच (BSB) नंबर एक ऑस्ट्रेलियाई सॉर्ट कोड है जिसमें 6 अंक होते हैं। आमतौर पर आपके मासिक विवरण में पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipRoutingCodeBSB: "यह आपके बैंक की उस शाखा की पहचान करता है जहां आपका खाता है। आमतौर पर आपके मासिक विवरण या बैंक कार्ड पर पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipABAFedwireRoutingCode: "अमेरिका में बैंकों की पहचान करने के लिए इस्तेमाल किया जाने वाला 9 अंकों का नंबर। आमतौर पर आपके मासिक विवरण में पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipIFSCCode: "आमतौर पर यह कोड आपके मासिक विवरण या आपकी पासबुक पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipTermsAndConditions: "नियम और शर्तों को स्वीकार करके आप पुष्टि करते हैं कि आपने हमारे नियम और शर्तों को पढ़ लिया है और उनसे सहमत हैं।",
    tooltipIBAN: "आपका अंतर्राष्ट्रीय बैंक खाता संख्या, जिसका उपयोग आप अंतर्राष्ट्रीय भुगतान करते या प्राप्त करते समय कर सकते हैं। आमतौर पर आपके मासिक विवरण में पाया जाता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBeneficiaryContactNameandTelephoneNumber: "लाभार्थी संपर्क नाम और टेलीफोन नंबर।",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "लाभार्थी की जन्म तिथि और जन्म स्थान।",
    tooltipBankRoutingCodeNationalID: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankTransitCodeSwift: "यह 5 अंकों का होता है और आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAddress: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankAddress2: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankCity: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankState: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBranchName: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBankCode: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBIKCode: "आपके बैंक की पहचान करने के लिए 9-अंक।",
    tooltipBranchCode: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBranchCodeUniversal: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipCNAPSCode: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipRemittanceLine4: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें ।",
    tooltipCUILNumber: "यह एक 11 अंकों की संख्या है, जिसमें दो अंक, हाइफ़न, नौ अंक और एक अंक का चेकसम होता है।",
    tooltipTaxIDRFC13DigitsORCURP18digits: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipCFPNumber: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipBranchNameorBranchCode7digits: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipCedulaJuridica910digitsTaxID: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipTaxIDRUT89digits: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipJob: "आपका वरर्तमान व्यवसाय।",
    tooltipDateOfBirth: "आपकी जन्म तिथी।",
    tooltipNationality: "आपकी राष्ट्रीयता।",
    tooltipDocumentType: "दस्तावेज़ प्रकार की आवश्यकता है।",
    tooltipDocumentNumber: "यह आपके दस्तावेज़ प्रकार चयन से संबद्ध दस्तावेज़ संख्या है।",
    tooltipRutNumber: "आपका RUT  नंबर (Rol Único Tributario)।",
    tooltipCedulaTaxIDIndividual811digits: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipCIIndividualTaxIDBranchLocation: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    tooltipTaxIDRUCorCI: "आमतौर पर आपके मासिक बैंक स्टेटमेंट पर पाया जा सकता है या यदि आप अभी भी अनिश्चित हैं, तो अपने बैंक से पूछें।",
    errorCPFCode: "फिलहाल हम केवल CPF को PIX कुंजी के रूप में स्वीकार करते हैं।"
};
