import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {useFormContext} from '../providers/FormProvider';
import {CurrencyCountryInvalidState} from '../../state/activity';
import {AlertHolder} from './AlertHolder';

export const CountryCurrencyInvalidAlert = () => {

    const {
        isOfflinePayment
    } = useFormContext();

    const {t} = useTranslation();
    const currencyCountryInvalid = useRecoilValue(CurrencyCountryInvalidState);

    const CountryCurrencyValidation = () => {
        return (
            <Box>{t('form:validationCountryCurrency')}</Box>
        );
    }

    const OfflinePaymentValidation = () => {
        return (
            <Box>{t('form:validationOfflinePayment')}</Box>
        );
    }

    return (
        <>
            {currencyCountryInvalid &&
                <AlertHolder
                    severity='warning'
                    content={<CountryCurrencyValidation/>}
                />
            }

            {isOfflinePayment &&
                <AlertHolder
                    severity='warning'
                    content={<OfflinePaymentValidation/>}
                />
            }
        </>
    );
}