import * as React from 'react';
import {
    Box,
    Checkbox
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps
} from 'formik';
import {IInputProps} from './IInputProps';
import {ErrorMessage} from '../ui/ErrorMessage';
import {IFormValues} from '../Interfaces/IFormValues';

interface IBooleanInputProps extends IInputProps {
    hyperlink?: string;
    hyperlinkText?: string;
}

export const BooleanInput = (props: IBooleanInputProps) => {

    const {
        controlId,
        hidden,
        hyperlink,
        hyperlinkText,
        label,
        onClick,
        tooltip,
    } = props;

    const handleChange = (fieldProps: FieldProps<IFormValues>) => () => {
        void fieldProps.form.setFieldValue(controlId, !fieldProps.field.value);
    }

    const handleOnClick = (event: any) => {
        onClick(event);
    }

    return (<>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues>) => (
                        <Grid container pb={2}>
                            <Grid size={{xs: 4, md: 4}}>
                                <Grid size={{xs: 6, md: 6}} justifyContent='flex-end' display='flex'>
                                    <ErrorMessage fieldProps={fieldProps}/>
                                    <Checkbox
                                        name={controlId}
                                        sx={{pt: 0}}
                                        checked={fieldProps.form.getFieldProps(controlId).value}
                                        onChange={handleChange(fieldProps)}
                                        onClick={handleOnClick}
                                    />
                                </Grid>
                            </Grid>
                            <Grid size={{xs: 8, md: 4}} justifyContent='flex-end' display='flex'
                                  alignItems='flex-start'>
                                <Box sx={{color: 'black', pl: '5px'}}>
                                    {hyperlink === null &&
                                        <Box component='span'>{label}</Box>
                                    }
                                    {hyperlink !== null &&
                                        <Box component='span'>
                                            {label} <a href={hyperlink} target='_blank'>{hyperlinkText}.</a>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Field>
            }
        </>
    );
}