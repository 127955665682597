export default {
    headingAdverseWeather: "Anda mengalami kondisi cuaca buruk yang parah yang membuatnya tidak aman untuk melakukan perjalanan ke acara tersebut dan ini telah diamanatkan oleh Badan Pemerintah (misalnya Otoritas Lokal, Polisi, dll). Dan Anda punya bukti untuk membuktikan ini?",
    headingAdverseWeatherDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    tooltipRefundReasonConfirm: "Ya atau tidak",
    labelBookingCancelledByOperator: "Tiket dibatalkan oleh Operator/Penyelenggara.",
    labelBookingRescheduledByOperator: "Tanggal diubah oleh Operator/Penyelenggara dan saya tidak dapat menghadiri tanggal baru.",
    labelBookedWrongDate: "Saya atau anggota pemesanan, membuat kesalahan saat membeli tiket dan memesan tanggal yang salah.",
    labelBookedWrongTickets: "Saya atau anggota pemesanan, membuat kesalahan saat membeli tiket dan memesan tiket yang salah.",
    labelBookedTooManyTickets: "Saya atau anggota pemesanan, membuat kesalahan saat membeli tiket dan memesan terlalu banyak tiket.",
    labelCOVID19TravelRestrictions: "Saya atau anggota pemesanan, tidak dapat hadir karena larangan perjalanan COVID-19.",
    labelADWrongSelection: "Anda mungkin telah memilih alasan pengembalian dana yang salah. Harap tinjau kembali daftar drop-down Alasan Pengembalian Dana.",
    labelAdverseWeather1: "Mengapa cuaca buruk berarti Anda tidak dapat menghadiri acara tersebut?",
    tooltipAdverseWeather1: "Mohon diintilnya",
    labelAdverseWeather2: "Tanggal kejadian",
    tooltipAdverseWeather2: "Silakan pilih",
    headingArmedForces: "Anda diminta oleh Majikan Anda yang merupakan Angkatan Bersenjata atau Layanan Darurat untuk masuk kerja ketika Anda awalnya tidak dijadwalkan untuk melakukannya dan ini mencegah Anda menghadiri acara tersebut?",
    headingArmedForcesDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelArmedForces1: "Kekuatan atau Layanan Darurat apa yang Anda gunakan?",
    tooltipArmedForces1: "Silakan pilih",
    labelArmedForcesOther1: "Silakan tentukan",
    tooltipArmedForcesOther1: "Silakan tentukan",
    labelArmedForces2: "Tanggal kejadian",
    tooltipArmedForces2: "Silakan pilih",
    headingChangesToExam: "Tanggal ujian Anda telah diubah oleh sekolah / perguruan tinggi Anda, dll? Ini bukan ujian gagal yang harus Anda resit?",
    headingChangesToExamDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelExam1: "Berapa tanggal ujian aslinya?",
    tooltipExam1: "Silakan pilih",
    labelExam2: "Apa tanggal ujian baru?",
    tooltipExam2: "Silakan pilih",
    labelExam3: "Mengapa tanggal pemeriksaan berubah?",
    tooltipExam3: "Silakan masukkan detailnya",
    labelExam4: "Apakah Anda gagal dalam ujian sebelumnya?",
    tooltipExam4: "Ya atau tidak",
    headingCourtSummons: "Anda telah dipanggil untuk tampil di pengadilan sebagai saksi pada tanggal yang sama dengan acara tersebut?",
    headingCourtSummonsDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelCourtSummons1: "Apa alasan panggilan pengadilan Anda?",
    tooltipCourtSummons1: "Silakan pilih",
    labelCourtSummonsOther1: "Silakan tentukan",
    tooltipCourtSummonsOther1: "Silakan tentukan",
    labelCourtSummons2: "Kapan panggilan pengadilan dikeluarkan?",
    tooltipCourtSummons2: "Silakan pilih",
    headingCOVID19Test: "Saya, anggota keluarga saya atau anggota kelompok yang menghadiri acara tersebut telah didiagnosis dengan COVID-19?",
    headingCOVID19TestDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelCOVID19Test1: "Siapa yang menderita COVID-19?",
    tooltipCOVID19Test1: "Silakan pilih",
    labelCOVID19TestOther1a: "Silakan tentukan",
    labelCOVID19TestOther1b: "Tentukan nama",
    tooltipCOVID19TestOther1a: "Silakan tentukan",
    tooltipCOVID19TestOther1b: "Tentukan nama",
    labelCOVID19Test2: "Jenis tes COVID-19 apa yang Anda miliki?",
    tooltipCOVID19Test2: "Silakan pilih",
    labelCOVID19TestOther2: "Silakan tentukan",
    tooltipCOVID19TestOther2: "Silakan tentukan",
    labelCOVID19Test3: "Berapa tanggal tes COVID-19 Anda?",
    tooltipCOVID19Test3: "Silakan pilih",
    headingDeath: "Telah terjadi kematian seorang anggota langsung keluarga saya dan itu mencegah saya menghadiri acara tersebut?",
    headingDeathDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelDeath1: "Anggota keluarga Anda yang mana yang telah meninggal?",
    tooltipDeath1: "Silakan pilih",
    labelDeathOther1: "Silakan tentukan",
    tooltipDeathOther1: "Silakan tentukan",
    labelDeath2: "Kapan tanggal kematiannya?",
    tooltipDeath2: "Silakan pilih",
    labelDeath3: "Nama almarhum",
    tooltipDeath3: "Silakan tentukan",
    headingEmergency: "Saya telah mengalami keadaan darurat yang nyata yang berada di luar kendali saya dan tidak terduga yang dapat saya berikan buktinya?",
    headingEmergencyDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelEmergency1: "Tolong jelaskan jenis keadaan daruratnya?",
    tooltipEmergency1: "Mohon diintilnya",
    labelEmergency2: "Kapan ini terjadi?",
    tooltipEmergency2: "Silakan pilih",
    labelEmergency3: "Mengapa situasi ini menghentikan Anda menghadiri acara tersebut?",
    tooltipEmergency3: "Mohon diintilnya",
    labelEmergency4: "Apakah Anda memiliki bukti keadaan darurat ini?",
    tooltipEmergency4: "Ya atau tidak",
    headingHomeEmergency: "Saya memiliki keadaan darurat rumah tangga yaitu Perampokan, Banjir, Kebakaran, Vandalisme, Kekerasan Dalam Rumah Tangga yang mencegah saya menghadiri acara tersebut?",
    headingHomeEmergencyDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelHomeEmergency1: "Apa itu darurat rumah?",
    tooltipHomeEmergency1: "Silakan pilih",
    labelHomeEmergencyOther1: "Silakan tentukan",
    tooltipHomeEmergencyOther1: "Silakan tentukan",
    labelHomeEmergency2: "Kapan ini terjadi?",
    tooltipHomeEmergency2: "Silakan pilih",
    labelHomeEmergency3: "Mengapa itu menghentikan Anda menghadiri acara tersebut?",
    tooltipHomeEmergency3: "Mohon diintilnya",
    headingInjury: "Saya, seorang anggota keluarga saya atau anggota kelompok yang menghadiri acara tersebut telah didiagnosis dengan kondisi medis atau cedera yang sekarang mencegah kita pergi ke acara tersebut?",
    headingInjuryDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelInjury1: "Apa sifat dari Penyakit / Cedera / Kecelakaan?",
    tooltipInjury1: "Mohon diintilnya",
    labelInjury2: "Kapan ini terjadi?",
    tooltipInjury2: "Silakan pilih",
    labelInjury3: "Pernahkah Anda mengunjungi fasilitas medis untuk penyakit/cedera ini?",
    tooltipInjury3: "Ya atau tidak",
    labelInjury4: "Apakah ini kondisi medis yang sudah ada sebelumnya?",
    tooltipInjury4: "Ya atau tidak",
    labelInjury5: "Kapan ini pertama kali didiagnosis?",
    tooltipInjury5: "Silakan pilih",
    labelInjury6: "Mengapa ini berarti Anda tidak dapat menghadiri acara tersebut?",
    tooltipInjury6: "Mohon diintilnya",
    labelInjury7: "Siapa yang menderita penyakit/cedera tersebut?",
    tooltipInjury7: "Silakan pilih",
    headingJuryService: "Saya atau anggota pemesanan telah dipanggil ke Layanan Juri?",
    headingJuryServiceDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelJuryService1: "Kapan Anda diberitahu tentang Layanan Juri?",
    tooltipJuryService1: "Silakan pilih",
    headingMechanicalBreakdown: "Kendaraan kami mengalami kerusakan mekanis atau mengalami kecelakaan dan ini mencegah kami menghadiri acara tersebut?",
    headingMechanicalBreakdownDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelMechanicalBreakdown1: "Apa yang terjadi dengan kendaraan itu?",
    tooltipMechanicalBreakdown1: "Silakan pilih",
    labelMechanicalBreakdown2: "Kapan ini terjadi?",
    tooltipMechanicalBreakdown2: "Silakan pilih",
    labelMechanicalBreakdown3: "Mengapa Anda tidak dapat menggunakan transportasi alternatif?",
    tooltipMechanicalBreakdown3: "Mohon diintilnya",
    headingPregnancyComplication: "Saya atau anggota pemesanan memiliki komplikasi kehamilan yang telah didiagnosis oleh profesional medis dan oleh karena itu mencegah kehadiran di acara tersebut?",
    headingPregnancyComplicationDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelPregnancyComplication1: "Apa sifat komplikasi kehamilan?",
    tooltipPregnancyComplication1: "Mohon diintilnya",
    labelPregnancyComplication2: "Kapan ini terjadi?",
    tooltipPregnancyComplication2: "Silakan pilih",
    labelPregnancyComplication3: "Apakah Anda mencari perhatian medis?",
    tooltipPregnancyComplication3: "Ya atau tidak",
    labelPregnancyComplication4: "Mengapa ini berarti Anda tidak dapat menghadiri acara tersebut?",
    tooltipPregnancyComplication4: "Mohon diintilnya",
    headingPublicTransportFailure: "Metode Transportasi Umum (Kereta Api, Bus, Trem, Perahu, dll) tiba-tiba gagal artinya tidak ada cara lain saya bisa melakukan perjalanan ke acara ini?",
    headingPublicTransportFailureDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelPublicTransportFailure1: "Jenis Transportasi Umum apa yang Anda rencanakan untuk digunakan?",
    tooltipPublicTransportFailure1: "Silakan pilih",
    labelPublicTransportFailure2: "Kapan Anda berencana untuk bepergian?",
    tooltipPublicTransportFailure2: "Silakan pilih",
    labelPublicTransportFailure3: "Apa yang terjadi?",
    tooltipPublicTransportFailure3: "Mohon diintilnya",
    labelPublicTransportFailure4: "Mengapa Anda tidak bisa mengambil transportasi alternatif?",
    tooltipPublicTransportFailure4: "Mohon diintilnya",
    headingScheduledAirlineFailure: "Penerbangan pra-pemesanan saya dibatalkan secara tak terduga dan saya tidak dapat menemukan alternatif yang sesuai yang berarti saya tidak dapat menghadiri acara tersebut?",
    headingScheduledAirlineFailureDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelScheduledAirlineFailure1: "Nama Maskapai Penerbangan",
    tooltipScheduledAirlineFailure1: "Nama Maskapai Penerbangan",
    labelScheduledAirlineFailure2: "Nomor penerbangan",
    tooltipScheduledAirlineFailure2: "Nomor penerbangan",
    labelScheduledAirlineFailure3: "Bepergian dari",
    tooltipScheduledAirlineFailure3: "Bepergian dari",
    labelScheduledAirlineFailure4: "Bepergian ke",
    tooltipScheduledAirlineFailure4: "Bepergian ke",
    labelScheduledAirlineFailure5: "Tanggal kejadian?",
    tooltipScheduledAirlineFailure5: "Silakan pilih",
    labelScheduledAirlineFailure6: "Mengapa Anda tidak dapat mengambil penerbangan alternatif?",
    tooltipScheduledAirlineFailure6: "Mohon diintilnya",
    headingTheftOfDocuments: "Dokumen saya dicuri dan tidak mungkin untuk mengatur penggantian dalam jangka waktu yang diperlukan sehingga saya tidak dapat menghadiri acara tersebut",
    headingTheftOfDocumentsDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelTheftOfDocuments1: "Jenis Dokumen apa yang dicuri?",
    tooltipTheftOfDocuments1: "Silakan pilih",
    labelTheftOfDocuments2: "Kapan ini terjadi?",
    tooltipTheftOfDocuments2: "Silakan pilih",
    labelTheftOfDocuments3: "Apakah Anda mencoba agar dokumen-dokumen itu diterbitkan ulang?",
    tooltipTheftOfDocuments3: "Ya atau tidak",
    headingWorkRelocation: "Majikan saya telah meminta saya untuk mengubah lokasi pekerjaan saya setidaknya selama tiga bulan dan jaraknya signifikan yang berarti saya sekarang tidak dapat menghadiri acara tersebut?",
    headingWorkRelocationDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelWorkRelocation1: "Apakah ini relokasi sementara atau permanen dari pekerjaan Anda?",
    tooltipWorkRelocation1: "Silakan pilih",
    labelWorkRelocation2: "Berapa lama Anda akan berada di alamat baru?",
    tooltipWorkRelocation2: "Mohon diintilnya",
    labelWorkRelocation3: "Apa alamat kerja Anda saat ini?",
    tooltipWorkRelocation3: "Mohon diintilnya",
    labelWorkRelocation4: "Apa alamat kerja baru Anda?",
    tooltipWorkRelocation4: "Mohon diintilnya",
    labelWorkRelocation5: "Kapan Anda diberitahu tentang relokasi ini?",
    tooltipWorkRelocation5: "Silakan pilih",
    headingRedundancy: "Saya atau anggota pemesanan telah dibuat berlebihan oleh majikan mereka yang berarti saya tidak dapat lagi menghadiri pemesanan?",
    headingRedundancyDecline: "Anda tidak boleh melanjutkan dengan aplikasi ini karena pengembalian dana kemungkinan besar akan ditolak.",
    labelRedundancy1: "Jenis pekerjaan?",
    tooltipRedundancy1: "Silakan pilih",
    labelRedundancy2: "Sudah berapa lama Anda dipekerjakan oleh perusahaan ini?",
    tooltipRedundancy2: "Silakan pilih",
    labelRedundancy3: "Apakah redundansi itu sukarela?",
    tooltipRedundancy3: "Silakan pilih",
    labelRedundancy4: "Kapan Anda diberitahu tentang redundansi Anda?",
    tooltipRedundancy4: "Silakan pilih",
    labelTestOption: "Pilih Ya untuk melanjutkan",
}