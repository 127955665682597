export default {
    labelBookingCancelledPostponedHeading: "Сіздің брондауыңыз жойылды ма немесе кейінге қалдырылды ма? Толығырақ оқыңыз",
    labelBookingCancelledPostponedText1: "басыңыз",
    labelBookingCancelledPostponedText2: "қосымша ақпарат алу үшін.",
    labelNewRefundHeading: "Жаңа ақшаны қайтару өтініші?",
    labelNewRefundText: "Брондау анықтамаңызды осында енгізіңіз...",
    placeholderBookingReference: "Брондау анықтамасы",
    validationBookingReference: "Брондау туралы анықтама қажет",
    validationEmailAsBookingReference: "Электрондық пошта мекенжайы брондау анықтамасы ретінде қабылданбайды",
    buttonFindBooking: "Брондау табыңыз",
    labelEmailHeading: "Қазірдің өзінде басталды?",
    labelEmailText: "Қолданбаңызды шығарып алу үшін электрондық пошта еске салғышын жіберіңіз.",
    placeholderEmail: "Электрондық пошта",
    buttonEmailReminder: "Электрондық пошта еске салғышы",
    labelCustomerName : "Тұтынушы аты",
    validationCustomerName: "Тұтынушының аты қажет",
    labelTypeOfBooking: "Брондау түрі",
    validationTypeOfBooking: "Брондау түрі қажет",
    labelEventName:"Оқиға атауы",
    labelVenueName:"Орын атауы",
    labelFlightNumber:"Ұшу нөмірі",
    labelOriginAirport: "Бастапқы әуежай",
    labelHotelName: "Орналастыру атауы",
    labelHotelLocation: "Орналастыру орны",
    labelCurrency: "Валюта",
    validationCurrency: "Валюта қажет",
    validationCountryCurrency: "Таңдалған валюта сіздің еліңіздің валютасы болуы керек",
    validationOfflinePayment: "Валюта мен ел таңдауыңыз үшін қайтаруды өңдей алмаймыз, балама нұсқаларды таңдаңыз",
    labelBookingValue: "Брондау құны",
    validationBookingValue: "Брондау құны қажет",
    validationBookingValueAmount: "0-ден үлкен болуы керек",
    labelDateOfPurchase: "Сатып алу күні",
    validationDateOfPurchase: "Сатып алу күні қажет",
    labelEmailAddress: "Электрондық пошта",
    validationEmailAddress: "Электрондық пошта мекенжайы қажет",
    validationEmailAddressFormat: "Қате электрондық пошта пішімі",
    labelConfirmEmailAddress: "Электронды Поштаның Адресін Растау",
    validationConfirmEmailAddressFormat: "Қате электрондық пошта пішімі",
    validationConfirmEmailAddress: "Растау электрондық пошта мекенжайы қажет",
    validationConfirmEmailAddressMatch: "Электрондық пошта мекенжайлары сәйкес келмейді",
    validationEmailDomain: "Бұл электрондық пошта провайдері қабылданбайды, балама нұсқасын пайдаланыңыз",
    validationDateOfEvent: "Оқиға күні қажет",
    labelHomeCountry: "Отан",
    validationHomeCountry: "Туған елі қажет",
    labelContactNumberCountryCode: "Байланыс нөмірі Ел коды",
    validationContactNumberCountryCode: "Елді теру коды қажет",
    labelContactNumber: "Байланыс нөмірі",
    validationContactNumber: "Байланыс нөмірі қажет",
    labelAddressLine1: "Адрестік Жол 1",
    labelAddressLine2: "Мекенжай жолы 2",
    labelCity: "Қала",
    labelZipCode: "Пошта индексі",
    labelRegion: "Аймақ",
    validationRegion: "Аймақ қажет",
    labelNumberOfTicketsToRefund: "Қайтарылатын билеттер саны",
    validationNumberOfTicketsToRefund: "Төлемді қайтару үшін билеттер саны қажет",
    validationNumberOfTicketsToRefundValue: "0-ден үлкен болуы керек",
    labelCostPerTicket: "Әрбір Билеттің құны",
    validationCostPerTicket: "Бір билеттің құны талап етіледі",
    validationCostPerTicketValue: "0-ден үлкен болуы керек",
    labelTotalRefundAmount: "Қайтарудың жалпы сомасы",
    validationTotalRefundAmount: "Қайтарудың жалпы сомасы қажет",
    validationTotalRefundAmountValue: "0-ден үлкен болуы керек",
    validationTotalRefundGreaterThanBookingValue: "Қайтарудың жалпы сомасы жоғарыдағы Брондау құнынан артық болмауы керек",
    labelBookingConfirmationFiles: "Брондау растауын жүктеп салу",
    validationBookingConfirmationFiles: "Кем дегенде бір файлды тіркеңіз немесе «Сақтау» түймесін басып, құжаттарыңыз болған кезде кейінірек оралыңыз",
    validationEvidenceFilesPopup: "Жібермес бұрын дәлелдемелерді жүктеп салу қажет. Дәлелдер дәл қазір болмаса, өтінімді сақтап, қолыңызда болғаннан кейін қайтарыңыз. Біз электронды көшірмелерді, сканер көшірмелерін және фотосуреттерді қабылдаймыз.",
    validationBookingConfirmationFilesPopup: "Брондау растауын жіберу алдында жүктеп салу қажет. Егер сізде дәл қазір брондау растамасы болмаса, қолданбаны сақтап, бар болғаннан кейін қайтарыңыз. Біз электронды көшірмелерді, сканер көшірмелерін және фотосуреттерді қабылдаймыз.",
    alertUploadDocuments: "Электрондық поштада егжей-тегжейлі көрсетілген тиісті құжаттаманы жүктеп салыңыз, содан кейін 3-қадамға өтіңіз.",
    labelRefundReasonCategory: "Қайтару себебі",
    validationRefundReasonCategory: "Қайтару себебі қажет",
    labelDateOfIncident: "Оқиға күні",
    labelAdditionalComments: "Қосымша түсініктемелер",
    tooltipAdditionalComments: "Қосымша түсініктемелер",
    validationAdditionalComments: "Қосымша түсініктемелер қажет",
    labelUnableToNotifyWithinTimeLimit: "60 күн ішінде хабарлау мүмкін емес",
    labelReasonForNotificationOutsideTimeLimit: "Уақыт шегінен тыс хабарлама туралы түсініктеме",
    validationReasonForNotificationOutsideTimeLimit: "60 күннен кейін хабарландыру себебін көрсету қажет",
    labelRefundReasonFiles: "Дәлелдерді жүктеп салу",
    validationRefundReasonFiles: "Кем дегенде бір файлды тіркеңіз немесе «Сақтау» түймесін басып, құжаттарыңыз болған кезде кейінірек оралыңыз",
    validationTextScript: "Латын графикасына сәйкес келуі керек",
    optionTypeOfBooking1: "Оқиға",
    optionTypeOfBooking2: "Тур",
    optionTypeOfBooking3: "Ұшу",
    optionTypeOfBooking4: "Орналасу",
    optionTypeOfBooking5: "Тасымалдау",
    optionTypeOfBooking6: "Көлік тұрағы",
    optionTypeOfBooking7: "Басқа саяхат",
    labelEventTypeDate: "Оқиға күні",
    labelEventTypeTour: "Тур күні",
    labelEventTypeFlight: "Ұшу күні",
    labelEventTypeCheckIn: "Тіркелу күні",
    labelEventTypeTransfer: "Аудару күні",
    labelEventTypeParking: "Тұрақ күні",
    labelEventTypeOtherTravel: "Саяхат күні",
    optionRefundReason11: "Қолайсыз ауа райы",
    optionRefundReason10: "Қарулы күштер мен төтенше жағдайлар қызметтерін шақыру",
    optionRefundReason15: "Емтихан күндеріндегі өзгерістер",
    optionRefundReason18: "Сотқа шақыру",
    optionRefundReason17: "COVID-19 себебінен сапар шегу шектеулері",
    optionRefundReason19: "COVID-19 оң сынағы",
    optionRefundReason20: "Оператор/Ұйымдастырушы брондаудан бас тартты",
    optionRefundReason21: "Брондау Оператор/Ұйымдастырушы арқылы қайта жоспарланған",
    optionRefundReason22: "Брондау күні қате",
    optionRefundReason23: "Қате билеттерді брондау",
    optionRefundReason24: "Тым көп билеттер брондалған",
    optionRefundReason4: "Өлім",
    optionRefundReason16: "Төтенше жағдайлар",
    optionRefundReason14: "Үкіметтің саяхатқа тыйым салуы",
    optionRefundReason9: "Үйдегі төтенше жағдай",
    optionRefundReason1: "Ауру/жарақат/апат",
    optionRefundReason8: "Қазылар алқасы қызметі",
    optionRefundReason7: "Механикалық бұзылу",
    optionRefundReason2: "Бұрыннан бар медициналық жағдай",
    optionRefundReason3: "Жүктіліктің асқынуы",
    optionRefundReason5: "Қоғамдық көліктің бұзылуы",
    optionRefundReason6: "Ұшуды бұзу",
    optionRefundReason13: "Билеттерді ұрлау",
    optionRefundReason12: "Жұмыс орнын ауыстыру",
    optionRefundReason25: "Жұмыс орнын қысқарту",
    optionRefundReason26: "Сынақ опциясы",
    optionRefundReasonConfirm1: "Жоқ",
    optionRefundReasonConfirm2: "Иә",
    optionRefundReasonArmedForces1: "Армия",
    optionRefundReasonArmedForces2: "Әскери-теңіз флоты",
    optionRefundReasonArmedForces3: "Әскери-әуе күштері",
    optionRefundReasonArmedForces4: "Арнайы мақсаттағы бөлімшелер",
    optionRefundReasonArmedForces5: "Теңіз жаяу әскерлері",
    optionRefundReasonArmedForces6: "От",
    optionRefundReasonArmedForces7: "Жедел жәрдем көлігі",
    optionRefundReasonArmedForces8: "Дәрігер/медбике",
    optionRefundReasonArmedForces9: "Стоматолог",
    optionRefundReasonArmedForces10: "Басқалары",
    optionRefundReasonCourtSummons1: "Қылмыстық қудалау органдары",
    optionRefundReasonCourtSummons2: "Азаматтық сот ісі",
    optionRefundReasonCourtSummons3: "Қылмыстық қудалауды iзге түсушiнiң куәгер",
    optionRefundReasonCourtSummons4: "Азаматтық іс бойынша куә",
    optionRefundReasonCourtSummons5: "Талапкер",
    optionRefundReasonCourtSummons6: "Жауапкер",
    optionRefundReasonCourtSummons7: "Басқалары",
    optionRefundReasonDeath1: "Күйеуі",
    optionRefundReasonDeath2: "Әйелі",
    optionRefundReasonDeath3: "Дыбыс",
    optionRefundReasonDeath4: "Қызы",
    optionRefundReasonDeath5: "Әкесі",
    optionRefundReasonDeath6: "Ана",
    optionRefundReasonDeath7: "Бауыры",
    optionRefundReasonDeath8: "Қарындас",
    optionRefundReasonDeath9: "Атасы",
    optionRefundReasonDeath10: "Әжесі",
    optionRefundReasonDeath11: "Басқалары",
    optionRefundReasonHomeEmergency1: "От",
    optionRefundReasonHomeEmergency2: "Су тасқыны",
    optionRefundReasonHomeEmergency3: "Тонау",
    optionRefundReasonHomeEmergency4: "Вандализм",
    optionRefundReasonHomeEmergency5: "Тұрмыстық зорлық-зомбылық",
    optionRefundReasonHomeEmergency6: "Киднап",
    optionRefundReasonHomeEmergency7: "Басқалары",
    optionRefundReasonCOVID19Person1: "Өзім",
    optionRefundReasonCOVID19Person2: "Брондаудағы басқа адам",
    optionRefundReasonCOVID19Person3: "Менің үй шаруашылығымдағы адам",
    optionRefundReasonCOVID19Person4: "Басқалары",
    optionRefundReasonCOVID19TestType1: "ПТР тесті",
    optionRefundReasonCOVID19TestType2: "RAT тесті",
    optionRefundReasonCOVID19TestType3: "Бүйірлік ағынды тексеру",
    optionRefundReasonCOVID19TestType4: "Өздігінен басқарылатын тест",
    optionRefundReasonCOVID19TestType5: "Тест жоқ, бірақ менде симптомдар бар",
    optionRefundReasonCOVID19TestType6: "Басқалары",
    optionRefundReasonBreakdown1: "Сыну",
    optionRefundReasonBreakdown2: "Жазатайым оқиға",
    optionRefundReasonBreakdown3: "От",
    optionRefundReasonBreakdown4: "Ұрлық",
    optionRefundReasonPublicTransportType1: "Пойыз",
    optionRefundReasonPublicTransportType2: "Автобус",
    optionRefundReasonPublicTransportType3: "Трамвай",
    optionRefundReasonPublicTransportType4: "Қайық",
    optionRefundReasonDocumentType1: "Төлқұжат",
    optionRefundReasonDocumentType2: "Жол жүруге рұқсат",
    optionRefundReasonDocumentType3: "Ұлттық идентифика- ция",
    optionRefundReasonDocumentType4: "Билеттер",
    optionRefundReasonWorkRelocationType1: "Уақытша",
    optionRefundReasonWorkRelocationType2: "Тұрақты",
    optionRefundReasonWorkRelocationTemporary1: "0-3 ай",
    optionRefundReasonWorkRelocationTemporary2: "3-6 ай",
    optionRefundReasonWorkRelocationTemporary3: "6 айдан астам",
    optionRefundReasonBookingRelationshipType1: "Брондаудағы біреу",
    optionRefundReasonBookingRelationshipType2: "Брондаудағы біреудің тікелей туысы",
    optionEmploymentType1: "Толық уақыт",
    optionEmploymentType2: "Бөлім уақыты",
    optionEmploymentType3: "Келiсiм-шарт",
    optionEmploymentLengthType1: "0-1 жыл",
    optionEmploymentLengthType2: "1-2 жыл",
    optionEmploymentLengthType3: "2 жылдан астам",
    textRefundEvidenceHeading: "Келесі дәлелдерді келтіріңіз:",
    textRefundEvidence1: "Авиакомпанияның растауы",
    textRefundEvidence2: "Кез келген тиісті растаушы дәлелдемелер",
    textRefundEvidence3: "Сыну қызметі растауды шақырады",
    textRefundEvidence4: "Қайтыс болғаны туралы куәлік",
    textRefundEvidence5: "Авиакомпаниядан растаудың күшін жою",
    textRefundEvidence6: "Ауа райы туралы есеп",
    textRefundEvidence7: "Билеттерді қайта беру мүмкіндігін растайтын брондау агентінен хат алмасу",
    textRefundEvidence8: "Соттан хат",
    textRefundEvidence9: "Медициналық анықтама/ Дәрігердің жазбасы",
    textRefundEvidenceDescription9: "Аурудың немесе жарақаттың егжей-тегжейін, оның алғаш пайда болған күнін растай отырып, ол Сізге Брондауға қатысуға кедергі келтіреді.",
    textRefundEvidence10: "Жұмыс берушінің хатын растау",
    textRefundEvidence11: "Сақтандыру талабы бойынша хат-хабарлар",
    textRefundEvidence12: "Жұмыс берушінің хаты",
    textRefundEvidence13: "Ұлттық үкіметтің веб-сайтын растау",
    textRefundEvidence14: "Сараптаманы өзгерту туралы хабарлама",
    textRefundEvidence15: "Полицияның есебі",
    textRefundEvidence16: "Полиция ведомствасы және анықтамалық нөмірі",
    textRefundEvidence17: "Көлік провайдерінің растауы",
    textRefundEvidence18: "Жол жүру туралы ескерту",
    textRefundEvidence20: "2016 жылдың 1-ші жартыжылдығында 2016 жылдың 1-ші жартыжылдығында 2",
    textRefundEvidence24: "Оқшаулау туралы мемлекеттік хабарлама",
    textRefundEvidence25: "Іссапарға жіберілген офицердің хаты",
    textRefundEvidence26: "Сақтанушылардың хаты",
    textRefundEvidence27: "Адресті растау",
    textRefundEvidence28: "Қайтыс болған адамның қарым-қатынасының дәлелі",
    textRefundEvidence29: "Жарақаттанған/ауырған адаммен қарым-қатынасты растау",
    textRefundEvidence30: "Төлем картасы",
    tooltipBookingReference: "Брондау анықтамаңызды анықтау үшін брондауды растауды тексеріңіз және кеңейтілген қызметіміз туралы бөлімді қараңыз.",
    tooltipCustomerName: "Брондау үшін пайдаланылған аты-жөні.",
    tooltipTypeOfBooking: "Сізге қайтарылатын брондау түрі.",
    tooltipEventName: "Оқиғаның аты.",
    tooltipVenueName: "Өткізілетін орынның атауы.",
    tooltipFlightNumber: "Рейс нөмірі.",
    tooltipOriginAirport: "Ұшу әуежайы.",
    tooltipHotelName: "Қонақ үйдің атауы.",
    tooltipHotelLocation: "Тұрғын үйдің орналасқан жері.",
    tooltipCurrency: "Валюта брондау үшін төленген.",
    tooltipBookingValue: "Брондау үшін төлеген жалпы баға.",
    tooltipDateOfPurchase: "Брондау жасалған күн.",
    tooltipEmailAddress: "Жарамды электрондық пошта мекенжайын пайдаланыңыз. Біз бұл электрондық поштаны қайтару өтінімі кезінде хат алмасу үшін пайдаланамыз.",
    tooltipConfirmEmailAddress: "Жоғарыда көрсетілген электрондық пошта мекенжайын пайдаланыңыз, бұл тексеру үшін.",
    tooltipHomeCountry: "Бұл соңғы қадамда дұрыс банк ақпаратын алуға көмектеседі.",
    tooltipContactNumberCountryCode: "Байланыс нөмірі ел коды.",
    tooltipContactNumber: "Жарамды нөмірді пайдаланыңыз. Біз мұны ақшаны қайтару өтініші кезінде хат алмасу үшін пайдаланамыз.",
    tooltipAddressLine1: "Сіздің мекенжайыңыз банктік шотыңызды растау кезінде пайдаланылатынын ескеріңіз.",
    tooltipAddressLine2: "Мекенжайдың екінші жолы.",
    tooltipCity: "Сіздің туған қалаңыз, мәліметтерді банктік мәліметтерді тексеру үшін пайдалануға болады.",
    tooltipZipCode: "Пошта индексі / Пошта индексі.",
    tooltipRegion: "Сіздің туған қалаңыздың аймағы, мәліметтерді банктік мәліметтерді тексеру үшін пайдалануға болады.",
    tooltipNumberOfTicketsToRefund: "Сіз қайтарғыңыз келетін билеттер саны.",
    tooltipTotalRefundAmount: "Бұл жалпы брондау құнынан аспауы керек, егер солай болса, өтінімді жібере алмайсыз.",
    tooltipBookingConfirmationFiles: "Толық брондау растамасының скриншоты/қосымшасы, оны әдетте электрондық поштаңыздан табуға болады.",
    tooltipRefundReasonCategory: "Қайтару себебін таңдаңыз.",
    tooltipDateOfIncident: "Брондауыңызға әсер еткен оқиға болған күн.",
    tooltipRefundExplanation: "Қайтару туралы шағымды қолдайтын мүмкіндігінше толық мәлімет беріңіз.",
    tooltipUnableToNotifyWithinTimeLimit: "60 күндік уақыт шегінде хабарлау мүмкін емес.",
    tooltipReasonForNotificationOutsideTimeLimit: "Уақыт шегінен тыс хабарлама туралы түсініктеме.",
    tooltipRefundReasonFiles: "Қажетті құжаттардың кез келгенін жүктеп салыңыз.",
    tooltipEventTypeDate: "Нақты оқиға / брондау күні.",
    tooltipEventTypeTour: "Нақты турдың күні.",
    tooltipEventTypeFlight: "Нақты рейс күні.",
    tooltipEventTypeCheckIn: "Нақты тіркеу күні.",
    tooltipEventTypeTransfer: "Нақты аудару күні.",
    tooltipEventTypeParking: "Нақты тұрақ күні.",
    tooltipEventTypeOtherTravel: "Нақты сапардың басталу күні."
};