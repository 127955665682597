import * as React from 'react';
import {
    useEffect,
    useState
} from 'react';
import {
    languageOptions,
    getDetectedLanguage
} from './locales/languageSettings';
import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import {useFormContext} from '../components/form/providers/FormProvider';
import {LanguageSelectionModal} from '../components/form/modals/LanguageSelectionModal';
import {IDropDownItem} from '../components/form/Interfaces/IDropDownItem';
import {useRecoilValue} from 'recoil';
import {LanguageSelectorModalState} from '../components/state/activity';
import {sessionKeys} from '../components/state/sessionKeys';
import {BorderRadiusInputField} from '../components/form/utils/constants-styling';

export const LanguageSelector = () => {

    const {
        language,
        setLanguage
    } = useFormContext();

    const {
        i18n,
        t
    } = useTranslation();

    const [detectedLanguages, setDetectedLanguages] = useState<IDropDownItem[]>([]);
    const showLanguageSelectorModal = useRecoilValue(LanguageSelectorModalState);

    const changeLanguage = (e: SelectChangeEvent) => {
        if (e.target.value != '') {
            void i18n.changeLanguage(e.target.value);
            setLanguage(e.target.value);
        }
    }

    const getDetectedLanguages = (): string[] => {
        const languageDetector = new LanguageDetector(i18next.services,
            {order: ['cookie', 'sessionStorage', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain']});

        return [...new Set(languageDetector.detect())];
    };

    const getSupportedDetectedLanguages = (): IDropDownItem[] => {
        const detectedLanguages = getDetectedLanguages();

        const supportedDetectedLanguages = [...new Set(detectedLanguages.map((x) => {
            return languageOptions.find(option =>
                getDetectedLanguage(x) === option.value
            );
        }))].map(({value, label}) => ({
            key: value,
            value: label
        }));

        setDetectedLanguages(supportedDetectedLanguages);
        return supportedDetectedLanguages;
    };

    const loadLanguage = (): void => {
        const selectedLanguage = localStorage.getItem(sessionKeys.preferredlanguage);

        if (selectedLanguage) {
            void i18n.changeLanguage(selectedLanguage);
            setLanguage(selectedLanguage);
            return;
        }

        const detectedLanguages = getSupportedDetectedLanguages();
        const language = detectedLanguages?.[0]?.key?.toString();

        if (language) {
            void i18n.changeLanguage(language);
            setLanguage(language);
        }
    };

    useEffect(() => {
        loadLanguage();
    }, []);

    return (
        <>
            <FormControl size='small'>
                <Select name='language-selector'
                        sx={{
                            pl: 0,
                            borderRadius: BorderRadiusInputField,
                            minWidth: '170px'
                        }}
                        onChange={changeLanguage}
                        value={language}>
                    <MenuItem value=''>{t('common:labelLanguage')}</MenuItem>
                    {languageOptions.map(x =>
                        <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {showLanguageSelectorModal &&
                <LanguageSelectionModal
                    detectedLanguages={detectedLanguages}
                    onLanguageChange={changeLanguage}
                />
            }
        </>
    );
}