export default {
    headingAdverseWeather: "您遇到嚴重的惡劣天氣條件，使得前往活動場所變得不安全，這是政府機構（例如地方當局，員警等）的授權。你有證據證明這一點嗎？",
    headingAdverseWeatherDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    tooltipRefundReasonConfirm: "是或否",
    labelBookingCancelledByOperator: "門票已由營運商/主辦單位取消。",
    labelBookingRescheduledByOperator: "日期由運營商/召集人更改，我無法參加新的日期。",
    labelBookedWrongDate: "我或預訂的會員在購買機票時出錯，並預訂了錯誤的日期。",
    labelBookedWrongTickets: "我或預訂的會員在購買機票時出錯，並預訂了錯誤的機票。",
    labelBookedTooManyTickets: "我或預訂的會員在購買門票時出錯，並預訂了太多門票。",
    labelCOVID19TravelRestrictions: "我或預訂的會員由於COVID-19旅行禁令而無法參加。",
    labelADWrongSelection: "您可能選擇了錯誤的退款原因。請再次查看退款原因下拉清單。",
    labelAdverseWeather1: "為什麼惡劣的天氣意味著您無法參加活動？",
    tooltipAdverseWeather1: "請詳細說明",
    labelAdverseWeather2: "事件發生日期",
    tooltipAdverseWeather2: "請選擇",
    headingArmedForces: "您的僱主要求您在最初沒有安排的情況下進入工作狀態，這是武裝部隊或緊急服務部門，這會阻止您參加活動？",
    headingArmedForcesDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelArmedForces1: "您受雇於哪些部隊或緊急服務部門？",
    tooltipArmedForces1: "請選擇",
    labelArmedForcesOther1: "請具體說明",
    tooltipArmedForcesOther1: "請具體說明",
    labelArmedForces2: "事件發生日期",
    tooltipArmedForces2: "請選擇",
    headingChangesToExam: "您的學校/學院等更改了考試日期？這不是您必須重新參加的失敗考試嗎？",
    headingChangesToExamDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelExam1: "最初的考試日期是什麼時候？",
    tooltipExam1: "請選擇",
    labelExam2: "新的考試日期是什麼時候？",
    tooltipExam2: "請選擇",
    labelExam3: "為什麼考試日期會改變？",
    tooltipExam3: "請輸入詳細資訊",
    labelExam4: "你之前的考試沒有通過嗎？",
    tooltipExam4: "是或否",
    headingCourtSummons: "您是否被傳喚在與事件發生相同的日期作為證人出庭？",
    headingCourtSummonsDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelCourtSummons1: "你們的法庭傳票的原因是什麼？",
    tooltipCourtSummons1: "請選擇",
    labelCourtSummonsOther1: "請具體說明",
    tooltipCourtSummonsOther1: "請具體說明",
    labelCourtSummons2: "法院傳票是什麼時候發出的？",
    tooltipCourtSummons2: "請選擇",
    headingCOVID19Test: "我、我的家庭成員或參加活動的團體成員被診斷出患有COVID-19？",
    headingCOVID19TestDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelCOVID19Test1: "誰感染了新冠肺炎？",
    tooltipCOVID19Test1: "請選擇",
    labelCOVID19TestOther1a: "請具體說明",
    labelCOVID19TestOther1b: "指定名稱",
    tooltipCOVID19TestOther1a: "請具體說明",
    tooltipCOVID19TestOther1b: "指定名稱",
    labelCOVID19Test2: "您進行了哪種類型的COVID-19測試？",
    tooltipCOVID19Test2: "請選擇",
    labelCOVID19TestOther2: "請具體說明",
    tooltipCOVID19TestOther2: "請具體說明",
    labelCOVID19Test3: "您的COVID-19測試日期是什麼時候？",
    tooltipCOVID19Test3: "請選擇",
    headingDeath: "我的一位直系親屬去世了，這使我無法參加活動？",
    headingDeathDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelDeath1: "您家的哪位成員去世了？",
    tooltipDeath1: "請選擇",
    labelDeathOther1: "請具體說明",
    tooltipDeathOther1: "請具體說明",
    labelDeath2: "死亡日期是什麼時候？",
    tooltipDeath2: "請選擇",
    labelDeath3: "死者姓名",
    tooltipDeath3: "請具體說明",
    headingEmergency: "我遇到了一個真正的緊急情況，這是我無法控制和不可預見的，我可以提供證據嗎？",
    headingEmergencyDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelEmergency1: "請解釋緊急情況的類型？",
    tooltipEmergency1: "請詳細說明",
    labelEmergency2: "這是什麼時候發生的？",
    tooltipEmergency2: "請選擇",
    labelEmergency3: "為什麼這種情況會阻止您參加活動？",
    tooltipEmergency3: "請詳細說明",
    labelEmergency4: "你有這種緊急情況的證據嗎？",
    tooltipEmergency4: "是或否",
    headingHomeEmergency: "我有一個家庭緊急情況，即搶劫，洪水，火災，故意破壞，家庭虐待，阻止我參加活動？",
    headingHomeEmergencyDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelHomeEmergency1: "什麼是家庭緊急情況？",
    tooltipHomeEmergency1: "請選擇",
    labelHomeEmergencyOther1: "請具體說明",
    tooltipHomeEmergencyOther1: "請具體說明",
    labelHomeEmergency2: "這是什麼時候發生的？",
    tooltipHomeEmergency2: "請選擇",
    labelHomeEmergency3: "為什麼它阻止你參加這個活動？",
    tooltipHomeEmergency3: "請詳細說明",
    headingInjury: "我、我的家庭成員或參加活動的團體成員被診斷出患有醫療狀況或受傷，現在無法參加活動？",
    headingInjuryDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelInjury1: "疾病/傷害/事故的性質是什麼？",
    tooltipInjury1: "請詳細說明",
    labelInjury2: "這是什麼時候發生的？",
    tooltipInjury2: "請選擇",
    labelInjury3: "您是否因這種疾病/受傷去過醫療機構？",
    tooltipInjury3: "是或否",
    labelInjury4: "這是預先存在的醫療狀況嗎？",
    tooltipInjury4: "是或否",
    labelInjury5: "這是什麼時候第一次被診斷出來的？",
    tooltipInjury5: "請選擇",
    labelInjury6: "為什麼這意味著您無法參加活動？",
    tooltipInjury6: "請詳細說明",
    labelInjury7: "誰遭受了疾病/傷害？",
    tooltipInjury7: "請選擇",
    headingJuryService: "我或預訂的會員已被召集到陪審團服務？",
    headingJuryServiceDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelJuryService1: "您是什麼時候收到陪審團服務的通知的？",
    tooltipJuryService1: "請選擇",
    headingMechanicalBreakdown: "我們的車輛發生機械故障或發生事故，這使我們無法參加活動？",
    headingMechanicalBreakdownDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelMechanicalBreakdown1: "車輛發生了什麼變化？",
    tooltipMechanicalBreakdown1: "請選擇",
    labelMechanicalBreakdown2: "這是什麼時候發生的？",
    tooltipMechanicalBreakdown2: "請選擇",
    labelMechanicalBreakdown3: "為什麼您無法乘坐其他交通工具？",
    tooltipMechanicalBreakdown3: "請詳細說明",
    headingPregnancyComplication: "我或預訂的會員患有由醫療專業人員診斷的妊娠併發症，因此無法參加活動？",
    headingPregnancyComplicationDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelPregnancyComplication1: "妊娠併發症的性質是什麼？",
    tooltipPregnancyComplication1: "請詳細說明",
    labelPregnancyComplication2: "這是什麼時候發生的？",
    tooltipPregnancyComplication2: "請選擇",
    labelPregnancyComplication3: "您是否尋求醫療救助？",
    tooltipPregnancyComplication3: "是或否",
    labelPregnancyComplication4: "為什麼這意味著您無法參加活動？",
    tooltipPregnancyComplication4: "請詳細說明",
    headingPublicTransportFailure: "公共交通工具（火車，公共汽車，電車，船等）的方法意外失敗，這意味著沒有其他方式可以參加此活動？",
    headingPublicTransportFailureDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelPublicTransportFailure1: "您計劃使用哪種類型的公共交通工具？",
    tooltipPublicTransportFailure1: "請選擇",
    labelPublicTransportFailure2: "你打算什麼時候旅行？",
    tooltipPublicTransportFailure2: "請選擇",
    labelPublicTransportFailure3: "發生了什麼事？",
    tooltipPublicTransportFailure3: "請詳細說明",
    labelPublicTransportFailure4: "為什麼你不能採取其他交通工具？",
    tooltipPublicTransportFailure4: "請詳細說明",
    headingScheduledAirlineFailure: "我預訂的航班意外取消，我無法找到合適的替代方案，這意味著我無法參加活動？",
    headingScheduledAirlineFailureDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelScheduledAirlineFailure1: "航空公司名稱",
    tooltipScheduledAirlineFailure1: "航空公司名稱",
    labelScheduledAirlineFailure2: "航班號",
    tooltipScheduledAirlineFailure2: "航班號",
    labelScheduledAirlineFailure3: "出發地",
    tooltipScheduledAirlineFailure3: "出發地",
    labelScheduledAirlineFailure4: "前往",
    tooltipScheduledAirlineFailure4: "前往",
    labelScheduledAirlineFailure5: "事件發生日期？",
    tooltipScheduledAirlineFailure5: "請選擇",
    labelScheduledAirlineFailure6: "為什麼您無法乘坐其他航班？",
    tooltipScheduledAirlineFailure6: "請詳細說明",
    headingTheftOfDocuments: "我的文件被盜，無法在所需的時間範圍內安排更換，因此我無法參加活動",
    headingTheftOfDocumentsDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelTheftOfDocuments1: "什麼類型的文件被盜？",
    tooltipTheftOfDocuments1: "請選擇",
    labelTheftOfDocuments2: "這是什麼時候發生的？",
    tooltipTheftOfDocuments2: "請選擇",
    labelTheftOfDocuments3: "您是否嘗試過重新簽發檔？",
    tooltipTheftOfDocuments3: "是或否",
    headingWorkRelocation: "我的僱主要求我改變工作地點至少三個月，距離很重要，這意味著我現在無法參加活動？",
    headingWorkRelocationDecline: "您不應繼續使用此申請，因為退款很可能會被拒絕。",
    labelWorkRelocation1: "這是您工作的臨時還是永久搬遷？",
    tooltipWorkRelocation1: "請選擇",
    labelWorkRelocation2: "您將在新位址停留多長時間？",
    tooltipWorkRelocation2: "請詳細說明",
    labelWorkRelocation3: "您目前的工作地址是什麼？",
    tooltipWorkRelocation3: "請詳細說明",
    labelWorkRelocation4: "您的新工作地址是什麼？",
    tooltipWorkRelocation4: "請詳細說明",
    labelWorkRelocation5: "您是什麼時候接到搬遷通知的？",
    tooltipWorkRelocation5: "請選擇",
    headingRedundancy: "我或預訂的會員已被僱主裁員，這意味著我不能再參加預訂？",
    headingRedundancyDecline: "您不應繼續此申請，因為退款很可能會被拒絕。",
    labelRedundancy1: "就業類型？",
    tooltipRedundancy1: "請選擇",
    labelRedundancy2: "你在這家公司工作多久了？",
    tooltipRedundancy2: "請選擇",
    labelRedundancy3: "裁員是自願的嗎？",
    tooltipRedundancy3: "請選擇",
    labelRedundancy4: "您是什麼時候收到裁員通知的？",
    tooltipRedundancy4: "請選擇",
    labelTestOption: "選擇是繼續",
}