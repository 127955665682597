import * as React from 'react';
import {FormHelperText} from '@mui/material';
import {FieldProps} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';

interface ErrorMessageProps {
    fieldProps: FieldProps<IFormValues>;
}

export const ErrorMessage = (props: ErrorMessageProps) => {

    const {
        fieldProps
    } = props;

    return (
        <FormHelperText sx={{color: '#D32F2F'}}>{fieldProps.meta.touched ? fieldProps.meta.error : ''}</FormHelperText>
    );
}