export default {
    nonAutoApprovedLabelThankYou: "Merci pour votre demande de remboursement.",
    nonAutoApprovedLabelReview: "Notre équipe examinera votre demande dans les deux prochains jours ouvrables et vous répondra.",
    nonAutoApprovedLabelExperience: "Comment avons-nous fait?",
    nonAutoApprovedLabelExperienceIntroduction: "Nous vous serions vraiment reconnaissants de prendre le temps de nous évaluer et de laisser un avis car nous apprécions l’opinion de nos clients.",
    feedbackExcellent: "Excellente",
    feedbackGreat: "Génial",
    feedbackAverage: "Moyenne",
    feedbackPoor: "Pauvre",
    feedbackBad: "Mauvais",
    labelThankYouForFeedback: "Merci pour vos commentaires.",
    autoApprovedMessage1: "Nous sommes ravis de",
    autoApprovedMessage2: "Approuver",
    autoApprovedMessage3: "votre remboursement!",
    autoApprovedPayment1Day: "Votre paiement a déjà été traité et devrait être sur votre compte en seulement 1 jour ouvrable.",
    autoApprovedPayment2to4Days: "Votre paiement a déjà été traité et devrait être sur votre compte dans seulement 2-4 jours ouvrables.",
    autoApprovedPayment7to10Days: "Votre paiement a déjà été traité et devrait être sur votre compte dans seulement 7-10 jours ouvrables.",
    autoApprovedLabelExperience: "Comment avons-nous fait?",
    autoApprovedLabelExperienceIntroduction: "Nous vous serions vraiment reconnaissants de prendre le temps de nous évaluer et de laisser un avis car nous apprécions l’opinion de nos clients.",
    modalLabelNegative: "Vos commentaires sont vraiment importants pour nous, faites-nous savoir si vous souhaitez également que nous vous contactions.",
    placeholderFeedback: "Rétroaction",
    messageFeedbackSubmitted: "Commentaires soumis",
    reviewInstructionMessage: "5 étoiles est excellent, 4 étoiles est génial, 3 étoiles est moyenne, 2 étoiles est pauvre, 1 étoile est mauvaise."
}