export default {
    messageUnableToMakePaymentToCurrency: "Malheureusement, nous ne sommes pas en mesure de récupérer les coordonnées bancaires pour la devise sélectionnée. Veuillez vous assurer que la devise et le pays d'origine sont sélectionnés sur",
    messageOnlyBankAccountsAccepted: "Remarque: nous ne pouvons effectuer des paiements que sur des comptes bancaires pour le moment (les cartes de crédit et de débit ne sont pas acceptées)",
    labelAccountName: "Nom du titulaire du compte",
    labelBankAccountName: "Nom complet du titulaire du compte",
    labelBankCardHolderName: "Nom du titulaire",
    validationBankAccountName: "Le nom complet du titulaire du compte est requis",
    labelBankCountry: "Pays de la banque",
    labelBankName: "Nom de banque",
    labelPaymentType: "Type de paiement",
    labelInstitutionNumber: "Numéro d'établissement",
    labelBankAddress: "Adresse de la banque",
    labelBankAddress2: "Adresse de la banque",
    labelBankCity: "Banque Ville",
    labelBankState: "État de la banque",
    labelBankAccountNumber: "Numéro de compte bancaire",
    labelBankAccountNumberCBU: "Numéro de compte CBU",
    labelBankAccountNumberCCI: "Numéro de compte CCI",
    labelBankAccountNumberZAR: "Numéro de compte bancaire (7 à 11 chiffres)",
    labelCardNumber: "Numéro de carte",
    labelUnionpay: "Numéro de carte UnionPay",
    labelAlipay: "Identifiant Alipay (numéro de portable ou email)",
    labelBankAccountType: "Type de compte",
    optionBankAccountTypeCURRENT: "Compte courant",
    optionBankAccountTypeCHECKING: "Compte courant",
    optionBankAccountTypeSAVING: "Compte épargne",
    optionBankAccountTypeSAVINGS: "Compte épargne",
    optionBankAccountTypeCUENTA_VISTA: "Compte principal",
    optionBankAccountType1: "Compte courant",
    optionBankAccountType2: "Compte épargne",
    optionBankAccountType3: "Compte principal",
    optionBankAccountType4: "Compte Vista",
    optionBankAccountType5: "Compte RUT",
    labelSortCode: "Code de tri bancaire",
    labelRoutingCode: "Code de routage",
    labelBankRoutingCodeNationalID: "Code d'acheminement bancaire / ID national",
    labelBankTransitCodeSwift: "Numéro de transit",
    labelCNAPSCode: "Code d'acheminement / Code CNAPS (Code du système national de paiement avancé de la Chine)",
    labelABAFedwireRoutingCode: "Numéro de routage ACH",
    labelIFSCCode: "Code IFSC",
    labelBankCode: "Code bancaire",
    labelBIKCode: "Code BIK",
    labelBranchCode: "Code de la succursale",
    labelBranchCodeUniversal: "Code de succursale universel à 6 chiffres",
    labelRemittanceLine3: "Nom et numéro de téléphone du bénéficiaire",
    labelBeneficiaryContactNameandTelephoneNumber: "Nom et numéro de téléphone du bénéficiaire",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Bénéficiaire Date de naissance et lieu de naissance",
    labelRemittanceLine4: "Numéro fiscal individuel CUIL (9-12 chiffres)",
    labelCUILNumber: "Numéro d'identification fiscale : CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "ID fiscal RFC 13 chiffres ou CURP 18 chiffres",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Numéro d'identification fiscale individuel CPF Enregistrement individuel fiscal (11-14 chiffres), code de succursale",
    labelCFPNumber: "Numéro CPF (11-14 chiffres)",
    labelBranchNameorBranchCode7digits: "Nom de la succursale ou code de la succursale (7 chiffres)",
    labelCedulaJuridica910digitsTaxID: "Certificat juridique (numéro d'identification fiscale de 9 à 10 chiffres)",
    labelTaxIDRUT89digits: "Numéro d'identification fiscale RUT (8-9 chiffres)",
    labelJob: "Titre d'emploi",
    labelDateOfBirth: "Date de naissance",
    labelNationality: "Nationalité",
    labelDocumentType: "Type de document",
    labelDocumentNumber: "Numéro de document",
    labelRutNumber: "RUT number (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Carte d'identité (numéro d'identification fiscale individuel de 8 à 11 chiffres)",
    labelCIIndividualTaxIDBranchLocation: "CI (numéro d'identification fiscale individuel), emplacement de la succursale",
    labelTaxIDRUCorCI: "Numéro d'identification fiscale (RUC ou CI)",
    labelSortCodeHelp: "Aider?",
    labelBranchCodeHelp: "Aider?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Aider?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Aider?",
    labelRoutingCodeBSB: "Code de routage/BSB",
    labelBSB: "Succursale d'État de la banque (BSB)",
    labelSKNCode: "Code SKN",
    labelAcceptanceFlights: "J'accepte/nous acceptons que si la demande de remboursement aboutit, la réservation, y compris tous les vols et produits annexes, sera annulée.",
    labelAcceptanceTickets: "J'accepte/nous acceptons que si la demande de remboursement aboutit, la réservation, y compris tous les billets et produits annexes, sera annulée.",
    labelTermsAndConditions1: "J'ai / nous avons accepté le",
    labelTermsAndConditions2: "Termes et conditions",
    labelFraudWarning1: "Je crois que les faits fournis dans ce formulaire de demande de remboursement sont authentiques et véridiques. Je comprends que toute fausse information ou preuve peut être considérée comme un acte criminel et être transmise à la police ou à d'autres organismes chargés de l'application de la loi.",
    labelFraudWarning2: "Si nous soupçonnons une fraude concernant les pièces justificatives, cette affaire sera immédiatement transmise aux autorités de police locales. J'accepte et comprends que mes coordonnées seront transmises à ces autorités si ma demande est considérée comme frauduleuse et que cela peut devenir une enquête criminelle.",
    validationTermsAndConditions: "Les termes et conditions doivent être acceptés",
    messageFailbankName: "Le nom de la banque ne peut pas être vide",
    messageFailcustomerName: "Le nom du client ne peut pas être vide",
    messageFailbankAccountNumber: "Champs requis",
    messageFailbankAccountNumberFormat: "Doit être au format IBAN",
    messageFailsortCode: "Champs requis",
    messageFailbankSwiftBic: "Le BIC SWIFT du bénéficiaire ne peut pas être vide",
    messageFailbankSwiftBicFormat: "Doit être au format SWIFT",
    messageFailbeneficiaryAddress1: "L'adresse 1 ne peut pas être vide",
    messageFailbeneficiaryCity: "La ville ne peut pas être vide",
    messageFailbeneficiaryPostCode: "Le code postal ne peut pas être vide",
    messageFailremittanceLine3: "Champs requis",
    messageFailremittanceLine4: "Champs requis",
    modalHeading: "Confirmer les détails",
    modalLabelPleaseConfirmYourDetails: "Veuillez confirmer vos coordonnées",
    modalValidationPleaseConfirm: "Veuillez confirmer",
    modalValidationMustMatch: "Doit correspondre à l'entrée précédente",
    tooltipBankAccountName: "Votre nom tel qu'il apparaît sur votre compte bancaire / relevés bancaires / carte bancaire.",
    tooltipBankAccountNameRecipient: "Le prénom et le nom du destinataire. Le prénom et le nom doivent avoir plus d'un caractère.",
    tooltipBankCountry: "Le pays dans lequel votre banque est située.",
    tooltipBankName: "Le nom de votre banque.",
    tooltipPaymentType: "Le type de paiement dont vous avez besoin.",
    tooltipInstitutionNumber: "Il s'agit de 3 chiffres et se trouve généralement sur votre relevé mensuel ou votre carte bancaire ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankAccountNumber: "Votre numéro de compte individuel. Se trouve généralement sur votre relevé mensuel ou sur votre carte bancaire ou si vous n'êtes toujours pas sûr, adressez-vous à votre banque.",
    tooltipBankAccountNumberCBU: "Un numéro à 22 chiffres utilisé pour identifier votre compte. Se trouve généralement sur votre relevé mensuel ou votre carte bancaire ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankAccountNumberCCI: "Un numéro à 20 chiffres utilisé pour identifier votre compte. Se trouve généralement sur votre relevé mensuel ou votre carte bancaire ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankAccountNumberKRW: "Un numéro de 11 à 16 chiffres utilisé pour identifier votre compte. Se trouve généralement sur votre relevé mensuel ou votre carte bancaire ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCardNumber: "Votre numéro de carte. Se trouve généralement sur votre relevé mensuel ou votre carte bancaire ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipUnionpay: "Votre numéro de carte UnionPay.",
    tooltipAlipay: "Votre numéro de mobile ou votre adresse e-mail associée à votre compte Alipay.",    
    tooltipBankAccountType: "Il s'agit soit de vérification, soit d'épargne.",
    tooltipSortCode: "Cela identifie la succursale de votre banque où votre compte est détenu. Se trouve généralement sur votre relevé mensuel ou sur votre carte bancaire ou si vous n'êtes toujours pas sûr, adressez-vous à votre banque.",
    tooltipRoutingCode: "Cela identifie la succursale de votre banque où votre compte est détenu. Se trouve généralement sur votre relevé mensuel ou sur votre carte bancaire ou si vous n'êtes toujours pas sûr, adressez-vous à votre banque.",
    tooltipBeneficiarySWIFTBIC: "Se compose de 8 à 11 caractères utilisés pour identifier une banque dans une transaction internationale. Habituellement trouvé sur votre relevé mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBSB: "Un numéro BSB (Bank State Branch) est un code de tri australien composé de 6 chiffres. Habituellement trouvé sur votre relevé mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipRoutingCodeBSB: "Cela identifie la succursale de votre banque où votre compte est détenu. Se trouve généralement sur votre relevé mensuel ou sur votre carte bancaire ou si vous n'êtes toujours pas sûr, adressez-vous à votre banque.",
    tooltipABAFedwireRoutingCode: "Un numéro à 9 chiffres utilisé pour identifier les banques en Amérique. Habituellement trouvé sur votre relevé mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipIFSCCode: "Généralement, ce code se trouve sur votre relevé mensuel ou sur votre livret ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipTermsAndConditions: "En acceptant les conditions générales, vous confirmez que vous avez lu et accepté nos conditions générales.",
    tooltipIBAN: "Votre numéro de compte bancaire international, que vous pouvez utiliser pour effectuer ou recevoir des paiements internationaux. Habituellement trouvé sur votre relevé mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nom et numéro de téléphone du bénéficiaire.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Bénéficiaire Date de naissance et lieu de naissance.",
    tooltipBankRoutingCodeNationalID: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankTransitCodeSwift: "Il s'agit de 5 chiffres et peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankAddress: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankAddress2: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankCity: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankState: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBranchName: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBankCode: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBIKCode: "9 chiffres pour identifier votre banque.",
    tooltipBranchCode: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBranchCodeUniversal: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCNAPSCode: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipRemittanceLine4: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCUILNumber: "Il s'agit d'un numéro à 11 chiffres, composé de deux chiffres, d'un trait d'union, de neuf chiffres et d'une somme de contrôle à un chiffre.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCFPNumber: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipBranchNameorBranchCode7digits: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCedulaJuridica910digitsTaxID: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipTaxIDRUT89digits: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipJob: "Votre occupation actuelle.",
    tooltipDateOfBirth: "Votre date de naissance.",
    tooltipNationality: "Ta nationalité.",
    tooltipDocumentType: "Le type de document requis.",
    tooltipDocumentNumber: "Il s'agit du numéro de document associé à votre sélection de type de document.",
    tooltipRutNumber: "Votre numéro RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipCIIndividualTaxIDBranchLocation: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    tooltipTaxIDRUCorCI: "Peut généralement être trouvé sur votre relevé bancaire mensuel ou si vous n'êtes toujours pas sûr, demandez à votre banque.",
    errorCPFCode: "Pour le moment, nous acceptons uniquement CPF comme clé PIX."
};