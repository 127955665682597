import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {AlertHolder} from './AlertHolder';

export const BookingNotFoundAlert = () => {

    const {
        bookingCounter
    } = useFormContext();

    const {t} = useTranslation();

    const BookingNotFoundMessage = () => {
        return (
            <>
                <p>{t('common:messageBookingNotFound1')}</p>
                <p>{t('common:messageBookingNotFound2')}</p>
            </>
        );
    }

    return (
        <>
            {bookingCounter === 1 &&
                <AlertHolder
                    severity='error'
                    content={<BookingNotFoundMessage/>}
                />
            }
        </>
    );
}