export default {
    messageQuestionService: "Lähetä kysymys vain jos sinulla on ongelmia hakulomakkeen täyttämisessä, emme voi vastata muihin kysymyksiin tätä kautta",
    labelName: "Nimi",
    labelReference: "Varausviite",
    labelPhone: "Puhelinnumero",
    labelEmail: "Sähköposti",
    labelQuery: "Kysymyksesi",
    labelAskQuestion: "Lähetä meille kysymys tästä...",
    buttonOpenModal: "Kysyttävää",
    title: "Lähetä kysymys",
    modalTitle: "4. Onko sinulla tekninen ongelma lomakkeen kanssa?",
    toastSuccess: "Olemme vastaanottaneet kysymyksesi ja otamme sinuun yhteyttä 24 tunnin kuluessa",
    toastError: "Jotain meni pieleen pyynnössäsi. Yritä uudelleen",
    nameValidation:"Nimi vaaditaan",
    referenceValidation:"Viite vaaditaan",
    questionValidation: "Kysymyksesi on pakollinen"
}