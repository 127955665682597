export default {
    headingAdverseWeather: "Vă confruntați cu condiții meteorologice nefavorabile severe care fac nesigură deplasarea la eveniment și acest lucru a fost mandatat de un organism guvernamental (de exemplu, autoritatea locală, poliția etc.). Și aveți dovezi care să dovedească acest lucru?",
    headingAdverseWeatherDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    tooltipRefundReasonConfirm: "Da sau nu",
    labelBookingCancelledByOperator: "Biletele au fost anulate de operator/organizator.",
    labelBookingRescheduledByOperator: "Data a fost schimbată de operator/organizator și nu pot participa la noile date.",
    labelBookedWrongDate: "Eu sau un membru al rezervării, am făcut o eroare la achiziționarea biletelor și am rezervat data greșită.",
    labelBookedWrongTickets: "Eu sau un membru al rezervării, am făcut o eroare la achiziționarea biletelor și am rezervat biletele greșite.",
    labelBookedTooManyTickets: "Eu sau un membru al rezervării, am făcut o eroare la achiziționarea biletelor și am rezervat prea multe bilete.",
    labelCOVID19TravelRestrictions: "Eu sau un membru al rezervării, nu putem participa din cauza unei interdicții de călătorie covid-19.",
    labelADWrongSelection: "Este posibil să fi selectat motivul greșit pentru o rambursare. Vă rugăm să consultați din nou lista derulantă Motiv rambursare.",
    labelAdverseWeather1: "De ce vremea nefavorabilă a însemnat că nu ai putut participa la eveniment?",
    tooltipAdverseWeather1: "Vă rugăm să detaliați",
    labelAdverseWeather2: "Data incidentului",
    tooltipAdverseWeather2: "Vă rugăm să selectați",
    headingArmedForces: "Vi se cere de către angajator care este Forțele Armate sau serviciile de urgență să vină în muncă atunci când nu au fost inițial programate să facă acest lucru și acest lucru vă împiedică să participe la eveniment?",
    headingArmedForcesDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelArmedForces1: "De ce forțe sau servicii de urgență sunteți angajat?",
    tooltipArmedForces1: "Vă rugăm să selectați",
    labelArmedForcesOther1: "Vă rugăm să specificați",
    tooltipArmedForcesOther1: "Vă rugăm să specificați",
    labelArmedForces2: "Data incidentului",
    tooltipArmedForces2: "Vă rugăm să selectați",
    headingChangesToExam: "Datele examenului au fost schimbate de către școală / colegiu, etc? Acesta nu este un examen eșuat pe care trebuie să-l resit?",
    headingChangesToExamDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelExam1: "Care a fost data inițială a examinării?",
    tooltipExam1: "Vă rugăm să selectați",
    labelExam2: "Care este noua dată de examinare?",
    tooltipExam2: "Vă rugăm să selectați",
    labelExam3: "De ce s-a schimbat data examinării?",
    tooltipExam3: "Vă rugăm să introduceți detalii",
    labelExam4: "Ați picat examenul anterior?",
    tooltipExam4: "Da sau nu",
    headingCourtSummons: "Ați fost citat să vă prezentați în instanță în calitate de martor la aceleași date ca și evenimentul?",
    headingCourtSummonsDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelCourtSummons1: "Care este motivul citației dumneavoastră în instanță?",
    tooltipCourtSummons1: "Vă rugăm să selectați",
    labelCourtSummonsOther1: "Vă rugăm să specificați",
    tooltipCourtSummonsOther1: "Vă rugăm să specificați",
    labelCourtSummons2: "Când a fost emisă citația în instanță?",
    tooltipCourtSummons2: "Vă rugăm să selectați",
    headingCOVID19Test: "Eu, un membru al gospodăriei mele sau un membru al grupului care participă la eveniment am fost diagnosticat cu COVID-19?",
    headingCOVID19TestDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelCOVID19Test1: "Cine are COVID-19?",
    tooltipCOVID19Test1: "Vă rugăm să selectați",
    labelCOVID19TestOther1a: "Vă rugăm să specificați",
    labelCOVID19TestOther1b: "Specificarea numelui",
    tooltipCOVID19TestOther1a: "Vă rugăm să specificați",
    tooltipCOVID19TestOther1b: "Specificarea numelui",
    labelCOVID19Test2: "Ce tip de test COVID-19 ați avut?",
    tooltipCOVID19Test2: "Vă rugăm să selectați",
    labelCOVID19TestOther2: "Vă rugăm să specificați",
    tooltipCOVID19TestOther2: "Vă rugăm să specificați",
    labelCOVID19Test3: "Care a fost data testului covid-19?",
    tooltipCOVID19Test3: "Vă rugăm să selectați",
    headingDeath: "A existat un deces al unui membru imediat al familiei mele și mă împiedică să particip la eveniment?",
    headingDeathDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelDeath1: "Care membru al familiei tale a murit?",
    tooltipDeath1: "Vă rugăm să selectați",
    labelDeathOther1: "Vă rugăm să specificați",
    tooltipDeathOther1: "Vă rugăm să specificați",
    labelDeath2: "Când a fost data morții?",
    tooltipDeath2: "Vă rugăm să selectați",
    labelDeath3: "Numele defunctului",
    tooltipDeath3: "Vă rugăm să specificați",
    headingEmergency: "Am avut o urgență reală care este în afara controlului meu și neprevăzută pentru care pot furniza dovezi?",
    headingEmergencyDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelEmergency1: "Vă rugăm să explicați tipul de urgență?",
    tooltipEmergency1: "Vă rugăm să detaliați",
    labelEmergency2: "Când s-a întâmplat acest lucru?",
    tooltipEmergency2: "Vă rugăm să selectați",
    labelEmergency3: "De ce această situație vă oprește să participați la eveniment?",
    tooltipEmergency3: "Vă rugăm să detaliați",
    labelEmergency4: "Aveți dovezi ale acestei urgențe?",
    tooltipEmergency4: "Da sau nu",
    headingHomeEmergency: "Am o urgență la domiciliu care este Jaf, Potop, Incendiu, Vandalism, Abuz Domestic care mă împiedică să particip la eveniment?",
    headingHomeEmergencyDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelHomeEmergency1: "Care este urgența la domiciliu?",
    tooltipHomeEmergency1: "Vă rugăm să selectați",
    labelHomeEmergencyOther1: "Vă rugăm să specificați",
    tooltipHomeEmergencyOther1: "Vă rugăm să specificați",
    labelHomeEmergency2: "Când s-a întâmplat acest lucru?",
    tooltipHomeEmergency2: "Vă rugăm să selectați",
    labelHomeEmergency3: "De ce v-a oprit participarea la eveniment?",
    tooltipHomeEmergency3: "Vă rugăm să detaliați",
    headingInjury: "Eu, un membru al gospodăriei mele sau un membru al grupului care participă la eveniment au fost diagnosticați cu o afecțiune sau un prejudiciu care acum ne împiedică să mergem la eveniment?",
    headingInjuryDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelInjury1: "Care este natura bolii/ vătămării/accidentului?",
    tooltipInjury1: "Vă rugăm să detaliați",
    labelInjury2: "Când s-a întâmplat acest lucru?",
    tooltipInjury2: "Vă rugăm să selectați",
    labelInjury3: "Ați mers într-o unitate medicală pentru această boală/rănire?",
    tooltipInjury3: "Da sau nu",
    labelInjury4: "A fost aceasta o afectiune medicala preexistenta?",
    tooltipInjury4: "Da sau nu",
    labelInjury5: "Când a fost diagnosticat acest lucru pentru prima dată?",
    tooltipInjury5: "Vă rugăm să selectați",
    labelInjury6: "De ce înseamnă acest lucru că nu puteți participa la eveniment?",
    tooltipInjury6: "Vă rugăm să detaliați",
    labelInjury7: "Cine a suferit boala/accidentarea?",
    tooltipInjury7: "Vă rugăm să selectați",
    headingJuryService: "Eu sau un membru al rezervării am fost chemat la Serviciul Juriului?",
    headingJuryServiceDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelJuryService1: "Când ați fost înștiințat cu privire la Serviciul Juriului?",
    tooltipJuryService1: "Vă rugăm să selectați",
    headingMechanicalBreakdown: "Vehiculul nostru a avut o defecțiune mecanică sau a fost într-un accident și acest lucru ne împiedică să participăm la eveniment?",
    headingMechanicalBreakdownDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelMechanicalBreakdown1: "Ce s-a întâmplat cu vehiculul?",
    tooltipMechanicalBreakdown1: "Vă rugăm să selectați",
    labelMechanicalBreakdown2: "Când s-a întâmplat acest lucru?",
    tooltipMechanicalBreakdown2: "Vă rugăm să selectați",
    labelMechanicalBreakdown3: "De ce nu ați putut să luați transport alternativ?",
    tooltipMechanicalBreakdown3: "Vă rugăm să detaliați",
    headingPregnancyComplication: "Eu sau un membru al rezervării avem complicații ale sarcinii care au fost diagnosticate de un cadru medical și, prin urmare, împiedicăm participarea la eveniment?",
    headingPregnancyComplicationDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelPregnancyComplication1: "Care este natura complicatiei sarcinii?",
    tooltipPregnancyComplication1: "Vă rugăm să detaliați",
    labelPregnancyComplication2: "Când s-a întâmplat acest lucru?",
    tooltipPregnancyComplication2: "Vă rugăm să selectați",
    labelPregnancyComplication3: "Ați solicitat asistență medicală?",
    tooltipPregnancyComplication3: "Da sau nu",
    labelPregnancyComplication4: "De ce înseamnă acest lucru că nu puteți participa la eveniment?",
    tooltipPregnancyComplication4: "Vă rugăm să detaliați",
    headingPublicTransportFailure: "Metoda transportului public (tren, autobuz, tramvai, barcă, etc) a eșuat în mod neașteptat, ceea ce înseamnă că nu a existat nici o altă modalitate de a putea călători la acest eveniment?",
    headingPublicTransportFailureDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelPublicTransportFailure1: "Ce tip de Transport Public intenționați să utilizați?",
    tooltipPublicTransportFailure1: "Vă rugăm să selectați",
    labelPublicTransportFailure2: "Când ți-ai propus să călătorești?",
    tooltipPublicTransportFailure2: "Vă rugăm să selectați",
    labelPublicTransportFailure3: "Ce s-a întâmplat?",
    tooltipPublicTransportFailure3: "Vă rugăm să detaliați",
    labelPublicTransportFailure4: "De ce nu ați putea lua transport alternativ?",
    tooltipPublicTransportFailure4: "Vă rugăm să detaliați",
    headingScheduledAirlineFailure: "Zborul meu pre-rezervat s-a anulat în mod neașteptat și nu am reușit să găsesc o alternativă potrivită, ceea ce înseamnă că nu am putut participa la eveniment?",
    headingScheduledAirlineFailureDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelScheduledAirlineFailure1: "Numele companiei aeriene",
    tooltipScheduledAirlineFailure1: "Numele companiei aeriene",
    labelScheduledAirlineFailure2: "Numărul zborului",
    tooltipScheduledAirlineFailure2: "Numărul zborului",
    labelScheduledAirlineFailure3: "Călătorind de la",
    tooltipScheduledAirlineFailure3: "Călătorind de la",
    labelScheduledAirlineFailure4: "Călătorind la",
    tooltipScheduledAirlineFailure4: "Călătorind la",
    labelScheduledAirlineFailure5: "Data incidentului?",
    tooltipScheduledAirlineFailure5: "Vă rugăm să selectați",
    labelScheduledAirlineFailure6: "De ce nu ai putut să iei un zbor alternativ?",
    tooltipScheduledAirlineFailure6: "Vă rugăm să detaliați",
    headingTheftOfDocuments: "Documentele mele au fost furate și nu a fost posibil să se aranjeze înlocuirea (înlocuirile) în intervalul de timp necesar, astfel încât să nu pot participa la eveniment",
    headingTheftOfDocumentsDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelTheftOfDocuments1: "Ce tip de Document a fost furat?",
    tooltipTheftOfDocuments1: "Vă rugăm să selectați",
    labelTheftOfDocuments2: "Când s-a întâmplat acest lucru?",
    tooltipTheftOfDocuments2: "Vă rugăm să selectați",
    labelTheftOfDocuments3: "Ați încercat să reemiteți documentele?",
    tooltipTheftOfDocuments3: "Da sau nu",
    headingWorkRelocation: "Angajatorul meu mi-a solicitat să schimb locația muncii mele timp de cel puțin trei luni, iar distanța este semnificativă, ceea ce înseamnă că acum nu pot participa la eveniment?",
    headingWorkRelocationDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelWorkRelocation1: "Este aceasta o relocare temporară sau permanentă a activității dumneavoastră?",
    tooltipWorkRelocation1: "Vă rugăm să selectați",
    labelWorkRelocation2: "Cât timp veți fi la noua adresă?",
    tooltipWorkRelocation2: "Vă rugăm să detaliați",
    labelWorkRelocation3: "Care este adresa ta actuală de lucru?",
    tooltipWorkRelocation3: "Vă rugăm să detaliați",
    labelWorkRelocation4: "Care este noua ta adresă de lucru?",
    tooltipWorkRelocation4: "Vă rugăm să detaliați",
    labelWorkRelocation5: "Când ați fost înștiințat cu privire la această relocare?",
    tooltipWorkRelocation5: "Vă rugăm să selectați",
    headingRedundancy: "Eu sau un membru al rezervării am fost concediați de angajatorul lor, ceea ce înseamnă că nu mai pot participa la rezervare?",
    headingRedundancyDecline: "Nu trebuie să continuați cu această cerere, deoarece rambursarea va fi cel mai probabil refuzată.",
    labelRedundancy1: "Tipul de angajare?",
    tooltipRedundancy1: "Vă rugăm selectați",
    labelRedundancy2: "De cât timp sunteți angajat al acestei companii?",
    tooltipRedundancy2: "Vă rugăm selectați",
    labelRedundancy3: "Concedierea a fost voluntară?",
    tooltipRedundancy3: "Vă rugăm selectați",
    labelRedundancy4: "Când ați fost anunțat de concediere?",
    tooltipRedundancy4: "Vă rugăm selectați",
    labelTestOption: "Selectați Da pentru a continua",
}