export default {
    labelBookingCancelledPostponedHeading: "Η κράτησή σας ακυρώθηκε ή αναβλήθηκε; Διαβάστε περισσότερα",
    labelBookingCancelledPostponedText1: "Κάντε κλικ",
    labelBookingCancelledPostponedText2: "για περισσότερες πληροφορίες.",
    labelNewRefundHeading: "Νέα αίτηση επιστροφής χρημάτων;",
    labelNewRefundText: "Εισαγάγετε την αναφορά κράτησης εδώ...",
    placeholderBookingReference: "Αναφορά κράτησης",
    validationBookingReference: "Απαιτείται αναφορά κράτησης",
    validationEmailAsBookingReference: "Η διεύθυνση email δεν γίνεται αποδεκτή ως αναφορά κράτησης",
    buttonFindBooking: "Βρείτε Κράτηση",
    labelEmailHeading: "Ξεκινήσατε ήδη;",
    labelEmailText: "Στείλτε μια υπενθύμιση μέσω email για να ανακτήσετε την αίτησή σας.",
    placeholderEmail: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    buttonEmailReminder: "Υπενθύμιση email",
    labelCustomerName : "Όνομα πελάτη",
    validationCustomerName: "Απαιτείται όνομα πελάτη",
    labelTypeOfBooking: "Τύπος κράτησης",
    validationTypeOfBooking: "Απαιτείται τύπος κράτησης",
    labelEventName:"Όνομα συμβάντος",
    labelVenueName:"Όνομα Χώρου",
    labelFlightNumber:"Αριθμός πτήσης",
    labelOriginAirport: "Αεροδρόμιο προέλευσης",
    labelHotelName: "Όνομα Καταλύματος",
    labelHotelLocation: "Τοποθεσία Διαμονής",
    labelCurrency: "Νόμισμα",
    validationCurrency: "Απαιτείται νόμισμα",
    validationCountryCurrency: "Το επιλεγμένο νόμισμα πρέπει να είναι το νόμισμα της χώρας καταγωγής σας",
    validationOfflinePayment: "Δεν μπορούμε να επεξεργαστούμε επιστροφές χρημάτων για την επιλογή νομίσματος και χώρας, επιλέξτε εναλλακτικές",
    labelBookingValue: "Τιμή κράτησης",
    validationBookingValue: "Απαιτείται αξία κράτησης",
    validationBookingValueAmount: "Πρέπει να είναι μεγαλύτερο από 0",
    labelDateOfPurchase: "Ημερομηνία αγοράς",
    validationDateOfPurchase: "Απαιτείται ημερομηνία αγοράς",
    labelEmailAddress: "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
    validationEmailAddress: "Απαιτείται διεύθυνση email",
    validationEmailAddressFormat: "Λανθασμένη μορφή email",
    labelConfirmEmailAddress: "Επιβεβαίωση διεύθυνσης email",
    validationConfirmEmailAddressFormat: "Λανθασμένη μορφή email",
    validationConfirmEmailAddress: "Απαιτείται επιβεβαίωση Διεύθυνση Email",
    validationConfirmEmailAddressMatch: "Οι διευθύνσεις email δεν ταιριάζουν",
    validationEmailDomain: "Αυτός ο πάροχος email δεν είναι αποδεκτός, χρησιμοποιήστε μια εναλλακτική",
    validationDateOfEvent: "Απαιτείται ημερομηνία εκδήλωσης",
    labelHomeCountry: "Πατρίδα",
    validationHomeCountry: "Απαιτείται χώρα προέλευσης",
    labelContactNumberCountryCode: "Αριθμός Επικοινωνίας Κωδικός χώρας",
    validationContactNumberCountryCode: "Απαιτείται κωδικός κλήσης χώρας",
    labelContactNumber: "Αριθμός επαφής",
    validationContactNumber: "Απαιτείται ο αριθμός επικοινωνίας",
    labelAddressLine1: "Διεύθυνση 1",
    labelAddressLine2: "Διεύθυνση Γραμμή 2",
    labelCity: "Πόλη",
    labelZipCode: "Ταχυδρομικός κώδικας",
    labelRegion: "Περιοχή",
    validationRegion: "Απαιτείται περιοχή",
    labelNumberOfTicketsToRefund: "Αριθμός εισιτηρίων για επιστροφή χρημάτων",
    validationNumberOfTicketsToRefund: "Απαιτείται αριθμός εισιτηρίων για επιστροφή χρημάτων",
    validationNumberOfTicketsToRefundValue: "Πρέπει να είναι μεγαλύτερο από 0",
    labelCostPerTicket: "Κόστος κάθε εισιτηρίου",
    validationCostPerTicket: "Απαιτείται κόστος ανά εισιτήριο",
    validationCostPerTicketValue: "Πρέπει να είναι μεγαλύτερο από 0",
    labelTotalRefundAmount: "Συνολικό ποσό επιστροφής χρημάτων",
    validationTotalRefundAmount: "Απαιτείται συνολικό ποσό επιστροφής χρημάτων",
    validationTotalRefundAmountValue: "Πρέπει να είναι μεγαλύτερο από 0",
    validationTotalRefundGreaterThanBookingValue: "Το συνολικό ποσό επιστροφής χρημάτων δεν μπορεί να είναι μεγαλύτερο από την παραπάνω αξία κράτησης",
    labelBookingConfirmationFiles: "Μεταφόρτωση επιβεβαίωσης κράτησης",
    validationBookingConfirmationFiles: "Επισυνάψτε τουλάχιστον ένα αρχείο ή κάντε κλικ στην επιλογή Αποθήκευση και επιστρέψτε αργότερα όταν έχετε τα έγγραφα",
    validationEvidenceFilesPopup: "Απαιτείται η αποστολή αποδεικτικών στοιχείων πριν από την υποβολή. Εάν δεν έχετε τα αποδεικτικά στοιχεία αυτήν τη στιγμή, αποθηκεύστε την αίτηση και επιστρέψτε μόλις την αποκτήσετε. Δεχόμαστε ηλεκτρονικά αντίγραφα, αντίγραφα σαρωτή και φωτογραφίες.",
    validationBookingConfirmationFilesPopup: "Απαιτείται η μεταφόρτωση της επιβεβαίωσης κράτησης πριν από την υποβολή. Εάν δεν έχετε την επιβεβαίωση κράτησης αυτή τη στιγμή, αποθηκεύστε την αίτηση και επιστρέψτε μόλις την έχετε. Δεχόμαστε ηλεκτρονικά αντίγραφα, αντίγραφα σαρωτή και φωτογραφίες.",
    alertUploadDocuments: "Ανεβάστε τη σχετική τεκμηρίωση που περιγράφεται λεπτομερώς στο email και, στη συνέχεια, προχωρήστε στο Βήμα 3.",
    labelRefundReasonCategory: "Αιτία επιστροφής χρημάτων",
    validationRefundReasonCategory: "Απαιτείται λόγος επιστροφής χρημάτων",
    labelDateOfIncident: "Ημερομηνία περιστατικού",
    labelAdditionalComments: "Πρόσθετα σχόλια",
    tooltipAdditionalComments: "Πρόσθετα σχόλια",
    validationAdditionalComments: "Απαιτούνται πρόσθετα σχόλια",
    labelUnableToNotifyWithinTimeLimit: "Δεν είναι δυνατή η ειδοποίηση εντός 60 ημερών από",
    labelReasonForNotificationOutsideTimeLimit: "Ειδοποίηση εκτός χρονικού ορίου Επεξήγηση",
    validationReasonForNotificationOutsideTimeLimit: "Απαιτείται λόγος για ειδοποίηση μετά από 60 ημέρες",
    labelRefundReasonFiles: "Ανεβάστε αποδεικτικά στοιχεία",
    validationRefundReasonFiles: "Επισυνάψτε τουλάχιστον ένα αρχείο ή κάντε κλικ στην επιλογή Αποθήκευση και επιστρέψτε αργότερα όταν έχετε τα έγγραφα",
    validationTextScript: "Πρέπει να ταιριάζει με τη λατινική γραφή",
    optionTypeOfBooking1: "Εκδήλωση",
    optionTypeOfBooking2: "Περιοδεία",
    optionTypeOfBooking3: "Πτήση",
    optionTypeOfBooking4: "Κατάλυμα",
    optionTypeOfBooking5: "ΜΕΤΑΦΟΡΑ",
    optionTypeOfBooking6: "Στάθμευση",
    optionTypeOfBooking7: "Άλλα ταξίδια",
    labelEventTypeDate: "Ημερομηνία ακόμηt",
    labelEventTypeTour: "Ημερομηνία σαςr",
    labelEventTypeFlight: "Ημερομηνία πτήσης",
    labelEventTypeCheckIn: "Ημερομηνία παραδίδω αποσκευές",
    labelEventTypeTransfer: "Ημερομηνία Μεταβίβασης",
    labelEventTypeParking: "Ημερομηνία Στάθμευσης",
    labelEventTypeOtherTravel: "Ημερομηνία ταξιδιού",
    optionRefundReason11: "Δυσμενείς Καιρικές συνθήκες",
    optionRefundReason10: "Ανάκληση Ενόπλων Δυνάμεων & Υπηρεσιών Έκτακτης Ανάγκης",
    optionRefundReason15: "Αλλαγές στις Ημερομηνίες Εξετάσεων",
    optionRefundReason18: "Κλήση Δικαστηρίου",
    optionRefundReason17: "Covid-19 Περιορισμοί ταξιδιών",
    optionRefundReason19: "Θετικό τεστ COVID-19",
    optionRefundReason20: "Η κράτηση ακυρώθηκε από τον χειριστή/διοργανωτή",
    optionRefundReason21: "Η κράτηση επαναπρογραμματίστηκε από τον χειριστή/διοργανωτή",
    optionRefundReason22: "Κράτηση σε λάθος ημερομηνία",
    optionRefundReason23: "Κράτηση λάθος εισιτηρίων",
    optionRefundReason24: "Κλείσατε πάρα πολλά εισιτήρια",
    optionRefundReason4: "Θάνατος",
    optionRefundReason16: "Επείγουσες Περιστάσεις",
    optionRefundReason14: "Κυβερνητική ταξιδιωτική απαγόρευση",
    optionRefundReason9: "Κατ' οίκον έκτακτης ανάγκης",
    optionRefundReason1: "Ασθένεια/Τραυματισμός/Ατύχημα",
    optionRefundReason8: "Υπηρεσία κριτικής επιτροπής",
    optionRefundReason7: "Μηχανική Βλάβη",
    optionRefundReason2: "Προϋπάρχουσα Ιατρική Κατάσταση",
    optionRefundReason3: "Επιπλοκή εγκυμοσύνης",
    optionRefundReason5: "Αποτυχία μέσων μαζικής μεταφοράς",
    optionRefundReason6: "Διακοπή πτήσης",
    optionRefundReason13: "Κλοπή εισιτηρίου(ων)",
    optionRefundReason12: "Μετακίνηση Εργασίας",
    optionRefundReason25: "Απολύσεις στο χώρο εργασίας",
    optionRefundReason26: "Επιλογή δοκιμής",
    optionRefundReasonConfirm1: "Όχι",
    optionRefundReasonConfirm2: "Ναι",
    optionRefundReasonArmedForces1: "Στρατός",
    optionRefundReasonArmedForces2: "Ναυτικό",
    optionRefundReasonArmedForces3: "Αεροπορία",
    optionRefundReasonArmedForces4: "Ειδικές Δυνάμεις",
    optionRefundReasonArmedForces5: "Πεζοναύτες",
    optionRefundReasonArmedForces6: "Φωτιά",
    optionRefundReasonArmedForces7: "Ασθενοφόρο",
    optionRefundReasonArmedForces8: "Γιατρός/Νοσοκόμα",
    optionRefundReasonArmedForces9: "Οδοντίατρος",
    optionRefundReasonArmedForces10: "Άλλος",
    optionRefundReasonCourtSummons1: "Ποινική Δίωξη",
    optionRefundReasonCourtSummons2: "Αστική Νομική Υπόθεση",
    optionRefundReasonCourtSummons3: "Μάρτυρας κατηγορίας",
    optionRefundReasonCourtSummons4: "Μάρτυρας σε αστική υπόθεση",
    optionRefundReasonCourtSummons5: "Ενάγων",
    optionRefundReasonCourtSummons6: "Εναγόμενος",
    optionRefundReasonCourtSummons7: "Άλλος",
    optionRefundReasonDeath1: "Σύζυγος",
    optionRefundReasonDeath2: "Σύζυγος",
    optionRefundReasonDeath3: "Ήχος",
    optionRefundReasonDeath4: "Κόρη",
    optionRefundReasonDeath5: "Πατέρας",
    optionRefundReasonDeath6: "Μητέρα",
    optionRefundReasonDeath7: "Αδερφός",
    optionRefundReasonDeath8: "Αδερφή",
    optionRefundReasonDeath9: "Παππούς",
    optionRefundReasonDeath10: "Γιαγιά",
    optionRefundReasonDeath11: "Άλλος",
    optionRefundReasonHomeEmergency1: "Φωτιά",
    optionRefundReasonHomeEmergency2: "Πλημμύρα",
    optionRefundReasonHomeEmergency3: "Ληστεία",
    optionRefundReasonHomeEmergency4: "Βανδαλισμός",
    optionRefundReasonHomeEmergency5: "Ενδοοικογενειακή κακοποίηση",
    optionRefundReasonHomeEmergency6: "Απάγω",
    optionRefundReasonHomeEmergency7: "Άλλος",
    optionRefundReasonCOVID19Person1: "Εγώ ο ίδιος",
    optionRefundReasonCOVID19Person2: "Ένα άλλο άτομο στην κράτηση",
    optionRefundReasonCOVID19Person3: "Ένα άτομο στο νοικοκυριό μου",
    optionRefundReasonCOVID19Person4: "Άλλος",
    optionRefundReasonCOVID19TestType1: "Δοκιμή PCR",
    optionRefundReasonCOVID19TestType2: "Δοκιμή RAT",
    optionRefundReasonCOVID19TestType3: "Δοκιμή πλευρικής ροής",
    optionRefundReasonCOVID19TestType4: "Αυτοδιαχειριζόταν τεστ",
    optionRefundReasonCOVID19TestType5: "Δεν υπάρχει τεστ, αλλά έχω συμπτώματα.",
    optionRefundReasonCOVID19TestType6: "Άλλος",
    optionRefundReasonBreakdown1: "Ανάλυση",
    optionRefundReasonBreakdown2: "Ατύχημα",
    optionRefundReasonBreakdown3: "Φωτιά",
    optionRefundReasonBreakdown4: "Κλοπή",
    optionRefundReasonPublicTransportType1: "Τρένο",
    optionRefundReasonPublicTransportType2: "Λεωφορείο",
    optionRefundReasonPublicTransportType3: "Τραμ",
    optionRefundReasonPublicTransportType4: "Βάρκα",
    optionRefundReasonDocumentType1: "Διαβατήριο",
    optionRefundReasonDocumentType2: "Άδεια ταξιδίου",
    optionRefundReasonDocumentType3: "Εθνική ταυτότητα",
    optionRefundReasonDocumentType4: "Εισιτήρια",
    optionRefundReasonWorkRelocationType1: "Προσωρινός",
    optionRefundReasonWorkRelocationType2: "Μόνιμος",
    optionRefundReasonWorkRelocationTemporary1: "0-3 μήνες",
    optionRefundReasonWorkRelocationTemporary2: "3-6 μήνες",
    optionRefundReasonWorkRelocationTemporary3: "Πάνω από 6 μήνες",
    optionRefundReasonBookingRelationshipType1: "Κάποιος στην κράτηση",
    optionRefundReasonBookingRelationshipType2: "Άμεσος συγγενής κάποιου στην κράτηση",
    optionEmploymentType1: "Πλήρης απασχόληση",
    optionEmploymentType2: "Μερική Απασχόληση",
    optionEmploymentType3: "Σύμβαση",
    optionEmploymentLengthType1: "0-1 ετών",
    optionEmploymentLengthType2: "1-2 χρόνια",
    optionEmploymentLengthType3: "Περισσότερα από 2 χρόνια",
    textRefundEvidenceHeading: "Παρέχετε τα ακόλουθα αποδεικτικά στοιχεία:",
    textRefundEvidence1: "Επιβεβαίωση αεροπορικής εταιρείας",
    textRefundEvidence2: "Τυχόν κατάλληλα αποδεικτικά στοιχεία",
    textRefundEvidence3: "Επιβεβαίωση κλήσης υπηρεσίας ανάλυσης",
    textRefundEvidence4: "Πιστοποιητικό θανάτου",
    textRefundEvidence5: "Ακύρωση επιβεβαίωσης από την αεροπορική εταιρεία",
    textRefundEvidence6: "Δελτίο Καιρού",
    textRefundEvidence7: "Αλληλογραφία από πράκτορα κρατήσεων που επιβεβαιώνει ότι δεν υπάρχει δυνατότητα επανάκρισης εισιτηρίων",
    textRefundEvidence8: "Επιστολή του Δικαστηρίου",
    textRefundEvidence9: "Ιατρικό Πιστοποιητικό / Σημείωμα Γιατρού",
    textRefundEvidenceDescription9: "Επιβεβαιώνοντας τα στοιχεία της ασθένειας ή του τραυματισμού, την ημερομηνία που πρωτοήραι, ότι σας εμποδίζει να παρευρεθείτε στην Κράτηση.",
    textRefundEvidence10: "Επιβεβαίωση επιστολής εργοδότη",
    textRefundEvidence11: "Αλληλογραφία ασφαλιστικών αξιών",
    textRefundEvidence12: "Επιστολή εργοδότη",
    textRefundEvidence13: "Επιβεβαίωση ιστοσελίδας της Εθνικής Κυβέρνησης",
    textRefundEvidence14: "Ειδοποίηση Αλλαγής Εξέτασης",
    textRefundEvidence15: "Αναφορά αστυνομίας",
    textRefundEvidence16: "Αναφορά της αστυνομίας και αριθμός αναφοράς",
    textRefundEvidence17: "Επιβεβαίωση παρόχου μεταφορών",
    textRefundEvidence18: "Προειδοποίηση ταξιδιού",
    textRefundEvidence20: "Πιστοποιητικό δοκιμής COVID-19",
    textRefundEvidence24: "Ανακοίνωση κυβερνητικής απομόνωσης",
    textRefundEvidence25: "Επιστολή από τον Διοικητή",
    textRefundEvidence26: "Επιστολή ασφαλιστών",
    textRefundEvidence27: "Απόδειξη διεύθυνσης",
    textRefundEvidence28: "Απόδειξη σχέσης με τον θανόντα",
    textRefundEvidence29: "Απόδειξη σχέσης με τον ζημιωθέν/άρρωστο",
    textRefundEvidence30: "Απόδειξη πληρωμής",
    tooltipBookingReference: "Για να προσδιορίσετε την αναφορά της κράτησής σας, ελέγξτε την επιβεβαίωση της κράτησής σας και δείτε την ενότητα σχετικά με την εκτεταμένη υπηρεσία μας.",
    tooltipCustomerName: "Όνομα και επώνυμο που χρησιμοποιήθηκε για την κράτηση.",
    tooltipTypeOfBooking: "Τύπος κράτησης που θέλετε να λάβετε επιστροφή χρημάτων.",
    tooltipEventName: "Το όνομα της εκδήλωσης.",
    tooltipVenueName: "Το όνομα του χώρου.",
    tooltipFlightNumber: "Ο αριθμός πτήσης.",
    tooltipOriginAirport: "Το αεροδρόμιο αναχώρησης.",
    tooltipHotelName: "Το όνομα του καταλύματος.",
    tooltipHotelLocation: "Η τοποθεσία του καταλύματος.",
    tooltipCurrency: "Το νόμισμα που χρησιμοποιήθηκε για την πληρωμή της κράτησης.",
    tooltipBookingValue: "Συνολική τιμή που πληρώσατε για την κράτηση.",
    tooltipDateOfPurchase: "Η ημερομηνία που έγινε η κράτηση.",
    tooltipEmailAddress: "Χρησιμοποιήστε μια έγκυρη διεύθυνση email. Χρησιμοποιούμε αυτό το email για αλληλογραφία κατά τη διάρκεια της αίτησης επιστροφής χρημάτων.",
    tooltipConfirmEmailAddress: "Χρησιμοποιήστε την ίδια διεύθυνση email όπως παραπάνω, αυτή είναι για επικύρωση.",
    tooltipHomeCountry: "Αυτό θα μας βοηθήσει να φέρουμε τις σωστές τραπεζικές πληροφορίες στο τελικό βήμα.",
    tooltipContactNumberCountryCode: "Αριθμός επικοινωνίας Κωδικός χώρας.",
    tooltipContactNumber: "Χρησιμοποιήστε έναν έγκυρο αριθμό. Το χρησιμοποιούμε για αλληλογραφία κατά την αίτηση επιστροφής χρημάτων.",
    tooltipAddressLine1: "Λάβετε υπόψη ότι η διεύθυνσή σας θα χρησιμοποιηθεί κατά την επικύρωση του τραπεζικού σας λογαριασμού.",
    tooltipAddressLine2: "Δεύτερη γραμμή διεύθυνσης.",
    tooltipCity: "Η πόλη σας, τα στοιχεία μπορούν να χρησιμοποιηθούν για την επικύρωση τραπεζικών στοιχείων.",
    tooltipZipCode: "Ταχυδρομικός Κώδικας / Ταχυδρομικός Κώδικας.",
    tooltipRegion: "Περιοχή της πόλης σας, τα στοιχεία μπορούν να χρησιμοποιηθούν για την επικύρωση τραπεζικών στοιχείων.",
    tooltipNumberOfTicketsToRefund: "Ο αριθμός των εισιτηρίων που θέλετε να επιστραφούν.",
    tooltipTotalRefundAmount: "Αυτό δεν μπορεί να είναι μεγαλύτερο από τη συνολική αξία κράτησης, δεν θα μπορείτε να υποβάλετε την αίτηση εάν το κάνει.",
    tooltipBookingConfirmationFiles: "Στιγμιότυπο οθόνης/συνημμένο της πλήρους επιβεβαίωσης της κράτησής σας που συνήθως βρίσκεται στα email σας.",
    tooltipRefundReasonCategory: "Επιλέξτε τον λόγο για την επιστροφή χρημάτων.",
    tooltipDateOfIncident: "Η ημερομηνία κατά την οποία συνέβη το περιστατικό που επηρέασε την κράτησή σας.",
    tooltipRefundExplanation: "Δώστε όσο το δυνατόν περισσότερες λεπτομέρειες που υποστηρίζουν την αξίωση επιστροφής χρημάτων.",
    tooltipUnableToNotifyWithinTimeLimit: "Δεν είναι δυνατή η ειδοποίηση εντός χρονικού ορίου 60 ημερών.",
    tooltipReasonForNotificationOutsideTimeLimit: "Εξήγηση εκτός χρονικού ορίου ειδοποίησης.",
    tooltipRefundReasonFiles: "Ανεβάστε οποιοδήποτε από τα απαιτούμενα έγγραφα.",
    tooltipEventTypeDate: "Ημερομηνία της πραγματικής εκδήλωσης / κράτησης.",
    tooltipEventTypeTour: "Ημερομηνία της πραγματικής ξενάγησης.",
    tooltipEventTypeFlight: "Ημερομηνία της πραγματικής πτήσης.",
    tooltipEventTypeCheckIn: "Ημερομηνία του πραγματικού παραδίδω αποσκευές.",
    tooltipEventTypeTransfer: "Ημερομηνία της πραγματικής μεταφοράς.",
    tooltipEventTypeParking: "Ημερομηνία της πραγματικής στάθμευσης.",
    tooltipEventTypeOtherTravel: "Ημερομηνία έναρξης του πραγματικού ταξιδιού."
};