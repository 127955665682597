import {
    googleMapsApiKey,
    googleMapsGeocodeApiUri
} from '../config';
import axios from 'axios';

export const getIsoCountryCode = async (lat:number, long:number): Promise<string> => {
    const requestUri = `${googleMapsGeocodeApiUri}?latlng=${lat},${long}&key=${googleMapsApiKey}`;
    const response = await axios.get(requestUri);
    const results = response.data.results;
    return response.status === 200 ? results[0]?.address_components.find((x: any) => x.types.includes('country') )?.short_name ?? '' : '';
}

export const getIsoCurrencyCode = async (countryCode:string): Promise<string> => {
    const requestUri = `/api/currency/${countryCode}`;
    const response = await axios.get<string>(requestUri);
    return response.status === 200 ? response.data : '';
}