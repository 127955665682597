import * as React from 'react';
import {
    DateInput,
    FileInput,
    SelectInput,
    TextInput
} from '../../formInputs';
import {
    OptionDeathOther,
    OptionYes
} from '../../utils/constants';
import {
    RefundReasonFilesSecondaryState,
    RefundReasonFilesState
} from '../../../state/activity';
import {useTranslation} from 'react-i18next';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../../Interfaces/IFormValues';
import {useFormContext} from '../../providers/FormProvider';
import {FormSectionProps} from './RefundReasonHandlerSection';
import {useDynamicEvidence} from '../../utils/helpers';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';

export const DeathSection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {
        clearFiles,
        isEvidenceRequired,
        previousRefundReasonFiles,
        previousRefundReasonFilesAdditional,
        setClearFiles
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingDeath')}
                declineWarning={t('bespokeQuestions:headingDeathDecline')}
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                defaultValue={0}
                label={t('bespokeQuestions:labelDeath1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                datasourceUrl={'/api/referencedata/familytypes'}
                prefixKey={'form:optionRefundReasonDeath'}
                tooltip={t('bespokeQuestions:tooltipDeath1')}
                displayLabelAsTitle={false}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelectText1)}
                placeholder={t('bespokeQuestions:labelDeathOther1')}
                label={t('bespokeQuestions:labelDeathOther1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelectText1)}
                hidden={formProps.values.refundReasonBespokeSelect1 !== OptionDeathOther}
                multiLine={false}
                maxLength={50}
                tooltip={t('bespokeQuestions:tooltipDeathOther1')}
                required
            />

            <DateInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeDate2)}
                label={t('bespokeQuestions:labelDeath2')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate2)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                tooltip={t('bespokeQuestions:tooltipDeath2')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                placeholder={t('bespokeQuestions:labelDeath3')}
                label={t('bespokeQuestions:labelDeath3')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                maxLength={50}
                tooltip={t('bespokeQuestions:tooltipDeath3')}
                required
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFiles)}
                label={useDynamicEvidence(false)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFiles}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesState}
                formProps={formProps}
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFilesSecondary)}
                label={useDynamicEvidence(true)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFilesSecondary)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFilesAdditional}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesSecondaryState}
                formProps={formProps}
            />
        </>
    );
}