export default {
    nonAutoApprovedLabelThankYou: "Täname teid tagasimaksetaotluse eest.",
    nonAutoApprovedLabelReview: "Meie meeskond vaatab teie taotluse järgmise kahe tööpäeva jooksul läbi ja võtab teiega ühendust vastusega.",
    nonAutoApprovedLabelExperience: "Kuidas meil läks?",
    nonAutoApprovedLabelExperienceIntroduction: "Oleksime väga tänulikud, kui võtaksite aega meie hindamiseks ja arvustuse jätmiseks, kuna hindame oma klientide arvamust.",
    feedbackExcellent: "Ülihea",
    feedbackGreat: "Suurepärane",
    feedbackAverage: "Keskmine",
    feedbackPoor: "Vaene",
    feedbackBad: "Paha",
    labelThankYouForFeedback: "Täname teid tagasiside eest.",
    autoApprovedMessage1: "Meil on hea meel",
    autoApprovedMessage2: "Kinnitada",
    autoApprovedMessage3: "Teie tagasimakse!",
    autoApprovedPayment1Day: "Teie makse on juba töödeldud ja see peaks olema teie kontol vaid 1 tööpäeva jooksul.",
    autoApprovedPayment2to4Days: "Teie makse on juba töödeldud ja peaks olema teie kontol vaid 2–4 tööpäeva jooksul.",
    autoApprovedPayment7to10Days: "Teie makse on juba töödeldud ja see peaks olema teie kontol vaid 7–10 tööpäeva jooksul.",
    autoApprovedLabelExperience: "Kuidas meil läks?",
    autoApprovedLabelExperienceIntroduction: "Oleksime väga tänulikud, kui võtaksite aega meie hindamiseks ja arvustuse jätmiseks, kuna hindame oma klientide arvamust.",
    modalLabelNegative: "Teie tagasiside on meile väga oluline, andke meile teada, kui soovite, et ka meie teiega ühendust võtaksime.",
    placeholderFeedback: "Tagasiside",
    messageFeedbackSubmitted: "Tagasiside esitatud",
    reviewInstructionMessage: "5 tärni on suurepärane, 4 tärni on suurepärane, 3 tärni on keskmine, 2 tärni on halb, 1 täht on halb.",
};