export default {
    dividerBankingDetails: "Банківські реквізити",
    dividerBookingDetails: "Деталі бронювання",
    labelSavedApplicationReference: "Посилання на збережену програму:",
    labelLanguage: "Мова",
    messageWarningBookingHeading1: "Бронювання скасовано:",
    messageWarningBookingHeading2: "Бронювання відкладено/перенесено:",
    messageWarningBookingBody1: "Якщо ваше бронювання було скасовано організатором або авіакомпанією, ця команда не зможе допомогти вам повернути кошти. Вам потрібно буде зв’язатися безпосередньо зі своєю командою обслуговування клієнтів; перегляньте своє підтвердження бронювання або веб-сайт для контактної інформації.",
    messageWarningBookingBody2: "Якщо ваше бронювання було відкладено, ваші розширені умови повернення коштів буде перенесено на нову дату. Якщо у вас є поважна причина для повернення коштів згідно з нашими умовами, заповніть форму, додавши правильні підтверджуючі докази.",
    messageWarningBookingBody3: "Будь ласка, зверніть увагу: ця послуга може надати відшкодування лише з причин, зазначених у нашому формулюванні, доступному у вашому електронному листі з підтвердженням бронювання.",
    messageWarningBookingBody4: "Якщо ви не можете здійснити бронювання через причини, що не входять до наших умов, ми не зможемо допомогти. Вам потрібно буде зв’язатися безпосередньо зі своєю командою обслуговування клієнтів, оскільки вони можуть допомогти; дивіться підтвердження бронювання або веб-сайт для контактної інформації.",
    linkWarningBooking: "тут",
    formSectionNavigatorStep1: "Крок 1",
    formSectionNavigatorStep2: "Крок 2",
    formSectionNavigatorStep3: "Крок 3",
    formSectionNavigatorStep1Text: "Контактна інформація та деталі бронювання",
    formSectionNavigatorStep2Text: "Причина відшкодування",
    formSectionNavigatorStep3Text: "Платіжні реквізити",
    messageBookingMatchedAndFound: "Якщо ваше посилання на бронювання пов’язане з існуючою програмою, ми надішлемо вам електронний лист із посиланням для доступу до вашої програми",
    messageBookingMatchedAndFoundCompleted: "Вашу заявку на відшкодування вже схвалено! Якщо у вас виникли проблеми з оплатою, зв’яжіться з нами:",
    applicationDeclinedMessage: "Ваша заявка була відхилена і зараз закрита.",
    messageBookingNotFound1: "Ми не можемо знайти бронювання з цим посиланням. Будь ласка, перевірте електронну пошту з підтвердженням бронювання та ввійдіть повторно.",
    messageBookingNotFound2: "Не хвилюйтеся, якщо ваш номер бронювання не працює, ви можете вибрати «Знайти бронювання» та ввести свої дані. Заповнюючи форму, будь ласка, завантажте копію електронного листа з підтвердженням бронювання, щоб допомогти нам знайти деталі вашого бронювання.",
    messageLocatingBooking: "Розміщення вашого бронювання...",
    messageLoadingBank: "Завантаження банківської інформації...",
    messageFailCurrencyLookup: "Помилка отримання валюти",
    messageSavingApplication: "Збереження вашої програми...",
    messageSavedApplication: "Заявку на повернення коштів успішно збережено",
    messageFailSavedApplication: "Не вдалося зберегти заявку на повернення коштів",
    messageSendingEmailReminder: "Обробка...",
    messageErrorLocationDetails: "Помилка отримання відомостей про місцезнаходження",
    messageFailFormSubmission: "Не вдалося надіслати форму",
    messageApplicationCannotBeFound: "Не вдалося знайти додаток",
    messageApplicationRetrievalError: "Помилка отримання програми",
    messageSubmittingApplication: "Подача заявки...",
    messageEventDateLimitError: "Причина повідомлення за межами терміну",
    messageAllTicketsAssigned: "Усі квитки віднесено до іншої програми повернення коштів.",
    selectPrefix: "Виберіть",
    selectPostfix: "",
    fileUpload: "натисніть тут, щоб завантажити",
    fileUploadAcceptedFilesMessage: "Ми приймаємо лише PDF-файли та формати зображень .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "Вибрано 0 файлів",
    fileUploadPreviouslyUploaded: "Раніше завантажено:",
    fileUploadSelectedUploaded: "Вибрані вже завантажено:",
    buttonNext: "Далі",
    buttonPrevious: "Попередній",
    buttonSave: "Зберегти",
    buttonCancel: "Скасувати",
    buttonSubmit: "Подати",
    buttonConfirm: "Підтвердьте",
    buttonYes: "Так",
    buttonNo: "Немає",
    buttonClose: "Закрити",
    linkPrivacyStatement: "Заява про конфіденційність",
    linkComplaintsPolicy: "Політика скарг",
    validationRequired: "вимагається",
    validationInvalid: "Недійсний",
    labelUploadPrefix: "Завантажити",
    labelUploadPostfix: "",
    labelChange: "Зміна",
    labelView: "Вид",
    browserNavigationMessage: "Незбережені зміни буде втрачено"
};