export default {
    messageQuestionService: "यह सेवा केवल उपयोग के लिए है यदि आपको आवेदन पत्र को पूरा करने में कोई समस्या हो रही है, तो हम यहां किसी अन्य प्रश्न का उत्तर नहीं दे सकते हैं।",
    labelName: "नाम",
    labelReference: "आरक्षण संदर्भ",
    labelPhone: "फ़ोन नंबर",
    labelEmail: "ईमेल",
    labelQuery: "आपका प्रश्न",
    labelAskQuestion: "हमें यहां एक प्रश्न भेजें...",
    buttonOpenModal: "सवाल",
    title: "प्रश्न पूछें",
    modalTitle: "4. प्रपत्र में कोई तकनीकी समस्या है?",
    toastSuccess: "हमें आपका प्रश्न मिल गया है और हम 24 घंटे के भीतर आपसे संपर्क करेंगे",
    toastError: "आपके अनुरोध में कुछ गलत हुआ, कृपया पुनः प्रयास करें",
    nameValidation:"नाम आवश्यक है",
    referenceValidation:"संदर्भ आवश्यक है",
    questionValidation: "आपका प्रश्न आवश्यक है"
}
