import * as React from 'react';
import Dialog, {
    DialogProps
} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

type AllowedWidths = 'xs' | 'sm' | 'md' | 'lg';

interface ModalHolderProps {
    content: JSX.Element;
    handleClose?: () => void;
    showModal: boolean;
    width: AllowedWidths;
}

export const ModalHolder = (props: ModalHolderProps) => {

    const {
        content,
        handleClose,
        showModal,
        width
    } = props;

    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(width);

    return (
        <Dialog open={showModal}
                disableEnforceFocus
                scroll='body'
                maxWidth={maxWidth}
                fullWidth
        >
            <DialogContent>
                {content}
            </DialogContent>
        </Dialog>
    );
}