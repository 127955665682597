export default {
    nonAutoApprovedLabelThankYou: "Geri Ödeme Başvurunuz için teşekkür ederiz.",
    nonAutoApprovedLabelReview: "Ekibimiz önümüzdeki iki iş günü içinde başvurunuzu inceleyecek ve size bir yanıtla geri dönecektir.",
    nonAutoApprovedLabelExperience: "Nasıl yaptık?",
    nonAutoApprovedLabelExperienceIntroduction: "Müşterilerimizin görüşlerine değer verdiğimiz için bizi derecelendirmek ve bir inceleme bırakmak için zaman ayırdığınız için gerçekten minnettarız.",
    feedbackExcellent: "Mükemmel",
    feedbackGreat: "Büyük",
    feedbackAverage: "Ortalama",
    feedbackPoor: "Fakir",
    feedbackBad: "Kötü",
    labelThankYouForFeedback: "Geri bildiriminiz için teşekkür ederiz.",
    autoApprovedMessage1: "Çok mutluyuz",
    autoApprovedMessage2: "Onaylamak",
    autoApprovedMessage3: "geri ödemeniz!",
    autoApprovedPayment1Day: "Ödemeniz zaten işleme alınmıştır ve yalnızca 1 iş günü içinde hesabınızda olmalıdır.",
    autoApprovedPayment2to4Days: "Ödemeniz zaten işleme alınmıştır ve yalnızca 2-4 iş günü içinde hesabınızda olmalıdır.",
    autoApprovedPayment7to10Days: "Ödemeniz zaten işleme alınmıştır ve yalnızca 7-10 iş günü içinde hesabınızda olmalıdır.",
    autoApprovedLabelExperience: "Nasıl yaptık?",
    autoApprovedLabelExperienceIntroduction: "Müşterilerimizin görüşlerine değer verdiğimiz için bizi derecelendirmek ve bir inceleme bırakmak için zaman ayırdığınız için gerçekten minnettarız.",
    modalLabelNegative: "Geri bildiriminiz bizim için gerçekten önemlidir, sizinle iletişime geçmemizi isterseniz bize bildirin.",
    placeholderFeedback: "Geri besleme",
    messageFeedbackSubmitted: "Geri bildirim gönderildi",
    reviewInstructionMessage: "5 yıldız mükemmel, 4 yıldız harika, 3 yıldız ortalama, 2 yıldız zayıf, 1 yıldız kötü."
}