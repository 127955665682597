export default {
    messageUnableToMakePaymentToCurrency: "Dessverre kan vi ikke hente bankdetaljer for den valgte valutaen. Vennligst sørg for at valuta og hjemland er valgt på",
    messageOnlyBankAccountsAccepted: "Merk: Vi kan bare foreta betalinger til bankkontoer på dette tidspunktet (kreditt- og debetkort godtas ikke)",
    labelAccountName: "Navn på kontoinnehaver",
    labelBankAccountName: "Fullt navn på kontoinnehaver",
    labelBankCardHolderName: "Navn på kortholder",
    validationBankAccountName: "Fullt navn på kontoinnehaver er påkrevd",
    labelBankCountry: "Bank Land",
    labelBankName: "Bankens navn",
    labelPaymentType: "Betalingstype",
    labelInstitutionNumber: "Institusjonsnummer",
    labelBankAddress: "Bankadresse",
    labelBankAddress2: "Bankadresse",
    labelBankCity: "Bank City",
    labelBankState: "Bankstat",
    labelBankAccountNumber: "Bankkontonummer",
    labelBankAccountNumberCBU: "Kontonummer CBU",
    labelBankAccountNumberCCI: "Kontonummer CCI",
    labelBankAccountNumberZAR: "Bankkontonummer (7–11 sifre)",
    labelCardNumber: "Kortnummer",
    labelUnionpay: "UnionPay-kortnummer",
    labelAlipay: "Alipay ID (mobilnummer eller e-post)",
    labelBankAccountType: "Kontotype",
    optionBankAccountTypeCURRENT: "Brukskonto",
    optionBankAccountTypeCHECKING: "Sjekker konto",
    optionBankAccountTypeSAVING: "Sparekonto",
    optionBankAccountTypeSAVINGS: "Sparekonto",
    optionBankAccountTypeCUENTA_VISTA: "Hovedkonto",
    optionBankAccountType1: "Sjekker konto",
    optionBankAccountType2: "Sparekonto",
    optionBankAccountType3: "Hovedkonto",
    optionBankAccountType4: "Vista-konto",
    optionBankAccountType5: "RUT-konto",
    labelSortCode: "Sorter kode",
    labelRoutingCode: "Ruting kode",
    labelBankRoutingCodeNationalID: "Bankrutingkode / nasjonal ID",
    labelBankTransitCodeSwift: "Transittnummer",
    labelCNAPSCode: "Rutingkode / CNAPS-kode (Kinas nasjonale kode for avansert betalingssystem)",
    labelABAFedwireRoutingCode: "ACH-rutingnummer",
    labelIFSCCode: "IFSC-kode",
    labelBankCode: "Bankkode",
    labelBIKCode: "BIK-kode",
    labelBranchCode: "Gren Kode",
    labelBranchCodeUniversal: "Universell 6-sifret grenkode",
    labelRemittanceLine3: "Mottakers kontaktnavn og telefonnummer",
    labelBeneficiaryContactNameandTelephoneNumber: "Mottakers kontaktnavn og telefonnummer",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Mottakers fødselsdato og fødested",
    labelRemittanceLine4: "Individuelt skattenummer CUIL (9-12 siffer)",
    labelCUILNumber: "Skatte-ID: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Skatte-ID: RFC: 13 sifre eller CURP 18 sifre",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuell skatte-ID, CPF, Cadastro de Pessoa (11–14 siffer), filialkode",
    labelCFPNumber: "CPF-nummer (11-14 siffer)",
    labelBranchNameorBranchCode7digits: "Grennavn eller grenkode (7 siffer)",
    labelCedulaJuridica910digitsTaxID: "Juridisk sertifikat (9-10 siffer skatte-ID)",
    labelTaxIDRUT89digits: "Skatte-ID RUT (8–9 siffer)",
    labelJob: "Jobbtittel",
    labelDateOfBirth: "Fødselsdato",
    labelNationality: "Nasjonalitet",
    labelDocumentType: "Dokumenttype",
    labelDocumentNumber: "Dokumentnummer",
    labelRutNumber: "RUT nummer (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (Skatte-ID: Individuell 8-11 siffer)",
    labelCIIndividualTaxIDBranchLocation: "CI (individuelt skatte-ID), filial",
    labelTaxIDRUCorCI: "Skatte-ID (RUC eller CI)",
    labelSortCodeHelp: "Hjelp?",
    labelBranchCodeHelp: "Hjelp?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Hjelp?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "RASK / BIC",
    labelBeneficiarySWIFTBICHelp: "Hjelp?",
    labelRoutingCodeBSB: "Rutingkode/BSB",
    labelBSB: "Bank Stat Filial (BSB)",
    labelSKNCode: "SKN-kode",
    labelAcceptanceFlights: "Jeg/vi godtar at hvis refusjonssøknaden er vellykket, vil bestillingen inkludert alle flyvninger og tilleggsprodukter bli kansellert.",
    labelAcceptanceTickets: "Jeg/vi godtar at hvis refusjonssøknaden er vellykket, vil bestillingen inkludert alle billetter og tilleggsprodukter bli kansellert.",
    labelTermsAndConditions1: "Jeg/vi har blitt enige om",
    labelTermsAndConditions2: "Regler og vilkår",
    labelFraudWarning1: "Jeg tror at fakta gitt i dette refusjonssøknadsskjemaet er ekte og sanne. Jeg forstår at falsk informasjon eller bevis kan betraktes som en kriminell handling og bli gitt videre til politiet eller andre rettshåndhevende organer for å forfølge.",
    labelFraudWarning2: "Der vi mistenker svindel i forhold til underlagsdokumentasjon, vil denne saken umiddelbart bli henvist til lokale politimyndigheter. Jeg godtar og forstår at mine opplysninger vil bli sendt til disse myndighetene dersom søknaden min blir ansett som uredelig, og det kan bli en kriminell etterforskning.",
    validationTermsAndConditions: "Vilkår og betingelser må være enige om",
    messageFailbankName: "Banknavn kan ikke være tomt",
    messageFailcustomerName: "Kundenavn kan ikke være tomt",
    messageFailbankAccountNumber: "Obligatorisk felt",
    messageFailbankAccountNumberFormat: "Må være IBAN-format",
    messageFailsortCode: "Obligatorisk felt",
    messageFailbankSwiftBic: "Mottaker SWIFT BIC kan ikke være tom",
    messageFailbankSwiftBicFormat: "Må være SWIFT-format",
    messageFailbeneficiaryAddress1: "Adresse 1 kan ikke være tom",
    messageFailbeneficiaryCity: "City kan ikke være tom",
    messageFailbeneficiaryPostCode: "Postnummeret kan ikke være tomt",
    messageFailremittanceLine3: "Obligatorisk felt",
    messageFailremittanceLine4: "Obligatorisk felt",
    modalHeading: "Bekreft detaljer",
    modalLabelPleaseConfirmYourDetails: "Bekreft opplysningene dine",
    modalValidationPleaseConfirm: "Vennligst bekreft",
    modalValidationMustMatch: "Må samsvare med forrige oppføring",
    tooltipBankAccountName: "Navnet ditt slik det vises på bankkontoen/kontoutskriften/bankkortet.",
    tooltipBankAccountNameRecipient: "Mottakerens for- og etternavn. Både fornavn og etternavn må ha mer enn ett tegn.",
    tooltipBankCountry: "Landet der banken din ligger.",
    tooltipBankName: "Navnet på banken din.",
    tooltipPaymentType: "Betalingstypen du trenger.",
    tooltipInstitutionNumber: "Dette er 3 sifre og finnes vanligvis på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAccountNumber: "Ditt individuelle kontonummer. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAccountNumberCBU: "Et 22-sifret nummer som brukes til å identifisere kontoen din. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAccountNumberCCI: "Et 20-sifret nummer som brukes til å identifisere kontoen din. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAccountNumberKRW: "Et 11–16-sifret nummer som brukes til å identifisere kontoen din. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCardNumber: "Kortnummeret ditt. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipUnionpay: "UnionPay-kortnummeret ditt.",
    tooltipAlipay: "Mobilnummeret eller e-postadressen din som er knyttet til Alipay-kontoen din.",
    tooltipBankAccountType: "Dette er enten kontroll eller sparing.",
    tooltipSortCode: "Dette identifiserer filialen av banken din der kontoen din ligger. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipRoutingCode: "Dette identifiserer filialen av banken din der kontoen din ligger. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBeneficiarySWIFTBIC: "Består av 8-11 tegn som brukes til å identifisere en bank i en internasjonal transaksjon. Vanligvis finnes på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBSB: "Et BSB-nummer (Bank State Branch) er en australsk sorteringskode som har 6 sifre. Vanligvis finnes på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipRoutingCodeBSB: "Dette identifiserer filialen av banken din der kontoen din ligger. Vanligvis finnes på din månedlige kontoutskrift eller bankkort, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipABAFedwireRoutingCode: "Et 9-sifret nummer som brukes til å identifisere banker i Amerika. Vanligvis finnes på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipIFSCCode: "Vanligvis kan denne koden finnes på din månedlige kontoutskrift eller på passeboken din, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipTermsAndConditions: "Ved å godta vilkårene bekrefter du at du har lest og godtar våre vilkår og betingelser.",
    tooltipIBAN: "Ditt internasjonale bankkontonummer, som du kan bruke når du foretar eller mottar internasjonale betalinger. Vanligvis finnes på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Mottakers kontaktnavn og telefonnummer.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Mottaker, fødselsdato og fødested.",
    tooltipBankRoutingCodeNationalID: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankTransitCodeSwift: "Dette er 5 sifre og kan vanligvis finnes på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAddress: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankAddress2: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankCity: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankState: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBranchName: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBankCode: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBIKCode: "9 sifre for å identifisere banken din.",
    tooltipBranchCode: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBranchCodeUniversal: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCNAPSCode: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipRemittanceLine4: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCUILNumber: "Dette er et 11-sifret tall, bestående av to sifre, bindestrek, ni sifre og en ettsifret kontrollsum.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCFPNumber: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipBranchNameorBranchCode7digits: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCedulaJuridica910digitsTaxID: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipTaxIDRUT89digits: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipJob: "Ditt nåværende yrke.",
    tooltipDateOfBirth: "Fødselsdatoen din.",
    tooltipNationality: "Din nasjonalitet.",
    tooltipDocumentType: "Dokumenttypen som kreves.",
    tooltipDocumentNumber: "Dette er dokumentnummeret som er knyttet til valg av dokumenttype.",
    tooltipRutNumber: "RUT-nummeret ditt (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipCIIndividualTaxIDBranchLocation: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    tooltipTaxIDRUCorCI: "Kan vanligvis bli funnet på din månedlige kontoutskrift, eller hvis du fortsatt er usikker, spør banken din.",
    errorCPFCode: "For øyeblikket aksepterer vi kun CPF som en PIX-nøkkel."
};