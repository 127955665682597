import * as React from 'react';
import {
    DateInput,
    FileInput,
    SelectInput,
    TextInput
} from "../../formInputs";
import {
    OptionWorkRelocationTemporary,
    OptionYes
} from "../../utils/constants";
import {useTranslation} from 'react-i18next';
import {nameof} from "ts-simple-nameof";
import {IFormValues} from "../../Interfaces/IFormValues";
import {useFormContext} from "../../providers/FormProvider";
import {FormSectionProps} from "./RefundReasonHandlerSection";
import {useDynamicEvidence} from "../../utils/helpers";
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';
import {RefundReasonFilesState} from '../../../state/activity';

export const WorkRelocationSection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {
        clearFiles,
        isEvidenceRequired,
        previousRefundReasonFiles,
        setClearFiles
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingWorkRelocation')}
                declineWarning={t('bespokeQuestions:headingWorkRelocationDecline')}
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                defaultValue={0}
                label={t('bespokeQuestions:labelWorkRelocation1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                datasourceUrl='/api/referencedata/worklocationtypes'
                prefixKey={'form:optionRefundReasonWorkRelocationType'}
                tooltip={t('bespokeQuestions:tooltipWorkRelocation1')}
                displayLabelAsTitle={false}
                required
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                defaultValue={0}
                label={t('bespokeQuestions:labelWorkRelocation2')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                hidden={formProps.values.refundReasonBespokeSelect1 !== OptionWorkRelocationTemporary}
                datasourceUrl='/api/referencedata/worklocationtemporarytypes'
                prefixKey={'form:optionRefundReasonWorkRelocationTemporary'}
                tooltip={t('bespokeQuestions:tooltipWorkRelocation2')}
                displayLabelAsTitle={false}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                placeholder={t('bespokeQuestions:labelWorkRelocation3')}
                label={t('bespokeQuestions:labelWorkRelocation3')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={true}
                maxLength={250}
                tooltip={t('bespokeQuestions:tooltipWorkRelocation3')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText4)}
                placeholder={t('bespokeQuestions:labelWorkRelocation4')}
                label={t('bespokeQuestions:labelWorkRelocation4')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText4)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={true}
                maxLength={250}
                tooltip={t('bespokeQuestions:tooltipWorkRelocation4')}
                required
            />

            <DateInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeDate5)}
                label={t('bespokeQuestions:labelWorkRelocation5')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate5)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                tooltip={t('bespokeQuestions:tooltipWorkRelocation5')}
                required
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFiles)}
                label={useDynamicEvidence(false)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFiles}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesState}
                formProps={formProps}
            />
        </>
    );
}