export default {
    headingAdverseWeather: "Вы испытываете серьезные неблагоприятные погодные условия, которые делают небезопасным поездку на мероприятие, и это было санкционировано государственным органом (например, местным органом власти, полицией и т. Д.). И у вас есть доказательства, чтобы доказать это?",
    headingAdverseWeatherDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    tooltipRefundReasonConfirm: "Да или нет",
    labelBookingCancelledByOperator: "Билеты были аннулированы Оператором/Организатором.",
    labelBookingRescheduledByOperator: "Дата была изменена Оператором/Организатором, и я не могу присутствовать на новых датах.",
    labelBookedWrongDate: "Я или участник бронирования допустил ошибку при покупке билетов и забронировал неправильную дату.",
    labelBookedWrongTickets: "Я или участник бронирования допустил ошибку при покупке билетов и забронировал неправильные билеты.",
    labelBookedTooManyTickets: "Я или участник бронирования допустил ошибку при покупке билетов и забронировал слишком много билетов.",
    labelCOVID19TravelRestrictions: "Я или участник бронирования не могу присутствовать из-за запрета на поездки из-за COVID-19.",
    labelADWrongSelection: "Возможно, вы выбрали неправильную причину для возврата средств. Пожалуйста, ознакомьтесь с раскрывающимся списком Причина возврата еще раз.",
    labelAdverseWeather1: "Почему неблагоприятная погода означала, что вы не смогли присутствовать на мероприятии?",
    tooltipAdverseWeather1: "Пожалуйста, подробнее",
    labelAdverseWeather2: "Дата инцидента",
    tooltipAdverseWeather2: "Пожалуйста, выберите",
    headingArmedForces: "Ваш работодатель просит вас, какие вооруженные силы или аварийные службы должны прийти на работу, когда вы изначально не планировали это делать, и это мешает вам присутствовать на мероприятии?",
    headingArmedForcesDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelArmedForces1: "В каких силах или аварийно-спасательной службе вы работаете?",
    tooltipArmedForces1: "Пожалуйста, выберите",
    labelArmedForcesOther1: "Пожалуйста, уточните",
    tooltipArmedForcesOther1: "Пожалуйста, уточните",
    labelArmedForces2: "Дата инцидента",
    tooltipArmedForces2: "Пожалуйста, выберите",
    headingChangesToExam: "Даты вашего экзамена были изменены вашей школой / колледжем и т. Д.? Это не неудачный экзамен, который вы должны пересдать?",
    headingChangesToExamDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelExam1: "Какова была первоначальная дата экзамена?",
    tooltipExam1: "Пожалуйста, выберите",
    labelExam2: "Какова новая дата экзамена?",
    tooltipExam2: "Пожалуйста, выберите",
    labelExam3: "Почему изменилась дата экзамена?",
    tooltipExam3: "Пожалуйста, введите детали",
    labelExam4: "Вы провалили предыдущий экзамен?",
    tooltipExam4: "Да или нет",
    headingCourtSummons: "Вас вызвали в суд в качестве свидетеля в те же даты, что и событие?",
    headingCourtSummonsDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelCourtSummons1: "С чем связана ваша повестка в суд?",
    tooltipCourtSummons1: "Пожалуйста, выберите",
    labelCourtSummonsOther1: "Пожалуйста, уточните",
    tooltipCourtSummonsOther1: "Пожалуйста, уточните",
    labelCourtSummons2: "Когда была выдана повестка в суд?",
    tooltipCourtSummons2: "Пожалуйста, выберите",
    headingCOVID19Test: "У меня, члена моей семьи или члена группы, посещающей мероприятие, был диагностирован COVID-19?",
    headingCOVID19TestDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelCOVID19Test1: "У кого COVID-19?",
    tooltipCOVID19Test1: "Пожалуйста, выберите",
    labelCOVID19TestOther1a: "Пожалуйста, уточните",
    labelCOVID19TestOther1b: "Укажите имя",
    tooltipCOVID19TestOther1a: "Пожалуйста, уточните",
    tooltipCOVID19TestOther1b: "Укажите имя",
    labelCOVID19Test2: "Какой тип теста на COVID-19 у вас был?",
    tooltipCOVID19Test2: "Пожалуйста, выберите",
    labelCOVID19TestOther2: "Пожалуйста, уточните",
    tooltipCOVID19TestOther2: "Пожалуйста, уточните",
    labelCOVID19Test3: "Какова была дата вашего теста на COVID-19?",
    tooltipCOVID19Test3: "Пожалуйста, выберите",
    headingDeath: "Умер ближайший член моей семьи, и это мешает мне присутствовать на мероприятии?",
    headingDeathDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelDeath1: "Кто из членов вашей семьи умер?",
    tooltipDeath1: "Пожалуйста, выберите",
    labelDeathOther1: "Пожалуйста, уточните",
    tooltipDeathOther1: "Пожалуйста, уточните",
    labelDeath2: "Когда была дата смерти?",
    tooltipDeath2: "Пожалуйста, выберите",
    labelDeath3: "Имя умершего",
    tooltipDeath3: "Пожалуйста, уточните",
    headingEmergency: "У меня была реальная чрезвычайная ситуация, которая находится вне моего контроля и непредвиденная, для которой я могу предоставить доказательства?",
    headingEmergencyDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelEmergency1: "Объясните, пожалуйста, тип чрезвычайной ситуации?",
    tooltipEmergency1: "Пожалуйста, подробнее",
    labelEmergency2: "Когда это произошло?",
    tooltipEmergency2: "Пожалуйста, выберите",
    labelEmergency3: "Почему эта ситуация мешает вам присутствовать на мероприятии?",
    tooltipEmergency3: "Пожалуйста, подробнее",
    labelEmergency4: "Есть ли у вас доказательства этой чрезвычайной ситуации?",
    tooltipEmergency4: "Да или нет",
    headingHomeEmergency: "У меня есть чрезвычайная ситуация в доме, которая является ограблением, наводнением, пожаром, вандализмом, домашним насилием, которое мешает мне посетить мероприятие?",
    headingHomeEmergencyDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelHomeEmergency1: "Что такое домашняя чрезвычайная ситуация?",
    tooltipHomeEmergency1: "Пожалуйста, выберите",
    labelHomeEmergencyOther1: "Пожалуйста, уточните",
    tooltipHomeEmergencyOther1: "Пожалуйста, уточните",
    labelHomeEmergency2: "Когда это произошло?",
    tooltipHomeEmergency2: "Пожалуйста, выберите",
    labelHomeEmergency3: "Почему это помешало вам посетить мероприятие?",
    tooltipHomeEmergency3: "Пожалуйста, подробнее",
    headingInjury: "У меня, члена моей семьи или члена группы, посещающей мероприятие, было диагностировано заболевание или травма, которая теперь мешает нам пойти на мероприятие?",
    headingInjuryDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelInjury1: "Какова природа болезни/травмы/несчастного случая?",
    tooltipInjury1: "Пожалуйста, подробнее",
    labelInjury2: "Когда это произошло?",
    tooltipInjury2: "Пожалуйста, выберите",
    labelInjury3: "Посещали ли Вы медицинское учреждение по поводу этого заболевания/травмы?",
    tooltipInjury3: "Да или нет",
    labelInjury4: "Было ли это ранее существовавшим заболеванием?",
    tooltipInjury4: "Да или нет",
    labelInjury5: "Когда это было впервые диагностировано?",
    tooltipInjury5: "Пожалуйста, выберите",
    labelInjury6: "Почему это означает, что вы не можете присутствовать на мероприятии?",
    tooltipInjury6: "Пожалуйста, подробнее",
    labelInjury7: "Кто перенес болезнь/травму?",
    tooltipInjury7: "Пожалуйста, выберите",
    headingJuryService: "Я или участник бронирования был вызван в Службу присяжных?",
    headingJuryServiceDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelJuryService1: "Когда вы были уведомлены о службе присяжных?",
    tooltipJuryService1: "Пожалуйста, выберите",
    headingMechanicalBreakdown: "Наш автомобиль получил механическую поломку или попал в аварию, и это мешает нам присутствовать на мероприятии?",
    headingMechanicalBreakdownDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelMechanicalBreakdown1: "Что случилось с автомобилем?",
    tooltipMechanicalBreakdown1: "Пожалуйста, выберите",
    labelMechanicalBreakdown2: "Когда это произошло?",
    tooltipMechanicalBreakdown2: "Пожалуйста, выберите",
    labelMechanicalBreakdown3: "Почему вы не смогли воспользоваться альтернативным транспортом?",
    tooltipMechanicalBreakdown3: "Пожалуйста, подробнее",
    headingPregnancyComplication: "У меня или у участника бронирования есть осложнения беременности, которые были диагностированы медицинским работником и, следовательно, препятствуют посещению мероприятия?",
    headingPregnancyComplicationDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelPregnancyComplication1: "Какова природа осложнения беременности?",
    tooltipPregnancyComplication1: "Пожалуйста, подробнее",
    labelPregnancyComplication2: "Когда это произошло?",
    tooltipPregnancyComplication2: "Пожалуйста, выберите",
    labelPregnancyComplication3: "Обращались ли вы за медицинской помощью?",
    tooltipPregnancyComplication3: "Да или нет",
    labelPregnancyComplication4: "Почему это означает, что вы не можете присутствовать на мероприятии?",
    tooltipPregnancyComplication4: "Пожалуйста, подробнее",
    headingPublicTransportFailure: "Способ общественного транспорта (поезд, автобус, трамвай, лодка и т. Д.) Неожиданно потерпел неудачу, что означало, что не было другого способа, которым я мог бы добраться до этого события?",
    headingPublicTransportFailureDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelPublicTransportFailure1: "Какой тип общественного транспорта вы планировали использовать?",
    tooltipPublicTransportFailure1: "Пожалуйста, выберите",
    labelPublicTransportFailure2: "Когда вы планировали путешествовать?",
    tooltipPublicTransportFailure2: "Пожалуйста, выберите",
    labelPublicTransportFailure3: "Что случилось?",
    tooltipPublicTransportFailure3: "Пожалуйста, подробнее",
    labelPublicTransportFailure4: "Почему вы не могли воспользоваться альтернативным транспортом?",
    tooltipPublicTransportFailure4: "Пожалуйста, подробнее",
    headingScheduledAirlineFailure: "Мой предварительно забронированный рейс неожиданно отменен, и я не смог найти подходящую альтернативу, что означает, что я не смог посетить мероприятие?",
    headingScheduledAirlineFailureDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelScheduledAirlineFailure1: "Название авиакомпании",
    tooltipScheduledAirlineFailure1: "Название авиакомпании",
    labelScheduledAirlineFailure2: "Номер рейса",
    tooltipScheduledAirlineFailure2: "Номер рейса",
    labelScheduledAirlineFailure3: "Путешествуя из",
    tooltipScheduledAirlineFailure3: "Путешествуя из",
    labelScheduledAirlineFailure4: "Путешествие в",
    tooltipScheduledAirlineFailure4: "Путешествие в",
    labelScheduledAirlineFailure5: "Дата инцидента?",
    tooltipScheduledAirlineFailure5: "Пожалуйста, выберите",
    labelScheduledAirlineFailure6: "Почему вы не смогли сесть на альтернативный рейс?",
    tooltipScheduledAirlineFailure6: "Пожалуйста, подробнее",
    headingTheftOfDocuments: "Мои документы были украдены, и не было возможности организовать замену (ы) в необходимые сроки, чтобы я не мог присутствовать на мероприятии",
    headingTheftOfDocumentsDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelTheftOfDocuments1: "Какой тип документа был украден?",
    tooltipTheftOfDocuments1: "Пожалуйста, выберите",
    labelTheftOfDocuments2: "Когда это произошло?",
    tooltipTheftOfDocuments2: "Пожалуйста, выберите",
    labelTheftOfDocuments3: "Вы пытались переоформить документы?",
    tooltipTheftOfDocuments3: "Да или нет",
    headingWorkRelocation: "Мой работодатель попросил меня изменить место моей работы по крайней мере на три месяца, и расстояние является значительным, что означает, что я теперь не могу присутствовать на мероприятии?",
    headingWorkRelocationDecline: "Вы не должны продолжать эту заявку, так как возврат, скорее всего, будет отклонен.",
    labelWorkRelocation1: "Это временное или постоянное перемещение вашей работы?",
    tooltipWorkRelocation1: "Пожалуйста, выберите",
    labelWorkRelocation2: "Как долго вы будете находиться по новому адресу?",
    tooltipWorkRelocation2: "Пожалуйста, подробнее",
    labelWorkRelocation3: "Каков ваш текущий рабочий адрес?",
    tooltipWorkRelocation3: "Пожалуйста, подробнее",
    labelWorkRelocation4: "Каков ваш новый рабочий адрес?",
    tooltipWorkRelocation4: "Пожалуйста, подробнее",
    labelWorkRelocation5: "Когда вы были уведомлены об этом переезде?",
    tooltipWorkRelocation5: "Пожалуйста, выберите",
    headingRedundancy: "Я или участник бронирования был уволен своим работодателем, что означает, что я больше не могу присутствовать на бронировании?",
    headingRedundancyDecline: "Вы не должны продолжать эту заявку, так как в возврате, скорее всего, будет отказано.",
    labelRedundancy1: "Тип занятости?",
    tooltipRedundancy1: "Пожалуйста, выберите",
    labelRedundancy2: "Как давно вы работаете в этой компании?",
    tooltipRedundancy2: "Пожалуйста, выберите",
    labelRedundancy3: "Было ли увольнение добровольным?",
    tooltipRedundancy3: "Пожалуйста, выберите",
    labelRedundancy4: "Когда вас уведомили об увольнении?",
    tooltipRedundancy4: "Пожалуйста, выберите",
    labelTestOption: "Выберите Да, чтобы продолжить.",
}