import * as React from 'react';
import {Box} from '@mui/material';

interface AsteriskProps {
    required: boolean;
}

export const Asterisk = (props: AsteriskProps) => {

    const {
        required
    } = props;

    return (
        <Box component='span' sx={{
            fontSize: '20px',
            pl: '5px',
            color: 'red'
        }}>
            {required ? '*' : null}
        </Box>
    );
}