export default {
    messageUnableToMakePaymentToCurrency: "很遺憾，我們無法檢索所選貨幣的銀行詳細信息。請確保貨幣和母國在",
    messageOnlyBankAccountsAccepted: "請注意：我們目前只能在銀行帳戶中付款（不接受信用卡和借記卡）",
    labelAccountName: "賬戶持有人姓名",
    labelBankAccountName: "賬戶持有人的全名",
    labelBankCardHolderName: "持卡人姓名",
    validationBankAccountName: "需要賬戶持有人的全名",
    labelBankCountry: "銀行國家/ 地區",
    labelBankName: "銀行名稱",
    labelPaymentType: "付款方式",
    labelInstitutionNumber: "機構編號",
    labelBankAddress: "銀行地址",
    labelBankAddress2: "銀行地址",
    labelBankCity: "銀行城市",
    labelBankState: "銀行狀態",
    labelBankAccountNumber: "銀行帳號",
    labelBankAccountNumberCBU: "帳號 CBU",
    labelBankAccountNumberCCI: "帳號 CCI",
    labelBankAccountNumberZAR: "銀行帳號（7-11 位數字）",
    labelCardNumber: "卡號",
    labelUnionpay: "銀聯卡號",
    labelAlipay: "支付寶ID（手機號或郵箱）",
    labelBankAccountType: "帳戶類型",
    optionBankAccountTypeCURRENT: "往來賬戶",
    optionBankAccountTypeCHECKING: "支票賬戶",
    optionBankAccountTypeSAVING: "儲蓄賬戶",
    optionBankAccountTypeSAVINGS: "儲蓄賬戶",
    optionBankAccountTypeCUENTA_VISTA: "主賬戶",
    optionBankAccountType1: "支票賬戶",
    optionBankAccountType2: "儲蓄賬戶",
    optionBankAccountType3: "主賬戶",
    optionBankAccountType4: "Vista 帳戶",
    optionBankAccountType5: "RUT賬戶",
    labelSortCode: "排序代碼",
    labelRoutingCode: "路由代碼",
    labelBankRoutingCodeNationalID: "銀行路由代碼 / 國家ID",
    labelBankTransitCodeSwift: "過境號碼",
    labelCNAPSCode: "路由代碼 / CNAPS代碼（中國國家高級支付系統代碼）",
    labelABAFedwireRoutingCode: "ACH 路由號碼",
    labelIFSCCode: "國際金融服務委員會代碼",
    labelBankCode: "銀行代碼",
    labelBIKCode: "代碼",
    labelBranchCode: "分支代碼",
    labelBranchCodeUniversal: "通用6位分行代碼",
    labelRemittanceLine3: "收款人姓名和電話號碼",
    labelBeneficiaryContactNameandTelephoneNumber: "受益人聯繫人姓名和電話號碼",
    labelBeneficiaryDateofBirthandPlaceofBirth: "受益人出生日期和出生地",
    labelRemittanceLine4: "個人稅號居里（9 - 12位數字）",
    labelCUILNumber: "稅號：CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "稅號RFC 13位或CURP 18位",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digits: "個人稅號CPF個人納稅人註冊（11-14位），分行代碼",
    labelCFPNumber: "CPF編號（11-14位）",
    labelBranchNameorBranchCode7digits: "分支名稱或分支代碼（7位）",
    labelCedulaJuridica910digitsTaxID: "法律證明書（9-10位數的稅號）",
    labelTaxIDRUT89digits: "稅務編號RUT（8-9位數）",
    labelJob: "職稱",
    labelDateOfBirth: "出生日期",
    labelNationality: "國籍",
    labelDocumentType: "文件類型",
    labelDocumentNumber: "文檔編號",
    labelRutNumber: "RUT 編號 (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "塞杜拉（稅號，個人8-11位數字）",
    labelCIIndividualTaxIDBranchLocation: "CI（個人稅號），分支機構位置",
    labelTaxIDRUCorCI: "稅號（RUC或CI）",
    labelSortCodeHelp: "Help?",
    labelBranchCodeHelp: "Help?",
    labelIBAN: "幫助？",
    labelIBANHelp: "Help?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "幫助？",
    labelRoutingCodeBSB: "路由代碼 / BSB",
    labelBSB: "銀行國家分行（BSB）",
    labelSKNCode: " 斯凱恩 代碼",
    labelAcceptanceFlights: "我/我們接受，如果退款申請成功，預訂包括所有航班和附屬產品將被取消。",
    labelAcceptanceTickets: "我/我們接受，如果退款申請成功，包括所有機票和附屬產品在內的預訂將被取消。",
    labelTermsAndConditions1: "我 / 我們已經同意",
    labelTermsAndConditions2: "條款和條件",
    labelFraudWarning1: "我相信本退款申請表中提供的事實是真實的。我了解任何虛假信息或證據都可能被視為犯罪行為，並被轉交給警方或其他執法機構追查。",
    labelFraudWarning2: "如果我們懷疑與證明文件有關的欺詐行為，此問題將立即提交給當地警察當局。我接受並理解如果我的申請被認為是欺詐性的並且可能成為刑事調查，我的詳細信息將被傳遞給這些當局。",
    validationTermsAndConditions: "條款和條件必須得到同意",
    messageFailbankName: "銀行名稱不能為空",
    messageFailcustomerName: "客戶名稱不能為空",
    messageFailbankAccountNumber: "必填字段",
    messageFailbankAccountNumberFormat: "必須為IBAN格式",
    messageFailsortCode: "必填字段",
    messageFailbankSwiftBic: "收款人的SWIFT BIC不能為空",
    messageFailbankSwiftBicFormat: "必須為SWIFT格式",
    messageFailbeneficiaryAddress1: "地址1不能為空",
    messageFailbeneficiaryCity: "城市不能為空",
    messageFailbeneficiaryPostCode: "郵政編碼不能為空",
    messageFailremittanceLine3: "必填字段",
    messageFailremittanceLine4: "必填字段",
    modalHeading: "確認詳情",
    modalLabelPleaseConfirmYourDetails: "請確認您的詳細信息",
    modalValidationPleaseConfirm: "請確認",
    modalValidationMustMatch: "必須匹配上一個條目",
    tooltipBankAccountName: "您在銀行帳戶/銀行對帳單/銀行卡上顯示的姓名。",
    tooltipBankAccountNameRecipient: "收件人的名字和姓氏。名字和姓氏都必須有多個字符。",
    tooltipBankCountry: "您的銀行所在的國家/地區。",
    tooltipBankName: "您的銀行名稱。",
    tooltipPaymentType: "您需要的付款類型。",
    tooltipInstitutionNumber: "這是 3 位數字，通常可以在您的月結單或銀行卡上找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipBankAccountNumber: "您的個人帳號。通常在您的月結單或銀行卡上找到，或者如果您仍然不確定，請諮詢您的銀行。",
    tooltipBankAccountNumberCBU: "用於識別您的帳戶的 22 位數字。通常可以在您的月結單或銀行卡上找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipBankAccountNumberCCI: "用於識別您的帳戶的 20 位數字。通常可以在您的月結單或銀行卡上找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipBankAccountNumberKRW: "用於標識您的帳戶的 11-16 位數字。通常可以在您的月結單或銀行卡上找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipCardNumber: "你的卡號。通常可以在您的月結單或銀行卡上找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipUnionpay: "您的銀聯卡號。",
    tooltipAlipay: "與您的支付寶帳戶關聯的手機號碼或電子郵件地址。",
    tooltipBankAccountType: "這是支票或儲蓄。",
    tooltipSortCode: "這標識了您的帳戶所在銀行的分支機構。通常在您的月結單或銀行卡上找到，或者如果您仍然不確定，請諮詢您的銀行。",
    tooltipRoutingCode: "這標識了您的帳戶所在銀行的分支機構。通常在您的月結單或銀行卡上找到，或者如果您仍然不確定，請諮詢您的銀行。",
    tooltipBeneficiarySWIFTBIC: "由8-11個字符組成，用於在國際交易中識別銀行。通常在您的月度對帳單上找到，或者如果您仍然不確定，請向您的銀行查詢。",
    tooltipBSB: "銀行州分行（BSB）編號是澳大利亞的郵政編碼，有6位數字。通常在您的月度對帳單上找到，或者如果您仍然不確定，請向您的銀行查詢。",
    tooltipRoutingCodeBSB: "這標識了您的帳戶所在銀行的分支機構。通常在您的月結單或銀行卡上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipABAFedwireRoutingCode: "9位數字，用於標識美國的銀行。通常在您的月度對帳單上找到，或者如果您仍然不確定，請向您的銀行查詢。",
    tooltipIFSCCode: "通常可以在您的月結單或存摺上找到此代碼，如果您仍然不確定，請諮詢您的銀行。",
    tooltipTermsAndConditions: "接受條款和條件，即表示您已經閱讀並同意我們的條款和條件。",
    tooltipIBAN: "您的國際銀行帳號，可在進行國際付款時使用。通常在您的月度對帳單上找到，或者如果您仍然不確定，請向您的銀行查詢。",
    tooltipBeneficiaryContactNameandTelephoneNumber: "收款人聯絡人姓名和電話號碼。",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "收款人的出生日期和出生地。",
    tooltipBankRoutingCodeNationalID: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBankTransitCodeSwift: "這是 5 位數字，通常可以在您的每月銀行對帳單中找到，如果您仍然不確定，請諮詢您的銀行。",
    tooltipBankAddress: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBankAddress2: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBankCity: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBankState: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBranchName: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBankCode: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBIKCode: "9 位數字來識別您的銀行。",
    tooltipBranchCode: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBranchCodeUniversal: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipCNAPSCode: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipRemittanceLine4: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipCUILNumber: "這是一個 11 位數字，由兩位數字、連字符、九位數字和一位數字校驗和組成。",
    tooltipTaxIDRFC13DigitsORCURP18digits: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipCFPNumber: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipBranchNameorBranchCode7digits: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipCedulaJuridica910digitsTaxID: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipTaxIDRUT89digits: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipJob: "你現在的職業。",
    tooltipDateOfBirth: "您的出生日期。",
    tooltipNationality: "你的國籍。",
    tooltipDocumentType: "所需的文檔類型。",
    tooltipDocumentNumber: "這是與您的單據類型選擇相關聯的單據編號。",
    tooltipRutNumber: "您的 RUT 編號 (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipCIIndividualTaxIDBranchLocation: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    tooltipTaxIDRUCorCI: "通常可以在您的每月銀行對帳單上找到，或者如果您仍然不確定，請詢問您的銀行。",
    errorCPFCode: "目前我們只接受 CPF 作為 PIX 金鑰。"
};