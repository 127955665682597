export default {
    headingAdverseWeather: "Αντιμετωπίζετε σοβαρές δυσμενείς καιρικές συνθήκες που καθιστούν επισφαλή τη μετακίνηση στην εκδήλωση και αυτό έχει εξουσιοδοτηθεί από κυβερνητικό φορέα (π.χ. Τοπική Αρχή, Αστυνομία κ.λπ.). Και έχεις αποδείξεις για να το αποδείξεις αυτό;",
    headingAdverseWeatherDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    tooltipRefundReasonConfirm: "Ναι ή όχι",
    labelBookingCancelledByOperator: "Τα εισιτήρια ακυρώθηκαν από τον Διαχειριστή/Διοργανωτή.",
    labelBookingRescheduledByOperator: "Η ημερομηνία άλλαξε από τον Χειριστή/Διοργανωτή και δεν μπορώ να παρευρεθώ στις νέες ημερομηνίες.",
    labelBookedWrongDate: "Εγώ ή ένα μέλος της κράτησης, έκανα λάθος κατά την αγορά των εισιτηρίων και κλείσαμε λάθος ημερομηνία.",
    labelBookedWrongTickets: "Εγώ ή ένα μέλος της κράτησης, έκανα λάθος κατά την αγορά των εισιτηρίων και κλείσαμε λάθος εισιτήρια.",
    labelBookedTooManyTickets: "Εγώ ή ένα μέλος της κράτησης, έκανα λάθος κατά την αγορά των εισιτηρίων και κλείσαμε πάρα πολλά εισιτήρια.",
    labelCOVID19TravelRestrictions: "Εγώ ή ένα μέλος της κράτησης δεν μπορούμε να παρευρεθούμε λόγω ταξιδιωτικής απαγόρευσης λόγω covid-19.",
    labelADWrongSelection: "Μπορεί να έχετε επιλέξει λάθος λόγο για επιστροφή χρημάτων. Ελέγξτε ξανά την αναπτυσσόμενη λίστα Λόγος επιστροφής χρημάτων.",
    labelAdverseWeather1: "Γιατί οι δυσμενείς καιρικές συνθήκες σήμαναν ότι δεν μπορούσατε να παρευρεθείτε στην εκδήλωση;",
    tooltipAdverseWeather1: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelAdverseWeather2: "Ημερομηνία συμβάντος",
    tooltipAdverseWeather2: "Επιλέξτε",
    headingArmedForces: "Σας ζητείται από τον Εργοδότη σας, ο οποίος είναι Ένοπλες Δυνάμεις ή Υπηρεσίες Έκτακτης Ανάγκης, να εργαστείτε όταν δεν είχατε αρχικά προγραμματιστεί να το κάνετε και αυτό σας εμποδίζει να παρευρεθείτε στην εκδήλωση;",
    headingArmedForcesDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelArmedForces1: "Από ποιες δυνάμεις ή υπηρεσία έκτακτης ανάγκης εργάζεστε;",
    tooltipArmedForces1: "Επιλέξτε",
    labelArmedForcesOther1: "Διευκρινίστε",
    tooltipArmedForcesOther1: "Διευκρινίστε",
    labelArmedForces2: "Ημερομηνία συμβάντος",
    tooltipArmedForces2: "Επιλέξτε",
    headingChangesToExam: "Οι ημερομηνίες της εξέτασής σας έχουν αλλάξει από το σχολείο / κολέγιό σας, κλπ; Αυτή δεν είναι μια αποτυχημένη εξέταση που πρέπει να ξανανοίξιμο;",
    headingChangesToExamDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelExam1: "Ποια ήταν η αρχική ημερομηνία εξέτασης;",
    tooltipExam1: "Επιλέξτε",
    labelExam2: "Ποια είναι η νέα ημερομηνία εξέτασης;",
    tooltipExam2: "Επιλέξτε",
    labelExam3: "Γιατί άλλαξε η ημερομηνία εξέτασης;",
    tooltipExam3: "Παρακαλώ εισάγετε τα στοιχεία",
    labelExam4: "Απέτυχες στην προηγούμενη εξέταση;",
    tooltipExam4: "Ναι ή όχι",
    headingCourtSummons: "Έχετε κληθεί να εμφανιστείτε στο δικαστήριο ως μάρτυρας στις ίδιες ημερομηνίες με το γεγονός;",
    headingCourtSummonsDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelCourtSummons1: "Ποιος είναι ο λόγος για την κλήτευση του δικαστηρίου σας;",
    tooltipCourtSummons1: "Επιλέξτε",
    labelCourtSummonsOther1: "Διευκρινίστε",
    tooltipCourtSummonsOther1: "Διευκρινίστε",
    labelCourtSummons2: "Πότε εκδόθηκε η δικαστική κλήτευση;",
    tooltipCourtSummons2: "Επιλέξτε",
    headingCOVID19Test: "Εγώ, ένα μέλος του νοικοκυριού μου ή ένα μέλος της ομάδας που παρευρίσκεται στην εκδήλωση, έχω διαγνωστεί με COVID-19;",
    headingCOVID19TestDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelCOVID19Test1: "Ποιος έχει COVID-19;",
    tooltipCOVID19Test1: "Επιλέξτε",
    labelCOVID19TestOther1a: "Διευκρινίστε",
    labelCOVID19TestOther1b: "Καθορισμός ονόματος",
    tooltipCOVID19TestOther1a: "Διευκρινίστε",
    tooltipCOVID19TestOther1b: "Καθορισμός ονόματος",
    labelCOVID19Test2: "Τι είδους τεστ COVID-19 είχατε;",
    tooltipCOVID19Test2: "Επιλέξτε",
    labelCOVID19TestOther2: "Διευκρινίστε",
    tooltipCOVID19TestOther2: "Διευκρινίστε",
    labelCOVID19Test3: "Ποια ήταν η ημερομηνία του τεστ COVID-19;",
    tooltipCOVID19Test3: "Επιλέξτε",
    headingDeath: "Υπήρξε θάνατος ενός άμεσου μέλους της οικογένειάς μου και αυτό με εμποδίζει να παρευρεθώ στην εκδήλωση;",
    headingDeathDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelDeath1: "Ποιο μέλος της οικογένειάς σου πέθανε;",
    tooltipDeath1: "Επιλέξτε",
    labelDeathOther1: "Διευκρινίστε",
    tooltipDeathOther1: "Διευκρινίστε",
    labelDeath2: "Πότε ήταν η ημερομηνία θανάτου;",
    tooltipDeath2: "Επιλέξτε",
    labelDeath3: "Όνομα του νεκρού",
    tooltipDeath3: "Διευκρινίστε",
    headingEmergency: "Είχα μια πραγματική κατάσταση έκτακτης ανάγκης που είναι εκτός του ελέγχου μου και απρόβλεπτη για την οποία μπορώ να παρέχω αποδεικτικά στοιχεία;",
    headingEmergencyDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelEmergency1: "Παρακαλώ εξηγήστε το είδος της έκτακτης ανάγκης;",
    tooltipEmergency1: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelEmergency2: "Πότε συνέβη αυτό;",
    tooltipEmergency2: "Επιλέξτε",
    labelEmergency3: "Γιατί αυτή η κατάσταση σε εμποδίζει να παρευρεθείς στην εκδήλωση;",
    tooltipEmergency3: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelEmergency4: "Έχετε αποδείξεις για την κατάσταση έκτακτης ανάγκης;",
    tooltipEmergency4: "Ναι ή όχι",
    headingHomeEmergency: "Έχω μια κατάσταση έκτακτης ανάγκης στο σπίτι που είναι ληστεία, πλημμύρα, πυρκαγιά, βανδαλισμός, ενδοοικογενειακή κακοποίηση που με εμποδίζει να παρευρεθώ στην εκδήλωση;",
    headingHomeEmergencyDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelHomeEmergency1: "Ποια είναι η κατάσταση έκτακτης ανάγκης στο σπίτι;",
    tooltipHomeEmergency1: "Επιλέξτε",
    labelHomeEmergencyOther1: "Διευκρινίστε",
    tooltipHomeEmergencyOther1: "Διευκρινίστε",
    labelHomeEmergency2: "Πότε συνέβη αυτό;",
    tooltipHomeEmergency2: "Επιλέξτε",
    labelHomeEmergency3: "Γιατί σε σταμάτησε να παρευρίσκεσαι στην εκδήλωση;",
    tooltipHomeEmergency3: "Παρακαλώ περιγράψτε λεπτομερώς",
    headingInjury: "Εγώ, ένα μέλος του νοικοκυριού μου ή ένα μέλος της ομάδας που παρευρίσκεται στην εκδήλωση, έχω διαγνωστεί με μια ιατρική πάθηση ή τραυματισμό που τώρα μας εμποδίζει να πάμε στην εκδήλωση;",
    headingInjuryDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelInjury1: "Ποια είναι η φύση της ασθένειας/τραυματισμού/ατυχήματος;",
    tooltipInjury1: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelInjury2: "Πότε συνέβη αυτό;",
    tooltipInjury2: "Επιλέξτε",
    labelInjury3: "Έχετε πάει σε ιατρική μονάδα για αυτήν την ασθένεια/τραυματισμό;",
    tooltipInjury3: "Ναι ή όχι",
    labelInjury4: "Ήταν προϋπάρχουσα ιατρική πάθηση;",
    tooltipInjury4: "Ναι ή όχι",
    labelInjury5: "Πότε διαγνώστηκε για πρώτη φορά αυτό;",
    tooltipInjury5: "Επιλέξτε",
    labelInjury6: "Γιατί αυτό σημαίνει ότι δεν μπορείτε να παρευρεθείτε στην εκδήλωση;",
    tooltipInjury6: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelInjury7: "Ποιος έχει υποστεί την ασθένεια/τραυματισμό;",
    tooltipInjury7: "Επιλέξτε",
    headingJuryService: "Εγώ ή ένα μέλος της κράτησης έχει κληθεί στην Υπηρεσία Ενόρκων;",
    headingJuryServiceDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelJuryService1: "Πότε ειδοποιήθηκες για την Υπηρεσία Ενόρκων;",
    tooltipJuryService1: "Επιλέξτε",
    headingMechanicalBreakdown: "Το όχημά μας είχε μηχανική βλάβη ή είχε ατύχημα και αυτό μας εμποδίζει να παρευρεθεί στην εκδήλωση;",
    headingMechanicalBreakdownDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelMechanicalBreakdown1: "Τι έγινε με το όχημα;",
    tooltipMechanicalBreakdown1: "Επιλέξτε",
    labelMechanicalBreakdown2: "Πότε συνέβη αυτό;",
    tooltipMechanicalBreakdown2: "Επιλέξτε",
    labelMechanicalBreakdown3: "Γιατί δεν μπορούσες να πάρεις εναλλακτική μεταφορά;",
    tooltipMechanicalBreakdown3: "Παρακαλώ περιγράψτε λεπτομερώς",
    headingPregnancyComplication: "Εγώ ή ένα μέλος της κράτησης έχει επιπλοκές εγκυμοσύνης που έχουν διαγνωστεί από έναν επαγγελματία υγείας και ως εκ τούτου εμποδίζουν τη συμμετοχή στην εκδήλωση;",
    headingPregnancyComplicationDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelPregnancyComplication1: "Ποια είναι η φύση της επιπλοκής εγκυμοσύνης;",
    tooltipPregnancyComplication1: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelPregnancyComplication2: "Πότε συνέβη αυτό;",
    tooltipPregnancyComplication2: "Επιλέξτε",
    labelPregnancyComplication3: "Ζήτησες ιατρική βοήθεια;",
    tooltipPregnancyComplication3: "Ναι ή όχι",
    labelPregnancyComplication4: "Γιατί αυτό σημαίνει ότι δεν μπορείτε να παρευρεθείτε στην εκδήλωση;",
    tooltipPregnancyComplication4: "Παρακαλώ περιγράψτε λεπτομερώς",
    headingPublicTransportFailure: "Η μέθοδος των δημόσιων συγκοινωνιών (τρένο, λεωφορείο, τραμ, σκάφος κ.λπ.) απέτυχε απροσδόκητα που σημαίνει ότι δεν υπήρχε άλλος τρόπος να ταξιδέψω σε αυτό το γεγονός;",
    headingPublicTransportFailureDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelPublicTransportFailure1: "Τι είδους Μέσα Μαζικής Μεταφοράς σχεδίαζατε να χρησιμοποιήσετε;",
    tooltipPublicTransportFailure1: "Επιλέξτε",
    labelPublicTransportFailure2: "Πότε σχεδίαζατε να ταξιδέψετε;",
    tooltipPublicTransportFailure2: "Επιλέξτε",
    labelPublicTransportFailure3: "Τι συνέβη;?",
    tooltipPublicTransportFailure3: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelPublicTransportFailure4: "Γιατί δεν μπορούσες να πάρεις εναλλακτική μεταφορά;",
    tooltipPublicTransportFailure4: "Παρακαλώ περιγράψτε λεπτομερώς",
    headingScheduledAirlineFailure: "Η προ-κράτηση πτήσης μου ακυρώθηκε απροσδόκητα και δεν μπόρεσα να βρω μια κατάλληλη εναλλακτική λύση που σημαίνει ότι δεν ήμουν σε θέση να παρευρεθώ στην εκδήλωση;",
    headingScheduledAirlineFailureDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelScheduledAirlineFailure1: "Ονοματεπώνυμο Αεροπορικής Εταιρείας",
    tooltipScheduledAirlineFailure1: "Ονοματεπώνυμο Αεροπορικής Εταιρείας",
    labelScheduledAirlineFailure2: "Αριθμός πτήσης",
    tooltipScheduledAirlineFailure2: "Αριθμός πτήσης",
    labelScheduledAirlineFailure3: "Ταξιδεύοντας από",
    tooltipScheduledAirlineFailure3: "Ταξιδεύοντας από",
    labelScheduledAirlineFailure4: "Ταξιδεύοντας προς",
    tooltipScheduledAirlineFailure4: "Ταξιδεύοντας προς",
    labelScheduledAirlineFailure5: "Ημερομηνία συμβάντος;",
    tooltipScheduledAirlineFailure5: "Επιλέξτε",
    labelScheduledAirlineFailure6: "Γιατί δεν μπόρεσες να πάρεις μια εναλλακτική πτήση;",
    tooltipScheduledAirlineFailure6: "Παρακαλώ περιγράψτε λεπτομερώς",
    headingTheftOfDocuments: "Τα έγγραφά μου εκλάπησαν και δεν ήταν δυνατό να κανονίσω την αντικατάσταση εντός του απαιτούμενου χρονικού πλαισίου, ώστε να μην μπορώ να παρευρεθώ στην εκδήλωση",
    headingTheftOfDocumentsDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelTheftOfDocuments1: "Τι είδους έγγραφο κλάπηκε;",
    tooltipTheftOfDocuments1: "Επιλέξτε",
    labelTheftOfDocuments2: "Πότε συνέβη αυτό;",
    tooltipTheftOfDocuments2: "Επιλέξτε",
    labelTheftOfDocuments3: "Προσπαθησες να εκδώσεις εκ νέου τα έγγραφα;",
    tooltipTheftOfDocuments3: "Ναι ή όχι",
    headingWorkRelocation: "Ο εργοδότης μου μου ζήτησε να αλλάξω την τοποθεσία της εργασίας μου για τουλάχιστον τρεις μήνες και η απόσταση είναι σημαντική πράγμα που σημαίνει ότι δεν μπορώ πλέον να παρευρεθώ στην εκδήλωση;",
    headingWorkRelocationDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelWorkRelocation1: "Πρόκειται για προσωρινή ή μόνιμη μετεγκατάσταση της εργασίας σας;",
    tooltipWorkRelocation1: "Επιλέξτε",
    labelWorkRelocation2: "Πόσο καιρό θα είσαι στη νέα διεύθυνση;",
    tooltipWorkRelocation2: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelWorkRelocation3: "Ποια είναι η τρέχουσα διεύθυνση εργασίας σας;",
    tooltipWorkRelocation3: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelWorkRelocation4: "Ποια είναι η νέα σας διεύθυνση εργασίας;",
    tooltipWorkRelocation4: "Παρακαλώ περιγράψτε λεπτομερώς",
    labelWorkRelocation5: "Πότε ειδοποιήθηκες γι' αυτή τη μετεγκατάσταση;",
    tooltipWorkRelocation5: "Επιλέξτε",
    headingRedundancy: "Εγώ ή ένα μέλος της κράτησης έχει απολυθεί από τον εργοδότη του, πράγμα που σημαίνει ότι δεν μπορώ πλέον να παρευρεθώ στην κράτηση;",
    headingRedundancyDecline: "Δεν πρέπει να προχωρήσετε με αυτήν την αίτηση, καθώς η επιστροφή χρημάτων πιθανότατα θα απορριφθεί.",
    labelRedundancy1: "Είδος απασχόλησης;",
    tooltipRedundancy1: "Παρακαλώ επιλέξτε",
    labelRedundancy2: "Πόσο καιρό εργάζεστε σε αυτήν την εταιρεία;",
    tooltipRedundancy2: "Παρακαλώ επιλέξτε",
    labelRedundancy3: "Ήταν εθελοντική η απόλυση;",
    tooltipRedundancy3: "Παρακαλώ επιλέξτε",
    labelRedundancy4: "Πότε ενημερωθήκατε για την απόλυσή σας;",
    tooltipRedundancy4: "Παρακαλώ επιλέξτε",
    labelTestOption: "Επιλέξτε Ναι για να συνεχίσετε",
}