export default {
    messageUnableToMakePaymentToCurrency: "残念ながら、選択した通貨の銀行の詳細を取得することはできません。通貨と母国が選択されていることを確認してください",
    messageOnlyBankAccountsAccepted: "注意：現時点では、銀行口座への支払いのみが可能です（クレジットカードとデビットカードはご利用いただけません）",
    labelAccountName: "口座名義",
    labelBankAccountName: "アカウント所有者のフルネーム",
    labelBankCardHolderName: "クレジットカード名義人氏名",
    validationBankAccountName: "アカウント所有者のフルネームが必要です",
    labelBankCountry: "銀行の国",
    labelBankName: "銀行名",
    labelPaymentType: "払いの種類",
    labelInstitutionNumber: "機関番号",
    labelBankAddress: "銀行の住所",
    labelBankAddress2: "銀行の住所",
    labelBankCity: "バンクシティ",
    labelBankState: "銀行の州",
    labelBankAccountNumber: "銀行の口座番号",
    labelBankAccountNumberCBU: "アカウント番号CBU",
    labelBankAccountNumberCCI: "アカウント番号CCI",
    labelBankAccountNumberZAR: "銀行口座番号 (7 ～ 11 桁)",
    labelCardNumber: "カード番号",
    labelUnionpay: "銀聯カード番号",
    labelAlipay: "Alipay ID（携帯電話番号またはEメール）",
    labelBankAccountType: "口座の種類",
    optionBankAccountTypeCURRENT: "当座預金",
    optionBankAccountTypeCHECKING: "当座預金口座",
    optionBankAccountTypeSAVING: "普通預金口座",
    optionBankAccountTypeSAVINGS: "普通預金口座",
    optionBankAccountTypeCUENTA_VISTA: "マスターアカウント",
    optionBankAccountType1: "当座預金口座",
    optionBankAccountType2: "普通預金口座",
    optionBankAccountType3: "マスターアカウント",
    optionBankAccountType4: "Vista アカウント",
    optionBankAccountType5: "RUTアカウント",
    labelSortCode: "ソートコード",
    labelRoutingCode: "ルーティングコード",
    labelBankRoutingCodeNationalID: "銀行のルーティングコード/国民ID",
    labelBankTransitCodeSwift: "トランジット番号",
    labelCNAPSCode: "ルーティングコード/ CNAPSコード（中国国家高額決済システムコード",
    labelABAFedwireRoutingCode: "ACH ルーティング番号",
    labelIFSCCode: "IFSCコード",
    labelBankCode: "金融機関コード",
    labelBIKCode: "BIKコード",
    labelBranchCode: "支店コード",
    labelBranchCodeUniversal: "ユニバーサル6桁の支店コード",
    labelRemittanceLine3: "受取人の連絡先の名前と電話番号",
    labelBeneficiaryContactNameandTelephoneNumber: "受取人の連絡先の名前と電話番号",
    labelBeneficiaryDateofBirthandPlaceofBirth: "受益者の生年月日と出生地",
    labelRemittanceLine4: "個人税番号CUIL（9〜12桁",
    labelCUILNumber: "納税者番号：CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "納税者番号RFC13桁またはCURP18桁",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "個人納税者番号CPFCadastrodePessoaFísica（11〜14桁）、支店コード",
    labelCFPNumber: "CPF番号（11〜14桁）",
    labelBranchNameorBranchCode7digits: "支店名または支店コード（7桁",
    labelCedulaJuridica910digitsTaxID: "セデュラ・ジュリディカ（9-10桁の納税者番号）",
    labelTaxIDRUT89digits: "納税者番号RUT（8〜9桁）",
    labelJob: "職名",
    labelDateOfBirth: "生年月日",
    labelNationality: "国籍",
    labelDocumentType: "ドキュメントタイプ",
    labelDocumentNumber: "書類番号",
    labelRutNumber: "RUT番号 (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "セドゥラ（納税者番号個別8〜11桁）",
    labelCIIndividualTaxIDBranchLocation: "CI（個人納税者番号）、支店の場所",
    labelTaxIDRUCorCI: "納税者番号（RUCまたはCI）",
    labelSortCodeHelp: "助けて？",
    labelBranchCodeHelp: "助けて？",
    labelIBAN: "IBAN",
    labelIBANHelp: "助けて？",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "助けて？",
    labelRoutingCodeBSB: "ルーティングコード/ BSB ",
    labelBSB: "バンクステートブランチ（BSB）",
    labelSKNCode: "SKNコード",
    labelAcceptanceFlights: "私/私たちは、払い戻し申請が成功した場合、すべてのフライトを含む予約と付属品がキャンセルされることに同意します。",
    labelAcceptanceTickets: "私/私たちは、払い戻し申請が成功した場合、すべてのチケットを含む予約、および付属品がキャンセルされることに同意します.",
    labelTermsAndConditions1: "私/私たちは同意しました",
    labelTermsAndConditions2: "規約と条件",
    labelFraudWarning1: "この返金申請書に記載されている事実は本物であり、真実であると私は信じています。私は、虚偽の情報や証拠が犯罪行為と見なされ、警察やその他の法執行機関に渡されて追跡される可能性があることを理解しています。",
    labelFraudWarning2: "裏付けとなる文書に関連して詐欺が疑われる場合、この問題は直ちに地元の警察当局に照会されます。私は、私の申請が不正であると見なされ、それが犯罪捜査になる可能性がある場合、私の詳細がこれらの当局に渡されることを受け入れ、理解します。",
    validationTermsAndConditions: "利用規約に同意する必要があります",
    messageFailbankName: "銀行名を空白にすることはできません",
    messageFailcustomerName: "顧客名を空白にすることはできません",
    messageFailbankAccountNumber: "必須フィールド",
    messageFailbankAccountNumberFormat: "IBAN形式である必要があります",
    messageFailsortCode: "必須フィールド",
    messageFailbankSwiftBic: "受取人のSWIFTBICを空白にすることはできません",
    messageFailbankSwiftBicFormat: "SWIFT形式である必要があります",
    messageFailbeneficiaryAddress1: "アドレス1を空白にすることはできません",
    messageFailbeneficiaryCity: "都市を空白にすることはできません",
    messageFailbeneficiaryPostCode: "郵便番号を空白にすることはできません",
    messageFailremittanceLine3: "必須フィールド",
    messageFailremittanceLine4: "必須フィールド",
    modalHeading: "銀行の詳細を確認する",
    modalLabelPleaseConfirmYourDetails: "銀行の詳細を確認して確認してください。",
    modalValidationPleaseConfirm: "確認してください",
    modalValidationMustMatch: "前のエントリと一致する必要があります",
    tooltipBankAccountName: "銀行口座/銀行取引明細書/銀行カードに記載されている名前。",
    tooltipBankAccountNameRecipient: "受信者の姓名。名と姓の両方に複数の文字が必要です。",
    tooltipBankCountry: "銀行が所在する国。",
    tooltipBankName: "あなたの銀行の名前。",
    tooltipPaymentType: "必要な支払いの種類。",
    tooltipInstitutionNumber: "これは3桁で、通常は毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAccountNumber: "個人の口座番号。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAccountNumberCBU: "アカウントを識別するために使用される22桁の番号。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAccountNumberCCI: "アカウントを識別するために使用される20桁の番号。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAccountNumberKRW: "アカウントを識別するために使用される11〜16桁の番号。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCardNumber: "あなたのカード番号。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipUnionpay: "銀聯カード番号。",
    tooltipAlipay: "Alipayアカウントに関連付けられている携帯電話番号またはメールアドレス。",
    tooltipBankAccountType: "これは、当座預金または貯蓄のいずれかです。",
    tooltipSortCode: "これにより、アカウントが保持されている銀行の支店が識別されます。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipRoutingCode: "これにより、アカウントが保持されている銀行の支店が識別されます。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBeneficiarySWIFTBIC: "国際取引で銀行を識別するために使用される8〜11文字で構成されます。通常、毎月の明細書に記載されていますが、それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBSB: "銀行番号支店（BSB）番号は、6桁のオーストラリアのソートコードです。通常、毎月の明細書に記載されていますが、それでもわからない場合は、銀行にお問い合わせください。",
    tooltipRoutingCodeBSB: "これにより、アカウントが保持されている銀行の支店が識別されます。通常、毎月の明細書または銀行カードに記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipABAFedwireRoutingCode: "アメリカの銀行を識別するために使用される9桁の番号。通常、毎月の明細書に記載されていますが、それでもわからない場合は、銀行にお問い合わせください。",
    tooltipIFSCCode: "通常、このコードは毎月の明細書または通帳に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipTermsAndConditions: "利用規約に同意することにより、利用規約を読み、同意したことになります。",
    tooltipIBAN: "国際銀行口座番号。国際決済を行ったり受け取ったりするときに使用できます。通常、毎月の明細書に記載されていますが、それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBeneficiaryContactNameandTelephoneNumber: "受取人の連絡先の名前と電話番号。",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "受益者の生年月日と出生地。",
    tooltipBankRoutingCodeNationalID: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankTransitCodeSwift: "これは5桁で、通常は毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAddress: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankAddress2: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankCity: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankState: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBranchName: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBankCode: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBIKCode: "銀行を識別するための9桁。",
    tooltipBranchCode: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBranchCodeUniversal: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCNAPSCode: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipRemittanceLine4: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCUILNumber: "これは11桁の数字で、2桁、ハイフン、9桁、および1桁のチェックサムで構成されます。",
    tooltipTaxIDRFC13DigitsORCURP18digits: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCFPNumber: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipBranchNameorBranchCode7digits: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCedulaJuridica910digitsTaxID: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipTaxIDRUT89digits: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipJob: "あなたの現在の職業。",
    tooltipDateOfBirth: "あなたの誕生日。",
    tooltipNationality: "あなたの国籍。",
    tooltipDocumentType: "必要なドキュメントタイプ。",
    tooltipDocumentNumber: "これは、選択したドキュメントタイプに関連付けられているドキュメント番号です。",
    tooltipRutNumber: "あなたのRUT番号 (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipCIIndividualTaxIDBranchLocation: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    tooltipTaxIDRUCorCI: "通常、毎月の銀行取引明細書に記載されています。それでもわからない場合は、銀行にお問い合わせください。",
    errorCPFCode: "現時点では、CPF のみを PIX キーとして受け入れます。"
};