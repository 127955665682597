export default {
    dividerBankingDetails: "Banking Details",
    dividerBookingDetails: "Booking Details",
    labelSavedApplicationReference: "Saved Application Ref:",
    labelLanguage: "Language",
    messageWarningBookingHeading1: "Booking Cancelled",
    messageWarningBookingHeading2: "Booking Postponed / Rescheduled",
    messageWarningBookingBody1: "If your booking has been cancelled by the organiser or airline, this team will not be able to assist you with a refund. You will need to contact your customer service team directly; see your booking confirmation or website for contact details.",
    messageWarningBookingBody2: "If your booking has been postponed, your extended refundable terms will carry over to the new date. If you have a valid reason for refund under our terms, please complete the form with the correct supporting evidence.",
    messageWarningBookingBody3: "Please note: This service can only provide refunds for the reasons included in our wording, available in your booking confirmation email.",
    messageWarningBookingBody4: "If you cannot make the booking due to reasons outside of our terms, we will not be able to assist. You will need to contact your customer service team directly as they may be able to help; see your booking confirmation or website for contact details.",
    linkWarningBooking: "here",
    formSectionNavigatorStep1: "Step 1",
    formSectionNavigatorStep2: "Step 2",
    formSectionNavigatorStep3: "Step 3",
    formSectionNavigatorStep1Text: "Contact & Booking Details",
    formSectionNavigatorStep1CfarText: "Contact & Booking Details",
    formSectionNavigatorStep2Text: "Refund Reason",
    formSectionNavigatorStep2CfarText: "Cancellation Reason",
    formSectionNavigatorStep3Text: "Payment Details",
    formSectionNavigatorStep3CfarText: "Payment Details",
    messageBookingMatchedAndFound: "If your booking reference is associated with an existing application we will send you an email containing a link to access your application",
    messageBookingMatchedAndFoundCompleted: "Your refund application has already been approved! If you have any issues with your payment please contact:",
    applicationDeclinedMessage: "Your application has been declined and is now closed.",
    messageBookingNotFound1: "We cannot find a booking with that reference. Please check your booking confirmation email and re-enter.",
    messageBookingNotFound2: "Don't worry, if your booking reference doesn't work you can select 'Find Booking' and enter your details. When completing the form please upload a copy of your booking confirmation email to help us locate your booking details.",
    messageLocatingBooking: "Locating your booking...",
    messageLoadingBank: "Loading bank information...",
    messageFailCurrencyLookup: "Error retrieving currency",
    messageSavingApplication: "Saving your application...",
    messageSavedApplication: "Refund Application successfully saved",
    messageFailSavedApplication: "Refund Application failed to save",
    messageSendingEmailReminder: "Processing...",
    messageErrorLocationDetails: "Error retrieving location details",
    messageFailFormSubmission: "Form Submission Failed",
    messageApplicationCannotBeFound: "Application could not be found",
    messageApplicationRetrievalError: "Error retrieving application",
    messageSubmittingApplication: "Submitting application...",
    messageEventDateLimitError: "Reason for notification outside time limit",
    messageAllTicketsAssigned: "All tickets have been assigned to another refund application.",
    selectPrefix: "Select",
    selectPostfix: "",
    fileUpload: "Click here to upload",
    fileUploadAcceptedFilesMessage: "We only accept PDFs and image formats of .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 Files Selected",
    fileUploadPreviouslyUploaded: "Previously Uploaded:",
    fileUploadSelectedUploaded: "The selected have already been uploaded:",
    buttonNext: "Next",
    buttonPrevious: "Previous",
    buttonSave: "Save",
    buttonCancel: "Cancel",
    buttonSubmit: "Submit",
    buttonConfirm: "Confirm",
    buttonYes: "Yes",
    buttonNo: "No",
    buttonClose: "Close",
    linkPrivacyStatement: "Privacy Statement",
    linkComplaintsPolicy: "Complaints Policy",
    validationRequired: "Required",
    validationInvalid: "Invalid",
    labelUploadPrefix: "Upload",
    labelUploadPostfix: "",
    labelChange: "Change",
    labelView: "View",
    browserNavigationMessage: "Unsaved changes will be lost",
    cfarDeclineHeader: "Refund Application Closed",
    cfarDeclineText: "Hi there! It looks like you're applying for a refund within {{hours}} hours of your ticket event date. Our Terms & Conditions state that all refunds should be submitted at least {{hours}} hours before the event date to be eligible for refunds."
};