import axios from 'axios'

export const getIpAddress = async () => {
    try {
        const response = await axios.get<IipAddress>(`https://api.ipify.org/?format=json`);
        if (response.status === 200) {
            return response.data
        }
        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
}