export default {
    labelSavedApplicationReference: " Zapisany numer aplikacji:",
    labelLanguage: "Język",
    messageWarningBookingHeading1: "Rezerwacja anulowana:",
    messageWarningBookingHeading2: "Rezerwacja przełożona / przełożona:",
    messageWarningBookingBody1: "Jeśli Twoja rezerwacja została anulowana przez organizatora lub linię lotniczą, zespół ten nie będzie w stanie pomóc Ci w uzyskaniu zwrotu pieniędzy. Będziesz musiał skontaktować się bezpośrednio z zespołem obsługi klienta; dane kontaktowe znajdują się w potwierdzeniu rezerwacji lub na stronie internetowej.",
    messageWarningBookingBody2: "Jeśli Twoja rezerwacja została przełożona, Twoje przedłużone warunki zwrotu zostaną przeniesione na nową datę. Jeśli masz ważny powód do zwrotu kosztów zgodnie z naszymi warunkami, wypełnij formularz, podając odpowiednie dowody.",
    messageWarningBookingBody3: "Uwaga: Ta usługa może zapewnić zwrot pieniędzy tylko z powodów określonych w naszej treści, dostępnej w wiadomości e-mail z potwierdzeniem rezerwacji.",
    messageWarningBookingBody4: "Jeśli nie możesz dokonać rezerwacji z przyczyn niezależnych od naszych warunków, nie będziemy w stanie pomóc. Będziesz musiał skontaktować się bezpośrednio z zespołem obsługi klienta, ponieważ może on być w stanie pomóc; zobacz potwierdzenie rezerwacji lub stronę internetową, aby uzyskać dane kontaktowe.",
    linkWarningBooking: "tutaj",
    formSectionNavigatorStep1: "Krok 1",
    formSectionNavigatorStep2: "Krok 2",
    formSectionNavigatorStep3: "Krok 3",
    formSectionNavigatorStep1Text: "Dane kontaktowe i rezerwacyjne",
    formSectionNavigatorStep2Text: "Powód zwrotu",
    formSectionNavigatorStep3Text: "Szczegóły płatności",
    messageBookingMatchedAndFound: "Jeśli numer rezerwacji jest powiązany z istniejącą aplikacją, wyślemy Ci wiadomość e-mail zawierającą link umożliwiający dostęp do Twojej aplikacji",
    messageBookingMatchedAndFoundCompleted: "Twój wniosek o zwrot pieniędzy został już zatwierdzony! W przypadku problemów z płatnością prosimy o kontakt:",
    applicationDeclinedMessage: "Twoje zgłoszenie zostało odrzucone i jest teraz zamknięte.",
    messageBookingNotFound1: "Nie możemy znaleźć rezerwacji z tym numerem referencyjnym. Sprawdź wiadomość e-mail z potwierdzeniem rezerwacji i wprowadź ponownie.",
    messageBookingNotFound2: "Nie martw się, jeśli Twój numer rezerwacji nie działa, możesz wybrać „Znajdź rezerwację” i wprowadzić swoje dane. Podczas wypełniania formularza prześlij kopię wiadomości e-mail z potwierdzeniem rezerwacji, aby pomóc nam zlokalizować szczegóły rezerwacji.",
    messageLocatingBooking: "Lokalizowanie rezerwacji ...",
    messageLoadingBank: "Ładowanie informacji bankowych ...",
    messageFailCurrencyLookup: "Błąd podczas pobierania waluty",
    messageSavingApplication: "Zapisywanie aplikacji ...",
    messageSavedApplication: "Wniosek o zwrot środków został pomyślnie zapisany",
    messageFailSavedApplication: "Nie udało się zapisać wniosku o zwrot środków",
    messageSendingEmailReminder: "Przetwarzanie...",
    messageErrorLocationDetails: "Błąd podczas pobierania szczegółów lokalizacji",
    messageFailFormSubmission: "Przesłanie formularza nie powiodło się",
    messageApplicationCannotBeFound: "Nie można znaleźć aplikacji",
    messageApplicationRetrievalError: "Błąd podczas pobierania aplikacji",
    messageSubmittingApplication: " Składanie wniosku ...",
    messageEventDateLimitError: "Powód powiadomienia poza terminem",
    messageAllTicketsAssigned: "Wszystkie bilety zostały przypisane do innego wniosku o zwrot.",
    selectPrefix: "Wybierz",
    selectPostfix: "",
    fileUpload: "kliknij tutaj, aby przesłać",
    fileUploadAcceptedFilesMessage: "Akceptujemy tylko pliki PDF i formaty obrazów .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 wybranych plików",
    fileUploadPreviouslyUploaded: "Poprzednio wgrane:",
    fileUploadSelectedUploaded: "Wybrane zostały już przesłane:",
    buttonNext: "Kolejny",
    buttonPrevious: "Poprzedni",
    buttonSave: "Zapisać",
    buttonCancel: "anulować",
    buttonSubmit: "Zatwierdź",
    buttonConfirm: "Potwierdzać",
    buttonYes: "Tak",
    buttonNo: "Nie",
    buttonClose: "Blisko",
    linkPrivacyStatement: "Prywatność Komunikat",
    linkComplaintsPolicy: "Polityka reklamacji",
    validationRequired: "Wymagany",
    validationInvalid: "Nieważny",
    labelUploadPrefix: "Wgrywać",
    labelUploadPostfix: "",
    labelChange: "Zmieniać",
    labelView: "Widok",
    browserNavigationMessage: "Niezapisane zmiany zostaną utracone"
};