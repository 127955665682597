export default {
    nonAutoApprovedLabelThankYou: "Obrigado por sua solicitação de reembolso.",
    nonAutoApprovedLabelReview: "Nossa equipe revisará sua aplicação nos próximos dois dias úteis e voltará para você com uma resposta.",
    nonAutoApprovedLabelExperience: "Como é que nós fizemos?",
    nonAutoApprovedLabelExperienceIntroduction: "Nós realmente apreciamos você ter tempo para nos classificar e deixar uma revisão como valorizamos a opinião de nossos clientes.",
    feedbackExcellent: "Excelente",
    feedbackGreat: "Legal",
    feedbackAverage: "Média",
    feedbackPoor: "Pobre",
    feedbackBad: "Mau",
    labelThankYouForFeedback: "Obrigado pelo seu feedback.",
    autoApprovedMessage1: "Estamos encantados em",
    autoApprovedMessage2: "Aprovar",
    autoApprovedMessage3: "seu reembolso!",
    autoApprovedPayment1Day: "Seu pagamento já foi processado e deve estar em sua conta em apenas 1 dia útil.",
    autoApprovedPayment2to4Days: "Seu pagamento já foi processado e deve estar em sua conta em apenas 2 a 4 dias úteis.",
    autoApprovedPayment7to10Days: "Seu pagamento já foi processado e deve estar em sua conta em apenas 7 a 10 dias úteis.",
    autoApprovedLabelExperience: "Como é que nós fizemos?",
    autoApprovedLabelExperienceIntroduction: "Nós realmente apreciamos você ter tempo para nos classificar e deixar uma revisão como valorizamos a opinião de nossos clientes.",
    modalLabelNegative: "Seu feedback é muito importante para nós, deixe-nos saber se você também gostaria que entremos em contato com você.",
    placeholderFeedback: "Realimentação",
    messageFeedbackSubmitted: "Feedback enviado",
    reviewInstructionMessage: "5 estrelas é excelente, 4 estrelas é grande, 3 estrelas é média, 2 estrelas é pobre, 1 estrela é ruim."
}