export default {
    messageUnableToMakePaymentToCurrency: "Desværre kan vi ikke hente bankoplysninger for den valgte valuta. Sørg for, at Valuta og Hjemland er valgt den",
    messageOnlyBankAccountsAccepted: "Bemærk: Vi kan kun foretage betalinger til bankkonti på dette tidspunkt (kredit- og betalingskort accepteres ikke)",
    labelAccountName: "Kontohavers navn",
    labelBankAccountName: "Kontoindehavers fulde navn",
    labelBankCardHolderName: "Kortholders navn",
    validationBankAccountName: "Fuldt navn på kontoholder er påkrævet",
    labelBankCountry: "Bankland",
    labelBankName: "Bank-navn",
    labelPaymentType: "Betalings type",
    labelInstitutionNumber: "Institutionsnummer",
    labelBankAddress: "Bank Adresse",
    labelBankAddress2: "Bank Adresse",
    labelBankCity: "Bank City",
    labelBankState: "Bankstat",
    labelBankAccountNumber: "Bankkontonnummer",
    labelBankAccountNumberCBU: "Kontonummer CBU",
    labelBankAccountNumberCCI: "Kontonummer CCI",
    labelBankAccountNumberZAR: "Bankkontonummer (7-11 cifre)",
    labelCardNumber: "Kortnummer",
    labelUnionpay: "UnionPay-kortnummer",
    labelAlipay: "Alipay ID (mobilnummer eller e-mail)",
    labelBankAccountType: "Kontotype",
    optionBankAccountTypeCURRENT: "Nuværende konto",
    optionBankAccountTypeCHECKING: "Checkkonto",
    optionBankAccountTypeSAVING: "Opsparingskonto",
    optionBankAccountTypeSAVINGS: "Opsparingskonto",
    optionBankAccountTypeCUENTA_VISTA: "Hovedkonto",
    optionBankAccountType1: "Checkkonto",
    optionBankAccountType2: "Opsparingskonto",
    optionBankAccountType3: "Hovedkonto",
    optionBankAccountType4: "Vista Konto",
    optionBankAccountType5: "RUT Konto",
    labelSortCode: "Sortr Kods",
    labelRoutingCode: "Rutekode",
    labelBankRoutingCodeNationalID: "Bankkomlægningskode/national id",
    labelBankTransitCodeSwift: "Transitnummer",
    labelCNAPSCode: "Rutekode / CNAPS-kode (Kina National Fremskreden Betaling System Kode)",
    labelIFSCCode: "IFSC-kode",
    labelABAFedwireRoutingCode: "ACH-routingnummer",
    labelBranchCode: "Filialkode",
    labelBankCode: "Bankkode",
    labelBIKCode: "BIK-kode",
    labelBranchCodeUniversal: "Universal 6-cifret filialkode",
    labelRemittanceLine3: "Modtagerens Kontaktnavn og telefonnummer",
    labelBeneficiaryContactNameandTelephoneNumber: "Modtagerens Kontaktnavn of telefonnummer",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Modtagerens fødselsdato of fødested ",
    labelRemittanceLine4: "Individuelt skattenummer CUIL (9-12 cifre)",
    labelCUILNumber: "Skatte-id: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Skatte-id RFC 13 cifre eller CURP 18 cifre",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuelt skate-ID CPF Cadastro de Pessoa Física (11-14cifre), Filialkode",
    labelCFPNumber: "CPF-nummer (11-14 cifre)",
    labelBranchNameorBranchCode7digits: "Filialnavn eller filialkode(7 cifre)",
    labelCedulaJuridica910digitsTaxID: "Juridisk attest (9-10 cifre moms-id)",
    labelTaxIDRUT89digits: "Skatte-ID RUT (8-9 cifre)",
    labelJob: "Jobtitel",
    labelDateOfBirth: "Fødselsdato",
    labelNationality: "Nationalitet",
    labelDocumentType: "Dokument type",
    labelDocumentNumber: "Dokument Nummer",
    labelRutNumber: "RUT-nummer (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "ID-kort (skatte-ID individuelle 8-11 cifre)",
    labelCIIndividualTaxIDBranchLocation: "CI (individuelt skatte-id), filial placering",
    labelTaxIDRUCorCI: "Skatte-ID (RUC eller CI)",
    labelSortCodeHelp: "Hjælp?",
    labelBranchCodeHelp: "Hjælp?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Hjælp?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Hjælp?",
    labelRoutingCodeBSB: "Rutekode/BSB",
    labelBSB: "Bank Statsfilial (BSB)",
    labelSKNCode: "SKN-kode",
    labelAcceptanceFlights: "Jeg/vi accepterer, at hvis refusionsansøgningen lykkes, annulleres reservationen inklusive alle flyvninger og tilhørende produkter.",
    labelAcceptanceTickets: "Jeg/vi accepterer, at hvis refusionsansøgningen lykkes, vil reservationen inklusive alle billetter og tilknyttede produkter blive annulleret.",
    labelTermsAndConditions1: "Jeg/vi har accepteret",
    labelTermsAndConditions2: "Vilkår og betingelser",
    labelFraudWarning1: "Jeg mener, at de fakta, der er angivet i denne refusionsansøgningsformular, er ægte og sande. Jeg forstår, at enhver falsk information eller bevis kan betragtes som en kriminel handling og blive videregivet til politiet eller andre retshåndhævende myndigheder for at forfølge dem.",
    labelFraudWarning2: "Hvis vi har mistanke om bedrageri i forbindelse med understøttende dokumentation, vil denne sag straks blive henvist til de lokale politimyndigheder. Jeg accepterer og forstår, at mine oplysninger vil blive videregivet til disse myndigheder, hvis min ansøgning anses for at være svigagtig, og det kan blive en kriminel efterforskning.",
    validationTermsAndConditions: "Vilkår og betingelser skal accepteres",
    messageFailbankName: "Banknavn kan ikke være tomt",
    messageFailcustomerName: "Kundens navn kan ikke være tomt",
    messageFailbankAccountNumber: "Påkrævet område",
    messageFailbankAccountNumberFormat: "Skal være i IBAN-format",
    messageFailsortCode: "Påkrævet område",
    messageFailbankSwiftBic: "Modtager SWIFT BIC kan ikke være tom",
    messageFailbankSwiftBicFormat: "Skal være SWIFT-Format",
    messageFailbeneficiaryAddress1: "Adresse 1 kan ikke være tom",
    messageFailbeneficiaryCity: "Byen van ikke være tom",
    messageFailbeneficiaryPostCode: "Postnummer van ikke være tomt",
    messageFailremittanceLine3: "Påkrævet område",
    messageFailremittanceLine4: "Påkrævet område ",
    modalHeading: "Bekræft detaljer",
    modalLabelPleaseConfirmYourDetails: "Bekræft dine oplysninger",
    modalValidationPleaseConfirm: "Bekræft venligst",
    modalValidationMustMatch: "Skal matche tildligere post",
    tooltipBankAccountName: "Dit navn, som det vises på din bankkonto / kontoudtog / bankkort.",
    tooltipBankAccountNameRecipient: "Modtagerens for- og efternavn. Både for- og efternavn skal have mere end et tegn.",
    tooltipBankCountry: "Det land, hvor din bank er beliggende.",
    tooltipBankName: "Navnet på din bank.",
    tooltipPaymentType: "Den type betaling, du har brug for.",
    tooltipInstitutionNumber: "Dette er 3 cifre og findes normalt på din månedlige kontoudtog eller dit bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCardNumber: "Dit kortnummer. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipUnionpay: "Dit UnionPay-kortnummer.",
    tooltipAlipay: "Dit mobilnummer eller din e-mail-adresse, der er knyttet til din Alipay-konto.",
    tooltipBankAccountNumber: "Dit individuelle kontonummer. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAccountNumberCBU: "Et 22-cifret nummer, der bruges til at identificere din konto. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAccountNumberCCI: "Et 20-cifret nummer, der bruges til at identificere din konto. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAccountNumberKRW: "Et 11–16 cifret nummer, der bruges til at identificere din konto. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAccountType: "Dette er enten kontrol eller besparelse.",
    tooltipSortCode: "Dette identificerer den filial på din bank, hvor din konto opbevares. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipRoutingCode: "Dette identificerer den filial på din bank, hvor din konto opbevares. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBeneficiarySWIFTBIC: "Består af 8-11 tegn, der bruges til at identificere en bank i en international transaktion. Normalt findes på din månedlige opgørelse, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBSB: "Et Bank State Branch (BSB) -nummer er en australsk sorteringskode, der har 6 cifre. Normalt findes på din månedlige opgørelse, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipRoutingCodeBSB: "Dette identificerer den filial på din bank, hvor din konto opbevares. Normalt findes på dit månedlige kontoudtog eller bankkort, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipIFSCCode: "Normalt kan denne kode findes på dit månedlige kontoudtog eller i din adgangsbog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipABAFedwireRoutingCode: "Et 9-cifret nummer, der bruges til at identificere banker i Amerika. Normalt findes på din månedlige opgørelse, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipTermsAndConditions: "Ved at acceptere vilkårene og betingelserne bekræfter du, at du har læst og accepterer vores vilkår og betingelser.",
    tooltipIBAN: "Dit internationale bankkontonummer, som du kan bruge, når du foretager eller modtager internationale betalinger. Normalt findes på din månedlige opgørelse, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "kontaktpersonens navn og telefonnummer.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Modtagerens fødselsdato og fødested.",
    tooltipBankRoutingCodeNationalID: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankTransitCodeSwift: "Dette er 5 cifre og kan normalt findes på din månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAddress: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankAddress2: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankCity: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankState: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBranchName: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBranchCode: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBankCode: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBIKCode: "9 cifre til at identificere din bank.",
    tooltipBranchCodeUniversal: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCNAPSCode: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipRemittanceLine4: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCUILNumber: "Dette er et 11-cifret nummer bestående af to cifre, bindestreg, ni cifre og et kontrolsum med et cifre.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCFPNumber: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipBranchNameorBranchCode7digits: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCedulaJuridica910digitsTaxID: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipTaxIDRUT89digits: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipJob: "Din nuværende beskæftigelse.",
    tooltipDateOfBirth: "Din fødselsdag.",
    tooltipNationality: "Din nationalitet.",
    tooltipDocumentType: "Den krævede dokumenttype.",
    tooltipDocumentNumber: "Dette er dokumentnummeret, der er knyttet til dit dokumenttypevalg.",
    tooltipRutNumber: "Dit RUT-nummer (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipCIIndividualTaxIDBranchLocation: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    tooltipTaxIDRUCorCI: "Kan normalt findes på dit månedlige kontoudtog, eller hvis du stadig er usikker, så spørg din bank.",
    errorCPFCode: "I øjeblikket accepterer vi kun CPF som en PIX-nøgle."
};