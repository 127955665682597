export default {
    dividerBankingDetails: "Pankkitiedot",
    dividerBookingDetails: "Tilaustiedot",
    labelSavedApplicationReference: "Tallennetun hakemuksen viite:",
    labelLanguage: "Kieli",
    messageWarningBookingHeading1: "Varaus peruutettu:",
    messageWarningBookingHeading2: "Varaus siirretty / siirretty:",
    messageWarningBookingBody1: "Jos järjestäjä tai lentoyhtiö on peruuttanut varauksesi, tämä tiimi ei voi auttaa sinua hyvityksen kanssa. Sinun on otettava suoraan yhteyttä asiakaspalvelutiimiisi. Katso yhteystiedot varausvahvistuksestasi tai verkkosivuiltasi.",
    messageWarningBookingBody2: "Jos varaustasi on lykätty, pidennetyt hyvitettävät ehdot siirtyvät uuteen päivämäärään. Jos sinulla on pätevä syy hyvitykseen ehtojemme mukaisesti, täytä lomake oikealla todisteella.",
    messageWarningBookingBody3: "Huomaa: Tämä palvelu voi tarjota hyvityksiä vain syistä, jotka sisältyvät sanamuotoihimme, jotka ovat saatavilla varausvahvistuksessasi.",
    messageWarningBookingBody4: "Jos et voi tehdä varausta ehtojemme ulkopuolisista syistä, emme voi auttaa. Sinun on otettava suoraan yhteyttä asiakaspalvelutiimiisi, koska he voivat auttaa. Katso yhteystiedot varausvahvistuksestasi tai verkkosivuiltasi.",
    linkWarningBooking: "tästä",
    formSectionNavigatorStep1: "Vaihe 1",
    formSectionNavigatorStep2: "Vaihe 2",
    formSectionNavigatorStep3: "Vaihe 3",
    formSectionNavigatorStep1Text: "Yhteystiedot ja varaustiedot",
    formSectionNavigatorStep2Text: "Hyvityksen syy",
    formSectionNavigatorStep3Text: "Maksutiedot",
    messageBookingMatchedAndFound: "Jos varausviitesi liittyy olemassa olevaan hakemukseen, lähetämme sinulle sähköpostin, joka sisältää linkin hakemukseesi pääsemiseksi",
    messageBookingMatchedAndFoundCompleted: "Hyvityshakemuksesi on jo hyväksytty! Jos sinulla on ongelmia maksun kanssa, ota yhteyttä:",
    applicationDeclinedMessage: "Hakemuksesi on hylätty, ja se on nyt suljettu.",
    messageBookingNotFound1: "Emme löydä varausta tällä viitteellä. Tarkista varausvahvistussähköposti ja syötä uudelleen.",
    messageBookingNotFound2: "Älä huoli, jos varausviittauksesi ei toimi, voit valita 'Etsi varaus' ja syöttää tietosi. Kun täytät lomaketta, lataa kopio varausvahvistussähköpostistasi, jotta voimme löytää varaustietosi.",
    messageLocatingBooking: "Varauksesi sijainti...",
    messageLoadingBank: "Ladataan pankkitietoja...",
    messageFailCurrencyLookup: "Virhe valuuttaa haettaessa",
    messageSavingApplication: "Tallennetaan sovellustasi...",
    messageSavedApplication: "Hyvityshakemus tallennettu onnistuneesti",
    messageFailSavedApplication: "Hyvityshakemuksen tallennus epäonnistui",
    messageSendingEmailReminder: "Käsitellään...",
    messageErrorLocationDetails: "Virhe haettaessa sijaintitietoja",
    messageFailFormSubmission: "Lomakkeen lähetys epäonnistui",
    messageApplicationCannotBeFound: "Sovellusta ei löydy",
    messageApplicationRetrievalError: "Virhe haettaessa sovellusta",
    messageSubmittingApplication: "Lähetetään hakemusta...",
    messageEventDateLimitError: "Ilmoituksen syy aikarajan ulkopuolella",
    messageAllTicketsAssigned: "Kaikki liput on liitetty toiseen palautussovellukseen.",
    selectPrefix: "Valitse",
    selectPostfix: "",
    fileUpload: "klikkaa tästä ladataksesi",
    fileUploadAcceptedFilesMessage: "Hyväksymme vain PDF-tiedostot ja kuvamuodot .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 tiedostoa valittu",
    fileUploadPreviouslyUploaded: "Aiemmin ladattu:",
    fileUploadSelectedUploaded: "Valitut on jo ladattu:",
    buttonNext: "Seuraava",
    buttonPrevious: "Edellinen",
    buttonSave: "Tallenna",
    buttonCancel: "Takaisin",
    buttonSubmit: "Lähetä",
    buttonConfirm: "Vahvista",
    buttonYes: "Joo",
    buttonNo: "Ei",
    buttonClose: "kiinni",
    linkPrivacyStatement: "Tietosuojalausunto",
    linkComplaintsPolicy: "Reklamaatiot",
    validationRequired: "Vaaditaan",
    validationInvalid: "Virheellinen",
    labelUploadPrefix: "Lataa",
    labelUploadPostfix: "",
    labelChange: "Muuta",
    labelView: "Näkymä",
    browserNavigationMessage: "Tallentamattomat muutokset menetetään"
};