import * as React from 'react';
import {
    Button,
    ThemeProvider
} from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {buttonTheme} from '../themes/ButtonThemeProvider';
import {ButtonTextTheme} from '../themes/ButtonTextTheme';

interface ReminderButtonProps {
    buttonText: string;
    disabled?: boolean;
    handleOnClick: () => void;
}

export const ReminderButton = (props: ReminderButtonProps) => {

    const {
        buttonText,
        disabled,
        handleOnClick
    } = props;

    return (
        <ThemeProvider theme={buttonTheme}>
            <Button
                disabled={disabled}
                startIcon={<NotificationsActiveOutlinedIcon fontSize='small'/>}
                onClick={handleOnClick}>
                <ButtonTextTheme buttonText={buttonText}/>
            </Button>
        </ThemeProvider>
    );
}