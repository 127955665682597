export default {
    nonAutoApprovedLabelThankYou: "ขอขอบคุณสําหรับการยื่นขอเงินคืนของคุณ",
    nonAutoApprovedLabelReview: "ทีมงานของเราจะตรวจสอบใบสมัครของคุณภายในสองวันทําการถัดไปและติดต่อกลับพร้อมการตอบกลับ",
    nonAutoApprovedLabelExperience: "เราทําได้อย่างไร?",
    nonAutoApprovedLabelExperienceIntroduction: "เราขอขอบคุณที่คุณสละเวลาให้คะแนนเราและแสดงความคิดเห็นเนื่องจากเราให้ความสําคัญกับความคิดเห็นของลูกค้าของเรา",
    feedbackExcellent: "ยอดเยี่ยม",
    feedbackGreat: "ใหญ่",
    feedbackAverage: "เฉลี่ย",
    feedbackPoor: "ยากจน",
    feedbackBad: "ไม่ดี",
    labelThankYouForFeedback: "ขอขอบคุณสําหรับความคิดเห็นของคุณ",
    autoApprovedMessage1: "เรารู้สึกยินดีเป็นอย่างยิ่งที่ได้",
    autoApprovedMessage2: "อนุมัติ",
    autoApprovedMessage3: "การคืนเงินของคุณ!",
    autoApprovedPayment1Day: "การชําระเงินของคุณได้รับการดําเนินการแล้วและควรอยู่ในบัญชีของคุณภายใน 1 วันทําการ",
    autoApprovedPayment2to4Days: "การชําระเงินของคุณได้รับการดําเนินการแล้วและควรอยู่ในบัญชีของคุณภายใน 2-4 วันทําการ",
    autoApprovedPayment7to10Days: "การชําระเงินของคุณได้รับการดําเนินการแล้วและควรอยู่ในบัญชีของคุณภายใน 7-10 วันทําการ",
    autoApprovedLabelExperience: "เราทําได้อย่างไร?",
    autoApprovedLabelExperienceIntroduction: "เราขอขอบคุณที่คุณสละเวลาให้คะแนนเราและแสดงความคิดเห็นเนื่องจากเราให้ความสําคัญกับความคิดเห็นของลูกค้าของเรา",
    modalLabelNegative: "ความคิดเห็นของคุณเป็นสิ่งสําคัญมากสําหรับเราแจ้งให้เราทราบหากคุณต้องการให้เราติดต่อคุณ",
    placeholderFeedback: "การตอบสนอง",
    messageFeedbackSubmitted: "ส่งความคิดเห็นแล้ว",
    reviewInstructionMessage: "5 ดาวยอดเยี่ยม 4 ดาวยอดเยี่ยม 3 ดาวคือค่าเฉลี่ย 2 ดาวไม่ดี 1 ดาวไม่ดี"
}