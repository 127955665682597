import * as React from "react";
import {Checkbox} from '@mui/material';
import {IProductItem} from "../Interfaces/IProductItem";

interface ProductModalCheckItemProps {
    checkedState: boolean[];
    disabled: boolean;
    disableTicketChange: boolean;
    handleOnChange: (index: number) => void;
    index: number;
    productItem: IProductItem;
}

export const ProductModalCheckItem: React.FC<ProductModalCheckItemProps> = (props: ProductModalCheckItemProps) => {

    const {
        checkedState,
        disabled,
        disableTicketChange,
        handleOnChange,
        index,
        productItem
    } = props;

    return (
        <ul>
            <li key={`li-${productItem.productId}`}>
                {!disableTicketChange &&
                    <Checkbox
                        sx={{
                            pt: 0,
                            pl: 0,
                            pb: '5px'
                        }}
                        name={`product-checkbox-${productItem.productId}`}
                        disabled={disabled}
                        checked={checkedState[index] ?? false}
                        onChange={() => handleOnChange(index)}
                    />
                }
                {productItem.reference}
            </li>
        </ul>
    );
}