export default {
    messageUnableToMakePaymentToCurrency: "Rất tiếc, chúng tôi không thể truy xuất chi tiết ngân hàng cho đơn vị tiền tệ đã chọn. Hãy đảm bảo rằng Đơn vị tiền tệ và Quốc gia được chọn trên",
    messageOnlyBankAccountsAccepted: "Xin lưu ý: Chúng tôi chỉ có thể thực hiện thanh toán vào Tài khoản Ngân hàng tại thời điểm này (Không chấp nhận Thẻ tín dụng và Thẻ ghi nợ)",
    labelAccountName: "Tên Chủ Tài khoản",
    labelBankAccountName: "Tên đầy đủ của Chủ tài khoản",
    labelBankCardHolderName: "Tên chủ thẻ",
    validationBankAccountName: "Tên đầy đủ của Chủ tài khoản là bắt buộc",
    labelBankCountry: "Ngân hàng Quốc gia",
    labelBankName: "Tên Ngân hàng",
    labelPaymentType: "Hình thức thanh toán",
    labelInstitutionNumber: "Số tổ chức",
    labelBankAddress: "Địa chỉ Ngân hàng",
    labelBankAddress2: "Địa chỉ Ngân hàng",
    labelBankCity: "Thành phố Ngân hàng",
    labelBankState: "Ngân hàng Nhà nước",
    labelBankAccountNumber: "Số Tài khoản Ngân hàng",
    labelBankAccountNumberCBU: "Số tài khoản CBU",
    labelBankAccountNumberCCI: "Số tài khoản CCI",
    labelBankAccountNumberZAR: "Số tài khoản ngân hàng (7-11 chữ số)",
    labelCardNumber: "Số thẻ",
    labelUnionpay: "Số thẻ UnionPay",
    labelAlipay: "ID Alipay (số điện thoại di động hoặc email)",
    labelBankAccountType: "Kiểu tài khoản",
    optionBankAccountTypeCURRENT: "Tài khoản hiện tại",
    optionBankAccountTypeCHECKING: "Kiểm tra tài khoản",
    optionBankAccountTypeSAVING: "Tài khoản tiết kiệm",
    optionBankAccountTypeSAVINGS: "Tài khoản tiết kiệm",
    optionBankAccountTypeCUENTA_VISTA: "Tài khoản chủ",
    optionBankAccountType1: "Kiểm tra tài khoản",
    optionBankAccountType2: "Tài khoản tiết kiệm",
    optionBankAccountType3: "Tài khoản chủ",
    optionBankAccountType4: "Tài khoản Vista",
    optionBankAccountType5: "Tài khoản RUT",
    labelSortCode: "Mã sắp xếp",
    labelRoutingCode: "Mã định tuyến",
    labelBankRoutingCodeNationalID: "Mã định tuyến ngân hàng / ID quốc gia",
    labelBankTransitCodeSwift: "Mã số chuyển tiếp",
    labelCNAPSCode: "Mã định tuyến / Mã CNAPS (Mã hệ thống thanh toán nâng cao quốc gia Trung Quốc)",
    labelABAFedwireRoutingCode: "Số định tuyến ACH",
    labelIFSCCode: "Mã IFSC",
    labelBankCode: "Mã ngân hàng",
    labelBIKCode: "Mã BIK",
    labelBranchCode: "Mã Chi nhánh",
    labelBranchCodeUniversal: "Mã chi nhánh 6 chữ số chung",
    labelRemittanceLine3: "Tên liên hệ và số điện thoại của người thụ hưởng",
    labelBeneficiaryContactNameandTelephoneNumber: "Tên liên hệ và số điện thoại của người thụ hưởng",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Ngày sinh và nơi sinh của người thụ hưởng",
    labelRemittanceLine4: "Mã số thuế cá nhân CUIL (9-12 chữ số)",
    labelCUILNumber: "Id thuế: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Mã số thuế RFC 13 chữ số hoặc CURP 18 chữ số",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "ID thuế cá nhân CPF Cadastro de Pessoa Física (11-14 chữ số), Mã chi nhánh",
    labelCFPNumber: "Số CPF (11-14 chữ số)",
    labelBranchNameorBranchCode7digits: "Tên Chi nhánh hoặc Mã Chi nhánh (7 chữ số)",
    labelCedulaJuridica910digitsTaxID: "Giấy chứng nhận hợp pháp (Mã số thuế 9-10 chữ số)",
    labelTaxIDRUT89digits: "ID thuế RUT (8-9 chữ số)",
    labelJob: "Chức vụ",
    labelDateOfBirth: "Ngày sinh",
    labelNationality: "Quốc tịch",
    labelDocumentType: "Loại tài liệu",
    labelDocumentNumber: "Số văn bản",
    labelRutNumber: "Số RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (Mã số thuế Cá nhân 8-11 chữ số)",
    labelCIIndividualTaxIDBranchLocation: "CI (Mã số thuế cá nhân), Vị trí chi nhánh",
    labelTaxIDRUCorCI: "Mã số thuế (RUC hoặc CI)",
    labelSortCodeHelp: "Cứu giúp?",
    labelBranchCodeHelp: "Cứu giúp?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Cứu giúp?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Cứu giúp?",
    labelRoutingCodeBSB: "Mã định tuyến / BSB",
    labelBSB: "Ngân hàng Nhà nước Chi nhánh (BSB)",
    labelSKNCode: "Mã SKN",
    labelAcceptanceFlights: "Tôi/chúng tôi chấp nhận rằng nếu đơn xin hoàn tiền thành công, đặt chỗ bao gồm tất cả các chuyến bay và các sản phẩm phụ trợ sẽ bị hủy.",
    labelAcceptanceTickets: "Tôi/chúng tôi chấp nhận rằng nếu đơn xin hoàn trả thành công, đặt chỗ bao gồm tất cả vé và các sản phẩm phụ trợ sẽ bị hủy.",
    labelTermsAndConditions1: "Tôi / chúng tôi đã đồng ý với",
    labelTermsAndConditions2: "Điều khoản và Điều kiện",
    labelFraudWarning1: "Tôi tin rằng các thông tin được cung cấp trong mẫu đơn xin hoàn lại tiền này là xác thực và đúng sự thật. Tôi hiểu rằng bất kỳ thông tin hoặc bằng chứng sai lệch nào đều có thể bị coi là hành vi phạm tội và được chuyển cho cảnh sát hoặc các cơ quan thực thi pháp luật khác để theo đuổi.",
    labelFraudWarning2: "Nếu chúng tôi nghi ngờ gian lận liên quan đến tài liệu hỗ trợ, vấn đề này sẽ ngay lập tức được chuyển đến cơ quan cảnh sát địa phương. Tôi chấp nhận và hiểu rằng thông tin chi tiết của tôi sẽ được chuyển cho các cơ quan có thẩm quyền nếu đơn đăng ký của tôi bị coi là gian lận và điều đó có thể trở thành một cuộc điều tra hình sự.",
    validationTermsAndConditions: "",
    messageFailbankName: "Tên Ngân hàng không được để trống",
    messageFailcustomerName: "Tên Khách hàng không được để trống",
    messageFailbankAccountNumber: "Trường Bắt buộc",
    messageFailbankAccountNumberFormat: "Phải ở định dạng IBAN",
    messageFailsortCode: "Trường Bắt buộc",
    messageFailbankSwiftBic: "Không được để trống SWIFT BIC của người thụ hưởng",
    messageFailbankSwiftBicFormat: "Phải ở định dạng SWIFT",
    messageFailbenebeneficiaryAddress1: "Địa chỉ 1 không được để trống",
    messageFailbeneficiaryCity: "Thành phố không được để trống",
    messageFailbeneficiaryPostCode: "Mã Bưu điện không được để trống",
    messageFailremittanceLine3: "Trường bắt buộc",
    messageFailremittanceLine4: "Trường Bắt buộc",
    modalHeading: "Xác nhận Chi tiết Ngân hàng",
    modalLabelPleaseConfirmYourDetails: "Vui lòng kiểm tra chi tiết ngân hàng của bạn và xác nhận.",
    modalValidationPleaseConfirm: "Vui lòng xác nhận",
    modalValidationMustMatch: "Phải khớp với mục nhập trước đó",
    tooltipBankAccountName: "Tên của bạn xuất hiện trên tài khoản ngân hàng / bảng sao kê ngân hàng / thẻ ngân hàng của bạn.",
    tooltipBankAccountNameRecipient: "Họ và tên người nhận. Cả họ và tên phải có nhiều hơn một ký tự.",
    tooltipBankCountry: "Quốc gia mà ngân hàng của bạn đặt trụ sở.",
    tooltipBankName: "Tên ngân hàng của bạn.",
    tooltipPaymentType: "Hình thức thanh toán bạn yêu cầu.",
    tooltipInstitutionNumber: "Đây là 3 chữ số và thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAccountNumber: "Số tài khoản cá nhân của bạn. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAccountNumberCBU: "Một số gồm 22 chữ số được sử dụng để xác định tài khoản của bạn. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAccountNumberCCI: "Một số gồm 20 chữ số được sử dụng để xác định tài khoản của bạn. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAccountNumberKRW: "Một số có 11–16 chữ số được sử dụng để xác định tài khoản của bạn. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCardNumber: "Số thẻ của bạn. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipUnionpay: "Số thẻ UnionPay của bạn.",
    tooltipAlipay: "Số điện thoại di động hoặc địa chỉ email của bạn được liên kết với tài khoản Alipay của bạn.",
    tooltipBankAccountType: "Đây là Séc hoặc Tiết kiệm.",
    tooltipSortCode: "Điều này xác định chi nhánh ngân hàng của bạn nơi tài khoản của bạn được giữ. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipRoutingCode: "Điều này xác định chi nhánh ngân hàng của bạn nơi tài khoản của bạn được giữ. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBeneficiarySWIFTBIC: "Bao gồm 8-11 ký tự được sử dụng để xác định ngân hàng trong giao dịch quốc tế. Thường được tìm thấy trên bảng sao kê hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBSB: "Số của Chi nhánh Ngân hàng Nhà nước (BSB) là một mã phân loại của Úc có 6 chữ số. Thường được tìm thấy trên bảng sao kê hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipRoutingCodeBSB: "Điều này xác định chi nhánh ngân hàng của bạn nơi tài khoản của bạn được giữ. Thường được tìm thấy trên bảng sao kê hàng tháng hoặc thẻ ngân hàng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipABAFedwireRoutingCode: "Một số gồm 9 chữ số được sử dụng để xác định các ngân hàng ở Mỹ. Thường được tìm thấy trên bảng sao kê hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipIFSCCode: "Thông thường, bạn có thể tìm thấy mã này trên bảng sao kê hàng tháng hoặc trên sổ tiết kiệm của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipTermsAndConditions: "Bằng cách chấp nhận các điều khoản và điều kiện, bạn xác nhận rằng bạn đã đọc và đồng ý với Điều khoản và điều kiện của chúng tôi.",
    tooltipIBAN: "Số tài khoản ngân hàng quốc tế của bạn, mà bạn có thể sử dụng khi thực hiện hoặc nhận thanh toán quốc tế. Thường được tìm thấy trên bảng sao kê hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Tên liên lạc và số điện thoại của người thụ hưởng.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Người thụ hưởng Ngày sinh và Nơi sinh.",
    tooltipBankRoutingCodeNationalID: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankTransitCodeSwift: "Đây là 5 chữ số và thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAddress: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankAddress2: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankCity: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankState: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBranchName: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBankCode: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBIKCode: "9 chữ số để xác định ngân hàng của bạn.",
    tooltipBranchCode: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBranchCodeUniversal: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCNAPSCode: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipRemittanceLine4: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCUILNumber: "Đây là một số có 11 chữ số, bao gồm hai chữ số, gạch nối, chín chữ số và một tổng kiểm tra một chữ số.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCFPNumber: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipBranchNameorBranchCode7digits: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCedulaJuridica910digitsTaxID: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipTaxIDRUT89digits: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipJob: "Nghề nghiệp hiện tại của bạn.",
    tooltipDateOfBirth: "Ngày sinh của bạn.",
    tooltipNationality: "Quốc tịch của bạn.",
    tooltipDocumentType: "Loại tài liệu bắt buộc.",
    tooltipDocumentNumber: "Đây là số tài liệu được liên kết với lựa chọn loại tài liệu của bạn.",
    tooltipRutNumber: "Số RUT của bạn (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipCIIndividualTaxIDBranchLocation: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    tooltipTaxIDRUCorCI: "Thường có thể được tìm thấy trên bảng sao kê ngân hàng hàng tháng của bạn hoặc nếu bạn vẫn không chắc chắn, hãy hỏi ngân hàng của bạn.",
    errorCPFCode: "Hiện tại, chúng tôi chỉ chấp nhận CPF làm khóa PIX."
};