export default {
    messageUnableToMakePaymentToCurrency: "ขออภัย เราไม่สามารถเรียกข้อมูลรายละเอียดธนาคารสำหรับสกุลเงินที่เลือกได้ โปรดตรวจสอบให้แน่ใจว่าได้เลือกสกุลเงินและประเทศบ้านเกิดบน",
    messageOnlyBankAccountsAccepted: "โปรดทราบ: ในขณะนี้เราสามารถชำระเงินไปยังบัญชีธนาคารเท่านั้น (ไม่รับบัตรเครดิตและบัตรเดบิต)",
    labelAccountName: "ชื่อเจ้าของบัญชี",
    labelBankAccountName: "ชื่อเต็มของเจ้าของบัญชี",
    labelBankCardHolderName: "ชื่อผู้ถือบัตร",
    validationBankAccountName: "ต้องระบุชื่ต้องระบุชื่อเต็มของเจ้าของบัญชี",
    labelBankCountry: "ประเทศของธนาคาร",
    labelBankName: "ชื่อธนาคาร",
    labelPaymentType: "ประเภทการชำระเงิน",
    labelInstitutionNumber: "หมายเลขสถาบัน",
    labelBankAddress: "ที่อยู่ธนาคาร",
    labelBankAddress2: "ที่อยู่ธนาคาร",
    labelBankCity: " ธนาคารซิตี้",
    labelBankState: "รัฐธนาคาร",
    labelBankAccountNumber: "หมายเลขบัญชีธนาคาร",
    labelBankAccountNumberCBU: "เลขที่บัญชี CBU",
    labelBankAccountNumberCCI: "เลขที่บัญชี CCI",
    labelBankAccountNumberZAR: "หมายเลขบัญชีธนาคาร (7-11 หลัก)",
    labelCardNumber: "หมายเลขบัตร",
    labelUnionpay: "หมายเลขบัตร UnionPay",
    labelAlipay: "Alipay ID (หมายเลขโทรศัพท์มือถือหรืออีเมล)",
    labelBankAccountType: "ประเภทบัญชี",
    optionBankAccountTypeCURRENT: "บัญชีกระแสรายวัน",
    optionBankAccountTypeCHECKING: "ตรวจสอบบัญชี",
    optionBankAccountTypeSAVING: "บัญชีออมทรัพย์",
    optionBankAccountTypeSAVINGS: "บัญชีออมทรัพย์",
    optionBankAccountTypeCUENTA_VISTA: "บัญชีหลัก",
    optionBankAccountType1: "ตรวจสอบบัญชี",
    optionBankAccountType2: "บัญชีออมทรัพย์",
    optionBankAccountType3: "บัญชีหลัก",
    optionBankAccountType4: "บัญชี Vista",
    optionBankAccountType5: "บัญชี RUT",
    labelSortCode: "รหัสเรียงลำดับ",
    labelRoutingCode: "รหัสเส้นทาง",
    labelBankRoutingCodeNationalID: "รหัสเส้นทางธนาคาร / รหัสประจำตัว",
    labelBankTransitCodeSwift: "หมายเลขขนส่ง ทางผ่าน",
    labelCNAPSCode: " รหัสเส้นทาง / รหัส CNAPS (รหัสระบบการชำระเงินขั้นสูงแห่งชาติจีน)",
    labelABAFedwireRoutingCode: "หมายเลขเส้นทาง ACH",
    labelIFSCCode: "รหัส IFSC",
    labelBankCode: "รหัสธนาคาร",
    labelBIKCode: "รหัส BIK",
    labelBranchCode: "รหัสสาขา",
    labelBranchCodeUniversal: "รหัสสาขา 6 หลักสากล",
    labelRemittanceLine3: "ชื่อผู้ติดต่อและหมายเลขโทรศัพท์ของผู้รับเงิน",
    labelBeneficiaryContactNameandTelephoneNumber: "ชื่อผู้ติดต่อและหมายเลขโทรศัพท์ของผู้รับเงิน",
    labelBeneficiaryDateofBirthandPlaceofBirth: "วันเดือนปีเกิดและสถานที่เกิดของผู้รับผลประโยชน์",
    labelRemittanceLine4: "หมายเลขภาษีบุคคล CUIL (9-12 หลัก)",
    labelCUILNumber: "หมายเลขประจำตัวผู้เสียภาษี: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "เลขประจำตัวผู้เสียภาษี RFC 13 หลักหรือ CURP 18 หลัก",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "หมายเลขประจำตัวผู้เสียภาษี CPF Cadastro de Pessoa Física (11-14 หลัก), รหัสสาขา",
    labelCFPNumber: "หมายเลข CPF (11-14 หลัก)",
    labelBranchNameorBranchCode7digits: "ชื่อสาขาหรือรหัสสาขา (7 หลัก)",
    labelCedulaJuridica910digitsTaxID: "ใบรับรองทางกฎหมาย (เลขประจำตัวผู้เสียภาษี 9-10 หลัก)",
    labelTaxIDRUT89digits: "RUT เลขประจำตัวผู้เสียภาษี (8-9 หลัก)",
    labelJob: "ตำแหน่งงาน",
    labelDateOfBirth: "วันเกิด",
    labelNationality: "สัญชาติ",
    labelDocumentType: "ประเภทเอกสาร",
    labelDocumentNumber: "หมายเลขเอกสาร",
    labelRutNumber: "หมายเลข RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "บัตรประชาชน (เลขประจำตัวผู้เสียภาษี 8-11 หลัก)",
    labelCIIndividualTaxIDBranchLocation: "CI (หมายเลขประจำตัวผู้เสียภาษี) ที่ตั้งสาขา",
    labelTaxIDRUCorCI: "หมายเลขประจำตัวผู้เสียภาษี (RUC หรือ CI)",
    labelSortCodeHelp: "ช่วยด้วย?",
    labelBranchCodeHelp: "ช่วยด้วย?",
    labelIBAN: "IBAN",
    labelIBANHelp: "ช่วยด้วย?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "ช่วยด้วย?",
    labelRoutingCodeBSB: "รหัสเส้นทาง / BSB",
    labelBSB: "ธนาคารสาขารัฐ (BSB)",
    labelSKNCode: "รหัส SKN",
    labelAcceptanceFlights: "ฉัน/เรายอมรับว่าหากการขอคืนเงินสำเร็จ การจองรวมถึงเที่ยวบินทั้งหมดและผลิตภัณฑ์เสริมจะถูกยกเลิก",
    labelAcceptanceTickets: "ฉัน/เรายอมรับว่าหากการขอคืนเงินสำเร็จ การจองรวมถึงตั๋วทั้งหมดและผลิตภัณฑ์เสริมจะถูกยกเลิก",
    labelTermsAndConditions1: "ฉัน / เราได้ตกลงที่จะ",
    labelTermsAndConditions2: "ข้อกำหนดและเงื่อนไข",
    labelFraudWarning1: "ฉันเชื่อว่าข้อเท็จจริงที่ให้ไว้ในแบบฟอร์มขอคืนเงินนี้เป็นเรื่องจริงและเป็นความจริง ฉันเข้าใจว่าข้อมูลหรือหลักฐานที่เป็นเท็จอาจถือเป็นการกระทำผิดทางอาญาและส่งต่อให้ตำรวจหรือหน่วยงานบังคับใช้กฎหมายอื่นดำเนินการ",
    labelFraudWarning2: "ในกรณีที่เราสงสัยว่ามีการฉ้อโกงเกี่ยวกับเอกสารประกอบ เรื่องนี้จะถูกส่งต่อไปยังเจ้าหน้าที่ตำรวจในท้องที่ทันที ฉันยอมรับและเข้าใจว่ารายละเอียดของฉันจะถูกส่งต่อไปยังหน่วยงานเหล่านี้ หากใบสมัครของฉันถูกพิจารณาว่าเป็นการฉ้อโกงและอาจกลายเป็นการสอบสวนทางอาญา",
    validationTermsAndConditions: "ต้องยอมรับข้อกำหนดและเงื่อนไข",
    messageFailbankName: "ชื่อธนาคารต้องไม่ว่างเปล่า",
    messageFailcustomerName: "ชื่อลูกค้าต้องไม่ว่างเปล่า",
    messageFailbankAccountNumber: "ช่องที่ต้องกรอก",
    messageFailbankAccountNumberFormat: "ต้องเป็นรูปแบบ IBAN",
    messageFailsortCode: "ฟิลด์ที่จำเป็น",
    messageFailbankSwiftBic: "ผู้รับเงิน SWIFT BIC ต้องไม่เว้นว่าง",
    messageFailbankSwiftBicFormat: "ต้องเป็นรูปแบบ swfit",
    messageFailbeneficiaryAddress1: "ที่อยู่ 1 ต้องไม่ว่างเปล่า",
    messageFailbeneficiaryCity: "เมืองต้องไม่ว่างเปล่า",
    messageFailbeneficiaryPostCode: "รหัสไปรษณีย์ต้องไม่เว้นว่าง",
    messageFailremittanceLine3: "ฟิลด์ที่ต้องการ",
    messageFailremittanceLine4: "ฟิลด์ที่ต้องการ",
    modalHeading: "ยืนยันรายละเอียดธนาคาร",
    modalLabelPleaseConfirmYourDetails: "โปรดตรวจสอบรายละเอียดธนาคารของคุณและยืนยัน",
    modalValidationPleaseConfirm: "โปรดยืนยัน",
    modalValidationMustMatch: "ต้องตรงกับรายการก่อนหน้า",
    tooltipBankAccountName: "ชื่อของคุณตามที่ปรากฏในบัญชีธนาคาร / ใบแจ้งยอดธนาคาร / บัตรธนาคาร",
    tooltipBankAccountNameRecipient: "ชื่อและนามสกุลของผู้รับ ทั้งชื่อและนามสกุลต้องมีอักขระมากกว่าหนึ่งตัว",
    tooltipBankCountry: "ประเทศที่ธนาคารของคุณตั้งอยู่",
    tooltipBankName: "ชื่อธนาคารของคุณ",
    tooltipPaymentType: "ประเภทการชำระเงินที่คุณต้องการ",
    tooltipInstitutionNumber: "นี่คือตัวเลข 3 หลักและมักพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคารของคุณ หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipBankAccountNumber: "หมายเลขบัญชีส่วนตัวของคุณ โดยปกติจะพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคารของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipBankAccountNumberCBU: "ตัวเลข 22 หลักที่ใช้ระบุบัญชีของคุณ มักพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคาร หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipBankAccountNumberCCI: "ตัวเลข 20 หลักที่ใช้ระบุบัญชีของคุณ มักพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคาร หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipBankAccountNumberKRW: "ตัวเลข 11-16 หลักที่ใช้ระบุบัญชีของคุณ มักพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคาร หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipCardNumber: "หมายเลขบัตรของคุณ มักพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคาร หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipUnionpay: "หมายเลขบัตร UnionPay ของคุณ",
    tooltipAlipay: "หมายเลขโทรศัพท์มือถือหรือที่อยู่อีเมลของคุณที่เชื่อมโยงกับบัญชี Alipay ของคุณ",
    tooltipBankAccountType: "นี่คือการตรวจสอบหรือการออม",
    tooltipSortCode: "ข้อมูลนี้ระบุสาขาของธนาคารของคุณที่บัญชีของคุณถูกเก็บไว้ โดยปกติจะพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคารของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipRoutingCode: "ข้อมูลนี้ระบุสาขาของธนาคารของคุณที่บัญชีของคุณถูกเก็บไว้ โดยปกติจะพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคารของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipBeneficiarySWIFTBIC: "ประกอบด้วยอักขระ 8-11 ตัวที่ใช้ระบุธนาคารในการทำธุรกรรมระหว่างประเทศ โดยปกติจะพบในใบแจ้งยอดรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipBSB: "หมายเลข ธนาคารสาขารัฐ (BSB) คือรหัสการเรียงลำดับของออสเตรเลียซึ่งมี 6 หลัก โดยปกติจะพบในใบแจ้งยอดรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipRoutingCodeBSB: "ข้อมูลนี้ระบุสาขาของธนาคารของคุณที่บัญชีของคุณถูกเก็บไว้ โดยปกติจะพบในใบแจ้งยอดรายเดือนหรือบัตรธนาคารของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipABAFedwireRoutingCode: "หมายเลข 9 หลักที่ใช้ระบุธนาคารในอเมริกา โดยปกติจะพบในใบแจ้งยอดรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipIFSCCode: "โดยปกติรหัสนี้จะอยู่ในใบแจ้งยอดรายเดือนหรือในสมุดคู่ฝาก หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipTermsAndConditions: "การยอมรับข้อกำหนดในการให้บริการแสดงว่าคุณได้อ่านและยอมรับข้อกำหนดในการให้บริการของเรา",
    tooltipIBAN: "หมายเลขบัญชีธนาคารระหว่างประเทศของคุณซึ่งคุณสามารถใช้เมื่อชำระเงินหรือรับการชำระเงินระหว่างประเทศ โดยปกติจะพบในใบแจ้งยอดรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคารของคุณ",
    tooltipBeneficiaryContactNameandTelephoneNumber: "ชื่อผู้ติดต่อผู้รับเงินและหมายเลขโทรศัพท์",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "วันเดือนปีเกิดและสถานที่เกิดของผู้รับผลประโยชน์",
    tooltipBankRoutingCodeNationalID: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBankTransitCodeSwift: "นี่คือตัวเลข 5 หลัก และมักจะพบได้ในใบแจ้งยอดธนาคารรายเดือนของคุณ หรือหากคุณยังไม่แน่ใจ ให้สอบถามธนาคารของคุณ",
    tooltipBankAddress: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBankAddress2: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBankCity: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBankState: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBranchName: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBankCode: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBIKCode: "9 หลักเพื่อระบุธนาคารของคุณ",
    tooltipBranchCode: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBranchCodeUniversal: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipCNAPSCode: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipRemittanceLine4: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipCUILNumber: "นี่คือตัวเลข 11 หลัก ประกอบด้วยสองหลัก ยัติภังค์ เก้าหลัก และเช็คซัมหนึ่งหลัก",
    tooltipTaxIDRFC13DigitsORCURP18digits: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipCFPNumber: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipBranchNameorBranchCode7digits: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipCedulaJuridica910digitsTaxID: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipTaxIDRUT89digits: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipJob: "อาชีพปัจจุบันของคุณ",
    tooltipDateOfBirth: "วันเกิดของคุณ.",
    tooltipNationality: "สัญชาติของคุณ.",
    tooltipDocumentType: "ประเภทเอกสารที่ต้องการ",
    tooltipDocumentNumber: "นี่คือหมายเลขเอกสารที่เกี่ยวข้องกับการเลือกประเภทเอกสารของคุณ",
    tooltipRutNumber: "หมายเลข RUT ของคุณ (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipCIIndividualTaxIDBranchLocation: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    tooltipTaxIDRUCorCI: "โดยปกติจะพบได้ในใบแจ้งยอดบัญชีธนาคารรายเดือนของคุณหรือหากคุณยังไม่แน่ใจให้สอบถามธนาคาร",
    errorCPFCode: "ในขณะนี้เรายอมรับเฉพาะ CPF เป็นคีย์ PIX เท่านั้น"
};