export default {
    messageUnableToMakePaymentToCurrency: "למרבה הצער איננו יכולים לאחזר את פרטי הבנק עבור המטבע שנבחר. אנא ודא כי מטבע ומדינת הבית נבחרו בתא",
    messageOnlyBankAccountsAccepted: "שימו לב: אנו יכולים לבצע תשלומים רק לחשבונות בנק כרגע (כרטיסי אשראי וכרטיסי חיוב אינם מתקבלים)",
    labelAccountName: "שם בעל החשבון",
    labelBankAccountName: "שם מלא של בעל החשבון",
    labelBankCardHolderName: "שם בעל הכרטיס",
    validationBankAccountName: "נדרש שם מלא של בעל החשבון",
    labelBankCountry: "ארץ הבנק",
    labelBankName: "שם בנק",
    labelPaymentType: "סוג תשלום",
    labelInstitutionNumber: "מספר מוסד",
    labelBankAddress: "כתובת בנק",
    labelBankAddress2: "כתובת בנק",
    labelBankCity: "בנק העיר",
    labelBankState: "מדינת בנק",
    labelBankAccountNumber: "מספר חשבון בנק",
    labelBankAccountNumberCBU: "מספר חשבון CBU",
    labelBankAccountNumberCCI: "מספר חשבון CCI",
    labelBankAccountNumberZAR: "מספר חשבון בנק (7-11 ספרות)",
    labelCardNumber: "מספר כרטיס",
    labelUnionpay: "מספר כרטיס UnionPay",
    labelAlipay: "מספר טלפון נייד או אימייל של מזהה Alipay",
    labelBankAccountType: "סוג החשבון",
    optionBankAccountTypeCURRENT: "חשבון נוכחי",
    optionBankAccountTypeCHECKING: "בודק חשבון",
    optionBankAccountTypeSAVING: "חשבון חיסכון",
    optionBankAccountTypeSAVINGS: "חשבון חיסכון",
    optionBankAccountTypeCUENTA_VISTA: "חשבון ראשי",
    optionBankAccountType1: "בודק חשבון",
    optionBankAccountType2: "חשבון חיסכון",
    optionBankAccountType3: "חשבון ראשי",
    optionBankAccountType4: "Vista חֶשְׁבּוֹן",
    optionBankAccountType5: "RUT חֶשְׁבּוֹן",
    labelSortCode: "קוד מיון",
    labelRoutingCode: "קוד ניתוב",
    labelBankRoutingCodeNationalID: "קוד ניתוב בנק / תעודת זהות לאומית",
    labelBankTransitCodeSwift: "מספר מעבר",
    labelCNAPSCode: "קוד ניתוב / קוד CNAPS (קוד מערכת התשלומים הלאומית של סין)",
    labelABAFedwireRoutingCode: "מספר ניתוב ACH",
    labelIFSCCode: "קוד IFSC",
    labelBankCode: "קוד בנק",
    labelBIKCode: "קוד BIK",
    labelBranchCode: "קוד סניף",
    labelBranchCodeUniversal: "קוד סניף אוניברסלי בן 6 ספרות",
    labelRemittanceLine3: "שם איש קשר ונותן מספר טלפון",
    labelBeneficiaryContactNameandTelephoneNumber: "שם איש הקשר המוטב ומספר הטלפון",
    labelBeneficiaryDateofBirthandPlaceofBirth: "תאריך לידה מוטב ומקום לידה",
    labelRemittanceLine4: "מספר מס אישי",
    labelCUILNumber: "מזהה מס: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "מזהה מס RFC 13 ספרות או CURP 18 ספרות",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "תעודת זהות מס פרט CPF רישום משלם מיסים פרטני (11-14 ספרות), קוד סניף",
    labelCFPNumber: "מספר CPF (11-14 ספרות)",
    labelBranchNameorBranchCode7digits: "שם הסניף או קוד הסניף (7 ספרות)",
    labelCedulaJuridica910digitsTaxID: "תעודה משפטית (9-10 ספרות מזהה מס)",
    labelTaxIDRUT89digits: "RUT מזהה מס (8-9 ספרות)",
    labelJob: "הגדרת תפקיד",
    labelDateOfBirth: "תאריך לידה",
    labelNationality: "לאום",
    labelDocumentType: "סוג מסמך",
    labelDocumentNumber: "מספר מסמך",
    labelRutNumber: "מספר RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "קדולה (מספר זיהוי מס אישי בן 8-11 ספרות)",
    labelCIIndividualTaxIDBranchLocation: "CI (מזהה מס פרטני), מיקום סניף",
    labelTaxIDRUCorCI: "מזהה מס (RUC או CI)",
    labelSortCodeHelp: "עֶזרָה?",
    labelBranchCodeHelp: "עֶזרָה?",
    labelIBAN: "IBAN",
    labelIBANHelp: "עֶזרָה?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "עֶזרָה?",
    labelRoutingCodeBSB: "קוד ניתוב / BSB",
    labelBSB: "סניף מדינת הבנק (BSB)",
    labelSKNCode: "קוד SKN",
    labelAcceptanceFlights: "אני/אנו מאשרים שאם בקשת ההחזר תצליח, ההזמנה כולל כל הטיסות והמוצרים הנלווים תבוטל.",
    labelAcceptanceTickets: "אני/אנו מקבלים שאם בקשת ההחזר תצליח, ההזמנה הכוללת את כל הכרטיסים והמוצרים הנלווים תבוטל.",
    labelTermsAndConditions1: "אני / הסכמנו ל",
    labelTermsAndConditions2: "תנאים והגבלות",
    labelFraudWarning1: "אני מאמין שהעובדות שסופקו בטופס הבקשה להחזר זה הן אמיתיות ונכונות. אני מבין שכל מידע או ראיה כוזבים עלולים להיחשב כמעשה פלילי ולהועבר למשטרה או לרשויות אכיפת חוק אחרות כדי להמשיך.",
    labelFraudWarning2: "כאשר אנו חושדים בהונאה ביחס לתיעוד תומך, עניין זה יועבר מיד לרשויות המשטרה המקומיות. אני מקבל ומבין שהפרטים שלי יועברו לרשויות אלה אם הבקשה שלי תיחשב כמרמה וזו עלולה להפוך לחקירה פלילית.",
    validationTermsAndConditions: "יש להסכים לתנאים ולהגבלות",
    messageFailbankName: "שם הבנק לא יכול להיות ריק",
    messageFailcustomerName: "שם הלקוח לא יכול להיות ריק",
    messageFailbankAccountNumber: "שדה חובה",
    messageFailbankAccountNumberFormat: "חייב להיות בפורמט IBAN",
    messageFailsortCode: "שדה חובה",
    messageFailbankSwiftBic: "מוטב SWIFT BIC אינו יכול להיות ריק",
    messageFailbankSwiftBicFormat: "חייב להיות בפורמט SWIFT",
    messageFailbeneficiaryAddress1: "כתובת 1 לא יכולה להיות ריקה",
    messageFailbeneficiaryCity: "העיר לא יכולה להיות ריקה",
    messageFailbeneficiaryPostCode: "המיקוד לא יכול להיות ריק",
    messageFailremittanceLine3: "שדה חובה",
    messageFailremittanceLine4: "שדה חובה",
    modalHeading: "אשר פרטי בנק",
    modalLabelPleaseConfirmYourDetails: "אנא בדוק את פרטי הבנק שלך ואשר.",
    modalValidationPleaseConfirm: "אנא אשר",
    modalValidationMustMatch: "חייב להתאים לערך הקודם",
    tooltipBankAccountName: "שמך כפי שהוא מופיע בחשבון הבנק שלך / בהצהרות הבנק / בכרטיס הבנק שלך.",
    tooltipBankAccountNameRecipient: "שם פרטי ושם משפחה של המקבל. שם פרטי ושם משפחה חייבים לכלול יותר מתו אחד.",
    tooltipBankCountry: "המדינה בה נמצא הבנק שלך.",
    tooltipBankName: "שם הבנק שלך.",
    tooltipPaymentType: "סוג התשלום שאתה זקוק לו.",
    tooltipInstitutionNumber: "זה שלוש ספרות ונמצא בדרך כלל בדוח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankAccountNumber: 'מספר החשבון האישי שלך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBankAccountNumberCBU: 'מספר בן 22 ספרות המשמש לזיהוי חשבונך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBankAccountNumberCCI: 'מספר בן 20 ספרות המשמש לזיהוי חשבונך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBankAccountNumberKRW: 'מספר בן 11–16 ספרות המשמש לזיהוי חשבונך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipCardNumber: 'מספר הכרטיס שלך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipUnionpay: "מספר כרטיס ה- UnionPay שלך.",
    tooltipAlipay: 'מספר הטלפון הנייד שלך או כתובת הדוא"ל שלך המשויכים לחשבון Alipay שלך.',
    tooltipBankAccountType: "זה בדיקה או חיסכון.",
    tooltipSortCode: 'זה מזהה את סניף הבנק שלך שבו מתנהל החשבון שלך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipRoutingCode: 'זה מזהה את סניף הבנק שלך שבו מתנהל החשבון שלך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBeneficiarySWIFTBIC: 'מורכב מ-8-11 תווים המשמשים לזיהוי בנק בעסקה בינלאומית. בדרך כלל נמצא בדו"ח החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBSB: 'מספר סניף מדינת בנק (BSB) הוא קוד מיון אוסטרלי ובו 6 ספרות. בדרך כלל נמצא בדו"ח החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipRoutingCodeBSB: 'זה מזהה את סניף הבנק שלך שבו מתנהל החשבון שלך. בדרך כלל נמצא בדו"ח החודשי או בכרטיס הבנק שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipABAFedwireRoutingCode: 'מספר בן 9 ספרות המשמש לזיהוי בנקים באמריקה. בדרך כלל נמצא בדו"ח החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipIFSCCode: "בדרך כלל ניתן למצוא קוד זה בדוח החודשי שלך או בספר הדרכים שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipTermsAndConditions: 'על ידי קבלת התנאים וההגבלות אתה מאשר שקראת ומסכים לתנאים וההגבלות שלנו.',
    tooltipIBAN: 'מספר חשבון הבנק הבינלאומי שלך, שבו אתה יכול להשתמש בעת ביצוע או קבלת תשלומים בינלאומיים. בדרך כלל נמצא בדו"ח החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.',
    tooltipBeneficiaryContactNameandTelephoneNumber: "שם איש הקשר והמוטב ומספר הטלפון שלו.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "תאריך לידה מוטב ומקום לידה.",
    tooltipBankRoutingCodeNationalID: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankTransitCodeSwift: "מדובר בחמש ספרות ולרוב ניתן למצוא אותן בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankAddress: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankAddress2: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankCity: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankState: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBranchName: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBankCode: "בדרך כלל ניתן למצוא אותו על חשבון הבנק החודשי שלך או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBIKCode: "9 ספרות לזיהוי הבנק שלך.",
    tooltipBranchCode: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBranchCodeUniversal: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipCNAPSCode: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipRemittanceLine4: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipCUILNumber: "זהו מספר בן 11 ספרות, המורכב משתי ספרות, מקף, תשע ספרות ובדיקת ספרה אחת.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipCFPNumber: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipBranchNameorBranchCode7digits: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipCedulaJuridica910digitsTaxID: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipTaxIDRUT89digits: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipJob: "העיסוק הנוכחי שלך.",
    tooltipDateOfBirth: "תאריך הלידה שלך.",
    tooltipNationality: "הלאום שלך.",
    tooltipDocumentType: "סוג המסמך הנדרש.",
    tooltipDocumentNumber: "זהו מספר המסמך המשויך לבחירת סוג המסמך שלך.",
    tooltipRutNumber: "מספר ה- RUT שלך (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipCIIndividualTaxIDBranchLocation: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    tooltipTaxIDRUCorCI: "בדרך כלל ניתן למצוא בדף הבנק החודשי שלך, או אם אתה עדיין לא בטוח, שאל את הבנק שלך.",
    errorCPFCode: "כרגע אנחנו מקבלים CPF רק כמפתח PIX."
};