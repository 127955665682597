export default {
    headingAdverseWeather: "Du oplever alvorlige ugunstige vejrforhold, der gør det usikkert at rejse til begivenheden, og dette er blevet pålagt af et regeringsorgan (f.eks. Lokal myndighed, politi osv.). Og du har beviser for at bevise dette?",
    headingAdverseWeatherDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    tooltipRefundReasonConfirm: "Ja eller nej",
    labelBookingCancelledByOperator: "Billetterne blev annulleret af operatøren/arrangøren.",
    labelBookingRescheduledByOperator: "Datoen blev ændret af operatøren/arrangøren, og jeg kan ikke deltage i de nye datoer.",
    labelBookedWrongDate: "Jeg eller et medlem af reservationen begik en fejl ved køb af billetterne og reserverede den forkerte dato.",
    labelBookedWrongTickets: "Jeg eller et medlem af reservationen begik en fejl ved køb af billetterne og reserverede de forkerte billetter.",
    labelBookedTooManyTickets: "Jeg eller et medlem af reservationen lavede en fejl ved køb af billetterne og reserverede for mange billetter.",
    labelCOVID19TravelRestrictions: "Jeg eller et medlem af bookingen kan ikke deltage på grund af et COVID-19 rejseforbud.",
    labelADWrongSelection: "Du har muligvis valgt den forkerte årsag til en refusion. Se rullelisten Refusionsårsag igen.",
    labelAdverseWeather1: "Hvorfor betød ugunstigt vejr, at du ikke var i stand til at deltage i arrangementet?",
    tooltipAdverseWeather1: "Venligst detaljer",
    labelAdverseWeather2: "Dato for hændelse",
    tooltipAdverseWeather2: "Vælg venligst",
    headingArmedForces: "Du bliver spurgt af din arbejdsgiver, hvilken er væbnede styrker eller beredskabstjenester, der skal komme på arbejde, når du ikke oprindeligt var planlagt til at gøre det, og dette forhindrer dig i at deltage i arrangementet?",
    headingArmedForcesDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelArmedForces1: "Hvilke kræfter eller beredskab er du ansat af?",
    tooltipArmedForces1: "Vælg venligst",
    labelArmedForcesOther1: "Angiv venligst",
    tooltipArmedForcesOther1: "Angiv venligst",
    labelArmedForces2: "Dato for hændelse",
    tooltipArmedForces2: "Vælg venligst",
    headingChangesToExam: "Datoerne for din eksamen er blevet ændret af din skole/kollegium osv.? Dette er ikke en mislykket eksamen, som du skal resitere?",
    headingChangesToExamDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelExam1: "Hvad var den oprindelige eksamensdato?",
    tooltipExam1: "Vælg venligst",
    labelExam2: "Hvad er den nye eksamensdato?",
    tooltipExam2: "Vælg venligst",
    labelExam3: "Hvorfor ændrede eksamensdatoen sig?",
    tooltipExam3: "Indtast venligst detaljer",
    labelExam4: "Dumpede du den forrige eksamen?",
    tooltipExam4: "Ja eller nej",
    headingCourtSummons: "Du er blevet indkaldt til at møde op i retten som vidne på samme datoer som begivenheden?",
    headingCourtSummonsDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelCourtSummons1: "Hvad er årsagen til din stævning?",
    tooltipCourtSummons1: "Vælg venligst",
    labelCourtSummonsOther1: "Angiv venligst",
    tooltipCourtSummonsOther1: "Angiv venligst",
    labelCourtSummons2: "Hvornår blev stævningen udstedt?",
    tooltipCourtSummons2: "Vælg venligst",
    headingCOVID19Test: "Jeg, et medlem af min husstand eller et medlem af gruppen, der deltager i arrangementet, er blevet diagnosticeret med COVID-19?",
    headingCOVID19TestDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelCOVID19Test1: "Hvem har COVID-19?",
    tooltipCOVID19Test1: "Vælg venligst",
    labelCOVID19TestOther1a: "Angiv venligst",
    labelCOVID19TestOther1b: "Angiv navn",
    tooltipCOVID19TestOther1a: "Angiv venligst",
    tooltipCOVID19TestOther1b: "Angiv navn",
    labelCOVID19Test2: "Hvilken type COVID-19-test havde du?",
    tooltipCOVID19Test2: "Vælg venligst",
    labelCOVID19TestOther2: "Angiv venligst",
    tooltipCOVID19TestOther2: "Angiv venligst",
    labelCOVID19Test3: "Hvad var datoen for din COVID-19-test?",
    tooltipCOVID19Test3: "Vælg venligst",
    headingDeath: "Der har været et dødsfald af et nært medlem af min familie, og det forhindrer mig i at deltage i arrangementet?",
    headingDeathDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelDeath1: "Hvilket medlem af din familie er død?",
    tooltipDeath1: "Vælg venligst",
    labelDeathOther1: "Angiv venligst",
    tooltipDeathOther1: "Angiv venligst",
    labelDeath2: "Hvornår var dødsdatoen?",
    tooltipDeath2: "Vælg venligst",
    labelDeath3: "Afdødes navn",
    tooltipDeath3: "Angiv venligst",
    headingEmergency: "Jeg har haft en reel nødsituation, der er uden for min kontrol og uforudset, som jeg kan fremlægge bevis for?",
    headingEmergencyDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelEmergency1: "Forklar venligst typen af nødsituation?",
    tooltipEmergency1: "Venligst detaljer",
    labelEmergency2: "Hvornår skete dette?",
    tooltipEmergency2: "Vælg venligst",
    labelEmergency3: "Hvorfor forhindrer denne situation dig i at deltage i arrangementet?",
    tooltipEmergency3: "Venligst detaljer",
    labelEmergency4: "Har du beviser for denne nødsituation?",
    tooltipEmergency4: "Ja eller nej",
    headingHomeEmergency: "Jeg har en nødsituation i hjemmet, som er røveri, oversvømmelse, brand, hærværk, vold i hjemmet, der forhindrer mig i at deltage i arrangementet?",
    headingHomeEmergencyDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelHomeEmergency1: "Hvad er nødsituationen i hjemmet?",
    tooltipHomeEmergency1: "Vælg venligst",
    labelHomeEmergencyOther1: "Angiv venligst",
    tooltipHomeEmergencyOther1: "Angiv venligst",
    labelHomeEmergency2: "Hvornår skete dette?",
    tooltipHomeEmergency2: "Vælg venligst",
    labelHomeEmergency3: "Hvorfor forhindrede det dig i at deltage i arrangementet?",
    tooltipHomeEmergency3: "Venligst detaljer",
    headingInjury: "Jeg, et medlem af min husstand eller et medlem af den gruppe, der deltager i begivenheden, er blevet diagnosticeret med en medicinsk tilstand eller skade, der nu forhindrer os i at gå til arrangementet?",
    headingInjuryDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelInjury1: "Hvad er arten af sygdommen/skaden/ulykken?",
    tooltipInjury1: "Venligst detaljer",
    labelInjury2: "Hvornår skete dette?",
    tooltipInjury2: "Vælg venligst",
    labelInjury3: "Har du besøgt et lægehus for denne sygdom/skade?",
    tooltipInjury3: "Ja eller nej",
    labelInjury4: "Var dette en allerede eksisterende medicinsk tilstand?",
    tooltipInjury4: "Ja eller nej",
    labelInjury5: "Hvornår blev dette først diagnosticeret?",
    tooltipInjury5: "Vælg venligst",
    labelInjury6: "Hvorfor betyder det, at du ikke kan deltage i arrangementet?",
    tooltipInjury6: "Venligst detaljer",
    labelInjury7: "Hvem har været udsat for sygdommen/skaden?",
    tooltipInjury7: "Vælg venligst",
    headingJuryService: "Jeg eller et medlem af bookingen er blevet kaldt til Jury Service?",
    headingJuryServiceDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelJuryService1: "Hvornår blev du underrettet om Jury Service?",
    tooltipJuryService1: "Vælg venligst",
    headingMechanicalBreakdown: "Vores køretøj har haft et mekanisk nedbrud eller været i en ulykke, og det forhindrer os i at deltage i arrangementet?",
    headingMechanicalBreakdownDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelMechanicalBreakdown1: "Hvad skete der med køretøjet?",
    tooltipMechanicalBreakdown1: "Vælg venligst",
    labelMechanicalBreakdown2: "Hvornår skete dette?",
    tooltipMechanicalBreakdown2: "Vælg venligst",
    labelMechanicalBreakdown3: "Hvorfor kunne du ikke tage alternativ transport?",
    tooltipMechanicalBreakdown3: "Venligst detaljer",
    headingPregnancyComplication: "Jeg eller et medlem af bookingen har graviditetskomplikationer, der er blevet diagnosticeret af en læge og derfor forhindrer deltagelse i arrangementet?",
    headingPregnancyComplicationDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelPregnancyComplication1: "Hvad er arten af graviditetskomplikationen?",
    tooltipPregnancyComplication1: "Venligst detaljer",
    labelPregnancyComplication2: "Hvornår skete dette?",
    tooltipPregnancyComplication2: "Vælg venligst",
    labelPregnancyComplication3: "Søgte du lægehjælp?",
    tooltipPregnancyComplication3: "Ja eller nej",
    labelPregnancyComplication4: "Hvorfor betyder det, at du ikke kan deltage i arrangementet?",
    tooltipPregnancyComplication4: "Venligst detaljer",
    headingPublicTransportFailure: "Metoden til offentlig transport (tog, bus, sporvogn, båd osv.) mislykkedes uventet, hvilket betyder, at der ikke var nogen anden måde, jeg kunne rejse til denne begivenhed?",
    headingPublicTransportFailureDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelPublicTransportFailure1: "Hvilken type offentlig transport planlagde du at bruge?",
    tooltipPublicTransportFailure1: "Vælg venligst",
    labelPublicTransportFailure2: "Hvornår planlagde du at rejse?",
    tooltipPublicTransportFailure2: "Vælg venligst",
    labelPublicTransportFailure3: "Hvad skete der?",
    tooltipPublicTransportFailure3: "Venligst detaljer",
    labelPublicTransportFailure4: "Hvorfor kunne du ikke tage alternativ transport?",
    tooltipPublicTransportFailure4: "Venligst detaljer",
    headingScheduledAirlineFailure: "Mit forudbestilte fly aflyste uventet, og jeg kunne ikke finde et passende alternativ, hvilket betyder, at jeg ikke kunne deltage i arrangementet?",
    headingScheduledAirlineFailureDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelScheduledAirlineFailure1: "Flyselskabets navn",
    tooltipScheduledAirlineFailure1: "Flyselskabets navn",
    labelScheduledAirlineFailure2: "Flynummer",
    tooltipScheduledAirlineFailure2: "Flynummer",
    labelScheduledAirlineFailure3: "Rejser fra",
    tooltipScheduledAirlineFailure3: "Rejser fra",
    labelScheduledAirlineFailure4: "Rejser til",
    tooltipScheduledAirlineFailure4: "Rejser til",
    labelScheduledAirlineFailure5: "Dato for hændelse?",
    tooltipScheduledAirlineFailure5: "Vælg venligst",
    labelScheduledAirlineFailure6: "Hvorfor kunne du ikke tage et alternativt fly?",
    tooltipScheduledAirlineFailure6: "Venligst detaljer",
    headingTheftOfDocuments: "Mine dokumenter blev stjålet, og det var ikke muligt at arrangere udskiftning(er) inden for den nødvendige tidsramme, så jeg kan ikke deltage i arrangementet",
    headingTheftOfDocumentsDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelTheftOfDocuments1: "Hvilken type dokument blev stjålet?",
    tooltipTheftOfDocuments1: "Vælg venligst",
    labelTheftOfDocuments2: "Hvornår skete dette?",
    tooltipTheftOfDocuments2: "Vælg venligst",
    labelTheftOfDocuments3: "Forsøgte du at få dokumenterne genudstedt?",
    tooltipTheftOfDocuments3: "Ja eller nej",
    headingWorkRelocation: "Min arbejdsgiver har anmodet mig om at ændre placeringen af mit arbejde i mindst tre måneder, og afstanden er betydelig, hvilket betyder, at jeg nu ikke er i stand til at deltage i arrangementet?",
    headingWorkRelocationDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelWorkRelocation1: "Er dette en midlertidig eller permanent flytning af dit arbejde?",
    tooltipWorkRelocation1: "Vælg venligst",
    labelWorkRelocation2: "Hvor længe vil du være på den nye adresse?",
    tooltipWorkRelocation2: "Venligst detaljer",
    labelWorkRelocation3: "Hvad er din nuværende arbejdsadresse?",
    tooltipWorkRelocation3: "Venligst detaljer",
    labelWorkRelocation4: "Hvad er din nye arbejdsadresse?",
    tooltipWorkRelocation4: "Venligst detaljer",
    labelWorkRelocation5: "Hvornår blev du underrettet om denne flytning?",
    tooltipWorkRelocation5: "Vælg venligst",
    headingRedundancy: "Jeg eller et medlem af bookingen er blevet afskediget af deres arbejdsgiver, hvilket betyder, at jeg ikke længere kan deltage i bookingen?",
    headingRedundancyDecline: "Du bør ikke fortsætte med denne ansøgning, da refusionen sandsynligvis vil blive afvist.",
    labelRedundancy1: "Type beskæftigelse?",
    tooltipRedundancy1: "Vælg venligst",
    labelRedundancy2: "Hvor længe har du været ansat i dette firma?",
    tooltipRedundancy2: "Vælg venligst",
    labelRedundancy3: "Var afskedigelsen frivillig?",
    tooltipRedundancy3: "Vælg venligst",
    labelRedundancy4: "Hvornår fik du besked om din afskedigelse?",
    tooltipRedundancy4: "Vælg venligst",
    labelTestOption: "Vælg Ja for at fortsætte",
}