export default {
    nonAutoApprovedLabelThankYou: "Cảm ơn bạn đã hoàn lại tiền cho đơn đăng ký của bạn.",
    nonAutoApprovedLabelReview: "Nhóm của chúng tôi sẽ xem xét đơn đăng ký của bạn trong vòng hai ngày làm việc tiếp theo và liên hệ lại với bạn để trả lời.",
    nonAutoApprovedLabelExperience: "Chúng tôi đã làm như thế nào?",
    nonAutoApprovedLabelExperienceIntroduction: "Chúng tôi thực sự đánh giá cao việc bạn dành thời gian để đánh giá chúng tôi và để lại đánh giá vì chúng tôi đánh giá cao ý kiến của khách hàng.",
    feedbackExcellent: "Tuyệt vời",
    feedbackGreat: "Tuyệt",
    feedbackAverage: "Trung bình",
    feedbackPoor: "Nghèo",
    feedbackBad: "Tồi",
    labelThankYouForFeedback: "Cảm ơn bạn đã phản hồi của bạn.",
    autoApprovedMessage1: "Chúng tôi rất vui mừng được",
    autoApprovedMessage2: "Chấp nhận",
    autoApprovedMessage3: "tiền hoàn lại của bạn!",
    autoApprovedPayment1Day: "Khoản thanh toán của bạn đã được xử lý và sẽ có trong tài khoản của bạn chỉ sau 1 ngày làm việc.",
    autoApprovedPayment2to4Days: "Thanh toán của bạn đã được xử lý và sẽ có trong tài khoản của bạn chỉ sau 2-4 ngày làm việc.",
    autoApprovedPayment7to10Days: "Thanh toán của bạn đã được xử lý và sẽ có trong tài khoản của bạn chỉ sau 7-10 ngày làm việc.",
    autoApprovedLabelExperience: "Chúng tôi đã làm như thế nào?",
    autoApprovedLabelExperienceIntroduction: "Chúng tôi thực sự đánh giá cao việc bạn dành thời gian để đánh giá chúng tôi và để lại đánh giá vì chúng tôi đánh giá cao ý kiến của khách hàng.",
    modalLabelNegative: "Phản hồi của bạn thực sự quan trọng đối với chúng tôi, hãy cho chúng tôi biết nếu bạn cũng muốn chúng tôi liên hệ với bạn.",
    placeholderFeedback: "Phản hồi",
    messageFeedbackSubmitted: "Phản hồi đã gửi",
    reviewInstructionMessage: "5 sao là xuất sắc, 4 sao là tuyệt vời, 3 sao là trung bình, 2 sao là kém, 1 sao là xấu."
}