export default {
    labelBookingCancelledPostponedHeading: "Rezervarea dvs. a fost anulată sau amânată? Citeşte mai mult",
    labelBookingCancelledPostponedText1: "Clic",
    labelBookingCancelledPostponedText2: "pentru mai multe informatii.",
    labelNewRefundHeading: "Noua cerere de rambursare?",
    labelNewRefundText: "Introdu aici referința rezervării...",
    placeholderBookingReference: "Referință de rezervare",
    validationBookingReference: "Este necesară o referință de rezervare",
    validationEmailAsBookingReference: "Adresa de e-mail nu este acceptată ca referință pentru rezervare",
    buttonFindBooking: "Găsiți Booking",
    labelEmailHeading: "Ați început deja?",
    labelEmailText: "Trimiteți un memento prin e-mail pentru a vă prelua cererea.",
    placeholderEmail: "E-mail",
    buttonEmailReminder: "Memento prin e-mail",
    labelCustomerName : "Numele clientului",
    validationCustomerName: "Numele clientului este obligatoriu",
    labelTypeOfBooking: "Tip de rezervare",
    validationTypeOfBooking: "Tipul de rezervare este necesar",
    labelEventName:"Numele evenimentului",
    labelVenueName:"Numele locului de întâlnire",
    labelFlightNumber:"Numărul zborului",
    labelOriginAirport: "Aeroportul de origine",
    labelHotelName: "Nume cazare",
    labelHotelLocation: "Locație de cazare",
    labelCurrency: "Valută",
    validationCurrency: "Moneda este obligatorie",
    validationCountryCurrency: "Moneda selectată trebuie să fie moneda țării dumneavoastră de origine",
    validationOfflinePayment: "Nu putem procesa rambursări pentru moneda dvs. și țara selectată, vă rugăm să selectați alternative",
    labelBookingValue: "Valoarea rezervării",
    validationBookingValue: "Valoarea rezervării este necesară",
    validationBookingValueAmount: "Trebuie să fie mai mare decât 0",
    labelDateOfPurchase: "Data cumpararii",
    validationDateOfPurchase: "Data achiziției este obligatorie",
    labelEmailAddress: "Adresa de e-mail",
    validationEmailAddress: "Este necesară adresa de e-mail",
    validationEmailAddressFormat: "Format de e-mail incorect",
    labelConfirmEmailAddress: "Confirmați adresa de e-mail",
    validationConfirmEmailAddressFormat: "Format de e-mail incorect",
    validationConfirmEmailAddress: "Este necesară adresa de e-mail de confirmare",
    validationConfirmEmailAddressMatch: "Adresele de Email nu corespund",
    validationEmailDomain: "Acest furnizor de e-mail nu este acceptat, vă rugăm să utilizați o alternativă",
    validationDateOfEvent: "Data evenimentului este obligatorie",
    labelHomeCountry: "Țară de origine",
    validationHomeCountry: "Este necesară țara de origine",
    labelContactNumberCountryCode: "Numărul de contact Codul țării",
    validationContactNumberCountryCode: "Codul de apelare al țării este necesar",
    labelContactNumber: "Numar de contact",
    validationContactNumber: "Numărul de contact este necesar",
    labelAddressLine1: "Adresa Rândul 1",
    labelAddressLine2: "Linia de adresă 2",
    labelCity: "Oraș",
    labelZipCode: "Cod poștal",
    labelRegion: "Regiune",
    validationRegion: "Regiunea este obligatorie",
    labelNumberOfTicketsToRefund: "Numărul de bilete de rambursat",
    validationNumberOfTicketsToRefund: "Numărul de bilete pentru rambursare este necesar",
    validationNumberOfTicketsToRefundValue: "Trebuie să fie mai mare decât 0",
    labelCostPerTicket: "Costul fiecărui bilet",
    validationCostPerTicket: "Costul per Bilet este necesar",
    validationCostPerTicketValue: "Trebuie să fie mai mare decât 0",
    labelTotalRefundAmount: "Suma totală de rambursare",
    validationTotalRefundAmount: "Este necesară suma totală de rambursare",
    validationTotalRefundAmountValue: "Trebuie să fie mai mare decât 0",
    validationTotalRefundGreaterThanBookingValue: "Suma totală de rambursare nu poate fi mai mare decât valoarea rezervării de mai sus",
    labelBookingConfirmationFiles: "Încărcați confirmarea rezervării",
    validationBookingConfirmationFiles: "Atașați cel puțin un fișier sau faceți clic pe Salvare și reveniți mai târziu când aveți documentele",
    validationEvidenceFilesPopup: "Este necesar să se încarce dovezi înainte de trimitere. Dacă nu aveți dovezi în acest moment, vă rugăm să salvați cererea și să reveniți după ce le aveți. Acceptăm copii electronice, copii scaner și fotografii.",
    validationBookingConfirmationFilesPopup: "Confirmarea rezervării trebuie să fie încărcată înainte de trimitere. Dacă nu aveți confirmarea rezervării în acest moment, vă rugăm să salvați aplicația și să reveniți după ce ați avut-o. Acceptăm copii electronice, copii scaner și fotografii.",
    alertUploadDocuments: "Vă rugăm să încărcați documentația relevantă detaliată în e-mail și apoi treceți la Pasul 3.",
    labelRefundReasonCategory: "Motivul rambursării",
    validationRefundReasonCategory: "Motivul rambursării este necesar",
    labelDateOfIncident: "data incidentului",
    labelAdditionalComments: "Comentarii suplimentare",
    tooltipAdditionalComments: "Comentarii suplimentare",
    validationAdditionalComments: "Sunt necesare comentarii suplimentare",
    labelUnableToNotifyWithinTimeLimit: "Nu se poate notifica în 60 de zile de la",
    labelReasonForNotificationOutsideTimeLimit: "Notificare în afara limitei de timp Explicație",
    validationReasonForNotificationOutsideTimeLimit: "Este necesar un motiv pentru notificare după 60 de zile",
    labelRefundReasonFiles: "Încărcați dovezi",
    validationRefundReasonFiles: "Atașați cel puțin un fișier sau faceți clic pe Salvare și reveniți mai târziu când aveți documentele",
    validationTextScript: "Trebuie să se potrivească cu scrierea latină",
    optionTypeOfBooking1: "Eveniment",
    optionTypeOfBooking2: "Tur",
    optionTypeOfBooking3: "Zbor",
    optionTypeOfBooking4: "Cazare",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parcare",
    optionTypeOfBooking7: "Alte călătorii",
    labelEventTypeDate: "Data evenimentului",
    labelEventTypeTour: "Data turneului",
    labelEventTypeFlight: "Data zborului",
    labelEventTypeCheckIn: "Data check-in-ului",
    labelEventTypeTransfer: "Data transferului",
    labelEventTypeParking: "Data parcării",
    labelEventTypeOtherTravel: "Data călătoriei",
    optionRefundReason11: "vreme proastă",
    optionRefundReason10: "Rechemarea forțelor armate și a serviciilor de urgență",
    optionRefundReason15: "Modificări ale datelor de examinare",
    optionRefundReason18: "Somatia instantei",
    optionRefundReason17: "Covid-19 Restricții de călătorie",
    optionRefundReason19: "Test pozitiv pentru COVID-19",
    optionRefundReason20: "Rezervare anulată de către operator/organizator",
    optionRefundReason21: "Rezervarea a fost reprogramată de către operator/organizator",
    optionRefundReason22: "A rezervat o dată greșită",
    optionRefundReason23: "Am rezervat bilete greșite",
    optionRefundReason24: "Am rezervat prea multe bilete",
    optionRefundReason4: "Moarte",
    optionRefundReason16: "Circumstanțele de urgență",
    optionRefundReason14: "Interdicția guvernamentală de călătorie",
    optionRefundReason9: "Urgență la domiciliu",
    optionRefundReason1: "Boală/Vănire/Accident",
    optionRefundReason8: "Serviciul juriului",
    optionRefundReason7: "Defecțiune mecanică",
    optionRefundReason2: "Condiție medicală preexistentă",
    optionRefundReason3: "Complicația sarcinii",
    optionRefundReason5: "Eșecul transportului public",
    optionRefundReason6: "Întreruperea zborului",
    optionRefundReason13: "Furtul de bilet(e)",
    optionRefundReason12: "Mutarea muncii",
    optionRefundReason25: "Redundanță la locul de muncă",
    optionRefundReason26: "Opțiune de testare",
    optionRefundReasonConfirm1: "Nu",
    optionRefundReasonConfirm2: "Da",
    optionRefundReasonArmedForces1: "Armată",
    optionRefundReasonArmedForces2: "Marină militară",
    optionRefundReasonArmedForces3: "Forțele Aeriene",
    optionRefundReasonArmedForces4: "Forțele Speciale",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Foc",
    optionRefundReasonArmedForces7: "Ambulanță",
    optionRefundReasonArmedForces8: "Medic/Asistentă medicală",
    optionRefundReasonArmedForces9: "Dentist",
    optionRefundReasonArmedForces10: "Alt",
    optionRefundReasonCourtSummons1: "Urmărire penală",
    optionRefundReasonCourtSummons2: "Cauza civilă",
    optionRefundReasonCourtSummons3: "Martor într-o procuratură",
    optionRefundReasonCourtSummons4: "Martor într-o cauză civilă",
    optionRefundReasonCourtSummons5: "Reclamant",
    optionRefundReasonCourtSummons6: "Pârât",
    optionRefundReasonCourtSummons7: "Alt",
    optionRefundReasonDeath1: "Soț",
    optionRefundReasonDeath2: "Soție",
    optionRefundReasonDeath3: "Sunet",
    optionRefundReasonDeath4: "Fiică",
    optionRefundReasonDeath5: "Tată",
    optionRefundReasonDeath6: "Mamă",
    optionRefundReasonDeath7: "Frate",
    optionRefundReasonDeath8: "Soră",
    optionRefundReasonDeath9: "Bunic",
    optionRefundReasonDeath10: "Bunică",
    optionRefundReasonDeath11: "Alt",
    optionRefundReasonHomeEmergency1: "Foc",
    optionRefundReasonHomeEmergency2: "Inundație",
    optionRefundReasonHomeEmergency3: "Tâlhărie",
    optionRefundReasonHomeEmergency4: "Vandalism",
    optionRefundReasonHomeEmergency5: "Abuzul domestic",
    optionRefundReasonHomeEmergency6: "Răpească",
    optionRefundReasonHomeEmergency7: "Alt",
    optionRefundReasonCOVID19Person1: "Însumi",
    optionRefundReasonCOVID19Person2: "O altă persoană din rezervare",
    optionRefundReasonCOVID19Person3: "O persoană din gospodăria mea",
    optionRefundReasonCOVID19Person4: "Alt",
    optionRefundReasonCOVID19TestType1: "Testul PCR",
    optionRefundReasonCOVID19TestType2: "Testul RAT",
    optionRefundReasonCOVID19TestType3: "Încercarea fluxului lateral",
    optionRefundReasonCOVID19TestType4: "Test autoadministrat",
    optionRefundReasonCOVID19TestType5: "Nici un test, dar am simptome",
    optionRefundReasonCOVID19TestType6: "Alt",
    optionRefundReasonBreakdown1: "Defalcare",
    optionRefundReasonBreakdown2: "Accident",
    optionRefundReasonBreakdown3: "Foc",
    optionRefundReasonBreakdown4: "Furt",
    optionRefundReasonPublicTransportType1: "Tren",
    optionRefundReasonPublicTransportType2: "Autobuz",
    optionRefundReasonPublicTransportType3: "Tramvai",
    optionRefundReasonPublicTransportType4: "Barcă",
    optionRefundReasonDocumentType1: "Pașaport",
    optionRefundReasonDocumentType2: "Permis de călătorie",
    optionRefundReasonDocumentType3: "Id-ul național",
    optionRefundReasonDocumentType4: "Bilete",
    optionRefundReasonWorkRelocationType1: "Temporar",
    optionRefundReasonWorkRelocationType2: "Durabil",
    optionRefundReasonWorkRelocationTemporary1: "0-3 luni",
    optionRefundReasonWorkRelocationTemporary2: "3-6 luni",
    optionRefundReasonWorkRelocationTemporary3: "Peste 6 luni",
    optionRefundReasonBookingRelationshipType1: "Cineva din rezervare",
    optionRefundReasonBookingRelationshipType2: "Ruda imediată a unei persoane din rezervare",
    optionEmploymentType1: "Cu normă întreagă",
    optionEmploymentType2: "Cu jumătate de normă",
    optionEmploymentType3: "Contract",
    optionEmploymentLengthType1: "0-1 ani",
    optionEmploymentLengthType2: "1-2 ani",
    optionEmploymentLengthType3: "Mai mult de 2 ani",
    textRefundEvidenceHeading: "Vă rugăm să furnizați următoarele dovezi:",
    textRefundEvidence1: "Confirmarea companiei aeriene",
    textRefundEvidence2: "Orice dovezi justificative adecvate",
    textRefundEvidence3: "Confirmarea apelului la serviciul de defalcare",
    textRefundEvidence4: "Certificat de deces",
    textRefundEvidence5: "Confirmarea anulării de la compania aeriană",
    textRefundEvidence6: "Raport meteo",
    textRefundEvidence7: "Corespondența de la agentul de rezervare care confirmă că nu există posibilitatea de a reemite biletele",
    textRefundEvidence8: "Scrisoare din partea Curții",
    textRefundEvidence9: "Adeverință medicală / Nota medicului",
    textRefundEvidenceDescription9: "Confirmând detalii despre boală sau vătămare, data la care a avut loc prima dată, că vă împiedică să participați la rezervare.",
    textRefundEvidence10: "Confirmarea scrisorii angajatorului",
    textRefundEvidence11: "Corespondența privind cererile de asigurare",
    textRefundEvidence12: "Scrisoare de la Angajator",
    textRefundEvidence13: "Confirmarea site-ului guvernului național",
    textRefundEvidence14: "Notificare de modificare a examinării",
    textRefundEvidence15: "Raportul poliției",
    textRefundEvidence16: "Raportul poliției și numărul de referință",
    textRefundEvidence17: "Confirmarea furnizorului de transport",
    textRefundEvidence18: "Avertizare de călătorie",
    textRefundEvidence20: "Certificat de testare COVID-19",
    textRefundEvidence24: "Notificare de izolare guvernamentală",
    textRefundEvidence25: "Scrisoarea comandantului",
    textRefundEvidence26: "Scrisoare de la asiguratori",
    textRefundEvidence27: "Dovada adresei",
    textRefundEvidence28: "Dovada relației cu defunctul",
    textRefundEvidence29: "Dovada relației cu persoana vătămată/bolnavă",
    textRefundEvidence30: "Foaie de plată",
    tooltipBookingReference: "Pentru a identifica referința rezervării, verificați confirmarea rezervării și consultați secțiunea despre serviciul nostru extins.",
    tooltipCustomerName: "Numele și prenumele care au fost folosite la efectuarea rezervării.",
    tooltipTypeOfBooking: "Tip de rezervare pe care ați dori să vi se ramburseze.",
    tooltipEventName: "Numele evenimentului.",
    tooltipVenueName: "Numele locației.",
    tooltipFlightNumber: "Numărul zborului.",
    tooltipOriginAirport: "Aeroportul de plecare.",
    tooltipHotelName: "Numele unității de cazare.",
    tooltipHotelLocation: "Locația cazării.",
    tooltipCurrency: "Moneda folosită pentru plata rezervării.",
    tooltipBookingValue: "Prețul total plătit pentru rezervare.",
    tooltipDateOfPurchase: "Data la care a fost făcută rezervarea.",
    tooltipEmailAddress: "Vă rugăm să utilizați o adresă de e-mail validă. Folosim acest e-mail pentru corespondență în timpul cererii de rambursare.",
    tooltipConfirmEmailAddress: "Utilizați aceeași adresă de e-mail ca mai sus, aceasta este pentru validare.",
    tooltipHomeCountry: "Acest lucru ne va ajuta să aducem informațiile bancare corecte la pasul final.",
    tooltipContactNumberCountryCode: "Numărul de contact codul țării.",
    tooltipContactNumber: "Vă rugăm să utilizați un număr valid. Folosim aceasta pentru corespondență în timpul cererii de rambursare.",
    tooltipAddressLine1: "Vă rugăm să rețineți că adresa dvs. va fi folosită la validarea contului dvs. bancar.",
    tooltipAddressLine2: "Al doilea rând de adresă.",
    tooltipCity: "Orașul dvs. de domiciliu, detaliile pot fi folosite pentru validarea detaliilor bancare.",
    tooltipZipCode: "Cod poștal / Cod poștal.",
    tooltipRegion: "Regiunea orașului dvs. natal, detaliile pot fi folosite pentru validarea detaliilor bancare.",
    tooltipNumberOfTicketsToRefund: "Numărul de bilete pe care ați dori să vi se ramburseze.",
    tooltipTotalRefundAmount: "Aceasta nu poate depăși valoarea totală a rezervării, nu veți putea depune cererea dacă o face.",
    tooltipBookingConfirmationFiles: "Captură de ecran/atașare a confirmării complete a rezervării, care poate fi găsită de obicei în e-mailurile dvs.",
    tooltipRefundReasonCategory: "Vă rugăm să selectați motivul rambursării.",
    tooltipDateOfIncident: "Data la care a avut loc incidentul care a afectat rezervarea dvs.",
    tooltipRefundExplanation: "Vă rugăm să oferiți cât mai multe detalii posibil care să susțină cererea de rambursare.",
    tooltipUnableToNotifyWithinTimeLimit: "Nu se poate notifica în termen de 60 de zile.",
    tooltipReasonForNotificationOutsideTimeLimit: "Notificare în afara limitei de timp Explicație.",
    tooltipRefundReasonFiles: "Vă rugăm să încărcați oricare dintre documentele necesare.",
    tooltipEventTypeDate: "Data evenimentului/rezervării efective.",
    tooltipEventTypeTour: "Data turului propriu-zis.",
    tooltipEventTypeFlight: "Data zborului efectiv.",
    tooltipEventTypeCheckIn: "Data înregistrării efective.",
    tooltipEventTypeTransfer: "Data transferului efectiv.",
    tooltipEventTypeParking: "Data parcării efective.",
    tooltipEventTypeOtherTravel: "Data începerii călătoriei efective."
};