import * as React from 'react';
import Tooltip,
{
    TooltipProps,
    tooltipClasses
} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';

interface FormLabelTooltipProps extends TooltipProps {
    children: React.ReactElement
}

export const FormLabelTooltip = styled(({className, ...props}: FormLabelTooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}} children={props.children}/>
))
(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.8)'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        opacity: 0.5,
        padding: '10px',
        fontSize: '0.8em'
    },
}));