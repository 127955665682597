import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {DateInput, FileInput, SelectInput} from '../../formInputs';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../../Interfaces/IFormValues';
import {useFormContext} from '../../providers/FormProvider';
import {OptionYes} from '../../utils/constants';
import {FormSectionProps} from './RefundReasonHandlerSection';
import {useDynamicEvidence} from '../../utils/helpers';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';
import {RefundReasonFilesState} from '../../../state/activity';

export const TheftOfDocumentsSection = (props: FormSectionProps): JSX.Element => {

    const {
        formProps
    } = props;

    const {
        clearFiles,
        isEvidenceRequired,
        previousRefundReasonFiles,
        setClearFiles
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingTheftOfDocuments')}
                declineWarning={t('bespokeQuestions:headingTheftOfDocumentsDecline')}
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                defaultValue={0}
                label={t('bespokeQuestions:labelTheftOfDocuments1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                datasourceUrl='/api/referencedata/theftdocumenttypes'
                prefixKey={'form:optionRefundReasonDocumentType'}
                tooltip={t('bespokeQuestions:tooltipTheftOfDocuments1')}
                displayLabelAsTitle={false}
                required
            />

            <DateInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeDate2)}
                label={t('bespokeQuestions:labelTheftOfDocuments2')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate2)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                tooltip={t('bespokeQuestions:tooltipTheftOfDocuments2')}
                required
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect3)}
                defaultValue={0}
                label={t('bespokeQuestions:labelTheftOfDocuments3')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect3)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                prefixKey={'form:optionRefundReasonConfirm'}
                datasourceUrl='/api/referencedata/yesnooptions'
                tooltip={t('bespokeQuestions:tooltipTheftOfDocuments3')}
                displayLabelAsTitle={false}
                required
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFiles)}
                label={useDynamicEvidence(false)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFiles}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesState}
                formProps={formProps}
            />
        </>
    );
}