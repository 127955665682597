export default {
    messageUnableToMakePaymentToCurrency: "Kahjuks ei saa me valitud valuuta pangaandmeid kätte. Palun veenduge, et valuuta ja koduriik on valitud",
    messageOnlyBankAccountsAccepted: "Pange tähele: Praegu saame makseid teha ainult pangakontodele (krediit- ja deebetkaarte ei aktsepteerita)",
    labelAccountName: "Konto omaniku nimi",
    labelBankAccountName: "Konto omaniku täielik nimi",
    labelBankCardHolderName: "Kaardi omaniku nimi",
    validationBankAccountName: "Konto omaniku täielik nimi on kohustuslik",
    labelBankCountry: "Panga riik",
    labelBankName: "Panga nimi",
    labelPaymentType: "Makse tüüp",
    labelInstitutionNumber: "Asutuse number",
    labelBankAddress: "Panga aadress",
    labelBankAddress2: "Panga aadress",
    labelBankCity: "Panga linn",
    labelBankState: "Panga riik",
    labelBankAccountNumber: "Pangakonto number",
    labelBankAccountNumberCBU: "Konto number CBU",
    labelBankAccountNumberCCI: "Konto number: CCI",
    labelBankAccountNumberZAR: "Pangakonto number (7–11 numbrit)",
    labelCardNumber: "Kaardi number",
    labelUnionpay: "UnionPay kaardi number",
    labelAlipay: "Alipay ID (mobiiltelefoni number või e-posti aadress)",
    labelBankAccountType: "Konto tüüp",
    optionBankAccountTypeCURRENT: "Arvelduskonto",
    optionBankAccountTypeCHECKING: "Konto arveldus",
    optionBankAccountTypeSAVING: "Hoiukonto",
    optionBankAccountTypeSAVINGS: "Hoiukonto",
    optionBankAccountTypeCUENTA_VISTA: "Põhikonto",
    optionBankAccountType1: "Konto arveldus",
    optionBankAccountType2: "Hoiukonto",
    optionBankAccountType3: "Põhikonto",
    optionBankAccountType4: "Vista konto",
    optionBankAccountType5: "RUT konto",
    labelSortCode: "Sorteeri kood",
    labelRoutingCode: "Marsruudi kood",
    labelBankRoutingCodeNationalID: "Panga marsruutimiskood / riiklik ID",
    labelBankTransitCodeSwift: "Transiidi number",
    labelCNAPSCode: "Marsruutimiskood / CNAPS-kood (Hiina riikliku ettemaksesüsteemi kood)",
    labelABAFedwireRoutingCode: "ACH marsruudi number",
    labelIFSCCode: "IFSC kood",
    labelBankCode: "Panga kood",
    labelBIKCode: "BIK-kood",
    labelBranchCode: "Filiaali kood",
    labelBranchCodeUniversal: "Universaalne 6-kohaline harukood",
    labelRemittanceLine3: "Saaja kontaktnimi ja telefoninumber",
    labelBeneficiaryContactNameandTelephoneNumber: "Saaja kontaktnimi ja telefoninumber",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Soodustatud isiku sünniaeg ja -koht",
    labelRemittanceLine4: "Individuaalne maksunumber CUIL (9-12 numbrit)",
    labelCUILNumber: "Maksu ID: FLY / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Maksu ID RFC 13-kohaline või CURP 18-kohaline",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuaalne maksu-ID, CPF, individuaalne maksumaksja registreerimine (11-14 numbrit), filiaali kood",
    labelCFPNumber: "CPF number (11-14 numbrit)",
    labelBranchNameorBranchCode7digits: "Filiaali nimi või filiaali kood (7 numbrit)",
    labelCedulaJuridica910digitsTaxID: "Juriidiline tõend (9-10-kohaline maksu-ID)",
    labelTaxIDRUT89digits: "Maksu ID RUT (8-9 numbrit)",
    labelJob: "Töö pealkiri",
    labelDateOfBirth: "Sünnikuupäev",
    labelNationality: "Kodakondsus",
    labelDocumentType: "Dokumendi liik",
    labelDocumentNumber: "Dokumendi number",
    labelRutNumber: "RUT-number (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (maksu-ID individuaalne 8-11 numbrit)",
    labelCIIndividualTaxIDBranchLocation: "CI (individuaalne maksu-ID), filiaali asukoht",
    labelTaxIDRUCorCI: "Maksu ID (RUC või CI)",
    labelSortCodeHelp: "Abi?",
    labelBranchCodeHelp: "Abi?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Abi?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Abi?",
    labelRoutingCodeBSB: "Marsruudi kood / BSB",
    labelBSB: "Panga riigi filiaal (BSB)",
    labelSKNCode: "SKN-kood",
    labelAcceptanceFlights: "Nõustume, et kui tagasimaksetaotlus on edukas, tühistatakse broneering, mis hõlmab kõiki lende, ja lisatooted.",
    labelAcceptanceTickets: "Nõustun, et kui tagasimakse taotlus on edukas, tühistatakse broneering koos kõigi piletite ja lisatoodetega.",
    labelTermsAndConditions1: "Olen kokku leppinud",
    labelTermsAndConditions2: "Tingimused",
    labelFraudWarning1: "Usun, et selles tagasimakse taotlusvormis esitatud faktid on ehtsad ja tõesed. Ma saan aru, et mis tahes valeteavet või -tõendeid võib pidada kuriteoks ja edastada politseile või muudele õiguskaitseorganitele jälitamiseks.",
    labelFraudWarning2: "Kui kahtlustame tõendavate dokumentidega seotud pettust, edastatakse see küsimus viivitamatult kohalikele politseiasutustele. Ma aktsepteerin ja mõistan, et minu andmed edastatakse nendele asutustele, kui minu taotlust peetakse pettuseks ja sellest võib saada kriminaaluurimine.",
    validationTermsAndConditions: "Tingimustega tuleb kokku leppida",
    messageFailbankName: "Panga nimi ei tohi olla tühi",
    messageFailcustomerName: "Kliendi nimi ei tohi olla tühi",
    messageFailbankAccountNumber: "Kohustuslik väli",
    messageFailbankAccountNumberFormat: "Peab olema IBAN-vormingus",
    messageFailsortCode: "Kohustuslik väli",
    messageFailbankSwiftBic: "Saaja SWIFT BIC ei saa olla tühi",
    messageFailbankSwiftBicFormat: "Peab olema SWIFT-vormingus",
    messageFailbeneficiaryAddress1: "Aadress 1 ei saa olla tühi",
    messageFailbeneficiaryCity: "Linn ei saa olla tühi",
    messageFailbeneficiaryPostCode: "Sihtnumber ei saa olla tühi",
    messageFailremittanceLine3: "Kohustuslik väli",
    messageFailremittanceLine4: "Kohustuslik väli",
    modalHeading: "Kinnitage üksikasjad",
    modalLabelPleaseConfirmYourDetails: "Palun kinnitage oma andmed",
    modalValidationPleaseConfirm: "Palun kinnitage",
    modalValidationMustMatch: "Peab vastama eelmisele kirjele",
    tooltipBankAccountName: "Teie nimi, nagu see on teie pangakontol/pangaväljavõttel/pangakaardil.",
    tooltipBankAccountNameRecipient: "Saaja ees- ja perekonnanimi. Nii ees- kui ka perekonnanimel peab olema rohkem kui üks märk.",
    tooltipBankCountry: "Riik, kus teie pank asub.",
    tooltipBankName: "Teie panga nimi.",
    tooltipPaymentType: "Nõutav maksetüüp.",
    tooltipInstitutionNumber: "See on 3-kohaline ja tavaliselt leidub see teie kuuväljavõttel või pangakaardil või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAccountNumber: "Teie individuaalne kontonumber. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAccountNumberCBU: "22-kohaline number, mida kasutatakse teie konto tuvastamiseks. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAccountNumberCCI: "20-kohaline number, mida kasutatakse teie konto tuvastamiseks. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAccountNumberKRW: "11–16-kohaline number, mida kasutatakse teie konto tuvastamiseks. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCardNumber: "Teie kaardi number. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipUnionpay: "Teie UnionPay kaardi number.",
    tooltipAlipay: "Teie mobiilinumber või e-posti aadress, mis on seotud teie Alipay kontoga.",
    tooltipBankAccountType: "See on kas kontrollimine või kokkuhoid.",
    tooltipSortCode: "See tuvastab teie panga filiaali, kus teie kontot hoitakse. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipRoutingCode: "See tuvastab teie panga filiaali, kus teie kontot hoitakse. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBeneficiarySWIFTBIC: "Koosneb 8-11 tähemärgist, mida kasutatakse panga tuvastamiseks rahvusvahelises tehingus. Tavaliselt leiate selle oma igakuiselt väljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBSB: "Panga osariigi filiaali (BSB) number on Austraalia sortimiskood, millel on 6 numbrit. Tavaliselt leiate selle oma igakuiselt väljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipRoutingCodeBSB: "See tuvastab teie panga filiaali, kus teie kontot hoitakse. Tavaliselt leiate selle oma kuuväljavõttelt või pangakaardilt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipABAFedwireRoutingCode: "9-kohaline number, mida kasutatakse Ameerika pankade tuvastamiseks. Tavaliselt leiate selle oma igakuiselt väljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipIFSCCode: "Tavaliselt leiate selle koodi oma kuuväljavõttelt või passiraamatust või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipTermsAndConditions: "Tingimustega nõustudes kinnitate, et olete meie tingimused läbi lugenud ja nõustute nendega.",
    tooltipIBAN: "Teie rahvusvaheline pangakontonumber, mida saate kasutada rahvusvaheliste maksete tegemisel või vastuvõtmisel. Tavaliselt leiate selle oma igakuiselt väljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Saaja kontaktnimi ja telefoninumber.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "soodustatud isiku sünniaeg ja -koht.",
    tooltipBankRoutingCodeNationalID: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankTransitCodeSwift: "See on 5-kohaline ja selle leiate tavaliselt oma igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAddress: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankAddress2: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankCity: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankState: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBranchName: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBankCode: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBIKCode: "9 numbrit teie panga tuvastamiseks.",
    tooltipBranchCode: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBranchCodeUniversal: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCNAPSCode: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipRemittanceLine4: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCUILNumber: "See on 11-kohaline number, mis koosneb kahest numbrist, sidekriipsust, üheksast numbrist ja ühekohalisest kontrollsummast.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCFPNumber: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipBranchNameorBranchCode7digits: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCedulaJuridica910digitsTaxID: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipTaxIDRUT89digits: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipJob: "Teie praegune amet.",
    tooltipDateOfBirth: "Teie sünnikuupäev.",
    tooltipNationality: "Teie kodakondsus.",
    tooltipDocumentType: "Nõutav dokumendi tüüp.",
    tooltipDocumentNumber: "See on teie dokumenditüübi valikuga seotud dokumendinumber.",
    tooltipRutNumber: "Teie RUT-number (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipCIIndividualTaxIDBranchLocation: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    tooltipTaxIDRUCorCI: "Võib tavaliselt leida teie igakuiselt pangaväljavõttelt või kui te pole endiselt kindel, küsige oma pangast.",
    errorCPFCode: "Praegu aktsepteerime CPF-i ainult PIX-võtmena.",
};