export default {
    dividerBankingDetails: "Bankoplysninger",
    dividerBookingDetails: "Booking detaljer",
    labelSavedApplicationReference: "Gemt ansøgning Ref:",
    labelLanguage: "Sprog",
    messageWarningBookingHeading1: "Annullering af reservation:",
    messageWarningBookingHeading2: "Booking udsat / omplanlagt:",
    messageWarningBookingBody1: "Hvis din reservation er blevet annulleret af arrangøren eller flyselskabet, kan dette team ikke hjælpe dig med en refusion. Du skal kontakte dit kundeserviceteam direkte; se din reservationsbekræftelse eller dit websted for kontaktoplysninger.",
    messageWarningBookingBody2: "Hvis din reservation er udsat, overføres dine udvidede refunderbare vilkår til den nye dato. Hvis du har en gyldig grund til refusion under vores vilkår, skal du udfylde formularen med det korrekte bevis.",
    messageWarningBookingBody3: "Bemærk venligst: Denne service kan kun give refusion af de årsager, der er inkluderet i vores ordlyd, som er tilgængelig i din reservationsbekræftelses-e-mail.",
    messageWarningBookingBody4: "Hvis du ikke kan foretage reservationen på grund af årsager uden for vores vilkår, vil vi ikke være i stand til at hjælpe. Du skal kontakte dit kundeserviceteam direkte, da de muligvis kan hjælpe; se din reservationsbekræftelse eller hjemmeside for kontaktoplysninger.",
    linkWarningBooking: "her",
    formSectionNavigatorStep1: "Trin 1",
    formSectionNavigatorStep2: "Trin 2",
    formSectionNavigatorStep3: "Trin 3",
    formSectionNavigatorStep1Text: "Kontakt og reservationsdetaljer",
    formSectionNavigatorStep2Text: "Årsag til tilbagebetaling",
    formSectionNavigatorStep3Text: "Betalingsoplysninger",
    messageBookingMatchedAndFound: "Hvis din reservationsreference er knyttet til en eksisterende applikation, sender vi dig en e-mail med et link til at få adgang til din applikation",
    messageBookingMatchedAndFoundCompleted: "Din refusionsansøgning er allerede godkendt! Hvis du har problemer med din betaling, bedes du kontakte:",
    applicationDeclinedMessage: "Din ansøgning er blevet afvist og er nu lukket.",
    messageBookingNotFound1: "Vi kan ikke finde en reservation med denne reference. Tjek venligst din bookingbekræftelses-e-mail, og indtast igen.",
    messageBookingNotFound2: "Bare rolig, hvis din bookingreference ikke virker, kan du vælge 'Find booking' og indtaste dine oplysninger. Når du udfylder formularen, skal du uploade en kopi af din reservationsbekræftelses -e -mail for at hjælpe os med at finde dine bookingoplysninger.",
    messageLocatingBooking: "Find din reservaion...",
    messageLoadingBank: "Indlæer bankoplysninger...",
    messageFailCurrencyLookup: "Fejl ved hentning af valuta",
    messageSavingApplication: "Gemmer din ansøgning...",
    messageSavedApplication: "Refusionsappliktiotione n blev gemt",
    messageFailSavedApplication: " Refusionsappliktiotione n kunne ikke gemmes",
    messageSendingEmailReminder: "Forarbejdning...",
    messageErrorLocationDetails: "Fejl ved hentning af placeringsoplysninger",
    messageFailFormSubmission: "Formularindsenelsemislykedes",
    messageApplicationCannotBeFound: " Applikationen kunne ikke findes",
    messageApplicationRetrievalError: "Fejl ved hentning af applikation",
    messageSubmittingApplication: "Indsender ansøgning...",
    messageEventDateLimitError: "Årsag til underretning uden for fristen",
    messageAllTicketsAssigned: "Alle billetter er blevet tildelt en anden refusionsansøgning.",
    selectPrefix: "Vælg",
    selectPostfix: "",
    fileUpload: "Klik her for at uploade",
    fileUploadAcceptedFilesMessage: "Vi accepterer kun PDF -filer og billedformater af .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 valgte filler",
    fileUploadPreviouslyUploaded: "Tidligere uploadet:",
    fileUploadSelectedUploaded: "De valgte er allerede blevet uploadet:",
    buttonNext: "Næste",
    buttonPrevious: "Tidligere",
    buttonSave: "Gemme",
    buttonCancel: "Afbestille",
    buttonSubmit: "Indsend",
    buttonConfirm: "Bekræft",
    buttonYes: "Ja",
    buttonNo: "Ingen",
    buttonClose: "Tæt",
    linkPrivacyStatement: "Fortroligheddserklæring",
    linkComplaintsPolicy: "Klagepolitik",
    validationRequired: "Påkrævet",
    validationInvalid: "Ugyldig",
    labelUploadPrefix: "Upload",
    labelUploadPostfix: "",
    labelChange: "Forandre",
    labelView: "Udsigt",
    browserNavigationMessage: "Ikke-gemte ændringer vil gå tabt"
}