export default {
    dividerBankingDetails: "รายละเอียดการธนาคาร",
    dividerBookingDetails: "รายละเอียดการจอง",
    labelSavedApplicationReference: "แอปพลิเคชันที่บันทึกไว้อ้างอิง:",
    labelLanguage: "ภาษา",
    messageWarningBookingHeading1: "ยกเลิกการจอง:",
    messageWarningBookingHeading2: "เลื่อนการจอง / กำหนดเวลาใหม่:",
    messageWarningBookingBody1: "หากผู้จัดหรือสายการบินยกเลิกการจองของคุณทีมนี้จะไม่สามารถช่วยเหลือคุณในการคืนเงินได้ คุณจะต้องติดต่อทีมบริการลูกค้าของคุณโดยตรง ดูข้อมูลยืนยันการจองหรือเว็บไซต์สำหรับรายละเอียดการติดต่อ",
    messageWarningBookingBody2: "หากการจองของคุณถูกเลื่อนออกไปข้อกำหนดที่สามารถขอคืนเงินเพิ่มเติมของคุณจะมีผลต่อวันที่ใหม่ หากคุณมีเหตุผลที่ถูกต้องในการคืนเงินภายใต้เงื่อนไขของเราโปรดกรอกแบบฟอร์มพร้อมหลักฐานประกอบที่ถูกต้อง",
    messageWarningBookingBody3: "โปรดทราบ: บริการนี้สามารถคืนเงินได้เฉพาะเหตุผลที่รวมอยู่ในข้อความของเรา ซึ่งมีอยู่ในอีเมลยืนยันการจองของคุณ",
    messageWarningBookingBody4: "หากคุณไม่สามารถทำการจองได้เนื่องจากเหตุผลที่อยู่นอกเหนือข้อกำหนดของเรา เราจะไม่สามารถให้ความช่วยเหลือได้ คุณจะต้องติดต่อทีมบริการลูกค้าของคุณโดยตรง เนื่องจากอาจสามารถช่วยได้ ดูข้อมูลยืนยันการจองหรือเว็บไซต์สำหรับรายละเอียดการติดต่อ",
    linkWarningBooking: "ที่นี่",
    formSectionNavigatorStep1: "ขั้นตอนที่ 1",
    formSectionNavigatorStep2: "ขั้นตอนที่ 2",
    formSectionNavigatorStep3: "ขั้นตอนที่ 3",
    formSectionNavigatorStep1Text: "รายละเอียดการติดต่อและการจอง",
    formSectionNavigatorStep2Text: "เหตุผลในการคืนเงิน",
    formSectionNavigatorStep3Text: "รายละเอียดการชำระเงิน",
    messageBookingMatchedAndFound: "หากข้อมูลอ้างอิงการจองของคุณเชื่อมโยงกับใบสมัครที่มีอยู่ เราจะส่งอีเมลพร้อมลิงก์เพื่อเข้าถึงใบสมัครของคุณ",
    messageBookingMatchedAndFoundCompleted: "ใบสมัครขอเงินคืนของคุณได้รับการอนุมัติแล้ว! หากคุณมีปัญหาใด ๆ เกี่ยวกับการชำระเงินของคุณโปรดติดต่อ:",
    applicationDeclinedMessage: "ใบสมัครของคุณถูกปฏิเสธและปิดแล้ว",
    messageBookingNotFound1: "เราไม่พบการจองที่มีข้อมูลอ้างอิงนั้น โปรดตรวจสอบอีเมลยืนยันการจองและป้อนใหม่อีกครั้ง",
    messageBookingNotFound2: "ไม่ต้องกังวล หากการอ้างอิงการจองของคุณใช้ไม่ได้ คุณสามารถเลือก 'ค้นหาการจอง' และป้อนรายละเอียดของคุณ เมื่อกรอกแบบฟอร์ม โปรดอัปโหลดสำเนาอีเมลยืนยันการจองของคุณ เพื่อช่วยเราค้นหารายละเอียดการจองของคุณ",
    messageLocatingBooking: "กำลังค้นหาการจองของคุณ ... ",
    messageLoadingBank: "กำลังโหลดข้อมูลธนาคาร ... ",
    messageFailCurrencyLookup: "เกิดข้อผิดพลาดในการเรียกสกุลเงิน",
    messageSavingApplication: "กำลังบันทึกแอปพลิเคชันของคุณ ... ",
    messageSavedApplication: "บันทึกแอปพลิเคชันคืนเงินเรียบร้อยแล้ว",
    messageFailSavedApplication: "ไม่สามารถบันทึกแอปพลิเคชันการคืนเงินได้",
    messageSendingEmailReminder: "กำลังประมวลผล...",
    messageErrorLocationDetails: "เกิดข้อผิดพลาดในการเรียกข้อมูลรายละเอียดตำแหน่ง",
    messageFailFormSubmission: "การส่งแบบฟอร์มล้มเหลว",
    messageApplicationCannotBeFound: "ไม่พบแอปพลิเคชัน",
    messageApplicationRetrievalError: "ข้อผิดพลาดในการเรียกแอปพลิเคชัน",
    messageSubmittingApplication: "กำลังส่งใบสมัคร ... ",
    messageEventDateLimitError: "เหตุผลในการแจ้งเตือนนอกเวลา จำกัด ",
    messageAllTicketsAssigned: "ตั๋วทั้งหมดถูกกำหนดให้กับแอปพลิเคชันขอคืนเงินอื่น",
    selectPrefix: "เลือก",
    selectPostfix: "",
    fileUpload: "คลิกที่นี่เพื่ออัปโหลด",
    fileUploadAcceptedFilesMessage: "เรายอมรับเฉพาะ PDF และรูปแบบรูปภาพของ .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 ไฟล์ที่เลือก",
    fileUploadPreviouslyUploaded: "อัปโหลดก่อนหน้านี้:",
    fileUploadSelectedUploaded: "อัปโหลดที่เลือกไว้แล้ว:",
    buttonNext: "ถัดไป",
    buttonPrevious: "ก่อนหน้า",
    buttonSave: "บันทึก",
    buttonCancel: "ยกเลิก",
    buttonSubmit: "ส่ง",
    buttonConfirm: "ยืนยัน",
    buttonYes: "ใช่",
    buttonNo: "ไม่",
    buttonClose: "ปิด",
    linkPrivacyStatement: "คำชี้แจงสิทธิ์ส่วนบุคคล",
    linkComplaintsPolicy: "นโยบายการร้องเรียน",
    validationRequired: "จำเป็น",
    validationInvalid: "ไม่ถูกต้อง",
    labelUploadPrefix: "ที่อัพโหลด",
    labelUploadPostfix: "",
    labelChange: "เปลี่ยน",
    labelView: "ทิวทัศน์",
    browserNavigationMessage: "การเปลี่ยนแปลงที่ไม่ได้บันทึกจะหายไป"
};