import * as React from 'react';
import {
    TextField,
    Button
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps
} from 'formik';
import {
    BorderRadiusInputField,
    MinWidthInputField
} from '../utils/constants-styling';
import {IInputProps} from './IInputProps';
import {isInvalid} from '../utils/validationHelpers';
import {useState} from 'react';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {IFormValues} from '../Interfaces/IFormValues';

interface NumberInputProps extends IInputProps {
    buttonAddOnClickEvent?: () => void;
    buttonAddOnText?: string;
}

export const NumberInput = (props: NumberInputProps) => {

    const {
        buttonAddOnClickEvent,
        buttonAddOnText,
        controlId,
        disabled,
        hidden,
        invalid,
        label,
        onChange,
        placeholder,
        required,
        tooltip
    } = props;

    const [numberInputValue, setNumberInputValue] = useState(null);

    const handleOnBlur = (fieldProps: FieldProps<IFormValues>) => (event: React.FocusEvent<HTMLInputElement>) => {
        void fieldProps.form.setFieldValue(controlId, event.target.value);
        void fieldProps.form.setTouched({...fieldProps.form.touched, [controlId]: true}, false);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberInputValue(event.target.value);

        if (onChange && typeof (onChange) === 'function') {
            onChange(event);
        }
    }

    const ButtonAdornment = () => {
        return (
            <>
                {buttonAddOnText !== null &&
                    <Button 
                        variant='contained'
                        sx={{
                            borderBottomLeftRadius: BorderRadiusInputField,
                            borderTopLeftRadius: BorderRadiusInputField
                        }}
                        onClick={buttonAddOnClickEvent}>{buttonAddOnText}
                    </Button>
                }
                {buttonAddOnText === null &&
                    null
                }
            </>
        );
    }

    return (
        <>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues>) => (
                        <Grid container alignItems='center' columns={{ xs: 1, sm: 12,  md: 12 }} pb={2}>
                            <FormLabelHolder
                                label={label}
                                required={required}
                                tooltip={tooltip}
                            />
                            <Grid size={{xs: 6, md: 5}}>
                                <TextField
                                    name={controlId}
                                    variant='outlined'
                                    type='number'
                                    size='small'
                                    placeholder={placeholder}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur(fieldProps)}
                                    value={numberInputValue ?? fieldProps.form.getFieldProps(controlId).value}
                                    error={isInvalid(fieldProps)}
                                    disabled={disabled}
                                    fullWidth
                                    helperText={fieldProps.meta.touched ? fieldProps.meta.error : ''}
                                    sx={{
                                        'fieldset': {
                                            borderRadius: BorderRadiusInputField,
                                            minWidth: MinWidthInputField
                                        },
                                        '.MuiInputBase-input': {
                                            pl: '10px'
                                        },
                                        '.MuiOutlinedInput-root': {
                                            pl: '3px'
                                        }
                                    }}
                                    slotProps={{
                                        input: {
                                            startAdornment: <ButtonAdornment />
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Field>
            }
        </>
    );
}