import * as React from "react";
import {
    DateInput,
    FileInput,
    PhoneInput,
    SelectInput,
    TextInput
} from '../formInputs';
import {
    isAutoDeclineReason,
    isNullOrWhitespace
} from '../utils/helpers';
import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section';
import {FormikProps} from 'formik';
import {nameof} from 'ts-simple-nameof';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import {BookingConfirmationFilesState} from '../../state/activity';
import {RefundReasonTestOption} from '../utils/constants';
import {CountryCurrencyInvalidAlert} from '../alerts/CountryCurrencyInvalidAlert';
import {CustomerNameSearchModal} from '../modals/CustomerNameSearchModal';
import {ProductSelectionControl} from '../controls/ProductSelectionControl';
import {CurrencySelectsControl} from '../controls/CurrencySelectsControl';
import {CurrencyInputsControl} from '../controls/CurrencyInputsControl';
import {SectionDivider} from '../ui/SectionDivider';
import {SectionHeadingTextTheme} from './SectionHeadingTextTheme';

interface BookingAndContactInformationPage {
    formProps: FormikProps<IFormValues>;
    memberId?: string;
}

export const BookingAndContactInformationSection = (props: BookingAndContactInformationPage) => {

    const {
        formProps,
        memberId
    } = props;

    const {
        bookingCounter,
        bookingMatched,
        bookingRefLookupAttempted,
        clearFiles,
        dateOfEventLabel,
        dateOfEventTooltip,
        isBookingConfirmationRequired,
        isEventHidden,
        isFlightHidden,
        isHotelHidden,
        previousBookingConfirmationFiles,
        selectedSection,
        setClearFiles,
        showBookingTypeFields
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            {bookingCounter <= 1 || selectedSection === Section.BookingAndContactInformation &&
                <>
                    <SectionHeadingTextTheme text={t(`common:formSectionNavigatorStep1Text`)}/>

                    <CountryCurrencyInvalidAlert/>

                    <TextInput
                        key={nameof<IFormValues>(x => x.customerName)}
                        placeholder={t('form:labelCustomerName')}
                        label={t('form:labelCustomerName')}
                        hidden={false}
                        controlId={nameof<IFormValues>(x => x.customerName)}
                        maxLength={64}
                        tooltip={t('form:validationCustomerName')}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.emailAddress)}
                        placeholder={t('form:labelEmailAddress')}
                        label={t('form:labelEmailAddress')}
                        controlId={nameof<IFormValues>(x => x.emailAddress)}
                        hidden={false}
                        tooltip={t('form:tooltipEmailAddress')}
                        maxLength={100}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.confirmEmailAddress)}
                        placeholder={t('form:labelConfirmEmailAddress')}
                        label={t('form:labelConfirmEmailAddress')}
                        controlId={nameof<IFormValues>(x => x.confirmEmailAddress)}
                        hidden={false}
                        tooltip={t('form:tooltipConfirmEmailAddress')}
                        maxLength={100}
                        required
                    />

                    <PhoneInput
                        key={nameof<IFormValues>(x => x.contactNumber)}
                        autoCompleteId={nameof<IFormValues>(x => x.contactNumberCountryCode)}
                        placeholder={t('form:labelContactNumber')}
                        controlId={nameof<IFormValues>(x => x.contactNumber)}
                        label={t('form:labelContactNumber')}
                        hidden={false}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.typeOfBooking)}
                        label={t('form:labelTypeOfBooking')}
                        controlId={nameof<IFormValues>(x => x.typeOfBooking)}
                        hidden={false}
                        datasourceUrl='/api/referencedata/eventTypes'
                        disabled={false}
                        prefixKey={'form:optionTypeOfBooking'}
                        onChange={(event) => showBookingTypeFields(+event.target.value)}
                        tooltip={t('form:tooltipTypeOfBooking')}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.eventName)}
                        label={t('form:labelEventName')}
                        placeholder={t('form:labelEventName')}
                        controlId={nameof<IFormValues>(x => x.eventName)}
                        hidden={isEventHidden}
                        tooltip={t('form:tooltipEventName')}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.venueName)}
                        placeholder={t('form:labelVenueName')}
                        label={t('form:labelVenueName')}
                        controlId={nameof<IFormValues>(x => x.venueName)}
                        hidden={isEventHidden}
                        tooltip={t('form:tooltipVenueName')}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.flightNumber)}
                        placeholder={t('form:labelFlightNumber')}
                        label={t('form:labelFlightNumber')}
                        controlId={nameof<IFormValues>(x => x.flightNumber)}
                        hidden={isFlightHidden}
                        tooltip={t('form:tooltipFlightNumber')}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.originAirport)}
                        placeholder={t('form:labelOriginAirport')}
                        label={t('form:labelOriginAirport')}
                        controlId={nameof<IFormValues>(x => x.originAirport)}
                        hidden={isFlightHidden}
                        tooltip={t('form:tooltipOriginAirport')}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.hotelName)}
                        placeholder={t('form:labelHotelName')}
                        label={t('form:labelHotelName')}
                        controlId={nameof<IFormValues>(x => x.hotelName)}
                        hidden={isHotelHidden}
                        tooltip={t('form:tooltipHotelName')}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.hotelLocation)}
                        placeholder={t('form:labelHotelLocation')}
                        label={t('form:labelHotelLocation')}
                        controlId={nameof<IFormValues>(x => x.hotelLocation)}
                        hidden={isHotelHidden}
                        tooltip={t('form:tooltipHotelLocation')}
                    />

                    <SectionDivider dividerText={t('common:dividerBookingDetails')}/>

                    <FileInput
                        key={nameof<IFormValues>(x => x.bookingConfirmationFiles)}
                        label={t('form:labelBookingConfirmationFiles')}
                        controlId={nameof<IFormValues>(x => x.bookingConfirmationFiles)}
                        hidden={false}
                        clearFiles={clearFiles}
                        setClearFiles={setClearFiles}
                        setFieldValue={formProps.setFieldValue}
                        previouslyUploaded={previousBookingConfirmationFiles}
                        tooltip={t('form:tooltipBookingConfirmationFiles')}
                        required={formProps.values.refundReasonCategory !== RefundReasonTestOption &&
                            isBookingConfirmationRequired &&
                            !isAutoDeclineReason(formProps.values.refundReasonCategory)}
                        state={BookingConfirmationFilesState}
                        formProps={formProps}
                    />

                    <DateInput
                        key={nameof<IFormValues>(x => x.dateOfPurchase)}
                        label={t('form:labelDateOfPurchase')}
                        controlId={nameof<IFormValues>(x => x.dateOfPurchase)}
                        hidden={(!bookingRefLookupAttempted || bookingMatched) && !isNullOrWhitespace(nameof<IFormValues>(x => x.dateOfPurchase))}
                        disabled={bookingMatched && !isNullOrWhitespace(nameof<IFormValues>(x => x.dateOfPurchase))}
                        tooltip={t('form:tooltipDateOfPurchase')}
                        required
                    />

                    <DateInput
                        key={nameof<IFormValues>(x => x.dateOfEvent)}
                        label={dateOfEventLabel}
                        controlId={nameof<IFormValues>(x => x.dateOfEvent)}
                        hidden={(!bookingRefLookupAttempted || bookingMatched) && !isNullOrWhitespace(nameof<IFormValues>(x => x.dateOfEvent))}
                        disabled={bookingMatched && !isNullOrWhitespace(nameof<IFormValues>(x => x.dateOfEvent))}
                        tooltip={dateOfEventTooltip}
                        required
                    />

                    <ProductSelectionControl formProps={formProps}/>

                    <CurrencySelectsControl formProps={formProps}/>

                    <CurrencyInputsControl formProps={formProps}/>

                    <CustomerNameSearchModal formProps={formProps}/>

                </>
            }
        </>
    );
}