export default {
    messageQuestionService: "該服務僅在您填寫申請表有問題時使用，我們無法在此處回答任何其他問題。",
    labelName: "姓名",
    labelReference: "預訂參考",
    labelPhone: "電話號碼",
    labelEmail: "電子郵件",
    labelQuery: "你的問題",
    labelAskQuestion: "在這裡給我們發一個問題...",
    buttonOpenModal: "問題",
    title: "問一個問題",
    modalTitle: "4.表格有技術問題嗎？",
    toastSuccess: "我們已收到您的問題，我們將在24小時內與您聯繫",
    toastError: "您的請求出了點問題，請重試",
    nameValidation: "名稱為必填項",
    referenceValidation: "需要參考",
    questionValidation: "您的問題是必需的"
}