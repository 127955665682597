export default {
    messageUnableToMakePaymentToCurrency: "Δυστυχώς δεν μπορούμε να ανακτήσουμε τραπεζικά στοιχεία για το επιλεγμένο νόμισμα. Βεβαιωθείτε ότι έχετε επιλέξει Νόμισμα και Χώρα προέλευσης",
    messageOnlyBankAccountsAccepted: "Σημείωση: Μπορούμε να κάνουμε πληρωμές μόνο σε τραπεζικούς λογαριασμούς αυτήν τη στιγμή (οι πιστωτικές και χρεωστικές κάρτες δεν γίνονται δεκτές)",
    labelAccountName: "Όνομα Κατόχου Λογαριασμού",
    labelBankAccountName: "Πλήρες Όνομα Κατόχου Λογαριασμού",
    labelBankCardHolderName: "Ονομα ιδιοκτήτη κάρτας",
    validationBankAccountName: "Απαιτείται πλήρες όνομα κατόχου λογαριασμού",
    labelBankCountry: "Τράπεζα Χώρα",
    labelBankName: "όνομα τράπεζας",
    labelPaymentType: "Τρόπος πληρωμής",
    labelInstitutionNumber: "Αριθμός ιδρύματος",
    labelBankAddress: "Διεύθυνση τράπεζας",
    labelBankAddress2: "Διεύθυνση τράπεζας",
    labelBankCity: "Πόλη της τράπεζας",
    labelBankState: "Τραπεζικό κράτος",
    labelBankAccountNumber: "Αριθμός τραπεζικού λογαριασμού",
    labelBankAccountNumberCBU: "Αριθμός λογαριασμού CBU",
    labelBankAccountNumberCCI: "Αριθμός λογαριασμού CCI",
    labelBankAccountNumberZAR: "Αριθμός τραπεζικού λογαριασμού (7-11 ψηφία)",
    labelCardNumber: "Αριθμός κάρτας",
    labelUnionpay: "Αριθμός κάρτας UnionPay",
    labelAlipay: "Αναγνωριστικό Alipay (αριθμός κινητού ή email)",
    labelBankAccountType: "Τύπος Λογαριασμού",
    optionBankAccountTypeCURRENT: "Τρεχούμενος λογαριασμός",
    optionBankAccountTypeCHECKING: "Τρεχούμενος λογαριασμός",
    optionBankAccountTypeSAVING: "Κύριος λογαριασμός",
    optionBankAccountTypeSAVINGS: "Κύριος λογαριασμός",
    optionBankAccountTypeCUENTA_VISTA: "Αποταμιευτικός λογαριασμός",
    optionBankAccountType1: "Τρεχούμενος λογαριασμός",
    optionBankAccountType2: "Αποταμιευτικός λογαριασμός",
    optionBankAccountType3: "Κύριος λογαριασμός",
    optionBankAccountType4: "Λογαριασμός Vista",
    optionBankAccountType5: "Λογαριασμός RUT",
    labelSortCode: "Κωδικός είδους",
    labelRoutingCode: "Κώδικας δρομολόγησης",
    labelBankRoutingCodeNationalID: "Κωδικός δρομολόγησης τράπεζας / Εθνική Ταυτότητα",
    labelBankTransitCodeSwift: "Αριθμός συγκοινωνίας",
    labelCNAPSCode: "Κωδικός δρομολόγησης / Κωδικός CNAPS (Κωδικός Εθνικού Προηγμένου Συστήματος Πληρωμών της Κίνας)",
    labelABAFedwireRoutingCode: "Αριθμός δρομολόγησης ACH",
    labelIFSCCode: "Κωδικός IFSC",
    labelBankCode: "Τραπεζικός κωδικός",
    labelBIKCode: "Κωδικός BIK",
    labelBranchCode: "Κωδικός υποκαταστήματος",
    labelBranchCodeUniversal: "Καθολικός 6ψήφιος κωδικός υποκαταστήματος",
    labelRemittanceLine3: "Όνομα και Τηλέφωνο Επικοινωνίας Δικαιούχου",
    labelBeneficiaryContactNameandTelephoneNumber: "Όνομα και Τηλέφωνο Επικοινωνίας Δικαιούχου",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Δικαιούχος Ημερομηνία Γέννησης και Τόπος Γέννησης",
    labelRemittanceLine4: "Ατομικός ΑΦΜ CUIL (9-12 ψηφία)",
    labelCUILNumber: "ΑΦΜ: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "ΑΦΜ RFC 13 ψηφίων ή CURP 18 ψηφίων",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Ατομικό ΑΦΜ CPF Cadastro de Pessoa Física (11-14 ψηφία), Κωδικός υποκαταστήματος",
    labelCFPNumber: "Αριθμός CPF (11-14 ψηφία)",
    labelBranchNameorBranchCode7digits: "Όνομα υποκαταστήματος ή κωδικός υποκαταστήματος (7 ψηφία)",
    labelCedulaJuridica910digitsTaxID: "Νομικό Πιστοποιητικό (ΑΦΜ 9-10 ψηφίων)",
    labelTaxIDRUT89digits: "ΑΦΜ RUT (8-9 ψηφία)",
    labelJob: "Τίτλος εργασίας",
    labelDateOfBirth: "Ημερομηνια γεννησης",
    labelNationality: "Ιθαγένεια",
    labelDocumentType: "Είδος αρχείου",
    labelDocumentNumber: "Αριθμός Εγγράφου",
    labelRutNumber: "Αριθμός RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (ΑΦΜ Ατομικό 8-11 ψηφία)",
    labelCIIndividualTaxIDBranchLocation: "CI (Ατομικό ΑΦΜ), Τοποθεσία Υποκαταστήματος",
    labelTaxIDRUCorCI: "ΑΦΜ (RUC ή CI)",
    labelSortCodeHelp: "Βοήθεια?",
    labelBranchCodeHelp: "Βοήθεια?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Βοήθεια?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Βοήθεια?",
    labelRoutingCodeBSB: "Κωδικός δρομολόγησης/BSB",
    labelBSB: "Υποκατάστημα Τράπεζας State (BSB)",
    labelSKNCode: "Κωδικός SKN",
    labelAcceptanceFlights: "Αποδέχομαι/αποδέχομαι ότι εάν η αίτηση επιστροφής χρημάτων είναι επιτυχής, η κράτηση, συμπεριλαμβανομένων όλων των πτήσεων, και των βοηθητικών προϊόντων θα ακυρωθεί.",
    labelAcceptanceTickets: "Αποδέχομαι/αποδέχομαι ότι εάν η αίτηση επιστροφής χρημάτων είναι επιτυχής, η κράτηση συμπεριλαμβανομένων όλων των εισιτηρίων και των βοηθητικών προϊόντων θα ακυρωθεί.",
    labelTermsAndConditions1: "Εγώ/έχουμε συμφωνήσει στο",
    labelTermsAndConditions2: "Οροι και Προϋποθέσεις",
    labelFraudWarning1: "Πιστεύω ότι τα στοιχεία που παρέχονται σε αυτήν την αίτηση επιστροφής χρημάτων είναι γνήσια και αληθινά. Κατανοώ ότι οποιαδήποτε ψευδή πληροφορία ή αποδεικτικό στοιχείο μπορεί να θεωρηθεί εγκληματική ενέργεια και να διαβιβαστεί στην αστυνομία ή σε άλλες υπηρεσίες επιβολής του νόμου για δίωξη.",
    labelFraudWarning2: "Όπου υποπτευόμαστε απάτη σε σχέση με τα δικαιολογητικά, το θέμα θα παραπεμφθεί αμέσως στις τοπικές αστυνομικές αρχές. Αποδέχομαι και κατανοώ ότι τα στοιχεία μου θα γνωστοποιηθούν σε αυτές τις αρχές εάν η αίτησή μου θεωρηθεί δόλια και αυτό μπορεί να γίνει ποινική έρευνα.",
    validationTermsAndConditions: "Οι όροι και οι προϋποθέσεις πρέπει να συμφωνηθούν",
    messageFailbankName: "Το όνομα της τράπεζας δεν μπορεί να είναι κενό",
    messageFailcustomerName: "Το όνομα πελάτη δεν μπορεί να είναι κενό",
    messageFailbankAccountNumber: "Υποχρεωτικό πεδίο",
    messageFailbankAccountNumberFormat: "Πρέπει να είναι σε μορφή IBAN",
    messageFailsortCode: "Υποχρεωτικό πεδίο",
    messageFailbankSwiftBic: "Το SWIFT BIC του δικαιούχου δεν μπορεί να είναι κενό",
    messageFailbankSwiftBicFormat: "Πρέπει να είναι μορφή SWIFT",
    messageFailbeneficiaryAddress1: "Η διεύθυνση 1 δεν μπορεί να είναι κενή",
    messageFailbeneficiaryCity: "Η πόλη δεν μπορεί να είναι κενή",
    messageFailbeneficiaryPostCode: "Ο ταχυδρομικός κώδικας δεν μπορεί να είναι κενός",
    messageFailremittanceLine3: "Υποχρεωτικό πεδίο",
    messageFailremittanceLine4: "Υποχρεωτικό πεδίο",
    modalHeading: "Επιβεβαίωση Στοιχείων",
    modalLabelPleaseConfirmYourDetails: "Επιβεβαιώστε τα στοιχεία σας",
    modalValidationPleaseConfirm: "Παρακαλώ Επιβεβαιώστε",
    modalValidationMustMatch: "Πρέπει να ταιριάζει με την προηγούμενη καταχώριση",
    tooltipBankAccountName: "Το όνομά σας όπως εμφανίζεται στον τραπεζικό λογαριασμό/τα αντίγραφα κίνησης/τραπεζική κάρτα σας.",
    tooltipBankAccountNameRecipient: "Όνομα και επίθετο του παραλήπτη. Τόσο το όνομα όσο και το επώνυμο πρέπει να έχουν περισσότερους από έναν χαρακτήρες.",
    tooltipBankCountry: "Η χώρα στην οποία βρίσκεται η τράπεζά σας.",
    tooltipBankName: "Το όνομα της τράπεζάς σας.",
    tooltipPaymentType: "Το είδος της πληρωμής που χρειάζεστε.",
    tooltipInstitutionNumber: "Αυτό είναι 3 ψηφία και συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή εάν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAccountNumber: "Ο ατομικός αριθμός λογαριασμού σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAccountNumberCBU: "Ένας 22ψήφιος αριθμός που χρησιμοποιείται για την αναγνώριση του λογαριασμού σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAccountNumberCCI: "Ένας 20ψήφιος αριθμός που χρησιμοποιείται για την αναγνώριση του λογαριασμού σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAccountNumberKRW: "Ένας 11-16ψήφιος αριθμός που χρησιμοποιείται για την αναγνώριση του λογαριασμού σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCardNumber: "Ο αριθμός της κάρτας σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipUnionpay: "Ο αριθμός της κάρτας σας UnionPay.",
    tooltipAlipay: "Ο αριθμός του κινητού σας ή η διεύθυνση email που σχετίζεται με τον λογαριασμό σας Alipay.",
    tooltipBankAccountType: "Αυτό είναι είτε Έλεγχος είτε Αποταμίευση.",
    tooltipSortCode: "Αυτό προσδιορίζει το υποκατάστημα της τράπεζάς σας όπου βρίσκεται ο λογαριασμός σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipRoutingCode: "Αυτό προσδιορίζει το υποκατάστημα της τράπεζάς σας όπου βρίσκεται ο λογαριασμός σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBeneficiarySWIFTBIC: "Αποτελείται από 8-11 χαρακτήρες που χρησιμοποιούνται για την αναγνώριση μιας τράπεζας σε μια διεθνή συναλλαγή. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBSB: "Ένας αριθμός Bank State Branch (BSB) είναι ένας αυστραλιανός κωδικός ταξινόμησης που έχει 6 ψηφία. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipRoutingCodeBSB: "Αυτό προσδιορίζει το υποκατάστημα της τράπεζάς σας όπου βρίσκεται ο λογαριασμός σας. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή στην τραπεζική σας κάρτα ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipABAFedwireRoutingCode: "Ένας 9ψήφιος αριθμός που χρησιμοποιείται για την αναγνώριση τραπεζών στην Αμερική. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipIFSCCode: "Συνήθως αυτός ο κωδικός μπορεί να βρεθεί στο μηνιαίο αντίγραφο κίνησης ή στο βιβλιάριο σας ή εάν εξακολουθείτε να μην είστε σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipTermsAndConditions: "Με την αποδοχή των όρων και προϋποθέσεων επιβεβαιώνετε ότι έχετε διαβάσει και συμφωνείτε με τους Όρους και τις Προϋποθέσεις μαςs.",
    tooltipIBAN: "Ο διεθνής αριθμός τραπεζικού λογαριασμού σας, τον οποίο μπορείτε να χρησιμοποιήσετε όταν κάνετε ή λαμβάνετε διεθνείς πληρωμές. Συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Όνομα και Τηλέφωνο Επικοινωνίας Δικαιούχου.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Δικαιούχος Ημερομηνία Γέννησης και Τόπος Γέννησης.",
    tooltipBankRoutingCodeNationalID: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankTransitCodeSwift: "Αυτό είναι 5 ψηφία και συνήθως βρίσκεται στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή εάν εξακολουθείτε να μην είστε σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAddress: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankAddress2: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankCity: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας",
    tooltipBankState: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας",
    tooltipBranchName: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBankCode: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBIKCode: "9 ψηφία για την αναγνώριση της τράπεζάς σας.",
    tooltipBranchCode: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBranchCodeUniversal: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCNAPSCode: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipRemittanceLine4: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCUILNumber: "Αυτός είναι ένας 11ψήφιος αριθμός, που αποτελείται από δύο ψηφία, παύλα, εννέα ψηφία και ένα μονοψήφιο άθροισμα ελέγχου.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCFPNumber: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipBranchNameorBranchCode7digits: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCedulaJuridica910digitsTaxID: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipTaxIDRUT89digits: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipJob: "Το τωρινό σας επάγγελμα.",
    tooltipDateOfBirth: "Η ημερομηνία γέννησής σου.",
    tooltipNationality: "Η εθνικότητά σου.",
    tooltipDocumentType: "Ο απαιτούμενος τύπος εγγράφου.",
    tooltipDocumentNumber: "Αυτός είναι ο αριθμός εγγράφου που σχετίζεται με την επιλογή τύπου εγγράφου.",
    tooltipRutNumber: "Ο αριθμός RUT σας (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    tooltipCIIndividualTaxIDBranchLocation: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας",
    tooltipTaxIDRUCorCI: "Συνήθως μπορείτε να το βρείτε στο μηνιαίο αντίγραφο κίνησης του τραπεζικού σας λογαριασμού ή αν δεν είστε ακόμα σίγουροι, ρωτήστε την τράπεζά σας.",
    errorCPFCode: "Προς το παρόν δεχόμαστε μόνο CPF ως κλειδί PIX."
};