export default {
    headingAdverseWeather: "אתה חווה תנאי מזג אוויר קשים קשים שהופכים את הנסיעה לאירוע לבלתי בטוחה וזה נקבע על ידי גוף ממשלתי (למשל רשות מקומית, משטרה וכו '). ויש לך ראיות להוכיח את זה?",
    headingAdverseWeatherDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    tooltipRefundReasonConfirm: "כן או לא",
    labelBookingCancelledByOperator: "הכרטיסים בוטלו על ידי המפעיל / המארגן.",
    labelBookingRescheduledByOperator: "התאריך שונה על ידי המפעיל / המארגן ואני לא יכול להשתתף בתאריכים החדשים.",
    labelBookedWrongDate: "אני או חבר בהזמנה, טעינו בעת רכישת הכרטיסים והזמנו את התאריך הלא נכון.",
    labelBookedWrongTickets: "אני או חבר בהזמנה, טעיתי בעת רכישת הכרטיסים והזמנו את הכרטיסים הלא נכונים.",
    labelBookedTooManyTickets: "אני או חבר בהזמנה, עשינו טעות בעת רכישת הכרטיסים והזמנו יותר מדי כרטיסים.",
    labelCOVID19TravelRestrictions: "אני או חבר בהזמנה, לא יכולים להשתתף בגלל איסור נסיעה של COVID-19.",
    labelADWrongSelection: "ייתכן שבחרת את הסיבה הלא נכונה להחזר כספי. אנא עיין שוב ברשימה הנפתחת 'סיבת ההחזר הכספי'.",
    labelAdverseWeather1: "מדוע מזג אוויר קשה גרם לכך שלא יכולתם להשתתף באירוע?",
    tooltipAdverseWeather1: "אנא פרטו",
    labelAdverseWeather2: "תאריך האירוע",
    tooltipAdverseWeather2: "אנא בחר",
    headingArmedForces: "אתה מתבקש על ידי המעסיק שלך איזה מהם הכוחות המזוינים או שירותי החירום להיכנס לעבודה כאשר לא היית אמור במקור לעשות זאת וזה מונע ממך להשתתף באירוע?",
    headingArmedForcesDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelArmedForces1: "באילו כוחות או שירות חירום אתם מועסקים?",
    tooltipArmedForces1: "אנא בחר",
    labelArmedForcesOther1: "נא לציין",
    tooltipArmedForcesOther1: "נא לציין",
    labelArmedForces2: "תאריך האירוע",
    tooltipArmedForces2: "אנא בחר",
    headingChangesToExam: "מועדי הבחינה שונו על ידי בית הספר/המכללה וכו'? זו לא בחינה כושלת שעליכם לחזור עליה?",
    headingChangesToExamDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelExam1: "מה היה תאריך הבדיקה המקורי?",
    tooltipExam1: "אנא בחר",
    labelExam2: "מהו תאריך הבחינה החדש?",
    tooltipExam2: "אנא בחר",
    labelExam3: "מדוע השתנה תאריך הבדיקה?",
    tooltipExam3: "אנא הזן פרטים",
    labelExam4: "האם נכשלת בבדיקה הקודמת?",
    tooltipExam4: "כן או לא",
    headingCourtSummons: "זומנת להופיע בבית המשפט כעד באותם תאריכים כמו האירוע?",
    headingCourtSummonsDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelCourtSummons1: "מה הסיבה לזימון שלך לבית המשפט?",
    tooltipCourtSummons1: "אנא בחר",
    labelCourtSummonsOther1: "נא לציין",
    tooltipCourtSummonsOther1: "נא לציין",
    labelCourtSummons2: "מתי הוצאו הזימונים לבית המשפט?",
    tooltipCourtSummons2: "אנא בחר",
    headingCOVID19Test: "אני, בן ביתי או חבר בקבוצה שהשתתפה באירוע אובחנו כחולי קורונה?",
    headingCOVID19TestDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelCOVID19Test1: "למי יש COVID-19?",
    tooltipCOVID19Test1: "אנא בחר",
    labelCOVID19TestOther1a: "נא לציין",
    labelCOVID19TestOther1b: "ציין שם",
    tooltipCOVID19TestOther1a: "נא לציין",
    tooltipCOVID19TestOther1b: "ציין שם",
    labelCOVID19Test2: "איזה סוג של בדיקת COVID-19 היה לך?",
    tooltipCOVID19Test2: "אנא בחר",
    labelCOVID19TestOther2: "נא לציין",
    tooltipCOVID19TestOther2: "נא לציין",
    labelCOVID19Test3: "מה היה תאריך בדיקת הקורונה?",
    tooltipCOVID19Test3: "אנא בחר",
    headingDeath: "היה מוות של בן משפחה קרוב וזה מונע ממני להשתתף באירוע?",
    headingDeathDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelDeath1: "איזה מבני משפחתך מת?",
    tooltipDeath1: "אנא בחר",
    labelDeathOther1: "נא לציין",
    tooltipDeathOther1: "נא לציין",
    labelDeath2: "מתי היה תאריך הפטירה?",
    tooltipDeath2: "אנא בחר",
    labelDeath3: "שם המנוח",
    tooltipDeath3: "נא לציין",
    headingEmergency: "היה לי מצב חירום אמיתי שהוא מחוץ לשליטתי ובלתי צפוי שאני יכול לספק לו ראיות?",
    headingEmergencyDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelEmergency1: "בבקשה תסבירו את סוג החירום?",
    tooltipEmergency1: "אנא פרטו",
    labelEmergency2: "מתי זה קרה?",
    tooltipEmergency2: "אנא בחר",
    labelEmergency3: "למה המצב הזה מונע מכם להשתתף באירוע?",
    tooltipEmergency3: "אנא פרטו",
    labelEmergency4: "האם יש לך ראיות למצב החירום הזה?",
    tooltipEmergency4: "כן או לא",
    headingHomeEmergency: "יש לי מצב חירום ביתי שהוא שוד, שיטפון, שריפה, ונדליזם, התעללות במשפחה שמונע ממני להשתתף באירוע?",
    headingHomeEmergencyDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelHomeEmergency1: "מהו מצב החירום הביתי?",
    tooltipHomeEmergency1: "אנא בחר",
    labelHomeEmergencyOther1: "נא לציין",
    tooltipHomeEmergencyOther1: "נא לציין",
    labelHomeEmergency2: "מתי זה קרה?",
    tooltipHomeEmergency2: "אנא בחר",
    labelHomeEmergency3: "למה זה מנע ממך להשתתף באירוע?",
    tooltipHomeEmergency3: "אנא פרטו",
    headingInjury: "אני, בן ביתי או חבר בקבוצה שנכחה באירוע אובחנו כסובלים ממצב רפואי או מפציעה שמונעת מאיתנו כעת להגיע לאירוע?",
    headingInjuryDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelInjury1: "מה טיבה של המחלה/פציעה/תאונה?",
    tooltipInjury1: "אנא פרטו",
    labelInjury2: "מתי זה קרה?",
    tooltipInjury2: "אנא בחר",
    labelInjury3: "האם השתתפת במתקן רפואי בגין מחלה/פציעה זו?",
    tooltipInjury3: "כן או לא",
    labelInjury4: "האם היה זה מצב רפואי קיים?",
    tooltipInjury4: "כן או לא",
    labelInjury5: "מתי זה אובחן לראשונה?",
    tooltipInjury5: "אנא בחר",
    labelInjury6: "למה זה אומר שאתה לא יכול להשתתף באירוע?",
    tooltipInjury6: "אנא פרטו",
    labelInjury7: "מי סבל מהמחלה/פציעה?",
    tooltipInjury7: "אנא בחר",
    headingJuryService: "אני או חבר בהזמנה נקראנו לשירות המושבעים?",
    headingJuryServiceDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelJuryService1: "מתי קיבלת הודעה על שירות המושבעים?",
    tooltipJuryService1: "אנא בחר",
    headingMechanicalBreakdown: "הרכב שלנו עבר תקלה מכנית או עבר תאונה וזה מונע מאיתנו להשתתף באירוע?",
    headingMechanicalBreakdownDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelMechanicalBreakdown1: "מה קרה עם הרכב?",
    tooltipMechanicalBreakdown1: "אנא בחר",
    labelMechanicalBreakdown2: "מתי זה קרה?",
    tooltipMechanicalBreakdown2: "אנא בחר",
    labelMechanicalBreakdown3: "מדוע לא יכולתם לנסוע בתחבורה חלופית?",
    tooltipMechanicalBreakdown3: "אנא פרטו",
    headingPregnancyComplication: "לי או לחבר בהזמנה יש סיבוכי הריון שאובחנו על ידי איש מקצוע רפואי ולכן מונעים נוכחות באירוע?",
    headingPregnancyComplicationDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelPregnancyComplication1: "מה אופיו של סיבוך ההריון?",
    tooltipPregnancyComplication1: "אנא פרטו",
    labelPregnancyComplication2: "מתי זה קרה?",
    tooltipPregnancyComplication2: "אנא בחר",
    labelPregnancyComplication3: "האם פניתם לטיפול רפואי?",
    tooltipPregnancyComplication3: "כן או לא",
    labelPregnancyComplication4: "למה זה אומר שאתה לא יכול להשתתף באירוע?",
    tooltipPregnancyComplication4: "אנא פרטו",
    headingPublicTransportFailure: "שיטת התחבורה הציבורית (רכבת, אוטובוס, חשמלית, סירה וכו ') נכשלה באופן בלתי צפוי כלומר לא הייתה דרך אחרת שאוכל לנסוע לאירוע זה?",
    headingPublicTransportFailureDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelPublicTransportFailure1: "באיזה סוג של תחבורה ציבורית תכננת להשתמש?",
    tooltipPublicTransportFailure1: "אנא בחר",
    labelPublicTransportFailure2: "מתי תכננת לנסוע?",
    tooltipPublicTransportFailure2: "אנא בחר",
    labelPublicTransportFailure3: "מה קרה?",
    tooltipPublicTransportFailure3: "אנא פרטו",
    labelPublicTransportFailure4: "למה אתה לא יכול לקחת תחבורה חלופית?",
    tooltipPublicTransportFailure4: "אנא פרטו",
    headingScheduledAirlineFailure: "הטיסה שהזמנתי מראש בוטלה במפתיע ולא הצלחתי למצוא אלטרנטיבה מתאימה כלומר לא הצלחתי להשתתף באירוע?",
    headingScheduledAirlineFailureDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelScheduledAirlineFailure1: "שם חברת התעופה",
    tooltipScheduledAirlineFailure1: "שם חברת התעופה",
    labelScheduledAirlineFailure2: "מספר טיסה",
    tooltipScheduledAirlineFailure2: "מספר טיסה",
    labelScheduledAirlineFailure3: "נסיעה מ",
    tooltipScheduledAirlineFailure3: "נסיעה מ",
    labelScheduledAirlineFailure4: "נוסעים ל",
    tooltipScheduledAirlineFailure4: "נוסעים ל",
    labelScheduledAirlineFailure5: "תאריך האירוע?",
    tooltipScheduledAirlineFailure5: "אנא בחר",
    labelScheduledAirlineFailure6: "מדוע לא הצלחתם לעלות על טיסה חלופית?",
    tooltipScheduledAirlineFailure6: "אנא פרטו",
    headingTheftOfDocuments: "המסמכים שלי נגנבו ולא ניתן היה לארגן החלפה במסגרת הזמן הדרושה כדי שלא אוכל להשתתף באירוע",
    headingTheftOfDocumentsDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelTheftOfDocuments1: "איזה סוג של מסמך נגנב?",
    tooltipTheftOfDocuments1: "אנא בחר",
    labelTheftOfDocuments2: "מתי זה קרה?",
    tooltipTheftOfDocuments2: "אנא בחר",
    labelTheftOfDocuments3: "האם ניסית להנפיק מחדש את המסמכים?",
    tooltipTheftOfDocuments3: "כן או לא",
    headingWorkRelocation: "המעסיק שלי ביקש ממני לשנות את מיקום העבודה שלי לשלושה חודשים לפחות והמרחק משמעותי כלומר אני לא יכול עכשיו להשתתף באירוע?",
    headingWorkRelocationDecline: "אין להמשיך בבקשה זו מכיוון שההחזר הכספי ככל הנראה יידחה.",
    labelWorkRelocation1: "האם מדובר בהעתקה זמנית או קבועה של עבודתך?",
    tooltipWorkRelocation1: "אנא בחר",
    labelWorkRelocation2: "כמה זמן תהיה בכתובת החדשה?",
    tooltipWorkRelocation2: "אנא פרטו",
    labelWorkRelocation3: "מהי כתובת העבודה הנוכחית שלך?",
    tooltipWorkRelocation3: "אנא פרטו",
    labelWorkRelocation4: "מהי כתובת העבודה החדשה שלך?",
    tooltipWorkRelocation4: "אנא פרטו",
    labelWorkRelocation5: "מתי קיבלת הודעה על הרילוקיישן הזה?",
    tooltipWorkRelocation5: "אנא בחר",
    headingRedundancy: "אני או חבר בהזמנה התייתרנו על ידי המעסיק שלהם, כלומר אני כבר לא יכול להשתתף בהזמנה?",
    headingRedundancyDecline: "אתה לא צריך להמשיך עם יישום זה כמו ההחזר סביר להניח יידחה.",
    labelRedundancy1: "סוג העסקה?",
    tooltipRedundancy1: "אנא בחר",
    labelRedundancy2: "כמה זמן אתה מועסק בחברה זו?",
    tooltipRedundancy2: "אנא בחר",
    labelRedundancy3: "האם הפיטורים היו מרצון?",
    tooltipRedundancy3: "אנא בחר",
    labelRedundancy4: "מתי הודיעו לך על היתירות?",
    tooltipRedundancy4: "אנא בחר",
    labelTestOption: "Sבחר כן כדי להמשיך",
}