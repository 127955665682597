export default {
    dividerBankingDetails: "Bankdetaljer",
    dividerBookingDetails: "Bestillingsdetaljer",
    labelBookingCancelledPostponedHeading: "Har bestillingen din blitt kansellert eller utsatt? Les mer",
    labelBookingCancelledPostponedText1: "Klikke",
    labelBookingCancelledPostponedText2: "for mer info.",
    labelNewRefundHeading: "Ny refusjonssøknad?",
    labelNewRefundText: "Skriv inn din bestillingsreferanse her ...",
    placeholderBookingReference: "Referanse for bestilling",
    validationBookingReference: "Bestillingsreferanse er nødvendig",
    validationEmailAsBookingReference: "E-postadresse godtas ikke som bestillingsreferanse",
    buttonFindBooking: "Finn bestilling",
    labelEmailHeading: "Allerede startet?",
    labelEmailText: "Send en e-postpåminnelse for å hente søknaden din.",
    placeholderEmail: "E-post",
    buttonEmailReminder: "E-postpåminnelse",
    labelCustomerName : "Kundenavn",
    validationCustomerName: "Kundenavn er obligatorisk",
    labelTypeOfBooking: "Type bestilling",
    validationTypeOfBooking: "Type bestilling er påkrevd",
    labelEventName: "Navn på hendelse",
    labelVenueName: "Steds navn",
    labelFlightNumber: "Flynummer",
    labelOriginAirport: "opprinnelse flyplass",
    labelHotelName: "Overnattingsstedets navn",
    labelHotelLocation: "Overnatting Beliggenhet",
    labelCurrency: "Valuta",
    validationCurrency: "Valuta er påkrevd",
    validationCountryCurrency: "Den valgte valutaen må være valutaen i hjemlandet ditt",
    validationOfflinePayment: "Vi kan ikke behandle refusjoner for ditt valg av valuta og land. Velg alternativer",
    labelBookingValue: "Verdi ved bestilling",
    validationBookingValue: "Bestillingsverdi er nødvendig",
    validationBookingValueAmount: "Må være større enn 0",
    labelDateOfPurchase: "Kjøpsdato",
    validationDateOfPurchase: "Kjøpsdato er påkrevd",
    labelEmailAddress: "E-postadresse",
    validationEmailAddress: "E-postadresse kreves",
    validationEmailAddressFormat: "Feil e-postformat",
    labelConfirmEmailAddress: "Bekreft e-postadresse",
    validationConfirmEmailAddressFormat: "Feil e-postformat",
    validationConfirmEmailAddress: "E-postadresse for bekreftelse kreves",
    validationConfirmEmailAddressMatch: "E-postadressene samsvarer ikke",
    validationEmailDomain: "Denne e-postleverandøren aksepteres ikke, vennligst bruk et alternativ",
    validationDateOfEvent: "Dato for hendelse er påkrevd",
    labelHomeCountry: "Hjemland",
    validationHomeCountry: "Hjemland er påkrevd",
    labelContactNumberCountryCode: "Kontaktnummer: Landskode",
    validationContactNumberCountryCode: "Landskode kreves",
    labelContactNumber: "Kontakt nummer",
    validationContactNumber: "Kontaktnummer er påkrevd",
    labelAddressLine1: "Adresse Linje 1",
    labelAddressLine2: "Adresse Linje 2",
    labelCity: "By",
    labelZipCode: "Postnummer",
    labelRegion: "Region",
    validationRegion: "Region er påkrevd",
    labelNumberOfTicketsToRefund: "Antall billetter å refundere",
    validationNumberOfTicketsToRefund: "Antall billetter som skal refunderes kreves",
    validationNumberOfTicketsToRefundValue: "Må være større enn 0",
    labelCostPerTicket: "Kostnad for hver billett",
    validationCostPerTicket: "Kostnad per billett kreves",
    validationCostPerTicketValue: "Må være større enn 0",
    labelTotalRefundAmount: "Totalt refusjonsbeløp",
    validationTotalRefundAmount: "Totalt refusjonsbeløp kreves",
    validationTotalRefundAmountValue: "Må være større enn 0",
    validationTotalRefundGreaterThanBookingValue: "Totalt refusjonsbeløp kan ikke være større enn bestillingsverdien ovenfor",
    labelBookingConfirmationFiles: "Last opp bestillingsbekreftelse",
    validationBookingConfirmationFiles: "Legg ved minst én fil, eller klikk Lagre og returner senere når du har dokumentene",
    validationEvidenceFilesPopup: "Bevis må lastes opp før innsending. Hvis du ikke har bevisene akkurat nå, vennligst lagre søknaden og returner når du har det. Vi aksepterer elektroniske kopier, skannerkopier og bilder.",
    validationBookingConfirmationFilesPopup: "Bestillingsbekreftelse må lastes opp før innsending. Hvis du ikke har bestillingsbekreftelsen akkurat nå, vennligst lagre søknaden og returner når du har gjort det. Vi aksepterer elektroniske kopier, skannerkopier og bilder.",
    alertUploadDocuments: "Last opp relevant dokumentasjon som er beskrevet i e-posten, og fortsett deretter til trinn 3.",
    labelRefundReasonCategory: "Årsak til refusjon",
    validationRefundReasonCategory: "Refusjonsgrunn kreves",
    labelDateOfIncident: "Dato for hendelse",
    labelAdditionalComments: "Ytterligere kommentarer",
    tooltipAdditionalComments: "Ytterligere kommentarer",
    validationAdditionalComments: "Ytterligere kommentarer kreves",
    labelUnableToNotifyWithinTimeLimit: "Kan ikke varsle innen 60 dager fra",
    labelReasonForNotificationOutsideTimeLimit: "Varsling utenom frist Forklaring",
    validationReasonForNotificationOutsideTimeLimit: "En Årsak til varsling etter 60 dager er nødvendig",
    labelRefundReasonFiles: "Last opp bevis",
    validationRefundReasonFiles: "Legg ved minst én fil, eller klikk Lagre og returner senere når du har dokumentene",
    validationTextScript: "Må samsvare med latinsk skrift",
    optionTypeOfBooking1: "Begivenhet",
    optionTypeOfBooking2: "Turné",
    optionTypeOfBooking3: "Flytur",
    optionTypeOfBooking4: "Innkvartering",
    optionTypeOfBooking5: "Overføre",
    optionTypeOfBooking6: "Parkering",
    optionTypeOfBooking7: "Andre reiser",
    labelEventTypeDate: "Dato for arrangement",
    labelEventTypeTour: "Dato for tur",
    labelEventTypeFlight: "Dato for flyreise",
    labelEventTypeCheckIn: "Dato for innsjekking",
    labelEventTypeTransfer: "Dato for overføring",
    labelEventTypeParking: "Dato for parkering",
    labelEventTypeOtherTravel: "Dato for reise",
    optionRefundReason11: "Ugunstig vær",
    optionRefundReason10: "Forsvaret og nødetatene tilbakekaller",
    optionRefundReason15: "Endringer i eksamensdatoer",
    optionRefundReason18: "Innkalling til tingretten",
    optionRefundReason17: "COVID-19 reiserestriksjoner",
    optionRefundReason19: "COVID-19 positiv test",
    optionRefundReason20: "Bestilling kansellert av operatør/arrangør",
    optionRefundReason21: "Bestilling planlagt på nytt av operatør/arrangør",
    optionRefundReason22: "Bestilt feil dato",
    optionRefundReason23: "Bestilte feil billetter",
    optionRefundReason24: "Bestilte for mange billetter",
    optionRefundReason4: "Død",
    optionRefundReason16: "Nødsituasjon",
    optionRefundReason14: "Regjeringens reiseforbud",
    optionRefundReason9: "Nødssituasjon i hjemmet",
    optionRefundReason1: "Sykdom/skade/ulykke",
    optionRefundReason8: "Juryens tjeneste",
    optionRefundReason7: "Mekanisk sammenbrudd",
    optionRefundReason2: "Pre-eksisterende medisinsk tilstand",
    optionRefundReason3: "Graviditet komplikasjon",
    optionRefundReason5: "Svikt i kollektivtrafikken",
    optionRefundReason6: "Flyavbrudd",
    optionRefundReason13: "Tyveri av dokument(er)",
    optionRefundReason12: "Flytting av arbeid",
    optionRefundReason25: "Redundans på arbeidsplassen",
    optionRefundReason26: "Testalternativ",
    optionRefundReasonConfirm1: "Nei",
    optionRefundReasonConfirm2: "Ja",
    optionRefundReasonArmedForces1: "Hær",
    optionRefundReasonArmedForces2: "Marine",
    optionRefundReasonArmedForces3: "Flyvåpen",
    optionRefundReasonArmedForces4: "Spesialstyrker",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Ild",
    optionRefundReasonArmedForces7: "Ambulanse",
    optionRefundReasonArmedForces8: "Lege/sykepleier",
    optionRefundReasonArmedForces9: "Tannlege",
    optionRefundReasonArmedForces10: "Annet",
    optionRefundReasonCourtSummons1: "Straffeforfølgelse",
    optionRefundReasonCourtSummons2: "Sivil rettssak",
    optionRefundReasonCourtSummons3: "Vitne i en tiltale",
    optionRefundReasonCourtSummons4: "Vitne i sivil sak",
    optionRefundReasonCourtSummons5: "Saksøker",
    optionRefundReasonCourtSummons6: "Saksøkt",
    optionRefundReasonCourtSummons7: "Annet",
    optionRefundReasonDeath1: "Ektemann",
    optionRefundReasonDeath2: "Kone",
    optionRefundReasonDeath3: "Lyd",
    optionRefundReasonDeath4: "Datter",
    optionRefundReasonDeath5: "Far",
    optionRefundReasonDeath6: "Mor",
    optionRefundReasonDeath7: "Bror",
    optionRefundReasonDeath8: "Søster",
    optionRefundReasonDeath9: "Bestefar",
    optionRefundReasonDeath10: "Bestemor",
    optionRefundReasonDeath11: "Annet",
    optionRefundReasonHomeEmergency1: "Ild",
    optionRefundReasonHomeEmergency2: "Flom",
    optionRefundReasonHomeEmergency3: "Ran",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Vold i nære relasjoner",
    optionRefundReasonHomeEmergency6: "Kidnappe",
    optionRefundReasonHomeEmergency7: "Annet",
    optionRefundReasonCOVID19Person1: "Meg selv",
    optionRefundReasonCOVID19Person2: "En annen person i bestillingen",
    optionRefundReasonCOVID19Person3: "En person i min husstand",
    optionRefundReasonCOVID19Person4: "Annet",
    optionRefundReasonCOVID19TestType1: "PCR-test",
    optionRefundReasonCOVID19TestType2: "RAT-test",
    optionRefundReasonCOVID19TestType3: "Lateral strømningstest",
    optionRefundReasonCOVID19TestType4: "Selvadministrert test",
    optionRefundReasonCOVID19TestType5: "Ingen test, men jeg har symptomer",
    optionRefundReasonCOVID19TestType6: "Annet",
    optionRefundReasonBreakdown1: "Sammenbrudd",
    optionRefundReasonBreakdown2: "Ulykke",
    optionRefundReasonBreakdown3: "Ild",
    optionRefundReasonBreakdown4: "Tyveri",
    optionRefundReasonPublicTransportType1: "Tog",
    optionRefundReasonPublicTransportType2: "Buss",
    optionRefundReasonPublicTransportType3: "Trikk",
    optionRefundReasonPublicTransportType4: "Båt",
    optionRefundReasonDocumentType1: "Pass",
    optionRefundReasonDocumentType2: "Reisetillatelse",
    optionRefundReasonDocumentType3: "Nasjonal ID",
    optionRefundReasonDocumentType4: "Billetter",
    optionRefundReasonWorkRelocationType1: "Midlertidig",
    optionRefundReasonWorkRelocationType2: "Permanent",
    optionRefundReasonWorkRelocationTemporary1: "0-3 måneder",
    optionRefundReasonWorkRelocationTemporary2: "3-6 måneder",
    optionRefundReasonWorkRelocationTemporary3: "Over 6 måneder",
    optionRefundReasonBookingRelationshipType1: "Noen i bestillingen",
    optionRefundReasonBookingRelationshipType2: "Nærmeste slektning til noen i bestillingen",
    optionEmploymentType1: "Heltid",
    optionEmploymentType2: "Deltid",
    optionEmploymentType3: "Kontrakt",
    optionEmploymentLengthType1: "0-1 år",
    optionEmploymentLengthType2: "1-2 år",
    optionEmploymentLengthType3: "Mer enn 2 år",
    textRefundEvidenceHeading: "Vennligst oppgi følgende bevis:",
    textRefundEvidence1: "Bekreftelse av flyselskap",
    textRefundEvidence2: "Eventuelle passende støttende bevis",
    textRefundEvidence3: "Bekreftelse på utrykning av havaritjeneste",
    textRefundEvidence4: "Dødsattest",
    textRefundEvidence5: "Bekreftelse avbestilling fra flyselskap",
    textRefundEvidence6: "Værvarsel",
    textRefundEvidence7: "Korrespondanse fra bestillingsagent som bekrefter ingen mulighet til å utstede billetter på nytt",
    textRefundEvidence8: "Brev fra retten",
    textRefundEvidence9: "Legeerklæring / legeerklæring",
    textRefundEvidenceDescription9: "Bekrefte detaljer om sykdommen eller skaden, datoen den først oppstod, at det hindrer deg i å delta i bookingen.",
    textRefundEvidence10: "Bekreftelse på arbeidsgiverbrev",
    textRefundEvidence11: "Korrespondanse med forsikringskrav",
    textRefundEvidence12: "Brev fra arbeidsgiver",
    textRefundEvidence13: "Bekreftelse av nasjonale myndigheters nettsted",
    textRefundEvidence14: "Varsel om endring av eksamen",
    textRefundEvidence15: "Politianmeldelse",
    textRefundEvidence16: "Politianmeldelse og referansenummer",
    textRefundEvidence17: "Bekreftelse fra transportleverandør",
    textRefundEvidence18: "Travel Advarsel",
    textRefundEvidence20: "COVID-19 testsertifikat",
    textRefundEvidence24: "Varsel om isolasjon fra myndighetene",
    textRefundEvidence25: "Brev fra befal",
    textRefundEvidence26: "Brev fra forsikringsselskapene",
    textRefundEvidence27: "Adressebevis",
    textRefundEvidence28: "Bevis på relasjon til avdøde",
    textRefundEvidence29: "Bevis på relasjon til skadet/syk person",
    textRefundEvidence30: "Lønnsslipp",
    tooltipBookingReference: "For å identifisere bestillingsreferansen din, sjekk bestillingsbekreftelsen og se delen om vår utvidede tjeneste.",
    tooltipCustomerName: "Fornavn og etternavn som ble brukt til å gjøre bestillingen.",
    tooltipTypeOfBooking: "Type bestilling ønsker du å bli refundert.",
    tooltipEventName: "Navnet på arrangementet.",
    tooltipVenueName: "Navnet på lokalet.",
    tooltipFlightNumber: "Flynummeret.",
    tooltipOriginAirport: "Avreise flyplassen.",
    tooltipHotelName: "Navnet på boligen.",
    tooltipHotelLocation: "Plasseringen av boligen.",
    tooltipCurrency: "Valutaen som ble brukt til å betale for bestillingen.",
    tooltipBookingValue: "Totalprisen du betalte for bestillingen.",
    tooltipDateOfPurchase: "Datoen da bestillingen ble gjort.",
    tooltipEmailAddress: "Bruk en gyldig e-postadresse. Vi bruker denne e-posten for korrespondanse under refusjonssøknaden.",
    tooltipConfirmEmailAddress: "Bruk samme e-postadresse som ovenfor, dette er for validering.",
    tooltipHomeCountry: "Dette vil hjelpe oss med å få opp riktig bankinformasjon på det siste trinnet.",
    tooltipContactNumberCountryCode: "Kontaktnummer landskode.",
    tooltipContactNumber: "Bruk et gyldig nummer. Vi bruker dette til korrespondanse under refusjonssøknaden.",
    tooltipAddressLine1: "Vær oppmerksom på at adressen din vil bli brukt når du validerer bankkontoen din.",
    tooltipAddressLine2: "Andre adresselinje.",
    tooltipCity: "Din hjemby, detaljer kan brukes til å validere bankdetaljer.",
    tooltipZipCode: "Postnummer / postnummer.",
    tooltipRegion: "Region i hjembyen din, kan detaljer brukes til å validere bankdetaljer.",
    tooltipNumberOfTicketsToRefund: "Antall billetter du ønsker å få refundert.",
    tooltipTotalRefundAmount: "Dette kan ikke være mer enn den totale bestillingsverdien, du vil ikke kunne sende inn søknaden hvis den gjør det.",
    tooltipBookingConfirmationFiles: "Skjermbilde / vedlegg av hele bestillingsbekreftelsen som vanligvis finnes i e-postene dine.",
    tooltipRefundReasonCategory: "Velg årsaken til refusjonen.",
    tooltipDateOfIncident: "Datoen da hendelsen som påvirket bestillingen din skjedde.",
    tooltipRefundExplanation: "Vennligst oppgi så mye informasjon som mulig som støtter kravet om refusjon.",
    tooltipUnableToNotifyWithinTimeLimit: "Kan ikke varsle innen 60 dager.",
    tooltipReasonForNotificationOutsideTimeLimit: "Varsling utenom fristforklaring.",
    tooltipRefundReasonFiles: "Last opp de nødvendige dokumentene.",
    tooltipEventTypeDate: "Dato for selve arrangementet/bookingen.",
    tooltipEventTypeTour: "Dato for selve turen.",
    tooltipEventTypeFlight: "Dato for den faktiske flyvningen.",
    tooltipEventTypeCheckIn: "Dato for faktisk innsjekking.",
    tooltipEventTypeTransfer: "Dato for selve overføringen.",
    tooltipEventTypeParking: "Dato for selve parkeringen.",
    tooltipEventTypeOtherTravel: "Startdato for selve turen."
};