export default {
    messageQuestionService: "هذه الخدمة للاستخدام فقط إذا كنت تواجه مشكلة في استكمال نموذج الطلب ، ولا يمكننا الرد على أي أسئلة أخرى هنا.",
    labelName: "اسم",
    labelReference: "معلومات الحجز",
    labelPhone: "رقم التليفون",
    labelEmail: "بريد الالكتروني",
    labelQuery: "سؤالك",
    labelAskQuestion: "ارسل لنا سؤال هنا ...",
    buttonOpenModal: "سؤال",
    title: "طرح سؤال",
    modalTitle: "٤. هل لديك مشكلة فنية في النموذج؟",
    toastSuccess: "لقد تلقينا سؤالك وسنتصل بك في غضون ٢٤ ساعة",
    toastError: "حدث خطأ ما في طلبك ، يرجى المحاولة مرة أخرى",
    nameValidation:"الاسم مطلوب",
    referenceValidation:"المرجع مطلوب",
    questionValidation: "سؤالك مطلوب"
}