export default {
    headingAdverseWeather: "ወደ ዝግጅቱ መጓዝን አደገኛ የሚያደርጉ ከባድ የአየር ሁኔታ ያጋጥማችኋል። ይህንንም በመንግስት አካል (ለምሳሌ የአካባቢ ባለስልጣን፣ ፖሊስ ወዘተ) ትዕዛዝ ተላልፈዋል። ይህን የምታረጋግጥበት ማስረጃ አለህ?",
    headingAdverseWeatherDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    tooltipRefundReasonConfirm: "አዎ ወይም አይ",
    labelBookingCancelledByOperator: "ትኬቱ በኦፕሬተር/ኦርጋናይዘር ተሰርዟል።",
    labelBookingRescheduledByOperator: "ቀኑ የተቀየረው ኦፕሬተር/ኦርጋናይዘር ሲሆን እኔም በአዲሱ ቀን ላይ መገኘት አልችልም።",
    labelBookedWrongDate: "እኔም ሆንኩ የመጽሐፉ አባል ትኬቱን ስንገዙ ስህተት ሠርተን የተሳሳተ ቀን መዝግበን ነበር።",
    labelBookedWrongTickets: "እኔም ሆንኩ የመዝገበያው አባል ትኬቱን ስንገዙ ስህተት ሠርተን የተሳሳቱ ትኬቶችን መዝግበን ነበር።",
    labelBookedTooManyTickets: "እኔም ሆንኩ የመዝገበያው አባል ትኬቱን ስንገዙ ስህተት ሠርተን ብዙ ቲኬቶች ገዝቼ ነበር።",
    labelCOVID19TravelRestrictions: "እኔ ወይም የመዝገቡ አባል በ COVID-19 የጉዞ እገዳ ምክንያት በስብሰባው ላይ መገኘት አልቻልንም.",
    labelADWrongSelection: "ገንዘብ እንድትመለስ ያደረግኸውን የተሳሳተ ምክንያት መርጠህ ይሆናል። እባክዎን የRefund Reason የማውረድ ዝርዝር እንደገና ይመልከቱ.",
    labelAdverseWeather1: "መጥፎ የአየር ጠባይ ሲያጋጥምህ በዝግጅቱ ላይ መገኘት ያልቻልከው ለምንድን ነው?",
    tooltipAdverseWeather1: "እባክዎ ዝርዝር",
    labelAdverseWeather2: "የተከሰተበት ቀን",
    tooltipAdverseWeather2: "እባክዎን ይምረጡ",
    headingArmedForces: "አሠሪህ የጦር ኃይሎች ወይም የአደጋ ጊዜ አገልግሎት የትኛው እንደሆነ መጀመሪያ ላይ ባልታሰብክበት ጊዜ ወደ ሥራ እንድትገባ ይጠይቃችኋል ፤ ይህ ደግሞ በስብሰባው ላይ እንዳትገኙ ያግዳችኋል?",
    headingArmedForcesDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelArmedForces1: "የምትሠራው በምን ዓይነት ኃይሎች ወይም ድንገተኛ አደጋ አገልግሎት ነው?",
    tooltipArmedForces1: "እባክዎን ይምረጡ",
    labelArmedForcesOther1: "እባክዎን ይግለፅ",
    tooltipArmedForcesOther1: "እባክዎን ይግለፅ",
    labelArmedForces2: "የተከሰተበት ቀን",
    tooltipArmedForces2: "እባክዎን ይምረጡ",
    headingChangesToExam: "የፈተናህ ቀን በትምህርት ቤትህ/ኮሌጅ ወዘተ ተቀይሯል? ይህ ልትቀምሱበት የሚገባ ያልተሳካ ፈተና አይደለምን?",
    headingChangesToExamDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelExam1: "የመጀመሪያው የፈተና ቀን ምን ነበር?",
    tooltipExam1: "እባክዎን ይምረጡ",
    labelExam2: "አዲሱ የፈተና ቀን ምንድነው?",
    tooltipExam2: "እባክዎን ይምረጡ",
    labelExam3: "የምርመራውን ቀን የቀየረው ለምንድን ነው?",
    tooltipExam3: "እባክዎ ዝርዝር ያስገቡ",
    labelExam4: "ቀደም ሲል ያደረግከው ምርመራ አልተሳካልህም?",
    tooltipExam4: "አዎ ወይም አይ",
    headingCourtSummons: "ከድርጊቱ ጋር በተያያዘ በተመሳሳይ ቀን ምስክር ሆናችሁ ፍርድ ቤት እንድትቀርቡ ተጠርታችኋል?",
    headingCourtSummonsDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelCourtSummons1: "የእርስዎ ፍርድ ቤት የጠራበት ምክንያት ምንድን ነው?",
    tooltipCourtSummons1: "እባክዎን ይምረጡ",
    labelCourtSummonsOther1: "እባክዎን ይግለፅ",
    tooltipCourtSummonsOther1: "እባክዎን ይግለፅ",
    labelCourtSummons2: "ፍርድ ቤቱ የጠራው መቼ ነው?",
    tooltipCourtSummons2: "እባክዎን ይምረጡ",
    headingCOVID19Test: "እኔ የቤተሰቤ አባል ወይም በስብሰባው ላይ የተገኘ የቡድኑ አባል COVID-19 የተባለ በሽታ እንዳለብኝ ታወቀ?",
    headingCOVID19TestDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelCOVID19Test1: "COVID-19 ያለው ማነው?",
    tooltipCOVID19Test1: "እባክዎን ይምረጡ",
    labelCOVID19TestOther1a: "እባክዎን ይግለፅ",
    labelCOVID19TestOther1b: "ስምን አስቀምጥ",
    tooltipCOVID19TestOther1a: "እባክዎን ይግለፅ",
    tooltipCOVID19TestOther1b: "ስምን አስቀምጥ",
    labelCOVID19Test2: "ምን አይነት COVID-19 ፈተና አለዎት?",
    tooltipCOVID19Test2: "እባክዎን ይምረጡ",
    labelCOVID19TestOther2: "እባክዎን ይግለፅ",
    tooltipCOVID19TestOther2: "እባክዎን ይግለፅ",
    labelCOVID19Test3: "የእርስዎ COVID-19 ፈተና ቀን ምን ነበር?",
    tooltipCOVID19Test3: "እባክዎን ይምረጡ",
    headingDeath: "አንድ የቅርብ የቤተሰቤ አባል የሞተ ሲሆን በስብሰባው ላይ እንዳገኝ ይከለክለኛል?",
    headingDeathDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelDeath1: "የሞተው የትኛው የቤተሰብህ አባል ነው?",
    tooltipDeath1: "እባክዎን ይምረጡ",
    labelDeathOther1: "እባክዎን ይግለፅ",
    tooltipDeathOther1: "እባክዎን ይግለፅ",
    labelDeath2: "የሞቱበት ቀን መቼ ነበር?",
    tooltipDeath2: "እባክዎን ይምረጡ",
    labelDeath3: "የሟቹ ስም",
    tooltipDeath3: "እባክዎን ይግለፅ",
    headingEmergency: "ከቁጥጥሩ ውጭ የሆነና ማስረጃ ማቅረብ የምችለው ያልታሰበ ድንገተኛ አደጋ አጋጥሞኛል?",
    headingEmergencyDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelEmergency1: "እባክዎ ስለ አስቸኳይ ሁኔታ ምን አይነት ይግለጹ?",
    tooltipEmergency1: "እባክዎ ዝርዝር",
    labelEmergency2: "ይህ የሆነው መቼ ነው?",
    tooltipEmergency2: "እባክዎን ይምረጡ",
    labelEmergency3: "ይህ ሁኔታ በስብሰባው ላይ እንዳትገኝ የሚያግድህ ለምንድን ነው?",
    tooltipEmergency3: "እባክዎ ዝርዝር",
    labelEmergency4: "ለዚህ ድንገተኛ አደጋ ማስረጃ አለዎት?",
    tooltipEmergency4: "አዎ ወይም አይ",
    headingHomeEmergency: "በዝግጅቱ ላይ እንዳልገኝ የሚያግደኝ ዘረፋ፣ የጥፋት ፍሰት፣ እሳት፣ ሥርዓት አልበኝነት፣ የቤት ውስጥ ጥቃት ነው የቤት ውስጥ ድንገተኛ አደጋ ያለብኝ?",
    headingHomeEmergencyDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelHomeEmergency1: "የቤት ድንገተኛ አደጋ ምንድን ነው?",
    tooltipHomeEmergency1: "እባክዎን ይምረጡ",
    labelHomeEmergencyOther1: "እባክዎን ይግለፅ",
    tooltipHomeEmergencyOther1: "እባክዎን ይግለፅ",
    labelHomeEmergency2: "ይህ የሆነው መቼ ነው?",
    tooltipHomeEmergency2: "እባክዎን ይምረጡ",
    labelHomeEmergency3: "በዝግጅቱ ላይ እንዳትገኝ ያቆመህ ለምንድን ነው?",
    tooltipHomeEmergency3: "እባክዎ ዝርዝር",
    headingInjury: "እኔ፣ የቤተሰቤ አባል ወይም በስብሰባው ላይ የተገኘ አንድ የቡድኑ አባል፣ አሁን ወደ ዝግጅቱ እንዳንሄድ የሚያግደን የጤና እክል ወይም ጉዳት ደርሶብኝ ነበር?",
    headingInjuryDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelInjury1: "የህመሙ/ጉዳቱ/ አደጋ ምንድነው?",
    tooltipInjury1: "እባክዎ ዝርዝር",
    labelInjury2: "ይህ የሆነው መቼ ነው?",
    tooltipInjury2: "እባክዎን ይምረጡ",
    labelInjury3: "ለዚህ ህመም/ጉዳት ወደ ህክምና ተቋም ገብተዋል?",
    tooltipInjury3: "አዎ ወይም አይ",
    labelInjury4: "ይህ ሁኔታ ቀደም ብሎ የነበረ የጤና እክል ነበር?",
    tooltipInjury4: "አዎ ወይም አይ",
    labelInjury5: "ይህ ለመጀመሪያ ጊዜ የተረጋገጠው መቼ ነው?",
    tooltipInjury5: "እባክዎን ይምረጡ",
    labelInjury6: "ይህ ማለት በበዓሉ ላይ መገኘት የማትችሉት ለምንድን ነው?",
    tooltipInjury6: "እባክዎ ዝርዝር",
    labelInjury7: "ማነው በህመሙ/ጉዳቱ የተጎዳው?",
    tooltipInjury7: "እባክዎን ይምረጡ",
    headingJuryService: "እኔ ወይም አንድ የመዝገብ አባል ወደ Jury አገልግሎት ተጠርተዋል?",
    headingJuryServiceDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelJuryService1: "ስለ እማኝ ዳኝነት አገልግሎት የተሰማዎት መቼ ነው?",
    tooltipJuryService1: "እባክዎን ይምረጡ",
    headingMechanicalBreakdown: "መኪናችን የመኪና አደጋ ደርሶበት አሊያም አደጋ ደርሶበት ነበር፤ ይህ ደግሞ በስብሰባው ላይ እንዳንገኝ እንቅፋት ይሆንብናል?",
    headingMechanicalBreakdownDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelMechanicalBreakdown1: "መኪናው ምን ሆነ?",
    tooltipMechanicalBreakdown1: "እባክዎን ይምረጡ",
    labelMechanicalBreakdown2: "ይህ የሆነው መቼ ነው?",
    tooltipMechanicalBreakdown2: "እባክዎን ይምረጡ",
    labelMechanicalBreakdown3: "አማራጭ ትራንስፖርት መውሰድ ያልቻልከው ለምንድን ነው?",
    tooltipMechanicalBreakdown3: "እባክዎ ዝርዝር",
    headingPregnancyComplication: "እኔ ወይም የመጽሐፉ አባል በህክምና ባለሙያ ምርመራ የተደረገለት እና በዚህም ምክንያት በዝግጅቱ ላይ እንዳይገኝ የሚከላከሉ የእርግዝና ችግሮች ያጋጥሙናል?",
    headingPregnancyComplicationDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelPregnancyComplication1: "የእርግዝና ውሂብ ምንዓይነት ነው?",
    tooltipPregnancyComplication1: "እባክዎ ዝርዝር",
    labelPregnancyComplication2: "ይህ የሆነው መቼ ነው?",
    tooltipPregnancyComplication2: "እባክዎን ይምረጡ",
    labelPregnancyComplication3: "የሕክምና እርዳታ ፈልገህ ነበር?",
    tooltipPregnancyComplication3: "አዎ ወይም አይ",
    labelPregnancyComplication4: "ይህ ማለት በበዓሉ ላይ መገኘት የማትችሉት ለምንድን ነው?",
    tooltipPregnancyComplication4: "እባክዎ ዝርዝር",
    headingPublicTransportFailure: "የህዝብ ትራንስፖርት ዘዴ (Train, Bus, Tram, Boat, ወዘተ) ዘዴ ባልተጠበቀ ሁኔታ ውድቅ ሆነ ማለት እዚህ ዝግጅት ላይ ሌላ መንገድ አልነበረም?",
    headingPublicTransportFailureDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelPublicTransportFailure1: "ምን ዓይነት የሕዝብ ትራንስፖርት ለመጠቀም አስበህ ነበር?",
    tooltipPublicTransportFailure1: "እባክዎን ይምረጡ",
    labelPublicTransportFailure2: "ለመጓዝ ያቀድከው መቼ ነው?",
    tooltipPublicTransportFailure2: "እባክዎን ይምረጡ",
    labelPublicTransportFailure3: "ምን ሆነ?",
    tooltipPublicTransportFailure3: "እባክዎ ዝርዝር",
    labelPublicTransportFailure4: "አማራጭ መጓጓዣ መውሰድ ያልቻልከው ለምንድን ነው?",
    tooltipPublicTransportFailure4: "እባክዎ ዝርዝር",
    headingScheduledAirlineFailure: "በቅድሚያ የያዝኩት በረራ በድንገት ተሰረዘና በዝግጅቱ ላይ መገኘት ያቃተኝን ተስማሚ አማራጭ ትርጉም ማግኘት አልቻልኩም?",
    headingScheduledAirlineFailureDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelScheduledAirlineFailure1: "የአየር መንገድ ስም",
    tooltipScheduledAirlineFailure1: "የአየር መንገድ ስም",
    labelScheduledAirlineFailure2: "የበረራ ቁጥር",
    tooltipScheduledAirlineFailure2: "የበረራ ቁጥር",
    labelScheduledAirlineFailure3: "ጉዞ",
    tooltipScheduledAirlineFailure3: "ጉዞ",
    labelScheduledAirlineFailure4: "ጉዞ ወደ",
    tooltipScheduledAirlineFailure4: "ጉዞ ወደ",
    labelScheduledAirlineFailure5: "የተፈጠረበት ቀን?",
    tooltipScheduledAirlineFailure5: "እባክዎን ይምረጡ",
    labelScheduledAirlineFailure6: "አማራጭ በረራ ማድረግ ያልቻልከው ለምንድን ነው?",
    tooltipScheduledAirlineFailure6: "እባክዎ ዝርዝር",
    headingTheftOfDocuments: "ሰነዶቼ ተሰርቀው በስብሰባው ላይ መገኘት ስለማልችል በሚያስፈልገው የጊዜ ሰሌዳ ውስጥ ምትክ (s) ማዘጋጀት አልተቻለም",
    headingTheftOfDocumentsDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelTheftOfDocuments1: "ምን አይነት ዶክመንት ተሰረቀ?",
    tooltipTheftOfDocuments1: "እባክዎን ይምረጡ",
    labelTheftOfDocuments2: "ይህ የሆነው መቼ ነው?",
    tooltipTheftOfDocuments2: "እባክዎን ይምረጡ",
    labelTheftOfDocuments3: "ሰነዶቹ እንደገና እንዲወጡ ለማድረግ ሞክረሃል?",
    tooltipTheftOfDocuments3: "አዎ ወይም አይ",
    headingWorkRelocation: "አሠሪዬ ቢያንስ ለሦስት ወራት የሥራዬን ቦታ እንድቀይር ጠይቆኛል ፤ ርቀቱም ትልቅ ትርጉም አለው ፤ አሁን በስብሰባው ላይ መገኘት አልቻልኩም ማለት ነው?",
    headingWorkRelocationDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelWorkRelocation1: "ይህ ሥራህ ጊዜያዊ ወይም ቋሚ የሆነ ቦታ ነውን?",
    tooltipWorkRelocation1: "እባክዎን ይምረጡ",
    labelWorkRelocation2: "በአዲሱ አድራሻ ላይ የምትቆየው እስከ መቼ ነው?",
    tooltipWorkRelocation2: "እባክዎ ዝርዝር",
    labelWorkRelocation3: "የአሁኑ የስራ አድራሻዎ ምንድን ነው?",
    tooltipWorkRelocation3: "እባክዎ ዝርዝር",
    labelWorkRelocation4: "አዲሱ የሥራ አድራሻህ ምንድን ነው?",
    tooltipWorkRelocation4: "እባክዎ ዝርዝር",
    labelWorkRelocation5: "ወደዚህ አካባቢ መዛወራችሁን የተሰማችሁት መቼ ነው?",
    tooltipWorkRelocation5: "እባክዎን ይምረጡ",
    headingRedundancy: "እኔም ሆንኩ አንድ ሰው በመዝገቡ ላይ መገኘት አልችልም በሚል አሠሪዎቻቸው ተትረፍርፈዋል ማለት ነው?",
    headingRedundancyDecline: "የተመለሰው ገንዘብ ሊቀንስ ስለሚችል ይህን ማመልከቻ መቀጠል የለብህም።",
    labelRedundancy1: "የሥራ ዓይነት?",
    tooltipRedundancy1: "እባክዎን ይምረጡ",
    labelRedundancy2: "ይህ ኩባንያ ለምን ያህል ጊዜ ተቀጥረሃል?",
    tooltipRedundancy2: "እባክዎን ይምረጡ",
    labelRedundancy3: "የተትረፍርፈው ንረት በፍቃደኝነት ነበር?",
    tooltipRedundancy3: "እባክዎን ይምረጡ",
    labelRedundancy4: "ስለ ብዙ ነገርህ የተሰማህ መቼ ነው?",
    tooltipRedundancy4: "እባክዎን ይምረጡ",
    labelTestOption: "ለመቀጠል አዎ የሚለውን ይምረጡ",
}