export default {
    messageUnableToMakePaymentToCurrency: "Leider können wir keine Bankverbindung für die ausgewählte Währung abrufen. Bitte stellen Sie sicher, dass Währung und Heimatland ausgewählt sind auf",
    messageOnlyBankAccountsAccepted: "Bitte beachten Sie: Wir können derzeit nur Zahlungen auf Bankkonten vornehmen (Kredit- und Debitkarten werden nicht akzeptiert).",
    labelAccountName: "Name des Kontoinhabers",
    labelBankAccountName: "Vollständiger Name des Kontoinhabers",
    labelBankCardHolderName: "Name des Karteninhabers",
    validationBankAccountName: "Der vollständige Name des Kontoinhabers ist erforderlich",
    labelBankCountry: "Bankland",
    labelBankName: "Bank Name",
    labelPaymentType: "Zahlungsart",
    labelInstitutionNumber: "Institutionsnummer",
    labelBankAddress: "Bankadresse",
    labelBankAddress2: "Bankadresse",
    labelBankCity: "Bank-Stadt",
    labelBankState: "Bank-Staat",
    labelBankAccountNumber: "Bankkontonummer",
    labelBankAccountNumberCBU: "Bankkontonummer CBU",
    labelBankAccountNumberCCI: "Bankkontonummer CCI",
    labelBankAccountNumberZAR: "Bankkontonummer (7-11 Ziffern)",
    labelCardNumber: "Kartennummer",
    labelUnionpay: "UnionPay-Kartennummer",
    labelAlipay: "Alipay ID (Handynummer oder E-Mail)",
    labelBankAccountType: "Konto Typ",
    optionBankAccountTypeCURRENT: "Aktuelles Konto",
    optionBankAccountTypeCHECKING: "Girokonto",
    optionBankAccountTypeSAVING: "Sparkonto",
    optionBankAccountTypeSAVINGS: "Sparkonto",
    optionBankAccountTypeCUENTA_VISTA: "Hauptkonto",
    optionBankAccountType1: "Girokonto",
    optionBankAccountType2: "Sparkonto",
    optionBankAccountType3: "Hauptkonto",
    optionBankAccountType4: "Vista-Konto",
    optionBankAccountType5: "RUT-Konto",
    labelSortCode: "Sortiercode",
    labelRoutingCode: "Bankleitzahl",
    labelBankRoutingCodeNationalID: "Bankleitzahl / National ID",
    labelBankTransitCodeSwift: "Transitnummer",
    labelCNAPSCode: "Routing-Code / CNAPS-Code (China Nationaler Code für fortgeschrittene Zahlungssysteme)",
    labelABAFedwireRoutingCode: "ACH-Routing-Nummer",
    labelIFSCCode: "IFSC-Code",
    labelBankCode: "Bankleitzahl",
    labelBIKCode: "BIK-Code",
    labelBranchCode: "Branchencode",
    labelBranchCodeUniversal: "Universeller 6-stelliger Zweigcode",
    labelRemittanceLine3: "Kontaktname und Telefonnummer des Begünstigten",
    labelBeneficiaryContactNameandTelephoneNumber: "Kontaktname und Telefonnummer des Begünstigten",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Geburtsdatum und Geburtsort des Begünstigten",
    labelRemittanceLine4: "Individuelle Steuernummer CUIL (9-12 Stellen)",
    labelCUILNumber: "Steuernummer: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Steuernummer RFC 13-stellig oder CURP 18-stellig",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuelle Steuernummer CPF Cadastro de Pessoa Física (11-14 Ziffern), Filialcode",
    labelCFPNumber: "CPF-Nummer (11-14 Stellen)",
    labelBranchNameorBranchCode7digits: "Filialname oder Filialcode (7 Ziffern)",
    labelCedulaJuridica910digitsTaxID: "Gesetzliches Zertifikat (9-10-stellige Steuernummer)",
    labelTaxIDRUT89digits: "Steuernummer RUT (8-9 Stellen)",
    labelJob: "Berufsbezeichnung",
    labelDateOfBirth: "Geburtsdatum",
    labelNationality: "Staatsangehörigkeit",
    labelDocumentType: "Art des Dokuments",
    labelDocumentNumber: "Dokumentnummer",
    labelRutNumber: "RUT-Nummer (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Personalausweis (Steuernummer Individuell 8-11 Ziffern)",
    labelCIIndividualTaxIDBranchLocation: "CI (Individual Tax ID), Zweigstellenstandort",
    labelTaxIDRUCorCI: "Steuernummer (RUC oder CI)",
    labelSortCodeHelp: "Hilfe?",
    labelBranchCodeHelp: "Hilfe?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Hilfe?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Hilfe?",
    labelRoutingCodeBSB: "Routing Code/BSB",
    labelBSB: "Bank State Branch (BSB)",
    labelSKNCode: "SKN-Code",
    labelAcceptanceFlights: "Ich/wir akzeptiere/n, dass bei erfolgreichem Erstattungsantrag die Buchung inklusive aller Flüge und Nebenprodukte storniert wird.",
    labelAcceptanceTickets: "Ich/Wir akzeptiere/n, dass bei erfolgreichem Erstattungsantrag die Buchung inklusive aller Tickets und Nebenprodukte storniert wird.",
    labelTermsAndConditions1: "Ich / wir haben dem zugestimmt",
    labelTermsAndConditions2: "Geschäftsbedingungen",
    labelFraudWarning1: "Ich glaube, dass die in diesem Erstattungsantragsformular angegebenen Fakten echt und wahr sind. Mir ist bekannt, dass falsche Informationen oder Beweise als Straftat angesehen und zur Verfolgung an die Polizei oder andere Strafverfolgungsbehörden weitergeleitet werden können.",
    labelFraudWarning2: "Wenn wir einen Betrug in Bezug auf Belege vermuten, wird diese Angelegenheit unverzüglich an die örtlichen Polizeibehörden weitergeleitet. Ich akzeptiere und verstehe, dass meine Daten an diese Behörden weitergegeben werden, falls mein Antrag als betrügerisch angesehen wird und dies zu einer strafrechtlichen Untersuchung führen kann.",
    validationTermsAndConditions: "Bedingungen und Konditionen müssen vereinbart werden",
    messageFailbankName: "Der Bankname darf nicht leer sein",
    messageFailcustomerName: "Der Kundenname darf nicht leer sein",
    messageFailbankAccountNumber: "Pflichtfeld ",
    messageFailbankAccountNumberFormat: "Muss das IBAN-Format haben",
    messageFailsortCode: "Pflichtfeld",
    messageFailbankSwiftBic: "Der SWIFT BIC des Begünstigten darf nicht leer sein",
    messageFailbankSwiftBicFormat: "Muss im SWIFT-Format sein",
    messageFailbeneficiaryAddress1: "Adresse 1 darf nicht leer sein",
    messageFailbeneficiaryCity: "Stadt kann nicht leer sein",
    messageFailbeneficiaryPostCode: "Die Postleitzahl darf nicht leer sein",
    messageFailremittanceLine3: "Pflichtfeld",
    messageFailremittanceLine4: "Pflichtfeld",
    modalHeading: "Bankdaten bestätigen",
    modalLabelPleaseConfirmYourDetails: "Bitte überprüfen Sie Ihre Bankdaten und bestätigen Sie.",
    modalValidationPleaseConfirm: "Bitte bestätigen",
    modalValidationMustMatch: "Muss mit dem vorherigen Eintrag übereinstimmen",
    tooltipBankAccountName: "Ihr Name, wie er auf Ihrem Bankkonto / Kontoauszug / Ihrer Bankkarte erscheint.",
    tooltipBankAccountNameRecipient: "Der Vor- und Nachname des Empfängers. Sowohl der Vor- als auch der Nachname müssen mehr als ein Zeichen haben.",
    tooltipBankCountry: "Das Land, in dem sich Ihre Bank befindet.",
    tooltipBankName: "Der Name Ihrer Bank.",
    tooltipPaymentType: "Die von Ihnen gewünschte Zahlungsart.",
    tooltipInstitutionNumber: "Diese ist 3-stellig und befindet sich normalerweise auf Ihrer Monatsabrechnung oder Bankkarte oder wenn Sie sich noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipBankAccountNumber: "Ihre individuelle Kontonummer. Normalerweise auf Ihrem monatlichen Kontoauszug oder Ihrer Bankkarte zu finden oder wenn Sie sich immer noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipBankAccountNumberCBU: "Eine 22-stellige Nummer zur Identifizierung Ihres Kontos. In der Regel auf Ihrer Monatsabrechnung oder Bankkarte zu finden oder wenn Sie sich noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipBankAccountNumberCCI: "Eine 20-stellige Nummer zur Identifizierung Ihres Kontos. In der Regel auf Ihrer Monatsabrechnung oder Bankkarte zu finden oder wenn Sie sich noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipBankAccountNumberKRW: "Eine 11-16-stellige Nummer zur Identifizierung Ihres Kontos. In der Regel auf Ihrem Monatsauszug oder Ihrer Bankkarte zu finden oder wenn Sie sich noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipCardNumber: "Ihre Kartennummer. In der Regel auf Ihrer Monatsabrechnung oder Bankkarte zu finden oder wenn Sie sich noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipUnionpay: "Ihre UnionPay-Kartennummer.",
    tooltipAlipay: "Ihre Handynummer oder E-Mail-Adresse, die mit Ihrem Alipay-Konto verknüpft ist.",
    tooltipBankAccountType: "Dies ist entweder Prüfen oder Sparen.",
    tooltipSortCode: "Dies identifiziert die Filiale Ihrer Bank, in der Ihr Konto geführt wird. Normalerweise auf Ihrem monatlichen Kontoauszug oder Ihrer Bankkarte zu finden oder wenn Sie sich immer noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipRoutingCode: "Dies identifiziert die Filiale Ihrer Bank, in der Ihr Konto geführt wird. Normalerweise auf Ihrem monatlichen Kontoauszug oder Ihrer Bankkarte zu finden oder wenn Sie sich immer noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipBeneficiarySWIFTBIC: "Besteht aus 8 bis 11 Zeichen, mit denen eine Bank bei einer internationalen Transaktion identifiziert wird. In der Regel auf Ihrem monatlichen Kontoauszug zu finden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBSB: "Eine Bank State Branch (BSB) -Nummer ist ein australischer Bankleitzahl mit 6 Ziffern. In der Regel auf Ihrem monatlichen Kontoauszug zu finden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipRoutingCodeBSB: "Dies identifiziert die Filiale Ihrer Bank, in der Ihr Konto geführt wird. Normalerweise auf Ihrem Monatsauszug oder Ihrer Bankkarte zu finden oder wenn Sie sich immer noch nicht sicher sind, fragen Sie Ihre Bank.",
    tooltipABAFedwireRoutingCode: "Eine 9-stellige Nummer zur Identifizierung von Banken in Amerika. In der Regel auf Ihrem monatlichen Kontoauszug zu finden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipIFSCCode: "Normalerweise finden Sie diesen Code auf Ihrer Monatsabrechnung oder Ihrem Sparbuch oder fragen Sie bei Ihrer Bank nach.",
    tooltipTermsAndConditions: "Durch das Akzeptieren der Allgemeinen Geschäftsbedingungen bestätigen Sie, dass Sie unsere Allgemeinen Geschäftsbedingungen gelesen haben und diesen zustimmen.",
    tooltipIBAN: "Ihre internationale Bankkontonummer, die Sie beim Tätigen oder Empfangen internationaler Zahlungen verwenden können. In der Regel auf Ihrem monatlichen Kontoauszug zu finden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Name und Telefonnummer des Empfängers.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Geburtsdatum und Geburtsort des Begünstigten.",
    tooltipBankRoutingCodeNationalID: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBankTransitCodeSwift: "Diese ist 5-stellig und steht in der Regel auf Ihrem monatlichen Kontoauszug oder wenn Sie sich noch unsicher sind, fragen Sie bei Ihrer Bank nach.",
    tooltipBankAddress: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBankAddress2: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBankCity: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBankState: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBranchName: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBankCode: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBIKCode: "9 stellig, um Ihre Bank zu identifizieren.",
    tooltipBranchCode: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBranchCodeUniversal: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipCNAPSCode: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipRemittanceLine4: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipCUILNumber: "Dies ist eine 11-stellige Zahl, bestehend aus zwei Ziffern, Bindestrich, neun Ziffern und einer einstelligen Prüfsumme.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipCFPNumber: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipBranchNameorBranchCode7digits: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipCedulaJuridica910digitsTaxID: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipTaxIDRUT89digits: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipJob: "Ihre aktuelle Beschäftigung.",
    tooltipDateOfBirth: "Dein Geburtsdatum.",
    tooltipNationality: "Deine Nationalität.",
    tooltipDocumentType: "Der erforderliche Dokumenttyp.",
    tooltipDocumentNumber: "Dies ist die Dokumentnummer, die Ihrer Dokumenttypauswahl zugeordnet ist.",
    tooltipRutNumber: "Ihre RUT-Nummer (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipCIIndividualTaxIDBranchLocation: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    tooltipTaxIDRUCorCI: "Kann normalerweise auf Ihrem monatlichen Kontoauszug gefunden werden oder wenn Sie immer noch unsicher sind, fragen Sie Ihre Bank.",
    errorCPFCode: "Im Moment akzeptieren wir nur CPF als PIX-Schlüssel."
};