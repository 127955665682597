export default {
    nonAutoApprovedLabelThankYou: "Tak for din refusionsansøgning.",
    nonAutoApprovedLabelReview: "Vores team gennemgår din ansøgning inden for de næste to arbejdsdage og vender tilbage til dig med et svar.",
    nonAutoApprovedLabelExperience: "Hvordan klarede vi os?",
    nonAutoApprovedLabelExperienceIntroduction: "Vi vil virkelig sætte pris på, at du tager dig tid til at bedømme os og efterlade en anmeldelse, da vi værdsætter vores kunders mening.",
    feedbackExcellent: "Udmærket",
    feedbackGreat: "Enestående",
    feedbackAverage: "Gennemsnitlig",
    feedbackPoor: "Fattig",
    feedbackBad: "Dårlig",
    labelThankYouForFeedback: "Tak for din feedback.",
    autoApprovedMessage1: "Vi er glade for at",
    autoApprovedMessage2: "Godkende",
    autoApprovedMessage3: "din refusion!",
    autoApprovedPayment1Day: "Din betaling er allerede blevet behandlet og skulle være på din konto på kun 1 arbejdsdag.",
    autoApprovedPayment2to4Days: "Din betaling er allerede blevet behandlet og skulle være på din konto på kun 2-4 hverdage.",
    autoApprovedPayment7to10Days: "Din betaling er allerede blevet behandlet og skulle være på din konto inden for kun 7-10 hverdage.",
    autoApprovedLabelExperience: "Hvordan klarede vi os?",
    autoApprovedLabelExperienceIntroduction: "Vi vil virkelig sætte pris på, at du tager dig tid til at bedømme os og efterlade en anmeldelse, da vi værdsætter vores kunders mening.",
    modalLabelNegative: "Din feedback er virkelig vigtig for os, så lad os vide, hvis du også gerne vil have os til at komme i kontakt med dig.",
    placeholderFeedback: "Feedback",
    messageFeedbackSubmitted: "Feedback indsendt",
    reviewInstructionMessage: "5 stjerner er fremragende, 4 stjerner er fantastisk, 3 stjerner er gennemsnitlige, 2 stjerner er fattige, 1 stjerne er dårlig."
}