import {useTranslation} from 'react-i18next';

export const languageOptions = [
    {value: 'am', label: 'አማርኛ'},
    {value: 'ar', label: 'عربي'},
    {value: 'da', label: 'Dansk'},
    {value: 'de', label: 'Deutsch'},
    {value: 'el', label: 'Ελληνικά'},
    {value: 'en', label: 'English'},
    {value: 'es', label: 'Español'},
    {value: 'et', label: 'eesti keel'},
    {value: 'fi', label: 'Suomi'},
    {value: 'fr', label: 'Français'},
    {value: 'he', label: 'עִברִית'},
    {value: 'hi', label: 'हिंदी'},
    {value: 'id', label: 'Bahasa Indonesia'},
    {value: 'it', label: 'Italiano'},
    {value: 'ja', label: '日本語'},
    {value: 'kk', label: 'қазақ'},
    {value: 'km', label: 'ខ្មែរ'},
    {value: 'ko', label: '한국어'},
    {value: 'lt', label: 'latviešu valoda'},
    {value: 'lv', label: 'lietuvių kalba'},
    {value: 'ms', label: 'Bahasa Melayu'},
    {value: 'nb', label: 'Norsk Bokmål'},
    {value: 'nl', label: 'Dutch'},
    {value: 'pl', label: 'Polskie'},
    {value: 'pt', label: 'Português PT'},
    {value: 'ptbr', label: 'Português BR'},
    {value: 'ro', label: 'Română'},
    {value: 'ru', label: 'русский'},
    {value: 'sv', label: 'Svenska'},
    {value: 'th', label: 'ไทย'},
    {value: 'tr', label: 'Türkçe'},
    {value: 'uk', label: 'український'},
    {value: 'vi', label: 'Tiếng Việt'},
    {value: 'zhcn', label: '中文'},
    {value: 'zhtw', label: '台灣'}
]

export const useRefundableTermsLanguageLink = () => {

    const {i18n} = useTranslation();

    switch (i18n.language) {
        case 'ptbr':
            return 'pt-br';
        case 'zhcn':
            return 'zh-cn';
        case 'zhtw':
            return 'zh-tw';
        default:
            return i18n.language;
    }
}

export const useDateLocale = () => {

    const {i18n} = useTranslation();

    switch (i18n.language) {
        case 'en':
            return 'en-gb';
        case 'zhcn':
            return 'zh-cn';
        case 'zhtw':
            return 'zh-tw';
        default:
            return i18n.language;

    }
}

export const getWiseLanguage = (inputLanguage: string) => {

    if (inputLanguage === undefined || inputLanguage === '') {
        return 'gb';
    }

    switch (inputLanguage) {
        case 'de':
        case 'fr':
        case 'es':
        case 'id':
        case 'it':
        case 'nl':
        case 'pl':
        case 'ru':
        case 'tr':
            return inputLanguage;
        case 'ja':
            return 'jp';
        case 'pt':
        case 'ptbr':
            return 'pt';
        default:
            return 'gb';
    }
}

export const getDetectedLanguage = (inputLanguage: string) => {

    if (inputLanguage === undefined || inputLanguage === '') {
        return 'en';
    }

    const language = inputLanguage.toLowerCase();
    let outputLanguage: string;

    switch (language) {
        case 'pt-br': {
            outputLanguage = 'ptbr';
            break;
        }
        // Simplified Chinese. E.g. Mainland China
        case 'zh-cn':
        case 'zh-hans':
            outputLanguage = 'zhcn';
            break;
        // Traditional Chinese. E.g Taiwan
        case 'zh-tw':
        case 'zh-hant':
            outputLanguage = 'zhtw';
            break;
        //Spanish
        case 'es-ar':
        case 'es-bo':
        case 'es-cl':
        case 'es-co':
        case 'es-cr':
        case 'es-do':
        case 'es-ec':
        case 'es-sv':
        case 'es-gt':
        case 'es-hn':
        case 'es-mx':
        case 'es-ni':
        case 'es-pa':
        case 'es-py':
        case 'es-pe':
        case 'es-pr':
        case 'es-uy':
        case 'es-ve':
            outputLanguage = 'es';
            break;
        //German
        case 'de-at':
        case 'de-li':
        case 'de-lu':
        case 'de-ch':
            outputLanguage = 'de';
            break;
        //French
        case 'fr-be':
        case 'fr-lu':
        case 'fr-ch':
            outputLanguage = 'fr';
            break;
        default:
            outputLanguage = language;
            break;

    }

    if (languageOptions.map(x => x.value).includes(outputLanguage)) {
        return outputLanguage;
    } else {
        return 'en';
    }
}