import * as React from 'react';
import {FormikProps} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {AlertHolder} from './AlertHolder';
import {IFormValues} from '../Interfaces/IFormValues';

export interface BookingMatchedAndFoundAlertProps {
    formProps: FormikProps<IFormValues>;
}

export const BookingMatchedAndFoundAlert = (props: BookingMatchedAndFoundAlertProps) => {

    const {
        formProps
    } = props;

    const {
        applicationDeclined,
        bookingMatchedAndFound,
        bookingMatchedAndFoundCompleted,
        cancel,
        customerPaymentsEmail,
        emailReminder
    } = useFormContext();

    const {t} = useTranslation();

    const isMatchedAndFound = !applicationDeclined && (bookingMatchedAndFound || emailReminder);
    const isBookingMatchedFoundCompleted = !applicationDeclined && bookingMatchedAndFoundCompleted;

    useEffect(() => {
        if (isMatchedAndFound || isBookingMatchedFoundCompleted) {
            cancel(formProps);
        }
    }, []);

    const BookingMatchedAndFoundMessage = () => {
        return (
            <p>{t('common:messageBookingMatchedAndFound')}</p>
        );
    }

    const BookingMatchedAndFoundCompletedMessage = () => {
        return (
            <p>{t('common:messageBookingMatchedAndFoundCompleted')}
                <a href={`mailto:${customerPaymentsEmail}`}>{customerPaymentsEmail}</a>
            </p>
        );
    }

    return (
        <>
            {isMatchedAndFound &&
                <AlertHolder
                    severity='info'
                    content={<BookingMatchedAndFoundMessage/>}
                />
            }

            {isBookingMatchedFoundCompleted &&
                <AlertHolder
                    severity='info'
                    content={<BookingMatchedAndFoundCompletedMessage/>}
                />
            }
        </>
    );
}