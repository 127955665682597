export default {
    nonAutoApprovedLabelThankYou: "Paldies par atmaksas pieteikumu.",
    nonAutoApprovedLabelReview: "Mūsu komanda izskatīs jūsu pieteikumu nākamo divu darba dienu laikā un sazināsies ar jums ar atbildi.",
    nonAutoApprovedLabelExperience: "Kā mēs to izdarījām?",
    nonAutoApprovedLabelExperienceIntroduction: "Mēs ļoti novērtētu, ja veltītu laiku, lai mūs novērtētu un atstātu atsauksmi, jo mēs augstu vērtējam mūsu klientu viedokli.",
    feedbackExcellent: "Lielisks",
    feedbackGreat: "Lielisks",
    feedbackAverage: "Vidējais",
    feedbackPoor: "Nabags",
    feedbackBad: "Slikts",
    labelThankYouForFeedback: "Paldies par jūsu atsauksmēm.",
    autoApprovedMessage1: "Mēs priecājamies:",
    autoApprovedMessage2: "Apstiprināt",
    autoApprovedMessage3: "Jūsu atmaksa!",
    autoApprovedPayment1Day: "Jūsu maksājums jau ir apstrādāts, un tam vajadzētu būt jūsu kontā tikai 1 darba dienas laikā.",
    autoApprovedPayment2to4Days: "Jūsu maksājums jau ir apstrādāts, un tam vajadzētu būt jūsu kontā tikai 2–4 darbadienu laikā.",
    autoApprovedPayment7to10Days: "Jūsu maksājums jau ir apstrādāts, un tam vajadzētu būt jūsu kontā tikai 7–10 darbadienu laikā.",
    autoApprovedLabelExperience: "Kā mēs to izdarījām?",
    autoApprovedLabelExperienceIntroduction: "Mēs ļoti novērtētu, ja veltītu laiku, lai mūs novērtētu un atstātu atsauksmi, jo mēs augstu vērtējam mūsu klientu viedokli.",
    modalLabelNegative: "Jūsu atsauksmes mums ir patiešām svarīgas, informējiet mūs, ja vēlaties, lai mēs ar jums sazinātos.",
    placeholderFeedback: "Atsauksmes",
    messageFeedbackSubmitted: "Iesniegtās atsauksmes",
    reviewInstructionMessage: "5 zvaigznes ir lieliskas, 4 zvaigznes ir lieliskas, 3 zvaigznes ir vidējas, 2 zvaigznes ir sliktas, 1 zvaigzne ir slikta.",
};