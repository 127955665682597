export default {
    messageQuestionService: "이 서비스는 신청서 작성에 문제가 있는 경우에만 사용되며, 여기에서 다른 질문 사항에 대한 답변을 드릴 수 없음을 알려드립니다.",
    labelName: "이름",
    labelReference: "예약 번호",
    labelPhone: "전화 번호",
    labelEmail: "이메일",
    labelQuery: "귀하의 질문",
    labelAskQuestion: "여기에 질문 내용을 작성하십시오",
    buttonOpenModal: "질문",
    title: "질문하기",
    modalTitle: "4. 신청서 양식에 이해가 안가는 부분이 있습니까?",
    toastSuccess: "귀하의 질문이 접수되었으며 24 시간 이내에 연락 드리겠습니다.",
    toastError: "요청에 문제가 발생했습니다. 다시 시도하십시오.",
    nameValidation: "사용하신 이름이 필요합니다",
    referenceValidation: "사용하신 참조번호가 필요합니다.",
    questionValidation: "사용하신 귀하의 질문이 필요합니다"
}