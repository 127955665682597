export default {
    labelBookingCancelledPostponedHeading: "Ваше бронирование было отменено или перенесено? Читать далее",
    labelBookingCancelledPostponedText1: "Нажмите",
    labelBookingCancelledPostponedText2: "для получения дополнительной информации.",
    labelNewRefundHeading: "Новое заявление на возврат?",
    labelNewRefundText: "Введите ваш номер брони здесь...",
    placeholderBookingReference: "Ссылка на бронирование",
    validationBookingReference: "Бронирование Обязательно",
    validationEmailAsBookingReference: "Адрес электронной почты не принимается в качестве ссылки на бронирование.",
    buttonFindBooking: "Номер бронирования",
    labelEmailHeading: "Уже начали?",
    labelEmailText: "Отправьте напоминание по электронной почте, чтобы получить ваше приложение.",
    placeholderEmail: "Электронный адрес",
    buttonEmailReminder: "Напоминание по электронной почте",
    labelCustomerName : "Имя клиента",
    validationCustomerName: "Требуется имя клиента",
    labelTypeOfBooking: "Тип бронирования",
    validationTypeOfBooking: "Требуется тип бронирования",
    labelEventName:"Название события",
    labelVenueName:"Название места проведения",
    labelFlightNumber:"Номер рейса",
    labelOriginAirport: "Аэропорт происхождения",
    labelHotelName: "Имя места жительства",
    labelHotelLocation: "Место проживания",
    labelCurrency: "Валюта",
    validationCurrency: "Требуется валюта",
    validationCountryCurrency: "Выбранная валюта должна быть валютой вашей страны проживания.",
    validationOfflinePayment: "Мы не можем обработать возврат средств для выбранной вами валюты и страны. Выберите альтернативные варианты.",
    labelBookingValue: "Стоимость бронирования",
    validationBookingValue: "Требуется Стоимость бронирования",
    validationBookingValueAmount: "Должно быть больше 0",
    labelDateOfPurchase: "Дата покупки",
    validationDateOfPurchase: "Требуется дата Покупки",
    labelEmailAddress: "Адрес электронной почты",
    validationEmailAddress: "Требуется адрес электронной почты",
    validationEmailAddressFormat: "Неправильный формат электронной почты",
    labelConfirmEmailAddress: "Подтвердить адрес электронной почты",
    validationConfirmEmailAddressFormat: "Неправильный формат электронной почты",
    validationConfirmEmailAddress: "Подтверждение Адрес электронной почты",
    validationConfirmEmailAddressMatch: "Адреса электронной почты не совпадают",
    validationEmailDomain: "Этот провайдер электронной почты не принимается, используйте альтернативный",
    validationDateOfEvent: "Дата события является обязательной",
    labelHomeCountry: "Страна постоянного местожительства",
    validationHomeCountry: "Страна происхождения требуется",
    labelContactNumberCountryCode: "Контактный номер Код страны",
    validationContactNumberCountryCode: "Требуется телефонный код страны",
    labelContactNumber: "Контактный номер",
    validationContactNumber: "Требуется контактный телефон",
    labelAddressLine1: "Адресная линия 1",
    labelAddressLine2: "Адресная линия 2",
    labelCity: "Город",
    labelZipCode: "Почтовый индекс",
    labelRegion: "Регион",
    validationRegion: "Требуется регион",
    labelNumberOfTicketsToRefund: "Количество билетов на возврат",
    validationNumberOfTicketsToRefund: "Количество билетов для возврата необходимо",
    validationNumberOfTicketsToRefundValue: "Должно быть больше 0",
    labelCostPerTicket: "Стоимость каждого билета",
    validationCostPerTicket: "Стоимость одного билета",
    validationCostPerTicketValue: "Должно быть больше 0",
    labelTotalRefundAmount: "Всего Сумма возврата",
    validationTotalRefundAmount: "Всего Требуется возврат Сумма",
    validationTotalRefundAmountValue: "Должно быть больше 0",
    validationTotalRefundGreaterThanBookingValue: "Общая сумма возврата не может превышать указанную выше стоимость бронирования.",
    labelBookingConfirmationFiles: "Загрузить подтверждение бронирования",
    validationBookingConfirmationFiles: "Прикрепите по крайней мере один файл или нажмите кнопку Сохранить и вернуться позже, когда документы будут у вас.",
    validationEvidenceFilesPopup: "Перед отправкой необходимо загрузить доказательства. Если у вас нет доказательств прямо сейчас, сохраните заявление и верните его, как только получите. Мы принимаем электронные копии, сканерные копии и фотографии.",
    validationBookingConfirmationFilesPopup: "Перед отправкой необходимо загрузить подтверждение бронирования. Если у вас нет подтверждения бронирования прямо сейчас, пожалуйста, сохраните заявку и верните ее, как только получите. Мы принимаем электронные копии, сканированные копии и фотографии.",
    alertUploadDocuments: "Пожалуйста, загрузите соответствующую документацию, указанную в электронном письме, а затем перейдите к шагу 3.",
    labelRefundReasonCategory: "Причина возврата",
    validationRefundReasonCategory: "Требуется возврат Обоснование",
    labelDateOfIncident: "Дата происшествия",
    labelAdditionalComments: "Дополнительные комментарии",
    tooltipAdditionalComments: "Дополнительные комментарии",
    validationAdditionalComments: "Требуются дополнительные комментарии",
    labelUnableToNotifyWithinTimeLimit: "Невозможно уведомить в течение 60 дней.",
    labelReasonForNotificationOutsideTimeLimit: "Уведомление Объяснение за пределами временного предела",
    validationReasonForNotificationOutsideTimeLimit: "Требуется основание для уведомления по истечении 60 дней.",
    labelRefundReasonFiles: "Загрузить доказательства",
    validationRefundReasonFiles: "Прикрепите по крайней мере один файл или нажмите кнопку Сохранить и вернуться позже, когда документы будут у вас.",
    validationTextScript: "Должен совпадать с латинским шрифтом",
    optionTypeOfBooking1: "Событие",
    optionTypeOfBooking2: "Тур",
    optionTypeOfBooking3: "Рейс",
    optionTypeOfBooking4: "Проживание",
    optionTypeOfBooking5: "Перевод",
    optionTypeOfBooking6: "Парковка",
    optionTypeOfBooking7: "Другое Путешествие",
    labelEventTypeDate: "Дата проведения мероприятия",
    labelEventTypeTour: "Дата тура",
    labelEventTypeFlight: "Дата вылета",
    labelEventTypeCheckIn: "Дата регистрации",
    labelEventTypeTransfer: "Дата перевода",
    labelEventTypeParking: "Дата парковки",
    labelEventTypeOtherTravel: "Дата путешествия",
    optionRefundReason11: "неблагоприятная погода",
    optionRefundReason10: "Вооруженные силы и службы реагирования на чрезвычайные ситуации Напоминаем",
    optionRefundReason15: "Изменения в датах сдачи экзаменов",
    optionRefundReason18: "Вызов в суд",
    optionRefundReason17: "COVID-19 ограничения при поездках",
    optionRefundReason19: "COVID-19 положительный тест",
    optionRefundReason20: "Бронирование отменено Оператором / Организатором",
    optionRefundReason21: "Бронирование перенесено оператором/организатором",
    optionRefundReason22: "Забронирована неверная дата",
    optionRefundReason23: "Забронированы неправильные билеты",
    optionRefundReason24: "Забронировано слишком много билетов",
    optionRefundReason4: "Смерть",
    optionRefundReason16: "Чрезвычайные обстоятельства",
    optionRefundReason14: "Государственный запрет на поездки",
    optionRefundReason9: "Чрезвычайная ситуация на дому",
    optionRefundReason1: "Болезнь/Травма/Инцидент",
    optionRefundReason8: "Жюри",
    optionRefundReason7: "Механическая поломка",
    optionRefundReason2: "Существовавшее ранее медицинское состояние",
    optionRefundReason3: "Беременность Осложнение",
    optionRefundReason5: "Отказ общественного транспорта",
    optionRefundReason6: "Нарушение полета",
    optionRefundReason13: "Кража документов",
    optionRefundReason12: "Переезд на работу",
    optionRefundReason25: "Избыточность рабочих мест",
    optionRefundReason26: "Тестовый вариант",
    optionRefundReasonConfirm1: "Нет",
    optionRefundReasonConfirm2: "Да",
    optionRefundReasonArmedForces1: "Армия",
    optionRefundReasonArmedForces2: "Военно-морской флот",
    optionRefundReasonArmedForces3: "ВВС",
    optionRefundReasonArmedForces4: "Спецназ",
    optionRefundReasonArmedForces5: "Морских пехотинцев",
    optionRefundReasonArmedForces6: "Пожар",
    optionRefundReasonArmedForces7: "Скорая помощь",
    optionRefundReasonArmedForces8: "Врач/Медсестра",
    optionRefundReasonArmedForces9: "Зубной врач",
    optionRefundReasonArmedForces10: "Другой",
    optionRefundReasonCourtSummons1: "Уголовное преследование",
    optionRefundReasonCourtSummons2: "Гражданское дело",
    optionRefundReasonCourtSummons3: "Свидетель обвинения",
    optionRefundReasonCourtSummons4: "Свидетель по гражданскому делу",
    optionRefundReasonCourtSummons5: "Истец",
    optionRefundReasonCourtSummons6: "Ответчик",
    optionRefundReasonCourtSummons7: "Другой",
    optionRefundReasonDeath1: "Муж",
    optionRefundReasonDeath2: "Жена",
    optionRefundReasonDeath3: "Звук",
    optionRefundReasonDeath4: "Дочь",
    optionRefundReasonDeath5: "Отец",
    optionRefundReasonDeath6: "Мать",
    optionRefundReasonDeath7: "Брат",
    optionRefundReasonDeath8: "Сестра",
    optionRefundReasonDeath9: "Дед",
    optionRefundReasonDeath10: "Бабушка",
    optionRefundReasonDeath11: "Другой",
    optionRefundReasonHomeEmergency1: "Пожар",
    optionRefundReasonHomeEmergency2: "Наводнение",
    optionRefundReasonHomeEmergency3: "Грабёж",
    optionRefundReasonHomeEmergency4: "Вандализм",
    optionRefundReasonHomeEmergency5: "Домашнее насилие",
    optionRefundReasonHomeEmergency6: "Похищать",
    optionRefundReasonHomeEmergency7: "Другой",
    optionRefundReasonCOVID19Person1: "Себя",
    optionRefundReasonCOVID19Person2: "Другое лицо в бронировании",
    optionRefundReasonCOVID19Person3: "Человек в моей семье",
    optionRefundReasonCOVID19Person4: "Другой",
    optionRefundReasonCOVID19TestType1: "ПЦР-тест",
    optionRefundReasonCOVID19TestType2: "Тест RAT",
    optionRefundReasonCOVID19TestType3: "Испытание бокового потока",
    optionRefundReasonCOVID19TestType4: "Самостоятельный тест",
    optionRefundReasonCOVID19TestType5: "Нет теста, но у меня есть симптомы",
    optionRefundReasonCOVID19TestType6: "Другой",
    optionRefundReasonBreakdown1: "Поломка",
    optionRefundReasonBreakdown2: "Авария",
    optionRefundReasonBreakdown3: "Пожар",
    optionRefundReasonBreakdown4: "Кража",
    optionRefundReasonPublicTransportType1: "Поезд",
    optionRefundReasonPublicTransportType2: "Автобус",
    optionRefundReasonPublicTransportType3: "Трамвай",
    optionRefundReasonPublicTransportType4: "Лодка",
    optionRefundReasonDocumentType1: "Паспорт",
    optionRefundReasonDocumentType2: "Разрешение на поездку",
    optionRefundReasonDocumentType3: "Национальное удостоверение личности",
    optionRefundReasonDocumentType4: "Билеты",
    optionRefundReasonWorkRelocationType1: "Временный",
    optionRefundReasonWorkRelocationType2: "Постоянный",
    optionRefundReasonWorkRelocationTemporary1: "0-3 месяца",
    optionRefundReasonWorkRelocationTemporary2: "3-6 месяцев",
    optionRefundReasonWorkRelocationTemporary3: "Более 6 месяцев",
    optionRefundReasonBookingRelationshipType1: "Кто-то в бронировании",
    optionRefundReasonBookingRelationshipType2: "Ближайший родственник кого-то в бронировании",
    optionEmploymentType1: "Полная занятость",
    optionEmploymentType2: "Неполный рабочий день",
    optionEmploymentType3: "Контракт",
    optionEmploymentLengthType1: "0-1 год",
    optionEmploymentLengthType2: "1-2 года",
    optionEmploymentLengthType3: "Более 2 лет",
    textRefundEvidenceHeading: "Пожалуйста, предоставьте следующие доказательства:",
    textRefundEvidence1: "Подтверждение авиакомпании",
    textRefundEvidence2: "Любые соответствующие подтверждающие доказательства",
    textRefundEvidence3: "Подтверждение вызова службы поломки",
    textRefundEvidence4: "Свидетельство о смерти",
    textRefundEvidence5: "Подтверждение отмены от авиакомпании",
    textRefundEvidence6: "Прогноз погоды",
    textRefundEvidence7: "Корреспонденция от агента по бронированию, подтверждающая отсутствие возможности переоформления билетов",
    textRefundEvidence8: "Письмо суда",
    textRefundEvidence9: "Медицинская справка / Справка от врача",
    textRefundEvidenceDescription9: "Подтверждая детали болезни или травмы, дату их первого возникновения, что это мешает Вам присутствовать на Бронировании.",
    textRefundEvidence10: "Подтверждение письма работодателя",
    textRefundEvidence11: "Корреспонденция страховых претензий",
    textRefundEvidence12: "Письмо от работодателя",
    textRefundEvidence13: "Подтверждение веб-сайта национального правительства",
    textRefundEvidence14: "Уведомление об изменении экзамена",
    textRefundEvidence15: "Полицейский отчет",
    textRefundEvidence16: "Полицейский протокол и справочный номер",
    textRefundEvidence17: "Подтверждение поставщика транспортных услуг",
    textRefundEvidence18: "Предупреждение о путешествии",
    textRefundEvidence20: "Сертификат теста на COVID-19",
    textRefundEvidence24: "Уведомление об изоляции правительства",
    textRefundEvidence25: "Письмо командира",
    textRefundEvidence26: "Письмо от страховщиков",
    textRefundEvidence27: "Подтверждение адреса",
    textRefundEvidence28: "Доказательство родства с умершим",
    textRefundEvidence29: "Доказательство родства с потерпевшим/больным лицом",
    textRefundEvidence30: "расчетный лист",
    tooltipBookingReference: "Чтобы определить номер вашего бронирования, проверьте подтверждение бронирования и просмотрите раздел о нашем расширенном обслуживании.",
    tooltipCustomerName: "Имя и фамилия, использованные при бронировании.",
    tooltipTypeOfBooking: "Тип бронирования, за которое вы хотите получить возврат.",
    tooltipEventName: "Название мероприятия.",
    tooltipVenueName: "Название места проведения.",
    tooltipFlightNumber: "Номер рейса.",
    tooltipOriginAirport: "Аэропорт вылета.",
    tooltipHotelName: "Название жилья.",
    tooltipHotelLocation: "Расположение жилья.",
    tooltipCurrency: "Валюта, используемая для оплаты бронирования.",
    tooltipBookingValue: "Общая стоимость, которую вы заплатили за бронирование.",
    tooltipDateOfPurchase: "Дата, когда было совершено бронирование.",
    tooltipEmailAddress: "Пожалуйста, используйте действующий адрес электронной почты. Мы используем этот адрес электронной почты для переписки при подаче заявки на возврат.",
    tooltipConfirmEmailAddress: "Используйте тот же адрес электронной почты, что и выше, это для проверки.",
    tooltipHomeCountry: "Это поможет нам отобразить правильную банковскую информацию на последнем этапе.",
    tooltipContactNumberCountryCode: "Контактный телефонный код страны.",
    tooltipContactNumber: "Пожалуйста, используйте действительный номер. Мы используем его для переписки при подаче заявки на возврат.",
    tooltipAddressLine1: "Обратите внимание, что ваш адрес будет использован при подтверждении вашего банковского счета.",
    tooltipAddressLine2: "Вторая строка адреса.",
    tooltipCity: "в нашем городе, данные могут быть использованы для проверки банковских реквизитов.",
    tooltipZipCode: "Индекс / Почтовый индекс.",
    tooltipRegion: "Регион вашего города, реквизиты могут быть использованы для проверки банковских реквизитов.",
    tooltipNumberOfTicketsToRefund: "Количество билетов, за которые вы хотите получить возврат.",
    tooltipTotalRefundAmount: "Это не может быть больше общей стоимости бронирования, в противном случае вы не сможете подать заявку.",
    tooltipBookingConfirmationFiles: "Снимок экрана / приложение с полным подтверждением бронирования, которое обычно можно найти в ваших электронных письмах.",
    tooltipRefundReasonCategory: "Пожалуйста, выберите причину возврата.",
    tooltipDateOfIncident: "Дата, когда произошел инцидент, повлиявший на ваше бронирование.",
    tooltipRefundExplanation: "Пожалуйста, предоставьте как можно больше подробностей, подтверждающих требование о возмещении.",
    tooltipUnableToNotifyWithinTimeLimit: "Невозможно уведомить в течение 60 дней.",
    tooltipReasonForNotificationOutsideTimeLimit: "Уведомление вне пределов времени. Объяснение.",
    tooltipRefundReasonFiles: "Пожалуйста, загрузите любой из необходимых документов.",
    tooltipEventTypeDate: "Дата фактического события / бронирования.",
    tooltipEventTypeTour: "Дата фактического тура.",
    tooltipEventTypeFlight: "Дата фактического полета.",
    tooltipEventTypeCheckIn: "Дата фактического заселения.",
    tooltipEventTypeTransfer: "Дата фактического перевода.",
    tooltipEventTypeParking: "Дата фактической парковки.",
    tooltipEventTypeOtherTravel: "Дата начала фактического путешествия."
};