import * as React from 'react';
import {
    FormikErrors,
    FormikProps
} from 'formik';
import {
    BookingConfirmationReminderState,
    EvidenceReminderState
} from '../../state/activity';
import {
    createContext,
    useContext
} from 'react';
import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section';
import {nameof} from 'ts-simple-nameof';
import {useFormContext} from './FormProvider';
import {SectionFieldLookup} from '../navigation/sectionFieldLookup';
import {SectionFieldLookupOfflinePayment} from '../navigation/sectionFieldLookupOfflinePayment';
import {useSetRecoilState} from 'recoil';
import {useIsOffline} from '../utils/helpers';

interface IValidationContextValues {
    saveFocus: (formProps: FormikProps<IFormValues>) => void;
    saveValidate: (formProps: FormikProps<IFormValues>) => void;
    submitFocus: (errors: FormikErrors<IFormValues>) => void;
    submitValidate: (errors: FormikErrors<IFormValues>) => void;
}

export const useValidationContext = () => useContext(Context);

const Context = createContext<IValidationContextValues>({
    saveFocus(formProps: FormikProps<IFormValues>): void { },
    saveValidate(formProps: FormikProps<IFormValues>): void { },
    submitFocus(errors: FormikErrors<IFormValues>): void { },
    submitValidate(errors: FormikErrors<IFormValues>): void { }
});

export const ValidationProvider = ({children}: Props) => {

    const isOfflinePayment = useIsOffline();

    const {
        saveForm,
        setSelectedSection
    } = useFormContext();

    const setBookingConfirmationReminder = useSetRecoilState(BookingConfirmationReminderState);
    const setEvidenceReminder = useSetRecoilState(EvidenceReminderState);

    const saveFocus = (formProps: FormikProps<IFormValues>) => {

        const isValid = formProps.errors.emailAddress === undefined &&
            formProps.errors.confirmEmailAddress === undefined &&
            formProps.errors.contactNumberCountryCode === undefined &&
            formProps.errors.contactNumber === undefined &&
            formProps.errors.totalRefundAmount === undefined;

        if (isValid) {
            saveForm(formProps);
            return;
        }

        const getNameOfErrorField = () => {
            if (formProps.errors.emailAddress !== undefined) {
                return nameof<IFormValues>(x => x.emailAddress);
            }
            if (formProps.errors.confirmEmailAddress !== undefined) {
                return nameof<IFormValues>(x => x.confirmEmailAddress);
            }
            if (formProps.errors.contactNumberCountryCode !== undefined) {
                return nameof<IFormValues>(x => x.contactNumberCountryCode);
            }
            if (formProps.errors.contactNumber !== undefined) {
                return nameof<IFormValues>(x => x.contactNumber);
            }
            if (formProps.errors.totalRefundAmount !== undefined) {
                return nameof<IFormValues>(x => x.totalRefundAmount);
            }
            return '';
        }

        const nameOfErrorField = getNameOfErrorField();

        if (nameOfErrorField === '') {
            return;
        }

        const selector = `[name="${nameOfErrorField}"]`;
        const errorElement = document.querySelector(selector) as HTMLInputElement;
        errorElement?.focus();
    }

    const saveValidate = (formProps: FormikProps<IFormValues>) => {

        setSelectedSection(Section.BookingAndContactInformation);

        void formProps.setFieldTouched(nameof<IFormValues>(x => x.emailAddress));
        formProps.validateField(nameof<IFormValues>(x => x.emailAddress));
        void formProps.setFieldTouched(nameof<IFormValues>(x => x.confirmEmailAddress));
        formProps.validateField(nameof<IFormValues>(x => x.confirmEmailAddress));
        void formProps.setFieldTouched(nameof<IFormValues>(x => x.contactNumberCountryCode));
        formProps.validateField(nameof<IFormValues>(x => x.contactNumberCountryCode));
        void formProps.setFieldTouched(nameof<IFormValues>(x => x.contactNumber));
        formProps.validateField(nameof<IFormValues>(x => x.contactNumber));
        void formProps.setFieldTouched(nameof<IFormValues>(x => x.totalRefundAmount));
        formProps.validateField(nameof<IFormValues>(x => x.totalRefundAmount));
    }

    const submitFocus = (errors: FormikErrors<IFormValues>) => {

        const keys = Object.keys(errors);
        const nameSelector = `[name="${keys[0]}"]`;
        const idSelector = `[id="${keys[0]}"]`;
        const selector = nameSelector !== '' ? nameSelector : idSelector;

        const errorElement = document.querySelector(selector) as HTMLInputElement;
        errorElement?.focus();
        validateEvidenceFiles(errors, keys);
    }

    const submitValidate = (errors: FormikErrors<IFormValues>) => {

        const keys = Object.keys(errors);
        if (keys.length === 0) {
            return;
        }

        const sectionToSelect: Section = !isOfflinePayment ? SectionFieldLookup[keys[0]] : SectionFieldLookupOfflinePayment[keys[0]] as Section;
        setSelectedSection(sectionToSelect);
    }

    function validateEvidenceFiles (errors: FormikErrors<IFormValues>, keys: string[]) {

        const isKeyWithinRange = keys?.length >= 1 && keys?.length <= 3;
        const hasBookingConfirmationFileErrors = errors.bookingConfirmationFiles?.length > 0;
        const hasRefundReasonFileErrors = errors.refundReasonFiles?.length > 0 || errors.refundReasonFilesSecondary?.length > 0;

        if (!isOfflinePayment && isKeyWithinRange && hasBookingConfirmationFileErrors) {
            setBookingConfirmationReminder(true);
        }

        if (!isOfflinePayment && isKeyWithinRange && hasRefundReasonFileErrors) {
            setEvidenceReminder(true);
        }
    }

    return (
        <Context.Provider value={{
            saveFocus,
            saveValidate,
            submitFocus,
            submitValidate
        }}>
            {children}
        </Context.Provider>
    );
}