export default {
    labelBookingCancelledPostponedHeading: "ቦታ ማስያዝዎ ተሰርዟል ወይም ለሌላ ጊዜ ተላልፏል? ተጨማሪ ያንብቡ",
    labelBookingCancelledPostponedText1: "ጠቅ ያድርጉ",
    labelBookingCancelledPostponedText2: "ለበለጠ መረጃ.",
    labelNewRefundHeading: "አዲስ ገንዘብ ተመላሽ መተግበሪያ?",
    labelNewRefundText: "የቦታ ማስያዣ ማጣቀሻዎን እዚህ ያስገቡ...",
    placeholderBookingReference: "የቦታ ማስያዝ ማጣቀሻ",
    validationBookingReference: "የቦታ ማስያዝ ማጣቀሻ ያስፈልጋል",
    validationEmailAsBookingReference: "የኢሜል አድራሻ እንደ ማስያዣ ማጣቀሻ ተቀባይነት የለውም",
    buttonFindBooking: "ቦታ ማስያዝ ያግኙ",
    labelEmailHeading: "አስቀድሞ ተጀምሯል።?",
    labelEmailText: "መተግበሪያዎን ሰርስሮ ለማውጣት የኢሜይል አስታዋሽ ይላኩ።.",
    placeholderEmail: "ኢሜይል",
    buttonEmailReminder: "የኢሜል አስታዋሽ",
    labelCustomerName : "የደንበኛ ስም",
    validationCustomerName: "የደንበኛ ስም ያስፈልጋል",
    labelTypeOfBooking: "የቦታ ማስያዝ አይነት",
    validationTypeOfBooking: "የቦታ ማስያዣ አይነት ያስፈልጋል",
    labelEventName:"የክስተት ስም",
    labelVenueName:"የቦታ ስም",
    labelFlightNumber:"የበረራ ቁጥር",
    labelOriginAirport: "መነሻ አየር ማረፊያ",
    labelHotelName: "የመጠለያ ስም",
    labelHotelLocation: "ማረፊያ ቦታ",
    labelCurrency: "ምንዛሪ",
    validationCurrency: "ምንዛሪ ያስፈልጋል",
    validationCountryCurrency: "የተመረጠው ምንዛሪ የሀገርዎ ምንዛሬ መሆን አለበት።",
    validationOfflinePayment: "ለእርስዎ ገንዘብ እና የአገር ምርጫ ተመላሽ ገንዘቦችን ማካሄድ አንችልም፣ እባክዎ አማራጮችን ይምረጡ",
    labelBookingValue: "ዋጋ ማስያዝ",
    validationBookingValue: "ዋጋ ማስያዝ ያስፈልጋል",
    validationBookingValueAmount: "ከ0 በላይ መሆን አለበት።",
    labelDateOfPurchase: "የተገዛበት ቀን",
    validationDateOfPurchase: "የግዢ ቀን ያስፈልጋል",
    labelEmailAddress: "የ ኢሜል አድራሻ",
    validationEmailAddress: "ኢሜል አድራሻ ያስፈልጋል",
    validationEmailAddressFormat: "የተሳሳተ የኢሜይል ቅርጸት",
    labelConfirmEmailAddress: "ኢሜል አረጋግጥ",
    validationConfirmEmailAddressFormat: "የተሳሳተ የኢሜይል ቅርጸት",
    validationConfirmEmailAddress: "የማረጋገጫ ኢሜይል አድራሻ ያስፈልጋል",
    validationConfirmEmailAddressMatch: "የኢሜል አድራሻዎች አይዛመዱም።",
    validationEmailDomain: "ይህ ኢሜይል አቅራቢ ተቀባይነት የለውም፣ እባክዎ አማራጭ ይጠቀሙ",
    validationDateOfEvent: "የዝግጅቱ ቀን ያስፈልጋል",
    labelHomeCountry: "የትውልድ ሀገር",
    validationHomeCountry: "የቤት ሀገር ያስፈልጋል",
    labelContactNumberCountryCode: "የእውቂያ ቁጥር የአገር ኮድ",
    validationContactNumberCountryCode: "የአገር መደወያ ኮድ ያስፈልጋል",
    labelContactNumber: "የእውቂያ ቁጥር",
    validationContactNumber: "የእውቂያ ቁጥር ያስፈልጋል",
    labelAddressLine1: "አድራሻ መስመር 1",
    labelAddressLine2: "አድራሻ መስመር 2",
    labelCity: "ከተማ",
    labelZipCode: "አካባቢያዊ መለያ ቁጥር",
    labelRegion: "ክልል",
    validationRegion: "ክልል ያስፈልጋል",
    labelNumberOfTicketsToRefund: "ገንዘብ ለመመለስ የቲኬቶች ብዛት",
    validationNumberOfTicketsToRefund: "ገንዘብ ለመመለስ የቲኬቶች ብዛት ያስፈልጋል",
    validationNumberOfTicketsToRefundValue: "ከ0 በላይ መሆን አለበት።",
    labelCostPerTicket: "የእያንዳንዱ ቲኬት ዋጋ",
    validationCostPerTicket: "የቲኬት ዋጋ ያስፈልጋል",
    validationCostPerTicketValue: "ከ0 በላይ መሆን አለበት።",
    labelTotalRefundAmount: "ጠቅላላ የተመላሽ ገንዘብ መጠን",
    validationTotalRefundAmount: "ጠቅላላ የተመላሽ ገንዘብ መጠን ያስፈልጋል",
    validationTotalRefundAmountValue: "ከ0 በላይ መሆን አለበት።",
    validationTotalRefundGreaterThanBookingValue: "ጠቅላላ የተመላሽ ገንዘብ መጠን ከላይ ካለው የቦታ ማስያዣ ዋጋ ሊበልጥ አይችልም።",
    labelBookingConfirmationFiles: "የሰቀላ ቦታ ማስያዝ ማረጋገጫ",
    validationBookingConfirmationFiles: "ቢያንስ አንድ ፋይል ያያይዙ ወይም አስቀምጥ የሚለውን ጠቅ ያድርጉ እና ሰነዶቹ ሲይዙ በኋላ ይመለሱ",
    validationEvidenceFilesPopup: "ከማቅረቡ በፊት ማስረጃዎች መጫን አለባቸው። ማስረጃው አሁን ከሌለዎት እባክዎ ማመልከቻውን ያስቀምጡ እና አንዴ ካገኙ ይመለሱ። ኤሌክትሮኒክ ቅጂዎችን, ስካነር ቅጂዎችን እና ፎቶዎችን እንቀበላለን.",
    validationBookingConfirmationFilesPopup: "ከማቅረቡ በፊት የቦታ ማስያዣ ማረጋገጫ መጫን ያስፈልጋል። የቦታ ማስያዣ ማረጋገጫ አሁን ከሌለዎት እባክዎ ማመልከቻውን ያስቀምጡ እና አንዴ ካገኙ ይመለሱ። ኤሌክትሮኒክ ቅጂዎችን, ስካነር ቅጂዎችን እና ፎቶዎችን እንቀበላለን.",
    alertUploadDocuments: "እባክዎን ተዛማጅ ሰነዶችን በኢሜል ውስጥ ይስቀሉ እና ከዚያ ወደ ደረጃ 3 ይቀጥሉ።",
    labelRefundReasonCategory: "የተመላሽ ገንዘብ ምክንያት",
    validationRefundReasonCategory: "የተመላሽ ገንዘብ ምክንያት ያስፈልጋል",
    labelDateOfIncident: "የተከሰተበት ቀን",
    labelAdditionalComments: "ተጨማሪ አስተያየቶች",
    tooltipAdditionalComments: "ተጨማሪ አስተያየቶች",
    validationAdditionalComments: "ተጨማሪ አስተያየቶች ያስፈልጋሉ።",
    labelUnableToNotifyWithinTimeLimit: "ከ ጀምሮ ባሉት 60 ቀናት ውስጥ ማሳወቅ አልተቻለም",
    labelReasonForNotificationOutsideTimeLimit: "ከግዜ ገደብ ውጪ ማስታወቂያ",
    validationReasonForNotificationOutsideTimeLimit: "ከ60 ቀናት በኋላ የማሳወቂያ ምክንያት ያስፈልጋል",
    labelRefundReasonFiles: "ማስረጃ ስቀል",
    validationRefundReasonFiles: "ቢያንስ አንድ ፋይል ያያይዙ ወይም አስቀምጥ የሚለውን ጠቅ ያድርጉ እና ሰነዶቹ ሲይዙ በኋላ ይመለሱ",
    validationTextScript: "የላቲን ፊደል መመሳሰል አለበት።",
    optionTypeOfBooking1: "ክስተት",
    optionTypeOfBooking2: "ጉብኝት",
    optionTypeOfBooking3: "በረራ",
    optionTypeOfBooking4: "ማረፊያ",
    optionTypeOfBooking5: "ማስተላለፍ",
    optionTypeOfBooking6: "የመኪና ማቆሚያ",
    optionTypeOfBooking7: "ሌላ ጉዞ",
    labelEventTypeDate: "የዝግጅቱ ቀን",
    labelEventTypeTour: "የጉብኝት ቀን",
    labelEventTypeFlight: "የበረራ ቀን",
    labelEventTypeCheckIn: "የመግቢያ ቀን",
    labelEventTypeTransfer: "የተላለፈበት ቀን",
    labelEventTypeParking: "የመኪና ማቆሚያ ቀን",
    labelEventTypeOtherTravel: "የጉዞ ቀን",
    optionRefundReason11: "መጥፎ የአየር ሁኔታ",
    optionRefundReason10: "የጦር ኃይሎች እና የአደጋ ጊዜ አገልግሎት ጥሪ",
    optionRefundReason15: "በፈተና ቀናት ላይ የተደረጉ ለውጦች",
    optionRefundReason18: "የፍርድ ቤት መጥሪያ",
    optionRefundReason17: "ኮቪድ -19 የጉዞ ገደቦች",
    optionRefundReason19: "የኮቪድ-19 አዎንታዊ ሙከራ",
    optionRefundReason20: "ቦታ ማስያዝ በኦፕሬተር/አደራጅ ተሰርዟል።",
    optionRefundReason21: "ቦታ ማስያዝ በኦፕሬተር/አደራጅ እንደገና ተይዟል።",
    optionRefundReason22: "የተያዘው የተሳሳተ ቀን",
    optionRefundReason23: "የተያዙ የተሳሳቱ ቲኬቶች",
    optionRefundReason24: "በጣም ብዙ ቲኬቶችን ተይዟል።",
    optionRefundReason4: "ሞት",
    optionRefundReason16: "የአደጋ ጊዜ ሁኔታዎች",
    optionRefundReason14: "የመንግስት የጉዞ እገዳ",
    optionRefundReason9: "የቤት ድንገተኛ አደጋ",
    optionRefundReason1: "ህመም / ጉዳት / አደጋ",
    optionRefundReason8: "የዳኝነት አገልግሎት",
    optionRefundReason7: "ሜካኒካል ብልሽት",
    optionRefundReason2: "ቀደም ሲል የነበረ የሕክምና ሁኔታ",
    optionRefundReason3: "የእርግዝና ውስብስብነት",
    optionRefundReason5: "የህዝብ ትራንስፖርት ውድቀት",
    optionRefundReason6: "የበረራ መቋረጥ",
    optionRefundReason13: "የቲኬት መስረቅ",
    optionRefundReason12: "የሥራ ቦታ ማዛወር",
    optionRefundReason25: "የሥራ ቦታ ድግግሞሽ",
    optionRefundReason26: "የሙከራ አማራጭ",
    optionRefundReasonConfirm1: "አይ",
    optionRefundReasonConfirm2: "አዎ",
    optionRefundReasonArmedForces1: "ሠራዊት",
    optionRefundReasonArmedForces2: "ባሕር ኃይል",
    optionRefundReasonArmedForces3: "የአየር ኃይል",
    optionRefundReasonArmedForces4: "ልዩ ኃይል",
    optionRefundReasonArmedForces5: "የባሕር ኃይል",
    optionRefundReasonArmedForces6: "እሳት",
    optionRefundReasonArmedForces7: "አምቡላንስ",
    optionRefundReasonArmedForces8: "ዶ/ር አረጋ ይርዳው",
    optionRefundReasonArmedForces9: "የጥርስ ሐኪም",
    optionRefundReasonArmedForces10: "ሌላ",
    optionRefundReasonCourtSummons1: "የወንጀል ክስ",
    optionRefundReasonCourtSummons2: "ሲቪል የህግ ጉዳይ",
    optionRefundReasonCourtSummons3: "በዐቃቤ ሕግ ውስጥ ምስክር",
    optionRefundReasonCourtSummons4: "በእርስ በርስ ጉዳይ ላይ የተመሰከሩ ምሥክሮች",
    optionRefundReasonCourtSummons5: "ተከሳሽ",
    optionRefundReasonCourtSummons6: "ተከሳሽ",
    optionRefundReasonCourtSummons7: "ሌላ",
    optionRefundReasonDeath1: "ባል",
    optionRefundReasonDeath2: "ሚስት",
    optionRefundReasonDeath3: "ድምፅ",
    optionRefundReasonDeath4: "ሴት ልጅ",
    optionRefundReasonDeath5: "አባት",
    optionRefundReasonDeath6: "እናት",
    optionRefundReasonDeath7: "ወንድም",
    optionRefundReasonDeath8: "እህት",
    optionRefundReasonDeath9: "ወንድ አያት",
    optionRefundReasonDeath10: "ሴት አያት",
    optionRefundReasonDeath11: "ሌላ",
    optionRefundReasonHomeEmergency1: "እሳት",
    optionRefundReasonHomeEmergency2: "ጎርፍ",
    optionRefundReasonHomeEmergency3: "ዝርፊያ",
    optionRefundReasonHomeEmergency4: "ሥርዓት አልበኝነት",
    optionRefundReasonHomeEmergency5: "በቤት ውስጥ የሚፈጸም በደል",
    optionRefundReasonHomeEmergency6: "አፈና",
    optionRefundReasonHomeEmergency7: "ሌላ",
    optionRefundReasonCOVID19Person1: "እኔ ራሴ",
    optionRefundReasonCOVID19Person2: "በመዝገቡ ውስጥ ሌላ ሰው",
    optionRefundReasonCOVID19Person3: "በቤቴ ውስጥ ያለ ሰው",
    optionRefundReasonCOVID19Person4: "ሌላ",
    optionRefundReasonCOVID19TestType1: "PCR ፈተና",
    optionRefundReasonCOVID19TestType2: "የአይቲ ምርመራ",
    optionRefundReasonCOVID19TestType3: "የላተራል ፍሰት ፈተና",
    optionRefundReasonCOVID19TestType4: "ራስን ችሎ የተፈተነ ፈተና",
    optionRefundReasonCOVID19TestType5: "ምንም ፈተና የለም ግን የሕመም ምልክቶች",
    optionRefundReasonCOVID19TestType6: "ሌላ",
    optionRefundReasonBreakdown1: "መበጠስ",
    optionRefundReasonBreakdown2: "አደጋ",
    optionRefundReasonBreakdown3: "እሳት",
    optionRefundReasonBreakdown4: "ስርቆት",
    optionRefundReasonPublicTransportType1: "ባቡር",
    optionRefundReasonPublicTransportType2: "አውቶቡስ",
    optionRefundReasonPublicTransportType3: "የትራም",
    optionRefundReasonPublicTransportType4: "ጀልባ",
    optionRefundReasonDocumentType1: "ፓስፖርት",
    optionRefundReasonDocumentType2: "የጉዞ ፈቃድ",
    optionRefundReasonDocumentType3: "ብሔራዊ መታወቂያ",
    optionRefundReasonDocumentType4: "ቲኬቶች",
    optionRefundReasonWorkRelocationType1: "ጊዜያዊ",
    optionRefundReasonWorkRelocationType2: "ቋሚ",
    optionRefundReasonWorkRelocationTemporary1: "0-3 ወር",
    optionRefundReasonWorkRelocationTemporary2: "3-6 ወራት",
    optionRefundReasonWorkRelocationTemporary3: "ከ6 ወር በላይ",
    optionRefundReasonBookingRelationshipType1: "በመዝገብ ውስጥ ያለ ሰው",
    optionRefundReasonBookingRelationshipType2: "በመዝገብ ላይ ያለ ሰው የቅርብ ዘመድ",
    optionEmploymentType1: "ሙሉ ጊዜ",
    optionEmploymentType2: "ክፍል ሰዓት",
    optionEmploymentType3: "ውል",
    optionEmploymentLengthType1: "0-1 ዓመት",
    optionEmploymentLengthType2: "1-2 ዓመት",
    optionEmploymentLengthType3: "ከ 2 ዓመት በላይ",
    textRefundEvidenceHeading: "እባክዎ የሚከተለውን ማስረጃ ያቅርቡ:",
    textRefundEvidence1: "የአየር መንገድ ማረጋገጫ",
    textRefundEvidence2: "ማንኛውም ተገቢ ድጋፍ ማስረጃ",
    textRefundEvidence3: "ሰበር አገልግሎት የማረጋገጫ ጥሪ",
    textRefundEvidence4: "የሞት ምስክር ወረቀት",
    textRefundEvidence5: "የማረጋገጫ ስርጭት ከአየር መንገድ",
    textRefundEvidence6: "የአየር ሁኔታ ሪፖርት",
    textRefundEvidence7: "ትኬቶችን እንደገና ለማውጣት ምንም ችሎታ እንደሌለ የሚያረጋግጥ ከመዝገብ ወኪል የተላከ ደብዳቤ",
    textRefundEvidence8: "ከፍርድ ቤት የተላከ ደብዳቤ",
    textRefundEvidence9: "የህክምና ምስክር ወረቀት / የዶክትሬት ማስታወሻ",
    textRefundEvidenceDescription9: "ስለ ሕመሙ ወይም ስለ ጉዳቱ ዝርዝር መረጃ ማረጋገጫ, መጀመሪያ የተከሰተበት ቀን, አንተ ቡኪንግ ላይ እንዳትገኝ ይከለክለሃል.",
    textRefundEvidence10: "የአሠሪ ደብዳቤ ማረጋገጫ",
    textRefundEvidence11: "የኢንሹራንስ አዋሳኝ ደብዳቤ",
    textRefundEvidence12: "ከአሠሪ የተላከ ደብዳቤ",
    textRefundEvidence13: "ብሔራዊ የመንግስት ድረ-ገፅ ማረጋገጫ",
    textRefundEvidence14: "የፈተና ለውጥ ማስታወቂያ",
    textRefundEvidence15: "የፖሊስ ሪፖርት",
    textRefundEvidence16: "የፖሊስ ሪፖርት እና የማመሳከሪያ ቁጥር",
    textRefundEvidence17: "የትራንስፖርት አቅራቢ ማረጋገጫ",
    textRefundEvidence18: "የጉዞ ማስጠንቀቂያ",
    textRefundEvidence20: "COVID-19 የፈተና ሰርተፊኬት",
    textRefundEvidence24: "የመንግሥት ገለልተኛነት ማስታወቂያ",
    textRefundEvidence25: "ከኮማንድ ኦፊሰር የተላከ ደብዳቤ",
    textRefundEvidence26: "ከኢንሹራኖች የተላከ ደብዳቤ",
    textRefundEvidence27: "የአድራሻ ማስረጃ",
    textRefundEvidence28: "ከሟቹ ጋር ያለውን ዝምድና የሚያረጋግጥ ማስረጃ",
    textRefundEvidence29: "ጉዳት የደረሰበት/ኢል ሰው ጋር ግንኙነት ያለው መሆኑን የሚያረጋግጥ ማስረጃ",
    textRefundEvidence30: "የክፍያ ደብተር",
    tooltipBookingReference: "የቦታ ማስያዣ ማጣቀሻዎን ለመለየት፣ የቦታ ማስያዣ ማረጋገጫዎን ያረጋግጡ እና ስለየተራዘመ አገልግሎታችን ያለውን ክፍል ይመልከቱ።",
    tooltipCustomerName: "ቦታ ለማስያዝ ስራ ላይ የዋለው የመጀመሪያ እና የአያት ስም.",
    tooltipTypeOfBooking: "ገንዘብ እንዲመለስልዎ የሚፈልጉት የቦታ ማስያዣ አይነት.",
    tooltipEventName: "የዝግጅቱ ስም.",
    tooltipVenueName: "የቦታው ስም.",
    tooltipFlightNumber: "የበረራ ቁጥር.",
    tooltipOriginAirport: "የመነሻ አየር ማረፊያ.",
    tooltipHotelName: "የመጠለያው ስም.",
    tooltipHotelLocation: "የመጠለያው ቦታ.",
    tooltipCurrency: "ለቦታ ማስያዝ ለመክፈል ያገለገለው ገንዘብ.",
    tooltipBookingValue: "ለቦታ ማስያዝ የከፈሉት ጠቅላላ ዋጋ.",
    tooltipDateOfPurchase: "ቦታ ማስያዝ የተደረገበት ቀን.",
    tooltipEmailAddress: "እባክህ የሚሰራ የኢሜይል አድራሻ ተጠቀም። ገንዘብ ተመላሽ በሚደረግበት ጊዜ ይህንን ኢሜይል ለደብዳቤ እንጠቀማለን።.",
    tooltipConfirmEmailAddress: "ከላይ ያለውን ተመሳሳይ የኢሜይል አድራሻ ይጠቀሙ፣ ይህ ለማረጋገጫ ነው።.",
    tooltipHomeCountry: "ይህ በመጨረሻው ደረጃ ላይ ትክክለኛውን የባንክ መረጃ ለማምጣት ይረዳናል.",
    tooltipContactNumberCountryCode: "የእውቂያ ቁጥር የአገር ኮድ.",
    tooltipContactNumber: "እባክዎ ትክክለኛ ቁጥር ይጠቀሙ። ይህንን ገንዘብ ተመላሽ በሚደረግበት ጊዜ ለደብዳቤ እንጠቀማለን።.",
    tooltipAddressLine1: "የባንክ ሒሳብዎን ሲያረጋግጡ አድራሻዎ ጥቅም ላይ እንደሚውል እባክዎ ልብ ይበሉ.",
    tooltipAddressLine2: "ሁለተኛ መስመር አድራሻ.",
    tooltipCity: "የትውልድ ከተማዎ፣ ዝርዝሮች የባንክ ዝርዝሮችን ለማረጋገጥ ጥቅም ላይ ሊውሉ ይችላሉ።.",
    tooltipZipCode: "ዚፕ ኮድ / የፖስታ ኮድ.",
    tooltipRegion: "የትውልድ ከተማዎ ክልል፣ ዝርዝሮች የባንክ ዝርዝሮችን ለማረጋገጥ ጥቅም ላይ ሊውሉ ይችላሉ።.",
    tooltipNumberOfTicketsToRefund: "ተመላሽ እንዲሆን የሚፈልጉት የቲኬቶች ብዛት.",
    tooltipTotalRefundAmount: "ይህ ከጠቅላላው የቦታ ማስያዣ ዋጋ በላይ ሊሆን አይችልም፣ ካቀረበ ማመልከቻውን ማስገባት አይችሉም.",
    tooltipBookingConfirmationFiles: "አብዛኛውን ጊዜ በኢሜልዎ ውስጥ ሊገኝ የሚችል የሙሉ ቦታ ማስያዝ ማረጋገጫዎ ቅጽበታዊ ገጽ እይታ/አባሪ.",
    tooltipRefundReasonCategory: "እባክህ ገንዘቡን የምትመልስበትን ምክንያት ምረጥ.",
    tooltipDateOfIncident: "በቦታ ማስያዝዎ ላይ ተፅዕኖ ያሳደረበት ክስተት የተከሰተበት ቀን.",
    tooltipRefundExplanation: "እባክዎ የተመላሽ ገንዘብ ጥያቄን የሚደግፉ በተቻለ መጠን ብዙ ዝርዝሮችን ይስጡ.",
    tooltipUnableToNotifyWithinTimeLimit: "በ60 ቀናት የጊዜ ገደብ ውስጥ ማሳወቅ አልተቻለም.",
    tooltipReasonForNotificationOutsideTimeLimit: "ከግዜ ገደብ ውጪ ማስታወቂያ.",
    tooltipRefundReasonFiles: "እባክዎ ማናቸውንም አስፈላጊ ሰነዶች ይስቀሉ።",
    tooltipEventTypeDate: "ትክክለኛው ክስተት / ቦታ ማስያዝ ቀን.",
    tooltipEventTypeTour: "ትክክለኛው የጉብኝት ቀን.",
    tooltipEventTypeFlight: "ትክክለኛው የበረራ ቀን.",
    tooltipEventTypeCheckIn: "ትክክለኛው የመግቢያ ቀን.",
    tooltipEventTypeTransfer: "ትክክለኛው የዝውውር ቀን.",
    tooltipEventTypeParking: "ትክክለኛው የመኪና ማቆሚያ ቀን.",
    tooltipEventTypeOtherTravel: "የእውነተኛው ጉዞ መጀመሪያ ቀን."
};