export default {
    labelBookingCancelledPostponedHeading: "การจองของคุณถูกยกเลิกหรือเลื่อนออกไปหรือไม่? อ่านเพิ่มเติม",
    labelBookingCancelledPostponedText1: "คลิก",
    labelBookingCancelledPostponedText2: "สำหรับข้อมูลเพิ่มเติม",
    labelNewRefundHeading: "การขอคืนเงินใหม่?",
    labelNewRefundText: "ป้อนข้อมูลอ้างอิงการจองของคุณที่นี่ ... ",
    placeholderBookingReference: "ข้อมูลอ้างอิงการจอง",
    validationBookingReference: "จำเป็นต้องมีข้อมูลอ้างอิงการจอง",
    validationEmailAsBookingReference: "ที่อยู่อีเมลไม่ได้รับการยอมรับเป็นข้อมูลอ้างอิงการจอง",
    buttonFindBooking: "ค้นหาการจอง",
    labelEmailHeading: "เริ่มแล้ว?",
    labelEmailText: "ส่งการแจ้งเตือนทางอีเมลเพื่อดึงแอปพลิเคชันของคุณ",
    placeholderEmail: "อีเมล์",
    buttonEmailReminder: "การแจ้งเตือนอีเมล",
    labelCustomerName: "ชื่อลูกค้า",
    validationCustomerName: "ต้องระบุชื่อลูกค้า",
    labelTypeOfBooking: "ประเภทการจอง",
    validationTypeOfBooking: "ต้องระบุประเภทการจอง",
    labelEventName: "ชื่อเหตุการณ์",
    labelVenueName: "ชื่อสถานที่",
    labelFlightNumber: "หมายเลขเที่ยวบิน",
    labelOriginAirport: "สนามบินต้นทาง",
    labelHotelName: "ชื่อที่พัก",
    labelHotelLocation: "ที่ตั้งที่พัก",
    labelCurrency: "สกุลเงิน",
    validationCurrency: "ต้องใช้สกุลเงิน",
    validationCountryCurrency: "สกุลเงินที่เลือกต้องเป็นสกุลเงินของประเทศบ้านเกิดของคุณ",
    validationOfflinePayment: "เราไม่สามารถดำเนินการคืนเงินสำหรับสกุลเงินและประเทศที่คุณเลือกได้ โปรดเลือกทางเลือกอื่น",
    labelBookingValue: "มูลค่าการจอง",
    validationBookingValue: "ต้องระบุมูลค่าการจอง",
    validationBookingValueAmount: "ต้องมากกว่า 0",
    labelDateOfPurchase: "วันที่ซื้อ",
    validationDateOfPurchase: "ต้องระบุวันที่ซื้อ",
    labelEmailAddress: "ที่อยู่อีเมล",
    validationEmailAddress: "ต้องระบุที่อยู่อีเมล",
    validationEmailAddressFormat: "รูปแบบอีเมลไม่ถูกต้อง",
    labelConfirmEmailAddress: "ยืนยันที่อยู่อีเมล",
    validationConfirmEmailAddressFormat: "รูปแบบอีเมลไม่ถูกต้อง",
    validationConfirmEmailAddress: "ต้องระบุที่อยู่อีเมลยืนยัน",
    validationConfirmEmailAddressMatch: "ที่อยู่อีเมลไม่ตรงกัน",
    validationEmailDomain: "ไม่รับผู้ให้บริการอีเมลรายนี้ โปรดใช้ทางเลือกอื่น",
    validationDateOfEvent: "ต้องระบุวันที่ของเหตุการณ์",
    labelHomeCountry: "ประเทศบ้านเกิด",
    validationHomeCountry: "ประเทศบ้านเกิดจำเป็น",
    labelContactNumberCountryCode: "หมายเลขติดต่อรหัสประเทศ",
    validationContactNumberCountryCode: "ต้องระบุรหัสประเทศ",
    labelContactNumber: "เบอร์ติดต่อ",
    validationContactNumber: "ต้องระบุหมายเลขติดต่อ",
    labelAddressLine1: "ที่อยู่บรรทัดที่ 1",
    labelAddressLine2: "ที่อยู่บรรทัดที่ 2",
    labelCity: "เมือง",
    labelZipCode: "รหัสไปรษณีย์",
    labelRegion: "ภูมิภาค",
    validationRegion: "ต้องระบุภูมิภาค",
    labelNumberOfTicketsToRefund: "จำนวนตั๋วที่จะขอคืนเงิน",
    validationNumberOfTicketsToRefund: "ต้องระบุจำนวนตั๋วที่จะขอคืนเงิน",
    validationNumberOfTicketsToRefundValue: "ต้องมากกว่า 0",
    labelCostPerTicket: "ราคาตั๋วแต่ละใบ",
    validationCostPerTicket: "ต้องใช้ราคาต่อตั๋ว",
    validationCostPerTicketValue: "ต้องมากกว่า 0",
    labelTotalRefundAmount: "จำนวนเงินคืนทั้งหมด",
    validationTotalRefundAmount: "ต้องระบุจำนวนเงินคืนทั้งหมด",
    validationTotalRefundAmountValue: "ต้องมากกว่า 0",
    validationTotalRefundGreaterThanBookingValue: "จำนวนเงินที่ขอคืนต้องไม่เกินมูลค่าการจองข้างต้น",
    labelBookingConfirmationFiles: "อัปโหลดการยืนยันการจอง",
    validationBookingConfirmationFiles: "แนบไฟล์อย่างน้อยหนึ่งไฟล์หรือคลิกบันทึกและส่งคืนในภายหลังเมื่อคุณมีเอกสาร",
    validationEvidenceFilesPopup: "ต้องอัปโหลดหลักฐานก่อนส่ง หากคุณไม่มีหลักฐานในตอนนี้ โปรดบันทึกใบสมัครและส่งคืนเมื่อมี เรารับสำเนาอิเล็กทรอนิกส์ สำเนาสแกนเนอร์ และภาพถ่าย",
    validationBookingConfirmationFilesPopup: "ต้องอัปโหลดการยืนยันการจองก่อนส่ง หากคุณไม่มีการยืนยันการจองในขณะนี้ โปรดบันทึกใบสมัครและส่งคืนเมื่อคุณมี เรารับสำเนาอิเล็กทรอนิกส์ สำเนาสแกนเนอร์ และภาพถ่าย",
    alertUploadDocuments: "โปรดอัปโหลดเอกสารที่เกี่ยวข้องซึ่งมีรายละเอียดอยู่ในอีเมล จากนั้นดำเนินการต่อในขั้นตอนที่ 3",
    labelRefundReasonCategory: "เหตุผลในการคืนเงิน",
    validationRefundReasonCategory: "ต้องมีเหตุผลในการคืนเงิน",
    labelDateOfIncident: "วันที่เกิดเหตุ",
    labelAdditionalComments: "ความคิดเห็นเพิ่มเติม",
    tooltipAdditionalComments: "ความคิดเห็นเพิ่มเติม",
    validationAdditionalComments: "จำเป็นต้องมีความคิดเห็นเพิ่มเติม",
    labelUnableToNotifyWithinTimeLimit: "ไม่สามารถแจ้งเตือนภายใน 60 วันนับจาก",
    labelReasonForNotificationOutsideTimeLimit: "การแจ้งเตือนนอกเวลา จำกัด คำอธิบาย",
    validationReasonForNotificationOutsideTimeLimit: "ต้องระบุเหตุผลในการแจ้งเตือนหลังจาก 60 วัน",
    labelRefundReasonFiles: "หลักฐานการอัปโหลด",
    validationRefundReasonFiles: "แนบไฟล์อย่างน้อยหนึ่งไฟล์หรือคลิกบันทึกและส่งคืนในภายหลังเมื่อคุณมีเอกสาร",
    validationTextScript:"ต้องตรงกับสคริปต์ละติน",
    optionTypeOfBooking1: "เหตุการณ์",
    optionTypeOfBooking2: "ทัวร์",
    optionTypeOfBooking3: "เที่ยวบิน",
    optionTypeOfBooking4: "ที่พัก",
    optionTypeOfBooking5: "โอน",
    optionTypeOfBooking6: "ที่จอดรถ",
    optionTypeOfBooking7: "การเดินทางอื่น ๆ",
    labelEventTypeDate: "วันที่จัดงาน",
    labelEventTypeTour: "วันที่ทัวร์",
    labelEventTypeFlight: "วันที่ของเที่ยวบิน",
    labelEventTypeCheckIn: "วันที่เช็คอิน",
    labelEventTypeTransfer: "วันที่โอน",
    labelEventTypeParking: "วันที่จอดรถ",
    labelEventTypeOtherTravel: "วันที่เดินทาง",
    optionRefundReason11: "สภาพอากาศไม่เอื้ออำนวย",
    optionRefundReason10: "การเรียกคืนกองกำลังและบริการฉุกเฉิน",
    optionRefundReason15: "การเปลี่ยนแปลงวันสอบ",
    optionRefundReason18: "หมายเรียกศาล",
    optionRefundReason17: "การ จำกัด การเดินทางเนื่องจากโควิด -19",
    optionRefundReason19: "การทดสอบในเชิงบวกของ COVID-19",
    optionRefundReason20: "การจองถูกยกเลิกโดยผู้ประกอบการ/ผู้จัด",
    optionRefundReason21: "ตารางการจองใหม่โดยโอเปอเรเตอร์/ผู้จัด",
    optionRefundReason22: "จองผิดวัน",
    optionRefundReason23: "จองตั๋วผิด",
    optionRefundReason24: "จองตั๋วมากเกินไป",
    optionRefundReason4: "ตาย",
    optionRefundReason16: "สถานการณ์ฉุกเฉิน",
    optionRefundReason14: "ห้ามการเดินทางของรัฐบาล",
    optionRefundReason9: "ฉุกเฉินที่บ้าน",
    optionRefundReason1: "เจ็บป่วย / บาดเจ็บ / อุบัติเหตุ",
    optionRefundReason8: "บริการคณะลูกขุน",
    optionRefundReason7: "รายละเอียดทางกล",
    optionRefundReason2: "เงื่อนไขทางการแพทย์ที่มีอยู่ก่อนแล้ว",
    optionRefundReason3: "ภาวะแทรกซ้อนจากการตั้งครรภ์",
    optionRefundReason5: "ระบบขนส่งสาธารณะล้มเหลว",
    optionRefundReason6: "การหยุดชะงักของเที่ยวบิน",
    optionRefundReason13: "การขโมยเอกสาร",
    optionRefundReason12: "ย้ายที่ทำงาน",
    optionRefundReason25: "ความซ้ำซ้อนในสถานที่ทำงาน",
    optionRefundReason26: "ตัวเลือกการทดสอบ",
    optionRefundReasonConfirm1: "ไม่ใช่",
    optionRefundReasonConfirm2: "ใช่",
    optionRefundReasonArmedForces1: "กองทัพ",
    optionRefundReasonArmedForces2: "กองทัพเรือ",
    optionRefundReasonArmedForces3: "กองทัพอากาศ",
    optionRefundReasonArmedForces4: "กองกําลังพิเศษ",
    optionRefundReasonArmedForces5: "นาวิกโยธิน",
    optionRefundReasonArmedForces6: "เตาไฟ",
    optionRefundReasonArmedForces7: "รถพยาบาล",
    optionRefundReasonArmedForces8: "แพทย์/พยาบาล",
    optionRefundReasonArmedForces9: "ทันตแพทย์",
    optionRefundReasonArmedForces10: "อื่น",
    optionRefundReasonCourtSummons1: "การดําเนินคดีอาญา",
    optionRefundReasonCourtSummons2: "คดีแพ่ง",
    optionRefundReasonCourtSummons3: "พยานในคดี",
    optionRefundReasonCourtSummons4: "พยานในคดีแพ่ง",
    optionRefundReasonCourtSummons5: "โจทก์",
    optionRefundReasonCourtSummons6: "จำเลย",
    optionRefundReasonCourtSummons7: "อื่น",
    optionRefundReasonDeath1: "สามี",
    optionRefundReasonDeath2: "ภรรยา",
    optionRefundReasonDeath3: "เสียง",
    optionRefundReasonDeath4: "ลูกสาว",
    optionRefundReasonDeath5: "พ่อ",
    optionRefundReasonDeath6: "แม่",
    optionRefundReasonDeath7: "น้องชาย",
    optionRefundReasonDeath8: "น้องสาว",
    optionRefundReasonDeath9: "ปู่",
    optionRefundReasonDeath10: "ยาย",
    optionRefundReasonDeath11: "อื่น",
    optionRefundReasonHomeEmergency1: "เตาไฟ",
    optionRefundReasonHomeEmergency2: "อุทกภัย",
    optionRefundReasonHomeEmergency3: "การปล้น",
    optionRefundReasonHomeEmergency4: "ป่าเถื่อน",
    optionRefundReasonHomeEmergency5: "การทารุณกรรมภายในประเทศ",
    optionRefundReasonHomeEmergency6: "ลักพาตัว",
    optionRefundReasonHomeEmergency7: "อื่น",
    optionRefundReasonCOVID19Person1: "ฉันเอง",
    optionRefundReasonCOVID19Person2: "บุคคลอื่นในการจอง",
    optionRefundReasonCOVID19Person3: "บุคคลในครัวเรือนของฉัน",
    optionRefundReasonCOVID19Person4: "อื่น",
    optionRefundReasonCOVID19TestType1: "การทดสอบ PCR",
    optionRefundReasonCOVID19TestType2: "การทดสอบหนู",
    optionRefundReasonCOVID19TestType3: "การทดสอบการไหลด้านข้าง",
    optionRefundReasonCOVID19TestType4: "การทดสอบการจัดการด้วยตนเอง",
    optionRefundReasonCOVID19TestType5: "ไม่มีการทดสอบ แต่ฉันมีอาการ",
    optionRefundReasonCOVID19TestType6: "อื่น",
    optionRefundReasonBreakdown1: "แบ่ง",
    optionRefundReasonBreakdown2: "อุบัติเหตุ",
    optionRefundReasonBreakdown3: "เตาไฟ",
    optionRefundReasonBreakdown4: "การลักขโมย",
    optionRefundReasonPublicTransportType1: "รถไฟ",
    optionRefundReasonPublicTransportType2: "รถเมล์",
    optionRefundReasonPublicTransportType3: "รถราง",
    optionRefundReasonPublicTransportType4: "เรือ",
    optionRefundReasonDocumentType1: "หนังสือเดินทาง",
    optionRefundReasonDocumentType2: "ใบอนุญาตเดินทาง",
    optionRefundReasonDocumentType3: "บัตรประจําตัวประชาชน",
    optionRefundReasonDocumentType4: "ตั๋ว",
    optionRefundReasonWorkRelocationType1: "ชั่วคราว",
    optionRefundReasonWorkRelocationType2: "ถาวร",
    optionRefundReasonWorkRelocationTemporary1: "0-3 เดือน",
    optionRefundReasonWorkRelocationTemporary2: "3-6 เดือน",
    optionRefundReasonWorkRelocationTemporary3: "มากกว่า 6 เดือน",
    optionRefundReasonBookingRelationshipType1: "บุคคลในการจอง",
    optionRefundReasonBookingRelationshipType2: "ญาติสนิทของใครบางคนในการจอง",
    optionEmploymentType1: "งานเต็มเวลา",
    optionEmploymentType2: "งานพาร์ทไทม์",
    optionEmploymentType3: "สัญญา",
    optionEmploymentLengthType1: "0-1 ปี",
    optionEmploymentLengthType2: "1-2 ปี",
    optionEmploymentLengthType3: "มากกว่า 2 ปี",
    textRefundEvidenceHeading: "โปรดระบุหลักฐานต่อไปนี้:",
    textRefundEvidence1: "การยืนยันสายการบิน",
    textRefundEvidence2: "หลักฐานสนับสนุนที่เหมาะสม",
    textRefundEvidence3: "การยืนยันการเรียกบริการย่อย",
    textRefundEvidence4: "มรณบัตร",
    textRefundEvidence5: "การยกเลิกการยืนยันจากสายการบิน",
    textRefundEvidence6: "รายงานสภาพอากาศ",
    textRefundEvidence7: "การติดต่อจากตัวแทนการจองที่ยืนยันว่าไม่มีความสามารถในการออกตั๋วอีกครั้ง",
    textRefundEvidence8: "จดหมายจากศาล",
    textRefundEvidence9: "ใบรับรองแพทย์ / หมายเหตุแพทย์",
    textRefundEvidenceDescription9: "ยืนยันรายละเอียดของการเจ็บป่วยหรือการบาดเจ็บวันที่เกิดขึ้นครั้งแรกว่าป้องกันไม่ให้คุณเข้าร่วมการจอง",
    textRefundEvidence10: "การยืนยันจดหมายนายจ้าง",
    textRefundEvidence11: "การติดต่อเรียกร้องค่าสินไหมทดแทน",
    textRefundEvidence12: "จดหมายจากนายจ้าง",
    textRefundEvidence13: "การยืนยันเว็บไซต์ของรัฐบาลแห่งชาติ",
    textRefundEvidence14: "ประกาศเปลี่ยนแปลงการสอบ",
    textRefundEvidence15: "รายงานของตํารวจ",
    textRefundEvidence16: "รายงานตํารวจและหมายเลขอ้างอิง",
    textRefundEvidence17: "การยืนยันผู้ให้บริการขนส่ง",
    textRefundEvidence18: "คําเตือนการเดินทาง",
    textRefundEvidence20: "ใบรับรองการทดสอบ COVID-19",
    textRefundEvidence24: "ประกาศแยกตัวของรัฐบาล",
    textRefundEvidence25: "จดหมายจากผู้บังคับบัญชา",
    textRefundEvidence26: "จดหมายจากบริษัทประกัน",
    textRefundEvidence27: "หลักฐานแสดงที่อยู่",
    textRefundEvidence28: "หลักฐานความสัมพันธ์ของผู้เสียชีวิต",
    textRefundEvidence29: "หลักฐานความสัมพันธ์กับผู้บาดเจ็บ/ผู้ป่วย",
    textRefundEvidence30: "สลิปเงินเดือน",
    tooltipBookingReference: "เพื่อระบุข้อมูลอ้างอิงการจองของคุณ โปรดตรวจสอบข้อมูลยืนยันการจองและดูส่วนเกี่ยวกับบริการเพิ่มเติมของเรา",
    tooltipCustomerName: "ชื่อและนามสกุลที่ใช้ในการจอง",
    tooltipTypeOfBooking: "ประเภทการจองที่คุณต้องการคืนเงิน",
    tooltipEventName: "ชื่อของเหตุการณ์",
    tooltipVenueName: "ชื่อสถานที่จัดงาน",
    tooltipFlightNumber: "หมายเลขเที่ยวบิน",
    tooltipOriginAirport: "สนามบินขาออก",
    tooltipHotelName: "ชื่อที่พัก.",
    tooltipHotelLocation: "ที่ตั้งของที่พัก.",
    tooltipCurrency: "สกุลเงินที่ใช้ในการชำระเงินสำหรับการจอง",
    tooltipBookingValue: "ราคารวมที่คุณจ่ายสำหรับการจอง",
    tooltipDateOfPurchase: "วันที่ทำการจอง",
    tooltipEmailAddress: "โปรดใช้ที่อยู่อีเมลที่ถูกต้อง เราใช้อีเมลนี้เพื่อการติดต่อระหว่างการขอคืนเงิน",
    tooltipConfirmEmailAddress: "ใช้ที่อยู่อีเมลเดียวกันกับด้านบนซึ่งมีไว้สำหรับการตรวจสอบความถูกต้อง",
    tooltipHomeCountry: "สิ่งนี้จะช่วยให้เราแสดงข้อมูลธนาคารที่ถูกต้องในขั้นตอนสุดท้าย",
    tooltipContactNumberCountryCode: "หมายเลขติดต่อรหัสประเทศ.",
    tooltipContactNumber: "กรุณาใช้หมายเลขที่ถูกต้อง เราใช้สิ่งนี้เพื่อการติดต่อระหว่างการขอคืนเงิน",
    tooltipAddressLine1: "โปรดทราบว่าที่อยู่ของคุณจะถูกใช้เมื่อตรวจสอบบัญชีธนาคารของคุณ",
    tooltipAddressLine2: "ที่อยู่บรรทัดที่สอง",
    tooltipCity: "เมืองบ้านเกิดของคุณสามารถใช้รายละเอียดเพื่อตรวจสอบความถูกต้องของรายละเอียดธนาคารได้",
    tooltipZipCode: "รหัสไปรษณีย์ / รหัสไปรษณีย์",
    tooltipRegion: "ภูมิภาคของเมืองบ้านเกิดของคุณสามารถใช้รายละเอียดเพื่อตรวจสอบความถูกต้องของรายละเอียดธนาคารได้",
    tooltipNumberOfTicketsToRefund: "จำนวนตั๋วที่คุณต้องการขอคืน",
    tooltipTotalRefundAmount: "ต้องไม่เกินมูลค่าการจองทั้งหมดคุณจะไม่สามารถส่งใบสมัครได้หากเป็นเช่นนั้น",
    tooltipBookingConfirmationFiles: "ภาพหน้าจอ / ไฟล์แนบของการยืนยันการจองแบบเต็มซึ่งโดยปกติจะอยู่ในอีเมลของคุณ",
    tooltipRefundReasonCategory: "โปรดเลือกเหตุผลในการขอคืนเงิน",
    tooltipDateOfIncident: "วันที่เกิดเหตุการณ์ที่ส่งผลกระทบต่อการจองของคุณ",
    tooltipRefundExplanation: "โปรดระบุรายละเอียดที่สนับสนุนการเรียกร้องเงินคืนให้มากที่สุดเท่าที่จะทำได้",
    tooltipUnableToNotifyWithinTimeLimit: "ไม่สามารถแจ้งได้ภายในระยะเวลา 60 วัน",
    tooltipReasonForNotificationOutsideTimeLimit: "การแจ้งเตือนนอกเวลา จำกัด คำอธิบาย",
    tooltipRefundReasonFiles: "กรุณาอัพโหลดเอกสารใด ๆ ที่จำเป็น",
    tooltipEventTypeDate: "วันที่จัดงานจริง / จอง.",
    tooltipEventTypeTour: "วันที่ของทัวร์จริง",
    tooltipEventTypeFlight: "วันที่ของเที่ยวบินจริง",
    tooltipEventTypeCheckIn: "วันที่เช็คอินจริง",
    tooltipEventTypeTransfer: "วันที่โอนจริง.",
    tooltipEventTypeParking: "วันที่จอดรถจริง.",
    tooltipEventTypeOtherTravel: "วันที่เริ่มต้นการเดินทางจริง."
};