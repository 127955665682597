export default {
    dividerBankingDetails: "ព័ត៌មានលម្អិតអំពីធនាគារ",
    dividerBookingDetails: "ព័ត៌មានលម្អិតនៃការកក់",
    labelSavedApplicationReference: "កម្មវិធីដែលបានរក្សាទុក ឯកសារយោង:",
    labelLanguage: "ភាសា",
    messageWarningBookingHeading1: "ការកក់ត្រូវបានលុបចោល:",
    messageWarningBookingHeading2: "ការកក់ត្រូវបានពន្យារពេល / កំណត់ពេលឡើងវិញ:",
    messageWarningBookingBody1: "ប្រសិនបើការកក់របស់អ្នកត្រូវបានលុបចោលដោយអ្នករៀបចំ ឬក្រុមហ៊ុនអាកាសចរណ៍ ក្រុមការងារនេះនឹងមិនអាចជួយអ្នកក្នុងការសងប្រាក់វិញបានទេ។ អ្នកនឹងត្រូវទាក់ទងក្រុមសេវាកម្មអតិថិជនរបស់អ្នកដោយផ្ទាល់។ សូមមើលការបញ្ជាក់ការកក់របស់អ្នក ឬគេហទំព័រសម្រាប់ព័ត៌មានលម្អិតទំនាក់ទំនង.",
    messageWarningBookingBody2: "ប្រសិនបើការកក់របស់អ្នកត្រូវបានពន្យារពេល លក្ខខណ្ឌដែលអាចសងប្រាក់វិញបានបន្ថែមរបស់អ្នកនឹងបន្តរហូតដល់កាលបរិច្ឆេទថ្មី។ ប្រសិនបើអ្នកមានហេតុផលត្រឹមត្រូវសម្រាប់ការសងប្រាក់វិញក្រោមលក្ខខណ្ឌរបស់យើង សូមបំពេញទម្រង់បែបបទជាមួយនឹងភស្តុតាងគាំទ្រត្រឹមត្រូវ។.",
    messageWarningBookingBody3: "សូមចំណាំ៖ សេវាកម្មនេះអាចផ្តល់ការបង្វិលសងវិញសម្រាប់ហេតុផលដែលរួមបញ្ចូលនៅក្នុងពាក្យរបស់យើង ដែលមាននៅក្នុងអ៊ីមែលបញ្ជាក់ការកក់របស់អ្នក។",
    messageWarningBookingBody4: "ប្រសិនបើអ្នកមិនអាចធ្វើការកក់ទុកបាន ដោយសារហេតុផលក្រៅពីលក្ខខណ្ឌរបស់យើង យើងនឹងមិនអាចជួយបានទេ។ អ្នកនឹងត្រូវទាក់ទងក្រុមសេវាកម្មអតិថិជនរបស់អ្នកដោយផ្ទាល់ ព្រោះពួកគេអាចអាចជួយបាន។ សូមមើលការបញ្ជាក់ការកក់របស់អ្នក ឬគេហទំព័រសម្រាប់ព័ត៌មានលម្អិតទំនាក់ទំនង។",
    linkWarningBooking: "នៅទីនេះ",
    formSectionNavigatorStep1: "ជំហានទី 1",
    formSectionNavigatorStep2: "ជំហានទី 2",
    formSectionNavigatorStep3: "ជំហានទី 3",
    formSectionNavigatorStep1Text: "ទំនាក់ទំនង និងការកក់ព័ត៌មានលម្អិត",
    formSectionNavigatorStep2Text: "ហេតុផលសងប្រាក់វិញ។",
    formSectionNavigatorStep3Text: "ព័ត៌មានលម្អិតអំពីការទូទាត់",
    messageBookingMatchedAndFound: "ប្រសិនបើឯកសារយោងការកក់របស់អ្នកត្រូវបានភ្ជាប់ជាមួយកម្មវិធីដែលមានស្រាប់ យើងនឹងផ្ញើអ៊ីមែលដែលមានតំណភ្ជាប់ដើម្បីចូលប្រើកម្មវិធីរបស់អ្នក។",
    messageBookingMatchedAndFoundCompleted: "ពាក្យស្នើសុំសងប្រាក់វិញរបស់អ្នកត្រូវបានអនុម័តរួចហើយ! ប្រសិនបើអ្នកមានបញ្ហាជាមួយការទូទាត់របស់អ្នក សូមទាក់ទង:",
    applicationDeclinedMessage: "កម្មវិធីរបស់អ្នកត្រូវបានបដិសេធ ហើយឥឡូវនេះត្រូវបានបិទ។",
    messageBookingNotFound1: "យើងមិនអាចស្វែងរកការកក់ដែលមានឯកសារយោងនោះទេ។ សូមពិនិត្យមើលអ៊ីមែលបញ្ជាក់ការកក់របស់អ្នក ហើយបញ្ចូលម្តងទៀត.",
    messageBookingNotFound2: "កុំបារម្ភ ប្រសិនបើឯកសារយោងការកក់របស់អ្នកមិនដំណើរការ អ្នកអាចជ្រើសរើស 'ស្វែងរកការកក់' ហើយបញ្ចូលព័ត៌មានលម្អិតរបស់អ្នក។ នៅពេលបំពេញទម្រង់បែបបទ សូមបង្ហោះច្បាប់ចម្លងនៃអ៊ីមែលបញ្ជាក់ការកក់របស់អ្នក ដើម្បីជួយយើងកំណត់ទីតាំងព័ត៌មានលម្អិតនៃការកក់របស់អ្នក។.",
    messageLocatingBooking: "កំណត់ទីតាំងការកក់របស់អ្នក។...",
    messageLoadingBank: "កំពុងផ្ទុកព័ត៌មានធនាគារ...",
    messageFailCurrencyLookup: "កំហុសក្នុងការទាញយករូបិយប័ណ្ណ",
    messageSavingApplication: "កំពុងរក្សាទុកកម្មវិធីរបស់អ្នក។...",
    messageSavedApplication: "កម្មវិធីសងប្រាក់ត្រូវបានរក្សាទុកដោយជោគជ័យ",
    messageFailSavedApplication: "កម្មវិធីសងប្រាក់វិញបានបរាជ័យក្នុងការរក្សាទុក",
    messageSendingEmailReminder: "កំពុងដំណើរការ...",
    messageErrorLocationDetails: "កំហុសក្នុងការទាញយកព័ត៌មានលម្អិតទីតាំង",
    messageFailFormSubmission: "ការបញ្ជូនទម្រង់បរាជ័យ",
    messageApplicationCannotBeFound: "រកមិនឃើញកម្មវិធីទេ។",
    messageApplicationRetrievalError: "កំហុសក្នុងការទាញយកកម្មវិធី",
    messageSubmittingApplication: "ការដាក់ពាក្យស្នើសុំ...",
    messageEventDateLimitError: "ហេតុផលសម្រាប់ការជូនដំណឹងក្រៅម៉ោងកំណត់",
    messageAllTicketsAssigned: "សំបុត្រទាំងអស់ត្រូវបានចាត់ឱ្យទៅកម្មវិធីសងប្រាក់ផ្សេងទៀត។",
    selectPrefix: "ជ្រើសរើស",
    selectPostfix: "",
    fileUpload: "ចុចទីនេះដើម្បីផ្ទុកឡើង",
    fileUploadAcceptedFilesMessage: "យើងទទួលយកតែទម្រង់ PDF និងរូបភាពនៃ .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 ឯកសារដែលបានជ្រើសរើស",
    fileUploadPreviouslyUploaded: "បង្ហោះពីមុន:",
    fileUploadSelectedUploaded: "ឯកសារដែលបានជ្រើសរើសត្រូវបានផ្ទុកឡើងរួចហើយ៖",
    buttonNext: "បន្ទាប់",
    buttonPrevious: "មុន",
    buttonSave: "រក្សាទុក",
    buttonCancel: "បោះបង់",
    buttonSubmit: "ដាក់ស្នើ",
    buttonConfirm: "បញ្ជាក់",
    buttonYes: "បាទ",
    buttonNo: "ទេ",
    buttonClose: "បិទ",
    linkPrivacyStatement: "សេចក្តី​ថ្លែងការណ៍​សិទ្ធិ​ឯកជន",
    linkComplaintsPolicy: "គោលនយោបាយបណ្តឹង",
    validationRequired: "ទាមទារ",
    validationInvalid: "មិនត្រឹមត្រូវ",
    labelUploadPrefix: "បង្ហោះ",
    labelUploadPostfix: "",
    labelChange: "ការផ្លាស់ប្តូរ",
    labelView: "ទិដ្ឋភាព",
    browserNavigationMessage: "ការផ្លាស់ប្តូរដែលមិនបានរក្សាទុកនឹងត្រូវបាត់បង់"
};