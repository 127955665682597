export default {
    messageUnableToMakePaymentToCurrency: "Maalesef seçilen para birimi için banka ayrıntılarını alamıyoruz. Lütfen Para Birimi ve Bulunduğunuz Ülke'nin seçili olduğundan emin olun.",
    messageOnlyBankAccountsAccepted: "Lütfen unutmayın: Şu anda yalnızca Banka Hesaplarına ödeme yapabiliyoruz (Kredi ve Banka Kartları kabul edilmemektedir)",
    labelAccountName: "Hesap Sahibinin Adı",
    labelBankAccountName: "Hesap Sahibinin Tam Adı",
    labelBankCardHolderName: "Kart Sahibinin Adı",
    validationBankAccountName: "Hesap Sahibinin Tam Adı gereklidir",
    labelBankCountry: "Banka Ülkesi",
    labelBankName: "Banka adı",
    labelPaymentType: "Ödeme türü",
    labelInstitutionNumber: "Kurum Numarası",
    labelBankAddress: "Banka adresi",
    labelBankAddress2: "Banka adresi",
    labelBankCity: "banka şehri",
    labelBankState: "Banka Eyaleti",
    labelBankAccountNumber: "Banka hesabı numarası",
    labelBankAccountNumberCBU: "Hesap Numarası CBU",
    labelBankAccountNumberCCI: "Hesap Numarası CCI",
    labelBankAccountNumberZAR: "Banka Hesap Numarası (7-11 haneli)",
    labelCardNumber: "Kart numarası",
    labelUnionpay: "UnionPay Kart Numarası",
    labelAlipay: "Alipay Kimliği (cep numarası veya e-posta)",
    labelBankAccountType: "Hesap tipi",
    optionBankAccountTypeCURRENT: "Mevcut hesap",
    optionBankAccountTypeCHECKING: "Hesap kontrol ediliyor",
    optionBankAccountTypeSAVING: "Birikim Hesabı",
    optionBankAccountTypeSAVINGS: "Birikim Hesabı",
    optionBankAccountTypeCUENTA_VISTA: "Ana hesap",
    optionBankAccountType1: "Hesap kontrol ediliyor",
    optionBankAccountType2: "Birikim Hesabı",
    optionBankAccountType3: "Ana hesap",
    optionBankAccountType4: "Vista Hesabı",
    optionBankAccountType5: "RUT Hesabı",
    labelSortCode: "Sıralama kodu",
    labelRoutingCode: "Yönlendirme Kodu",
    labelBankRoutingCodeNationalID: "Banka Yönlendirme Kodu / Ulusal Kimlik",
    labelBankTransitCodeSwift: "Taşıma Numarası",
    labelCNAPSCode: "Yönlendirme Kodu / CNAPS Kodu (Çin Ulusal Gelişmiş Ödeme Sistemi Kodu)",
    labelABAFedwireRoutingCode: "ACH Yönlendirme Numarası",
    labelIFSCCode: "IFSC Kodu",
    labelBankCode: "Banka kodu",
    labelBIKCode: "BIK Kodu",
    labelBranchCode: "Şube Kodu",
    labelBranchCodeUniversal: "Evrensel 6 haneli şube kodu",
    labelRemittanceLine3: "Faydalanıcı İletişim Adı ve Telefon Numarası",
    labelBeneficiaryContactNameandTelephoneNumber: "Faydalanıcı İletişim Adı ve Telefon Numarası",
    labelBeneficiaryDateofBirthandPlaceofBirth: " Faydalanıcı Doğum Tarihi ve Doğum Yeri",
    labelRemittanceLine4: "Bireysel Vergi Numarası CUIL (9-12 hane)",
    labelCUILNumber: "Vergi No: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Vergi Kimliği RFC 13 basamak veya CURP 18 basamak",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Bireysel Vergi Kimliği CPF Cadastro de Pessoa Física (11-14 hane), Şube Kodu",
    labelCFPNumber: "CPF Numarası (11-14 hane)",
    labelBranchNameorBranchCode7digits: "Şube Adı veya Şube Kodu (7 hane)",
    labelCedulaJuridica910digitsTaxID: "Yasal sertifika (9-10 basamaklı Vergi Kimliği)",
    labelTaxIDRUT89digits: "Vergi Kimliği RUT (8-9 hane)",
    labelJob: "İş ismi",
    labelDateOfBirth: "Doğum tarihi",
    labelNationality: "Milliyet",
    labelDocumentType: "Döküman tipi",
    labelDocumentNumber: "Belge Numarası",
    labelRutNumber: "RUT numarası (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (Vergi Kimliği Bireysel 8-11 basamaklı)",
    labelCIIndividualTaxIDBranchLocation: "CI (Bireysel Vergi Kimliği), Şube Konumu",
    labelTaxIDRUCorCI: "Vergi Kimliği (RUC veya CI)",
    labelSortCodeHelp: "Yardım?",
    labelBranchCodeHelp: "Yardım?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Yardım?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Yardım?",
    labelRoutingCodeBSB: "Yönlendirme Kodu / BSB",
    labelBSB: "Banka Devlet Şubesi (BSB)",
    labelSKNCode: "SKN Kodu",
    labelAcceptanceFlights: "İade başvurusunun başarılı olması halinde, tüm uçuşlar ve yan ürünler dahil olmak üzere rezervasyonun iptal edileceğini kabul ediyorum/ediyoruz.",
    labelAcceptanceTickets: "İade başvurusunun başarılı olması halinde tüm biletler ve yan ürünler dahil rezervasyonun iptal edileceğini kabul ediyorum/ediyoruz.",
    labelTermsAndConditions1: "Ben / biz kabul ettik",
    labelTermsAndConditions2: "Şartlar ve koşullar",
    labelFraudWarning1: "Bu geri ödeme başvuru formunda sağlanan bilgilerin gerçek ve doğru olduğuna inanıyorum. Herhangi bir yanlış bilgi veya kanıtın bir suç eylemi olarak kabul edilebileceğini ve takip edilmesi için polise veya diğer kanun uygulayıcı kurumlara iletilebileceğini anlıyorum.",
    labelFraudWarning2: "Destekleyici belgelerle ilgili olarak dolandırıcılıktan şüphelendiğimizde, bu konu derhal yerel polis yetkililerine havale edilecektir. Başvurumun hileli olarak değerlendirilmesi ve bir ceza soruşturmasına dönüşmesi halinde, bilgilerimin bu makamlara iletileceğini kabul ediyor ve anlıyorum.",
    validationTermsAndConditions: "Şartlar ve Koşullar kabul edilmelidir",
    messageFailbankName: "Banka Adı boş olamaz",
    messageFailcustomerName: "Müşteri Adı boş olamaz",
    messageFailbankAccountNumber: "Gerekli alan",
    messageFailbankAccountNumberFormat: "IBAN biçiminde olmalıdır",
    messageFailsortCode: "Gerekli alan",
    messageFailbankSwiftBic: "Lehtar SWIFT BIC boş bırakılamaz",
    messageFailbankSwiftBicFormat: "SWIFT biçimi olmalı",
    messageFailbeneficiaryAddress1: "Adres 1 boş olamaz",
    messageFailbeneficiaryCity: "Şehir boş olamaz",
    messageFailbeneficiaryPostCode: "Posta Kodu boş olamaz",
    messageFailremittanceLine3: "Gerekli alan",
    messageFailremittanceLine4: "Gerekli alan",
    modalHeading: "Banka Bilgilerini Onaylayın",
    modalLabelPleaseConfirmYourDetails: "Lütfen banka bilgilerinizi kontrol edin ve onaylayın.",
    modalValidationPleaseConfirm: "Lütfen onaylayın",
    modalValidationMustMatch: "Önceki girişle eşleşmelidir",
    tooltipBankAccountName: "Banka hesabınızda / banka ekstrelerinizde / banka kartınızda göründüğü şekliyle adınız.",
    tooltipBankAccountNameRecipient: "Alıcının adı ve soyadı. Hem ad hem de soyadı birden fazla karakter içermelidir.",
    tooltipBankCountry: "Bankanızın bulunduğu ülke.",
    tooltipBankName: "Bankanızın adı.",
    tooltipPaymentType: "İstediğiniz ödeme türü.",
    tooltipInstitutionNumber: "Bu 3 basamaklıdır ve genellikle aylık ekstrenizde veya banka kartınızda bulunur ya da hala emin değilseniz bankanıza sorun.",
    tooltipBankAccountNumber: "Bireysel hesap numaranız. Genellikle aylık ekstrenizde veya banka kartınızda bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipBankAccountNumberCBU: "Hesabınızı tanımlamak için kullanılan 22 haneli bir numara. Genellikle aylık ekstrenizde veya banka kartınızda bulunur ya da hala emin değilseniz bankanıza sorun.",
    tooltipBankAccountNumberCCI: "Hesabınızı tanımlamak için kullanılan 20 haneli bir numara. Genellikle aylık ekstrenizde veya banka kartınızda bulunur ya da hala emin değilseniz bankanıza sorun.",
    tooltipBankAccountNumberKRW: "Hesabınızı tanımlamak için kullanılan 11-16 basamaklı bir sayı. Genellikle aylık ekstrenizde veya banka kartınızda bulunur ya da hala emin değilseniz bankanıza sorun.",
    tooltipCardNumber: "Kart numaranız. Genellikle aylık ekstrenizde veya banka kartınızda bulunur ya da hala emin değilseniz bankanıza sorun.",
    tooltipUnionpay: "UnionPay kart numaranız.",
    tooltipAlipay: "Alipay hesabınızla ilişkili cep telefonu numaranız veya e-posta adresiniz.",
    tooltipBankAccountType: "Bu, Kontrol veya Tasarruftur.",
    tooltipSortCode: "Bu, hesabınızın bulunduğu bankanızın şubesini tanımlar. Genellikle aylık ekstrenizde veya banka kartınızda bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipRoutingCode: "Bu, hesabınızın bulunduğu bankanızın şubesini tanımlar. Genellikle aylık ekstrenizde veya banka kartınızda bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipBeneficiarySWIFTBIC: "Uluslararası bir işlemde bir bankayı tanımlamak için kullanılan 8-11 karakterden oluşur. Genellikle aylık ekstrenizde bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipBSB: "Bir Banka Eyalet Şubesi (BSB) numarası, 6 basamaklı bir Avustralya tasnif kodudur. Genellikle aylık ekstrenizde bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipRoutingCodeBSB: "Bu, hesabınızın bulunduğu bankanızın şubesini tanımlar. Genellikle aylık ekstrenizde veya banka kartınızda bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipABAFedwireRoutingCode: "Amerika'daki bankaları tanımlamak için kullanılan 9 haneli bir sayı. Genellikle aylık ekstrenizde bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipIFSCCode: "Genellikle bu kod, aylık ekstrenizde veya hesap cüzdanınızda bulunur veya hala emin değilseniz bankanıza sorun.",
    tooltipTermsAndConditions: "Şartları ve koşulları kabul ederek, Şartlar ve Koşullarımızı okuduğunuzu ve kabul ettiğinizi onaylamış olursunuz.",
    tooltipIBAN: "Uluslararası ödemeler yaparken veya alırken kullanabileceğiniz Uluslararası Banka Hesap Numaranız. Genellikle aylık ekstrenizde bulunur veya hala emin değilseniz, bankanıza sorun.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Faydalanıcı İletişim Adı ve Telefon Numarası.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Yararlanıcı Doğum Tarihi ve Doğum Yeri.",
    tooltipBankRoutingCodeNationalID: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBankTransitCodeSwift: "Bu 5 basamaklıdır ve genellikle aylık banka ekstrenizde bulunur veya hala emin değilseniz bankanıza sorun.",
    tooltipBankAddress: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBankAddress2: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBankCity: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBankState: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBranchName: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBankCode: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBIKCode: "Bankanızı tanımlamak için 9 hane.",
    tooltipBranchCode: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBranchCodeUniversal: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipCNAPSCode: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipRemittanceLine4: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipCUILNumber: "Bu, iki basamak, kısa çizgi, dokuz basamak ve bir basamak sağlama toplamından oluşan 11 basamaklı bir sayıdır.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipCFPNumber: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipBranchNameorBranchCode7digits: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipCedulaJuridica910digitsTaxID: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipTaxIDRUT89digits: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipJob: "Mevcut mesleğiniz.",
    tooltipDateOfBirth: "Senin doğum tarihin.",
    tooltipNationality: "Senin uyruğun.",
    tooltipDocumentType: "Gerekli belge türü.",
    tooltipDocumentNumber: "Bu, belge türü seçiminizle ilişkili belge numarasıdır.",
    tooltipRutNumber: "RUT numaranız (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipCIIndividualTaxIDBranchLocation: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    tooltipTaxIDRUCorCI: "Genellikle aylık banka ekstrenizde bulunabilir veya hala emin değilseniz bankanıza sorun.",
    errorCPFCode: "Şu anda yalnızca CPF'yi PIX anahtarı olarak kabul ediyoruz."
};