export default {
    labelBookingCancelledPostponedHeading: "Wurde Ihre Buchung storniert oder verschoben? Mehr lesen",
    labelBookingCancelledPostponedText1: "Klicken",
    labelBookingCancelledPostponedText2: "Für mehr Information.",
    labelNewRefundHeading: "Neuer Rückerstattungsantrag?",
    labelNewRefundText: "Geben Sie hier Ihre Buchungsnummer ein ...",
    placeholderBookingReference: "Buchungsreferenz",
    validationBookingReference: "Buchungsreferenz ist erforderlich",
    validationEmailAsBookingReference: "Die E-Mail-Adresse wird nicht als Buchungsreferenz akzeptiert",
    buttonFindBooking: "Buchung finden",
    labelEmailHeading: "Bereits gestartet?",
    labelEmailText: "Senden Sie eine E-Mail-Erinnerung, um Ihre Bewerbung abzurufen.",
    placeholderEmail: "Email",
    buttonEmailReminder: "E-Mail-Erinnerung",
    labelCustomerName: "Kundenname",
    validationCustomerName: "Kundenname ist erforderlich",
    labelTypeOfBooking: "Art der Buchung",
    validationTypeOfBooking: "Art der Buchung ist erforderlich",
    labelEventName: "Veranstaltungsname",
    labelVenueName: "Veranstaltungsort Namen",
    labelFlightNumber: "Flugnummer",
    labelOriginAirport: "Origin Flughafen",
    labelHotelName: "Name der Unterkunft",
    labelHotelLocation: "Unterkunft Lage",
    labelCurrency: "Währung",
    validationCurrency: "Währung ist erforderlich",
    validationCountryCurrency: "Die ausgewählte Währung muss die Währung Ihres Heimatlandes sein",
    validationOfflinePayment: "Für Ihre Währungs- und Länderauswahl können wir keine Rückerstattungen bearbeiten. Bitte wählen Sie Alternativen aus",
    labelBookingValue: "Buchungswert",
    validationBookingValue: "Buchungswert ist erforderlich",
    validationBookingValueAmount: "Muss größer als 0 sein",
    labelDateOfPurchase: "Kaufdatum",
    validationDateOfPurchase: "Kaufdatum ist erforderlich",
    labelEmailAddress: "E-Mail-Addresse",
    validationEmailAddress: "E-Mailadresse wird benötigt",
    validationEmailAddressFormat: "Falsches E-Mail-Format",
    labelConfirmEmailAddress: "e-Mail-Adresse bestätigen",
    validationConfirmEmailAddressFormat: "Falsches E-Mail-Format",
    validationConfirmEmailAddress: "Bestätigungs-E-Mail-Adresse ist erforderlich",
    validationConfirmEmailAddressMatch: "E-Mail-Adressen stimmen nicht überein",
    validationEmailDomain: "Dieser E-Mail-Anbieter wird nicht akzeptiert, bitte verwenden Sie eine Alternative",
    validationDateOfEvent: "Datum der Veranstaltung ist erforderlich",
    labelHomeCountry: "Heimatland",
    validationHomeCountry: "Heimatland ist erforderlich",
    labelContactNumberCountryCode: "Kontaktnummer Ländercode",
    validationContactNumberCountryCode: "Ländervorwahl ist erforderlich",
    labelContactNumber: "Kontakt Nummer",
    validationContactNumber: "Kontaktnummer ist erforderlich",
    labelAddressLine1: "Anschrift Zeile 1",
    labelAddressLine2: " Adresszeile 2",
    labelCity: "Stadt",
    labelZipCode: "Postleitzahl",
    labelRegion: "Region",
    validationRegion: "Region ist erforderlich",
    labelNumberOfTicketsToRefund: "Anzahl der zu erstattenden Tickets",
    validationNumberOfTicketsToRefund: "Die Anzahl der zu erstattenden Tickets ist erforderlich",
    validationNumberOfTicketsToRefundValue: "Muss größer als 0 sein",
    labelCostPerTicket: "Kosten für jedes Ticket",
    validationCostPerTicket: "Kosten pro Ticket sind erforderlich",
    validationCostPerTicketValue: "Muss größer als 0 sein",
    labelTotalRefundAmount: "Gesamter Rückerstattungsbetrag",
    validationTotalRefundAmount: "Der gesamte Rückerstattungsbetrag ist erforderlich",
    validationTotalRefundAmountValue: "Muss größer als 0 sein",
    validationTotalRefundGreaterThanBookingValue: "Der Gesamtrückerstattungsbetrag darf nicht höher sein als der oben genannte Buchungswert",
    labelBookingConfirmationFiles: "Buchungsbestätigung hochladen",
    validationBookingConfirmationFiles: "Hängen Sie mindestens eine Datei an oder klicken Sie auf Speichern und kehren Sie später zurück, wenn Sie über die Dokumente verfügen",
    validationEvidenceFilesPopup: "Vor der Einreichung müssen die Nachweise hochgeladen werden. Sollten Ihnen die Nachweise noch nicht vorliegen, speichern Sie den Antrag bitte und senden Sie ihn anschließend zurück. Wir akzeptieren elektronische Kopien, Scannerkopien und Fotos.",
    validationBookingConfirmationFilesPopup: "Die Buchungsbestätigung muss vor dem Absenden hochgeladen werden. Wenn Sie jetzt keine Buchungsbestätigung haben, speichern Sie bitte den Antrag und senden Sie ihn zurück, sobald Sie ihn haben. Wir akzeptieren elektronische Kopien, Scannerkopien und Fotos.",
    alertUploadDocuments: "Bitte laden Sie die in der E-Mail aufgeführten relevanten Unterlagen hoch und fahren Sie dann mit Schritt 3 fort.",
    labelRefundReasonCategory: "Grund für die Rückerstattung",
    validationRefundReasonCategory: "Rückerstattungsgrund ist erforderlich",
    labelDateOfIncident: "Datum des Vorfalls",
    labelAdditionalComments: "Zusätzliche Kommentare",
    tooltipAdditionalComments: "Zusätzliche Kommentare",
    validationAdditionalComments: "Zusätzliche Kommentare sind erforderlich",
    labelUnableToNotifyWithinTimeLimit: "Innerhalb von 60 Tagen kann keine Benachrichtigung erfolgen",
    labelReasonForNotificationOutsideTimeLimit: "Benachrichtigung außerhalb des Zeitlimits Erläuterung",
    validationReasonForNotificationOutsideTimeLimit: "Ein Grund für die Benachrichtigung nach 60 Tagen ist erforderlich",
    labelRefundReasonFiles: "Beweise hochladen",
    validationRefundReasonFiles: "Hängen Sie mindestens eine Datei an oder klicken Sie auf Speichern und kehren Sie später zurück, wenn Sie über die Dokumente verfügen",
    validationTextScript: "Muss mit lateinischer Schrift übereinstimmen",
    optionTypeOfBooking1: "Veranstaltung",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Flug",
    optionTypeOfBooking4: "Unterkunft",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parken",
    optionTypeOfBooking7: "Andere Reisen",
    labelEventTypeDate: "Datum der Veranstaltung",
    labelEventTypeTour: "Datum der Tour",
    labelEventTypeFlight: "Datum des Fluges",
    labelEventTypeCheckIn: "Datum des Eincheckens",
    labelEventTypeTransfer: "Datum der Übertragung",
    labelEventTypeParking: "Datum des Parkens",
    labelEventTypeOtherTravel: "Reisedatum",
    optionRefundReason11: " Ungünstiges Wetter",
    optionRefundReason10: "Rückruf von Streitkräften und Rettungsdiensten",
    optionRefundReason15: "Änderungen der Prüfungstermine",
    optionRefundReason18: "Vorladung",
    optionRefundReason17: "COVID-19 Reiseeinschränkungen",
    optionRefundReason19: "COVID-19 positiver Test",
    optionRefundReason20: "Buchung vom Betreiber/Veranstalter storniert",
    optionRefundReason21: "Buchung vom Betreiber/Organisator verschoben",
    optionRefundReason22: "Falsches Datum gebucht",
    optionRefundReason23: "Falsche Tickets gebucht",
    optionRefundReason24: "Zu viele Tickets gebucht",
    optionRefundReason4: "Tod",
    optionRefundReason16: "Notfälle",
    optionRefundReason14: "Reiseverbot der Regierung",
    optionRefundReason9: "Heimnotfall",
    optionRefundReason1: "Krankheit / Verletzung / Unfall",
    optionRefundReason8: "Juryservice",
    optionRefundReason7: "Mechanische Panne",
    optionRefundReason2: "Vorbestehender medizinischer Zustand",
    optionRefundReason3: "Schwangerschaftskomplikation",
    optionRefundReason5: "Ausfall des öffentlichen Verkehrs",
    optionRefundReason6: "Flugstörung",
    optionRefundReason13: "Diebstahl von Dokumenten",
    optionRefundReason12: "Arbeitsverlagerung",
    optionRefundReason25: "Arbeitsplatzredundanz",
    optionRefundReason26: "Testoption",
    optionRefundReasonConfirm1: "Nein",
    optionRefundReasonConfirm2: "Ja",
    optionRefundReasonArmedForces1: "Armee",
    optionRefundReasonArmedForces2: "Kriegsmarine",
    optionRefundReasonArmedForces3: "Luftwaffe",
    optionRefundReasonArmedForces4: "Spezialeinheiten",
    optionRefundReasonArmedForces5: "Marineinfanterie",
    optionRefundReasonArmedForces6: "Feuer",
    optionRefundReasonArmedForces7: "Krankenwagen",
    optionRefundReasonArmedForces8: "Arzt/Krankenschwester",
    optionRefundReasonArmedForces9: "Zahnarzt",
    optionRefundReasonArmedForces10: "Andere",
    optionRefundReasonCourtSummons1: "Strafverfolgung",
    optionRefundReasonCourtSummons2: "Zivilrechtliche Rechtssache",
    optionRefundReasonCourtSummons3: "Zeuge in einer Anklage",
    optionRefundReasonCourtSummons4: "Zeuge in einer Zivilsache",
    optionRefundReasonCourtSummons5: "Kläger",
    optionRefundReasonCourtSummons6: "Angeklagter",
    optionRefundReasonCourtSummons7: "Andere",
    optionRefundReasonDeath1: "Ehemann",
    optionRefundReasonDeath2: "Ehefrau",
    optionRefundReasonDeath3: "Ton",
    optionRefundReasonDeath4: "Tochter",
    optionRefundReasonDeath5: "Vater",
    optionRefundReasonDeath6: "Mutter",
    optionRefundReasonDeath7: "Bruder",
    optionRefundReasonDeath8: "Schwester",
    optionRefundReasonDeath9: "Großvater",
    optionRefundReasonDeath10: "Großmutter",
    optionRefundReasonDeath11: "Andere",
    optionRefundReasonHomeEmergency1: "Feuer",
    optionRefundReasonHomeEmergency2: "Flut",
    optionRefundReasonHomeEmergency3: "Raub",
    optionRefundReasonHomeEmergency4: "Vandalismus",
    optionRefundReasonHomeEmergency5: "Häusliche Gewalt",
    optionRefundReasonHomeEmergency6: "Entführen",
    optionRefundReasonHomeEmergency7: "Andere",
    optionRefundReasonCOVID19Person1: "Mich",
    optionRefundReasonCOVID19Person2: "Eine andere Person in der Buchung",
    optionRefundReasonCOVID19Person3: "Eine Person in meinem Haushalt",
    optionRefundReasonCOVID19Person4: "Andere",
    optionRefundReasonCOVID19TestType1: "PCR-Test",
    optionRefundReasonCOVID19TestType2: "RAT-Test",
    optionRefundReasonCOVID19TestType3: "Lateral-Flow-Test",
    optionRefundReasonCOVID19TestType4: "Selbstverwalteter Test",
    optionRefundReasonCOVID19TestType5: "Kein Test, aber ich habe Symptome",
    optionRefundReasonCOVID19TestType6: "Andere",
    optionRefundReasonBreakdown1: "Panne",
    optionRefundReasonBreakdown2: "Unfall",
    optionRefundReasonBreakdown3: "Feuer",
    optionRefundReasonBreakdown4: "Diebstahl",
    optionRefundReasonPublicTransportType1: "Zug",
    optionRefundReasonPublicTransportType2: "Bus",
    optionRefundReasonPublicTransportType3: "Straßenbahn",
    optionRefundReasonPublicTransportType4: "Boot",
    optionRefundReasonDocumentType1: "Pass",
    optionRefundReasonDocumentType2: "Reisegenehmigung",
    optionRefundReasonDocumentType3: "Nationaler Ausweis",
    optionRefundReasonDocumentType4: "Billette",
    optionRefundReasonWorkRelocationType1: "Vorläufig",
    optionRefundReasonWorkRelocationType2: "Permanent sind",
    optionRefundReasonWorkRelocationTemporary1: "0-3 Monate",
    optionRefundReasonWorkRelocationTemporary2: "3-6 Monate",
    optionRefundReasonWorkRelocationTemporary3: "Über 6 Monate",
    optionRefundReasonBookingRelationshipType1: "Jemand in der Buchung",
    optionRefundReasonBookingRelationshipType2: "Unmittelbarer Verwandter einer Person in der Buchung",
    optionEmploymentType1: "Vollzeit",
    optionEmploymentType2: "Teilzeit",
    optionEmploymentType3: "Vertrag",
    optionEmploymentLengthType1: "0-1 Jahre",
    optionEmploymentLengthType2: "1-2 Jahre",
    optionEmploymentLengthType3: "mehr als 2 Jahre",
    textRefundEvidenceHeading: "Bitte legen Sie folgende Nachweise vor:",
    textRefundEvidence1: "Bestätigung der Fluggesellschaft",
    textRefundEvidence2: "Alle geeigneten Belege",
    textRefundEvidence3: "Breakdown-Service-Call-Out-Bestätigung",
    textRefundEvidence4: "Sterbeurkunde",
    textRefundEvidence5: "Bestätigung der Stornierung von der Fluggesellschaft",
    textRefundEvidence6: "Wetterbericht",
    textRefundEvidence7: "Korrespondenz des Buchungsagenten, die bestätigt, dass keine Möglichkeit besteht, Tickets erneut auszustellen",
    textRefundEvidence8: "Schreiben des Gerichts",
    textRefundEvidence9: "Ärztliches Attest / ärztliches Attest",
    textRefundEvidenceDescription9: "Bestätigung der Details der Krankheit oder Verletzung, des Datums, an dem sie zum ersten Mal aufgetreten ist, dass sie Sie daran hindert, an der Buchung teilzunehmen.",
    textRefundEvidence10: "Bestätigung des Arbeitgeberbriefes",
    textRefundEvidence11: "Korrespondenz mit Versicherungsansprüchen",
    textRefundEvidence12: "Schreiben des Arbeitgebers",
    textRefundEvidence13: "Bestätigung der Website der nationalen Regierung",
    textRefundEvidence14: "Prüfungsänderungsbescheid",
    textRefundEvidence15: "Polizeibericht",
    textRefundEvidence16: "Polizeibericht und Referenznummer",
    textRefundEvidence17: "Bestätigung des Transportanbieters",
    textRefundEvidence18: "Reisewarnung",
    textRefundEvidence20: "COVID-19 Testzertifikat",
    textRefundEvidence24: "Hinweis zur Isolation durch Behörden",
    textRefundEvidence25: "Brief des Kommandierenden Offiziers",
    textRefundEvidence26: "Schreiben von Versicherern",
    textRefundEvidence27: "Adressnachweis",
    textRefundEvidence28: "Nachweis der Beziehung zum Verstorbenen",
    textRefundEvidence29: "Nachweis der Beziehung zur verletzten/kranken Person",
    textRefundEvidence30: "Gehaltsabrechnung",
    tooltipBookingReference: "Um Ihre Buchungsreferenz zu ermitteln, überprüfen Sie Ihre Buchungsbestätigung und sehen Sie sich den Abschnitt über unseren erweiterten Service an.",
    tooltipCustomerName: "Vor- und Nachname, der für die Buchung verwendet wurde.",
    tooltipTypeOfBooking: "Art der Buchung, die Sie zurückerstatten möchten.",
    tooltipEventName: "Der Name des Ereignisses.",
    tooltipVenueName: "Der Name des Veranstaltungsortes.",
    tooltipFlightNumber: "Die Flugnummer.",
    tooltipOriginAirport: "Der Abflughafen.",
    tooltipHotelName: "Der Name der Unterkunft.",
    tooltipHotelLocation: "Die Lage der Unterkunft.",
    tooltipCurrency: "Die Währung, in der die Buchung bezahlt wurde.",
    tooltipBookingValue: "Gesamtpreis, den Sie für die Buchung bezahlt haben.",
    tooltipDateOfPurchase: "Das Datum, an dem die Buchung vorgenommen wurde.",
    tooltipEmailAddress: "Bitte verwenden Sie eine gültige E-Mail-Adresse. Wir verwenden diese E-Mail für die Korrespondenz während des Rückerstattungsantrags.",
    tooltipConfirmEmailAddress: "Verwenden Sie dieselbe E-Mail-Adresse wie oben, dies dient zur Validierung.",
    tooltipHomeCountry: "Dies hilft uns, im letzten Schritt die richtigen Bankinformationen aufzurufen.",
    tooltipContactNumberCountryCode: "Ländernummer der Kontaktnummer.",
    tooltipContactNumber: "Bitte verwenden Sie eine gültige Nummer. Wir verwenden dies für die Korrespondenz während des Rückerstattungsantrags.",
    tooltipAddressLine1: "Bitte beachten Sie, dass Ihre Adresse bei der Validierung Ihres Bankkontos verwendet wird.",
    tooltipAddressLine2: "Zweite Adresszeile.",
    tooltipCity: "Ihre Heimatstadt, Details können zur Validierung von Bankdaten verwendet werden.",
    tooltipZipCode: "Postleitzahl.",
    tooltipRegion: "In der Region Ihrer Heimatstadt können Details zur Überprüfung der Bankdaten verwendet werden.",
    tooltipNumberOfTicketsToRefund: "Die Anzahl der Tickets, die Sie erstatten möchten.",
    tooltipTotalRefundAmount: "Dies kann nicht mehr als der Gesamtbuchungswert sein. In diesem Fall können Sie den Antrag nicht einreichen.",
    tooltipBookingConfirmationFiles: "Screenshot / Anhang Ihrer vollständigen Buchungsbestätigung, die normalerweise in Ihren E-Mails enthalten ist.",
    tooltipRefundReasonCategory: "Bitte geben Sie Ihren Grund für die Rückerstattung an.",
    tooltipDateOfIncident: "Das Datum, an dem der Vorfall aufgetreten ist, der sich auf Ihre Buchung ausgewirkt hat.",
    tooltipRefundExplanation: "Bitte geben Sie so viele Details wie möglich an, die den Rückerstattungsanspruch stützen.",
    tooltipUnableToNotifyWithinTimeLimit: "Benachrichtigung innerhalb von 60 Tagen nicht möglich.",
    tooltipReasonForNotificationOutsideTimeLimit: "Benachrichtigung außerhalb des Zeitlimits Erläuterung.",
    tooltipRefundReasonFiles: "Bitte laden Sie alle erforderlichen Dokumente hoch.",
    tooltipEventTypeDate: "Datum der tatsächlichen Veranstaltung / Buchung.",
    tooltipEventTypeTour: "Datum der eigentlichen Tour.",
    tooltipEventTypeFlight: "Datum des tatsächlichen Fluges.",
    tooltipEventTypeCheckIn: "Datum des tatsächlichen Eincheckens.",
    tooltipEventTypeTransfer: "Datum der tatsächlichen Übertragung.",
    tooltipEventTypeParking: "Datum des tatsächlichen Parkens.",
    tooltipEventTypeOtherTravel: "Startdatum der eigentlichen Reise."
};