import * as React from 'react';
import Grid from '@mui/material/Grid2';
import {Divider} from '@mui/material';

interface SectionDividerProps {
    dividerText: string;
}

export const SectionDivider = (props: SectionDividerProps) => {

    const {
        dividerText
    } = props;

    return (<>
            <Grid container justifyContent='center' pb={2}>
                <Grid size={{xs: 12, md: 8}}>
                    <Divider sx={{
                        textTransform: 'uppercase',
                        wordSpacing: '1px',
                        fontSize: '17px',
                        pt: {
                            xs: '10px',
                            sm: '30px',
                            md: '30px'
                        },
                        pb: {
                            xs: '10px',
                            sm: '30px',
                            md: '30px'
                        }
                    }}>{dividerText}</Divider>
                </Grid>
            </Grid>
        </>
    );
}