export default {
    headingAdverseWeather: "You experience severe adverse weather conditions that make it unsafe to travel to the event and this has been mandated by a Government Body (e.g Local Authority, Police, etc). And you have evidence to prove this?",
    headingAdverseWeatherDecline: "You should not proceed with this application as the refund will most likely be declined.",
    tooltipRefundReasonConfirm: "Yes or No",
    labelBookingCancelledByOperator: "The tickets were cancelled by the Operator/Organiser.",
    labelBookingRescheduledByOperator: "The date was changed by the Operator/Organiser and I can not attend the new dates.",
    labelBookedWrongDate: "I or a member of the booking, made an error when purchasing the tickets and booked the wrong date.",
    labelBookedWrongTickets: "I or a member of the booking, made an error when purchasing the tickets and booked the wrong tickets.",
    labelBookedTooManyTickets: "I or a member of the booking, made an error when purchasing the tickets and booked too many tickets.",
    labelCOVID19TravelRestrictions: "I or a member of the booking, are unable to attend due to a COVID-19 travel ban.",
    labelADWrongSelection: "You may have selected the wrong reason for a refund. Please review the Refund Reason drop down list again.",
    labelAdverseWeather1:"Why did adverse weather mean you were unable to attend the event?",
    tooltipAdverseWeather1:"Please detail",
    labelAdverseWeather2:"Date of incident",
    tooltipAdverseWeather2:"Please select",
    headingArmedForces: "You are asked by your Employer which is Armed Forces or Emergency Services to come into work when you were not originally scheduled to do so and this prevents you from attending the event?",
    headingArmedForcesDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelArmedForces1: "What forces or Emergency Service are you employed by?",
    tooltipArmedForces1: "Please select",
    labelArmedForcesOther1: "Please specify",
    tooltipArmedForcesOther1: "Please specify",
    labelArmedForces2:"Date of incident",
    tooltipArmedForces2:"Please select",
    headingChangesToExam: "The dates of your examination have been changed by your school/college, etc? This is not a failed exam which you must resit?",
    headingChangesToExamDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelExam1: "What was the original examination date?",
    tooltipExam1: "Please select",
    labelExam2: "What is the new examination date?",
    tooltipExam2: "Please select",
    labelExam3: "Why did the examination date change?",
    tooltipExam3: "Please enter details",
    labelExam4: "Did you fail the previous examination?",
    tooltipExam4: "Yes or No",
    headingCourtSummons: "You have been summoned to appear in court as a witness on the same dates as the event?",
    headingCourtSummonsDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelCourtSummons1: "What is the reason for your court summons?",
    tooltipCourtSummons1: "Please select",
    labelCourtSummonsOther1: "Please specify",
    tooltipCourtSummonsOther1: "Please specify",
    labelCourtSummons2: "When was the court summons issued?",
    tooltipCourtSummons2: "Please select",
    headingCOVID19Test: "I, a member of my household or a member of the group attending the event have been diagnosed with COVID-19?",
    headingCOVID19TestDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelCOVID19Test1: "Who has COVID-19?",
    tooltipCOVID19Test1: "Please select",
    labelCOVID19TestOther1a: "Please specify",
    labelCOVID19TestOther1b: "Specify name",
    tooltipCOVID19TestOther1a: "Please specify",
    tooltipCOVID19TestOther1b: "Specify name",
    labelCOVID19Test2: "What type of COVID-19 test did you have?",
    tooltipCOVID19Test2: "Please select",
    labelCOVID19TestOther2: "Please specify",
    tooltipCOVID19TestOther2: "Please specify",
    labelCOVID19Test3: "What was the date of your COVID-19 test?",
    tooltipCOVID19Test3: "Please select",
    headingDeath: "There has been a death of an immediate member of my family and it prevents me from attending the event?",
    headingDeathDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelDeath1: "Which member of your family has died?",
    tooltipDeath1: "Please select",
    labelDeathOther1: "Please specify",
    tooltipDeathOther1: "Please specify",
    labelDeath2: "When was the date of death?",
    tooltipDeath2: "Please select",
    labelDeath3: "Name of the deceased",
    tooltipDeath3: "Please specify",
    headingEmergency: "I have had a genuine emergency that is outside of my control and unforeseen which I can provide evidence for?",
    headingEmergencyDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelEmergency1: "Please explain the type of emergency?",
    tooltipEmergency1: "Please detail",
    labelEmergency2: "When did this happen?",
    tooltipEmergency2: "Please select",
    labelEmergency3: "Why does this situation stop you attending the event?",
    tooltipEmergency3: "Please detail",
    labelEmergency4: "Do you have evidence of this emergency?",
    tooltipEmergency4: "Yes or No",
    headingHomeEmergency: "I have a home emergency which is Robbery, Flood, Fire, Vandalism, Domestic Abuse that prevents me from attending the event?",
    headingHomeEmergencyDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelHomeEmergency1: "What is the home emergency?",
    tooltipHomeEmergency1: "Please select",
    labelHomeEmergencyOther1: "Please specify",
    tooltipHomeEmergencyOther1: "Please specify",
    labelHomeEmergency2: "When did this happen?",
    tooltipHomeEmergency2: "Please select",
    labelHomeEmergency3: "Why did it stop you attending the event?",
    tooltipHomeEmergency3: "Please detail",
    headingInjury: "I, a member of my household or a member of the group attending the event have been diagnosed with a medical condition or injury that now prevents us from going to the event?",
    headingInjuryDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelInjury1: "What is the nature of the Illness/Injury/Accident?",
    tooltipInjury1: "Please detail",
    labelInjury2: "When did this happen?",
    tooltipInjury2: "Please select",
    labelInjury3: "Have you attended a medical facility for this illness/injury?",
    tooltipInjury3: "Yes or No",
    labelInjury4: "Was this a pre-existing medical condition?",
    tooltipInjury4: "Yes or No",
    labelInjury5: "When was this first diagnosed?",
    tooltipInjury5: "Please select",
    labelInjury6: "Why does this mean you cannot attend the event?",
    tooltipInjury6: "Please detail",
    labelInjury7: "Who has suffered the illness/injury?",
    tooltipInjury7: "Please select",
    headingJuryService: "I or a member of the booking has been called to Jury Service?",
    headingJuryServiceDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelJuryService1: "When were you notified of Jury Service?",
    tooltipJuryService1: "Please select",
    headingMechanicalBreakdown: "Our vehicle has had a mechanical breakdown or been in an accident and this prevents us from attending the event?",
    headingMechanicalBreakdownDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelMechanicalBreakdown1: "What happened with the vehicle?",
    tooltipMechanicalBreakdown1: "Please select",
    labelMechanicalBreakdown2: "When did this happen?",
    tooltipMechanicalBreakdown2: "Please select",
    labelMechanicalBreakdown3: "Why were you unable to take alternative transportation?",
    tooltipMechanicalBreakdown3: "Please detail",
    headingPregnancyComplication: "I or a member of the booking has pregnancy complications that have been diagnosed by a medical professional and therefore prevent attendance at the event?",
    headingPregnancyComplicationDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelPregnancyComplication1: "What is the nature of the Pregnancy Complication?",
    tooltipPregnancyComplication1: "Please detail",
    labelPregnancyComplication2: "When did this happen?",
    tooltipPregnancyComplication2: "Please select",
    labelPregnancyComplication3: "Did you seek medical attention?",
    tooltipPregnancyComplication3: "Yes or No",
    labelPregnancyComplication4: "Why does this mean you cannot attend the event?",
    tooltipPregnancyComplication4: "Please detail",
    headingPublicTransportFailure: "The method of Public Transport (Train, Bus, Tram, Boat, etc) unexpectedly failed meaning there was No other way I could travel to this event?",
    headingPublicTransportFailureDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelPublicTransportFailure1: "What type of Public Transport did you plan to use?",
    tooltipPublicTransportFailure1: "Please select",
    labelPublicTransportFailure2: "When did you plan to travel?",
    tooltipPublicTransportFailure2: "Please select",
    labelPublicTransportFailure3: "What happened?",
    tooltipPublicTransportFailure3: "Please detail",
    labelPublicTransportFailure4: "Why could you not take alternative transport?",
    tooltipPublicTransportFailure4: "Please detail",
    headingScheduledAirlineFailure: "My pre-booked flight cancelled unexpectedly and I was unable to find a suitable alternative meaning I was unable to attend the event?",
    headingScheduledAirlineFailureDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelScheduledAirlineFailure1: "Name of Airline",
    tooltipScheduledAirlineFailure1: "Name of Airline",
    labelScheduledAirlineFailure2: "Flight number",
    tooltipScheduledAirlineFailure2: "Flight number",
    labelScheduledAirlineFailure3: "Travelling from",
    tooltipScheduledAirlineFailure3: "Travelling from",
    labelScheduledAirlineFailure4: "Travelling to",
    tooltipScheduledAirlineFailure4: "Travelling to",
    labelScheduledAirlineFailure5: "Date of incident?",
    tooltipScheduledAirlineFailure5: "Please select",
    labelScheduledAirlineFailure6: "Why were you unable to take an alternative flight?",
    tooltipScheduledAirlineFailure6: "Please detail",
    headingTheftOfDocuments: "My documents were stolen and it was not possible to arrange replacement(s) within the timeframe needed so I cannot attend the event",
    headingTheftOfDocumentsDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelTheftOfDocuments1: "What type of Document was stolen?",
    tooltipTheftOfDocuments1: "Please select",
    labelTheftOfDocuments2: "When did this happen?",
    tooltipTheftOfDocuments2: "Please select",
    labelTheftOfDocuments3: "Did you attempt to have the documents re-issued?",
    tooltipTheftOfDocuments3: "Yes or No",
    headingWorkRelocation: "My employer has requested me to change the location of my work for at least three months and the distance is significant meaning I am now unable to attend the event?",
    headingWorkRelocationDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelWorkRelocation1: "Is this a temporary or permanent relocation of your work?",
    tooltipWorkRelocation1: "Please select",
    labelWorkRelocation2: "How long will you be at the new address?",
    tooltipWorkRelocation2: "Please detail",
    labelWorkRelocation3: "What is your current work address?",
    tooltipWorkRelocation3: "Please detail",
    labelWorkRelocation4: "What is your new work address?",
    tooltipWorkRelocation4: "Please detail",
    labelWorkRelocation5: "When were you notified of this relocation?",
    tooltipWorkRelocation5: "Please select",
    headingRedundancy: "I or a member of the booking has been made redundant by their employer meaning I can no longer attend the booking?",
    headingRedundancyDecline: "You should not proceed with this application as the refund will most likely be declined.",
    labelRedundancy1: "Type of employment?",
    tooltipRedundancy1: "Please select",
    labelRedundancy2: "How long have you been employed by this company?",
    tooltipRedundancy2: "Please select",
    labelRedundancy3: "Was the redundancy voluntary?",
    tooltipRedundancy3: "Please select",
    labelRedundancy4: "When were you notified of your redundancy?",
    tooltipRedundancy4: "Please select",
    labelTestOption: "Select Yes to proceed",
}