export default {
    messageUnableToMakePaymentToCurrency: "Sayangnya kami tidak dapat mengambil butiran bank untuk mata wang yang dipilih. Pastikan Mata Wang dan Negara Asal dipilih",
    messageOnlyBankAccountsAccepted: "Harap maklum: Kami hanya dapat melakukan pembayaran ke Akaun Bank pada masa ini (Kad Kredit dan Debit tidak diterima)",
    labelAccountName: "Nama Pemegang Akaun",
    labelBankAccountName: "Nama Penuh Pemegang Akaun",
    labelBankCardHolderName: "Pemegang kad nama",
    validationBankAccountName: "Nama Penuh Pemegang Akaun diperlukan",
    labelBankCountry: "Negara Bank",
    labelBankName: "Nama bank",
    labelPaymentType: "Jenis pembayaran",
    labelInstitutionNumber: "Nombor Institusi",
    labelBankAddress: "Alamat bank",
    labelBankAddress2: "Alamat bank",
    labelBankCity: "Bank Bandar",
    labelBankState: "Bank Negara",
    labelBankAccountNumber: "Nombor akaun bank",
    labelBankAccountNumberCBU: "Nombor Akaun CBU",
    labelBankAccountNumberCCI: "Nombor Akaun CCI",
    labelBankAccountNumberZAR: "Nombor Akaun Bank (7-11 digit)",
    labelCardNumber: "Nombor kad",
    labelUnionpay: "Nombor Kad UnionPay",
    labelAlipay: "Alipay ID (nombor mudah alih atau e-mel)",
    labelBankAccountType: "Jenis Akaun",
    optionBankAccountTypeCURRENT: "Akaun terkini",
    optionBankAccountTypeCHECKING: "Memeriksa akaun",
    optionBankAccountTypeSAVING: "Akaun simpanan",
    optionBankAccountTypeSAVINGS: "Akaun simpanan",
    optionBankAccountTypeCUENTA_VISTA: "Akaun Induk",
    optionBankAccountType1: "Memeriksa akaun",
    optionBankAccountType2: "Akaun simpanan",
    optionBankAccountType3: "Akaun Induk",
    optionBankAccountType4: "Akaun Vista",
    optionBankAccountType5: "Akaun RUT",
    labelSortCode: "Kod jenis",
    labelRoutingCode: "Kod Laluan",
    labelBankRoutingCodeNationalID: "Kod Laluan Bank / ID Nasional",
    labelBankTransitCodeSwift: "Nombor Transit",
    labelCNAPSCode: "Kod Laluan / Kod CNAPS (Kod Sistem Pembayaran Lanjutan Nasional China)",
    labelABAFedwireRoutingCode: "Nombor Penghalaan ACH",
    labelIFSCCode: "Kod IFSC",
    labelBankCode: "Kod bank",
    labelBIKCode: "Kod BIK",
    labelBranchCode: "Kod Cawangan",
    labelBranchCodeUniversal: "Kod cawangan 6 digit universal",
    labelRemittanceLine3: "Nama Perhubungan Penerima dan Nombor Telefon",
    labelBeneficiaryContactNameandTelephoneNumber: "Nama Perhubungan Penerima dan Nombor Telefon",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Tarikh Lahir dan Tempat Lahir Waris",
    labelRemittanceLine4: "Nombor Cukai Individu CUIL (9-12 digit)",
    labelCUILNumber: "Id Cukai: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "ID cukai RFC 13 digit atau CURP 18 digit",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "ID Cukai Individu CPF Cadastro de Pessoa Física (11-14 digit), Kod Cawangan",
    labelCFPNumber: "Nombor CPF (11-14 digit)",
    labelBranchNameorBranchCode7digits: "Nama Cawangan atau Kod Cawangan (7 digit)",
    labelCedulaJuridica910digitsTaxID: "Perakuan Undang-Undang (ID Cukai 9-10 digit)",
    labelTaxIDRUT89digits: "RUT ID Cukai (8-9 digit)",
    labelJob: "Tajuk Pekerjaan",
    labelDateOfBirth: "Tarikh lahir",
    labelNationality: "Kewarganegaraan",
    labelDocumentType: "jenis dokumen",
    labelDocumentNumber: "nombor dokumen",
    labelRutNumber: "Nombor RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (Cukai Individu 8-11 digit)",
    labelCIIndividualTaxIDBranchLocation: "CI (ID Cukai Individu), Lokasi Cawangan",
    labelTaxIDRUCorCI: "ID Cukai (RUC atau CI)",
    labelSortCodeHelp: "Tolong?",
    labelBranchCodeHelp: "Tolong?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Tolong?",
    labelCLABE: "KELAB",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Tolong?",
    labelRoutingCodeBSB: "Kod Laluan / BSB",
    labelBSB: "Cawangan Negeri Bank (BSB)",
    labelSKNCode: "Kod SKN",
    labelAcceptanceFlights: "Saya/kami menerima bahawa jika permohonan bayaran balik berjaya, tempahan termasuk semua penerbangan, dan produk sampingan akan dibatalkan.",
    labelAcceptanceTickets: "Saya/kami menerima bahawa jika permohonan bayaran balik berjaya, tempahan termasuk semua tiket, dan produk sampingan akan dibatalkan.",
    labelTermsAndConditions1: "Saya / kami telah bersetuju untuk",
    labelTermsAndConditions2: "Terma dan syarat",
    labelFraudWarning1: "Saya percaya fakta yang diberikan dalam borang permohonan bayaran balik ini adalah tulen dan benar. Saya faham bahawa sebarang maklumat atau bukti palsu boleh dianggap sebagai tindakan jenayah dan diserahkan kepada polis atau agensi penguatkuasaan undang-undang lain untuk dikejar.",
    labelFraudWarning2: "Jika kami mengesyaki penipuan berhubung dengan dokumentasi sokongan, perkara ini akan segera dirujuk kepada pihak berkuasa polis tempatan. Saya menerima dan memahami butiran saya akan diserahkan kepada pihak berkuasa ini sekiranya permohonan saya dianggap sebagai penipuan dan itu boleh menjadi penyiasatan jenayah.",
    validationTermsAndConditions: "Terma dan Syarat mesti dipersetujui",
    messageFailbankName: "Nama Bank tidak boleh kosong",
    messageFailcustomerName: "Nama Pelanggan tidak boleh kosong",
    messageFailbankAccountNumber: "Medan yang Diperlukan",
    messageFailbankAccountNumberFormat: "Mesti berformat IBAN",
    messageFailsortCode: "Medan yang Diperlukan",
    messageFailbankSwiftBic: "SWIFT BIC penerima tidak boleh kosong",
    messageFailbankSwiftBicFormat: "Mesti format SWIFT",
    messageFailbeneficiaryAddress1: "Alamat 1 tidak boleh kosong",
    messageFailbeneficiaryCity: "Bandar tidak boleh kosong",
    messageFailbeneficiaryPostCode: "Poskod tidak boleh kosong",
    messageFailremittanceLine3: "Medan yang Diperlukan",
    messageFailremittanceLine4: "Medan yang Diperlukan",
    modalHeading: "Sahkan Butiran",
    modalLabelPleaseConfirmYourDetails: "Sila sahkan maklumat anda",
    modalValidationPleaseConfirm: "Tolong sahkan",
    modalValidationMustMatch: "Mesti sepadan dengan entri sebelumnya",
    tooltipBankAccountName: "Nama anda seperti tertera di akaun bank / penyata bank / kad bank anda.",
    tooltipBankAccountNameRecipient: "Nama depan dan nama belakang penerima. Nama depan dan belakang mesti mempunyai lebih daripada satu watak.",
    tooltipBankCountry: "Negara tempat bank anda berada.",
    tooltipBankName: "Nama bank anda.",
    tooltipPaymentType: "Jenis pembayaran yang anda perlukan.",
    tooltipInstitutionNumber: "Ini adalah 3 digit dan biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAccountNumber: "Nombor akaun individu anda. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAccountNumberCBU: "Nombor 22 digit yang digunakan untuk mengenal pasti akaun anda. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAccountNumberCCI: "Nombor 20 digit yang digunakan untuk mengenal pasti akaun anda. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAccountNumberKRW: "Nombor 11–16 digit yang digunakan untuk mengenal pasti akaun anda. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCardNumber: "Nombor kad anda. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipUnionpay: "Nombor kad UnionPay anda.",
    tooltipAlipay: "Nombor telefon bimbit atau alamat e-mel anda yang berkaitan dengan akaun Alipay anda.",
    tooltipBankAccountType: "Ini sama ada Memeriksa atau Menabung.",
    tooltipSortCode: "Ini mengenal pasti cawangan bank anda di mana akaun anda disimpan. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipRoutingCode: "Ini mengenal pasti cawangan bank anda di mana akaun anda disimpan. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBeneficiarySWIFTBIC: "Terdiri daripada 8-11 aksara yang digunakan untuk mengenal pasti bank dalam transaksi antarabangsa. Biasanya terdapat pada penyata bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBSB: "Nombor Bank State Branch (BSB) adalah kod jenis Australia yang mempunyai 6 digit. Biasanya terdapat pada penyata bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipRoutingCodeBSB: "Ini mengenal pasti cawangan bank anda di mana akaun anda disimpan. Biasanya terdapat pada penyata bulanan atau kad bank anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipABAFedwireRoutingCode: "Nombor 9 digit digunakan untuk mengenal pasti bank di Amerika. Biasanya terdapat pada penyata bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipIFSCCode: "Biasanya kod ini boleh didapati di penyata bulanan atau di buku laluan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipTermsAndConditions: "Dengan menerima terma dan syarat anda mengesahkan bahawa anda telah membaca dan bersetuju dengan Terma dan Syarat kami.",
    tooltipIBAN: "Nombor Akaun Bank Antarabangsa anda, yang boleh anda gunakan semasa membuat atau menerima pembayaran antarabangsa. Biasanya terdapat pada penyata bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nama Perhubungan Penerima dan Nombor Telefon.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Tarikh Lahir dan Tempat Lahir Waris.",
    tooltipBankRoutingCodeNationalID: "biasanya terdapat pada penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankTransitCodeSwift: "Ini adalah 5 digit dan biasanya terdapat pada penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAddress: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankAddress2: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankCity: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankState: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBranchName: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBankCode: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBIKCode: "9 digit untuk mengenal pasti bank anda.",
    tooltipBranchCode: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBranchCodeUniversal: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCNAPSCode: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipRemittanceLine4: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCUILNumber: "Ini adalah nombor 11 digit, yang terdiri daripada dua digit, tanda hubung, sembilan digit, dan satu digit checksum.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCFPNumber: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipBranchNameorBranchCode7digits: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCedulaJuridica910digitsTaxID: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipTaxIDRUT89digits: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipJob: "Pekerjaan anda sekarang.",
    tooltipDateOfBirth: "Tarikh lahir anda.",
    tooltipNationality: "Kewarganegaraan anda.",
    tooltipDocumentType: "Jenis dokumen diperlukan.",
    tooltipDocumentNumber: "Ini adalah nombor dokumen yang berkaitan dengan pemilihan jenis dokumen anda.",
    tooltipRutNumber: "Nombor RUT anda (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipCIIndividualTaxIDBranchLocation: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    tooltipTaxIDRUCorCI: "Biasanya boleh didapati di penyata bank bulanan anda atau jika anda masih tidak pasti, tanyakan kepada bank anda.",
    errorCPFCode: "Pada masa ini kami hanya menerima CPF sebagai kunci PIX."
};