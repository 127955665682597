import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../form/Interfaces/IFormValues';
import {LabelType} from '../form/enums/labelType';
import {useTranslation} from 'react-i18next';
import {useLabels} from './usePaymentInformation';

export default (currency: string) => {

    const {t} = useTranslation();

    const labelBankAccountName =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountName), LabelType.Text)
        ?? t('payment:labelBankAccountName');

    const tooltipBankAccountName =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountName), LabelType.TooltipText)
        ?? t('payment:tooltipBankAccountName');
    
    const labelBankAccountNumber =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountNumber), LabelType.Text)
        ?? t('payment:labelBankAccountNumber');

    const tooltipBankAccountNumber =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountNumber), LabelType.TooltipText)
        ?? t('payment:tooltipBankAccountNumber');

    const labelCardNumber =
        useLabels(currency, nameof<IFormValues>(x => x.cardNumber), LabelType.Text)
        ?? t('payment:labelCardNumber');

    const tooltipCardNumber =
        useLabels(currency, nameof<IFormValues>(x => x.cardNumber), LabelType.TooltipText)
        ?? t('payment:tooltipCardNumber');

    const labelSortCode =
        useLabels(currency, nameof<IFormValues>(x => x.sortCode), LabelType.Text)
        ?? t('payment:labelSortCode');

    const tooltipSortCode =
        useLabels(currency, nameof<IFormValues>(x => x.sortCode), LabelType.TooltipText)
        ?? t('payment:tooltipSortCode');

    const labelBankName =
        useLabels(currency, nameof<IFormValues>(x => x.bankName), LabelType.Text)
        ?? t('payment:labelBankName');

    const tooltipBankName =
        useLabels(currency, nameof<IFormValues>(x => x.bankName), LabelType.TooltipText)
        ?? t('payment:tooltipBankName');

    const labelBranchCode =
        useLabels(currency, nameof<IFormValues>(x => x.branchCode), LabelType.Text)
        ?? t('payment:labelBranchCode');

    const tooltipBranchCode =
        useLabels(currency, nameof<IFormValues>(x => x.branchCode), LabelType.TooltipText)
        ?? t('payment:tooltipBranchCode');

    const labelBankAccountType =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountType), LabelType.Text)
        ?? t('payment:labelBankAccountType');

    const tooltipBankAccountType =
        useLabels(currency, nameof<IFormValues>(x => x.bankAccountType), LabelType.TooltipText)
        ?? t('payment:tooltipBankAccountType');

    const labelPaymentType =
        useLabels(currency, nameof<IFormValues>(x => x.paymentType), LabelType.Text)
        ?? t('payment:labelPaymentType');

    const tooltipPaymentType =
        useLabels(currency, nameof<IFormValues>(x => x.paymentType), LabelType.TooltipText)
        ?? t('payment:tooltipPaymentType');

    const labelDocumentType =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine3Select), LabelType.Text)
        ?? t('payment:labelDocumentType');

    const tooltipDocumentType =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine3Select), LabelType.TooltipText)
        ?? t('payment:tooltipDocumentType');

    const labelDocumentNumber =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine3), LabelType.Text)
        ?? t('payment:labelDocumentNumber');

    const tooltipDocumentNumber =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine3), LabelType.TooltipText)
        ?? t('payment:tooltipDocumentNumber');

    const labelRemittanceLine4 =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine4), LabelType.Text)
        ?? t('payment:labelRemittanceLine4');

    const tooltipRemittanceLine4 =
        useLabels(currency, nameof<IFormValues>(x => x.remittanceLine4), LabelType.TooltipText)
        ?? t('payment:tooltipRemittanceLine4');

    const labelDateOfBirth =
        useLabels(currency, nameof<IFormValues>(x => x.dateOfBirth), LabelType.Text)
        ?? t('payment:labelDateOfBirth');

    const tooltipDateOfBirth =
        useLabels(currency, nameof<IFormValues>(x => x.dateOfBirth), LabelType.TooltipText)
        ?? t('payment:tooltipDateOfBirth');

    const labelBeneficiarySWIFTBIC =
        useLabels(currency, nameof<IFormValues>(x => x.bankSwiftBic), LabelType.Text)
        ?? t('payment:labelBeneficiarySWIFTBIC');

    const tooltipBeneficiarySWIFTBIC =
        useLabels(currency, nameof<IFormValues>(x => x.bankSwiftBic), LabelType.TooltipText)
        ?? t('payment:tooltipBeneficiarySWIFTBIC');

    return {
        labelBankAccountName,
        tooltipBankAccountName,
        labelBankAccountNumber,
        tooltipBankAccountNumber,
        labelCardNumber,
        tooltipCardNumber,
        labelSortCode,
        tooltipSortCode,
        labelBankName,
        tooltipBankName,
        labelBranchCode,
        tooltipBranchCode,
        labelBankAccountType,
        tooltipBankAccountType,
        labelPaymentType,
        tooltipPaymentType,
        labelDocumentType,
        tooltipDocumentType,
        labelDocumentNumber,
        tooltipDocumentNumber,
        labelRemittanceLine4,
        tooltipRemittanceLine4,
        labelDateOfBirth,
        tooltipDateOfBirth,
        labelBeneficiarySWIFTBIC,
        tooltipBeneficiarySWIFTBIC
    }
}