export default {
    messageQuestionService: "Este servicio es solo para usar si tiene problemas para completar el formulario de solicitud, no podemos responder a ninguna otra pregunta aquí.",
    labelName: "Nombre",
    labelReference: "Referencia de reserva",
    labelPhone: "Número de teléfono",
    labelEmail: "Correo electrónico",
    labelQuery: "Tu pregunta",
    labelAskQuestion: "Envíanos una pregunta aquí ...",
    buttonOpenModal: "Pregunta",
    title: "Hacer una pregunta",
    modalTitle: "4. ¿Tiene algún problema técnico con el formulario?",
    toastSuccess: "Hemos recibido tu pregunta y nos comunicaremos contigo en un plazo de 24 horas.",
    toastError: "Algo salió mal con su solicitud. Vuelva a intentarlo.",
    nameValidation: "Se requiere el nombre",
    referenceValidation: "Se requiere referencia",
    questionValidation: "Tu pregunta es requerida"
}