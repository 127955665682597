import * as React from 'react';
import {
    Box,
    FormLabel,
    IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {InfoOutlined} from '@mui/icons-material';
import {Asterisk} from './Asterisk';
import {FormLabelTooltip} from './FormLabelTooltip';

interface FormLabelHolderProps {
    label: string;
    required: boolean;
    tooltip: string
}

export const FormLabelHolder = (props: FormLabelHolderProps) => {

    const {
        label,
        required,
        tooltip
    } = props;

    return (<>
        <Grid size={{xs: 6, md: 4}}>
            <Box sx={{pr: 2}} justifyContent='flex-end' display={{sm: 'flex', md: 'flex'}}>
                <FormLabel sx={{
                    color: 'black',
                    paddingBottom: {
                      xs: '8px',
                      sm: 0,
                      md: 0
                    },
                    textAlign: {
                        xs: 'left',
                        sm: 'right',
                        md: 'right'
                    }
                }}>
                    {label}
                    <Asterisk required={required}/>
                    {!!tooltip &&
                        <FormLabelTooltip title={tooltip}>
                            <IconButton sx={{p: 0}}>
                                <InfoOutlined sx={{fontSize: 14}}/>
                            </IconButton>
                        </FormLabelTooltip>
                    }
                </FormLabel>
            </Box>
        </Grid>
    </>);
}