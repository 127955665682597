export default {
    headingAdverseWeather: "Doświadczasz poważnych niekorzystnych warunków pogodowych, które sprawiają, że podróż na wydarzenie jest niebezpieczna, co zostało nakazane przez organ rządowy (np. Władze lokalne, policja itp.). I masz na to dowody?",
    headingAdverseWeatherDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    tooltipRefundReasonConfirm: "Tak lub nie",
    labelBookingCancelledByOperator: "Bilety zostały anulowane przez Operatora/Organizatora.",
    labelBookingRescheduledByOperator: "Termin został zmieniony przez Operatora/Organizatora i nie mogę uczestniczyć w nowych terminach.",
    labelBookedWrongDate: "Ja lub członek rezerwacji popełniliśmy błąd przy zakupie biletów i zarezerwowaliśmy niewłaściwy termin.",
    labelBookedWrongTickets: "Ja lub członek rezerwacji popełniliśmy błąd przy zakupie biletów i zarezerwowaliśmy niewłaściwe bilety.",
    labelBookedTooManyTickets: "Ja lub członek rezerwacji popełniliśmy błąd przy zakupie biletów i zarezerwowaliśmy zbyt wiele biletów.",
    labelCOVID19TravelRestrictions: "Ja lub członek rezerwacji nie możemy uczestniczyć z powodu zakazu podróżowania COVID-19.",
    labelADWrongSelection: "Być może wybrałeś niewłaściwy powód zwrotu pieniędzy. Przejrzyj ponownie listę rozwijaną Powód zwrotu pieniędzy.",
    labelAdverseWeather1: "Dlaczego niekorzystna pogoda sprawiła, że nie mogłeś wziąć udziału w wydarzeniu?",
    tooltipAdverseWeather1: "Proszę o szczegóły",
    labelAdverseWeather2: "Data zdarzenia",
    tooltipAdverseWeather2: "Proszę wybrać",
    headingArmedForces: "Zostajesz poproszony przez swojego Pracodawcę, który jest Siłami Zbrojnymi lub Służbami Ratunkowymi, o przyjście do pracy, gdy nie byłeś pierwotnie zaplanowany, a to uniemożliwia Ci udział w wydarzeniu?",
    headingArmedForcesDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelArmedForces1: "W jakich siłach lub służbach ratunkowych jesteś zatrudniony?",
    tooltipArmedForces1: "Proszę wybrać",
    labelArmedForcesOther1: "Proszę określić",
    tooltipArmedForcesOther1: "Proszę określić",
    labelArmedForces2: "Data zdarzenia",
    tooltipArmedForces2: "Proszę wybrać",
    headingChangesToExam: "Daty egzaminu zostały zmienione przez szkołę/uczelnię itp.? To nie jest nieudany egzamin, który musisz powtórzyć?",
    headingChangesToExamDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelExam1: "Jaka była pierwotna data egzaminu?",
    tooltipExam1: "Proszę wybrać",
    labelExam2: "Jaki jest nowy termin egzaminu?",
    tooltipExam2: "Proszę wybrać",
    labelExam3: "Dlaczego data egzaminu uległa zmianie?",
    tooltipExam3: "Podaj szczegóły",
    labelExam4: "Czy nie zdałeś poprzedniego egzaminu?",
    tooltipExam4: "Tak lub nie",
    headingCourtSummons: "Zostałeś wezwany do stawienia się w sądzie w charakterze świadka w tym samym dniu co zdarzenie?",
    headingCourtSummonsDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelCourtSummons1: "Jaki jest powód wezwania do sądu?",
    tooltipCourtSummons1: "Proszę wybrać",
    labelCourtSummonsOther1: "Proszę określić",
    tooltipCourtSummonsOther1: "Proszę określić",
    labelCourtSummons2: "Kiedy wydano wezwanie do sądu?",
    tooltipCourtSummons2: "Proszę wybrać",
    headingCOVID19Test: "U mnie, członka mojego gospodarstwa domowego lub członka grupy uczestniczącej w wydarzeniu zdiagnozowano COVID-19?",
    headingCOVID19TestDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelCOVID19Test1: "Kto ma COVID-19?",
    tooltipCOVID19Test1: "Proszę wybrać",
    labelCOVID19TestOther1a: "Proszę określić",
    labelCOVID19TestOther1b: "Podaj nazwę",
    tooltipCOVID19TestOther1a: "Proszę określić",
    tooltipCOVID19TestOther1b: "Podaj nazwę",
    labelCOVID19Test2: "Jaki rodzaj testu na COVID-19 miałeś?",
    tooltipCOVID19Test2: "Proszę wybrać",
    labelCOVID19TestOther2: "Proszę określić",
    tooltipCOVID19TestOther2: "Proszę określić",
    labelCOVID19Test3: "Jaka była data twojego testu na COVID-19?",
    tooltipCOVID19Test3: "Proszę wybrać",
    headingDeath: "Nastąpiła śmierć najbliższego członka mojej rodziny i uniemożliwia mi to udział w wydarzeniu?",
    headingDeathDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelDeath1: "Który członek twojej rodziny zmarł?",
    tooltipDeath1: "Proszę wybrać",
    labelDeathOther1: "Proszę określić",
    tooltipDeathOther1: "Proszę określić",
    labelDeath2: "Kiedy była data śmierci?",
    tooltipDeath2: "Proszę wybrać",
    labelDeath3: "Imię zmarłego",
    tooltipDeath3: "Proszę określić",
    headingEmergency: "Miałem prawdziwą sytuację awaryjną, która jest poza moją kontrolą i nieprzewidziana, na którą mogę dostarczyć dowody?",
    headingEmergencyDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelEmergency1: "Proszę wyjaśnić rodzaj sytuacji awaryjnej?",
    tooltipEmergency1: "Proszę o szczegóły",
    labelEmergency2: "Kiedy to się stało?",
    tooltipEmergency2: "Proszę wybrać",
    labelEmergency3: "Dlaczego ta sytuacja uniemożliwia ci udział w wydarzeniu?",
    tooltipEmergency3: "Proszę o szczegóły",
    labelEmergency4: "Czy masz dowody na tę sytuację awaryjną?",
    tooltipEmergency4: "Tak lub nie",
    headingHomeEmergency: "Mam nagły wypadek domowy, który jest rabunkiem, powodzią, pożarem, wandalizmem, przemocą domową, która uniemożliwia mi udział w wydarzeniu?",
    headingHomeEmergencyDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelHomeEmergency1: "Co to jest nagły wypadek w domu?",
    tooltipHomeEmergency1: "Proszę wybrać",
    labelHomeEmergencyOther1: "Proszę określić",
    tooltipHomeEmergencyOther1: "Proszę określić",
    labelHomeEmergency2: "Kiedy to się stało?",
    tooltipHomeEmergency2: "Proszę wybrać",
    labelHomeEmergency3: "Dlaczego to powstrzymało Cię przed uczestnictwem w tym wydarzeniu?",
    tooltipHomeEmergency3: "Proszę o szczegóły",
    headingInjury: "U mnie, członka mojego gospodarstwa domowego lub członka grupy uczestniczącej w wydarzeniu zdiagnozowano stan zdrowia lub uraz, który teraz uniemożliwia nam pójście na wydarzenie?",
    headingInjuryDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelInjury1: "Jaka jest natura choroby/urazu/wypadku?",
    tooltipInjury1: "Proszę o szczegóły",
    labelInjury2: "Kiedy to się stało?",
    tooltipInjury2: "Proszę wybrać",
    labelInjury3: "Czy uczęszczałeś do placówki medycznej z powodu tej choroby/urazu?",
    tooltipInjury3: "Tak lub nie",
    labelInjury4: "Czy był to wcześniej istniejący stan chorobowy?",
    tooltipInjury4: "Tak lub nie",
    labelInjury5: "Kiedy po raz pierwszy zdiagnozowano tę chorobę?",
    tooltipInjury5: "Proszę wybrać",
    labelInjury6: "Dlaczego oznacza to, że nie możesz wziąć udziału w wydarzeniu?",
    tooltipInjury6: "Proszę o szczegóły",
    labelInjury7: "Kto doznał choroby/urazu?",
    tooltipInjury7: "Proszę wybrać",
    headingJuryService: "Ja lub członek rezerwacji został wezwany do Jury Service?",
    headingJuryServiceDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelJuryService1: "Kiedy zostałeś powiadomiony o Służbie Jury?",
    tooltipJuryService1: "Proszę wybrać",
    headingMechanicalBreakdown: "Nasz pojazd uległ awarii mechanicznej lub uległ wypadkowi, co uniemożliwia nam udział w wydarzeniu?",
    headingMechanicalBreakdownDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelMechanicalBreakdown1: "Co się stało z pojazdem?",
    tooltipMechanicalBreakdown1: "Proszę wybrać",
    labelMechanicalBreakdown2: "Kiedy to się stało?",
    tooltipMechanicalBreakdown2: "Proszę wybrać",
    labelMechanicalBreakdown3: "Dlaczego nie mogłeś skorzystać z alternatywnego transportu?",
    tooltipMechanicalBreakdown3: "Proszę o szczegóły",
    headingPregnancyComplication: "Ja lub członek rezerwacji ma powikłania ciąży, które zostały zdiagnozowane przez lekarza i dlatego uniemożliwiają udział w wydarzeniu?",
    headingPregnancyComplicationDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelPregnancyComplication1: "Jaka jest natura powikłania ciąży?",
    tooltipPregnancyComplication1: "Proszę o szczegóły",
    labelPregnancyComplication2: "Kiedy to się stało?",
    tooltipPregnancyComplication2: "Proszę wybrać",
    labelPregnancyComplication3: "Czy szukałeś pomocy medycznej?",
    tooltipPregnancyComplication3: "Tak lub nie",
    labelPregnancyComplication4: "Dlaczego oznacza to, że nie możesz wziąć udziału w wydarzeniu?",
    tooltipPregnancyComplication4: "Proszę o szczegóły",
    headingPublicTransportFailure: "Metoda transportu publicznego (pociąg, autobus, tramwaj, łódź itp.) niespodziewanie zawiodła, co oznacza, że nie było innego sposobu, w jaki mógłbym podróżować na to wydarzenie?",
    headingPublicTransportFailureDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelPublicTransportFailure1: "Z jakiego rodzaju transportu publicznego planowałeś skorzystać?",
    tooltipPublicTransportFailure1: "Proszę wybrać",
    labelPublicTransportFailure2: "Kiedy planowałeś podróż?",
    tooltipPublicTransportFailure2: "Proszę wybrać",
    labelPublicTransportFailure3: "Co się stało?",
    tooltipPublicTransportFailure3: "Proszę o szczegóły",
    labelPublicTransportFailure4: "Dlaczego nie można skorzystać z alternatywnego transportu?",
    tooltipPublicTransportFailure4: "Proszę o szczegóły",
    headingScheduledAirlineFailure: "Mój wcześniej zarezerwowany lot został niespodziewanie odwołany i nie mogłem znaleźć odpowiedniej alternatywy, co oznacza, że nie mogłem wziąć udziału w wydarzeniu?",
    headingScheduledAirlineFailureDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelScheduledAirlineFailure1: "Nazwa linii lotniczej",
    tooltipScheduledAirlineFailure1: "Nazwa linii lotniczej",
    labelScheduledAirlineFailure2: "Numer lotu",
    tooltipScheduledAirlineFailure2: "Numer lotu",
    labelScheduledAirlineFailure3: "Podróż z",
    tooltipScheduledAirlineFailure3: "Podróż z",
    labelScheduledAirlineFailure4: "Podróż do",
    tooltipScheduledAirlineFailure4: "Podróż do",
    labelScheduledAirlineFailure5: "Data zdarzenia?",
    tooltipScheduledAirlineFailure5: "Proszę wybrać",
    labelScheduledAirlineFailure6: "Dlaczego nie mogłeś skorzystać z alternatywnego lotu?",
    tooltipScheduledAirlineFailure6: "Proszę o szczegóły",
    headingTheftOfDocuments: "Moje dokumenty zostały skradzione i nie było możliwe zorganizowanie wymiany w wymaganym czasie, więc nie mogę uczestniczyć w wydarzeniu",
    headingTheftOfDocumentsDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelTheftOfDocuments1: "Jaki rodzaj dokumentu został skradziony?",
    tooltipTheftOfDocuments1: "Proszę wybrać",
    labelTheftOfDocuments2: "Kiedy to się stało?",
    tooltipTheftOfDocuments2: "Proszę wybrać",
    labelTheftOfDocuments3: "Czy próbowałeś ponownie wydać dokumenty?",
    tooltipTheftOfDocuments3: "Tak lub nie",
    headingWorkRelocation: "Mój pracodawca poprosił mnie o zmianę miejsca pracy na co najmniej trzy miesiące, a odległość jest znacząca, co oznacza, że nie mogę teraz uczestniczyć w wydarzeniu?",
    headingWorkRelocationDecline: "Nie należy kontynuować tej aplikacji, ponieważ zwrot najprawdopodobniej zostanie odrzucony.",
    labelWorkRelocation1: "Czy jest to tymczasowa czy stała relokacja Twojej pracy?",
    tooltipWorkRelocation1: "Proszę wybrać",
    labelWorkRelocation2: "Jak długo będziesz pod nowym adresem?",
    tooltipWorkRelocation2: "Proszę o szczegóły",
    labelWorkRelocation3: "Jaki jest Twój obecny adres służbowy?",
    tooltipWorkRelocation3: "Proszę o szczegóły",
    labelWorkRelocation4: "Jaki jest Twój nowy adres służbowy?",
    tooltipWorkRelocation4: "Proszę o szczegóły",
    labelWorkRelocation5: "Kiedy zostaliŚcie powiadomieni o tej relokacji?",
    tooltipWorkRelocation5: "Proszę wybrać",
    headingRedundancy: "Ja lub członek rezerwacji zostaliśmy zwolnieni przez swojego pracodawcę, co oznacza, że nie mogę już uczestniczyć w rezerwacji?",
    headingRedundancyDecline: "Nie należy kontynuować tego wniosku, ponieważ zwrot zostanie najprawdopodobniej odrzucony.",
    labelRedundancy1: "Rodzaj zatrudnienia?",
    tooltipRedundancy1: "Proszę wybrać",
    labelRedundancy2: "Jak długo jesteś zatrudniony w tej firmie?",
    tooltipRedundancy2: "Proszę wybrać",
    labelRedundancy3: "Czy zwolnienie było dobrowolne?",
    tooltipRedundancy3: "Proszę wybrać",
    labelRedundancy4: "Kiedy zostałeś powiadomiony o zwolnieniu?",
    tooltipRedundancy4: "Proszę wybrać",
    labelTestOption: "Wybierz opcję Tak, aby kontynuować",
}