export default {
    headingAdverseWeather: "Jūs susiduriate su sunkiomis nepalankiomis oro sąlygomis, dėl kurių nesaugu keliauti į renginį, ir tai įpareigojo vyriausybės įstaiga (pvz., Vietos valdžia, policija ir kt.). Ir jūs turite tai patvirtinančių įrodymų?",
    headingAdverseWeatherDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    tooltipRefundReasonConfirm: "Taip ar ne",
    labelBookingCancelledByOperator: "Bilietus atšaukė Operatorius/Organizatorius.",
    labelBookingRescheduledByOperator: "Operatorius / organizatorius pakeitė datą ir aš negaliu dalyvauti naujose datose.",
    labelBookedWrongDate: "Aš arba užsakymo narys padariau klaidą pirkdamas bilietus ir užsisakiau neteisingą datą.",
    labelBookedWrongTickets: "Aš arba užsakymo narys padariau klaidą pirkdamas bilietus ir užsisakiau neteisingus bilietus.",
    labelBookedTooManyTickets: "Aš arba užsakymo narys padariau klaidą pirkdamas bilietus ir užsisakiau per daug bilietų.",
    labelCOVID19TravelRestrictions: "Aš arba užsakymo narys negaliu dalyvauti dėl COVID-19 draudimo keliauti.",
    labelADWrongSelection: "Galbūt pasirinkote netinkamą lėšų grąžinimo priežastį. Dar kartą peržiūrėkite išskleidžiamąjį sąrašą Grąžinimo priežastis.",
    labelAdverseWeather1: "Kodėl dėl nepalankių oro sąlygų negalėjote dalyvauti renginyje?",
    tooltipAdverseWeather1: "Prašome detalizuoti",
    labelAdverseWeather2: "Incidento data",
    tooltipAdverseWeather2: "Pasirinkite",
    headingArmedForces: "Jūsų darbdavys, kuris yra ginkluotosios pajėgos ar avarinės tarnybos, jūsų prašo atvykti į darbą, kai iš pradžių nebuvote suplanavęs to padaryti, ir dėl to negalite dalyvauti renginyje?",
    headingArmedForcesDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelArmedForces1: "Kokiose pajėgose ar Avarinėje tarnyboje dirbate?",
    tooltipArmedForces1: "Pasirinkite",
    labelArmedForcesOther1: "Nurodykite",
    tooltipArmedForcesOther1: "Nurodykite",
    labelArmedForces2: "Incidento data",
    tooltipArmedForces2: "Pasirinkite",
    headingChangesToExam: "Jūsų egzamino datas pakeitė jūsų mokykla / kolegija ir pan.? Tai nėra neišlaikytas egzaminas, kurį privalote laikyti iš naujo?",
    headingChangesToExamDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelExam1: "Kokia buvo pirminė egzamino data?",
    tooltipExam1: "Pasirinkite",
    labelExam2: "Kokia yra nauja egzamino data?",
    tooltipExam2: "Pasirinkite",
    labelExam3: "Kodėl pasikeitė egzamino data?",
    tooltipExam3: "Įveskite duomenis",
    labelExam4: "Ar neišlaikėte ankstesnio egzamino?",
    tooltipExam4: "Taip ar ne",
    headingCourtSummons: "Jums buvo įteiktas šaukimas atvykti į teismą kaip liudytojui tomis pačiomis datomis kaip ir įvykio metu?",
    headingCourtSummonsDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelCourtSummons1: "Kokia yra jūsų šaukimo į teismą priežastis?",
    tooltipCourtSummons1: "Pasirinkite",
    labelCourtSummonsOther1: "Nurodykite",
    tooltipCourtSummonsOther1: "Nurodykite",
    labelCourtSummons2: "Kada buvo išduotas teismo šaukimas?",
    tooltipCourtSummons2: "Pasirinkite",
    headingCOVID19Test: "Man, mano namų ūkio nariui ar renginyje dalyvaujančios grupės nariui diagnozuotas COVID-19?",
    headingCOVID19TestDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelCOVID19Test1: "Kas serga COVID-19?",
    tooltipCOVID19Test1: "Pasirinkite",
    labelCOVID19TestOther1a: "Nurodykite",
    labelCOVID19TestOther1b: "Nurodykite pavadinimą",
    tooltipCOVID19TestOther1a: "Nurodykite",
    tooltipCOVID19TestOther1b: "Nurodykite pavadinimą",
    labelCOVID19Test2: "Kokį COVID-19 testą turėjote?",
    tooltipCOVID19Test2: "Pasirinkite",
    labelCOVID19TestOther2: "Nurodykite",
    tooltipCOVID19TestOther2: "Nurodykite",
    labelCOVID19Test3: "Kokia buvo jūsų COVID-19 testo data?",
    tooltipCOVID19Test3: "Pasirinkite",
    headingDeath: "Mirė artimas mano šeimos narys ir tai neleidžia man dalyvauti renginyje?",
    headingDeathDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelDeath1: "Kuris jūsų šeimos narys mirė?",
    tooltipDeath1: "Pasirinkite",
    labelDeathOther1: "Nurodykite",
    tooltipDeathOther1: "Nurodykite",
    labelDeath2: "Kada buvo mirties data?",
    tooltipDeath2: "Pasirinkite",
    labelDeath3: "Mirusiojo vardas ir pavardė",
    tooltipDeath3: "Nurodykite",
    headingEmergency: "Patyriau tikrą ekstremalią situaciją, kurios aš negaliu kontroliuoti ir kuri yra nenumatyta, dėl kurios galiu pateikti įrodymų?",
    headingEmergencyDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelEmergency1: "Prašome paaiškinti, kokios rūšies ekstremali situacija?",
    tooltipEmergency1: "Prašome detalizuoti",
    labelEmergency2: "Kada tai įvyko?",
    tooltipEmergency2: "Pasirinkite",
    labelEmergency3: "Kodėl ši situacija trukdo jums dalyvauti renginyje?",
    tooltipEmergency3: "Prašome detalizuoti",
    labelEmergency4: "Ar turite šios ekstremalios situacijos įrodymų?",
    tooltipEmergency4: "Taip ar ne",
    headingHomeEmergency: "Turiu namų avarinę situaciją, kuri yra apiplėšimas, potvynis, gaisras, vandalizmas, smurtas šeimoje, dėl kurios negaliu dalyvauti renginyje?",
    headingHomeEmergencyDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelHomeEmergency1: "Kas yra avarinė situacija namuose?",
    tooltipHomeEmergency1: "Pasirinkite",
    labelHomeEmergencyOther1: "Nurodykite",
    tooltipHomeEmergencyOther1: "Nurodykite",
    labelHomeEmergency2: "Kada tai įvyko?",
    tooltipHomeEmergency2: "Pasirinkite",
    labelHomeEmergency3: "Kodėl tai sutrukdė jums dalyvauti renginyje?",
    tooltipHomeEmergency3: "Prašome detalizuoti",
    headingInjury: "Man, mano namų ūkio nariui ar renginyje dalyvaujančiam grupės nariui buvo diagnozuota sveikatos būklė ar trauma, dėl kurios dabar negalime vykti į renginį?",
    headingInjuryDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelInjury1: "Koks yra ligos, sužalojimo ar nelaimingo atsitikimo pobūdis?",
    tooltipInjury1: "Prašome detalizuoti",
    labelInjury2: "Kada tai įvyko?",
    tooltipInjury2: "Pasirinkite",
    labelInjury3: "Ar lankėtės gydymo įstaigoje dėl šios ligos/traumos?",
    tooltipInjury3: "Taip ar ne",
    labelInjury4: "Ar tai buvo anksčiau buvusi sveikatos būklė?",
    tooltipInjury4: "Taip ar ne",
    labelInjury5: "Kada tai buvo pirmą kartą diagnozuota?",
    tooltipInjury5: "Pasirinkite",
    labelInjury6: "Kodėl tai reiškia, kad negalite dalyvauti renginyje?",
    tooltipInjury6: "Prašome detalizuoti",
    labelInjury7: "Kas patyrė ligą / traumą?",
    tooltipInjury7: "Pasirinkite",
    headingJuryService: "Aš ar užsakymo narys buvau iškviestas į Prisiekusiųjų tarnybą?",
    headingJuryServiceDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelJuryService1: "Kada jums buvo pranešta apie prisiekusiųjų tarnybą?",
    tooltipJuryService1: "Pasirinkite",
    headingMechanicalBreakdown: "Mūsų transporto priemonė patyrė mechaninį gedimą ar pateko į avariją ir tai neleidžia mums dalyvauti renginyje?",
    headingMechanicalBreakdownDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelMechanicalBreakdown1: "Kas nutiko su transporto priemone?",
    tooltipMechanicalBreakdown1: "Pasirinkite",
    labelMechanicalBreakdown2: "Kada tai įvyko?",
    tooltipMechanicalBreakdown2: "Pasirinkite",
    labelMechanicalBreakdown3: "Kodėl negalėjote pasirinkti alternatyvaus transporto?",
    tooltipMechanicalBreakdown3: "Prašome detalizuoti",
    headingPregnancyComplication: "Aš ar užsakymo narys turi nėštumo komplikacijų, kurias diagnozavo medicinos specialistas ir todėl neleidžia dalyvauti renginyje?",
    headingPregnancyComplicationDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelPregnancyComplication1: "Koks yra nėštumo komplikacijos pobūdis?",
    tooltipPregnancyComplication1: "Prašome detalizuoti",
    labelPregnancyComplication2: "Kada tai įvyko?",
    tooltipPregnancyComplication2: "Pasirinkite",
    labelPregnancyComplication3: "Ar kreipėtės į medikus?",
    tooltipPregnancyComplication3: "Taip ar ne",
    labelPregnancyComplication4: "Kodėl tai reiškia, kad negalite dalyvauti renginyje?",
    tooltipPregnancyComplication4: "Prašome detalizuoti",
    headingPublicTransportFailure: "Viešojo transporto būdas (traukinys, autobusas, tramvajus, valtis ir kt.) netikėtai sugedo, o tai reiškia, kad nebuvo kito būdo, kaip galėčiau keliauti į šį renginį?",
    headingPublicTransportFailureDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelPublicTransportFailure1: "Kokio tipo viešuoju transportu planavote naudotis?",
    tooltipPublicTransportFailure1: "Pasirinkite",
    labelPublicTransportFailure2: "Kada planavote keliauti?",
    tooltipPublicTransportFailure2: "Pasirinkite",
    labelPublicTransportFailure3: "Kas atsitiko?",
    tooltipPublicTransportFailure3: "Prašome detalizuoti",
    labelPublicTransportFailure4: "Kodėl negalėtumėte imtis alternatyvaus transporto?",
    tooltipPublicTransportFailure4: "Prašome detalizuoti",
    headingScheduledAirlineFailure: "Mano iš anksto užsakytas skrydis netikėtai atšauktas ir aš negalėjau rasti tinkamos alternatyvos, t. y. negalėjau dalyvauti renginyje?",
    headingScheduledAirlineFailureDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelScheduledAirlineFailure1: "Aviakompanijos pavadinimas",
    tooltipScheduledAirlineFailure1: "Aviakompanijos pavadinimas",
    labelScheduledAirlineFailure2: "Skrydžio numeris",
    tooltipScheduledAirlineFailure2: "Skrydžio numeris",
    labelScheduledAirlineFailure3: "Kelionės iš",
    tooltipScheduledAirlineFailure3: "Kelionės iš",
    labelScheduledAirlineFailure4: "Kelionės į",
    tooltipScheduledAirlineFailure4: "Kelionės į",
    labelScheduledAirlineFailure5: "Incidento data?",
    tooltipScheduledAirlineFailure5: "Pasirinkite",
    labelScheduledAirlineFailure6: "Kodėl negalėjote imtis alternatyvaus skrydžio?",
    tooltipScheduledAirlineFailure6: "Prašome detalizuoti",
    headingTheftOfDocuments: "Mano dokumentai buvo pavogti ir nebuvo įmanoma susitarti dėl pakeitimo (-ų) per reikiamą laiką, todėl negaliu dalyvauti renginyje",
    headingTheftOfDocumentsDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelTheftOfDocuments1: "Kokio tipo dokumentas buvo pavogtas?",
    tooltipTheftOfDocuments1: "Pasirinkite",
    labelTheftOfDocuments2: "Kada tai įvyko?",
    tooltipTheftOfDocuments2: "Pasirinkite",
    labelTheftOfDocuments3: "Ar bandėte dokumentus išduoti iš naujo?",
    tooltipTheftOfDocuments3: "Taip ar ne",
    headingWorkRelocation: "Mano darbdavys paprašė manęs pakeisti darbo vietą bent trims mėnesiams, o atstumas yra didelis, o tai reiškia, kad dabar negaliu dalyvauti renginyje?",
    headingWorkRelocationDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelWorkRelocation1: "Ar tai laikinas ar nuolatinis jūsų darbo perkėlimas?",
    tooltipWorkRelocation1: "Pasirinkite",
    labelWorkRelocation2: "Kiek laiko būsite naujuoju adresu?",
    tooltipWorkRelocation2: "Prašome detalizuoti",
    labelWorkRelocation3: "Koks jūsų dabartinis darbo adresas?",
    tooltipWorkRelocation3: "Prašome detalizuoti",
    labelWorkRelocation4: "Koks jūsų naujasis darbo adresas?",
    tooltipWorkRelocation4: "Prašome detalizuoti",
    labelWorkRelocation5: "Kada jums buvo pranešta apie šį perkėlimą?",
    tooltipWorkRelocation5: "Pasirinkite",
    headingRedundancy: "Mane arba užsakymo narį darbdavys atleido iš darbo, o tai reiškia, kad nebegaliu dalyvauti užsakyme?",
    headingRedundancyDecline: "Neturėtumėte tęsti šios paraiškos, nes pinigų grąžinimas greičiausiai bus atmestas.",
    labelRedundancy1: "Darbo pobūdis?",
    tooltipRedundancy1: "Pasirinkite",
    labelRedundancy2: "Kiek laiko dirbate šioje įmonėje?",
    tooltipRedundancy2: "Pasirinkite",
    labelRedundancy3: "Ar atleidimas buvo savanoriškas?",
    tooltipRedundancy3: "Pasirinkite",
    labelRedundancy4: "Kada jums buvo pranešta apie jūsų atleidimą?",
    tooltipRedundancy4: "Pasirinkite",
    labelTestOption: "Pasirinkite Taip, kad tęstumėte",
};