export default {
    nonAutoApprovedLabelThankYou: "Vielen Dank für Ihren Erstattungsantrag.",
    nonAutoApprovedLabelReview: "Unser Team wird Ihre Bewerbung innerhalb der nächsten zwei Werktage prüfen und sich mit einer Antwort bei Ihnen melden.",
    nonAutoApprovedLabelExperience: "Wie haben wir das gemacht?",
    nonAutoApprovedLabelExperienceIntroduction: "Wir würden uns sehr freuen, wenn Sie sich die Zeit nehmen, uns zu bewerten und eine Bewertung zu hinterlassen, da wir die Meinung unserer Kunden schätzen.",
    feedbackExcellent: "Ausgezeichnet",
    feedbackGreat: "Großartig",
    feedbackAverage: "Durchschnitt",
    feedbackPoor: "Arm",
    feedbackBad: "Schlecht",
    labelThankYouForFeedback: "Vielen Dank für Ihr Feedback.",
    autoApprovedMessage1: "Wir freuen uns,",
    autoApprovedMessage2: "Billigen",
    autoApprovedMessage3: "Ihre Rückerstattung!",
    autoApprovedPayment1Day: "Ihre Zahlung wurde bereits bearbeitet und sollte in nur 1 Werktag auf Ihrem Konto sein.",
    autoApprovedPayment2to4Days: "Ihre Zahlung wurde bereits bearbeitet und sollte in nur 2-4 Werktagen auf Ihrem Konto sein.",
    autoApprovedPayment7to10Days: "Ihre Zahlung wurde bereits bearbeitet und sollte in nur 7-10 Werktagen auf Ihrem Konto sein.",
    autoApprovedLabelExperience: "Wie haben wir das gemacht?",
    autoApprovedLabelExperienceIntroduction: "Wir würden uns sehr freuen, wenn Sie sich die Zeit nehmen, uns zu bewerten und eine Bewertung zu hinterlassen, da wir die Meinung unserer Kunden schätzen.",
    modalLabelNegative: "Ihr Feedback ist uns sehr wichtig, lassen Sie es uns wissen, wenn Sie auch möchten, dass wir uns mit Ihnen in Verbindung setzen.",
    placeholderFeedback: "Feedback",
    messageFeedbackSubmitted: "Eingereichtes Feedback",
    reviewInstructionMessage: "5 Sterne sind ausgezeichnet, 4 Sterne sind großartig, 3 Sterne sind durchschnittlich, 2 Sterne sind schlecht, 1 Stern ist schlecht."
}