export default {
    labelBookingCancelledPostponedHeading: "La tua prenotazione è stata cancellata o posticipata? Per saperne di più",
    labelBookingCancelledPostponedText1: "Clic",
    labelBookingCancelledPostponedText2: "per maggiori informazioni.",
    labelNewRefundHeading: "Nuova richiesta di rimborso?",
    labelNewRefundText: "Inserisci qui il riferimento della tua prenotazione...",
    placeholderBookingReference: "Riferimento prenotazione",
    validationBookingReference: "È richiesto un riferimento di prenotazione",
    validationEmailAsBookingReference: "L'indirizzo e-mail non è accettato come riferimento della prenotazione",
    buttonFindBooking: "Trova una prenotazione",
    labelEmailHeading:  "Già iniziato?",
    labelEmailText: "Invia un'e-mail di promemoria per ritirare la domanda.",
    placeholderEmail: "E-mail",
    buttonEmailReminder: "Promemoria tramite posta elettronica",
    labelCustomerName: "Nome del cliente",
    validationCustomerName: "Il nome del cliente è obbligatorio ",
    labelTypeOfBooking: "Tipo di prenotazione",
    validationTypeOfBooking: "Il tipo di prenotazione è obbligatorio",
    labelEventName: "Nome dell'evento ",
    labelVenueName: "Nome del luogo",
    labelFlightNumber: "numero di volo",
    labelOriginAirport: "Aeroporto di origine",
    labelHotelName: "Nome dell'alloggio",
    labelHotelLocation: "Luogo di alloggio",
    labelCurrency: "Motto",
    validationCurrency: "È richiesta la valuta",
    validationCountryCurrency: "La valuta selezionata deve essere la valuta del tuo Paese di origine",
    validationOfflinePayment: "Non possiamo elaborare rimborsi per la valuta e il paese selezionati, seleziona delle alternative",
    labelBookingValue: "Valore della prenotazione",
    validationBookingValue: "Il valore della prenotazione è obbligatorio",
    validationBookingValueAmount: "Deve essere maggiore di 0",
    labelDateOfPurchase: "Data di acquisto",
    validationDateOfPurchase: "È richiesta la data di acquisto",
    labelEmailAddress: "indirizzo email",
    validationEmailAddress: "È richiesto un indirizzo e-mail",
    validationEmailAddressFormat: "Formato e-mail errato",
    labelConfirmEmailAddress: "Conferma il tuo indirizzo email",
    validationConfirmEmailAddressFormat: "Formato e-mail errato",
    validationConfirmEmailAddress: "È richiesto un indirizzo email di conferma",
    validationConfirmEmailAddressMatch: "Gli indirizzi email non combaciano",
    validationEmailDomain: "Questo provider di posta elettronica non è accettato, utilizzare un'alternativa",
    validationDateOfEvent: "La data dell'evento è obbligatoria",
    labelHomeCountry: "Paese d'origine",
    validationHomeCountry: "È richiesto il paese di origine",
    labelContactNumberCountryCode: "Numero di contatto Prefisso internazionale",
    validationContactNumberCountryCode: "Il prefisso del paese è obbligatorio",
    labelContactNumber: "Numero di contatto",
    validationContactNumber: "Il numero di contatto è obbligatorio",
    labelAddressLine1: "indirizzo 1",
    labelAddressLine2: "indirizzo 2",
    labelCity: "città",
    labelZipCode: "Postal code",
    labelRegion: "Regione",
    validationRegion: "La regione è obbligatoria",
    labelNumberOfTicketsToRefund: "Numero di biglietti da rimborsare",
    validationNumberOfTicketsToRefund: "È richiesto il numero di biglietti da rimborsare",
    validationNumberOfTicketsToRefundValue: "Deve essere maggiore di 0",
    labelCostPerTicket: "Costo di ogni biglietto",
    validationCostPerTicket: "Il costo per biglietto è obbligatorio",
    validationCostPerTicketValue: "Deve essere maggiore di 0",
    labelTotalRefundAmount: "Importo totale del rimborso",
    validationTotalRefundAmount: "È richiesto l'importo del rimborso completo",
    validationTotalRefundAmountValue: "Deve essere maggiore di 0",
    validationTotalRefundGreaterThanBookingValue: "L'importo totale del rimborso non può essere superiore al valore di prenotazione sopra indicato",
    labelBookingConfirmationFiles: "Scarica la conferma della prenotazione",
    validationBookingConfirmationFiles: "Allega almeno un file o fai clic su Salva e torna più tardi quando hai i document",
    validationEvidenceFilesPopup: "Le prove devono essere caricate prima dell'invio. Se non hai le prove in questo momento, salva la domanda e restituiscila una volta che le hai. Accettiamo copie elettroniche, copie scanner e foto.",
    validationBookingConfirmationFilesPopup: "È necessario caricare la conferma della prenotazione prima di inviare. Se non hai la conferma della prenotazione in questo momento, salva l'applicazione e torna una volta che l'hai. Accettiamo copie elettroniche, copie scanner e foto.",
    alertUploadDocuments: "Si prega di caricare la documentazione pertinente dettagliata nell'e-mail e quindi procedere al passaggio 3.",
    labelRefundReasonCategory: "Motivo del rimborso",
    validationRefundReasonCategory: "È richiesto il motivo del rimborso",
    labelDateOfIncident: "Data dell'incidente",
    labelAdditionalComments: "Commenti aggiuntivi",
    tooltipAdditionalComments: "Commenti aggiuntivi",
    validationAdditionalComments: "Sono necessari ulteriori commenti",
    labelUnableToNotifyWithinTimeLimit: "Impossibile notificare entro 60 giorni",
    labelReasonForNotificationOutsideTimeLimit: "Notifica fuori termine Spiegazione",
    validationReasonForNotificationOutsideTimeLimit: "È richiesto il motivo della notifica dopo 60 giorni",
    labelRefundReasonFiles: "Carica le prove",
    validationRefundReasonFiles: "Allega almeno un file o fai clic su Salva e torna più tardi quando hai i documenti",
    validationTextScript: "Deve corrispondere alla scrittura latina",
    optionTypeOfBooking1: "Evento",
    optionTypeOfBooking2: "Torre",
    optionTypeOfBooking3: "Volo",
    optionTypeOfBooking4: "alloggio",
    optionTypeOfBooking5: "Trasferimento",
    optionTypeOfBooking6: "Parcheggio",
    optionTypeOfBooking7: "Altro viaggio",
    labelEventTypeDate: "Data dell'evento",
    labelEventTypeTour: "Data del tour",
    labelEventTypeFlight: "Data del volo",
    labelEventTypeCheckIn: "data d'arrivo",
    labelEventTypeTransfer: "data di trasferimento",
    labelEventTypeParking: "Data di parcheggio",
    labelEventTypeOtherTravel: "Data del viaggio",
    optionRefundReason11: "condizioni climatiche avverse",
    optionRefundReason10: "Richiamo di forze armate e servizi di emergenza",
    optionRefundReason15: "Modifiche alle date degli esami",
    optionRefundReason18: "Citazione in tribunale",
    optionRefundReason17: "COVID-19 restrizioni di viaggio",
    optionRefundReason19: "Test positivo al COVID-19",
    optionRefundReason20: "Prenotazione annullata dall'operatore/organizzatore",
    optionRefundReason21: "Prenotazione riprogrammata dall'operatore/organizzatore",
    optionRefundReason22: "Data errata prenotata",
    optionRefundReason23: "Biglietti sbagliati prenotati",
    optionRefundReason24: "Ho prenotato troppi biglietti",
    optionRefundReason4: "Morte",
    optionRefundReason16: "Circostanze di emergenza",
    optionRefundReason14: "Divieto di viaggio governativo",
    optionRefundReason9: "Emergenza domestica",
    optionRefundReason1: "Malattia / infortunio / incidente",
    optionRefundReason8: "Servizio di giuria",
    optionRefundReason7: "Guasto meccanico",
    optionRefundReason2: "edizione medica preesistente",
    optionRefundReason3: "Complicazione della gravidanza",
    optionRefundReason5: "Fallimento del trasporto pubblico",
    optionRefundReason6: "Interruzione del volo",
    optionRefundReason13: "Furto di documenti",
    optionRefundReason12: "Trasferimento di lavoro",
    optionRefundReason25: "Ridondanza sul posto di lavoro",
    optionRefundReason26: "Opzione di prova",
    optionRefundReasonConfirm1: "No",
    optionRefundReasonConfirm2: "Sì",
    optionRefundReasonArmedForces1: "Esercito",
    optionRefundReasonArmedForces2: "Marina militare",
    optionRefundReasonArmedForces3: "Forze aeree",
    optionRefundReasonArmedForces4: "Forze Speciali",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Fuoco",
    optionRefundReasonArmedForces7: "Ambulanza",
    optionRefundReasonArmedForces8: "Medico/Infermiere",
    optionRefundReasonArmedForces9: "Dentista",
    optionRefundReasonArmedForces10: "Altro",
    optionRefundReasonCourtSummons1: "Procedimento penale",
    optionRefundReasonCourtSummons2: "Causa legale civile",
    optionRefundReasonCourtSummons3: "Testimone in un'accusa",
    optionRefundReasonCourtSummons4: "Testimone in una causa civile",
    optionRefundReasonCourtSummons5: "Querelante",
    optionRefundReasonCourtSummons6: "Imputato",
    optionRefundReasonCourtSummons7: "Altro",
    optionRefundReasonDeath1: "Marito",
    optionRefundReasonDeath2: "Moglie",
    optionRefundReasonDeath3: "Suono",
    optionRefundReasonDeath4: "Figlia",
    optionRefundReasonDeath5: "Padre",
    optionRefundReasonDeath6: "Madre",
    optionRefundReasonDeath7: "Fratello",
    optionRefundReasonDeath8: "Sorella",
    optionRefundReasonDeath9: "Nonno",
    optionRefundReasonDeath10: "Nonna",
    optionRefundReasonDeath11: "Altro",
    optionRefundReasonHomeEmergency1: "Fuoco",
    optionRefundReasonHomeEmergency2: "Inondazione",
    optionRefundReasonHomeEmergency3: "Rapina",
    optionRefundReasonHomeEmergency4: "Vandalismo",
    optionRefundReasonHomeEmergency5: "Abusi domestici",
    optionRefundReasonHomeEmergency6: "Rapire",
    optionRefundReasonHomeEmergency7: "Altro",
    optionRefundReasonCOVID19Person1: "Me stesso",
    optionRefundReasonCOVID19Person2: "Un'altra persona nella prenotazione",
    optionRefundReasonCOVID19Person3: "Una persona nella mia famiglia",
    optionRefundReasonCOVID19Person4: "Altro",
    optionRefundReasonCOVID19TestType1: "PCR Test",
    optionRefundReasonCOVID19TestType2: "RAT Test",
    optionRefundReasonCOVID19TestType3: "Test di flusso laterale",
    optionRefundReasonCOVID19TestType4: "Test autosomministrato",
    optionRefundReasonCOVID19TestType5: "Nessun test ma ho sintomi",
    optionRefundReasonCOVID19TestType6: "Altro",
    optionRefundReasonBreakdown1: "Crollo",
    optionRefundReasonBreakdown2: "Incidente",
    optionRefundReasonBreakdown3: "Fuoco",
    optionRefundReasonBreakdown4: "Furto",
    optionRefundReasonPublicTransportType1: "Treno",
    optionRefundReasonPublicTransportType2: "Autobus",
    optionRefundReasonPublicTransportType3: "Tram",
    optionRefundReasonPublicTransportType4: "Barca",
    optionRefundReasonDocumentType1: "Passaporto",
    optionRefundReasonDocumentType2: "Permesso di viaggio",
    optionRefundReasonDocumentType3: "Documento d'identità",
    optionRefundReasonDocumentType4: "Biglietti",
    optionRefundReasonWorkRelocationType1: "Provvisorio",
    optionRefundReasonWorkRelocationType2: "Permanente",
    optionRefundReasonWorkRelocationTemporary1: "0-3 mesi",
    optionRefundReasonWorkRelocationTemporary2: "3-6 mesi",
    optionRefundReasonWorkRelocationTemporary3: "Oltre 6 mesi",
    optionRefundReasonBookingRelationshipType1: "Qualcuno nella prenotazione",
    optionRefundReasonBookingRelationshipType2: "Parente stretto di qualcuno nella prenotazione",
    optionEmploymentType1: "Tempo pieno",
    optionEmploymentType2: "Tempo parziale",
    optionEmploymentType3: "Contratto",
    optionEmploymentLengthType1: "0-1 anni",
    optionEmploymentLengthType2: "1-2 anni",
    optionEmploymentLengthType3: "Più di 2 anni",
    textRefundEvidenceHeading: "Fornisci le seguenti prove:",
    textRefundEvidence1: "Conferma della compagnia aerea",
    textRefundEvidence2: "Qualsiasi prova a sostegno appropriata",
    textRefundEvidence3: "Il servizio di soccorso stradale chiama la conferma",
    textRefundEvidence4: "Certificato di morte",
    textRefundEvidence5: "Conferma cancellazione dalla compagnia aerea",
    textRefundEvidence6: "Previsioni del tempo",
    textRefundEvidence7: "Corrispondenza da parte dell'agente di prenotazione che conferma che non è stata possibile riemettere i biglietti",
    textRefundEvidence8: "Lettera della Corte",
    textRefundEvidence9: "Certificato medico / Nota del medico",
    textRefundEvidenceDescription9: "Confermare i dettagli della malattia o dell'infortunio, la data in cui si è verificato per la prima volta, che ti impedisce di partecipare alla prenotazione.",
    textRefundEvidence10: "Conferma della lettera del datore di lavoro",
    textRefundEvidence11: "Corrispondenza per sinistri assicurativi",
    textRefundEvidence12: "Lettera del datore di lavoro",
    textRefundEvidence13: "Conferma del sito web del governo nazionale",
    textRefundEvidence14: "Avviso di modifica dell'esame",
    textRefundEvidence15: "Rapporto della polizia",
    textRefundEvidence16: "Rapporto di polizia e numero di riferimento",
    textRefundEvidence17: "Conferma del fornitore di servizi di trasporto",
    textRefundEvidence18: "Avviso di viaggio",
    textRefundEvidence20: "Certificato di prova COVID-19",
    textRefundEvidence24: "Avviso di isolamento governativo",
    textRefundEvidence25: "Lettera dell'ufficiale comandante",
    textRefundEvidence26: "Lettera degli assicuratori",
    textRefundEvidence27: "Prova di indirizzo",
    textRefundEvidence28: "Prova della relazione con il defunto",
    textRefundEvidence29: "Prova della relazione con la persona lesa/malata",
    textRefundEvidence30: "Busta paga",
    tooltipBookingReference: "Per identificare il riferimento della tua prenotazione, controlla la conferma della prenotazione e visualizza la sezione relativa al nostro servizio esteso.",
    tooltipCustomerName: "Nome e cognome utilizzati per effettuare la prenotazione.",
    tooltipTypeOfBooking: "Tipo di prenotazione che desideri essere rimborsato.",
    tooltipEventName: "Il nome dell'evento.",
    tooltipVenueName: "Il nome del luogo.",
    tooltipFlightNumber: "Il numero del volo.",
    tooltipOriginAirport: "L'aeroporto di partenza.",
    tooltipHotelName: "Il nome dell'alloggio.",
    tooltipHotelLocation: "L'ubicazione dell'alloggio.",
    tooltipCurrency: "La valuta utilizzata per pagare la prenotazione.",
    tooltipBookingValue: "Prezzo totale pagato per la prenotazione.",
    tooltipDateOfPurchase: "La data in cui è stata effettuata la prenotazione.",
    tooltipEmailAddress: "Utilizza un indirizzo e-mail valido. Usiamo questa e-mail per la corrispondenza durante la richiesta di rimborso.",
    tooltipConfirmEmailAddress: "Usa lo stesso indirizzo email di cui sopra, questo è per la convalida.",
    tooltipHomeCountry: "Questo ci aiuterà a visualizzare le informazioni bancarie corrette nel passaggio finale.",
    tooltipContactNumberCountryCode: "Codice del paese del numero di contatto.",
    tooltipContactNumber: "Si prega di utilizzare un numero valido. Lo usiamo per la corrispondenza durante la richiesta di rimborso.",
    tooltipAddressLine1: "Tieni presente che il tuo indirizzo verrà utilizzato per la convalida del tuo conto bancario.",
    tooltipAddressLine2: "Seconda riga di indirizzo.",
    tooltipCity: "La tua città di residenza, i dettagli possono essere utilizzati per convalidare i dettagli bancari.",
    tooltipZipCode: "Codice di avviamento postale / C.A.P.",
    tooltipRegion: "Regione della tua città, i dettagli possono essere utilizzati per convalidare i dettagli bancari.",
    tooltipNumberOfTicketsToRefund: "Il numero di biglietti che desideri rimborsare.",
    tooltipTotalRefundAmount: "Questo non può essere superiore al valore totale della prenotazione, in caso contrario non potrai inviare la domanda.",
    tooltipBookingConfirmationFiles: "Screenshot / allegato della tua conferma di prenotazione completa che di solito si trova nelle tue e-mail.",
    tooltipRefundReasonCategory: "Seleziona il motivo del rimborso.",
    tooltipDateOfIncident: "La data in cui si è verificato l'incidente che ha avuto un impatto sulla tua prenotazione.",
    tooltipRefundExplanation: "Si prega di fornire quanti più dettagli possibili a supporto della richiesta di rimborso.",
    tooltipUnableToNotifyWithinTimeLimit: "Impossibile notificare entro il limite di tempo di 60 giorni.",
    tooltipReasonForNotificationOutsideTimeLimit: "Notifica al di fuori del limite di tempo Spiegazione.",
    tooltipRefundReasonFiles: "Si prega di caricare uno qualsiasi dei documenti richiesti.",
    tooltipEventTypeDate: "Data dell'evento / prenotazione effettivo.",
    tooltipEventTypeTour: "Data del tour effettivo.",
    tooltipEventTypeFlight: "Data del volo effettivo.",
    tooltipEventTypeCheckIn: "Data dell'effettivo check-in.",
    tooltipEventTypeTransfer: "Data dell'effettivo trasferimento.",
    tooltipEventTypeParking: "Data del parcheggio effettivo.",
    tooltipEventTypeOtherTravel: "Data di inizio del viaggio effettivo."
};