import * as React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import useCfar from '../../hooks/useCfar';

export const ApplicationReference = () => {

    const {
        applicationSubmitted,
        applicationReference,
        bookingRefLookupAttempted
    } = useFormContext();

    const {t} = useTranslation();
    const {cfarResponse} = useCfar();

    const showApplicationReference = 
        !applicationSubmitted &&
        bookingRefLookupAttempted &&
        applicationReference?.length > 0 &&
        !cfarResponse.isCfar;

    return (
        <>
            {showApplicationReference &&
                <Grid container pb={2} justifyContent='center'>
                    <Grid size={{md: 8}}>
                        <Box justifyContent='flex-end' display='flex' mr={2}>
                            <b>
                                {t('common:labelSavedApplicationReference')} {applicationReference.toLocaleUpperCase()}
                            </b>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
}