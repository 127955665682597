import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import am from '../src/languages/locales/am';
import ar from '../src/languages/locales/ar';
import da from '../src/languages/locales/da';
import de from '../src/languages/locales/de';
import el from '../src/languages/locales/el';
import en from '../src/languages/locales/en';
import es from '../src/languages/locales/es';
import et from '../src/languages/locales/et';
import fi from '../src/languages/locales/fi';
import fr from '../src/languages/locales/fr';
import he from '../src/languages/locales/he';
import hi from '../src/languages/locales/hi';
import id from '../src/languages/locales/id';
import it from '../src/languages/locales/it';
import ja from '../src/languages/locales/ja';
import kk from '../src/languages/locales/kk';
import km from '../src/languages/locales/km';
import ko from '../src/languages/locales/ko';
import lt from '../src/languages/locales/lt';
import lv from '../src/languages/locales/lv';
import ms from '../src/languages/locales/ms';
import nb from '../src/languages/locales/nb';
import nl from '../src/languages/locales/nl';
import pl from '../src/languages/locales/pl';
import pt from '../src/languages/locales/pt';
import ptbr from '../src/languages/locales/pt-br';
import ro from '../src/languages/locales/ro';
import ru from '../src/languages/locales/ru';
import sv from '../src/languages/locales/sv';
import th from '../src/languages/locales/th';
import tr from '../src/languages/locales/tr';
import uk from '../src/languages/locales/uk';
import vi from '../src/languages/locales/vi';
import zhcn from '../src/languages/locales/zh-cn';
import zhtw from '../src/languages/locales/zh-tw';
import Backend from 'i18next-http-backend';

export const AVAILABLE_LANGUAGES = {
    am,
    ar,
    da,
    de,
    el,
    en,
    es,
    et,
    fi,
    fr,
    he,
    hi,
    id,
    it,
    ja,
    kk,
    km,
    ko,
    lt,
    lv,
    ms,
    nb,
    nl,
    pl,
    pt,
    ptbr,
    ro,
    ru,
    sv,
    th,
    tr,
    uk,
    vi,
    zhcn,
    zhtw
};

i18next
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: AVAILABLE_LANGUAGES,
        lng: 'en',
        fallbackLng: 'en',
        ns: ['form', 'common', 'payment'],
        debug: false,
        defaultNS: 'common',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18next;