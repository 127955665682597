export default {
    messageUnableToMakePaymentToCurrency: "Niestety nie możemy pobrać danych bankowych dla wybranej waluty. Upewnij się, że waluta i kraj pochodzenia są wybrane w dniu",
    messageOnlyBankAccountsAccepted: "Uwaga: obecnie możemy dokonywać płatności tylko na konta bankowe (karty kredytowe i debetowe nie są akceptowane)",
    labelAccountName: "Nazwa właściciela konta",
    labelBankAccountName: "Imię i nazwisko właściciela konta",
    labelBankCardHolderName: "Imię właściciela karty",
    validationBankAccountName: "Pełne imię i nazwisko właściciela konta jest wymagane",
    labelBankCountry: "Kraj banku",
    labelBankName: "Nazwa banku",
    labelPaymentType: "Typ płatności",
    labelInstitutionNumber: "Numer instytucji",
    labelBankAddress: "Numer konta",
    labelBankAddress2: "Numer konta",
    labelBankCity: " miasto banku ",
    labelBankState: "stan banku",
    labelBankAccountNumber: "Numer konta bankowego",
    labelBankAccountNumberCBU: "Numer konta CBU",
    labelBankAccountNumberCCI: "Numer konta CCI",
    labelBankAccountNumberZAR: "Numer konta bankowego (7-11 cyfr)",
    labelCardNumber: "Numer karty",
    labelUnionpay: "Numer karty UnionPay",
    labelAlipay: "Alipay ID (numer telefonu komórkowego lub e-mail)",
    labelBankAccountType: "Typ konta",
    optionBankAccountTypeCURRENT: "Rachunek bieżący",
    optionBankAccountTypeCHECKING: "Sprawdzanie konta",
    optionBankAccountTypeSAVING: "Konto oszczędnościowe",
    optionBankAccountTypeSAVINGS: "Konto oszczędnościowe",
    optionBankAccountTypeCUENTA_VISTA: "Konto główne",
    optionBankAccountType1: "Sprawdzanie konta",
    optionBankAccountType2: "Konto oszczędnościowe",
    optionBankAccountType3: "Konto główne",
    optionBankAccountType4: "Konto Visty",
    optionBankAccountType5: "Konto RUT",
    labelSortCode: "Kod sortowania",
    labelRoutingCode: "Kod routingu",
    labelBankRoutingCodeNationalID: "Kod banku / krajowy identyfikator",
    labelBankTransitCodeSwift: "Numer tranzytowy",
    labelCNAPSCode: "Kod rozliczeniowy / kod CNAPS (chiński krajowy kod zaawansowanego systemu płatności)",
    labelABAFedwireRoutingCode: "Numer rozliczeniowy ACH",
    labelIFSCCode: "Kod IFSC",
    labelBankCode: "Kod bankowy",
    labelBIKCode: "Kod BIK",
    labelBranchCode: "Kod oddziału",
    labelBranchCodeUniversal: "Uniwersalny 6-cyfrowy kod oddziału",
    labelRemittanceLine3: "Imię i nazwisko oraz numer telefonu beneficjenta",
    labelBeneficiaryContactNameandTelephoneNumber: "Imię i nazwisko oraz numer telefonu beneficjenta",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Data urodzenia i miejsce urodzenia beneficjenta",
    labelRemittanceLine4: "Indywidualny numer podatkowy CUIL (9–12 cyfr)",
    labelCUILNumber: "NIP: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Identyfikator podatkowy 13 cyfr RFC lub 18 cyfr CURP",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Indywidualny identyfikator podatkowy CPF Cadastro de Pessoa Física (11-14 cyfr), kod oddziału",
    labelCFPNumber: "Numer CPF (11-14 cyfr)",
    labelBranchNameorBranchCode7digits: "Nazwa oddziału lub kod oddziału (7 cyfr)",
    labelCedulaJuridica910digitsTaxID: "Certyfikat prawny (9-10 cyfr NIP)",
    labelTaxIDRUT89digits: "Identyfikator podatkowy RUT (8-9 cyfr)",
    labelJob: "Stanowisko",
    labelDateOfBirth: "Data urodzenia",
    labelNationality: "Narodowość",
    labelDocumentType: "Typ dokumentu",
    labelDocumentNumber: "Numer dokumentu",
    labelRutNumber: "Numer RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Karta identyfikacyjna (numer identyfikacji podatkowej 8-11 cyfr)",
    labelCIIndividualTaxIDBranchLocation: "CI (indywidualny identyfikator podatkowy), lokalizacja oddziału",
    labelTaxIDRUCorCI: "Identyfikator podatkowy (RUC lub CI)",
    labelSortCodeHelp: "Wsparcie?",
    labelBranchCodeHelp: "Wsparcie?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Wsparcie?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Wsparcie?",
    labelRoutingCodeBSB: "Kod trasy / BSB",
    labelBSB: "Oddział banku centralnego (BSB)",
    labelSKNCode: "Kod SKN",
    labelAcceptanceFlights: "Akceptuję/akceptujemy, że jeśli wniosek o zwrot zostanie rozpatrzony pomyślnie, rezerwacja obejmująca wszystkie loty i produkty dodatkowe zostanie anulowana.",
    labelAcceptanceTickets: "Akceptuję/akceptujemy, że jeśli wniosek o zwrot zostanie rozpatrzony pomyślnie, rezerwacja obejmująca wszystkie bilety i produkty dodatkowe zostanie anulowana.",
    labelTermsAndConditions1: "Zgodziliśmy się na",
    labelTermsAndConditions2: "Warunki i Warunki",
    labelFraudWarning1: "Uważam, że fakty podane w tym formularzu wniosku o zwrot są prawdziwe i prawdziwe. Rozumiem, że wszelkie fałszywe informacje lub dowody mogą zostać uznane za przestępstwo i przekazane policji lub innym organom ścigania w celu ich ścigania.",
    labelFraudWarning2: "W przypadku podejrzenia oszustwa w związku z dokumentacją uzupełniającą, sprawa ta zostanie niezwłocznie skierowana do lokalnych organów policji. Akceptuję i rozumiem, że moje dane zostaną przekazane tym władzom, jeśli moje zgłoszenie zostanie uznane za fałszywe i może stać się postępowaniem karnym.",
    validationTermsAndConditions: "Warunki muszą zostać zaakceptowane",
    messageFailbankName: "Nazwa banku nie może być pusta",
    messageFailcustomerName: "Nazwa klienta nie może być pusta",
    messageFailbankAccountNumber: "Pole wymagane",
    messageFailbankAccountNumberFormat: "Musi mieć format IBAN",
    messageFailsortCode: "Pole wymagane",
    messageFailbankSwiftBic: "BIC beneficjenta SWIFT nie może być puste",
    messageFailbankSwiftBicFormat: "Musi być w formacie SWIFT",
    messageFailbeneficiaryAddress1: "Adres 1 nie może być pusty",
    messageFailbeneficiaryCity: "Miasto nie może być puste",
    messageFailbeneficiaryPostCode: "Kod pocztowy nie może być pusty",
    messageFailremittanceLine3: "Pole wymagane",
    messageFailremittanceLine4: "Pole wymagane",
    modalHeading: "Potwierdź dane bankowe",
    modalLabelPleaseConfirmYourDetails: "Sprawdź swoje dane bankowe i potwierdź.",
    modalValidationPleaseConfirm: "Proszę potwierdzić",
    modalValidationMustMatch: "Musi pasować do poprzedniego wpisu",
    tooltipBankAccountName: "Twoje imię i nazwisko, tak jak widnieje na koncie bankowym / wyciągach bankowych / karcie bankowej.",
    tooltipBankAccountNameRecipient: "Imię i nazwisko odbiorcy. Zarówno imię, jak i nazwisko muszą mieć więcej niż jeden znak.",
    tooltipBankCountry: "Kraj, w którym znajduje się Twój bank.",
    tooltipBankName: "Nazwa Twojego banku.",
    tooltipPaymentType: "Rodzaj płatności, którego potrzebujesz.",
    tooltipInstitutionNumber: "Jest to 3 cyfry i zwykle znajduje się na wyciągu miesięcznym lub karcie bankowej lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAccountNumber: "Twój indywidualny numer konta. Zwykle znajduje się na wyciągu miesięcznym lub na karcie bankowej, a jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAccountNumberCBU: "22-cyfrowy numer służący do identyfikacji Twojego konta. Zwykle znajduje się na wyciągu miesięcznym lub karcie bankowej lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAccountNumberCCI: "20-cyfrowy numer służący do identyfikacji Twojego konta. Zwykle znajduje się na wyciągu miesięcznym lub karcie bankowej lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAccountNumberKRW: "11-16-cyfrowy numer służący do identyfikacji Twojego konta. Zwykle znajduje się na wyciągu miesięcznym lub karcie bankowej lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCardNumber: "Twój numer karty. Zwykle znajduje się na wyciągu miesięcznym lub karcie bankowej lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipUnionpay: "Numer Twojej karty UnionPay.",
    tooltipAlipay: "Twój numer telefonu komórkowego lub adres e-mail powiązany z Twoim kontem Alipay.",
    tooltipBankAccountType: "To jest albo sprawdzanie, albo oszczędności.",
    tooltipSortCode: "Identyfikuje oddział Twojego banku, w którym jest prowadzone Twoje konto. Zwykle znajduje się na wyciągu miesięcznym lub na karcie bankowej, a jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipRoutingCode: "Identyfikuje oddział Twojego banku, w którym jest prowadzone Twoje konto. Zwykle znajduje się na wyciągu miesięcznym lub na karcie bankowej, a jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBeneficiarySWIFTBIC: "Składa się z 8-11 znaków używanych do identyfikacji banku w transakcji międzynarodowej. Zwykle znajduje się na wyciągu miesięcznym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBSB: "Numer oddziału banku (BSB) to australijski kod rozliczeniowy składający się z 6 cyfr. Zwykle znajduje się na wyciągu miesięcznym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipRoutingCodeBSB: "Identyfikuje oddział Twojego banku, w którym jest prowadzone Twoje konto. Zwykle znajduje się na wyciągu miesięcznym lub na karcie bankowej, a jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipABAFedwireRoutingCode: "9-cyfrowy numer używany do identyfikacji banków w Ameryce. Zwykle znajduje się na wyciągu miesięcznym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipIFSCCode: "Zazwyczaj ten kod można znaleźć na wyciągu miesięcznym lub w książeczce, a jeśli nadal nie masz pewności, zapytaj w swoim banku.",
    tooltipTermsAndConditions: "Akceptując warunki, potwierdzasz, że przeczytałeś i zgadzasz się z naszymi Warunkami korzystania z usługi.",
    tooltipIBAN: "Twój międzynarodowy numer konta bankowego, którego możesz używać podczas dokonywania lub otrzymywania płatności międzynarodowych. Zwykle znajduje się na wyciągu miesięcznym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Imię i nazwisko osoby kontaktowej beneficjenta oraz numer telefonu.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Data urodzenia i miejsce urodzenia beneficjenta.",
    tooltipBankRoutingCodeNationalID: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankTransitCodeSwift: "Jest to 5 cyfr i zwykle można je znaleźć na miesięcznym wyciągu bankowym lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAddress: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankAddress2: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankCity: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankState: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBranchName: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBankCode: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub, jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBIKCode: "9 cyfr do identyfikacji Twojego banku.",
    tooltipBranchCode: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBranchCodeUniversal: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCNAPSCode: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipRemittanceLine4: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCUILNumber: "Jest to 11-cyfrowa liczba składająca się z dwóch cyfr, myślnika, dziewięciu cyfr i jednocyfrowej sumy kontrolnej.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCFPNumber: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipBranchNameorBranchCode7digits: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCedulaJuridica910digitsTaxID: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipTaxIDRUT89digits: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipJob: "Twój obecny zawód.",
    tooltipDateOfBirth: "Twoja data urodzenia.",
    tooltipNationality: "Twoja narodowość.",
    tooltipDocumentType: "Wymagany typ dokumentu.",
    tooltipDocumentNumber: "Jest to numer dokumentu powiązany z wybranym typem dokumentu.",
    tooltipRutNumber: "Twój numer RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipCIIndividualTaxIDBranchLocation: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    tooltipTaxIDRUCorCI: "Zwykle można je znaleźć na miesięcznym wyciągu bankowym lub jeśli nadal nie masz pewności, zapytaj swój bank.",
    errorCPFCode: "W tej chwili akceptujemy tylko CPF jako klucz PIX."
};