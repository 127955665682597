import * as React from 'react';
import {SelectChangeEvent} from '@mui/material';
import {
    useFieldHidden,
    useWisePaymentItems
} from '../../hooks/usePaymentInformation';
import {FormikProps} from 'formik';
import {nameof} from 'ts-simple-nameof';
import {SelectInput} from '../formInputs';
import usePaymentLabels from '../../hooks/usePaymentLabels';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from '../providers/FormProvider';
import {useSetRecoilState} from 'recoil';
import {BankNameState} from '../../state/activity';

interface BankSelectControlProps {
    formProps: FormikProps<IFormValues>;
}

export const BankSelectControl = (props: BankSelectControlProps) => {

    const {
        formProps
    } = props;

    const {
        paymentResponseFields,
        setBankBranchesDropDownItems,
        updateBankBranches
    } = useFormContext();

    const paymentLabels = usePaymentLabels(formProps.values.currency);
    const wisePaymentItems = useWisePaymentItems();
    const isFieldHidden = useFieldHidden();

    const setBankName = useSetRecoilState(BankNameState);

    const handleBankNameChange = (event: SelectChangeEvent) => {

        const banks = paymentResponseFields
            .map(function (x) {
                return x.valuesAllowed;
            })
            .reduce(function (y, z) {
                return y.concat(z);
            }, []);

        const bankName = banks?.find(x => x.key === event.target.value).name;

        setBankName(bankName);

        if (event.target.value === '' && formProps.values.branchCodeSelect !== '') {
            void formProps.setFieldValue(nameof<IFormValues>(x => x.branchCodeSelect), '');
            setBankBranchesDropDownItems([]);
            return;
        }

        updateBankBranches(formProps.values.currency, formProps.values.homeCountry, event.target.value);
    }

    return (
        <SelectInput
            key={nameof<IFormValues>(x => x.bankNameSelect)}
            label={paymentLabels.labelBankName}
            controlId={nameof<IFormValues>(x => x.bankNameSelect)}
            hidden={isFieldHidden.bankNameSelect}
            dropDownItemsInput={wisePaymentItems.bankNameSelect}
            onChange={(e) => handleBankNameChange(e)}
            tooltip={paymentLabels.tooltipBankName}
            required
        />
    );
}