import bespokeQuestions from './bespokeQuestions';
import form from './form';
import common from './common';
import payment from './payment';
import modal from './modal';
import confirmation from './confirmation';
import productModal from './productModal';
import findBookingModal from "./findBookingModal";
import confirmRefundAmountModal from "./confirmRefundAmountModal";

export default {
    bespokeQuestions,
    form,
    common,
    payment,
    modal,
    confirmation,
    productModal,
    findBookingModal,
    confirmRefundAmountModal
};