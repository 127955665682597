export default {
    nonAutoApprovedLabelThankYou: "Kiitos hyvityshakemuksestasi.",
    nonAutoApprovedLabelReview: "Tiimimme tarkistaa hakemuksesi seuraavien kahden arkipäivän aikana ja ottaa sinuun yhteyttä vastauksen kanssa.",
    nonAutoApprovedLabelExperience: "Miten pärjäsimme?",
    nonAutoApprovedLabelExperienceIntroduction: "Olisimme todella kiitollisia, että käyttäisit aikaa arvioidaksesi meitä ja jättääksesi arvostelun, koska arvostamme asiakkaidemme mielipidettä.",
    feedbackExcellent: "Erinomainen",
    feedbackGreat: "Hyvä",
    feedbackAverage: "Tyydyttävä",
    feedbackPoor: "Välttävä",
    feedbackBad: "Surkea",
    labelThankYouForFeedback: "Kiitos palautteestasi.",
    autoApprovedMessage1: "Olemme iloisia voidessamme",
    autoApprovedMessage2: "Hyväksyä",
    autoApprovedMessage3: "hyvityksesi!",
    autoApprovedPayment1Day: "Maksusi on jo käsitelty, ja sen pitäisi olla tililläsi vain 1 arkipäivänä.",
    autoApprovedPayment2to4Days: "Maksusi on jo käsitelty, ja sen pitäisi olla tililläsi vain 2-4 arkipäivän kuluttua.",
    autoApprovedPayment7to10Days: "Maksusi on jo käsitelty, ja sen pitäisi olla tililläsi vain 7-10 arkipäivän kuluessa.",
    autoApprovedLabelExperience: "Miten pärjäsimme?",
    autoApprovedLabelExperienceIntroduction: "Olisimme todella kiitollisia, että käyttäisit aikaa arvioidaksesi meitä ja jättääksesi arvostelun, koska arvostamme asiakkaidemme mielipidettä.",
    modalLabelNegative: "Palautteesi on meille todella tärkeää, kerro meille, jos haluat meidän myös ottavan sinuun yhteyttä.",
    placeholderFeedback: "Palaute",
    messageFeedbackSubmitted: "Palaute lähetetty",
    reviewInstructionMessage: "5 tähteä on erinomainen, 4 tähteä on hienoa, 3 tähteä on keskimäärin, 2 tähteä on huono, 1 tähti on huono."
}