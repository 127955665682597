import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormSectionProps} from './RefundReasonHandlerSection'
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';

export const BookingRescheduledByOperatorSection = (props: FormSectionProps) => {

    const { t } = useTranslation();

    const {
        formProps
    } = props;
    
    return (
        <RefundReasonConfirmationSection
            formProps={formProps}
            heading={t('bespokeQuestions:labelBookingRescheduledByOperator')}
            declineWarning={t('bespokeQuestions:labelADWrongSelection')}
        />
    );
}