import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AlertHolder} from './AlertHolder';

export const EventDateLimitAlert = () => {

    const {t} = useTranslation();

    const EventDateLimitErrorMessage = () => {
        return (
            <Box>{t('form:messageEventDateLimitError')}</Box>
        );
    }

    return (
        <AlertHolder
            severity='error'
            content={<EventDateLimitErrorMessage/>}
        />
    );
}