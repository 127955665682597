export default {
    labelBookingCancelledPostponedHeading: "Đặt phòng của bạn đã bị hủy hoặc hoãn lại? Đọc thêm",
    labelBookingCancelledPostponedText1: "Nhấp chuột",
    labelBookingCancelledPostponedText2: "để biết thêm thông tin.",
    labelNewRefundHeading: "Yêu cầu hoàn lại tiền mới?",
    labelNewRefundText: "Nhập thông tin tham khảo đặt phòng của bạn tại đây ...",
    placeholderBookingReference: "Tham chiếu đặt chỗ",
    validationBookingReference: "Cần có tài liệu tham khảo đặt phòng",
    validationEmailAsBookingReference: "Địa chỉ email không được chấp nhận làm tài liệu tham khảo đặt phòng",
    buttonFindBooking: "Tìm đặt chỗ",
    labelEmailHeading: "Đã bắt đầu?",
    labelEmailText: "Gửi email nhắc nhở để truy xuất ứng dụng của bạn.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Nhắc nhở qua e-mail",
    labelCustomerName: "Tên khách hàng",
    validationCustomerName: "Tên khách hàng là bắt buộc",
    labelTypeOfBooking: "Loại đặt chỗ",
    validationTypeOfBooking: "Loại đặt chỗ là bắt buộc",
    labelEventName: "Tên của sự kiện",
    labelVenueName: "Tên địa điểm",
    labelFlightNumber: "Số chuyến bay",
    labelOriginAirport: "Sân bay xuất phát",
    labelHotelName: "Tên của chỗ ở",
    labelHotelLocation: "Nơi ở",
    labelCurrency: "Tiền tệ",
    validationCurrency: "Tiền tệ là bắt buộc",
    validationCountryCurrency: "Đơn vị tiền tệ được chọn phải là đơn vị tiền tệ của Nước sở tại của bạn",
    validationOfflinePayment: "Chúng tôi không thể xử lý khoản tiền hoàn lại cho lựa chọn quốc gia và đơn vị tiền tệ của bạn, vui lòng chọn các lựa chọn thay thế",
    labelBookingValue: "Giá trị đặt trước",
    validationBookingValue: "Giá trị đặt trước là bắt buộc",
    validationBookingValueAmount: "Phải lớn hơn 0",
    labelDateOfPurchase: "Ngày mua",
    validationDateOfPurchase: "Ngày mua là bắt buộc",
    labelEmailAddress: "Địa chỉ email",
    validationEmailAddress: "Địa chỉ email là bắt buộc",
    validationEmailAddressFormat: "Định dạng email không chính xác",
    labelConfirmEmailAddress: "Xác nhận địa chỉ email của bạn",
    validationConfirmEmailAddressFormat: "Định dạng e-mail không chính xác",
    validationConfirmEmailAddress: "Địa chỉ e-mail xác nhận là bắt buộc",
    validationConfirmEmailAddressMatch: "địa chỉ e-mail không khớp",
    validationEmailDomain: "Nhà cung cấp email này không được chấp nhận, vui lòng sử dụng một giải pháp thay thế",
    validationDateOfEvent: "Ngày diễn ra sự kiện là bắt buộc",
    labelHomeCountry: "Native country",
    validationHomeCountry: "Quốc gia xuất xứ là bắt buộc",
    labelContactNumberCountryCode: "Số liên lạc Mã quốc gia",
    validationContactNumberCountryCode: "Mã quay số quốc gia là bắt buộc",
    labelContactNumber: "Số liên hệ",
    validationContactNumber: "Số liên hệ là bắt buộc",
    labelAddressLine1: "Địa chỉ 1",
    labelAddressLine2: "Dòng địa chỉ 2",
    labelCity: "Thành phố",
    labelZipCode: "Mã bưu điện",
    labelRegion: "Vùng",
    validationRegion: "Khu vực là bắt buộc",
    labelNumberOfTicketsToRefund: "Số lượng vé sẽ được hoàn",
    validationNumberOfTicketsToRefund: "Số lượng vé được hoàn lại là bắt buộc",
    validationNumberOfTicketsToRefundValue: "Phải lớn hơn 0",
    labelCostPerTicket: "Giá mỗi vé",
    validationCostPerTicket: "Chi phí cho mỗi vé là bắt buộc",
    validationCostPerTicketValue: "Phải lớn hơn 0",
    labelTotalRefundAmount: "Tổng số tiền hoàn lại",
    validationTotalRefundAmount: "Tổng số tiền hoàn lại là bắt buộc",
    validationTotalRefundAmountValue: "Phải lớn hơn 0",
    validationTotalRefundGreaterThanBookingValue: "Tổng số tiền hoàn lại không được lớn hơn Giá trị đặt trước ở trên",
    labelBookingConfirmationFiles: "Tải xuống xác nhận đặt phòng",
    validationBookingConfirmationFiles: "Đính kèm ít nhất một tệp hoặc nhấp vào Lưu và quay lại sau khi bạn có tài liệu",
    validationEvidenceFilesPopup: "Bằng chứng được yêu cầu phải được tải lên trước khi gửi. Nếu bạn không có bằng chứng ngay bây giờ, vui lòng lưu đơn đăng ký và quay lại khi bạn có. Chúng tôi chấp nhận bản sao điện tử, bản sao máy quét và ảnh.",
    validationBookingConfirmationFilesPopup: "Xác nhận đặt chỗ được yêu cầu phải được tải lên trước khi gửi. Nếu bạn không có xác nhận đặt phòng ngay bây giờ, vui lòng lưu đơn đăng ký và quay lại khi bạn có. Chúng tôi chấp nhận bản sao điện tử, bản sao máy quét và ảnh.",
    alertUploadDocuments: "Vui lòng tải lên tài liệu liên quan được nêu chi tiết trong email rồi chuyển sang Bước 3.",
    labelRefundReasonCategory: "Lý do hoàn tiền",
    validationRefundReasonCategory: "Lý do hoàn lại tiền là bắt buộc",
    labelDateOfIncident: "Ngày xảy ra sự cố",
    labelAdditionalComments: "Ý kiến ​​khác",
    tooltipAdditionalComments: "Ý kiến ​​khác",
    validationAdditionalComments: "Cần có ý kiến ​​bổ sung",
    labelUnableToNotifyWithinTimeLimit: "Không thể thông báo trong vòng 60 ngày",
    labelReasonForNotificationOutsideTimeLimit: "Thông báo ngoài thời hạn Giải thích",
    validationReasonForNotificationOutsideTimeLimit: "Cần có lý do thông báo sau 60 ngày",
    labelRefundReasonFiles: "Tải xuống bản thử nghiệm",
    validationRefundReasonFiles: "Đính kèm ít nhất một tệp hoặc nhấp vào Lưu và quay lại sau khi bạn có tài liệu",
    validationTextScript:"Phải khớp với tập lệnh Latin",
    optionTypeOfBooking1: "Một sự kiện",
    optionTypeOfBooking2: "Tham quan",
    optionTypeOfBooking3: "Chuyến bay",
    optionTypeOfBooking4: "Chỗ ở",
    optionTypeOfBooking5: "Chuyển",
    optionTypeOfBooking6: "Bãi đậu xe",
    optionTypeOfBooking7: "Du lịch khác",
    labelEventTypeDate: "Ngày diễn ra sự kiện",
    labelEventTypeTour: "Ngày tham quan",
    labelEventTypeFlight: "Ngày bay",
    labelEventTypeCheckIn: "Ngày đến",
    labelEventTypeTransfer: "Ngày chuyển",
    labelEventTypeParking: "Ngày đậu xe",
    labelEventTypeOtherTravel: "Ngày đi du lịch",
    optionRefundReason11: "Điều kiện thời tiết bất lợi",
    optionRefundReason10: "Thu hồi lực lượng vũ trang và dịch vụ khẩn cấp",
    optionRefundReason15: "Thay đổi ngày thi",
    optionRefundReason18: "Giấy triệu tập của Tòa án",
    optionRefundReason17: "Hạn chế đi lại do COVID-19",
    optionRefundReason19: "Thử nghiệm dương tính COVID-19",
    optionRefundReason20: "Đơn vị đã hủy bởi Nhà điều hành / Nhà tổ chức",
    optionRefundReason21: "Đặt trước do Nhà điều hành / Nhà tổ chức lên lịch",
    optionRefundReason22: "Đã đặt nhầm ngày",
    optionRefundReason23: "Đã đặt nhầm vé",
    optionRefundReason24: "Đã đặt quá nhiều vé",
    optionRefundReason4: "Chết",
    optionRefundReason16: "Trường hợp khẩn cấp",
    optionRefundReason14: "Chính phủ cấm đi lại",
    optionRefundReason9: "Trường hợp khẩn cấp tại nhà",
    optionRefundReason1: "Bệnh tật / thương tật / tai nạn",
    optionRefundReason8: "Dịch vụ bồi thẩm đoàn",
    optionRefundReason7: "Lỗi cơ học",
    optionRefundReason2: "Tình trạng bệnh từ trước",
    optionRefundReason3: "Biến chứng khi mang thai",
    optionRefundReason5: "Giao thông công cộng không thành công",
    optionRefundReason6: "Gián đoạn chuyến bay",
    optionRefundReason13: "(Các) Tài liệu Trộm cắp",
    optionRefundReason12: "Chuyển nơi làm việc",
    optionRefundReason25: "Dự phòng nơi làm việc",
    optionRefundReason26: "Tùy chọn kiểm tra",
    optionRefundReasonConfirm1: "Không",
    optionRefundReasonConfirm2: "Có",
    optionRefundReasonArmedForces1: "Quân đội",
    optionRefundReasonArmedForces2: "Hải quân",
    optionRefundReasonArmedForces3: "Không quân",
    optionRefundReasonArmedForces4: "Lực lượng đặc biệt",
    optionRefundReasonArmedForces5: "Thủy quân lục chiến",
    optionRefundReasonArmedForces6: "Lửa",
    optionRefundReasonArmedForces7: "Xe cứu thương",
    optionRefundReasonArmedForces8: "Bác sĩ/Y tá",
    optionRefundReasonArmedForces9: "Nha sĩ",
    optionRefundReasonArmedForces10: "Khác",
    optionRefundReasonCourtSummons1: "Khởi tố hình sự",
    optionRefundReasonCourtSummons2: "Vụ kiện dân sự",
    optionRefundReasonCourtSummons3: "Nhân chứng trong một vụ truy tố",
    optionRefundReasonCourtSummons4: "Nhân chứng trong vụ án dân sự",
    optionRefundReasonCourtSummons5: "Nguyên cáo",
    optionRefundReasonCourtSummons6: "Bị cáo",
    optionRefundReasonCourtSummons7: "Khác",
    optionRefundReasonDeath1: "Chồng",
    optionRefundReasonDeath2: "Vợ",
    optionRefundReasonDeath3: "Âm thanh",
    optionRefundReasonDeath4: "Con gái",
    optionRefundReasonDeath5: "Cha",
    optionRefundReasonDeath6: "Mẹ",
    optionRefundReasonDeath7: "Anh",
    optionRefundReasonDeath8: "Chị",
    optionRefundReasonDeath9: "Ông",
    optionRefundReasonDeath10: "Bà",
    optionRefundReasonDeath11: "Khác",
    optionRefundReasonHomeEmergency1: "Lửa",
    optionRefundReasonHomeEmergency2: "Lụt",
    optionRefundReasonHomeEmergency3: "Cướp",
    optionRefundReasonHomeEmergency4: "Phá hoại",
    optionRefundReasonHomeEmergency5: "Lạm dụng gia đình",
    optionRefundReasonHomeEmergency6: "Bắt cóc",
    optionRefundReasonHomeEmergency7: "Khác",
    optionRefundReasonCOVID19Person1: "Bản thân mình",
    optionRefundReasonCOVID19Person2: "Một người khác trong đặt phòng",
    optionRefundReasonCOVID19Person3: "Một người trong gia đình tôi",
    optionRefundReasonCOVID19Person4: "Khác",
    optionRefundReasonCOVID19TestType1: "Xét nghiệm PCR",
    optionRefundReasonCOVID19TestType2: "Kiểm tra RAT",
    optionRefundReasonCOVID19TestType3: "Kiểm tra lưu lượng bên",
    optionRefundReasonCOVID19TestType4: "Kiểm tra tự quản lý",
    optionRefundReasonCOVID19TestType5: "Không xét nghiệm nhưng tôi có triệu chứng",
    optionRefundReasonCOVID19TestType6: "Khác",
    optionRefundReasonBreakdown1: "Phân tích",
    optionRefundReasonBreakdown2: "Tai nạn",
    optionRefundReasonBreakdown3: "Lửa",
    optionRefundReasonBreakdown4: "Sự trộm cắp",
    optionRefundReasonPublicTransportType1: "Xe lửa",
    optionRefundReasonPublicTransportType2: "Xe buýt",
    optionRefundReasonPublicTransportType3: "Tàu điện",
    optionRefundReasonPublicTransportType4: "Thuyền",
    optionRefundReasonDocumentType1: "Hộ chiếu",
    optionRefundReasonDocumentType2: "Giấy phép du lịch",
    optionRefundReasonDocumentType3: "Căn cước công dân",
    optionRefundReasonDocumentType4: "Vé",
    optionRefundReasonWorkRelocationType1: "Tạm",
    optionRefundReasonWorkRelocationType2: "Vĩnh viễn",
    optionRefundReasonWorkRelocationTemporary1: "0-3 tháng",
    optionRefundReasonWorkRelocationTemporary2: "3-6 tháng",
    optionRefundReasonWorkRelocationTemporary3: "Trên 6 tháng",
    optionRefundReasonBookingRelationshipType1: "Ai đó trong đặt phòng",
    optionRefundReasonBookingRelationshipType2: "Người thân trực tiếp của ai đó trong đặt phòng",
    optionEmploymentType1: "Toàn thời gian",
    optionEmploymentType2: "Bán thời gian",
    optionEmploymentType3: "Hợp đồng",
    optionEmploymentLengthType1: "0-1 Năm",
    optionEmploymentLengthType2: "1-2 năm",
    optionEmploymentLengthType3: "Hơn 2 năm",
    textRefundEvidenceHeading: "Vui lòng cung cấp bằng chứng sau:",
    textRefundEvidence1: "Xác nhận hãng hàng không",
    textRefundEvidence2: "Bất kỳ bằng chứng hỗ trợ thích hợp nào",
    textRefundEvidence3: "Dịch vụ phân tích gọi xác nhận",
    textRefundEvidence4: "Giấy chứng tử",
    textRefundEvidence5: "Hủy xác nhận từ hãng hàng không",
    textRefundEvidence6: "Báo cáo thời tiết",
    textRefundEvidence7: "Thư từ từ đại lý đặt phòng xác nhận không có khả năng phát hành lại vé",
    textRefundEvidence8: "Thư từ Tòa án",
    textRefundEvidence9: "Giấy chứng nhận y tế / Ghi chú của bác sĩ",
    textRefundEvidenceDescription9: "Xác nhận chi tiết về bệnh hoặc thương tích, ngày nó xảy ra lần đầu tiên, rằng nó ngăn cản Bạn tham dự Đặt phòng.",
    textRefundEvidence10: "Xác nhận thư của người sử dụng lao động",
    textRefundEvidence11: "Thư từ yêu cầu bồi thường bảo hiểm",
    textRefundEvidence12: "Thư của người sử dụng lao động",
    textRefundEvidence13: "Xác nhận trang web của Chính phủ Quốc gia",
    textRefundEvidence14: "Thông báo thay đổi kiểm tra",
    textRefundEvidence15: "Báo cáo của cảnh sát",
    textRefundEvidence16: "Báo cáo của cảnh sát và số tham chiếu",
    textRefundEvidence17: "Xác nhận của nhà cung cấp dịch vụ vận tải",
    textRefundEvidence18: "Cảnh báo du lịch",
    textRefundEvidence20: "Giấy chứng nhận xét nghiệm COVID-19",
    textRefundEvidence24: "Thông báo cách ly của chính phủ",
    textRefundEvidence25: "Thư của sĩ quan chỉ huy",
    textRefundEvidence26: "Thư từ các công ty bảo hiểm",
    textRefundEvidence27: "Bằng chứng địa chỉ",
    textRefundEvidence28: "Bằng chứng về mối quan hệ với người quá cố",
    textRefundEvidence29: "Bằng chứng về mối quan hệ với người bị thương/ người bệnh",
    textRefundEvidence30: "Phiếu lương",
    tooltipBookingReference: "Để xác định tài liệu tham khảo đặt phòng của bạn, hãy kiểm tra xác nhận đặt phòng của bạn và xem phần về dịch vụ mở rộng của chúng tôi.",
    tooltipCustomerName: "Họ và tên đã được sử dụng để đặt chỗ.",
    tooltipTypeOfBooking: "Loại đặt phòng bạn muốn được hoàn tiền.",
    tooltipEventName: "Tên của sự kiện.",
    tooltipVenueName: "Tên của địa điểm.",
    tooltipFlightNumber: "Số hiệu chuyến bay.",
    tooltipOriginAirport: "Các sân bay khởi hành.",
    tooltipHotelName: "Tên của chỗ ở.",
    tooltipHotelLocation: "Vị trí của chỗ ở.",
    tooltipCurrency: "Đơn vị tiền tệ được sử dụng để thanh toán cho đặt phòng.",
    tooltipBookingValue: "Tổng giá bạn đã trả cho đặt phòng.",
    tooltipDateOfPurchase: "Ngày đặt phòng được thực hiện.",
    tooltipEmailAddress: "Vui lòng sử dụng một địa chỉ email hợp lệ. Chúng tôi sử dụng email này để trao đổi thư từ trong quá trình nộp đơn hoàn lại tiền.",
    tooltipConfirmEmailAddress: "Sử dụng cùng một địa chỉ email như trên, điều này là để xác thực.",
    tooltipHomeCountry: "Điều này sẽ giúp chúng tôi hiển thị thông tin ngân hàng chính xác ở bước cuối cùng.",
    tooltipContactNumberCountryCode: "Mã quốc gia của số liên hệ.",
    tooltipContactNumber: "Vui lòng sử dụng một số hợp lệ. Chúng tôi sử dụng điều này để trao đổi thư từ trong quá trình nộp đơn xin hoàn lại tiền.",
    tooltipAddressLine1: "Xin lưu ý rằng địa chỉ của bạn sẽ được sử dụng khi xác thực tài khoản ngân hàng của bạn.",
    tooltipAddressLine2: "Dòng địa chỉ thứ hai.",
    tooltipCity: "Thành phố quê hương của bạn, thông tin chi tiết có thể được sử dụng để xác thực chi tiết ngân hàng.",
    tooltipZipCode: "Mã Zip / Mã Bưu điện.",
    tooltipRegion: "Khu vực thành phố quê hương của bạn, thông tin chi tiết có thể được sử dụng để xác thực chi tiết ngân hàng.",
    tooltipNumberOfTicketsToRefund: "Số lượng vé bạn muốn được hoàn lại.",
    tooltipTotalRefundAmount: "Số tiền này không được nhiều hơn tổng giá trị đặt phòng, bạn sẽ không thể gửi đơn đăng ký nếu có.",
    tooltipBookingConfirmationFiles: "Ảnh chụp màn hình / tệp đính kèm của xác nhận đặt phòng đầy đủ của bạn, thường có thể được tìm thấy trong email của bạn.",
    tooltipRefundReasonCategory: "Vui lòng chọn lý do hoàn lại tiền của bạn.",
    tooltipDateOfIncident: "Ngày xảy ra sự cố ảnh hưởng đến đặt phòng của bạn.",
    tooltipRefundExplanation: "Vui lòng cung cấp càng nhiều chi tiết càng tốt để hỗ trợ yêu cầu hoàn lại tiền.",
    tooltipUnableToNotifyWithinTimeLimit: "Không thể thông báo trong thời hạn 60 ngày.",
    tooltipReasonForNotificationOutsideTimeLimit: "Thông báo Bên ngoài Giới hạn Thời gian Giải thích.",
    tooltipRefundReasonFiles: "Vui lòng tải lên bất kỳ tài liệu cần thiết nào.",
    tooltipEventTypeDate: "Ngày diễn ra sự kiện / đặt chỗ thực tế.",
    tooltipEventTypeTour: "Ngày của chuyến tham quan thực tế.",
    tooltipEventTypeFlight: "Ngày của chuyến bay thực tế.",
    tooltipEventTypeCheckIn: "Ngày kiểm tra thực tế.",
    tooltipEventTypeTransfer: "Ngày chuyển tiền thực tế.",
    tooltipEventTypeParking: "Ngày của bãi đậu xe thực tế.",
    tooltipEventTypeOtherTravel: "Ngày bắt đầu của chuyến đi thực tế."
};