export default {
    messageUnableToMakePaymentToCurrency: "Din păcate, nu putem prelua detaliile bancare pentru moneda selectată. Vă rugăm să vă asigurați că sunt selectate Moneda și Țara de origine",
    messageOnlyBankAccountsAccepted: "Vă rugăm să rețineți: în acest moment putem efectua plăți numai în conturi bancare (nu se acceptă carduri de credit și de debit)",
    labelAccountName: "Numele deținătorului contului",
    labelBankAccountName: "Numele complet al titularului de cont",
    labelBankCardHolderName: "Nume Deținător Card",
    validationBankAccountName: "Numele complet al titularului de cont este necesar",
    labelBankCountry: "Țara Băncii",
    labelBankName: "Numele băncii",
    labelPaymentType: "Tipul de plată",
    labelInstitutionNumber: "Numărul instituției",
    labelBankAddress: "Adresa băncii",
    labelBankAddress2: "Adresa băncii",
    labelBankCity: "Orașul băncii",
    labelBankState: "Statul bancar",
    labelBankAccountNumber: "Numarul contului bancar",
    labelBankAccountNumberCBU: "Numărul de cont CBU",
    labelBankAccountNumberCCI: "Numărul de cont CCI",
    labelBankAccountNumberZAR: "Numărul contului bancar (7-11 cifre)",
    labelCardNumber: "Numărul de card",
    labelUnionpay: "Numărul cardului UnionPay",
    labelAlipay: "ID Alipay (număr de mobil sau e-mail)",
    labelBankAccountType: "Tip de cont",
    optionBankAccountTypeCURRENT: "Contul curent",
    optionBankAccountTypeCHECKING: "Verificarea contului",
    optionBankAccountTypeSAVING: "Cont de economii",
    optionBankAccountTypeSAVINGS: "Cont de economii",
    optionBankAccountTypeCUENTA_VISTA: "Cont principal",
    optionBankAccountType1: "Verificarea contului",
    optionBankAccountType2: "Cont de economii",
    optionBankAccountType3: "Cont principal",
    optionBankAccountType4: "Cont Vista",
    optionBankAccountType5: "Cont RUT",
    labelSortCode: "Cod de sortare",
    labelRoutingCode: "Codul de rutare",
    labelBankRoutingCodeNationalID: "Cod de rutare bancar / ID național",
    labelBankTransitCodeSwift: "Număr de tranzit",
    labelCNAPSCode: "Cod de rutare / Cod CNAPS (Codul sistemului național de plată avansată din China)",
    labelABAFedwireRoutingCode: "Numărul de rutare ACH",
    labelIFSCCode: "Cod IFSC",
    labelBankCode: "Cod bancar",
    labelBIKCode: "BIK Code",
    labelBranchCode: "Codul filialei",
    labelBranchCodeUniversal: "Cod universal de sucursală din 6 cifre",
    labelRemittanceLine3: "Numele de contact și numărul de telefon al beneficiarului",
    labelBeneficiaryContactNameandTelephoneNumber: "Numele de contact și numărul de telefon al beneficiarului",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Beneficiar Data Nașterii și Locul Nașterii",
    labelRemittanceLine4: "Număr fiscal individual CUIL (9-12 cifre)",
    labelCUILNumber: "Cod fiscal: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Cod fiscal RFC 13 cifre sau CURP 18 cifre",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Cod fiscal individual CPF Cadastro de Pessoa Física (11-14 cifre), Cod sucursală",
    labelCFPNumber: "Număr CPF (11-14 cifre)",
    labelBranchNameorBranchCode7digits: "Numele filialei sau codul sucursalei (7 cifre)",
    labelCedulaJuridica910digitsTaxID: "Certificat legal (ID fiscal de 9-10 cifre)",
    labelTaxIDRUT89digits: "Cod fiscal RUT (8-9 cifre)",
    labelJob: "Denumirea funcției",
    labelDateOfBirth: "Data de nastere",
    labelNationality: "Naţionalitate",
    labelDocumentType: "Tipul documentului",
    labelDocumentNumber: "numarul documentului",
    labelRutNumber: "Număr RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (ID fiscal Persoană fizică 8-11 cifre)",
    labelCIIndividualTaxIDBranchLocation: "CI (ID fiscal individual), locația sucursalei",
    labelTaxIDRUCorCI: "Cod fiscal (RUC sau CI)",
    labelSortCodeHelp: "Ajutor?",
    labelBranchCodeHelp: "Ajutor?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Ajutor?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Ajutor?",
    labelRoutingCodeBSB: "Cod de rutare/BSB",
    labelBSB: "Sucursala de stat bancară (BSB)",
    labelSKNCode: "Cod SKN",
    labelAcceptanceFlights: "Accept/acceptăm că, dacă cererea de rambursare are succes, rezervarea inclusiv toate zborurile și produsele auxiliare vor fi anulate.",
    labelAcceptanceTickets: "Accept/acceptăm că dacă cererea de rambursare are succes, rezervarea inclusiv toate biletele și produsele auxiliare vor fi anulate.",
    labelTermsAndConditions1: "Am/am fost de acord cu",
    labelTermsAndConditions2: "Termeni si conditii",
    labelFraudWarning1: "Consider că faptele furnizate în acest formular de cerere de rambursare sunt autentice și adevărate. Înțeleg că orice informații sau dovezi false pot fi considerate un act penal și pot fi transmise poliției sau altor agenții de aplicare a legii pentru a le urmări.",
    labelFraudWarning2: "În cazul în care suspectăm fraudă în legătură cu documentele justificative, această chestiune va fi imediat înaintată autorităților locale de poliție. Accept și înțeleg că detaliile mele vor fi transmise acestor autorități în cazul în care cererea mea este considerată frauduloasă și poate deveni o anchetă penală.",
    validationTermsAndConditions: "Termenii și condițiile trebuie acceptate",
    messageFailbankName: "Numele băncii nu poate fi necompletat",
    messageFailcustomerName: "Numele clientului nu poate fi necompletat",
    messageFailbankAccountNumber: "Câmp necesar",
    messageFailbankAccountNumberFormat: "Trebuie să fie în format IBAN",
    messageFailsortCode: "Câmp necesar",
    messageFailbankSwiftBic: "Beneficiarul SWIFT BIC nu poate fi necompletat",
    messageFailbankSwiftBicFormat: "Trebuie să fie în format SWIFT",
    messageFailbeneficiaryAddress1: "Adresa 1 nu poate fi goală",
    messageFailbeneficiaryCity: "Orașul nu poate fi gol",
    messageFailbeneficiaryPostCode: "Codul poștal nu poate fi necompletat",
    messageFailremittanceLine3: "Câmp necesar",
    messageFailremittanceLine4: "Câmp necesar",
    modalHeading: "Confirmați detaliile",
    modalLabelPleaseConfirmYourDetails: "Vă rugăm să vă confirmați detaliile",
    modalValidationPleaseConfirm: "Vă rugăm să confirmați",
    modalValidationMustMatch: "Trebuie să se potrivească cu intrarea anterioară",
    tooltipBankAccountName: "Numele dvs. așa cum apare pe contul dvs. bancar/extrasele bancare/cardul bancar.",
    tooltipBankAccountNameRecipient: "Numele și prenumele destinatarului. Atât numele, cât și numele trebuie să aibă mai mult de un caracter.",
    tooltipBankCountry: "Țara în care se află banca dvs.",
    tooltipBankName: "Numele băncii dvs.",
    tooltipPaymentType: "Tipul de plată de care aveți nevoie.",
    tooltipInstitutionNumber: "Acesta este format din 3 cifre și se găsește de obicei pe extrasul dvs. lunar sau pe cardul bancar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankAccountNumber: "Numărul dvs. individual de cont. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipBankAccountNumberCBU: "Un număr de 22 de cifre utilizat pentru a vă identifica contul. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipBankAccountNumberCCI: "Un număr de 20 de cifre utilizat pentru a vă identifica contul. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipBankAccountNumberKRW: "Un număr de 11-16 cifre utilizat pentru a vă identifica contul. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipCardNumber: "Numărul cardului dvs. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipUnionpay: "Numărul cardului dvs. UnionPay.",
    tooltipAlipay: "Numărul dvs. de mobil sau adresa de e-mail asociată contului dvs. Alipay.",
    tooltipBankAccountType: "Aceasta este fie verificare, fie economii",
    tooltipSortCode: "Aceasta identifică sucursala băncii dvs. în care este deținut contul dvs. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipRoutingCode: "Aceasta identifică sucursala băncii dvs. în care este deținut contul dvs. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipBeneficiarySWIFTBIC: "Constă din 8-11 caractere utilizate pentru a identifica o bancă într-o tranzacție internațională. De obicei, se găsește pe extrasul dvs. lunar sau dacă încă nu sunteți sigur, întrebați banca dvs.",
    tooltipBSB: "Un număr de Bank State Branch (BSB) este un cod de sortare australian care are 6 cifre. De obicei se găsește pe extrasul dvs. lunar sau dacă încă nu sunteți sigur, întrebați banca.",
    tooltipRoutingCodeBSB: "Aceasta identifică sucursala băncii dvs. în care este deținut contul dvs. De obicei se găsește pe extrasul tău lunar sau pe cardul bancar sau dacă încă nu ești sigur, întreabă-ți banca.",
    tooltipABAFedwireRoutingCode: "Un număr din 9 cifre folosit pentru a identifica băncile din America. De obicei se găsește pe extrasul dvs. lunar sau dacă încă nu sunteți sigur, întrebați banca.",
    tooltipIFSCCode: "De obicei, acest cod poate fi găsit pe extrasul dvs. lunar sau pe carnetul de abonare sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipTermsAndConditions: "Acceptând termenii și condițiile, confirmați că ați citit și sunteți de acord cu Termenii și condițiile noastre.",
    tooltipIBAN: "Numărul de cont bancar internațional, pe care îl puteți utiliza atunci când efectuați sau primiți plăți internaționale. De obicei se găsește pe extrasul dvs. lunar sau dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Numele de contact și numărul de telefon al beneficiarului.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Beneficiar Data Nașterii și Locul Nașterii.",
    tooltipBankRoutingCodeNationalID: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankTransitCodeSwift: "Acesta este format din 5 cifre și poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca dvs.",
    tooltipBankAddress: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankAddress2: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankCity: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankState: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBranchName: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBankCode: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBIKCode: "9 cifre pentru a vă identifica banca.",
    tooltipBranchCode: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBranchCodeUniversal: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipCNAPSCode: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipRemittanceLine4: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipCUILNumber: "Acesta este un număr de 11 cifre, format din două cifre, cratima, nouă cifre și o sumă de control cu ​​o cifră.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipCFPNumber: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipBranchNameorBranchCode7digits: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipCedulaJuridica910digitsTaxID: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipTaxIDRUT89digits: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipJob: "Ocupatia ta curenta.",
    tooltipDateOfBirth: "Data ta de nastere.",
    tooltipNationality: "Nationalitatea ta.",
    tooltipDocumentType: "Tipul de document necesar.",
    tooltipDocumentNumber: "Acesta este numărul documentului asociat cu selecția dvs. de tip de document.",
    tooltipRutNumber: "Numărul dvs. RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipCIIndividualTaxIDBranchLocation: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    tooltipTaxIDRUCorCI: "Poate fi găsit de obicei pe extrasul dvs. bancar lunar sau, dacă încă nu sunteți sigur, întrebați banca.",
    errorCPFCode: "Momentan acceptăm doar CPF ca cheie PIX."
};