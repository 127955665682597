export default {
    messageQuestionService: "Denne tjenesten er kun til bruk hvis du har problemer med å fylle ut søknadsskjemaet, vi kan ikke svare på andre spørsmål her.",
    labelName: "Navn",
    labelReference: "Referanse for bestilling",
    labelPhone: "Telefonnummer",
    labelEmail: "E-post",
    labelQuery: "Spørsmålet ditt",
    labelAskQuestion: "Send oss et spørsmål her...",
    buttonOpenModal: "Spørsmål",
    title: "Still et spørsmål",
    modalTitle: "4. Har du et teknisk problem med skjemaet?",
    toastSuccess: "Vi har mottatt spørsmålet ditt, og vi vil kontakte deg innen 24 timer",
    toastError: "Noe gikk galt med forespørselen din, prøv igjen",
    nameValidation: "Navn er påkrevd",
    referenceValidation: "Det kreves referanse",
    questionValidation: "Spørsmålet ditt er påkrevd"
}