export default {
    headingAdverseWeather: "Si verificano condizioni meteorologiche avverse severe che rendono pericoloso viaggiare per l'evento e questo è stato richiesto da un ente governativo (ad esempio autorità locale, polizia, ecc.). E avete prove per dimostrarlo?",
    headingAdverseWeatherDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    tooltipRefundReasonConfirm: "Sì o No",
    labelBookingCancelledByOperator: "I biglietti sono stati annullati dall'Operatore/Organizzatore.",
    labelBookingRescheduledByOperator: "La data è stata modificata dall'Operatore/Organizzatore e non posso partecipare alle nuove date.",
    labelBookedWrongDate: "Io o un membro della prenotazione, ho commesso un errore durante l'acquisto dei biglietti e abbiamo prenotato la data sbagliata.",
    labelBookedWrongTickets: "Io o un membro della prenotazione, ho commesso un errore durante l'acquisto dei biglietti e ho prenotato i biglietti sbagliati.",
    labelBookedTooManyTickets: "Io o un membro della prenotazione, ho commesso un errore durante l'acquisto dei biglietti e ho prenotato troppi biglietti.",
    labelCOVID19TravelRestrictions: "Io o un membro della prenotazione non siamo in grado di partecipare a causa di un divieto di viaggio COVID-19.",
    labelADWrongSelection: "Potresti aver selezionato il motivo sbagliato per un rimborso. Consulta nuovamente l'elenco a discesa Motivo del rimborso.",
    labelAdverseWeather1: "Perché il tempo avverso ha significato che non sei stato in grado di partecipare all'evento?",
    tooltipAdverseWeather1: "Si prega di dettaglio",
    labelAdverseWeather2: "Data dell'incidente",
    tooltipAdverseWeather2: "Per favore seleziona",
    headingArmedForces: "Ti viene chiesto dal tuo datore di lavoro, che sono le forze armate o i servizi di emergenza, di entrare al lavoro quando non eri originariamente programmato per farlo e questo ti impedisce di partecipare all'evento?",
    headingArmedForcesDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelArmedForces1: "Da quali forze o servizi di emergenza sei impiegato?",
    tooltipArmedForces1: "Per favore seleziona",
    labelArmedForcesOther1: "Si prega di specificare",
    tooltipArmedForcesOther1: "Si prega di specificare",
    labelArmedForces2: "Data dell'incidente",
    tooltipArmedForces2: "Per favore seleziona",
    headingChangesToExam: "Le date del tuo esame sono state modificate dalla tua scuola / università, ecc?? Questo non è un esame fallito che devi ripetere?",
    headingChangesToExamDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelExam1: "Qual era la data originale dell'esame?",
    tooltipExam1: "Per favore seleziona",
    labelExam2: "Qual è la nuova data dell'esame?",
    tooltipExam2: "Per favore seleziona",
    labelExam3: "Perché la data dell'esame è cambiata?",
    tooltipExam3: "Inserisci i dettagli",
    labelExam4: "Hai fallito l'esame precedente?",
    tooltipExam4: "Sì o No",
    headingCourtSummons: "Lei è stato convocato per comparire in tribunale come testimone nelle stesse date dell'evento?",
    headingCourtSummonsDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelCourtSummons1: "Qual è il motivo della vostra citazione in giudizio?",
    tooltipCourtSummons1: "Per favore seleziona",
    labelCourtSummonsOther1: "Si prega di specificare",
    tooltipCourtSummonsOther1: "Si prega di specificare",
    labelCourtSummons2: "Quando è stata emessa la citazione in giudizio?",
    tooltipCourtSummons2: "Per favore seleziona",
    headingCOVID19Test: "A me, a un membro della mia famiglia o a un membro del gruppo che partecipa all'evento è stato diagnosticato il COVID-19?",
    headingCOVID19TestDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelCOVID19Test1: "Chi ha COVID-19?",
    tooltipCOVID19Test1: "Per favore seleziona",
    labelCOVID19TestOther1a: "Si prega di specificare",
    labelCOVID19TestOther1b: "Specificare il nome",
    tooltipCOVID19TestOther1a: "Si prega di specificare",
    tooltipCOVID19TestOther1b: "Specificare il nome",
    labelCOVID19Test2: "Che tipo di test COVID-19 hai avuto?",
    tooltipCOVID19Test2: "Per favore seleziona",
    labelCOVID19TestOther2: "Si prega di specificare",
    tooltipCOVID19TestOther2: "Si prega di specificare",
    labelCOVID19Test3: "Qual è stata la data del tuo test COVID-19?",
    tooltipCOVID19Test3: "Per favore seleziona",
    headingDeath: "C'è stata la morte di un membro immediato della mia famiglia e mi impedisce di partecipare all'evento?",
    headingDeathDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelDeath1: "Quale membro della tua famiglia è morto?",
    tooltipDeath1: "Per favore seleziona",
    labelDeathOther1: "Si prega di specificare",
    tooltipDeathOther1: "Si prega di specificare",
    labelDeath2: "Quando è stata la data della morte?",
    tooltipDeath2: "Per favore seleziona",
    labelDeath3: "Nome del defunto",
    tooltipDeath3: "Si prega di specificare",
    headingEmergency: "Ho avuto una vera emergenza che è al di fuori del mio controllo e imprevista per la quale posso fornire prove?",
    headingEmergencyDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelEmergency1: "Si prega di spiegare il tipo di emergenza?",
    tooltipEmergency1: "Si prega di dettaglio",
    labelEmergency2: "Quando è successo?",
    tooltipEmergency2: "Per favore seleziona",
    labelEmergency3: "Perché questa situazione ti impedisce di partecipare all'evento?",
    tooltipEmergency3: "Si prega di dettaglio",
    labelEmergency4: "Avete prove di questa emergenza?",
    tooltipEmergency4: "Sì o No",
    headingHomeEmergency: "Ho un'emergenza domestica che è Rapina, Alluvione, Incendio, Vandalismo, Abuso domestico che mi impedisce di partecipare all'evento?",
    headingHomeEmergencyDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelHomeEmergency1: "Cos'è l'emergenza domestica?",
    tooltipHomeEmergency1: "Per favore seleziona",
    labelHomeEmergencyOther1: "Si prega di specificare",
    tooltipHomeEmergencyOther1: "Si prega di specificare",
    labelHomeEmergency2: "Quando è successo?",
    tooltipHomeEmergency2: "Per favore seleziona",
    labelHomeEmergency3: "Perché ti ha impedito di partecipare all'evento?",
    tooltipHomeEmergency3: "Si prega di dettaglio",
    headingInjury: "A me, a un membro della mia famiglia o a un membro del gruppo che partecipa all'evento è stata diagnosticata una condizione medica o una lesione che ora ci impedisce di andare all'evento?",
    headingInjuryDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelInjury1: "Qual è la natura della malattia/infortunio/incidente?",
    tooltipInjury1: "Si prega di dettaglio",
    labelInjury2: "Quando è successo?",
    tooltipInjury2: "Per favore seleziona",
    labelInjury3: "Hai frequentato una struttura medica per questa malattia/lesione?",
    tooltipInjury3: "Sì o No",
    labelInjury4: "Era una condizione medica preesistente?",
    tooltipInjury4: "Sì o No",
    labelInjury5: "Quando è stato diagnosticato per la prima volta?",
    tooltipInjury5: "Per favore seleziona",
    labelInjury6: "Perché questo significa che non puoi partecipare all'evento?",
    tooltipInjury6: "Si prega di dettaglio",
    labelInjury7: "Chi ha subito la malattia/infortunio?",
    tooltipInjury7: "Per favore seleziona",
    headingJuryService: "Io o un membro della prenotazione siamo stati chiamati al Servizio Giuria?",
    headingJuryServiceDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelJuryService1: "Quando è stato informato del Servizio della Giuria?",
    tooltipJuryService1: "Per favore seleziona",
    headingMechanicalBreakdown: "Il nostro veicolo ha avuto un guasto meccanico o è stato coinvolto in un incidente e questo ci impedisce di partecipare all'evento?",
    headingMechanicalBreakdownDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelMechanicalBreakdown1: "Cosa è successo con il veicolo?",
    tooltipMechanicalBreakdown1: "Per favore seleziona",
    labelMechanicalBreakdown2: "Quando è successo?",
    tooltipMechanicalBreakdown2: "Per favore seleziona",
    labelMechanicalBreakdown3: "Perché non sei stato in grado di prendere mezzi di trasporto alternativi?",
    tooltipMechanicalBreakdown3: "Si prega di dettaglio",
    headingPregnancyComplication: "Io o un membro della prenotazione abbiamo complicazioni della gravidanza che sono state diagnosticate da un medico e quindi impediscono la partecipazione all'evento?",
    headingPregnancyComplicationDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelPregnancyComplication1: "Qual è la natura della complicazione della gravidanza?",
    tooltipPregnancyComplication1: "Si prega di dettaglio",
    labelPregnancyComplication2: "Quando è successo?",
    tooltipPregnancyComplication2: "Per favore seleziona",
    labelPregnancyComplication3: "Hai cercato assistenza medica?",
    tooltipPregnancyComplication3: "Sì o No",
    labelPregnancyComplication4: "Perché questo significa che non puoi partecipare all'evento?",
    tooltipPregnancyComplication4: "Si prega di dettaglio",
    headingPublicTransportFailure: "Il metodo di trasporto pubblico (treno, autobus, tram, barca, ecc.) ha fallito inaspettatamente, il che significa che non c'era altro modo in cui potevo viaggiare a questo evento?",
    headingPublicTransportFailureDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelPublicTransportFailure1: "Che tipo di trasporto pubblico hai intenzione di utilizzare?",
    tooltipPublicTransportFailure1: "Per favore seleziona",
    labelPublicTransportFailure2: "Quando hai pensato di viaggiare?",
    tooltipPublicTransportFailure2: "Per favore seleziona",
    labelPublicTransportFailure3: "Cos'è successo?",
    tooltipPublicTransportFailure3: "Si prega di dettaglio",
    labelPublicTransportFailure4: "Perché non potresti prendere un trasporto alternativo?",
    tooltipPublicTransportFailure4: "Si prega di dettaglio",
    headingScheduledAirlineFailure: "Il mio volo prenotato in anticipo è stato cancellato inaspettatamente e non sono riuscito a trovare un'alternativa adatta, il che significa che non sono stato in grado di partecipare all'evento?",
    headingScheduledAirlineFailureDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelScheduledAirlineFailure1: "Nome della compagnia aerea",
    tooltipScheduledAirlineFailure1: "Nome della compagnia aerea",
    labelScheduledAirlineFailure2: "Numero di volo",
    tooltipScheduledAirlineFailure2: "Numero di volo",
    labelScheduledAirlineFailure3: "Viaggiare da",
    tooltipScheduledAirlineFailure3: "Viaggiare da",
    labelScheduledAirlineFailure4: "Viaggiare a",
    tooltipScheduledAirlineFailure4: "Viaggiare a",
    labelScheduledAirlineFailure5: "Data dell'incidente?",
    tooltipScheduledAirlineFailure5: "Per favore seleziona",
    labelScheduledAirlineFailure6: "Perché non sei riuscito a prendere un volo alternativo?",
    tooltipScheduledAirlineFailure6: "Si prega di dettaglio",
    headingTheftOfDocuments: "I miei documenti sono stati rubati e non è stato possibile organizzare sostituzioni entro i tempi necessari, quindi non posso partecipare all'evento",
    headingTheftOfDocumentsDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelTheftOfDocuments1: "Che tipo di documento è stato rubato?",
    tooltipTheftOfDocuments1: "Per favore seleziona",
    labelTheftOfDocuments2: "Quando è successo?",
    tooltipTheftOfDocuments2: "Per favore seleziona",
    labelTheftOfDocuments3: "Hai tentato di far riemettere i documenti?",
    tooltipTheftOfDocuments3: "Sì o No",
    headingWorkRelocation: "Il mio datore di lavoro mi ha chiesto di cambiare la sede del mio lavoro per almeno tre mesi e la distanza è significativa, il che significa che ora non sono in grado di partecipare all'evento?",
    headingWorkRelocationDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà probabilmente rifiutato.",
    labelWorkRelocation1: "Si tratta di un trasferimento temporaneo o permanente del tuo lavoro?",
    tooltipWorkRelocation1: "Per favore seleziona",
    labelWorkRelocation2: "Per quanto tempo sarai al nuovo indirizzo?",
    tooltipWorkRelocation2: "Si prega di dettaglio",
    labelWorkRelocation3: "Qual è il tuo attuale indirizzo di lavoro?",
    tooltipWorkRelocation3: "Si prega di dettaglio",
    labelWorkRelocation4: "Qual è il tuo nuovo indirizzo di lavoro?",
    tooltipWorkRelocation4: "Si prega di dettaglio",
    labelWorkRelocation5: "Quando è stato informato di questo trasferimento?",
    tooltipWorkRelocation5: "Per favore seleziona",
    headingRedundancy: "Io o un membro della prenotazione siamo stati licenziati dal loro datore di lavoro, il che significa che non posso più partecipare alla prenotazione?",
    headingRedundancyDecline: "Non dovresti procedere con questa applicazione poiché il rimborso verrà molto probabilmente rifiutato.",
    labelRedundancy1: "Tipo di impiego?",
    tooltipRedundancy1: "Prego selezionare",
    labelRedundancy2: "Da quanto tempo sei dipendente di questa azienda?",
    tooltipRedundancy2: "Prego selezionare",
    labelRedundancy3: "Il licenziamento è stato volontario?",
    tooltipRedundancy3: "Prego selezionare",
    labelRedundancy4: "Quando sei stato informato del tuo licenziamento?",
    tooltipRedundancy4: "Prego selezionare",
    labelTestOption: "Selezionare Sì per procedere",
}