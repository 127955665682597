export default {
    messageUnableToMakePaymentToCurrency: "Sayangnya kami tidak dapat mengambil detail bank untuk mata uang yang dipilih. Pastikan Mata Uang dan Negara Asal dipilih pada",
    messageOnlyBankAccountsAccepted: "Harap diperhatikan: Saat ini kami hanya dapat melakukan pembayaran ke Rekening Bank (Kartu Kredit dan Debit tidak diterima)",
    labelAccountName: "Nomor ponsel atau alamat email Anda yang terkait dengan akun Alipay Anda.",
    labelBankAccountName: "Nama Lengkap Pemegang Rekening",
    labelBankCardHolderName: "Nama pemegang kartu",
    validationBankAccountName: "Nama Lengkap Pemegang Rekening wajib diisi",
    labelBankCountry: "Negara Bank",
    labelBankName: "Nama Bank",
    labelPaymentType: "Payment Type",
    labelInstitutionNumber: "Nomor Institusi",
    labelBankAddress: "Alamat Bank",
    labelBankAddress2: "Alamat Bank",
    labelBankCity: "Kota Bank",
    labelBankState: "Negara Bank",
    labelBankAccountNumber: "Nomor Rekening Bank",
    labelBankAccountNumberCBU: "Nomor Rekening CBU",
    labelBankAccountNumberCCI: "Nomor Rekening CCI",
    labelBankAccountNumberZAR: "Nomor Rekening Bank (7-11 digit)",
    labelCardNumber: "Nomor kartu",
    labelUnionpay: "Nomor Kartu UnionPay",
    labelAlipay: "ID Alipay (nomor ponsel atau email)",
    labelBankAccountType: "Jenis akun",
    optionBankAccountTypeCURRENT: "Akun saat ini",
    optionBankAccountTypeCHECKING: "Rekening giro",
    optionBankAccountTypeSAVING: "Rekening tabungan",
    optionBankAccountTypeSAVINGS: "Rekening tabungan",
    optionBankAccountTypeCUENTA_VISTA: "Akun Utama",
    optionBankAccountType1: "Rekening giro",
    optionBankAccountType2: "Rekening tabungan",
    optionBankAccountType3: "Akun Utama",
    optionBankAccountType4: "Akun Vista",
    optionBankAccountType5: "Akun RUT",
    labelSortCode: "Kode Urut",
    labelRoutingCode: "Kode Perutean",
    labelBankRoutingCodeNationalID: "Kode Perutean Bank / ID Nasional",
    labelBankTransitCodeSwift: "Nomor Transit",
    labelCNAPSCode: "Routing Code / CNAPS Code (China National Advanced Payment System Code)",
    labelABAFedwireRoutingCode: "Nomor Perutean ACH",
    labelIFSCCode: "Kode IFSC",
    labelBankCode: "Kode Bank",
    labelBIKCode: "Kode BIK",
    labelBranchCode: "Kode Cabang",
    labelBranchCodeUniversal: "Kode cabang universal 6 digit",
    labelRemittanceLine3: "Nama Kontak Penerima dan Nomor Telepon",
    labelBeneficiaryContactNameandTelephoneNumber: "Nama Kontak Penerima dan Nomor Telepon",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Penerima Tanggal Lahir dan Tempat Lahir",
    labelRemittanceLine4: "Nomor Pajak Perorangan CUIL (9-12 digit)",
    labelCUILNumber: "Nomor Pajak: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "NPWP RFC 13 digit atau CURP 18 digit",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Nomor Pajak Perorangan CPF Cadastro de Pessoa Física (11-14 digit), Kode Cabang",
    labelCFPNumber: "Nomor CPF (11-14 digit)",
    labelBranchNameorBranchCode7digits: "Nama Cabang atau Kode Cabang (7 digit)",
    labelCedulaJuridica910digitsTaxID: "Sertifikat Legal (NPWP 9-10 digit)",
    labelTaxIDRUT89digits: "Id pajak RUT (8-9 digit)",
    labelJob: "Judul pekerjaan",
    labelDateOfBirth: "Tanggal lahir",
    labelNationality: "Kebangsaan",
    labelDocumentType: "Tipe dokumen",
    labelDocumentNumber: "Nomor dokumen",
    labelRutNumber: "nomor RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Kartu identitas (NPWP Individu 8-11 digit)",
    labelCIIndividualTaxIDBranchLocation: "CI (ID Pajak Perorangan), Lokasi Cabang",
    labelTaxIDRUCorCI: "Nomor Pajak (RUC atau CI)",
    labelSortCodeHelp: "Tolong?",
    labelBranchCodeHelp: "Tolong?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Tolong?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Tolong?",
    labelRoutingCodeBSB: "Kode Perutean / BSB",
    labelBSB: "Cabang Bank Negara (BSB)",
    labelSKNCode: "Kode SKN",
    labelAcceptanceFlights: "Saya/kami menerima bahwa jika permohonan pengembalian uang berhasil, pemesanan termasuk semua penerbangan, dan produk tambahan akan dibatalkan.",
    labelAcceptanceTickets: "Saya/kami menerima bahwa jika aplikasi pengembalian uang berhasil, pemesanan termasuk semua tiket, dan produk tambahan akan dibatalkan.",
    labelTermsAndConditions1: "Saya / kami telah menyetujui",
    labelTermsAndConditions2: "Syarat dan Ketentuan",
    labelFraudWarning1: "Saya percaya bahwa fakta yang diberikan dalam formulir permohonan pengembalian dana ini adalah asli dan benar. Saya mengerti bahwa setiap informasi atau bukti palsu dapat dianggap sebagai tindakan kriminal dan diteruskan ke polisi atau lembaga penegak hukum lainnya untuk ditindaklanjuti.",
    labelFraudWarning2: "Jika kami mencurigai adanya penipuan terkait dengan dokumentasi pendukung, hal ini akan segera dirujuk ke pihak kepolisian setempat. Saya menerima dan memahami rincian saya akan diteruskan ke pihak berwenang ini jika aplikasi saya dianggap penipuan dan dapat menjadi penyelidikan kriminal.",
    validationTermsAndConditions: "Syarat dan Ketentuan harus disetujui",
    messageFailbankName: "Nama Bank tidak boleh kosong",
    messageFailcustomerName: "Nama Pelanggan tidak boleh kosong",
    messageFailbankAccountNumber: "Bidang Wajib",
    messageFailbankAccountNumberFormat: "Harus dalam format IBAN",
    messageFailsortCode: "Bidang Wajib",
    messageFailbankSwiftBic: "Penerima SWIFT BIC tidak boleh kosong",
    messageFailbankSwiftBicFormat: "Harus dalam format SWIFT",
    messageFailbeneficiaryAddress1: "Alamat 1 tidak boleh kosong",
    messageFailbeneficiaryCity: "Kota tidak boleh kosong",
    messageFailbeneficiaryPostCode: "Kode Pos tidak boleh kosong",
    messageFailremittanceLine3: "Bidang Wajib",
    messageFailremittanceLine4: "Bidang Wajib",
    modalHeading: "Konfirmasi Rincian Bank",
    modalLabelPleaseConfirmYourDetails: "Silakan periksa detail bank Anda dan konfirmasi.",
    modalValidationPleaseConfirm: "Harap konfirmasi",
    modalValidationMustMatch: "Harus cocok dengan entri sebelumnya",
    tooltipBankAccountName: "Nama Anda seperti yang tertera di rekening bank / rekening koran / kartu bank Anda.",
    tooltipBankAccountNameRecipient: "Nama depan dan belakang penerima. Nama depan dan nama belakang harus memiliki lebih dari satu karakter.",
    tooltipBankCountry: "Negara tempat bank Anda berada.",
    tooltipPaymentType: "Jenis pembayaran yang Anda butuhkan.",
    tooltipInstitutionNumber: "Ini adalah 3 digit dan biasanya ditemukan pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankName: "Nama bank Anda.",
    tooltipBankAccountNumber: "Nomor rekening pribadi Anda. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankAccountNumberCBU: "Nomor 22 digit yang digunakan untuk mengidentifikasi akun Anda. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankAccountNumberCCI: "Nomor 20 digit yang digunakan untuk mengidentifikasi akun Anda. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankAccountNumberKRW: "Nomor 11–16 digit yang digunakan untuk mengidentifikasi akun Anda. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCardNumber: "Nomor kartu Anda. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipUnionpay: "Nomor kartu UnionPay Anda.",
    tooltipAlipay: "Nomor ponsel atau alamat email Anda yang terkait dengan akun Alipay Anda.",
    tooltipBankAccountType: "Ini adalah Cek atau Tabungan.",
    tooltipSortCode: "Ini mengidentifikasi cabang bank Anda tempat rekening Anda berada. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipRoutingCode: "Ini mengidentifikasi cabang bank Anda tempat rekening Anda berada. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBeneficiarySWIFTBIC: "Terdiri dari 8-11 karakter yang digunakan untuk mengidentifikasi bank dalam transaksi internasional. Biasanya terdapat pada laporan bulanan Anda atau jika Anda masih ragu, tanyakan pada bank Anda.",
    tooltipBSB: "Nomor Bank State Branch (BSB) adalah kode sortir Australia yang terdiri dari 6 digit. Biasanya terdapat pada laporan bulanan Anda atau jika Anda masih ragu, tanyakan pada bank Anda.",
    tooltipRoutingCodeBSB: "Ini mengidentifikasi cabang bank Anda tempat rekening Anda berada. Biasanya terdapat pada laporan bulanan atau kartu bank Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipABAFedwireRoutingCode: "Angka 9 digit yang digunakan untuk mengidentifikasi bank di Amerika. Biasanya terdapat pada laporan bulanan Anda atau jika Anda masih ragu, tanyakan pada bank Anda.",
    tooltipIFSCCode: "Biasanya kode ini dapat ditemukan di laporan bulanan Anda atau di buku tabungan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipTermsAndConditions: "Dengan menerima persyaratan dan ketentuan, Anda mengonfirmasi bahwa Anda telah membaca dan menyetujui Persyaratan dan Ketentuan kami.",
    tooltipIBAN: "Nomor Rekening Bank Internasional Anda, yang dapat Anda gunakan saat melakukan atau menerima pembayaran internasional. Biasanya terdapat pada laporan bulanan Anda atau jika Anda masih ragu, tanyakan pada bank Anda.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nama Kontak dan Nomor Telepon Penerima.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Tanggal Penerima Manfaat dan Tempat Lahir.",
    tooltipBankRoutingCodeNationalID: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankTransitCodeSwift: "Ini adalah 5 digit dan biasanya dapat ditemukan pada laporan mutasi bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan kepada bank Anda.",
    tooltipBankAddress: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankAddress2: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankCity: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankState: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBranchName: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBankCode: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBIKCode: "9 digit untuk mengidentifikasi bank Anda.",
    tooltipBranchCode: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBranchCodeUniversal: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCNAPSCode: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipRemittanceLine4: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCUILNumber: "Ini adalah angka 11 digit, terdiri dari dua digit, tanda hubung, sembilan digit, dan checksum satu digit.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCFPNumber: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipBranchNameorBranchCode7digits: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCedulaJuridica910digitsTaxID: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipTaxIDRUT89digits: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipJob: "Pekerjaan Anda saat ini.",
    tooltipDateOfBirth: "Tanggal lahirmu.",
    tooltipNationality: "Kebangsaan Anda.",
    tooltipDocumentType: "Jenis dokumen yang diperlukan.",
    tooltipDocumentNumber: "Ini adalah nomor dokumen yang terkait dengan pemilihan jenis dokumen Anda.",
    tooltipRutNumber: "Nomor RUT Anda (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipCIIndividualTaxIDBranchLocation: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    tooltipTaxIDRUCorCI: "Biasanya dapat ditemukan pada laporan bank bulanan Anda atau jika Anda masih tidak yakin, tanyakan pada bank Anda.",
    errorCPFCode: "Saat ini kami hanya menerima CPF sebagai kunci PIX."
};