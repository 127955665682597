import * as React from 'react'
import {
    Backdrop,
    CircularProgress
} from '@mui/material';
import {useRecoilValue} from 'recoil';
import {loadingShimState} from '../../state/activity';

export const LoadingShim = () => {
    
    const loadingShim = useRecoilValue(loadingShimState);

    return (
        <Backdrop sx={{ zIndex: 2000 }} open={loadingShim.open}>
            <CircularProgress />
        </Backdrop>
    );
}