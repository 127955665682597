export default {
    labelBookingCancelledPostponedHeading: "¿Su reserva ha sido cancelada o pospuesta? Leer más",
    labelBookingCancelledPostponedText1: "Hacer clic",
    labelBookingCancelledPostponedText2: "para más información.",
    labelNewRefundHeading: "¿Nueva solicitud de reembolso?",
    labelNewRefundText: "Introduzca su referencia de reserva aquí...",
    placeholderBookingReference: "Referencia de la reserva",
    validationBookingReference: "Se requiere una referencia de reserva",
    validationEmailAsBookingReference: "La dirección de correo electrónico no se acepta como referencia de la reserva.",
    buttonFindBooking: "Encuentra la reserva",
    labelEmailHeading: "¿Ya comenzó?",
    labelEmailText: "Envíe un correo electrónico recordatorio para recuperar su solicitud.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Recordatorio de correo electrónico",
    labelCustomerName : "Nombre del cliente",
    validationCustomerName: "Se requiere el nombre del cliente",
    labelTypeOfBooking: "Tipo de reserva",
    validationTypeOfBooking: "Se requiere un tipo de reservación",
    labelEventName:"Nombre del evento",
    labelVenueName:"Nombre del lugar",
    labelFlightNumber:"Número de vuelo",
    labelOriginAirport: "Aeropuerto de origen",
    labelHotelName: "Nombre del alojamiento",
    labelHotelLocation: "Ubicación del alojamiento",
    labelCurrency: "Moneda",
    validationCurrency: "La moneda es necesaria",
    validationCountryCurrency: "La moneda seleccionada debe ser la moneda de su país de origen",
    validationOfflinePayment: "No podemos procesar reembolsos para su selección de moneda y país, seleccione alternativas",
    labelBookingValue: "Valor de la reserva",
    validationBookingValue: "Se requiere un valor de reserva",
    validationBookingValueAmount: "Debe ser mayor que 0",
    labelDateOfPurchase: "Fecha de compra",
    validationDateOfPurchase: "Se requiere la fecha de compra",
    labelEmailAddress: "Dirección de correo electrónico",
    validationEmailAddress: "Se requiere una dirección de correo electrónico",
    validationEmailAddressFormat: "Formato incorrecto del correo electrónico",
    labelConfirmEmailAddress: "Confirme la dirección de correo electrónico",
    validationConfirmEmailAddressFormat: "Formato incorrecto del correo electrónico",
    validationConfirmEmailAddress: "Se requiere una dirección de correo electrónico de confirmación",
    validationConfirmEmailAddressMatch: "Las direcciones de correo electrónico no coinciden",
    validationEmailDomain: "Este proveedor de correo electrónico no es aceptado, utilice uno alternativo",
    validationDateOfEvent: "Se requiere la fecha del evento",
    labelHomeCountry: "País de origen",
    validationHomeCountry: "Se requiere el país de origen",
    labelContactNumberCountryCode: "Número de contacto Código de país",
    validationContactNumberCountryCode: "Se requiere el código de marcación del país",
    labelContactNumber: "Número de contacto",
    validationContactNumber: "Se requiere un número de contacto",
    labelAddressLine1: "Dirección Línea 1",
    labelAddressLine2: "Dirección Línea 2",
    labelCity: "Ciudad",
    labelZipCode: "Código postal",
    labelRegion: "Región",
    validationRegion: "Se requiere una región",
    labelNumberOfTicketsToRefund: "Número de billetes a reembolsar",
    validationNumberOfTicketsToRefund: "Se requiere un número de boletos de reembolso",
    validationNumberOfTicketsToRefundValue: "Debe ser mayor que 0",
    labelCostPerTicket: "El costo de cada boleto",
    validationCostPerTicket: "Cost per Ticket is required",
    validationCostPerTicketValue: "Debe ser mayor que 0",
    labelTotalRefundAmount: "Cantidad total de reembolso",
    validationTotalRefundAmount: "Se requiere una cantidad total de reembolso",
    validationTotalRefundAmountValue: "Debe ser mayor que 0",
    validationTotalRefundGreaterThanBookingValue: "El monto total del reembolso no puede ser mayor que el valor de la reserva anterior",
    labelBookingConfirmationFiles: "Subir Confirmación de reserva",
    validationBookingConfirmationFiles: "Adjunte al menos un archivo o haga clic en Guardar y volver más tarde cuando tenga los documentos",
    validationEvidenceFilesPopup: "Se requiere que se carguen las pruebas antes de enviarlas. Si no tiene las pruebas en este momento, guarde la solicitud y devuélvala una vez que la tenga. Aceptamos copias electrónicas, copias de escáner y fotografías.",
    validationBookingConfirmationFilesPopup: "Es necesario cargar la confirmación de la reserva antes de enviarla. Si no tiene la confirmación de la reserva en este momento, guarde la solicitud y devuélvala una vez que la tenga. Aceptamos copias electrónicas, copias de escáner y fotos.",
    alertUploadDocuments: "Cargue la documentación relevante detallada en el correo electrónico y luego continúe con el Paso 3.",
    labelRefundReasonCategory: "Razón del reembolso",
    validationRefundReasonCategory: "Se requiere una razón de reembolso",
    labelDateOfIncident: "Fecha del incidente",
    labelAdditionalComments: "Comentarios adicionales",
    tooltipAdditionalComments: "Comentarios adicionales",
    validationAdditionalComments: "Se requieren comentarios adicionales",
    labelUnableToNotifyWithinTimeLimit: "No se puede notificar dentro de 60 días a partir de",
    labelReasonForNotificationOutsideTimeLimit: "Notificación fuera del límite de tiempo Explicación",
    validationReasonForNotificationOutsideTimeLimit: "Se requiere un motivo de notificación después de 60 días",
    labelRefundReasonFiles: "Subir las pruebas",
    validationRefundReasonFiles: "Adjunte al menos un archivo o haga clic en Guardar y volver más tarde cuando tenga los documentos",
    validationTextScript: "Debe coincidir con la escritura latina",
    optionTypeOfBooking1: "Evento",
    optionTypeOfBooking2: "Gira",
    optionTypeOfBooking3: "Vuelo",
    optionTypeOfBooking4: "Alojamiento",
    optionTypeOfBooking5: "Transferencia",
    optionTypeOfBooking6: "Aparcamiento",
    optionTypeOfBooking7: "Otros viajes",
    labelEventTypeDate: "Fecha del evento",
    labelEventTypeTour: "Fecha de la gira",
    labelEventTypeFlight: "Fecha de vuelo",
    labelEventTypeCheckIn: "Fecha de registro",
    labelEventTypeTransfer: "Fecha de la transferencia",
    labelEventTypeParking: "Fecha de estacionamiento",
    labelEventTypeOtherTravel: "Fecha de viaje",
    optionRefundReason11: "Tiempo adverso",
    optionRefundReason10: "Retiro de las Fuerzas Armadas y Servicios de Emergencia",
    optionRefundReason15: "Cambios en las fechas de examen",
    optionRefundReason18: "Citación judicial",
    optionRefundReason17: "Restricciones para viajar por el COVID-19",
    optionRefundReason19: "Prueba positiva de COVID-19",
    optionRefundReason20: "Reserva cancelada por la operadora / organizadora",
    optionRefundReason21: "Reserva reprogramada por la operadora / organizadora",
    optionRefundReason22: "Fecha incorrecta reservada",
    optionRefundReason23: "Billetes reservados equivocados",
    optionRefundReason24: "Reservó demasiados boletos",
    optionRefundReason4: "Muerte",
    optionRefundReason16: "Circunstancias de emergencia",
    optionRefundReason14: "Prohibición de viajes del gobierno",
    optionRefundReason9: "Emergencia en el hogar",
    optionRefundReason1: "Enfermedad/lesión/accidente",
    optionRefundReason8: "Servicio de jurado",
    optionRefundReason7: "Avería mecánica",
    optionRefundReason2: "Condición médica preexistente",
    optionRefundReason3: "Complicaciones del embarazo",
    optionRefundReason5: "Fallo del transporte público",
    optionRefundReason6: "Interrupción del vuelo",
    optionRefundReason13: "Robo de documento (s)",
    optionRefundReason12: "Reubicación laboral",
    optionRefundReason25: "Despido laboral",
    optionRefundReason26: "Opción de prueba",
    optionRefundReasonConfirm1: "No",
    optionRefundReasonConfirm2: "Sí",
    optionRefundReasonArmedForces1: "Ejército",
    optionRefundReasonArmedForces2: "Marina",
    optionRefundReasonArmedForces3: "Fuerza aérea",
    optionRefundReasonArmedForces4: "Fuerzas Especiales",
    optionRefundReasonArmedForces5: "Infantería de marina",
    optionRefundReasonArmedForces6: "Fuego",
    optionRefundReasonArmedForces7: "Ambulancia",
    optionRefundReasonArmedForces8: "Médico/Enfermera",
    optionRefundReasonArmedForces9: "Dentista",
    optionRefundReasonArmedForces10: "Otro",
    optionRefundReasonCourtSummons1: "Persecución penal",
    optionRefundReasonCourtSummons2: "Caso Legal Civil",
    optionRefundReasonCourtSummons3: "Testigo en una acusación",
    optionRefundReasonCourtSummons4: "Testigo en un caso civil",
    optionRefundReasonCourtSummons5: "Demandante",
    optionRefundReasonCourtSummons6: "Acusado",
    optionRefundReasonCourtSummons7: "Otro",
    optionRefundReasonDeath1: "Marido",
    optionRefundReasonDeath2: "Esposa",
    optionRefundReasonDeath3: "Sonido",
    optionRefundReasonDeath4: "Hija",
    optionRefundReasonDeath5: "Padre",
    optionRefundReasonDeath6: "Madre",
    optionRefundReasonDeath7: "Hermano",
    optionRefundReasonDeath8: "Hermana",
    optionRefundReasonDeath9: "Abuelo",
    optionRefundReasonDeath10: "Abuela",
    optionRefundReasonDeath11: "Otro",
    optionRefundReasonHomeEmergency1: "Fuego",
    optionRefundReasonHomeEmergency2: "Inundación",
    optionRefundReasonHomeEmergency3: "Robo",
    optionRefundReasonHomeEmergency4: "Vandalismo",
    optionRefundReasonHomeEmergency5: "Abuso doméstico",
    optionRefundReasonHomeEmergency6: "Secuestrar",
    optionRefundReasonHomeEmergency7: "Otro",
    optionRefundReasonCOVID19Person1: "Yo mismo",
    optionRefundReasonCOVID19Person2: "Otra persona en la reserva",
    optionRefundReasonCOVID19Person3: "Una persona en mi hogar",
    optionRefundReasonCOVID19Person4: "Otro",
    optionRefundReasonCOVID19TestType1: "Prueba PCR",
    optionRefundReasonCOVID19TestType2: "Prueba RAT",
    optionRefundReasonCOVID19TestType3: "Prueba de flujo lateral",
    optionRefundReasonCOVID19TestType4: "Prueba autoadministrada",
    optionRefundReasonCOVID19TestType5: "No hay prueba pero tengo síntomas",
    optionRefundReasonCOVID19TestType6: "Otro",
    optionRefundReasonBreakdown1: "Desintegración",
    optionRefundReasonBreakdown2: "Accidente",
    optionRefundReasonBreakdown3: "Fuego",
    optionRefundReasonBreakdown4: "Robo",
    optionRefundReasonPublicTransportType1: "Tren",
    optionRefundReasonPublicTransportType2: "Autobús",
    optionRefundReasonPublicTransportType3: "Tranvía",
    optionRefundReasonPublicTransportType4: "Bote",
    optionRefundReasonDocumentType1: "Pasaporte",
    optionRefundReasonDocumentType2: "Permiso de viaje",
    optionRefundReasonDocumentType3: "Documento Nacional de Identidad",
    optionRefundReasonDocumentType4: "Entradas",
    optionRefundReasonWorkRelocationType1: "Temporal",
    optionRefundReasonWorkRelocationType2: "Permanente",
    optionRefundReasonWorkRelocationTemporary1: "0-3 meses",
    optionRefundReasonWorkRelocationTemporary2: "3-6 meses",
    optionRefundReasonWorkRelocationTemporary3: "Más de 6 meses",
    optionRefundReasonBookingRelationshipType1: "Alguien en la reserva",
    optionRefundReasonBookingRelationshipType2: "Pariente inmediato de alguien en la reserva",
    optionEmploymentType1: "Dedicación plena",
    optionEmploymentType2: "Tiempo parcial",
    optionEmploymentType3: "Contrato",
    optionEmploymentLengthType1: "0-1 años",
    optionEmploymentLengthType2: "1-2 años",
    optionEmploymentLengthType3: "Más de 2 años",
    textRefundEvidenceHeading: "Sírvase proporcionar las siguientes pruebas:",
    textRefundEvidence1: "Confirmación de la aerolínea",
    textRefundEvidence2: "Cualquier evidencia de apoyo apropiada",
    textRefundEvidence3: "Confirmación de llamada del servicio de avería",
    textRefundEvidence4: "Certificado de defunción",
    textRefundEvidence5: "Confirmación de cancelación de la aerolínea",
    textRefundEvidence6: "Pronóstico del tiempo",
    textRefundEvidence7: "Correspondencia del agente de reservas que confirma que no hay capacidad para volver a emitir boletos",
    textRefundEvidence8: "Carta de la Corte",
    textRefundEvidence9: "Certificado Médico / Nota del Médico",
    textRefundEvidenceDescription9: "Confirmar los detalles de la enfermedad o lesión, la fecha en que ocurrió por primera vez, que le impide asistir a la Reserva.",
    textRefundEvidence10: "Confirmación de la carta del empleador",
    textRefundEvidence11: "Correspondencia de reclamaciones de seguros",
    textRefundEvidence12: "Carta del empleador",
    textRefundEvidence13: "Confirmación del sitio web del Gobierno Nacional",
    textRefundEvidence14: "Aviso de cambio de examen",
    textRefundEvidence15: "Informe policial",
    textRefundEvidence16: "Informe policial y número de referencia",
    textRefundEvidence17: "Confirmación del proveedor de transporte",
    textRefundEvidence18: "Advertencia de viaje",
    textRefundEvidence20: "Certificado de prueba COVID-19",
    textRefundEvidence24: "Aviso de aislamiento del gobierno",
    textRefundEvidence25: "Carta del oficial al mando",
    textRefundEvidence26: "Carta de las aseguradoras",
    textRefundEvidence27: "Comprobante de domicilio",
    textRefundEvidence28: "Prueba de relación con el fallecido",
    textRefundEvidence29: "Prueba de relación con la persona lesionada/enferma",
    textRefundEvidence30: "Recibo de nómina",
    tooltipBookingReference: "Para identificar su referencia de reserva, consulte la confirmación de su reserva y consulte la sección sobre nuestro servicio ampliado.",
    tooltipCustomerName: "Nombre y apellido que se utilizó para hacer la reserva.",
    tooltipTypeOfBooking: "Tipo de reserva que le gustaría que le reembolsaran.",
    tooltipEventName: "El nombre del evento.",
    tooltipVenueName: "El nombre del lugar.",
    tooltipFlightNumber: "El número de vuelo.",
    tooltipOriginAirport: "El aeropuerto de salida.",
    tooltipHotelName: "El nombre del alojamiento.",
    tooltipHotelLocation: "La ubicación del alojamiento.",
    tooltipCurrency: "La moneda utilizada para pagar la reserva.",
    tooltipBookingValue: "Precio total que pagó por la reserva.",
    tooltipDateOfPurchase: "La fecha en que se realizó la reserva.",
    tooltipEmailAddress: "Por favor utilice una dirección de correo electrónico válida. Usamos este correo electrónico para la correspondencia durante la solicitud de reembolso.",
    tooltipConfirmEmailAddress: "Utilice la misma dirección de correo electrónico que la anterior, esto es para validación.",
    tooltipHomeCountry: "Esto nos ayudará a obtener la información bancaria correcta en el paso final.",
    tooltipContactNumberCountryCode: "Código de país del número de contacto.",
    tooltipContactNumber: "Utilice un número válido. Usamos esto para la correspondencia durante la solicitud de reembolso.",
    tooltipAddressLine1: "Tenga en cuenta que su dirección se utilizará al validar su cuenta bancaria.",
    tooltipAddressLine2: "Segunda línea de dirección.",
    tooltipCity: "Su ciudad de residencia, los detalles se pueden utilizar para validar los datos bancarios.",
    tooltipZipCode: "Código postal / Código postal.",
    tooltipRegion: "Región de su ciudad de origen, los detalles se pueden utilizar para validar los datos bancarios.",
    tooltipNumberOfTicketsToRefund: "La cantidad de boletos que le gustaría que le reembolsaran.",
    tooltipTotalRefundAmount: "Este no puede ser más que el valor total de la reserva, no podrá enviar la solicitud si lo hace.",
    tooltipBookingConfirmationFiles: "Captura de pantalla / archivo adjunto de la confirmación completa de la reserva que generalmente se puede encontrar en sus correos electrónicos.",
    tooltipRefundReasonCategory: "Seleccione el motivo del reembolso.",
    tooltipDateOfIncident: "La fecha en la que ocurrió el incidente que afectó su reserva.",
    tooltipRefundExplanation: "Proporcione tantos detalles como sea posible que respalden el reclamo de reembolso.",
    tooltipUnableToNotifyWithinTimeLimit: "No se puede notificar dentro del límite de tiempo de 60 días.",
    tooltipReasonForNotificationOutsideTimeLimit: "Notificación fuera del límite de tiempo Explicación.",
    tooltipRefundReasonFiles: "Cargue cualquiera de los documentos requeridos.",
    tooltipEventTypeDate: "Fecha del evento / reserva real.",
    tooltipEventTypeTour: "Fecha de la gira real.",
    tooltipEventTypeFlight: "Fecha del vuelo real.",
    tooltipEventTypeCheckIn: "Fecha del check in real.",
    tooltipEventTypeTransfer: "Fecha de la transferencia real.",
    tooltipEventTypeParking: "Fecha del estacionamiento real.",
    tooltipEventTypeOtherTravel: "Fecha de inicio del viaje real."
};