import * as React from "react";
import {Box} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useField} from "formik";
import {IFormValues} from "../Interfaces/IFormValues";
import {nameof} from "ts-simple-nameof";
import {getCurrencySymbol} from "../utils/helpers";
import {useSetRecoilState} from "recoil";
import {RefundAmountConfirmationModalCounterState} from "../../state/activity";
import {CloseButton} from '../buttons/CloseButton';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {ModalHolder} from './ModalHolder';

interface ConfirmRefundAmountModalProps {
    formValues: IFormValues;
    handleClose: () => void;
    showModal: boolean;
}

export const ConfirmRefundAmountModal = (props: ConfirmRefundAmountModalProps) => {

    const {
        formValues,
        showModal,
        handleClose
    } = props;

    const {t} = useTranslation();
    const setRefundAmountConfirmationModalCounter = useSetRecoilState(RefundAmountConfirmationModalCounterState);

    const [isRefundAmountConfirmedField, isRefundAmountConfirmedMeta, isRefundAmountConfirmedHelper] =
        useField(nameof<IFormValues>(x => x.isRefundAmountConfirmed));

    const handler = (confirm: boolean) => () => {
        setRefundAmountConfirmationModalCounter(curr => curr + 1);
        void isRefundAmountConfirmedHelper.setValue(confirm);
    }

    const onClose = () => {
        handler(false);
        handleClose();
    }

    const onConfirm = () => {
        handler(true);
        handleClose();
    }

    const amountWording = `${getCurrencySymbol(formValues.currency)}${formValues.totalRefundAmount}`;

    const Content = () => {
        return (<>
                <Box pb={1}>
                    <Box>
                        {t('confirmRefundAmountModal:textPrefixAmount')} {
                        <b>{amountWording}</b>} {t('confirmRefundAmountModal:textPostfixAmount')}
                    </Box>
                    <Box>
                        {t('confirmRefundAmountModal:textSuccessAmount')}
                    </Box>
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonCancel')}
                        handleOnClick={onClose}
                    />

                    <ConfirmButton
                        buttonText={t('common:buttonConfirm')}
                        handleOnClick={onConfirm}
                        isSubmitButton={false}
                    />
                </Box>
            </>
        );
    }

    return (
        <ModalHolder
            content={<Content/>}
            showModal={showModal}
            handleClose={onClose}
            width='md'
        />
    );
}