import * as React from 'react';
import {
    CssBaseline,
    createTheme,
    ThemeProvider
} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import {
    QueryClient,
    QueryClientProvider
} from 'react-query';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import {Main} from './components/form/Main';
import {FormProvider} from './components/form/providers/FormProvider';
import {QuestionProvider} from './components/form/providers/QuestionProvider';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RecoilRoot} from 'recoil';
import {LoadingShim} from './components/form/ui/LoadingShim';
import './i18next';
import {MSClarityScript} from './ms-clarity/MSClarity';
import axios from 'axios';
import {ValidationProvider} from './components/form/providers/ValidationProvider';
import './custom.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const urlParams = new URLSearchParams(window.location.search.toLocaleLowerCase());
const memberId = urlParams.get('memberid');
const root = createRoot(rootElement);
const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
const Start = () => <Main memberId={memberId} url={window.location.href}/>

const theme = createTheme({
    typography: {
        fontFamily: 'Inter',
        fontWeightBold: 500
    }
});

root.render(
    <ThemeProvider theme={theme}>
        <RecoilRoot>
            <LoadingShim/>
            <MSClarityScript/>
            <React.Fragment>
                <CssBaseline/>
                <React.StrictMode>
                    <BrowserRouter>
                        <FormProvider>
                            <ValidationProvider>
                                <QuestionProvider>
                                    <QueryClientProvider client={queryClient}>
                                        <Routes>
                                            <Route path="/forms/refund" element={<Start/>}/>
                                            <Route path="/" element={<Start/>}/>
                                        </Routes>
                                    </QueryClientProvider>
                                </QuestionProvider>
                            </ValidationProvider>
                        </FormProvider>
                    </BrowserRouter>
                    <ToastContainer autoClose={5000}/>
                </React.StrictMode>
            </React.Fragment>
        </RecoilRoot>
    </ThemeProvider>);