export default {
    headingAdverseWeather: "Bạn gặp phải điều kiện thời tiết bất lợi nghiêm trọng khiến bạn không an toàn khi đi du lịch đến sự kiện và điều này đã được ủy quyền bởi Một Cơ quan Chính phủ (ví dụ: Chính quyền địa phương, Cảnh sát, v.v.). Và bạn có bằng chứng để chứng minh điều này?",
    headingAdverseWeatherDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    tooltipRefundReasonConfirm: "Có hay không",
    labelBookingCancelledByOperator: "Vé đã bị hủy bởi Nhà điều hành / Ban tổ chức.",
    labelBookingRescheduledByOperator: "Ngày đã được thay đổi bởi Nhà điều hành / Ban tổ chức và tôi không thể tham dự các ngày mới.",
    labelBookedWrongDate: "Tôi hoặc một thành viên của đặt chỗ, đã mắc lỗi khi mua vé và đặt sai ngày.",
    labelBookedWrongTickets: "Tôi hoặc một thành viên của đặt chỗ, đã mắc lỗi khi mua vé và đặt sai vé.",
    labelBookedTooManyTickets: "Tôi hoặc một thành viên của đặt chỗ, đã mắc lỗi khi mua vé và đặt quá nhiều vé.",
    labelCOVID19TravelRestrictions: "Tôi hoặc một thành viên của đặt chỗ, không thể tham dự do lệnh cấm du lịch COVID-19.",
    labelADWrongSelection: "Có thể bạn đã chọn sai lý do hoàn lại tiền. Vui lòng xem lại danh sách thả xuống Lý do hoàn tiền một lần nữa.",
    labelAdverseWeather1: "Tại sao thời tiết bất lợi có nghĩa là bạn không thể tham dự sự kiện?",
    tooltipAdverseWeather1: "Vui lòng chi tiết",
    labelAdverseWeather2: "Ngày xảy ra sự cố",
    tooltipAdverseWeather2: "Vui lòng chọn",
    headingArmedForces: "Bạn được yêu cầu bởi chủ lao động của bạn là Lực lượng Vũ trang hoặc Dịch vụ Khẩn cấp để đi vào làm việc khi bạn ban đầu không được lên kế hoạch để làm như vậy và điều này ngăn cản bạn tham dự sự kiện?",
    headingArmedForcesDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelArmedForces1: "Bạn đang sử dụng lực lượng hoặc dịch vụ khẩn cấp nào?",
    tooltipArmedForces1: "Vui lòng chọn",
    labelArmedForcesOther1: "Vui lòng chỉ định",
    tooltipArmedForcesOther1: "Vui lòng chỉ định",
    labelArmedForces2: "Ngày xảy ra sự cố",
    tooltipArmedForces2: "Vui lòng chọn",
    headingChangesToExam: "Ngày kiểm tra của bạn đã được thay đổi bởi trường học / trường đại học của bạn, vv? Đây không phải là một kỳ thi thất bại mà bạn phải resit?",
    headingChangesToExamDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelExam1: "Ngày kiểm tra ban đầu là gì?",
    tooltipExam1: "Vui lòng chọn",
    labelExam2: "Ngày thi mới là gì?",
    tooltipExam2: "Vui lòng chọn",
    labelExam3: "Tại sao ngày thi lại thay đổi?",
    tooltipExam3: "Vui lòng nhập chi tiết",
    labelExam4: "Bạn đã trượt kỳ thi trước đó?",
    tooltipExam4: "Có hay không",
    headingCourtSummons: "Bạn đã được triệu tập để xuất hiện tại tòa án với tư cách là nhân chứng vào cùng ngày với sự kiện?",
    headingCourtSummonsDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelCourtSummons1: "Lý do triệu tập tòa án của bạn là gì?",
    tooltipCourtSummons1: "Vui lòng chọn",
    labelCourtSummonsOther1: "Vui lòng chỉ định",
    tooltipCourtSummonsOther1: "Vui lòng chỉ định",
    labelCourtSummons2: "Giấy triệu tập của tòa án được ban hành khi nào?",
    tooltipCourtSummons2: "Vui lòng chọn",
    headingCOVID19Test: "Tôi, một thành viên trong gia đình tôi hoặc một thành viên của nhóm tham dự sự kiện đã được chẩn đoán mắc COVID-19?",
    headingCOVID19TestDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelCOVID19Test1: "Ai mắc COVID-19?",
    tooltipCOVID19Test1: "Vui lòng chọn",
    labelCOVID19TestOther1a: "Vui lòng chỉ định",
    labelCOVID19TestOther1b: "Chỉ rõ tên",
    tooltipCOVID19TestOther1a: "Vui lòng chỉ định",
    tooltipCOVID19TestOther1b: "Chỉ rõ tên",
    labelCOVID19Test2: "Bạn đã có loại xét nghiệm COVID-19 nào?",
    tooltipCOVID19Test2: "Vui lòng chọn",
    labelCOVID19TestOther2: "Vui lòng chỉ định",
    tooltipCOVID19TestOther2: "Vui lòng chỉ định",
    labelCOVID19Test3: "Ngày xét nghiệm COVID-19 của bạn là gì?",
    tooltipCOVID19Test3: "Vui lòng chọn",
    headingDeath: "Đã có một cái chết của một thành viên ngay lập tức trong gia đình tôi và nó ngăn cản tôi tham dự sự kiện này?",
    headingDeathDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelDeath1: "Thành viên nào trong gia đình bạn đã chết?",
    tooltipDeath1: "Vui lòng chọn",
    labelDeathOther1: "Vui lòng chỉ định",
    tooltipDeathOther1: "Vui lòng chỉ định",
    labelDeath2: "Ngày chết là khi nào?",
    tooltipDeath2: "Vui lòng chọn",
    labelDeath3: "Tên của người đã khuất",
    tooltipDeath3: "Vui lòng chỉ định",
    headingEmergency: "Tôi đã có một trường hợp khẩn cấp thực sự nằm ngoài tầm kiểm soát của tôi và không lường trước được mà tôi có thể cung cấp bằng chứng?",
    headingEmergencyDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelEmergency1: "Xin ông giải thích về tình trạng khẩn cấp?",
    tooltipEmergency1: "Vui lòng chi tiết",
    labelEmergency2: "Chuyện này xảy ra khi nào?",
    tooltipEmergency2: "Vui lòng chọn",
    labelEmergency3: "Tại sao tình huống này ngăn cản bạn tham dự sự kiện?",
    tooltipEmergency3: "Vui lòng chi tiết",
    labelEmergency4: "Bạn có bằng chứng về tình trạng khẩn cấp này không?",
    tooltipEmergency4: "Có hay không",
    headingHomeEmergency: "Tôi có một trường hợp khẩn cấp tại nhà đó là Cướp, Lũ lụt, Hỏa hoạn, Phá hoại, Lạm dụng gia đình ngăn cản tôi tham dự sự kiện?",
    headingHomeEmergencyDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelHomeEmergency1: "Trường hợp khẩn cấp tại nhà là gì?",
    tooltipHomeEmergency1: "Vui lòng chọn",
    labelHomeEmergencyOther1: "Vui lòng chỉ định",
    tooltipHomeEmergencyOther1: "Vui lòng chỉ định",
    labelHomeEmergency2: "Chuyện này xảy ra khi nào?",
    tooltipHomeEmergency2: "Vui lòng chọn",
    labelHomeEmergency3: "Tại sao nó ngăn cản bạn tham dự sự kiện?",
    tooltipHomeEmergency3: "Vui lòng chi tiết",
    headingInjury: "Tôi, một thành viên trong gia đình tôi hoặc một thành viên của nhóm tham dự sự kiện đã được chẩn đoán mắc một tình trạng y tế hoặc chấn thương mà bây giờ ngăn cản chúng tôi đi đến sự kiện?",
    headingInjuryDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelInjury1: "Bản chất của bệnh tật / thương tích / tai nạn là gì?",
    tooltipInjury1: "Vui lòng chi tiết",
    labelInjury2: "Chuyện này xảy ra khi nào?",
    tooltipInjury2: "Vui lòng chọn",
    labelInjury3: "Bạn đã đến cơ sở y tế cho bệnh / chấn thương này chưa?",
    tooltipInjury3: "Có hay không",
    labelInjury4: "Đây có phải là một tình trạng y tế đã có từ trước?",
    tooltipInjury4: "Có hay không",
    labelInjury5: "Lần đầu tiên được chẩn đoán là khi nào?",
    tooltipInjury5: "Vui lòng chọn",
    labelInjury6: "Tại sao điều này có nghĩa là bạn không thể tham dự sự kiện?",
    tooltipInjury6: "Vui lòng chi tiết",
    labelInjury7: "Ai đã bị bệnh/chấn thương?",
    tooltipInjury7: "Vui lòng chọn",
    headingJuryService: "Tôi hoặc một thành viên của đặt phòng đã được gọi đến Dịch vụ Bồi thẩm đoàn?",
    headingJuryServiceDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelJuryService1: "Khi nào bạn được thông báo về dịch vụ bồi thẩm đoàn?",
    tooltipJuryService1: "Vui lòng chọn",
    headingMechanicalBreakdown: "Chiếc xe của chúng tôi đã bị hỏng hóc cơ học hoặc gặp tai nạn và điều này ngăn cản chúng tôi tham dự sự kiện?",
    headingMechanicalBreakdownDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelMechanicalBreakdown1: "Chuyện gì đã xảy ra với chiếc xe?",
    tooltipMechanicalBreakdown1: "Vui lòng chọn",
    labelMechanicalBreakdown2: "Chuyện này xảy ra khi nào?",
    tooltipMechanicalBreakdown2: "Vui lòng chọn",
    labelMechanicalBreakdown3: "Tại sao bạn không thể đi phương tiện giao thông thay thế?",
    tooltipMechanicalBreakdown3: "Vui lòng chi tiết",
    headingPregnancyComplication: "Tôi hoặc một thành viên của đặt phòng có biến chứng mang thai đã được chẩn đoán bởi một chuyên gia y tế và do đó ngăn chặn tham dự sự kiện?",
    headingPregnancyComplicationDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelPregnancyComplication1: "Bản chất của biến chứng thai kỳ là gì?",
    tooltipPregnancyComplication1: "Vui lòng chi tiết",
    labelPregnancyComplication2: "Chuyện này xảy ra khi nào?",
    tooltipPregnancyComplication2: "Vui lòng chọn",
    labelPregnancyComplication3: "Bạn đã tìm kiếm sự chăm sóc y tế?",
    tooltipPregnancyComplication3: "Có hay không",
    labelPregnancyComplication4: "Tại sao điều này có nghĩa là bạn không thể tham dự sự kiện?",
    tooltipPregnancyComplication4: "Vui lòng chi tiết",
    headingPublicTransportFailure: "Phương pháp giao thông công cộng (Xe lửa, Xe buýt, Xe điện, Thuyền, v.v.) bất ngờ thất bại có nghĩa là không có cách nào khác tôi có thể đi đến sự kiện này?",
    headingPublicTransportFailureDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelPublicTransportFailure1: "Loại phương tiện giao thông công cộng nào bạn dự định sử dụng?",
    tooltipPublicTransportFailure1: "Vui lòng chọn",
    labelPublicTransportFailure2: "Bạn dự định đi du lịch khi nào?",
    tooltipPublicTransportFailure2: "Vui lòng chọn",
    labelPublicTransportFailure3: "Điều gì đã xảy ra?",
    tooltipPublicTransportFailure3: "Vui lòng chi tiết",
    labelPublicTransportFailure4: "Tại sao bạn không thể sử dụng phương tiện giao thông thay thế?",
    tooltipPublicTransportFailure4: "Vui lòng chi tiết",
    headingScheduledAirlineFailure: "Chuyến bay đã đặt trước của tôi bị hủy bất ngờ và tôi không thể tìm thấy một giải pháp thay thế phù hợp có nghĩa là tôi không thể tham dự sự kiện?",
    headingScheduledAirlineFailureDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelScheduledAirlineFailure1: "Tên hãng hàng không",
    tooltipScheduledAirlineFailure1: "Tên hãng hàng không",
    labelScheduledAirlineFailure2: "Số chuyến bay",
    tooltipScheduledAirlineFailure2: "Số chuyến bay",
    labelScheduledAirlineFailure3: "Đi từ",
    tooltipScheduledAirlineFailure3: "Đi từ",
    labelScheduledAirlineFailure4: "Đi du lịch đến",
    tooltipScheduledAirlineFailure4: "Đi du lịch đến",
    labelScheduledAirlineFailure5: "Ngày xảy ra sự cố?",
    tooltipScheduledAirlineFailure5: "Vui lòng chọn",
    labelScheduledAirlineFailure6: "Tại sao bạn không thể thực hiện một chuyến bay thay thế?",
    tooltipScheduledAirlineFailure6: "Vui lòng chi tiết",
    headingTheftOfDocuments: "Tài liệu của tôi đã bị đánh cắp và không thể sắp xếp (các) thay thế trong khung thời gian cần thiết để tôi không thể tham dự sự kiện",
    headingTheftOfDocumentsDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelTheftOfDocuments1: "Loại tài liệu nào đã bị đánh cắp?",
    tooltipTheftOfDocuments1: "Vui lòng chọn",
    labelTheftOfDocuments2: "Chuyện này xảy ra khi nào?",
    tooltipTheftOfDocuments2: "Vui lòng chọn",
    labelTheftOfDocuments3: "Bạn đã cố gắng để có các tài liệu được cấp lại?",
    tooltipTheftOfDocuments3: "Có hay không",
    headingWorkRelocation: "Chủ lao động của tôi đã yêu cầu tôi thay đổi vị trí làm việc của tôi trong ít nhất ba tháng và khoảng cách là đáng kể có nghĩa là bây giờ tôi không thể tham dự sự kiện?",
    headingWorkRelocationDecline: "Bạn không nên tiến hành ứng dụng này vì rất có thể khoản hoàn lại sẽ bị từ chối.",
    labelWorkRelocation1: "Đây có phải là một sự di dời tạm thời hoặc vĩnh viễn công việc của bạn?",
    tooltipWorkRelocation1: "Vui lòng chọn",
    labelWorkRelocation2: "Bạn sẽ ở địa chỉ mới trong bao lâu?",
    tooltipWorkRelocation2: "Vui lòng chi tiết",
    labelWorkRelocation3: "Địa chỉ làm việc hiện tại của bạn là gì?",
    tooltipWorkRelocation3: "Vui lòng chi tiết",
    labelWorkRelocation4: "Địa chỉ làm việc mới của bạn là gì?",
    tooltipWorkRelocation4: "Vui lòng chi tiết",
    labelWorkRelocation5: "Khi nào bạn được thông báo về việc di dời này?",
    tooltipWorkRelocation5: "Vui lòng chọn",
    headingRedundancy: "Tôi hoặc một thành viên của đặt phòng đã bị chủ lao động của họ làm cho dư thừa, có nghĩa là tôi không thể tham dự đặt phòng nữa?",
    headingRedundancyDecline: "Bạn không nên tiếp tục với đơn đăng ký này vì rất có thể khoản tiền hoàn lại sẽ bị từ chối.",
    labelRedundancy1: "Loại hình việc làm?",
    tooltipRedundancy1: "Vui lòng chọn",
    labelRedundancy2: "Bạn đã làm việc cho công ty này bao lâu rồi?",
    tooltipRedundancy2: "Vui lòng chọn",
    labelRedundancy3: "Việc dư thừa có phải là tự nguyện không?",
    tooltipRedundancy3: "Vui lòng chọn",
    labelRedundancy4: "Khi nào bạn được thông báo về sự dư thừa của mình?",
    tooltipRedundancy4: "Vui lòng chọn",
    labelTestOption: "Chọn Có để tiếp tục",
}