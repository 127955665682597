import * as React from 'react'
import {useEffect} from 'react'
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useTranslation} from 'react-i18next';
import {BorderRadiusSmall} from '../utils/constants-styling';
import useCfar from '../../hooks/useCfar';
import {useFormContext} from '../providers/FormProvider';
import {FormikProps} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';
import {RefundStatus} from '../enums/refundStatus';
import {useRecoilValue} from 'recoil';
import {ProductCodeState} from '../../state/activity';

interface CfarDeclineSectionProps {
    formProps: FormikProps<IFormValues>;
}

export const CfarDeclineSection = (props: CfarDeclineSectionProps) => {

    const {formProps} = props;
    const {saveForm} = useFormContext();    
    const {t} = useTranslation();
    const {cfarResponse} = useCfar();

    const productCode = useRecoilValue(ProductCodeState);

    const isInvalidCfar = cfarResponse.isCfar && !cfarResponse.validEventDate;

    useEffect(() => {
        if (isInvalidCfar) {
            formProps.values.status = RefundStatus.Declined;
            formProps.values.productCode = productCode;
            saveForm(formProps, false);
        }
    }, [isInvalidCfar]);

    return (
        <>
            {isInvalidCfar &&
                <>
                    <Grid container justifyContent='center'
                          sx={{
                              ml: 2,
                              mr: 2,
                              pt: 4
                          }}>
                        <Grid size={{md: 5}} pb={2}>
                            <Box sx={{
                                backgroundColor: '#FBFBFB',
                                border: '1px solid lightgrey',
                                borderRadius: BorderRadiusSmall,
                                p: 6
                            }}>
                                <Box sx={{
                                    color: '#C90606',
                                    pb: 3
                                }}>
                                    {t('common:cfarDeclineHeader')}
                                </Box>
                                <Box>
                                    {t('common:cfarDeclineText', {hours: cfarResponse.hours})}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}