export default {
    headingAdverseWeather: "Etkinliğe seyahat etmeyi güvensiz hale getiren ciddi olumsuz hava koşulları yaşarsınız ve bu bir Devlet Organı (örneğin Yerel Otorite, Polis, vb.) tarafından zorunlu kılınmıştır. Ve bunu kanıtlayacak kanıtınız var mı?",
    headingAdverseWeatherDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    tooltipRefundReasonConfirm: "Evet veya Hayır",
    labelBookingCancelledByOperator: "Biletler Operatör/Organizatör tarafından iptal edildi.",
    labelBookingRescheduledByOperator: "Tarih Operatör/Organizatör tarafından değiştirildi ve yeni tarihlere katılamıyorum.",
    labelBookedWrongDate: "Ben veya rezervasyonun bir üyesi, biletleri satın alırken bir hata yaptık ve yanlış tarih rezervasyonu yaptık.",
    labelBookedWrongTickets: "Ben veya rezervasyonun bir üyesi, biletleri satın alırken bir hata yaptık ve yanlış bilet rezervasyonu yaptık.",
    labelBookedTooManyTickets: "Ben veya rezervasyonun bir üyesi, biletleri satın alırken bir hata yaptık ve çok fazla bilet rezervasyonu yaptık.",
    labelCOVID19TravelRestrictions: "Ben veya rezervasyonun bir üyesi, COVID-19 seyahat yasağı nedeniyle katılamıyoruz.",
    labelADWrongSelection: "Geri ödeme için yanlış bir neden seçmiş olabilirsiniz. Lütfen Geri Ödeme Nedeni açılır listesini tekrar gözden geçirin.",
    labelAdverseWeather1: "Olumsuz hava koşulları neden etkinliğe katılamayacağınız anlamına geliyordu?",
    tooltipAdverseWeather1: "Lütfen detaylandırın",
    labelAdverseWeather2: "Olay tarihi",
    tooltipAdverseWeather2: "Lütfen seçiniz",
    headingArmedForces: "Silahlı Kuvvetler veya Acil Durum Hizmetleri olan İşvereniniz tarafından, başlangıçta programlanmadığınız halde işe gelmeniz isteniyor ve bu da etkinliğe katılmanızı engelliyor mu?",
    headingArmedForcesDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelArmedForces1: "Hangi kuvvetler veya Acil Servis tarafından istihdam ediliyorsunuz?",
    tooltipArmedForces1: "Lütfen seçiniz",
    labelArmedForcesOther1: "Lütfen belirtin",
    tooltipArmedForcesOther1: "Lütfen belirtin",
    labelArmedForces2: "Olay tarihi",
    tooltipArmedForces2: "Lütfen seçiniz",
    headingChangesToExam: "Sınav tarihleriniz okulunuz/kolejiniz vb. tarafından değiştirildi mi? Bu, tekrarlamanız gereken başarısız bir sınav değil mi?",
    headingChangesToExamDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelExam1: "Orijinal sınav tarihi neydi?",
    tooltipExam1: "Lütfen seçiniz",
    labelExam2: "Yeni sınav tarihi nedir?",
    tooltipExam2: "Lütfen seçiniz",
    labelExam3: "Sınav tarihi neden değişti?",
    tooltipExam3: "Lütfen detayları girin",
    labelExam4: "Önceki sınavda başarısız oldunuz mu?",
    tooltipExam4: "Evet veya Hayır",
    headingCourtSummons: "Etkinlikle aynı tarihlerde mahkemeye tanık olarak çağrıldınız mı?",
    headingCourtSummonsDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelCourtSummons1: "Mahkeme celbinizin sebebi nedir?",
    tooltipCourtSummons1: "Lütfen seçiniz",
    labelCourtSummonsOther1: "Lütfen belirtin",
    tooltipCourtSummonsOther1: "Lütfen belirtin",
    labelCourtSummons2: "Mahkeme celbi ne zaman çıkarıldı?",
    tooltipCourtSummons2: "Lütfen seçiniz",
    headingCOVID19Test: "Ben, evimin bir üyesi veya etkinliğe katılan grubun bir üyesi COVID-19 teşhisi kondu mu?",
    headingCOVID19TestDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelCOVID19Test1: "COVID-19 kimlerde görüldü?",
    tooltipCOVID19Test1: "Lütfen seçiniz",
    labelCOVID19TestOther1a: "Lütfen belirtin",
    labelCOVID19TestOther1b: "Adı belirtme",
    tooltipCOVID19TestOther1a: "Lütfen belirtin",
    tooltipCOVID19TestOther1b: "Adı belirtme",
    labelCOVID19Test2: "Ne tür bir COVID-19 testi yaptırdınız?",
    tooltipCOVID19Test2: "Lütfen seçiniz",
    labelCOVID19TestOther2: "Lütfen belirtin",
    tooltipCOVID19TestOther2: "Lütfen belirtin",
    labelCOVID19Test3: "COVID-19 testinizin tarihi neydi?",
    tooltipCOVID19Test3: "Lütfen seçiniz",
    headingDeath: "Ailemin yakın bir üyesinin ölümü oldu ve etkinliğe katılmamı engelliyor mu?",
    headingDeathDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelDeath1: "Ailenizin hangi üyesi öldü?",
    tooltipDeath1: "Lütfen seçiniz",
    labelDeathOther1: "Lütfen belirtin",
    tooltipDeathOther1: "Lütfen belirtin",
    labelDeath2: "Ölüm tarihi ne zamandı?",
    tooltipDeath2: "Lütfen seçiniz",
    labelDeath3: "Merhumun adı",
    tooltipDeath3: "Lütfen belirtin",
    headingEmergency: "Kontrolüm dışında ve öngörülemeyen ve kanıt sağlayabileceğim gerçek bir acil durum yaşadım mı?",
    headingEmergencyDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelEmergency1: "Lütfen acil durumun türünü açıklayın?",
    tooltipEmergency1: "Lütfen detaylandırın",
    labelEmergency2: "Bu ne zaman oldu?",
    tooltipEmergency2: "Lütfen seçiniz",
    labelEmergency3: "Bu durum neden etkinliğe katılmanızı engelliyor?",
    tooltipEmergency3: "Lütfen detaylandırın",
    labelEmergency4: "Bu acil duruma dair kanıtınız var mı?",
    tooltipEmergency4: "Evet veya Hayır",
    headingHomeEmergency: "Etkinliğe katılmamı engelleyen Soygun, Sel, Yangın, Vandalizm, Aile İçi İstismar olan bir ev acil durumum var mı?",
    headingHomeEmergencyDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelHomeEmergency1: "Ev acil durumu nedir?",
    tooltipHomeEmergency1: "Lütfen seçiniz",
    labelHomeEmergencyOther1: "Lütfen belirtin",
    tooltipHomeEmergencyOther1: "Lütfen belirtin",
    labelHomeEmergency2: "Bu ne zaman oldu?",
    tooltipHomeEmergency2: "Lütfen seçiniz",
    labelHomeEmergency3: "Neden etkinliğe katılmanızı engelledi?",
    tooltipHomeEmergency3: "Lütfen detaylandırın",
    headingInjury: "Ben, evimin bir üyesi veya etkinliğe katılan grubun bir üyesine, şimdi etkinliğe gitmemizi engelleyen tıbbi bir durum veya yaralanma teşhisi kondu mu?",
    headingInjuryDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelInjury1: "Hastalık/Yaralanma/Kazanın niteliği nedir?",
    tooltipInjury1: "Lütfen detaylandırın",
    labelInjury2: "Bu ne zaman oldu?",
    tooltipInjury2: "Lütfen seçiniz",
    labelInjury3: "Bu hastalık/yaralanma için tıbbi bir tesise gittiniz mi?",
    tooltipInjury3: "Evet veya Hayır",
    labelInjury4: "Bu önceden var olan bir tıbbi durum muydu?",
    tooltipInjury4: "Evet veya Hayır",
    labelInjury5: "Bu ilk ne zaman teşhis edildi?",
    tooltipInjury5: "Lütfen seçiniz",
    labelInjury6: "Bu neden etkinliğe katılamayacağınız anlamına geliyor?",
    tooltipInjury6: "Lütfen detaylandırın",
    labelInjury7: "Hastalığı/yaralanmayı kimler yaşamıştır?",
    tooltipInjury7: "Lütfen seçiniz",
    headingJuryService: "Ben veya rezervasyonun bir üyesi Jüri Hizmetine çağrıldı mı?",
    headingJuryServiceDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelJuryService1: "Jüri Hizmetinden ne zaman haberdar edildiniz?",
    tooltipJuryService1: "Lütfen seçiniz",
    headingMechanicalBreakdown: "Aracımız mekanik bir arıza geçirdi veya bir kaza geçirdi ve bu da etkinliğe katılmamızı engelliyor mu?",
    headingMechanicalBreakdownDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelMechanicalBreakdown1: "Araca ne oldu?",
    tooltipMechanicalBreakdown1: "Lütfen seçiniz",
    labelMechanicalBreakdown2: "Bu ne zaman oldu?",
    tooltipMechanicalBreakdown2: "Lütfen seçiniz",
    labelMechanicalBreakdown3: "Neden alternatif ulaşım hizmeti alamadınız?",
    tooltipMechanicalBreakdown3: "Lütfen detaylandırın",
    headingPregnancyComplication: "Ben veya rezervasyonun bir üyesi, bir tıp uzmanı tarafından teşhis edilen ve bu nedenle etkinliğe katılımı engelleyen gebelik komplikasyonlarına sahip mi?",
    headingPregnancyComplicationDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelPregnancyComplication1: "Gebelik Komplikasyonunun niteliği nedir?",
    tooltipPregnancyComplication1: "Lütfen detaylandırın",
    labelPregnancyComplication2: "Bu ne zaman oldu?",
    tooltipPregnancyComplication2: "Lütfen seçiniz",
    labelPregnancyComplication3: "Tıbbi yardım istediniz mi?",
    tooltipPregnancyComplication3: "Evet veya Hayır",
    labelPregnancyComplication4: "Bu neden etkinliğe katılamayacağınız anlamına geliyor?",
    tooltipPregnancyComplication4: "Lütfen detaylandırın",
    headingPublicTransportFailure: "Toplu Taşıma yöntemi (Tren, Otobüs, Tramvay, Tekne vb.) beklenmedik bir şekilde başarısız oldu, yani bu etkinliğe seyahat edebilmemin başka bir yolu yok muydu?",
    headingPublicTransportFailureDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelPublicTransportFailure1: "Ne tür bir Toplu Taşıma kullanmayı planladınız?",
    tooltipPublicTransportFailure1: "Lütfen seçiniz",
    labelPublicTransportFailure2: "Ne zaman seyahat etmeyi planladınız?",
    tooltipPublicTransportFailure2: "Lütfen seçiniz",
    labelPublicTransportFailure3: "Ne oldu?",
    tooltipPublicTransportFailure3: "Lütfen detaylandırın",
    labelPublicTransportFailure4: "Neden alternatif ulaşım alamıyorsunuz?",
    tooltipPublicTransportFailure4: "Lütfen detaylandırın",
    headingScheduledAirlineFailure: "Önceden rezerve edilmiş uçuşum beklenmedik bir şekilde iptal edildi ve etkinliğe katılamadığım anlamına gelen uygun bir alternatif bulamadım?",
    headingScheduledAirlineFailureDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelScheduledAirlineFailure1: "Havayolu Adı",
    tooltipScheduledAirlineFailure1: "Havayolu Adı",
    labelScheduledAirlineFailure2: "Uçuş numarası",
    tooltipScheduledAirlineFailure2: "Uçuş numarası",
    labelScheduledAirlineFailure3: "Kalkış",
    tooltipScheduledAirlineFailure3: "Kalkış",
    labelScheduledAirlineFailure4: "Seyahat",
    tooltipScheduledAirlineFailure4: "Seyahat",
    labelScheduledAirlineFailure5: "Olay tarihi?",
    tooltipScheduledAirlineFailure5: "Lütfen seçiniz",
    labelScheduledAirlineFailure6: "Neden alternatif bir uçuş yapamadınız?",
    tooltipScheduledAirlineFailure6: "Lütfen detaylandırın",
    headingTheftOfDocuments: "Belgelerim çalındı ve gerekli zaman dilimi içinde değiştirme ayarlamam mümkün değildi, bu yüzden etkinliğe katılamıyorum",
    headingTheftOfDocumentsDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelTheftOfDocuments1: "Ne tür bir belge çalındı?",
    tooltipTheftOfDocuments1: "Lütfen seçiniz",
    labelTheftOfDocuments2: "Bu ne zaman oldu?",
    tooltipTheftOfDocuments2: "Lütfen seçiniz",
    labelTheftOfDocuments3: "Belgelerin yeniden düzenlenmesini sağlamaya çalıştınız mı?",
    tooltipTheftOfDocuments3: "Evet veya Hayır",
    headingWorkRelocation: "İşverenim en az üç aydır işimin yerini değiştirmemi istedi ve mesafe önemli bir şey şu anda etkinliğe katılamayacağım anlamına mı geliyor?",
    headingWorkRelocationDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu uygulamaya devam etmemelisiniz.",
    labelWorkRelocation1: "Bu, işinizin geçici veya kalıcı olarak taşınması mı?",
    tooltipWorkRelocation1: "Lütfen seçiniz",
    labelWorkRelocation2: "Yeni adreste ne kadar süre kalacaksınız?",
    tooltipWorkRelocation2: "Lütfen detaylandırın",
    labelWorkRelocation3: "Şu anki iş adresiniz nedir?",
    tooltipWorkRelocation3: "Lütfen detaylandırın",
    labelWorkRelocation4: "Yeni iş adresiniz nedir?",
    tooltipWorkRelocation4: "Lütfen detaylandırın",
    labelWorkRelocation5: "Bu yer değiştirmeden ne zaman haberdar edildiniz?",
    tooltipWorkRelocation5: "Lütfen seçiniz",
    headingRedundancy: "Ben veya rezervasyonun bir üyesi işverenleri tarafından gereksiz hale getirildi, yani artık rezervasyona katılamıyor muyum?",
    headingRedundancyDecline: "Geri ödeme büyük olasılıkla reddedileceğinden bu başvuruya devam etmemelisiniz.",
    labelRedundancy1: "İstihdam türü?",
    tooltipRedundancy1: "Lütfen seçiniz",
    labelRedundancy2: "Ne zamandır bu şirkette çalışıyorsunuz?",
    tooltipRedundancy2: "Lütfen seçiniz",
    labelRedundancy3: "Fazlalık gönüllü müydü?",
    tooltipRedundancy3: "Lütfen seçiniz",
    labelRedundancy4: "Fazlalığınız size ne zaman bildirildi?",
    tooltipRedundancy4: "Lütfen seçiniz",
    labelTestOption: "Devam etmek için Evet'i seçin",
}