export default {
    messageQuestionService: "Ši paslauga skirta naudoti tik tuo atveju, jei kyla problemų pildant paraiškos formą, čia negalime atsakyti į jokius kitus klausimus.",
    labelName: "Vardas, pavadinimas",
    labelReference: "Užsakymo nuoroda",
    labelPhone: "Telefono numeris",
    labelEmail: "El paštas",
    labelQuery: "Jūsų klausimas",
    labelAskQuestion: "Atsiųskite mums klausimą čia...",
    buttonOpenModal: "Klausimas",
    title: "Užduokite klausimą",
    modalTitle: "4. Turite techninių problemų su forma?",
    toastSuccess: "Mes gavome jūsų klausimą ir susisieksime su jumis per 24 valandas",
    toastError: "Kažkas negerai su jūsų užklausa, bandykite dar kartą",
    nameValidation: "Vardas yra būtinas",
    referenceValidation: "Būtina nurodyti",
    questionValidation: "Jūsų klausimas yra būtinas",
};