import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {AlertHolder} from './AlertHolder';

export const AllTicketsAssignedAlert = () => {

    const {t} = useTranslation();

    const {
        allTicketsAssigned
    } = useFormContext();

    const AllTicketsAssignedMessage = () => {
        return (
            <p>{t('common:messageAllTicketsAssigned')}</p>
        );
    }

    return (<>
            {allTicketsAssigned &&
                <AlertHolder
                    severity='info'
                    content={<AllTicketsAssignedMessage/>}
                />
            }
        </>
    );
}