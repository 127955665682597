export default {
    messageQuestionService: "Este serviço destina-se apenas a ser utilizado se tiver problemas com o preenchimento do formulário de candidatura, não podemos responder a quaisquer outras questões aqui.",
    labelName: "Nome",
    labelReference: "Referência de reserva",
    labelPhone: "Número de telefone",
    labelEmail: "O email",
    labelQuery: "Sua pergunta",
    labelAskQuestion: "Envie-nos uma pergunta aqui ...",
    buttonOpenModal: "Pergunta",
    title: "Faça uma pergunta",
    modalTitle: "4. Tem um problema técnico com o formulário?",
    toastSuccess: "Recebemos sua pergunta e entraremos em contato em 24 horas",
    toastError: "Algo deu errado com sua solicitação, tente novamente",
    nameValidation: "Nome é obrigatório",
    referenceValidation: "Referência é necessária",
    questionValidation: "Sua pergunta é obrigatória"
}