import * as React from 'react';
import {
    useEffect,
    useState
} from 'react';
import {
    FormikProps,
    useField
} from 'formik';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../Interfaces/IFormValues';
import {IProductItem} from '../Interfaces/IProductItem';
import {ProductModalBodyContent} from './ProductModalBodyContent';
import axios from 'axios';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {ModalHolder} from './ModalHolder';
import useCfar from '../../hooks/useCfar';

export interface ProductSelectionModalProps {
    formProps: FormikProps<IFormValues>;
    handleClose: () => void;
    showModal: boolean;
}

export const ProductModal = (props: ProductSelectionModalProps) => {

    const {t} = useTranslation();

    const {
        formProps,
        handleClose,
        showModal
    } = props;

    const {
        disableTicketChange,
        platformProductItems
    } = useFormContext();

    const {cfarResponse} = useCfar();

    const [displayProducts, setDisplayProducts] = useState<boolean>(false);
    const [checkedState, setCheckedState] = useState<boolean[]>([false]);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [productItemsField, productItemsMeta, productItemsHelper] =
        useField(nameof<IFormValues>(x => x.productItems));
    const [productItems, setProductItems] = useState<IProductItem[]>([]);
    const selectedProducts: IProductItem[] = [];

    const updateSelectedProductsAmounts = (selectedProducts: IProductItem[]) => {

        let selectedBookingAmount = selectedProducts
            .reduce((sum, product) => sum + product.totalValue, 0);

        if (cfarResponse.isCfar) {
            selectedBookingAmount = selectedBookingAmount * cfarResponse.refundAmountMaximumPercentage / 100;
        }

        void formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount),
            selectedBookingAmount.toFixed(2));

        void productItemsHelper.setValue(selectedProducts);

        setDisplayProducts(false);

        handleClose();
    }

    const closeModal = () => {
        updateSelectedProductsAmounts(productItems);
    }

    useEffect(() => {

        if (platformProductItems?.length === 0) {
            return;
        }

        if (formProps.values.originalCurrency !== formProps.values.currency) {
            platformProductItems.forEach(platformProductItem => {
                axios
                    .get<number>(`/api/refund/bookingvalueinrequestedcurrency?sourceCurrency=${formProps.values.originalCurrency}&bookingValue=${platformProductItem.totalValue}&requestedCurrency=${formProps.values.currency}`)
                    .then(x => {
                        platformProductItem.totalValue = x.data;
                        platformProductItem.currencyCode = formProps.values.currency;
                    }).catch(() => {
                    platformProductItem.totalValue = 0;
                    platformProductItem.currencyCode = formProps.values.originalCurrency
                });
            });
        }

        const checkedItems: boolean[] = [];
        const products: IProductItem[] = [];

        if (disableTicketChange) {

            const previouslySelectedProducts = formProps.values.productItems
                .filter(x => x.applicationReference === formProps.values.applicationReference);

            previouslySelectedProducts.forEach(refundProductItem => {
                const platformProductItem = platformProductItems
                    .find(x => refundProductItem.productId === x.productId);

                if (platformProductItem !== undefined) {
                    products.push(platformProductItem);
                    platformProductItem.disabled = true;
                }
            });

            setProductItems(previouslySelectedProducts);

            return;
        }

        platformProductItems.forEach(platformProductItem => {

            const refundProductItem = formProps.values.productItems
                .find(x => platformProductItem.productId === x.productId);

            if (refundProductItem !== undefined && refundProductItem.disabled) {
                checkedItems.push(false);
                platformProductItem.disabled = true;
                products.push(platformProductItem);
                return;
            }

            if (refundProductItem !== undefined && !refundProductItem.disabled) {
                checkedItems.push(true);
                products.push(platformProductItem);
                return;
            }

            checkedItems.push(false);
            products.push(platformProductItem);
        });

        const showProducts = () => {
            return checkedItems.every(x => x === false) || products.some(x => x.disabled);
        }

        const disableOk = () => {
            return checkedItems.every(x => x === false) || products.every(x => x.disabled);
        }

        setProductItems(products);
        setDisplayProducts(showProducts);
        setIsConfirmButtonDisabled(disableOk);
        setCheckedState(checkedItems);

    }, [platformProductItems, formProps.values.currency]);

    const handleOnChange = async (position: number) => {

        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setIsConfirmButtonDisabled(updatedCheckedState.every(x => x === false));
        setCheckedState(updatedCheckedState);
    }

    const handleToggleAllOnClick = () => {
        if (checkedState.every(x => x === true)) {
            const updatedFalseCheckedState = checkedState.map(x => {
                return false;
            });
            setCheckedState(updatedFalseCheckedState);
            setIsConfirmButtonDisabled(true);
            return;
        }

        const updatedTrueCheckedState = checkedState.map(x => {
            return true;
        });
        setCheckedState(updatedTrueCheckedState);
        setIsConfirmButtonDisabled(false);
    }

    const handleConfirmClick = () => {

        checkedState.forEach((checked, index) => {
            if (checked && !productItems[index].disabled) {
                selectedProducts.push(platformProductItems[index]);
            }
        });

        void formProps.setFieldValue(nameof<IFormValues>(x => x.numberOfTicketsToRefund), selectedProducts.length);

        updateSelectedProductsAmounts(selectedProducts);
    }

    const Content = () => {
        return (
            <>
                <ProductModalBodyContent
                    productItems={productItems}
                    checkedState={checkedState}
                    disableTicketChange={disableTicketChange}
                    handleToggleAllOnClick={handleToggleAllOnClick}
                    handleOnChange={handleOnChange}
                />

                {!disableTicketChange &&
                    <ConfirmButton
                        isSubmitButton={false}
                        buttonText={t('common:buttonConfirm')}
                        handleOnClick={handleConfirmClick}
                        disabled={isConfirmButtonDisabled}
                    />
                }

                {disableTicketChange &&
                    <ConfirmButton
                        isSubmitButton={false}
                        buttonText={t('common:buttonClose')}
                        handleOnClick={closeModal}
                    />
                }
            </>
        );
    }

    return (
        <ModalHolder
            showModal={showModal || displayProducts}
            content={<Content/>}
            handleClose={closeModal}
            width='lg'
        />
    );
}