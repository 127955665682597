import {
    createTheme,
    Theme
} from '@mui/material';
import {BorderRadiusButton} from '../utils/constants-styling';

export const buttonTheme = (theme: Theme) => createTheme({
    ...theme,
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    borderRadius: BorderRadiusButton,
                    letterSpacing: '1px'
                },
                startIcon: {
                    marginRight: 0,
                    marginLeft: 0
                },
                endIcon: {
                    marginRight: 0,
                    marginLeft: 0
                }
            }
        }
    }
});