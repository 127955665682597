export default {
    nonAutoApprovedLabelThankYou: "Dėkojame už jūsų grąžinimo paraišką.",
    nonAutoApprovedLabelReview: "Mūsų komanda peržiūrės jūsų paraišką per kitas dvi darbo dienas ir susisieks su jumis su atsakymu.",
    nonAutoApprovedLabelExperience: "Kaip mums sekėsi?",
    nonAutoApprovedLabelExperienceIntroduction: "Mes tikrai būtume dėkingi, jei skirtumėte laiko mus įvertinti ir palikti atsiliepimą, nes vertiname savo klientų nuomonę.",
    feedbackExcellent: "Puikus",
    feedbackGreat: "Puiku, puikus",
    feedbackAverage: "Vidutinis",
    feedbackPoor: "Menkas",
    feedbackBad: "Blogas",
    labelThankYouForFeedback: "Dėkojame už jūsų atsiliepimą.",
    autoApprovedMessage1: "Džiaugiamės galėdami",
    autoApprovedMessage2: "Patvirtinti",
    autoApprovedMessage3: "Jūsų grąžinamoji išmoka!",
    autoApprovedPayment1Day: "Jūsų mokėjimas jau apdorotas ir turėtų būti paskyroje vos per 1 darbo dieną.",
    autoApprovedPayment2to4Days: "Mokėjimas jau apdorotas ir paskyroje turėtų būti atliktas vos per 2–4 darbo dienas.",
    autoApprovedPayment7to10Days: "Mokėjimas jau apdorotas ir turėtų būti paskyroje vos per 7–10 darbo dienų.",
    autoApprovedLabelExperience: "Kaip mums sekėsi?",
    autoApprovedLabelExperienceIntroduction: "Mes tikrai būtume dėkingi, jei skirtumėte laiko mus įvertinti ir palikti atsiliepimą, nes vertiname savo klientų nuomonę.",
    modalLabelNegative: "Jūsų atsiliepimai mums tikrai svarbūs, praneškite mums, jei taip pat norėtumėte, kad susisiektume su jumis.",
    placeholderFeedback: "Atsiliepimus",
    messageFeedbackSubmitted: "Pateikti atsiliepimai",
    reviewInstructionMessage: "5 žvaigždutės yra puikios, 4 žvaigždės yra puikios, 3 žvaigždės yra vidutinės, 2 žvaigždės yra prastos, 1 žvaigždė yra bloga.",
};