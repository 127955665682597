export default {
    nonAutoApprovedLabelThankYou: "आप अपने धनवापसी आवेदन के लिए धन्यवाद.",
    nonAutoApprovedLabelReview: "हमारी टीम अगले दो व्यावसायिक दिनों के भीतर आपके आवेदन की समीक्षा करेगी और प्रतिक्रिया के साथ आपके पास वापस आएगी।",
    nonAutoApprovedLabelExperience: "हमने ऐसा कैसे किया?",
    nonAutoApprovedLabelExperienceIntroduction: "हम वास्तव में सराहना करते हैं कि आप हमें रेट करने के लिए समय निकालते हैं और समीक्षा छोड़ देते हैं क्योंकि हम अपने ग्राहकों की राय को महत्व देते हैं।",
    feedbackExcellent: "अति उत्कृष्ट",
    feedbackGreat: "शानदार",
    feedbackAverage: "औसत",
    feedbackPoor: "गरीब",
    feedbackBad: "ख़राब",
    labelThankYouForFeedback: "आपकी प्रतिक्रिया के लिए धन्यवाद।",
    autoApprovedMessage1: "हम खुश हैं",
    autoApprovedMessage2: "समर्थन कर",
    autoApprovedMessage3: "आपकी वापसी!",
    autoApprovedPayment1Day: "आपका भुगतान पहले ही संसाधित किया जा चुका है और केवल 1 व्यावसायिक दिन में आपके खाते में होना चाहिए।",
    autoApprovedPayment2to4Days: "आपका भुगतान पहले ही संसाधित किया जा चुका है और केवल 2-4 व्यावसायिक दिनों में आपके खाते में होना चाहिए।",
    autoApprovedPayment7to10Days: "आपका भुगतान पहले ही संसाधित किया जा चुका है और केवल 7-10 व्यावसायिक दिनों में आपके खाते में होना चाहिए।",
    autoApprovedLabelExperience: "हमने ऐसा कैसे किया?",
    autoApprovedLabelExperienceIntroduction: "हम वास्तव में सराहना करते हैं कि आप हमें रेट करने के लिए समय निकालते हैं और समीक्षा छोड़ देते हैं क्योंकि हम अपने ग्राहकों की राय को महत्व देते हैं।",
    modalLabelNegative: "आपकी प्रतिक्रिया हमारे लिए वास्तव में महत्वपूर्ण है, हमें बताएं कि क्या आप भी चाहते हैं कि हम आपसे संपर्क करें।",
    placeholderFeedback: "प्रतिपुष्टि",
    messageFeedbackSubmitted: "प्रस्तुत की गई प्रतिक्रिया",
    reviewInstructionMessage: "5 सितारे उत्कृष्ट हैं, 4 सितारे महान हैं, 3 सितारे औसत हैं, 2 सितारे खराब हैं, 1 सितारा खराब है।"
}
