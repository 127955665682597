import * as React from 'react';
import {
    DateInput,
    FileInput,
    TextInput
} from '../../formInputs';
import {useTranslation} from 'react-i18next';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../../Interfaces/IFormValues';
import {useFormContext} from '../../providers/FormProvider';
import {OptionYes} from '../../utils/constants';
import {FormSectionProps} from './RefundReasonHandlerSection';
import {useDynamicEvidence} from '../../utils/helpers';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';
import {RefundReasonFilesState} from '../../../state/activity';

export const ScheduledAirlineFailureSection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {
        clearFiles,
        isEvidenceRequired,
        previousRefundReasonFiles,
        setClearFiles
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingScheduledAirlineFailure')}
                declineWarning={t('bespokeQuestions:headingScheduledAirlineFailureDecline')}
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText1)}
                placeholder={t('bespokeQuestions:labelScheduledAirlineFailure1')}
                label={t('bespokeQuestions:labelScheduledAirlineFailure1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText1)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={false}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure1')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText2)}
                placeholder={t('bespokeQuestions:labelScheduledAirlineFailure2')}
                label={t('bespokeQuestions:labelScheduledAirlineFailure2')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText2)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={false}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure2')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                placeholder={t('bespokeQuestions:labelScheduledAirlineFailure3')}
                label={t('bespokeQuestions:labelScheduledAirlineFailure3')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText3)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={false}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure3')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText4)}
                placeholder={t('bespokeQuestions:labelScheduledAirlineFailure4')}
                label={t('bespokeQuestions:labelScheduledAirlineFailure4')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText4)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={false}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure4')}
                required
            />

            <DateInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeDate5)}
                label={t('bespokeQuestions:labelScheduledAirlineFailure5')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate5)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure5')}
                required
            />

            <TextInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeText6)}
                placeholder={t('bespokeQuestions:labelScheduledAirlineFailure6')}
                label={t('bespokeQuestions:labelScheduledAirlineFailure6')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeText6)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                multiLine={true}
                maxLength={250}
                tooltip={t('bespokeQuestions:tooltipScheduledAirlineFailure6')}
                required
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFiles)}
                label={useDynamicEvidence(false)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFiles}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesState}
                formProps={formProps}
            />
        </>
    );
}