import {IFormValues} from '../form/Interfaces/IFormValues';
import {EventType} from '../form/enums/eventType';
import {RefundStatus} from '../form/enums/refundStatus';

export const defaultFormState: IFormValues = {
    additionalComments: '',
    adjustmentAmount: 0,
    applicationReference: '',
    bankAccountName: '',
    bankAccountNumber: '',
    bankAccountNumberConfirm: '',
    bankAccountType: '',
    bankName: '',
    bankNameSelect: '',
    bankSwiftBic: '',
    bankSwiftBicConfirm: '',
    bankSwiftBicSelect: '',
    beneficiaryAddress1: '',
    beneficiaryAddress2: '',
    beneficiaryCity: '',
    beneficiaryPostCode: '',
    beneficiaryRegion: '',
    bookingConfirmationFiles: [],
    bookingReference: '',
    bookingValue: 0,
    branchCode: '',
    branchCodeSelect: '',
    cardNumber: '',
    cardNumberConfirm: '',
    confirmEmailAddress: '',
    contactNumber: '',
    contactNumberCountryCode: '',
    contactNumberCountryValue: '',
    currency: '',
    customerName: '',
    customerNameLookup: '',
    dateOfBirth: '',
    dateOfEvent: null,
    dateOfEventLabel: '',
    dateOfPurchase: null,
    emailAddress: '',
    eventName: '',
    feedbackComments: '',
    flightNumber: '',
    hasAgreedTermsAndConditions: false,
    homeCountry: '',
    hotelLocation: '',
    hotelName: '',
    ipAddress: '',
    isBankDetailsRequested: false,
    isCancelled: false,
    isEnhancedMatched: false,
    isMatched: false,
    isRefundAmountConfirmed: false,
    isRefundAmountDisabled: false,
    isSold: false,
    isTypeOfBookingDisabled: false,
    language: '',
    memberId: null,
    numberOfTicketsToRefund: 0,
    originAirport: '',
    originalBookingValue: 0,
    originalCurrency: '',
    paymentType: '',
    productCode: '',
    productItems: [],
    reasonForNotificationOutsideTimeLimit: '',
    refundedCount: 0,
    refundReasonBespokeDate1: null,
    refundReasonBespokeDate2: null,
    refundReasonBespokeDate3: null,
    refundReasonBespokeDate4: null,
    refundReasonBespokeDate5: null,
    refundReasonBespokeDate6: null,
    refundReasonBespokeDate7: null,
    refundReasonBespokeEvidenceSelect: 0,
    refundReasonBespokeEvidenceSelectText: '',
    refundReasonBespokeSelect1: 0,
    refundReasonBespokeSelect2: 0,
    refundReasonBespokeSelect3: 0,
    refundReasonBespokeSelect4: 0,
    refundReasonBespokeSelect5: 0,
    refundReasonBespokeSelect6: 0,
    refundReasonBespokeSelect7: 0,
    refundReasonBespokeSelectText1: '',
    refundReasonBespokeSelectText2: '',
    refundReasonBespokeSelectText3: '',
    refundReasonBespokeSelectText4: '',
    refundReasonBespokeSelectText5: '',
    refundReasonBespokeSelectText6: '',
    refundReasonBespokeSelectText7: '',
    refundReasonBespokeText1: '',
    refundReasonBespokeText2: '',
    refundReasonBespokeText3: '',
    refundReasonBespokeText4: '',
    refundReasonBespokeText5: '',
    refundReasonBespokeText6: '',
    refundReasonBespokeText7: '',
    refundReasonCategory: 0,
    refundReasonConfirm: 0,
    refundReasonFiles: [],
    refundReasonFilesSecondary: [],
    remittanceLine3: '',
    remittanceLine3Select: '',
    remittanceLine4: '',
    remittanceLine4Select: '',
    sortCode: '',
    sortCodeConfirm: '',
    status: RefundStatus.None,
    totalRefundAmount: 0,
    transactionId: null,
    typeOfBooking: EventType.None,
    unableToNotifyWithinTimeLimit: false,
    url: '',
    venueName: '',
}