import {FormikProps} from "formik";
import {IFormValues} from "../form/Interfaces/IFormValues";

export const initialValues = (formProps: FormikProps<IFormValues>) : IQueryNote =>  {
    return {
        email: formProps.values.emailAddress,
        name: formProps.values.customerName,
        query: "",
        reference: formProps.values.bookingReference
    }
}