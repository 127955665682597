import {TFunction} from 'i18next';

export const paymentLabelOverrides = (t: TFunction): Record<string, Record<string, string>> => {

    return {
        'bankAccountNumber': {
            'AED': t('payment:labelIBAN'),
            'ARS': t('payment:labelBankAccountNumberCBU'),
            'EUR': t('payment:labelIBAN'),
            'BGN': t('payment:labelIBAN'),
            'CHF': t('payment:labelIBAN'),
            'HRK': t('payment:labelIBAN'),
            'DJF': t('payment:labelIBAN'),
            'DKK': t('payment:labelIBAN'),
            'EGP': t('payment:labelIBAN'),
            'ILS': t('payment:labelIBAN'),
            'JOD': t('payment:labelIBAN'),
            'KMF': t('payment:labelIBAN'),
            'KWD': t('payment:labelIBAN'),
            'GEL': t('payment:labelIBAN'),
            'MAD': t('payment:labelIBAN'),
            'MGA': t('payment:labelIBAN'),
            'MDL': t('payment:labelIBAN'),
            'MKD': t('payment:labelIBAN'),
            'MVR': t('payment:labelIBAN'),
            'MZN': t('payment:labelIBAN'),
            'NOK': t('payment:labelIBAN'),
            'PEN': t('payment:labelBankAccountNumberCCI'),
            'PLN': t('payment:labelIBAN'),
            'QAR': t('payment:labelIBAN'),
            'SEK': t('payment:labelIBAN'),
            'AFN': t('payment:labelIBAN'),
            'ALL': t('payment:labelIBAN'),
            'AMD': t('payment:labelIBAN'),
            'ANT': t('payment:labelIBAN'),
            'AOA': t('payment:labelIBAN'),
            'AZM': t('payment:labelIBAN'),
            'BAM': t('payment:labelIBAN'),
            'BDT': t('payment:labelIBAN'),
            'BHD': t('payment:labelIBAN'),
            'BMD': t('payment:labelIBAN'),
            'BND': t('payment:labelIBAN'),
            'BSD': t('payment:labelIBAN'),
            'BZD': t('payment:labelIBAN'),
            'TMT': t('payment:labelIBAN'),
            'TRY': t('payment:labelIBAN'),
            'SAR': t('payment:labelIBAN'),
            'MXN': t('payment:labelCLABE'),
            "ZAR": t('payment:labelBankAccountNumberZAR')
        },
        'cardNumber': {
            'CNY': t('payment:labelUnionpay'),
        },
        'branchCode': {
            'CAD': t('payment:labelInstitutionNumber')
        },
        'sortCode': {
            'AUD': t('payment:labelBSB'),
            'AWG': t('payment:labelRoutingCodeBSB'),
            'INR': t('payment:labelIFSCCode'),
            'GBP': t('payment:labelSortCode'),
            'USD': t('payment:labelABAFedwireRoutingCode'),
            'ZAR': t('payment:labelBranchCodeUniversal'),
            'CAD': t('payment:labelBankTransitCodeSwift'),
            'CZK': t('payment:labelBankCode'),
            'RUB': t('payment:labelBIKCode')
        },
        'remittanceLine3': {
            'CLP': t('payment:labelRutNumber')
        }
    }
}

export const paymentTooltipOverrides = (t: TFunction): Record<string, Record<string, string>> => {

    return {
        'bankAccountName': {
            'AED': t('payment:tooltipBankAccountNameRecipient'),
            'AUD': t('payment:tooltipBankAccountNameRecipient'),
            'CAD': t('payment:tooltipBankAccountNameRecipient'),
            'CHF': t('payment:tooltipBankAccountNameRecipient'),
            'EUR': t('payment:tooltipBankAccountNameRecipient'),
            'BGN': t('payment:tooltipBankAccountNameRecipient'),
            'CNY': t('payment:tooltipBankAccountNameRecipient'),
            'DKK': t('payment:tooltipBankAccountNameRecipient'),
            'EGP': t('payment:tooltipBankAccountNameRecipient'),
            'GEL': t('payment:tooltipBankAccountNameRecipient'),
            'GBP': t('payment:tooltipBankAccountNameRecipient'),
            'HKD': t('payment:tooltipBankAccountNameRecipient'),
            'HUF': t('payment:tooltipBankAccountNameRecipient'),
            'HRK': t('payment:tooltipBankAccountNameRecipient'),
            'IDR': t('payment:tooltipBankAccountNameRecipient'),
            'ILS': t('payment:tooltipBankAccountNameRecipient'),
            'INR': t('payment:tooltipBankAccountNameRecipient'),
            'KES': t('payment:tooltipBankAccountNameRecipient'),
            'KRW': t('payment:tooltipBankAccountNameRecipient'),
            'MXN': t('payment:tooltipBankAccountNameRecipient'),
            'MYR': t('payment:tooltipBankAccountNameRecipient'),
            'NOK': t('payment:tooltipBankAccountNameRecipient'),
            'NZD': t('payment:tooltipBankAccountNameRecipient'),
            'PEN': t('payment:tooltipBankAccountNameRecipient'),
            'PLN': t('payment:tooltipBankAccountNameRecipient'),
            'USD': t('payment:tooltipBankAccountNameRecipient'),
            'RON': t('payment:tooltipBankAccountNameRecipient'),
            'SEK': t('payment:tooltipBankAccountNameRecipient'),
            'THB': t('payment:tooltipBankAccountNameRecipient'),
            'TRY': t('payment:tooltipBankAccountNameRecipient'),
            'ZAR': t('payment:tooltipBankAccountNameRecipient'),
            'VND': t('payment:tooltipBankAccountNameRecipient'),
            'JPY': t('payment:tooltipBankAccountNameRecipient'),
            'GHS': t('payment:tooltipBankAccountNameRecipient'),
            'NGN': t('payment:tooltipBankAccountNameRecipient'),
            'MAD': t('payment:tooltipBankAccountNameRecipient'),
            'LKR': t('payment:tooltipBankAccountNameRecipient'),
            'CZK': t('payment:tooltipBankAccountNameRecipient'),
            'ISK': t('payment:tooltipBankAccountNameRecipient'),
            'RUB': t('payment:tooltipBankAccountNameRecipient')
        },
        'bankAccountNumber': {
            'ARS': t('payment:tooltipBankAccountNumberCBU'),
            'CHF': t('payment:tooltipIBAN'),
            'EUR': t('payment:tooltipIBAN'),
            'BDT': t('payment:tooltipIBAN'),
            'BGN': t('payment:tooltipIBAN'),
            'DJF': t('payment:tooltipIBAN'),
            'HRK': t('payment:tooltipIBAN'),
            'EGP': t('payment:tooltipIBAN'),
            'ILS': t('payment:tooltipIBAN'),
            'JOD': t('payment:tooltipIBAN'),
            'KMF': t('payment:tooltipIBAN'),
            'KWD': t('payment:tooltipIBAN'),
            'CZK': t('payment:tooltipIBAN'),
            'DKK': t('payment:tooltipIBAN'),
            'GEL': t('payment:tooltipIBAN'),
            'MAD': t('payment:tooltipIBAN'),
            'MGA': t('payment:tooltipIBAN'),
            'MDL': t('payment:tooltipIBAN'),
            'MKD': t('payment:tooltipIBAN'),
            'MVR': t('payment:tooltipIBAN'),
            'MZN': t('payment:tooltipIBAN'),
            'KRW': t('payment:tooltipBankAccountNumberKRW'),
            'PEN': t('payment:tooltipBankAccountNumberCCI'),
            'PLN': t('payment:tooltipIBAN'),
            'QAR': t('payment:tooltipIBAN'),
            'SEK': t('payment:tooltipIBAN'),
            'AED': t('payment:tooltipIBAN'),
            'AFN': t('payment:tooltipIBAN'),
            'ALL': t('payment:tooltipIBAN'),
            'AMD': t('payment:tooltipIBAN'),
            'ANT': t('payment:tooltipIBAN'),
            'AOA': t('payment:tooltipIBAN'),
            'AZM': t('payment:tooltipIBAN'),
            'BAM': t('payment:tooltipIBAN'),
            'BHD': t('payment:tooltipIBAN'),
            'BMD': t('payment:tooltipIBAN'),
            'BND': t('payment:tooltipIBAN'),
            'BSD': t('payment:tooltipIBAN'),
            'BZD': t('payment:tooltipIBAN'),
            'TMT': t('payment:tooltipIBAN'),
            'TRY': t('payment:tooltipIBAN'),
            'SAR': t('payment:tooltipIBAN')
        },
        'cardNumber': {
            'CNY': t('payment:tooltipUnionpay'),
        },
        'branchCode': {
            'CAD': t('payment:tooltipInstitutionNumber')
        },
        'sortCode': {
            'AUD': t('payment:tooltipBSB'),
            'INR': t('payment:tooltipIFSCCode'),
            'MXN': t('payment:tooltipRoutingCode'),
            'AWG': t('payment:tooltipRoutingCodeBSB'),
            'GBP': t('payment:tooltipSortCode'),
            'USD': t('payment:tooltipABAFedwireRoutingCode'),
            'ZAR': t('payment:tooltipBranchCodeUniversal'),
            'CAD': t('payment:tooltipBankTransitCodeSwift'),
            'CZK': t('payment:tooltipBankCode'),
            'RUB': t('payment:tooltipBIKCode')
        },
        'remittanceLine3': {
            'CLP': t('payment:tooltipRutNumber')
        }
    }
}

export const paymentLabelMessages: Record<string, boolean> = {
    'remittanceLine3': true,
    'remittanceLine4': true
}