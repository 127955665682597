export default {
    messageUnableToMakePaymentToCurrency: "Helaas kunnen we geen bankgegevens ophalen voor de geselecteerde valuta. Zorg ervoor dat Valuta en Thuisland zijn geselecteerd op",
    messageOnlyBankAccountsAccepted: "Let op: we kunnen op dit moment alleen betalingen uitvoeren naar bankrekeningen (creditcards en betaalpassen worden niet geaccepteerd)",
    labelAccountName: "Naam rekeninghouder",
    labelBankAccountName: "Volledige naam van de rekeninghouder",
    labelBankCardHolderName: "Naam pashouder",
    validationBankAccountName: "Volledige naam van rekeninghouder is vereist",
    labelBankCountry: "Land van bank",
    labelBankName: "Banknaam",
    labelPaymentType: "Betalingswijze",
    labelInstitutionNumber: "Instellingsnummer",
    labelBankAddress: "Bankadres",
    labelBankAddress2: "Bankadres",
    labelBankCity: "Bank stad",
    labelBankState: "Bankstaat",
    labelBankAccountNumber: "Bankrekeningnummer",
    labelBankAccountNumberCBU: "Rekeningnummer CBU",
    labelBankAccountNumberCCI: "Rekeningnummer CCI",
    labelBankAccountNumberZAR: "Bankrekeningnummer (7-11 cijfers)",
    labelCardNumber: "Kaartnummer",
    labelUnionpay: "UnionPay-kaartnummer",
    labelAlipay: "Alipay ID (mobiel nummer of e-mail)",
    labelBankAccountType: "Account type",
    optionBankAccountTypeCURRENT: "Lopende rekening",
    optionBankAccountTypeCHECKING: "Betaalrekening",
    optionBankAccountTypeSAVING: "Spaarrekening",
    optionBankAccountTypeSAVINGS: "Spaarrekening",
    optionBankAccountTypeCUENTA_VISTA: "Hoofdaccount",
    optionBankAccountType1: "Betaalrekening",
    optionBankAccountType2: "Spaarrekening",
    optionBankAccountType3: "Hoofdaccount",
    optionBankAccountType4: "Vista-account",
    optionBankAccountType5: "RUT-account",
    labelSortCode: "Sorteercode",
    labelRoutingCode: "Routingcode",
    labelBankRoutingCodeNationalID: "Bankrouteringscode / nationale ID",
    labelBankTransitCodeSwift: "Vervoers nummer",
    labelCNAPSCode: "Routing Code / CNAPS Code (China National Advanced Payment System Code)",
    labelABAFedwireRoutingCode: "ACH-routeringsnummer",
    labelIFSCCode: "IFSC-code",
    labelBankCode: "Bankcode",
    labelBIKCode: "BIK-code",
    labelBranchCode: "Filiaalcode",
    labelBranchCodeUniversal: "Universele 6-cijferige filiaalcode",
    labelRemittanceLine3: "Naam en telefoonnummer contactpersoon begunstigde",
    labelBeneficiaryContactNameandTelephoneNumber: "Beneficiary Contact Name and Telephone Number",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Begunstigde geboortedatum en geboorteplaats",
    labelRemittanceLine4: "Individueel belastingnummer CUIL (9-12 cijfers)",
    labelCUILNumber: "Belastingnummer: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "BTW- nummer RFC 13 cijfers of CURP 18 cijfers",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individual Tax ID CPF Cadastro de Pessoa Física (11-14 cijfers), Branch Code",
    labelCFPNumber: "CPF-nummer (11-14 cijfers)",
    labelBranchNameorBranchCode7digits: "Filiaalnaam of filiaalcode (7 cijfers)",
    labelCedulaJuridica910digitsTaxID: "Wettelijk certificaat (9-10 cijfers belasting-ID)",
    labelTaxIDRUT89digits: "Belasting-ID RUT (8-9 cijfers)",
    labelJob: "Functietitel",
    labelDateOfBirth: "Geboortedatum",
    labelNationality: "Nationaliteit",
    labelDocumentType: "Type document",
    labelDocumentNumber: "Document Nummer",
    labelRutNumber: "RUT number (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Identiteitskaart (belasting-ID individueel 8-11 cijfers)",
    labelCIIndividualTaxIDBranchLocation: "CI (Individual Tax ID), filiaallocatie",
    labelTaxIDRUCorCI: "Belasting-ID (RUC of CI)",
    labelSortCodeHelp: "Helpen?",
    labelBranchCodeHelp: "Helpen?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Helpen?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Helpen?",
    labelRoutingCodeBSB: "Routing Code / BSB",
    labelBSB: "Bank State Branch (BSB)",
    labelSKNCode: "SKN-code",
    labelAcceptanceFlights: "Ik/wij accepteren/accepteren dat als de terugbetalingsaanvraag succesvol is, de boeking inclusief alle vluchten en aanvullende producten wordt/worden geannuleerd.",
    labelAcceptanceTickets: "Ik/wij accepteren/accepteren dat als de terugbetalingsaanvraag succesvol is, de boeking inclusief alle tickets en aanvullende producten wordt geannuleerd.",
    labelTermsAndConditions1: "Ik / wij hebben ingestemd met de",
    labelTermsAndConditions2: "Algemene voorwaarden",
    labelFraudWarning1: "Ik ben van mening dat de feiten in dit aanvraagformulier voor terugbetaling echt en waar zijn. Ik begrijp dat valse informatie of bewijsmateriaal als een criminele daad kan worden beschouwd en kan worden doorgegeven aan de politie of andere wetshandhavingsinstanties om te vervolgen.",
    labelFraudWarning2: "Wanneer we fraude vermoeden met betrekking tot ondersteunende documentatie, wordt deze kwestie onmiddellijk doorverwezen naar de lokale politie. Ik accepteer en begrijp dat mijn gegevens aan deze autoriteiten worden doorgegeven als mijn aanvraag als frauduleus wordt beschouwd en dat een strafrechtelijk onderzoek kan worden.",
    validationTermsAndConditions: "De voorwaarden moeten worden overeengekomen met",
    messageFailbankName: "Banknaam mag niet leeg zijn",
    messageFailcustomerName: "Klantnaam mag niet leeg zijn",
    messageFailbankAccountNumber: "Verplicht veld",
    messageFailbankAccountNumberFormat: "Moet het IBAN-formaat zijn",
    messageFailsortCode: "Verplicht veld",
    messageFailbankSwiftBic: "Begunstigde SWIFT BIC mag niet leeg zijn",
    messageFailbankSwiftBicFormat: "Moet SWIFT-formaat zijn",
    messageFailbeneficiaryAddress1: "Adres 1 mag niet leeg zijn",
    messageFailbeneficiaryCity: "Plaats mag niet leeg zijn",
    messageFailbeneficiaryPostCode: "Postcode mag niet leeg zijn",
    messageFailremittanceLine3: "Verplicht veld",
    messageFailremittanceLine4: "Verplicht veld",
    modalHeading: "Bevestig details",
    modalLabelPleaseConfirmYourDetails: "Bevestig uw gegevens",
    modalValidationPleaseConfirm: "Gelieve te bevestigen",
    modalValidationMustMatch: "Moet overeenkomen met vorige invoer",
    tooltipBankAccountName: "Uw naam zoals deze op uw bankrekening / bankafschriften / bankpas staat.",
    tooltipBankAccountNameRecipient: "De voor- en achternaam van de ontvanger. Zowel de voor- als achternaam moeten meer dan één teken hebben.",
    tooltipBankCountry: "Het land waarin uw bank is gevestigd.",
    tooltipInstitutionNumber: "Dit is 3 cijfers en staat meestal op uw maandafschrift of bankpas of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipPaymentType: "Het type betaling dat u nodig heeft.",
    tooltipBankName: "De naam van uw bank.",
    tooltipBankAccountNumber: "Uw individuele rekeningnummer. Meestal te vinden op uw maandelijkse afschrift of bankkaart, of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankAccountNumberCBU: "Een 22-cijferig nummer dat wordt gebruikt om uw account te identificeren. Meestal te vinden op uw maandafschrift of bankkaart of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipBankAccountNumberCCI: "Een 20-cijferig nummer dat wordt gebruikt om uw account te identificeren. Meestal te vinden op uw maandafschrift of bankkaart of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipBankAccountNumberKRW: "Een nummer van 11-16 cijfers dat wordt gebruikt om uw account te identificeren. Meestal te vinden op uw maandafschrift of bankkaart of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipCardNumber: "Uw kaartnummer. Meestal te vinden op uw maandafschrift of bankkaart of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipUnionpay: "Uw UnionPay-kaartnummer.",
    tooltipAlipay: "Uw mobiele nummer of e-mailadres dat is gekoppeld aan uw Alipay-account.",
    tooltipBankAccountType: "Dit is ofwel Controleren of Sparen.",
    tooltipSortCode: "Dit identificeert het filiaal van uw bank waar uw rekening wordt aangehouden. Meestal te vinden op uw maandelijkse afschrift of bankkaart, of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipRoutingCode: "Dit identificeert het filiaal van uw bank waar uw rekening wordt aangehouden. Meestal te vinden op uw maandelijkse afschrift of bankkaart, of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBeneficiarySWIFTBIC: "Bestaat uit 8-11 tekens die worden gebruikt om een ​​bank te identificeren bij een internationale transactie. Meestal te vinden op uw maandelijkse afschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBSB: "Een Bank State Branch (BSB) -nummer is een Australische sorteercode die uit zes cijfers bestaat. Meestal te vinden op uw maandelijkse afschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipRoutingCodeBSB: "Dit identificeert het filiaal van uw bank waar uw rekening wordt aangehouden. Meestal te vinden op uw maandelijkse afschrift of bankkaart, of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipABAFedwireRoutingCode: "Een 9-cijferig nummer dat wordt gebruikt om banken in Amerika te identificeren. Meestal te vinden op uw maandelijkse afschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipIFSCCode: "Meestal vindt u deze code op uw maandoverzicht of op uw bankboekje of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipTermsAndConditions: "Door de algemene voorwaarden te accepteren, bevestigt u dat u onze algemene voorwaarden heeft gelezen en ermee akkoord gaat.",
    tooltipIBAN: "Uw internationale bankrekeningnummer, dat u kunt gebruiken bij het maken of ontvangen van internationale betalingen. Meestal te vinden op uw maandelijkse afschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Naam en telefoonnummer van de begunstigde.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Geboortedatum en geboorteplaats begunstigde.",
    tooltipBankRoutingCodeNationalID: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankTransitCodeSwift: "Dit is 5 cijfers en is meestal terug te vinden op uw maandelijkse bankafschrift of als u het nog niet zeker weet, vraag het dan aan uw bank.",
    tooltipBankAddress: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankAddress2: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankCity: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankState: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBranchName: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBankCode: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBIKCode: "9 cijfers om uw bank te identificeren.",
    tooltipBranchCode: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBranchCodeUniversal: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipCNAPSCode: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipRemittanceLine4: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipCUILNumber: "Dit is een 11-cijferig nummer, bestaande uit twee cijfers, een koppelteken, negen cijfers en een controlesom van één cijfer.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipCFPNumber: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipBranchNameorBranchCode7digits: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipCedulaJuridica910digitsTaxID: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipTaxIDRUT89digits: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipJob: "Uw huidige beroep.",
    tooltipDateOfBirth: "Je geboortedatum.",
    tooltipNationality: "Jouw nationaliteit.",
    tooltipDocumentType: "Het vereiste documenttype.",
    tooltipDocumentNumber: "Dit is het documentnummer dat is gekoppeld aan uw documenttypeselectie.",
    tooltipRutNumber: "Uw RUT-nummer (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipCIIndividualTaxIDBranchLocation: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    tooltipTaxIDRUCorCI: "Meestal te vinden op uw maandelijkse bankafschrift of als u nog steeds niet zeker bent, vraag het dan aan uw bank.",
    errorCPFCode: "Op dit moment accepteren we alleen CPF als PIX-sleutel."
};