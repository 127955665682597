import * as React from 'react';
import {
    OptionYes,
    RefundReasonBookedTooManyTickets,
    RefundReasonBookedWrongDate,
    RefundReasonBookedWrongTickets,
    RefundReasonBookingCancelledByOperator,
    RefundReasonBookingRescheduledByOperator,
    RefundReasonCOVID19PositiveTest,
    RefundReasonCOVID19TravelRestrictions,
    refundBlanketDeclineReasons,
    RefundReasonOther
} from '../utils/constants';
import {
    BooleanInput,
    SelectInput,
    TextInput
} from '../formInputs';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from '../providers/FormProvider';
import {FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {TermsAndConditionsModal} from '../modals/TermsAndConditionsModal';
import {useIsOffline} from '../utils/helpers';
import {RefundReasonHandlerSection} from './refundReasonQuestionSets/RefundReasonHandlerSection';
import {EventDateLimitAlert} from '../alerts/EventDateLimitAlert';
import {EvidenceResubmissionAlert} from '../alerts/EvidenceResubmissionAlert';
import {Section} from '../enums/section';
import {SectionHeadingTextTheme} from './SectionHeadingTextTheme';
import {useRecoilValue} from 'recoil';
import {ProductCodeState} from '../../state/activity';
import useCfar from '../../hooks/useCfar';

interface RefundRequestPageProps {
    formProps: FormikProps<IFormValues>;
}

export const RefundRequestSection = (props: RefundRequestPageProps) => {

    const {
        formProps
    } = props;

    const {
        dateOfEventLabel,
        isEventDateBeyondTimeLimit,
        isExtendedTerms,
        selectedSection,
        updateReasonDeclineStatus
    } = useFormContext();

    const {t} = useTranslation();
    const isOfflinePayment = useIsOffline();
    const {cfarResponse} = useCfar();

    const productCode = useRecoilValue(ProductCodeState);
    
    const reasonDataSource = !cfarResponse.isCfar ?
        `/api/referencedata/reasons?reasonId=${formProps.values.refundReasonCategory}` :
        `/api/referencedata/reasons-cfar?productCode=${productCode}`;
    
    const labelRefundReasonCategory = !cfarResponse.isCfar ?
        t('form:labelRefundReasonCategory') :
        t('form:labelRefundReasonCategoryCfar');
    
    const headingText = !cfarResponse.isCfar ?
        t(`common:formSectionNavigatorStep2Text`) :
        t(`common:formSectionNavigatorStep2CfarText`);

    return (
        <>
            {selectedSection === Section.RefundRequest &&
                <>
                    <SectionHeadingTextTheme text={headingText}/>

                    <EvidenceResubmissionAlert/>

                    <SelectInput
                        key={nameof<IFormValues>(x => x.refundReasonCategory)}
                        defaultValue={0}
                        label={labelRefundReasonCategory}
                        controlId={nameof<IFormValues>(x => x.refundReasonCategory)}
                        hidden={false}
                        prefixKey={'form:optionRefundReason'}
                        datasourceUrl={reasonDataSource}
                        onChange={(e) => void updateReasonDeclineStatus(formProps,
                            +e.target.value
                        )}
                        tooltip={t('form:tooltipRefundReasonCategory')}
                        required
                    />

                    <RefundReasonHandlerSection formProps={formProps}/>

                    {((formProps.values.refundReasonCategory !== RefundReasonCOVID19PositiveTest
                                && formProps.values.refundReasonCategory !== RefundReasonCOVID19TravelRestrictions
                                && formProps.values.refundReasonCategory !== RefundReasonBookingCancelledByOperator
                                && formProps.values.refundReasonCategory !== RefundReasonBookingRescheduledByOperator
                                && formProps.values.refundReasonCategory !== RefundReasonBookedWrongDate
                                && formProps.values.refundReasonCategory !== RefundReasonBookedWrongTickets
                                && formProps.values.refundReasonCategory !== RefundReasonBookedTooManyTickets
                                && formProps.values.refundReasonConfirm === OptionYes) ||
                            (formProps.values.refundReasonCategory === RefundReasonCOVID19PositiveTest &&
                                formProps.values.refundReasonConfirm === OptionYes &&
                                isExtendedTerms !== false)) &&
                        <>
                            {isEventDateBeyondTimeLimit &&
                                <EventDateLimitAlert/>
                            }

                            <BooleanInput
                                key={nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit)}
                                label={t('form:labelUnableToNotifyWithinTimeLimit') + ' ' + dateOfEventLabel}
                                controlId={nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit)}
                                hidden={!isEventDateBeyondTimeLimit}
                                checked={isEventDateBeyondTimeLimit}
                                disabled={isEventDateBeyondTimeLimit}
                                tooltip={t('form:tooltipUnableToNotifyWithinTimeLimit')}
                            />

                            <TextInput
                                key={nameof<IFormValues>(x => x.reasonForNotificationOutsideTimeLimit)}
                                placeholder={t('form:labelReasonForNotificationOutsideTimeLimit')}
                                label={t('form:labelReasonForNotificationOutsideTimeLimit')}
                                controlId={nameof<IFormValues>(x => x.reasonForNotificationOutsideTimeLimit)}
                                hidden={!isEventDateBeyondTimeLimit}
                                maxLength={1500}
                                multiLine={true}
                                tooltip={t('form:tooltipReasonForNotificationOutsideTimeLimit')}
                                required
                            />
                        </>
                    }

                    {(formProps.values.refundReasonCategory === RefundReasonOther ||
                        formProps.values.refundReasonConfirm === OptionYes) &&
                        <TextInput
                            key={nameof<IFormValues>(x => x.additionalComments)}
                            placeholder={t('form:labelAdditionalComments')}
                            label={t('form:labelAdditionalComments')}
                            controlId={nameof<IFormValues>(x => x.additionalComments)}
                            hidden={false}
                            multiLine={true}
                            maxLength={1000}
                            tooltip={t('form:tooltipAdditionalComments')}
                            required={refundBlanketDeclineReasons
                                .map(x => x.id).includes(formProps.values.refundReasonCategory)}
                        />
                    }

                    {formProps.values.refundReasonCategory !== 0
                        && formProps.values.refundReasonConfirm !== 0
                        && isOfflinePayment &&
                        <TermsAndConditionsModal memberId={formProps.values.memberId}/>
                    }
                </>
            }
        </>
    );
}