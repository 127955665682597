import * as React from 'react';
import {
    Button,
    ThemeProvider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ButtonTextTheme} from '../themes/ButtonTextTheme';
import {buttonTheme} from '../themes/ButtonThemeProvider';

interface CancelButtonProps {
    buttonText: string;
    handleOnClick: () => void;
}

export const CancelButton = (props: CancelButtonProps) => {

    const {
        buttonText,
        handleOnClick
    } = props;

    return (
        <ThemeProvider theme={buttonTheme}>
            <Button
                sx={{
                    backgroundColor: '#C0C0C0',
                    color: 'white'
                }}
                endIcon={<CloseIcon/>}
                onClick={handleOnClick}>
                <ButtonTextTheme buttonText={buttonText}/>
            </Button>
        </ThemeProvider>
    );
}