export default {
    labelBookingCancelledPostponedHeading: "Kas teie broneering on tühistatud või edasi lükatud? Loe edasi",
    labelBookingCancelledPostponedText1: "Klõpsake",
    labelBookingCancelledPostponedText2: "Rohkem infot.",
    labelNewRefundHeading: "Uus tagasimaksetaotlus?",
    labelNewRefundText: "Sisestage siia oma broneeringu number ...",
    placeholderBookingReference: "Broneeringu viitenumber",
    validationBookingReference: "Broneeringu viide on kohustuslik",
    validationEmailAsBookingReference: "E-posti aadressi ei aktsepteerita broneeringu viitenumbrina",
    buttonFindBooking: "Leia broneering",
    labelEmailHeading: "Kas olete juba alustanud?",
    labelEmailText: "Saatke rakenduse hankimiseks e-posti teel meeldetuletus.",
    placeholderEmail: "Meil",
    buttonEmailReminder: "E-posti meeldetuletus",
    labelCustomerName : "Kliendi nimi",
    validationCustomerName: "Kliendi nimi on kohustuslik",
    labelTypeOfBooking: "Broneeringu liik",
    validationTypeOfBooking: "Broneeringu tüüp on kohustuslik",
    labelEventName: "Sündmuse nimi",
    labelVenueName: "Koha nimi",
    labelFlightNumber: "Lennu number",
    labelOriginAirport: "Päritolu lennujaam",
    labelHotelName: "Majutusasutuse nimi",
    labelHotelLocation: "Majutuse asukoht",
    labelCurrency: "Valuuta",
    validationCurrency: "Valuuta on kohustuslik",
    validationCountryCurrency: "Valitud valuuta peab olema teie koduriigi valuuta",
    validationOfflinePayment: "Me ei saa töödelda tagasimakseid teie valuuta ja riigi valiku kohta, palun valige alternatiivid",
    labelBookingValue: "Broneeringu väärtus",
    validationBookingValue: "Broneeringu väärtus on kohustuslik",
    validationBookingValueAmount: "Peab olema suurem kui 0",
    labelDateOfPurchase: "Ostu kuupäev",
    validationDateOfPurchase: "Ostukuupäev on kohustuslik",
    labelEmailAddress: "E-posti aadress",
    validationEmailAddress: "E-posti aadress on kohustuslik",
    validationEmailAddressFormat: "Vale e-posti vorming",
    labelConfirmEmailAddress: "Kinnitage e-posti aadress",
    validationConfirmEmailAddressFormat: "Vale e-posti vorming",
    validationConfirmEmailAddress: "Kinnitus E-posti aadress on kohustuslik",
    validationConfirmEmailAddressMatch: "E-posti aadressid ei ühti",
    validationEmailDomain: "Seda e-posti teenusepakkujat ei aktsepteerita, palun kasutage alternatiivi",
    validationDateOfEvent: "Sündmuse kuupäev on kohustuslik",
    labelHomeCountry: "Koduriik",
    validationHomeCountry: "Koduriik on kohustuslik",
    labelContactNumberCountryCode: "Kontaktnumber Riigi kood",
    validationContactNumberCountryCode: "Riigi valimiskood on kohustuslik",
    labelContactNumber: "Kontaktnumber",
    validationContactNumber: "Kontaktnumber on kohustuslik",
    labelAddressLine1: "Aadressirida 1",
    labelAddressLine2: "Aadressirida 2",
    labelCity: "Linn",
    labelZipCode: "Sihtkood",
    labelRegion: "Piirkond",
    validationRegion: "Piirkond on nõutav",
    labelNumberOfTicketsToRefund: "Tagastatavate piletite arv",
    validationNumberOfTicketsToRefund: "Tagastatavate piletite arv on nõutav",
    validationNumberOfTicketsToRefundValue: "Peab olema suurem kui 0",
    labelCostPerTicket: "Iga pileti maksumus",
    validationCostPerTicket: "Pileti hind on kohustuslik",
    validationCostPerTicketValue: "Peab olema suurem kui 0",
    labelTotalRefundAmount: "Tagasimakse kogusumma",
    validationTotalRefundAmount: "Tagasimakse kogusumma on kohustuslik",
    validationTotalRefundAmountValue: "Peab olema suurem kui 0",
    validationTotalRefundGreaterThanBookingValue: "Tagasimakse kogusumma ei saa olla suurem kui ülaltoodud broneeringu väärtus",
    labelBookingConfirmationFiles: "Lae üles broneeringu kinnitus",
    validationBookingConfirmationFiles: "Manustage vähemalt üks fail või klõpsake nuppu Salvesta ja naaske hiljem, kui dokumendid on olemas",
    validationEvidenceFilesPopup: "Tõendid tuleb enne esitamist üles laadida. Kui teil ei ole praegu tõendeid, salvestage taotlus ja tagastage, kui teil on see olemas. Aktsepteerime elektroonilisi koopiaid, skanneri koopiaid ja fotosid.",
    validationBookingConfirmationFilesPopup: "Broneeringu kinnitus tuleb üles laadida enne esitamist. Kui teil ei ole praegu broneeringu kinnitust, siis palun salvestage taotlus ja tagastage, kui teil on see olemas. Aktsepteerime elektroonilisi koopiaid, skanneri koopiaid ja fotosid.",
    alertUploadDocuments: "Palun laadige üles e-kirjas üksikasjalikult kirjeldatud asjakohased dokumendid ja jätkake 3. sammuga.",
    labelRefundReasonCategory: "Tagasimakse põhjus",
    validationRefundReasonCategory: "Tagasimakse põhjus on kohustuslik",
    labelDateOfIncident: "Juhtumi kuupäev",
    labelAdditionalComments: "Täiendavad kommentaarid",
    tooltipAdditionalComments: "Täiendavad kommentaarid",
    validationAdditionalComments: "Lisakommentaarid on vajalikud",
    labelUnableToNotifyWithinTimeLimit: "Ei saa teatada 60 päeva jooksul alates",
    labelReasonForNotificationOutsideTimeLimit: "Teatamine väljaspool tähtaega Selgitus",
    validationReasonForNotificationOutsideTimeLimit: "Nõutav on 60 päeva pärast teatamise põhjus",
    labelRefundReasonFiles: "Tõendite üleslaadimine",
    validationRefundReasonFiles: "Manustage vähemalt üks fail või klõpsake nuppu Salvesta ja naaske hiljem, kui dokumendid on olemas",
    validationTextScript: "Peab vastama ladina tähestikule",
    optionTypeOfBooking1: "Sündmus",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Lend",
    optionTypeOfBooking4: "Majutus",
    optionTypeOfBooking5: "Üleandmine",
    optionTypeOfBooking6: "Parkimine",
    optionTypeOfBooking7: "Muud reisid",
    labelEventTypeDate: "Sündmuse kuupäev",
    labelEventTypeTour: "Ekskursiooni kuupäev",
    labelEventTypeFlight: "Lennu kuupäev",
    labelEventTypeCheckIn: "Sisseregistreerimise kuupäev",
    labelEventTypeTransfer: "Üleandmise kuupäev",
    labelEventTypeParking: "Parkimise kuupäev",
    labelEventTypeOtherTravel: "Reisi kuupäev",
    optionRefundReason11: "Ebasoodne ilm",
    optionRefundReason10: "Relvajõudude ja hädaabiteenistuste tagasikutsumine",
    optionRefundReason15: "Eksamikuupäevade muudatused",
    optionRefundReason18: "Kohtukutse",
    optionRefundReason17: "COVID-19 reisipiirangud",
    optionRefundReason19: "COVID-19 positiivne test",
    optionRefundReason20: "Operaator/reisikorraldaja tühistatud broneering",
    optionRefundReason21: "Broneeringu ajakava muudetakse operaatori/reisikorraldaja poolt",
    optionRefundReason22: "Broneeritud vale kuupäev",
    optionRefundReason23: "Broneeritud valed piletid",
    optionRefundReason24: "Broneeritud liiga palju pileteid",
    optionRefundReason4: "Surm",
    optionRefundReason16: "Erakorralised asjaolud",
    optionRefundReason14: "Valitsuse reisikeeld",
    optionRefundReason9: "Kodu hädaolukord",
    optionRefundReason1: "Haigus/vigastus/õnnetusjuhtum",
    optionRefundReason8: "Žürii teenus",
    optionRefundReason7: "Mehaaniline jaotus",
    optionRefundReason2: "Olemasolev meditsiiniline seisund",
    optionRefundReason3: "Raseduse tüsistus",
    optionRefundReason5: "Ühistranspordi rike",
    optionRefundReason6: "Lennuhäired",
    optionRefundReason13: "Dokumendi (dokumentide) vargus",
    optionRefundReason12: "Töö ümberpaigutamine",
    optionRefundReason25: "Töökoha koondamine",
    optionRefundReason26: "Testi võimalus",
    optionRefundReasonConfirm1: "Ei",
    optionRefundReasonConfirm2: "Jah",
    optionRefundReasonArmedForces1: "Armee",
    optionRefundReasonArmedForces2: "Merevägi",
    optionRefundReasonArmedForces3: "Õhujõud",
    optionRefundReasonArmedForces4: "Eriväed",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Tuli",
    optionRefundReasonArmedForces7: "Kiirabi",
    optionRefundReasonArmedForces8: "Arst/õde",
    optionRefundReasonArmedForces9: "Hambaarst",
    optionRefundReasonArmedForces10: "Muu",
    optionRefundReasonCourtSummons1: "Kriminaalvastutusele võtmine",
    optionRefundReasonCourtSummons2: "Tsiviilõiguslik kohtuasi",
    optionRefundReasonCourtSummons3: "Tunnistaja süüdistuses",
    optionRefundReasonCourtSummons4: "Tunnistaja tsiviilasjas",
    optionRefundReasonCourtSummons5: "Hageja",
    optionRefundReasonCourtSummons6: "Kostja",
    optionRefundReasonCourtSummons7: "Muu",
    optionRefundReasonDeath1: "Mees",
    optionRefundReasonDeath2: "Naine",
    optionRefundReasonDeath3: "Heli",
    optionRefundReasonDeath4: "Tütar",
    optionRefundReasonDeath5: "Isa",
    optionRefundReasonDeath6: "Ema",
    optionRefundReasonDeath7: "Vend",
    optionRefundReasonDeath8: "Õde",
    optionRefundReasonDeath9: "Vanaisa",
    optionRefundReasonDeath10: "Vanaema",
    optionRefundReasonDeath11: "Muu",
    optionRefundReasonHomeEmergency1: "Tuli",
    optionRefundReasonHomeEmergency2: "Üleujutuste",
    optionRefundReasonHomeEmergency3: "Röövimine",
    optionRefundReasonHomeEmergency4: "Vandalism",
    optionRefundReasonHomeEmergency5: "Koduvägivald",
    optionRefundReasonHomeEmergency6: "Röövida",
    optionRefundReasonHomeEmergency7: "Muu",
    optionRefundReasonCOVID19Person1: "Ise",
    optionRefundReasonCOVID19Person2: "Teine isik broneeringus",
    optionRefundReasonCOVID19Person3: "Inimene minu leibkonnas",
    optionRefundReasonCOVID19Person4: "Muu",
    optionRefundReasonCOVID19TestType1: "PCR-test",
    optionRefundReasonCOVID19TestType2: "RAT-test",
    optionRefundReasonCOVID19TestType3: "Külgvoolu katse",
    optionRefundReasonCOVID19TestType4: "Ise manustatud test",
    optionRefundReasonCOVID19TestType5: "Testi pole, kuid mul on sümptomid",
    optionRefundReasonCOVID19TestType6: "Muu",
    optionRefundReasonBreakdown1: "Jaotus",
    optionRefundReasonBreakdown2: "Õnnetus",
    optionRefundReasonBreakdown3: "Tuli",
    optionRefundReasonBreakdown4: "Vargus",
    optionRefundReasonPublicTransportType1: "Rong",
    optionRefundReasonPublicTransportType2: "Buss",
    optionRefundReasonPublicTransportType3: "Tramm",
    optionRefundReasonPublicTransportType4: "Paat",
    optionRefundReasonDocumentType1: "Pass",
    optionRefundReasonDocumentType2: "Reisiluba",
    optionRefundReasonDocumentType3: "Riiklik isikutunnistus",
    optionRefundReasonDocumentType4: "Piletid",
    optionRefundReasonWorkRelocationType1: "Ajutine",
    optionRefundReasonWorkRelocationType2: "Püsiv",
    optionRefundReasonWorkRelocationTemporary1: "0-3 kuud",
    optionRefundReasonWorkRelocationTemporary2: "3-6 kuud",
    optionRefundReasonWorkRelocationTemporary3: "Üle 6 kuu",
    optionRefundReasonBookingRelationshipType1: "Keegi broneeringus",
    optionRefundReasonBookingRelationshipType2: "Broneeringus oleva isiku lähisugulane",
    optionEmploymentType1: "Täiskohaga",
    optionEmploymentType2: "Osaline tööaeg",
    optionEmploymentType3: "Leping",
    optionEmploymentLengthType1: "0-1 aastat",
    optionEmploymentLengthType2: "1-2 aastat",
    optionEmploymentLengthType3: "Rohkem kui 2 aastat",
    textRefundEvidenceHeading: "Palun esitage järgmised tõendid:",
    textRefundEvidence1: "Lennufirma kinnitus",
    textRefundEvidence2: "Kõik asjakohased täiendavad tõendid",
    textRefundEvidence3: "Rikketeenuse väljahelistamise kinnitus",
    textRefundEvidence4: "Surmatunnistus",
    textRefundEvidence5: "Lennufirma kinnituse tühistamine",
    textRefundEvidence6: "Ilmateade",
    textRefundEvidence7: "Broneerimisagendi kiri, mis kinnitab, et pileteid ei ole võimalik uuesti väljastada",
    textRefundEvidence8: "Euroopa Kohtu kiri",
    textRefundEvidence9: "Arstitõend / arsti märkus",
    textRefundEvidenceDescription9: "Kinnitades haiguse või vigastuse üksikasju, kuupäeva, mil see esmakordselt tekkis, et see takistab teil broneeringus osaleda.",
    textRefundEvidence10: "Tööandja kirja kinnitus",
    textRefundEvidence11: "Kindlustusnõuete kirjavahetus",
    textRefundEvidence12: "Tööandja kiri",
    textRefundEvidence13: "Riigi valitsuse veebisaidi kinnitus",
    textRefundEvidence14: "Eksami muutmise teade",
    textRefundEvidence15: "Politseiraport",
    textRefundEvidence16: "Politseiraport ja viitenumber",
    textRefundEvidence17: "Vedaja kinnitus",
    textRefundEvidence18: "Reisihoiatus",
    textRefundEvidence20: "COVID-19 testitõend",
    textRefundEvidence24: "Valitsuse isolatsiooniteatis",
    textRefundEvidence25: "Käsundusohvitseri kiri",
    textRefundEvidence26: "Kindlustusandjate kiri",
    textRefundEvidence27: "Aadressi tõendav dokument",
    textRefundEvidence28: "Tõend suguluse kohta lahkunuga",
    textRefundEvidence29: "Tõend suguluse kohta vigastatud/haige inimesega",
    textRefundEvidence30: "Palgaleht",
    tooltipBookingReference: "Broneeringu viitenumbri tuvastamiseks vaadake oma broneeringu kinnitust ja vaadake jaotist meie laiendatud teenuse kohta.",
    tooltipCustomerName: "Ees- ja perekonnanimi, mida kasutati broneeringu tegemisel.",
    tooltipTypeOfBooking: "Broneeringu tüüp, mille eest soovite raha tagasi saada.",
    tooltipEventName: "Sündmuse nimi.",
    tooltipVenueName: "Toimumiskoha nimi.",
    tooltipFlightNumber: "Lennu number.",
    tooltipOriginAirport: "Lähtelennujaam.",
    tooltipHotelName: "Majutusettevõtte nimi.",
    tooltipHotelLocation: "Majutuse asukoht.",
    tooltipCurrency: "Broneeringu eest tasumiseks kasutatav valuuta.",
    tooltipBookingValue: "Koguhind, mille maksite broneeringu eest.",
    tooltipDateOfPurchase: "Broneeringu tegemise kuupäev.",
    tooltipEmailAddress: "Palun kasutage kehtivat e-posti aadressi. Kasutame seda e-posti aadressi tagasimaksetaotluse ajal kirjavahetuse pidamiseks.",
    tooltipConfirmEmailAddress: "Kasutage sama e-posti aadressi nagu eespool, see on valideerimiseks.",
    tooltipHomeCountry: "See aitab meil viimase sammu kohta õigeid pangaandmeid esitada.",
    tooltipContactNumberCountryCode: "Kontaktnumber, riigikood.",
    tooltipContactNumber: "Palun kasutage kehtivat numbrit. Kasutame seda tagasimakse taotlemise ajal kirjavahetuse pidamiseks.",
    tooltipAddressLine1: "Pange tähele, et teie pangakonto kinnitamisel kasutatakse teie aadressi.",
    tooltipAddressLine2: "Teine aadressirida.",
    tooltipCity: "Teie kodulinn, andmeid saab kasutada pangaandmete kinnitamiseks.",
    tooltipZipCode: "Postiindeks / sihtnumber.",
    tooltipRegion: "Teie kodulinna piirkonnas saab pangaandmete valideerimiseks kasutada üksikasju.",
    tooltipNumberOfTicketsToRefund: "Piletite arv, mille eest soovite raha tagasi saada.",
    tooltipTotalRefundAmount: "See ei saa olla suurem kui broneeringu koguväärtus, te ei saa taotlust esitada, kui see nii on.",
    tooltipBookingConfirmationFiles: "Ekraanipilt/manus teie täielikust broneeringu kinnitusest, mille leiate tavaliselt oma e-kirjadest.",
    tooltipRefundReasonCategory: "Valige tagasimakse põhjus.",
    tooltipDateOfIncident: "Kuupäev, millal teie broneeringut mõjutanud intsident aset leidis.",
    tooltipRefundExplanation: "Palun esitage võimalikult palju üksikasju, mis toetavad tagasimakse nõuet.",
    tooltipUnableToNotifyWithinTimeLimit: "Teadet ei saa teatada 60 päeva jooksul.",
    tooltipReasonForNotificationOutsideTimeLimit: "Teatamine väljaspool tähtaega Selgitus.",
    tooltipRefundReasonFiles: "Palun laadige üles kõik vajalikud dokumendid.",
    tooltipEventTypeDate: "Tegeliku sündmuse / broneeringu kuupäev.",
    tooltipEventTypeTour: "Tegeliku ekskursiooni kuupäev.",
    tooltipEventTypeFlight: "Tegeliku lennu kuupäev.",
    tooltipEventTypeCheckIn: "Tegeliku sisseregistreerimise kuupäev.",
    tooltipEventTypeTransfer: "Tegeliku üleandmise kuupäev.",
    tooltipEventTypeParking: "Tegeliku parkimise kuupäev.",
    tooltipEventTypeOtherTravel: "Tegeliku reisi alguskuupäev."
};