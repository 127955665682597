export default {
    labelBookingCancelledPostponedHeading: "您的預訂被取消或延遲了嗎？閱讀更多",
    labelBookingCancelledPostponedText1: "點擊",
    labelBookingCancelledPostponedText2: "有關更多信息。",
    labelNewRefundHeading: "新的退款申請？",
    labelNewRefundText:"在這裡輸入您的預訂參考...",
    placeholderBookingReference:"預訂參考",
    validationBookingReference:"需要預訂參考",
    validationEmailAsBookingReference: "電子郵件地址不被接受作為預訂參考",
    buttonFindBooking:"查找預訂",
    labelEmailHeading:"已經開始？",
    labelEmailText: "發送提醒電子郵件以檢索您的應用程序。",
    placeholderEmail:"電子郵件",
    buttonEmailReminder:"通過電子郵件提醒",
    labelCustomerName:"客戶名稱",
    validationCustomerName:"必須輸入客戶名稱",
    labelTypeOfBooking:"預訂類型",
    validationTypeOfBooking:"保留類型為必填項",
    labelEventName:"事件名稱",
    labelVenueName:"地名",
    labelFlightNumber:"航班號",
    labelOriginAirport:"起源機場",
    labelHotelName:"住宿名稱",
    labelHotelLocation:"住宿地點",
    labelCurrency:"貨幣",
    validationCurrency: "需要貨幣",
    validationCountryCurrency: "選擇的貨幣必須是您本國的貨幣",
    validationOfflinePayment: "我們無法處理您選擇的貨幣和國家的退款，請選擇替代方案",
    labelBookingValue:"保留值",
    validationBookingValue:"需要保留值",
    validationBookingValueAmount:"必須大於0",
    labelDateOfPurchase:"購買日期",
    validationDateOfPurchase:"需要購買日期",
    labelEmailAddress:"電子郵件地址",
    validationEmailAddress:"電子郵件地址為必填",
    validationEmailAddressFormat:"電子郵件格式不正確",
    labelConfirmEmailAddress:"確認您的電子郵件地址",
    validationConfirmEmailAddressFormat:"電子郵件格式不正確",
    validationConfirmEmailAddress:"需要確認電子郵件地址",
    validationConfirmEmailAddressMatch:"電子郵件地址不匹配",
    validationEmailDomain: "不接受此電子郵件提供商，請使用其他電子郵件提供商",
    validationDateOfEvent:"需要事件的日期",
    labelHomeCountry:"本國",
    validationHomeCountry:"需要原產國",
    labelContactNumberCountryCode:"聯繫電話國家/ 地區代碼",
    validationContactNumberCountryCode:"必須輸入國家 / 地區代碼",
    labelContactNumber:"聯繫電話",
    validationContactNumber:"必須提供聯繫電話",
    labelAddressLine1:"地址1",
    labelAddressLine2:"地址行2",
    labelCity:"城市",
    labelZipCode:"郵政編碼",
    labelRegion:"區域",
    validationRegion:"必須輸入區域",
    labelNumberOfTicketsToRefund:"要退款的票數",
    validationNumberOfTicketsToRefund:"需要退款的票數",
    validationNumberOfTicketsToRefundValue:"必須大於0",
    labelCostPerTicket:"每張票的費用",
    validationCostPerTicket:"每張票的費用是強制性的",
    validationCostPerTicketValue:"必須大於0",
    labelTotalRefundAmount:"退款總額",
    validationTotalRefundAmount:"需要退款總額",
    validationTotalRefundAmountValue: "必須大於0",
    validationTotalRefundGreaterThanBookingValue: "總退款金額不能大於上述預訂金額",
    labelBookingConfirmationFiles:"下載預訂確認書",
    validationBookingConfirmationFiles:"附加至少一個文件或單擊“保存”，然後在擁有文檔後返回",
    validationEvidenceFilesPopup: "提交前需要上傳證據。如果您現在沒有證據，請保存申請並在您擁有後返回。我們接受電子副本、掃描儀副本和照片。",
    validationBookingConfirmationFilesPopup: "提交前需上傳預訂確認信息。如果您現在沒有預訂確認，請保存申請並在獲得後返回。我們接受電子副本、掃描儀副本和照片。",
    alertUploadDocuments: "請上傳電子郵件中詳述的相關文件，然後繼續執行步驟 3。",
    labelRefundReasonCategory:"退款原因",
    validationRefundReasonCategory:"需要退款的原因",
    labelDateOfIncident:"事件日期",
    labelAdditionalComments: "附加評論",
    tooltipAdditionalComments: "附加評論",
    validationAdditionalComments: "需要補充意見",
    labelUnableToNotifyWithinTimeLimit:"無法在60天內通知",
    labelReasonForNotificationOutsideTimeLimit:"超出時間限制的通知說明",
    validateReasonForNotificationOutsideTimeLimit:"需要60天后通知的理由",
    labelRefundReasonFiles:"下載證明",
    validationRefundReasonFiles: "附加至少一個文件或單擊“保存”，然後在擁有文檔後返回",
    validationTextScript: "必須與拉丁文字匹配",
    optionTypeOfBooking1:"一個事件",
    optionTypeOfBooking2:"遊覽",
    optionTypeOfBooking3:"飛行",
    optionTypeOfBooking4:"住宿",
    optionTypeOfBooking5:"傳輸",
    optionTypeOfBooking6: "停車",
    optionTypeOfBooking7: "其他旅行",
    labelEventTypeDate:"事件的日期",
    labelEventTypeTour:"遊覽日期",
    labelEventTypeFlight:"飛行日期",
    labelEventTypeCheckIn:"到達日期",
    labelEventTypeTransfer:"轉讓日期",
    labelEventTypeParking: "停車日期",
    labelEventTypeOtherTravel: "旅行日期",
    optionRefundReason11:"惡劣天氣條件",
    optionRefundReason10:"召回武裝部隊和緊急服務",
    optionRefundReason15: "考試日期更改",
    optionRefundReason18: "法院傳票",
    optionRefundReason17: "2019新冠肺炎旅行限制",
    optionRefundReason19: "COVID-19 陽性測試",
    optionRefundReason20: "運營商/組織者取消預訂",
    optionRefundReason21: "運營商/組織者重新安排預訂",
    optionRefundReason22: "訂錯日期",
    optionRefundReason23: "訂錯票",
    optionRefundReason24: "訂了太多票",
    optionRefundReason4:"死亡",
    optionRefundReason16:"緊急情況",
    optionRefundReason14:"政府禁止旅行",
    optionRefundReason9:"家庭緊急情況",
    optionRefundReason1:"疾病 / 傷害 / 事故",
    optionRefundReason8:"陪審團服務",
    optionRefundReason7:"機械故障",
    optionRefundReason2:"先前存在的醫療狀況",
    optionRefundReason3:"妊娠並發症",
    optionRefundReason5:"公共運輸失敗",
    optionRefundReason6:"航班中斷",
    optionRefundReason13:"文件被盜",
    optionRefundReason12:"工作搬遷",
    optionRefundReason25: "工作場所冗餘",
    optionRefundReason26: "測試選項",
    optionRefundReasonConfirm1: "不",
    optionRefundReasonConfirm2: "是的",
    optionRefundReasonArmedForces1: "軍隊",
    optionRefundReasonArmedForces2: "海軍",
    optionRefundReasonArmedForces3: "空軍",
    optionRefundReasonArmedForces4: "特種部隊",
    optionRefundReasonArmedForces5: "海軍陸戰隊",
    optionRefundReasonArmedForces6: "火災",
    optionRefundReasonArmedForces7: "救護車",
    optionRefundReasonArmedForces8: "醫生/護士",
    optionRefundReasonArmedForces9: "牙醫",
    optionRefundReasonArmedForces10: "其他",
    optionRefundReasonCourtSummons1: "刑事訴訟",
    optionRefundReasonCourtSummons2: "民事法律案件",
    optionRefundReasonCourtSummons3: "控方證人",
    optionRefundReasonCourtSummons4: "民事案件中的證人",
    optionRefundReasonCourtSummons5: "原告",
    optionRefundReasonCourtSummons6: "被告",
    optionRefundReasonCourtSummons7: "其他",
    optionRefundReasonDeath1: "老公",
    optionRefundReasonDeath2: "老婆",
    optionRefundReasonDeath3: "聲音",
    optionRefundReasonDeath4: "女兒",
    optionRefundReasonDeath5: "爸爸",
    optionRefundReasonDeath6: "媽媽",
    optionRefundReasonDeath7: "哥哥",
    optionRefundReasonDeath8: "姐姐",
    optionRefundReasonDeath9: "爺爺",
    optionRefundReasonDeath10: "奶奶",
    optionRefundReasonDeath11: "其他",
    optionRefundReasonHomeEmergency1: "火災",
    optionRefundReasonHomeEmergency2: "洪水",
    optionRefundReasonHomeEmergency3: "搶劫",
    optionRefundReasonHomeEmergency4: "破壞",
    optionRefundReasonHomeEmergency5: "家庭虐待",
    optionRefundReasonHomeEmergency6: "綁架",
    optionRefundReasonHomeEmergency7: "其他",
    optionRefundReasonCOVID19Person1: "本人",
    optionRefundReasonCOVID19Person2: "預訂中的其他人",
    optionRefundReasonCOVID19Person3: "我家中的某個人",
    optionRefundReasonCOVID19Person4: "其他",
    optionRefundReasonCOVID19TestType1: "聚合酶鏈反應檢測",
    optionRefundReasonCOVID19TestType2: "大鼠測試",
    optionRefundReasonCOVID19TestType3: "側向流測試",
    optionRefundReasonCOVID19TestType4: "自我管理測試",
    optionRefundReasonCOVID19TestType5: "沒有測試，但我有癥狀",
    optionRefundReasonCOVID19TestType6: "其他",
    optionRefundReasonBreakdown1: "故障",
    optionRefundReasonBreakdown2: "事故",
    optionRefundReasonBreakdown3: "火災",
    optionRefundReasonBreakdown4: "盜竊",
    optionRefundReasonPublicTransportType1: "火車",
    optionRefundReasonPublicTransportType2: "總線",
    optionRefundReasonPublicTransportType3: "電車",
    optionRefundReasonPublicTransportType4: "船",
    optionRefundReasonDocumentType1: "護照",
    optionRefundReasonDocumentType2: "旅行許可證",
    optionRefundReasonDocumentType3: "國民身份證",
    optionRefundReasonDocumentType4: "票",
    optionRefundReasonWorkRelocationType1: "臨時",
    optionRefundReasonWorkRelocationType2: "永久",
    optionRefundReasonWorkRelocationTemporary1: "0-3個月",
    optionRefundReasonWorkRelocationTemporary2: "3-6個月",
    optionRefundReasonWorkRelocationTemporary3: "6個月以上",
    optionRefundReasonBookingRelationshipType1: "預訂中的某人",
    optionRefundReasonBookingRelationshipType2: "預訂中某人的直系親屬",
    optionEmploymentType1: "全職",
    optionEmploymentType2: "兼職",
    optionEmploymentType3: "合同",
    optionEmploymentLengthType1: "0-1 歲",
    optionEmploymentLengthType2: "1-2 歲",
    optionEmploymentLengthType3: "2年以上",
    textRefundEvidenceHeading:"請提供以下證據:",
    textRefundEvidence1: "航空公司確認",
    textRefundEvidence2: "任何適當的支持證據",
    textRefundEvidence3: "故障服務呼叫確認",
    textRefundEvidence4: "死亡證明書",
    textRefundEvidence5: "航空公司確認取消",
    textRefundEvidence6: "氣象報告",
    textRefundEvidence7: "預訂代理的信函確認無法重新出票",
    textRefundEvidence8: "法院信函",
    textRefundEvidence9: "醫療證明/醫生證明",
    textRefundEvidenceDescription9: "確認疾病或受傷的詳細資訊，即首次發生的日期，以防止您參加預訂。",
    textRefundEvidence10: "雇主信函確認",
    textRefundEvidence11: "保險索賠信函",
    textRefundEvidence12: "雇主信函",
    textRefundEvidence13: "國家政府網站確認",
    textRefundEvidence14: "考試變更通知",
    textRefundEvidence15: "警方報告",
    textRefundEvidence16: "警方報告和參考編號",
    textRefundEvidence17: "傳輸供應商確認",
    textRefundEvidence18: "旅行警告",
    textRefundEvidence20: "新冠肺炎檢測證書",
    textRefundEvidence24: "政府隔離通知",
    textRefundEvidence25: "指揮官的信",
    textRefundEvidence26: "保險公司的信函",
    textRefundEvidence27: "地址證明",
    textRefundEvidence28: "與死者關係的證明",
    textRefundEvidence29: "與受傷/病人的關係證明",
    textRefundEvidence30: "工資單",
    tooltipBookingReference: "若要識別您的預訂參考號，請檢查您的預訂確認資訊並查看有關我們的擴充服務的部分。",
    tooltipCustomerName: "用於預訂的名字和姓氏。",
    tooltipTypeOfBooking: "您想要退款的預訂類型。",
    tooltipEventName: "事件的名稱。",
    tooltipVenueName: "場地名稱。",
    tooltipFlightNumber: "航班號。",
    tooltipOriginAirport: "出發機場。",
    tooltipHotelName: "住宿名稱。",
    tooltipHotelLocation: "住宿的位置。",
    tooltipCurrency: "用於支付預訂的貨幣。",
    tooltipBookingValue: "您為預訂支付的總價。",
    tooltipDateOfPurchase: "預訂的日期。",
    tooltipEmailAddress: "請使用有效的電子郵件地址。在退款申請過程中，我們將使用此電子郵件進行通信。",
    tooltipConfirmEmailAddress: "使用與上述相同的電子郵件地址，用於驗證。",
    tooltipHomeCountry: "這將幫助我們在最後一步顯示正確的銀行信息。",
    tooltipContactNumberCountryCode: "聯繫電話國家/地區代碼。",
    tooltipContactNumber: "請使用有效號碼。我們在退款申請過程中將其用於通信。",
    tooltipAddressLine1: "請注意，驗證銀行帳戶時將使用您的地址。",
    tooltipAddressLine2: "地址的第二行。",
    tooltipCity: "您的居住城市的詳細信息可用於驗證銀行詳細信息。",
    tooltipZipCode: "郵政編碼/郵政編碼。",
    tooltipRegion: "您所在城市的地區，詳細信息可用於驗證銀行詳細信息。",
    tooltipNumberOfTicketsToRefund: "您想退款的票數。",
    tooltipTotalRefundAmount: "這不能超過總預訂價值，否則將無法提交申請。",
    tooltipBookingConfirmationFiles: "您可以在電子郵件中找到完整預訂確認的屏幕截圖/附件。",
    tooltipRefundReasonCategory: "請選擇退款原因。",
    tooltipDateOfIncident: "影響您的預訂的事件發生的日期。",
    tooltipRefundExplanation: "請提供盡可能多的詳細信息以支持退款要求。",
    tooltipUnableToNotifyWithinTimeLimit: "無法在60天內通知。",
    tooltipReasonForNotificationOutsideTimeLimit: "超出時間限制的通知說明。",
    tooltipRefundReasonFiles: "請上傳任何所需的文件。",
    tooltipEventTypeDate: "實際事件/預訂的日期。",
    tooltipEventTypeTour: "實際遊覽的日期。",
    tooltipEventTypeFlight: "實際航班的日期。",
    tooltipEventTypeCheckIn: "實際入住日期。",
    tooltipEventTypeTransfer: "實際轉帳日期。",
    tooltipEventTypeParking: "實際停車的日期。",
    tooltipEventTypeOtherTravel: "實際行程的開始日期。"
};