import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {errorInvalidCPFCode} from '../utils/constants';
import {AlertHolder} from './AlertHolder';

export const PaymentInformationAlert = (): JSX.Element => {

    const {
        paymentErrors
    } = useFormContext();

    const {t} = useTranslation();

    const PaymentErrorMessages = () => {

        const getPaymentErrors = () => {
            if (paymentErrors?.some(x => x.message?.toLocaleLowerCase().includes(errorInvalidCPFCode))) {
                return <li key={`payment-error-cpf`}>{t('payment:errorCPFCode')}</li>
            }
            return paymentErrors?.map(x => <li key={`payment-error-${x.code}`}>{x.message}</li>);
        }

        return (
            <ul>
                {getPaymentErrors()}
            </ul>
        );
    }

    const OnlyBankAccountsAcceptedMessage = () => {
        return (
            <Box>{t('payment:messageOnlyBankAccountsAccepted')}</Box>
        );
    }

    return (
        <>
            {paymentErrors?.length > 0 &&
                <AlertHolder
                    severity='error'
                    content={<PaymentErrorMessages/>}
                />
            }

            <AlertHolder
                severity='info'
                content={<OnlyBankAccountsAcceptedMessage/>}
            />
        </>
    );
}