export default {
    labelBookingCancelledPostponedHeading: "តើការកក់របស់អ្នកត្រូវបានលុបចោល ឬពន្យារពេលទេ? អានបន្ថែម",
    labelBookingCancelledPostponedText1: "ចុច",
    labelBookingCancelledPostponedText2: "សម្រាប់ព័ត៌មានបន្ថែម.",
    labelNewRefundHeading: "កម្មវិធីសងប្រាក់ថ្មី។?",
    labelNewRefundText: "បញ្ចូលឯកសារយោងការកក់របស់អ្នកនៅទីនេះ...",
    placeholderBookingReference: "ឯកសារយោងការកក់",
    validationBookingReference: "ឯកសារយោងការកក់ត្រូវបានទាមទារ",
    validationEmailAsBookingReference: "អាសយដ្ឋានអ៊ីមែលមិនត្រូវបានទទួលយកជាឯកសារយោងការកក់ទេ។",
    buttonFindBooking: "ស្វែងរកការកក់",
    labelEmailHeading: "បានចាប់ផ្តើមរួចហើយ?",
    labelEmailText: "ផ្ញើការរំលឹកតាមអ៊ីមែល ដើម្បីទាញយកកម្មវិធីរបស់អ្នក។.",
    placeholderEmail: "អ៊ីមែល",
    buttonEmailReminder: "អ៊ីមែលរំលឹក",
    labelCustomerName : "ឈ្មោះ​អតិថិជន",
    validationCustomerName: "ឈ្មោះអតិថិជនត្រូវបានទាមទារ",
    labelTypeOfBooking: "ប្រភេទនៃការកក់",
    validationTypeOfBooking: "ប្រភេទនៃការកក់ត្រូវបានទាមទារ",
    labelEventName:"មោះព្រឹត្តិការណ៍",
    labelVenueName:"ឈ្មោះទីកន្លែង",
    labelFlightNumber:"លេខជើងហោះហើរ",
    labelOriginAirport: "អាកាសយានដ្ឋានដើម",
    labelHotelName: "ឈ្មោះកន្លែងស្នាក់នៅ",
    labelHotelLocation: "ទីតាំងស្នាក់នៅ",
    labelCurrency: "រូបិយប័ណ្ណ",
    validationCurrency: "រូបិយប័ណ្ណត្រូវបានទាមទារ",
    validationCountryCurrency: "រូបិយប័ណ្ណដែលបានជ្រើសរើសត្រូវតែជារូបិយប័ណ្ណនៃប្រទេសដើមរបស់អ្នក។",
    validationOfflinePayment: "យើងមិនអាចដំណើរការការសងប្រាក់វិញសម្រាប់ជម្រើសរូបិយប័ណ្ណ និងប្រទេសរបស់អ្នកទេ សូមជ្រើសរើសជម្រើសផ្សេងទៀត។",
    labelBookingValue: "តម្លៃកក់",
    validationBookingValue: "តម្លៃកក់ត្រូវបានទាមទារ",
    validationBookingValueAmount: "ត្រូវតែធំជាង 0",
    labelDateOfPurchase: "កាលបរិច្ឆេទ​នៃ​ការ​ទិញ",
    validationDateOfPurchase: "កាលបរិច្ឆេទនៃការទិញត្រូវបានទាមទារ",
    labelEmailAddress: "អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល",
    validationEmailAddress: "អាសយដ្ឋានអ៊ីមែលត្រូវបានទាមទារ",
    validationEmailAddressFormat: "ទម្រង់អ៊ីមែលមិនត្រឹមត្រូវ",
    labelConfirmEmailAddress: "បញ្ជាក់អាសយដ្ឋានអ៊ីមែល",
    validationConfirmEmailAddressFormat: "ទម្រង់អ៊ីមែលមិនត្រឹមត្រូវ",
    validationConfirmEmailAddress: "អាសយដ្ឋានអ៊ីមែលបញ្ជាក់គឺត្រូវបានទាមទារ",
    validationConfirmEmailAddressMatch: "អាសយដ្ឋានអ៊ីមែលមិនត្រូវគ្នាទេ។",
    validationDateOfEvent: "កាលបរិច្ឆេទនៃព្រឹត្តិការណ៍ត្រូវបានទាមទារ",
    validationEmailDomain: "អ្នកផ្តល់អ៊ីមែលនេះមិនត្រូវបានទទួលយកទេ សូមប្រើជម្រើសជំនួស",
    labelHomeCountry: "ប្រទេសដើម",
    validationHomeCountry: "ប្រទេសដើមត្រូវបានទាមទារ",
    labelContactNumberCountryCode: "លេខទំនាក់ទំនង លេខកូដប្រទេស",
    validationContactNumberCountryCode: "តម្រូវ​ឱ្យ​មាន​លេខ​កូដ​ហៅ​ទូរសព្ទ​ប្រទេស",
    labelContactNumber: "លេខ​ទំនាក់​ទំនង",
    validationContactNumber: "ត្រូវការលេខទំនាក់ទំនង",
    labelAddressLine1: "អាសយដ្ឋាន​បន្ទាត់​ទី 1",
    labelAddressLine2: "អ​ស​យ​ដ្ឋាន​ខ្សែ​រ​ទី 2",
    labelCity: "ទីក្រុង",
    labelZipCode: "លេខ​កូដ​តំបន់",
    labelRegion: "តំបន់",
    validationRegion: "តំបន់ត្រូវបានទាមទារ",
    labelNumberOfTicketsToRefund: "ចំនួនសំបុត្រដែលត្រូវសងប្រាក់វិញ។",
    validationNumberOfTicketsToRefund: "ចំនួនសំបុត្រដែលត្រូវសងប្រាក់វិញគឺត្រូវបានទាមទារ",
    validationNumberOfTicketsToRefundValue: "ត្រូវតែធំជាង 0",
    labelCostPerTicket: "តម្លៃសំបុត្រនីមួយៗ",
    validationCostPerTicket: "ថ្លៃដើមសំបុត្រគឺចាំបាច់",
    validationCostPerTicketValue: "ត្រូវតែធំជាង 0",
    labelTotalRefundAmount: "ចំនួនទឹកប្រាក់បង្វិលសងសរុប",
    validationTotalRefundAmount: "តម្រូវ​ឱ្យ​មាន​ចំនួន​ប្រាក់​បង្វិល​សរុប",
    validationTotalRefundAmountValue: "ត្រូវតែធំជាង 0",
    validationTotalRefundGreaterThanBookingValue: "ចំនួនទឹកប្រាក់បង្វិលសងសរុបមិនអាចធំជាងតម្លៃកក់ខាងលើទេ។",
    labelBookingConfirmationFiles: "ផ្ទុកឡើងការបញ្ជាក់ការកក់",
    validationBookingConfirmationFiles: "ភ្ជាប់ឯកសារយ៉ាងហោចណាស់មួយ ឬចុច រក្សាទុក ហើយត្រឡប់ពេលក្រោយ នៅពេលដែលអ្នកមានឯកសារ",
    validationEvidenceFilesPopup: "ភស្តុតាង​ត្រូវ​បាន​តម្រូវ​ឱ្យ​ត្រូវ​បាន​ផ្ទុក​ឡើង​មុន​ពេល​បញ្ជូន​។ ប្រសិនបើអ្នកមិនមានភ័ស្តុតាងនៅពេលនេះទេ សូមរក្សាទុកពាក្យសុំ ហើយត្រលប់មកវិញនៅពេលដែលអ្នកមាន។ យើងទទួលយកច្បាប់ចម្លងអេឡិចត្រូនិច ច្បាប់ចម្លងម៉ាស៊ីនស្កេន និងរូបថត.",
    validationBookingConfirmationFilesPopup: "ការបញ្ជាក់ការកក់គឺតម្រូវឱ្យផ្ទុកឡើងមុនពេលដាក់ស្នើ។ ប្រសិនបើអ្នកមិនមានការបញ្ជាក់ការកក់ឥឡូវនេះទេ សូមរក្សាទុកពាក្យសុំ ហើយត្រលប់មកវិញនៅពេលដែលអ្នកមាន។ យើងទទួលយកច្បាប់ចម្លងអេឡិចត្រូនិច ច្បាប់ចម្លងម៉ាស៊ីនស្កេន និងរូបថត។",
    alertUploadDocuments: "សូមបង្ហោះឯកសារពាក់ព័ន្ធលម្អិតនៅក្នុងអ៊ីមែល ហើយបន្ទាប់មកបន្តទៅជំហានទី 3 ។",
    labelRefundReasonCategory: "ហេតុផលសងប្រាក់វិញ។",
    validationRefundReasonCategory: "ទាមទារហេតុផលសងប្រាក់វិញ។",
    labelDateOfIncident: "កាលបរិច្ឆេទនៃឧប្បត្តិហេតុ",
    labelAdditionalComments: "យោបល់​បន្ថែម",
    tooltipAdditionalComments: "យោបល់​បន្ថែម",
    validationAdditionalComments: "មតិយោបល់បន្ថែមត្រូវបានទាមទារ",
    labelUnableToNotifyWithinTimeLimit: "មិនអាចជូនដំណឹងក្នុងរយៈពេល 60 ថ្ងៃចាប់ពីថ្ងៃ",
    labelReasonForNotificationOutsideTimeLimit: "ការជូនដំណឹងក្រៅម៉ោង ការពន្យល់",
    validationReasonForNotificationOutsideTimeLimit: "ហេតុផលសម្រាប់ការជូនដំណឹងបន្ទាប់ពី 60 ថ្ងៃគឺត្រូវបានទាមទារ",
    labelRefundReasonFiles: "ផ្ទុកឡើងភស្តុតាង",
    validationRefundReasonFiles: "ភ្ជាប់ឯកសារយ៉ាងហោចណាស់មួយ ឬចុច រក្សាទុក ហើយត្រឡប់ពេលក្រោយ នៅពេលដែលអ្នកមានឯកសារ",
    validationTextScript: "ត្រូវតែផ្គូផ្គងអក្សរឡាតាំង",
    optionTypeOfBooking1: "ព្រឹត្តិការណ៍",
    optionTypeOfBooking2: "ដំណើរកំសាន្ត",
    optionTypeOfBooking3: "ជើងហោះហើរ",
    optionTypeOfBooking4: "កន្លែងស្នាក់នៅ",
    optionTypeOfBooking5: "ផ្ទេរ",
    optionTypeOfBooking6: "ចំណតរថយន្ត",
    optionTypeOfBooking7: "ការធ្វើដំណើរផ្សេងទៀត។",
    labelEventTypeDate: "កាលបរិច្ឆេទនៃព្រឹត្តិការណ៍",
    labelEventTypeTour: "កាលបរិច្ឆេទនៃដំណើរកម្សាន្",
    labelEventTypeFlight: "កាលបរិច្ឆេទនៃការហោះហើរ",
    labelEventTypeCheckIn: "កាលបរិច្ឆេទនៃការចុះឈ្មោះចូល",
    labelEventTypeTransfer: "កាលបរិច្ឆេទផ្ទេរ",
    labelEventTypeParking: "កាលបរិច្ឆេទនៃការចតរថយន្ត",
    labelEventTypeOtherTravel: "កាលបរិច្ឆេទនៃការធ្វើដំណើរ",
    optionRefundReason11: "អាកាសធាតុអាក្រក់",
    optionRefundReason10: "ការប្រមូលកងកម្លាំងប្រដាប់អាវុធ និងសេវាសង្គ្រោះបន្ទាន់",
    optionRefundReason15: "ការផ្លាស់ប្តូរកាលបរិច្ឆេទប្រឡង",
    optionRefundReason18: "តុលាការកោះហៅ",
    optionRefundReason17: "ការ​ដាក់កំហិត​លើ​ការ​ធ្វើដំណើរ​អំឡុង​ពេល​មាន​ជំងឺ covid​-19",
    optionRefundReason19: "តេស្តវិជ្ជមាន COVID-19",
    optionRefundReason20: "ការកក់ត្រូវបានលុបចោលដោយប្រតិបត្តិករ/អ្នករៀបចំ",
    optionRefundReason21: "ការកក់ត្រូវកំណត់ពេលឡើងវិញដោយប្រតិបត្តិករ/អ្នករៀបចំ",
    optionRefundReason22: "កាលបរិច្ឆេទដែលបានកក់ខុស",
    optionRefundReason23: "កក់សំបុត្រខុស",
    optionRefundReason24: "កក់សំបុត្រច្រើនពេក",
    optionRefundReason4: "ការស្លាប់",
    optionRefundReason16: "ស្ថានភាពអាសន្ន",
    optionRefundReason14: "ការហាមឃាត់ការធ្វើដំណើររបស់រដ្ឋាភិបាល",
    optionRefundReason9: "ផ្ទះសង្គ្រោះបន្ទាន់",
    optionRefundReason1: "ជំងឺ/របួស/គ្រោះថ្នាក់",
    optionRefundReason8: "សេវាកម្មគណៈវិនិច្ឆ័យ",
    optionRefundReason7: "ការបំបែកមេកានិច",
    optionRefundReason2: "លក្ខខណ្ឌវេជ្ជសាស្ត្រដែលមានស្រាប់",
    optionRefundReason3: "ផលវិបាកនៃការមានផ្ទៃពោះ",
    optionRefundReason5: "ការបរាជ័យនៃការដឹកជញ្ជូនសាធារណៈ",
    optionRefundReason6: "ការរំខានការហោះហើរ",
    optionRefundReason13: "ការលួចសំបុត្រ",
    optionRefundReason12: "ការផ្លាស់ទីលំនៅការងារ",
    optionRefundReason25: "កន្លែងធ្វើការហួសកម្រិត",
    optionRefundReason26: "ជម្រើសសាកល្បង",
    optionRefundReasonConfirm1: "ទេ",
    optionRefundReasonConfirm2: "បាទ/ចាស",
    optionRefundReasonArmedForces1: "កង ទ័ព",
    optionRefundReasonArmedForces2: "កង ទ័ព ជើង ទឹក",
    optionRefundReasonArmedForces3: "កង កម្លាំង អាកាស",
    optionRefundReasonArmedForces4: "កង កម្លាំង ពិសេស",
    optionRefundReasonArmedForces5: "កង ទ័ព ជើង ទឹក",
    optionRefundReasonArmedForces6: "ភ្លើង ឆេះ",
    optionRefundReasonArmedForces7: "សង្គ្រោះបន្ទាន់",
    optionRefundReasonArmedForces8: "វេជ្ជបណ្ឌិត/គិលានុបដ្ឋាយិកា",
    optionRefundReasonArmedForces9: "ធ្មេញធ្មេញ",
    optionRefundReasonArmedForces10: "ផ្សេងទៀត",
    optionRefundReasonCourtSummons1: "ក្រមព្រហ្មទណ្ឌ",
    optionRefundReasonCourtSummons2: "សំណុំរឿង រដ្ឋប្បវេណី",
    optionRefundReasonCourtSummons3: "សាក្សី ក្នុង ការ កាត់ ទោស",
    optionRefundReasonCourtSummons4: "សាក្សី ក្នុង សំណុំ រឿង រដ្ឋប្បវេណី",
    optionRefundReasonCourtSummons5: "ដើមបណ្តឹង",
    optionRefundReasonCourtSummons6: "ជនជាប់ចោទ",
    optionRefundReasonCourtSummons7: "ផ្សេងទៀត",
    optionRefundReasonDeath1: "ប្តី",
    optionRefundReasonDeath2: "ប្រពន្ធ",
    optionRefundReasonDeath3: "សំឡេង",
    optionRefundReasonDeath4: "កូនស្រី",
    optionRefundReasonDeath5: "ប៉ា",
    optionRefundReasonDeath6: "ម៉ាក់",
    optionRefundReasonDeath7: "បងប្រុស",
    optionRefundReasonDeath8: "បងស្រី",
    optionRefundReasonDeath9: "លោកតា",
    optionRefundReasonDeath10: "លោកយាយ",
    optionRefundReasonDeath11: "ផ្សេងទៀត",
    optionRefundReasonHomeEmergency1: "ភ្លើង ឆេះ",
    optionRefundReasonHomeEmergency2: "ទឹកជំនន់",
    optionRefundReasonHomeEmergency3: "ប្លន់",
    optionRefundReasonHomeEmergency4: "ការ បំផ្លិច បំផ្លាញ",
    optionRefundReasonHomeEmergency5: "ការរំលោភបំពានក្នុងគ្រួសារ",
    optionRefundReasonHomeEmergency6: "Kidnap",
    optionRefundReasonHomeEmergency7: "ផ្សេងទៀត",
    optionRefundReasonCOVID19Person1: "ខ្លួនខ្ញុំផ្ទាល់",
    optionRefundReasonCOVID19Person2: "មនុស្ស ម្នាក់ ទៀត នៅ ក្នុង ការ កក់",
    optionRefundReasonCOVID19Person3: "មនុស្ស ម្នាក់ ក្នុង គ្រួសារ ខ្ញុំ",
    optionRefundReasonCOVID19Person4: "ផ្សេងទៀត",
    optionRefundReasonCOVID19TestType1: "ការធ្វើតេស្ត PCR",
    optionRefundReasonCOVID19TestType2: "ការធ្វើតេស្ត RAT",
    optionRefundReasonCOVID19TestType3: "ការធ្វើតេស្តលំហូរ Lateral",
    optionRefundReasonCOVID19TestType4: "ការសាកល្បងដែលគ្រប់គ្រងដោយខ្លួនឯង",
    optionRefundReasonCOVID19TestType5: "គ្មាន ការ សាកល្បង ទេ ប៉ុន្តែ ខ្ញុំ មាន រោគ សញ្ញា",
    optionRefundReasonCOVID19TestType6: "ផ្សេងទៀត",
    optionRefundReasonBreakdown1: "បែកធ្លាយ",
    optionRefundReasonBreakdown2: "គ្រោះថ្នាក់ចរាចរណ៍",
    optionRefundReasonBreakdown3: "ភ្លើង ឆេះ",
    optionRefundReasonBreakdown4: "ចោរលួច",
    optionRefundReasonPublicTransportType1: "រថភ្លើង",
    optionRefundReasonPublicTransportType2: "ឡានក្រុង",
    optionRefundReasonPublicTransportType3: "ត្រាំ",
    optionRefundReasonPublicTransportType4: "ទូក",
    optionRefundReasonDocumentType1: "លិខិត ឆ្លង ដែន",
    optionRefundReasonDocumentType2: "លិខិតអនុញ្ញាតធ្វើដំណើរ",
    optionRefundReasonDocumentType3: "អត្តសញ្ញាណប័ណ្ណជាតិ",
    optionRefundReasonDocumentType4: "សំបុត្រ",
    optionRefundReasonWorkRelocationType1: "បណ្តោះអាសន្ន",
    optionRefundReasonWorkRelocationType2: "អចិន្រ្តៃយ៍",
    optionRefundReasonWorkRelocationTemporary1: "០-៣ ខែ",
    optionRefundReasonWorkRelocationTemporary2: "៣-៦ ខែ",
    optionRefundReasonWorkRelocationTemporary3: "ជាង៦ខែ",
    optionRefundReasonBookingRelationshipType1: "នរណា ម្នាក់ ក្នុង ការ កក់",
    optionRefundReasonBookingRelationshipType2: "សាច់ញាតិ របស់ នរណា ម្នាក់ ភ្លាមៗ ក្នុង ការ កក់",
    optionEmploymentType1: "ពេលវេលាពេញ",
    optionEmploymentType2: "ពេល វេលា ផ្នែក",
    optionEmploymentType3: "កិច្ចសន្យា",
    optionEmploymentLengthType1: "០-១ ឆ្នាំ",
    optionEmploymentLengthType2: "១-២ ឆ្នាំ",
    optionEmploymentLengthType3: "ជាង ២ ឆ្នាំ",
    textRefundEvidenceHeading: "សូមផ្តល់ភស្តុតាងដូចខាងក្រោមៈ",
    textRefundEvidence1: "ការបញ្ជាក់របស់ក្រុមហ៊ុនអាកាសចរណ៍",
    textRefundEvidence2: "ភស្តុតាង គាំទ្រ សមរម្យ ណា មួយ",
    textRefundEvidence3: "សេវា បែកបាក់ អំពាវនាវ ឲ្យ មាន ការ បញ្ជាក់",
    textRefundEvidence4: "វិញ្ញាបនបត្រមរណភាព",
    textRefundEvidence5: "ការ លុប ចោល ការ បញ្ជាក់ ពី ក្រុម ហ៊ុន អាកាសចរណ៍",
    textRefundEvidence6: "របាយការណ៍អាកាសធាតុ",
    textRefundEvidence7: "ការ ឆ្លើយ តប ពី ភ្នាក់ងារ កក់ ដែល បញ្ជាក់ ថា គ្មាន សមត្ថ ភាព ក្នុង ការ ចេញ សំបុត្រ ឡើង វិញ ទេ",
    textRefundEvidence8: "លិខិតពីតុលាការ",
    textRefundEvidence9: "វិញ្ញាបនបត្រវេជ្ជសាស្រ្ត / កំណត់ត្រារបស់វេជ្ជបណ្ឌិត",
    textRefundEvidenceDescription9: "ការ បញ្ជាក់ លម្អិត អំពី ជំងឺ ឬ របួស កាល បរិច្ឆេទ ដែល វា បាន កើត ឡើង ជា លើក ដំបូង ដែល វា រា រាំង អ្នក មិន ឲ្យ ចូល រួម ការ កក់ ។",
    textRefundEvidence10: "ការ បញ្ជាក់ លិខិត និយោជក",
    textRefundEvidence11: "ការ ឆ្លើយ តប ការ ទាម ទារ ធានា រ៉ាប់ រង",
    textRefundEvidence12: "លិខិតពីនិយោជក",
    textRefundEvidence13: "ការ បញ្ជាក់ ពី គេហទំព័រ របស់ រដ្ឋាភិបាល ជាតិ",
    textRefundEvidence14: "សេចក្ដីជូនដំណឹងស្តីពីការផ្លាស់ប្តូរការប្រឡង",
    textRefundEvidence15: "របាយការណ៍ របស់ ប៉ូលិស",
    textRefundEvidence16: "របាយការណ៍ និង លេខ យោង របស់ ប៉ូលីស",
    textRefundEvidence17: "ការ បញ្ជាក់ របស់ អ្នក ផ្តល់ សេវា ដឹក ជញ្ជូន",
    textRefundEvidence18: "ការ ព្រមាន អំពី ការ ធ្វើ ដំណើរ",
    textRefundEvidence20: "COVID-19 វិញ្ញាបនបត្រប្រឡង",
    textRefundEvidence24: "សេចក្តី ជូន ដំណឹង ផ្តាច់ ខ្លួន របស់ រដ្ឋាភិបាល",
    textRefundEvidence25: "លិខិតពីមន្ត្រីបញ្ជាការ",
    textRefundEvidence26: "លិខិតពី Insurers",
    textRefundEvidence27: "ភស្តុតាងនៃអាសយដ្ឋាន",
    textRefundEvidence28: "ភស្តុតាង នៃ ទំនាក់ទំនង ទៅ នឹង អ្នក ស្លាប់",
    textRefundEvidence29: "ភស្តុតាង នៃ ទំនាក់ទំនង ទៅ នឹង មនុស្ស ដែល រង របួស/ill",
    textRefundEvidence30: "ប័ណ្ណប្រាក់បៀវត្សរ៍",
    tooltipBookingReference: "ដើម្បីកំណត់អត្តសញ្ញាណឯកសារយោងការកក់របស់អ្នក សូមពិនិត្យមើលការបញ្ជាក់ការកក់របស់អ្នក ហើយមើលផ្នែកអំពីសេវាកម្មបន្ថែមរបស់យើង។",
    tooltipCustomerName: "នាមខ្លួន និងនាមត្រកូលដែលប្រើដើម្បីធ្វើការបញ្ជាទិញ.",
    tooltipTypeOfBooking: "ប្រភេទនៃការកក់ដែលអ្នកចង់សងប្រាក់វិញ។.",
    tooltipEventName: "ឈ្មោះព្រឹត្តិការណ៍.",
    tooltipVenueName: "ឈ្មោះទីកន្លែង.",
    tooltipFlightNumber: "លេខជើងហោះហើរ.",
    tooltipOriginAirport: "អាកាសយានដ្ឋានចាកចេញ.",
    tooltipHotelName: "ឈ្មោះកន្លែងស្នាក់នៅ.",
    tooltipHotelLocation: "ទីតាំងនៃកន្លែងស្នាក់នៅ.",
    tooltipCurrency: "រូបិយបណ្ណដែលប្រើសម្រាប់ចំណាយសម្រាប់ការកក់.",
    tooltipBookingValue: "តម្លៃសរុបដែលអ្នកបានបង់សម្រាប់ការកក់.",
    tooltipDateOfPurchase: "កាលបរិច្ឆេទដែលការកក់ត្រូវបានធ្វើឡើង.",
    tooltipEmailAddress: "សូមប្រើអាសយដ្ឋានអ៊ីមែលត្រឹមត្រូវ។ យើងប្រើអ៊ីមែលនេះសម្រាប់ការឆ្លើយឆ្លងអំឡុងពេលកម្មវិធីសងប្រាក់វិញ។.",
    tooltipConfirmEmailAddress: "ប្រើអាសយដ្ឋានអ៊ីមែលដូចខាងលើ នេះគឺសម្រាប់សុពលភាព.",
    tooltipHomeCountry: "វានឹងជួយយើងនាំយកព័ត៌មានធនាគារត្រឹមត្រូវនៅលើជំហានចុងក្រោយ.",
    tooltipContactNumberCountryCode: "លេខ​កូដ​ប្រទេស​ទំនាក់ទំនង.",
    tooltipContactNumber: "សូមប្រើលេខត្រឹមត្រូវ។ យើងប្រើវាសម្រាប់ការឆ្លើយឆ្លងអំឡុងពេលកម្មវិធីសងប្រាក់វិញ។.",
    tooltipAddressLine1: "សូមចំណាំថាអាសយដ្ឋានរបស់អ្នកនឹងត្រូវបានប្រើនៅពេលផ្ទៀងផ្ទាត់គណនីធនាគាររបស់អ្នក។.",
    tooltipAddressLine2: "ជួរទីពីរនៃអាសយដ្ឋាន.",
    tooltipCity: "ទីក្រុងកំណើតរបស់អ្នក ព័ត៌មានលម្អិតអាចត្រូវបានប្រើសម្រាប់ការបញ្ជាក់ព័ត៌មានលម្អិតអំពីធនាគារ.",
    tooltipZipCode: "លេខកូដប្រៃសណីយ៍ / លេខកូដប្រៃសណីយ៍.",
    tooltipRegion: "តំបន់នៃទីក្រុងកំណើតរបស់អ្នក ព័ត៌មានលម្អិតអាចត្រូវបានប្រើសម្រាប់បញ្ជាក់ព័ត៌មានលម្អិតអំពីធនាគារ.",
    tooltipNumberOfTicketsToRefund: "ចំនួនសំបុត្រដែលអ្នកចង់សងប្រាក់វិញ។.",
    tooltipTotalRefundAmount: "នេះមិនអាចលើសពីតម្លៃកក់សរុបទេ អ្នកនឹងមិនអាចដាក់ពាក្យសុំបានទេប្រសិនបើវាកើតឡើង.",
    tooltipBookingConfirmationFiles: "រូបថតអេក្រង់/ឯកសារភ្ជាប់នៃការបញ្ជាក់ការកក់ពេញលេញរបស់អ្នក ដែលជាធម្មតាអាចរកបាននៅក្នុងអ៊ីមែលរបស់អ្នក។.",
    tooltipRefundReasonCategory: "សូមជ្រើសរើសហេតុផលរបស់អ្នកសម្រាប់ការសងប្រាក់វិញ។.",
    tooltipDateOfIncident: "កាលបរិច្ឆេទនៃឧប្បត្តិហេតុដែលប៉ះពាល់ដល់ការកក់របស់អ្នកបានកើតឡើង.",
    tooltipRefundExplanation: "សូមផ្តល់ព័ត៌មានលម្អិតតាមដែលអាចធ្វើទៅបាន ដែលគាំទ្រការទាមទារសំណង.",
    tooltipUnableToNotifyWithinTimeLimit: "មិនអាចជូនដំណឹងក្នុងរយៈពេលកំណត់ 60 ថ្ងៃបានទេ។.",
    tooltipReasonForNotificationOutsideTimeLimit: "ការជូនដំណឹងក្រៅម៉ោង ការពន្យល់.",
    tooltipRefundReasonFiles: "សូមបង្ហោះឯកសារដែលត្រូវការណាមួយ។.",
    tooltipEventTypeDate: "កាលបរិច្ឆេទនៃព្រឹត្តិការណ៍ពិត / ការកក់.",
    tooltipEventTypeTour: "កាលបរិច្ឆេទនៃដំណើរកម្សាន្តពិតប្រាកដ.",
    tooltipEventTypeFlight: "កាលបរិច្ឆេទនៃការហោះហើរពិតប្រាកដ.",
    tooltipEventTypeCheckIn: "កាលបរិច្ឆេទនៃការចុះឈ្មោះពិតប្រាកដ.",
    tooltipEventTypeTransfer: "កាលបរិច្ឆេទនៃការផ្ទេរប្រាក់ពិតប្រាកដ.",
    tooltipEventTypeParking: "កាលបរិច្ឆេទនៃការចតរថយន្តពិតប្រាកដ.",
    tooltipEventTypeOtherTravel: "កាលបរិច្ឆេទចាប់ផ្តើមនៃការធ្វើដំណើរពិតប្រាកដ."
};