export default {
    headingAdverseWeather: "Virallinen taho (esim. paikallisviranomainen, poliisi jne.) on määrännyt tapahtumaan matkustamisen vaaralliseksi vakavien epäsuotuisten sääolosuhteiden takia. Ja minulla on todisteita tämän todistamiseksi?",
    headingAdverseWeatherDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    tooltipRefundReasonConfirm: "Kyllä vai ei",
    labelBookingCancelledByOperator: "Palvelun tarjoaja/järjestäjä peruutti liput.",
    labelBookingRescheduledByOperator: "Palvelun tarjoaja/järjestäjä siirsi päivämäärää, enkä voi osallistua uutena päivämääränä.",
    labelBookedWrongDate: "Minä tai varauksen jäsen tein virheen ostaessani lippuja ja varasin väärän päivämäärän.",
    labelBookedWrongTickets: "Minä tai varauksen jäsen tein virheen ostaessani lippuja ja varasin väärät liput.",
    labelBookedTooManyTickets: "Minä tai varauksen jäsen tein virheen ostaessani lippuja ja varasin liian monta lippua.",
    labelCOVID19TravelRestrictions: "Minä tai varauksen jäsen emme voi osallistua COVID-19-matkustuskiellon vuoksi.",
    labelADWrongSelection: "Olet ehkä valinnut väärän syyn hyvitykselle. Tarkista hyvityksen syy uudelleen avattavasta palautussyyluettelosta.",
    labelAdverseWeather1: "Miksi epäsuotuisa sää tarkoitti, ettet voinut osallistua tapahtumaan?",
    tooltipAdverseWeather1: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelAdverseWeather2: "Tapahtuman päivämäärä",
    tooltipAdverseWeather2: "Valitse",
    headingArmedForces: "Työnantajasi, joka on puolustusvoimat tai pelastuslaitos, pyytää sinua tulemaan töihin, kun sinun ei alun perin ollut tarkoitus tehdä niin, ja tämä estää sinua osallistumasta tapahtumaan?",
    headingArmedForcesDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelArmedForces1: "Missä joukoissa tai hätäpalvelussa työskentelet?",
    tooltipArmedForces1: "Valitse",
    labelArmedForcesOther1: "Määritä",
    tooltipArmedForcesOther1: "Määritä",
    labelArmedForces2: "Tapahtuman päivämäärä",
    tooltipArmedForces2: "Valitse",
    headingChangesToExam: "Onko koulusi/korkeakoulusi on muuttanut kokeesi päivämääriä? Syyksi ei lasketa epäonnistunutta koesuoritusta, joka sinun on suoritettava uudestaan?",
    headingChangesToExamDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelExam1: "Mikä oli alkuperäinen kokeen suorituspäivä?",
    tooltipExam1: "Valitse",
    labelExam2: "Mikä on uusi koepäivä?",
    tooltipExam2: "Valitse",
    labelExam3: "Miksi koepäivä muuttui?",
    tooltipExam3: "Anna tiedot",
    labelExam4: "Epäonnistuitko edellisessä kokeessa?",
    tooltipExam4: "Kyllä vai ei",
    headingCourtSummons: "Onko teidät kutsuttu oikeuteen todistajaksi samana päivänä kuin tapahtuma?",
    headingCourtSummonsDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelCourtSummons1: "Mikä on syy oikeuden kutsuun?",
    tooltipCourtSummons1: "Valitse",
    labelCourtSummonsOther1: "Määritä",
    tooltipCourtSummonsOther1: "Määritä",
    labelCourtSummons2: "Milloin oikeuden kutsu annettiin?",
    tooltipCourtSummons2: "Valitse",
    headingCOVID19Test: "Minulla, kotitalouteni jäsenellä tai tapahtumaan osallistuvalla ryhmän jäsenellä, on diagnosoitu COVID-19?",
    headingCOVID19TestDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelCOVID19Test1: "Kenellä on COVID-19?",
    tooltipCOVID19Test1: "Valitse",
    labelCOVID19TestOther1a: "Määritä",
    labelCOVID19TestOther1b: "Määritä nimi",
    tooltipCOVID19TestOther1a: "Määritä",
    tooltipCOVID19TestOther1b: "Määritä nimi",
    labelCOVID19Test2: "Minkä tyyppinen COVID-19-testi sinulla oli?",
    tooltipCOVID19Test2: "Valitse",
    labelCOVID19TestOther2: "Määritä",
    tooltipCOVID19TestOther2: "Määritä",
    labelCOVID19Test3: "Mikä oli COVID-19-testisi päivämäärä?",
    tooltipCOVID19Test3: "Valitse",
    headingDeath: "Perheenjäseneni on kuollut ja se estää minua osallistumasta tapahtumaan?",
    headingDeathDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelDeath1: "Kuka perheenjäsenistäsi on kuollut?",
    tooltipDeath1: "Valitse",
    labelDeathOther1: "Määritä",
    tooltipDeathOther1: "Määritä",
    labelDeath2: "Milloin kuolinpäivä oli?",
    tooltipDeath2: "Valitse",
    labelDeath3: "Vainajan nimi",
    tooltipDeath3: "Määritä",
    headingEmergency: "Minulla on ollut todellinen odottamaton hätätilanne, joka ei ollut hallinnassani ja josta voin esittää todisteita?",
    headingEmergencyDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelEmergency1: "Kuvaile hätätilanteen luonne.",
    tooltipEmergency1: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelEmergency2: "Milloin tämä tapahtui?",
    tooltipEmergency2: "Valitse",
    labelEmergency3: "Miksi tämä tilanne estää sinua osallistumasta tapahtumaan?",
    tooltipEmergency3: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelEmergency4: "Onko teillä todisteita tästä hätätilanteesta?",
    tooltipEmergency4: "Kyllä vai ei",
    headingHomeEmergency: "Minulle on tapahtunut kodin hätätilanne (ryöstö, tulva, tulipalo, ilkivalta, kotiväkivalta) joka estää minua osallistumasta tapahtumaan?",
    headingHomeEmergencyDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelHomeEmergency1: "Mistä hätätilanteesta on kyse?",
    tooltipHomeEmergency1: "Valitse",
    labelHomeEmergencyOther1: "Määritä",
    tooltipHomeEmergencyOther1: "Määritä",
    labelHomeEmergency2: "Milloin tämä tapahtui?",
    tooltipHomeEmergency2: "Valitse",
    labelHomeEmergency3: "Miksi se esti sinua osallistumasta tapahtumaan?",
    tooltipHomeEmergency3: "Ole hyvä ja kerro yksityiskohtaisesti",
    headingInjury: "Minulla, kotitalouteni jäsenellä tai tapahtumaan osallistuvalla ryhmän jäsenellä, on diagnosoitu sairaus tai vamma, joka nyt estää meitä menemästä tapahtumaan?",
    headingInjuryDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelInjury1: "Mikä on sairauden/vamman/onnettomuuden luonne?",
    tooltipInjury1: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelInjury2: "Milloin tämä tapahtui?",
    tooltipInjury2: "Valitse",
    labelInjury3: "Oletko käynyt lääkärikeskuksessa tämän sairauden/vamman vuoksi?",
    tooltipInjury3: "Kyllä vai ei",
    labelInjury4: "Oliko tämä olemassa oleva sairaus?",
    tooltipInjury4: "Kyllä vai ei",
    labelInjury5: "Milloin tämä diagnosoitiin ensimmäisen kerran?",
    tooltipInjury5: "Valitse",
    labelInjury6: "Miksi tämä tarkoittaa, ettet voi osallistua tapahtumaan?",
    tooltipInjury6: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelInjury7: "Kuka on kärsinyt sairaudesta/vammasta?",
    tooltipInjury7: "Valitse",
    headingJuryService: "Onko minut tai varauksen jäsen kutsuttu valamiehistöön?",
    headingJuryServiceDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelJuryService1: "Milloin sait ilmoituksen valamiehistöstä?",
    tooltipJuryService1: "Valitse",
    headingMechanicalBreakdown: "Ajoneuvossamme on ollut mekaaninen vika tai se on ollut onnettomuudessa ja tämä estää meitä osallistumasta tapahtumaan?",
    headingMechanicalBreakdownDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelMechanicalBreakdown1: "Mitä autolle tapahtui?",
    tooltipMechanicalBreakdown1: "Valitse",
    labelMechanicalBreakdown2: "Milloin tämä tapahtui?",
    tooltipMechanicalBreakdown2: "Valitse",
    labelMechanicalBreakdown3: "Miksi et pystynyt käyttämään muita kulkutapoja?",
    tooltipMechanicalBreakdown3: "Ole hyvä ja kerro yksityiskohtaisesti",
    headingPregnancyComplication: "Minulla tai varauksen jäsenellä on raskauskomplikaatioita, jotka lääketieteen ammattilainen on diagnosoinut ja estävät siten osallistumisen tapahtumaan?",
    headingPregnancyComplicationDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelPregnancyComplication1: "Mikä on raskauden komplikaation luonne?",
    tooltipPregnancyComplication1: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelPregnancyComplication2: "Milloin tämä tapahtui?",
    tooltipPregnancyComplication2: "Valitse",
    labelPregnancyComplication3: "Hakeuduitko lääkäriin?",
    tooltipPregnancyComplication3: "Kyllä vai ei",
    labelPregnancyComplication4: "Miksi tämä tarkoittaa, ettet voi osallistua tapahtumaan?",
    tooltipPregnancyComplication4: "Ole hyvä ja kerro yksityiskohtaisesti",
    headingPublicTransportFailure: "Julkisen liikenteen matka (juna, bussi, raitiovaunu, laiva jne.) viivästyi odottamatta, eikä minulla ollut muuta tapaa matkustaa tähän tapahtumaan?",
    headingPublicTransportFailureDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelPublicTransportFailure1: "Millaista julkista liikennettä aioit käyttää?",
    tooltipPublicTransportFailure1: "Valitse",
    labelPublicTransportFailure2: "Milloin aioit matkustaa?",
    tooltipPublicTransportFailure2: "Valitse",
    labelPublicTransportFailure3: "Mitä tapahtui?",
    tooltipPublicTransportFailure3: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelPublicTransportFailure4: "Miksi et voinut käyttää vaihtoehtoisia kuljetuksia?",
    tooltipPublicTransportFailure4: "Ole hyvä ja kerro yksityiskohtaisesti",
    headingScheduledAirlineFailure: "Ennakkoon varattu lentoni peruutettiin odottamatta, enkä löytänyt sopivaa vaihtoehtoa, mikä tarkoittaa, etten voinut osallistua tapahtumaan?",
    headingScheduledAirlineFailureDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelScheduledAirlineFailure1: "Lentoyhtiön nimi",
    tooltipScheduledAirlineFailure1: "Lentoyhtiön nimi",
    labelScheduledAirlineFailure2: "Lennon numero",
    tooltipScheduledAirlineFailure2: "Lennon numero",
    labelScheduledAirlineFailure3: "Matkustaminen kohteesta",
    tooltipScheduledAirlineFailure3: "Matkustaminen kohteesta",
    labelScheduledAirlineFailure4: "Matkustaminen kohteeseen",
    tooltipScheduledAirlineFailure4: "Matkustaminen kohteeseen",
    labelScheduledAirlineFailure5: "Tapahtumapäivä?",
    tooltipScheduledAirlineFailure5: "Valitse",
    labelScheduledAirlineFailure6: "Miksi et pystynyt lähtemään vaihtoehtoiselle lennolle?",
    tooltipScheduledAirlineFailure6: "Ole hyvä ja kerro yksityiskohtaisesti",
    headingTheftOfDocuments: "Asiakirjani varastettiin, eikä korvaamista ollut mahdollista järjestää vaaditussa määräajassa, joten en voi osallistua tapahtumaan",
    headingTheftOfDocumentsDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelTheftOfDocuments1: "Minkä tyyppinen asiakirja varastettiin?",
    tooltipTheftOfDocuments1: "Valitse",
    labelTheftOfDocuments2: "Milloin tämä tapahtui?",
    tooltipTheftOfDocuments2: "Valitse",
    labelTheftOfDocuments3: "Yrititkö saada asiakirjat uudelleen julkisuuteen?",
    tooltipTheftOfDocuments3: "Kyllä vai ei",
    headingWorkRelocation: "Työnantajani on pyytänyt minua siirtämään työpaikkani sijaintia vähintään kolmeksi kuukaudeksi ja etäisyys on merkittävä, mikä tarkoittaa että en voi osallistua tapahtumaan?",
    headingWorkRelocationDecline: "Sinun ei pitäisi jatkaa tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelWorkRelocation1: "Onko tämä työsi väliaikainen vai pysyvä siirto?",
    tooltipWorkRelocation1: "Valitse",
    labelWorkRelocation2: "Miten pitkään olet töissä uudessa osoitteessa?",
    tooltipWorkRelocation2: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelWorkRelocation3: "Mikä on nykyinen työosoitteesi?",
    tooltipWorkRelocation3: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelWorkRelocation4: "Mikä on uusi työosoitteesi?",
    tooltipWorkRelocation4: "Ole hyvä ja kerro yksityiskohtaisesti",
    labelWorkRelocation5: "Milloin sinulle ilmoitettiin tästä siirrosta?",
    tooltipWorkRelocation5: "Valitse",
    headingRedundancy: "Työnantaja on irtisanonut minut tai varauksen jäsenen, mikä tarkoittaa, etten voi enää osallistua varaukseen?",
    headingRedundancyDecline: "Älä jatka tätä hakemusta, koska hyvitys todennäköisesti hylätään.",
    labelRedundancy1: "Työsuhteen tyyppi?",
    tooltipRedundancy1: "Valitse",
    labelRedundancy2: "Kuinka kauan olet ollut tämän yrityksen palveluksessa?",
    tooltipRedundancy2: "Valitse",
    labelRedundancy3: "Oliko irtisanominen vapaaehtoista?",
    tooltipRedundancy3: "Valitse",
    labelRedundancy4: "Milloin sinulle ilmoitettiin irtisanomisesta?",
    tooltipRedundancy4: "Valitse",
    labelTestOption: "Valitse Kyllä jatkaaksesi",
}