export default {
    messageUnableToMakePaymentToCurrency: "Diemžēl mēs nevaram izgūt bankas datus par izvēlēto valūtu. Lūdzu, pārliecinieties, ka valūta un mītnes valsts ir atlasīti vietnē",
    messageOnlyBankAccountsAccepted: "Lūdzu, ņemiet vērā: pašlaik mēs varam veikt maksājumus uz bankas kontiem tikai (kredītkartes un debetkartes netiek pieņemtas)",
    labelAccountName: "Konta īpašnieka vārds un uzvārds",
    labelBankAccountName: "Konta īpašnieka pilns vārds un uzvārds",
    labelBankCardHolderName: "Kartes īpašnieka vārds un uzvārds",
    validationBankAccountName: "Ir nepieciešams pilns konta īpašnieka vārds un uzvārds",
    labelBankCountry: "Bankas valsts",
    labelBankName: "Bankas nosaukums",
    labelPaymentType: "Maksājuma veids",
    labelInstitutionNumber: "Iestādes numurs",
    labelBankAddress: "Bankas adrese",
    labelBankAddress2: "Bankas adrese",
    labelBankCity: "Bankas pilsēta",
    labelBankState: "Bankas valsts",
    labelBankAccountNumber: "Bankas konta numurs",
    labelBankAccountNumberCBU: "Konta numurs CBU",
    labelBankAccountNumberCCI: "Konta numurs KKI",
    labelBankAccountNumberZAR: "Bankas konta numurs (7–11 cipari)",
    labelCardNumber: "Kartes numurs",
    labelUnionpay: "UnionPay kartes numurs",
    labelAlipay: "Alipay ID (mobilā tālruņa numurs vai e-pasts)",
    labelBankAccountType: "Konta tips",
    optionBankAccountTypeCURRENT: "Norēķinu konts",
    optionBankAccountTypeCHECKING: "Konta pārbaude",
    optionBankAccountTypeSAVING: "Krājkonts",
    optionBankAccountTypeSAVINGS: "Krājkonts",
    optionBankAccountTypeCUENTA_VISTA: "Galvenais konts",
    optionBankAccountType1: "Konta pārbaude",
    optionBankAccountType2: "Krājkonts",
    optionBankAccountType3: "Galvenais konts",
    optionBankAccountType4: "Vista konts",
    optionBankAccountType5: "RUT konts",
    labelSortCode: "Kārtošanas kods",
    labelRoutingCode: "Maršrutēšanas kods",
    labelBankRoutingCodeNationalID: "Bankas maršrutēšanas kods / nacionālais ID",
    labelBankTransitCodeSwift: "Tranzīta numurs",
    labelCNAPSCode: "Maršrutēšanas kods / CNAPS kods (Ķīnas valsts avansa maksājumu sistēmas kods)",
    labelABAFedwireRoutingCode: "ACH maršrutēšanas numurs",
    labelIFSCCode: "IFSC kods",
    labelBankCode: "Bankas kods",
    labelBIKCode: "BIK kods",
    labelBranchCode: "Filiāles kods",
    labelBranchCodeUniversal: "Universāls 6 ciparu filiāles kods",
    labelRemittanceLine3: "Saņēmēja kontaktpersona, vārds, uzvārds un tālruņa numurs",
    labelBeneficiaryContactNameandTelephoneNumber: "Saņēmēja kontaktpersona, vārds, uzvārds un tālruņa numurs",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Saņēmēja dzimšanas datums un dzimšanas vieta",
    labelRemittanceLine4: "Individuālais nodokļu numurs CUIL (9-12 cipari)",
    labelCUILNumber: "Nodokļu maksātāja ID: FLY / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Nodokļu maksātāja ID RFC 13 cipari vai CURP 18 cipari",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individuālais nodokļu maksātāja ID, CPF, Individuālā nodokļu maksātāja reģistrācija (11-14 cipari), Filiāles kods",
    labelCFPNumber: "CPF numurs (11-14 cipari)",
    labelBranchNameorBranchCode7digits: "Filiāles nosaukums vai filiāles kods (7 cipari)",
    labelCedulaJuridica910digitsTaxID: "Juridiskā izziņa (9-10 cipari nodokļu maksātāja ID)",
    labelTaxIDRUT89digits: "Tax ID RUT (8-9 cipari)",
    labelJob: "Darba nosaukums",
    labelDateOfBirth: "Dzimšanas datums",
    labelNationality: "Pilsonība",
    labelDocumentType: "Dokumenta tips",
    labelDocumentNumber: "Dokumenta numurs",
    labelRutNumber: "RUT numurs (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (nodokļu maksātāja ID — 8–11 cipari)",
    labelCIIndividualTaxIDBranchLocation: "CI (individuālais nodokļu maksātāja ID), filiāles atrašanās vieta",
    labelTaxIDRUCorCI: "Nodokļu maksātāja ID (RUC vai CI)",
    labelSortCodeHelp: "Palīgā?",
    labelBranchCodeHelp: "Palīgā?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Palīgā?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Palīgā?",
    labelRoutingCodeBSB: "Maršrutēšanas kods/BSB",
    labelBSB: "Bankas valsts filiāle (BSB)",
    labelSKNCode: "SKN kods",
    labelAcceptanceFlights: "Es/mēs piekrītam, ka gadījumā, ja atmaksas pieteikums būs veiksmīgs, rezervācija, ieskaitot visus lidojumus un papildu produktus, tiks atcelta.",
    labelAcceptanceTickets: "Es/mēs piekrītam, ka gadījumā, ja atmaksas pieteikums būs veiksmīgs, rezervācija, ieskaitot visas biļetes un papildu produktus, tiks atcelta.",
    labelTermsAndConditions1: "Es/mēs esam piekrituši/mēs",
    labelTermsAndConditions2: "Noteikumi un nosacījumi",
    labelFraudWarning1: "Es uzskatu, ka šajā atmaksas pieteikuma veidlapā sniegtie fakti ir patiesi un patiesi. Es saprotu, ka jebkura nepatiesa informācija vai pierādījumi var tikt uzskatīti par krimināli sodāmu darbību un var tikt nodoti policijai vai citām tiesībaizsardzības iestādēm, lai tās veiktu vajāšanu.",
    labelFraudWarning2: "Ja mums ir aizdomas par krāpšanu saistībā ar apliecinošiem dokumentiem, šī lieta nekavējoties tiks nodota vietējām policijas iestādēm. Es pieņemu un saprotu, ka mani dati tiks nodoti šīm iestādēm, ja mans pieteikums tiks uzskatīts par krāpniecisku un tas var kļūt par kriminālizmeklēšanu.",
    validationTermsAndConditions: "Jāpiekrīt noteikumiem un nosacījumiem",
    messageFailbankName: "Bankas nosaukums nevar būt tukšs",
    messageFailcustomerName: "Klienta vārds nevar būt tukšs",
    messageFailbankAccountNumber: "Obligāti aizpildāms lauks",
    messageFailbankAccountNumberFormat: "Jābūt IBAN formātam",
    messageFailsortCode: "Obligāti aizpildāms lauks",
    messageFailbankSwiftBic: "Saņēmēja SWIFT BIC nevar būt tukšs",
    messageFailbankSwiftBicFormat: "Jābūt SWIFT formātam",
    messageFailbeneficiaryAddress1: "Adrese 1 nevar būt tukša",
    messageFailbeneficiaryCity: "Pilsēta nevar būt tukša",
    messageFailbeneficiaryPostCode: "Pasta indekss nevar būt tukšs",
    messageFailremittanceLine3: "Obligāti aizpildāms lauks",
    messageFailremittanceLine4: "Obligāti aizpildāms lauks",
    modalHeading: "Apstipriniet sīkāku informāciju",
    modalLabelPleaseConfirmYourDetails: "Lūdzu, apstipriniet savu informāciju",
    modalValidationPleaseConfirm: "Lūdzu, apstipriniet",
    modalValidationMustMatch: "Jāatbilst iepriekšējam ierakstam",
    tooltipBankAccountName: "Jūsu vārds, kas norādīts uz jūsu bankas konta / bankas izrakstiem / bankas kartes.",
    tooltipBankAccountNameRecipient: "Saņēmēja vārds un uzvārds. Gan vārdam, gan uzvārdam jābūt vairāk nekā vienai rakstzīmei.",
    tooltipBankCountry: "Valsts, kurā atrodas jūsu banka.",
    tooltipBankName: "Jūsu bankas nosaukums.",
    tooltipPaymentType: "Nepieciešamā maksājuma veids.",
    tooltipInstitutionNumber: "Tas ir 3 cipari, un parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAccountNumber: "Jūsu individuālais konta numurs. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAccountNumberCBU: "22 ciparu skaitlis, kas tiek izmantots, lai identificētu jūsu kontu. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAccountNumberCCI: "20 ciparu skaitlis, kas tiek izmantots, lai identificētu jūsu kontu. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAccountNumberKRW: "11–16 ciparu skaitlis, kas tiek izmantots, lai identificētu jūsu kontu. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCardNumber: "Jūsu kartes numurs. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipUnionpay: "Jūsu UnionPay kartes numurs.",
    tooltipAlipay: "Jūsu mobilā tālruņa numurs vai e-pasta adrese, kas saistīta ar jūsu Alipay kontu.",
    tooltipBankAccountType: "Tas ir vai nu pārbaude, vai ietaupījumi.",
    tooltipSortCode: "Tas identificē jūsu bankas filiāli, kurā atrodas jūsu konts. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipRoutingCode: "Tas identificē jūsu bankas filiāli, kurā atrodas jūsu konts. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBeneficiarySWIFTBIC: "Sastāv no 8-11 rakstzīmēm, ko izmanto, lai identificētu banku starptautiskā darījumā. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBSB: "Bankas valsts filiāles (BSB) numurs ir Austrālijas šķirošanas kods, kuram ir 6 cipari. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipRoutingCodeBSB: "Tas identificē jūsu bankas filiāli, kurā atrodas jūsu konts. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai bankas kartē, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipABAFedwireRoutingCode: "9 ciparu skaitlis, ko izmanto, lai identificētu bankas Amerikā. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipIFSCCode: "Parasti šo kodu var atrast jūsu ikmēneša pārskatā vai grāmatiņā, vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipTermsAndConditions: "Piekrītot noteikumiem un nosacījumiem, jūs apstiprināt, ka esat izlasījis un piekrītat mūsu pakalpojumu sniegšanas noteikumiem.",
    tooltipIBAN: "Jūsu starptautiskais bankas konta numurs, kuru varat izmantot, veicot vai saņemot starptautiskus maksājumus. Parasti tas ir atrodams jūsu ikmēneša pārskatā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Saņēmēja kontaktpersonas vārds, uzvārds un tālruņa numurs.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "saņēmēja dzimšanas datums un dzimšanas vieta.",
    tooltipBankRoutingCodeNationalID: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankTransitCodeSwift: "Tas ir 5 cipari, un to parasti var atrast ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAddress: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankAddress2: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankCity: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankState: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBranchName: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBankCode: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBIKCode: "9 cipari, lai identificētu jūsu banku.",
    tooltipBranchCode: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBranchCodeUniversal: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCNAPSCode: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipRemittanceLine4: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCUILNumber: "Tas ir 11 ciparu skaitlis, kas sastāv no diviem cipariem, defises, deviņiem cipariem un viencipara kontrolsummas.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCFPNumber: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipBranchNameorBranchCode7digits: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCedulaJuridica910digitsTaxID: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipTaxIDRUT89digits: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipJob: "Jūsu pašreizējā nodarbošanās.",
    tooltipDateOfBirth: "Jūsu dzimšanas datums.",
    tooltipNationality: "Jūsu tautība.",
    tooltipDocumentType: "Nepieciešams dokumenta tips.",
    tooltipDocumentNumber: "Šis ir dokumenta numurs, kas saistīts ar jūsu dokumenta tipa atlasi.",
    tooltipRutNumber: "Jūsu RUT numurs (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipCIIndividualTaxIDBranchLocation: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    tooltipTaxIDRUCorCI: "Parasti to var atrast jūsu ikmēneša bankas izrakstā vai, ja joprojām neesat pārliecināts, jautājiet savai bankai.",
    errorCPFCode: "Šobrīd mēs pieņemam CPF tikai kā PIX atslēgu.",
};