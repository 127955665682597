export default {
    messageUnableToMakePaymentToCurrency: "Infelizmente, não podemos recuperar os dados bancários para a moeda selecionada. Certifique-se de que a moeda e o país de origem estão selecionados em",
    messageOnlyBankAccountsAccepted: "Observação: no momento, só podemos fazer pagamentos para contas bancárias (cartões de crédito e débito não são aceitos)",
    labelAccountName: "Nome do titular da conta",
    labelBankAccountName: "Nome completo do titular da conta",
    labelBankCardHolderName: "Nome do Titular",
    validationBankAccountName: "O nome completo do titular da conta é obrigatório",
    labelBankCountry: "País do Banco",
    labelBankName: "Banco",
    labelPaymentType: "Tipo de pagamento",
    labelInstitutionNumber: "Número da Instituição",
    labelBankAddress: "Endereço do banco",
    labelBankAddress2: "Endereço do banco",
    labelBankCity: "Banco cidade",
    labelBankState: "Banco Estado",
    labelBankAccountNumber: "Conta (normalmente contém 5 dígitos + 1dv)",
    labelBankAccountNumberCBU: "Número da conta CBU",
    labelBankAccountNumberCCI: "Número da conta CCI",
    labelBankAccountNumberZAR: "Número da conta bancária (7 a 11 dígitos)",
    labelCardNumber: "Número do cartão",
    labelUnionpay: "Número do cartão UnionPay",
    labelAlipay: "ID Alipay (número de celular ou e-mail)",
    labelBankAccountType: "Tipo de conta",
    optionBankAccountTypeCURRENT: "Conta corrente",
    optionBankAccountTypeCHECKING: "Conta corrente",
    optionBankAccountTypeSAVING: "Conta Poupança",
    optionBankAccountTypeSAVINGS: "Conta Poupança",
    optionBankAccountTypeCUENTA_VISTA: "Conta mestre",
    optionBankAccountType1: "Conta corrente",
    optionBankAccountType2: "Conta Poupança",
    optionBankAccountType3: "Conta mestre",
    optionBankAccountType4: "Conta Vista",
    optionBankAccountType5: "Conta RUT",
    labelSortCode: "Ordenar código",
    labelRoutingCode: "Código de roteamento",
    labelBankRoutingCodeNationalID: " Banco Encaminhamento Código / Nacional EU IRIA",
    labelBankTransitCodeSwift: "Número de trânsito",
    labelCNAPSCode: "Encaminhamento Código / Código do Sistema Nacional de Pagamento Avançado da China",
    labelABAFedwireRoutingCode: "Número de roteamento ACH",
    labelBranchCode: "Agência (4 dígitos)",
    labelIFSCCode: "Código IFSC",
    labelBankCode: "Código bancário",
    labelBIKCode: "Código BIK",
    labelBranchCodeUniversal: "Código de agência universal de 6 dígitos",
    labelRemittanceLine3: "Nome de contato do beneficiário e número de telefone",
    labelBeneficiaryContactNameandTelephoneNumber: "Nome de contato do beneficiário e número de telefone",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Data de nascimento do beneficiário e local de nascimento",
    labelRemittanceLine4: "Número fiscal individual CUIL (9 a 12 dígitos)",
    labelCUILNumber: "Id fiscal: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "ID fiscal RFC de 13 dígitos ou CURP de 18 dígitos",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "CPF Cadastro de Pessoa Física (11 a 14 dígitos), Código da Agência",
    labelCFPNumber: "Número do CPF (11-14 dígitos)",
    labelBranchNameorBranchCode7digits: "Nome da agência ou código da agência (7 dígitos)",
    labelCedulaJuridica910digitsTaxID: "Certificado Legal (ID fiscal de 9 a 10 dígitos)",
    labelTaxIDRUT89digits: "ID fiscal RUT (8-9 dígitos)",
    labelJob: "Cargo",
    labelDateOfBirth: "Data de nascimento",
    labelNationality: "Nacionalidade",
    labelDocumentType: "Cadastro",
    labelDocumentNumber: "CPF do titular da conta. Aceitamos apenas CPF como chave PIX",
    labelRutNumber: "Número RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Carteira de identidade (CPF / indivíduo 8-11 dígitos)",
    labelCIIndividualTaxIDBranchLocation: "CI (identificação fiscal individual), localização da filial",
    labelTaxIDRUCorCI: "ID fiscal (RUC ou CI)",
    labelSortCodeHelp: "Ajuda?",
    labelBranchCodeHelp: "Ajuda?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Ajuda?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Ajuda?",
    labelRoutingCodeBSB: "Código de Roteamento / BSB",
    labelBSB: "Agência do Estado do Banco (BSB)",
    labelSKNCode: "Código SKN",
    labelAcceptanceFlights: "Aceito/aceitamos que, se o pedido de reembolso for bem-sucedido, a reserva, incluindo todos os voos e produtos auxiliares, será cancelada.",
    labelAcceptanceTickets: "Eu/nós aceitamos/aceitamos que, se o pedido de reembolso for bem-sucedido, a reserva incluindo todos os bilhetes e produtos auxiliares serão cancelados.",
    labelTermsAndConditions1: "Eu / nós concordamos com o",
    labelTermsAndConditions2: "Termos e Condições",
    labelFraudWarning1: "Acredito que os fatos fornecidos neste formulário de solicitação de reembolso são genuínos e verdadeiros. Entendo que qualquer informação ou evidência falsa pode ser considerada um ato criminoso e ser repassada à polícia ou outras agências de aplicação da lei para serem investigadas.",
    labelFraudWarning2: "Quando suspeitarmos de fraude em relação à documentação de suporte, este assunto será imediatamente encaminhado às autoridades policiais locais. Aceito e entendo que meus dados serão repassados a essas autoridades caso meu pedido seja considerado fraudulento e isso possa se tornar uma investigação criminal.",
    validationTermsAndConditions: "Os termos e condições devem ser aceitos",
    messageFailbankName: "O nome do banco não pode ficar em branco",
    messageFailcustomerName: "O nome do cliente não pode ficar em branco",
    messageFailbankAccountNumber: "Campo obrigatório",
    messageFailbankAccountNumberFormat: "Deve ser o formato IBAN",
    messageFailsortCode: "Campo obrigatório",
    messageFailbankSwiftBic: "O SWIFT BIC do beneficiário não pode ficar em branco",
    messageFailbankSwiftBicFormat: "Deve ser o formato SWIFT",
    messageFailbeneficiaryAddress1: "O endereço 1 não pode ficar em branco",
    messageFailbeneficiaryCity: "A cidade não pode ficar em branco",
    messageFailbeneficiaryPostCode: "A cidade não pode ficar em branco",
    messageFailremittanceLine3: "Campo obrigatório",
    messageFailremittanceLine4: "Campo obrigatório",
    modalHeading: "Confirmar detalhes",
    modalLabelPleaseConfirmYourDetails: "Por favor, confirme seus detalhes",
    modalValidationPleaseConfirm: "Por favor confirme",
    modalValidationMustMatch: "Deve corresponder à entrada anterior",
    tooltipBankAccountName: "Seu nome conforme aparece em sua conta bancária / extratos bancários / cartão bancário.",
    tooltipBankAccountNameRecipient: "O nome e o sobrenome do destinatário. Tanto o nome quanto o sobrenome devem ter mais de um caractere.",
    tooltipBankCountry: "O país em que seu banco está localizado.",
    tooltipBankName: "O nome do seu banco.",
    tooltipPaymentType: "O tipo de pagamento que você exige.",
    tooltipInstitutionNumber: "Ele tem 3 dígitos e geralmente pode ser encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAccountNumber: "O seu número de conta individual. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAccountNumberCBU: "Um número de 22 dígitos usado para identificar sua conta. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAccountNumberCCI: "Um número de 20 dígitos usado para identificar sua conta. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAccountNumberKRW: "Um número de 11 a 16 dígitos usado para identificar sua conta. Normalmente encontrado no seu extrato mensal ou cartão do banco, ou se ainda tiver dúvidas, pergunte ao seu banco.",
    tooltipCardNumber: "Número do seu cartão. Normalmente encontrado no seu extrato mensal ou cartão do banco, ou se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipUnionpay: "O número do seu cartão UnionPay.",
    tooltipAlipay: "Seu número de celular ou endereço de e-mail associado à sua conta Alipay.",
    tooltipBankAccountType: "Isso é verificação ou economia.",
    tooltipSortCode: "Isso identifica a agência de seu banco onde sua conta está mantida. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipRoutingCode: "Isso identifica a agência de seu banco onde sua conta está mantida. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBeneficiarySWIFTBIC: "Consiste em 8-11 caracteres usados ​​para identificar um banco em uma transação internacional. Normalmente encontrado no seu extrato mensal ou se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBSB: "O número do Bank State Branch (BSB) é um código de classificação australiano com 6 dígitos. Normalmente encontrado no seu extrato mensal ou se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipRoutingCodeBSB: "Isso identifica a agência de seu banco onde sua conta está mantida. Normalmente encontrado no seu extrato mensal ou cartão do banco ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipABAFedwireRoutingCode: "Um número de 9 dígitos usado para identificar bancos na América. Normalmente encontrado no seu extrato mensal ou se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipIFSCCode: "Normalmente, esse código pode ser encontrado no seu extrato mensal ou na sua caderneta ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipTermsAndConditions: "Ao aceitar os termos e condições, você confirma que leu e concorda com nossos Termos e Condições.",
    tooltipIBAN: "O número da sua conta bancária internacional, que você pode usar ao fazer ou receber pagamentos internacionais. Normalmente encontrado no seu extrato mensal ou se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nome de contato do beneficiário e número de telefone.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Data de nascimento do beneficiário e local de nascimento.",
    tooltipBankRoutingCodeNationalID: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankTransitCodeSwift: "São 5 dígitos e geralmente podem ser encontrados no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAddress: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankAddress2: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankCity: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankState: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBranchName: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBankCode: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBIKCode: "9 dígitos para identificar seu banco.",
    tooltipBranchCode: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBranchCodeUniversal: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipCNAPSCode: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipRemittanceLine4: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipCUILNumber: "Este é um número de 11 dígitos, consistindo de dois dígitos, hífen, nove dígitos e uma soma de verificação de um dígito.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipCFPNumber: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipBranchNameorBranchCode7digits: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipCedulaJuridica910digitsTaxID: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipTaxIDRUT89digits: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipJob: "Sua ocupação atual.",
    tooltipDateOfBirth: "Sua data de nascimento.",
    tooltipNationality: "Sua nacionalidade.",
    tooltipDocumentType: "O tipo de documento necessário.",
    tooltipDocumentNumber: "Este é o número do documento associado à seleção do tipo de documento.",
    tooltipRutNumber: "Seu número RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipCIIndividualTaxIDBranchLocation: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    tooltipTaxIDRUCorCI: "Geralmente, pode ser encontrado no seu extrato bancário mensal ou, se ainda não tiver certeza, pergunte ao seu banco.",
    errorCPFCode: "No momento apenas aceitames CPF como chave PIX."
};