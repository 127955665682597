export default {
    nonAutoApprovedLabelThankYou: "返金申請ありがとうございます。",
    nonAutoApprovedLabelReview: "私たちのチームは、今後2営業日以内にあなたの申請書を審査し、応答をあなたに戻ってきます。",
    nonAutoApprovedLabelExperience: "どうやったの?",
    nonAutoApprovedLabelExperienceIntroduction: "私たちは本当にあなたが私たちを評価し、私たちが顧客の意見を大切にするようにレビューを残すために時間を割いていただければ幸いです。",
    feedbackExcellent: "たいへん良い",
    feedbackGreat: "すごい",
    feedbackAverage: "平均",
    feedbackPoor: "貧しい",
    feedbackBad: "悪い",
    labelThankYouForFeedback: "ご意見ありがとうございます。",
    autoApprovedMessage1: "私たちは喜んでいます",
    autoApprovedMessage2: "承認する",
    autoApprovedMessage3: "あなたの払い戻し!",
    autoApprovedPayment1Day: "お支払いは既に処理されており、わずか 1 営業日でアカウントに入金されます。",
    autoApprovedPayment2to4Days: "お支払いはすでに処理されており、わずか2~4営業日でアカウントに入金されます。",
    autoApprovedPayment7to10Days: "お支払いはすでに処理されており、わずか7-10営業日以内にアカウントに入金されるはずです。",
    autoApprovedLabelExperience: "どうやったの?",
    autoApprovedLabelExperienceIntroduction: "私たちは本当にあなたが私たちを評価し、私たちが顧客の意見を大切にするようにレビューを残すために時間を割いていただければ幸いです。",
    modalLabelNegative: "あなたのフィードバックは私たちにとって本当に重要です、あなたも私たちと連絡を取りたいなら、私たちに知らせてください。",
    placeholderFeedback: "フィードバック",
    messageFeedbackSubmitted: "提出されたフィードバック",
    reviewInstructionMessage: "5つ星は優秀、4つ星は素晴らしい、3つ星は平均、2つ星は貧弱、1つ星は悪いです。"
}