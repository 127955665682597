export default {
    headingAdverseWeather: "تواجه ظروفا جوية سيئة للغاية تجعل من غير الآمن السفر إلى الحدث وقد تم تفويض ذلك من قبل هيئة حكومية (مثل السلطة المحلية والشرطة وما إلى ذلك). ولديك أدلة تثبت ذلك؟",
    headingAdverseWeatherDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    tooltipRefundReasonConfirm: "نعم أو لا",
    labelBookingCancelledByOperator: "تم إلغاء التذاكر من قبل المشغل / المنظم.",
    labelBookingRescheduledByOperator: "تم تغيير التاريخ من قبل المشغل / المنظم ولا يمكنني حضور التواريخ الجديدة.",
    labelBookedWrongDate: "أنا أو أحد أعضاء الحجز ، ارتكبت خطأ عند شراء التذاكر وحجزت التاريخ الخطأ.",
    labelBookedWrongTickets: "أنا أو أحد أعضاء الحجز ، ارتكبت خطأ عند شراء التذاكر وحجزت التذاكر الخاطئة.",
    labelBookedTooManyTickets: "أنا أو أحد أعضاء الحجز ، ارتكبت خطأ عند شراء التذاكر وحجزت الكثير من التذاكر.",
    labelCOVID19TravelRestrictions: "أنا أو أحد أعضاء الحجز غير قادر على الحضور بسبب حظر السفر COVID-19.",
    labelADWrongSelection: "ربما تكون قد حددت السبب الخاطئ لاسترداد الأموال. يرجى مراجعة القائمة المنسدلة سبب استرداد الأموال مرة أخرى.",
    labelAdverseWeather1: "لماذا يعني الطقس السيئ أنك لم تتمكن من حضور الحدث؟",
    tooltipAdverseWeather1: "يرجى التفاصيل",
    labelAdverseWeather2: "تاريخ الحادث",
    tooltipAdverseWeather2: "الرجاء اختيار",
    headingArmedForces: "يطلب منك صاحب العمل الخاص بك وهو القوات المسلحة أو خدمات الطوارئ أن تأتي إلى العمل عندما لم يكن من المقرر أن تفعل ذلك في الأصل وهذا يمنعك من حضور الحدث؟",
    headingArmedForcesDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelArmedForces1: "ما هي القوات أو خدمة الطوارئ التي توظفون بها؟",
    tooltipArmedForces1: "الرجاء اختيار",
    labelArmedForcesOther1: "يرجى التحديد",
    tooltipArmedForcesOther1: "يرجى التحديد",
    labelArmedForces2: "تاريخ الحادث",
    tooltipArmedForces2: "الرجاء اختيار",
    headingChangesToExam: "هل تم تغيير مواعيد امتحانك من قبل مدرستك / كليتك ، إلخ؟ هذا ليس امتحانا فاشلا يجب عليك إعادة صياغته؟",
    headingChangesToExamDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelExam1: "ما هو تاريخ الامتحان الأصلي؟",
    tooltipExam1: "الرجاء اختيار",
    labelExam2: "ما هو موعد الامتحان الجديد؟",
    tooltipExam2: "الرجاء اختيار",
    labelExam3: "لماذا تغير موعد الامتحان؟",
    tooltipExam3: "الرجاء إدخال التفاصيل",
    labelExam4: "هل فشلت في الامتحان السابق؟",
    tooltipExam4: "نعم أو لا",
    headingCourtSummons: "هل تم استدعاؤك للمثول أمام المحكمة كشاهد في نفس تواريخ الحدث؟",
    headingCourtSummonsDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelCourtSummons1: "ما هو سبب استدعائك للمحكمة؟",
    tooltipCourtSummons1: "الرجاء اختيار",
    labelCourtSummonsOther1: "يرجى التحديد",
    tooltipCourtSummonsOther1: "يرجى التحديد",
    labelCourtSummons2: "متى صدرت أوامر الاستدعاء أمام المحكمة؟",
    tooltipCourtSummons2: "الرجاء اختيار",
    headingCOVID19Test: "هل تم تشخيص إصابتي بكوفيد-19 أو أحد أفراد أسرتي أو أحد أفراد المجموعة التي حضرت الحدث؟",
    headingCOVID19TestDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelCOVID19Test1: "من لديه كوفيد-19؟",
    tooltipCOVID19Test1: "الرجاء اختيار",
    labelCOVID19TestOther1a: "يرجى التحديد",
    labelCOVID19TestOther1b: "تحديد الاسم",
    tooltipCOVID19TestOther1a: "يرجى التحديد",
    tooltipCOVID19TestOther1b: "تحديد الاسم",
    labelCOVID19Test2: "ما نوع اختبار كوفيد-19 الذي أجريته؟",
    tooltipCOVID19Test2: "الرجاء اختيار",
    labelCOVID19TestOther2: "يرجى التحديد",
    tooltipCOVID19TestOther2: "يرجى التحديد",
    labelCOVID19Test3: "ما هو تاريخ اختبار COVID-19 الخاص بك؟",
    tooltipCOVID19Test3: "الرجاء اختيار",
    headingDeath: "هل حدثت وفاة أحد أفراد عائلتي المباشرين وهذا يمنعني من حضور الحدث؟",
    headingDeathDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelDeath1: "أي فرد من أفراد عائلتك توفي؟",
    tooltipDeath1: "الرجاء اختيار",
    labelDeathOther1: "يرجى التحديد",
    tooltipDeathOther1: "يرجى التحديد",
    labelDeath2: "متى كان تاريخ الوفاة؟",
    tooltipDeath2: "الرجاء اختيار",
    labelDeath3: "اسم المتوفى",
    tooltipDeath3: "يرجى التحديد",
    headingEmergency: "لقد عانيت من حالة طوارئ حقيقية خارجة عن إرادتي وغير متوقعة يمكنني تقديم دليل عليها؟",
    headingEmergencyDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelEmergency1: "يرجى توضيح نوع حالة الطوارئ؟",
    tooltipEmergency1: "يرجى التفاصيل",
    labelEmergency2: "متى حدث هذا؟",
    tooltipEmergency2: "الرجاء اختيار",
    labelEmergency3: "لماذا يمنعك هذا الموقف من حضور الحدث؟",
    tooltipEmergency3: "يرجى التفاصيل",
    labelEmergency4: "هل لديك دليل على هذه الحالة الطارئة؟",
    tooltipEmergency4: "نعم أو لا",
    headingHomeEmergency: "لدي حالة طوارئ منزلية وهي السرقة والفيضانات والحرائق والتخريب وسوء المعاملة المنزلية التي تمنعني من حضور الحدث؟",
    headingHomeEmergencyDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelHomeEmergency1: "ما هي حالة الطوارئ المنزلية؟",
    tooltipHomeEmergency1: "الرجاء اختيار",
    labelHomeEmergencyOther1: "يرجى التحديد",
    tooltipHomeEmergencyOther1: "يرجى التحديد",
    labelHomeEmergency2: "متى حدث هذا؟",
    tooltipHomeEmergency2: "الرجاء اختيار",
    labelHomeEmergency3: "لماذا منعك من حضور الحدث؟",
    tooltipHomeEmergency3: "يرجى التفاصيل",
    headingInjury: "أنا أو أحد أفراد أسرتي أو أحد أفراد المجموعة التي تحضر الحدث قد تم تشخيصي بحالة طبية أو إصابة تمنعنا الآن من الذهاب إلى الحدث؟",
    headingInjuryDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelInjury1: "ما هي طبيعة المرض / الإصابة / الحادث؟",
    tooltipInjury1: "يرجى التفاصيل",
    labelInjury2: "متى حدث هذا؟",
    tooltipInjury2: "الرجاء اختيار",
    labelInjury3: "هل ذهبت إلى منشأة طبية لهذا المرض / الإصابة؟",
    tooltipInjury3: "نعم أو لا",
    labelInjury4: "هل كانت هذه حالة طبية موجودة مسبقا؟",
    tooltipInjury4: "نعم أو لا",
    labelInjury5: "متى تم تشخيص هذا لأول مرة؟",
    tooltipInjury5: "الرجاء اختيار",
    labelInjury6: "لماذا يعني هذا أنك لا تستطيع حضور الحدث؟",
    tooltipInjury6: "يرجى التفاصيل",
    labelInjury7: "من عانى من المرض / الإصابة؟",
    tooltipInjury7: "الرجاء اختيار",
    headingJuryService: "هل تم استدعائي أنا أو أحد أعضاء الحجز إلى خدمة لجنة التحكيم؟",
    headingJuryServiceDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelJuryService1: "متى تم إخطارك بخدمة لجنة التحكيم؟",
    tooltipJuryService1: "الرجاء اختيار",
    headingMechanicalBreakdown: "تعرضت سيارتنا لعطل ميكانيكي أو تعرضت لحادث وهذا يمنعنا من حضور الحدث؟",
    headingMechanicalBreakdownDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelMechanicalBreakdown1: "ماذا حدث مع السيارة؟",
    tooltipMechanicalBreakdown1: "الرجاء اختيار",
    labelMechanicalBreakdown2: "متى حدث هذا؟",
    tooltipMechanicalBreakdown2: "الرجاء اختيار",
    labelMechanicalBreakdown3: "لماذا لم تتمكن من ركوب وسائل النقل البديلة؟",
    tooltipMechanicalBreakdown3: "يرجى التفاصيل",
    headingPregnancyComplication: "أنا أو أحد أعضاء الحجز لديه مضاعفات الحمل التي تم تشخيصها من قبل أخصائي طبي وبالتالي منع حضور الحدث؟",
    headingPregnancyComplicationDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelPregnancyComplication1: "ما هي طبيعة مضاعفات الحمل؟",
    tooltipPregnancyComplication1: "يرجى التفاصيل",
    labelPregnancyComplication2: "متى حدث هذا؟",
    tooltipPregnancyComplication2: "الرجاء اختيار",
    labelPregnancyComplication3: "هل طلبت العناية الطبية؟",
    tooltipPregnancyComplication3: "نعم أو لا",
    labelPregnancyComplication4: "لماذا يعني هذا أنك لا تستطيع حضور الحدث؟",
    tooltipPregnancyComplication4: "يرجى التفاصيل",
    headingPublicTransportFailure: "فشلت طريقة النقل العام (القطار ، الحافلة ، الترام ، القارب ، إلخ) بشكل غير متوقع مما يعني أنه لم تكن هناك طريقة أخرى يمكنني من خلالها السفر إلى هذا الحدث؟",
    headingPublicTransportFailureDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelPublicTransportFailure1: "ما نوع وسائل النقل العام التي خططت لاستخدامها؟",
    tooltipPublicTransportFailure1: "الرجاء اختيار",
    labelPublicTransportFailure2: "متى كنت تخطط للسفر؟",
    tooltipPublicTransportFailure2: "الرجاء اختيار",
    labelPublicTransportFailure3: "ماذا حدث؟",
    tooltipPublicTransportFailure3: "يرجى التفاصيل",
    labelPublicTransportFailure4: "لماذا لم تستطع أن تأخذ وسائل النقل البديلة؟",
    tooltipPublicTransportFailure4: "يرجى التفاصيل",
    headingScheduledAirlineFailure: "تم إلغاء رحلتي المحجوزة مسبقا بشكل غير متوقع ولم أتمكن من العثور على بديل مناسب مما يعني أنني لم أتمكن من حضور الحدث؟",
    headingScheduledAirlineFailureDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelScheduledAirlineFailure1: "اسم شركة الطيران",
    tooltipScheduledAirlineFailure1: "اسم شركة الطيران",
    labelScheduledAirlineFailure2: "رقم الرحلة الجوية",
    tooltipScheduledAirlineFailure2: "رقم الرحلة الجوية",
    labelScheduledAirlineFailure3: "السفر من",
    tooltipScheduledAirlineFailure3: "السفر من",
    labelScheduledAirlineFailure4: "السفر إلى",
    tooltipScheduledAirlineFailure4: "السفر إلى",
    labelScheduledAirlineFailure5: "تاريخ الحادث؟",
    tooltipScheduledAirlineFailure5: "الرجاء اختيار",
    labelScheduledAirlineFailure6: "لماذا لم تتمكن من القيام برحلة بديلة؟",
    tooltipScheduledAirlineFailure6: "يرجى التفاصيل",
    headingTheftOfDocuments: "سرقت مستنداتي ولم يكن من الممكن ترتيب الاستبدال (الاستبدال) ضمن الإطار الزمني اللازم لذلك لا يمكنني حضور الحدث",
    headingTheftOfDocumentsDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelTheftOfDocuments1: "ما نوع المستند الذي تمت سرقته؟",
    tooltipTheftOfDocuments1: "الرجاء اختيار",
    labelTheftOfDocuments2: "متى حدث هذا؟",
    tooltipTheftOfDocuments2: "الرجاء اختيار",
    labelTheftOfDocuments3: "هل حاولت إعادة إصدار الوثائق؟",
    tooltipTheftOfDocuments3: "نعم أو لا",
    headingWorkRelocation: "طلب مني صاحب العمل تغيير موقع عملي لمدة ثلاثة أشهر على الأقل والمسافة كبيرة بمعنى أنني الآن غير قادر على حضور الحدث؟",
    headingWorkRelocationDecline: "يجب عليك عدم متابعة هذا الطلب حيث سيتم رفض المبلغ المسترد على الأرجح.",
    labelWorkRelocation1: "هل هذا نقل مؤقت أو دائم لعملك؟",
    tooltipWorkRelocation1: "الرجاء اختيار",
    labelWorkRelocation2: "كم من الوقت ستبقى في العنوان الجديد؟",
    tooltipWorkRelocation2: "يرجى التفاصيل",
    labelWorkRelocation3: "ما هو عنوان عملك الحالي؟",
    tooltipWorkRelocation3: "يرجى التفاصيل",
    labelWorkRelocation4: "ما هو عنوان عملك الجديد؟",
    tooltipWorkRelocation4: "يرجى التفاصيل",
    labelWorkRelocation5: "متى تم إخطارك بهذا الانتقال؟",
    tooltipWorkRelocation5: "الرجاء اختيار",
    headingRedundancy: "لقد تم تسريحي أنا أو أحد أعضاء الحجز من قبل صاحب العمل مما يعني أنه لم يعد بإمكاني حضور الحجز؟",
    headingRedundancyDecline: "يجب ألا تتابع هذا الطلب حيث من المرجح أن يتم رفض رد الأموال.",
    labelRedundancy1: "نوع العمل؟",
    tooltipRedundancy1: "يرجى الاختيار",
    labelRedundancy2: "منذ متى وأنت موظف في هذه الشركة؟",
    tooltipRedundancy2: "يرجى الاختيار",
    labelRedundancy3: "هل كان التكرار طوعيا؟",
    tooltipRedundancy3: "يرجى الاختيار",
    labelRedundancy4: "متى تم إخطارك بالتكرار الخاص بك؟",
    tooltipRedundancy4: "يرجى الاختيار",
    labelTestOption: "حدد نعم للمتابعة",
}