export default {
    labelBookingCancelledPostponedHeading: "予約がキャンセルまたは延期されましたか?続きを読む",
    labelBookingCancelledPostponedText1: "クリック",
    labelBookingCancelledPostponedText2: "詳細については。",
    labelNewRefundHeading: "新しい返金申請？",
    labelNewRefundText: "ここに予約参照を入力してください...",
    placeholderBookingReference: "予約番号",
    validationBookingReference: "予約参照が必要です",
    validationEmailAsBookingReference: "メールアドレスは予約番号として受け付けられません",
    buttonFindBooking: "予約を探す",
    labelEmailHeading: "すでに開始？",
    labelEmailText: "アプリケーションを取得するための電子メールリマインダーを送信します。",
    placeholderEmail: " Eメール",
    buttonEmailReminder: "メールリマインダー",
    labelCustomerName : "お名前",
    validationCustomerName: "顧客名が必要です",
    labelTypeOfBooking: "選択する",
    validationTypeOfBooking: "予約の種類が必要です",
    labelEventName:"イベント名",
    labelVenueName:" 会場名",
    labelFlightNumber:" フライトナンバー",
    labelOriginAirport: "オリジン空港",
    labelHotelName: "宿泊施設名",
    labelHotelLocation: "宿泊場所",
    labelCurrency: "通貨",
    validationCurrency: "通貨が必要です",
    validationCountryCurrency: "選択する通貨は、自国の通貨である必要があります",
    validationOfflinePayment: "選択した通貨と国では返金を処理できません。別の通貨と国を選択してください",
    labelBookingValue: "購入価格",
    validationBookingValue: "予約金額が必要です",
    validationBookingValueAmount: " 0より大きくなければなりません",
    labelDateOfPurchase: "購入日",
    validationDateOfPurchase: "購入日が必要です",
    labelEmailAddress: "ールアドレス",
    validationEmailAddress: "メールアドレスが必要です",
    validationEmailAddressFormat: "間違ったメールフォーマット",
    labelConfirmEmailAddress: "メールアドレス再入力",
    validationConfirmEmailAddressFormat: "間違ったメールフォーマット",
    validationConfirmEmailAddress: "確認メールアドレスが必要です",
    validationConfirmEmailAddressMatch: "メールアドレスが合わない",
    validationEmailDomain: "このメール プロバイダーは承認されていません。別のプロバイダーを使用してください",
    validationDateOfEvent: "イベントの日付が必要です",
    labelHomeCountry: "居住国",
    validationHomeCountry: "自国が必要です",
    labelContactNumberCountryCode: "国番号",
    validationContactNumberCountryCode: "国のダイヤルコードが必要です",
    labelContactNumber: "電話番号",
    validationContactNumber: "連絡先番号が必要です",
    labelAddressLine1: "住所",
    labelAddressLine2: "アパート・マンション名",
    labelCity: "都道府県",
    labelZipCode: "郵便番号",
    labelRegion: "選択する",
    validationRegion: "地域が必要です",
    labelNumberOfTicketsToRefund: "返金するチケットの数",
    validationNumberOfTicketsToRefund: " 払い戻しのチケット数が必要です",
    validationNumberOfTicketsToRefundValue: " 0より大きくなければなりません",
    labelCostPerTicket: "各チケットの費用",
    validationCostPerTicket: "チケット1枚あたりの費用が必要です",
    validationCostPerTicketValue: " 0より大きくなければなりません",
    labelTotalRefundAmount: "申請金額",
    validationTotalRefundAmount: "合計払い戻し額が必要です",
    validationTotalRefundAmountValue: " 0より大きくなければなりません",
    validationTotalRefundGreaterThanBookingValue: "払い戻し総額は、上記の予約額を超えることはできません",
    labelBookingConfirmationFiles: "予約確認のアップロード",
    validationBookingConfirmationFiles: "少なくとも1つのファイルを添付するか、[保存]をクリックして、ドキュメントができたら後で戻る",
    validationEvidenceFilesPopup: "提出する前に証拠をアップロードする必要があります。現在証拠がない場合は、申請書を保存し、証拠があれば戻ってください。電子コピー、スキャナーコピー、写真を受け付けています。",
    validationBookingConfirmationFilesPopup: "送信する前に、予約確認書をアップロードする必要があります。現在予約確認がない場合は、アプリケーションを保存して、受け取ったら戻ってください。電子コピー、スキャナーコピー、写真を受け付けています。",
    alertUploadDocuments: "メールに記載されている関連書類をアップロードしてから、ステップ 3 に進んでください。",
    labelRefundReasonCategory: "選択する",
    validationRefundReasonCategory: "返金理由が必要です",
    labelDateOfIncident: "インシデントの日付",
    labelAdditionalComments: "追加コメント",
    tooltipAdditionalComments: "追加コメント",
    validationAdditionalComments: "追加のコメントが必要です",
    labelUnableToNotifyWithinTimeLimit: "から60日以内に通知できません",
    labelReasonForNotificationOutsideTimeLimit: "制限時間外の通知説明",
    validationReasonForNotificationOutsideTimeLimit: " 60日後に通知する理由が必要です",
    labelRefundReasonFiles: "証拠をアップロードする",
    validationRefundReasonFiles: "少なくとも1つのファイルを添付するか、[保存]をクリックして、ドキュメントができたら後で戻る",
    validationTextScript:"ラテン文字と一致する必要があります。",
    optionTypeOfBooking1: "イベント",
    optionTypeOfBooking2: "旅行",
    optionTypeOfBooking3: "フライト",
    optionTypeOfBooking4: "宿泊施設",
    optionTypeOfBooking5: "転送",
    optionTypeOfBooking6: "パーキング",
    optionTypeOfBooking7: "その他の旅行",
    labelEventTypeDate: "イベントの日付",
    labelEventTypeTour: "ツアーの日付",
    labelEventTypeFlight: "フライトの日付",
    labelEventTypeCheckIn: "チェックイン日",
    labelEventTypeTransfer: "譲渡日",
    labelEventTypeParking: "駐車日",
    labelEventTypeOtherTravel: "旅行の日付",
    optionRefundReason11: "悪天候",
    optionRefundReason10: "軍隊と緊急サービスのリコール",
    optionRefundReason15: "審査日の変更",
    optionRefundReason18: "裁判所召喚",
    optionRefundReason17: "COVID-19渡航制限",
    optionRefundReason19: "COVID-19陽性検査",
    optionRefundReason20: "オペレーター/オーガナイザーによる予約のキャンセル",
    optionRefundReason21: "予約はオペレーター/主催者によって再スケジュールされました",
    optionRefundReason22: "間違った日付を予約しました",
    optionRefundReason23: "間違ったチケットを予約しました",
    optionRefundReason24: "予約したチケットが多すぎます",
    optionRefundReason4: "死",
    optionRefundReason16: "緊急事態",
    optionRefundReason14: "政府の旅行禁止",
    optionRefundReason9: "家の緊急事態",
    optionRefundReason1: "病気/怪我/事故",
    optionRefundReason8: "陪審員サービス",
    optionRefundReason7: "機械的故障",
    optionRefundReason2: "既存の病状",
    optionRefundReason3: "妊娠の合併症",
    optionRefundReason5: "公共交通機関の失敗",
    optionRefundReason6: "飛行妨害",
    optionRefundReason13: "文書の盗難",
    optionRefundReason12: "仕事の移転",
    optionRefundReason25: "職場の冗長性",
    optionRefundReason26: "テストオプション",
    optionRefundReasonConfirm1: "いいえ",
    optionRefundReasonConfirm2: "はい",
    optionRefundReasonArmedForces1: "陸軍",
    optionRefundReasonArmedForces2: "海軍",
    optionRefundReasonArmedForces3: "空軍",
    optionRefundReasonArmedForces4: "特殊部隊",
    optionRefundReasonArmedForces5: "海兵隊",
    optionRefundReasonArmedForces6: "火事",
    optionRefundReasonArmedForces7: "救急車",
    optionRefundReasonArmedForces8: "医師/看護師",
    optionRefundReasonArmedForces9: "歯医者",
    optionRefundReasonArmedForces10: "他",
    optionRefundReasonCourtSummons1: "刑事訴追",
    optionRefundReasonCourtSummons2: "民事訴訟",
    optionRefundReasonCourtSummons3: "検察側の証人",
    optionRefundReasonCourtSummons4: "民事事件の証人",
    optionRefundReasonCourtSummons5: "原告",
    optionRefundReasonCourtSummons6: "被告",
    optionRefundReasonCourtSummons7: "他",
    optionRefundReasonDeath1: "夫",
    optionRefundReasonDeath2: "妻",
    optionRefundReasonDeath3: "音",
    optionRefundReasonDeath4: "娘",
    optionRefundReasonDeath5: "父",
    optionRefundReasonDeath6: "母",
    optionRefundReasonDeath7: "兄弟",
    optionRefundReasonDeath8: "姉妹",
    optionRefundReasonDeath9: "祖父",
    optionRefundReasonDeath10: "お婆さん",
    optionRefundReasonDeath11: "他",
    optionRefundReasonHomeEmergency1: "火事",
    optionRefundReasonHomeEmergency2: "洪水",
    optionRefundReasonHomeEmergency3: "強盗",
    optionRefundReasonHomeEmergency4: "荒らし",
    optionRefundReasonHomeEmergency5: "家庭内虐待",
    optionRefundReasonHomeEmergency6: "攫う",
    optionRefundReasonHomeEmergency7: "他",
    optionRefundReasonCOVID19Person1: "自分",
    optionRefundReasonCOVID19Person2: "予約内の別の人物",
    optionRefundReasonCOVID19Person3: "私の世帯の人",
    optionRefundReasonCOVID19Person4: "他",
    optionRefundReasonCOVID19TestType1: "PCR検査",
    optionRefundReasonCOVID19TestType2: "ラットテスト",
    optionRefundReasonCOVID19TestType3: "ラテラルフローテスト",
    optionRefundReasonCOVID19TestType4: "自己管理テスト",
    optionRefundReasonCOVID19TestType5: "テストはありませんが、症状があります",
    optionRefundReasonCOVID19TestType6: "他",
    optionRefundReasonBreakdown1: "内訳",
    optionRefundReasonBreakdown2: "事故",
    optionRefundReasonBreakdown3: "火事",
    optionRefundReasonBreakdown4: "窃盗",
    optionRefundReasonPublicTransportType1: "列車",
    optionRefundReasonPublicTransportType2: "バス",
    optionRefundReasonPublicTransportType3: "路面電車",
    optionRefundReasonPublicTransportType4: "舟",
    optionRefundReasonDocumentType1: "旅券",
    optionRefundReasonDocumentType2: "旅行許可証",
    optionRefundReasonDocumentType3: "国民識別番号",
    optionRefundReasonDocumentType4: "チケット",
    optionRefundReasonWorkRelocationType1: "一時的",
    optionRefundReasonWorkRelocationType2: "パーマネント",
    optionRefundReasonWorkRelocationTemporary1: "0-3ヶ月",
    optionRefundReasonWorkRelocationTemporary2: "3-6ヶ月",
    optionRefundReasonWorkRelocationTemporary3: "6ヶ月以上",
    optionRefundReasonBookingRelationshipType1: "予約の誰か",
    optionRefundReasonBookingRelationshipType2: "予約の誰かの直接の親戚",
    optionEmploymentType1: "フルタイム",
    optionEmploymentType2: "パートタイム",
    optionEmploymentType3: "契約",
    optionEmploymentLengthType1: "0-1歳",
    optionEmploymentLengthType2: "1-2歳",
    optionEmploymentLengthType3: "2年以上",
    textRefundEvidenceHeading: "次の証拠を提出してください。",
    textRefundEvidence1: "航空会社の確認",
    textRefundEvidence2: "適切な裏付けとなる証拠",
    textRefundEvidence3: "故障サービスコールアウト確認",
    textRefundEvidence4: "死亡証明書",
    textRefundEvidence5: "航空会社からのキャンセル確認",
    textRefundEvidence6: "天気予報",
    textRefundEvidence7: "予約代理店からの連絡で、航空券の再発行機能がないことを確認する",
    textRefundEvidence8: "裁判所からの手紙",
    textRefundEvidence9: "診断書/医師の診断書",
    textRefundEvidenceDescription9: "病気や怪我の詳細、それが最初に発生した日付、それが予約に参加できないことを確認すること。",
    textRefundEvidence10: "雇用主の手紙の確認",
    textRefundEvidence11: "保険金請求対応",
    textRefundEvidence12: "雇用主からの手紙",
    textRefundEvidence13: "国別ホームページの確認",
    textRefundEvidence14: "試験変更届",
    textRefundEvidence15: "警察報告書",
    textRefundEvidence16: "警察の報告書と参照番号",
    textRefundEvidence17: "トランスポートプロバイダーの確認",
    textRefundEvidence18: "旅行警告",
    textRefundEvidence20: "COVID-19 テスト証明書",
    textRefundEvidence24: "政府隔離通知",
    textRefundEvidence25: "司令官からの手紙",
    textRefundEvidence26: "保険会社からの手紙",
    textRefundEvidence27: "住所の証明",
    textRefundEvidence28: "故人との関係の証明",
    textRefundEvidence29: "負傷者・病人との関係の証明",
    textRefundEvidence30: "給与明細",
    tooltipBookingReference: "予約番号を確認するには、予約確認書を確認し、延長サービスに関するセクションをご覧ください。",
    tooltipCustomerName: "予約に使用された姓名。",
    tooltipTypeOfBooking: "返金を希望する予約の種類。",
    tooltipEventName: "イベントの名前。",
    tooltipVenueName: "会場名。",
    tooltipFlightNumber: "便名。",
    tooltipOriginAirport: "出発空港。",
    tooltipHotelName: "宿泊施設の名前。",
    tooltipHotelLocation: "宿泊施設の場所。",
    tooltipCurrency: "予約の支払いに使用された通貨。",
    tooltipBookingValue: "予約に支払った合計金額。",
    tooltipDateOfPurchase: "予約が行われた日付。",
    tooltipEmailAddress: "有効なメールアドレスを使用してください。このメールは、返金申請時の連絡に使用します。",
    tooltipConfirmEmailAddress: "上記と同じメールアドレスを使用してください。これは検証用です。",
    tooltipHomeCountry: "これは、最終ステップで正しい銀行情報を表示するのに役立ちます。",
    tooltipContactNumberCountryCode: "連絡先番号の国コード。",
    tooltipContactNumber: "有効な番号を使用してください。返金申請時の連絡に使用します。",
    tooltipAddressLine1: "銀行口座の確認には住所が使用されますのでご注意ください。",
    tooltipAddressLine2: "アドレスの2行目。",
    tooltipCity: "あなたの故郷の都市、詳細は銀行の詳細を検証するために使用することができます。",
    tooltipZipCode: "郵便番号/郵便番号。",
    tooltipRegion: "あなたの故郷の都市の地域、詳細は銀行の詳細を検証するために使用することができます。",
    tooltipNumberOfTicketsToRefund: "払い戻しを希望するチケットの数。",
    tooltipTotalRefundAmount: "これは予約総額を超えることはできません。超えた場合、申請書を提出することはできません。",
    tooltipBookingConfirmationFiles: "完全な予約確認のスクリーンショット/添付ファイル。通常はメールに記載されています。",
    tooltipRefundReasonCategory: "払い戻しの理由を選択してください。",
    tooltipDateOfIncident: "予約に影響を与えたインシデントが発生した日付。",
    tooltipRefundExplanation: "払い戻しの請求を裏付ける詳細をできるだけ詳しく教えてください。",
    tooltipUnableToNotifyWithinTimeLimit: "60日以内に通知できません。",
    tooltipReasonForNotificationOutsideTimeLimit: "制限時間外の通知説明。",
    tooltipRefundReasonFiles: "必要書類をアップロードしてください。",
    tooltipEventTypeDate: "実際のイベント/予約の日付。",
    tooltipEventTypeTour: "実際のツアーの日付。",
    tooltipEventTypeFlight: "実際のフライトの日付。",
    tooltipEventTypeCheckIn: "実際のチェックインの日付。",
    tooltipEventTypeTransfer: "実際の転送日。",
    tooltipEventTypeParking: "実際の駐車日。",
    tooltipEventTypeOtherTravel: "実際の旅行の開始日。"
};