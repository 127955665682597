export enum RefundStatus {
    None = 0,
    Open = 1,
    Approved = 2,
    Referred = 3,
    Declined = 4,
    Retracted = 5,
    Settled = 6,
    Lapsed = 7,
    Incomplete = 8,
    New = 9,
    Expired = 10,
    AwaitingApproval = 11,
    Processing = 12
}