import {
    paymentLabelMessages,
    paymentLabelOverrides,
    paymentTooltipOverrides
} from '../form/utils/paymentFields';
import {LabelType} from '../form/enums/labelType';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../form/providers/FormProvider';
import {removeSpacesAndSpecialChars} from '../form/utils/helpers';
import {IDropDownItem} from '../form/Interfaces/IDropDownItem';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../form/Interfaces/IFormValues';

export function useLabels(currency: string, code: string, labelType: LabelType): string {

    const {
        paymentResponseFields
    } = useFormContext();

    const {t} = useTranslation();

    const getField = (code: string) => paymentResponseFields.find(x => x.code === code);

    const getOverride = (): string | undefined => {

        if (!currency) {
            return undefined;
        }

        const override = labelType === LabelType.Text ? paymentLabelOverrides(t)[code] : paymentTooltipOverrides(t)[code];

        if (override) {
            return override[currency];
        }

        return undefined;
    }

    const override = getOverride();
    if (override) {
        return override;
    }

    const type = labelType === LabelType.Text ? 'payment:label' : 'payment:tooltip';

    if (paymentLabelMessages[code]) {
        const field = getField(code);
        if (field && field.informationMessage) {
            return t(type + removeSpacesAndSpecialChars(field.informationMessage));
        }
    }

    return null;
}

export function useWisePaymentItems() {

    const {
        paymentResponseFields
    } = useFormContext();

    const getWiseRelatedPaymentItems = (name: string) => {

        const items: IDropDownItem[] = paymentResponseFields.find(x => x.code === name)?.valuesAllowed.map(item => {
            return {key: item.key, value: item.name}
        });

        return items;
    }

    const bankNameSelect = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.bankNameSelect));
    const bankAccountType = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.bankAccountType));
    const paymentType = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.paymentType));
    const remittanceLine3Select = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.remittanceLine3Select));
    const remittanceLine4Select = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.remittanceLine4Select));
    const bankSwiftBicSelect = getWiseRelatedPaymentItems(nameof<IFormValues>(x => x.bankSwiftBicSelect))

    return {
        bankNameSelect,
        bankAccountType,
        paymentType,
        remittanceLine3Select,
        remittanceLine4Select,
        bankSwiftBicSelect
    };
}

export function useFieldHidden() {

    const {
        paymentResponseFields,
        bankBranchesDropdownItems
    } = useFormContext();

    const getField = (code: string) => paymentResponseFields.find(x => x.code === code);
    const isFieldHidden = (code: string): boolean => getField(code) === undefined;

    const bankAccountNumber = isFieldHidden(nameof<IFormValues>(x => x.bankAccountNumber));
    const cardNumber = isFieldHidden(nameof<IFormValues>(x => x.cardNumber));
    const sortCode = isFieldHidden(nameof<IFormValues>(x => x.sortCode));
    const bankName = isFieldHidden(nameof<IFormValues>(x => x.bankName));
    const paymentType = getField(nameof<IFormValues>(x => x.paymentType))?.valuesAllowed.length <= 1;
    const bankNameSelect = isFieldHidden(nameof<IFormValues>(x => x.bankNameSelect));
    const branchCodeSelect = isFieldHidden(nameof<IFormValues>(x => x.branchCodeSelect)) || bankBranchesDropdownItems?.length === 0;
    const bankAccountType = isFieldHidden(nameof<IFormValues>(x => x.bankAccountType));
    const branchCode = isFieldHidden(nameof<IFormValues>(x => x.branchCode));
    const remittanceLine3Select = isFieldHidden(nameof<IFormValues>(x => x.remittanceLine3Select));
    const remittanceLine3 = isFieldHidden(nameof<IFormValues>(x => x.remittanceLine3));
    const remittanceLine4Select = isFieldHidden(nameof<IFormValues>(x => x.remittanceLine4Select));
    const remittanceLine4 = isFieldHidden(nameof<IFormValues>(x => x.remittanceLine4));
    const dateOfBirth = isFieldHidden(nameof<IFormValues>(x => x.dateOfBirth));
    const bankSwiftBic =isFieldHidden(nameof<IFormValues>(x => x.bankSwiftBic));
    const bankSwiftBicSelect = isFieldHidden(nameof<IFormValues>(x => x.bankSwiftBicSelect));

    return {
        bankAccountNumber,
        cardNumber,
        sortCode,
        bankName,
        paymentType,
        bankNameSelect,
        branchCodeSelect,
        bankAccountType,
        branchCode,
        remittanceLine3Select,
        remittanceLine3,
        remittanceLine4Select,
        remittanceLine4,
        dateOfBirth,
        bankSwiftBic,
        bankSwiftBicSelect
    }
}