export default {
    labelBookingCancelledPostponedHeading: "Har din bokning blivit avbokad eller uppskjuten? Läs mer",
    labelBookingCancelledPostponedText1: "Klick",
    labelBookingCancelledPostponedText2: "för mer information.",
    labelNewRefundHeading: "Ny begäran om ersättning?",
    labelNewRefundText: "Ange din bokningsreferens här ...",
    placeholderBookingReference: "Bokningsreferens",
    validationBookingReference: "En bokningsreferens krävs",
    validationEmailAsBookingReference: "E-postadress accepteras inte som bokningsreferens",
    buttonFindBooking: "Hitta en reservation",
    labelEmailHeading: "Har du redan börjat?",
    labelEmailText: "Skicka ett påminnelsemeddelande för att hämta din ansökan.",
    placeholderEmail: "E-post",
    buttonEmailReminder: "Påminnelse via e-post",
    labelCustomerName: "Kundnamn",
    validationCustomerName: "Kundnamn krävs",
    labelTypeOfBooking: "Typ av bokning",
    validationTypeOfBooking: "Typ av bokning krävs",
    labelEventName: "Event of the event",
    labelVenueName: "Platsnamn",
    labelFlightNumber: "Flygnummer",
    labelOriginAirport: "Origin flygplats",
    labelHotelName: "Boendets namn",
    labelHotelLocation: "Boende",
    labelCurrency: "Valuta",
    validationCurrency: "Valuta krävs",
    validationCountryCurrency: "Den valda valutan måste vara valutan i ditt hemland",
    validationOfflinePayment: "Vi kan inte behandla återbetalningar för ditt val av valuta och land, välj alternativ",
    labelBookingValue: "Bokningsvärde",
    validationBookingValue: "Bokningsvärdet krävs",
    validationBookingValueAmount: "Måste vara större än 0",
    labelDateOfPurchase: "Inköpsdatum",
    validationDateOfPurchase: "Köpdatum krävs",
    labelEmailAddress: "E-postadress",
    validationEmailAddress: "E-postadress krävs",
    validationEmailAddressFormat: "Fel e-postformat",
    labelConfirmEmailAddress: "Bekräfta din e-postadress",
    validationConfirmEmailAddressFormat: "Fel e-postformat",
    validationConfirmEmailAddress: "E-postadressen för bekräftelse krävs",
    validationConfirmEmailAddressMatch: "e-postadresserna matchar inte",
    validationEmailDomain: "Denna e-postleverantör accepteras inte, använd ett alternativ",
    validationDateOfEvent: "Datumet för händelsen krävs",
    labelHomeCountry: "Native country",
    validationHomeCountry: "Ursprungslandet krävs",
    labelContactNumberCountryCode: "Kontaktnummer Landskod",
    validationContactNumberCountryCode: "Landets landskod krävs",
    labelContactNumber: "Contact number",
    validationContactNumber: "Kontaktnumret krävs",
    labelAddressLine1: "Adress 1",
    labelAddressLine2: "Adressrad 2",
    labelCity: "City",
    labelZipCode: "Postal code",
    labelRegion: "Region",
    validationRegion: "Region krävs",
    labelNumberOfTicketsToRefund: "Antal biljetter att återbetala",
    validationNumberOfTicketsToRefund: "Antalet biljetter som ska återbetalas krävs",
    validationNumberOfTicketsToRefundValue: "Måste vara större än 0",
    labelCostPerTicket: "Kostnad för varje biljett",
    validationCostPerTicket: "Kostnaden per biljett är obligatorisk",
    validationCostPerTicketValue: "Måste vara större än 0",
    labelTotalRefundAmount: "Totalt återbetalningsbelopp",
    validationTotalRefundAmount: "Det totala återbetalningsbeloppet krävs",
    validationTotalRefundAmountValue: "Måste vara större än 0",
    validationTotalRefundGreaterThanBookingValue: "Det totala återbetalningsbeloppet kan inte vara högre än bokningsvärdet ovan",
    labelBookingConfirmationFiles: "Ladda ner bokningsbekräftelsen",
    validationBookingConfirmationFiles: "Bifoga minst en fil eller klicka på Spara och kom tillbaka senare när du har dokumenten",
    validationEvidenceFilesPopup: "Bevis måste laddas upp innan de skickas. Om du inte har bevisen just nu, vänligen spara programmet och returnera när du har. Vi accepterar elektroniska kopior, skannerkopior och foton.",
    validationBookingConfirmationFilesPopup: "Bokningsbekräftelse måste laddas upp innan du skickar in. Om du inte har bokningsbekräftelsen just nu vänligen spara ansökan och returnera när du har. Vi accepterar elektroniska kopior, skannerkopior och foton.",
    alertUploadDocuments: "Ladda upp den relevanta dokumentationen i e-postmeddelandet och fortsätt sedan till steg 3.",
    labelRefundReasonCategory: "Orsak till återbetalning",
    validationRefundReasonCategory: "Anledning för återbetalning krävs",
    labelDateOfIncident: "Datum för incidenten",
    labelAdditionalComments: "Ytterligare kommentarer",
    tooltipAdditionalComments: "Ytterligare kommentarer",
    validationAdditionalComments: "Ytterligare kommentarer krävs",
    labelUnableToNotifyWithinTimeLimit: "Det går inte att meddela inom 60 dagar",
    labelReasonForNotificationOutsideTimeLimit: "Meddelande utanför tidsgränsen Förklaring",
    validationReasonForNotificationOutsideTimeLimit: "En anledning till avisering efter 60 dagar krävs",
    labelRefundReasonFiles: "Ladda ner bevis",
    validationRefundReasonFiles: "Bifoga minst en fil eller klicka på Spara och kom tillbaka senare när du har dokumenten",
    validationTextScript:"Måste matcha latinska skript",
    optionTypeOfBooking1: "En händelse",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Flight",
    optionTypeOfBooking4: "Boende",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parkering",
    optionTypeOfBooking7: "Andra resor",
    labelEventTypeDate: "Datum för händelsen",
    labelEventTypeTour: "Tour of the tour",
    labelEventTypeFlight: "Flygdatum",
    labelEventTypeCheckIn: "Ankomstdatum",
    labelEventTypeTransfer: "Datum för överföring",
    labelEventTypeParking: "Datum för parkering",
    labelEventTypeOtherTravel: "Resedatum",
    optionRefundReason11: "ogynnsamma väderförhållanden",
    optionRefundReason10: "Återkallelse av väpnade styrkor och räddningstjänster",
    optionRefundReason15: "Ändringar av examinationsdatum",
    optionRefundReason18: "Kallelse till domstolen",
    optionRefundReason17: "Covid-19 reserestriktioner",
    optionRefundReason19: "COVID-19 positivt test",
    optionRefundReason20: "Bokning avbruten av operatör/arrangör",
    optionRefundReason21: "Bokning ombokad av operatör/arrangör",
    optionRefundReason22: "Bokat fel datum",
    optionRefundReason23: "Bokade fel biljetter",
    optionRefundReason24: "Bokat för många biljetter",
    optionRefundReason4: "Death",
    optionRefundReason16: "Nödsituationer",
    optionRefundReason14: "Regeringsförbud",
    optionRefundReason9: "Home emergency",
    optionRefundReason1: "Sjukdom / skada / olycka",
    optionRefundReason8: "Jurytjänst",
    optionRefundReason7: "Mekaniskt fel",
    optionRefundReason2: "Befintligt medicinskt tillstånd",
    optionRefundReason3: "Graviditetskomplikation",
    optionRefundReason5: "Kollektivtrafiken misslyckades",
    optionRefundReason6: "Flygstörning",
    optionRefundReason13: "Stöld av dokument",
    optionRefundReason12: "Arbetsflyttning",
    optionRefundReason25: "Redundans på arbetsplatsen",
    optionRefundReason26: "Testalternativ",
    optionRefundReasonConfirm1: "Nej",
    optionRefundReasonConfirm2: "Ja",
    optionRefundReasonArmedForces1: "Armé",
    optionRefundReasonArmedForces2: "Marin",
    optionRefundReasonArmedForces3: "Flygvapen",
    optionRefundReasonArmedForces4: "Specialstyrkor",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Eld",
    optionRefundReasonArmedForces7: "Ambulans",
    optionRefundReasonArmedForces8: "Läkare/sjuksköterska",
    optionRefundReasonArmedForces9: "Tandläkare",
    optionRefundReasonArmedForces10: "Annan",
    optionRefundReasonCourtSummons1: "Åtal",
    optionRefundReasonCourtSummons2: "Tvistemål",
    optionRefundReasonCourtSummons3: "Vittne i ett åtal",
    optionRefundReasonCourtSummons4: "Vittne i tvistemål",
    optionRefundReasonCourtSummons5: "Kärande",
    optionRefundReasonCourtSummons6: "Svarande",
    optionRefundReasonCourtSummons7: "Annan",
    optionRefundReasonDeath1: "Man",
    optionRefundReasonDeath2: "Hustru",
    optionRefundReasonDeath3: "Ljud",
    optionRefundReasonDeath4: "Dotter",
    optionRefundReasonDeath5: "Far",
    optionRefundReasonDeath6: "Mor",
    optionRefundReasonDeath7: "Bror",
    optionRefundReasonDeath8: "Syster",
    optionRefundReasonDeath9: "Farfar",
    optionRefundReasonDeath10: "Mormor",
    optionRefundReasonDeath11: "Annan",
    optionRefundReasonHomeEmergency1: "Eld",
    optionRefundReasonHomeEmergency2: "Översvämning",
    optionRefundReasonHomeEmergency3: "Rån",
    optionRefundReasonHomeEmergency4: "Vandalism",
    optionRefundReasonHomeEmergency5: "Misshandel i hemmet",
    optionRefundReasonHomeEmergency6: "Kidnappa",
    optionRefundReasonHomeEmergency7: "Annan",
    optionRefundReasonCOVID19Person1: "Mig",
    optionRefundReasonCOVID19Person2: "En annan person i bokningen",
    optionRefundReasonCOVID19Person3: "En person i mitt hushåll",
    optionRefundReasonCOVID19Person4: "Annan",
    optionRefundReasonCOVID19TestType1: "PCR-test",
    optionRefundReasonCOVID19TestType2: "RAT-test",
    optionRefundReasonCOVID19TestType3: "Lateralt flödestest",
    optionRefundReasonCOVID19TestType4: "Självadministrerat test",
    optionRefundReasonCOVID19TestType5: "Inget test men jag har symtom",
    optionRefundReasonCOVID19TestType6: "Annan",
    optionRefundReasonBreakdown1: "Sammanbrott",
    optionRefundReasonBreakdown2: "Olycka",
    optionRefundReasonBreakdown3: "Eld",
    optionRefundReasonBreakdown4: "Stöld",
    optionRefundReasonPublicTransportType1: "Tåg",
    optionRefundReasonPublicTransportType2: "Buss",
    optionRefundReasonPublicTransportType3: "Spårvagn",
    optionRefundReasonPublicTransportType4: "Båt",
    optionRefundReasonDocumentType1: "Pass",
    optionRefundReasonDocumentType2: "Resetillstånd",
    optionRefundReasonDocumentType3: "Nationellt ID",
    optionRefundReasonDocumentType4: "Biljetter",
    optionRefundReasonWorkRelocationType1: "Temporär",
    optionRefundReasonWorkRelocationType2: "Permanent",
    optionRefundReasonWorkRelocationTemporary1: "0-3 månader",
    optionRefundReasonWorkRelocationTemporary2: "3-6 månader",
    optionRefundReasonWorkRelocationTemporary3: "Över 6 månader",
    optionRefundReasonBookingRelationshipType1: "Någon i bokningen",
    optionRefundReasonBookingRelationshipType2: "Omedelbar släkting till någon i bokningen",
    optionEmploymentType1: "Heltid",
    optionEmploymentType2: "Deltid",
    optionEmploymentType3: "Kontrakt",
    optionEmploymentLengthType1: "0-1 år",
    optionEmploymentLengthType2: "1-2 år",
    optionEmploymentLengthType3: "Mer än 2 år",
    textRefundEvidenceHeading: "Ange följande bevis:",
    textRefundEvidence1: "Bekräftelse från flygbolag",
    textRefundEvidence2: "Eventuella lämpliga styrkande handlingar",
    textRefundEvidence3: "Bekräftelse av avbrottstjänstanrop",
    textRefundEvidence4: "Dödsattest",
    textRefundEvidence5: "Avbokning av bekräftelse från flygbolaget",
    textRefundEvidence6: "Väderprognos",
    textRefundEvidence7: "Korrespondens från bokningsagent som bekräftar ingen förmåga att utfärda biljetter igen",
    textRefundEvidence8: "Brev från domstolen",
    textRefundEvidence9: "Läkarintyg / Läkarintyg",
    textRefundEvidenceDescription9: "Bekräfta uppgifter om sjukdomen eller skadan, det datum då den först inträffade, att den hindrar dig från att delta i bokningen.",
    textRefundEvidence10: "Bekräftelse av arbetsgivarbrev",
    textRefundEvidence11: "Korrespondens med försäkringsanspråk",
    textRefundEvidence12: "Brev från arbetsgivare",
    textRefundEvidence13: "Bekräftelse av den nationella regeringens webbplats",
    textRefundEvidence14: "Meddelande om ändring av examination",
    textRefundEvidence15: "Polisrapport",
    textRefundEvidence16: "Polisanmälan och referensnummer",
    textRefundEvidence17: "Bekräftelse från transportleverantör",
    textRefundEvidence18: "Resevarning",
    textRefundEvidence20: "Intyg om covid-19-test",
    textRefundEvidence24: "Regeringens isoleringsmeddelande",
    textRefundEvidence25: "Brev från befälhavare",
    textRefundEvidence26: "Brev från försäkringsgivare",
    textRefundEvidence27: "Bevis på adress",
    textRefundEvidence28: "Bevis på förhållande till den avlidne",
    textRefundEvidence29: "Bevis på relation till den skadade/sjuka personen",
    textRefundEvidence30: "Lönebesked",
    tooltipBookingReference: "För att identifiera din bokningsreferens, kontrollera din bokningsbekräftelse och se avsnittet om vår utökade tjänst.",
    tooltipCustomerName: "För- och efternamn som användes vid bokningen.",
    tooltipTypeOfBooking: "Typ av bokning som du vill få tillbaka.",
    tooltipEventName: "Namnet på evenemanget.",
    tooltipVenueName: "Namnet på platsen.",
    tooltipFlightNumber: "Flygnumret.",
    tooltipOriginAirport: "Avgångsflygplatsen.",
    tooltipHotelName: "Boendets namn.",
    tooltipHotelLocation: "Platsen för boendet.",
    tooltipCurrency: "Valutan som används för att betala för bokningen.",
    tooltipBookingValue: "Totalt pris du betalade för bokningen.",
    tooltipDateOfPurchase: "Datumet då bokningen gjordes.",
    tooltipEmailAddress: "Använd en giltig e-postadress. Vi använder detta e-postmeddelande för korrespondens under återbetalningsansökan.",
    tooltipConfirmEmailAddress: "Använd samma e-postadress som ovan, detta är för validering.",
    tooltipHomeCountry: "Detta hjälper oss att få fram rätt bankinformation i det sista steget.",
    tooltipContactNumberCountryCode: "Kontaktnummer landskod.",
    tooltipContactNumber: "Använd ett giltigt nummer. Vi använder detta för korrespondens under återbetalningsansökan.",
    tooltipAddressLine1: "Observera att din adress kommer att användas när du validerar ditt bankkonto.",
    tooltipAddressLine2: "Andra adressraden.",
    tooltipCity: "Din hemstad, detaljer kan användas för att validera bankuppgifter.",
    tooltipZipCode: "Postnummer.",
    tooltipRegion: "Region i din hemstad, detaljer kan användas för att validera bankuppgifter.",
    tooltipNumberOfTicketsToRefund: "Antalet biljetter du vill få tillbaka.",
    tooltipTotalRefundAmount: "Detta kan inte vara mer än det totala bokningsvärdet, du kommer inte att kunna skicka in ansökan om den gör det.",
    tooltipBookingConfirmationFiles: "Skärmdump / bifogad bokningsbekräftelse som vanligtvis finns i dina e-postmeddelanden.",
    tooltipRefundReasonCategory: "Välj din anledning till återbetalning.",
    tooltipDateOfIncident: "Datumet då incidenten som påverkade din bokning inträffade.",
    tooltipRefundExplanation: "Vänligen ge så mycket information som möjligt som stöder återbetalningskravet.",
    tooltipUnableToNotifyWithinTimeLimit: "Det går inte att meddela inom 60 dagar.",
    tooltipReasonForNotificationOutsideTimeLimit: "Meddelande utanför tidsgränsen Förklaring.",
    tooltipRefundReasonFiles: "Ladda upp något av de nödvändiga dokumenten.",
    tooltipEventTypeDate: "Datum för den aktuella händelsen / bokningen.",
    tooltipEventTypeTour: "Datum för själva turnén.",
    tooltipEventTypeFlight: "Datum för den faktiska flygningen.",
    tooltipEventTypeCheckIn: "Datum för själva incheckningen.",
    tooltipEventTypeTransfer: "Datum för den faktiska överföringen.",
    tooltipEventTypeParking: "Datum för den faktiska parkeringen.",
    tooltipEventTypeOtherTravel: "Startdatum för den faktiska resan."
};