export default {
    labelSavedApplicationReference: "Lagret applikasjon ref:",
    labelLanguage: "Språk",
    messageWarningBookingHeading1: "Booking kansellert:",
    messageWarningBookingHeading2: "Booking utsatt / Omberammet:",
    messageWarningBookingBody1: "Hvis bestillingen din har blitt kansellert av arrangøren eller flyselskapet, vil ikke dette teamet kunne hjelpe deg med refusjon. Du må kontakte kundeserviceteamet ditt direkte; Se bestillingsbekreftelsen eller nettstedet ditt for kontaktinformasjon.",
    messageWarningBookingBody2: "Hvis bestillingen din har blitt utsatt, vil de utvidede refunderbare vilkårene overføres til den nye datoen. Hvis du har en gyldig grunn til refusjon i henhold til våre vilkår, vennligst fyll ut skjemaet med riktig støttende bevis.",
    messageWarningBookingBody3: "Merk: Denne tjenesten kan bare gi refusjon av årsakene som er inkludert i ordlyden vår, som er tilgjengelig i bestillingsbekreftelsen din.",
    messageWarningBookingBody4: "Hvis du ikke kan gjøre bestillingen på grunn av årsaker utenfor våre vilkår, vil vi ikke kunne hjelpe. Du må kontakte kundeserviceteamet ditt direkte, da de kanskje kan hjelpe; Se bestillingsbekreftelsen eller nettstedet ditt for kontaktinformasjon.",
    linkWarningBooking: "her",
    formSectionNavigatorStep1: "Trinn 1",
    formSectionNavigatorStep2: "Trinn 2",
    formSectionNavigatorStep3: "Trinn 3",
    formSectionNavigatorStep1Text: "Kontakt- og bestillingsdetaljer",
    formSectionNavigatorStep2Text: "Refusjonsårsak",
    formSectionNavigatorStep3Text: "Betalingsdetaljer",
    messageBookingMatchedAndFound: "Hvis bestillingsreferansen din er knyttet til en eksisterende applikasjon, sender vi deg en e-post med en lenke for å få tilgang til applikasjonen din",
    messageBookingMatchedAndFoundCompleted: "Refusjonssøknaden din er allerede godkjent! Hvis du har problemer med betalingen, kan du kontakte:",
    applicationDeclinedMessage: "Søknaden din har blitt avslått og er nå stengt.",
    messageBookingNotFound1: "Vi finner ikke en bestilling med den referansen. Vennligst sjekk e-postbekreftelsen din og skriv inn igjen.",
    messageBookingNotFound2: "Ikke bekymre deg, hvis bestillingsreferansen din ikke fungerer, kan du velge 'Finn bestilling' og skrive inn detaljene dine. Når du fyller ut skjemaet, kan du laste opp en kopi av e-postbekreftelsen for å hjelpe oss med å finne bestillingsdetaljene dine.",
    messageLocatingBooking: "Finne din bestilling ...",
    messageLoadingBank: "Laster inn bankinformasjon...",
    messageFailCurrencyLookup: "Feil ved henting av valuta",
    messageSavingApplication: "Lagre søknaden din ...",
    messageSavedApplication: "Refusjon Søknad vellykket lagret",
    messageFailSavedApplication: "Refusjonssøknaden kunne ikke lagres",
    messageSendingEmailReminder: "Behandling...",
    messageErrorLocationDetails: "Feil ved henting av stedsdetaljer",
    messageFailFormSubmission: "Skjemainnsending mislyktes",
    messageApplicationCannotBeFound: "Finner ikke søknaden",
    messageApplicationRetrievalError: "Feil ved henting av program",
    messageSubmittingApplication: "Sende inn søknad...",
    messageEventDateLimitError: "Årsak til varsling utenfor frist",
    messageAllTicketsAssigned: "Alle billetter har blitt tildelt en annen refusjonssøknad.",
    selectPrefix: "Velge",
    selectPostfix: "",
    fileUpload: "Klikk her for å laste opp",
    fileUploadAcceptedFilesMessage: "Vi godtar bare PDF-filer og bildeformater av .png, .jpeg, .heic .webp",
    fileUploadNoFiles: "0 filer valgt",
    fileUploadPreviouslyUploaded: "Tidligere lastet opp:",
    fileUploadSelectedUploaded: "De valgte er allerede lastet opp:",
    buttonNext: "Neste",
    buttonPrevious: "Foregående",
    buttonSave: "Lagre",
    buttonCancel: "Annullere",
    buttonSubmit: "Forelegge",
    buttonConfirm: "Bekrefte",
    buttonYes: "Ja",
    buttonNo: "Nei",
    buttonClose: "Lukke",
    linkPrivacyStatement: "Personvernerklæring",
    linkComplaintsPolicy: "Retningslinjer for klage",
    validationRequired: "Påkrevd",
    validationInvalid: "Ugyldig",
    labelUploadPrefix: "Opplasting",
    labelUploadPostfix: "",
    labelChange: "Forandre",
    labelView: "Utsikt",
    browserNavigationMessage: "Ulagrede endringer vil gå tapt"
}