export default {
    headingAdverseWeather: "Teil on rasked ebasoodsad ilmastikutingimused, mis muudavad üritusele reisimise ohtlikuks ja selleks on andnud loa valitsusasutus (nt kohalik omavalitsus, politsei jne). Ja teil on tõendeid selle tõestamiseks?",
    headingAdverseWeatherDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    tooltipRefundReasonConfirm: "Jah või ei",
    labelBookingCancelledByOperator: "Korraldaja/korraldaja tühistas piletid.",
    labelBookingRescheduledByOperator: "Operaator/korraldaja muutis kuupäeva ja ma ei saa uutel kuupäevadel osaleda.",
    labelBookedWrongDate: "Mina või broneeringu liige tegin piletite ostmisel vea ja broneerisin vale kuupäeva.",
    labelBookedWrongTickets: "Mina või broneeringu liige tegin piletite ostmisel vea ja broneerisin valed piletid.",
    labelBookedTooManyTickets: "Mina või broneeringu liige tegin piletite ostmisel vea ja broneerisin liiga palju pileteid.",
    labelCOVID19TravelRestrictions: "Mina või broneeringu liige ei saa COVID-19 reisikeelu tõttu osaleda.",
    labelADWrongSelection: "Võimalik, et valisite tagasimakse tegemiseks vale põhjuse. Vaadake uuesti üle rippmenüü Tagasimakse põhjus.",
    labelAdverseWeather1: "Miks tähendas halb ilm, et te ei saanud üritusel osaleda?",
    tooltipAdverseWeather1: "Palun täpsustage",
    labelAdverseWeather2: "Juhtumi kuupäev",
    tooltipAdverseWeather2: "Palun valige",
    headingArmedForces: "Teie tööandja, kes on relvajõud või hädaabiteenistus, palub teil tööle tulla, kui te ei olnud algselt plaanis seda teha, ja see takistab teil üritusel osaleda?",
    headingArmedForcesDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelArmedForces1: "Millistes jõududes või hädaabiteenistuses te töötate?",
    tooltipArmedForces1: "Palun valige",
    labelArmedForcesOther1: "Määrake",
    tooltipArmedForcesOther1: "Määrake",
    labelArmedForces2: "Juhtumi kuupäev",
    tooltipArmedForces2: "Palun valige",
    headingChangesToExam: "Teie kooli / kolledži jne on teie eksami kuupäevi muutnud? See ei ole ebaõnnestunud eksam, mille peate uuesti sooritama?",
    headingChangesToExamDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelExam1: "Milline oli esialgne eksamikuupäev?",
    tooltipExam1: "Palun valige",
    labelExam2: "Mis on uus eksamikuupäev?",
    tooltipExam2: "Palun valige",
    labelExam3: "Miks eksami kuupäev muutus?",
    tooltipExam3: "Palun sisestage üksikasjad",
    labelExam4: "Kas kukkusite eelmisel eksamil läbi?",
    tooltipExam4: "Jah või ei",
    headingCourtSummons: "Teid on kutsutud kohtusse tunnistajana kohtusse ilmuma sündmusega samadel kuupäevadel?",
    headingCourtSummonsDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelCourtSummons1: "Mis on teie kohtukutse põhjus?",
    tooltipCourtSummons1: "Palun valige",
    labelCourtSummonsOther1: "Määrake",
    tooltipCourtSummonsOther1: "Määrake",
    labelCourtSummons2: "Millal kohtukutse väljastati?",
    tooltipCourtSummons2: "Palun valige",
    headingCOVID19Test: "Minul, minu leibkonna liikmel või üritusel osaleval grupi liikmel on diagnoositud COVID-19?",
    headingCOVID19TestDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelCOVID19Test1: "Kellel on COVID-19?",
    tooltipCOVID19Test1: "Palun valige",
    labelCOVID19TestOther1a: "Määrake",
    labelCOVID19TestOther1b: "Nime määramine",
    tooltipCOVID19TestOther1a: "Määrake",
    tooltipCOVID19TestOther1b: "Nime määramine",
    labelCOVID19Test2: "Mis tüüpi COVID-19 test teil oli?",
    tooltipCOVID19Test2: "Palun valige",
    labelCOVID19TestOther2: "Määrake",
    tooltipCOVID19TestOther2: "Määrake",
    labelCOVID19Test3: "Mis oli teie COVID-19 testi kuupäev?",
    tooltipCOVID19Test3: "Palun valige",
    headingDeath: "Minu lähisugulane on surnud ja see takistab mul üritusel osaleda?",
    headingDeathDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelDeath1: "Milline teie pereliige on surnud?",
    tooltipDeath1: "Palun valige",
    labelDeathOther1: "Määrake",
    tooltipDeathOther1: "Määrake",
    labelDeath2: "Millal oli surmakuupäev?",
    tooltipDeath2: "Palun valige",
    labelDeath3: "Lahkunu nimi",
    tooltipDeath3: "Määrake",
    headingEmergency: "Mul on olnud tõeline hädaolukord, mis on väljaspool minu kontrolli ja ettenägematu, mille kohta saan tõendeid esitada?",
    headingEmergencyDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelEmergency1: "Palun selgitage, mis liiki hädaolukord on?",
    tooltipEmergency1: "Palun täpsustage",
    labelEmergency2: "Millal see juhtus?",
    tooltipEmergency2: "Palun valige",
    labelEmergency3: "Miks see olukord takistab teil üritusel osalemast?",
    tooltipEmergency3: "Palun täpsustage",
    labelEmergency4: "Kas teil on tõendeid selle hädaolukorra kohta?",
    tooltipEmergency4: "Jah või ei",
    headingHomeEmergency: "Mul on kodune hädaolukord, mis on röövimine, üleujutus, tulekahju, vandalism, koduvägivald, mis takistab mul üritusel osaleda?",
    headingHomeEmergencyDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelHomeEmergency1: "Mis on kodune hädaolukord?",
    tooltipHomeEmergency1: "Palun valige",
    labelHomeEmergencyOther1: "Määrake",
    tooltipHomeEmergencyOther1: "Määrake",
    labelHomeEmergency2: "Millal see juhtus?",
    tooltipHomeEmergency2: "Palun valige",
    labelHomeEmergency3: "Miks see takistas teil üritusel osalemast?",
    tooltipHomeEmergency3: "Palun täpsustage",
    headingInjury: "Minul, minu leibkonna liikmel või üritusel osaleva rühma liikmel on diagnoositud tervislik seisund või vigastus, mis takistab meil nüüd üritusele minemast?",
    headingInjuryDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelInjury1: "Milline on haiguse/vigastuse/õnnetuse olemus?",
    tooltipInjury1: "Palun täpsustage",
    labelInjury2: "Millal see juhtus?",
    tooltipInjury2: "Palun valige",
    labelInjury3: "Kas olete selle haiguse/vigastuse tõttu käinud meditsiiniasutuses?",
    tooltipInjury3: "Jah või ei",
    labelInjury4: "Kas see oli juba olemasolev meditsiiniline seisund?",
    tooltipInjury4: "Jah või ei",
    labelInjury5: "Millal see esimest korda diagnoositi?",
    tooltipInjury5: "Palun valige",
    labelInjury6: "Miks see tähendab, et te ei saa üritusel osaleda?",
    tooltipInjury6: "Palun täpsustage",
    labelInjury7: "Kes on haiguse/vigastuse läbi põdenud?",
    tooltipInjury7: "Palun valige",
    headingJuryService: "Mind või broneeringu liiget on kutsutud žüriiteenindusse?",
    headingJuryServiceDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelJuryService1: "Millal teid žüriiteenistusest teavitati?",
    tooltipJuryService1: "Palun valige",
    headingMechanicalBreakdown: "Meie sõidukil on olnud mehaaniline rike või õnnetus ja see takistab meil üritusel osalemast?",
    headingMechanicalBreakdownDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelMechanicalBreakdown1: "Mis sõidukiga juhtus?",
    tooltipMechanicalBreakdown1: "Palun valige",
    labelMechanicalBreakdown2: "Millal see juhtus?",
    tooltipMechanicalBreakdown2: "Palun valige",
    labelMechanicalBreakdown3: "Miks te ei saanud kasutada alternatiivset transporti?",
    tooltipMechanicalBreakdown3: "Palun täpsustage",
    headingPregnancyComplication: "Minul või broneeringu liikmel on rasedustüsistusi, mille on diagnoosinud meditsiinitöötaja ja mis seetõttu takistavad üritusel osalemist?",
    headingPregnancyComplicationDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelPregnancyComplication1: "Milline on raseduse komplikatsiooni olemus?",
    tooltipPregnancyComplication1: "Palun täpsustage",
    labelPregnancyComplication2: "Millal see juhtus?",
    tooltipPregnancyComplication2: "Palun valige",
    labelPregnancyComplication3: "Kas pöördusite arsti poole?",
    tooltipPregnancyComplication3: "Jah või ei",
    labelPregnancyComplication4: "Miks see tähendab, et te ei saa üritusel osaleda?",
    tooltipPregnancyComplication4: "Palun täpsustage",
    headingPublicTransportFailure: "Ühistranspordi meetod (rong, buss, tramm, laev jne) ebaõnnestus ootamatult, mis tähendab, et ma ei saanud sellele üritusele muud võimalust reisida?",
    headingPublicTransportFailureDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelPublicTransportFailure1: "Millist tüüpi ühistransporti plaanisite kasutada?",
    tooltipPublicTransportFailure1: "Palun valige",
    labelPublicTransportFailure2: "Millal plaanisite reisida?",
    tooltipPublicTransportFailure2: "Palun valige",
    labelPublicTransportFailure3: "Mis juhtus?",
    tooltipPublicTransportFailure3: "Palun täpsustage",
    labelPublicTransportFailure4: "Miks te ei võiks kasutada alternatiivset transporti?",
    tooltipPublicTransportFailure4: "Palun täpsustage",
    headingScheduledAirlineFailure: "Minu eelbroneeritud lend tühistati ootamatult ja ma ei suutnud leida sobivat alternatiivi, mis tähendab, et ma ei saanud üritusel osaleda?",
    headingScheduledAirlineFailureDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelScheduledAirlineFailure1: "Lennufirma nimi",
    tooltipScheduledAirlineFailure1: "Lennufirma nimi",
    labelScheduledAirlineFailure2: "Lennu number",
    tooltipScheduledAirlineFailure2: "Lennu number",
    labelScheduledAirlineFailure3: "Reisimine",
    tooltipScheduledAirlineFailure3: "Reisimine",
    labelScheduledAirlineFailure4: "Reisimine",
    tooltipScheduledAirlineFailure4: "Reisimine",
    labelScheduledAirlineFailure5: "Juhtumi kuupäev?",
    tooltipScheduledAirlineFailure5: "Palun valige",
    labelScheduledAirlineFailure6: "Miks te ei saanud alternatiivsele lennule minna?",
    tooltipScheduledAirlineFailure6: "Palun täpsustage",
    headingTheftOfDocuments: "Minu dokumendid varastati ja asendus(t)e tegemist ei olnud võimalik vajaliku aja jooksul korraldada, nii et ma ei saanud üritusel osaleda",
    headingTheftOfDocumentsDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelTheftOfDocuments1: "Mis tüüpi dokument varastati?",
    tooltipTheftOfDocuments1: "Palun valige",
    labelTheftOfDocuments2: "Millal see juhtus?",
    tooltipTheftOfDocuments2: "Palun valige",
    labelTheftOfDocuments3: "Kas üritasite dokumente uuesti välja anda?",
    tooltipTheftOfDocuments3: "Jah või ei",
    headingWorkRelocation: "Mu tööandja on palunud mul vähemalt kolmeks kuuks oma töökoha asukohta muuta ja vahemaa on märkimisväärne, mis tähendab, et ma ei saa nüüd üritusel osaleda?",
    headingWorkRelocationDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelWorkRelocation1: "Kas see on teie töö ajutine või püsiv ümberpaigutamine?",
    tooltipWorkRelocation1: "Palun valige",
    labelWorkRelocation2: "Kui kaua te uuel aadressil viibite?",
    tooltipWorkRelocation2: "Palun täpsustage",
    labelWorkRelocation3: "Mis on teie praegune töökoha aadress?",
    tooltipWorkRelocation3: "Palun täpsustage",
    labelWorkRelocation4: "Mis on teie uus töökoha aadress?",
    tooltipWorkRelocation4: "Palun täpsustage",
    labelWorkRelocation5: "Millal teid sellest ümberpaigutamisest teavitati?",
    tooltipWorkRelocation5: "Palun valige",
    headingRedundancy: "Tööandja koondas mind või broneeringu liiget, mis tähendab, et ma ei saa enam broneeringus osaleda?",
    headingRedundancyDecline: "Te ei tohiks selle taotlusega jätkata, kuna tagasimakse lükatakse tõenäoliselt tagasi.",
    labelRedundancy1: "Töö liik?",
    tooltipRedundancy1: "Palun valige",
    labelRedundancy2: "Kui kaua olete selles ettevõttes töötanud?",
    tooltipRedundancy2: "Palun valige",
    labelRedundancy3: "Kas koondamine oli vabatahtlik?",
    tooltipRedundancy3: "Palun valige",
    labelRedundancy4: "Millal teid koondamisest teavitati?",
    tooltipRedundancy4: "Palun valige",
    labelTestOption: "Jätkamiseks valige Jah",
};