export default {
    headingAdverseWeather: "악천후로 인해 정부 기관(예 : 지방 당국, 경찰 등)에 의해 참석하지 않도록 권고받았습니다. 이것을 증명할 자료를 가지고 있습니까?",
    headingAdverseWeatherDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    tooltipRefundReasonConfirm: "예 또는 아니요",
    labelBookingCancelledByOperator: "티켓은 주최자에 의해 취소되었습니다.",
    labelBookingRescheduledByOperator: "날짜는 주최자에 의해 변경되었으며 새로운 날짜에 참석할 수 없습니다.",
    labelBookedWrongDate: "본인 또는 예약 회원이 티켓을 구입할 때 오류가 발생하여 잘못된 날짜를 예약했습니다.",
    labelBookedWrongTickets: "본인 또는 예약 회원이 티켓을 구입할 때 오류가 발생하여 잘못된 티켓을 예약했습니다.",
    labelBookedTooManyTickets: "본인 또는 예약 회원이 티켓을 구입할 때 오류가 발생하여 너무 많은 티켓을 예약했습니다.",
    labelCOVID19TravelRestrictions: "본인 또는 예약 회원은 COVID-19 여행 금지로 인해 참석할 수 없습니다.",
    labelADWrongSelection: "환불 사유를 잘못 선택했을 수 있습니다. 환불 이유 드롭 다운 목록을 다시 검토하십시오.",
    labelAdverseWeather1: "악천후로 인해 행사에 참석할 수 없었던 이유는 무엇입니까?",
    tooltipAdverseWeather1: "자세히 설명해주세요.",
    labelAdverseWeather2: "사건 발생 날짜",
    tooltipAdverseWeather2: "선택하십시오.",
    headingArmedForces: "당신은 군대 또는 긴급구조 등에 고용되어 있으며, 해당 기관/고용인으로부터 예정에 없는 작업을 요청 받아 행사에 참석할 수 없습니까?",
    headingArmedForcesDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelArmedForces1: "어떤 군대나 긴급구조 등에 고용되어 있습니까?",
    tooltipArmedForces1: "선택하십시오.",
    labelArmedForcesOther1: "선택하십시오.",
    tooltipArmedForcesOther1: "선택하십시오.",
    labelArmedForces2: "사건 발생 날짜",
    tooltipArmedForces2: "선택하십시오.",
    headingChangesToExam: "시험 날짜가 학교 또는 대학 등에 의해 변경되었습니까? 아니면 이전 시험을 통과하지 못했습니까? ",
    headingChangesToExamDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelExam1: "기존 시험 날짜는 언제입니까?",
    tooltipExam1: "선택하십시오.",
    labelExam2: "새로운 시험 날짜는 언제입니까?",
    tooltipExam2: "선택하십시오.",
    labelExam3: "시험 날짜가 변경된 이유는 무엇입니까?",
    tooltipExam3: "세부 정보를 입력하십시오.",
    labelExam4: "이전 시험을 통과하지 못했습니까?",
    tooltipExam4: "예 또는 아니요",
    headingCourtSummons: "예약한 시간에 증인으로 법정에 출두하도록 소환되었습니까?",
    headingCourtSummonsDecline: "환불이 거절 될 가능성이 높기 때문에 이 신청을 진행해서는 안됩니다.",
    labelCourtSummons1: "법원 소환의 이유는 무엇입니까?",
    tooltipCourtSummons1: "선택하십시오.",
    labelCourtSummonsOther1: "지정해 주십시오.",
    tooltipCourtSummonsOther1: "지정해 주십시오.",
    labelCourtSummons2: "법원 소환장은 언제 발부되었습니까?",
    tooltipCourtSummons2: "선택하십시오.",
    headingCOVID19Test: "나, 내 가족의 일원 또는 행사에 참석 한 그룹의 구성원이 COVID-19로 진단 받았습니까?",
    headingCOVID19TestDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelCOVID19Test1: "누가 COVID-19를 걸렸습니까?",
    tooltipCOVID19Test1: "선택하십시오.",
    labelCOVID19TestOther1a: "선택해 주세요.",
    labelCOVID19TestOther1b: "이름 선택",
    tooltipCOVID19TestOther1a: "선택해 주세요.",
    tooltipCOVID19TestOther1b: "이름 선택",
    labelCOVID19Test2: "어떤 유형의 COVID-19 검사를 받았습니까?",
    tooltipCOVID19Test2: "선택하십시오.",
    labelCOVID19TestOther2: "선택해 주세요.",
    tooltipCOVID19TestOther2: "선택해 주세요.",
    labelCOVID19Test3: "COVID-19 검사 날짜는 언제였습니까?",
    tooltipCOVID19Test3: "선택하십시오.",
    headingDeath: "내 가족의 직계 구성원이 사망하여 행사에 참석할 수 없습니까?",
    headingDeathDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelDeath1: "가족 중 누가 사망했습니까?",
    tooltipDeath1: "선택하십시오.",
    labelDeathOther1: "선택해 주세요.",
    tooltipDeathOther1: "선택해 주세요.",
    labelDeath2: "사망 날짜는 언제였습니까?",
    tooltipDeath2: "선택하십시오.",
    labelDeath3: "고인의 이름",
    tooltipDeath3: "선택해 주세요.",
    headingEmergency: "본인이 통제 할 수 없는 예기치 않은 비상 사태를 겪었으며, 이를 증빙할 수 있는 문서를 제공 할 수 있습니까?",
    headingEmergencyDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelEmergency1: "비상 사태의 유형을 설명해주십시오.",
    tooltipEmergency1: "자세히 설명해주세요.",
    labelEmergency2: "언제 이런 일이 일어 났습니까?",
    tooltipEmergency2: "선택하십시오.",
    labelEmergency3: "어떠한 이유로 이 상황으로 인해 당신이 행사에 참석이 어렵습니까?",
    tooltipEmergency3: "자세히 설명해주세요.",
    labelEmergency4: "이 비상 사태에 대한 증거가 있습니까?",
    tooltipEmergency4: "예 또는 아니요",
    headingHomeEmergency: "강도, 홍수, 화재, 기물 파손, 가정 폭력 등으로 인해 행사에 참가할 수 없는 비상 사태에 놓여있습니까?",
    headingHomeEmergencyDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelHomeEmergency1: "가정 비상 사태는 무엇입니까?",
    tooltipHomeEmergency1: "선택하십시오.",
    labelHomeEmergencyOther1: "선택해 주세요.",
    tooltipHomeEmergencyOther1: "선택해 주세요.",
    labelHomeEmergency2: "언제 비상사테가 발생했습니까?",
    tooltipHomeEmergency2: "선택하십시오.",
    labelHomeEmergency3: "어떠한 이유로 행사에 참석하는 것을 어렵습니까?",
    tooltipHomeEmergency3: "자세히 설명해주세요.",
    headingInjury: "본인, 가족의 구성원 또는 행사에 참석하는 그룹의 구성원이 건강 상태 또는 부상으로 진단되어 현재 행사에 참석할 수 없습니까?",
    headingInjuryDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelInjury1: "질병, 부상, 사고는 무엇입니까?",
    tooltipInjury1: "자세히 설명해주세요.",
    labelInjury2: "언제 이런 일이 일어났습니까?",
    tooltipInjury2: "선택하십시오.",
    labelInjury3: "이 질병 부상으로 의료 시설에 방문했습니까?",
    tooltipInjury3: "예 또는 아니요",
    labelInjury4: "이 질병이 기저질환(지병, 만성 질병 등)였습니까?",
    tooltipInjury4: "예 또는 아니요",
    labelInjury5: "언제 처음 진단을 받았습니까?",
    tooltipInjury5: "선택하십시오.",
    labelInjury6: "어떠한 이유로 행사에 참석할 수 없었습니까?",
    tooltipInjury6: "자세히 설명해주세요.",
    labelInjury7: "누가 질병 또는 부상을 입었습니까?",
    tooltipInjury7: "선택하십시오.",
    headingJuryService: "본인 또는 예약 회원이 배심원으로 참석 요청을 받았습니까?",
    headingJuryServiceDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelJuryService1: "배심원 참석 통보는 언제 받았습니까?",
    tooltipJuryService1: "선택하십시오.",
    headingMechanicalBreakdown: "차량에 기계적인 고장이 났거나 사고가 났기 때문에 행사에 참석할 수 없습니까?",
    headingMechanicalBreakdownDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelMechanicalBreakdown1: "차량에 어떤 문제가 일어났습니까?",
    tooltipMechanicalBreakdown1: "선택하십시오.",
    labelMechanicalBreakdown2: "언제 문제가 일어 났습니까?",
    tooltipMechanicalBreakdown2: "선택하십시오.",
    labelMechanicalBreakdown3: "대체 교통편을 이용할 수 없었던 이유는 무엇입니까?",
    tooltipMechanicalBreakdown3: "자세히 설명해주세요.",
    headingPregnancyComplication: "본인 또는 예약 회원이 의사에 의해 진단된 임신 합병증으로 인해 행사에 참석하지 못합니까?",
    headingPregnancyComplicationDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelPregnancyComplication1: "임신 합병증의 이유는 무엇입니까?",
    tooltipPregnancyComplication1: "자세히 설명해주세요.",
    labelPregnancyComplication2: "언제 이런 일이 일어 났습니까?",
    tooltipPregnancyComplication2: "선택하십시오.",
    labelPregnancyComplication3: "치료를 위해 의료 서비스를 요청했습니까?",
    tooltipPregnancyComplication3: "예 또는 아니요",
    labelPregnancyComplication4: "어떤 이유로 당신이 행사에 참석할 수 없었습니까?",
    tooltipPregnancyComplication4: "자세히 설명해주세요.",
    headingPublicTransportFailure: "대중 교통 수단 (기차, 버스, 보트 등) 문제로 인해 행사에 참여할 수 없었다면 다른 방법으로 이동할 수 없었습니까?",
    headingPublicTransportFailureDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelPublicTransportFailure1: "어떤 유형의 대중 교통을 사용할 계획이었습니까?",
    tooltipPublicTransportFailure1: "선택하십시오.",
    labelPublicTransportFailure2: "언제 여행할 계획이었습니까?",
    tooltipPublicTransportFailure2: "선택하십시오.",
    labelPublicTransportFailure3: "어떤 일이 있었습니까?",
    tooltipPublicTransportFailure3: "자세히 설명해주세요.",
    labelPublicTransportFailure4: "어떠한 이유로 대체 교통편을 이용할 수 없습니까?",
    tooltipPublicTransportFailure4: "자세히 설명해주세요.",
    headingScheduledAirlineFailure: "사전 예약 된 항공편이 예기치 않게 취소되어 얘약된 이벤트에 참석할 없었다면 다른 적절한 대안을 없었습니까?",
    headingScheduledAirlineFailureDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelScheduledAirlineFailure1: "항공사 이름",
    tooltipScheduledAirlineFailure1: "항공사 이름",
    labelScheduledAirlineFailure2: "항공편 번호",
    tooltipScheduledAirlineFailure2: "항공편 번호",
    labelScheduledAirlineFailure3: "출발지",
    tooltipScheduledAirlineFailure3: "출발지",
    labelScheduledAirlineFailure4: "여행 대상",
    tooltipScheduledAirlineFailure4: "여행 대상",
    labelScheduledAirlineFailure5: "사건 날짜?",
    tooltipScheduledAirlineFailure5: "선택하십시오.",
    labelScheduledAirlineFailure6: "대체 항공편을 이용할 수 없었던 이유는 무엇입니까?",
    tooltipScheduledAirlineFailure6: "자세히 설명해주세요.",
    headingTheftOfDocuments: "제출 서류가 도난당하여 필요한 기간 내에 제출할 수 없었기 때문에 행사에 참석할 수 없습니다.",
    headingTheftOfDocumentsDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelTheftOfDocuments1: "어떤 종류의 문서가 도난 당했습니까?",
    tooltipTheftOfDocuments1: "선택하십시오.",
    labelTheftOfDocuments2: "언제 이런 일이 일어 났습니까?",
    tooltipTheftOfDocuments2: "선택하십시오.",
    labelTheftOfDocuments3: "문서를 다시 발급하려고 했습니까?",
    tooltipTheftOfDocuments3: "예 또는 아니요",
    headingWorkRelocation: "고용주가 적어도 3개월 이상 직장 위치 변경을 요청하여 거리상의 문제로 행사에 참석할 수 없습니까?",
    headingWorkRelocationDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelWorkRelocation1: "업무 지역이 일시적 또는 영구적으로 이전합니까?",
    tooltipWorkRelocation1: "선택하십시오.",
    labelWorkRelocation2: "새 주소에 얼마나 오래 머물 예정입니까?",
    tooltipWorkRelocation2: "자세히 설명해 주십시오.",
    labelWorkRelocation3: "현재 직장 주소는 무엇입니까?",
    tooltipWorkRelocation3: "자세히 설명해 주십시오.",
    labelWorkRelocation4: "새 직장 주소는 무엇입니까?",
    tooltipWorkRelocation4: "자세히 설명해 주십시오.",
    labelWorkRelocation5: "직장 지역 재배치에 대한 통보는 언제 받았습니까?",
    tooltipWorkRelocation5: "선택하십시오.",
    headingRedundancy: "본인 또는 예약 회원이 고용주에 의해 해고되어 더 이상 참석할 수 없습니까?",
    headingRedundancyDecline: "환불 거절 가능성이 높아 환불 신청이 어렵습니다.",
    labelRedundancy1: "고용 유형은 어떤 것입니까?",
    tooltipRedundancy1: "선택해 주세요",
    labelRedundancy2: "이 회사에 입사한 지 얼마나 되었습니까?",
    tooltipRedundancy2: "선택해 주세요",
    labelRedundancy3: "자발적 이직이었습니까?",
    tooltipRedundancy3: "선택해 주세요",
    labelRedundancy4: "해고 통보는 언제 받았습니까?",
    tooltipRedundancy4: "선택해 주세요",
    labelTestOption: "계속하려면 예를 선택하세요.",
}