import * as React from 'react';
import {TextInput} from '../formInputs';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../Interfaces/IFormValues';
import {useTranslation} from 'react-i18next';
import {getWiseLanguage} from '../../../languages/locales/languageSettings';
import {FormikProps} from 'formik';
import {bankBeforeAccountCurrencyCodes} from '../utils/constants';
import usePaymentLabels from '../../hooks/usePaymentLabels';
import {useFieldHidden} from '../../hooks/usePaymentInformation';

interface AccountCaptureFieldsControlProps {
    formProps: FormikProps<IFormValues>;
}

export const AccountCaptureFieldsControl = (accountFieldsProps: AccountCaptureFieldsControlProps) => {

    const {
        formProps
    } = accountFieldsProps;

    const {
        i18n,
        t
    } = useTranslation();

    const paymentLabels = usePaymentLabels(formProps.values.currency);
    const getWiseLanguageCode = getWiseLanguage(i18n.language);
    const isFieldHidden = useFieldHidden();

    const BankAccountNumber = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.bankAccountNumber)}
                placeholder={paymentLabels.labelBankAccountNumber}
                label={paymentLabels.labelBankAccountNumber}
                controlId={nameof<IFormValues>(x => x.bankAccountNumber)}
                hidden={isFieldHidden.bankAccountNumber}
                tooltip={paymentLabels.tooltipBankAccountNumber}
                required
                externalLink={paymentLabels.labelBankAccountNumber.includes('IBAN') ?
                    `https://wise.com/${getWiseLanguageCode}/iban` : null}
                externalLinkText={t('payment:labelIBANHelp')}
            />
        );
    }

    const CardNumber = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.cardNumber)}
                placeholder={paymentLabels.labelCardNumber}
                label={paymentLabels.labelCardNumber}
                controlId={nameof<IFormValues>(x => x.cardNumber)}
                hidden={isFieldHidden.cardNumber}
                tooltip={paymentLabels.tooltipCardNumber}
                required
            />
        );
    }

    const SortCode = () => {
        return (
            <TextInput
                key={nameof<IFormValues>(x => x.sortCode)}
                placeholder={paymentLabels.labelSortCode}
                label={paymentLabels.labelSortCode}
                controlId={nameof<IFormValues>(x => x.sortCode)}
                hidden={isFieldHidden.sortCode}
                tooltip={paymentLabels.tooltipSortCode}
                externalLink={paymentLabels.labelSortCode.includes('Transit') ?
                    'https://wise.com/help/articles/2955294/how-do-i-find-my-cad-institution-andor-transit-number' : null}
                externalLinkText={t('payment:labelSortCodeHelp')}
                required
            />
        );
    }

    const DefaultDisplay = () => {
        return (
            <>
                <BankAccountNumber/>
                <CardNumber/>
                <SortCode/>
            </>
        );
    }

    const BankBeforeAccountDisplay = () => {
        return (
            <>
                <SortCode/>
                <BankAccountNumber/>
                <CardNumber/>
            </>
        );
    }

    return (<>
            {bankBeforeAccountCurrencyCodes.some(x => x === formProps.values.currency) ?
                <BankBeforeAccountDisplay/> :
                <DefaultDisplay/>
            }
        </>
    );
}