import {
    NotApplicable,
    System,
    OptionNo,
    OptionYes,
    RefundReasonEmergencyCircumstances
} from '../utils/constants';
import {FormikProps} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';
import {nameof} from 'ts-simple-nameof';
import {isNullOrWhitespace} from '../utils/helpers';

const notApplicableSystemText = `${NotApplicable} - ${System}`;

export const defaultQuestionSetFieldValues = (formProps: FormikProps<IFormValues>) => {

    if (formProps.values.refundReasonCategory !== RefundReasonEmergencyCircumstances) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonCategory), RefundReasonEmergencyCircumstances);
    }

    if (formProps.values.refundReasonConfirm === 0 || formProps.values.refundReasonConfirm === OptionNo) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonConfirm), OptionYes);
    }

    if (isNullOrWhitespace(formProps.values.refundReasonBespokeText1)) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonBespokeText1), notApplicableSystemText);
    }

    if (formProps.values.refundReasonBespokeDate2 === null) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonBespokeDate2), new Date());
    }

    if (isNullOrWhitespace(formProps.values.refundReasonBespokeText3)) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonBespokeText3), notApplicableSystemText);
    }

    if (formProps.values.refundReasonBespokeSelect4 === 0) {
        void formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonBespokeSelect4), OptionNo);
    }
}