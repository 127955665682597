import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormSectionProps} from './RefundReasonHandlerSection';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';

export const BookedWrongDateSection = (props: FormSectionProps) => {

    const { t } = useTranslation();

    const {
        formProps
    } = props;

    return (
        <RefundReasonConfirmationSection
            formProps={formProps}
            heading={t('bespokeQuestions:labelBookedWrongDate')}
            declineWarning={t('bespokeQuestions:labelADWrongSelection')}
        />
    );
}