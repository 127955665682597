import * as React from 'react';
import {
    DateInput,
    FileInput,
    SelectInput,
    TextInput
} from '../../formInputs';
import {
    OptionCOVID19PositiveTestAnotherPerson,
    OptionCOVID19PositiveTestHouseholdPerson,
    OptionCOVID19PositiveTestOther,
    OptionCOVID19PositiveTestTypeOther,
    OptionNo,
    OptionYes
} from '../../utils/constants';
import {useTranslation} from 'react-i18next';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../../Interfaces/IFormValues';
import {FormikProps} from 'formik';
import {useFormContext} from '../../providers/FormProvider';
import {useDynamicEvidence} from '../../utils/helpers';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';
import {RefundReasonFilesState} from '../../../state/activity';

interface COVID19PositiveTestSectionProps {
    formProps: FormikProps<IFormValues>;
    isHidden: boolean;
}

export const COVID19PositiveTestSection = (props: COVID19PositiveTestSectionProps) => {

    const {
        formProps,
        isHidden
    } = props;

    const {
        clearFiles,
        setClearFiles,
        previousRefundReasonFiles,
        isEvidenceRequired
    } = useFormContext();

    const {t} = useTranslation();
    const dynamicEvidenceLabel = useDynamicEvidence(false);

    const showSelectText1Other = () => {

        if (formProps.values.refundReasonConfirm === OptionNo) {
            return true;
        }

        return formProps.values.refundReasonBespokeSelect1 !== OptionCOVID19PositiveTestAnotherPerson &&
            formProps.values.refundReasonBespokeSelect1 !== OptionCOVID19PositiveTestHouseholdPerson &&
            formProps.values.refundReasonBespokeSelect1 !== OptionCOVID19PositiveTestOther
    }

    const labelSelectText1Other = () => {
        if (formProps.values.refundReasonBespokeSelect1 === OptionCOVID19PositiveTestOther) {
            return t('bespokeQuestions:labelCOVID19TestOther1a');
        }

        return t('bespokeQuestions:labelCOVID19TestOther1b');
    }

    const tooltipSelectText1Other = () => {
        if (formProps.values.refundReasonBespokeSelect1 === OptionCOVID19PositiveTestOther) {
            return t('bespokeQuestions:tooltipCOVID19TestOther1a');
        }

        return t('bespokeQuestions:tooltipCOVID19TestOther1b');
    }

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingCOVID19Test')}
                declineWarning={t('bespokeQuestions:headingCOVID19TestDecline')}
            />

            {!isHidden &&
                <>
                    <SelectInput
                        key={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                        defaultValue={0}
                        label={t('bespokeQuestions:labelCOVID19Test1')}
                        controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                        hidden={formProps.values.refundReasonConfirm !== OptionYes}
                        datasourceUrl='/api/referencedata/covid19persontypes'
                        prefixKey={'form:optionRefundReasonCOVID19Person'}
                        tooltip={t('bespokeQuestions:tooltipCOVID19Test1')}
                        displayLabelAsTitle={false}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.refundReasonBespokeSelectText1)}
                        placeholder={labelSelectText1Other()}
                        label={labelSelectText1Other()}
                        controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelectText1)}
                        hidden={showSelectText1Other()}
                        multiLine={false}
                        maxLength={50}
                        tooltip={tooltipSelectText1Other()}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                        defaultValue={0}
                        label={t('bespokeQuestions:labelCOVID19Test2')}
                        controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                        hidden={formProps.values.refundReasonConfirm !== OptionYes}
                        datasourceUrl='/api/referencedata/covid19testtypes'
                        prefixKey={'form:optionRefundReasonCOVID19TestType'}
                        tooltip={t('bespokeQuestions:tooltipCOVID19Test2')}
                        displayLabelAsTitle={false}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.refundReasonBespokeSelectText2)}
                        placeholder={t('bespokeQuestions:labelCOVID19TestOther2')}
                        label={t('bespokeQuestions:labelCOVID19TestOther2')}
                        controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelectText2)}
                        hidden={formProps.values.refundReasonConfirm !== OptionYes
                            || formProps.values.refundReasonBespokeSelect2 !== OptionCOVID19PositiveTestTypeOther}
                        multiLine={false}
                        maxLength={50}
                        tooltip={t('bespokeQuestions:tooltipCOVID19TestOther2')}
                        required
                    />

                    <DateInput
                        key={nameof<IFormValues>(x => x.refundReasonBespokeDate3)}
                        label={t('bespokeQuestions:labelCOVID19Test3')}
                        controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate3)}
                        hidden={formProps.values.refundReasonConfirm !== OptionYes}
                        tooltip={t('bespokeQuestions:tooltipCOVID19Test3')}
                        required
                    />

                    <FileInput
                        key={nameof<IFormValues>(x => x.refundReasonFiles)}
                        label={dynamicEvidenceLabel}
                        clearFiles={clearFiles}
                        setClearFiles={setClearFiles}
                        controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                        hidden={formProps.values.refundReasonConfirm !== OptionYes}
                        setFieldValue={formProps.setFieldValue}
                        previouslyUploaded={previousRefundReasonFiles}
                        tooltip={t('form:tooltipRefundReasonFiles')}
                        required={isEvidenceRequired}
                        state={RefundReasonFilesState}
                        formProps={formProps}
                    />
                </>
            }
        </>
    );
}