export default {
    messageUnableToMakePaymentToCurrency: "Өкінішке орай, таңдалған валюта үшін банк деректемелерін алу мүмкін емес. Валюта және туған ел таңдалғанына көз жеткізіңіз",
    messageOnlyBankAccountsAccepted: "Назар аударыңыз: біз қазір тек банктік шоттарға төлем жасай аламыз (Несиелік және дебеттік карталар қабылданбайды)",
    labelAccountName: "Есептік жазба иесінің аты",
    labelBankAccountName: "Шот иесінің толық аты-жөні",
    labelBankCardHolderName: "Несие карточкасын иеленушісінің Аты",
    validationBankAccountName: "Есептік жазба иесінің толық аты-жөні қажет",
    labelBankCountry: "Банк елі",
    labelBankName: "Банк атауы",
    labelPaymentType: "Төлем түрі",
    labelInstitutionNumber: "Мекеме нөмірі",
    labelBankAddress: "Банк мекенжайы",
    labelBankAddress2: "Банк мекенжайы",
    labelBankCity: "Банк қаласы",
    labelBankState: "Банк мемлекеті",
    labelBankAccountNumber: "Банк шотының нөмірі",
    labelBankAccountNumberCBU: "Есептік жазба нөмірі CBU",
    labelBankAccountNumberCCI: "Есептік жазба нөмірі CCI",
    labelBankAccountNumberZAR: "Банк шотының нөмірі (7-11 сан)",
    labelCardNumber: "Карточка нөмірі",
    labelUnionpay: "UnionPay картасының нөмірі",
    labelAlipay: "Alipay ID (ұялы телефон нөмірі немесе электрондық пошта)",
    labelBankAccountType: "Тіркелгі түрі",
    optionBankAccountTypeCURRENT: "Ағымдағы шот",
    optionBankAccountTypeCHECKING: "Есептік жазбаны тексеру",
    optionBankAccountTypeSAVING:"Жинақ шоты",
    optionBankAccountTypeSAVINGS:"Жинақ шоты",
    optionBankAccountTypeCUENTA_VISTA: "Негізгі тіркелгі",
    optionBankAccountType1: "Есептік жазбаны тексеру",
    optionBankAccountType2: "Жинақ шоты",
    optionBankAccountType3: "Негізгі тіркелгі",
    optionBankAccountType4: "Vista тіркелгісі",
    optionBankAccountType5: "RUT тіркелгісі",
    labelSortCode: "Сұрыптау коды",
    labelRoutingCode: "Маршруттау коды",
    labelBankRoutingCodeNationalID: "Банктік бағыттау коды / Ұлттық идентификатор",
    labelBankTransitCodeSwift: "Транзит нөмірі",
    labelCNAPSCode: "Маршруттау коды / CNAPS коды (Қытай ұлттық жетілдірілген төлем жүйесінің коды)",
    labelABAFedwireRoutingCode: "ACH бағыттау нөмірі",
    labelIFSCCode: "IFSC коды",
    labelBankCode: "Банк коды",
    labelBIKCode: "BIK коды",
    labelBranchCode: "Филиал коды",
    labelBranchCodeUniversal: "Әмбебап 6 таңбалы филиал коды",
    labelRemittanceLine3: "Бенефициардың байланыс аты және телефон нөмірі",
    labelBeneficiaryContactNameandTelephoneNumber: "Бенефициардың байланыс аты және телефон нөмірі",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Бенефициардың туған күні және туған жері",
    labelRemittanceLine4: "Жеке салық нөмірі CUIL (9-12 сан)",
    labelCUILNumber: "Салық идентификаторы: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Салық идентификаторы RFC 13 сан немесе CURP 18 сан",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Жеке салық идентификаторы CPF Cadastro de Pessoa Física (11-14 сан), филиал коды",
    labelCFPNumber: "CPF нөмірі (11-14 сан)",
    labelBranchNameorBranchCode7digits: "Филиал атауы немесе филиал коды (7 сан)",
    labelCedulaJuridica910digitsTaxID: "Заңды куәлік (9-10 санды салық идентификаторы)",
    labelTaxIDRUT89digits: "Салық идентификаторы RUT (8-9 сан)",
    labelJob: "Қызмет атауы",
    labelDateOfBirth: "Туған жылы",
    labelNationality: "Ұлты",
    labelDocumentType: "Құжат түрі",
    labelDocumentNumber: "Құжат нөмірі",
    labelRutNumber: "RUT нөмірі (Бірегей орам ағыны)",
    labelCedulaTaxIDIndividual811digits: "Жеке куәлік (жеке салық идентификаторы 8-11 сан)",
    labelCIIndividualTaxIDBranchLocation: "CI (жеке салық идентификаторы), филиалдың орналасқан жері",
    labelTaxIDRUCorCI: "Салық идентификаторы (RUC немесе CI)",
    labelSortCodeHelp: "Көмектесіңдер?",
    labelBranchCodeHelp: "Көмектесіңдер?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Көмектесіңдер?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Көмектесіңдер?",
    labelRoutingCodeBSB: "Маршруттау коды/BSB",
    labelBSB: "Мемлекеттік банк филиалы (БББ)",
    labelSKNCode: "SKN коды",
    labelAcceptanceFlights: "Қайтару өтінімі сәтті болса, барлық рейстер мен қосалқы өнімдерді қоса алғанда, брондау жойылатынын қабылдаймын.",
    labelAcceptanceTickets: "Қайтару өтінімі сәтті болса, барлық билеттерді және қосымша өнімдерді қоса алғанда, брондау жойылатынын қабылдаймын.",
    labelTermsAndConditions1: "Мен/біз келістік",
    labelTermsAndConditions2: "Ережелер мен шарттар",
    labelFraudWarning1: "Осы қайтару туралы өтініш нысанында келтірілген фактілер шынайы және шынайы деп есептеймін. Кез келген жалған ақпарат немесе дәлелдемелер қылмыстық әрекет болып саналып, оны іздеу үшін полицияға немесе басқа құқық қорғау органдарына берілуі мүмкін екенін түсінемін.",
    labelFraudWarning2: "Егер растайтын құжаттарға қатысты алаяқтық бар деп күдіктенсек, бұл мәселе дереу жергілікті полиция органдарына жіберіледі. Менің өтінішім жалған деп танылса және бұл қылмыстық тергеуге айналуы мүмкін болса, менің мәліметтерім осы органдарға берілетінін қабылдаймын және түсінемін.",
    validationTermsAndConditions: "Шарттар мен талаптармен келісу керек",
    messageFailbankName: "Банк атауы бос болмауы керек",
    messageFailcustomerName: "Тұтынушы аты бос болмауы керек",
    messageFailbankAccountNumber: "Міндетті өріс",
    messageFailbankAccountNumberFormat: "IBAN пішімі болуы керек",
    messageFailsortCode: "Міндетті өріс",
    messageFailbankSwiftBic: "Бенефициар SWIFT BIC бос болмауы керек",
    messageFailbankSwiftBicFormat: "SWIFT пішімі болуы керек",
    messageFailbeneficiaryAddress1: "1 мекенжай бос болмауы керек",
    messageFailbeneficiaryCity: "Қала бос болуы мүмкін емес",
    messageFailbeneficiaryPostCode: "Пошта индексі бос болмауы керек",
    messageFailremittanceLine3: "Міндетті өріс",
    messageFailremittanceLine4: "Міндетті өріс",
    modalHeading: "Мәліметтерді растау",
    modalLabelPleaseConfirmYourDetails: "Мәліметтеріңізді растаңыз",
    modalValidationPleaseConfirm: "Растауыңызды өтінеміз",
    modalValidationMustMatch: "Алдыңғы жазбаға сәйкес келуі керек",
    tooltipBankAccountName: "Сіздің атыңыз банктік шотыңызда/банк үзіндісінде/банк картаңызда көрсетілгендей.",
    tooltipBankAccountNameRecipient: "Алушының аты-жөні. Аты да, фамилиясы да бір таңбадан көп болуы керек.",
    tooltipBankCountry: "Сіздің банкіңіз орналасқан ел.",
    tooltipBankName: "Сіздің банкіңіздің атауы.",
    tooltipPaymentType: "Сізге қажет төлем түрі.",
    tooltipInstitutionNumber: "Бұл 3 сан және әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз..",
    tooltipBankAccountNumber: "Сіздің жеке шот нөміріңіз. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankAccountNumberCBU: "Есептік жазбаңызды анықтау үшін пайдаланылатын 22 таңбалы нөмір. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankAccountNumberCCI: "Есептік жазбаңызды анықтау үшін пайдаланылатын 20 таңбалы нөмір. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankAccountNumberKRW: "Есептік жазбаңызды анықтау үшін пайдаланылатын 11–16 таңбалы сан. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCardNumber: "Сіздің карта нөміріңіз. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipUnionpay: "UnionPay картаңыздың нөмірі.",
    tooltipAlipay: "Сіздің Alipay тіркелгіңізге байланысты ұялы телефон нөміріңіз немесе электрондық пошта мекенжайы.",
    tooltipBankAccountType: "Бұл тексеру немесе үнемдеу.",
    tooltipSortCode: "Бұл сіздің шотыңыз орналасқан банк бөлімшесін анықтайды. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipRoutingCode: "Бұл сіздің шотыңыз орналасқан банк бөлімшесін анықтайды. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBeneficiarySWIFTBIC: "Халықаралық транзакцияда банкті анықтау үшін қолданылатын 8-11 таңбадан тұрады. Әдетте ай сайынғы үзінді көшірмеде кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBSB: "Банктің мемлекеттік филиалының (BSB) нөмірі - 6 саннан тұратын австралиялық сұрыптау коды. Әдетте ай сайынғы үзінді көшірмеде кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipRoutingCodeBSB: "Бұл сіздің шотыңыз орналасқан банк бөлімшесін анықтайды. Әдетте ай сайынғы үзінді көшірмеде немесе банк картасында кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipABAFedwireRoutingCode: "Америкадағы банктерді анықтау үшін қолданылатын 9 таңбалы сан. Әдетте ай сайынғы үзінді көшірмеде кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipIFSCCode: "Әдетте бұл кодты ай сайынғы үзінді көшірмеде немесе есеп кітапшасынан табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз..",
    tooltipTermsAndConditions: "Шарттар мен шарттарды қабылдау арқылы сіз біздің Шарттарымызбен танысқаныңызды және олармен келіскеніңізді растайсыз.",
    tooltipIBAN: "Халықаралық төлемдерді жүзеге асыру немесе алу кезінде пайдалануға болатын халықаралық банктік шот нөмірі. Әдетте ай сайынғы үзінді көшірмеде кездеседі немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Бенефициардың байланыс аты және телефон нөмірі.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Бенефициардың туған күні және туған жері.",
    tooltipBankRoutingCodeNationalID: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankTransitCodeSwift: "Бұл 5 сан және оны әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз..",
    tooltipBankAddress: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankAddress2: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankCity: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankState: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBranchName: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBankCode: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBIKCode: "Банкіңізді анықтау үшін 9 сан.",
    tooltipBranchCode: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBranchCodeUniversal: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCNAPSCode: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipRemittanceLine4: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCUILNumber: "Бұл екі цифрдан, сызықшадан, тоғыз цифрдан және бір таңбалы бақылау сомасынан тұратын 11 таңбалы сан.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCFPNumber: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipBranchNameorBranchCode7digits: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCedulaJuridica910digitsTaxID: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipTaxIDRUT89digits: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipJob: "Сіздің қазіргі кәсібіңіз.",
    tooltipDateOfBirth: "Сіздің туған күніңіз.",
    tooltipNationality: "Сіздің ұлтыңыз.",
    tooltipDocumentType: "Қажетті құжат түрі.",
    tooltipDocumentNumber: "Бұл құжат түрін таңдауға байланысты құжат нөмірі.",
    tooltipRutNumber: "Сіздің RUT нөміріңіз (Бірегей орам ағыны).",
    tooltipCedulaTaxIDIndividual811digits: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipCIIndividualTaxIDBranchLocation: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    tooltipTaxIDRUCorCI: "Әдетте ай сайынғы банк үзіндісінде табуға болады немесе әлі де сенімді болмасаңыз, банктен сұраңыз.",
    errorCPFCode: "Қазіргі уақытта біз тек PIX кілті ретінде CPF қабылдаймыз."
};