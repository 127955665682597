import * as React from 'react';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from 'react-i18next';

interface SectionHeadingTextThemeProps {
    text: string;
}

export const SectionHeadingTextTheme = (props: SectionHeadingTextThemeProps) => {

    const {
        text
    } = props;

    const {t} = useTranslation();

    const theme = useTheme();
    const matchesSmAndAbove = useMediaQuery(theme.breakpoints.up('sm'));

    return (<>
            {matchesSmAndAbove &&
                null
            }

            {!matchesSmAndAbove &&
                <Box sx={{
                    pb: 2,
                    color: 'black',
                    fontWeight: 550
                }}>
                    {text}
                </Box>
            }
        </>
    );
}