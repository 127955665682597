export default {
    headingAdverseWeather: "イベントへの旅行が安全でないような悪天候に見舞われ、政府機関(地方自治体、警察など)によって義務付けられています。そして、あなたはこれを証明する証拠を持っていますか?",
    headingAdverseWeatherDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    tooltipRefundReasonConfirm: "イエスかノーか答えてください",
    labelBookingCancelledByOperator: "チケットはオペレーター/主催者によってキャンセルされました。",
    labelBookingRescheduledByOperator: "日付はオペレーター/主催者によって変更され、私は新しい日付に出席することはできません。",
    labelBookedWrongDate: "私または予約のメンバーが、チケットの購入時にエラーが発生し、間違った日付を予約しました。",
    labelBookedWrongTickets: "私または予約のメンバーが、チケットの購入時にエラーが発生し、間違ったチケットを予約しました。",
    labelBookedTooManyTickets: "私または予約のメンバーが、チケットの購入時にエラーが発生し、チケットを予約しすぎました。",
    labelCOVID19TravelRestrictions: "私または予約のメンバーは、COVID-19の渡航禁止のため参加できません。",
    labelADWrongSelection: "払い戻しの理由を間違えた可能性があります。[払い戻しの理由] ドロップダウン リストをもう一度確認してください。",
    labelAdverseWeather1: "悪天候により,イベントに出席できなかったのはなぜでしょうか。",
    tooltipAdverseWeather1: "詳細をお願いします",
    labelAdverseWeather2: "インシデント発生日",
    tooltipAdverseWeather2: "選択してください",
    headingArmedForces: "あなたはもともとそうする予定がなかったときに仕事に来るようにあなたの雇用主から頼まれ、これはあなたがイベントに参加するのを妨げますか?",
    headingArmedForcesDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelArmedForces1: "あなたはどの部隊や緊急サービスに雇われていますか?",
    tooltipArmedForces1: "選択してください",
    labelArmedForcesOther1: "指定してください",
    tooltipArmedForcesOther1: "指定してください",
    labelArmedForces2: "インシデント発生日",
    tooltipArmedForces2: "選択してください",
    headingChangesToExam: "あなたの試験の日付は、あなたの学校/大学などによって変更されていますか?これはあなたが再受験しなければならない不合格試験ではありませんか?",
    headingChangesToExamDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelExam1: "当初の試験日は何日でしたか?",
    tooltipExam1: "選択してください",
    labelExam2: "新しい試験日はいつですか?",
    tooltipExam2: "選択してください",
    labelExam3: "なぜ試験日が変わったのですか?",
    tooltipExam3: "詳細を入力してください",
    labelExam4: "前回の試験に不合格でしたか?",
    tooltipExam4: "イエスかノーか答えてください",
    headingCourtSummons: "あなたは事件と同じ日に証人として法廷に出廷するよう召喚されましたか?",
    headingCourtSummonsDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelCourtSummons1: "裁判所が召喚された理由は何ですか?",
    tooltipCourtSummons1: "選択してください",
    labelCourtSummonsOther1: "指定してください",
    tooltipCourtSummonsOther1: "指定してください",
    labelCourtSummons2: "裁判所の召喚状はいつ発行されましたか?",
    tooltipCourtSummons2: "選択してください",
    headingCOVID19Test: "私、私の家族のメンバー、またはイベントに参加するグループのメンバーがCOVID-19と診断されましたか?",
    headingCOVID19TestDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelCOVID19Test1: "COVID-19に罹患しているのは誰ですか?",
    tooltipCOVID19Test1: "選択してください",
    labelCOVID19TestOther1a: "指定してください",
    labelCOVID19TestOther1b: "名前の指定",
    tooltipCOVID19TestOther1a: "指定してください",
    tooltipCOVID19TestOther1b: "名前の指定",
    labelCOVID19Test2: "どのような種類のCOVID-19検査を受けましたか?",
    tooltipCOVID19Test2: "選択してください",
    labelCOVID19TestOther2: "指定してください",
    tooltipCOVID19TestOther2: "指定してください",
    labelCOVID19Test3: "COVID-19検査の日付はいつですか?",
    tooltipCOVID19Test3: "選択してください",
    headingDeath: "私の家族の直接のメンバーの死があり、それは私がイベントに参加するのを妨げていますか?",
    headingDeathDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelDeath1: "あなたの家族のだれが亡くなりましたか。",
    tooltipDeath1: "選択してください",
    labelDeathOther1: "指定してください",
    tooltipDeathOther1: "指定してください",
    labelDeath2: "死亡日はいつですか?",
    tooltipDeath2: "選択してください",
    labelDeath3: "故人の名前",
    tooltipDeath3: "指定してください",
    headingEmergency: "私は自分のコントロールの及ばない、そして私が証拠を提供できる予期せぬ本当の緊急事態を経験しましたか?",
    headingEmergencyDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelEmergency1: "緊急事態の種類について教えてください。",
    tooltipEmergency1: "詳細をお願いします",
    labelEmergency2: "これはいつ起こったのですか?",
    tooltipEmergency2: "選択してください",
    labelEmergency3: "この状況でイベントに参加できなくなるのはなぜですか。",
    tooltipEmergency3: "詳細をお願いします",
    labelEmergency4: "この緊急事態の証拠はありますか?",
    tooltipEmergency4: "イエスかノーか答えてください",
    headingHomeEmergency: "私は強盗、洪水、火災、破壊行為、家庭内虐待である家庭の緊急事態があり、イベントに参加できませんか?",
    headingHomeEmergencyDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelHomeEmergency1: "在宅緊急事態とは何ですか?",
    tooltipHomeEmergency1: "選択してください",
    labelHomeEmergencyOther1: "指定してください",
    tooltipHomeEmergencyOther1: "指定してください",
    labelHomeEmergency2: "これはいつ起こったのですか?",
    tooltipHomeEmergency2: "選択してください",
    labelHomeEmergency3: "なぜイベントへの参加を止めたのですか?",
    tooltipHomeEmergency3: "詳細をお願いします",
    headingInjury: "私、私の家族の一員、またはイベントに参加するグループのメンバーは、現在私たちがイベントに行くのを妨げている病状または怪我と診断されましたか?",
    headingInjuryDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelInjury1: "病気/怪我/事故の性質は何ですか?",
    tooltipInjury1: "詳細をお願いします",
    labelInjury2: "これはいつ起こったのですか?",
    tooltipInjury2: "選択してください",
    labelInjury3: "この病気/怪我のために医療施設に通ったことがありますか?",
    tooltipInjury3: "イエスかノーか答えてください",
    labelInjury4: "これは既存の病状でしたか?",
    tooltipInjury4: "イエスかノーか答えてください",
    labelInjury5: "これが最初に診断されたのはいつですか?",
    tooltipInjury5: "選択してください",
    labelInjury6: "イベントに参加できないのはなぜでしょうか。",
    tooltipInjury6: "詳細をお願いします",
    labelInjury7: "誰が病気/怪我を負ったのですか?",
    tooltipInjury7: "選択してください",
    headingJuryService: "私または予約のメンバーが陪審員サービスに呼び出されましたか?",
    headingJuryServiceDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelJuryService1: "陪審員サービスについて通知を受けたのはいつですか?",
    tooltipJuryService1: "選択してください",
    headingMechanicalBreakdown: "私たちの車は機械的な故障をしたり、事故に遭ったりしましたが、これは私たちがイベントに参加するのを妨げていますか?",
    headingMechanicalBreakdownDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelMechanicalBreakdown1: "車両で何が起こったのですか?",
    tooltipMechanicalBreakdown1: "選択してください",
    labelMechanicalBreakdown2: "これはいつ起こったのですか?",
    tooltipMechanicalBreakdown2: "選択してください",
    labelMechanicalBreakdown3: "なぜ代替交通手段を利用できなかったのですか?",
    tooltipMechanicalBreakdown3: "詳細をお願いします",
    headingPregnancyComplication: "私または予約のメンバーは、医療専門家によって診断された妊娠合併症を持っているため、イベントへの出席を妨げていますか?",
    headingPregnancyComplicationDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelPregnancyComplication1: "妊娠合併症の性質は何ですか?",
    tooltipPregnancyComplication1: "詳細をお願いします",
    labelPregnancyComplication2: "これはいつ起こったのですか?",
    tooltipPregnancyComplication2: "選択してください",
    labelPregnancyComplication3: "医師の診察を受けましたか?",
    tooltipPregnancyComplication3: "イエスかノーか答えてください",
    labelPregnancyComplication4: "イベントに参加できないのはなぜでしょうか。",
    tooltipPregnancyComplication4: "詳細をお願いします",
    headingPublicTransportFailure: "公共交通機関(電車、バス、トラム、ボートなど)の方法が予期せず失敗し、このイベントに旅行できる他の方法がありませんでしたか?",
    headingPublicTransportFailureDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelPublicTransportFailure1: "どのようなタイプの公共交通機関を使用する予定でしたか?",
    tooltipPublicTransportFailure1: "選択してください",
    labelPublicTransportFailure2: "いつ旅行する予定でしたか?",
    tooltipPublicTransportFailure2: "選択してください",
    labelPublicTransportFailure3: "どうされました。",
    tooltipPublicTransportFailure3: "詳細をお願いします",
    labelPublicTransportFailure4: "なぜ代替交通手段を利用できなかったのですか?",
    tooltipPublicTransportFailure4: "詳細をお願いします",
    headingScheduledAirlineFailure: "事前予約したフライトが予期せずキャンセルされ、イベントに参加できなかった適切な代替手段を見つけることができませんでしたか?",
    headingScheduledAirlineFailureDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelScheduledAirlineFailure1: "航空会社名",
    tooltipScheduledAirlineFailure1: "航空会社名",
    labelScheduledAirlineFailure2: "フライトナンバー",
    tooltipScheduledAirlineFailure2: "フライトナンバー",
    labelScheduledAirlineFailure3: "出発地",
    tooltipScheduledAirlineFailure3: "出発地",
    labelScheduledAirlineFailure4: "旅行先",
    tooltipScheduledAirlineFailure4: "旅行先",
    labelScheduledAirlineFailure5: "事件発生日は?",
    tooltipScheduledAirlineFailure5: "選択してください",
    labelScheduledAirlineFailure6: "代替便に乗れなかったのはなぜですか?",
    tooltipScheduledAirlineFailure6: "詳細をお願いします",
    headingTheftOfDocuments: "書類が盗まれ、必要な時間内に交換品を手配できなかったため、イベントに参加できません",
    headingTheftOfDocumentsDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelTheftOfDocuments1: "どのような種類のドキュメントが盗まれましたか?",
    tooltipTheftOfDocuments1: "選択してください",
    labelTheftOfDocuments2: "これはいつ起こったのですか?",
    tooltipTheftOfDocuments2: "選択してください",
    labelTheftOfDocuments3: "書類の再発行を試みましたか?",
    tooltipTheftOfDocuments3: "イエスかノーか答えてください",
    headingWorkRelocation: "私の雇用主は私に少なくとも3ヶ月間私の仕事の場所を変更するように要求しました、そして、距離は私が今イベントに出席できないことを意味する重要な意味ですか?",
    headingWorkRelocationDecline: "払い戻しが拒否される可能性が最も高いため、この申請を続行しないでください。",
    labelWorkRelocation1: "これはあなたの仕事の一時的または恒久的な移転ですか?",
    tooltipWorkRelocation1: "選択してください",
    labelWorkRelocation2: "新しい住所にどのくらいの期間滞在しますか?",
    tooltipWorkRelocation2: "詳細をお願いします",
    labelWorkRelocation3: "現在の勤務先住所は何ですか?",
    tooltipWorkRelocation3: "詳細をお願いします",
    labelWorkRelocation4: "新しい勤務先住所は何ですか?",
    tooltipWorkRelocation4: "詳細をお願いします",
    labelWorkRelocation5: "この移転の通知はいつですか?",
    tooltipWorkRelocation5: "選択してください",
    headingRedundancy: "私または予約のメンバーが雇用主によって冗長にされたため、予約に参加できなくなりましたか?",
    headingRedundancyDecline: "払い戻しは拒否される可能性が高いため、この申請を続行しないでください。",
    labelRedundancy1: "雇用形態は?",
    tooltipRedundancy1: "選択してください",
    labelRedundancy2: "この会社に入社してどのくらいになりますか?",
    tooltipRedundancy2: "選択してください",
    labelRedundancy3: "冗長性は任意でしたか?",
    tooltipRedundancy3: "選択してください",
    labelRedundancy4: "冗長性はいつ通知されましたか?",
    tooltipRedundancy4: "選択してください",
    labelTestOption: "続行するには「はい」を選択してください",
}