export default {
    nonAutoApprovedLabelThankYou: "환불 신청에 감사드립니다.",
    nonAutoApprovedLabelReview: "저희 팀이 영업일 기준 이틀 이내에 신청서를 검토하고 답변드리겠습니다.",
    nonAutoApprovedLabelExperience: "서비스에 대해 어떻게 생각하시나요?",
    nonAutoApprovedLabelExperienceIntroduction: "소중한 시간을 내어 서비스 평가 및리뷰를 남겨주셔서 진심으로 감사합니다.",
    feedbackExcellent: "훌륭한",
    feedbackGreat: "좋음",
    feedbackAverage: "평균",
    feedbackPoor: "미흡",
    feedbackBad: "나쁨",
    labelThankYouForFeedback: "의견에 진심으로 감사합니다.",
    autoApprovedMessage1: "고객님의 환불요청이",
    autoApprovedMessage2: "승인",
    autoApprovedMessage3: "되었습니다!",
    autoApprovedPayment1Day: "환불이 이미 진행 중입니다. 환불 금액은 영업일 1일 이내에 고객님의 계좌로 입금될 예정입니다.",
    autoApprovedPayment2to4Days: "환불이 이미 진행 중입니다. 환불 금액은 영업일 2-4일 이내에 고객님의 계좌로 입금될 예정입니다.",
    autoApprovedPayment7to10Days: "환불이 이미 진행 중입니다. 환불 금액은 영업일 7-10일 이내에 고객님의 계좌로 입금될 예정입니다.",
    autoApprovedLabelExperience: "서비스에 만족하셨습니까?",
    autoApprovedLabelExperienceIntroduction: "소중한 시간을 내어 서비스 평가 및 리뷰를 남겨주셔서 진심으로 감사합니다.",
    modalLabelNegative: "남격주신 의견은 프로텍트 그룹 발전에 매우 중요합니다. 고객님과 연락을 원하는지 알려주세요.",
    placeholderFeedback: "피드백",
    messageFeedbackSubmitted: "피드백 제출",
    reviewInstructionMessage: "5 점은 매우 좋음, 4점은 좋음, 3점은 보통, 2점은 나쁨, 1점은 매우 나쁨을 뜻합니다."
}