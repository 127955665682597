export default {
    headingAdverseWeather: "Você experimenta condições climáticas adversas severas que tornam inseguro viajar para o evento e isso foi ordenado por um Órgão Governamental (por exemplo, Autoridade Local, Polícia, etc. E você tem provas para provar isso?",
    headingAdverseWeatherDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    tooltipRefundReasonConfirm: "Sim ou não",
    labelBookingCancelledByOperator: "Os ingressos foram cancelados pelo Operador/Organizador.",
    labelBookingRescheduledByOperator: "A data foi alterada pelo Operador/Organizador e não posso comparecer às novas datas.",
    labelBookedWrongDate: "Eu ou um membro da reserva, cometi um erro ao comprar os ingressos e reservei a data errada.",
    labelBookedWrongTickets: "Eu ou um membro da reserva, cometi um erro ao comprar os ingressos e reservei os ingressos errados.",
    labelBookedTooManyTickets: "Eu ou um membro da reserva, cometi um erro ao comprar os ingressos e reservei muitos ingressos.",
    labelCOVID19TravelRestrictions: "Eu ou um membro da reserva, não posso comparecer devido a uma proibição de viagem COVID-19.",
    labelADWrongSelection: "Você pode ter selecionado a razão errada para um reembolso. Por favor, revise a lista de queda da Razão de Reembolso novamente.",
    labelAdverseWeather1: "Por que o tempo adverso significava que você não pôde comparecer ao evento?",
    tooltipAdverseWeather1: "Por favor, detalhes",
    labelAdverseWeather2: "Data do incidente",
    tooltipAdverseWeather2: "Por favor, selecione",
    headingArmedForces: "Você é solicitado pelo seu Empregador que é as Forças Armadas ou Serviços de Emergência para entrar em trabalho quando você não estava originalmente programado para fazê-lo e isso impede você de participar do evento?",
    headingArmedForcesDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelArmedForces1: "Por que forças ou serviço de emergência você está empregado?",
    tooltipArmedForces1: "Por favor, selecione",
    labelArmedForcesOther1: "Por favor, especifique",
    tooltipArmedForcesOther1: "Por favor, especifique",
    labelArmedForces2: "Data do incidente",
    tooltipArmedForces2: "Por favor, selecione",
    headingChangesToExam: "As datas do seu exame foram alteradas pela sua escola/faculdade, etc? Este não é um exame fracassado que você deve resitá-lo?",
    headingChangesToExamDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelExam1: "Qual era a data original do exame?",
    tooltipExam1: "Por favor, selecione",
    labelExam2: "Qual é a nova data do exame?",
    tooltipExam2: "Por favor, selecione",
    labelExam3: "Por que a data do exame mudou?",
    tooltipExam3: "Por favor, digite detalhes",
    labelExam4: "Você falhou no exame anterior?",
    tooltipExam4: "Sim ou não",
    headingCourtSummons: "Você foi convocada para comparecer ao tribunal como testemunha nas mesmas datas do evento?",
    headingCourtSummonsDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelCourtSummons1: "Qual é a razão para sua intimação judicial?",
    tooltipCourtSummons1: "Por favor, selecione",
    labelCourtSummonsOther1: "Por favor, especifique",
    tooltipCourtSummonsOther1: "Por favor, especifique",
    labelCourtSummons2: "Quando a intimação judicial foi emitida?",
    tooltipCourtSummons2: "Por favor, selecione",
    headingCOVID19Test: "Eu, um membro da minha família ou um membro do grupo que participou do evento fui diagnosticado com COVID-19?",
    headingCOVID19TestDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelCOVID19Test1: "Quem tem COVID-19?",
    tooltipCOVID19Test1: "Por favor, selecione",
    labelCOVID19TestOther1a: "Por favor, especifique",
    labelCOVID19TestOther1b: "Especificar nome",
    tooltipCOVID19TestOther1a: "Por favor, especifique",
    tooltipCOVID19TestOther1b: "Especificar nome",
    labelCOVID19Test2: "Que tipo de teste COVID-19 você fez?",
    tooltipCOVID19Test2: "Por favor, selecione",
    labelCOVID19TestOther2: "Por favor, especifique",
    tooltipCOVID19TestOther2: "Por favor, especifique",
    labelCOVID19Test3: "Qual foi a data do seu teste COVID-19?",
    tooltipCOVID19Test3: "Por favor, selecione",
    headingDeath: "Houve a morte de um membro imediato da minha família e isso me impede de participar do evento?",
    headingDeathDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelDeath1: "Qual membro da sua família morreu?",
    tooltipDeath1: "Por favor, selecione",
    labelDeathOther1: "Por favor, especifique",
    tooltipDeathOther1: "Por favor, especifique",
    labelDeath2: "Quando foi a data da morte?",
    tooltipDeath2: "Por favor, selecione",
    labelDeath3: "Nome do falecido",
    tooltipDeath3: "Por favor, especifique",
    headingEmergency: "Eu tive uma emergência genuína que está fora do meu controle e imprevisto que eu posso fornecer evidências?",
    headingEmergencyDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelEmergency1: "Por favor, explique o tipo de emergência?",
    tooltipEmergency1: "Por favor, detalhes",
    labelEmergency2: "Quando isso aconteceu?",
    tooltipEmergency2: "Por favor, selecione",
    labelEmergency3: "Por que essa situação te impede de ir ao evento?",
    tooltipEmergency3: "Por favor, detalhes",
    labelEmergency4: "Você tem provas dessa emergência?",
    tooltipEmergency4: "Sim ou não",
    headingHomeEmergency: "Eu tenho uma emergência domiciliar que é roubo, inundação, incêndio, vandalismo, abuso doméstico que me impede de participar do evento?",
    headingHomeEmergencyDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelHomeEmergency1: "Qual é a emergência domiciliar?",
    tooltipHomeEmergency1: "Por favor, selecione",
    labelHomeEmergencyOther1: "Por favor, especifique",
    tooltipHomeEmergencyOther1: "Por favor, especifique",
    labelHomeEmergency2: "Quando isso aconteceu?",
    tooltipHomeEmergency2: "Por favor, selecione",
    labelHomeEmergency3: "Por que isso te impediu de ir ao evento?",
    tooltipHomeEmergency3: "Por favor, detalhes",
    headingInjury: "Eu, um membro da minha família ou um membro do grupo que participou do evento fui diagnosticado com uma condição médica ou lesão que agora nos impede de ir ao evento?",
    headingInjuryDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelInjury1: "Qual é a natureza da doença/lesão/acidente?",
    tooltipInjury1: "Por favor, detalhes",
    labelInjury2: "Quando isso aconteceu?",
    tooltipInjury2: "Por favor, selecione",
    labelInjury3: "Você foi a um centro médico para esta doença/lesão?",
    tooltipInjury3: "Sim ou não",
    labelInjury4: "Era uma condição médica pré-existente?",
    tooltipInjury4: "Sim ou não",
    labelInjury5: "Quando isso foi diagnosticado pela primeira vez?",
    tooltipInjury5: "Por favor, selecione",
    labelInjury6: "Por que isso significa que você não pode participar do evento?",
    tooltipInjury6: "Por favor, detalhes",
    labelInjury7: "Quem sofreu a doença/lesão?",
    tooltipInjury7: "Por favor, selecione",
    headingJuryService: "Eu ou um membro da reserva fui chamado para o Serviço do Júri?",
    headingJuryServiceDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelJuryService1: "Quando foi notificado do Serviço do Júri?",
    tooltipJuryService1: "Por favor, selecione",
    headingMechanicalBreakdown: "Nosso veículo teve uma pane mecânica ou foi em um acidente e isso nos impede de participar do evento?",
    headingMechanicalBreakdownDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelMechanicalBreakdown1: "O que aconteceu com o veículo?",
    tooltipMechanicalBreakdown1: "Por favor, selecione",
    labelMechanicalBreakdown2: "Quando isso aconteceu?",
    tooltipMechanicalBreakdown2: "Por favor, selecione",
    labelMechanicalBreakdown3: "Por que você foi incapaz de tomar transporte alternativo?",
    tooltipMechanicalBreakdown3: "Por favor, detalhes",
    headingPregnancyComplication: "Eu ou um membro da reserva temos complicações de gravidez que foram diagnosticadas por um profissional médico e, portanto, prevenir o comparecimento no evento?",
    headingPregnancyComplicationDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelPregnancyComplication1: "Qual é a natureza da Complicação da Gravidez?",
    tooltipPregnancyComplication1: "Por favor, detalhes",
    labelPregnancyComplication2: "Quando isso aconteceu?",
    tooltipPregnancyComplication2: "Por favor, selecione",
    labelPregnancyComplication3: "Você procurou atendimento médico?",
    tooltipPregnancyComplication3: "Sim ou não",
    labelPregnancyComplication4: "Por que isso significa que você não pode participar do evento?",
    tooltipPregnancyComplication4: "Por favor, detalhes",
    headingPublicTransportFailure: "O método de Transporte Público (Trem, Ônibus, Bonde, Barco, etc) falhou inesperadamente, o que significa que não havia outra maneira de eu viajar para este evento?",
    headingPublicTransportFailureDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelPublicTransportFailure1: "Que tipo de transporte público você planejava usar?",
    tooltipPublicTransportFailure1: "Por favor, selecione",
    labelPublicTransportFailure2: "Quando planejava viajar?",
    tooltipPublicTransportFailure2: "Por favor, selecione",
    labelPublicTransportFailure3: "O que aconteceu?",
    tooltipPublicTransportFailure3: "Por favor, detalhes",
    labelPublicTransportFailure4: "Por que não pegou transporte alternativo?",
    tooltipPublicTransportFailure4: "Por favor, detalhes",
    headingScheduledAirlineFailure: "Meu voo pré-reservado foi cancelado inesperadamente e eu não consegui encontrar uma alternativa adequada, o que significa que não pude comparecer ao evento?",
    headingScheduledAirlineFailureDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelScheduledAirlineFailure1: "Nome da Companhia Aérea",
    tooltipScheduledAirlineFailure1: "Nome da Companhia Aérea",
    labelScheduledAirlineFailure2: "Número do vôo",
    tooltipScheduledAirlineFailure2: "Número do vôo",
    labelScheduledAirlineFailure3: "Viajando de",
    tooltipScheduledAirlineFailure3: "Viajando de",
    labelScheduledAirlineFailure4: "Viajando para",
    tooltipScheduledAirlineFailure4: "Viajando para",
    labelScheduledAirlineFailure5: "Data do incidente?",
    tooltipScheduledAirlineFailure5: "Por favor, selecione",
    labelScheduledAirlineFailure6: "Por que você foi incapaz de pegar um voo alternativo?",
    tooltipScheduledAirlineFailure6: "Por favor, detalhes",
    headingTheftOfDocuments: "Meus documentos foram roubados e não foi possível providenciar substituições dentro do prazo necessário para que eu não possa comparecer ao evento",
    headingTheftOfDocumentsDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelTheftOfDocuments1: "Que tipo de documento foi roubado?",
    tooltipTheftOfDocuments1: "Por favor, selecione",
    labelTheftOfDocuments2: "Quando isso aconteceu?",
    tooltipTheftOfDocuments2: "Por favor, selecione",
    labelTheftOfDocuments3: "Tentou reemitir os documentos?",
    tooltipTheftOfDocuments3: "Sim ou não",
    headingWorkRelocation: "Meu empregador me pediu para mudar a localização do meu trabalho por pelo menos três meses e a distância é significativa, o que significa que agora não posso comparecer ao evento?",
    headingWorkRelocationDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelWorkRelocation1: "Isso é uma realocação temporária ou permanente do seu trabalho?",
    tooltipWorkRelocation1: "Por favor, selecione",
    labelWorkRelocation2: "Quanto tempo você vai ficar no novo endereço?",
    tooltipWorkRelocation2: "Por favor, detalhes",
    labelWorkRelocation3: "Qual é o seu endereço de trabalho atual?",
    tooltipWorkRelocation3: "Por favor, detalhes",
    labelWorkRelocation4: "Qual é o seu novo endereço de trabalho?",
    tooltipWorkRelocation4: "Por favor, detalhes",
    labelWorkRelocation5: "Quando foi notificado dessa realocação?",
    tooltipWorkRelocation5: "Por favor, selecione",
    headingRedundancy: "Eu ou um membro da reserva foi despedido pelo seu empregador, o que significa que já não posso participar na reserva?",
    headingRedundancyDecline: "Você não deve prosseguir com este pedido, pois o reembolso provavelmente será recusado.",
    labelRedundancy1: "Tipo de emprego?",
    tooltipRedundancy1: "Por favor, selecione",
    labelRedundancy2: "Há quanto tempo você trabalha nessa empresa?",
    tooltipRedundancy2: "Por favor, selecione",
    labelRedundancy3: "O despedimento foi voluntário?",
    tooltipRedundancy3: "Por favor, selecione",
    labelRedundancy4: "Quando foi notificado do seu despedimento?",
    tooltipRedundancy4: "Por favor, selecione",
    labelTestOption: "Selecione Sim para prosseguir",
}