export default {
    headingAdverseWeather: "Сіздер іс-шараға баруды қауіпсіз ететін қатты қолайсыз ауа-райы жағдайларын бастан кешіріп отырсыздар және бұл туралы Мемлекеттік орган (мысалы, жергілікті билік, полиция және т.б.) тағайындады. Ал сізде мұны дәлелдейтін дәлелдер бар ма?",
    headingAdverseWeatherDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    tooltipRefundReasonConfirm: "Иә немесе жоқ",
    labelBookingCancelledByOperator: "Билеттердің күшін Оператор/Органист алып тастады.",
    labelBookingRescheduledByOperator: "Күнін Оператор/Органист өзгертті және мен жаңа күндерге қатыса алмаймын.",
    labelBookedWrongDate: "Мен немесе брондау мүшесі билеттерді сатып алған кезде қателестім және қате күнді броньдадым.",
    labelBookedWrongTickets: "Мен немесе брондау мүшесі билеттерді сатып алған кезде қателестім және қате билеттерді броньдадым.",
    labelBookedTooManyTickets: "Мен немесе брондау мүшесі, билеттерді сатып алу кезінде қателіктер жолдадым және тым көп билеттерді броньдадым.",
    labelCOVID19TravelRestrictions: "Мен немесе брондау мүшесі, КОВИД-19 жол жүруге тыйым салуға байланысты қатыса алмаймын.",
    labelADWrongSelection: "Қайтарудың қате себебін таңдаған шығарсыз. Қайтару себебі ашылмалы тізімін қайта қарап шығыңыз.",
    labelAdverseWeather1: "Неліктен ауа райының қолайсыздығы іс-шараға қатыса алмадыңыз дегенді білдіреді?",
    tooltipAdverseWeather1: "Егжей-тегжейлі",
    labelAdverseWeather2: "Оқиға болған күні",
    tooltipAdverseWeather2: "Таңдауыңызды сұраймын",
    headingArmedForces: "Сізден Қарулы Күштер немесе Авариялық-құтқару қызметтері болып табылатын Жұмыс беруші сізден бастапқыда жоспарланбаған кезде жұмысқа келуді сұрайды және бұл сізге іс-шараға қатысуға кедергі келтіреді ме?",
    headingArmedForcesDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelArmedForces1: "Сіз қандай күшпен немесе авариялық-құтқару қызметімен жұмысқа орналасасыз?",
    tooltipArmedForces1: "Таңдауыңызды сұраймын",
    labelArmedForcesOther1: "Көрсетіңіз",
    tooltipArmedForcesOther1: "Көрсетіңіз",
    labelArmedForces2: "Оқиға болған күні",
    tooltipArmedForces2: "Таңдауыңызды сұраймын",
    headingChangesToExam: "Сіздің мектеп/колледж және т.б. сіздің емтихан күнін өзгерттіңіз бе? Бұл сізге қайта жазу керек емтихан емес пе?",
    headingChangesToExamDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelExam1: "Сараптаманың бастапқы күні қандай болды?",
    tooltipExam1: "Таңдауыңызды сұраймын",
    labelExam2: "Сараптаманың жаңа күні қандай?",
    tooltipExam2: "Таңдауыңызды сұраймын",
    labelExam3: "Сараптама күні неге өзгерді?",
    tooltipExam3: "Егжей-тегжейін келтіріңіз",
    labelExam4: "Сіз алдыңғы сараптамадан өтпедіңіз бе?",
    tooltipExam4: "Иә немесе жоқ",
    headingCourtSummons: "Сіз оқиғамен бір күнде куә ретінде сотқа келуге шақырылдыңыз ба?",
    headingCourtSummonsDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelCourtSummons1: "Сіздің сот шақыруыңыздың себебі неде?",
    tooltipCourtSummons1: "Таңдауыңызды сұраймын",
    labelCourtSummonsOther1: "Көрсетіңіз",
    tooltipCourtSummonsOther1: "Көрсетіңіз",
    labelCourtSummons2: "Соттың шақыру қағазы қашан берілді?",
    tooltipCourtSummons2: "Таңдауыңызды сұраймын",
    headingCOVID19Test: "Мен, менің үй шаруашылығымның мүшесі ме, әлде іс-шараға қатысатын топ мүшесіме ДЕВИД-19 диагнозы қойылды ма?",
    headingCOVID19TestDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelCOVID19Test1: "2016 жылдың 1-ші жартыжылдығында 2016 жылдың 1-ші жартыжылдығында 2016",
    tooltipCOVID19Test1: "Таңдауыңызды сұраймын",
    labelCOVID19TestOther1a: "Көрсетіңіз",
    labelCOVID19TestOther1b: "Атауын көрсету",
    tooltipCOVID19TestOther1a: "Көрсетіңіз",
    tooltipCOVID19TestOther1b: "Атауын көрсету",
    labelCOVID19Test2: "Сізде КОВИД-19 тестінің қандай түрі болды?",
    tooltipCOVID19Test2: "Таңдауыңызды сұраймын",
    labelCOVID19TestOther2: "Көрсетіңіз",
    tooltipCOVID19TestOther2: "Көрсетіңіз",
    labelCOVID19Test3: "Сіздің COVID-19 тестіңіздің күні қандай болды?",
    tooltipCOVID19Test3: "Таңдауыңызды сұраймын",
    headingDeath: "Менің отбасымның тікелей мүшесі қайтыс болды және ол маған іс-шараға қатысуға кедергі келтіре ме?",
    headingDeathDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelDeath1: "Сіздің отбасыңыздың қай мүшесі қайтыс болды?",
    tooltipDeath1: "Таңдауыңызды сұраймын",
    labelDeathOther1: "Көрсетіңіз",
    tooltipDeathOther1: "Көрсетіңіз",
    labelDeath2: "Қайтыс болған күні қашан болды?",
    tooltipDeath2: "Таңдауыңызды сұраймын",
    labelDeath3: "Марқұмның аты-жөні",
    tooltipDeath3: "Көрсетіңіз",
    headingEmergency: "Менде өзімнің бақылауымнан тыс және күтпеген жерден дәлелдер келтіре алатын шынайы төтенше жағдай болды ма?",
    headingEmergencyDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelEmergency1: "Төтенше жағдайдың түрін түсіндіруіңізді сұраймын ба?",
    tooltipEmergency1: "Егжей-тегжейлі",
    labelEmergency2: "Бұл қашан болды?",
    tooltipEmergency2: "Таңдауыңызды сұраймын",
    labelEmergency3: "Неліктен бұл жағдай іс-шараға қатысуды тоқтатады?",
    tooltipEmergency3: "Егжей-тегжейлі",
    labelEmergency4: "Сізде осы төтенше жағдай туралы деректер бар ма?",
    tooltipEmergency4: "Иә немесе жоқ",
    headingHomeEmergency: "Менде тонау, су тасқыны, өрт, вандализм, тұрмыстық зорлық-зомбылық болып табылатын үйдегі төтенше жағдай бар ма, бұл маған іс-шараға қатысуға кедергі келтіреді ме?",
    headingHomeEmergencyDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelHomeEmergency1: "Үйдегі төтенше жағдай дегеніміз не?",
    tooltipHomeEmergency1: "Таңдауыңызды сұраймын",
    labelHomeEmergencyOther1: "Көрсетіңіз",
    tooltipHomeEmergencyOther1: "Көрсетіңіз",
    labelHomeEmergency2: "Бұл қашан болды?",
    tooltipHomeEmergency2: "Таңдауыңызды сұраймын",
    labelHomeEmergency3: "Іс-шараға қатысуды неге тоқтатқан?",
    tooltipHomeEmergency3: "Егжей-тегжейлі",
    headingInjury: "Мен, менің үй шаруашылығымның мүшесі немесе іс-шараға қатысатын топ мүшесіне медициналық жағдай немесе жарақат диагнозы қойылды ма, ол қазір бізге іс-шараға баруға кедергі келтіреді ме?",
    headingInjuryDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelInjury1: "Аурудың/жарақаттың/жазатайым оқиғаның сипаты қандай?",
    tooltipInjury1: "Егжей-тегжейлі",
    labelInjury2: "Бұл қашан болды?",
    tooltipInjury2: "Таңдауыңызды сұраймын",
    labelInjury3: "Сіз осы ауру/жарақат бойынша медициналық мекемеге бардыңыз ба?",
    tooltipInjury3: "Иә немесе жоқ",
    labelInjury4: "Бұл бұрын болған медициналық жағдай болды ма?",
    tooltipInjury4: "Иә немесе жоқ",
    labelInjury5: "Бұл алғаш рет қашан диагноз қойылды?",
    tooltipInjury5: "Таңдауыңызды сұраймын",
    labelInjury6: "Неліктен бұл іс-шараға қатыса алмайсыз дегенді білдіреді?",
    tooltipInjury6: "Егжей-тегжейлі",
    labelInjury7: "Ауруға/жарақатқа кім шалдыққан?",
    tooltipInjury7: "Таңдауыңызды сұраймын",
    headingJuryService: "Қазылар алқасы қызметіне шақырылдым ба, әлде брондау мүшесі ме?",
    headingJuryServiceDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelJuryService1: "Қазылар алқасының қызметі туралы Сізге қашан хабарланды?",
    tooltipJuryService1: "Таңдауыңызды сұраймын",
    headingMechanicalBreakdown: "Біздің көлік механикалық күйзеліске ұшырады ма, әлде апатқа ұшырады ма және бұл бізге іс-шараға қатысуға кедергі келтіре ме?",
    headingMechanicalBreakdownDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelMechanicalBreakdown1: "Көлік құралымен не болды?",
    tooltipMechanicalBreakdown1: "Таңдауыңызды сұраймын",
    labelMechanicalBreakdown2: "Бұл қашан болды?",
    tooltipMechanicalBreakdown2: "Таңдауыңызды сұраймын",
    labelMechanicalBreakdown3: "Баламалы тасымалдауды неге ала алмадыңыз?",
    tooltipMechanicalBreakdown3: "Егжей-тегжейлі",
    headingPregnancyComplication: "Менде немесе брондау мүшесінде медицина қызметкерінің диагнозы қойылған жүктілік асқынулары бар ма, осыған байланысты іс-шараға қатысуға кедергі келтіре ме?",
    headingPregnancyComplicationDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelPregnancyComplication1: "Жүктілік асқынуының сипаты қандай?",
    tooltipPregnancyComplication1: "Егжей-тегжейлі",
    labelPregnancyComplication2: "Бұл қашан болды?",
    tooltipPregnancyComplication2: "Таңдауыңызды сұраймын",
    labelPregnancyComplication3: "Сіз медициналық көмекке жүгінесіз бе?",
    tooltipPregnancyComplication3: "Иә немесе жоқ",
    labelPregnancyComplication4: "Неліктен бұл іс-шараға қатыса алмайсыз дегенді білдіреді?",
    tooltipPregnancyComplication4: "Егжей-тегжейлі",
    headingPublicTransportFailure: "Қоғамдық көлік әдісі (Пойыз, автобус, трамвай, қайық және т.б.) күтпеген жерден сәтсіздікке ұшырады, яғни мен бұл іс-шараға бара алмадым ба?",
    headingPublicTransportFailureDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelPublicTransportFailure1: "Сіз қоғамдық көліктің қандай түрін пайдалануды жоспарлап отырсыз?",
    tooltipPublicTransportFailure1: "Таңдауыңызды сұраймын",
    labelPublicTransportFailure2: "Сіз қашан саяхаттауды жоспарлаған едіңіз?",
    tooltipPublicTransportFailure2: "Таңдауыңызды сұраймын",
    labelPublicTransportFailure3: "Не болды?",
    tooltipPublicTransportFailure3: "Егжей-тегжейлі",
    labelPublicTransportFailure4: "Неліктен баламалы көлікпен жүру мүмкін болмады?",
    tooltipPublicTransportFailure4: "Егжей-тегжейлі",
    headingScheduledAirlineFailure: "Менің алдын ала брондалған рейсім күтпеген жерден тоқтатылды және мен іс-шараға қатыса алмадым деген лайықты балама мағына таба алмадым ба?",
    headingScheduledAirlineFailureDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelScheduledAirlineFailure1: "Авиакомпанияның атауы",
    tooltipScheduledAirlineFailure1: "Авиакомпанияның атауы",
    labelScheduledAirlineFailure2: "Ұшу нөмірі",
    tooltipScheduledAirlineFailure2: "Ұшу нөмірі",
    labelScheduledAirlineFailure3: "Саяхат",
    tooltipScheduledAirlineFailure3: "Саяхат",
    labelScheduledAirlineFailure4: "Саяхат",
    tooltipScheduledAirlineFailure4: "Саяхат",
    labelScheduledAirlineFailure5: "Оқиға болған күн?",
    tooltipScheduledAirlineFailure5: "Таңдауыңызды сұраймын",
    labelScheduledAirlineFailure6: "Неліктен баламалы рейс жасай алмадыңыз?",
    tooltipScheduledAirlineFailure6: "Егжей-тегжейлі",
    headingTheftOfDocuments: "Менің құжаттарым ұрланды және қажетті мерзімде ауыстыруды ұйымдастыру мүмкін болмады, сондықтан іс-шараға қатыса алмаймын",
    headingTheftOfDocumentsDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelTheftOfDocuments1: "Құжаттың қандай түрі ұрланған?",
    tooltipTheftOfDocuments1: "Таңдауыңызды сұраймын",
    labelTheftOfDocuments2: "Бұл қашан болды?",
    tooltipTheftOfDocuments2: "Таңдауыңызды сұраймын",
    labelTheftOfDocuments3: "Сіз құжаттарды қайтадан беруге әрекеттендіңіз бе?",
    tooltipTheftOfDocuments3: "Иә немесе жоқ",
    headingWorkRelocation: "Менің жұмыс берушім жұмыс орнымды кем дегенде үш айға өзгертуді өтінді және арақашықтық маңызды мағынаға ие, мен қазір іс-шараға қатыса алмаймын ба?",
    headingWorkRelocationDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelWorkRelocation1: "Бұл Сіздің жұмысыңыздың уақытша немесе тұрақты ауысуы ма?",
    tooltipWorkRelocation1: "Таңдауыңызды сұраймын",
    labelWorkRelocation2: "Сіз жаңа мекен-жайда қанша уақыт боласыз?",
    tooltipWorkRelocation2: "Егжей-тегжейлі",
    labelWorkRelocation3: "Сіздің ағымдағы жұмыс мекенжайыңыз қандай?",
    tooltipWorkRelocation3: "Егжей-тегжейлі",
    labelWorkRelocation4: "Сіздің жаңа жұмыс мекенжайыңыз қандай?",
    tooltipWorkRelocation4: "Егжей-тегжейлі",
    labelWorkRelocation5: "Бұл қоныс аудару туралы Сізге қашан хабарланды?",
    tooltipWorkRelocation5: "Таңдауыңызды сұраймын",
    headingRedundancy: "Мен немесе брондау мүшесін жұмыс беруші артық жасады, яғни мен енді брондауға қатыса алмаймын ба?",
    headingRedundancyDecline: "Қайтару азайтылатындықтан, бұл қосымшаны жалғастырмау керек.",
    labelRedundancy1: "Жұмыспен қамтылу түрі бар ма?",
    tooltipRedundancy1: "Таңдауыңызды сұраймын",
    labelRedundancy2: "Сіз осы компанияға қанша уақыт жұмысқа орналастыңыз?",
    tooltipRedundancy2: "Таңдауыңызды сұраймын",
    labelRedundancy3: "Жұмыстан босату ерікті болды ма?",
    tooltipRedundancy3: "Таңдауыңызды сұраймын",
    labelRedundancy4: "Сізге жұмыстан босату туралы қашан хабарланды?",
    tooltipRedundancy4: "Таңдауыңызды сұраймын",
    labelTestOption: "Жалғастыру үшін Иә таңдаңыз",
}