import * as React from 'react';
import {LiveChatWidget} from '@livechat/widget-react';
import {getQueryParamValue} from '../components/form/utils/helpers';
import {useRecoilValue} from 'recoil';
import {LiveChatEnabledState} from '../components/state/activity';

interface LiveChatWidgetProps {
    showChat: boolean;
}

export const LiveChat: React.FC<LiveChatWidgetProps> = ({showChat}) => {

    const isLiveChatEnabled = useRecoilValue(LiveChatEnabledState);
    const handleWidgetReady = () => {
        const LiveChatWidget = (window as any).LiveChatWidget;
        if (LiveChatWidget) {
            LiveChatWidget.call('set_session_variables', {
                application_reference: getQueryParamValue('id', null),
            });
        }
    };

    return (<>
            {showChat && isLiveChatEnabled &&
                <LiveChatWidget
                    license='17424501'
                    visibility='maximized'
                    onReady={handleWidgetReady}
                />
            }
        </>
    );
}