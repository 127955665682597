export default {
    labelBookingCancelledPostponedHeading: "A sua reserva foi cancelada ou adiada? Leia mais",
    labelBookingCancelledPostponedText1: "Clique",
    labelBookingCancelledPostponedText2: "para mais informações.",
    labelNewRefundHeading: "Novo pedido de reembolso?",
    labelNewRefundText: "Digite sua referência de reserva aqui...",
    placeholderBookingReference: "Referência de reserva",
    validationBookingReference: "Referência de reserva é necessária",
    validationEmailAsBookingReference: "O endereço de e-mail não é aceito como referência de reserva",
    buttonFindBooking: "Encontrar reserva",
    labelEmailHeading: "Já começou?",
    labelEmailText: "Envie um lembrete por e-mail para recuperar seu aplicativo.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Lembrete de e-mail",
    labelCustomerName: "Nome do cliente",
    validationCustomerName: "Nome do cliente é obrigatório",
    labelTypeOfBooking: "Tipo de reserva",
    validationTypeOfBooking: "O tipo de reserva é obrigatório",
    labelEventName: "Nome do evento",
    labelVenueName: "Nome do local",
    labelFlightNumber: "Número do voo",
    labelOriginAirport: "Aeroporto de origem",
    labelHotelName: "Nome da acomodação",
    labelHotelLocation: "Local de Alojamento",
    labelCurrency: "Moeda",
    validationCurrency: "A moeda é obrigatória",
    validationCountryCurrency: "A moeda selecionada deve ser a moeda do seu país de residência",
    validationOfflinePayment: "Não podemos processar reembolsos para sua seleção de moeda e país. Selecione alternativas",
    labelBookingValue: "Valor da reserva",
    validationBookingValue: "O valor da reserva é obrigatório",
    validationBookingValueAmount: "Deve ser maior que 0",
    labelDateOfPurchase: "Data da compra",
    validationDateOfPurchase: "A data de compra é obrigatória",
    labelEmailAddress: "Endereço de e-mail",
    validationEmailAddress: "É necessário um endereço de e-mail",
    validationEmailAddressFormat: "Formato de e-mail incorreto",
    labelConfirmEmailAddress: "Confirme o endereço de e-mail",
    validationConfirmEmailAddressFormat: "Formato de e-mail incorreto",
    validationConfirmEmailAddress: "O endereço de e-mail de confirmação é obrigatório",
    validationConfirmEmailAddressMatch: "Os endereços de e-mail não correspondem",
    validationEmailDomain: "Este provedor de e-mail não é aceito, use uma alternativa",
    validationDateOfEvent: "A data do evento é obrigatória",
    labelHomeCountry: "País natal",
    validationHomeCountry: "País de origem é obrigatório",
    labelContactNumberCountryCode: "Código do país do número de contato",
    validationContactNumberCountryCode: "O código de discagem do país é obrigatório",
    labelContactNumber: "Número de contato",
    validationContactNumber: "O número de contato é obrigatório",
    labelAddressLine1: "Endereço Linha 1",
    labelAddressLine2: "Endereço linha 2",
    labelCity: "Cidade",
    labelZipCode: "Código postal",
    labelRegion: "Região",
    validationRegion: "Região é obrigatória",
    labelNumberOfTicketsToRefund: "Número de ingressos para reembolso",
    validationNumberOfTicketsToRefund: "Número de ingressos para reembolso é obrigatório",
    validationNumberOfTicketsToRefundValue: "Deve ser maior que 0",
    labelCostPerTicket: "Custo de cada Ingresso",
    validationCostPerTicket: "O custo por ingresso é obrigatório",
    validationCostPerTicketValue: "Deve ser maior que 0",
    labelTotalRefundAmount: "Valor total do reembolso",
    validationTotalRefundAmount: "O valor total do reembolso é obrigatório",
    validationTotalRefundAmountValue: "Deve ser maior que 0",
    validationTotalRefundGreaterThanBookingValue: "O valor total do reembolso não pode ser maior que o valor da reserva acima",
    labelBookingConfirmationFiles: "Carregar confirmação de reserva",
    validationBookingConfirmationFiles: "Anexe pelo menos um arquivo ou clique em Salvar e volte mais tarde quando tiver os documentos",
    validationEvidenceFilesPopup: "A evidência deve ser carregada antes do envio. Se você não tiver as evidências agora, salve o formulário e retorne assim que as tiver. Aceitamos cópias eletrônicas, cópias em scanner e fotos.",
    validationBookingConfirmationFilesPopup: "É necessário fazer o upload da confirmação da reserva antes de enviar. Se você não tiver a confirmação da reserva agora, salve o aplicativo e retorne quando tiver. Aceitamos cópias eletrônicas, cópias digitalizadas e fotos.",
    alertUploadDocuments: "Carregue a documentação relevante detalhada no e-mail e prossiga para a Etapa 3.",
    labelRefundReasonCategory: "Motivo do reembolso",
    validationRefundReasonCategory: "O motivo do reembolso é obrigatório",
    labelDateOfIncident: "Data do incidente",
    labelAdditionalComments: "Comentários adicionais",
    tooltipAdditionalComments: "Comentários adicionais",
    validationAdditionalComments: "Comentários adicionais são necessários",
    labelUnableToNotifyWithinTimeLimit: "Incapaz de notificar em 60 dias a partir de",
    labelReasonForNotificationOutsideTimeLimit: "Explicação de notificação fora do limite de tempo",
    validationReasonForNotificationOutsideTimeLimit: "É necessário um motivo para notificação após 60 dias",
    labelRefundReasonFiles: "Upload de evidências",
    validationRefundReasonFiles: "Anexe pelo menos um arquivo ou clique em Salvar e volte mais tarde quando tiver os documentos",
    validationTextScript: "Deve corresponder à escrita latina",
    optionTypeOfBooking1: "Evento",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Voar",
    optionTypeOfBooking4: "Alojamento",
    optionTypeOfBooking5: "Transferir",
    optionTypeOfBooking6: "Estacionamento",
    optionTypeOfBooking7: "Outras viagens",
    labelEventTypeDate: "Data do Evento",
    labelEventTypeTour: "Data do Tour",
    labelEventTypeFlight: "Data de Voo",
    labelEventTypeCheckIn: "Data de Check-In",
    labelEventTypeTransfer: "Data de Transferência",
    labelEventTypeParking: "Data de Estacionamento",
    labelEventTypeOtherTravel: "Data da Viagem",
    optionRefundReason11: "Condições climáticas adversas",
    optionRefundReason10: "Rechamada das Forças Armadas e Serviços de Emergência",
    optionRefundReason15: "Mudanças nas datas de exames",
    optionRefundReason18: "Convocação judicial",
    optionRefundReason17: "Restrições a viajar devido ao COVID-19",
    optionRefundReason19: "Teste Positivo COVID-19",
    optionRefundReason20: "Reserva cancelada pelo operador / organizador",
    optionRefundReason21: "Reserva reagendada pelo operador/organizador",
    optionRefundReason22: "Data errada reservada",
    optionRefundReason23: "Bilhetes reservados errados",
    optionRefundReason24: "Reservaram muitos ingressos",
    optionRefundReason4: "Morte",
    optionRefundReason16: "Circunstâncias de Emergência",
    optionRefundReason14: "Proibição de viagens do governo",
    optionRefundReason9: "Emergência Doméstica",
    optionRefundReason1: "Doença / Lesão / Acidente",
    optionRefundReason8: "Serviço de júri",
    optionRefundReason7: "Avaria mecânica",
    optionRefundReason2: "Condição Médica Pré-existente",
    optionRefundReason3: "Complicação na Gravidez",
    optionRefundReason5: "Falha no transporte público",
    optionRefundReason6: "Interrupção de voo",
    optionRefundReason13: "Roubo de documento (s)",
    optionRefundReason12: "Relocação de Trabalho",
    optionRefundReason25: "Redundância no local de trabalho",
    optionRefundReason26: "Opção de teste",
    optionRefundReasonConfirm1: "Não",
    optionRefundReasonConfirm2: "Sim",
    optionRefundReasonArmedForces1: "Exército",
    optionRefundReasonArmedForces2: "Marinha",
    optionRefundReasonArmedForces3: "Força aérea",
    optionRefundReasonArmedForces4: "Forças Especiais",
    optionRefundReasonArmedForces5: "Fuzileiros",
    optionRefundReasonArmedForces6: "Fogo",
    optionRefundReasonArmedForces7: "Ambulância",
    optionRefundReasonArmedForces8: "Médico/Enfermeiro",
    optionRefundReasonArmedForces9: "Dentista",
    optionRefundReasonArmedForces10: "Outro",
    optionRefundReasonCourtSummons1: "Processo Penal",
    optionRefundReasonCourtSummons2: "Processo Civil",
    optionRefundReasonCourtSummons3: "Testemunha em uma acusação",
    optionRefundReasonCourtSummons4: "Testemunha em um caso civil",
    optionRefundReasonCourtSummons5: "Autor",
    optionRefundReasonCourtSummons6: "Acusado",
    optionRefundReasonCourtSummons7: "Outro",
    optionRefundReasonDeath1: "Marido",
    optionRefundReasonDeath2: "Esposa",
    optionRefundReasonDeath3: "Som",
    optionRefundReasonDeath4: "Filha",
    optionRefundReasonDeath5: "Pai",
    optionRefundReasonDeath6: "Mãe",
    optionRefundReasonDeath7: "Irmão",
    optionRefundReasonDeath8: "Irmã",
    optionRefundReasonDeath9: "Avô",
    optionRefundReasonDeath10: "Avó",
    optionRefundReasonDeath11: "Outro",
    optionRefundReasonHomeEmergency1: "Fogo",
    optionRefundReasonHomeEmergency2: "Inundação",
    optionRefundReasonHomeEmergency3: "Assalto",
    optionRefundReasonHomeEmergency4: "Vandalismo",
    optionRefundReasonHomeEmergency5: "Abuso doméstico",
    optionRefundReasonHomeEmergency6: "Raptar",
    optionRefundReasonHomeEmergency7: "Outro",
    optionRefundReasonCOVID19Person1: "Eu",
    optionRefundReasonCOVID19Person2: "Outra pessoa na reserva",
    optionRefundReasonCOVID19Person3: "Uma pessoa em minha casa",
    optionRefundReasonCOVID19Person4: "Outro",
    optionRefundReasonCOVID19TestType1: "Teste PCR",
    optionRefundReasonCOVID19TestType2: "Teste RAT-Antigeno",
    optionRefundReasonCOVID19TestType3: "Teste de fluxo lateral",
    optionRefundReasonCOVID19TestType4: "Auto teste",
    optionRefundReasonCOVID19TestType5: "Sem teste, mas tenho sintomas.",
    optionRefundReasonCOVID19TestType6: "Outro",
    optionRefundReasonBreakdown1: "Avaria",
    optionRefundReasonBreakdown2: "Acidente",
    optionRefundReasonBreakdown3: "Fogo",
    optionRefundReasonBreakdown4: "Furto",
    optionRefundReasonPublicTransportType1: "Trem",
    optionRefundReasonPublicTransportType2: "Autocarro",
    optionRefundReasonPublicTransportType3: "Bonde",
    optionRefundReasonPublicTransportType4: "Barco",
    optionRefundReasonDocumentType1: "Passaporte",
    optionRefundReasonDocumentType2: "Permissão de viagem",
    optionRefundReasonDocumentType3: "ID Nacional",
    optionRefundReasonDocumentType4: "Bilhetes",
    optionRefundReasonWorkRelocationType1: "Temporário",
    optionRefundReasonWorkRelocationType2: "Permanente",
    optionRefundReasonWorkRelocationTemporary1: "0-3 meses",
    optionRefundReasonWorkRelocationTemporary2: "3-6 meses",
    optionRefundReasonWorkRelocationTemporary3: "Mais de 6 meses",
    optionRefundReasonBookingRelationshipType1: "Alguém na reserva",
    optionRefundReasonBookingRelationshipType2: "Parente imediato de alguém na reserva",
    optionEmploymentType1: "Tempo Integral",
    optionEmploymentType2: "Meio período",
    optionEmploymentType3: "Contrato",
    optionEmploymentLengthType1: "0-1 Anos",
    optionEmploymentLengthType2: "1-2 Anos",
    optionEmploymentLengthType3: "Mais de 2 anos",
    textRefundEvidenceHeading: "Forneça as seguintes evidências:",
    textRefundEvidence1: "Confirmação da Companhia Aérea",
    textRefundEvidence2: "Qualquer evidência de apoio apropriada",
    textRefundEvidence3: "Confirmação de chamada de serviço de decomposição",
    textRefundEvidence4: "Atestado de óbito",
    textRefundEvidence5: "Cancelamento de confirmação da companhia aérea",
    textRefundEvidence6: "Previsão do tempo",
    textRefundEvidence7: "Correspondência do agente de reserva confirmando nenhuma capacidade de reemitir bilhetes",
    textRefundEvidence8: "Carta do Tribunal",
    textRefundEvidence9: "Atestado Médico / Nota do Médico",
    textRefundEvidenceDescription9: "Confirmando detalhes da doença ou lesão, a data em que ocorreu primeiro, que impede você de participar da Reserva.",
    textRefundEvidence10: "Confirmação da carta do empregador",
    textRefundEvidence11: "Correspondência de sinistro de seguro",
    textRefundEvidence12: "Carta do Empregador",
    textRefundEvidence13: "Confirmação do site do Governo Nacional",
    textRefundEvidence14: "Aviso de alteração do exame",
    textRefundEvidence15: "Relatório policial",
    textRefundEvidence16: "Boletim de ocorrência e número de referência",
    textRefundEvidence17: "Confirmação do provedor de transporte",
    textRefundEvidence18: "Aviso de Viagem",
    textRefundEvidence20: "Certificado de teste COVID-19",
    textRefundEvidence24: "Aviso de Isolamento do Governo",
    textRefundEvidence25: "Carta do Oficial Comandante",
    textRefundEvidence26: "Carta das Seguradoras",
    textRefundEvidence27: "Comprovante de Endereço",
    textRefundEvidence28: "Prova de relação com o falecido",
    textRefundEvidence29: "Prova de relação com a pessoa ferida/doente",
    textRefundEvidence30: "Recibo de pagamento",
    tooltipBookingReference: "Para identificar a referência da sua reserva, verifique a confirmação da sua reserva e consulte a secção sobre o nosso serviço alargado.",
    tooltipCustomerName: "O nome e o sobrenome usados ​​para fazer a reserva.",
    tooltipTypeOfBooking: "Tipo de reserva que você deseja ser reembolsado.",
    tooltipEventName: "O nome do evento.",
    tooltipVenueName: "O nome do local.",
    tooltipFlightNumber: "O número do vôo.",
    tooltipOriginAirport: "O aeroporto de partida.",
    tooltipHotelName: "O nome da acomodação.",
    tooltipHotelLocation: "A localização do alojamento.",
    tooltipCurrency: "A moeda usada para pagar a reserva.",
    tooltipBookingValue: "Preço total que você pagou pela reserva.",
    tooltipDateOfPurchase: "The date that the booking was made.",
    tooltipEmailAddress: "Por favor use um endereço de email válido. Usamos este e-mail para correspondência durante o pedido de reembolso.",
    tooltipConfirmEmailAddress: "Use o mesmo endereço de e-mail acima, isso é para validação.",
    tooltipHomeCountry: "Isso nos ajudará a trazer as informações bancárias corretas na etapa final.",
    tooltipContactNumberCountryCode: "Código do país do número de contato.",
    tooltipContactNumber: "Use um número válido. Usamos isso para correspondência durante o pedido de reembolso.",
    tooltipAddressLine1: "Observe que seu endereço será usado ao validar sua conta bancária.",
    tooltipAddressLine2: "Segunda linha de endereço.",
    tooltipCity: "Os dados da sua cidade natal podem ser usados ​​para validar os dados bancários.",
    tooltipZipCode: "Código postal / código postal.",
    tooltipRegion: "Região da sua cidade, os detalhes podem ser usados ​​para validar os dados bancários.",
    tooltipNumberOfTicketsToRefund: "O número de ingressos que você gostaria de ser reembolsado.",
    tooltipTotalRefundAmount: "Este não pode ser mais do que o valor total da reserva, você não poderá enviar a inscrição se for o caso.",
    tooltipBookingConfirmationFiles: "Captura de tela / anexo da confirmação de reserva completa, que geralmente pode ser encontrada em seus e-mails.",
    tooltipRefundReasonCategory: "Selecione o motivo do reembolso.",
    tooltipDateOfIncident: "A data em que ocorreu o incidente que afetou sua reserva.",
    tooltipRefundExplanation: "Forneça o máximo de detalhes possível que embasem o pedido de reembolso.",
    tooltipUnableToNotifyWithinTimeLimit: "Incapaz de notificar dentro do limite de tempo de 60 dias.",
    tooltipReasonForNotificationOutsideTimeLimit: "Explicação de notificação fora do limite de tempo.",
    tooltipRefundReasonFiles: "Faça o upload de qualquer um dos documentos necessários.",
    tooltipEventTypeDate: "Data do evento / reserva real.",
    tooltipEventTypeTour: "Data da turnê real.",
    tooltipEventTypeFlight: "Data do voo real.",
    tooltipEventTypeCheckIn: "Data do check-in real.",
    tooltipEventTypeTransfer: "Data da transferência real.",
    tooltipEventTypeParking: "Data do estacionamento real.",
    tooltipEventTypeOtherTravel: "Data de início da viagem real."
};