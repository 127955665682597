export default {
    nonAutoApprovedLabelThankYou: "Σας ευχαριστούμε για την αίτηση επιστροφής χρημάτων.",
    nonAutoApprovedLabelReview: "Η ομάδα μας θα εξετάσει την αίτησή σας εντός των επόμενων δύο εργάσιμων ημερών και θα επικοινωνήσει μαζί σας με απάντηση.",
    nonAutoApprovedLabelExperience: "Πώς τα καταφέραμε;",
    nonAutoApprovedLabelExperienceIntroduction: "Θα εκτιμούσαμε πραγματικά να αφιερώσετε χρόνο για να μας αξιολογήσετε και να αφήσετε μια κριτική, καθώς εκτιμούμε τη γνώμη των πελατών μας.",
    feedbackExcellent: "Άριστος",
    feedbackGreat: "Μεγάλο",
    feedbackAverage: "Μέσος όρος",
    feedbackPoor: "Φτωχός",
    feedbackBad: "Κακό",
    labelThankYouForFeedback: "Σας ευχαριστούμε για τα σχόλιά σας.",
    autoApprovedMessage1: "Είμαστε ενθουσιασμένοι που",
    autoApprovedMessage2: "Εγκρίνω",
    autoApprovedMessage3: "την επιστροφή των χρημάτων σας!",
    autoApprovedPayment1Day: "Η πληρωμή σας έχει ήδη υποβληθεί σε επεξεργασία και θα πρέπει να βρίσκεται στον λογαριασμό σας σε μόλις 1 εργάσιμη ημέρα.",
    autoApprovedPayment2to4Days: "Η πληρωμή σας έχει ήδη υποβληθεί σε επεξεργασία και θα πρέπει να βρίσκεται στον λογαριασμό σας σε μόλις 2-4 εργάσιμες ημέρες.",
    autoApprovedPayment7to10Days: "Η πληρωμή σας έχει ήδη υποβληθεί σε επεξεργασία και θα πρέπει να βρίσκεται στον λογαριασμό σας σε μόλις 7-10 εργάσιμες ημέρες.",
    autoApprovedLabelExperience: "Πώς τα καταφέραμε;",
    autoApprovedLabelExperienceIntroduction: "Θα εκτιμούσαμε πραγματικά να αφιερώσετε χρόνο για να μας αξιολογήσετε και να αφήσετε μια κριτική, καθώς εκτιμούμε τη γνώμη των πελατών μας.",
    modalLabelNegative: "Τα σχόλιά σας είναι πολύ σημαντικά για εμάς, ενημερώστε μας αν θέλετε επίσης να επικοινωνήσουμε μαζί σας.",
    placeholderFeedback: "Ανάδραση",
    messageFeedbackSubmitted: "Υποβολή σχολίων",
    reviewInstructionMessage: "5 αστέρια είναι εξαιρετική, 4 αστέρια είναι μεγάλη, 3 αστέρια είναι μέσος όρος, 2 αστέρια είναι φτωχό, 1 αστέρι είναι κακό."
}