export default {
    messageUnableToMakePaymentToCurrency: "ជាអកុសល យើងមិនអាចទាញយកព័ត៌មានលម្អិតអំពីធនាគារសម្រាប់រូបិយប័ណ្ណដែលបានជ្រើសរើសបានទេ។ សូមប្រាកដថារូបិយប័ណ្ណ និងប្រទេសដើមត្រូវបានជ្រើសរើសនៅលើ",
    messageOnlyBankAccountsAccepted: "សូមចំណាំ៖ យើងអាចធ្វើការទូទាត់ទៅគណនីធនាគារបានតែនៅពេលនេះ (កាតឥណទាន និងឥណពន្ធមិនត្រូវបានទទួលយកទេ)",
    labelAccountName: "អ្នក​កាន់​គណនី​ឈ្មោះ",
    labelBankAccountName: "ឈ្មោះពេញរបស់អ្នកកាន់គណនី",
    labelBankCardHolderName: "ឈ្មោះ​អ្នក​កាន់​ប័ណ្ណ",
    validationBankAccountName: "ឈ្មោះពេញរបស់អ្នកកាន់គណនីត្រូវបានទាមទារ",
    labelBankCountry: "ប្រទេសធនាគារ",
    labelBankName: "ឈ្មោះ​របស់​ធនាគារ",
    labelPaymentType: "ប្រភេទការទូទាត់",
    labelInstitutionNumber: "លេខស្ថាប័ន",
    labelBankAddress: "អាស័យ​ដ្ឋាន​ធនាគារ",
    labelBankAddress2: "អាស័យ​ដ្ឋាន​ធនាគារ",
    labelBankCity: "ធនាគារទីក្រុង",
    labelBankState: "ធនាគាររដ្ឋ",
    labelBankAccountNumber: "លេខ​គណនី​ធនាគារ",
    labelBankAccountNumberCBU: "លេខគណនី CBU",
    labelBankAccountNumberCCI: "លេខគណនី CCI",
    labelBankAccountNumberZAR: "លេខគណនីធនាគារ (7-11 ខ្ទង់)",
    labelCardNumber: "លេខ​កាត",
    labelUnionpay: "លេខកាត UnionPay",
    labelAlipay: "លេខសម្គាល់ Alipay (លេខទូរស័ព្ទ ឬអ៊ីមែល)",
    labelBankAccountType: "ប្រភេទ​គណនី",
    optionBankAccountTypeCURRENT: "គណនី​បច្ចុប្បន្ន",
    optionBankAccountTypeCHECKING: "កំពុងពិនិត្យគណនី",
    optionBankAccountTypeSAVING: "គណនី​សន្សំ",
    optionBankAccountTypeSAVINGS: "គណនី​សន្សំ",
    optionBankAccountTypeCUENTA_VISTA: "គណនីមេ",
    optionBankAccountType1: "កំពុងពិនិត្យគណនី",
    optionBankAccountType2: "គណនី​សន្សំ",
    optionBankAccountType3: "គណនីមេ",
    optionBankAccountType4: "គណនី Vista",
    optionBankAccountType5: "គណនី RUT",
    labelSortCode: "តម្រៀបលេខកូដ",
    labelRoutingCode: "លេខកូដផ្លូវ",
    labelBankRoutingCodeNationalID: "លេខសម្គាល់ធនាគារ/អត្តសញ្ញាណប័ណ្ណ",
    labelBankTransitCodeSwift: "លេខឆ្លងកាត់",
    labelCNAPSCode: "លេខកូដផ្លូវ / CNAPS Code (លេខកូដប្រព័ន្ធទូទាត់កម្រិតខ្ពស់ជាតិចិន)",
    labelABAFedwireRoutingCode: "លេខផ្លូវ ACH",
    labelIFSCCode: "លេខកូដ IFSC",
    labelBankCode: "លេខកូដធនាគារ",
    labelBIKCode: "លេខកូដ BIK",
    labelBranchCode: "លេខកូដសាខា",
    labelBranchCodeUniversal: "លេខកូដសាខា 6 ខ្ទង់ជាសកល",
    labelRemittanceLine3: "ឈ្មោះ និងលេខទូរស័ព្ទទំនាក់ទំនងអ្នកទទួលផល",
    labelBeneficiaryContactNameandTelephoneNumber: "ឈ្មោះ និងលេខទូរស័ព្ទទំនាក់ទំនងអ្នកទទួលផល",
    labelBeneficiaryDateofBirthandPlaceofBirth: "ថ្ងៃខែឆ្នាំកំណើត និងទីកន្លែងកំណើតរបស់អ្នកទទួលផល",
    labelRemittanceLine4: "លេខពន្ធបុគ្គល CUIL (9-12 ខ្ទង់)",
    labelCUILNumber: "លេខសម្គាល់ពន្ធ៖ CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "លេខសម្គាល់ពន្ធ RFC 13 ខ្ទង់ ឬ CURP 18 ខ្ទង់",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "លេខសម្គាល់ពន្ធបុគ្គល CPF Cadastro de Pessoa Física (11-14 ខ្ទង់) លេខកូដសាខា",
    labelCFPNumber: "លេខ CPF (11-14 ខ្ទង់)",
    labelBranchNameorBranchCode7digits: "ឈ្មោះសាខា ឬលេខកូដសាខា (7 ខ្ទង់)",
    labelCedulaJuridica910digitsTaxID: "វិញ្ញាបនបត្រផ្លូវច្បាប់ (លេខសម្គាល់ពន្ធ 9-10 ខ្ទង់)",
    labelTaxIDRUT89digits: "លេខសម្គាល់ពន្ធ RUT (8-9 ខ្ទង់)",
    labelJob: "ចំណងជើងការងារ",
    labelDateOfBirth: "ថ្ងៃខែ​ឆ្នាំ​កំណើត",
    labelNationality: "សញ្ជាតិ",
    labelDocumentType: "ប្រភេទ​ឯកសារ",
    labelDocumentNumber: "លេខ​ឯកសារ",
    labelRutNumber: "លេខ RUT (សាខា Roll Unique)",
    labelCedulaTaxIDIndividual811digits: "អត្តសញ្ញាណប័ណ្ណ (លេខសម្គាល់ពន្ធបុគ្គល 8-11 ខ្ទង់)",
    labelCIIndividualTaxIDBranchLocation: "CI (លេខសម្គាល់ពន្ធបុគ្គល) ទីតាំងសាខា",
    labelTaxIDRUCorCI: "លេខសម្គាល់ពន្ធ (RUC ឬ CI)",
    labelSortCodeHelp: "ជំនួយ?",
    labelBranchCodeHelp: "ជំនួយ?",
    labelIBAN: "IBAN",
    labelIBANHelp: "ជំនួយ?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "ជំនួយ?",
    labelRoutingCodeBSB: "លេខកូដផ្លូវ / BSB",
    labelBSB: "សាខាធនាគាររដ្ឋ (BSB)",
    labelSKNCode: "កូដ SKN",
    labelAcceptanceFlights: "ខ្ញុំ/យើង​ទទួល​យក​ថា​ប្រសិន​បើ​កម្មវិធី​បង្វិល​ប្រាក់​ទទួល​បាន​ជោគជ័យ ការ​កក់​សំបុត្រ​រួម​ទាំង​ជើង​ហោះ​ហើរ​ទាំង​អស់ ហើយ​ផលិតផល​បន្ថែម​នឹង​ត្រូវ​បាន​លុប​ចោល។",
    labelAcceptanceTickets: "ខ្ញុំ/យើង​ទទួល​យក​ថា​ប្រសិន​បើ​កម្មវិធី​បង្វិល​សង​ប្រាក់​បាន​ជោគជ័យ​ ការ​កក់​សំបុត្រ​រួម​ទាំង​សំបុត្រ​ទាំង​អស់ ហើយ​ផលិតផល​បន្ថែម​នឹង​ត្រូវ​បាន​លុបចោល។",
    labelTermsAndConditions1: "ខ្ញុំ / យើងបានយល់ព្រម",
    labelTermsAndConditions2: "លក្ខខណ្ឌ",
    labelFraudWarning1: "ខ្ញុំជឿថាការពិតដែលមាននៅក្នុងទម្រង់ពាក្យសុំសងប្រាក់វិញនេះគឺពិតប្រាកដ និងពិត។ ខ្ញុំយល់ថាព័ត៌មានមិនពិត ឬភ័ស្តុតាងអាចត្រូវបានចាត់ទុកថាជាអំពើឧក្រិដ្ឋ ហើយត្រូវបានបញ្ជូនទៅប៉ូលីស ឬភ្នាក់ងារអនុវត្តច្បាប់ផ្សេងទៀតដើម្បីបន្ត។",
    labelFraudWarning2: "កន្លែងដែលយើងសង្ស័យថាមានការក្លែងបន្លំទាក់ទងនឹងឯកសារគាំទ្រ បញ្ហានេះនឹងត្រូវបញ្ជូនទៅអាជ្ញាធរនគរបាលមូលដ្ឋានភ្លាមៗ។ ខ្ញុំទទួលយក និងយល់ថាព័ត៌មានលម្អិតរបស់ខ្ញុំនឹងត្រូវបញ្ជូនទៅអាជ្ញាធរទាំងនេះ ប្រសិនបើពាក្យសុំរបស់ខ្ញុំត្រូវបានចាត់ទុកថាជាការក្លែងបន្លំ ហើយនោះអាចនឹងក្លាយទៅជាការស៊ើបអង្កេតឧក្រិដ្ឋកម្ម។",
    validationTermsAndConditions: "លក្ខខណ្ឌត្រូវតែយល់ព្រម",
    messageFailbankName: "ឈ្មោះធនាគារមិនអាចទទេបានទេ។",
    messageFailcustomerName: "ឈ្មោះអតិថិជនមិនអាចទទេបានទេ។",
    messageFailbankAccountNumber: "វាលដែលត្រូវការ",
    messageFailbankAccountNumberFormat: "ត្រូវតែជាទម្រង់ IBAN",
    messageFailsortCode: "វាលដែលត្រូវការ",
    messageFailbankSwiftBic: "អ្នកទទួលផល SWIFT BIC មិនអាចទទេបានទេ។",
    messageFailbankSwiftBicFormat: "ត្រូវតែជាទម្រង់ SWIFT",
    messageFailbeneficiaryAddress1: "អាសយដ្ឋាន 1 មិនអាចទទេបានទេ។",
    messageFailbeneficiaryCity: "ទីក្រុងមិនអាចនៅទទេបានទេ។",
    messageFailbeneficiaryPostCode: "លេខកូដប្រៃសណីយ៍មិនអាចទទេបានទេ។",
    messageFailremittanceLine3: "វាលដែលត្រូវការ",
    messageFailremittanceLine4: "វាលដែលត្រូវការ",
    modalHeading: "បញ្ជាក់ព័ត៌មានលម្អិត",
    modalLabelPleaseConfirmYourDetails: "សូមបញ្ជាក់ព័ត៌មានលម្អិតរបស់អ្នក។",
    modalValidationPleaseConfirm: "សូម​បញ្ជាក់",
    modalValidationMustMatch: "ត្រូវតែផ្គូផ្គងធាតុមុន។",
    tooltipBankAccountName: "ឈ្មោះរបស់អ្នកដូចដែលវាបង្ហាញនៅលើគណនីធនាគារ/របាយការណ៍ធនាគារ/កាតធនាគាររបស់អ្នក។.",
    tooltipBankAccountNameRecipient: "ឈ្មោះ និងនាមត្រកូលរបស់អ្នកទទួល។ ទាំងនាមខ្លួន និងនាមត្រកូលត្រូវតែមានច្រើនជាងមួយតួអក្សរ.",
    tooltipBankCountry: "ប្រទេសដែលធនាគាររបស់អ្នកស្ថិតនៅ.",
    tooltipBankName: "មោះធនាគាររបស់អ្នក។.",
    tooltipPaymentType: "រភេទនៃការទូទាត់ដែលអ្នកត្រូវការ.",
    tooltipInstitutionNumber: "នេះជាលេខ 3 ខ្ទង់ ហើយជាធម្មតាមាននៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក.",
    tooltipBankAccountNumber: "លេខគណនីផ្ទាល់ខ្លួនរបស់អ្នក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankAccountNumberCBU: "លេខ 22 ខ្ទង់ដែលប្រើដើម្បីកំណត់អត្តសញ្ញាណគណនីរបស់អ្នក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankAccountNumberCCI: "លេខ 20 ខ្ទង់ដែលប្រើដើម្បីកំណត់អត្តសញ្ញាណគណនីរបស់អ្នក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankAccountNumberKRW: "លេខ 11-16 ខ្ទង់ដែលប្រើដើម្បីកំណត់អត្តសញ្ញាណគណនីរបស់អ្នក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCardNumber: "លេខកាតរបស់អ្នក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipUnionpay: "លេខកាត UnionPay របស់អ្នក។.",
    tooltipAlipay: "លេខទូរស័ព្ទ ឬអាសយដ្ឋានអ៊ីមែលរបស់អ្នកដែលភ្ជាប់ជាមួយគណនី Alipay របស់អ្នក។.",
    tooltipBankAccountType: "នេះគឺជាការពិនិត្យ ឬសន្សំ.",
    tooltipSortCode: "វាកំណត់សាខាធនាគាររបស់អ្នក ដែលគណនីរបស់អ្នកត្រូវបានកាន់កាប់។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipRoutingCode: "វាកំណត់សាខាធនាគាររបស់អ្នក ដែលគណនីរបស់អ្នកត្រូវបានកាន់កាប់។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBeneficiarySWIFTBIC: "មានតួអក្សរ 8-11 ដែលប្រើដើម្បីកំណត់អត្តសញ្ញាណធនាគារក្នុងប្រតិបត្តិការអន្តរជាតិ។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBSB: "លេខសាខារដ្ឋរបស់ធនាគារ (BSB) គឺជាលេខកូដតម្រៀបរបស់ប្រទេសអូស្ត្រាលីដែលមាន 6 ខ្ទង់។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipRoutingCodeBSB: "វាកំណត់សាខាធនាគាររបស់អ្នក ដែលគណនីរបស់អ្នកត្រូវបានកាន់កាប់។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែ ឬកាតធនាគាររបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipABAFedwireRoutingCode: "លេខ 9 ខ្ទង់ដែលប្រើដើម្បីកំណត់អត្តសញ្ញាណធនាគារនៅអាមេរិក។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipIFSCCode: "ជាធម្មតាលេខកូដនេះអាចត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែរបស់អ្នក ឬនៅលើសៀវភៅកត់ត្រារបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក.",
    tooltipTermsAndConditions: "តាមរយៈការទទួលយកលក្ខខណ្ឌនានា អ្នកបញ្ជាក់ថាអ្នកបានអាន និងយល់ព្រមតាមលក្ខខណ្ឌរបស់យើង។.",
    tooltipIBAN: "លេខគណនីធនាគារអន្តរជាតិរបស់អ្នក ដែលអ្នកអាចប្រើនៅពេលធ្វើ ឬទទួលការទូទាត់អន្តរជាតិ។ ជាធម្មតាត្រូវបានរកឃើញនៅលើរបាយការណ៍ប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "ឈ្មោះ និងលេខទូរស័ព្ទទំនាក់ទំនងអ្នកទទួលផល.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "ថ្ងៃខែឆ្នាំកំណើត និងទីកន្លែងកំណើតរបស់អ្នកទទួលផល.",
    tooltipBankRoutingCodeNationalID: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankTransitCodeSwift: "នេះគឺជា 5 ខ្ទង់ ហើយជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក.",
    tooltipBankAddress: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankAddress2: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankCity: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankState: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBranchName: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBankCode: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBIKCode: "9 ខ្ទង់ដើម្បីកំណត់អត្តសញ្ញាណធនាគាររបស់អ្នក។.",
    tooltipBranchCode: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBranchCodeUniversal: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCNAPSCode: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipRemittanceLine4: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCUILNumber: "នេះគឺជាលេខ 11 ខ្ទង់ ដែលមានពីរខ្ទង់ សហសញ្ញា ប្រាំបួនខ្ទង់ និងលេខមួយខ្ទង់.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCFPNumber: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipBranchNameorBranchCode7digits: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCedulaJuridica910digitsTaxID: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipTaxIDRUT89digits: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipJob: "មុខរបរបច្ចុប្បន្នរបស់អ្នក។.",
    tooltipDateOfBirth: "ថ្ងៃខែឆ្នាំកំណើតរបស់អ្នក។.",
    tooltipNationality: "សញ្ជាតិរបស់អ្នក។.",
    tooltipDocumentType: "ប្រភេទឯកសារដែលត្រូវការ.",
    tooltipDocumentNumber: "នេះគឺជាលេខឯកសារដែលទាក់ទងនឹងការជ្រើសរើសប្រភេទឯកសាររបស់អ្នក។.",
    tooltipRutNumber: "លេខ RUT របស់អ្នក  (សាខា Roll តែមួយគត់).",
    tooltipCedulaTaxIDIndividual811digits: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipCIIndividualTaxIDBranchLocation: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    tooltipTaxIDRUCorCI: "ជាធម្មតាអាចរកបាននៅលើរបាយការណ៍ធនាគារប្រចាំខែរបស់អ្នក ឬប្រសិនបើអ្នកនៅតែមិនប្រាកដ សូមសួរធនាគាររបស់អ្នក។.",
    errorCPFCode: "នៅពេលនេះ យើងទទួលយកតែ CPF ជាកូនសោ PIX ប៉ុណ្ណោះ។"
};