import * as React from 'react';
import {FormikProps, useField} from 'formik';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from '../providers/FormProvider';
import {SelectInput} from '../formInputs';

interface CurrencySelectsControlProps {
    formProps: FormikProps<IFormValues>;
}

export const CurrencySelectsControl = (props: CurrencySelectsControlProps) => {

    const {
        formProps
    } = props;

    const {
        bookingMatched,
        changeCurrency,
        setCountryCodeDialingCodeValue,
        updateBeneficiaryLabels,
        updateCountryCurrency,
        updateRegion
    } = useFormContext();

    const {t} = useTranslation();

    const [contactNumberField, contactNumberMeta, contactNumberHelper] = useField(nameof<IFormValues>(x => x.contactNumberCountryCode));
    const [totalRefundAmountField, totalRefundAmountMeta, totalRefundAmountHelper] = useField(nameof<IFormValues>(x => x.totalRefundAmount));

    const handleCountryChange = (value: string) => {

        void contactNumberHelper.setValue(value);
        updateRegion(value, formProps.values.currency);
        setCountryCodeDialingCodeValue(formProps, value);
        updateBeneficiaryLabels(formProps, formProps.values.memberId, value, formProps.values.currency, formProps.values.bookingValue, false, formProps.values.paymentType);
        updateCountryCurrency(value, formProps.values.currency);
    }

    const currencyHandler = (value: string) => {

        if (bookingMatched) {
            changeCurrency(formProps, value);
        } else {
            void totalRefundAmountHelper.setValue(0);
        }

        updateRegion(formProps.values.homeCountry, value);
        updateBeneficiaryLabels(formProps, formProps.values.memberId, formProps.values.homeCountry, value, formProps.values.bookingValue, false, formProps.values.paymentType);
        updateCountryCurrency(formProps.values.homeCountry, value);

        if (bookingMatched) {
            const selector = `[name='${nameof<IFormValues>(x => x.totalRefundAmount)}']`;
            const element = document.querySelector(selector) as HTMLInputElement;
            element.focus();
        }
    }

    return (
        <>
            <SelectInput
                key={nameof<IFormValues>(x => x.currency)}
                label={t('form:labelCurrency')}
                controlId={nameof<IFormValues>(x => x.currency)}
                hidden={false}
                datasourceUrl='/api/referencedata/currencies'
                onChange={currencyHandler}
                tooltip={t('form:tooltipCurrency')}
                displayAsAutoComplete
                required
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.homeCountry)}
                label={t('form:labelHomeCountry')}
                controlId={nameof<IFormValues>(x => x.homeCountry)}
                hidden={false}
                datasourceUrl='/api/referencedata/countries'
                onChange={handleCountryChange}
                tooltip={t('form:tooltipHomeCountry')}
                displayAsAutoComplete
                required
            />
        </>
    );
}