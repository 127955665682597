export default {
    messageUnableToMakePaymentToCurrency: "很遗憾，我们无法检索所选货币​​的银行详细信息。请确保货币和母国在",
    messageOnlyBankAccountsAccepted: "请注意：我们目前只能在银行帐户中付款（不接受信用卡和借记卡）",
    labelAccountName: "账户持有人姓名",
    labelBankAccountName: "账户持有人的全名",
    labelBankCardHolderName: "持卡人姓名",
    validationBankAccountName: "需要账户持有人的全名",
    labelBankCountry: "银行国家/ 地区",
    labelBankName: "银行名称",
    labelPaymentType: "付款方式",
    labelInstitutionNumber: "机构编号",
    labelBankAddress: "银行地址",
    labelBankAddress2: "银行地址",
    labelBankCity: "银行城市",
    labelBankState: "银行状态",
    labelBankAccountNumber: "银行帐号",
    labelBankAccountNumberCBU: "帐号 CBU",
    labelBankAccountNumberCCI: "帐号 CCI",
    labelBankAccountNumberZAR: "银行帐号（7-11 位数字）",
    labelCardNumber: "卡号",
    labelUnionpay: "银联卡号",
    labelAlipay: "支付宝ID（手机号或邮箱）",
    labelBankAccountType: "帐户类型",
    optionBankAccountTypeCURRENT: "往来账户",
    optionBankAccountTypeCHECKING: "支票账户",
    optionBankAccountTypeSAVING: "储蓄账户",
    optionBankAccountTypeSAVINGS: "储蓄账户",
    optionBankAccountTypeCUENTA_VISTA: "主账户",
    optionBankAccountType1: "支票账户",
    optionBankAccountType2: "储蓄账户",
    optionBankAccountType3: "主账户",
    optionBankAccountType4: "Vista 帐户",
    optionBankAccountType5: "RUT账户",
    labelSortCode: "排序代码",
    labelRoutingCode: "路由代码",
    labelBankRoutingCodeNationalID: "银行路由代码 / 国家ID",
    labelBankTransitCodeSwift: "过境号码",
    labelCNAPSCode: "路由代码 / CNAPS代码（中国国家高级支付系统代码）",
    labelABAFedwireRoutingCode: "ACH 路由号码",
    labelIFSCCode: "国际金融服务委员会代码",
    labelBankCode: "银行代码",
    labelBIKCode: "代码",
    labelBranchCode: "分支代码",
    labelBranchCodeUniversal: "通用6位分行代码",
    labelRemittanceLine3: "收款人姓名和电话号码",
    labelBeneficiaryContactNameandTelephoneNumber: "受益人联系人姓名和电话号码",
    labelBeneficiaryDateofBirthandPlaceofBirth: "受益人出生日期和出生地",
    labelRemittanceLine4: "个人税号CUIL（9 - 12位数字）",
    labelCUILNumber: "税号：CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "税号RFC 13位或CURP 18位",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "个人税号 公积金卡德斯特罗·德佩索阿·菲西卡（11-14位数字）,分行代码",
    labelCFPNumber: "CPF编号（11-14位）",
    labelBranchNameorBranchCode7digits: "分支名称或分支代码（7位）",
    labelCedulaJuridica910digitsTaxID: "法律证明书（9-10位数的税号）",
    labelTaxIDRUT89digits: "税务编号RUT（8-9位数）",
    labelJob: "职称",
    labelDateOfBirth: "出生日期",
    labelNationality: "国籍",
    labelDocumentType: "文件类型",
    labelDocumentNumber: "文档编号",
    labelRutNumber: "RUT 编号 (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "塞杜拉（税号，个人8-11位数字）",
    labelCIIndividualTaxIDBranchLocation: "CI（个人税号），分支机构位置",
    labelTaxIDRUCorCI: "税号（RUC或CI）",
    labelSortCodeHelp: "Help?",
    labelBranchCodeHelp: "Help?",
    labelIBAN: "IBAN",
    labelIBANHelp: "帮助？",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "帮助？",
    labelRoutingCodeBSB: "路由代码 / BSB",
    labelBSB: "银行国家分行（BSB）",
    labelSKNCode: "斯凯恩码",
    labelAcceptanceFlights: "我/我们接受，如果退款申请成功，预订包括所有航班和附属产品将被取消。",
    labelAcceptanceTickets: "我/我们接受，如果退款申请成功，包括所有机票和附属产品在内的预订将被取消。",
    labelTermsAndConditions1: "我 / 我们已经同意",
    labelTermsAndConditions2: "条款和条件",
    labelFraudWarning1: "我相信本退款申请表中提供的事实是真实的。我了解任何虚假信息或证据都可能被视为犯罪行为，并被转交给警方或其他执法机构追查。",
    labelFraudWarning2: "如果我们怀疑与证明文件有关的欺诈行为，此问题将立即提交给当地警察当局。我接受并理解如果我的申请被认为是欺诈性的并且可能成为刑事调查，我的详细信息将被传递给这些当局。",
    validationTermsAndConditions: "条款和条件必须得到同意",
    messageFailbankName: "银行名称不能为空",
    messageFailcustomerName: "客户名称不能为空",
    messageFailbankAccountNumber: "必填字段",
    messageFailbankAccountNumberFormat: "必须为IBAN格式",
    messageFailsortCode: "必填字段",
    messageFailbankSwiftBic: "收款人的SWIFT BIC不能为空",
    messageFailbankSwiftBicFormat: "必须为SWIFT格式",
    messageFailbeneficiaryAddress1: "地址1不能为空",
    messageFailbeneficiaryCity: "城市不能为空",
    messageFailbeneficiaryPostCode: "邮政编码不能为空",
    messageFailremittanceLine3: "必填字段",
    messageFailremittanceLine4: "必填字段",
    modalHeading: "确认详情",
    modalLabelPleaseConfirmYourDetails: "请确认您的详细信息",
    modalValidationPleaseConfirm: "请确认",
    modalValidationMustMatch: "必须匹配上一个条目",
    tooltipBankAccountName: "您在银行帐户/银行对帐单/银行卡上显示的姓名。",
    tooltipBankAccountNameRecipient: "收件人的名字和姓氏。名字和姓氏都必须有多个字符。",
    tooltipBankCountry: "您的银行所在的国家/地区。",
    tooltipBankName: "您的银行名称。",
    tooltipPaymentType: "您需要的付款类型。",
    tooltipInstitutionNumber: "这是 3 位数字，通常可以在您的月结单或银行卡上找到，如果您仍然不确定，请咨询您的银行。",
    tooltipBankAccountNumber: "您的个人帐号。通常在您的月结单或银行卡上找到，或者如果您仍然不确定，请咨询您的银行。",
    tooltipBankAccountNumberCBU: "用于识别您的帐户的 22 位数字。通常可以在您的月结单或银行卡上找到，如果您仍然不确定，请咨询您的银行。",
    tooltipBankAccountNumberCCI: "用于识别您的帐户的 20 位数字。通常可以在您的月结单或银行卡上找到，如果您仍然不确定，请咨询您的银行。",
    tooltipBankAccountNumberKRW: "用于标识您的帐户的 11-16 位数字。通常可以在您的月结单或银行卡上找到，如果您仍然不确定，请咨询您的银行。",
    tooltipCardNumber: "你的卡号。通常可以在您的月结单或银行卡上找到，如果您仍然不确定，请咨询您的银行。",
    tooltipUnionpay: "您的银联卡号。",
    tooltipAlipay: "与您的支付宝帐户关联的手机号码或电子邮件地址。",
    tooltipBankAccountType: "这是支票或储蓄。",
    tooltipSortCode: "这标识了您的帐户所在银行的分支机构。通常在您的月结单或银行卡上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipRoutingCode: "这标识了您的帐户所在银行的分支机构。通常在您的月结单或银行卡上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBeneficiarySWIFTBIC: "由8-11个字符组成，用于在国际交易中识别银行。通常在您的月度对帐单上找到，或者如果您仍然不确定，请向您的银行查询。",
    tooltipBSB: "银行州分行（BSB）号是澳大利亚的一种邮政编码，有6位数字。通常在您的月度对帐单上找到，或者如果您仍然不确定，请向您的银行查询。",
    tooltipRoutingCodeBSB: "这标识了您的帐户所在银行的分支机构。通常在您的月结单或银行卡上找到，或者如果您仍然不确定，请咨询您的银行。",
    tooltipABAFedwireRoutingCode: "9位数字，用于标识美国的银行。通常在您的月度对帐单上找到，或者如果您仍然不确定，请向您的银行查询。",
    tooltipIFSCCode: "通常可以在您的月结单或存折上找到此代码，如果您仍然不确定，请咨询您的银行。",
    tooltipTermsAndConditions: "接受条款和条件，即表示您已阅读并同意我们的条款和条件。",
    tooltipIBAN: "您的国际银行帐号，可在进行国际付款时使用。通常在您的月度对帐单上找到，或者如果您仍然不确定，请向您的银行查询。",
    tooltipBeneficiaryContactNameandTelephoneNumber: "收款人联络人姓名和电话号码。",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "收款人的出生日期和出生地。",
    tooltipBankRoutingCodeNationalID: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBankTransitCodeSwift: "这是 5 位数字，通常可以在您的每月银行对帐单中找到，如果您仍然不确定，请咨询您的银行。",
    tooltipBankAddress: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBankAddress2: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBankCity: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBankState: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBranchName: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBankCode: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBIKCode: "9 位数字来识别您的银行。",
    tooltipBranchCode: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBranchCodeUniversal: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipCNAPSCode: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipRemittanceLine4: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipCUILNumber: "这是一个 11 位数字，由两位数字、连字符、九位数字和一位数字校验和组成。",
    tooltipTaxIDRFC13DigitsORCURP18digits: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipCFPNumber: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipBranchNameorBranchCode7digits: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipCedulaJuridica910digitsTaxID: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipTaxIDRUT89digits: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipJob: "你现在的职业。",
    tooltipDateOfBirth: "您的出生日期。",
    tooltipNationality: "你的国籍。",
    tooltipDocumentType: "所需的文档类型。",
    tooltipDocumentNumber: "这是与您的单据类型选择相关联的单据编号。",
    tooltipRutNumber: "您的 RUT 编号 (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipCIIndividualTaxIDBranchLocation: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    tooltipTaxIDRUCorCI: "通常可以在您的每月银行对帐单上找到，或者如果您仍然不确定，请询问您的银行。",
    errorCPFCode: "目前我们只接受 CPF 作为 PIX 密钥。"
};