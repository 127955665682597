export default {
    headingAdverseWeather: "អ្នក ជួប ប្រទះ នូវ ស្ថានភាព អាកាសធាតុ អន់ ថយ ធ្ងន់ធ្ងរ ដែល ធ្វើ ឲ្យ វា មិន មាន សុវត្ថិភាព ក្នុង ការ ធ្វើ ដំណើរ ទៅ កាន់ ព្រឹត្តិការណ៍ នេះ ហើយ ការណ៍ នេះ ត្រូវ បាន បញ្ញត្តិ ដោយ អង្គភាព រដ្ឋាភិបាល (ឧ. អាជ្ញាធរ មូលដ្ឋាន នគរបាល ជាដើម)។ ហើយ អ្នក មាន ភស្ដុ តាង ដើម្បី បង្ហាញ រឿង នេះ ?",
    headingAdverseWeatherDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    tooltipRefundReasonConfirm: "ព្រម​ ឬ​មិនព្រម",
    labelBookingCancelledByOperator: "សំបុត្រត្រូវបានលុបចោលដោយប្រតិបត្តិករ/អ្នករៀបចំ។",
    labelBookingRescheduledByOperator: "កាល បរិច្ឆេទ នេះ ត្រូវ បាន ផ្លាស់ ប្តូរ ដោយ Operator/Organiser ហើយ ខ្ញុំ មិន អាច ចូល រួម កាល បរិច្ឆេទ ថ្មី បាន ទេ ។",
    labelBookedWrongDate: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ បាន ធ្វើ កំហុស ពេល ទិញ សំបុត្រ ហើយ កក់ កាល បរិច្ឆេទ ខុស ។",
    labelBookedWrongTickets: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ បាន ធ្វើ កំហុស ពេល ទិញ សំបុត្រ ហើយ កក់ សំបុត្រ ខុស ។",
    labelBookedTooManyTickets: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ បាន ធ្វើ កំហុស ពេល ទិញ សំបុត្រ ហើយ កក់ សំបុត្រ ច្រើន ពេក ។",
    labelCOVID19TravelRestrictions: "ខ្ញុំ ឬ សមាជិក ម្នាក់ នៃ ការ កក់ មិន អាច ចូល រួម បាន ទេ ដោយសារ តែ ការ ហាម ឃាត់ ការ ធ្វើ ដំណើរ COVID-19 ។",
    labelADWrongSelection: "អ្នក ប្រហែល ជា បាន ជ្រើស មូលហេតុ ខុស សម្រាប់ ការ សង ប្រាក់ វិញ ។ សូម ពិនិត្យ ឡើង វិញ នូវ ការ ធ្លាក់ ចុះ នៃ បញ្ជី ឈ្មោះ Refund Reason ម្តង ទៀត ។",
    labelAdverseWeather1: "ហេតុអ្វី អាកាសធាតុ អាក្រក់ មាន ន័យ ថា អ្នក មិន អាច ចូល រួម ព្រឹត្តិការណ៍ នេះ បាន ?",
    tooltipAdverseWeather1: "សូមលម្អិត",
    labelAdverseWeather2: "កាលបរិច្ឆេទ នៃ ឧប្បត្តិហេតុ",
    tooltipAdverseWeather2: "សូមជ្រើសរើស",
    headingArmedForces: "តើ អ្នក ត្រូវ បាន សួរ ដោយ និយោជក របស់ អ្នក ថា តើ កង កម្លាំង ប្រដាប់ អាវុធ ឬ សេវា កម្ម សង្គ្រោះ បន្ទាន់ ត្រូវ ចូល ធ្វើ ការ នៅ ពេល ដែល អ្នក មិន ត្រូវ បាន គ្រោង ធ្វើ ដូច្នេះ ពី ដំបូង ឡើយ ហើយ នេះ រា រាំង អ្នក មិន ឲ្យ ចូល រួម ព្រឹត្តិ ការណ៍ នេះ ?",
    headingArmedForcesDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelArmedForces1: "តើ អ្នក បាន ប្រើ កម្លាំង ឬ សេវា សង្គ្រោះ បន្ទាន់ អ្វី ខ្លះ ?",
    tooltipArmedForces1: "សូមជ្រើសរើស",
    labelArmedForcesOther1: "សូម បញ្ជាក់",
    tooltipArmedForcesOther1: "សូម បញ្ជាក់",
    labelArmedForces2: "កាលបរិច្ឆេទ នៃ ឧប្បត្តិហេតុ",
    tooltipArmedForces2: "សូមជ្រើសរើស",
    headingChangesToExam: "កាល បរិច្ឆេទ នៃ ការ ប្រឡង របស់ អ្នក ត្រូវ បាន ផ្លាស់ ប្ដូរ ដោយ សាលា/មហាវិទ្យាល័យ របស់ អ្នក។ល។ នេះ មិន មែន ជា ការ ប្រឡង ដែល បរាជ័យ ដែល អ្នក ត្រូវ តែ ស្តារ ឡើង វិញ ទេ ឬ អី ?",
    headingChangesToExamDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelExam1: "តើកាលបរិច្ឆេទប្រឡងដើមជាអ្វី?",
    tooltipExam1: "សូមជ្រើសរើស",
    labelExam2: "តើកាលបរិច្ឆេទប្រឡងថ្មីគឺជាអ្វី?",
    tooltipExam2: "សូមជ្រើសរើស",
    labelExam3: "ហេតុអ្វីបានជាកាលបរិច្ឆេទប្រឡងផ្លាស់ប្តូរ?",
    tooltipExam3: "សូម បញ្ចូល ព័ត៌មាន លម្អិត",
    labelExam4: "តើ អ្នក បាន បរាជ័យ ក្នុង ការ ប្រឡង មុន ដែរ ឬ ទេ ?",
    tooltipExam4: "ព្រម​ ឬ​មិនព្រម",
    headingCourtSummons: "តើ អ្នក ត្រូវ បាន កោះ ហៅ ឲ្យ បង្ហាញ ខ្លួន នៅ តុលាការ ជា សាក្សី នៅ ថ្ងៃ ដូច គ្នា នឹង ព្រឹត្តិ ការណ៍ នេះ ឬ ទេ ?",
    headingCourtSummonsDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelCourtSummons1: "តើអ្វីជាមូលហេតុនៃការកោះហៅរបស់តុលាការរបស់អ្នក?",
    tooltipCourtSummons1: "សូមជ្រើសរើស",
    labelCourtSummonsOther1: "សូម បញ្ជាក់",
    tooltipCourtSummonsOther1: "សូម បញ្ជាក់",
    labelCourtSummons2: "តើ ការ កោះ ហៅ របស់ តុលាការ ត្រូវ បាន ចេញ នៅ ពេល ណា ?",
    tooltipCourtSummons2: "សូមជ្រើសរើស",
    headingCOVID19Test: "ខ្ញុំ ជា សមាជិក ម្នាក់ ក្នុង គ្រួសារ របស់ ខ្ញុំ ឬ ជា សមាជិក ក្រុម ដែល ចូល រួម ក្នុង ព្រឹត្តិការណ៍ នេះ ត្រូវ បាន ពិនិត្យ ឃើញ ថា មាន ជំងឺ COVID-19?",
    headingCOVID19TestDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelCOVID19Test1: "តើ COVID-19 មាន នរណា ខ្លះ?",
    tooltipCOVID19Test1: "សូមជ្រើសរើស",
    labelCOVID19TestOther1a: "សូម បញ្ជាក់",
    labelCOVID19TestOther1b: "បញ្ជាក់ ឈ្មោះ",
    tooltipCOVID19TestOther1a: "សូម បញ្ជាក់",
    tooltipCOVID19TestOther1b: "បញ្ជាក់ ឈ្មោះ",
    labelCOVID19Test2: "តើការប្រឡង COVID-19 ប្រភេទណាខ្លះ?",
    tooltipCOVID19Test2: "សូមជ្រើសរើស",
    labelCOVID19TestOther2: "សូម បញ្ជាក់",
    tooltipCOVID19TestOther2: "សូម បញ្ជាក់",
    labelCOVID19Test3: "តើការធ្វើតេស្ត COVID-19 របស់អ្នកមានកាលបរិច្ឆេទអ្វីខ្លះ?",
    tooltipCOVID19Test3: "សូមជ្រើសរើស",
    headingDeath: "មាន ការ ស្លាប់ របស់ សមាជិក គ្រួសារ ខ្ញុំ ភ្លាម ៗ ហើយ វា រា រាំង ខ្ញុំ មិន ឲ្យ ចូល រួម ព្រឹត្តិ ការណ៍ នេះ ?",
    headingDeathDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelDeath1: "តើក្រុមគ្រួសាររបស់អ្នកបានស្លាប់សមាជិកណាខ្លះ?",
    tooltipDeath1: "សូមជ្រើសរើស",
    labelDeathOther1: "សូម បញ្ជាក់",
    tooltipDeathOther1: "សូម បញ្ជាក់",
    labelDeath2: "តើថ្ងៃស្លាប់នៅពេលណា?",
    tooltipDeath2: "សូមជ្រើសរើស",
    labelDeath3: "ឈ្មោះអ្នកស្លាប់",
    tooltipDeath3: "សូម បញ្ជាក់",
    headingEmergency: "ខ្ញុំ មាន គ្រោះ អាសន្ន ដ៏ ពិត ប្រាកដ មួយ ដែល នៅ ក្រៅ ការ គ្រប់ គ្រង របស់ ខ្ញុំ ហើយ មិន បាន គិត ទុក ជា មុន ថា តើ ខ្ញុំ អាច ផ្តល់ ភស្តុតាង សម្រាប់ នរណា ?",
    headingEmergencyDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelEmergency1: "សូម ពន្យល់ ពី ប្រភេទ អាសន្ន ?",
    tooltipEmergency1: "សូមលម្អិត",
    labelEmergency2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipEmergency2: "សូមជ្រើសរើស",
    labelEmergency3: "ហេតុអ្វី ស្ថានភាព នេះ បញ្ឈប់ អ្នក ឲ្យ ចូល រួម ព្រឹត្តិការណ៍ នេះ ?",
    tooltipEmergency3: "សូមលម្អិត",
    labelEmergency4: "តើ អ្នក មាន ភស្តុតាង នៃ គ្រោះ អាសន្ន នេះ ឬ ទេ ?",
    tooltipEmergency4: "ព្រម​ ឬ​មិនព្រម",
    headingHomeEmergency: "ខ្ញុំមានអាសន្ននៅផ្ទះដែលប្លន់, ទឹកជំនន់, ភ្លើង, វណ្ណដាលីស, ការរំលោភបំពានផ្ទៃក្នុងដែលរារាំងខ្ញុំមិនឲ្យចូលរួមកម្មវិធីនេះ?",
    headingHomeEmergencyDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelHomeEmergency1: "តើផ្ទះមានអាសន្នអ្វីខ្លះ?",
    tooltipHomeEmergency1: "សូមជ្រើសរើស",
    labelHomeEmergencyOther1: "សូម បញ្ជាក់",
    tooltipHomeEmergencyOther1: "សូម បញ្ជាក់",
    labelHomeEmergency2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipHomeEmergency2: "សូមជ្រើសរើស",
    labelHomeEmergency3: "ហេតុអ្វី បាន ជា វា បញ្ឈប់ អ្នក ឲ្យ ចូល រួម ព្រឹត្តិការណ៍ នេះ ?",
    tooltipHomeEmergency3: "សូមលម្អិត",
    headingInjury: "ខ្ញុំ ជា សមាជិក ម្នាក់ នៃ គ្រួសារ របស់ ខ្ញុំ ឬ សមាជិក ក្រុម ដែល ចូល រួម ព្រឹត្តិ ការណ៍ នេះ ត្រូវ បាន ពិនិត្យ ថា មាន ស្ថាន ភាព វេជ្ជ សាស្ត្រ ឬ របួស ដែល ឥឡូវ នេះ រា រាំង យើង មិន ឲ្យ ទៅ ព្រឹត្តិ ការណ៍ នោះ ?",
    headingInjuryDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelInjury1: "តើជំងឺ/អាការៈរបួស/មានគ្រោះថ្នាក់អ្វីខ្លះ?",
    tooltipInjury1: "សូមលម្អិត",
    labelInjury2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipInjury2: "សូមជ្រើសរើស",
    labelInjury3: "តតើ​អ្នក​បាន​ចូល​មន្ទីរពេទ្យ​សម្រាប់​ជំងឺ/របួស​នេះ​ទេ?",
    tooltipInjury3: "ព្រម​ ឬ​មិនព្រម",
    labelInjury4: "តើ នេះ ជា ស្ថានភាព វេជ្ជសាស្ត្រ ដែល មាន មុន ឬ ?",
    tooltipInjury4: "ព្រម​ ឬ​មិនព្រម",
    labelInjury5: "តើ នេះ ត្រូវ បាន វិនិច្ឆ័យ ជា លើក ដំបូង នៅ ពេល ណា ?",
    tooltipInjury5: "សូមជ្រើសរើស",
    labelInjury6: "ហេតុអ្វី បាន ជា នេះ មាន ន័យ ថា អ្នក មិន អាច ចូល រួម ព្រឹត្តិការណ៍ នេះ បាន ?",
    tooltipInjury6: "សូមលម្អិត",
    labelInjury7: "តើអ្នកណាបានឆ្លងជំងឺ/របួស?",
    tooltipInjury7: "សូមជ្រើសរើស",
    headingJuryService: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ ត្រូវ បាន គេ ហៅ ទៅ គណៈ វិនិច្ឆ័យ សេវា ?",
    headingJuryServiceDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelJuryService1: "តើ អ្នក ត្រូវ បាន ជូន ដំណឹង អំពី សេវា គណៈ វិនិច្ឆ័យ នៅ ពេល ណា ?",
    tooltipJuryService1: "សូមជ្រើសរើស",
    headingMechanicalBreakdown: "រថ យន្ត របស់ យើង បាន បាក់ មេកានិច ឬ ស្ថិត ក្នុង គ្រោះ ថ្នាក់ ហើយ នេះ រា រាំង យើង មិន ឲ្យ ចូល រួម ព្រឹត្តិ ការណ៍ នេះ ?",
    headingMechanicalBreakdownDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelMechanicalBreakdown1: "តើ មាន អ្វី កើត ឡើង ចំពោះ រថ យន្ត ?",
    tooltipMechanicalBreakdown1: "សូមជ្រើសរើស",
    labelMechanicalBreakdown2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipMechanicalBreakdown2: "សូមជ្រើសរើស",
    labelMechanicalBreakdown3: "ហេតុអ្វី អ្នក មិន អាច ដឹក ជញ្ជូន ជំនួស បាន ?",
    tooltipMechanicalBreakdown3: "សូមលម្អិត",
    headingPregnancyComplication: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ មាន ភាព ស្មុគស្មាញ ក្នុង ការ មាន ផ្ទៃ ពោះ ដែល ត្រូវ បាន ពិនិត្យ ដោយ អ្នក ជំនាញ វេជ្ជ សាស្ត្រ ដូច្នេះ សូម កុំ ឲ្យ ចូល រួម ក្នុង ព្រឹត្តិ ការណ៍ នេះ ?",
    headingPregnancyComplicationDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelPregnancyComplication1: "តើអ្វីជាធម្មជាតិនៃភាពស្មុគស្មាញនៃផ្ទៃពោះ?",
    tooltipPregnancyComplication1: "សូមលម្អិត",
    labelPregnancyComplication2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipPregnancyComplication2: "សូមជ្រើសរើស",
    labelPregnancyComplication3: "តើ អ្នក បាន ស្វែងរក ការ ព្យាបាល ដែរ ឬ ទេ ?",
    tooltipPregnancyComplication3: "ព្រម​ ឬ​មិនព្រម",
    labelPregnancyComplication4: "ហេតុអ្វី បាន ជា នេះ មាន ន័យ ថា អ្នក មិន អាច ចូល រួម ព្រឹត្តិការណ៍ នេះ បាន ?",
    tooltipPregnancyComplication4: "សូមលម្អិត",
    headingPublicTransportFailure: "វិធីសាស្ត្រ នៃ ការ ដឹក ជញ្ជូន សាធារណៈ (Train, Bus, Tram, Boat ជាដើម) បាន បរាជ័យ ដោយ មិន បាន រំពឹង ទុក នោះ ទេ មាន ន័យ ថា គ្មាន មធ្យោបាយ ណា ផ្សេង ទៀត ដែល ខ្ញុំ អាច ធ្វើ ដំណើរ ទៅ ព្រឹត្តិការណ៍ នេះ បាន ទេ?",
    headingPublicTransportFailureDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelPublicTransportFailure1: "តើ អ្នក មាន គម្រោង ប្រើប្រាស់ ការ ដឹក ជញ្ជូន សាធារណៈ ប្រភេទ ណា ខ្លះ ?",
    tooltipPublicTransportFailure1: "សូមជ្រើសរើស",
    labelPublicTransportFailure2: "តើ អ្នក មាន គម្រោង ធ្វើ ដំណើរ នៅ ពេល ណា ?",
    tooltipPublicTransportFailure2: "សូមជ្រើសរើស",
    labelPublicTransportFailure3: "តើមានរឿងអ្វីបានកើតឡើង?",
    tooltipPublicTransportFailure3: "សូមលម្អិត",
    labelPublicTransportFailure4: "ហេតុអ្វី អ្នក មិន អាច ដឹក ជញ្ជូន ជំនួស បាន ?",
    tooltipPublicTransportFailure4: "សូមលម្អិត",
    headingScheduledAirlineFailure: "ជើង ហោះ ហើរ ដែល បាន កក់ ទុក ជា មុន របស់ ខ្ញុំ ត្រូវ បាន លុប ចោល ដោយ មិន បាន រំពឹង ទុក ហើយ ខ្ញុំ មិន អាច ស្វែង រក អត្ថន័យ ជំនួស សមរម្យ ដែល ខ្ញុំ មិន អាច ចូល រួម ព្រឹត្តិ ការណ៍ នេះ បាន ទេ ?",
    headingScheduledAirlineFailureDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelScheduledAirlineFailure1: "ឈ្មោះ ក្រុមហ៊ុន អាកាសចរណ៍",
    tooltipScheduledAirlineFailure1: "ឈ្មោះ ក្រុមហ៊ុន អាកាសចរណ៍",
    labelScheduledAirlineFailure2: "លេខជើងហោះហើរធ",
    tooltipScheduledAirlineFailure2: "លេខជើងហោះហើរធ",
    labelScheduledAirlineFailure3: "ការ ធ្វើ ដំណើរ ពី",
    tooltipScheduledAirlineFailure3: "ការ ធ្វើ ដំណើរ ពី",
    labelScheduledAirlineFailure4: "ការ ធ្វើ ដំណើរ ទៅ កាន់",
    tooltipScheduledAirlineFailure4: "ការ ធ្វើ ដំណើរ ទៅ កាន់",
    labelScheduledAirlineFailure5: "កាលបរិច្ឆេទនៃហេតុការណ៍?",
    tooltipScheduledAirlineFailure5: "សូមជ្រើសរើស",
    labelScheduledAirlineFailure6: "ហេតុអ្វី អ្នក មិន អាច ហោះ ហើរ ជំនួស បាន ?",
    tooltipScheduledAirlineFailure6: "សូមលម្អិត",
    headingTheftOfDocuments: "ឯកសារ របស់ ខ្ញុំ ត្រូវ បាន លួច ហើយ វា មិន អាច រៀបចំ ការ ជំនួស (s) ក្នុង ស៊ុម ពេលវេលា ដែល ត្រូវការ ដើម្បី ខ្ញុំ មិន អាច ចូល រួម ព្រឹត្តិការណ៍ នេះ បាន ទេ",
    headingTheftOfDocumentsDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelTheftOfDocuments1: "តើឯកសារប្រភេទណាខ្លះត្រូវបានលួច?",
    tooltipTheftOfDocuments1: "សូមជ្រើសរើស",
    labelTheftOfDocuments2: "តើ រឿង នេះ បាន កើត ឡើង នៅ ពេល ណា ?",
    tooltipTheftOfDocuments2: "សូមជ្រើសរើស",
    labelTheftOfDocuments3: "តើ អ្នក បាន ព្យាយាម ចេញ ឯកសារ ឡើង វិញ ឬ ទេ ?",
    tooltipTheftOfDocuments3: "ព្រម​ ឬ​មិនព្រម",
    headingWorkRelocation: "និយោជក របស់ ខ្ញុំ បាន ស្នើ ឲ្យ ខ្ញុំ ផ្លាស់ ប្ដូរ ទីតាំង ការងារ របស់ ខ្ញុំ យ៉ាង ហោច ណាស់ បី ខែ ហើយ ចម្ងាយ គឺ មាន ន័យ ថា ខ្ញុំ មិន អាច ចូល រួម ព្រឹត្តិការណ៍ នេះ បាន ទេ ?",
    headingWorkRelocationDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelWorkRelocation1: "តើ នេះ ជា ការ ផ្លាស់ ប្តូរ ការងារ របស់ អ្នក ជា បណ្តោះ អាសន្ន ឬ ជា អចិន្ត្រៃយ៍ ឬ ទេ ?",
    tooltipWorkRelocation1: "សូមជ្រើសរើស",
    labelWorkRelocation2: "តើ អ្នក នឹង នៅ អាសយដ្ឋាន ថ្មី រយៈ ពេល ប៉ុន្មាន ?",
    tooltipWorkRelocation2: "សូមលម្អិត",
    labelWorkRelocation3: "តើ អាសយដ្ឋាន ការងារ បច្ចុប្បន្ន របស់ អ្នក ជា អ្វី ?",
    tooltipWorkRelocation3: "សូមលម្អិត",
    labelWorkRelocation4: "តើ អាសយដ្ឋាន ការងារ ថ្មី របស់ អ្នក ជា អ្វី ?",
    tooltipWorkRelocation4: "សូមលម្អិត",
    labelWorkRelocation5: "តើ អ្នក ត្រូវ បាន ជូន ដំណឹង អំពី ការ ផ្លាស់ ប្ដូរ នេះ នៅ ពេល ណា ?",
    tooltipWorkRelocation5: "សូមជ្រើសរើស",
    headingRedundancy: "ខ្ញុំ ឬ សមាជិក នៃ ការ កក់ ត្រូវ បាន ធ្វើ ឡើង ច្រើន ដោយ និយោជក របស់ ពួក គេ មាន ន័យ ថា ខ្ញុំ មិន អាច ចូល រួម ការ កក់ ទៀត ទេ ?",
    headingRedundancyDecline: "អ្នក មិន គួរ បន្ត កម្មវិធី នេះ ទេ ដោយសារ ការ សង ប្រាក់ វិញ ទំនង ជា នឹង ត្រូវ បាន បដិសេធ ។",
    labelRedundancy1: "ប្រភេទការងារ?",
    tooltipRedundancy1: "សូមជ្រើសរើស",
    labelRedundancy2: "តើ អ្នក ត្រូវ បាន ជួល ដោយ ក្រុម ហ៊ុន នេះ យូរ ប៉ុណ្ណា ?",
    tooltipRedundancy2: "សូមជ្រើសរើស",
    labelRedundancy3: "តើការស្ម័គ្រចិត្តដែលលើសលប់ឬទេ?",
    tooltipRedundancy3: "សូមជ្រើសរើស",
    labelRedundancy4: "តើ អ្នក ត្រូវ បាន ជូន ដំណឹង អំពី ភាព លើសលប់ របស់ អ្នក នៅ ពេល ណា ?",
    tooltipRedundancy4: "សូមជ្រើសរើស",
    labelTestOption: "ជ្រើសរើស បាទ/ចាស ដើម្បីបន្ត",
}