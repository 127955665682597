export default {
    nonAutoApprovedLabelThankYou: "Vă mulțumim pentru cererea de rambursare.",
    nonAutoApprovedLabelReview: "Echipa noastră vă va examina cererea în următoarele două zile lucrătoare și vă va contacta cu un răspuns.",
    nonAutoApprovedLabelExperience: "Cum ne-am descurcat?",
    nonAutoApprovedLabelExperienceIntroduction: "Ne-ar aprecia foarte mult să luați timp pentru a ne rata și lăsați o revizuire ca am valoare opinia clienților noștri.",
    feedbackExcellent: "Excelent",
    feedbackGreat: "Minunat",
    feedbackAverage: "Medie",
    feedbackPoor: "Sărac",
    feedbackBad: "Rău",
    labelThankYouForFeedback: "Vă mulțumim pentru feedback-ul dumneavoastră.",
    autoApprovedMessage1: "Suntem încântați să",
    autoApprovedMessage2: "Aproba",
    autoApprovedMessage3: "rambursarea dvs.!",
    autoApprovedPayment1Day: "Plata a fost deja procesată și ar trebui să fie în contul dumneavoastră în doar 1 zi lucrătoare.",
    autoApprovedPayment2to4Days: "Plata a fost deja procesată și ar trebui să fie în contul dumneavoastră în doar 2-4 zile lucrătoare.",
    autoApprovedPayment7to10Days: "Plata a fost deja procesată și ar trebui să fie în contul dumneavoastră în doar 7-10 zile lucrătoare.",
    autoApprovedLabelExperience: "Cum ne-am descurcat?",
    autoApprovedLabelExperienceIntroduction: "Ne-ar aprecia foarte mult să luați timp pentru a ne rata și lăsați o revizuire ca am valoare opinia clienților noștri.",
    modalLabelNegative: "Feedback-ul tău este foarte important pentru noi, anunță-ne dacă vrei să luăm legătura și cu tine.",
    placeholderFeedback: "Feedback",
    messageFeedbackSubmitted: "Feedback-ul trimis",
    reviewInstructionMessage: "5 stele este excelent, 4 stele este mare, 3 stele este medie, 2 stele este slabă, 1 stea este rău."
}