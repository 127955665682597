import * as React from 'react';
import {
    Box,
    Step,
    StepLabel,
    Stepper
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useFormContext} from '../providers/FormProvider';
import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {StepsTextTheme} from '../themes/StepsTextTheme';
import useCfar from '../../hooks/useCfar';

export interface StepsProps {
    formProps: FormikProps<IFormValues>;
}

export const Steps = (props: StepsProps) => {

    const {
        formProps
    } = props;

    const {
        applicationSubmitted,
        bookingCounter,
        hidePaymentInformation,
        selectedSection,
        setSelectedSection,
    } = useFormContext();

    const {t} = useTranslation();
    const {cfarResponse} = useCfar();
    
    const getStepText = (index: number) => {
        return !cfarResponse.isCfar ?
            t(`common:formSectionNavigatorStep${index + 1}Text`) :
            t(`common:formSectionNavigatorStep${index + 1}CfarText`)
    }

    const refundRequestSectionTitle = !formProps.values.isBankDetailsRequested ?
        t('common:formSectionNavigatorStep3') :
        t('common:formSectionNavigatorStep2')

    const steps = hidePaymentInformation(formProps, cfarResponse.isCfar) ?
        [
            t('common:formSectionNavigatorStep1'),
            t('common:formSectionNavigatorStep2')
        ]
        :
        [
            t('common:formSectionNavigatorStep1'),
            t('common:formSectionNavigatorStep2'),
            refundRequestSectionTitle
        ];

    const activeSelection = (index: number) => {

        switch (selectedSection) {
            case Section.BookingAndContactInformation: {
                return index === Section.BookingAndContactInformation;
            }
            case Section.RefundRequest: {
                return index === Section.RefundRequest;
            }
            case Section.PaymentInformation: {
                return index === Section.PaymentInformation;
            }
            default: {
                return false;
            }
        }
    }

    const setActiveSection = (index: number) => {

        if (selectedSection < index) {
            return;
        }

        setSelectedSection(index);
    }

    return (
        <>
            {bookingCounter > 1 &&
                <Grid sx={{
                    top: 0,
                    zIndex: 1000,
                    width: '100%',
                    position: 'sticky',
                    backgroundColor: 'white',
                    pt: '20px',
                    pb: '20px',
                    ...(bookingCounter > 1 && !applicationSubmitted) && {
                        boxShadow: {
                            xs: '0 1px 4px #D0D0D0',
                            sm: 0,
                            md: 0
                        }
                    }
                }}>
                    <Stepper alternativeLabel
                             sx={{
                                 textAlign: 'center',
                                 '.MuiStepConnector-root': {
                                     pt: {
                                         xs: '25px',
                                         sm: '55px',
                                         md: '55px'
                                     }
                                 }
                             }}
                    >
                        {steps.map((label, index) => (
                            <Step key={label}
                                  active={activeSelection(index)}
                                  onClick={() => setActiveSection(index)}
                                  sx={{
                                      '.MuiStepIcon-root.Mui-active': {
                                          color: '#22B77E'
                                      },
                                      ...(selectedSection > index) && {
                                          '.MuiStepIcon-root:hover': {
                                              cursor: 'pointer'
                                          }
                                      },
                                      '& .MuiStepIcon-text': {
                                          display: 'none'
                                      }
                                  }}>
                                <Box sx={{
                                    color: 'grey',
                                    textTransform: 'uppercase'
                                }}>
                                    {label}
                                </Box>
                                <StepsTextTheme text={getStepText(index)}/>
                                <StepLabel/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            }
        </>
    );
}