export default {
    headingAdverseWeather: "Experimenta condiciones climáticas adversas severas que hacen que no sea seguro viajar al evento y esto ha sido ordenado por un organismo gubernamental (por ejemplo, autoridad local, policía, etc.). ¿Y tiene pruebas que lo demuestren?",
    headingAdverseWeatherDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    tooltipRefundReasonConfirm: "Sí o no",
    labelBookingCancelledByOperator: "Los billetes fueron cancelados por el Operador/Organizador.",
    labelBookingRescheduledByOperator: "La fecha fue cambiada por el Operador/Organizador y no puedo asistir a las nuevas fechas.",
    labelBookedWrongDate: "Yo o un miembro de la reserva, cometí un error al comprar los boletos y reservé la fecha incorrecta.",
    labelBookedWrongTickets: "Yo o un miembro de la reserva, cometí un error al comprar los boletos y reservé los boletos incorrectos.",
    labelBookedTooManyTickets: "Yo o un miembro de la reserva, cometí un error al comprar los boletos y reservé demasiados boletos.",
    labelCOVID19TravelRestrictions: "Yo o un miembro de la reserva, no podemos asistir debido a una prohibición de viaje COVID-19.",
    labelADWrongSelection: "Es posible que haya seleccionado la razón incorrecta para un reembolso. Revise de nuevo la lista desplegable Motivo de reembolso.",
    labelAdverseWeather1: "¿Por qué el clima adverso significó que no pudo asistir al evento?",
    tooltipAdverseWeather1: "Por favor, detalle",
    labelAdverseWeather2: "Fecha del incidente",
    tooltipAdverseWeather2: "Por favor, seleccione",
    headingArmedForces: "¿Su empleador le pregunta cuáles son las Fuerzas Armadas o los Servicios de Emergencia para entrar a trabajar cuando originalmente no estaba programado para hacerlo y esto le impide asistir al evento?",
    headingArmedForcesDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelArmedForces1: "¿Por qué fuerzas o Servicio de Emergencia está empleado?",
    tooltipArmedForces1: "Por favor, seleccione",
    labelArmedForcesOther1: "Por favor, especifique",
    tooltipArmedForcesOther1: "Por favor, especifique",
    labelArmedForces2: "Fecha del incidente",
    tooltipArmedForces2: "Por favor, seleccione",
    headingChangesToExam: "¿Las fechas de su examen han sido cambiadas por su escuela / universidad, etc.? ¿Este no es un examen fallido que debe repetir?",
    headingChangesToExamDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelExam1: "¿Cuál era la fecha original del examen?",
    tooltipExam1: "Por favor, seleccione",
    labelExam2: "¿Cuál es la nueva fecha de examen?",
    tooltipExam2: "Por favor, seleccione",
    labelExam3: "¿Por qué cambió la fecha del examen?",
    tooltipExam3: "Por favor, introduzca los detalles",
    labelExam4: "¿Reprobó el examen anterior?",
    tooltipExam4: "Sí o no",
    headingCourtSummons: "¿Ha sido citado para comparecer ante el tribunal como testigo en las mismas fechas del evento?",
    headingCourtSummonsDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelCourtSummons1: "¿Cuál es la razón de su citación judicial?",
    tooltipCourtSummons1: "Por favor, seleccione",
    labelCourtSummonsOther1: "Por favor, especifique",
    tooltipCourtSummonsOther1: "Por favor, especifique",
    labelCourtSummons2: "¿Cuándo se emitió la citación judicial?",
    tooltipCourtSummons2: "Por favor, seleccione",
    headingCOVID19Test: "¿Yo, un miembro de mi hogar o un miembro del grupo que asiste al evento hemos sido diagnosticados con COVID-19?",
    headingCOVID19TestDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelCOVID19Test1: "¿Quién tiene COVID-19?",
    tooltipCOVID19Test1: "Por favor, seleccione",
    labelCOVID19TestOther1a: "Por favor, especifique",
    labelCOVID19TestOther1b: "Especificar nombre",
    tooltipCOVID19TestOther1a: "Por favor, especifique",
    tooltipCOVID19TestOther1b: "Especificar nombre",
    labelCOVID19Test2: "¿Qué tipo de prueba de COVID-19 tuviste?",
    tooltipCOVID19Test2: "Por favor, seleccione",
    labelCOVID19TestOther2: "Por favor, especifique",
    tooltipCOVID19TestOther2: "Por favor, especifique",
    labelCOVID19Test3: "¿Cuál fue la fecha de su prueba de COVID-19?",
    tooltipCOVID19Test3: "Por favor, seleccione",
    headingDeath: "¿Ha habido una muerte de un miembro inmediato de mi familia y eso me impide asistir al evento?",
    headingDeathDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelDeath1: "¿Qué miembro de su familia ha muerto?",
    tooltipDeath1: "Por favor, seleccione",
    labelDeathOther1: "Por favor, especifique",
    tooltipDeathOther1: "Por favor, especifique",
    labelDeath2: "¿Cuándo fue la fecha de la muerte?",
    tooltipDeath2: "Por favor, seleccione",
    labelDeath3: "Nombre del difunto",
    tooltipDeath3: "Por favor, especifique",
    headingEmergency: "¿He tenido una emergencia genuina que está fuera de mi control e imprevista de la cual puedo proporcionar evidencia?",
    headingEmergencyDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelEmergency1: "Por favor, explique el tipo de emergencia?",
    tooltipEmergency1: "Por favor, detalle",
    labelEmergency2: "¿Cuándo sucedió esto?",
    tooltipEmergency2: "Por favor, seleccione",
    labelEmergency3: "¿Por qué esta situación te impide asistir al evento?",
    tooltipEmergency3: "Por favor, detalle",
    labelEmergency4: "¿Tiene evidencia de esta emergencia?",
    tooltipEmergency4: "Sí o no",
    headingHomeEmergency: "¿Tengo una emergencia en el hogar que es robo, inundación, incendio, vandalismo, abuso doméstico que me impide asistir al evento?",
    headingHomeEmergencyDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelHomeEmergency1: "¿Qué es la emergencia en el hogar?",
    tooltipHomeEmergency1: "Por favor, seleccione",
    labelHomeEmergencyOther1: "Por favor, especifique",
    tooltipHomeEmergencyOther1: "Por favor, especifique",
    labelHomeEmergency2: "¿Cuándo sucedió esto?",
    tooltipHomeEmergency2: "Por favor, seleccione",
    labelHomeEmergency3: "¿Por qué te impidió asistir al evento?",
    tooltipHomeEmergency3: "Por favor, detalle",
    headingInjury: "¿Yo, un miembro de mi hogar o un miembro del grupo que asiste al evento hemos sido diagnosticado con una condición médica o lesión que ahora nos impide ir al evento?",
    headingInjuryDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelInjury1: "¿Cuál es la naturaleza de la enfermedad/lesión/accidente?",
    tooltipInjury1: "Por favor, detalle",
    labelInjury2: "¿Cuándo sucedió esto?",
    tooltipInjury2: "Por favor, seleccione",
    labelInjury3: "¿Ha asistido a un centro médico por esta enfermedad/lesión?",
    tooltipInjury3: "Sí o no",
    labelInjury4: "¿Era esta una condición médica preexistente?",
    tooltipInjury4: "Sí o no",
    labelInjury5: "¿Cuándo se diagnosticó esto por primera vez?",
    tooltipInjury5: "Por favor, seleccione",
    labelInjury6: "¿Por qué significa esto que no puede asistir al evento?",
    tooltipInjury6: "Por favor, detalle",
    labelInjury7: "¿Quién ha sufrido la enfermedad/lesión?",
    tooltipInjury7: "Por favor, seleccione",
    headingJuryService: "¿Yo o un miembro de la reserva hemos sido llamados al Servicio de Jurado?",
    headingJuryServiceDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelJuryService1: "¿Cuándo se le notificó el Servicio de Jurado?",
    tooltipJuryService1: "Por favor, seleccione",
    headingMechanicalBreakdown: "¿Nuestro vehículo ha tenido una avería mecánica o ha estado en un accidente y esto nos impide asistir al evento?",
    headingMechanicalBreakdownDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelMechanicalBreakdown1: "¿Qué pasó con el vehículo?",
    tooltipMechanicalBreakdown1: "Por favor, seleccione",
    labelMechanicalBreakdown2: "¿Cuándo sucedió esto?",
    tooltipMechanicalBreakdown2: "Por favor, seleccione",
    labelMechanicalBreakdown3: "¿Por qué no pudo tomar transporte alternativo?",
    tooltipMechanicalBreakdown3: "Por favor, detalle",
    headingPregnancyComplication: "¿Yo o un miembro de la reserva tenemos complicaciones del embarazo que han sido diagnosticadas por un profesional médico y, por lo tanto, impiden la asistencia al evento?",
    headingPregnancyComplicationDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelPregnancyComplication1: "¿Cuál es la naturaleza de la complicación del embarazo?",
    tooltipPregnancyComplication1: "Por favor, detalle",
    labelPregnancyComplication2: "¿Cuándo sucedió esto?",
    tooltipPregnancyComplication2: "Por favor, seleccione",
    labelPregnancyComplication3: "¿Buscó atención médica?",
    tooltipPregnancyComplication3: "Sí o no",
    labelPregnancyComplication4: "¿Por qué significa esto que no puede asistir al evento?",
    tooltipPregnancyComplication4: "Por favor, detalle",
    headingPublicTransportFailure: "El método de transporte público (tren, autobús, tranvía, barco, etc.) falló inesperadamente, lo que significa que no había otra forma de viajar a este evento?",
    headingPublicTransportFailureDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelPublicTransportFailure1: "¿Qué tipo de transporte público planeabas usar?",
    tooltipPublicTransportFailure1: "Por favor, seleccione",
    labelPublicTransportFailure2: "¿Cuándo planeaste viajar?",
    tooltipPublicTransportFailure2: "Por favor, seleccione",
    labelPublicTransportFailure3: "¿¿Qué pasó??",
    tooltipPublicTransportFailure3: "Por favor, detalle",
    labelPublicTransportFailure4: "¿Por qué no podrías tomar un transporte alternativo?",
    tooltipPublicTransportFailure4: "Por favor, detalle",
    headingScheduledAirlineFailure: "¿Mi vuelo reservado con anticipación se canceló inesperadamente y no pude encontrar una alternativa adecuada, lo que significa que no pude asistir al evento?",
    headingScheduledAirlineFailureDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelScheduledAirlineFailure1: "Nombre de la aerolínea",
    tooltipScheduledAirlineFailure1: "Nombre de la aerolínea",
    labelScheduledAirlineFailure2: "Número de vuelo",
    tooltipScheduledAirlineFailure2: "Número de vuelo",
    labelScheduledAirlineFailure3: "Viajar desde",
    tooltipScheduledAirlineFailure3: "Viajar desde",
    labelScheduledAirlineFailure4: "Viajar a",
    tooltipScheduledAirlineFailure4: "Viajar a",
    labelScheduledAirlineFailure5: "¿Fecha del incidente?",
    tooltipScheduledAirlineFailure5: "Por favor, seleccione",
    labelScheduledAirlineFailure6: "¿Por qué no pudo tomar un vuelo alternativo?",
    tooltipScheduledAirlineFailure6: "Por favor, detalle",
    headingTheftOfDocuments: "Mis documentos fueron robados y no fue posible organizar el reemplazo (s) dentro del plazo necesario, por lo que no puedo asistir al evento",
    headingTheftOfDocumentsDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelTheftOfDocuments1: "¿Qué tipo de documento fue robado?",
    tooltipTheftOfDocuments1: "Por favor, seleccione",
    labelTheftOfDocuments2: "¿Cuándo sucedió esto?",
    tooltipTheftOfDocuments2: "Por favor, seleccione",
    labelTheftOfDocuments3: "¿Intentó que se volvieran a emitir los documentos?",
    tooltipTheftOfDocuments3: "Sí o no",
    headingWorkRelocation: "¿Mi empleador me ha solicitado que cambie la ubicación de mi trabajo durante al menos tres meses y la distancia es significativa, lo que significa que ahora no puedo asistir al evento?",
    headingWorkRelocationDecline: "No debe continuar con esta solicitud, ya que lo más probable es que se rechace el reembolso.",
    labelWorkRelocation1: "¿Se trata de una reubicación temporal o permanente de su trabajo?",
    tooltipWorkRelocation1: "Por favor, seleccione",
    labelWorkRelocation2: "¿Cuánto tiempo estarás en la nueva dirección?",
    tooltipWorkRelocation2: "Por favor, detalle",
    labelWorkRelocation3: "¿Cuál es su dirección de trabajo actual?",
    tooltipWorkRelocation3: "Por favor, detalle",
    labelWorkRelocation4: "¿Cuál es tu nueva dirección de trabajo?",
    tooltipWorkRelocation4: "Por favor, detalle",
    labelWorkRelocation5: "¿Cuándo se le notificó esta reubicación?",
    tooltipWorkRelocation5: "Por favor, seleccione",
    headingRedundancy: "¿Mi empleador o un miembro de la reserva ha sido despedido, lo que significa que ya no puedo asistir a la reserva?",
    headingRedundancyDecline: "No debe continuar con esta solicitud ya que lo más probable es que el reembolso sea rechazado.",
    labelRedundancy1: "¿Tipo de empleo?",
    tooltipRedundancy1: "Por favor seleccione",
    labelRedundancy2: "¿Cuánto tiempo ha estado empleado por esta empresa?",
    tooltipRedundancy2: "Por favor seleccione",
    labelRedundancy3: "¿Fue voluntario el despido?",
    tooltipRedundancy3: "Por favor seleccione",
    labelRedundancy4: "¿Cuándo se le notificó su despido?",
    tooltipRedundancy4: "Por favor seleccione",
    labelTestOption: "Seleccione Sí para continuar",
}