import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CloseButton} from '../buttons/CloseButton';
import {ModalHolder} from './ModalHolder';

interface EvidenceReminderModalProps {
    close: () => void;
    showEvidenceReminder: boolean;
}

export const EvidenceReminderModal = (props: EvidenceReminderModalProps) => {

    const {
        close,
        showEvidenceReminder
    } = props;

    const {t} = useTranslation();

    const Content = () => {
        return (
            <>
                <Box pb={2}>
                    {t('form:validationEvidenceFilesPopup')}
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonClose')}
                        handleOnClick={close}
                    />
                </Box>
            </>
        );
    }

    return (
        <ModalHolder
            showModal={showEvidenceReminder}
            content={<Content/>}
            handleClose={close}
            width='sm'
        />
    );
}