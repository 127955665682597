export default {
    messageUnableToMakePaymentToCurrency: "Unfortunately we are unable to retrieve bank details for the selected currency. Please make sure Currency and Home Country are selected on",
    messageOnlyBankAccountsAccepted: "Please note: We can only make payments to Bank Accounts at this time (Credit and Debit Cards not accepted)",
    labelAccountName: "Account Holder Name",
    labelBankAccountName: "Full Name of Account Holder",
    labelBankCardHolderName: "Card Holder Name",
    validationBankAccountName: "Full Name of Account Holder is required",
    labelBankCountry: "Bank Country",
    labelBankName: "Bank Name",
    labelPaymentType: "Payment Type",
    labelInstitutionNumber: "Institution Number",
    labelBankAddress: "Bank Address",
    labelBankAddress2: "Bank Address",
    labelBankCity: "Bank City",
    labelBankState: "Bank State",
    labelBankAccountNumber: "Bank Account Number",
    labelBankAccountNumberCBU: "Account Number CBU",
    labelBankAccountNumberCCI: "Account Number CCI",
    labelBankAccountNumberZAR: "Bank Account Number (7-11 digits)",
    labelCardNumber: "Card Number",
    labelUnionpay: "UnionPay Card Number",
    labelAlipay: "Alipay ID (mobile number or email)",
    labelBankAccountType: "Account Type",
    optionBankAccountTypeCURRENT: "Current Account",
    optionBankAccountTypeCHECKING: "Checking Account",
    optionBankAccountTypeSAVING: "Savings Account",
    optionBankAccountTypeSAVINGS: "Savings Account",
    optionBankAccountTypeCUENTA_VISTA: "Master Account",
    optionBankAccountType1: "Checking Account",
    optionBankAccountType2: "Savings Account",
    optionBankAccountType3: "Master Account",
    optionBankAccountType4: "Vista Account",
    optionBankAccountType5: "RUT Account",
    labelSortCode: "Sort Code",
    labelRoutingCode: "Routing Code",
    labelBankRoutingCodeNationalID: "Bank Routing Code / National ID",
    labelBankTransitCodeSwift: "Transit Number",
    labelCNAPSCode: "Routing Code / CNAPS Code (China National Advanced Payment System Code)",
    labelABAFedwireRoutingCode: "ACH Routing Number",
    labelIFSCCode: "IFSC Code",
    labelBankCode: "Bank Code",
    labelBIKCode: "BIK Code",
    labelBranchCode: "Branch Code",
    labelBranchCodeUniversal: "Universal 6 digit branch code",
    labelRemittanceLine3: "Beneficiary Contact Name and Telephone Number",
    labelBeneficiaryContactNameandTelephoneNumber: "Beneficiary Contact Name and Telephone Number",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Beneficiary Date of Birth and Place of Birth",
    labelRemittanceLine4: "Individual Tax Number CUIL (9-12 digits)",
    labelCUILNumber: "Tax Id: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Tax ID RFC 13 digits or CURP 18 digits",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individual Tax ID CPF Cadastro de Pessoa Física (11-14 digits), Branch Code",
    labelCFPNumber: "CPF Number (11-14 digits)",
    labelBranchNameorBranchCode7digits: "Branch Name or Branch Code (7 digits)",
    labelCedulaJuridica910digitsTaxID: "Legal Certificate (9-10 digits Tax ID)",
    labelTaxIDRUT89digits: "Tax ID RUT (8-9 digits)",
    labelJob: "Job Title",
    labelDateOfBirth: "Date of Birth",
    labelNationality: "Nationality",
    labelDocumentType: "Document Type",
    labelDocumentNumber: "Document Number",
    labelRutNumber: "RUT number (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (Tax ID Individual 8-11 digits)",
    labelCIIndividualTaxIDBranchLocation: "CI (Individual Tax ID), Branch Location",
    labelTaxIDRUCorCI: "Tax ID (RUC or CI)",
    labelSortCodeHelp: "Help?",
    labelBranchCodeHelp: "Help?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Help?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Help?",
    labelRoutingCodeBSB: "Routing Code/BSB",
    labelBSB: "Bank State Branch (BSB)",
    labelSKNCode: "SKN Code",
    labelAcceptanceFlights: "I/we accept that if the refund application is successful the booking including all flights, and ancillary products will be cancelled.",
    labelAcceptanceTickets: "I/we accept that if the refund application is successful the booking including all tickets, and ancillary products will be cancelled.",
    labelTermsAndConditions1: "I/we have agreed to the",
    labelTermsAndConditions2: "Terms and Conditions",
    labelFraudWarning1: "I believe that the facts provided in this refund application form are genuine and true. I understand that any false information or evidence may be considered a criminal act and be passed on to police or other law enforcement agencies to pursue.",
    labelFraudWarning2: "Where we suspect fraud in relation to supporting documentation this matter will immediately be referred to the local police authorities. I accept and understand my details will be passed to these authorities should my application be considered fraudulent and that may become a criminal investigation.",
    validationTermsAndConditions: "Terms and Conditions must be agreed to",
    messageFailbankName: "Bank Name cannot be blank",
    messageFailcustomerName: "Customer Name cannot be blank",
    messageFailbankAccountNumber: "Required Field",
    messageFailbankAccountNumberFormat: "Must be IBAN format",
    messageFailsortCode: "Required Field",
    messageFailbankSwiftBic: "Beneficiary SWIFT BIC cannot be blank",
    messageFailbankSwiftBicFormat: "Must be SWIFT format",
    messageFailbeneficiaryAddress1: "Address 1 cannot be blank",
    messageFailbeneficiaryCity: "City cannot be blank",
    messageFailbeneficiaryPostCode: "Postal Code cannot be blank",
    messageFailremittanceLine3: "Required Field",
    messageFailremittanceLine4: "Required Field",
    modalHeading: "Confirm Details",
    modalLabelPleaseConfirmYourDetails: "Please confirm your details",
    modalValidationPleaseConfirm: "Please confirm",
    modalValidationMustMatch: "Must match previous entry",
    tooltipBankAccountName: "Your name as it appears on your bank account/bank statements/bank card.",
    tooltipBankAccountNameRecipient: "The recipient first and last name. Both first and last name must have more than one character.",
    tooltipBankCountry: "The country in which your bank is located.",
    tooltipBankName: "The name of your bank.",
    tooltipPaymentType: "The type of payment you require.",
    tooltipInstitutionNumber: "This is 3 digits and usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipBankAccountNumber: "Your individual account number. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipBankAccountNumberCBU: "A 22 digit number used to identify your account. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipBankAccountNumberCCI: "A 20 digit number used to identify your account. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipBankAccountNumberKRW: "An 11–16 digit number used to identify your account. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipCardNumber: "Your card number. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipUnionpay: "Your UnionPay card number.",
    tooltipAlipay: "Your mobile number or email address associated with your Alipay account.",
    tooltipBankAccountType: "This is either Checking or Savings.",
    tooltipSortCode: "This identifies the branch of your bank where your account is held. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipRoutingCode: "This identifies the branch of your bank where your account is held. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipBeneficiarySWIFTBIC: "Consists of 8-11 characters used to identify a bank in an international transaction. Usually found on your monthly statement or if you are still unsure, ask your bank.",
    tooltipBSB: "A Bank State Branch (BSB) number is an Australian sort code which has 6 digits. Usually found on your monthly statement or if you are still unsure, ask your bank.",
    tooltipRoutingCodeBSB: "This identifies the branch of your bank where your account is held. Usually found on your monthly statement or bank card or if you are still unsure, ask your bank.",
    tooltipABAFedwireRoutingCode: "A 9 digit number used to identify banks in America. Usually found on your monthly statement or if you are still unsure, ask your bank.",
    tooltipIFSCCode: "Usually this code can be found on your monthly statement or on your passbook or if you are still unsure, ask your bank.",
    tooltipTermsAndConditions: "By accepting the terms and conditions you confirm that you’ve read and agree to our Terms and Conditions.",
    tooltipIBAN: "Your International Bank Account Number, which you can use when making or receiving international payments. Usually found on your monthly statement or if you are still unsure, ask your bank.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Beneficiary Contact Name and Telephone Number.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Beneficiary Date of Birth and Place of Birth.",
    tooltipBankRoutingCodeNationalID: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankTransitCodeSwift: "This is 5 digits and can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankAddress: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankAddress2: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankCity: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankState: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBranchName: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBankCode: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBIKCode: "9 digits to identify your bank.",
    tooltipBranchCode: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBranchCodeUniversal: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipCNAPSCode: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipRemittanceLine4: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipCUILNumber: "This is an 11 digit number, consisting two digits, hyphen, nine digits, and a one digit checksum.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipCFPNumber: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipBranchNameorBranchCode7digits: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipCedulaJuridica910digitsTaxID: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipTaxIDRUT89digits: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipJob: "Your current occupation.",
    tooltipDateOfBirth: "Your date of birth.",
    tooltipNationality: "Your nationality.",
    tooltipDocumentType: "The document type required.",
    tooltipDocumentNumber: "This is the document number associated with your document type selection.",
    tooltipRutNumber: "Your RUT number (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipCIIndividualTaxIDBranchLocation: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    tooltipTaxIDRUCorCI: "Can usually be found on your monthly bank statement or if you are still unsure, ask your bank.",
    errorCPFCode: "At the moment we only accept CPF as a PIX key."
};