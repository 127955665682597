export default {
    nonAutoApprovedLabelThankYou: "សូមអរគុណចំពោះកម្មវិធី Refund របស់អ្នក។",
    nonAutoApprovedLabelReview: "ក្រុម របស់ យើង នឹង ពិនិត្យ មើល កម្មវិធី របស់ អ្នក ក្នុង រយៈ ពេល ពីរ ថ្ងៃ ខាង មុខ នេះ ហើយ ត្រឡប់ ទៅ រក អ្នក វិញ ដោយ ឆ្លើយ តប ។",
    nonAutoApprovedLabelExperience: "តើ យើង បាន ធ្វើ យ៉ាង ដូចម្ដេច ?",
    nonAutoApprovedLabelExperienceIntroduction: "យើង ពិត ជា មាន អំណរ គុណ ដែល អ្នក ចំណាយ ពេល ដើម្បី វាយ តម្លៃ យើង ហើយ ទុក ការ ពិនិត្យ ឡើង វិញ នៅ ពេល ដែល យើង ឲ្យ តម្លៃ លើ គំនិត របស់ អតិថិ ជន របស់ យើង ។",
    feedbackExcellent: "វិសេសវិសាលបំផុត",
    feedbackGreat: "អស្ចារ្យណាស់",
    feedbackAverage: "មធ្យម",
    feedbackPoor: "អ្នកក្រ",
    feedbackBad: "អាក្រក់",
    labelThankYouForFeedback: "សូមអរគុណចំពោះមតិយោបល់របស់អ្នក។",
    autoApprovedMessage1: "យើងរីករាយនឹង",
    autoApprovedMessage2: "អនុម័ត",
    autoApprovedMessage3: "សងវិញ!",
    autoApprovedPayment1Day: "ការ បង់សង របស់ អ្នក ត្រូវ បាន ដំណើរ ការ រួច ហើយ ហើយ ហើយ គួរ តែ ស្ថិត នៅ ក្នុង គណនី របស់ អ្នក ក្នុង ថ្ងៃ អាជីវកម្ម តែ ១ ប៉ុណ្ណោះ ។",
    autoApprovedPayment2to4Days: "ការ បង់ ប្រាក់ របស់ អ្នក ត្រូវ បាន ដំណើរ ការ រួច ហើយ ហើយ ហើយ គួរ តែ ស្ថិត នៅ ក្នុង គណនី របស់ អ្នក ក្នុង រយៈ ពេល តែ 2-4 ថ្ងៃ អាជីវកម្ម ប៉ុណ្ណោះ ។",
    autoApprovedPayment7to10Days: "ការ បង់ ប្រាក់ របស់ អ្នក ត្រូវ បាន ដំណើរ ការ រួច ហើយ ហើយ ហើយ គួរ តែ ស្ថិត នៅ ក្នុង គណនី របស់ អ្នក ក្នុង រយៈ ពេល តែ 7-10 ថ្ងៃ អាជីវកម្ម ប៉ុណ្ណោះ ។",
    autoApprovedLabelExperience: "តើ យើង បាន ធ្វើ យ៉ាង ដូចម្ដេច ?",
    autoApprovedLabelExperienceIntroduction: "យើង ពិត ជា មាន អំណរ គុណ ដែល អ្នក ចំណាយ ពេល ដើម្បី វាយ តម្លៃ យើង ហើយ ទុក ការ ពិនិត្យ ឡើង វិញ នៅ ពេល ដែល យើង ឲ្យ តម្លៃ លើ គំនិត របស់ អតិថិ ជន របស់ យើង ។",
    modalLabelNegative: "មតិ យោបល់ របស់ អ្នក ពិត ជា សំខាន់ ចំពោះ យើង សូម ប្រាប់ យើង ថា តើ អ្នក ក៏ ចង់ ឲ្យ យើង ទាក់ ទង ជាមួយ អ្នក ដែរ ឬ ទេ ។",
    placeholderFeedback: "បញ្ចេញមតិ",
    messageFeedbackSubmitted: "មតិយោបល់ដែលបានដាក់ស្នើ",
    reviewInstructionMessage: "ផ្កាយ ៥ គឺ ល្អ ប្រសើរ ៤ ផ្កាយ គឺ អស្ចារ្យ ៣ ផ្កាយ ជា មធ្យម ២ តារា អន់ ១ ផ្កាយ គឺ មិន ល្អ ទេ។"
}