export default {
    labelBookingCancelledPostponedHeading: "您的预订被取消或推迟了吗？阅读更多",
    labelBookingCancelledPostponedText1: "点击",
    labelBookingCancelledPostponedText2: "有关更多信息。",
    labelNewRefundHeading: "新的退款申请？",
    labelNewRefundText:"在这里输入您的预订参考...",
    placeholderBookingReference:"预订参考",
    validationBookingReference:"需要预订参考",
    validationEmailAsBookingReference: "电子邮件地址不被接受作为预订参考",
    buttonFindBooking:"查找预订",
    labelEmailHeading:"已经开始？",
    labelEmailText:"发送提醒电子邮件以检索您的应用程序。",
    placeholderEmail:"电子邮件",
    buttonEmailReminder:"电子邮件提醒",
    labelCustomerName:"客户名称",
    validationCustomerName:"必须输入客户名称",
    labelTypeOfBooking:"预订类型",
    validationTypeOfBooking:"保留类型为必填项",
    labelEventName:"事件名称",
    labelVenueName:"地名",
    labelFlightNumber:"航班号",
    labelOriginAirport:"起源机场",
    labelHotelName:"住宿名称",
    labelHotelLocation:"住宿地点",
    labelCurrency:"货币",
    validationCurrency: "需要货币",
    validationCountryCurrency: "选择的货币必须是您本国的货币",
    validationOfflinePayment: "我们无法处理您选择的货币和国家/地区的退款，请选择替代方案",
    labelBookingValue:"保留值",
    validationBookingValue:"需要保留值",
    validationBookingValueAmount:"必须大于0",
    labelDateOfPurchase:"购买日期",
    validationDateOfPurchase:"需要购买日期",
    labelEmailAddress:"电子邮件地址",
    validationEmailAddress:"必须提供电子邮件地址",
    validationEmailAddressFormat:"电子邮件格式不正确",
    labelConfirmEmailAddress:"确认您的电子邮件地址",
    validationConfirmEmailAddressFormat:"电子邮件格式不正确",
    validationConfirmEmailAddress:"需要确认电子邮件地址",
    validationConfirmEmailAddressMatch:"电子邮件地址不匹配",
    validationEmailDomain: "不接受此电子邮件提供商，请使用其他电子邮件提供商",
    validationDateOfEvent:"需要事件的日期",
    labelHomeCountry:"本国",
    validationHomeCountry:"需要原产国",
    labelContactNumberCountryCode:"联系电话国家/ 地区代码",
    validationContactNumberCountryCode:"必须输入国家 / 地区代码",
    labelContactNumber: "联系电话",
    validationContactNumber: "必须提供联系电话",
    labelAddressLine1:"地址1",
    labelAddressLine2: "地址行2",
    labelCity:"城市",
    labelZipCode:"邮政编码",
    labelRegion:"区域",
    validationRegion: "必须输入区域",
    labelNumberOfTicketsToRefund: "要退款的票数",
    validationNumberOfTicketsToRefund: "需要退款的票数",
    validationNumberOfTicketsToRefundValue:"必须大于0",
    labelCostPerTicket: "每张票的费用",
    validationCostPerTicket: "每张票的费用是强制性的",
    validationCostPerTicketValue: "必须大于0",
    labelTotalRefundAmount:"退款总额",
    validationTotalRefundAmount:"需要退款总额",
    validationTotalRefundAmountValue: "必须大于0",
    validationTotalRefundGreaterThanBookingValue: "总退款金额不能大于上述预订金额",
    labelBookingConfirmationFiles:"下载预订确认书",
    validationBookingConfirmationFiles: "附加至少一个文件或单击“保存”，然后在拥有文档后返回",
    validationEvidenceFilesPopup: "提交前需要上传证据。如果您现在没有证据，请保存申请并在您拥有后返回。我们接受电子副本、扫描仪副本和照片。",
    validationBookingConfirmationFilesPopup: "提交前需上传预订确认信息。如果您现在没有预订确认，请保存申请并在获得后返回。我们接受电子副本、扫描仪副本和照片。",
    alertUploadDocuments: "请上传电子邮件中详述的相关文件，然后继续执行步骤 3。",
    labelRefundReasonCategory:"退款原因",
    validationRefundReasonCategory:"需要退款的原因",
    labelDateOfIncident:"事件日期",
    labelAdditionalComments: "附加评论",
    tooltipAdditionalComments: "附加评论",
    validationAdditionalComments: "需要补充意见",
    labelUnableToNotifyWithinTimeLimit: "无法在60天内通知",
    labelReasonForNotificationOutsideTimeLimit: "超出时间限制的通知说明",
    validationReasonForNotificationOutsideTimeLimit:"需要60天后通知的理由",
    labelRefundReasonFiles:"下载证明",
    validationRefundReasonFiles: "附加至少一个文件或单击“保存”，然后在拥有文档后返回",
    validationTextScript: "必须与拉丁文字匹配",
    optionTypeOfBooking1:"一个事件",
    optionTypeOfBooking2:"游览",
    optionTypeOfBooking3:"飞行",
    optionTypeOfBooking4:"住宿",
    optionTypeOfBooking5:"传输",
    optionTypeOfBooking6: "停车",
    optionTypeOfBooking7: "其他旅行",
    labelEventTypeDate:"事件的日期",
    labelEventTypeTour:"游览日期",
    labelEventTypeFlight:"飞行日期",
    labelEventTypeCheckIn:"到达日期",
    labelEventTypeTransfer:"转让日期",
    labelEventTypeParking: "停车日期",
    labelEventTypeOtherTravel: "旅行日期",
    optionRefundReason11:"恶劣天气条件",
    optionRefundReason10:"召回武装部队和紧急服务",
    optionRefundReason15: "考试日期更改",
    optionRefundReason18: "法院传票",
    optionRefundReason17: "2019新冠肺炎旅行限制",
    optionRefundReason19: "COVID-19 阳性测试",
    optionRefundReason20: "运营商/组织者取消预订",
    optionRefundReason21: "运营商/组织者重新安排预订",
    optionRefundReason22: "订错日期",
    optionRefundReason23: "订错票",
    optionRefundReason24: "订了太多票",
    optionRefundReason4:"死亡",
    optionRefundReason16:"紧急情况",
    optionRefundReason14:"政府禁止旅行",
    optionRefundReason9:"家庭紧急情况",
    optionRefundReason1:"疾病 / 伤害 / 事故",
    optionRefundReason8:"陪审团服务",
    optionRefundReason7:"机械故障",
    optionRefundReason2:"先前存在的医疗状况",
    optionRefundReason3:"妊娠并发症",
    optionRefundReason5:"公共运输失败",
    optionRefundReason6:"航班中断",
    optionRefundReason13:"文件被盗",
    optionRefundReason12:"工作搬迁",
    optionRefundReason25: "工作场所冗余",
    optionRefundReason26: "测试选项",
    optionRefundReasonConfirm1: "不",
    optionRefundReasonConfirm2: "是的",
    optionRefundReasonArmedForces1: "军队",
    optionRefundReasonArmedForces2: "海军",
    optionRefundReasonArmedForces3: "空军",
    optionRefundReasonArmedForces4: "特种部队",
    optionRefundReasonArmedForces5: "海军陆战队",
    optionRefundReasonArmedForces6: "火灾",
    optionRefundReasonArmedForces7: "救护车",
    optionRefundReasonArmedForces8: "医生/护士",
    optionRefundReasonArmedForces9: "牙医",
    optionRefundReasonArmedForces10: "其他",
    optionRefundReasonCourtSummons1: "刑事诉讼",
    optionRefundReasonCourtSummons2: "民事法律案件",
    optionRefundReasonCourtSummons3: "控方证人",
    optionRefundReasonCourtSummons4: "民事案件中的证人",
    optionRefundReasonCourtSummons5: "原告",
    optionRefundReasonCourtSummons6: "被告",
    optionRefundReasonCourtSummons7: "其他",
    optionRefundReasonDeath1: "老公",
    optionRefundReasonDeath2: "老婆",
    optionRefundReasonDeath3: "声音",
    optionRefundReasonDeath4: "女儿",
    optionRefundReasonDeath5: "爸爸",
    optionRefundReasonDeath6: "妈妈",
    optionRefundReasonDeath7: "哥哥",
    optionRefundReasonDeath8: "姐姐",
    optionRefundReasonDeath9: "爷爷",
    optionRefundReasonDeath10: "奶奶",
    optionRefundReasonDeath11: "其他",
    optionRefundReasonHomeEmergency1: "火灾",
    optionRefundReasonHomeEmergency2: "洪水",
    optionRefundReasonHomeEmergency3: "抢劫",
    optionRefundReasonHomeEmergency4: "破坏",
    optionRefundReasonHomeEmergency5: "家庭虐待",
    optionRefundReasonHomeEmergency6: "绑架",
    optionRefundReasonHomeEmergency7: "其他",
    optionRefundReasonCOVID19Person1: "本人",
    optionRefundReasonCOVID19Person2: "预订中的其他人",
    optionRefundReasonCOVID19Person3: "我家中的某个人",
    optionRefundReasonCOVID19Person4: "其他",
    optionRefundReasonCOVID19TestType1: "聚合酶链反应检测",
    optionRefundReasonCOVID19TestType2: "大鼠测试",
    optionRefundReasonCOVID19TestType3: "侧向流测试",
    optionRefundReasonCOVID19TestType4: "自我管理测试",
    optionRefundReasonCOVID19TestType5: "没有测试，但我有症状",
    optionRefundReasonCOVID19TestType6: "其他",
    optionRefundReasonBreakdown1: "故障",
    optionRefundReasonBreakdown2: "事故",
    optionRefundReasonBreakdown3: "火灾",
    optionRefundReasonBreakdown4: "盗窃",
    optionRefundReasonPublicTransportType1: "火车",
    optionRefundReasonPublicTransportType2: "总线",
    optionRefundReasonPublicTransportType3: "电车",
    optionRefundReasonPublicTransportType4: "船",
    optionRefundReasonDocumentType1: "护照",
    optionRefundReasonDocumentType2: "旅行许可证",
    optionRefundReasonDocumentType3: "国民身份证",
    optionRefundReasonDocumentType4: "票",
    optionRefundReasonWorkRelocationType1: "临时",
    optionRefundReasonWorkRelocationType2: "永久",
    optionRefundReasonWorkRelocationTemporary1: "0-3个月",
    optionRefundReasonWorkRelocationTemporary2: "3-6个月",
    optionRefundReasonWorkRelocationTemporary3: "6个月以上",
    optionRefundReasonBookingRelationshipType1: "预订中的某人",
    optionRefundReasonBookingRelationshipType2: "预订中某人的直系亲属",
    optionEmploymentType1: "全职",
    optionEmploymentType2: "兼职",
    optionEmploymentType3: "合同",
    optionEmploymentLengthType1: "0-1 岁",
    optionEmploymentLengthType2: "1-2 岁",
    optionEmploymentLengthType3: "2年以上",
    textRefundEvidenceHeading:"请提供以下证据:",
    textRefundEvidence1: "航空公司确认",
    textRefundEvidence2: "任何适当的支持证据",
    textRefundEvidence3: "故障服务呼叫确认",
    textRefundEvidence4: "死亡证明书",
    textRefundEvidence5: "航空公司确认取消",
    textRefundEvidence6: "气象报告",
    textRefundEvidence7: "预订代理的信函确认无法重新出票",
    textRefundEvidence8: "法院信函",
    textRefundEvidence9: "医疗证明/医生证明",
    textRefundEvidenceDescription9: "确认疾病或受伤的详细信息，即首次发生的日期，以防止您参加预订。",
    textRefundEvidence10: "雇主信函确认",
    textRefundEvidence11: "保险索赔信函",
    textRefundEvidence12: "雇主信函",
    textRefundEvidence13: "国家政府网站确认",
    textRefundEvidence14: "考试变更通知",
    textRefundEvidence15: "警方报告",
    textRefundEvidence16: "警方报告和参考编号",
    textRefundEvidence17: "传输提供商确认",
    textRefundEvidence18: "旅行警告",
    textRefundEvidence20: "新冠肺炎检测证书",
    textRefundEvidence24: "政府隔离通知",
    textRefundEvidence25: "指挥官的信",
    textRefundEvidence26: "保险公司的信函",
    textRefundEvidence27: "地址证明",
    textRefundEvidence28: "与死者关系的证明",
    textRefundEvidence29: "与受伤/病人的关系证明",
    textRefundEvidence30: "工资单",
    tooltipBookingReference: "要识别您的预订参考号，请检查您的预订确认信息并查看有关我们的扩展服务的部分。",
    tooltipCustomerName: "用于预订的名字和姓氏。",
    tooltipTypeOfBooking: "您想要退款的预订类型。",
    tooltipEventName: "事件的名称。",
    tooltipVenueName: "场地名称。",
    tooltipFlightNumber: "航班号。",
    tooltipOriginAirport: "出发机场。",
    tooltipHotelName: "住宿名称。",
    tooltipHotelLocation: "住宿的位置。",
    tooltipCurrency: "用于支付预订的货币。",
    tooltipBookingValue: "您为预订支付的总价。",
    tooltipDateOfPurchase: "预订的日期。",
    tooltipEmailAddress: "请使用有效的电子邮件地址。在退款申请过程中，我们将使用此电子邮件进行通信。",
    tooltipConfirmEmailAddress: "使用与上述相同的电子邮件地址，用于验证。",
    tooltipHomeCountry: "这将帮助我们在最后一步显示正确的银行信息。",
    tooltipContactNumberCountryCode: "联系电话国家/地区代码。",
    tooltipContactNumber: "请使用有效号码。我们在退款申请过程中将其用于通信。",
    tooltipAddressLine1: "请注意，验证银行帐户时将使用您的地址。",
    tooltipAddressLine2: "地址的第二行。",
    tooltipCity: "您的居住城市的详细信息可用于验证银行详细信息。",
    tooltipZipCode: "邮政编码/邮政编码。",
    tooltipRegion: "您所在城市的地区，详细信息可用于验证银行详细信息。",
    tooltipNumberOfTicketsToRefund: "您想退款的票数。",
    tooltipTotalRefundAmount: "这不能超过总预订价值，否则将无法提交申请。",
    tooltipBookingConfirmationFiles: "您可以在电子邮件中找到完整预订确认的屏幕截图/附件。",
    tooltipRefundReasonCategory: "请选择退款原因。",
    tooltipDateOfIncident: "影响您的预订的事件发生的日期。",
    tooltipRefundExplanation: "请提供尽可能多的详细信息以支持退款要求。",
    tooltipUnableToNotifyWithinTimeLimit: "无法在60天内通知。",
    tooltipReasonForNotificationOutsideTimeLimit: "超出时间限制的通知说明。",
    tooltipRefundReasonFiles: "请上传任何所需的文件。",
    tooltipEventTypeDate: "实际事件/预订的日期。",
    tooltipEventTypeTour: "实际游览的日期。",
    tooltipEventTypeFlight: "实际航班的日期。",
    tooltipEventTypeCheckIn: "实际入住日期。",
    tooltipEventTypeTransfer: "实际转帐日期。",
    tooltipEventTypeParking: "实际停车的日期。",
    tooltipEventTypeOtherTravel: "实际行程的开始日期。"
};