export default {
    labelBookingCancelledPostponedHeading: "예약이 취소되거나 연기되었나요? 더 읽어보세요",
    labelBookingCancelledPostponedText1: "더 많은 정보를 위해",
    labelBookingCancelledPostponedText2: "를 클릭하십시오.",
    labelNewRefundHeading: "새로운 환불을 신청하십니까?",
    labelNewRefundText: "예약 참조 번호를 여기에 입력하세요…",
    placeholderBookingReference: "예약 참조 번호",
    validationBookingReference: "예약 참조 번호가 필요합니다",
    validationEmailAsBookingReference: "이메일 주소는 예약 번호로 허용되지 않습니다.",
    buttonFindBooking: "예약 찾기",
    labelEmailHeading: "진행중인 환불 요청이 있습니까?",
    labelEmailText: "환불 신청을 계속하기 위한 이메일 알림을 보냅니다.",
    placeholderEmail: "이메일",
    buttonEmailReminder: "이메일 알림",
    labelCustomerName: "고객성명",
    validationCustomerName: "고객 이름이 필요합니다",
    labelTypeOfBooking: "예약종류",
    validationTypeOfBooking: "예약 유형이 필요합니다.",
    labelEventName: "예약종류",
    labelVenueName: "장소이름",
    labelFlightNumber: "항공편 번호",
    labelOriginAirport: "기존 공항",
    labelHotelName: "숙박 이름",
    labelHotelLocation: "숙박 위치",
    labelCurrency: "통화",
    validationCurrency: "통화가 필요합니다",
    validationCountryCurrency: "선택한 통화는 해당 국가 통화이어야 합니다.",
    validationOfflinePayment: "귀하가 선택한 통화 및 국가에 대해서는 환불을 처리할 수 없습니다. 대체 통화를 선택하십시오.",
    labelBookingValue: "예약금액",
    validationBookingValue: "예약 금액 총액이 필요합니다.",
    validationBookingValueAmount: "0보다 커야 함",
    labelDateOfPurchase: "구매일",
    validationDateOfPurchase: "구매 날짜가 필요합니다",
    labelEmailAddress: "이메일 주소",
    validationEmailAddress: "이메일 주소가 필요합니다",
    validationEmailAddressFormat: "잘못된 이메일 형식",
    labelConfirmEmailAddress: "이메일 주소 확인",
    validationConfirmEmailAddressFormat: "잘못된 이메일 형식",
    validationConfirmEmailAddress: "확인 이메일 주소가 필요합니다",
    validationConfirmEmailAddressMatch: "이메일 주소가 일치하지 않습니다",
    validationEmailDomain: "이 이메일 주소는 허용되지 않습니다. 다른 이메일 주소를 입력해주세요.",
    validationDateOfEvent: "이벤트 날짜가 필요합니다.",
    labelHomeCountry: "거주국가",
    validationHomeCountry: "국가가 필요합니다",
    labelContactNumberCountryCode: "국가번호",
    validationContactNumberCountryCode: "국가 다이얼 코드가 필요합니다.",
    labelContactNumber: "연락번호",
    validationContactNumber: "연락처 번호가 필요합니다",
    labelAddressLine1: "주소",
    labelAddressLine2: "상세 주소",
    labelCity: "도시",
    labelZipCode: "우편번호",
    labelRegion: "지역",
    validationRegion: "지역이 필요합니다.",
    labelNumberOfTicketsToRefund: "환불해야 하는 티켓 개수",
    validationNumberOfTicketsToRefund: "환불할 티켓 수가 필요합니다.",
    validationNumberOfTicketsToRefundValue: "0보다 커야합니다",
    labelCostPerTicket: "각 티켓의 비용",
    validationCostPerTicket: "티켓당 비용이 필요합니다.",
    validationCostPerTicketValue: "0보다 커야 함",
    labelTotalRefundAmount: "총 환불금액",
    validationTotalRefundAmount: "총 환불 금액이 필요합니다.",
    validationTotalRefundAmountValue: "0보다 커야 함",
    validationTotalRefundGreaterThanBookingValue: "총 환불 금액은 위의 예약 금액보다 클 수 없습니다.",
    labelBookingConfirmationFiles: "예약 확인 업로드",
    validationBookingConfirmationFiles: "적어도 하나의 파일을 첨부하거나 문서가 있으면 저장 후에 돌아가기를 클릭하십시오.",
    validationEvidenceFilesPopup: "제출하기 전에 증거를 업로드해야 합니다. 지금 증거가 없다면 신청서를 저장한 후 추후 스캐너 사본, 사진 등의 증빙 서류를 업로드해주세요.",
    validationBookingConfirmationFilesPopup: "제출하기 전에 예약 확인을 업로드해야 합니다. 지금 예약 확인서를 가지고 있지 않다면 신청서를 저장한 후 추후 스캐너 사본, 사진 등의 증빙 서류를 업로드해주세요.",
    alertUploadDocuments: "이메일에 설명된 관련 문서를 업로드한 후 3단계로 진행하십시오.",
    labelRefundReasonCategory: "환불사유",
    validationRefundReasonCategory: "환불 사유가 필요합니다.",
    labelDateOfIncident: "사고 날짜",
    labelAdditionalComments: "추가 의견",
    tooltipAdditionalComments: "추가 의견",
    validationAdditionalComments: "추가 의견이 필요합니다.",
    labelUnableToNotifyWithinTimeLimit: "다음으로부터 60일 이내에 통지 할 수 없음",
    labelReasonForNotificationOutsideTimeLimit: "알림 시간 초과 설명",
    validationReasonForNotificationOutsideTimeLimit: "60일 후 알림 이유가 필요합니다.",
    labelRefundReasonFiles: "증거 업로드",
    validationRefundReasonFiles: "적어도 하나의 파일을 첨부하거나 문서가 있으면 저장 후 나중에 반환",
    validationTextScript:"라틴 문자와 일치해야합니다.",
    optionTypeOfBooking1: "이벤트",
    optionTypeOfBooking2: "투어",
    optionTypeOfBooking3: "항공",
    optionTypeOfBooking4: "숙박",
    optionTypeOfBooking5: "이동",
    optionTypeOfBooking6: "주차",
    optionTypeOfBooking7: "기타 여행",
    labelEventTypeDate: "이벤트 날짜",
    labelEventTypeTour: "투어 날짜",
    labelEventTypeFlight: "날짜",
    labelEventTypeCheckIn: "체크인 날짜",
    labelEventTypeTransfer: "이동 날짜",
    labelEventTypeParking: "주차 날짜",
    labelEventTypeOtherTravel: "여행 날짜",
    optionRefundReason11: "악천후",
    optionRefundReason10: "군대 및 응급 서비스",
    optionRefundReason15: "시험 날짜 변경",
    optionRefundReason18: "법원 소환",
    optionRefundReason17: "COVID-19 여행 제한",
    optionRefundReason19: "COVID-19 양성 테스트",
    optionRefundReason20: "운영자/주최자가 취소한 예약",
    optionRefundReason21: "운영자/주최자가 예약을 조정함",
    optionRefundReason22: "잘못된 날짜 예약",
    optionRefundReason23: "잘못된 티켓 예약",
    optionRefundReason24: "너무 많은 티켓을 예약했습니다",
    optionRefundReason4: "사망",
    optionRefundReason16: "긴급 상황",
    optionRefundReason14: "정부 여행 금지",
    optionRefundReason9: "가정 응급 상황",
    optionRefundReason1: "질병 / 부상 / 사고",
    optionRefundReason8: "배심원 요청",
    optionRefundReason7: "기계적 고장",
    optionRefundReason2: "기존 건강 상태",
    optionRefundReason3: "임신 합병증",
    optionRefundReason5: "대중 교통 이동 실패",
    optionRefundReason6: "비행 중단",
    optionRefundReason13: "문서 도난",
    optionRefundReason12: "작업 재배치",
    optionRefundReason25: "직장 문제",
    optionRefundReason26: "테스트 옵션",
    optionRefundReasonConfirm1: "아니요",
    optionRefundReasonConfirm2: "예",
    optionRefundReasonArmedForces1: "육군",
    optionRefundReasonArmedForces2: "해군",
    optionRefundReasonArmedForces3: "공군",
    optionRefundReasonArmedForces4: "특수부대",
    optionRefundReasonArmedForces5: "해병대",
    optionRefundReasonArmedForces6: "불",
    optionRefundReasonArmedForces7: "구급차",
    optionRefundReasonArmedForces8: "의사/간호사",
    optionRefundReasonArmedForces9: "치과의사",
    optionRefundReasonArmedForces10: "기타",
    optionRefundReasonCourtSummons1: "형사 기소",
    optionRefundReasonCourtSummons2: "민사 법률 사건",
    optionRefundReasonCourtSummons3: "검찰의 증인",
    optionRefundReasonCourtSummons4: "민사 사건의 증인",
    optionRefundReasonCourtSummons5: "원고",
    optionRefundReasonCourtSummons6: "피고",
    optionRefundReasonCourtSummons7: "기타",
    optionRefundReasonDeath1: "남편",
    optionRefundReasonDeath2: "아내",
    optionRefundReasonDeath3: "아들",
    optionRefundReasonDeath4: "딸",
    optionRefundReasonDeath5: "아버지",
    optionRefundReasonDeath6: "어머니",
    optionRefundReasonDeath7: "남동생",
    optionRefundReasonDeath8: "여동생",
    optionRefundReasonDeath9: "할아버지",
    optionRefundReasonDeath10: "할머니",
    optionRefundReasonDeath11: "기타",
    optionRefundReasonHomeEmergency1: "화재",
    optionRefundReasonHomeEmergency2: "홍수",
    optionRefundReasonHomeEmergency3: "강도",
    optionRefundReasonHomeEmergency4: "반달리즘에 의한 기물파손",
    optionRefundReasonHomeEmergency5: "가정폭력",
    optionRefundReasonHomeEmergency6: "납치",
    optionRefundReasonHomeEmergency7: "기타",
    optionRefundReasonCOVID19Person1: "자신",
    optionRefundReasonCOVID19Person2: "예약에 참여한 다른 사람",
    optionRefundReasonCOVID19Person3: "내 가족의 한 사람",
    optionRefundReasonCOVID19Person4: "기타",
    optionRefundReasonCOVID19TestType1: "PCR 테스트",
    optionRefundReasonCOVID19TestType2: "RAT 테스트",
    optionRefundReasonCOVID19TestType3: "감염성 질병 간이검사",
    optionRefundReasonCOVID19TestType4: "자가진단키트",
    optionRefundReasonCOVID19TestType5: "검사는 없지만 증상이 있습니다.",
    optionRefundReasonCOVID19TestType6: "기타",
    optionRefundReasonBreakdown1: "고장",
    optionRefundReasonBreakdown2: "사고",
    optionRefundReasonBreakdown3: "화재",
    optionRefundReasonBreakdown4: "절도",
    optionRefundReasonPublicTransportType1: "기차",
    optionRefundReasonPublicTransportType2: "버스",
    optionRefundReasonPublicTransportType3: "트램",
    optionRefundReasonPublicTransportType4: "배",
    optionRefundReasonDocumentType1: "여권",
    optionRefundReasonDocumentType2: "여행 허가서",
    optionRefundReasonDocumentType3: "주민등록증",
    optionRefundReasonDocumentType4: "티켓",
    optionRefundReasonWorkRelocationType1: "일시적인",
    optionRefundReasonWorkRelocationType2: "지속적인",
    optionRefundReasonWorkRelocationTemporary1: "0-3 개월",
    optionRefundReasonWorkRelocationTemporary2: "3-6 개월",
    optionRefundReasonWorkRelocationTemporary3: "6개월 이상",
    optionRefundReasonBookingRelationshipType1: "예약에 참여한 사람",
    optionRefundReasonBookingRelationshipType2: "예약에 참여한 사람의 직계 친척",
    optionEmploymentType1: "정규직",
    optionEmploymentType2: "파트 타임",
    optionEmploymentType3: "계약직",
    optionEmploymentLengthType1: "0-1 년",
    optionEmploymentLengthType2: "1-2 년",
    optionEmploymentLengthType3: "2년 이상",
    textRefundEvidenceHeading: "다음에 해당되는 증빙을 입력하십시오 :",
    textRefundEvidence1: "항공사 확인서",
    textRefundEvidence2: "기타 적절한 증빙 서류",
    textRefundEvidence3: "고장 서비스 호출 확인서",
    textRefundEvidence4: "사망 진단서",
    textRefundEvidence5: "항공사의 결항 확인서",
    textRefundEvidence6: "일기 예보",
    textRefundEvidence7: "항공권 재발행 불능을 확인한 예약 대리인/여행사 서신",
    textRefundEvidence8: "법원의 편지",
    textRefundEvidence9: "진단서/의사 소견서",
    textRefundEvidenceDescription9: "질병이나 부상에 대한 세부 사항, 처음 발생한 날짜, 귀하가 예약에 참석할 수 없음을 확인하는 서류.",
    textRefundEvidence10: "고용주 서신 확인서",
    textRefundEvidence11: "보험 청구 서신",
    textRefundEvidence12: "고용주의 서신",
    textRefundEvidence13: "국가 정부 웹사이트 확인서",
    textRefundEvidence14: "심사 변경 공지 사항",
    textRefundEvidence15: "경찰 보고서",
    textRefundEvidence16: "경찰 보고서 및 참조 번호",
    textRefundEvidence17: "운송 공급자 확인서",
    textRefundEvidence18: "여행 경고",
    textRefundEvidence20: "COVID-19 테스트 인증서",
    textRefundEvidence24: "정부 격리 통지서",
    textRefundEvidence25: "사령관의 서신",
    textRefundEvidence26: "보험사의 서신",
    textRefundEvidence27: "주소 증명서",
    textRefundEvidence28: "고인과의 관계 증명서",
    textRefundEvidence29: "부상당한 사람/아픈 사람과의 관계 증명서",
    textRefundEvidence30: "급여명세서",
    tooltipBookingReference: "예약 번호를 확인하려면 예약 확인을 확인하고 확장 서비스에 대한 섹션을 확인하세요.",
    tooltipCustomerName: "예약에 사용 된 이름과 성입니다.",
    tooltipTypeOfBooking: "환불을 원하는 예약 유형입니다.",
    tooltipEventName: "이벤트의 이름입니다.",
    tooltipVenueName: "장소의 이름.",
    tooltipFlightNumber: "항공 편 명.",
    tooltipOriginAirport: "출발 공항.",
    tooltipHotelName: "숙박 시설의 이름.",
    tooltipHotelLocation: "숙소의 위치.",
    tooltipCurrency: "예약 결제에 사용되는 통화입니다.",
    tooltipBookingValue: "예약에 대해 지불 한 총 가격입니다.",
    tooltipDateOfPurchase: "예약 한 날짜입니다.",
    tooltipEmailAddress: "유효한 이메일 주소를 입력하십시오. 이메일은 환불 신청 시 연락을 위해 사용될 수 있습니다.",
    tooltipConfirmEmailAddress: "동일한 이메일을 입력해 주십시오. 이메일을 재 확인 합니다.",
    tooltipHomeCountry: "귀하의 거주 국가를 입력하십시오.",
    tooltipContactNumberCountryCode: "연락처 국가 코드.",
    tooltipContactNumber: "유효한 연락가능 번호를 입력하십시오. 환불 신청 시 연락을 위해 사용 될 수 있습니다.",
    tooltipAddressLine1: "주소를 입력하십시오.",
    tooltipAddressLine2: "상세주소를 입력하십시오.",
    tooltipCity: "귀하의 거주 도시를 입력하십시오.",
    tooltipZipCode: "우편 번호를 입력하십시오.",
    tooltipRegion: "거주 지역(주/도).",
    tooltipNumberOfTicketsToRefund: "환불하고자하는 티켓/예약 수.",
    tooltipTotalRefundAmount: "총 환불금액. 이 금액은 총 예약 금액보다 클 수 없습니다.",
    tooltipBookingConfirmationFiles: "일반적으로 이메일을 통해 전달되는 전체 예약 확인서의 스크린 샷 / 첨부파일.",
    tooltipRefundReasonCategory: "환불 사유를 선택하십시오.",
    tooltipDateOfIncident: "사건 발생일.",
    tooltipRefundExplanation: "환불 청구를 위한 최대한 자세한 정황 정보를 입력하십시오.",
    tooltipUnableToNotifyWithinTimeLimit: "60 일 제한 기간 내에 환불을 신청할 수 없습니다.",
    tooltipReasonForNotificationOutsideTimeLimit: "제한 기간내에 환불을 신청할 수 없는 이유.",
    tooltipRefundReasonFiles: "필요한 서류를 업로드하십시오.",
    tooltipEventTypeDate: "실제 이벤트예약일.",
    tooltipEventTypeTour: "실제 투어일.",
    tooltipEventTypeFlight: "실제 비행일.",
    tooltipEventTypeCheckIn: "실제 체크인 날짜.",
    tooltipEventTypeTransfer: "실제 이전일.",
    tooltipEventTypeParking: "실제 주차일.",
    tooltipEventTypeOtherTravel: "실제 여행 시작일."
};