export default {
    labelBookingCancelledPostponedHeading: "Rezervasyonunuz iptal mi edildi veya ertelendi mi? Devamını oku",
    labelBookingCancelledPostponedText1: "",
    labelBookingCancelledPostponedText2: "tıklayarak daha fazla bilgi alabilirsiniz.",
    labelNewRefundHeading: "İade başvurunuz için bilet referans numaranızı",
    labelNewRefundText: "buraya girin...",
    placeholderBookingReference: "Rezervasyon referansı",
    validationBookingReference: "Bir rezervasyon referansı gerekli",
    validationEmailAsBookingReference: "E-posta adresi rezervasyon referansı olarak kabul edilmiyor",
    buttonFindBooking: "Biletimi Bul",
    labelEmailHeading: "Zaten başvuru yaptınız mı?",
    labelEmailText: "Hatırlatma e-postası için aşağıya e-posta adresinizi girebilirsiniz.",
    placeholderEmail: "E-posta",
    buttonEmailReminder: "E-posta ile hatırlatma",
    labelCustomerName: "Müşteri adı",
    validationCustomerName: "Müşteri adı gerekli",
    labelTypeOfBooking: "Rezervasyon türü",
    validationTypeOfBooking: "Rezervasyon türü gerekli",
    labelEventName: "Etkinliğin adı",
    labelVenueName: "Yer adı",
    labelFlightNumber: "Uçuş numarası",
    labelOriginAirport: "Kalkış havaalanı",
    labelHotelName: "Konaklama yerinin adı",
    labelHotelLocation: "Konaklama yeri",
    labelCurrency: "Para Birimi",
    validationCurrency: "Para birimi gereklidir",
    validationCountryCurrency: "Seçilen Para Birimi, Bulunduğunuz Ülkedeki para birimi olmalıdır",
    validationOfflinePayment: "Para birimi ve ülke seçiminize göre geri ödemeleri işleme alamıyoruz, lütfen alternatifleri seçin",
    labelBookingValue: "Rezervasyon değeri",
    validationBookingValue: "Rezervasyon değeri gerekli",
    validationBookingValueAmount: "0'dan büyük olmalıdır",
    labelDateOfPurchase: "Satın alma tarihi",
    validationDateOfPurchase: "Satın alma tarihi gerekli",
    labelEmailAddress: "E-posta adresi",
    validationEmailAddress: "E-posta adresi gerekli",
    validationEmailAddressFormat: "Yanlış e-posta biçimi",
    labelConfirmEmailAddress: "E-posta adresinizi onaylayın",
    validationConfirmEmailAddressFormat: "Yanlış e-posta biçimi",
    validationConfirmEmailAddress: "Onay e-posta adresi gerekli",
    validationConfirmEmailAddressMatch: "e-posta adresleri eşleşmiyor",
    validationEmailDomain: "Bu e-posta sağlayıcısı kabul edilmiyor, lütfen bir alternatif kullanın",
    validationDateOfEvent: "Olayın tarihi gerekli",
    labelHomeCountry: "Yerel Ülke",
    validationHomeCountry: "Menşe ülke gerekli",
    labelContactNumberCountryCode: "İletişim Numarası Ülke Kodu",
    validationContactNumberCountryCode: "Ülke çevirme kodu gerekli",
    labelContactNumber: "İletişim numarası",
    validationContactNumber: "İletişim numarası gerekli",
    labelAddressLine1: "Adres 1",
    labelAddressLine2: "Adres Satırı 2",
    labelCity: "Şehir",
    labelZipCode: "Posta kodu",
    labelRegion: "Bölge",
    validationRegion: "Bölge gerekli",
    labelNumberOfTicketsToRefund: "İade edilecek bilet sayısı",
    validationNumberOfTicketsToRefund: "İade edilecek bilet sayısı gereklidir",
    validationNumberOfTicketsToRefundValue: "0'dan büyük olmalıdır",
    labelCostPerTicket: "Her biletin maliyeti",
    validationCostPerTicket: "Bilet başına maliyet zorunludur",
    validationCostPerTicketValue: "0'dan büyük olmalıdır",
    labelTotalRefundAmount: "Geri ödemenin toplam tutarı",
    validationTotalRefundAmount: "Toplam geri ödeme tutarı gerekli",
    validationTotalRefundAmountValue: "0'dan büyük olmalıdır",
    validationTotalRefundGreaterThanBookingValue: "Toplam Geri Ödeme Tutarı, yukarıdaki Rezervasyon Değerinden büyük olamaz",
    labelBookingConfirmationFiles: "Rezervasyon onayını indirin",
    validationBookingConfirmationFiles: "En az bir dosya ekleyin veya Kaydet'i tıklayın ve daha sonra belgeleri aldığınızda tekrar gelin",
    validationEvidenceFilesPopup: "Göndermeden önce kanıtların yüklenmesi gerekir. Şu anda elinizde kanıt yoksa lütfen başvuruyu kaydedin ve elde ettikten sonra geri dönün. Elektronik kopyaları, tarayıcı kopyalarını ve fotoğrafları kabul ediyoruz.",
    validationBookingConfirmationFilesPopup: "Göndermeden önce rezervasyon onayının yüklenmesi gerekmektedir. Şu anda rezervasyon onayınız yoksa lütfen uygulamayı kaydedin ve aldıktan sonra geri dönün. Elektronik kopyaları, tarayıcı kopyalarını ve fotoğrafları kabul ediyoruz.",
    alertUploadDocuments: "Lütfen e-postada ayrıntıları verilen ilgili belgeleri yükleyin ve ardından 3. Adıma geçin.",
    labelRefundReasonCategory: "İade Sebebi",
    validationRefundReasonCategory: "Geri ödeme nedeni gerekli",
    labelDateOfIncident: "Olayın tarihi",
    labelAdditionalComments: "Ek Yorumlar",
    tooltipAdditionalComments: "Ek Yorumlar",
    validationAdditionalComments: "Ek yorumlar gerekli",
    labelUnableToNotifyWithinTimeLimit: "60 gün içinde bildirim yapılamıyor",
    labelReasonForNotificationOutsideTimeLimit: "Zaman sınırı dışında bildirim Açıklama",
    validationReasonForNotificationOutsideTimeLimit: "60 günden sonra bildirim için bir neden gereklidir",
    labelRefundReasonFiles: "Provaları indir",
    validationRefundReasonFiles: "En az bir dosya ekleyin veya Kaydet'i tıklayın ve belgeleri aldığınızda daha sonra tekrar gelin",
    validationTextScript: "Latin alfabesiyle eşleşmelidir",
    optionTypeOfBooking1: "Etkinlik",
    optionTypeOfBooking2: "Tur",
    optionTypeOfBooking3: "Uçuş",
    optionTypeOfBooking4: "Konaklama",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Park",
    optionTypeOfBooking7: "Diğer Seyahat",
    labelEventTypeDate: "Etkinliğin tarihi",
    labelEventTypeTour: "Turun tarihi",
    labelEventTypeFlight: "Uçuş tarihi",
    labelEventTypeCheckIn: "Varış tarihi",
    labelEventTypeTransfer: "Aktarım tarihi",
    labelEventTypeParking: "Park etme tarihi",
    labelEventTypeOtherTravel: "Seyahat Tarihi",
    optionRefundReason11: "Olumsuz hava koşulları",
    optionRefundReason10: "Silahlı kuvvetlerin ve acil durum hizmetlerinin geri çağrılması",
    optionRefundReason15: "Sınav tarihlerinde değişiklikler",
    optionRefundReason18: "Mahkeme çağrısı",
    optionRefundReason17: "COVID-19 seyahat kısıtlamaları",
    optionRefundReason19: "COVID-19 Pozitif Testi",
    optionRefundReason20: "Rezervasyon, Operatör/Organizasyon Görevlisi Tarafından İptal Edildi",
    optionRefundReason21: "Operatör/Organizatör Tarafından Yeniden Planlanan Rezervasyon",
    optionRefundReason22: "Yanlış Tarih Rezervasyonu",
    optionRefundReason23: "Yanlış Bilet rezervasyonu",
    optionRefundReason24: "Fazla Bilet Satın Alındı",
    optionRefundReason4: "Ölüm",
    optionRefundReason16: "Acil durumlar",
    optionRefundReason14: "Devlet tarafından seyahat yasağı",
    optionRefundReason9: "Evde acil durum",
    optionRefundReason1: "Hastalık / yaralanma / kaza",
    optionRefundReason8: "Jüri hizmeti",
    optionRefundReason7: "Mekanik arıza",
    optionRefundReason2: "Önceden var olan tıbbi durum",
    optionRefundReason3: "Gebelik komplikasyonu",
    optionRefundReason5: "Toplu Taşıma Sorunu",
    optionRefundReason6: "Uçuş Kesintisi",
    optionRefundReason13: "Belgelerin Hırsızlığı",
    optionRefundReason12: "İş Yerinin Değiştirilmesi",
    optionRefundReason25: "İşyeri Yedekliliği",
    optionRefundReason26: "Test Seçeneği",
    optionRefundReasonConfirm1: "Hayır",
    optionRefundReasonConfirm2: "Evet",
    optionRefundReasonArmedForces1: "Ordu",
    optionRefundReasonArmedForces2: "Donanma",
    optionRefundReasonArmedForces3: "Hava Kuvvetleri",
    optionRefundReasonArmedForces4: "Özel Kuvvetler",
    optionRefundReasonArmedForces5: "Denizci",
    optionRefundReasonArmedForces6: "Ateş",
    optionRefundReasonArmedForces7: "Ambulans",
    optionRefundReasonArmedForces8: "Doktor/Hemşire",
    optionRefundReasonArmedForces9: "Dişçi",
    optionRefundReasonArmedForces10: "Başka",
    optionRefundReasonCourtSummons1: "Cezai Kovuşturma",
    optionRefundReasonCourtSummons2: "Medeni Hukuk Davası",
    optionRefundReasonCourtSummons3: "Kovuşturmada Tanık",
    optionRefundReasonCourtSummons4: "Bir Hukuk Davasında Tanık",
    optionRefundReasonCourtSummons5: "Davacı",
    optionRefundReasonCourtSummons6: "Davalı",
    optionRefundReasonCourtSummons7: "Başka",
    optionRefundReasonDeath1: "Koca",
    optionRefundReasonDeath2: "Karı",
    optionRefundReasonDeath3: "Ses",
    optionRefundReasonDeath4: "Kız",
    optionRefundReasonDeath5: "Baba",
    optionRefundReasonDeath6: "Anne",
    optionRefundReasonDeath7: "Kardeş",
    optionRefundReasonDeath8: "Kız kardeş",
    optionRefundReasonDeath9: "Büyükbaba",
    optionRefundReasonDeath10: "Büyükanne",
    optionRefundReasonDeath11: "Başka",
    optionRefundReasonHomeEmergency1: "Ateş",
    optionRefundReasonHomeEmergency2: "Sel",
    optionRefundReasonHomeEmergency3: "Soygun",
    optionRefundReasonHomeEmergency4: "Vandalizm",
    optionRefundReasonHomeEmergency5: "Aile İçi İstismar",
    optionRefundReasonHomeEmergency6: "Kaçırmak",
    optionRefundReasonHomeEmergency7: "Başka",
    optionRefundReasonCOVID19Person1: "Kendim",
    optionRefundReasonCOVID19Person2: "Rezervasyondaki başka bir kişi",
    optionRefundReasonCOVID19Person3: "Evimdeki bir kişi",
    optionRefundReasonCOVID19Person4: "Başka",
    optionRefundReasonCOVID19TestType1: "PCR Testi",
    optionRefundReasonCOVID19TestType2: "RAT Testi",
    optionRefundReasonCOVID19TestType3: "Yanal Akış Testi",
    optionRefundReasonCOVID19TestType4: "Kendi Kendine Uygulanan Test",
    optionRefundReasonCOVID19TestType5: "Test yok ama semptomlarım var",
    optionRefundReasonCOVID19TestType6: "Başka",
    optionRefundReasonBreakdown1: "Arıza",
    optionRefundReasonBreakdown2: "Kaza",
    optionRefundReasonBreakdown3: "Ateş",
    optionRefundReasonBreakdown4: "Hırsızlık",
    optionRefundReasonPublicTransportType1: "Tren",
    optionRefundReasonPublicTransportType2: "Otobüs",
    optionRefundReasonPublicTransportType3: "Tramvay",
    optionRefundReasonPublicTransportType4: "Kayık",
    optionRefundReasonDocumentType1: "Pasaport",
    optionRefundReasonDocumentType2: "Seyahat İzni",
    optionRefundReasonDocumentType3: "Ulusal Kimlik",
    optionRefundReasonDocumentType4: "Bilet",
    optionRefundReasonWorkRelocationType1: "Geçici",
    optionRefundReasonWorkRelocationType2: "Kalıcı",
    optionRefundReasonWorkRelocationTemporary1: "0-3 ay",
    optionRefundReasonWorkRelocationTemporary2: "3-6 ay",
    optionRefundReasonWorkRelocationTemporary3: "6 aydan fazla",
    optionRefundReasonBookingRelationshipType1: "Rezervasyondaki biri",
    optionRefundReasonBookingRelationshipType2: "Rezervasyondaki birinin yakın akrabası",
    optionEmploymentType1: "Tam Zamanlı",
    optionEmploymentType2: "Yarı Zamanlı",
    optionEmploymentType3: "Sözleşme",
    optionEmploymentLengthType1: "0-1 Yıl",
    optionEmploymentLengthType2: "1-2 Yıl",
    optionEmploymentLengthType3: "2 yıldan fazla",
    textRefundEvidenceHeading: "Lütfen aşağıdaki kanıtları sağlayın:",
    textRefundEvidence1: "Havayolu Onay Belgesini",
    textRefundEvidence2: "Uygun Destekleyici Kanıtları",
    textRefundEvidence3: "Arıza Servisi Çağrı Onayı",
    textRefundEvidence4: "Ölüm Belgesini",
    textRefundEvidence5: "Havayolundan onay iptali",
    textRefundEvidence6: "Hava Tahminleri",
    textRefundEvidence7: "Rezervasyon acentesinden, biletleri yeniden düzenleme olanağı olmadığını doğrulayan yazışmalar",
    textRefundEvidence8: "Mahkeme Çağrı Belgesini",
    textRefundEvidence9: "Tıbbi Sertifika / Doktor Raporunu",
    textRefundEvidenceDescription9: "Hastalık veya yaralanmanın ayrıntılarını, ilk meydana geldiği tarihi, Rezervasyona Katılmanızı engellediğini onaylamak.",
    textRefundEvidence10: "İşveren mektubu onayı",
    textRefundEvidence11: "Sigorta hasar mektubu yazışmaları",
    textRefundEvidence12: "İşveren Mektubunu",
    textRefundEvidence13: "Ulusal Hükümet web sitesi onayı",
    textRefundEvidence14: "Sınav Değişikliği Bildirim Belgesini",
    textRefundEvidence15: "Polis Raporunu",
    textRefundEvidence16: "Polis raporu ve referans numarası",
    textRefundEvidence17: "Ulaşım Sağlayıcı Onayını",
    textRefundEvidence18: "Seyahat Uyarısı Belgesini",
    textRefundEvidence20: "COVID-19 Test Sertifikası",
    textRefundEvidence24: "Devlet İzolasyon Bildirimi belgesini",
    textRefundEvidence25: "Komutanın Mektubunu",
    textRefundEvidence26: "Sigorta Belgesini",
    textRefundEvidence27: "Adres Kanıtını",
    textRefundEvidence28: "Ölen Kişiyle Olan İlişkinizin Kanıtını",
    textRefundEvidence29: "Yaralı/hasta kişi ile ilişki kanıtı",
    textRefundEvidence30: "Maaş bordrosu",
    tooltipBookingReference: "Rezervasyon referansınızı belirlemek için rezervasyon onayınızı kontrol edin ve genişletilmiş hizmetimiz ile ilgili bölümü görüntüleyin.",
    tooltipCustomerName: "Rezervasyonu yapmak için kullanılan ad ve soyad.",
    tooltipTypeOfBooking: "İade almak istediğiniz rezervasyon türü.",
    tooltipEventName: "Etkinliğin adı.",
    tooltipVenueName: "Mekanın adı.",
    tooltipFlightNumber: "Uçuş numarası.",
    tooltipOriginAirport: "Kalkış havaalanı.",
    tooltipHotelName: "Konaklama yerinin adı.",
    tooltipHotelLocation: "Konaklama yeri.",
    tooltipCurrency: "Rezervasyon için ödeme yapmak için kullanılan para birimi.",
    tooltipBookingValue: "Rezervasyon için ödediğiniz toplam fiyat.",
    tooltipDateOfPurchase: "Rezervasyonun yapıldığı tarih.",
    tooltipEmailAddress: "Lütfen geçerli bir e-posta adresi kullanın. Bu e-postayı geri ödeme başvurusu sırasında yazışmalar için kullanıyoruz.",
    tooltipConfirmEmailAddress: "Yukarıdaki ile aynı e-posta adresini kullanın, bu doğrulama içindir.",
    tooltipHomeCountry: "Bu, son adımda doğru banka bilgilerini getirmemize yardımcı olacaktır.",
    tooltipContactNumberCountryCode: "İletişim numarası ülke kodu.",
    tooltipContactNumber: "Lütfen geçerli bir numara kullanın. Bunu iade başvurusu sırasında yazışmalar için kullanıyoruz.",
    tooltipAddressLine1: "Banka hesabınızı doğrularken adresinizin kullanılacağını lütfen unutmayın.",
    tooltipAddressLine2: "İkinci adres satırı.",
    tooltipCity: "Yaşadığınız şehir, ayrıntılar banka ayrıntılarını doğrulamak için kullanılabilir.",
    tooltipZipCode: "Amarikan Eyalet Kodu / Posta Kodu.",
    tooltipRegion: "Bulunduğunuz şehrin bölgesi, ayrıntılar banka ayrıntılarını doğrulamak için kullanılabilir.",
    tooltipNumberOfTicketsToRefund: "İade almak istediğiniz bilet sayısı.",
    tooltipTotalRefundAmount: "Bu, toplam rezervasyon değerinden fazla olamaz, varsa başvuruyu gönderemezsiniz.",
    tooltipBookingConfirmationFiles: "Genellikle e-postalarınızda bulunan tam rezervasyon onayınızın ekran görüntüsü / eki.",
    tooltipRefundReasonCategory: "Lütfen geri ödeme nedeninizi seçin.",
    tooltipDateOfIncident: "Rezervasyonunuzu etkileyen olayın meydana geldiği tarih.",
    tooltipRefundExplanation: "Lütfen iade talebini destekleyecek mümkün olduğunca fazla ayrıntı verin.",
    tooltipUnableToNotifyWithinTimeLimit: "60 Gün süre sınırı içinde bildirim yapılamıyor.",
    tooltipReasonForNotificationOutsideTimeLimit: "Zaman Sınırı Dışında Bildirim Açıklaması.",
    tooltipRefundReasonFiles: "Lütfen gerekli belgelerden herhangi birini yükleyin.",
    tooltipEventTypeDate: "Gerçek olayın / rezervasyonun tarihi.",
    tooltipEventTypeTour: "Gerçek turun tarihi.",
    tooltipEventTypeFlight: "Gerçek uçuşun tarihi.",
    tooltipEventTypeCheckIn: "Gerçek check-in tarihi.",
    tooltipEventTypeTransfer: "Gerçek transferin tarihi.",
    tooltipEventTypeParking: "Gerçek park yeri tarihi.",
    tooltipEventTypeOtherTravel: "Gerçek gezinin başlangıç ​​tarihi."
};