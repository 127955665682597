export default {
    messageQuestionService: "Denna tjänst är endast till för användning om du har problem med att fylla i ansökningsformuläret. Vi kan inte svara på några andra frågor här.",
    labelName: "namn",
    labelReference: "Bokningsnummer",
    labelPhone: "Telefonnummer",
    labelEmail: "E-post",
    labelQuery: "Din fråga",
    labelAskQuestion: "Skicka oss en fråga här ...",
    buttonOpenModal: "Fråga",
    title: "Ställa en fråga",
    modalTitle: "4. Har du ett tekniskt problem med formuläret?",
    toastSuccess: "Vi har fått din fråga och vi kommer att kontakta dig inom 24 timmar",
    toastError: "Något gick fel med din begäran, försök igen",
    nameValidation: "Namn krävs",
    referenceValidation: "Referens krävs",
    questionValidation: "Din fråga krävs"
}