import * as React from 'react';
import {
    DateInput,
    FileInput,
    SelectInput
} from "../../formInputs";
import {useTranslation} from 'react-i18next';
import {nameof} from "ts-simple-nameof";
import {IFormValues} from "../../Interfaces/IFormValues";
import {useFormContext} from "../../providers/FormProvider";
import {OptionYes} from "../../utils/constants";
import {FormSectionProps} from "./RefundReasonHandlerSection";
import {useDynamicEvidence} from "../../utils/helpers";
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';
import {RefundReasonFilesState} from '../../../state/activity';

export const WorkplaceRedundancySection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {
        clearFiles,
        isEvidenceRequired,
        previousRefundReasonFiles,
        setClearFiles
    } = useFormContext();

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:headingRedundancy')}
                declineWarning={t('bespokeQuestions:headingRedundancyDecline')}
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                defaultValue={0}
                label={t('bespokeQuestions:labelRedundancy1')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect1)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                datasourceUrl={'/api/referencedata/employmenttypes'}
                prefixKey={'form:optionEmploymentType'}
                tooltip={t('bespokeQuestions:tooltipRedundancy1')}
                displayLabelAsTitle={false}
                required
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                defaultValue={0}
                label={t('bespokeQuestions:labelRedundancy2')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect2)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                datasourceUrl={'/api/referencedata/employmentlengthtypes'}
                prefixKey={'form:optionEmploymentLengthType'}
                tooltip={t('bespokeQuestions:tooltipRedundancy2')}
                displayLabelAsTitle={false}
                required
            />

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeSelect3)}
                defaultValue={0}
                label={t('bespokeQuestions:labelRedundancy3')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeSelect3)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                prefixKey={'form:optionRefundReasonConfirm'}
                datasourceUrl='/api/referencedata/yesnooptions'
                tooltip={t('bespokeQuestions:tooltipRedundancy3')}
                displayLabelAsTitle={false}
                required
            />

            <DateInput
                key={nameof<IFormValues>(x => x.refundReasonBespokeDate4)}
                label={t('bespokeQuestions:labelRedundancy4')}
                controlId={nameof<IFormValues>(x => x.refundReasonBespokeDate4)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                tooltip={t('bespokeQuestions:tooltipRedundancy4')}
                required
            />

            <FileInput
                key={nameof<IFormValues>(x => x.refundReasonFiles)}
                label={useDynamicEvidence(false)}
                clearFiles={clearFiles}
                setClearFiles={setClearFiles}
                controlId={nameof<IFormValues>(x => x.refundReasonFiles)}
                hidden={formProps.values.refundReasonConfirm !== OptionYes}
                setFieldValue={formProps.setFieldValue}
                previouslyUploaded={previousRefundReasonFiles}
                tooltip={t('form:tooltipRefundReasonFiles')}
                required={isEvidenceRequired}
                state={RefundReasonFilesState}
                formProps={formProps}
            />
        </>
    );
}