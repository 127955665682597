export default {
    nonAutoApprovedLabelThankYou: "Terima kasih atas Permohonan Bayaran Balik anda.",
    nonAutoApprovedLabelReview: "Pasukan kami akan menyemak permohonan anda dalam masa dua hari perniagaan akan datang dan kembali kepada anda dengan respons.",
    nonAutoApprovedLabelExperience: "Bagaimana kita lakukan?",
    nonAutoApprovedLabelExperienceIntroduction: "Kami sangat menghargai anda meluangkan masa untuk menilai kami dan meninggalkan ulasan kerana kami menghargai pendapat pelanggan kami.",
    feedbackExcellent: "Cemerlang",
    feedbackGreat: "Besar",
    feedbackAverage: "Purata",
    feedbackPoor: "Miskin",
    feedbackBad: "Buruk",
    labelThankYouForFeedback: "Terima kasih atas maklum balas anda.",
    autoApprovedMessage1: "Kami gembira untuk",
    autoApprovedMessage2: "Meluluskan",
    autoApprovedMessage3: "bayaran balik anda!",
    autoApprovedPayment1Day: "Pembayaran anda telah diproses dan sepatutnya berada dalam akaun anda hanya dalam 1 hari perniagaan.",
    autoApprovedPayment2to4Days: "Pembayaran anda telah diproses dan sepatutnya berada dalam akaun anda hanya dalam 2-4 hari perniagaan.",
    autoApprovedPayment7to10Days: "Pembayaran anda telah diproses dan sepatutnya berada dalam akaun anda hanya dalam 7-10 hari perniagaan.",
    autoApprovedLabelExperience: "Bagaimana kita lakukan?",
    autoApprovedLabelExperienceIntroduction: "Kami sangat menghargai anda meluangkan masa untuk menilai kami dan meninggalkan ulasan kerana kami menghargai pendapat pelanggan kami.",
    modalLabelNegative: "Maklum balas anda sangat penting bagi kami, beritahu kami jika anda juga ingin kami menghubungi anda.",
    placeholderFeedback: "Maklum balas",
    messageFeedbackSubmitted: "Maklum balas dihantar",
    reviewInstructionMessage: "5 bintang sangat baik, 4 bintang hebat, 3 bintang adalah purata, 2 bintang miskin, 1 bintang buruk."
}