export default {
    messageUnableToMakePaymentToCurrency: "እንደ አለመታደል ሆኖ ለተመረጠው ገንዘብ የባንክ ዝርዝሮችን ማውጣት አልቻልንም። እባክዎን ምንዛሬ እና የቤት ሀገር መመረጣቸውን ያረጋግጡ",
    messageOnlyBankAccountsAccepted: "እባክዎን ያስተውሉ፡ በዚህ ጊዜ መክፈል የምንችለው ለባንክ አካውንቶች ብቻ ነው (ክሬዲት እና ዴቢት ካርዶች ተቀባይነት የላቸውም)",
    labelAccountName: "መለያ ያዥ ስም",
    labelBankAccountName: "የመለያ ባለቤት ሙሉ ስም",
    labelBankCardHolderName: "የባለ መታወቂያው ስም",
    validationBankAccountName: "የመለያ ባለቤት ሙሉ ስም ያስፈልጋል",
    labelBankCountry: "የባንክ ሀገር",
    labelBankName: "የባንክ ስም",
    labelPaymentType: "የክፍያ ዓይነት",
    labelInstitutionNumber: "ተቋም ቁጥር",
    labelBankAddress: "የባንክ አድራሻ",
    labelBankAddress2: "የባንክ አድራሻ",
    labelBankCity: "ባንክ ከተማ",
    labelBankState: "የባንክ ግዛት",
    labelBankAccountNumber: "የባንክ ሂሳብ ቁጥር",
    labelBankAccountNumberCBU: "መለያ ቁጥር CBU",
    labelBankAccountNumberCCI: "መለያ ቁጥር CCI",
    labelBankAccountNumberZAR: "የባንክ ሂሳብ ቁጥር (7-11 አሃዞች)",
    labelCardNumber: "የካርታ ቁጥር",
    labelUnionpay: "UnionPay ካርድ ቁጥር",
    labelAlipay: "Alipay መታወቂያ (የሞባይል ቁጥር ወይም ኢሜይል)",
    labelBankAccountType: "የመለያ ዓይነት",
    optionBankAccountTypeCURRENT: "የአሁኑ መለያ",
    optionBankAccountTypeCHECKING: "መለያ በማረጋግጥ ላይ",
    optionBankAccountTypeSAVING: "የቁጠባ ሂሳብ ማስቀመጫ",
    optionBankAccountTypeSAVINGS: "የቁጠባ ሂሳብ ማስቀመጫ",
    optionBankAccountTypeCUENTA_VISTA: "ዋና መለያ",
    optionBankAccountType1: "መለያ በማረጋግጥ ላይ",
    optionBankAccountType2: "የቁጠባ ሂሳብ ማስቀመጫ",
    optionBankAccountType3: "ዋና መለያ",
    optionBankAccountType4: "Vista መለያ",
    optionBankAccountType5: "RUT መለያ",
    labelSortCode: "አጭር ኮድ",
    labelRoutingCode: "የመሄጃ ኮድ",
    labelBankRoutingCodeNationalID: "የባንክ መስመር ኮድ / ብሔራዊ መታወቂያ",
    labelBankTransitCodeSwift: "የመጓጓዣ ቁጥር",
    labelCNAPSCode: "የማዞሪያ ኮድ / CNAPS ኮድ (የቻይና ብሄራዊ የላቀ የክፍያ ስርዓት ኮድ)",
    labelABAFedwireRoutingCode: "ACH Routing Number",
    labelIFSCCode: "የ IFSC ኮድ",
    labelBankCode: "የባንክ ኮድ",
    labelBIKCode: "የቢኪ ኮድ",
    labelBranchCode: "የቅርንጫፍ ኮድ",
    labelBranchCodeUniversal: "ሁለንተናዊ ባለ 6 አሃዝ ቅርንጫፍ ኮድ",
    labelRemittanceLine3: "የተጠቃሚ አድራሻ ስም እና የስልክ ቁጥር",
    labelBeneficiaryContactNameandTelephoneNumber: "የተጠቃሚ አድራሻ ስም እና የስልክ ቁጥር",
    labelBeneficiaryDateofBirthandPlaceofBirth: "የተጠቃሚው የትውልድ ቀን እና የትውልድ ቦታ",
    labelRemittanceLine4: "የግለሰብ የታክስ ቁጥር CUIL (9-12 አሃዞች)",
    labelCUILNumber: "የግብር መታወቂያ፡ CUIL/CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "የግብር መታወቂያ RFC 13 አሃዞች ወይም CURP 18 አሃዞች",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "የግለሰብ የታክስ መታወቂያ CPF Cadastro de Pessoa Física (11-14 አሃዞች)፣ የቅርንጫፍ ኮድ",
    labelCFPNumber: "የሲፒኤፍ ቁጥር (11-14 አሃዞች)",
    labelBranchNameorBranchCode7digits: "የቅርንጫፍ ስም ወይም የቅርንጫፍ ኮድ (7 አሃዞች)",
    labelCedulaJuridica910digitsTaxID: "ህጋዊ የምስክር ወረቀት (9-10 አሃዞች የግብር መታወቂያ)",
    labelTaxIDRUT89digits: "የግብር መታወቂያ RUT (8-9 አሃዞች)",
    labelJob: "የስራ መደቡ መጠሪያ",
    labelDateOfBirth: "የትውልድ ቀን",
    labelNationality: "ዜግነት",
    labelDocumentType: "የሰነድ አይነት",
    labelDocumentNumber: "የሰነድ ቁጥር",
    labelRutNumber: "RUT ቁጥር (ሮል ኡኒኮ ትሪቡታሪዮ)",
    labelCedulaTaxIDIndividual811digits: "ሴዱላ (የግብር መታወቂያ ግለሰብ 8-11 አሃዞች)",
    labelCIIndividualTaxIDBranchLocation: "CI (የግለሰብ ታክስ መታወቂያ)፣ የቅርንጫፍ ቦታ",
    labelTaxIDRUCorCI: "የግብር መታወቂያ (RUC ወይም CI)",
    labelSortCodeHelp: "እገዛ?",
    labelBranchCodeHelp: "እገዛ?",
    labelIBAN: "አይባን",
    labelIBANHelp: "እገዛ?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "እገዛ?",
    labelRoutingCodeBSB: "የመሄጃ ኮድ/ቢኤስቢ",
    labelBSB: "የባንክ ግዛት ቅርንጫፍ (BSB)",
    labelSKNCode: "SKN ኮድ",
    labelAcceptanceFlights: "እኔ/እኛ የተመላሽ ገንዘብ ማመልከቻው የተሳካ ከሆነ ሁሉንም በረራዎች ጨምሮ ቦታ ማስያዝ እና ረዳት ምርቶች እንደሚሰረዙ እንቀበላለን።",
    labelAcceptanceTickets: "እኔ/እኛ የተመላሽ ገንዘብ ማመልከቻው ከተሳካ ትኬቶችን ጨምሮ ቦታ ማስያዝ እና ረዳት ምርቶች እንደሚሰረዙ እንቀበላለን።",
    labelTermsAndConditions1: "እኔ/እኛ ተስማምተናል",
    labelTermsAndConditions2: "አተገባበሩና ​​መመሪያው",
    labelFraudWarning1: "በዚህ የተመላሽ ገንዘብ ማመልከቻ ቅጽ ላይ የቀረቡት እውነታዎች እውነተኛ እና እውነት እንደሆኑ አምናለሁ። ማንኛውም የውሸት መረጃ ወይም ማስረጃ እንደ ወንጀል ተቆጥሮ ለፖሊስ ወይም ለሌሎች የህግ አስከባሪ ኤጀንሲዎች ሊከታተል እንደሚችል ተረድቻለሁ።",
    labelFraudWarning2: "ከደጋፊ ሰነዶች ጋር በተገናኘ ማጭበርበር እንዳለ ከጠረጠርን ይህ ጉዳይ ወዲያውኑ ለአካባቢው የፖሊስ ባለስልጣናት እንመራለን። ማመልከቻዬ የተጭበረበረ እንደሆነ እና ይህም የወንጀል ምርመራ ሊሆን የሚችል ከሆነ ዝርዝሮቼ ለእነዚህ ባለስልጣናት እንደሚተላለፉ ተቀብያለሁ እና ተረድቻለሁ።",
    validationTermsAndConditions: "ውሎች እና ሁኔታዎች መስማማት አለባቸው",
    messageFailbankName: "የባንክ ስም ባዶ ሊሆን አይችልም።",
    messageFailcustomerName: "የደንበኛ ስም ባዶ ሊሆን አይችልም።",
    messageFailbankAccountNumber: "መሞላት ያለበት",
    messageFailbankAccountNumberFormat: "የ IBAN ቅርጸት መሆን አለበት",
    messageFailsortCode: "መሞላት ያለበት",
    messageFailbankSwiftBic: "ተጠቃሚ SWIFT BIC ባዶ ሊሆን አይችልም።",
    messageFailbankSwiftBicFormat: "SWIFT ቅርጸት መሆን አለበት።",
    messageFailbeneficiaryAddress1: "አድራሻ 1 ባዶ ሊሆን አይችልም።",
    messageFailbeneficiaryCity: "ከተማ ባዶ መሆን አይችልም",
    messageFailbeneficiaryPostCode: "የፖስታ ኮድ ባዶ ሊሆን አይችልም።",
    messageFailremittanceLine3: "መሞላት ያለበት",
    messageFailremittanceLine4: "መሞላት ያለበት",
    modalHeading: "ዝርዝሮችን ያረጋግጡ",
    modalLabelPleaseConfirmYourDetails: "እባክዎ ዝርዝሮችዎን ያረጋግጡ",
    modalValidationPleaseConfirm: "አባክዎ ያጽድቁ",
    modalValidationMustMatch: "ካለፈው ግቤት ጋር መመሳሰል አለበት።",
    tooltipBankAccountName: "ስምህ በባንክ ሂሳብህ/የባንክ መግለጫዎች/የባንክ ካርድህ ላይ እንደሚታየው።",
    tooltipBankAccountNameRecipient: "የተቀባዩ ስም እና የመጀመሪያ ስም። ሁለቱም የመጀመሪያ እና የአያት ስም ከአንድ በላይ ቁምፊዎች ሊኖራቸው ይገባል.",
    tooltipBankCountry: "ባንክዎ የሚገኝበት አገር።",
    tooltipBankName: "የባንክዎ ስም።",
    tooltipPaymentType: "የሚጠይቁት የክፍያ አይነት.",
    tooltipInstitutionNumber: "ይህ ባለ 3 አሃዝ ሲሆን አብዛኛውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ።",
    tooltipBankAccountNumber: "የእርስዎ የግል መለያ ቁጥር። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankAccountNumberCBU: "መለያዎን ለመለየት ባለ 22 አሃዝ ቁጥር። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankAccountNumberCCI: "መለያዎን ለመለየት ባለ 20 አሃዝ ቁጥር። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankAccountNumberKRW: "መለያዎን ለመለየት ከ11-16 አሃዝ ያለው ቁጥር። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCardNumber: "የካርድ ቁጥርዎ። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipUnionpay: "የእርስዎ UnionPay ካርድ ቁጥር.",
    tooltipAlipay: "ከAlipay መለያዎ ጋር የተገናኘ የሞባይል ቁጥርዎ ወይም የኢሜይል አድራሻዎ.",
    tooltipBankAccountType: "ይህ ቼኪንግ ወይም ቁጠባ ነው።.",
    tooltipSortCode: "ይህ መለያዎ የተያዘበትን የባንክዎን ቅርንጫፍ ይለያል። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipRoutingCode: "ይህ መለያዎ የተያዘበትን የባንክዎን ቅርንጫፍ ይለያል። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBeneficiarySWIFTBIC: "በአለም አቀፍ ግብይት ውስጥ ባንክን ለመለየት ከ8-11 ቁምፊዎችን ያካትታል። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ላይ ይገኛል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBSB: "የባንክ ግዛት ቅርንጫፍ (BSB) ቁጥር ​​6 አሃዞች ያሉት የአውስትራሊያ ዓይነት ኮድ ነው። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ላይ ይገኛል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipRoutingCodeBSB: "ይህ መለያዎ የተያዘበትን የባንክዎን ቅርንጫፍ ይለያል። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ወይም በባንክ ካርድዎ ላይ ይገኛሉ ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipABAFedwireRoutingCode: "በአሜሪካ ውስጥ ባንኮችን ለመለየት የሚያገለግል ባለ 9 አሃዝ ቁጥር። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ላይ ይገኛል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipIFSCCode: "ብዙውን ጊዜ ይህ ኮድ በወርሃዊ መግለጫዎ ወይም በፓስፖርት ደብተርዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipTermsAndConditions: "ውሎችን እና ሁኔታዎችን በመቀበል የእኛን ውሎች እና ሁኔታዎች እንዳነበቡ እና እንደተስማሙ ያረጋግጣሉ.",
    tooltipIBAN: "አለምአቀፍ ክፍያዎችን ሲፈጽሙ ወይም ሲቀበሉ ሊጠቀሙበት የሚችሉት የአለም አቀፍ የባንክ ሂሳብ ቁጥርዎ። ብዙውን ጊዜ በወርሃዊ መግለጫዎ ላይ ይገኛል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "የተጠቃሚ አድራሻ ስም እና የስልክ ቁጥር.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "የተጠቃሚው የትውልድ ቀን እና የትውልድ ቦታ.",
    tooltipBankRoutingCodeNationalID: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankTransitCodeSwift: "ይህ ባለ 5 አሃዝ ሲሆን አብዛኛውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankAddress: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankAddress2: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankCity: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankState: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBranchName: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBankCode: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBIKCode: "ባንክዎን ለመለየት 9 አሃዞች.",
    tooltipBranchCode: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBranchCodeUniversal: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCNAPSCode: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipRemittanceLine4: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCUILNumber: "ይህ ባለ 11 አሃዝ ቁጥር ነው፣ ባለ ሁለት አሃዞች፣ ሰረዝ፣ ዘጠኝ አሃዞች እና አንድ አሃዝ ቼክ.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCFPNumber: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipBranchNameorBranchCode7digits: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCedulaJuridica910digitsTaxID: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipTaxIDRUT89digits: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipJob: "የአሁኑ ስራህ.",
    tooltipDateOfBirth: "የእርስዎ የልደት ቀን.",
    tooltipNationality: "ዜግነትህ.",
    tooltipDocumentType: "የሚፈለገው የሰነድ አይነት.",
    tooltipDocumentNumber: "ይህ ከእርስዎ የሰነድ አይነት ምርጫ ጋር የተያያዘው የሰነድ ቁጥር ነው።.",
    tooltipRutNumber: "የእርስዎ RUT ቁጥር (ሮል ኡኒኮ ትሪቡታሪዮ).",
    tooltipCedulaTaxIDIndividual811digits: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipCIIndividualTaxIDBranchLocation: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    tooltipTaxIDRUCorCI: "ብዙውን ጊዜ በወርሃዊ የባንክ መግለጫዎ ላይ ሊገኝ ይችላል ወይም አሁንም እርግጠኛ ካልሆኑ ባንክዎን ይጠይቁ.",
    errorCPFCode: "በአሁኑ ጊዜ CPFን እንደ PIX ቁልፍ ብቻ ነው የምንቀበለው።"
};