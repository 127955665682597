export default {
    dividerBankingDetails: "Dettagli bancari",
    dividerBookingDetails: "Dettagli della prenotazione",
    labelSavedApplicationReference: " Applicazione salvata Rif:",
    labelLanguage: "Language",
    messageWarningBookingHeading1: "Prenotazione annullata:",
    messageWarningBookingHeading2: "Prenotazione posticipata / riprogrammata:",
    messageWarningBookingBody1: "Se la tua prenotazione è stata cancellata dall'organizzatore o dalla compagnia aerea, questo team non sarà in grado di aiutarti con un rimborso. Dovrai contattare direttamente il team del servizio clienti; vedere la conferma della prenotazione o il sito web per i dettagli di contatto.",
    messageWarningBookingBody2: "Se la tua prenotazione è stata posticipata, i termini rimborsabili estesi verranno trasferiti alla nuova data. Se hai un motivo valido per il rimborso in base ai nostri termini, compila il modulo con le prove a sostegno corrette.",
    messageWarningBookingBody3: "Nota: questo servizio può fornire rimborsi solo per i motivi inclusi nella nostra formulazione, disponibile nell'e-mail di conferma della prenotazione.",
    messageWarningBookingBody4: "Se non puoi effettuare la prenotazione per motivi al di fuori dei nostri termini, non saremo in grado di aiutarti. Dovrai contattare direttamente il tuo team di assistenza clienti in quanto potrebbero essere in grado di aiutarti; vedere la conferma della prenotazione o il sito Web per i dettagli di contatto.",
    linkWarningBooking: "qui",
    formSectionNavigatorStep1: "Passaggio 1",
    formSectionNavigatorStep2: "Passaggio 2",
    formSectionNavigatorStep3: "Passaggio 3",
    formSectionNavigatorStep1Text: "Dettagli di contatto e prenotazione",
    formSectionNavigatorStep2Text: "Motivo del rimborso",
    formSectionNavigatorStep3Text: "Dettagli di pagamento",
    messageBookingMatchedAndFound: "Se il riferimento della tua prenotazione è associato a un'applicazione esistente, ti invieremo un'e-mail contenente un collegamento per accedere alla tua applicazione",
    messageBookingMatchedAndFoundCompleted: "La tua richiesta di rimborso è già stata approvata! In caso di problemi con il pagamento, contattare:",
    applicationDeclinedMessage: "La tua richiesta è stata rifiutata e ora è chiusa.",
    messageBookingNotFound1: "Non riusciamo a trovare una prenotazione con quel riferimento. Si prega di controllare l'e-mail di conferma della prenotazione e rientrare.",
    messageBookingNotFound2: "Non preoccuparti, se il codice della tua prenotazione non funziona, puoi selezionare 'Trova prenotazione' e inserire i tuoi dati. Quando completi il ​​modulo, carica una copia dell'e-mail di conferma della prenotazione per aiutarci a individuare i dettagli della prenotazione.",
    messageLocatingBooking: "Ubicazione della tua prenotazione ...",
    messageLoadingBank: "Caricamento dati bancari ...",
    messageFailCurrencyLookup: " Errore durante il recupero della valuta",
    messageSavingApplication: "Salvataggio dell'applicazione ...",
    messageSavedApplication: "Richiesta di rimborso salvata correttamente",
    messageFailSavedApplication: "Impossibile salvare la richiesta di rimborso",
    messageSendingEmailReminder: "Өңдеу...",
    messageErrorLocationDetails: "Errore durante il recupero dei dettagli sulla posizione",
    messageFailFormSubmission: "Invio modulo non riuscito",
    messageApplicationCannotBeFound: "Impossibile trovare l'applicazione",
    messageApplicationRetrievalError: "Errore durante il recupero dell'applicazione",
    messageSubmittingApplication: " Presentazione delladomanda...",
    messageEventDateLimitError: " Motivo della notifica fuori termine",
    messageAllTicketsAssigned: "Tutti i biglietti sono stati assegnati a un'altra domanda di rimborso.",
    selectPrefix: "Seleziona",
    selectPostfix: "",
    fileUpload: "fai clic qui per scaricare",
    fileUploadNoFiles: "0 file selezionati",
    fileUploadAcceptedFilesMessage: "Accettiamo solo PDF e formati immagine .png, .jpeg, .heic, .webp",
    fileUploadPreviouslyUploaded: "Precedentemente caricato:",
    fileUploadSelectedUploaded: "I selezionati sono già stati caricati:",
    buttonNext: "Avanti",
    buttonPrevious: "Precedente",
    buttonSave: "Salva",
    buttonCancel: "Annulla",
    buttonSubmit: "Invia",
    buttonConfirm: "Conferma",
    buttonYes: "sì",
    buttonNo: "No",
    buttonClose: "Vicina",
    linkPrivacyStatement: " Informativa sulla Privacy",
    linkComplaintsPolicy: "Politica sui reclami",
    validationRequired: "Necessaria",
    validationInvalid: "Non valido",
    labelUploadPrefix: "Caricamento",
    labelUploadPostfix: "",
    labelChange: "Cambiare",
    labelView: "Vista",
    browserNavigationMessage: "Le modifiche non salvate andranno perse"
};