export default {
    nonAutoApprovedLabelThankYou: "Terima kasih atas Aplikasi Pengembalian Dana Anda.",
    nonAutoApprovedLabelReview: "Tim kami akan meninjau aplikasi Anda dalam dua hari kerja ke depan dan menghubungi Anda kembali dengan tanggapan.",
    nonAutoApprovedLabelExperience: "Bagaimana kami melakukannya?",
    nonAutoApprovedLabelExperienceIntroduction: "Kami sangat menghargai Anda meluangkan waktu untuk menilai kami dan meninggalkan ulasan karena kami menghargai pendapat pelanggan kami.",
    feedbackExcellent: "Unggul",
    feedbackGreat: "Hebat",
    feedbackAverage: "Tengah",
    feedbackPoor: "Miskin",
    feedbackBad: "Buruk",
    labelThankYouForFeedback: "Terima kasih atas tanggapan Anda.",
    autoApprovedMessage1: "Kami senang bisa",
    autoApprovedMessage2: "Setuju",
    autoApprovedMessage3: "pengembalian dana Anda!",
    autoApprovedPayment1Day: "Pembayaran Anda telah diproses dan harus berada di akun Anda hanya dalam 1 hari kerja.",
    autoApprovedPayment2to4Days: "Pembayaran Anda telah diproses dan harus berada di akun Anda hanya dalam 2-4 hari kerja.",
    autoApprovedPayment7to10Days: "Pembayaran Anda telah diproses dan harus berada di akun Anda hanya dalam 7-10 hari kerja.",
    autoApprovedLabelExperience: "Bagaimana kami melakukannya?",
    autoApprovedLabelExperienceIntroduction: "Kami sangat menghargai Anda meluangkan waktu untuk menilai kami dan meninggalkan ulasan karena kami menghargai pendapat pelanggan kami.",
    modalLabelNegative: "Umpan balik Anda sangat penting bagi kami, beri tahu kami jika Anda juga ingin kami menghubungi Anda.",
    placeholderFeedback: "Umpan balik",
    messageFeedbackSubmitted: "Umpan balik yang dikirimkan",
    reviewInstructionMessage: "5 bintang sangat bagus, 4 bintang hebat, 3 bintang rata-rata, 2 bintang buruk, 1 bintang buruk."
}