export default {
    nonAutoApprovedLabelThankYou: "Tack för din återbetalningsansökan.",
    nonAutoApprovedLabelReview: "Vårt team kommer att granska din ansökan inom de närmaste två arbetsdagarna och återkomma till dig med ett svar.",
    nonAutoApprovedLabelExperience: "Hur gjorde vi?",
    nonAutoApprovedLabelExperienceIntroduction: "Vi skulle verkligen uppskatta att du tar dig tid att betygsätta oss och lämna en recension eftersom vi värdesätter våra kunders åsikt.",
    feedbackExcellent: "Utmärkt",
    feedbackGreat: "Stor",
    feedbackAverage: "Genomsnitt",
    feedbackPoor: "Fattig",
    feedbackBad: "Dålig",
    labelThankYouForFeedback: "Tack för din feedback.",
    autoApprovedMessage1: "Vi är glada över att",
    autoApprovedMessage2: "Godkänna",
    autoApprovedMessage3: "din återbetalning!",
    autoApprovedPayment1Day: "Din betalning har redan behandlats och bör finnas på ditt konto på bara 1 arbetsdag.",
    autoApprovedPayment2to4Days: "Din betalning har redan behandlats och bör finnas på ditt konto på bara 2-4 arbetsdagar.",
    autoApprovedPayment7to10Days: "Din betalning har redan behandlats och bör finnas på ditt konto på bara 7-10 arbetsdagar.",
    autoApprovedLabelExperience: "Hur gjorde vi?",
    autoApprovedLabelExperienceIntroduction: "Vi skulle verkligen uppskatta att du tar dig tid att betygsätta oss och lämna en recension eftersom vi värdesätter våra kunders åsikt.",
    modalLabelNegative: "Din feedback är väldigt viktig för oss, låt oss veta om du också vill att vi ska komma i kontakt med dig.",
    placeholderFeedback: "Feedback",
    messageFeedbackSubmitted: "Feedback som lämnats in",
    reviewInstructionMessage: "5 stjärnor är utmärkt, 4 stjärnor är bra, 3 stjärnor är genomsnittliga, 2 stjärnor är dåliga, 1 stjärna är dålig."
}