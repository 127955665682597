export default {
    nonAutoApprovedLabelThankYou: "Gracias por su solicitud de reembolso.",
    nonAutoApprovedLabelReview: "Nuestro equipo revisará su solicitud dentro de los próximos dos días hábiles y se comunicará con usted con una respuesta.",
    nonAutoApprovedLabelExperience: "¿Cómo lo hicimos?",
    nonAutoApprovedLabelExperienceIntroduction: "Realmente le agradeceríamos que se tomara el tiempo para calificarnos y dejar una reseña, ya que valoramos la opinión de nuestros clientes.",
    feedbackExcellent: "Excelente",
    feedbackGreat: "Bien",
    feedbackAverage: "Promedio",
    feedbackPoor: "Pobre",
    feedbackBad: "Malo",
    labelThankYouForFeedback: "Gracias por sus comentarios.",
    autoApprovedMessage1: "Estamos encantados de",
    autoApprovedMessage2: "Aprobar",
    autoApprovedMessage3: "su reembolso!",
    autoApprovedPayment1Day: "Su pago ya ha sido procesado y debe estar en su cuenta en solo 1 día hábil.",
    autoApprovedPayment2to4Days: "Su pago ya ha sido procesado y debería estar en su cuenta en solo 2-4 días hábiles.",
    autoApprovedPayment7to10Days: "Su pago ya ha sido procesado y debería estar en su cuenta en solo 7-10 días hábiles.",
    autoApprovedLabelExperience: "¿Cómo lo hicimos?",
    autoApprovedLabelExperienceIntroduction: "Realmente le agradeceríamos que se tomara el tiempo para calificarnos y dejar una reseña, ya que valoramos la opinión de nuestros clientes.",
    modalLabelNegative: "Sus comentarios son realmente importantes para nosotros, háganos saber si también desea que nos pongamos en contacto con usted.",
    placeholderFeedback: "Retroalimentación",
    messageFeedbackSubmitted: "Comentarios enviados",
    reviewInstructionMessage: "5 estrellas es excelente, 4 estrellas es genial, 3 estrellas es promedio, 2 estrellas es pobre, 1 estrella es mala."
}