export default {
    messageQuestionService: "Ce service est uniquement à utiliser si vous rencontrez un problème pour remplir le formulaire de demande, nous ne pouvons pas répondre à d'autres questions ici.",
    labelName: "Nom",
    labelReference: "Référence de réservation",
    labelPhone: "Numéro de téléphone",
    labelEmail: "E-mail",
    labelQuery: "Ta question",
    labelAskQuestion: "Envoyez-nous une question ici ...",
    buttonOpenModal: "Question",
    title: "Poser une question",
    modalTitle: "4. Vous avez un problème technique avec le formulaire?",
    toastSuccess: "Nous avons reçu votre question et nous vous contacterons dans les 24 heures",
    toastError: "Une erreur s'est produite avec votre demande, veuillez réessayer",
    nameValidation: "Le nom est requis",
    referenceValidation: "Une référence est requise",
    questionValidation: "Votre question est obligatoire"
}