import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section'
import {nameof} from 'ts-simple-nameof';

export const SectionFieldLookupOfflinePayment: { [p: string]: Section } = {
    [nameof<IFormValues>(x => x.additionalComments)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.beneficiaryAddress1)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.beneficiaryCity)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.beneficiaryPostCode)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.beneficiaryRegion)]: Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.bookingConfirmationFiles)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.bookingReference)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.bookingValue)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.confirmEmailAddress)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.contactNumber)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.contactNumberCountryCode)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.currency)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.customerName)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.dateOfEvent)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.dateOfPurchase)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.emailAddress)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.eventName)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.flightNumber)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.hasAgreedTermsAndConditions)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.homeCountry)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.hotelLocation)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.hotelName)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.numberOfTicketsToRefund)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.originAirport)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.reasonForNotificationOutsideTimeLimit)]: Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate1)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate2)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate3)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate4)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate5)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate6)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeDate7)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect1)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect2)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect3)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect4)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect5)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect6)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelect7)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText1)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText2)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText3)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText4)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText5)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText6)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeSelectText7)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText1)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText2)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText3)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText4)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText5)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText6)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonBespokeText7)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonCategory)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonConfirm)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonFiles)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.refundReasonFilesSecondary)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.totalRefundAmount)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.typeOfBooking)] : Section.BookingAndContactInformation,
    [nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit)] : Section.RefundRequest,
    [nameof<IFormValues>(x => x.venueName)] : Section.BookingAndContactInformation
}