import * as React from 'react';
import {
    createContext,
    useContext,
    useState
} from 'react';

export const useQuestionContext = () => useContext(Context);

export const Context = createContext<IModalContextValues>({
    openModal(): void { },
    isOpen: false,
    setIsOpen: (value: boolean) => void {},
    handleClose: void {}
});

export const QuestionProvider = ({ children }: Props) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Context.Provider value={{
            openModal,
            isOpen,
            setIsOpen,
            handleClose
        }}>
            {children}
        </Context.Provider>
    );
}