import * as React from 'react';
import {
    Button,
    ThemeProvider
} from '@mui/material';
import {buttonTheme} from '../themes/ButtonThemeProvider';

interface CloseButtonProps {
    buttonText: string;
    handleOnClick: () => void;
}

export const CloseButton = (props: CloseButtonProps) => {

    const {
        buttonText,
        handleOnClick
    } = props;

    return (
        <ThemeProvider theme={buttonTheme}>
            <Button
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #9B9B9B',
                    color: 'black'
                }}
                onClick={handleOnClick}>
                {buttonText}
            </Button>
        </ThemeProvider>
    );
}