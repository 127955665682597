export default {
    nonAutoApprovedLabelThankYou: "Thank you for your Refund Application.",
    nonAutoApprovedLabelReview: "Our team will review your application within the next two business days and get back to you with a response.",
    nonAutoApprovedLabelExperience: "How did we do?",
    nonAutoApprovedLabelExperienceIntroduction: "We’d really appreciate you taking the time to rate us and leave a review as we value the opinion of our customers.",
    feedbackExcellent: "Excellent",
    feedbackGreat: "Great",
    feedbackAverage: "Average",
    feedbackPoor: "Poor",
    feedbackBad: "Bad",
    labelThankYouForFeedback: "Thank you for your feedback.",
    autoApprovedMessage1: "We’re delighted to",
    autoApprovedMessage2: "Approve",
    autoApprovedMessage3: "your refund!",
    autoApprovedPayment1Day: "Your payment has already been processed and should be in your account in just 1 business day.",
    autoApprovedPayment2to4Days: "Your payment has already been processed and should be in your account in just 2-4 business days.",
    autoApprovedPayment7to10Days: "Your payment has already been processed and should be in your account in just 7-10 business days.",
    autoApprovedLabelExperience: "How did we do?",
    autoApprovedLabelExperienceIntroduction: "We’d really appreciate you taking the time to rate us and leave a review as we value the opinion of our customers.",
    modalLabelNegative: "Your feedback is really important to us, let us know if you'd also like us to get in touch with you.",
    placeholderFeedback: "Feedback",
    messageFeedbackSubmitted: "Feedback submitted",
    reviewInstructionMessage: "5 stars is excellent, 4 stars is great, 3 stars is average, 2 stars is poor, 1 star is bad."
}