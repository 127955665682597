export default {
    nonAutoApprovedLabelThankYou: "Dziękujemy za złożenie wniosku o zwrot pieniędzy.",
    nonAutoApprovedLabelReview: "Nasz zespół rozpatrzy Twoją aplikację w ciągu najbliższych dwóch dni roboczych i skontaktuje się z Tobą z odpowiedzią.",
    nonAutoApprovedLabelExperience: "Jak nam poszło?",
    nonAutoApprovedLabelExperienceIntroduction: "Naprawdę będziemy wdzięczni za poświęcenie czasu na ocenę nas i pozostawienie recenzji, ponieważ cenimy opinię naszych klientów.",
    feedbackExcellent: "Doskonałe",
    feedbackGreat: "Wielki",
    feedbackAverage: "Średnia",
    feedbackPoor: "Biedny",
    feedbackBad: "Zły",
    labelThankYouForFeedback: "Dziękujemy za Twoją opinię.",
    autoApprovedMessage1: "Cieszymy się, że",
    autoApprovedMessage2: "Zatwierdzić",
    autoApprovedMessage3: "Twój zwrot!",
    autoApprovedPayment1Day: "Twoja płatność została już przetworzona i powinna znaleźć się na Twoim koncie w ciągu zaledwie 1 dnia roboczego.",
    autoApprovedPayment2to4Days: "Twoja płatność została już przetworzona i powinna znaleźć się na Twoim koncie w ciągu zaledwie 2-4 dni roboczych.",
    autoApprovedPayment7to10Days: "Twoja płatność została już przetworzona i powinna znaleźć się na Twoim koncie w ciągu zaledwie 7-10 dni roboczych.",
    autoApprovedLabelExperience: "Jak nam poszło?",
    autoApprovedLabelExperienceIntroduction: "Naprawdę będziemy wdzięczni za poświęcenie czasu na ocenę nas i pozostawienie recenzji, ponieważ cenimy opinię naszych klientów.",
    modalLabelNegative: "Twoja opinia jest dla nas bardzo ważna, daj nam znać, jeśli chcesz, abyśmy się z Tobą skontaktowali.",
    placeholderFeedback: "Sprzężenie zwrotne",
    messageFeedbackSubmitted: "Przesłana opinia",
    reviewInstructionMessage: "5 gwiazdek jest doskonałe, 4 gwiazdki są świetne, 3 gwiazdki to średnia, 2 gwiazdki to słaba, 1 gwiazdka jest zła."
}