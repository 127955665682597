import * as React from 'react';
import {Alert} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {AlertColor} from '@mui/material/Alert/Alert';
import {BorderRadiusAlert} from '../utils/constants-styling';

interface AlertHolderProps {
    content: JSX.Element;
    severity: AlertColor;
}

export const AlertHolder = (props: AlertHolderProps) => {

    const {
        content,
        severity
    } = props;

    return (
        <Grid container pb={2} justifyContent='center'>
            <Grid size={{xs: 12, md: 8}}>
                <Alert severity={severity}
                       sx={{
                           borderRadius: BorderRadiusAlert
                       }}>
                    {content}
                </Alert>
            </Grid>
        </Grid>
    );
}