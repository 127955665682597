import * as React from 'react';
import {
    CurrencyCountryInvalidState,
    EvidenceResubmissionState,
    LanguageSelectorModalState
} from '../../state/activity';
import {FormikProps} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from './FormProvider';
import {useEffect} from 'react';
import {getQueryParamValue} from '../utils/helpers';
import {useSetRecoilState} from 'recoil';
import {Section} from '../enums/section';

export interface ILoader {
    formProps: FormikProps<IFormValues>;
    url: string;
}

export const Loader = (props: ILoader) => {

    const {
        formProps,
        url
    } = props;

    const {
        fetchBookingData,
        loadApplication,
        setUrl,
    } = useFormContext();
    
    const setEvidenceResubmission = useSetRecoilState(EvidenceResubmissionState);
    const setLanguageSelectorModalState = useSetRecoilState(LanguageSelectorModalState);
    const setCurrencyCountryInvalid = useSetRecoilState(CurrencyCountryInvalidState);

    const loadOnUrl = (): void => {

        const urlToLowerCase = url.toLowerCase();

        if (urlToLowerCase.includes('bookingreference')) {

            const urlPath = url.split('?')[0];

            formProps.values.bookingReference = getQueryParamValue('bookingreference', urlToLowerCase);
            setLanguageSelectorModalState(false);
            fetchBookingData(formProps, getQueryParamValue('memberid', urlToLowerCase), urlPath);
            setUrl(urlPath);
            return;
        }

        if (urlToLowerCase.includes('id') && urlToLowerCase.includes('email')) {

            let pageSection: Section;
            formProps.values.applicationReference = getQueryParamValue('id', urlToLowerCase);
            formProps.values.emailAddress = getQueryParamValue('email', urlToLowerCase);
            setLanguageSelectorModalState(false);

            if (urlToLowerCase.includes('step')) {
                const section = getQueryParamValue('step', urlToLowerCase);
                switch (section) {
                    case Section[Section.RefundRequest].toLowerCase():
                        pageSection = Section.RefundRequest;
                        break;
                    case Section[Section.PaymentInformation].toLowerCase():
                        pageSection = Section.PaymentInformation;
                        break;
                }
            } else {
                pageSection = Section.BookingAndContactInformation;
            }

            if (urlToLowerCase.includes('resubmission')) {
                setEvidenceResubmission(true);
            }

            if (urlToLowerCase.includes('currencycountryinvalid')) {
                setCurrencyCountryInvalid(true);
            }

            const urlPath = url.split('?')[0];
            setUrl(urlPath);
            loadApplication(formProps, pageSection, urlPath);
        } else {
            setUrl(url);
        }
    };

    useEffect(loadOnUrl, [url]);

    return <></>;
}