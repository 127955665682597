import { TFunction } from 'i18next';
import * as Yup from 'yup'

export const modalSchema = (t: TFunction) => 

        Yup.object({
            name: Yup.string().required(t('modal:nameValidation')),
            reference: Yup.string().required(t('modal:referenceValidation')),
            email: Yup.string().trim().email(t('form:validationEmailAddressFormat'))
                .required(t('form:validationEmailAddress')),
            query: Yup.string().required(t('modal:questionValidation'))
        });