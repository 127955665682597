export default {
    headingAdverseWeather: "Jūs piedzīvojat smagus nelabvēlīgus laika apstākļus, kuru dēļ ceļošana uz pasākumu ir nedroša, un to ir pilnvarojusi valdības iestāde (piemēram, vietējā iestāde, policija utt.). Un jums ir pierādījumi, kas to pierāda?",
    headingAdverseWeatherDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    tooltipRefundReasonConfirm: "Jā vai nē",
    labelBookingCancelledByOperator: "Operators/organizators anulēja biļetes.",
    labelBookingRescheduledByOperator: "Datumu mainīja Operators/Organizators, un es nevaru apmeklēt jaunos datumus.",
    labelBookedWrongDate: "Es vai rezervācijas dalībnieks, pērkot biļetes, pieļāvu kļūdu un rezervēju nepareizu datumu.",
    labelBookedWrongTickets: "Es vai rezervācijas dalībnieks, pērkot biļetes, pieļāvu kļūdu un rezervēju nepareizas biļetes.",
    labelBookedTooManyTickets: "Es vai rezervācijas dalībnieks, pērkot biļetes, pieļāvu kļūdu un rezervēju pārāk daudz biļešu.",
    labelCOVID19TravelRestrictions: "Es vai rezervācijas dalībnieks nevaru ierasties COVID-19 ceļošanas aizlieguma dēļ.",
    labelADWrongSelection: "Iespējams, esat izvēlējies nepareizu atmaksas iemeslu. Lūdzu, vēlreiz pārskatiet atmaksas iemesla nolaižamo sarakstu.",
    labelAdverseWeather1: "Kāpēc nelabvēlīgu laikapstākļu dēļ jūs nevarējāt apmeklēt pasākumu?",
    tooltipAdverseWeather1: "Lūdzu, precizējiet",
    labelAdverseWeather2: "Incidenta datums",
    tooltipAdverseWeather2: "Lūdzu, izvēlieties",
    headingArmedForces: "Jūsu darba devējs, kas ir bruņotie spēki vai avārijas dienesti, lūdz jūs ierasties darbā, kad jums sākotnēji nebija plānots to darīt, un tas liedz jums apmeklēt pasākumu?",
    headingArmedForcesDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelArmedForces1: "Kādos spēkos vai avārijas dienestā jūs strādājat?",
    tooltipArmedForces1: "Lūdzu, izvēlieties",
    labelArmedForcesOther1: "Lūdzu, norādiet",
    tooltipArmedForcesOther1: "Lūdzu, norādiet",
    labelArmedForces2: "Incidenta datums",
    tooltipArmedForces2: "Lūdzu, izvēlieties",
    headingChangesToExam: "Jūsu skolas / koledžas eksāmenu datumus ir mainījusi utt.? Tas nav neveiksmīgs eksāmens, kas jums jākārto atkārtoti?",
    headingChangesToExamDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelExam1: "Kāds bija sākotnējais pārbaudes datums?",
    tooltipExam1: "Lūdzu, izvēlieties",
    labelExam2: "Kāds ir jaunais izmeklējuma datums?",
    tooltipExam2: "Lūdzu, izvēlieties",
    labelExam3: "Kāpēc mainījās izmeklējuma datums?",
    tooltipExam3: "Lūdzu, ievadiet informāciju",
    labelExam4: "Vai neizturējāt iepriekšējo eksāmenu?",
    tooltipExam4: "Jā vai nē",
    headingCourtSummons: "Jūs esat uzaicināts ierasties tiesā kā liecinieks tajos pašos datumos, kad notika notikums?",
    headingCourtSummonsDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelCourtSummons1: "Kāds ir jūsu tiesas pavēstes iemesls?",
    tooltipCourtSummons1: "Lūdzu, izvēlieties",
    labelCourtSummonsOther1: "Lūdzu, norādiet",
    tooltipCourtSummonsOther1: "Lūdzu, norādiet",
    labelCourtSummons2: "Kad tika izsniegta tiesas pavēste?",
    tooltipCourtSummons2: "Lūdzu, izvēlieties",
    headingCOVID19Test: "Man, manas mājsaimniecības loceklim vai grupas dalībniekam, kas apmeklē pasākumu, ir diagnosticēts Covid-19?",
    headingCOVID19TestDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelCOVID19Test1: "Kam ir Covid-19?",
    tooltipCOVID19Test1: "Lūdzu, izvēlieties",
    labelCOVID19TestOther1a: "Lūdzu, norādiet",
    labelCOVID19TestOther1b: "Norādiet nosaukumu",
    tooltipCOVID19TestOther1a: "Lūdzu, norādiet",
    tooltipCOVID19TestOther1b: "Norādiet nosaukumu",
    labelCOVID19Test2: "Kāda veida Covid-19 tests jums bija?",
    tooltipCOVID19Test2: "Lūdzu, izvēlieties",
    labelCOVID19TestOther2: "Lūdzu, norādiet",
    tooltipCOVID19TestOther2: "Lūdzu, norādiet",
    labelCOVID19Test3: "Kāds bija jūsu Covid-19 testa datums?",
    tooltipCOVID19Test3: "Lūdzu, izvēlieties",
    headingDeath: "Ir bijusi mana ģimenes locekļa nāve, un tas man liedz apmeklēt pasākumu?",
    headingDeathDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelDeath1: "Kurš no jūsu ģimenes locekļiem ir miris?",
    tooltipDeath1: "Lūdzu, izvēlieties",
    labelDeathOther1: "Lūdzu, norādiet",
    tooltipDeathOther1: "Lūdzu, norādiet",
    labelDeath2: "Kad bija nāves datums?",
    tooltipDeath2: "Lūdzu, izvēlieties",
    labelDeath3: "Mirušā vārds un uzvārds",
    tooltipDeath3: "Lūdzu, norādiet",
    headingEmergency: "Man ir bijusi patiesa ārkārtas situācija, kas ir ārpus manas kontroles un neparedzēta, par kuru es varu sniegt pierādījumus?",
    headingEmergencyDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelEmergency1: "Lūdzu, paskaidrojiet, kāda veida ārkārtas situācija?",
    tooltipEmergency1: "Lūdzu, precizējiet",
    labelEmergency2: "Kad tas notika?",
    tooltipEmergency2: "Lūdzu, izvēlieties",
    labelEmergency3: "Kāpēc šī situācija neļauj jums apmeklēt pasākumu?",
    tooltipEmergency3: "Lūdzu, precizējiet",
    labelEmergency4: "Vai jums ir pierādījumi par šo ārkārtas situāciju?",
    tooltipEmergency4: "Jā vai nē",
    headingHomeEmergency: "Man ir ārkārtas situācija mājās, kas ir laupīšana, plūdi, ugunsgrēks, vandālisms, vardarbība ģimenē, kas neļauj man apmeklēt pasākumu?",
    headingHomeEmergencyDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelHomeEmergency1: "Kas ir ārkārtas situācija mājās?",
    tooltipHomeEmergency1: "Lūdzu, izvēlieties",
    labelHomeEmergencyOther1: "Lūdzu, norādiet",
    tooltipHomeEmergencyOther1: "Lūdzu, norādiet",
    labelHomeEmergency2: "Kad tas notika?",
    tooltipHomeEmergency2: "Lūdzu, izvēlieties",
    labelHomeEmergency3: "Kāpēc tas jums traucēja apmeklēt pasākumu?",
    tooltipHomeEmergency3: "Lūdzu, precizējiet",
    headingInjury: "Man, manas mājsaimniecības loceklim vai grupas dalībniekam, kas apmeklē pasākumu, ir diagnosticēts veselības stāvoklis vai trauma, kas tagad neļauj mums doties uz pasākumu?",
    headingInjuryDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelInjury1: "Kāds ir slimības/traumas/nelaimes gadījuma raksturs?",
    tooltipInjury1: "Lūdzu, precizējiet",
    labelInjury2: "Kad tas notika?",
    tooltipInjury2: "Lūdzu, izvēlieties",
    labelInjury3: "Vai esat apmeklējis medicīnas iestādi šīs slimības/traumas dēļ?",
    tooltipInjury3: "Jā vai nē",
    labelInjury4: "Vai tas bija jau esošs veselības stāvoklis?",
    tooltipInjury4: "Jā vai nē",
    labelInjury5: "Kad tas pirmo reizi tika diagnosticēts?",
    tooltipInjury5: "Lūdzu, izvēlieties",
    labelInjury6: "Kāpēc tas nozīmē, ka jūs nevarat apmeklēt pasākumu?",
    tooltipInjury6: "Lūdzu, precizējiet",
    labelInjury7: "Kas ir cietis no slimības/traumas?",
    tooltipInjury7: "Lūdzu, izvēlieties",
    headingJuryService: "Es vai kāds rezervācijas dalībnieks ir izsaukts uz Žūrijas dienestu?",
    headingJuryServiceDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelJuryService1: "Kad jums tika paziņots par Žūrijas dienestu?",
    tooltipJuryService1: "Lūdzu, izvēlieties",
    headingMechanicalBreakdown: "Mūsu transportlīdzeklim ir bijis mehānisks bojājums vai bijis negadījumā, un tas neļauj mums apmeklēt pasākumu?",
    headingMechanicalBreakdownDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelMechanicalBreakdown1: "Kas notika ar transportlīdzekli?",
    tooltipMechanicalBreakdown1: "Lūdzu, izvēlieties",
    labelMechanicalBreakdown2: "Kad tas notika?",
    tooltipMechanicalBreakdown2: "Lūdzu, izvēlieties",
    labelMechanicalBreakdown3: "Kāpēc jūs nevarējāt izmantot alternatīvu transportu?",
    tooltipMechanicalBreakdown3: "Lūdzu, precizējiet",
    headingPregnancyComplication: "Man vai rezervācijas dalībniekam ir grūtniecības komplikācijas, kuras ir diagnosticējis medicīnas speciālists un tāpēc neļauj apmeklēt pasākumu?",
    headingPregnancyComplicationDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelPregnancyComplication1: "Kāds ir grūtniecības komplikācijas raksturs?",
    tooltipPregnancyComplication1: "Lūdzu, precizējiet",
    labelPregnancyComplication2: "Kad tas notika?",
    tooltipPregnancyComplication2: "Lūdzu, izvēlieties",
    labelPregnancyComplication3: "Vai jūs meklējāt medicīnisko palīdzību?",
    tooltipPregnancyComplication3: "Jā vai nē",
    labelPregnancyComplication4: "Kāpēc tas nozīmē, ka jūs nevarat apmeklēt pasākumu?",
    tooltipPregnancyComplication4: "Lūdzu, precizējiet",
    headingPublicTransportFailure: "Sabiedriskā transporta metode (vilciens, autobuss, tramvajs, laiva utt.) negaidīti izgāzās, kas nozīmēja, ka nebija cita veida, kā es varētu doties uz šo pasākumu?",
    headingPublicTransportFailureDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelPublicTransportFailure1: "Kāda veida sabiedrisko transportu jūs plānojāt izmantot?",
    tooltipPublicTransportFailure1: "Lūdzu, izvēlieties",
    labelPublicTransportFailure2: "Kad jūs plānojāt ceļot?",
    tooltipPublicTransportFailure2: "Lūdzu, izvēlieties",
    labelPublicTransportFailure3: "Kas noticis?",
    tooltipPublicTransportFailure3: "Lūdzu, precizējiet",
    labelPublicTransportFailure4: "Kāpēc jūs nevarētu izmantot alternatīvu transportu?",
    tooltipPublicTransportFailure4: "Lūdzu, precizējiet",
    headingScheduledAirlineFailure: "Mans iepriekš rezervētais lidojums negaidīti tika atcelts, un es nevarēju atrast piemērotu alternatīvu, kas nozīmē, ka es nevarēju apmeklēt pasākumu?",
    headingScheduledAirlineFailureDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelScheduledAirlineFailure1: "Aviokompānijas nosaukums",
    tooltipScheduledAirlineFailure1: "Aviokompānijas nosaukums",
    labelScheduledAirlineFailure2: "Reisa numurs",
    tooltipScheduledAirlineFailure2: "Reisa numurs",
    labelScheduledAirlineFailure3: "Ceļojot no",
    tooltipScheduledAirlineFailure3: "Ceļojot no",
    labelScheduledAirlineFailure4: "Ceļojot uz",
    tooltipScheduledAirlineFailure4: "Ceļojot uz",
    labelScheduledAirlineFailure5: "Incidenta datums?",
    tooltipScheduledAirlineFailure5: "Lūdzu, izvēlieties",
    labelScheduledAirlineFailure6: "Kāpēc jūs nevarējāt veikt alternatīvu lidojumu?",
    tooltipScheduledAirlineFailure6: "Lūdzu, precizējiet",
    headingTheftOfDocuments: "Mani dokumenti tika nozagti, un nebija iespējams noorganizēt nomaiņu(-as) nepieciešamajā termiņā, tāpēc es nevaru apmeklēt pasākumu",
    headingTheftOfDocumentsDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelTheftOfDocuments1: "Kāda veida dokuments tika nozagts?",
    tooltipTheftOfDocuments1: "Lūdzu, izvēlieties",
    labelTheftOfDocuments2: "Kad tas notika?",
    tooltipTheftOfDocuments2: "Lūdzu, izvēlieties",
    labelTheftOfDocuments3: "Vai jūs mēģinājāt panākt, lai dokumenti tiktu izsniegti atkārtoti?",
    tooltipTheftOfDocuments3: "Jā vai nē",
    headingWorkRelocation: "Mans darba devējs ir pieprasījis man mainīt darba vietu vismaz uz trim mēnešiem, un attālums ir ievērojams, kas nozīmē, ka es tagad nevaru apmeklēt pasākumu?",
    headingWorkRelocationDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelWorkRelocation1: "Vai tā ir jūsu darba pagaidu vai pastāvīga pārcelšana?",
    tooltipWorkRelocation1: "Lūdzu, izvēlieties",
    labelWorkRelocation2: "Cik ilgi jūs atradīsieties jaunajā adresē?",
    tooltipWorkRelocation2: "Lūdzu, precizējiet",
    labelWorkRelocation3: "Kāda ir jūsu pašreizējā darba adrese?",
    tooltipWorkRelocation3: "Lūdzu, precizējiet",
    labelWorkRelocation4: "Kāda ir jūsu jaunā darba adrese?",
    tooltipWorkRelocation4: "Lūdzu, precizējiet",
    labelWorkRelocation5: "Kad jums tika paziņots par šo pārcelšanos?",
    tooltipWorkRelocation5: "Lūdzu, izvēlieties",
    headingRedundancy: "Mani vai rezervācijas dalībnieku ir atlaidis viņu darba devējs, kas nozīmē, ka es vairs nevaru apmeklēt rezervāciju?",
    headingRedundancyDecline: "Jums nevajadzētu turpināt šo pieteikumu, jo atmaksa, visticamāk, tiks noraidīta.",
    labelRedundancy1: "Nodarbinātības veids?",
    tooltipRedundancy1: "Lūdzu, izvēlieties",
    labelRedundancy2: "Cik ilgi jūs strādājat šajā uzņēmumā?",
    tooltipRedundancy2: "Lūdzu, izvēlieties",
    labelRedundancy3: "Vai atlaišana bija brīvprātīga?",
    tooltipRedundancy3: "Lūdzu, izvēlieties",
    labelRedundancy4: "Kad jums paziņoja par atlaišanu?",
    tooltipRedundancy4: "Lūdzu, izvēlieties",
    labelTestOption: "Atlasiet Jā, lai turpinātu:",
};