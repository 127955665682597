export default {
    labelBookingCancelledPostponedHeading: "Is uw boeking geannuleerd of uitgesteld? Lees meer",
    labelBookingCancelledPostponedText1: "Klik",
    labelBookingCancelledPostponedText2: "voor meer informatie.",
    labelNewRefundHeading: "Nieuwe aanvraag voor restitutie?",
    labelNewRefundText: "Vul hier uw boekingsreferentie in ...",
    placeholderBookingReference: "Boekingsreferentie",
    validationBookingReference: "Boekingsreferentie is vereist",
    validationEmailAsBookingReference: "E-mailadres wordt niet geaccepteerd als boekingsreferentie",
    buttonFindBooking: "Vind boeking",
    labelEmailHeading: "Al begonnen?",
    labelEmailText: "Stuur een e-mailherinnering om uw aanvraag op te halen.",
    placeholderEmail: "E-mail",
    buttonEmailReminder: "E-mailherinnering",
    labelCustomerName: "Klantnaam",
    validationCustomerName: "Klantnaam is vereist",
    labelTypeOfBooking: "Type boeking",
    validationTypeOfBooking: "Type boeking is vereist",
    labelEventName: "Evenementnaam",
    labelVenueName: "Venue Name",
    labelFlightNumber: "Vluchtnummer",
    labelOriginAirport: "Origin Airport",
    labelHotelName: "Naam accommodatie",
    labelHotelLocation: "Accommodatie locatie",
    labelCurrency: "Valuta",
    validationCurrency: "Valuta is vereist",
    validationCountryCurrency: "De geselecteerde valuta moet de valuta van uw thuisland zijn",
    validationOfflinePayment: "We kunnen geen restituties verwerken voor uw valuta- en landselectie. Selecteer alternatieven",
    labelBookingValue: "Boekingswaarde",
    validationBookingValue: "Boekingswaarde is vereist",
    validationBookingValueAmount: "Moet groter zijn dan 0",
    labelDateOfPurchase: "Aankoopdatum",
    validationDateOfPurchase: "Aankoopdatum is vereist",
    labelEmailAddress: "E-mailadres",
    validationEmailAddress: "E-mailadres is vereist",
    validationEmailAddressFormat: "Onjuist e-mailformaat",
    labelConfirmEmailAddress: "Bevestig e-mailadres",
    validationConfirmEmailAddressFormat: "Onjuist e-mailformaat",
    validationConfirmEmailAddress: "Bevestiging e-mailadres is vereist",
    validationConfirmEmailAddressMatch: "E-mailadressen komen niet overeen",
    validationEmailDomain: "Deze e-mailprovider wordt niet geaccepteerd, gebruik een alternatief",
    validationDateOfEvent: "Datum van gebeurtenis is vereist",
    labelHomeCountry: "Land van herkomst",
    validationHomeCountry: "Thuisland is vereist",
    labelContactNumberCountryCode: "Contactnummer Landcode",
    validationContactNumberCountryCode: "Landnummer is vereist",
    labelContactNumber: "Contactnummer",
    validationContactNumber: "Contactnummer is vereist",
    labelAddressLine1: "Adresregel 1",
    labelAddressLine2: "Adresregel 2",
    labelCity: "Stad",
    labelZipCode: "Postcode",
    labelRegion: "Regio",
    validationRegion: "Regio is vereist",
    labelNumberOfTicketsToRefund: "Aantal terug te betalen tickets",
    validationNumberOfTicketsToRefund: "Aantal terug te betalen tickets is vereist",
    validationNumberOfTicketsToRefundValue: "Moet groter zijn dan 0",
    labelCostPerTicket: "Kosten van elk ticket",
    validationCostPerTicket: "Kosten per ticket zijn vereist",
    validationCostPerTicketValue: "Moet groter zijn dan 0",
    labelTotalRefundAmount: "Totaal restitutiebedrag",
    validationTotalRefundAmount: "Totaal restitutiebedrag is vereist",
    validationTotalRefundAmountValue: "Moet groter zijn dan 0",
    validationTotalRefundGreaterThanBookingValue: "Het totale restitutiebedrag kan niet groter zijn dan de bovenstaande boekingswaarde",
    labelBookingConfirmationFiles: "Upload boekingsbevestiging",
    validationBookingConfirmationFiles: "Voeg ten minste één bestand toe of klik op Opslaan en keer later terug als je de documenten hebt",
    validationEvidenceFilesPopup: "Bewijsmateriaal moet worden geüpload voordat het wordt ingediend. Als u op dit moment niet over het bewijs beschikt, sla dan de aanvraag op en stuur deze terug zodra u deze heeft. We accepteren elektronische kopieën, scannerkopieën en foto's.",
    validationBookingConfirmationFilesPopup: "De boekingsbevestiging moet worden geüpload voordat deze wordt verzonden. Als u de boekingsbevestiging nu niet heeft, sla dan de aanvraag op en keer terug zodra u deze heeft. We accepteren elektronische kopieën, scannerkopieën en foto's.",
    alertUploadDocuments: "Upload de relevante documentatie die in de e-mail wordt beschreven en ga dan verder met stap 3.",
    labelRefundReasonCategory: "Reden voor terugbetaling",
    validationRefundReasonCategory: "Reden voor terugbetaling is vereist",
    labelDateOfIncident: "Datum van incident",
    labelAdditionalComments: "Aanvullende opmerkingen",
    tooltipAdditionalComments: "Aanvullende opmerkingen",
    validationAdditionalComments: "Er zijn aanvullende opmerkingen nodig",
    labelUnableToNotifyWithinTimeLimit: "Niet mogelijk om binnen 60 dagen vanaf te melden",
    labelReasonForNotificationOutsideTimeLimit: "Melding buiten tijdslimiet toelichting",
    validationReasonForNotificationOutsideTimeLimit: "Een reden voor melding na 60 dagen is vereist",
    labelRefundReasonFiles: "Upload bewijs",
    validationRefundReasonFiles: "Voeg ten minste één bestand toe of klik op Opslaan en later terugkeren wanneer u de documenten hebt",
    validationTextScript: "Moet overeenkomen met Latijns schrift",
    optionTypeOfBooking1: "Evenement",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Vlucht",
    optionTypeOfBooking4: "Accommodatie",
    optionTypeOfBooking5: "Overdracht",
    optionTypeOfBooking6: "Parkeren",
    optionTypeOfBooking7: "Andere reizen",
    labelEventTypeDate: "Datum van gebeurtenis",
    labelEventTypeTour: "Datum van de tour",
    labelEventTypeFlight: "Datum van vlucht",
    labelEventTypeCheckIn: "Datum van inchecken",
    labelEventTypeTransfer: "Datum van overdracht",
    labelEventTypeParking: "Datum van parkeren",
    labelEventTypeOtherTravel: "Datum van reis",
    optionRefundReason11: "Slecht weer",
    optionRefundReason10: "Armed Forces & Emergency Services Recall",
    optionRefundReason15: "Wijzigingen in examendata",
    optionRefundReason17: "Reisbeperkingen COVID-19",
    optionRefundReason19: "COVID-19 positieve test",
    optionRefundReason20: "Boeking geannuleerd door operator/organisator",
    optionRefundReason21: "Boeking opnieuw gepland door operator/organisator",
    optionRefundReason22: "Verkeerde datum geboekt",
    optionRefundReason23: "Verkeerde tickets geboekt",
    optionRefundReason24: "Te veel tickets geboekt",
    optionRefundReason18: "Dagvaarding",
    optionRefundReason4: "Death",
    optionRefundReason16: "Noodsituaties",
    optionRefundReason14: "Overheidsreisverbod",
    optionRefundReason9: "Home Emergency",
    optionRefundReason1: "Ziekte / letsel / ongeval",
    optionRefundReason8: "Jurydienst",
    optionRefundReason7: "Mechanische storing",
    optionRefundReason2: "Reeds bestaande medische aandoening",
    optionRefundReason3: "Zwangerschapscomplicatie",
    optionRefundReason5: "Openbaar vervoer mislukt",
    optionRefundReason6: "Vluchtverstoring",
    optionRefundReason13: "Diefstal van Document(en)",
    optionRefundReason12: "Werkverplaatsing",
    optionRefundReason25: "Redundantie op de werkplek",
    optionRefundReason26: "Testoptie",
    optionRefundReasonConfirm1: "Nee",
    optionRefundReasonConfirm2: "Ja",
    optionRefundReasonArmedForces1: "Leger",
    optionRefundReasonArmedForces2: "Marine",
    optionRefundReasonArmedForces3: "Luchtmacht",
    optionRefundReasonArmedForces4: "Speciale eenheden",
    optionRefundReasonArmedForces5: "Militair",
    optionRefundReasonArmedForces6: "Brand",
    optionRefundReasonArmedForces7: "Ambulance",
    optionRefundReasonArmedForces8: "Arts/Verpleegkundige",
    optionRefundReasonArmedForces9: "Tandarts",
    optionRefundReasonArmedForces10: "Ander",
    optionRefundReasonCourtSummons1: "Strafrechtelijke vervolging",
    optionRefundReasonCourtSummons2: "Civiele rechtszaak",
    optionRefundReasonCourtSummons3: "Getuige in een vervolging",
    optionRefundReasonCourtSummons4: "Getuige in een civiele zaak",
    optionRefundReasonCourtSummons5: "Aanklager",
    optionRefundReasonCourtSummons6: "Gedaagde",
    optionRefundReasonCourtSummons7: "Ander",
    optionRefundReasonDeath1: "Man",
    optionRefundReasonDeath2: "Vrouw",
    optionRefundReasonDeath3: "Geluid",
    optionRefundReasonDeath4: "Dochter",
    optionRefundReasonDeath5: "Vader",
    optionRefundReasonDeath6: "Moeder",
    optionRefundReasonDeath7: "Broer",
    optionRefundReasonDeath8: "Zus",
    optionRefundReasonDeath9: "Opa",
    optionRefundReasonDeath10: "Oma",
    optionRefundReasonDeath11: "Ander",
    optionRefundReasonHomeEmergency1: "Brand",
    optionRefundReasonHomeEmergency2: "Overstroming",
    optionRefundReasonHomeEmergency3: "Beroving",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Huiselijk geweld",
    optionRefundReasonHomeEmergency6: "Ontvoeren",
    optionRefundReasonHomeEmergency7: "Ander",
    optionRefundReasonCOVID19Person1: "Mijzelf",
    optionRefundReasonCOVID19Person2: "Een andere persoon in de boeking",
    optionRefundReasonCOVID19Person3: "Een persoon in mijn huishouden",
    optionRefundReasonCOVID19Person4: "Ander",
    optionRefundReasonCOVID19TestType1: "PCR-test",
    optionRefundReasonCOVID19TestType2: "RAT-test",
    optionRefundReasonCOVID19TestType3: "Laterale flowtest",
    optionRefundReasonCOVID19TestType4: "Zelf afgenomen test",
    optionRefundReasonCOVID19TestType5: "Geen test maar ik heb wel symptomen",
    optionRefundReasonCOVID19TestType6: "Ander",
    optionRefundReasonBreakdown1: "Panne",
    optionRefundReasonBreakdown2: "Ongeluk",
    optionRefundReasonBreakdown3: "Brand",
    optionRefundReasonBreakdown4: "Diefstal",
    optionRefundReasonPublicTransportType1: "Trein",
    optionRefundReasonPublicTransportType2: "Bus",
    optionRefundReasonPublicTransportType3: "Tram",
    optionRefundReasonPublicTransportType4: "Boot",
    optionRefundReasonDocumentType1: "Paspoort",
    optionRefundReasonDocumentType2: "Reisvergunning",
    optionRefundReasonDocumentType3: "Nationaal identiteitsbewijs",
    optionRefundReasonDocumentType4: "Tickets",
    optionRefundReasonWorkRelocationType1: "Tijdelijk",
    optionRefundReasonWorkRelocationType2: "Bestendig",
    optionRefundReasonWorkRelocationTemporary1: "0-3 maanden",
    optionRefundReasonWorkRelocationTemporary2: "3-6 maanden",
    optionRefundReasonWorkRelocationTemporary3: "Meer dan 6 maanden",
    optionRefundReasonBookingRelationshipType1: "Iemand in de boeking",
    optionRefundReasonBookingRelationshipType2: "Direct familielid van iemand in de boeking",
    optionEmploymentType1: "Voltijds",
    optionEmploymentType2: "Parttime",
    optionEmploymentType3: "Contract",
    optionEmploymentLengthType1: "0-1 jaar",
    optionEmploymentLengthType2: "1-2 jaar",
    optionEmploymentLengthType3: "Meer dan 2 jaar",
    textRefundEvidenceHeading: "Geef het volgende bewijs aan:",
    textRefundEvidence1: "Bevestiging van luchtvaartmaatschappij",
    textRefundEvidence2: "Passend ondersteunend bewijs",
    textRefundEvidence3: "Storingsdienst oproepbevestiging",
    textRefundEvidence4: "Overlijdensakte",
    textRefundEvidence5: "Bevestiging annulering van luchtvaartmaatschappij",
    textRefundEvidence6: "Weerbericht",
    textRefundEvidence7: "Correspondentie van boekingsagent die bevestigt dat er geen mogelijkheid is om tickets opnieuw uit te geven",
    textRefundEvidence8: "Brief van het Hof",
    textRefundEvidence9: "Medisch certificaat / doktersbriefje",
    textRefundEvidenceDescription9: "Het bevestigen van details van de ziekte of het letsel, de datum waarop het zich voor het eerst voordeed, dat het voorkomt dat u de boeking bijwoont.",
    textRefundEvidence10: "Bevestiging werkgeversbrief",
    textRefundEvidence11: "Correspondentie over verzekeringsclaims",
    textRefundEvidence12: "Brief van werkgever",
    textRefundEvidence13: "Bevestiging van de website van de rijksoverheid",
    textRefundEvidence14: "Kennisgeving van wijziging van het onderzoek",
    textRefundEvidence15: "Proces-verbaal",
    textRefundEvidence16: "Proces-verbaal en referentienummer",
    textRefundEvidence17: "Bevestiging van de transporteur",
    textRefundEvidence18: "Reiswaarschuwing",
    textRefundEvidence20: "COVID-19 Test Certificaat",
    textRefundEvidence24: "Kennisgeving van isolatie door de overheid",
    textRefundEvidence25: "Brief van bevelvoerend officier",
    textRefundEvidence26: "Brief van verzekeraars",
    textRefundEvidence27: "Bewijs van adres",
    textRefundEvidence28: "Bewijs van relatie met de overledene",
    textRefundEvidence29: "Bewijs van relatie met de gewonde/zieke persoon",
    textRefundEvidence30: "Loonstrook",
    tooltipBookingReference: "Om uw boekingsreferentie te identificeren, controleert u uw boekingsbevestiging en bekijkt u het gedeelte over onze uitgebreide service.",
    tooltipCustomerName: "Voor- en achternaam die is gebruikt om de boeking te maken.",
    tooltipTypeOfBooking: "Type boeking waarvoor u restitutie wilt ontvangen.",
    tooltipEventName: "De naam van de afspraak.",
    tooltipVenueName: "De naam van de locatie.",
    tooltipFlightNumber: "Het vluchtnummer.",
    tooltipOriginAirport: "De luchthaven van vertrek.",
    tooltipHotelName: "De naam van de accommodatie.",
    tooltipHotelLocation: "De locatie van de accommodatie.",
    tooltipCurrency: "De valuta die wordt gebruikt om de boeking te betalen.",
    tooltipBookingValue: "Totale prijs die u voor de boeking heeft betaald.",
    tooltipDateOfPurchase: "De datum waarop de boeking is gemaakt.",
    tooltipEmailAddress: "Gebruik een geldig e-mailadres. We gebruiken deze e-mail voor correspondentie tijdens de terugbetalingsaanvraag.",
    tooltipConfirmEmailAddress: "Gebruik hetzelfde e-mailadres als hierboven, dit is ter validatie.",
    tooltipHomeCountry: "Dit zal ons helpen om bij de laatste stap de juiste bankgegevens naar voren te brengen.",
    tooltipContactNumberCountryCode: "Landcode contactnummer.",
    tooltipContactNumber: "Gebruik een geldig nummer. We gebruiken dit voor correspondentie tijdens de terugbetalingsaanvraag.",
    tooltipAddressLine1: "Let op: uw adres wordt gebruikt bij het valideren van uw bankrekening.",
    tooltipAddressLine2: "Tweede adresregel.",
    tooltipCity: "Uw woonplaats, gegevens kunnen worden gebruikt voor het valideren van bankgegevens.",
    tooltipZipCode: "Postcode",
    tooltipRegion: "Regio van uw thuisstad, gegevens kunnen worden gebruikt voor het valideren van bankgegevens.",
    tooltipNumberOfTicketsToRefund: "Het aantal tickets dat u terugbetaald wilt krijgen.",
    tooltipTotalRefundAmount: "Dit kan niet meer zijn dan de totale boekingswaarde, anders kunt u de aanvraag niet indienen.",
    tooltipBookingConfirmationFiles: "Screenshot / bijlage van uw volledige boekingsbevestiging die meestal in uw e-mails te vinden is.",
    tooltipRefundReasonCategory: "Selecteer uw reden voor terugbetaling.",
    tooltipDateOfIncident: "De datum waarop het incident plaatsvond dat van invloed was op uw boeking.",
    tooltipRefundExplanation: "Geef alstublieft zoveel mogelijk details die de aanspraak op terugbetaling ondersteunen.",
    tooltipUnableToNotifyWithinTimeLimit: "Kan niet binnen 60 dagen op de hoogte stellen.",
    tooltipReasonForNotificationOutsideTimeLimit: "Melding buiten tijdslimiet Toelichting.",
    tooltipRefundReasonFiles: "Upload een van de vereiste documenten.",
    tooltipEventTypeDate: "Datum van het daadwerkelijke evenement / boeking.",
    tooltipEventTypeTour: "Datum van de daadwerkelijke tour.",
    tooltipEventTypeFlight: "Datum van de daadwerkelijke vlucht.",
    tooltipEventTypeCheckIn: "Datum van de daadwerkelijke check-in.",
    tooltipEventTypeTransfer: "Datum van de daadwerkelijke overdracht.",
    tooltipEventTypeParking: "Datum van de daadwerkelijke parkeerplaats.",
    tooltipEventTypeOtherTravel: "Startdatum van de daadwerkelijke reis."
};