import * as React from 'react';
import {Box} from '@mui/material';
import {
    createRef,
    useEffect,
    useState
} from 'react';
import {
    Formik,
    Form,
    FormikProps,
    useField
} from 'formik';
import {
    createEmailQuery,
    createEmailQueryNote
} from '../services/refundService';
import {useQuestionContext} from '../providers/QuestionProvider';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {modalSchema} from '../schemas/modalSchema';
import {toast} from 'react-toastify';
import {initialValues} from '../../state/defaultModalState';
import {TextInput} from '../formInputs';
import {useFormContext} from '../providers/FormProvider';
import {IFormValues} from '../Interfaces/IFormValues';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import {googleReCAPTCHAv3SiteKey} from '../../../config';
import {ModalHolder} from './ModalHolder';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {CloseButton} from '../buttons/CloseButton';
import {FormOffsetHolder} from '../ui/FormOffsetHolder';

interface AskQuestionModalProps {
    formProps: FormikProps<IFormValues>;
}

export const AskQuestionModal = (props: AskQuestionModalProps) => {

    const {
        formProps
    } = props;

    const {
        isOpen,
        setIsOpen
    } = useQuestionContext();

    const {
        applicationReference,
        saveForm
    } = useFormContext();

    const reCaptchaRef = createRef<ReCAPTCHA>();
    const {t} = useTranslation();
    const [queryNote, setQueryNote] = useState<IQueryNote>(null);
    const [customerNameField, customerNameMeta, customerNameHelper]
        = useField(nameof<IFormValues>(x => x.customerName));
    const [emailAddressField, emailAddressMeta, emailAddressHelper]
        = useField(nameof<IFormValues>(x => x.emailAddress));

    const onSubmit = async (queryNote: IQueryNote): Promise<void> => {

        const token = await reCaptchaRef?.current?.executeAsync();

        if (!token) {
            console.log('Failed to obtain captcha token')
            return;
        }

        if (formProps.values.bookingReference.trim() === '') {
            return;
        }

        if (formProps.values.customerName.trim() === '') {
            await customerNameHelper.setValue(queryNote.name);
            formProps.values.customerName = queryNote.name;
        }

        if (formProps.values.emailAddress.trim() === '') {
            await emailAddressHelper.setValue(queryNote.email);
            formProps.values.emailAddress = queryNote.email;
        }

        try {
            const emailQuery = await createEmailQuery(queryNote);
            const successfulEmailQuery = emailQuery.data.success;

            if (successfulEmailQuery) {
                toast.success(t('modal:toastSuccess'));
                saveForm(formProps);
                setQueryNote(queryNote);
            }

        } catch (err) {
            toast.error(t('modal:toastError'));
        } finally {
            setIsOpen(false);
        }
    };

    useEffect(() => {

        if (applicationReference === '' || queryNote === null) {
            return;
        }

        (async () => {
            try {
                await createEmailQueryNote(applicationReference, formProps.values.emailAddress, queryNote);
            } catch {
                console.log('Error saving note');
            }
        })();
    }, [applicationReference, queryNote]);
    
    const close = () => {
        setIsOpen(false);
    }

    const Content = () => {
        
        const ButtonContent = () => {
            return (
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonCancel')}
                        handleOnClick={close}
                    />

                    <ConfirmButton
                        buttonText={t('common:buttonSubmit')}
                        isSubmitButton
                    />
                </Box>
            );
        }

        return (
            <>
                <Formik
                    initialValues={initialValues(formProps)}
                    validationSchema={modalSchema(t)}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validateOnBlur
                >
                    <Form>
                        <Box sx={{
                            p: {
                                xs: 0,
                                sm: 5,
                                md: 5
                            }
                        }}>
                            <h5>{t('modal:title')}</h5>
                            <hr/>

                            <Box sx={{pb: 5}}>{t('modal:messageQuestionService')}</Box>

                            <TextInput
                                key={nameof<IQueryNote>(x => x.reference)}
                                controlId={nameof<IQueryNote>(x => x.reference)}
                                label={t('modal:labelReference')}
                                placeholder={t('modal:labelReference')}
                                disabled
                                required={false}
                                hidden={false}
                            />

                            <TextInput
                                key={nameof<IQueryNote>(x => x.name)}
                                controlId={nameof<IQueryNote>(x => x.name)}
                                placeholder={t('modal:labelName')}
                                label={t('modal:labelName')}
                                tooltip={t('form:validationCustomerName')}
                                required
                                hidden={false}
                            />

                            <TextInput
                                key={nameof<IQueryNote>(x => x.email)}
                                controlId={nameof<IQueryNote>(x => x.email)}
                                placeholder={t('modal:labelEmail')}
                                label={t('modal:labelEmail')}
                                tooltip={t('form:tooltipEmailAddress')}
                                required
                                hidden={false}
                            />

                            <TextInput
                                key={nameof<IQueryNote>(x => x.query)}
                                controlId={nameof<IQueryNote>(x => x.query)}
                                placeholder={t('modal:labelQuery')}
                                label={t('modal:labelQuery')}
                                required
                                hidden={false}
                                multiLine
                            />

                            <ReCAPTCHA
                                sitekey={googleReCAPTCHAv3SiteKey}
                                ref={reCaptchaRef}
                                size="invisible"
                            />

                            <FormOffsetHolder content={<ButtonContent/>}/>

                        </Box>
                    </Form>
                </Formik>
            </>
        );
    }

    return (
        <ModalHolder
            content={<Content/>}
            handleClose={close}
            showModal={isOpen}
            width='lg'
        />
    );
}