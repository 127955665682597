import * as React from 'react';
import {
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    SelectChangeEvent,
    Autocomplete,
    TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps
} from 'formik';
import {
    BorderRadiusInputField,
    MinWidthInputField
} from '../utils/constants-styling';
import {useEffect, useState} from 'react';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {IInputProps} from './IInputProps';
import {IFormValues} from '../Interfaces/IFormValues';
import {IDropDownItem} from '../Interfaces/IDropDownItem';
import {useTranslation} from 'react-i18next';
import {labelExpression} from '../utils/helpers';
import {isInvalid} from '../utils/validationHelpers';
import {ErrorMessage} from '../ui/ErrorMessage';
import {IAutoComplete} from '../Interfaces/IAutoComplete'
import {useGetSelectData} from '../../hooks/queries';

interface SelectInputProps extends IInputProps {
    datasourceUrl?: string;
    displayAsAutoComplete?: boolean;
    displayLabelAsTitle?: boolean;
    dropDownItemsInput?: IDropDownItem[];
    onChange?: (value: any) => void;
    prefixKey?: string;
}

export const SelectInput = (props: SelectInputProps) => {

    const {
        controlId,
        datasourceUrl,
        displayAsAutoComplete,
        displayLabelAsTitle = true,
        dropDownItemsInput,
        hidden,
        label,
        onChange,
        prefixKey,
        required,
        tooltip
    } = props;

    const {t} = useTranslation();
    const {selectData} = useGetSelectData(controlId, datasourceUrl);

    const [dropdownItems, setDropDownItems] = useState<IDropDownItem[]>([]);

    const getData = () => {

        if (dropDownItemsInput !== undefined) {
            setDropDownItems(dropDownItemsInput);
            return;
        }
    };

    const getAutoValue = (fieldProps: FieldProps<IFormValues>): any => {

        const value = fieldProps.form.getFieldProps(controlId).value;
        return getAutoCompleteData().find(x => x.id === value)?.label;
    }

    const getAutoCompleteData = (): IAutoComplete[] => {

        if (dropdownItems?.length > 0) {
            return dropdownItems.map((dataItem) => {
                return {id: dataItem.key, label: dataItem.value}
            });
        }

        return selectData.map((dataItem) => {
            return {id: dataItem.key, label: dataItem.value}
        });
    }

    const getDropDownData = (): IDropDownItem[] => {

        if (dropdownItems?.length > 0) {
            return dropdownItems;
        }

        return selectData;
    }

    const handleOnChange = (fieldProps: FieldProps<IFormValues>) => (event: SelectChangeEvent) => {

        updateValue(event.target.value, fieldProps);

        if (onChange !== undefined) {
            onChange(event);
        }
    }

    const handleOnChangeAutoComplete = (fieldProps: FieldProps<IFormValues>, value: IAutoComplete) => {

        const nextValue = updateValue(value?.id.toString(), fieldProps);

        if (onChange !== undefined) {
            onChange(nextValue);
        }
    }

    const updateValue = (value: string, fieldProps: FieldProps<IFormValues>) => {

        let nextValue: number | string = value;

        if (nextValue === undefined) {
            nextValue = '';
        }

        if (nextValue === '') {
            void fieldProps.form.setFieldValue(controlId, '');
            return nextValue;
        }

        if (!isNaN(+nextValue) && typeof nextValue === String(undefined) && !nextValue.startsWith('0')) {
            nextValue = +nextValue;
        }

        void fieldProps.form.setFieldValue(controlId, nextValue);
        return nextValue;
    }

    const defaultValueLabel =
        labelExpression(t('common:selectPrefix'),
            t('common:selectPostfix'),
            displayLabelAsTitle ? label : '');

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues>) => (
                        <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
                            <FormLabelHolder
                                label={label}
                                required={required}
                                tooltip={tooltip}
                            />
                            <Grid size={{xs: 6, md: 5}}>
                                <FormControl sx={{minWidth: MinWidthInputField}} size='small' fullWidth>
                                    {displayAsAutoComplete &&
                                        <>
                                            <Autocomplete
                                                id={controlId}
                                                multiple={false}
                                                disablePortal
                                                size='small'
                                                value={getAutoValue(fieldProps) || null}
                                                onChange={(_, value: IAutoComplete) =>
                                                    handleOnChangeAutoComplete(fieldProps, value)
                                                }
                                                options={getAutoCompleteData()}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                               name={controlId}
                                                               error={isInvalid(fieldProps)}
                                                               value={fieldProps.form.getFieldProps(controlId).value}
                                                               label=''
                                                    />}
                                                sx={{
                                                    'fieldset': {
                                                        borderRadius: BorderRadiusInputField
                                                    }
                                                }}
                                            />
                                            <ErrorMessage fieldProps={fieldProps}/>
                                        </>
                                    }
                                    {!displayAsAutoComplete &&
                                        <>
                                            <Select
                                                name={controlId}
                                                label={label}
                                                onChange={handleOnChange(fieldProps)}
                                                input={<OutlinedInput/>}
                                                error={isInvalid(fieldProps)}
                                                value={fieldProps.form.getFieldProps(controlId).value}
                                                sx={{
                                                    borderRadius: BorderRadiusInputField,
                                                    minWidth: MinWidthInputField
                                                }}>
                                                <MenuItem
                                                    value={fieldProps.form.getFieldProps(controlId).value}>{defaultValueLabel}
                                                </MenuItem>
                                                {getDropDownData().map(x =>
                                                    <MenuItem key={`${controlId}_${x.key}`} value={x.key}
                                                              sx={{
                                                                  whiteSpace: 'pre-wrap',
                                                                  borderBottom: '1px solid #ECECEC',
                                                                  minHeight: '36px'
                                                              }}>
                                                        {prefixKey != null ? t(prefixKey + x.key) : x.value}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                            <ErrorMessage fieldProps={fieldProps}/>
                                        </>
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                </Field>
            }
        </>
    );
}