export default {
    messageUnableToMakePaymentToCurrency: "Deja, negalime gauti banko duomenų apie pasirinktą valiutą. Įsitikinkite, kad pasirinkta valiuta ir buveinės šalis",
    messageOnlyBankAccountsAccepted: "Atkreipkite dėmesį: šiuo metu galime atlikti mokėjimus tik į banko sąskaitas (kredito ir debeto kortelės nepriimamos)",
    labelAccountName: "Sąskaitos savininko vardas ir pavardė",
    labelBankAccountName: "Sąskaitos turėtojo vardas ir pavardė",
    labelBankCardHolderName: "Kortelės turėtojo vardas ir pavardė",
    validationBankAccountName: "Būtina nurodyti sąskaitos turėtojo vardą ir pavardę",
    labelBankCountry: "Banko šalis",
    labelBankName: "Banko pavadinimas",
    labelPaymentType: "Mokėjimo tipas",
    labelInstitutionNumber: "Įstaigos numeris",
    labelBankAddress: "Banko adresas",
    labelBankAddress2: "Banko adresas",
    labelBankCity: "Banko miestas",
    labelBankState: "Banko valstybė",
    labelBankAccountNumber: "Banko sąskaitos numeris",
    labelBankAccountNumberCBU: "Sąskaitos numeris CBU",
    labelBankAccountNumberCCI: "Sąskaitos numeris CCI",
    labelBankAccountNumberZAR: "Banko sąskaitos numeris (7–11 skaitmenų)",
    labelCardNumber: "Kortelės numeris",
    labelUnionpay: "UnionPay kortelės numeris",
    labelAlipay: "Alipay ID (mobiliojo telefono numeris arba el. pašto adresas)",
    labelBankAccountType: "Sąskaitos tipas",
    optionBankAccountTypeCURRENT: "Einamoji sąskaita",
    optionBankAccountTypeCHECKING: "Atsiskaitomoji sąskaita",
    optionBankAccountTypeSAVING: "Taupomoji sąskaita",
    optionBankAccountTypeSAVINGS: "Taupomoji sąskaita",
    optionBankAccountTypeCUENTA_VISTA: "Pagrindinė sąskaita",
    optionBankAccountType1: "Atsiskaitomoji sąskaita",
    optionBankAccountType2: "Taupomoji sąskaita",
    optionBankAccountType3: "Pagrindinė sąskaita",
    optionBankAccountType4: "Vista paskyra",
    optionBankAccountType5: "RUT sąskaita",
    labelSortCode: "Rūšiuoti kodą",
    labelRoutingCode: "Maršruto parinkimo kodas",
    labelBankRoutingCodeNationalID: "Banko registracijos kodas / nacionalinis id",
    labelBankTransitCodeSwift: "Tranzito numeris",
    labelCNAPSCode: "Nukreipimo kodas / CNAPS kodas (Kinijos nacionalinės išankstinio mokėjimo sistemos kodas)",
    labelABAFedwireRoutingCode: "ACH maršruto numeris",
    labelIFSCCode: "IFSC kodas",
    labelBankCode: "Banko kodas",
    labelBIKCode: "BIK kodas",
    labelBranchCode: "Filialo kodas",
    labelBranchCodeUniversal: "Universalus 6 skaitmenų filialo kodas",
    labelRemittanceLine3: "Gavėjo kontaktinis vardas, pavardė ir telefono numeris",
    labelBeneficiaryContactNameandTelephoneNumber: "Gavėjo kontaktinis vardas, pavardė ir telefono numeris",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Paramos gavėjo gimimo data ir gimimo vieta",
    labelRemittanceLine4: "Individualus mokesčių mokėtojo numeris CUIL (9–12 skaitmenų)",
    labelCUILNumber: "Mokesčių mokėtojo ID: FLY / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Mokesčių ID RFC 13 skaitmenų arba CURP 18 skaitmenų",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Individualus mokesčių mokėtojo ID, CPF, individualaus mokesčių mokėtojo registracija (11-14 skaitmenų), filialo kodas",
    labelCFPNumber: "CPF numeris (11–14 skaitmenų)",
    labelBranchNameorBranchCode7digits: "Filialo pavadinimas arba filialo kodas (7 skaitmenys)",
    labelCedulaJuridica910digitsTaxID: "Teisinis pažymėjimas (9-10 skaitmenų mokesčių mokėtojo ID)",
    labelTaxIDRUT89digits: "Mokesčių mokėtojo ID RUT (8-9 skaitmenys)",
    labelJob: "Pareigų pavadinimas",
    labelDateOfBirth: "Gimimo data",
    labelNationality: "Pilietybė",
    labelDocumentType: "Dokumento tipas",
    labelDocumentNumber: "Dokumento numeris",
    labelRutNumber: "RUT numeris (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (mokesčių mokėtojo ID 8-11 skaitmenų)",
    labelCIIndividualTaxIDBranchLocation: "CI (individualaus mokesčio ID), filialo buveinė",
    labelTaxIDRUCorCI: "Mokesčių mokėtojo ID (RUC arba CI)",
    labelSortCodeHelp: "Pagalba?",
    labelBranchCodeHelp: "Pagalba?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Pagalba?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Pagalba?",
    labelRoutingCodeBSB: "Maršruto parinkimo kodas / BSB",
    labelBSB: "Banko valstybinis skyrius (BSB)",
    labelSKNCode: "SKN kodas",
    labelAcceptanceFlights: "Aš (mes) sutinku, kad jei prašymas grąžinti pinigus bus patenkintas, užsakymas, įskaitant visus skrydžius ir papildomus produktus, bus atšauktas.",
    labelAcceptanceTickets: "Aš (mes) sutinku, kad jei prašymas grąžinti pinigus bus patenkintas, užsakymas, įskaitant visus bilietus ir papildomus produktus, bus atšauktas.",
    labelTermsAndConditions1: "Aš (mes) sutikau (-ame) su",
    labelTermsAndConditions2: "Taisyklės ir sąlygos",
    labelFraudWarning1: "Manau, kad šioje prašymo grąžinti pinigus formoje pateikti faktai yra tikri ir teisingi. Suprantu, kad bet kokia melaginga informacija ar įrodymai gali būti laikomi nusikalstama veika ir perduodami policijai ar kitoms teisėsaugos institucijoms persekioti.",
    labelFraudWarning2: "Jei įtarsime sukčiavimą, susijusį su patvirtinamaisiais dokumentais, šis klausimas bus nedelsiant perduotas vietos policijos institucijoms. Sutinku ir suprantu, kad mano duomenys bus perduoti šioms institucijoms, jei mano prašymas bus laikomas apgaulingu ir tai gali tapti baudžiamuoju tyrimu.",
    validationTermsAndConditions: "Turi būti susitarta dėl taisyklių ir sąlygų",
    messageFailbankName: "Banko pavadinimas negali būti tuščias",
    messageFailcustomerName: "Kliento vardas negali būti tuščias",
    messageFailbankAccountNumber: "Privalomas laukas",
    messageFailbankAccountNumberFormat: "Turi būti IBAN formato",
    messageFailsortCode: "Privalomas laukas",
    messageFailbankSwiftBic: "Naudos gavėjas SWIFT BIC negali būti tuščias",
    messageFailbankSwiftBicFormat: "Turi būti SWIFT formato",
    messageFailbeneficiaryAddress1: "1 adresas negali būti tuščias",
    messageFailbeneficiaryCity: "Miestas negali būti tuščias",
    messageFailbeneficiaryPostCode: "Pašto kodas negali būti tuščias",
    messageFailremittanceLine3: "Privalomas laukas",
    messageFailremittanceLine4: "Privalomas laukas",
    modalHeading: "Patvirtinkite išsamią informaciją",
    modalLabelPleaseConfirmYourDetails: "Patvirtinkite savo duomenis",
    modalValidationPleaseConfirm: "Prašome patvirtinti",
    modalValidationMustMatch: "Turi atitikti ankstesnį įrašą",
    tooltipBankAccountName: "Jūsų vardas, rodomas jūsų banko sąskaitoje / banko išrašuose / banko kortelėje.",
    tooltipBankAccountNameRecipient: "Gavėjo vardas ir pavardė. Tiek vardas, tiek pavardė turi turėti daugiau nei vieną simbolį.",
    tooltipBankCountry: "Šalis, kurioje yra jūsų bankas.",
    tooltipBankName: "Jūsų banko pavadinimas.",
    tooltipPaymentType: "Jums reikalingo mokėjimo tipas.",
    tooltipInstitutionNumber: "Tai yra 3 skaitmenys ir paprastai randami jūsų mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į savo banką.",
    tooltipBankAccountNumber: "Jūsų asmeninis sąskaitos numeris. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankAccountNumberCBU: "22 skaitmenų numeris, naudojamas jūsų paskyrai identifikuoti. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankAccountNumberCCI: "20 skaitmenų numeris, naudojamas jūsų paskyrai identifikuoti. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankAccountNumberKRW: "11–16 skaitmenų numeris, naudojamas paskyrai identifikuoti. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCardNumber: "Jūsų kortelės numeris. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipUnionpay: "Jūsų UnionPay kortelės numeris.",
    tooltipAlipay: "Jūsų mobiliojo telefono numeris arba el. pašto adresas, susietas su jūsų Alipay paskyra.",
    tooltipBankAccountType: "Tai yra tikrinimas arba taupymas.",
    tooltipSortCode: "Taip identifikuojamas banko filialas, kuriame yra jūsų sąskaita. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipRoutingCode: "Taip identifikuojamas banko filialas, kuriame yra jūsų sąskaita. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBeneficiarySWIFTBIC: "Susideda iš 8-11 simbolių, naudojamų bankui identifikuoti tarptautinėje operacijoje. Paprastai tai randama jūsų mėnesio ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBSB: "Banko valstybinio filialo (BSB) numeris yra Australijos rūšies kodas, kurį sudaro 6 skaitmenys. Paprastai tai randama jūsų mėnesio ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipRoutingCodeBSB: "Taip identifikuojamas banko filialas, kuriame yra jūsų sąskaita. Paprastai tai rasite mėnesio ataskaitoje ar banko kortelėje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipABAFedwireRoutingCode: "9 skaitmenų numeris, naudojamas bankams Amerikoje identifikuoti. Paprastai tai randama jūsų mėnesio ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipIFSCCode: "Paprastai šį kodą galima rasti mėnesio ataskaitoje arba knygelėje, o jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipTermsAndConditions: "Sutikdami su taisyklėmis ir nuostatomis, patvirtinate, kad perskaitėte ir sutinkate su mūsų taisyklėmis ir nuostatomis.",
    tooltipIBAN: "Jūsų tarptautinis banko sąskaitos numeris, kurį galite naudoti atlikdami ar gaudami tarptautinius mokėjimus. Paprastai tai randama jūsų mėnesio ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Gavėjo kontaktinis vardas, pavardė ir telefono numeris.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Paramos gavėjo gimimo data ir gimimo vieta.",
    tooltipBankRoutingCodeNationalID: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankTransitCodeSwift: "Tai yra 5 skaitmenys ir paprastai juos galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankAddress: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankAddress2: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankCity: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankState: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBranchName: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBankCode: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBIKCode: "9 skaitmenys bankui identifikuoti.",
    tooltipBranchCode: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBranchCodeUniversal: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCNAPSCode: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipRemittanceLine4: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCUILNumber: "Tai 11 skaitmenų skaičius, susidedantis iš dviejų skaitmenų, brūkšnelio, devynių skaitmenų ir vieno skaitmens kontrolinės sumos.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCFPNumber: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipBranchNameorBranchCode7digits: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCedulaJuridica910digitsTaxID: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipTaxIDRUT89digits: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipJob: "Jūsų dabartinė profesija.",
    tooltipDateOfBirth: "Jūsų gimimo data.",
    tooltipNationality: "Jūsų tautybė.",
    tooltipDocumentType: "Būtinas dokumento tipas.",
    tooltipDocumentNumber: "Tai dokumento numeris, susietas su jūsų dokumento tipo pasirinkimu.",
    tooltipRutNumber: "Jūsų RUT numeris (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipCIIndividualTaxIDBranchLocation: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    tooltipTaxIDRUCorCI: "Paprastai jį galima rasti mėnesio banko ataskaitoje arba, jei vis dar nesate tikri, kreipkitės į banką.",
    errorCPFCode: "Šiuo metu CPF priimame tik kaip PIX raktą.",
};