export default {
    messageUnableToMakePaymentToCurrency: "Purtroppo non siamo in grado di recuperare i dettagli bancari per la valuta selezionata. Assicurati che Valuta e Paese di origine siano selezionati su",
    labelBankAccountName: "Nome completo del titolare del conto",
    labelAccountName: "Nome del titolare",
    messageOnlyBankAccountsAccepted: "Nota: al momento possiamo solo effettuare pagamenti su conti bancari (carte di credito e di debito non accettate)",
    labelBankCardHolderName: "Nome del titolare",
    validationBankAccountName: "È richiesto il nome completo del titolare del conto",
    labelBankCountry: "paese della banca",
    labelBankName: "Nome della banca",
    labelPaymentType: "Modalità di pagamento",
    labelInstitutionNumber: "Numero istituto",
    labelBankAddress: "Indirizzo bancario",
    labelBankAddress2: "Indirizzo bancario",
    labelBankCity: "banca città",
    labelBankState: "Stato della banca",
    labelBankAccountNumber: "Numero di conto bancario",
    labelBankAccountNumberCBU: "Numero di conto CBU",
    labelBankAccountNumberCCI: "Numero di conto CCI",
    labelBankAccountNumberZAR: "Numero di conto bancario (7-11 cifre)",
    labelCardNumber: "Numero di carta",
    labelUnionpay: "Numero della carta UnionPay",
    labelAlipay: "ID Alipay (numero di cellulare o email)",
    labelBankAccountType: "Tipo di account",
    optionBankAccountTypeCURRENT: "Profilo corrente",
    optionBankAccountTypeCHECKING: "Conto corrente",
    optionBankAccountTypeSAVING: "Conto di risparmio",
    optionBankAccountTypeSAVINGS: "Conto di risparmio",
    optionBankAccountTypeCUENTA_VISTA: "Conto principale",
    optionBankAccountType1: "Conto corrente",
    optionBankAccountType2: "Conto di risparmio",
    optionBankAccountType3: "Conto principale",
    optionBankAccountType4: "Conto Vista",
    optionBankAccountType5: "Conto RUT",
    labelSortCode: "Codice di ordinamento",
    labelRoutingCode: "Codice di instradamento",
    labelBankRoutingCodeNationalID: "Codice di instradamento bancario / ID nazionale",
    labelBankTransitCodeSwift: "Numero di transito",
    labelCNAPSCode: "Codice di instradamento / Codice CNAPS (China National Advanced Payment System Code) ",
    labelABAFedwireRoutingCode: "Numero di instradamento ACH",
    labelIFSCCode: "Codice IFSC",
    labelBankCode: "Codice bancario",
    labelBIKCode: "Codice BIK",
    labelBranchCode: "Codice della filiale",
    labelBranchCodeUniversal: "Codice filiale universale a 6 cifre",
    labelRemittanceLine3: "Nome di contatto e numero di telefono del beneficiario",
    labelBeneficiaryContactNameandTelephoneNumber: "Nome di contatto e numero di telefono del beneficiario",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Data di nascita e luogo di nascita del beneficiario",
    labelRemittanceLine4: "Codice fiscale individuale CUIL (9-12 cifre)",
    labelCUILNumber: "Codice Fiscale: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "ID fiscale RFC 13 cifre o CURP 18 cifre",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Codice fiscale individuale CPF Cadastro de Pessoa Física (11-14 cifre), codice filiale",
    labelCFPNumber: "Numero CPF (11-14 cifre)",
    labelBranchNameorBranchCode7digits: "Nome o codice filiale (7 cifre)",
    labelCedulaJuridica910digitsTaxID: "Certificato legale (codice fiscale di 9-10 cifre)",
    labelTaxIDRUT89digits: "ID fiscale RUT (8-9 cifre)",
    labelJob: "Titolo di lavoro",
    labelDateOfBirth: "Data di nascita",
    labelNationality: "Nazionalità",
    labelDocumentType: "Tipo di documento",
    labelDocumentNumber: "Numero del documento",
    labelRutNumber: "Numero RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Carta d'identità (codice fiscale individuale 8-11 cifre)",
    labelCIIndividualTaxIDBranchLocation: "CI (codice fiscale individuale), sede della filiale",
    labelTaxIDRUCorCI: "ID fiscale (RUC o CI)",
    labelSortCodeHelp: "Aiuto?",
    labelBranchCodeHelp: "Aiuto?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Aiuto?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "Aiuto?",
    labelRoutingCodeBSB: "Codice di instradamento / BSB",
    labelBSB: "Bank State Branch (BSB)",
    labelSKNCode: "Codice SKN",
    labelAcceptanceFlights: "Accetto/accettiamo che, in caso di esito positivo della richiesta di rimborso, la prenotazione, inclusi tutti i voli e i prodotti accessori, verrà annullata.",
    labelAcceptanceTickets: "Accetto/accettiamo che, in caso di esito positivo della richiesta di rimborso, la prenotazione, inclusi tutti i biglietti e i prodotti accessori, verrà annullata.",
    labelTermsAndConditions1: "Io / noi abbiamo acconsentito al",
    labelTermsAndConditions2: "termini e condizioni",
    labelFraudWarning1: "Ritengo che i fatti forniti in questo modulo di richiesta di rimborso siano autentici e veritieri. Comprendo che qualsiasi informazione o prova falsa può essere considerata un atto criminale e può essere trasmessa alla polizia o ad altre forze dell'ordine per essere perseguita.",
    labelFraudWarning2: "Laddove sospettiamo una frode in relazione alla documentazione di supporto, la questione verrà immediatamente riferita alle autorità di polizia locali. Accetto e comprendo che i miei dati saranno trasmessi a queste autorità qualora la mia domanda fosse considerata fraudolenta e ciò potesse diventare un'indagine penale.",
    validationTermsAndConditions: "I termini e le condizioni devono essere concordati con",
    messageFailbankName: "Il nome della banca non può essere lasciato vuoto",
    messageFailcustomerName: "Il nome del cliente non può essere lasciato vuoto",
    messageFailbankAccountNumber: "Campo obbligatorio",
    messageFailbankAccountNumberFormat: "Deve essere in formato IBAN",
    messageFailsortCode: "Campo obbligatorio",
    messageFailbankSwiftBic: "Il BIC SWIFT del beneficiario non può essere lasciato vuoto",
    messageFailbankSwiftBicFormat: "Deve essere in formato SWIFT",
    messageFailbeneficiaryAddress1: "L'indirizzo 1 non può essere lasciato vuoto",
    messageFailbeneficiaryCity: "La città non può essere vuota",
    messageFailbeneficiaryPostCode: "Il codice postale non può essere lasciato vuoto",
    messageFailremittanceLine3: "Campo obbligatorio",
    messageFailremittanceLine4: "Campo obbligatorio",
    modalHeading: "Conferma i dettagli della banca",
    modalLabelPleaseConfirmYourDetails: "Controlla i tuoi dati bancari e conferma.",
    modalValidationPleaseConfirm: "Si prega di confermare",
    modalValidationMustMatch: "Deve corrispondere alla voce precedente",
    tooltipBankAccountName: "Il tuo nome come appare sul tuo conto bancario / estratti conto / carta di credito.",
    tooltipBankAccountNameRecipient: "Il nome e cognome del destinatario. Sia il nome che il cognome devono contenere più di un carattere.",
    tooltipBankCountry: "Il paese in cui si trova la tua banca.",
    tooltipBankName: "Il nome della tua banca.",
    tooltipPaymentType: "Il tipo di pagamento richiesto.",
    tooltipInstitutionNumber: "Si tratta di 3 cifre e di solito si trova sull'estratto conto mensile o sulla carta di credito o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAccountNumber: "Il tuo numero di conto individuale. Di solito si trova sul tuo estratto conto mensile o sulla carta bancaria o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAccountNumberCBU: "Un numero di 22 cifre utilizzato per identificare il tuo account. Di solito si trova sull'estratto conto mensile o sulla carta di credito o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAccountNumberCCI: "Un numero di 20 cifre utilizzato per identificare il tuo account. Di solito si trova sull'estratto conto mensile o sulla carta di credito o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAccountNumberKRW: "Un numero di 11-16 cifre utilizzato per identificare il tuo account. Di solito si trova sull'estratto conto mensile o sulla carta di credito o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCardNumber: "Il numero della tua carta. Di solito si trova sull'estratto conto mensile o sulla carta di credito o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipUnionpay: "Il numero della tua carta UnionPay.",
    tooltipAlipay: "Il tuo numero di cellulare o indirizzo email associato al tuo account Alipay.",
    tooltipBankAccountType: "Questo è Controllo o Risparmio.",
    tooltipSortCode: "Questo identifica la filiale della tua banca in cui è detenuto il tuo conto. Di solito si trova sul tuo estratto conto mensile o sulla carta bancaria o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipRoutingCode: "Questo identifica la filiale della tua banca in cui è detenuto il tuo conto. Di solito si trova sul tuo estratto conto mensile o sulla carta bancaria o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBeneficiarySWIFTBIC: "Consiste di 8-11 caratteri utilizzati per identificare una banca in una transazione internazionale. Di solito si trova sul tuo estratto conto mensile o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBSB: "Un numero Bank State Branch (BSB) è un codice di avviamento postale australiano composto da 6 cifre. Di solito si trova sul tuo estratto conto mensile o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipRoutingCodeBSB: "Questo identifica la filiale della tua banca in cui è detenuto il tuo conto. Di solito si trova sul tuo estratto conto mensile o sulla carta bancaria o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipABAFedwireRoutingCode: "Un numero di 9 cifre utilizzato per identificare le banche in America. Di solito si trova sul tuo estratto conto mensile o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipIFSCCode: "Di solito questo codice si trova sull'estratto conto mensile o sul libretto o se ancora non sei sicuro, chiedi alla tua banca.",
    tooltipTermsAndConditions: "Accettando i termini e le condizioni confermi di aver letto e accettato i nostri Termini e condizioni.",
    tooltipIBAN: "Il tuo numero di conto bancario internazionale, che puoi utilizzare quando effettui o ricevi pagamenti internazionali. Di solito si trova sul tuo estratto conto mensile o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nome di contatto e numero di telefono del beneficiario.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Data di nascita e luogo di nascita del beneficiario.",
    tooltipBankRoutingCodeNationalID: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankTransitCodeSwift: "Si tratta di 5 cifre e di solito si trova sul tuo estratto conto mensile o se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAddress: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankAddress2: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankCity: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankState: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBranchName: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBankCode: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBIKCode: "9 cifre per identificare la tua banca.",
    tooltipBranchCode: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBranchCodeUniversal: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCNAPSCode: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipRemittanceLine4: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCUILNumber: "Questo è un numero di 11 cifre, composto da due cifre, trattino, nove cifre e un checksum di una cifra.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCFPNumber: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipBranchNameorBranchCode7digits: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCedulaJuridica910digitsTaxID: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipTaxIDRUT89digits: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipJob: "La tua attuale occupazione.",
    tooltipDateOfBirth: "La tua data di nascita.",
    tooltipNationality: "La tua nazionalità.",
    tooltipDocumentType: "Il tipo di documento richiesto.",
    tooltipDocumentNumber: "Questo è il numero del documento associato alla selezione del tipo di documento.",
    tooltipRutNumber: "Il tuo numero RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipCIIndividualTaxIDBranchLocation: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    tooltipTaxIDRUCorCI: "Di solito si trova sul tuo estratto conto mensile o, se non sei ancora sicuro, chiedi alla tua banca.",
    errorCPFCode: "Al momento accettiamo solo CPF come chiave PIX."
};