export default {
    headingAdverseWeather: "Sie erleben schwere widrige Wetterbedingungen, die es unsicher machen, zur Veranstaltung zu reisen, und dies wurde von einer Regierungsbehörde (z. B. Kommunalbehörde, Polizei usw.) angeordnet. Und Sie haben Beweise, um dies zu beweisen?",
    headingAdverseWeatherDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    tooltipRefundReasonConfirm: "Ja oder nein",
    labelBookingCancelledByOperator: "Die Tickets wurden vom Betreiber/Veranstalter storniert.",
    labelBookingRescheduledByOperator: "Das Datum wurde vom Betreiber/Organisator geändert und ich kann an den neuen Terminen nicht teilnehmen.",
    labelBookedWrongDate: "Ich oder ein Mitglied der Buchung, habe beim Kauf der Tickets einen Fehler gemacht und das falsche Datum gebucht.",
    labelBookedWrongTickets: "Ich oder ein Mitglied der Buchung, habe beim Kauf der Tickets einen Fehler gemacht und die falschen Tickets gebucht.",
    labelBookedTooManyTickets: "Ich oder ein Mitglied der Buchung, habe beim Kauf der Tickets einen Fehler gemacht und zu viele Tickets gebucht.",
    labelCOVID19TravelRestrictions: "Ich oder ein Mitglied der Buchung können aufgrund eines COVID-19-Reiseverbots nicht teilnehmen.",
    labelADWrongSelection: "Möglicherweise haben Sie den falschen Grund für eine Rückerstattung ausgewählt. Bitte überprüfen Sie die Dropdown-Liste Erstattungsgrund erneut.",
    labelAdverseWeather1: "Warum konnten Sie aufgrund von schlechtem Wetter nicht an der Veranstaltung teilnehmen?",
    tooltipAdverseWeather1: "Bitte detailliert",
    labelAdverseWeather2: "Datum des Vorfalls",
    tooltipAdverseWeather2: "Bitte wählen Sie",
    headingArmedForces: "Sie werden von Ihrem Arbeitgeber, den Streitkräften oder Rettungsdiensten, gebeten, zur Arbeit zu kommen, wenn Sie ursprünglich nicht dafür vorgesehen waren, und dies hindert Sie daran, an der Veranstaltung teilzunehmen?",
    headingArmedForcesDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelArmedForces1: "Bei welchen Einsatzkräften oder Rettungsdiensten sind Sie angestellt?",
    tooltipArmedForces1: "Bitte wählen Sie",
    labelArmedForcesOther1: "Bitte angeben",
    tooltipArmedForcesOther1: "Bitte angeben",
    labelArmedForces2: "Datum des Vorfalls",
    tooltipArmedForces2: "Bitte wählen Sie",
    headingChangesToExam: "Die Daten Ihrer Prüfung wurden von Ihrer Schule / Hochschule usw. geändert? Dies ist keine nicht bestandene Prüfung, die Sie wiederholen müssen?",
    headingChangesToExamDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelExam1: "Was war der ursprüngliche Prüfungstermin?",
    tooltipExam1: "Bitte wählen Sie",
    labelExam2: "Was ist der neue Prüfungstermin?",
    tooltipExam2: "Bitte wählen Sie",
    labelExam3: "Warum hat sich der Prüfungstermin geändert?",
    tooltipExam3: "Bitte geben Sie Details ein",
    labelExam4: "Haben Sie die vorherige Prüfung nicht bestanden?",
    tooltipExam4: "Ja oder nein",
    headingCourtSummons: "Sie wurden vorgeladen, um am selben Tag wie das Ereignis als Zeuge vor Gericht zu erscheinen?",
    headingCourtSummonsDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelCourtSummons1: "Was ist der Grund für Ihre gerichtliche Vorladung?",
    tooltipCourtSummons1: "Bitte wählen Sie",
    labelCourtSummonsOther1: "Bitte angeben",
    tooltipCourtSummonsOther1: "Bitte angeben",
    labelCourtSummons2: "Wann wurde die gerichtliche Vorladung erlassen?",
    tooltipCourtSummons2: "Bitte wählen Sie",
    headingCOVID19Test: "Ich, ein Mitglied meines Haushalts oder ein Mitglied der Gruppe, die an der Veranstaltung teilnimmt, mit COVID-19 diagnostiziert wurde?",
    headingCOVID19TestDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelCOVID19Test1: "Wer hat COVID-19?",
    tooltipCOVID19Test1: "Bitte wählen Sie",
    labelCOVID19TestOther1a: "Bitte angeben",
    labelCOVID19TestOther1b: "Name angeben",
    tooltipCOVID19TestOther1a: "Bitte angeben",
    tooltipCOVID19TestOther1b: "Name angeben",
    labelCOVID19Test2: "Welche Art von COVID-19-Test hatten Sie?",
    tooltipCOVID19Test2: "Bitte wählen Sie",
    labelCOVID19TestOther2: "Bitte angeben",
    tooltipCOVID19TestOther2: "Bitte angeben",
    labelCOVID19Test3: "Was war das Datum Ihres COVID-19-Tests?",
    tooltipCOVID19Test3: "Bitte wählen Sie",
    headingDeath: "Es gab einen Tod eines unmittelbaren Mitglieds meiner Familie und es hindert mich daran, an der Veranstaltung teilzunehmen?",
    headingDeathDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelDeath1: "Welches Mitglied Ihrer Familie ist gestorben?",
    tooltipDeath1: "Bitte wählen Sie",
    labelDeathOther1: "Bitte angeben",
    tooltipDeathOther1: "Bitte angeben",
    labelDeath2: "Wann war das Todesdatum?",
    tooltipDeath2: "Bitte wählen Sie",
    labelDeath3: "Name des Verstorbenen",
    tooltipDeath3: "Bitte angeben",
    headingEmergency: "Ich hatte einen echten Notfall, der außerhalb meiner Kontrolle liegt und unvorhergesehen ist, für den ich Beweise vorlegen kann?",
    headingEmergencyDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelEmergency1: "Bitte erläutern Sie die Art des Notfalls?",
    tooltipEmergency1: "Bitte detailliert",
    labelEmergency2: "Wann ist das passiert?",
    tooltipEmergency2: "Bitte wählen Sie",
    labelEmergency3: "Warum hindert Sie diese Situation daran, an der Veranstaltung teilzunehmen?",
    tooltipEmergency3: "Bitte detailliert",
    labelEmergency4: "Haben Sie Beweise für diesen Notfall?",
    tooltipEmergency4: "Ja oder nein",
    headingHomeEmergency: "Ich habe einen häuslichen Notfall, der Raub, Überschwemmung, Feuer, Vandalismus, häuslicher Missbrauch ist, der mich daran hindert, an der Veranstaltung teilzunehmen?",
    headingHomeEmergencyDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelHomeEmergency1: "Was ist der häusliche Notfall?",
    tooltipHomeEmergency1: "Bitte wählen Sie",
    labelHomeEmergencyOther1: "Bitte angeben",
    tooltipHomeEmergencyOther1: "Bitte angeben",
    labelHomeEmergency2: "Wann ist das passiert?",
    tooltipHomeEmergency2: "Bitte wählen Sie",
    labelHomeEmergency3: "Warum hat es Sie davon abgehalten, an der Veranstaltung teilzunehmen?",
    tooltipHomeEmergency3: "Bitte detailliert",
    headingInjury: "Ich, ein Mitglied meines Haushalts oder ein Mitglied der Gruppe, die an der Veranstaltung teilnimmt, wurde mit einem medizinischen Zustand oder einer Verletzung diagnostiziert, die uns jetzt daran hindert, zur Veranstaltung zu gehen?",
    headingInjuryDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelInjury1: "Was ist die Art der Krankheit / Verletzung / Unfall?",
    tooltipInjury1: "Bitte detailliert",
    labelInjury2: "Wann ist das passiert?",
    tooltipInjury2: "Bitte wählen Sie",
    labelInjury3: "Waren Sie wegen dieser Krankheit/Verletzung in einer medizinischen Einrichtung?",
    tooltipInjury3: "Ja oder nein",
    labelInjury4: "War dies eine bereits bestehende Erkrankung?",
    tooltipInjury4: "Ja oder nein",
    labelInjury5: "Wann wurde dies zum ersten Mal diagnostiziert?",
    tooltipInjury5: "Bitte wählen Sie",
    labelInjury6: "Warum bedeutet das, dass Sie nicht an der Veranstaltung teilnehmen können?",
    tooltipInjury6: "Bitte detailliert",
    labelInjury7: "Wer hat die Krankheit/Verletzung erlitten?",
    tooltipInjury7: "Bitte wählen Sie",
    headingJuryService: "Ich oder ein Mitglied der Buchung wurde zum Jury Service gerufen?",
    headingJuryServiceDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelJuryService1: "Wann wurden Sie über den Geschworenendienst informiert?",
    tooltipJuryService1: "Bitte wählen Sie",
    headingMechanicalBreakdown: "Unser Fahrzeug hatte eine mechanische Panne oder war in einen Unfall verwickelt und das hindert uns daran, an der Veranstaltung teilzunehmen?",
    headingMechanicalBreakdownDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelMechanicalBreakdown1: "Was ist mit dem Fahrzeug passiert?",
    tooltipMechanicalBreakdown1: "Bitte wählen Sie",
    labelMechanicalBreakdown2: "Wann ist das passiert?",
    tooltipMechanicalBreakdown2: "Bitte wählen Sie",
    labelMechanicalBreakdown3: "Warum konnten Sie keine alternativen Verkehrsmittel nehmen?",
    tooltipMechanicalBreakdown3: "Bitte detailliert",
    headingPregnancyComplication: "Ich oder ein Mitglied der Buchung habe Schwangerschaftskomplikationen, die von einem Arzt diagnostiziert wurden und daher die Teilnahme an der Veranstaltung verhindern?",
    headingPregnancyComplicationDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelPregnancyComplication1: "Was ist die Art der Schwangerschaftskomplikation?",
    tooltipPregnancyComplication1: "Bitte detailliert",
    labelPregnancyComplication2: "Wann ist das passiert?",
    tooltipPregnancyComplication2: "Bitte wählen Sie",
    labelPregnancyComplication3: "Haben Sie einen Arzt aufgesucht?",
    tooltipPregnancyComplication3: "Ja oder nein",
    labelPregnancyComplication4: "Warum bedeutet das, dass Sie nicht an der Veranstaltung teilnehmen können?",
    tooltipPregnancyComplication4: "Bitte detailliert",
    headingPublicTransportFailure: "Die Methode der öffentlichen Verkehrsmittel (Zug, Bus, Straßenbahn, Boot usw.) ist unerwartet gescheitert, was bedeutet, dass es keine andere Möglichkeit gab, zu dieser Veranstaltung zu reisen?",
    headingPublicTransportFailureDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelPublicTransportFailure1: "Welche Art von öffentlichen Verkehrsmitteln wollten Sie nutzen?",
    tooltipPublicTransportFailure1: "Bitte wählen Sie",
    labelPublicTransportFailure2: "Wann hatten Sie vor, zu reisen?",
    tooltipPublicTransportFailure2: "Bitte wählen Sie",
    labelPublicTransportFailure3: "Was ist passiert?",
    tooltipPublicTransportFailure3: "Bitte detailliert",
    labelPublicTransportFailure4: "Warum konnten Sie keine alternativen Verkehrsmittel nehmen?",
    tooltipPublicTransportFailure4: "Bitte detailliert",
    headingScheduledAirlineFailure: "Mein im Voraus gebuchter Flug wurde unerwartet storniert und ich konnte keine geeignete Alternative finden, was bedeutet, dass ich nicht an der Veranstaltung teilnehmen konnte?",
    headingScheduledAirlineFailureDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelScheduledAirlineFailure1: "Name der Fluggesellschaft",
    tooltipScheduledAirlineFailure1: "Name der Fluggesellschaft",
    labelScheduledAirlineFailure2: "Flugnummer",
    tooltipScheduledAirlineFailure2: "Flugnummer",
    labelScheduledAirlineFailure3: "Anreise von",
    tooltipScheduledAirlineFailure3: "Anreise von",
    labelScheduledAirlineFailure4: "Anreise nach",
    tooltipScheduledAirlineFailure4: "Anreise nach",
    labelScheduledAirlineFailure5: "Datum des Vorfalls?",
    tooltipScheduledAirlineFailure5: "Bitte wählen Sie",
    labelScheduledAirlineFailure6: "Warum konnten Sie keinen alternativen Flug nehmen?",
    tooltipScheduledAirlineFailure6: "Bitte detailliert",
    headingTheftOfDocuments: "Meine Dokumente wurden gestohlen und es war nicht möglich, innerhalb des erforderlichen Zeitrahmens Ersatz zu arrangieren, so dass ich nicht an der Veranstaltung teilnehmen kann",
    headingTheftOfDocumentsDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelTheftOfDocuments1: "Welche Art von Dokument wurde gestohlen?",
    tooltipTheftOfDocuments1: "Bitte wählen Sie",
    labelTheftOfDocuments2: "Wann ist das passiert?",
    tooltipTheftOfDocuments2: "Bitte wählen Sie",
    labelTheftOfDocuments3: "Haben Sie versucht, die Dokumente neu ausstellen zu lassen?",
    tooltipTheftOfDocuments3: "Ja oder nein",
    headingWorkRelocation: "Mein Arbeitgeber hat mich gebeten, den Ort meiner Arbeit für mindestens drei Monate zu wechseln, und die Entfernung ist erheblich, was bedeutet, dass ich jetzt nicht an der Veranstaltung teilnehmen kann?",
    headingWorkRelocationDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelWorkRelocation1: "Handelt es sich um eine vorübergehende oder dauerhafte Verlagerung Ihrer Arbeit?",
    tooltipWorkRelocation1: "Bitte wählen Sie",
    labelWorkRelocation2: "Wie lange werden Sie an der neuen Adresse sein?",
    tooltipWorkRelocation2: "Bitte detailliert",
    labelWorkRelocation3: "Wie lautet Ihre aktuelle Arbeitsadresse?",
    tooltipWorkRelocation3: "Bitte detailliert",
    labelWorkRelocation4: "Wie lautet Ihre neue Arbeitsadresse?",
    tooltipWorkRelocation4: "Bitte detailliert",
    labelWorkRelocation5: "Wann wurden Sie über diesen Umzug informiert?",
    tooltipWorkRelocation5: "Bitte wählen Sie",
    headingRedundancy: "Ich oder ein Mitglied der Buchung wurde von seinem Arbeitgeber entlassen, was bedeutet, dass ich nicht mehr an der Buchung teilnehmen kann?",
    headingRedundancyDecline: "Sie sollten mit diesem Antrag nicht fortfahren, da die Rückerstattung höchstwahrscheinlich abgelehnt wird.",
    labelRedundancy1: "Art der Beschäftigung?",
    tooltipRedundancy1: "Bitte auswählen",
    labelRedundancy2: "Wie lange sind Sie schon bei diesem Unternehmen beschäftigt?",
    tooltipRedundancy2: "Bitte auswählen",
    labelRedundancy3: "War die Entlassung freiwillig?",
    tooltipRedundancy3: "Bitte auswählen",
    labelRedundancy4: "Wann wurden Sie über Ihre Entlassung informiert?",
    tooltipRedundancy4: "Bitte auswählen",
    labelTestOption: "Wählen Sie Ja, um fortzufahren",
}