import * as React from 'react';
import {
    FormGroup,
    TextField,
    Autocomplete
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps
} from 'formik';
import {
    BorderRadiusInputField,
    MinWidthInputField
} from '../utils/constants-styling';
import {useState} from 'react';
import {IInputProps} from './IInputProps';
import {isInvalid} from '../utils/validationHelpers';
import {IFormValues} from '../Interfaces/IFormValues';
import {IAutoComplete} from '../Interfaces/IAutoComplete';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {useGetSelectData} from '../../hooks/queries';

interface PhoneInputProps extends IInputProps {
    autoCompleteId: string;
    onChange?: (value: any) => void;
    placeholder: string;
}

export const PhoneInput = (props: PhoneInputProps) => {

    const {
        autoCompleteId,
        controlId,
        hidden,
        label,
        onChange,
        placeholder,
        required,
        tooltip
    } = props;

    const {selectData} = useGetSelectData(controlId, '/api/referencedata/diallingCodes');

    const [textInputValue, setTextInputValue] = useState(null);

    const handleOnBlur = (fieldProps: FieldProps<IFormValues>) => (event: React.FocusEvent<HTMLInputElement>) => {

        void fieldProps.form.setFieldValue(controlId, event.target.value);
        void fieldProps.form.setTouched({...fieldProps.form.touched, [controlId]: true}, false);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setTextInputValue(event.target.value);

        if (onChange && typeof (onChange) === 'function') {
            onChange(event);
        }
    }

    const handleOnChangeAutoComplete = (fieldProps: FieldProps<IFormValues>, value: IAutoComplete) => {

        let nextValue: number | string = value?.id.toString();

        if (!nextValue) {
            void fieldProps.form.setFieldValue(autoCompleteId, '');
            return;
        }

        if (!isNaN(+nextValue) && typeof nextValue === String(undefined) && !nextValue.startsWith('0')) {
            nextValue = +nextValue;
        }

        void fieldProps.form.setFieldValue(autoCompleteId, nextValue);

        if (onChange && typeof (onChange) === 'function') {
            onChange(nextValue);
        }
    }

    const getAutoCompleteData: IAutoComplete[] = selectData.map((dataItem) => {
            return {id: dataItem.key, label: dataItem.value}
    });

    const getAutoCompleteValue = (fieldProps: FieldProps<IFormValues>): any => {

        if (selectData.length === 0) {
            return '';
        }

        const value = fieldProps.form.getFieldProps(autoCompleteId).value;
        return getAutoCompleteData.find(x => x.id === value)?.label;
    }

    return (
        <>
            {!hidden &&
                <Grid container alignItems='center' pb={2}>
                    <FormLabelHolder
                        label={label}
                        required={required}
                        tooltip={tooltip}
                    />
                    <Grid size={{xs: 12, sm: 6, md: 5}}>
                        <FormGroup row>
                            <Grid size={{xs: 5, md: 4}}>
                                <>
                                    <Field name={autoCompleteId}>
                                        {(fieldProps: FieldProps<IFormValues>) => (
                                            <Autocomplete
                                                id={autoCompleteId}
                                                multiple={false}
                                                disablePortal
                                                size='small'
                                                value={getAutoCompleteValue(fieldProps) || null}
                                                onChange={(_, value: IAutoComplete) =>
                                                    handleOnChangeAutoComplete(fieldProps, value)}
                                                options={getAutoCompleteData}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        name={autoCompleteId}
                                                        error={isInvalid(fieldProps)}
                                                        helperText={fieldProps.meta.touched ? fieldProps.meta.error : ''}
                                                        label=''
                                                    />}
                                                sx={{
                                                    'fieldset': {
                                                        borderRadius: `${BorderRadiusInputField} 0px 0px ${BorderRadiusInputField}`
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                </>
                            </Grid>
                            <Grid size={{xs: 7, md: 8}}>
                                <Field name={controlId}>
                                    {(fieldProps: FieldProps<IFormValues>) => (
                                        <TextField
                                            name={controlId}
                                            variant='outlined'
                                            type='number'
                                            size='small'
                                            placeholder={placeholder}
                                            onChange={handleOnChange}
                                            onBlur={handleOnBlur(fieldProps)}
                                            value={textInputValue ?? fieldProps.form.getFieldProps(controlId).value}
                                            error={isInvalid(fieldProps)}
                                            helperText={fieldProps.meta.touched ? fieldProps.meta.error : ''}
                                            fullWidth
                                            sx={{
                                                'fieldset': {
                                                    borderRadius: `0px ${BorderRadiusInputField} ${BorderRadiusInputField} 0px`,
                                                    minWidth: MinWidthInputField
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            }
        </>
    );
}