export default {
    messageQuestionService: "Šis pakalpojums ir paredzēts lietošanai tikai tad, ja jums ir problēmas ar pieteikuma veidlapas aizpildīšanu, mēs šeit nevaram atbildēt uz citiem jautājumiem.",
    labelName: "Vārds",
    labelReference: "Rezervācijas atsauce",
    labelPhone: "Tālruņa numurs",
    labelEmail: "E-pasts",
    labelQuery: "Jūsu jautājums",
    labelAskQuestion: "Atsūtiet mums jautājumu šeit...",
    buttonOpenModal: "Jautājums",
    title: "Uzdot jautājumu",
    modalTitle: "4. Vai jums ir tehniska problēma ar veidlapu?",
    toastSuccess: "Mēs esam saņēmuši jūsu jautājumu un sazināsimies ar jums 24 stundu laikā",
    toastError: "Kaut kas nogāja greizi ar jūsu pieprasījumu, lūdzu, mēģiniet vēlreiz",
    nameValidation: "Vārds ir jānorāda",
    referenceValidation: "Atsauce ir nepieciešama",
    questionValidation: "Jūsu jautājums ir obligāts",
};