export default {
    messageQuestionService: "บริการนี้มีไว้สำหรับใช้งานในกรณีที่คุณมีปัญหาในการกรอกแบบฟอร์มใบสมัครเท่านั้นเราไม่สามารถตอบคำถามอื่น ๆ ได้ที่นี่",
    labelName: "ชื่อ",
    labelReference: "การอ้างอิงการจองห้องพัก",
    labelPhone: "หมายเลขโทรศัพท์",
    labelEmail: "อีเมล์",
    labelQuery: "คำถามของคุณ",
    labelAskQuestion: "ส่งคำถามถึงเราที่นี่ ...",
    buttonOpenModal: "คำถาม",
    title: "ถามคำถาม",
    modalTitle: "4. มีปัญหาทางเทคนิคเกี่ยวกับแบบฟอร์มหรือไม่?",
    toastSuccess: "เราได้รับคำถามของคุณแล้วและจะติดต่อกลับภายใน 24 ชั่วโมง",
    toastError: "มีข้อผิดพลาดกับคำขอของคุณโปรดลองอีกครั้ง",
    nameValidation: "ต้องระบุชื่อ",
    referenceValidation: "จำเป็นต้องมีการอ้างอิง",
    questionValidation: "จำเป็นต้องมีคำถามของคุณ"
}