export default {
    headingAdverseWeather: "您遇到严重的恶劣天气条件，使得前往活动场所变得不安全，这是政府机构（例如地方当局，警察等）的授权。你有证据证明这一点吗？",
    headingAdverseWeatherDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    tooltipRefundReasonConfirm: "是或否",
    labelBookingCancelledByOperator: "门票已由营运商/主办单位取消。",
    labelBookingRescheduledByOperator: "日期由运营商/组织者更改，我无法参加新的日期。",
    labelBookedWrongDate: "我或预订的会员在购买机票时出错，并预订了错误的日期。",
    labelBookedWrongTickets: "我或预订的会员在购买机票时出错，并预订了错误的机票。",
    labelBookedTooManyTickets: "我或预订的会员在购买门票时出错，并预订了太多门票。",
    labelCOVID19TravelRestrictions: "我或预订的会员由于COVID-19旅行禁令而无法参加。",
    labelADWrongSelection: "您可能选择了错误的退款原因。请再次查看退款原因下拉列表。",
    labelAdverseWeather1: "为什么恶劣的天气意味着您无法参加活动？",
    tooltipAdverseWeather1: "请详细说明",
    labelAdverseWeather2: "事件发生日期",
    tooltipAdverseWeather2: "请选择",
    headingArmedForces: "您的雇主要求您在最初没有安排的情况下进入工作状态，这是武装部队或紧急服务部门，这会阻止您参加活动？",
    headingArmedForcesDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelArmedForces1: "您受雇于哪些部队或紧急服务部门？",
    tooltipArmedForces1: "请选择",
    labelArmedForcesOther1: "请具体说明",
    tooltipArmedForcesOther1: "请具体说明",
    labelArmedForces2: "事件发生日期",
    tooltipArmedForces2: "请选择",
    headingChangesToExam: "您的学校/学院等更改了考试日期？这不是您必须重新参加的失败考试吗？",
    headingChangesToExamDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelExam1: "最初的考试日期是什么时候？",
    tooltipExam1: "请选择",
    labelExam2: "新的考试日期是什么时候？",
    tooltipExam2: "请选择",
    labelExam3: "为什么考试日期会改变？",
    tooltipExam3: "请输入详细信息",
    labelExam4: "你之前的考试没有通过吗？",
    tooltipExam4: "是或否",
    headingCourtSummons: "您是否被传唤在与事件发生相同的日期作为证人出庭？",
    headingCourtSummonsDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelCourtSummons1: "你们的法庭传票的原因是什么？",
    tooltipCourtSummons1: "请选择",
    labelCourtSummonsOther1: "请具体说明",
    tooltipCourtSummonsOther1: "请具体说明",
    labelCourtSummons2: "法院传票是什么时候发出的？",
    tooltipCourtSummons2: "请选择",
    headingCOVID19Test: "我、我的家庭成员或参加活动的团体成员被诊断出患有COVID-19？",
    headingCOVID19TestDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelCOVID19Test1: "谁感染了新冠肺炎？",
    tooltipCOVID19Test1: "请选择",
    labelCOVID19TestOther1a: "请具体说明",
    labelCOVID19TestOther1b: "指定名称",
    tooltipCOVID19TestOther1a: "请具体说明",
    tooltipCOVID19TestOther1b: "指定名称",
    labelCOVID19Test2: "您进行了哪种类型的COVID-19测试？",
    tooltipCOVID19Test2: "请选择",
    labelCOVID19TestOther2: "请具体说明",
    tooltipCOVID19TestOther2: "请具体说明",
    labelCOVID19Test3: "您的COVID-19测试日期是什么时候？",
    tooltipCOVID19Test3: "请选择",
    headingDeath: "我的一位直系亲属去世了，这使我无法参加活动？",
    headingDeathDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelDeath1: "您家的哪位成员去世了？",
    tooltipDeath1: "请选择",
    labelDeathOther1: "请具体说明",
    tooltipDeathOther1: "请具体说明",
    labelDeath2: "死亡日期是什么时候？",
    tooltipDeath2: "请选择",
    labelDeath3: "死者姓名",
    tooltipDeath3: "请具体说明",
    headingEmergency: "我遇到了一个真正的紧急情况，这是我无法控制和不可预见的，我可以提供证据吗？",
    headingEmergencyDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelEmergency1: "请解释紧急情况的类型？",
    tooltipEmergency1: "请详细说明",
    labelEmergency2: "这是什么时候发生的？",
    tooltipEmergency2: "请选择",
    labelEmergency3: "为什么这种情况会阻止您参加活动？",
    tooltipEmergency3: "请详细说明",
    labelEmergency4: "你有这种紧急情况的证据吗？",
    tooltipEmergency4: "是或否",
    headingHomeEmergency: "我有一个家庭紧急情况，即抢劫，洪水，火灾，故意破坏，家庭虐待，阻止我参加活动？",
    headingHomeEmergencyDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelHomeEmergency1: "什么是家庭紧急情况？",
    tooltipHomeEmergency1: "请选择",
    labelHomeEmergencyOther1: "请具体说明",
    tooltipHomeEmergencyOther1: "请具体说明",
    labelHomeEmergency2: "这是什么时候发生的？",
    tooltipHomeEmergency2: "请选择",
    labelHomeEmergency3: "为什么它阻止你参加这个活动？",
    tooltipHomeEmergency3: "请详细说明",
    headingInjury: "我、我的家庭成员或参加活动的团体成员被诊断出患有医疗状况或受伤，现在无法参加活动？",
    headingInjuryDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelInjury1: "疾病/伤害/事故的性质是什么？",
    tooltipInjury1: "请详细说明",
    labelInjury2: "这是什么时候发生的？",
    tooltipInjury2: "请选择",
    labelInjury3: "您是否因这种疾病/受伤去过医疗机构？",
    tooltipInjury3: "是或否",
    labelInjury4: "这是预先存在的医疗状况吗？",
    tooltipInjury4: "是或否",
    labelInjury5: "这是什么时候第一次被诊断出来的？",
    tooltipInjury5: "请选择",
    labelInjury6: "为什么这意味着您无法参加活动？",
    tooltipInjury6: "请详细说明",
    labelInjury7: "谁遭受了疾病/伤害？",
    tooltipInjury7: "请选择",
    headingJuryService: "我或预订的会员已被召集到陪审团服务？",
    headingJuryServiceDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelJuryService1: "您是什么时候收到陪审团服务的通知的？",
    tooltipJuryService1: "请选择",
    headingMechanicalBreakdown: "我们的车辆发生机械故障或发生事故，这使我们无法参加活动？",
    headingMechanicalBreakdownDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelMechanicalBreakdown1: "车辆发生了什么变化？",
    tooltipMechanicalBreakdown1: "请选择",
    labelMechanicalBreakdown2: "这是什么时候发生的？",
    tooltipMechanicalBreakdown2: "请选择",
    labelMechanicalBreakdown3: "为什么您无法乘坐其他交通工具？",
    tooltipMechanicalBreakdown3: "请详细说明",
    headingPregnancyComplication: "我或预订的会员患有由医疗专业人员诊断的妊娠并发症，因此无法参加活动？",
    headingPregnancyComplicationDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelPregnancyComplication1: "妊娠并发症的性质是什么？",
    tooltipPregnancyComplication1: "请详细说明",
    labelPregnancyComplication2: "这是什么时候发生的？",
    tooltipPregnancyComplication2: "请选择",
    labelPregnancyComplication3: "您是否寻求医疗救助？",
    tooltipPregnancyComplication3: "是或否",
    labelPregnancyComplication4: "为什么这意味着您无法参加活动？",
    tooltipPregnancyComplication4: "请详细说明",
    headingPublicTransportFailure: "公共交通工具（火车，公共汽车，电车，船等）的方法意外失败，这意味着没有其他方式可以参加此活动？",
    headingPublicTransportFailureDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelPublicTransportFailure1: "您计划使用哪种类型的公共交通工具？",
    tooltipPublicTransportFailure1: "请选择",
    labelPublicTransportFailure2: "你打算什么时候旅行？",
    tooltipPublicTransportFailure2: "请选择",
    labelPublicTransportFailure3: "发生了什么事？",
    tooltipPublicTransportFailure3: "请详细说明",
    labelPublicTransportFailure4: "为什么你不能采取其他交通工具？",
    tooltipPublicTransportFailure4: "请详细说明",
    headingScheduledAirlineFailure: "我预订的航班意外取消，我无法找到合适的替代方案，这意味着我无法参加活动？",
    headingScheduledAirlineFailureDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelScheduledAirlineFailure1: "航空公司名称",
    tooltipScheduledAirlineFailure1: "航空公司名称",
    labelScheduledAirlineFailure2: "航班号",
    tooltipScheduledAirlineFailure2: "航班号",
    labelScheduledAirlineFailure3: "出发地",
    tooltipScheduledAirlineFailure3: "出发地",
    labelScheduledAirlineFailure4: "前往",
    tooltipScheduledAirlineFailure4: "前往",
    labelScheduledAirlineFailure5: "事件发生日期？",
    tooltipScheduledAirlineFailure5: "请选择",
    labelScheduledAirlineFailure6: "为什么您无法乘坐其他航班？",
    tooltipScheduledAirlineFailure6: "请详细说明",
    headingTheftOfDocuments: "我的文件被盗，无法在所需的时间范围内安排更换，因此我无法参加活动",
    headingTheftOfDocumentsDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelTheftOfDocuments1: "什么类型的文档被盗？",
    tooltipTheftOfDocuments1: "请选择",
    labelTheftOfDocuments2: "这是什么时候发生的？",
    tooltipTheftOfDocuments2: "请选择",
    labelTheftOfDocuments3: "您是否尝试过重新签发文件？",
    tooltipTheftOfDocuments3: "是或否",
    headingWorkRelocation: "我的雇主要求我改变工作地点至少三个月，距离很重要，这意味着我现在无法参加活动？",
    headingWorkRelocationDecline: "您不应继续使用此申请，因为退款很可能会被拒绝。",
    labelWorkRelocation1: "这是您工作的临时还是永久搬迁？",
    tooltipWorkRelocation1: "请选择",
    labelWorkRelocation2: "您将在新地址停留多长时间？",
    tooltipWorkRelocation2: "请详细说明",
    labelWorkRelocation3: "您目前的工作地址是什么？",
    tooltipWorkRelocation3: "请详细说明",
    labelWorkRelocation4: "您的新工作地址是什么？",
    tooltipWorkRelocation4: "请详细说明",
    labelWorkRelocation5: "您是什么时候接到搬迁通知的？",
    tooltipWorkRelocation5: "请选择",
    headingRedundancy: "我或预订的会员已被雇主裁员，这意味着我不能再参加预订？",
    headingRedundancyDecline: "您不应继续此申请，因为退款很可能会被拒绝。",
    labelRedundancy1: "就业类型？",
    tooltipRedundancy1: "请选择",
    labelRedundancy2: "你在这家公司工作多久了？",
    tooltipRedundancy2: "请选择",
    labelRedundancy3: "裁员是自愿的吗？",
    tooltipRedundancy3: "请选择",
    labelRedundancy4: "您是什么时候收到裁员通知的？",
    tooltipRedundancy4: "请选择",
    labelTestOption: "选择是继续",
}