export default {
    messageQuestionService: "ይህ አገልግሎት ጥቅም ላይ የሚውለው የማመልከቻ ቅጹን መሙላት ላይ ችግር ካጋጠመዎት ብቻ ነው፣ለሌሎች ጥያቄዎች እዚህ መመለስ አንችልም።.",
    labelName: "ስም",
    labelReference: "የቦታ ማስያዝ ማጣቀሻ",
    labelPhone: "ስልክ ቁጥር",
    labelEmail: "ኢሜይል",
    labelQuery: "የእርስዎ ጥያቄ",
    labelAskQuestion: "እዚህ ላይ ጥያቄ ላኩልን።...",
    buttonOpenModal: "ጥያቄ",
    title: "ጥያቄ ይጠይቁ",
    modalTitle: "4. በቅጹ ላይ የቴክኒክ ችግር አጋጥሞታል።?",
    toastSuccess: "ጥያቄህን ተቀብለናል በ24 ሰአት ውስጥ እናገኝሃለን።",
    toastError: "በጥያቄዎ ላይ የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ",
    nameValidation:"ስም ያስፈልጋል",
    referenceValidation:"ማጣቀሻ ያስፈልጋል",
    questionValidation: "ጥያቄህ ያስፈልጋል"
};