import * as React from 'react';
import Grid from '@mui/material/Grid2';

interface FormOffsetHolderProps {
    content: JSX.Element
}

export const FormOffsetHolder = (props: FormOffsetHolderProps) => {
    
    const {
        content
    } = props

    return (
        <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
            <Grid size={{xs: 6, md: 5}} offset={{sm: 6, md: 4}}>
                {content}
            </Grid>
        </Grid>
    );
}