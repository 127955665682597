export default {
    dividerBankingDetails: "Bankgegevens",
    dividerBookingDetails: "Boekingsgegevens",
    labelSavedApplicationReference: "Opgeslagen aanvraag Ref:",
    labelLanguage: "Taal",
    messageWarningBookingHeading1: "Boeking geannuleerd:",
    messageWarningBookingHeading2: "Boeking uitgesteld / opnieuw gepland:",
    messageWarningBookingBody1: "Als uw boeking is geannuleerd door de organisator of luchtvaartmaatschappij, kan dit team u niet helpen met restitutie. U moet rechtstreeks contact opnemen met uw klantenserviceteam; zie uw boekingsbevestiging of website voor contactgegevens.",
    messageWarningBookingBody2: "Als uw boeking is uitgesteld, worden uw verlengde terugbetaalbare voorwaarden overgedragen naar de nieuwe datum. Als u een geldige reden voor terugbetaling heeft onder onze voorwaarden, vul dan het formulier in met het juiste ondersteunende bewijs.",
    messageWarningBookingBody3: "Let op: deze service kan alleen terugbetalingen doen om de redenen die zijn vermeld in onze formulering, beschikbaar in uw boekingsbevestigingsmail.",
    messageWarningBookingBody4: "Als u de boeking niet kunt maken vanwege redenen buiten onze voorwaarden, kunnen wij u niet helpen. U moet rechtstreeks contact opnemen met uw klantenserviceteam, aangezien zij u mogelijk kunnen helpen; zie uw boekingsbevestiging of website voor contactgegevens.",
    linkWarningBooking: "hier",
    formSectionNavigatorStep1: "Stap 1",
    formSectionNavigatorStep2: "Stap 2",
    formSectionNavigatorStep3: "Stap 3",
    formSectionNavigatorStep1Text: "Contact- en boekingsgegevens",
    formSectionNavigatorStep2Text: "Reden van terugbetaling",
    formSectionNavigatorStep3Text: "Betalingsgegevens",
    messageBookingMatchedAndFound: "Als uw boekingsreferentie aan een bestaande aanvraag is gekoppeld, sturen wij u een e-mail met een link waarmee u toegang krijgt tot uw aanvraag",
    messageBookingMatchedAndFoundCompleted: "Uw terugbetalingsaanvraag is al goedgekeurd! Als u problemen heeft met uw betaling, neem dan contact op met:",
    applicationDeclinedMessage: "Uw aanvraag is afgewezen en is nu gesloten.",
    messageBookingNotFound1: "We kunnen geen boeking vinden met die referentie. Controleer uw boekingsbevestigingsmail en voer opnieuw in.",
    messageBookingNotFound2: "Maakt u zich geen zorgen, als uw boekingsreferentie niet werkt, kunt u 'Find Booking' selecteren en uw gegevens invoeren. Upload bij het invullen van het formulier een kopie van uw boekingsbevestigingsmail om ons te helpen uw boekingsgegevens te vinden.",
    messageLocatingBooking: "Uw boeking lokaliseren ...",
    messageLoadingBank: "Bankgegevens laden ...",
    messageFailCurrencyLookup: "Fout bij ophalen van valuta",
    messageSavingApplication: "Uw aanvraag opslaan ...",
    messageSavedApplication: "Aanvraag voor teruggave succesvol opgeslagen",
    messageFailSavedApplication: "Teruggavetoepassing kan niet worden opgeslagen",
    messageSendingEmailReminder: "Verwerken...",
    messageErrorLocationDetails: "Fout bij het ophalen van locatiegegevens",
    messageFailFormSubmission: "Inzending van formulier is mislukt",
    messageApplicationCannotBeFound: "Applicatie kan niet worden gevonden",
    messageApplicationRetrievalError: "Fout bij ophalen van applicatie",
    messageSubmittingApplication: "Aanvraag indienen...",
    messageEventDateLimitError: "Reden voor melding buiten tijdslimiet",
    messageAllTicketsAssigned: "Alle tickets zijn toegewezen aan een andere terugbetalingsaanvraag.",
    selectPrefix: "Selecteer",
    selectPostfix: "",
    fileUpload: "klik hier om te uploaden",
    fileUploadAcceptedFilesMessage: "We accepteren alleen PDF's en afbeeldingsformaten van .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 bestanden geselecteerd",
    fileUploadPreviouslyUploaded: "Hiervoor geupload:",
    fileUploadSelectedUploaded: "De geselecteerde zijn al geüpload:",
    buttonNext: "De volgende",
    buttonPrevious: "Vorige",
    buttonSave: "Opslaan",
    buttonCancel: "annuleren",
    buttonSubmit: "Verzenden",
    buttonConfirm: "Bevestigen",
    buttonYes: "Ja",
    buttonNo: "Nee",
    buttonClose: "Dichtbij",
    linkPrivacyStatement: "Privacyverklaring",
    linkComplaintsPolicy: "Klachtenbeleid",
    validationRequired: "Verplicht",
    validationInvalid: "Ongeldig",
    labelUploadPrefix: "Uploaden",
    labelUploadPostfix: "",
    labelChange: "Veranderen",
    labelView: "Bekijken",
    browserNavigationMessage: "Niet-opgeslagen wijzigingen gaan verloren"
};