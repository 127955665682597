export default {
    messageUnableToMakePaymentToCurrency: "للأسف لا يمكننا استرداد التفاصيل المصرفية للعملة المحددة. يرجى التأكد من تحديد العملة والبلد الأصلي في",
    messageOnlyBankAccountsAccepted: "يرجى ملاحظة ما يلي: يمكننا فقط إجراء مدفوعات إلى الحسابات المصرفية في هذا الوقت (لا يتم قبول بطاقات الائتمان والخصم)",
    labelAccountName: "اسم صاحب الحساب",
    labelBankAccountName: "الاسم الكامل لصاحب الحساب",
    labelBankCardHolderName: "إسم صاحب البطاقة",
    validationBankAccountName: "مطلوب الاسم الكامل لصاحب الحساب",
    labelBankCountry: "بنك الدولة",
    labelBankName: "اسم البنك",
    labelPaymentType: "نوع الدفع",
    labelInstitutionNumber: "رقم المؤسسة",
    labelBankAddress: "عنوان البنك",
    labelBankAddress2: "عنوان البنك",
    labelBankCity: "مدينة البنك",
    labelBankState: "دولة البنك",
    labelBankAccountNumber: "رقم الحساب المصرفي",
    labelBankAccountNumberCBU: "رقم الحساب CBU",
    labelBankAccountNumberCCI: "رقم الحساب CCI",
    labelBankAccountNumberZAR: "رقم الحساب البنكي (٧-١١ رقم)",
    labelCardNumber: "رقم البطاقة",
    labelUnionpay: "رقم بطاقة UnionPay",
    labelAlipay: "معرف Alipay (رقم الهاتف المحمول أو البريد الإلكتروني)",
    labelBankAccountType: "نوع الحساب",
    optionBankAccountTypeCURRENT: "الحساب الحالي",
    optionBankAccountTypeCHECKING: "حساب جار",
    optionBankAccountTypeSAVING: "حساب التوفير",
    optionBankAccountTypeSAVINGS: "حساب التوفير",
    optionBankAccountTypeCUENTA_VISTA: "الحساب الرئيسي",
    optionBankAccountType1: "حساب جار",
    optionBankAccountType2: "حساب التوفير",
    optionBankAccountType3: "الحساب الرئيسي",
    optionBankAccountType4: "Vista الحساب",
    optionBankAccountType5: "RUT الحساب",
    labelSortCode: "الكود البنكى",
    labelRoutingCode: "كود توجيه",
    labelBankRoutingCodeNationalID: "رمز توجيه البنك / الرقم القومي",
    labelBankTransitCodeSwift: "رقم العبور",
    labelCNAPSCode: "رمز التوجيه / كود CNAPS (رمز نظام الدفع المتقدم الوطني الصيني)",
    labelABAFedwireRoutingCode: "رقم توجيه ACH",
    labelIFSCCode: "كود IFSC",
    labelBankCode: "رمز بنكي",
    labelBIKCode: "كود BIK",
    labelBranchCode: "رمز الفرع",
    labelBranchCodeUniversal: "رمز فرع عالمي مكون من ٦ أرقام",
    labelRemittanceLine3: "اسم ورقم هاتف المستفيد",
    labelBeneficiaryContactNameandTelephoneNumber: "اسم ورقم هاتف المستفيد",
    labelBeneficiaryDateofBirthandPlaceofBirth: "تاريخ الميلاد ومكان الميلاد المستفيد",
    labelRemittanceLine4: "الرقم الضريبي الفردي CUIL (٩-١٢ رقمًا)",
    labelCUILNumber: "معرف الضريبة: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "معرف الضريبة RFC ١٣ رقمًا أو CURP ١٨ رقمًا",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "المعرّف الضريبي الفردي CPF Cadastro de Pessoa Física (١١-١٤ رقمًا) ، رمز الفرع",
    labelCFPNumber: "رقم CPF (١١-١٤ رقمًا)",
    labelBranchNameorBranchCode7digits: "اسم الفرع أو رمز الفرع (٧ أرقام)",
    labelCedulaJuridica910digitsTaxID: "شهادة قانونية (٩-١۰ أرقام معرف الضريبة)",
    labelTaxIDRUT89digits: "المعرّف الضريبي RUT (٨-٩ أرقام)",
    labelJob: "مسمى وظيفي",
    labelDateOfBirth: "تاريخ الولادة",
    labelNationality: "جنسية",
    labelDocumentType: "نوع الوثيقة",
    labelDocumentNumber: "رقم المستند",
    labelRutNumber: "رقم RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "بطاقة الهوية (المعرّف الضريبي فردي من ٨ إلى ١١ رقمًا)",
    labelCIIndividualTaxIDBranchLocation: "CI (معرف الضريبة الفردي) ، موقع الفرع",
    labelTaxIDRUCorCI: "المعرّف الضريبي (RUC أو CI)",
    labelSortCodeHelp: "مساعدة؟",
    labelBranchCodeHelp: "مساعدة؟",
    labelIBAN: "ذاهبون",
    labelIBANHelp: "مساعدة؟",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "التحويلة البنكية الرمز البنكي",
    labelBeneficiarySWIFTBICHelp: "مساعدة؟",
    labelRoutingCodeBSB: "كود التوجيه / BSB",
    labelBSB: "فرع بنك الدولة (BSB)",
    labelSKNCode: "كود SKN",
    labelAcceptanceFlights: "أنا / نحن نقبل أنه في حالة نجاح طلب الاسترداد ، فسيتم إلغاء الحجز بما في ذلك جميع الرحلات الجوية والمنتجات الإضافية.",
    labelAcceptanceTickets: "أنا / نحن نقبل أنه في حالة نجاح طلب الاسترداد ، فسيتم إلغاء الحجز بما في ذلك جميع التذاكر والمنتجات الإضافية.",
    labelTermsAndConditions1: "أنا / لقد اتفقنا على",
    labelTermsAndConditions2: "الأحكام والشروط",
    labelFraudWarning1: "أعتقد أن الحقائق الواردة في نموذج طلب الاسترداد هذا أصلية وحقيقية. أفهم أن أي معلومات أو أدلة كاذبة يمكن اعتبارها فعلًا إجراميًا ويتم نقلها إلى الشرطة أو غيرها من وكالات إنفاذ القانون لملاحقتها.",
    labelFraudWarning2: "عندما نشك في وجود احتيال فيما يتعلق بالوثائق الداعمة ، فسيتم إحالة هذه المسألة على الفور إلى سلطات الشرطة المحلية. أنا أقبل وأفهم أنه سيتم إرسال بياناتي إلى هذه السلطات في حالة اعتبار طلبي احتياليًا وقد يصبح ذلك تحقيقًا جنائيًا.",
    validationTermsAndConditions: "يجب الموافقة على الشروط والأحكام",
    messageFailbankName: "لا يمكن أن يكون اسم البنك فارغًا",
    messageFailcustomerName: "لا يمكن أن يكون اسم العميل فارغًا",
    messageFailbankAccountNumber: "يتطلب حقلا",
    messageFailbankAccountNumberFormat: "يجب أن يكون تنسيق IBAN",
    messageFailsortCode: "يتطلب حقلا",
    messageFailbankSwiftBic: "لا يمكن ترك حقل SWIFT BIC للمستفيد فارغًا",
    messageFailbankSwiftBicFormat: "يجب أن يكون تنسيق SWIFT",
    messageFailbeneficiaryAddress1: "لا يمكن أن يكون العنوان 1 فارغًا",
    messageFailbeneficiaryCity: "لا يمكن أن يكون حقل المدينة فارغًا",
    messageFailbeneficiaryPostCode: "لا يمكن أن يكون الرمز البريدي فارغًا",
    messageFailremittanceLine3: "يتطلب حقلا",
    messageFailremittanceLine4: "يتطلب حقلا",
    modalHeading: "تأكيد التفاصيل",
    modalLabelPleaseConfirmYourDetails: "يرجى تأكيد التفاصيل الخاصة بك",
    modalValidationPleaseConfirm: "يرجى تأكيد",
    modalValidationMustMatch: "يجب أن يتطابق مع الإدخال السابق",
    tooltipBankAccountName: "اسمك كما يظهر في حسابك المصرفي / كشوف حسابك المصرفي / بطاقتك المصرفية.",
    tooltipBankAccountNameRecipient: "اسم المستلم الأول والأخير. يجب أن يحتوي كل من الاسم الأول والأخير على أكثر من حرف واحد.",
    tooltipBankCountry: "البلد الذي يقع فيه مصرفك.",
    tooltipBankName: "اسم البنك الذي تتعامل معه.",
    tooltipPaymentType: "نوع الدفع الذي تطلبه.",
    tooltipInstitutionNumber: "هذا مكون من ٣ أرقام وعادة ما يكون موجودًا في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAccountNumber: "رقم حسابك الفردي. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAccountNumberCBU: "رقم مكون من ٢٢ رقمًا يستخدم لتعريف حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAccountNumberCCI: "رقم مكون من ٢۰ رقمًا يستخدم لتعريف حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAccountNumberKRW: "رقم مكون من ۱۱ إلى ۱٦ رقمًا يستخدم لتعريف حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCardNumber: "رقم بطاقتك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipUnionpay: "رقم بطاقة الدفع النقابية الخاصة بك.",
    tooltipAlipay: "رقم هاتفك المحمول أو عنوان البريد الإلكتروني المرتبط بحساب Alipay الخاص بك.",
    tooltipBankAccountType: "هذا إما تدقيق أو توفير.",
    tooltipSortCode: "هذا يحدد فرع البنك الذي تتعامل معه حيث يوجد حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipRoutingCode: "هذا يحدد فرع البنك الذي تتعامل معه حيث يوجد حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBeneficiarySWIFTBIC: "يتكون من ۸-۱۱ حرفًا تستخدم لتعريف بنك في معاملة دولية. عادة ما يتم العثور عليها في كشف حسابك الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBSB: "رقم فرع البنك الدولي (BSB) هو رمز فرز أسترالي يتكون من ٦ أرقام. عادة ما يتم العثور عليها في كشف حسابك الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipRoutingCodeBSB: "هذا يحدد فرع البنك الذي تتعامل معه حيث يوجد حسابك. توجد عادة في كشف حسابك الشهري أو بطاقتك المصرفية أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipABAFedwireRoutingCode: "رقم مكون من ۹ أرقام يستخدم لتحديد البنوك في أمريكا. عادة ما يتم العثور عليها في كشف حسابك الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipIFSCCode: "عادة يمكن العثور على هذا الرمز في كشف حسابك الشهري أو في دفتر الحسابات الخاص بك أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipTermsAndConditions: "من خلال قبول البنود والشروط ، فإنك تؤكد أنك قد قرأت البنود والشروط الخاصة بنا ووافقت عليها.",
    tooltipIBAN: "رقم حسابك المصرفي الدولي ، والذي يمكنك استخدامه عند إجراء المدفوعات الدولية أو تلقيها. عادة ما يتم العثور عليها في كشف حسابك الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "اسم ورقم هاتف المستفيد.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "تاريخ الميلاد ومكان الميلاد المستفيد.",
    tooltipBankRoutingCodeNationalID: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankTransitCodeSwift: "هذا مكون من ۵ أرقام ويمكن العثور عليه عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAddress: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankAddress2: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankCity: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankState: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBranchName: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBankCode: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBIKCode: "۹ أرقام لتحديد البنك الذي تتعامل معه.",
    tooltipBranchCode: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBranchCodeUniversal: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCNAPSCode: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipRemittanceLine4: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCUILNumber: "هذا رقم مكون من 11 رقمًا ، يتكون من رقمين ، وواصلة ، وتسعة أرقام ، ومجموع اختباري مكون من رقم واحد.",   
    tooltipTaxIDRFC13DigitsORCURP18digits: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCFPNumber: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipBranchNameorBranchCode7digits: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCedulaJuridica910digitsTaxID: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipTaxIDRUT89digits: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipJob: "وظيفتك الحالية.",
    tooltipDateOfBirth: "تاريخ ميلادك.",
    tooltipNationality: "جنسيتك.",
    tooltipDocumentType: "نوع الوثيقة المطلوبة.",
    tooltipDocumentNumber: "هذا هو رقم المستند المرتبط بتحديد نوع المستند الخاص بك.",
    tooltipRutNumber: "رقم RUT الخاص بك (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipCIIndividualTaxIDBranchLocation: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    tooltipTaxIDRUCorCI: "يمكن العثور عليها عادةً في كشف حسابك المصرفي الشهري أو إذا كنت لا تزال غير متأكد ، فاسأل البنك الذي تتعامل معه.",
    errorCPFCode: "في الوقت الحالي، نقبل فقط CPF كمفتاح PIX."
};