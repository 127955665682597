export default {
    labelBookingCancelledPostponedHeading: "Apakah pemesanan Anda telah dibatalkan atau ditunda? Baca selengkapnya",
    labelBookingCancelledPostponedText1: "Klik",
    labelBookingCancelledPostponedText2: "untuk info lebih lanjut.",
    labelNewRefundHeading: "Aplikasi pengembalian dana baru?",
    labelNewRefundText: "Masukkan referensi pemesanan Anda di sini ...",
    placeholderBookingReference: "Referensi Pemesanan",
    validationBookingReference: "Diperlukan Referensi Pemesanan",
    validationEmailAsBookingReference: "Alamat email tidak diterima sebagai referensi pemesanan",
    buttonFindBooking: "Temukan Pemesanan",
    labelEmailHeading: "Sudah dimulai?",
    labelEmailText: "Kirim pengingat email untuk mengambil aplikasi Anda.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Email Reminder",
    labelCustomerName: "Nama Pelanggan",
    validationCustomerName: "Nama Pelanggan diperlukan",
    labelTypeOfBooking: "Jenis Pemesanan",
    validationTypeOfBooking: "Jenis Pemesanan diperlukan",
    labelEventName: "Nama Peristiwa",
    labelVenueName: "Nama Tempat",
    labelFlightNumber: "Nomor Penerbangan",
    labelOriginAirport: "Bandara Asal",
    labelHotelName: "Nama Akomodasi",
    labelHotelLocation: "Lokasi akomodasi",
    labelCurrency: "Mata uang",
    validationCurrency: "Mata uang diperlukan",
    validationCountryCurrency: "Mata Uang yang dipilih harus mata uang Negara Asal Anda",
    validationOfflinePayment: "Kami tidak dapat memproses pengembalian dana untuk mata uang dan pilihan negara Anda, silakan pilih alternatif lain",
    labelBookingValue: "Nilai Pemesanan",
    validationBookingValue: "Nilai Pemesanan diperlukan",
    validationBookingValueAmount: "Harus lebih besar dari 0",
    labelDateOfPurchase: "Tanggal Pembelian",
    validationDateOfPurchase: "Diperlukan tanggal pembelian",
    labelEmailAddress: "Alamat surel",
    validationEmailAddress: "Alamat Email diperlukan",
    validationEmailAddressFormat: "Format surelsalah",
    labelConfirmEmailAddress: "Konfirmasi Alamat surel",
    validationConfirmEmailAddressFormat: "Format surelsalah",
    validationConfirmEmailAddress: "Diperlukan Konfirmasi Alamat surel",
    validationConfirmEmailAddressMatch: "Alamat sureltidak cocok",
    validationEmailDomain: "Penyedia email ini tidak diterima, silakan gunakan alternatif",
    validationDateOfEvent: "Tanggal acara diperlukan",
    labelHomeCountry: "Negara Asal",
    validationHomeCountry: "Negara Asal diperlukan",
    labelContactNumberCountryCode: "Kode Negara Nomor Kontak",
    validationContactNumberCountryCode: "Kode Telepon Negara diperlukan",
    labelContactNumber: "Nomor Kontak",
    validationContactNumber: "Nomor Kontak diperlukan",
    labelAddressLine1: " baris alamat 1",
    labelAddressLine2: " baris alamat 2",
    labelCity: "Kota",
    labelZipCode: "Kode Pos",
    labelRegion: " wilayah",
    validationRegion: " wilayah diperlukan",
    labelNumberOfTicketsToRefund: "Jumlah Tiket untuk Dikembalikan",
    validationNumberOfTicketsToRefund: "Jumlah Tiket untuk Pengembalian Dana diperlukan",
    validationNumberOfTicketsToRefundValue: "Harus lebih besar dari 0",
    labelCostPerTicket: "Biaya setiap Tiket",
    validationCostPerTicket: "Biaya per Tiket diperlukan",
    validationCostPerTicketValue: "Harus lebih besar dari 0",
    labelTotalRefundAmount: "Jumlah Pengembalian Dana Total",
    validationTotalRefundAmount: "Diperlukan Jumlah Pengembalian Dana",
    validationTotalRefundAmountValue: "Harus lebih besar dari 0",
    validationTotalRefundGreaterThanBookingValue: "Jumlah Total Pengembalian Dana tidak boleh lebih besar dari Nilai Pemesanan di atas",
    labelBookingConfirmationFiles: "Unggah Konfirmasi Pemesanan",
    validationBookingConfirmationFiles: "Lampirkan setidaknya satu file atau klik Simpan dan kembali nanti ketika Anda memiliki dokumen",
    validationEvidenceFilesPopup: "Bukti harus diunggah sebelum diserahkan. Jika Anda tidak memiliki bukti sekarang, silakan simpan aplikasi dan kembalikan setelah Anda memilikinya. Kami menerima salinan elektronik, salinan pemindai, dan foto.",
    validationBookingConfirmationFilesPopup: "Konfirmasi pemesanan harus diunggah sebelum dikirim. Jika Anda tidak memiliki konfirmasi pemesanan sekarang, silakan simpan aplikasi dan kembalikan setelah Anda memilikinya. Kami menerima salinan elektronik, salinan pemindai, dan foto.",
    alertUploadDocuments: "Unggah dokumentasi relevan yang dirinci dalam email, lalu lanjutkan ke Langkah 3.",
    labelRefundReasonCategory: "Alasan Pengembalian Dana",
    validationRefundReasonCategory: "Diperlukan Alasan Pengembalian Dana",
    labelDateOfIncident: "Tanggal Insiden",
    labelAdditionalComments: "Komentar tambahan",
    tooltipAdditionalComments: "Komentar tambahan",
    validationAdditionalComments: "Diperlukan komentar tambahan",
    labelUnableToNotifyWithinTimeLimit: "Tidak Dapat Memberi Tahu Dalam 60 Hari Sejak",
    labelReasonForNotificationOutsideTimeLimit: "Pemberitahuan Di Luar Penjelasan Batas Waktu",
    validationReasonForNotificationOutsideTimeLimit: "Diperlukan alasan pemberitahuan setelah 60 hari",
    labelRefundReasonFiles: "Unggah Bukti",
    validationRefundReasonFiles: "Lampirkan setidaknya satu file atau klik Simpan dan kembali nanti ketika Anda memiliki dokumen",
    validationTextScript:"Harus sesuai dengan skrip latin",
    optionTypeOfBooking1: "Peristiwa",
    optionTypeOfBooking2: "Tur",
    optionTypeOfBooking3: "Penerbangan",
    optionTypeOfBooking4: "Akomodasi",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parkir",
    optionTypeOfBooking7: "Perjalanan Lainnya",
    labelEventTypeDate: "Tanggal Acara",
    labelEventTypeTour: "Tanggal Tur",
    labelEventTypeFlight: "Tanggal Penerbangan",
    labelEventTypeCheckIn: "Tanggal Check In",
    labelEventTypeTransfer: "Tanggal Transfer",
    labelEventTypeParking: "Tanggal Parkir",
    labelEventTypeOtherTravel: "Tanggal Perjalanan",
    optionRefundReason11: "Cuaca Buruk",
    optionRefundReason10: "Penarikan Kembali Angkatan Bersenjata & Layanan Darurat",
    optionRefundReason15: "Perubahan pada Tanggal Pemeriksaan",
    optionRefundReason18: "Panggilan Pengadilan",
    optionRefundReason17: "Pembatasan perjalanan COVID-19",
    optionRefundReason19: "Tes Positif COVID-19",
    optionRefundReason20: "Pemesanan Dibatalkan oleh Operator/Penyelenggara",
    optionRefundReason21: "Pemesanan Dijadwal Ulang oleh Operator/Penyelenggara",
    optionRefundReason22: "Memesan Tanggal yang Salah",
    optionRefundReason23: "Salah Memesan Tiket",
    optionRefundReason24: "Memesan Terlalu Banyak Tiket",
    optionRefundReason4: "Kematian",
    optionRefundReason16: "Keadaan Darurat",
    optionRefundReason14: "Larangan Perjalanan Pemerintah",
    optionRefundReason9: "Rumah Darurat",
    optionRefundReason1: "Penyakit / Cedera / Kecelakaan",
    optionRefundReason8: "Layanan Juri",
    optionRefundReason7: "Kerusakan Mekanis",
    optionRefundReason2: "Kondisi Medis yang Sudah Ada Sebelumnya",
    optionRefundReason3: "Komplikasi Kehamilan",
    optionRefundReason5: "Kegagalan Transportasi Umum",
    optionRefundReason6: "Gangguan Penerbangan",
    optionRefundReason13: "Pencurian Dokumen",
    optionRefundReason12: "Relokasi Kerja",
    optionRefundReason25: "Redundansi Tempat Kerja",
    optionRefundReason26: "Opsi Tes",
    optionRefundReasonConfirm1: "Tidak",
    optionRefundReasonConfirm2: "Ya",
    optionRefundReasonArmedForces1: "Tentara",
    optionRefundReasonArmedForces2: "Angkatan laut",
    optionRefundReasonArmedForces3: "Angkatan Udara",
    optionRefundReasonArmedForces4: "Pasukan Khusus",
    optionRefundReasonArmedForces5: "Marinir",
    optionRefundReasonArmedForces6: "Kebakaran",
    optionRefundReasonArmedForces7: "Ambulans",
    optionRefundReasonArmedForces8: "Dokter/Perawat",
    optionRefundReasonArmedForces9: "Dokter gigi",
    optionRefundReasonArmedForces10: "Lain",
    optionRefundReasonCourtSummons1: "Penuntutan Pidana",
    optionRefundReasonCourtSummons2: "Perkara Perkara Perdata",
    optionRefundReasonCourtSummons3: "Saksi dalam Penuntutan",
    optionRefundReasonCourtSummons4: "Saksi dalam Perkara Perdata",
    optionRefundReasonCourtSummons5: "Penggugat",
    optionRefundReasonCourtSummons6: "Terdakwa",
    optionRefundReasonCourtSummons7: "Lain",
    optionRefundReasonDeath1: "Suami",
    optionRefundReasonDeath2: "Istri",
    optionRefundReasonDeath3: "Bunyi",
    optionRefundReasonDeath4: "Anak perempuan",
    optionRefundReasonDeath5: "Ayah",
    optionRefundReasonDeath6: "Ibu",
    optionRefundReasonDeath7: "Saudara",
    optionRefundReasonDeath8: "Saudara perempuan",
    optionRefundReasonDeath9: "Kakek",
    optionRefundReasonDeath10: "Nenek",
    optionRefundReasonDeath11: "Lain",
    optionRefundReasonHomeEmergency1: "Kebakaran",
    optionRefundReasonHomeEmergency2: "Banjir",
    optionRefundReasonHomeEmergency3: "Perampokan",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Kekerasan Dalam Rumah Tangga",
    optionRefundReasonHomeEmergency6: "Menculik",
    optionRefundReasonHomeEmergency7: "Lain",
    optionRefundReasonCOVID19Person1: "Diriku",
    optionRefundReasonCOVID19Person2: "Orang lain dalam pemesanan",
    optionRefundReasonCOVID19Person3: "Seseorang di Rumah Tangga saya",
    optionRefundReasonCOVID19Person4: "Lain",
    optionRefundReasonCOVID19TestType1: "Tes PCR",
    optionRefundReasonCOVID19TestType2: "Uji RAT",
    optionRefundReasonCOVID19TestType3: "Uji Aliran Lateral",
    optionRefundReasonCOVID19TestType4: "Tes yang Diberikan Sendiri",
    optionRefundReasonCOVID19TestType5: "Tidak ada tes tetapi saya memiliki gejala",
    optionRefundReasonCOVID19TestType6: "Lain",
    optionRefundReasonBreakdown1: "Rincian",
    optionRefundReasonBreakdown2: "Kecelakaan",
    optionRefundReasonBreakdown3: "Kebakaran",
    optionRefundReasonBreakdown4: "Pencurian",
    optionRefundReasonPublicTransportType1: "Kereta api",
    optionRefundReasonPublicTransportType2: "Bis",
    optionRefundReasonPublicTransportType3: "Trem",
    optionRefundReasonPublicTransportType4: "Perahu",
    optionRefundReasonDocumentType1: "Paspor",
    optionRefundReasonDocumentType2: "Izin Perjalanan",
    optionRefundReasonDocumentType3: "ID Nasional",
    optionRefundReasonDocumentType4: "Tiket",
    optionRefundReasonWorkRelocationType1: "Sementara",
    optionRefundReasonWorkRelocationType2: "Abadi",
    optionRefundReasonWorkRelocationTemporary1: "0-3 bulan",
    optionRefundReasonWorkRelocationTemporary2: "3-6 bulan",
    optionRefundReasonWorkRelocationTemporary3: "Lebih dari 6 bulan",
    optionRefundReasonBookingRelationshipType1: "Seseorang dalam pemesanan",
    optionRefundReasonBookingRelationshipType2: "Kerabat dekat seseorang dalam pemesanan",
    optionEmploymentType1: "Penuh Waktu",
    optionEmploymentType2: "Paruh Waktu",
    optionEmploymentType3: "Kontrak",
    optionEmploymentLengthType1: "0-1 Tahun",
    optionEmploymentLengthType2: "1-2 Tahun",
    optionEmploymentLengthType3: "Lebih dari 2 tahun",
    textRefundEvidenceHeading: "Harap berikan bukti berikut:",
    textRefundEvidence1: "Konfirmasi Maskapai Penerbangan",
    textRefundEvidence2: "Bukti pendukung yang sesuai",
    textRefundEvidence3: "Layanan perincian memanggil konfirmasi",
    textRefundEvidence4: "Surat Keterangan Kematian",
    textRefundEvidence5: "Pembatalan konfirmasi dari maskapai penerbangan",
    textRefundEvidence6: "Laporan Cuaca",
    textRefundEvidence7: "Korespondensi dari agen pemesanan yang mengonfirmasi tidak ada kemampuan untuk menerbitkan ulang tiket",
    textRefundEvidence8: "Surat dari Pengadilan",
    textRefundEvidence9: "Sertifikat Medis / Catatan dokter",
    textRefundEvidenceDescription9: "Mengonfirmasi rincian penyakit atau cedera, tanggal pertama kali terjadi, yang mencegah Anda Menghadiri Pemesanan.",
    textRefundEvidence10: "Konfirmasi surat majikan",
    textRefundEvidence11: "Korespondensi klaim asuransi",
    textRefundEvidence12: "Surat dari Majikan",
    textRefundEvidence13: "Konfirmasi situs web Pemerintah Nasional",
    textRefundEvidence14: "Pemberitahuan Perubahan Pemeriksaan",
    textRefundEvidence15: "Laporan Polisi",
    textRefundEvidence16: "Laporan polisi dan nomor referensi",
    textRefundEvidence17: "Konfirmasi penyedia transportasi",
    textRefundEvidence18: "Peringatan Perjalanan",
    textRefundEvidence20: "Sertifikat Tes COVID-19",
    textRefundEvidence24: "Pemberitahuan Isolasi Pemerintah",
    textRefundEvidence25: "Surat dari Komandan",
    textRefundEvidence26: "Surat dari Perusahaan Asuransi",
    textRefundEvidence27: "Bukti Alamat",
    textRefundEvidence28: "Bukti hubungan dengan almarhum",
    textRefundEvidence29: "Bukti hubungan dengan orang yang terluka/sakit",
    textRefundEvidence30: "Slip gaji",
    tooltipBookingReference: "Untuk mengidentifikasi referensi pemesanan Anda, periksa konfirmasi pemesanan Anda dan lihat bagian tentang layanan tambahan kami.",
    tooltipCustomerName: "Nama depan dan belakang yang digunakan untuk melakukan pemesanan.",
    tooltipTypeOfBooking: "Jenis pemesanan yang ingin Anda kembalikan.",
    tooltipEventName: "Nama acara.",
    tooltipVenueName: "Nama tempat tersebut.",
    tooltipFlightNumber: "Nomor penerbangan.",
    tooltipOriginAirport: "Bandara keberangkatan.",
    tooltipHotelName: "Nama akomodasi.",
    tooltipHotelLocation: "Lokasi akomodasi.",
    tooltipCurrency: "Mata uang yang digunakan untuk membayar pemesanan.",
    tooltipBookingValue: "Harga total yang Anda bayarkan untuk pemesanan.",
    tooltipDateOfPurchase: "Tanggal pemesanan dibuat.",
    tooltipEmailAddress: "Harap gunakan alamat email yang valid. Kami menggunakan email ini untuk korespondensi selama aplikasi pengembalian dana.",
    tooltipConfirmEmailAddress: "Gunakan alamat email yang sama seperti di atas, ini untuk validasi.",
    tooltipHomeCountry: "Ini akan membantu kami memberikan informasi perbankan yang benar pada langkah terakhir.",
    tooltipContactNumberCountryCode: "Kode negara nomor kontak.",
    tooltipContactNumber: "Harap gunakan nomor yang valid. Kami menggunakan ini untuk korespondensi selama aplikasi pengembalian dana.",
    tooltipAddressLine1: "Harap dicatat bahwa alamat Anda akan digunakan saat memvalidasi rekening bank Anda.",
    tooltipAddressLine2: "Baris alamat kedua.",
    tooltipCity: "Kota asal Anda, detail dapat digunakan untuk memvalidasi detail perbankan.",
    tooltipZipCode: "Kode Pos / Kode Pos.",
    tooltipRegion: "Wilayah kota asal Anda, detail dapat digunakan untuk memvalidasi detail perbankan.",
    tooltipNumberOfTicketsToRefund: "Jumlah tiket yang ingin Anda kembalikan.",
    tooltipTotalRefundAmount: "Jumlah ini tidak boleh lebih dari nilai total pemesanan, Anda tidak akan bisa mengajukan aplikasi jika ya.",
    tooltipBookingConfirmationFiles: "Tangkapan layar / lampiran konfirmasi pemesanan lengkap Anda yang biasanya dapat ditemukan di email Anda.",
    tooltipRefundReasonCategory: "Pilih alasan pengembalian dana Anda.",
    tooltipDateOfIncident: "Tanggal terjadinya insiden yang memengaruhi pemesanan Anda.",
    tooltipRefundExplanation: "Harap berikan detail sebanyak mungkin yang mendukung klaim pengembalian dana.",
    tooltipUnableToNotifyWithinTimeLimit: "Tidak dapat memberi tahu dalam batas waktu 60 Hari.",
    tooltipReasonForNotificationOutsideTimeLimit: "Pemberitahuan Di Luar Penjelasan Batas Waktu.",
    tooltipRefundReasonFiles: "Silakan unggah salah satu dokumen yang diperlukan.",
    tooltipEventTypeDate: "Tanggal acara / pemesanan aktual.",
    tooltipEventTypeTour: "Tanggal tur sebenarnya.",
    tooltipEventTypeFlight: "Tanggal penerbangan sebenarnya.",
    tooltipEventTypeCheckIn: "Tanggal check-in sebenarnya.",
    tooltipEventTypeTransfer: "Tanggal transfer sebenarnya.",
    tooltipEventTypeParking: "Tanggal parkir sebenarnya.",
    tooltipEventTypeOtherTravel: "Tanggal mulai perjalanan sebenarnya."
};