import {useEffect, FC} from 'react';
import {GetMsClarityProjectId} from '../config';
import {useRecoilValue} from 'recoil';
import {MsClarityInjectionState} from '../components/state/activity';

interface MSClarityScriptProps {
    bookingReference?: string;
}

export const MSClarityScript: FC<MSClarityScriptProps> = ({ bookingReference }) => {
    const injectMsclarity = useRecoilValue(MsClarityInjectionState);

    useEffect(() => {
        if (injectMsclarity) {
            const projectId = GetMsClarityProjectId();

            (function (c: Window, l: Document, a: string, r: string, i: string, t?: HTMLScriptElement, y?: HTMLScriptElement) {
                (c as any)[a] = (c as any)[a] || function () {
                    ((c as any)[a].q = (c as any)[a].q || []).push(arguments);
                };
                t = l.createElement(r) as HTMLScriptElement;
                t.async = true;
                t.src = `https://www.clarity.ms/tag/${i}`;
                y = l.getElementsByTagName(r)[0] as HTMLScriptElement;
                y.parentNode.insertBefore(t, y);
            })(window, document, 'clarity', 'script', projectId);

            if (bookingReference) {
                const clarityInterval = setInterval(() => {
                    if (typeof (window as any).clarity === 'function') {
                        clearInterval(clarityInterval);
                        (window as any).clarity('set', 'bookingReference', bookingReference);
                    }
                }, 100);
            }
        }
    }, [injectMsclarity, bookingReference]);

    return null;
};