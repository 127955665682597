export default {
    nonAutoApprovedLabelThankYou: "ለመለስዎ ማመልከቻ እናመሰግናለን።",
    nonAutoApprovedLabelReview: "የእኛ ቡድን በሚቀጥሉት ሁለት የሥራ ቀናት ውስጥ ማመልከቻዎን ይመልከቱ እና ምላሽ በመስጠት ወደ እርስዎ ይመለሱ.",
    nonAutoApprovedLabelExperience: "እንዴት አድርገን ነበር?",
    nonAutoApprovedLabelExperienceIntroduction: "ጊዜ ወስዳችሁ እኛን ለመገምገም እና የደንበኞቻችንን አስተያየት ስናደንቅ ክለሳ ትታችሁ እንድትሄዱ እናመሰግናችኋለን።",
    feedbackExcellent: "እጅግ በጣም ጥሩ",
    feedbackGreat: "ታላቅ",
    feedbackAverage: "አማካይ",
    feedbackPoor: "ምስኪን",
    feedbackBad: "መጥፎ",
    labelThankYouForFeedback: "ለሰጠኸው አስተያየት አመሰግናለሁ።",
    autoApprovedMessage1: "ደስ ብሎናል",
    autoApprovedMessage2: "አጽድቅ",
    autoApprovedMessage3: "የመለስካችሁ!",
    autoApprovedPayment1Day: "የእርስዎ ክፍያ አስቀድሞ ተከናውኗል እና በ 1 የንግድ ቀን ውስጥ ብቻ በአካውንትዎ ውስጥ መሆን አለበት.",
    autoApprovedPayment2to4Days: "የእርስዎ ክፍያ አስቀድሞ ተሰርቷል እና በ2-4 የሥራ ቀናት ውስጥ ብቻ በአካውንትዎ ውስጥ መሆን አለበት.",
    autoApprovedPayment7to10Days: "የእርስዎ ክፍያ አስቀድሞ የተሰናዳ እና በ7-10 የሥራ ቀናት ውስጥ ብቻ በአካውንትዎ ውስጥ መሆን አለበት.",
    autoApprovedLabelExperience: "እንዴት አድርገን ነበር?",
    autoApprovedLabelExperienceIntroduction: "ጊዜ ወስዳችሁ እኛን ለመገምገም እና የደንበኞቻችንን አስተያየት ስናደንቅ ክለሳ ትታችሁ እንድትሄዱ እናመሰግናችኋለን።",
    modalLabelNegative: "አስተያየታችሁ ለእኛ በጣም አስፈላጊ ነው፣ ከእናንተ ጋር እንድንገናኝም የምትፈልግ ከሆነ ንገረን።",
    placeholderFeedback: "አስተያየት",
    messageFeedbackSubmitted: "አስተያየት ቀርቧል",
    reviewInstructionMessage: "5 ከዋክብት ግሩም ናቸው፣ 4 ከዋክብት ታላቅ ናቸው፣ 3 ከዋክብት አማካይ ናቸው፣ 2 ከዋክብት ድሆች ናቸው፣ 1 ኮከብ መጥፎ ነው።"
}