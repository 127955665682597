export default {
    headingAdverseWeather: "Vous rencontrez des conditions météorologiques défavorables sévères qui rendent dangereux de se rendre à l’événement et cela a été mandaté par un organisme gouvernemental (par exemple, l’autorité locale, la police, etc.). Et vous avez des preuves pour le prouver?",
    headingAdverseWeatherDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    tooltipRefundReasonConfirm: "Oui ou Non",
    labelBookingCancelledByOperator: "Les billets ont été annulés par l’Opérateur/Organisateur.",
    labelBookingRescheduledByOperator: "La date a été modifiée par l’Opérateur/Organisateur et je ne peux pas assister aux nouvelles dates.",
    labelBookedWrongDate: "Moi ou un membre de la réservation, j’ai fait une erreur lors de l’achat des billets et réservé la mauvaise date.",
    labelBookedWrongTickets: "Moi ou un membre de la réservation, j’ai fait une erreur lors de l’achat des billets et réservé les mauvais billets.",
    labelBookedTooManyTickets: "Moi ou un membre de la réservation, j’ai fait une erreur lors de l’achat des billets et réservé trop de billets.",
    labelCOVID19TravelRestrictions: "Moi ou un membre de la réservation ne sommes pas en mesure d’y assister en raison d’une interdiction de voyager COVID-19.",
    labelADWrongSelection: "Vous avez peut-être sélectionné la mauvaise raison pour un remboursement. Veuillez consulter à nouveau la liste déroulante Motif du remboursement.",
    labelAdverseWeather1: "Pourquoi les conditions météorologiques défavorables ont-elles empêché d’assister à l’événement?",
    tooltipAdverseWeather1: "S’il vous plaît détailler",
    labelAdverseWeather2: "Date de l’incident",
    tooltipAdverseWeather2: "Veuillez sélectionner",
    headingArmedForces: "Votre employeur vous demande, c’est-à-dire les forces armées ou les services d’urgence, d’entrer au travail alors que vous n’aviez pas prévu de le faire à l’origine, ce qui vous empêche d’assister à l’événement?",
    headingArmedForcesDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelArmedForces1: "Par quelles forces ou service d’urgence êtes-vous employé?",
    tooltipArmedForces1: "Veuillez sélectionner",
    labelArmedForcesOther1: "Veuillez préciser",
    tooltipArmedForcesOther1: "Veuillez préciser",
    labelArmedForces2: "Date de l’incident",
    tooltipArmedForces2: "Veuillez sélectionner",
    headingChangesToExam: "Les dates de votre examen ont été modifiées par votre école/collège, etc. ? Ce n’est pas un examen raté que vous devez répéter?",
    headingChangesToExamDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelExam1: "Quelle était la date initiale de l’examen?",
    tooltipExam1: "Veuillez sélectionner",
    labelExam2: "Quelle est la nouvelle date d’examen?",
    tooltipExam2: "Veuillez sélectionner",
    labelExam3: "Pourquoi la date de l’examen a-t-elle changé?",
    tooltipExam3: "Veuillez saisir les détails",
    labelExam4: "Avez-vous échoué à l’examen précédent?",
    tooltipExam4: "Oui ou Non",
    headingCourtSummons: "Vous avez été cité à comparaître devant le tribunal en tant que témoin aux mêmes dates que l’événement?",
    headingCourtSummonsDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelCourtSummons1: "Quelle est la raison de votre convocation au tribunal?",
    tooltipCourtSummons1: "Veuillez sélectionner",
    labelCourtSummonsOther1: "Veuillez préciser",
    tooltipCourtSummonsOther1: "Veuillez préciser",
    labelCourtSummons2: "Quand la citation à comparaître a-t-elle été émise?",
    tooltipCourtSummons2: "Veuillez sélectionner",
    headingCOVID19Test: "Moi, un membre de mon ménage ou un membre du groupe participant à l’événement avez reçu un diagnostic de COVID-19?",
    headingCOVID19TestDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelCOVID19Test1: "Qui a la COVID-19?",
    tooltipCOVID19Test1: "Veuillez sélectionner",
    labelCOVID19TestOther1a: "Veuillez préciser",
    labelCOVID19TestOther1b: "Spécifier le nom",
    tooltipCOVID19TestOther1a: "Veuillez préciser",
    tooltipCOVID19TestOther1b: "Spécifier le nom",
    labelCOVID19Test2: "Quel type de test COVID-19 avez-vous eu?",
    tooltipCOVID19Test2: "Veuillez sélectionner",
    labelCOVID19TestOther2: "Veuillez préciser",
    tooltipCOVID19TestOther2: "Veuillez préciser",
    labelCOVID19Test3: "Quelle était la date de votre test COVID-19?",
    tooltipCOVID19Test3: "Veuillez sélectionner",
    headingDeath: "Il y a eu un décès d’un membre immédiat de ma famille et cela m’empêche d’assister à l’événement?",
    headingDeathDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelDeath1: "Quel membre de votre famille est décédé?",
    tooltipDeath1: "Veuillez sélectionner",
    labelDeathOther1: "Veuillez préciser",
    tooltipDeathOther1: "Veuillez préciser",
    labelDeath2: "Quelle était la date du décès?",
    tooltipDeath2: "Veuillez sélectionner",
    labelDeath3: "Nom du défunt",
    tooltipDeath3: "Veuillez préciser",
    headingEmergency: "J’ai eu une véritable urgence qui est hors de mon contrôle et imprévue dont je peux fournir des preuves?",
    headingEmergencyDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelEmergency1: "Veuillez expliquer le type d’urgence?",
    tooltipEmergency1: "S’il vous plaît détailler",
    labelEmergency2: "Quand cela s’est-il produit?",
    tooltipEmergency2: "Veuillez sélectionner",
    labelEmergency3: "Pourquoi cette situation vous empêche-t-elle d’assister à l’événement?",
    tooltipEmergency3: "S’il vous plaît détailler",
    labelEmergency4: "Avez-vous des preuves de cette urgence?",
    tooltipEmergency4: "Oui ou Non",
    headingHomeEmergency: "J’ai une urgence à domicile qui est un vol, une inondation, un incendie, un vandalisme, une violence domestique qui m’empêche d’assister à l’événement?",
    headingHomeEmergencyDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelHomeEmergency1: "Qu’est-ce que l’urgence à domicile?",
    tooltipHomeEmergency1: "Veuillez sélectionner",
    labelHomeEmergencyOther1: "Veuillez préciser",
    tooltipHomeEmergencyOther1: "Veuillez préciser",
    labelHomeEmergency2: "Quand cela s’est-il produit?",
    tooltipHomeEmergency2: "Veuillez sélectionner",
    labelHomeEmergency3: "Pourquoi cela vous a-t-il empêché d’assister à l’événement?",
    tooltipHomeEmergency3: "S’il vous plaît détailler",
    headingInjury: "Moi, un membre de mon ménage ou un membre du groupe participant à l’événement avez reçu un diagnostic avec une condition médicale ou une blessure qui nous empêche maintenant d’aller à l’événement?",
    headingInjuryDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelInjury1: "Quelle est la nature de la maladie, de la blessure ou de l’accident?",
    tooltipInjury1: "S’il vous plaît détailler",
    labelInjury2: "Quand cela s’est-il produit?",
    tooltipInjury2: "Veuillez sélectionner",
    labelInjury3: "Avez-vous fréquenté un établissement médical pour cette maladie/blessure ?",
    tooltipInjury3: "Oui ou Non",
    labelInjury4: "S’agissait-il d’une condition médicale préexistante?",
    tooltipInjury4: "Oui ou Non",
    labelInjury5: "Quand cela a-t-il été diagnostiqué pour la première fois?",
    tooltipInjury5: "Veuillez sélectionner",
    labelInjury6: "Pourquoi cela signifie-t-il que vous ne pouvez pas assister à l’événement?",
    tooltipInjury6: "S’il vous plaît détailler",
    labelInjury7: "Qui a souffert de la maladie ou de la blessure?",
    tooltipInjury7: "Veuillez sélectionner",
    headingJuryService: "Moi ou un membre de la réservation a été appelé au service de jury?",
    headingJuryServiceDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelJuryService1: "Quand avez-vous été avisé du service de jury?",
    tooltipJuryService1: "Veuillez sélectionner",
    headingMechanicalBreakdown: "Notre véhicule a eu une panne mécanique ou a eu un accident et cela nous empêche d’assister à l’événement?",
    headingMechanicalBreakdownDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelMechanicalBreakdown1: "Que s’est-il passé avec le véhicule?",
    tooltipMechanicalBreakdown1: "Veuillez sélectionner",
    labelMechanicalBreakdown2: "Quand cela s’est-il produit?",
    tooltipMechanicalBreakdown2: "Veuillez sélectionner",
    labelMechanicalBreakdown3: "Pourquoi n’avez-vous pas pu prendre un autre moyen de transport?",
    tooltipMechanicalBreakdown3: "S’il vous plaît détailler",
    headingPregnancyComplication: "Moi ou un membre de la réservation a des complications de grossesse qui ont été diagnostiquées par un professionnel de la santé et empêchent donc la participation à l’événement?",
    headingPregnancyComplicationDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelPregnancyComplication1: "Quelle est la nature de la complication de grossesse?",
    tooltipPregnancyComplication1: "S’il vous plaît détailler",
    labelPregnancyComplication2: "Quand cela s’est-il produit?",
    tooltipPregnancyComplication2: "Veuillez sélectionner",
    labelPregnancyComplication3: "Avez-vous consulté un médecin?",
    tooltipPregnancyComplication3: "Oui ou Non",
    labelPregnancyComplication4: "Pourquoi cela signifie-t-il que vous ne pouvez pas assister à l’événement?",
    tooltipPregnancyComplication4: "S’il vous plaît détailler",
    headingPublicTransportFailure: "La méthode de transport public (train, bus, tram, bateau, etc.) a échoué de manière inattendue, ce qui signifie qu’il n’y avait pas d’autre moyen de me rendre à cet événement?",
    headingPublicTransportFailureDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelPublicTransportFailure1: "Quel type de transport en commun aviez-vous l’intention d’utiliser?",
    tooltipPublicTransportFailure1: "Veuillez sélectionner",
    labelPublicTransportFailure2: "Quand avez-vous prévu de voyager?",
    tooltipPublicTransportFailure2: "Veuillez sélectionner",
    labelPublicTransportFailure3: "Que s’est-il passé??",
    tooltipPublicTransportFailure3: "S’il vous plaît détailler",
    labelPublicTransportFailure4: "Pourquoi ne pourriez-vous pas prendre un autre moyen de transport?",
    tooltipPublicTransportFailure4: "S’il vous plaît détailler",
    headingScheduledAirlineFailure: "Mon vol pré-réservé a été annulé de manière inattendue et je n’ai pas pu trouver une alternative appropriée, ce qui signifie que je n’ai pas pu assister à l’événement?",
    headingScheduledAirlineFailureDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelScheduledAirlineFailure1: "Nom de la compagnie aérienne",
    tooltipScheduledAirlineFailure1: "Nom de la compagnie aérienne",
    labelScheduledAirlineFailure2: "Numéro de vol",
    tooltipScheduledAirlineFailure2: "Numéro de vol",
    labelScheduledAirlineFailure3: "Voyager de",
    tooltipScheduledAirlineFailure3: "Voyager de",
    labelScheduledAirlineFailure4: "Voyager à",
    tooltipScheduledAirlineFailure4: "Voyager à",
    labelScheduledAirlineFailure5: "Date de l’incident?",
    tooltipScheduledAirlineFailure5: "Veuillez sélectionner",
    labelScheduledAirlineFailure6: "Pourquoi n’avez-vous pas pu prendre un autre vol?",
    tooltipScheduledAirlineFailure6: "S’il vous plaît détailler",
    headingTheftOfDocuments: "Mes documents ont été volés et il n’a pas été possible d’organiser des remplacements dans les délais nécessaires, de sorte que je ne peux pas assister à l’événement.",
    headingTheftOfDocumentsDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelTheftOfDocuments1: "Quel type de document a été volé?",
    tooltipTheftOfDocuments1: "Veuillez sélectionner",
    labelTheftOfDocuments2: "Quand cela s’est-il produit?",
    tooltipTheftOfDocuments2: "Veuillez sélectionner",
    labelTheftOfDocuments3: "Avez-vous essayé de faire rééditer les documents?",
    tooltipTheftOfDocuments3: "Oui ou Non",
    headingWorkRelocation: "Mon employeur m’a demandé de changer le lieu de mon travail pendant au moins trois mois et la distance est importante, ce qui signifie que je ne peux plus assister à l’événement.",
    headingWorkRelocationDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera très probablement refusé.",
    labelWorkRelocation1: "S’agit-il d’un déménagement temporaire ou permanent de votre travail?",
    tooltipWorkRelocation1: "Veuillez sélectionner",
    labelWorkRelocation2: "Combien de temps serez-vous à la nouvelle adresse?",
    tooltipWorkRelocation2: "S’il vous plaît détailler",
    labelWorkRelocation3: "Quelle est votre adresse de travail actuelle?",
    tooltipWorkRelocation3: "S’il vous plaît détailler",
    labelWorkRelocation4: "Quelle est votre nouvelle adresse de travail?",
    tooltipWorkRelocation4: "S’il vous plaît détailler",
    labelWorkRelocation5: "Quand avez-vous été informé de ce déménagement?",
    tooltipWorkRelocation5: "Veuillez sélectionner",
    headingRedundancy: "Moi ou un membre de la réservation a été licencié par son employeur, ce qui signifie que je ne peux plus assister à la réservation?",
    headingRedundancyDecline: "Vous ne devez pas procéder à cette demande car le remboursement sera probablement refusé.",
    labelRedundancy1: "Type d’emploi?",
    tooltipRedundancy1: "Veuillez sélectionner",
    labelRedundancy2: "Depuis combien de temps êtes-vous employé par cette entreprise?",
    tooltipRedundancy2: "Veuillez sélectionner",
    labelRedundancy3: "Le licenciement était-il volontaire?",
    tooltipRedundancy3: "Veuillez sélectionner",
    labelRedundancy4: "Quand avez-vous été informé de votre licenciement ?",
    tooltipRedundancy4: "Veuillez sélectionner",
    labelTestOption: "Sélectionnez Oui pour continuer",
}