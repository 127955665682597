import * as React from 'react';
import {
    Button,
    ThemeProvider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {buttonTheme} from '../themes/ButtonThemeProvider';

interface SearchButtonProps {
    buttonText: string;
    disabled?: boolean;
    handleOnClick: () => void;
}

export const SearchButton = (props: SearchButtonProps) => {

    const {
        buttonText,
        disabled,
        handleOnClick,
    } = props;

    return (
        <ThemeProvider theme={buttonTheme}>
            <Button
                startIcon={<SearchIcon fontSize='small'/>}
                onClick={handleOnClick}
                disabled={disabled}>
                {buttonText}
            </Button>
        </ThemeProvider>
    );
}