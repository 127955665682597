import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FormSectionProps} from './RefundReasonHandlerSection';
import {RefundReasonConfirmationSection} from './RefundReasonConfirmationSection';

export const COVID19TravelRestrictionsSection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {t} = useTranslation();

    return (
        <>
            <RefundReasonConfirmationSection
                formProps={formProps}
                heading={t('bespokeQuestions:labelCOVID19TravelRestrictions')}
                declineWarning={t('bespokeQuestions:labelADWrongSelection')}
            />
        </>
    );
}