import * as React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {nameof} from 'ts-simple-nameof';
import {TextInput} from '../formInputs';
import {IFormValues} from '../Interfaces/IFormValues';
import {useTranslation} from 'react-i18next';
import {ICreateFeedbackRequest} from '../Interfaces/ICreateFeedbackRequest';
import {useFormContext} from '../providers/FormProvider';
import {useState} from 'react';
import {FormikProps} from 'formik';
import {useSetRecoilState} from 'recoil';
import {ShowReviewSectionState} from '../../state/activity';
import {ConfirmationStarControl} from './ConfirmationStarControl';
import {CancelButton} from '../buttons/CancelButton';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {ModalHolder} from '../modals/ModalHolder';

interface ConfirmationFeedbackControlProps {
    experienceIntroductionText: string,
    experienceText: string,
    formProps: FormikProps<IFormValues>;
    isAutoApproved: boolean,
    reviewSelected: boolean,
}

interface ConfirmationFeedbackControlProps {
    formProps: FormikProps<IFormValues>;
}

export const ConfirmationFeedbackControl = (props: ConfirmationFeedbackControlProps) => {

    const {
        experienceText,
        experienceIntroductionText,
        reviewSelected,
        isAutoApproved,
        formProps
    } = props;

    const {
        createApplicationResponse,
        saveFeedback,
        setShowNegativeFeedbackModal,
        showNegativeFeedbackModal
    } = useFormContext();

    const {t} = useTranslation();

    const [showFeedbackOptions, setShowFeedbackOptions] = useState<boolean>(true);
    const setShowReviewSectionState = useSetRecoilState(ShowReviewSectionState);
    const [feedback, setFeedback] = useState<string>('');

    const positiveFeedbackHandlerSubmit = (reviewSelected: boolean, isAutoApproved: boolean, feedback: string) => {

        const positiveFeedbackRequest: ICreateFeedbackRequest = {
            refundId: createApplicationResponse.refundId,
            feedback: feedback,
            isAutoApproved: isAutoApproved,
            noteText: null,
            reviewSelected: reviewSelected
        };

        saveFeedback(positiveFeedbackRequest);

        if (!isAutoApproved) {
            setShowFeedbackOptions(false);
            return;
        }

        setShowReviewSectionState(createApplicationResponse.enableReview);
    }

    const negativeFeedbackModalHandler = (feedback: string) => {
        setFeedback(feedback);
        setShowNegativeFeedbackModal(true);
    }

    const negativeFeedbackHandler = (feedbackProvided: boolean, isAutoApproved: boolean, feedback: string) => {
        if (feedbackProvided) {
            const negativeFeedbackRequest: ICreateFeedbackRequest = {
                refundId: createApplicationResponse.refundId,
                feedback: feedback,
                isAutoApproved: isAutoApproved,
                noteText: formProps.values.feedbackComments?.trim(),
                reviewSelected: false
            };
            saveFeedback(negativeFeedbackRequest);
            setShowFeedbackOptions(false);
        }

        setShowNegativeFeedbackModal(false);
    }

    const negativeFeedbackProvidedNo = () => {
        negativeFeedbackHandler(false, createApplicationResponse.isAutoApproved, feedback);
    }

    const negativeFeedbackProvidedYes = () => {
        negativeFeedbackHandler(true, createApplicationResponse.isAutoApproved, feedback);
    }

    const Content = () => {
        return (
            <>
                <Box pb={2} pt={2}>
                    <Box pb={2}><b>{t('confirmation:modalLabelNegative')}</b></Box>
                    <TextInput
                        key={nameof<IFormValues>(x => x.feedbackComments)}
                        controlId={nameof<IFormValues>(x => x.feedbackComments)}
                        label={null}
                        multiLine
                        hidden={false}
                        placeholder={t('confirmation:placeholderFeedback')}
                        maxLength={250}
                    />
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CancelButton
                        buttonText={t('common:buttonClose')}
                        handleOnClick={negativeFeedbackProvidedNo}
                    />

                    <ConfirmButton
                        isSubmitButton={false}
                        buttonText={t('common:buttonSubmit')}
                        handleOnClick={negativeFeedbackProvidedYes}
                    />
                </Box>
            </>
        );
    }

    return (
        <>
            {showFeedbackOptions &&
                <>
                    <Grid container justifyContent='center' textAlign='center'>
                        <Grid size={{md: 6}}>
                            <h3><b>{experienceText}</b></h3>
                            <h5>{experienceIntroductionText}</h5>
                        </Grid>
                    </Grid>

                    <ConfirmationStarControl
                        reviewSelected={reviewSelected}
                        isAutoApproved={isAutoApproved}
                        negativeFeedbackModalHandler={negativeFeedbackModalHandler}
                        positiveFeedbackHandlerSubmit={positiveFeedbackHandlerSubmit}
                    />
                </>
            }

            {!showFeedbackOptions &&
                <Grid container justifyContent='center' textAlign='center'>
                    <Grid size={{md: 6}}>
                        <h4>{t('confirmation:labelThankYouForFeedback')}</h4>
                    </Grid>
                </Grid>
            }

            <ModalHolder
                showModal={showNegativeFeedbackModal}
                content={<Content/>}
                handleClose={negativeFeedbackProvidedNo}
                width='md'
            />
        </>
    );
}