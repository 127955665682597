import * as React from 'react';
import {FormikProps} from 'formik';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {NumberInput} from '../formInputs';
import {useState} from 'react';
import {ProductModal} from '../modals/ProductModal';
import {useFormContext} from '../providers/FormProvider';
import {IFormValues} from '../Interfaces/IFormValues';

interface ProductSelectionControlProps {
    formProps: FormikProps<IFormValues>;
}

export const ProductSelectionControl = (props: ProductSelectionControlProps) => {

    const {
        formProps
    } = props;

    const {
        disableTicketChange,
        platformProductItems
    } = useFormContext();

    const {t} = useTranslation();

    const [showProductModal, setShowProductModal] = useState<boolean>(false);

    const handleShowProductModal = () => {
        setShowProductModal(true);
    }

    const handleCloseProductModal = () => {
        setShowProductModal(false);
    }

    return (
        <>
            <NumberInput
                key={nameof<IFormValues>(x => x.numberOfTicketsToRefund)}
                label={t('form:labelNumberOfTicketsToRefund')}
                controlId={nameof<IFormValues>(x => x.numberOfTicketsToRefund)}
                hidden={platformProductItems.length === 0}
                disabled={platformProductItems.length > 0}
                tooltip={t('form:tooltipNumberOfTicketsToRefund')}
                buttonAddOnText={!disableTicketChange && platformProductItems.length > 0 ?
                    `${t('common:labelChange')}` : `${t('common:labelView')}`}
                buttonAddOnClickEvent={handleShowProductModal}
                buttonAddOnVisible={platformProductItems.length > 0}
                required
            />

            <ProductModal
                formProps={formProps}
                showModal={showProductModal}
                handleClose={handleCloseProductModal}
            />
        </>
    );
}