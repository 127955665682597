export default {
    headingAdverseWeather: "คุณประสบกับสภาพอากาศที่เลวร้ายอย่างรุนแรงซึ่งทําให้ไม่ปลอดภัยในการเดินทางไปยังเหตุการณ์และสิ่งนี้ได้รับคําสั่งจากหน่วยงานของรัฐ (เช่นหน่วยงานท้องถิ่นตํารวจ ฯลฯ ) และคุณมีหลักฐานที่จะพิสูจน์เรื่องนี้?",
    headingAdverseWeatherDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    tooltipRefundReasonConfirm: "ใช่ หรือ ไม่",
    labelBookingCancelledByOperator: "ตั๋วถูกยกเลิกโดยผู้ประกอบการ/ผู้จัด",
    labelBookingRescheduledByOperator: "วันที่มีการเปลี่ยนแปลงโดยผู้ประกอบการ / ผู้จัดและฉันไม่สามารถเข้าร่วมวันที่ใหม่ได้",
    labelBookedWrongDate: "ฉันหรือสมาชิกของการจองทําผิดพลาดเมื่อซื้อตั๋วและจองวันที่ผิด",
    labelBookedWrongTickets: "ฉันหรือสมาชิกของการจองทําผิดพลาดเมื่อซื้อตั๋วและจองตั๋วผิด",
    labelBookedTooManyTickets: "ฉันหรือสมาชิกของการจองทําผิดพลาดเมื่อซื้อตั๋วและจองตั๋วมากเกินไป",
    labelCOVID19TravelRestrictions: "ฉันหรือสมาชิกของการจองไม่สามารถเข้าร่วมได้เนื่องจากถูกห้ามเดินทางในสถานการณ์ COVID-19",
    labelADWrongSelection: "คุณอาจเลือกเหตุผลในการคืนเงินผิด โปรดตรวจสอบรายการแบบเลื่อนลงเหตุผลการคืนเงินอีกครั้ง",
    labelAdverseWeather1: "ทําไมสภาพอากาศที่เลวร้ายจึงหมายความว่าคุณไม่สามารถเข้าร่วมกิจกรรมได้?",
    tooltipAdverseWeather1: "กรุณารายละเอียด",
    labelAdverseWeather2: "วันที่เกิดเหตุ",
    tooltipAdverseWeather2: "โปรดเลือก",
    headingArmedForces: "คุณถูกถามโดยนายจ้างของคุณซึ่งเป็นกองทัพหรือบริการฉุกเฉินที่จะเข้ามาทํางานเมื่อคุณไม่ได้มีกําหนดที่จะทําเช่นนั้นในตอนแรกและสิ่งนี้จะป้องกันไม่ให้คุณเข้าร่วมกิจกรรม?",
    headingArmedForcesDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelArmedForces1: "คุณได้รับการว่าจ้างจากกองกําลังหรือบริการฉุกเฉินใด",
    tooltipArmedForces1: "โปรดเลือก",
    labelArmedForcesOther1: "โปรดระบุ",
    tooltipArmedForcesOther1: "โปรดระบุ",
    labelArmedForces2: "วันที่เกิดเหตุ",
    tooltipArmedForces2: "โปรดเลือก",
    headingChangesToExam: "วันที่สอบของคุณมีการเปลี่ยนแปลงโดยโรงเรียน / วิทยาลัยของคุณ ฯลฯ ? นี่ไม่ใช่การสอบที่ล้มเหลวซึ่งคุณต้องทําซ้ําหรือไม่?",
    headingChangesToExamDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelExam1: "วันสอบเดิมคืออะไร?",
    tooltipExam1: "โปรดเลือก",
    labelExam2: "วันสอบใหม่คืออะไร?",
    tooltipExam2: "โปรดเลือก",
    labelExam3: "ทําไมวันสอบถึงเปลี่ยนไป?",
    tooltipExam3: "โปรดป้อนรายละเอียด",
    labelExam4: "คุณสอบตกครั้งก่อนหรือเปล่า?",
    tooltipExam4: "ใช่ หรือ ไม่",
    headingCourtSummons: "คุณถูกเรียกตัวให้ไปขึ้นศาล ในฐานะพยาน ในวันเดียวกับเหตุการณ์?",
    headingCourtSummonsDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelCourtSummons1: "เหตุผลในหมายเรียกศาลของคุณคืออะไร?",
    tooltipCourtSummons1: "โปรดเลือก",
    labelCourtSummonsOther1: "โปรดระบุ",
    tooltipCourtSummonsOther1: "โปรดระบุ",
    labelCourtSummons2: "ออกหมายเรียกศาลเมื่อไหร่?",
    tooltipCourtSummons2: "โปรดเลือก",
    headingCOVID19Test: "ฉันสมาชิกในครอบครัวของฉันหรือสมาชิกในกลุ่มที่เข้าร่วมกิจกรรมได้รับการวินิจฉัยว่าติดเชื้อ COVID-19 หรือไม่?",
    headingCOVID19TestDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelCOVID19Test1: "ใครมี COVID-19 บ้าง?",
    tooltipCOVID19Test1: "โปรดเลือก",
    labelCOVID19TestOther1a: "โปรดระบุ",
    labelCOVID19TestOther1b: "ระบุชื่อ",
    tooltipCOVID19TestOther1a: "โปรดระบุ",
    tooltipCOVID19TestOther1b: "ระบุชื่อ",
    labelCOVID19Test2: "คุณมีการตรวจ COVID-19 ประเภทใด?",
    tooltipCOVID19Test2: "โปรดเลือก",
    labelCOVID19TestOther2: "โปรดระบุ",
    tooltipCOVID19TestOther2: "โปรดระบุ",
    labelCOVID19Test3: "วันตรวจ COVID-19 ของคุณคือวันไหน?",
    tooltipCOVID19Test3: "โปรดเลือก",
    headingDeath: "มีการตายของสมาชิกในครอบครัวของฉันทันทีและมันป้องกันไม่ให้ฉันเข้าร่วมกิจกรรม?",
    headingDeathDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelDeath1: "สมาชิกคนไหนในครอบครัวคุณเสียชีวิต",
    tooltipDeath1: "โปรดเลือก",
    labelDeathOther1: "โปรดระบุ",
    tooltipDeathOther1: "โปรดระบุ",
    labelDeath2: "วันตายคือเมื่อไหร่?",
    tooltipDeath2: "โปรดเลือก",
    labelDeath3: "ชื่อผู้เสียชีวิต",
    tooltipDeath3: "โปรดระบุ",
    headingEmergency: "ฉันมีเหตุฉุกเฉินของแท้ที่อยู่นอกเหนือการควบคุมและไม่คาดฝันซึ่งฉันสามารถให้หลักฐานได้หรือไม่?",
    headingEmergencyDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelEmergency1: "กรุณาอธิบายประเภทของเหตุฉุกเฉิน?",
    tooltipEmergency1: "กรุณารายละเอียด",
    labelEmergency2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipEmergency2: "โปรดเลือก",
    labelEmergency3: "ทําไมสถานการณ์นี้ถึงหยุดคุณที่เข้าร่วมกิจกรรม?",
    tooltipEmergency3: "กรุณารายละเอียด",
    labelEmergency4: "คุณมีหลักฐานของเหตุฉุกเฉินนี้ไหม?",
    tooltipEmergency4: "ใช่ หรือ ไม่",
    headingHomeEmergency: "ฉันมีเหตุฉุกเฉินที่บ้านซึ่งเป็นการปล้น, น้ําท่วม, ไฟ, ป่าเถื่อน, การละเมิดในประเทศที่ป้องกันไม่ให้ฉันจากการเข้าร่วมเหตุการณ์?",
    headingHomeEmergencyDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelHomeEmergency1: "เหตุฉุกเฉินที่บ้านคืออะไร?",
    tooltipHomeEmergency1: "โปรดเลือก",
    labelHomeEmergencyOther1: "โปรดระบุ",
    tooltipHomeEmergencyOther1: "โปรดระบุ",
    labelHomeEmergency2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipHomeEmergency2: "โปรดเลือก",
    labelHomeEmergency3: "ทําไมมันถึงหยุดคุณที่เข้าร่วมงาน?",
    tooltipHomeEmergency3: "กรุณารายละเอียด",
    headingInjury: "ฉันสมาชิกในครอบครัวของฉันหรือสมาชิกในกลุ่มที่เข้าร่วมกิจกรรมได้รับการวินิจฉัยว่ามีเงื่อนไขทางการแพทย์หรือการบาดเจ็บที่ตอนนี้ทําให้เราไม่สามารถไปงานได้?",
    headingInjuryDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelInjury1: "ลักษณะของการเจ็บป่วย / การบาดเจ็บ / อุบัติเหตุคืออะไร?",
    tooltipInjury1: "กรุณารายละเอียด",
    labelInjury2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipInjury2: "โปรดเลือก",
    labelInjury3: "คคุณเคยเข้าสถานพยาบาลสำหรับการเจ็บป่วย/ได้รับบาดเจ็บนี้หรือไม่?",
    tooltipInjury3: "ใช่ หรือ ไม่",
    labelInjury4: "นี่เป็นเงื่อนไขทางการแพทย์ที่มีอยู่ก่อนหรือไม่?",
    tooltipInjury4: "ใช่ หรือ ไม่",
    labelInjury5: "นี่ได้รับการวินิจฉัยครั้งแรกเมื่อไหร่?",
    tooltipInjury5: "โปรดเลือก",
    labelInjury6: "ทําไมจึงหมายความว่าคุณไม่สามารถเข้าร่วมกิจกรรมได้?",
    tooltipInjury6: "กรุณารายละเอียด",
    labelInjury7: "ใครบ้างที่เจ็บป่วย/บาดเจ็บ?",
    tooltipInjury7: "กรุณาเลือก",
    headingJuryService: "ฉันหรือสมาชิกของการจองถูกเรียกไปยังบริการคณะลูกขุน?",
    headingJuryServiceDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelJuryService1: "คุณได้รับแจ้งจากคณะลูกขุนเมื่อไหร่?",
    tooltipJuryService1: "โปรดเลือก",
    headingMechanicalBreakdown: "รถของเราเสียทางกลหรือประสบอุบัติเหตุและสิ่งนี้ทําให้เราไม่สามารถเข้าร่วมกิจกรรมได้?",
    headingMechanicalBreakdownDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelMechanicalBreakdown1: "เกิดอะไรขึ้นกับรถคันนั้น?",
    tooltipMechanicalBreakdown1: "โปรดเลือก",
    labelMechanicalBreakdown2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipMechanicalBreakdown2: "โปรดเลือก",
    labelMechanicalBreakdown3: "ทําไมคุณไม่สามารถใช้บริการขนส่งทางเลือกได้?",
    tooltipMechanicalBreakdown3: "กรุณารายละเอียด",
    headingPregnancyComplication: "ฉันหรือสมาชิกของการจองมีภาวะแทรกซ้อนของการตั้งครรภ์ที่ได้รับการวินิจฉัยโดยแพทย์ผู้เชี่ยวชาญและดังนั้นจึงป้องกันการเข้าร่วมในงาน?",
    headingPregnancyComplicationDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelPregnancyComplication1: "ลักษณะของภาวะแทรกซ้อนของการตั้งครรภ์คืออะไร?",
    tooltipPregnancyComplication1: "กรุณารายละเอียด",
    labelPregnancyComplication2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipPregnancyComplication2: "โปรดเลือก",
    labelPregnancyComplication3: "คุณไปหาหมอมาเหรอ?",
    tooltipPregnancyComplication3: "ใช่ หรือ ไม่",
    labelPregnancyComplication4: "ทําไมจึงหมายความว่าคุณไม่สามารถเข้าร่วมกิจกรรมได้?",
    tooltipPregnancyComplication4: "กรุณารายละเอียด",
    headingPublicTransportFailure: "วิธีการขนส่งสาธารณะ (รถไฟรถบัสรถรางเรือ ฯลฯ ) ล้มเหลวโดยไม่คาดคิดหมายความว่าไม่มีวิธีอื่นที่ฉันสามารถเดินทางไปยังเหตุการณ์นี้ได้?",
    headingPublicTransportFailureDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelPublicTransportFailure1: "คุณวางแผนที่จะใช้ระบบขนส่งสาธารณะประเภทใด",
    tooltipPublicTransportFailure1: "โปรดเลือก",
    labelPublicTransportFailure2: "คุณวางแผนจะเดินทางตั้งแต่เมื่อไหร่?",
    tooltipPublicTransportFailure2: "โปรดเลือก",
    labelPublicTransportFailure3: "เกิดอะไรขึ้น ",
    tooltipPublicTransportFailure3: "กรุณารายละเอียด",
    labelPublicTransportFailure4: "ทําไมคุณไม่ใช้การขนส่งทางเลือก?",
    tooltipPublicTransportFailure4: "กรุณารายละเอียด",
    headingScheduledAirlineFailure: "เที่ยวบินที่จองไว้ล่วงหน้าของฉันถูกยกเลิกโดยไม่คาดคิดและฉันไม่สามารถหาทางเลือกที่เหมาะสมซึ่งหมายความว่าฉันไม่สามารถเข้าร่วมกิจกรรมได้หรือไม่?",
    headingScheduledAirlineFailureDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelScheduledAirlineFailure1: "ชื่อสายการบิน",
    tooltipScheduledAirlineFailure1: "ชื่อสายการบิน",
    labelScheduledAirlineFailure2: "เที่ยวบินหมายเลข",
    tooltipScheduledAirlineFailure2: "เที่ยวบินหมายเลข",
    labelScheduledAirlineFailure3: "การเดินทางจาก",
    tooltipScheduledAirlineFailure3: "การเดินทางจาก",
    labelScheduledAirlineFailure4: "การเดินทางไปยัง",
    tooltipScheduledAirlineFailure4: "การเดินทางไปยัง",
    labelScheduledAirlineFailure5: "วันที่เกิดเหตุ?",
    tooltipScheduledAirlineFailure5: "โปรดเลือก",
    labelScheduledAirlineFailure6: "ทําไมคุณถึงไม่สามารถขึ้นเครื่องอื่นได้?",
    tooltipScheduledAirlineFailure6: "กรุณารายละเอียด",
    headingTheftOfDocuments: "เอกสารของฉันถูกขโมยและไม่สามารถจัดให้มีการเปลี่ยนภายในกรอบเวลาที่จําเป็นดังนั้นฉันจึงไม่สามารถเข้าร่วมกิจกรรมได้",
    headingTheftOfDocumentsDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelTheftOfDocuments1: "เอกสารชนิดใดที่ถูกขโมย",
    tooltipTheftOfDocuments1: "โปรดเลือก",
    labelTheftOfDocuments2: "มันเกิดขึ้นเมื่อไหร่?",
    tooltipTheftOfDocuments2: "โปรดเลือก",
    labelTheftOfDocuments3: "คุณพยายามออกเอกสารใหม่หรือไม่?",
    tooltipTheftOfDocuments3: "ใช่ หรือ ไม่",
    headingWorkRelocation: "นายจ้างของฉันได้ขอให้ฉันเปลี่ยนสถานที่ทํางานของฉันเป็นเวลาอย่างน้อยสามเดือนและระยะทางที่สําคัญหมายความว่าตอนนี้ฉันไม่สามารถเข้าร่วมกิจกรรมได้หรือไม่?",
    headingWorkRelocationDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelWorkRelocation1: "นี่เป็นการย้ายงานชั่วคราวหรือถาวรของคุณหรือไม่?",
    tooltipWorkRelocation1: "โปรดเลือก",
    labelWorkRelocation2: "คุณจะอยู่ที่ที่อยู่ใหม่นานแค่ไหน?",
    tooltipWorkRelocation2: "กรุณารายละเอียด",
    labelWorkRelocation3: "ที่อยู่ที่ทํางานปัจจุบันของคุณคืออะไร",
    tooltipWorkRelocation3: "กรุณารายละเอียด",
    labelWorkRelocation4: "ที่อยู่ที่ทํางานใหม่ของคุณคืออะไร",
    tooltipWorkRelocation4: "กรุณารายละเอียด",
    labelWorkRelocation5: "คุณได้รับแจ้งเรื่องการย้ายถิ่นฐานนี้เมื่อไหร่?",
    tooltipWorkRelocation5: "โปรดเลือก",
    headingRedundancy: "ฉันหรือสมาชิกของการจองถูกนายจ้างทําซ้ําซ้อนซึ่งหมายความว่าฉันไม่สามารถเข้าร่วมการจองได้อีกต่อไป?",
    headingRedundancyDecline: "คุณไม่ควรดําเนินการกับแอปพลิเคชันนี้เนื่องจากการคืนเงินมักจะถูกปฏิเสธ",
    labelRedundancy1: "ประเภทของการจ้างงาน?",
    tooltipRedundancy1: "กรุณาเลือก",
    labelRedundancy2: "คุณได้รับการว่าจ้างจาก บริษัท นี้มานานแค่ไหนแล้ว?",
    tooltipRedundancy2: "กรุณาเลือก",
    labelRedundancy3: "ความซ้ําซ้อนเป็นไปโดยสมัครใจหรือไม่?",
    tooltipRedundancy3: "กรุณาเลือก",
    labelRedundancy4: "คุณได้รับแจ้งถึงความซ้ําซ้อนของคุณเมื่อใด",
    tooltipRedundancy4: "กรุณาเลือก",
    labelTestOption: "เลือกใช่เพื่อดำเนินการต่อ",
}