import * as React from 'react';
import {
    Box,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useEffect, useState} from 'react';
import {IDropDownItem} from '../Interfaces/IDropDownItem';
import {useTranslation} from 'react-i18next';
import {sessionKeys} from '../../state/sessionKeys';
import {ModalHolder} from './ModalHolder';
import {BorderRadiusInputField} from '../utils/constants-styling';

export interface LanguageSelectionModalProps {
    detectedLanguages: IDropDownItem[];
    onLanguageChange: (e: SelectChangeEvent) => void;
}

export const LanguageSelectionModal = (props: LanguageSelectionModalProps) => {

    const {
        detectedLanguages,
        onLanguageChange
    } = props;

    const {t} = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const closeModalAndCancel = (e: SelectChangeEvent) => {
        localStorage.setItem(sessionKeys.preferredlanguage, e.target.value);
        onLanguageChange(e);
        setShowModal(false);
    }

    useEffect(() => {
        setShowModal(detectedLanguages?.length > 1);
    }, [detectedLanguages]);

    const Content = () => {
        return (
            <>
                <Grid container alignItems='center'>
                    <Grid size={{xs: 5, md: 4}}>
                        <Box sx={{pr: 2}} justifyContent='flex-end' display={{sm: 'flex', md: 'flex'}}>
                            <h5>{`${t('common:selectPrefix')} ${t('common:labelLanguage').toLowerCase()}`}</h5>
                        </Box>
                    </Grid>
                    <Grid size={{xs: 7, md: 8}}>
                        <FormControl size='small' fullWidth>
                            <Select sx={{
                                borderRadius: BorderRadiusInputField,
                                minWidth: '200px'
                            }} onChange={closeModalAndCancel}>
                                <MenuItem>{t('common:labelLanguage')}</MenuItem>
                                {detectedLanguages?.map(x =>
                                    <MenuItem key={x.key} value={x.key}>{x.value}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <ModalHolder
            content={<Content/>}
            showModal={showModal}
            width='sm'
        />
    );
}