import * as React from 'react';
import {
    SelectChangeEvent,
    Box
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {IFormValues} from '../../Interfaces/IFormValues';
import {FormikProps} from 'formik';
import {OptionNo} from '../../utils/constants';
import {SelectInput} from '../../formInputs';
import {nameof} from 'ts-simple-nameof';
import {useFormContext} from '../../providers/FormProvider';
import {FormOffsetHolder} from '../../ui/FormOffsetHolder';

interface RefundReasonConfirmationSectionProps {
    declineWarning: string;
    heading: string;
    formProps: FormikProps<IFormValues>;
}

export const RefundReasonConfirmationSection = (props: RefundReasonConfirmationSectionProps): JSX.Element => {

    const {
        declineWarning,
        heading,
        formProps
    } = props;

    const {
        updateReasonDeclineStatusWithConfirmId
    } = useFormContext();

    const {t} = useTranslation();

    const reasonConfirmOnChange = (event: SelectChangeEvent) => {
        void updateReasonDeclineStatusWithConfirmId(+event.target.value, formProps.values.refundReasonCategory);
    }
    
    const OffsetHeadingContent = () => {
        return (
            <Box>{heading}</Box>
        );
    }

    const OffsetDeclineWarningContent = () => {
        return (
            <Box>{declineWarning}</Box>
        );
    }

    return (
        <>
            <FormOffsetHolder content={<OffsetHeadingContent/>}/>

            <SelectInput
                key={nameof<IFormValues>(x => x.refundReasonConfirm)}
                defaultValue={0}
                label={''}
                controlId={nameof<IFormValues>(x => x.refundReasonConfirm)}
                hidden={false}
                prefixKey={'form:optionRefundReasonConfirm'}
                datasourceUrl='/api/referencedata/yesnooptions'
                tooltip={t('bespokeQuestions:tooltipRefundReasonConfirm')}
                onChange={reasonConfirmOnChange}
                displayLabelAsTitle={false}
                required
            />

            {formProps.values.refundReasonConfirm === OptionNo &&
                <FormOffsetHolder content={<OffsetDeclineWarningContent/>}/>
            }
        </>
    );
}