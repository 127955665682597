export default {
    messageQuestionService: "Dịch vụ này chỉ được sử dụng nếu bạn gặp vấn đề với việc điền đơn đăng ký, chúng tôi không thể trả lời bất kỳ câu hỏi nào khác tại đây.",
    labelName: "Tên",
    labelReference: "Đặt chỗ",
    labelPhone: "Số điện thoại",
    labelEmail: "E-mail",
    labelQuery: "Câu hỏi của bạn",
    labelAskQuestion: "Gửi câu hỏi cho chúng tôi tại đây ...",
    buttonOpenModal: "Câu hỏi",
    title: "Đặt một câu hỏi",
    modalTitle: "4. Có vấn đề kỹ thuật với biểu mẫu?",
    toastSuccess: "Chúng tôi đã nhận được câu hỏi của bạn và chúng tôi sẽ liên hệ với bạn trong vòng 24 giờ",
    toastError: "Đã xảy ra lỗi với yêu cầu của bạn, vui lòng thử lại",
    nameValidation: "Tên là bắt buộc",
    referenceValidation: "Tham khảo là bắt buộc",
    questionValidation: "Câu hỏi của bạn là bắt buộc"
}