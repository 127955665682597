export default {
    dividerBankingDetails: "Détails bancaires",
    dividerBookingDetails: "Détails de la réservation",
    labelSavedApplicationReference: "Référence de l'application enregistrée:",
    labelLanguage: "Langue",
    messageWarningBookingHeading1: "Réservation annulée:",
    messageWarningBookingHeading2: "Réservation reportée / reprogrammée:",
    messageWarningBookingBody1: "Si votre réservation a été annulée par l'organisateur ou la compagnie aérienne, cette équipe ne pourra pas vous aider avec un remboursement. Vous devrez contacter directement votre équipe de service client; consultez votre confirmation de réservation ou votre site Web pour les coordonnées.",
    messageWarningBookingBody2: "Si votre réservation a été reportée, vos conditions de remboursement prolongées seront reportées à la nouvelle date. Si vous avez une raison valable de remboursement selon nos conditions, veuillez remplir le formulaire avec les justificatifs corrects.",
    messageWarningBookingBody3: "Veuillez noter : Ce service ne peut fournir des remboursements que pour les raisons incluses dans notre libellé, disponible dans votre e-mail de confirmation de réservation.",
    messageWarningBookingBody4: "Si vous ne pouvez pas effectuer la réservation pour des raisons extérieures à nos conditions, nous ne pourrons pas vous aider. Vous devrez contacter directement votre équipe de service client car ils pourront peut-être vous aider. consultez votre confirmation de réservation ou votre site Web pour obtenir les coordonnées.",
    linkWarningBooking: "ici",
    formSectionNavigatorStep1: "Étape 1",
    formSectionNavigatorStep2: "Étape 2",
    formSectionNavigatorStep3: "Étape 3",
    formSectionNavigatorStep1Text: "Détails de contact et de réservation",
    formSectionNavigatorStep2Text: "Raison du remboursement",
    formSectionNavigatorStep3Text: "Détails du paiement",
    messageBookingMatchedAndFound: "Si votre référence de réservation est associée à une candidature existante nous vous enverrons un email contenant un lien pour accéder à votre candidature",
    messageBookingMatchedAndFoundCompleted: "Votre demande de remboursement a déjà été approuvée! Si vous rencontrez des problèmes avec votre paiement, veuillez contacter:",
    applicationDeclinedMessage: "Votre demande a été refusée et est maintenant fermée.",
    messageBookingNotFound1: "Nous ne pouvons pas trouver de réservation avec cette référence. Veuillez vérifier votre e-mail de confirmation de réservation et ré-entrer.",
    messageBookingNotFound2: "Ne vous inquiétez pas, si votre référence de réservation ne fonctionne pas, vous pouvez sélectionner « Trouver une réservation » et saisir vos coordonnées. Lorsque vous remplissez le formulaire, veuillez télécharger une copie de votre e-mail de confirmation de réservation pour nous aider à localiser les détails de votre réservation.",
    messageLocatingBooking: "Localisation de votre réservation ...",
    messageLoadingBank: "Chargement des informations bancaires ...",
    messageFailCurrencyLookup: "Erreur lors de la récupération de la devise",
    messageSavingApplication: "Sauvegarde de votre application ...",
    messageSavedApplication: "Demande de remboursement enregistrée avec succès",
    messageFailSavedApplication: "La demande de remboursement n'a pas pu être enregistrée",
    messageSendingEmailReminder: "Traitement...",
    messageErrorLocationDetails: "Erreur lors de la récupération des détails de l'emplacement",
    messageFailFormSubmission: "La soumission du formulaire a échoué",
    messageApplicationCannotBeFound: "L'application n'a pas pu être trouvée",
    messageApplicationRetrievalError: "Erreur lors de la récupération de l'application",
    messageSubmittingApplication: "Soumission de la candidature ...",
    messageEventDateLimitError: "Motif de la notification en dehors du délai",
    messageAllTicketsAssigned: "Tous les billets ont été affectés à une autre demande de remboursement.",
    selectPrefix: "Sélectionner",
    selectPostfix: "",
    fileUpload: "cliquez ici pour télécharger",
    fileUploadAcceptedFilesMessage: "Nous n'acceptons que les fichiers PDF et les formats d'image .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 fichiers sélectionnés",
    fileUploadPreviouslyUploaded: "Précédemment téléchargée:",
    fileUploadSelectedUploaded: "Les sélectionnés ont déjà été téléchargés:",
    buttonNext: "Suivant",
    buttonPrevious: "Précédente",
    buttonSave: "Sauvegarder",
    buttonCancel: "Annuler",
    buttonSubmit: "Soumettre",
    buttonConfirm: "Confirmer",
    buttonYes: "Oui",
    buttonNo: "Non",
    buttonClose: "Fermer",
    linkPrivacyStatement: "Déclaration de confidentialité",
    linkComplaintsPolicy: "Politique de réclamation",
    validationRequired: "Obligatoire",
    validationInvalid: "Invalide",
    labelUploadPrefix: "Télécharger",
    labelUploadPostfix: "",
    labelChange: "Changement",
    labelView: "Vue",
    browserNavigationMessage: "Les modifications non enregistrées seront perdues"
};