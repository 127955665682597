export default {
    nonAutoApprovedLabelThankYou: "Благодарим Вас за Вашу Заявку на Возврат Средств.",
    nonAutoApprovedLabelReview: "Наша команда рассмотрит вашу заявку в течение следующих двух рабочих дней и свяжется с ответом.",
    nonAutoApprovedLabelExperience: "Как мы это сделали?",
    nonAutoApprovedLabelExperienceIntroduction: "Мы были бы очень признательны, если бы вы нашли время, чтобы оценить нас и оставить отзыв, поскольку мы ценим мнение наших клиентов.",
    feedbackExcellent: "Отлично",
    feedbackGreat: "Прекрасно",
    feedbackAverage: "Средний",
    feedbackPoor: "Бедный",
    feedbackBad: "Плохой",
    labelThankYouForFeedback: "Спасибо за ваш отзыв.",
    autoApprovedMessage1: "Мы рады",
    autoApprovedMessage2: "Одобрять",
    autoApprovedMessage3: "Ваш возврат!",
    autoApprovedPayment1Day: "Ваш платеж уже обработан и должен быть в вашем аккаунте всего за 1 рабочий день.",
    autoApprovedPayment2to4Days: "Ваш платеж уже обработан и должен быть на вашем счете всего через 2-4 рабочих дня.",
    autoApprovedPayment7to10Days: "Ваш платеж уже обработан и должен быть в вашем аккаунте всего через 7-10 рабочих дней.",
    autoApprovedLabelExperience: "Как мы это сделали?",
    autoApprovedLabelExperienceIntroduction: "Мы были бы очень признательны, если бы вы нашли время, чтобы оценить нас и оставить отзыв, поскольку мы ценим мнение наших клиентов.",
    modalLabelNegative: "Ваши отзывы действительно важны для нас, дайте нам знать, если вы также хотите, чтобы мы связались с вами.",
    placeholderFeedback: "Обратная связь",
    messageFeedbackSubmitted: "Отзыв отправлен",
    reviewInstructionMessage: "5 звезд - отлично, 4 звезды - это здорово, 3 звезды - это средне, 2 звезды - плохо, 1 звезда - плохо."
}