export default {
    labelBookingCancelledPostponedHeading: "Ваше бронювання було скасовано чи відкладено? Читати далі",
    labelBookingCancelledPostponedText1: "Натисніть",
    labelBookingCancelledPostponedText2: "для отримання додаткової інформації.",
    labelNewRefundHeading: "Нова заява на повернення коштів?",
    labelNewRefundText: "Введіть посилання на бронювання тут...",
    placeholderBookingReference: "Код бронювання",
    validationBookingReference: "Потрібна довідка про бронювання",
    validationEmailAsBookingReference: "Адреса електронної пошти не приймається як посилання на бронювання",
    buttonFindBooking: "Знайти Бронювання",
    labelEmailHeading: "Вже почали?",
    labelEmailText: "Надішліть нагадування електронною поштою, щоб отримати вашу заявку.",
    placeholderEmail: "Електронна пошта",
    buttonEmailReminder: "Нагадування електронною поштою",
    labelCustomerName : "Ім'я клієнта",
    validationCustomerName: "Укажіть ім’я клієнта",
    labelTypeOfBooking: "Тип бронювання",
    validationTypeOfBooking: "Тип бронювання обов'язковий",
    labelEventName:"Назва події",
    labelVenueName:"Назва місця проведення",
    labelFlightNumber:"Номер рейсу",
    labelOriginAirport: "Аеропорт відправлення",
    labelHotelName: "Назва житла",
    labelHotelLocation: "Розташування проживання",
    labelCurrency: "Валюта",
    validationCurrency: "Необхідна валюта",
    validationCountryCurrency: "Вибрана валюта має бути валютою вашої країни",
    validationOfflinePayment: "Ми не можемо обробити відшкодування для вибраної вами валюти та країни, виберіть альтернативні варіанти",
    labelBookingValue: "Вартість бронювання",
    validationBookingValue: "Необхідно вказати вартість бронювання",
    validationBookingValueAmount: "Має бути більше 0",
    labelDateOfPurchase: "Дата покупки",
    validationDateOfPurchase: "Необхідна дата покупки",
    labelEmailAddress: "Адреса електронної пошти",
    validationEmailAddress: "Необхідна адреса електронної пошти",
    validationEmailAddressFormat: "Неправильний формат електронної пошти",
    labelConfirmEmailAddress: "Підтвердьте адресу електронної пошти",
    validationConfirmEmailAddressFormat: "Неправильний формат електронної пошти",
    validationConfirmEmailAddress: "Потрібна електронна адреса підтвердження",
    validationConfirmEmailAddressMatch: "Адреси електронної пошти не збігаються",
    validationEmailDomain: "Цей провайдер електронної пошти не приймається, скористайтеся альтернативою",
    validationDateOfEvent: "Дата заходу обов’язкова",
    labelHomeCountry: "Заміський будинок",
    validationHomeCountry: "Укажіть країну проживання",
    labelContactNumberCountryCode: "Контактний номер Код країни",
    validationContactNumberCountryCode: "Необхідний код країни",
    labelContactNumber: "Контактний номер",
    validationContactNumber: "Необхідний контактний номер",
    labelAddressLine1: "Адресний рядок 1",
    labelAddressLine2: "Адресний рядок 2",
    labelCity: "Місто",
    labelZipCode: "ЗІП код",
    labelRegion: "Регіон",
    validationRegion: "Потрібний регіон",
    labelNumberOfTicketsToRefund: "Кількість квитків для повернення",
    validationNumberOfTicketsToRefund: "Необхідна кількість квитків для повернення",
    validationNumberOfTicketsToRefundValue: "Має бути більше 0",
    labelCostPerTicket: "Вартість кожного Квитка",
    validationCostPerTicket: "Необхідна вартість квитка",
    validationCostPerTicketValue: "Має бути більше 0",
    labelTotalRefundAmount: "Загальна сума відшкодування",
    validationTotalRefundAmount: "Необхідна загальна сума відшкодування",
    validationTotalRefundAmountValue: "Має бути більше 0",
    validationTotalRefundGreaterThanBookingValue: "Загальна сума відшкодування не може перевищувати наведену вище вартість бронювання",
    labelBookingConfirmationFiles: "Завантажте підтвердження бронювання",
    validationBookingConfirmationFiles: "Приєднайте принаймні один файл або натисніть Зберегти та повернути пізніше, коли у вас будуть документи",
    validationEvidenceFilesPopup: "Докази необхідно завантажити перед подачею. Якщо зараз у вас немає доказів, будь ласка, збережіть заяву та поверніться, коли у вас є. Ми приймаємо електронні копії, сканерні копії та фотографії.",
    validationBookingConfirmationFilesPopup: "Перед відправкою необхідно завантажити підтвердження бронювання. Якщо зараз у вас немає підтвердження бронювання, будь ласка, збережіть заявку та поверніться, як тільки отримаєте. Ми приймаємо електронні копії, сканерні копії та фотографії.",
    alertUploadDocuments: "Завантажте відповідну документацію, описану в електронному листі, а потім перейдіть до кроку 3.",
    labelRefundReasonCategory: "Причина повернення коштів",
    validationRefundReasonCategory: "Потрібна причина повернення коштів",
    labelDateOfIncident: "Дата інциденту",
    labelAdditionalComments: "Додаткові коментарі",
    tooltipAdditionalComments: "Додаткові коментарі",
    validationAdditionalComments: "Потрібні додаткові коментарі",
    labelUnableToNotifyWithinTimeLimit: "Неможливо повідомити протягом 60 днів з",
    labelReasonForNotificationOutsideTimeLimit: "Сповіщення поза межами часу Пояснення",
    validationReasonForNotificationOutsideTimeLimit: "Необхідно вказати причину повідомлення через 60 днів",
    labelRefundReasonFiles: "Завантажити докази",
    validationRefundReasonFiles: "Приєднайте принаймні один файл або натисніть Зберегти та повернути пізніше, коли у вас будуть документи",
    validationTextScript: "Повинен відповідати латинському шрифту",
    optionTypeOfBooking1: "Подія",
    optionTypeOfBooking2: "Тур",
    optionTypeOfBooking3: "Політ",
    optionTypeOfBooking4: "Проживання",
    optionTypeOfBooking5: "Передача",
    optionTypeOfBooking6: "Паркування",
    optionTypeOfBooking7: "Інші подорожі",
    labelEventTypeDate: "Дата події",
    labelEventTypeTour: "Дата туру",
    labelEventTypeFlight: "Дата польоту",
    labelEventTypeCheckIn: "Дата заїзду",
    labelEventTypeTransfer: "Дата передачі",
    labelEventTypeParking: "Дата паркування",
    labelEventTypeOtherTravel: "Дата подорожі",
    optionRefundReason11: "Несприятлива погода",
    optionRefundReason10: "Збройні сили та служби з надзвичайних ситуацій",
    optionRefundReason15: "Зміни дат іспитів",
    optionRefundReason18: "Судова повістка",
    optionRefundReason17: "Обмеження на поїздки через COVID-19",
    optionRefundReason19: "Позитивний тест на COVID-19",
    optionRefundReason20: "Бронювання скасовано оператором/організатором",
    optionRefundReason21: "Бронювання перенесено оператором/організатором",
    optionRefundReason22: "Заброньовано неправильну дату",
    optionRefundReason23: "Забронювали неправильні квитки",
    optionRefundReason24: "Забронювали забагато квитків",
    optionRefundReason4: "Смерть",
    optionRefundReason16: "Надзвичайні обставини",
    optionRefundReason14: "Урядова заборона на поїздки",
    optionRefundReason9: "Домашня екстрена допомога",
    optionRefundReason1: "Хвороба/Травма/Нещасний випадок",
    optionRefundReason8: "Служба журі",
    optionRefundReason7: "Механічна поломка",
    optionRefundReason2: "Попередній медичний стан",
    optionRefundReason3: "Ускладнення вагітності",
    optionRefundReason5: "Збій громадського транспорту",
    optionRefundReason6: "",
    optionRefundReason13: "Крадіжка квитків",
    optionRefundReason12: "Переїзд на роботу",
    optionRefundReason25: "Звільнення на робочому місці",
    optionRefundReason26: "Тестовий варіант",
    optionRefundReasonConfirm1: "Ні",
    optionRefundReasonConfirm2: "Так",
    optionRefundReasonArmedForces1: "Армія",
    optionRefundReasonArmedForces2: "Флот",
    optionRefundReasonArmedForces3: "Впс",
    optionRefundReasonArmedForces4: "Спецназівці",
    optionRefundReasonArmedForces5: "Морські піхотинці",
    optionRefundReasonArmedForces6: "Полум’я",
    optionRefundReasonArmedForces7: "Швидка допомога",
    optionRefundReasonArmedForces8: "Лікар/ медсестра",
    optionRefundReasonArmedForces9: "Стоматолог",
    optionRefundReasonArmedForces10: "Інший",
    optionRefundReasonCourtSummons1: "Кримінальне переслідування",
    optionRefundReasonCourtSummons2: "Цивільно-правова справа",
    optionRefundReasonCourtSummons3: "Свідок у прокуратурі",
    optionRefundReasonCourtSummons4: "Свідок у цивільній справі",
    optionRefundReasonCourtSummons5: "Позивач",
    optionRefundReasonCourtSummons6: "Відповідач",
    optionRefundReasonCourtSummons7: "Інший",
    optionRefundReasonDeath1: "Чоловік",
    optionRefundReasonDeath2: "Дружина",
    optionRefundReasonDeath3: "Звук",
    optionRefundReasonDeath4: "Дочка",
    optionRefundReasonDeath5: "Батько",
    optionRefundReasonDeath6: "Мати",
    optionRefundReasonDeath7: "Брат",
    optionRefundReasonDeath8: "Сестра",
    optionRefundReasonDeath9: "Дід",
    optionRefundReasonDeath10: "Бабуся",
    optionRefundReasonDeath11: "Інший",
    optionRefundReasonHomeEmergency1: "Полум’я",
    optionRefundReasonHomeEmergency2: "Повінь",
    optionRefundReasonHomeEmergency3: "Пограбування",
    optionRefundReasonHomeEmergency4: "Вандалізму",
    optionRefundReasonHomeEmergency5: "Домашнє насильство",
    optionRefundReasonHomeEmergency6: "Викрасти",
    optionRefundReasonHomeEmergency7: "Інший",
    optionRefundReasonCOVID19Person1: "Себе",
    optionRefundReasonCOVID19Person2: "Ще одна особа у бронюванні",
    optionRefundReasonCOVID19Person3: "Людина в моїй родині",
    optionRefundReasonCOVID19Person4: "Інший",
    optionRefundReasonCOVID19TestType1: "ПЛР-тест",
    optionRefundReasonCOVID19TestType2: "Тест на RAT",
    optionRefundReasonCOVID19TestType3: "Тест бічного потоку",
    optionRefundReasonCOVID19TestType4: "Тест на самостійне введення",
    optionRefundReasonCOVID19TestType5: "Немає тесту, але у мене є симптоми",
    optionRefundReasonCOVID19TestType6: "Інший",
    optionRefundReasonBreakdown1: "Розбивка",
    optionRefundReasonBreakdown2: "Аварія",
    optionRefundReasonBreakdown3: "Полум’я",
    optionRefundReasonBreakdown4: "Крадіжки",
    optionRefundReasonPublicTransportType1: "Поїзд",
    optionRefundReasonPublicTransportType2: "Автобус",
    optionRefundReasonPublicTransportType3: "Трамвай",
    optionRefundReasonPublicTransportType4: "Човен",
    optionRefundReasonDocumentType1: "Паспорт",
    optionRefundReasonDocumentType2: "Дозвіл на поїздку",
    optionRefundReasonDocumentType3: "Національний ідентифікатор",
    optionRefundReasonDocumentType4: "Квитки",
    optionRefundReasonWorkRelocationType1: "Тимчасовий",
    optionRefundReasonWorkRelocationType2: "Постійний",
    optionRefundReasonWorkRelocationTemporary1: "0-3 місяці",
    optionRefundReasonWorkRelocationTemporary2: "3-6 місяців",
    optionRefundReasonWorkRelocationTemporary3: "Більше 6 місяців",
    optionRefundReasonBookingRelationshipType1: "Хтось у бронюванні",
    optionRefundReasonBookingRelationshipType2: "Безпосередній родич когось із учасників бронювання",
    optionEmploymentType1: "Повний робочий день",
    optionEmploymentType2: "Неповний робочий день",
    optionEmploymentType3: "Контракт",
    optionEmploymentLengthType1: "0-1 рік",
    optionEmploymentLengthType2: "1-2 роки",
    optionEmploymentLengthType3: "Більше 2 років",
    textRefundEvidenceHeading: "Будь ласка, надайте такі докази:",
    textRefundEvidence1: "Підтвердження авіакомпанії",
    textRefundEvidence2: "Будь-які відповідні підтверджуючі докази",
    textRefundEvidence3: "Підтвердження виклику служби поломки",
    textRefundEvidence4: "Свідоцтво про смерть",
    textRefundEvidence5: "Підтвердження скасування від авіакомпанії",
    textRefundEvidence6: "Звіт про погоду",
    textRefundEvidence7: "Листування від агента з бронювання, що підтверджує відсутність можливості переоформити квитки",
    textRefundEvidence8: "Лист від суду",
    textRefundEvidence9: "Медична довідка / Записка лікаря",
    textRefundEvidenceDescription9: "Підтверджуючи дані про хворобу або травму, дату її першого виникнення, що вона не дозволяє Вам відвідати Бронювання.",
    textRefundEvidence10: "Підтвердження листа роботодавця",
    textRefundEvidence11: "Листування страхового позову",
    textRefundEvidence12: "Лист від роботодавця",
    textRefundEvidence13: "Підтвердження веб-сайту Національного уряду",
    textRefundEvidence14: "Повідомлення про зміну іспиту",
    textRefundEvidence15: "Звіт поліції",
    textRefundEvidence16: "Звіт поліції та довідковий номер",
    textRefundEvidence17: "Підтвердження постачальника транспортних послуг",
    textRefundEvidence18: "Попередження про поїздку",
    textRefundEvidence20: "Сертифікат тесту на COVID-19",
    textRefundEvidence24: "Повідомлення про ізоляцію уряду",
    textRefundEvidence25: "Лист від командира",
    textRefundEvidence26: "Лист від страховиків",
    textRefundEvidence27: "Підтвердження адреси",
    textRefundEvidence28: "Доказ зв'язку з померлим",
    textRefundEvidence29: "Доказ зв'язку з потерпілим/хворою людиною",
    textRefundEvidence30: "Платіжна відомість",
    tooltipBookingReference: "Щоб визначити номер свого бронювання, перевірте підтвердження бронювання та перегляньте розділ про наші розширені послуги.",
    tooltipCustomerName: "Ім’я та прізвище, які були використані для бронювання.",
    tooltipTypeOfBooking: "Тип бронювання, який ви бажаєте повернути.",
    tooltipEventName: "Назва події.",
    tooltipVenueName: "Назва місця проведення.",
    tooltipFlightNumber: "Номер рейсу.",
    tooltipOriginAirport: "Аеропорт вильоту.",
    tooltipHotelName: "Назва житла.",
    tooltipHotelLocation: "Розташування житла.",
    tooltipCurrency: "Валюта, використана для оплати бронювання.",
    tooltipBookingValue: "Загальна ціна, яку ви заплатили за бронювання.",
    tooltipDateOfPurchase: "Дата, коли було зроблено бронювання.",
    tooltipEmailAddress: "Будь ласка, використовуйте дійсну адресу електронної пошти. Ми використовуємо цю електронну пошту для листування під час подання заявки на відшкодування.",
    tooltipConfirmEmailAddress: "Використовуйте ту саму адресу електронної пошти, що й вище, це для перевірки.",
    tooltipHomeCountry: "Це допоможе нам отримати правильну банківську інформацію на останньому кроці.",
    tooltipContactNumberCountryCode: "Контактний номер код країни.",
    tooltipContactNumber: "Будь ласка, використовуйте дійсний номер. Ми використовуємо це для листування під час подання заявки на повернення коштів.",
    tooltipAddressLine1: "Зверніть увагу, що ваша адреса буде використовуватися під час підтвердження вашого банківського рахунку.",
    tooltipAddressLine2: "Другий рядок адреси.",
    tooltipCity: "Ваше рідне місто, реквізити можна використовувати для підтвердження банківських реквізитів.",
    tooltipZipCode: "Поштовий індекс.",
    tooltipRegion: "Регіон вашого рідного міста, реквізити можна використовувати для підтвердження банківських реквізитів.",
    tooltipNumberOfTicketsToRefund: "Кількість квитків, які ви хочете повернути.",
    tooltipTotalRefundAmount: "Це не може перевищувати загальну вартість бронювання, якщо так буде, ви не зможете подати заявку.",
    tooltipBookingConfirmationFiles: "Знімок екрана/додавання вашого повного підтвердження бронювання, яке зазвичай можна знайти у ваших електронних листах.",
    tooltipRefundReasonCategory: "Виберіть причину повернення коштів.",
    tooltipDateOfIncident: "Дата, на яку стався інцидент, який вплинув на ваше бронювання.",
    tooltipRefundExplanation: "Будь ласка, надайте якомога більше деталей, які підтверджують вимогу про відшкодування.",
    tooltipUnableToNotifyWithinTimeLimit: "Неможливо повідомити протягом 60 днів.",
    tooltipReasonForNotificationOutsideTimeLimit: "Сповіщення поза межами часу Пояснення.",
    tooltipRefundReasonFiles: "Будь ласка, завантажте будь-який із необхідних документів.",
    tooltipEventTypeDate: "Дата фактичного заходу / бронювання.",
    tooltipEventTypeTour: "Дата фактичного туру.",
    tooltipEventTypeFlight: "Дата фактичного рейсу.",
    tooltipEventTypeCheckIn: "Дата фактичної реєстрації.",
    tooltipEventTypeTransfer: "Дата фактичного переказу.",
    tooltipEventTypeParking: "Дата фактичного паркування.",
    tooltipEventTypeOtherTravel: "Дата початку фактичної подорожі."
};