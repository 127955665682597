export default {
    nonAutoApprovedLabelThankYou: "תודה על בקשת ההחזר הכספי שלך.",
    nonAutoApprovedLabelReview: "הצוות שלנו יבחן את הבקשה שלך במהלך שני ימי העסקים הקרובים ויחזור אליך עם תגובה.",
    nonAutoApprovedLabelExperience: "איך עשינו?",
    nonAutoApprovedLabelExperienceIntroduction: "נעריך מאוד שתקדישו את הזמן לדרג אותנו ולהשאיר ביקורת מכיוון שאנו מעריכים את דעתם של לקוחותינו.",
    feedbackExcellent: "מצוין",
    feedbackGreat: "נהדר",
    feedbackAverage: "ממוצע",
    feedbackPoor: "עני",
    feedbackBad: "רע",
    labelThankYouForFeedback: "תודה על המשוב שלך.",
    autoApprovedMessage1: "אנו שמחים",
    autoApprovedMessage2: "לאשר",
    autoApprovedMessage3: "ההחזר הכספי שלך!",
    autoApprovedPayment1Day: "התשלום שלך כבר עובד ואמור להיות בחשבונך תוך יום עסקים אחד בלבד.",
    autoApprovedPayment2to4Days: "התשלום שלך כבר עובד ואמור להיות בחשבון שלך תוך 2-4 ימי עסקים בלבד.",
    autoApprovedPayment7to10Days: "התשלום שלך כבר עובד ואמור להיות בחשבונך תוך 7-10 ימי עסקים בלבד.",
    autoApprovedLabelExperience: "איך עשינו?",
    autoApprovedLabelExperienceIntroduction: "נעריך מאוד שתקדישו את הזמן לדרג אותנו ולהשאיר ביקורת מכיוון שאנו מעריכים את דעתם של לקוחותינו.",
    modalLabelNegative: "המשוב שלך ממש חשוב לנו, תודיע לנו אם אתה רוצה שגם אנחנו ניצור איתך קשר.",
    placeholderFeedback: "משוב",
    messageFeedbackSubmitted: "המשוב נשלח",
    reviewInstructionMessage: "5 כוכבים זה מצוין, 4 כוכבים זה נהדר, 3 כוכבים זה ממוצע, 2 כוכבים זה גרוע, כוכב אחד זה רע."
}