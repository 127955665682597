import * as React from 'react';
import {
    TextField,
    InputAdornment
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps
} from 'formik';
import {
    BorderRadiusInputField,
    MinWidthInputField
} from '../utils/constants-styling';
import {IInputProps} from './IInputProps';
import {isInvalid} from '../utils/validationHelpers';
import {IFormValues} from '../Interfaces/IFormValues';
import {useState} from 'react';
import {ErrorMessage} from '../ui/ErrorMessage';
import {getCurrencySymbol} from '../utils/helpers';
import {FormLabelHolder} from '../ui/FormLabelHolder';

interface CurrencyInputProps extends IInputProps {
    currencyCode: string;
    placeholder: string;
}

export const CurrencyInput = (props: CurrencyInputProps) => {

    const {
        controlId,
        currencyCode,
        disabled,
        hidden,
        label,
        onBlur,
        placeholder,
        required,
        tooltip
    } = props;

    const [currencyInputValue, setCurrencyInputValue] = useState(null);

    const handleOnBlur = (fieldProps: FieldProps<IFormValues>) => (event: React.FocusEvent<HTMLInputElement>) => {
        void fieldProps.form.setFieldValue(controlId, event.target.value);
        void fieldProps.form.setTouched({...fieldProps.form.touched, [controlId]: true}, false);

        if (onBlur && typeof (onBlur) === 'function') {
            onBlur(event);
        }
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');
            if (decimal?.length > 2) {
                return;
            }
        }

        setCurrencyInputValue(event.target.value);
    }

    const currencyDisplay = currencyCode?.length === 3 && `${getCurrencySymbol(currencyCode)} (${currencyCode})`;

    return (
        <>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues>) => (
                        <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
                            <FormLabelHolder
                                label={label}
                                required={required}
                                tooltip={tooltip}
                            />
                            <Grid container size={{xs: 6, md: 5}}>
                                <TextField
                                    name={controlId}
                                    variant='outlined'
                                    type='number'
                                    size='small'
                                    placeholder={placeholder}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur(fieldProps)}
                                    disabled={disabled}
                                    value={currencyInputValue ?? fieldProps.form.getFieldProps(controlId).value}
                                    error={isInvalid(fieldProps)}
                                    fullWidth={true}
                                    sx={{
                                        'fieldset': {
                                            borderRadius: BorderRadiusInputField,
                                            minWidth: MinWidthInputField
                                        }
                                    }}
                                    slotProps={{
                                        input: {
                                            startAdornment: <InputAdornment
                                                position='start'>{currencyDisplay}</InputAdornment>
                                        },
                                    }}
                                />
                                <ErrorMessage fieldProps={fieldProps}/>
                            </Grid>
                        </Grid>
                    )}
                </Field>
            }
        </>
    );
}