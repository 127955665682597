export default {
    headingAdverseWeather: "U ervaart ernstige ongunstige weersomstandigheden die het onveilig maken om naar het evenement te reizen en dit is verplicht door een overheidsinstantie (bijv. Lokale autoriteit, politie, enz.). En je hebt bewijs om dit te bewijzen?",
    headingAdverseWeatherDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    tooltipRefundReasonConfirm: "Ja of nee",
    labelBookingCancelledByOperator: "De tickets werden geannuleerd door de Operator/Organisator.",
    labelBookingRescheduledByOperator: "De datum is gewijzigd door de Operator/Organisator en ik kan de nieuwe data niet bijwonen.",
    labelBookedWrongDate: "Ik of een lid van de boeking, heb een fout gemaakt bij het kopen van de tickets en de verkeerde datum geboekt.",
    labelBookedWrongTickets: "Ik of een lid van de boeking, heb een fout gemaakt bij het kopen van de tickets en de verkeerde tickets geboekt.",
    labelBookedTooManyTickets: "Ik of een lid van de boeking, heb een fout gemaakt bij het kopen van de tickets en te veel tickets geboekt.",
    labelCOVID19TravelRestrictions: "Ik of een lid van de boeking, kan niet aanwezig zijn vanwege een COVID-19 reisverbod.",
    labelADWrongSelection: "Mogelijk hebt u de verkeerde reden voor een terugbetaling geselecteerd. Bekijk de vervolgkeuzelijst Reden voor terugbetaling opnieuw.",
    labelAdverseWeather1: "Waarom betekende slecht weer dat je niet in staat was om het evenement bij te wonen?",
    tooltipAdverseWeather1: "Gelieve details",
    labelAdverseWeather2: "Datum van het incident",
    tooltipAdverseWeather2: "Maak een keuze",
    headingArmedForces: "U wordt door uw werkgever gevraagd welke strijdkrachten of hulpdiensten zijn om op het werk te komen wanneer u oorspronkelijk niet was gepland om dit te doen en dit voorkomt dat u het evenement bijwoont?",
    headingArmedForcesDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelArmedForces1: "Bij welke korpsen of Hulpdiensten ben je in dienst?",
    tooltipArmedForces1: "Maak een keuze",
    labelArmedForcesOther1: "Gelieve te specificeren",
    tooltipArmedForcesOther1: "Gelieve te specificeren",
    labelArmedForces2: "Datum van het incident",
    tooltipArmedForces2: "Maak een keuze",
    headingChangesToExam: "De data van je examen zijn gewijzigd door je school/hogeschool, etc? Is dit geen mislukt examen dat je moet herkansen?",
    headingChangesToExamDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelExam1: "Wat was de oorspronkelijke examendatum?",
    tooltipExam1: "Maak een keuze",
    labelExam2: "Wat is de nieuwe examendatum?",
    tooltipExam2: "Maak een keuze",
    labelExam3: "Waarom is de examendatum veranderd?",
    tooltipExam3: "Voer de gegevens in",
    labelExam4: "Ben je gezakt voor het vorige examen?",
    tooltipExam4: "Ja of nee",
    headingCourtSummons: "U bent opgeroepen om als getuige voor de rechtbank te verschijnen op dezelfde data als de gebeurtenis?",
    headingCourtSummonsDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelCourtSummons1: "Wat is de reden voor uw dagvaarding?",
    tooltipCourtSummons1: "Maak een keuze",
    labelCourtSummonsOther1: "Gelieve te specificeren",
    tooltipCourtSummonsOther1: "Gelieve te specificeren",
    labelCourtSummons2: "Wanneer is de dagvaarding van de rechtbank uitgebracht?",
    tooltipCourtSummons2: "Maak een keuze",
    headingCOVID19Test: "Ik, een lid van mijn huishouden of een lid van de groep die het evenement bijwoont, ben gediagnosticeerd met COVID-19?",
    headingCOVID19TestDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelCOVID19Test1: "Wie heeft COVID-19?",
    tooltipCOVID19Test1: "Maak een keuze",
    labelCOVID19TestOther1a: "Gelieve te specificeren",
    labelCOVID19TestOther1b: "Naam opgeven",
    tooltipCOVID19TestOther1a: "Gelieve te specificeren",
    tooltipCOVID19TestOther1b: "Naam opgeven",
    labelCOVID19Test2: "Wat voor soort COVID-19-test heb je gehad?",
    tooltipCOVID19Test2: "Maak een keuze",
    labelCOVID19TestOther2: "Gelieve te specificeren",
    tooltipCOVID19TestOther2: "Gelieve te specificeren",
    labelCOVID19Test3: "Wat was de datum van uw COVID-19-test?",
    tooltipCOVID19Test3: "Maak een keuze",
    headingDeath: "Er is een direct lid van mijn familie overleden en het verhindert mij om het evenement bij te wonen?",
    headingDeathDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelDeath1: "Welk familielid is overleden?",
    tooltipDeath1: "Maak een keuze",
    labelDeathOther1: "Gelieve te specificeren",
    tooltipDeathOther1: "Gelieve te specificeren",
    labelDeath2: "Wanneer was de sterfdatum?",
    tooltipDeath2: "Maak een keuze",
    labelDeath3: "Naam van de overledene",
    tooltipDeath3: "Gelieve te specificeren",
    headingEmergency: "Ik heb een echte noodsituatie gehad die buiten mijn controle ligt en onvoorzien is en waarvoor ik bewijs kan leveren?",
    headingEmergencyDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelEmergency1: "Kunt u uitleggen wat voor soort noodgeval er is?",
    tooltipEmergency1: "Gelieve details",
    labelEmergency2: "Wanneer is dit gebeurd?",
    tooltipEmergency2: "Maak een keuze",
    labelEmergency3: "Waarom houdt deze situatie je tegen om het evenement bij te wonen?",
    tooltipEmergency3: "Gelieve details",
    labelEmergency4: "Heeft u bewijs van deze noodsituatie?",
    tooltipEmergency4: "Ja of nee",
    headingHomeEmergency: "Ik heb een thuisnoodgeval dat is diefstal, overstroming, brand, vandalisme, huiselijk geweld waardoor ik het evenement niet kan bijwonen?",
    headingHomeEmergencyDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelHomeEmergency1: "Wat is de noodsituatie thuis?",
    tooltipHomeEmergency1: "Maak een keuze",
    labelHomeEmergencyOther1: "Gelieve te specificeren",
    tooltipHomeEmergencyOther1: "Gelieve te specificeren",
    labelHomeEmergency2: "Wanneer is dit gebeurd?",
    tooltipHomeEmergency2: "Maak een keuze",
    labelHomeEmergency3: "Waarom heb je er geen deel meer aan gedaan?",
    tooltipHomeEmergency3: "Gelieve details",
    headingInjury: "Ik, een lid van mijn huishouden of een lid van de groep die het evenement bijwoont, ben gediagnosticeerd met een medische aandoening of verwonding waardoor we nu niet naar het evenement kunnen gaan?",
    headingInjuryDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelInjury1: "Wat is de aard van de ziekte/verwonding/ongeval?",
    tooltipInjury1: "Gelieve details",
    labelInjury2: "Wanneer is dit gebeurd?",
    tooltipInjury2: "Maak een keuze",
    labelInjury3: "Bent u voor deze ziekte/letsel naar een medische instelling geweest?",
    tooltipInjury3: "Ja of nee",
    labelInjury4: "Was dit een reeds bestaande medische aandoening?",
    tooltipInjury4: "Ja of nee",
    labelInjury5: "Wanneer werd dit voor het eerst gediagnosticeerd?",
    tooltipInjury5: "Maak een keuze",
    labelInjury6: "Waarom betekent dit dat je het evenement niet kunt bijwonen?",
    tooltipInjury6: "Gelieve details",
    labelInjury7: "Wie heeft de ziekte/het letsel opgelopen?",
    tooltipInjury7: "Maak een keuze",
    headingJuryService: "Ik of een lid van de boeking is opgeroepen voor juryservice?",
    headingJuryServiceDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelJuryService1: "Wanneer bent u op de hoogte gebracht van jurydienst?",
    tooltipJuryService1: "Maak een keuze",
    headingMechanicalBreakdown: "Ons voertuig heeft een mechanisch defect gehad of een ongeluk gehad en dit voorkomt dat we het evenement bijwonen?",
    headingMechanicalBreakdownDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelMechanicalBreakdown1: "Wat is er met het voertuig gebeurd?",
    tooltipMechanicalBreakdown1: "Maak een keuze",
    labelMechanicalBreakdown2: "Wanneer is dit gebeurd?",
    tooltipMechanicalBreakdown2: "Maak een keuze",
    labelMechanicalBreakdown3: "Waarom was u niet in staat om alternatief vervoer te nemen?",
    tooltipMechanicalBreakdown3: "Gelieve details",
    headingPregnancyComplication: "Ik of een lid van de boeking heeft zwangerschapscomplicaties die zijn gediagnosticeerd door een medische professional en daarom verhinderen dat ik het evenement bijwoon?",
    headingPregnancyComplicationDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelPregnancyComplication1: "Wat is de aard van de zwangerschapscomplicatie?",
    tooltipPregnancyComplication1: "Gelieve details",
    labelPregnancyComplication2: "Wanneer is dit gebeurd?",
    tooltipPregnancyComplication2: "Maak een keuze",
    labelPregnancyComplication3: "Heb je medische hulp gezocht?",
    tooltipPregnancyComplication3: "Ja of nee",
    labelPregnancyComplication4: "Waarom betekent dit dat je het evenement niet kunt bijwonen?",
    tooltipPregnancyComplication4: "Gelieve details",
    headingPublicTransportFailure: "De methode van het openbaar vervoer (trein, bus, tram, boot, enz.) faalde onverwacht, wat betekende dat er geen andere manier was waarop ik naar dit evenement kon reizen?",
    headingPublicTransportFailureDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelPublicTransportFailure1: "Welk type openbaar vervoer was je van plan te gebruiken?",
    tooltipPublicTransportFailure1: "Maak een keuze",
    labelPublicTransportFailure2: "Wanneer was je van plan om te reizen?",
    tooltipPublicTransportFailure2: "Maak een keuze",
    labelPublicTransportFailure3: "Wat is er gebeurd?",
    tooltipPublicTransportFailure3: "Gelieve details",
    labelPublicTransportFailure4: "Waarom zou je geen alternatief vervoer kunnen nemen?",
    tooltipPublicTransportFailure4: "Gelieve details",
    headingScheduledAirlineFailure: "Mijn vooraf geboekte vlucht is onverwacht geannuleerd en ik heb geen geschikt alternatief kunnen vinden, zodat ik het evenement niet kon bijwonen?",
    headingScheduledAirlineFailureDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelScheduledAirlineFailure1: "Naam van de luchtvaartmaatschappij",
    tooltipScheduledAirlineFailure1: "Naam van de luchtvaartmaatschappij",
    labelScheduledAirlineFailure2: "Vluchtnummer",
    tooltipScheduledAirlineFailure2: "Vluchtnummer",
    labelScheduledAirlineFailure3: "Reizen vanaf",
    tooltipScheduledAirlineFailure3: "Reizen vanaf",
    labelScheduledAirlineFailure4: "Reizen naar",
    tooltipScheduledAirlineFailure4: "Reizen naar",
    labelScheduledAirlineFailure5: "Datum van incident?",
    tooltipScheduledAirlineFailure5: "Maak een keuze",
    labelScheduledAirlineFailure6: "Waarom kon je geen alternatieve vlucht nemen?",
    tooltipScheduledAirlineFailure6: "Gelieve details",
    headingTheftOfDocuments: "Mijn documenten zijn gestolen en het was niet mogelijk om vervanging(en) te regelen binnen het tijdsbestek dat nodig was, zodat ik het evenement niet kan bijwonen",
    headingTheftOfDocumentsDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelTheftOfDocuments1: "Welk type document is gestolen?",
    tooltipTheftOfDocuments1: "Maak een keuze",
    labelTheftOfDocuments2: "Wanneer is dit gebeurd?",
    tooltipTheftOfDocuments2: "Maak een keuze",
    labelTheftOfDocuments3: "Hebt u geprobeerd de documenten opnieuw te laten uitgeven?",
    tooltipTheftOfDocuments3: "Ja of nee",
    headingWorkRelocation: "Mijn werkgever heeft me gevraagd om de locatie van mijn werk voor ten minste drie maanden te wijzigen en de afstand is aanzienlijk, wat betekent dat ik nu niet in staat ben om het evenement bij te wonen?",
    headingWorkRelocationDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk wordt geweigerd.",
    labelWorkRelocation1: "Is dit een tijdelijke of permanente verhuizing van uw werk?",
    tooltipWorkRelocation1: "Maak een keuze",
    labelWorkRelocation2: "Hoe lang ben je op het nieuwe adres?",
    tooltipWorkRelocation2: "Gelieve details",
    labelWorkRelocation3: "Wat is je huidige werkadres?",
    tooltipWorkRelocation3: "Gelieve details",
    labelWorkRelocation4: "Wat is je nieuwe werkadres?",
    tooltipWorkRelocation4: "Gelieve details",
    labelWorkRelocation5: "Wanneer bent u op de hoogte gebracht van deze verhuizing?",
    tooltipWorkRelocation5: "Maak een keuze",
    headingRedundancy: "Ik of een lid van de boeking is ontslagen door hun werkgever, waardoor ik de boeking niet meer kan bijwonen?",
    headingRedundancyDecline: "U moet niet doorgaan met deze aanvraag, omdat de terugbetaling hoogstwaarschijnlijk zal worden geweigerd.",
    labelRedundancy1: "Type dienstverband?",
    tooltipRedundancy1: "Maak een keuze",
    labelRedundancy2: "Hoe lang ben je al in dienst bij dit bedrijf?",
    tooltipRedundancy2: "Maak een keuze",
    labelRedundancy3: "Was het ontslag vrijwillig?",
    tooltipRedundancy3: "Maak een keuze",
    labelRedundancy4: "Wanneer werd u op de hoogte gebracht van uw ontslag?",
    tooltipRedundancy4: "Maak een keuze",
    labelTestOption: "Selecteer Ja om door te gaan",
}