export default {
    nonAutoApprovedLabelThankYou: "Takk for din refusjonssøknad.",
    nonAutoApprovedLabelReview: "Teamet vårt vil gjennomgå søknaden din innen de neste to virkedagene og komme tilbake til deg med et svar.",
    nonAutoApprovedLabelExperience: "Hvordan gikk det?",
    nonAutoApprovedLabelExperienceIntroduction: "Vi vil virkelig sette pris på at du tar deg tid til å rangere oss og legge igjen en anmeldelse som vi verdsetter våre kunders mening.",
    feedbackExcellent: "Fortreffelig",
    feedbackGreat: "Utmerket",
    feedbackAverage: "Gjennomsnitt",
    feedbackPoor: "Fattig",
    feedbackBad: "Dårlig",
    labelThankYouForFeedback: "Takk for tilbakemeldingen.",
    autoApprovedMessage1: "Vi er glade for å",
    autoApprovedMessage2: "Godkjenne",
    autoApprovedMessage3: "din refusjon!",
    autoApprovedPayment1Day: "Betalingen din er allerede behandlet og skal være på kontoen din på bare 1 virkedag.",
    autoApprovedPayment2to4Days: "Betalingen din er allerede behandlet og skal være på kontoen din på bare 2-4 virkedager.",
    autoApprovedPayment7to10Days: "Betalingen din er allerede behandlet og skal være på kontoen din på bare 7-10 virkedager.",
    autoApprovedLabelExperience: "Hvordan gikk det?",
    autoApprovedLabelExperienceIntroduction: "Vi vil virkelig sette pris på at du tar deg tid til å rangere oss og legge igjen en anmeldelse som vi verdsetter våre kunders mening.",
    modalLabelNegative: "Din tilbakemelding er veldig viktig for oss, gi oss beskjed hvis du også vil at vi skal komme i kontakt med deg.",
    placeholderFeedback: "Tilbakemelding",
    messageFeedbackSubmitted: "Innsendt tilbakemelding",
    reviewInstructionMessage: "5 stjerner er utmerket, 4 stjerner er flott, 3 stjerner er gjennomsnittlig, 2 stjerner er dårlig, 1 stjerne er dårlig."
}