export default {
    labelBookingCancelledPostponedHeading: "क्या आपकी बुकिंग रद्द या स्थगित कर दी गई है? और पढ़ें",
    labelBookingCancelledPostponedText1: "क्लिक",
    labelBookingCancelledPostponedText2: "अधिक जानकारी के लिए।",
    labelNewRefundHeading: "नया धनवापसी आवेदन?",
    labelNewRefundText: "यहां अपना बुकिंग संदर्भ दर्ज करें...",
    placeholderBookingReference: "आरक्षण संदर्भ",
    validationBookingReference: "बुकिंग संदर्भ आवश्यक है",
    validationEmailAsBookingReference: "ईमेल पते को बुकिंग संदर्भ के रूप में स्वीकार नहीं किया जाता है",
    buttonFindBooking: "बुकिंग खोजें",
    labelEmailHeading: "पहले ही शुरू कर दिया है?",
    labelEmailText: "अपना आवेदन पुनः प्राप्त करने के लिए एक ईमेल अनुस्मारक भेजें।",
    placeholderEmail: "ईमेल",
    buttonEmailReminder: "ईमेल अनुस्मारक",
    labelCustomerName : "ग्राहक का नाम",
    validationCustomerName: "ग्राहक का नाम आवश्यक है",
    labelTypeOfBooking: "बुकिंग का प्रकार",
    validationTypeOfBooking: "बुकिंग का प्रकार आवश्यक है",
    labelEventName:"घटना नाम",
    labelVenueName:"स्थल का नाम",
    labelFlightNumber:"विमान संख्या",
    labelOriginAirport: "मूल हवाई अड्डा",
    labelHotelName: "आवास का नाम",
    labelHotelLocation: "आवास स्थान",
    labelCurrency: "मुद्रा",
    validationCurrency: "मुद्रा की आवश्यकता है",
    validationCountryCurrency: "चयनित मुद्रा आपके गृह देश की मुद्रा होनी चाहिए",
    validationOfflinePayment: "हम आपकी मुद्रा और देश चयन के लिए रिफंड संसाधित नहीं कर सकते, कृपया विकल्प चुनें",
    labelBookingValue: "बुकिंग मूल्य",
    validationBookingValue: "बुकिंग मूल्य आवश्यक है",
    validationBookingValueAmount: "0 से बड़ा होना चाहिए",
    labelDateOfPurchase: "खरीदने की तारीख",
    validationDateOfPurchase: "खरीद की तिथि आवश्यक है",
    labelEmailAddress: "ईमेल पता",
    validationEmailAddress: "ईमेल पते की ज़रुरत है",
    validationEmailAddressFormat: "गलत ईमेल प्रारूप",
    labelConfirmEmailAddress: "ईमेल पते की पुष्टि करें",
    validationConfirmEmailAddressFormat: "गलत ईमेल प्रारूप",
    validationConfirmEmailAddress: "पुष्टिकरण ईमेल पता आवश्यक है",
    validationConfirmEmailAddressMatch: "ई - मेल एड्रेस मैच नहीं हो रहा है",
    validationEmailDomain: "यह ईमेल प्रदाता स्वीकार नहीं किया जाता है, कृपया एक विकल्प का उपयोग करें",
    validationDateOfEvent: "घटना की तिथि आवश्यक है",
    labelHomeCountry: "स्वदेश",
    validationHomeCountry: "गृह देश आवश्यक है",
    labelContactNumberCountryCode: "संपर्क नंबर देश कोड",
    validationContactNumberCountryCode: "देश डायल कोड आवश्यक है",
    labelContactNumber: "संपर्क संख्या",
    validationContactNumber: "संपर्क नंबर आवश्यक है",
    labelAddressLine1: "पता पंक्ति 1",
    labelAddressLine2: "पता पंक्ति 2",
    labelCity: "शहर",
    labelZipCode: "ज़िप कोड",
    labelRegion: "क्षेत्र",
    validationRegion: "क्षेत्र की आवश्यकता है",
    labelNumberOfTicketsToRefund: "रिफंड के लिए टिकटों की संख्या",
    validationNumberOfTicketsToRefund: "धनवापसी के लिए टिकटों की संख्या आवश्यक है",
    validationNumberOfTicketsToRefundValue: "धनवापसी के लिए टिकटों की संख्या प्रतिरूप है",
    labelCostPerTicket: "प्रत्येक टिकट की लागत",
    validationCostPerTicket: "प्रति टिकट लागत आवश्यक है",
    validationCostPerTicketValue: "0 से बड़ा होना चाहिए",
    labelTotalRefundAmount: "कुल वापसी राशि",
    validationTotalRefundAmount: "कुल धनवापसी राशि आवश्यक है",
    validationTotalRefundAmountValue: "0 से बड़ा होना चाहिए",
    validationTotalRefundGreaterThanBookingValue: "कुल धनवापसी राशि उपरोक्त बुकिंग मूल्य से अधिक नहीं हो सकती",
    labelBookingConfirmationFiles: "बुकिंग पुष्टि अपलोड करें",
    validationBookingConfirmationFiles: "कम से कम एक फ़ाइल संलग्न करें या दस्तावेज़ होने पर सहेजें और बाद में वापस लौटें पर क्लिक करें",
    validationEvidenceFilesPopup: "जमा करने से पहले साक्ष्य अपलोड करना आवश्यक है। अगर आपके पास अभी सबूत नहीं है तो कृपया आवेदन को सेव कर लें और एक बार आपके पास वापस आ जाएं। हम इलेक्ट्रॉनिक प्रतियां, स्कैनर प्रतियां और तस्वीरें स्वीकार करते हैं।",
    validationBookingConfirmationFilesPopup: "सबमिट करने से पहले बुकिंग पुष्टिकरण अपलोड करना आवश्यक है। यदि आपके पास अभी बुकिंग की पुष्टि नहीं है, तो कृपया आवेदन को सहेजें और एक बार आपके पास वापस आ जाएं। हम इलेक्ट्रॉनिक प्रतियां, स्कैनर प्रतियां और तस्वीरें स्वीकार करते हैं।",
    alertUploadDocuments: "कृपया ईमेल में विस्तृत संबंधित दस्तावेज़ अपलोड करें और फिर चरण 3 पर जाएँ।",
    labelRefundReasonCategory: "वापसी का कारण",
    validationRefundReasonCategory: "धनवापसी कारण आवश्यक है",
    labelDateOfIncident: "घटना की तिथि",
    labelAdditionalComments: "अतिरिक्त टिप्पणियां",
    tooltipAdditionalComments: "अतिरिक्त टिप्पणियां",
    validationAdditionalComments: "अतिरिक्त टिप्पणियां आवश्यक हैं",
    labelUnableToNotifyWithinTimeLimit: "से 60 दिनों के भीतर सूचित करने में असमर्थ",
    labelReasonForNotificationOutsideTimeLimit: "अधिसूचना समय सीमा के बाहर स्पष्टीकरण",
    validationReasonForNotificationOutsideTimeLimit: "60 दिनों के बाद अधिसूचना का कारण आवश्यक है",
    labelRefundReasonFiles: "साक्ष्य अपलोड करें",
    validationRefundReasonFiles: "कम से कम एक फ़ाइल संलग्न करें या दस्तावेज़ होने पर सहेजें और बाद में वापस लौटें पर क्लिक करें",
    validationTextScript: "लैटिन लिपि से मेल खाना चाहिए",
    optionTypeOfBooking1: "आयोजन",
    optionTypeOfBooking2: "यात्रा",
    optionTypeOfBooking3: "उड़ान",
    optionTypeOfBooking4: "निवास स्थान",
    optionTypeOfBooking5: "स्थानांतरण",
    optionTypeOfBooking6: "पार्किंग",
    optionTypeOfBooking7: "अन्य यात्रा",
    labelEventTypeDate: "घटना की तिथि",
    labelEventTypeTour: "यात्रा की तिथि",
    labelEventTypeFlight: "उड़ान की तिथि",
    labelEventTypeCheckIn: "चेक इन की तिथि",
    labelEventTypeTransfer: "स्थानांतरण की तिथि",
    labelEventTypeParking: "पार्किंग की तिथि",
    labelEventTypeOtherTravel: "यात्रा की तिथि",
    optionRefundReason11: "प्रतिकूल मौसम",
    optionRefundReason10: "सशस्त्र बल और आपातकालीन सेवाएं रिकॉल",
    optionRefundReason15: "परीक्षा तिथियों में परिवर्तन",
    optionRefundReason18: "अदालती सम्मन",
    optionRefundReason17: "COVID-19 यात्रा नियंत्रण",
    optionRefundReason19: "COVID-19 पॉजिटिव टेस्ट",
    optionRefundReason20: "ऑपरेटर/आयोजक द्वारा रद्द की गई बुकिंग",
    optionRefundReason21: "ऑपरेटर/आयोजक द्वारा पुनर्निर्धारित बुकिंग",
    optionRefundReason22: "गलत दिनांक बुक किया गया",
    optionRefundReason23: "गलत टिकट बुक किया",
    optionRefundReason24: "बहुत अधिक टिकट बुक किया",
    optionRefundReason4: "मौत",
    optionRefundReason16: "आपातकालीन परिस्थितियाँ",
    optionRefundReason14: "सरकारी यात्रा प्रतिबंध",
    optionRefundReason9: "होम इमरजेंसी",
    optionRefundReason1: "बीमारी/चोट/दुर्घटना",
    optionRefundReason8: "जूरी सेवा",
    optionRefundReason7: "यांत्रिक टूटना",
    optionRefundReason2: "पहले से मौजूद चिकित्सा स्थिति",
    optionRefundReason3: "गर्भावस्था की जटिलता",
    optionRefundReason5: "सार्वजनिक परिवहन विफलता",
    optionRefundReason6: "उड़ान में व्यवधान",
    optionRefundReason13: "दस्तावेज़ की चोरी",
    optionRefundReason12: "कार्य स्थानांतरण",
    optionRefundReason25: "कार्यस्थल अतिरेक",
    optionRefundReason26: "परीक्षण विकल्प",
    optionRefundReasonConfirm1: "नहीं",
    optionRefundReasonConfirm2: "हाँ",
    optionRefundReasonArmedForces1: "सेना",
    optionRefundReasonArmedForces2: "नौसेना",
    optionRefundReasonArmedForces3: "वायु सेना",
    optionRefundReasonArmedForces4: "विशेष बलों",
    optionRefundReasonArmedForces5: "मरीन",
    optionRefundReasonArmedForces6: "अग्‍नि",
    optionRefundReasonArmedForces7: "रोगी-वाहन",
    optionRefundReasonArmedForces8: "डॉक्टर / नर्स",
    optionRefundReasonArmedForces9: "डेंटिस्ट",
    optionRefundReasonArmedForces10: "दूसरा",
    optionRefundReasonCourtSummons1: "आपराधिक मुकदमा",
    optionRefundReasonCourtSummons2: "सिविल कानूनी मामला",
    optionRefundReasonCourtSummons3: "अभियोजन पक्ष में गवाह",
    optionRefundReasonCourtSummons4: "एक सिविल मामले में गवाह",
    optionRefundReasonCourtSummons5: "वादी",
    optionRefundReasonCourtSummons6: "प्रतिवादी",
    optionRefundReasonCourtSummons7: "दूसरा",
    optionRefundReasonDeath1: "पति",
    optionRefundReasonDeath2: "पत्नी",
    optionRefundReasonDeath3: "ध्वनि",
    optionRefundReasonDeath4: "बेटी",
    optionRefundReasonDeath5: "पिता",
    optionRefundReasonDeath6: "माँ",
    optionRefundReasonDeath7: "भाई",
    optionRefundReasonDeath8: "बहन",
    optionRefundReasonDeath9: "दादा",
    optionRefundReasonDeath10: "दादी",
    optionRefundReasonDeath11: "दूसरा",
    optionRefundReasonHomeEmergency1: "अग्‍नि",
    optionRefundReasonHomeEmergency2: "बाढ",
    optionRefundReasonHomeEmergency3: "डकैती",
    optionRefundReasonHomeEmergency4: "बर्बरता",
    optionRefundReasonHomeEmergency5: "घरेलू दुर्व्यवहार",
    optionRefundReasonHomeEmergency6: "अपहरण",
    optionRefundReasonHomeEmergency7: "दूसरा",
    optionRefundReasonCOVID19Person1: "खुद",
    optionRefundReasonCOVID19Person2: "बुकिंग में एक और व्यक्ति",
    optionRefundReasonCOVID19Person3: "मेरे घर में एक व्यक्ति",
    optionRefundReasonCOVID19Person4: "दूसरा",
    optionRefundReasonCOVID19TestType1: "पीसीआर टेस्ट",
    optionRefundReasonCOVID19TestType2: "RAT परीक्षण",
    optionRefundReasonCOVID19TestType3: "पार्श्व प्रवाह परीक्षण",
    optionRefundReasonCOVID19TestType4: "स्व प्रशासित परीक्षण",
    optionRefundReasonCOVID19TestType5: "कोई परीक्षण नहीं है, लेकिन मेरे पास लक्षण हैं",
    optionRefundReasonCOVID19TestType6: "दूसरा",
    optionRefundReasonBreakdown1: "टूटने",
    optionRefundReasonBreakdown2: "दुर्घटना",
    optionRefundReasonBreakdown3: "अग्‍नि",
    optionRefundReasonBreakdown4: "चोरी",
    optionRefundReasonPublicTransportType1: "रेलगाड़ी",
    optionRefundReasonPublicTransportType2: "बस",
    optionRefundReasonPublicTransportType3: "ट्राम",
    optionRefundReasonPublicTransportType4: "नाव",
    optionRefundReasonDocumentType1: "पासपोर्ट",
    optionRefundReasonDocumentType2: "यात्रा परमिट",
    optionRefundReasonDocumentType3: "राष्ट्रीय ID",
    optionRefundReasonDocumentType4: "टिकट",
    optionRefundReasonWorkRelocationType1: "अस्थायी",
    optionRefundReasonWorkRelocationType2: "स्थायी",
    optionRefundReasonWorkRelocationTemporary1: "0-3 महीने",
    optionRefundReasonWorkRelocationTemporary2: "3-6 महीने",
    optionRefundReasonWorkRelocationTemporary3: "6 महीने से अधिक",
    optionRefundReasonBookingRelationshipType1: "बुकिंग में किसी को",
    optionRefundReasonBookingRelationshipType2: "बुकिंग में किसी के तत्काल रिश्तेदार",
    optionEmploymentType1: "पूरा समय",
    optionEmploymentType2: "अंशकालिक",
    optionEmploymentType3: "ठेका",
    optionEmploymentLengthType1: "0-1 वर्ष",
    optionEmploymentLengthType2: "1-2 साल",
    optionEmploymentLengthType3: "2 साल से अधिक",
    textRefundEvidenceHeading: "कृपया निम्नलिखित साक्ष्य प्रदान करें:",
    textRefundEvidence1: "एयरलाइन पुष्टिकरण",
    textRefundEvidence2: "कोई भी उपयुक्त समर्थन सबूत",
    textRefundEvidence3: "ब्रेकडाउन सेवा कॉल आउट पुष्टिकरण",
    textRefundEvidence4: "मृत्यु प्रमाण पत्र",
    textRefundEvidence5: "एयरलाइन से पुष्टि रद्दीकरण",
    textRefundEvidence6: "मौसम रिपोर्ट",
    textRefundEvidence7: "टिकट फिर से जारी करने की कोई क्षमता की पुष्टि बुकिंग एजेंट से पत्राचार",
    textRefundEvidence8: "अदालत से पत्र",
    textRefundEvidence9: "चिकित्सा प्रमाण पत्र / डॉक्टर का नोट",
    textRefundEvidenceDescription9: "बीमारी या चोट के विवरण की पुष्टि करते हुए, जिस तारीख को यह पहली बार हुआ था, कि यह आपको बुकिंग में भाग लेने से रोकता है।",
    textRefundEvidence10: "नियोक्ता पत्र की पुष्टि",
    textRefundEvidence11: "बीमा दावा पत्राचार",
    textRefundEvidence12: "नियोक्ता से पत्र",
    textRefundEvidence13: "राष्ट्रीय सरकार की वेबसाइट की पुष्टि",
    textRefundEvidence14: "परीक्षा परिवर्तन सूचना",
    textRefundEvidence15: "पुलिस रिपोर्ट",
    textRefundEvidence16: "पुलिस रिपोर्ट और संदर्भ संख्या",
    textRefundEvidence17: "परिवहन प्रदाता की पुष्टि",
    textRefundEvidence18: "यात्रा चेतावनी",
    textRefundEvidence20: "कोविड-19 टेस्ट सर्टिफिकेट",
    textRefundEvidence24: "सरकारी अलगाव नोटिस",
    textRefundEvidence25: "कमांडिंग ऑफिसर का पत्र",
    textRefundEvidence26: "बीमाकर्ताओं से पत्र",
    textRefundEvidence27: "पते का प्रमाण",
    textRefundEvidence28: "मृतक के साथ संबंधों का सबूत",
    textRefundEvidence29: "घायल / बीमार व्यक्ति के साथ संबंधों का सबूत",
    textRefundEvidence30: "वेतन पर्ची",
    tooltipBookingReference: "अपने बुकिंग संदर्भ की पहचान करने के लिए, अपनी बुकिंग पुष्टिकरण की जांच करें और हमारी विस्तारित सेवा के बारे में अनुभाग देखें।",
    tooltipCustomerName: "पहला और अंतिम नाम जिसका उपयोग बुकिंग करने के लिए किया गया था।",
    tooltipTypeOfBooking: "बुकिंग का प्रकार जिसे आप वापस करना चाहते हैं।",
    tooltipEventName: "घटना का नाम।",
    tooltipVenueName: "आयोजन स्थल का नाम।",
    tooltipFlightNumber: "उड़ान संख्या।",
    tooltipOriginAirport: "प्रस्थान हवाई अड्डा।",
    tooltipHotelName: "आवास का नाम।",
    tooltipHotelLocation: "आवास का स्थान।",
    tooltipCurrency: "बुकिंग के लिए भुगतान की जाने वाली मुद्रा।",
    tooltipBookingValue: "बुकिंग के लिए आपके द्वारा भुगतान की गई कुल कीमत।",
    tooltipDateOfPurchase: "जिस तारीख को बुकिंग की गई थी।",
    tooltipEmailAddress: "कृपया एक मान्य ईमेल पते का उपयोग करें। हम धनवापसी आवेदन के दौरान पत्राचार के लिए इस ईमेल का उपयोग करते हैं।",
    tooltipConfirmEmailAddress: "ऊपर दिए गए ईमेल पते का ही उपयोग करें, यह सत्यापन के लिए है।",
    tooltipHomeCountry: "इससे हमें अंतिम चरण में सही बैंकिंग जानकारी लाने में मदद मिलेगी।",
    tooltipContactNumberCountryCode: "संपर्क नंबर देश कोड।",
    tooltipContactNumber: "कृपया एक मान्य नंबर का उपयोग करें। हम धनवापसी आवेदन के दौरान पत्राचार के लिए इसका उपयोग करते हैं।",
    tooltipAddressLine1: "कृपया ध्यान दें कि आपके बैंक खाते का सत्यापन करते समय आपके पते का उपयोग किया जाएगा।",
    tooltipAddressLine2: "पते की दूसरी पंक्ति।",
    tooltipCity: "आपके गृह शहर, विवरण का उपयोग बैंकिंग विवरण को मान्य करने के लिए किया जा सकता है।",
    tooltipZipCode: "जिप कोड / पोस्टल कोड।",
    tooltipRegion: "आपके गृह शहर के क्षेत्र, विवरण का उपयोग बैंकिंग विवरण को मान्य करने के लिए किया जा सकता है।",
    tooltipNumberOfTicketsToRefund: "जितने टिकट आप वापस करना चाहते हैं।",
    tooltipTotalRefundAmount: "यह कुल बुकिंग मूल्य से अधिक नहीं हो सकता है, यदि ऐसा होता है तो आप आवेदन जमा नहीं कर पाएंगे।",
    tooltipBookingConfirmationFiles: "आपके पूर्ण बुकिंग पुष्टिकरण का स्क्रीनशॉट/संलग्नक जो आमतौर पर आपके ईमेल में पाया जा सकता है।",
    tooltipRefundReasonCategory: "कृपया धनवापसी के लिए अपना कारण चुनें।",
    tooltipDateOfIncident: "जिस तारीख से आपकी बुकिंग को प्रभावित करने वाली घटना हुई है।",
    tooltipRefundExplanation: "कृपया अधिक से अधिक विवरण दें जो धनवापसी के दावे का समर्थन करते हैं।",
    tooltipUnableToNotifyWithinTimeLimit: "60 दिनों की समय सीमा के भीतर सूचित करने में असमर्थ।",
    tooltipReasonForNotificationOutsideTimeLimit: "समय सीमा के बाहर अधिसूचना स्पष्टीकरण।",
    tooltipRefundReasonFiles: "कृपया कोई भी आवश्यक दस्तावेज अपलोड करें।",
    tooltipEventTypeDate: "वास्तविक घटना / बुकिंग की तिथि।",
    tooltipEventTypeTour: "वास्तविक दौरे की तिथि।",
    tooltipEventTypeFlight: "वास्तविक उड़ान की तिथि।",
    tooltipEventTypeCheckIn: "वास्तविक चेक इन की तिथि।",
    tooltipEventTypeTransfer: "वास्तविक स्थानांतरण की तिथि।",
    tooltipEventTypeParking: "वास्तविक पार्किंग की तिथि।",
    tooltipEventTypeOtherTravel: "वास्तविक यात्रा की आरंभ तिथि।"
};
