import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CloseButton} from '../buttons/CloseButton';
import {ModalHolder} from './ModalHolder';

interface DuplicateFilesModalProps {
    close: () => void;
    duplicateFiles: string[];
    showValidationModal: boolean;
}

export const DuplicateFilesModal = (props: DuplicateFilesModalProps) => {

    const {
        close,
        duplicateFiles,
        showValidationModal
    } = props;

    const {t} = useTranslation();

    const Content = () => {
        return (
            <>
                <Box pb={1}>
                    {t('common:fileUploadSelectedUploaded')}
                    <ul>
                        {duplicateFiles.map(x => <li key={`duplicate-${duplicateFiles.indexOf(x)}`}>{x}</li>)}
                    </ul>
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonClose')}
                        handleOnClick={close}
                    />
                </Box>
            </>
        );
    }

    return (
        <ModalHolder
            showModal={showValidationModal}
            content={<Content/>}
            handleClose={close}
            width='sm'
        />
    );
}