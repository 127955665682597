export default {
    labelBookingCancelledPostponedHeading: "Er din reservation blevet aflyst eller udskudt? Læs mere",
    labelBookingCancelledPostponedText1: "Klik på",
    labelBookingCancelledPostponedText2: "for mere info.",
    labelNewRefundHeading: "Ny refusionsansøgning?",
    labelNewRefundText: "Indtast din reservationsreference her...",
    placeholderBookingReference: "Bookingnummer",
    validationBookingReference: "Booking Reference er påkrævet",
    validationEmailAsBookingReference: "E-mailadresse accepteres ikke som reservationsreference",
    buttonFindBooking: "Find Booking",
    labelEmailHeading: "Allerede startet?",
    labelEmailText: "Send en E-mailpåmindelse for at hente din ansøgning",
    placeholderEmail: "E-mail",
    buttonEmailReminder: "E-mail-påmindelse",
    labelCustomerName: "Kundenavn",
    validationCustomerName: "Kundenavn kræves",
    labelTypeOfBooking: "Type reservation",
    validationTypeOfBooking: "Type reservation er påkrævet",
    labelEventName: "Begivenhedsavn ",
    labelVenueName: "Stedets navn",
    labelFlightNumber: "Flynummer ",
    labelOriginAirport: "Origin Lufthavn",
    labelHotelName: "Indkvarteringsnavn",
    labelHotelLocation: "Indkvartering placering",
    labelCurrency: "Betalingsmiddle",
    validationCurrency: "Vulta er påkrævet",
    validationCountryCurrency: "Den valgte valuta skal være valutaen i dit hjemland",
    validationOfflinePayment: "Vi kan ikke behandle refusioner for dit valg af valuta og lande, vælg venligst alternativer",
    labelBookingValue: "Bookingværdi",
    validationBookingValue: "Bookingværdi er pakrævet",
    validationBookingValueAmount: "Skal være større end 0",
    labelDateOfPurchase: "Købsdato",
    validationDateOfPurchase: "Købstado er påkrævet",
    labelEmailAddress: "Email adresse",
    validationEmailAddress: "E-mail-adresse er påkrævet",
    validationEmailAddressFormat: "Forkert e-mail-format",
    labelConfirmEmailAddress: "Bekræft email adresse",
    validationConfirmEmailAddressFormat: "Forkert e-mail-format",
    validationConfirmEmailAddress: "Bekræftelses",
    validationConfirmEmailAddressMatch: "E-mail-adresser stemmer ikke overens",
    validationEmailDomain: "Denne e-mail-udbyder accepteres ikke. Brug venligst et alternativ",
    validationDateOfEvent: "Dato for begivenhed er påkrævet",
    labelHomeCountry: "Hjemland",
    validationHomeCountry: "Hjemland er påkrævet",
    labelContactNumberCountryCode: "Kontaktnummer Landekode",
    validationContactNumberCountryCode: "Landekode er påkrævet",
    labelContactNumber: "Kontakt nummer",
    validationContactNumber: "Kontaktnummer er påkrævet",
    labelAddressLine1: "Adresse linie 1",
    labelAddressLine2: "Adresselinje 2",
    labelCity: "by",
    labelZipCode: "Postnummer",
    labelRegion: "Område",
    validationRegion: "Region er påkrævet",
    labelNumberOfTicketsToRefund: "Antal billetter, der skal refunderes",
    validationNumberOfTicketsToRefund: "Antal billetter, der skal refunderes, er påkrævet",
    validationNumberOfTicketsToRefundValue: "Skal være stōrre end 0",
    labelCostPerTicket: "Omkostninger for hver billet",
    validationCostPerTicket: "Omkostninger pr. Billet er påkrævet",
    validationCostPerTicketValue: "Skal være større and 0",
    labelTotalRefundAmount: "Samlet refusionsbeløb",
    validationTotalRefundAmount: "Samlet refusionsbeløb er påkrævet",
    validationTotalRefundAmountValue: "Skal være større end 0",
    validationTotalRefundGreaterThanBookingValue: "Samlet refusionsbeløb kan ikke være større end reservationsværdien ovenfor",
    labelBookingConfirmationFiles: "Upload reservationsbekræftelse",
    validationBookingConfirmationFiles: "Vedæft minds ten fil, eller kilk på Gem, og vend tilbage senere, når du har dokumenterne",
    validationEvidenceFilesPopup: "Bevis skal uploades før indsendelse. Hvis du ikke har beviserne lige nu, skal du gemme ansøgningen og vende tilbage, når du har. Vi accepterer elektroniske kopier, scannerkopier og fotos.",
    validationBookingConfirmationFilesPopup: "Bookingbekræftelse skal uploades før indsendelse. Hvis du ikke har reservationsbekræftelsen lige nu, bedes du gemme ansøgningen og returnere, når du har. Vi accepterer elektroniske kopier, scannerkopier og fotos.",
    alertUploadDocuments: "Upload venligst den relevante dokumentation, der er beskrevet i e-mailen, og fortsæt derefter til trin 3.",
    labelRefundReasonCategory: "Årsag till refusion",
    validationRefundReasonCategory: "Der kræves refusionårsag",
    labelDateOfIncident: "Dato for hændelsen",
    labelAdditionalComments: "Yderligere kommentarer",
    tooltipAdditionalComments: "Yderligere kommentarer",
    validationAdditionalComments: "Yderligere kommentarer er påkrævet",
    labelUnableToNotifyWithinTimeLimit: "Kan ikke give besked inden for 60 dage fra",
    labelReasonForNotificationOutsideTimeLimit: "Meddelelse Uden for tidsbegrænsning",
    validationReasonForNotificationOutsideTimeLimit: "Der kræves en grund til underretning efter 60 dage",
    labelRefundReasonFiles: "Upload bevis",
    validationRefundReasonFiles: "Vedhæft minds ten fil, eller klik på Gem, og vend tilbage senere, når har dokuenterne",
    validationTextScript: "Skal matche latinsk script",
    optionTypeOfBooking1: "Begivenhed",
    optionTypeOfBooking2: "Tur",
    optionTypeOfBooking3: "Flyvningen",
    optionTypeOfBooking4: "Indkvartering",
    optionTypeOfBooking5: "Øverforsel",
    optionTypeOfBooking6: "Parkering",
    optionTypeOfBooking7: "Startdato for den aktuelle rejse",
    labelEventTypeDate: "Dato for begivenhed",
    labelEventTypeTour: "Dato for tur",
    labelEventTypeFlight: "Dato for flyvning",
    labelEventTypeCheckIn: "Dato for indjekning",
    labelEventTypeTransfer: "Dato for øverforsel",
    labelEventTypeParking: "Dato for parkering",
    labelEventTypeOtherTravel: "Dato for rejsen",
    optionRefundReason11: "Uønsket vejr",
    optionRefundReason10: "Tilbagekaldelse af væbnede styrker og nødtjenester",
    optionRefundReason15: "Ændringer i eksamendatoer",
    optionRefundReason18: "Retsindkaldelse",
    optionRefundReason17: "Rejsebegrænsninger på grund af covid-19",
    optionRefundReason19: "COVID-19 Positiv test",
    optionRefundReason20: "Booking aflyst af operatør/arrangør",
    optionRefundReason21: "Booking omlagt af operatør/arrangør",
    optionRefundReason22: "Booket forkert dato",
    optionRefundReason23: "Bestilte forkerte billetter",
    optionRefundReason24: "Bookede for mange billetter",
    optionRefundReason4: "Død",
    optionRefundReason16: "Nødsituationer",
    optionRefundReason14: "Regeringens rejseforbud",
    optionRefundReason9: "Hjemmesituatilfælde",
    optionRefundReason1: "Sygdom /tilskadekomost /ulykke ",
    optionRefundReason8: "Jurytjeneste",
    optionRefundReason7: "Mekanisk opdeling",
    optionRefundReason2: "Eksisterende medicinsk tilstand",
    optionRefundReason3: "Graviditetskomplikation",
    optionRefundReason5: "Offentlig transportfejl",
    optionRefundReason6: "Flyforstyrrelse",
    optionRefundReason13: "Tyveri af dokumenter",
    optionRefundReason12: "Arbejdsflytning",
    optionRefundReason25: "Arbejdspladsredundans",
    optionRefundReason26: "Test mulighed",
    optionRefundReasonConfirm1: "Nej",
    optionRefundReasonConfirm2: "Ja",
    optionRefundReasonArmedForces1: "Hær",
    optionRefundReasonArmedForces2: "Søværn",
    optionRefundReasonArmedForces3: "Flyvevåben",
    optionRefundReasonArmedForces4: "Specialstyrker",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Ild",
    optionRefundReasonArmedForces7: "Ambulance",
    optionRefundReasonArmedForces8: "Læge/sygeplejerske",
    optionRefundReasonArmedForces9: "Tandlæge",
    optionRefundReasonArmedForces10: "Anden",
    optionRefundReasonCourtSummons1: "Strafferetlig forfølgning",
    optionRefundReasonCourtSummons2: "Civilretlig sag",
    optionRefundReasonCourtSummons3: "Vidne i en retssag",
    optionRefundReasonCourtSummons4: "Vidne i en civil sag",
    optionRefundReasonCourtSummons5: "Sagsøger",
    optionRefundReasonCourtSummons6: "Sagsøgt",
    optionRefundReasonCourtSummons7: "Anden",
    optionRefundReasonDeath1: "Mand",
    optionRefundReasonDeath2: "Kone",
    optionRefundReasonDeath3: "Lyd",
    optionRefundReasonDeath4: "Datter",
    optionRefundReasonDeath5: "Far",
    optionRefundReasonDeath6: "Mor",
    optionRefundReasonDeath7: "Bror",
    optionRefundReasonDeath8: "Søster",
    optionRefundReasonDeath9: "Bedstefar",
    optionRefundReasonDeath10: "Bedstemor",
    optionRefundReasonDeath11: "Anden",
    optionRefundReasonHomeEmergency1: "Ild",
    optionRefundReasonHomeEmergency2: "Oversvømmelse",
    optionRefundReasonHomeEmergency3: "Røveri",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Misbrug i hjemmet",
    optionRefundReasonHomeEmergency6: "Kidnappe",
    optionRefundReasonHomeEmergency7: "Anden",
    optionRefundReasonCOVID19Person1: "Mig",
    optionRefundReasonCOVID19Person2: "En anden person i reservationen",
    optionRefundReasonCOVID19Person3: "En person i min husstand",
    optionRefundReasonCOVID19Person4: "Anden",
    optionRefundReasonCOVID19TestType1: "PCR-test",
    optionRefundReasonCOVID19TestType2: "RAT Test",
    optionRefundReasonCOVID19TestType3: "Test af lateralt flow",
    optionRefundReasonCOVID19TestType4: "Selvadministreret test",
    optionRefundReasonCOVID19TestType5: "Ingen test, men jeg har symptomer",
    optionRefundReasonCOVID19TestType6: "Anden",
    optionRefundReasonBreakdown1: "Nedbrud",
    optionRefundReasonBreakdown2: "Ulykke",
    optionRefundReasonBreakdown3: "Ild",
    optionRefundReasonBreakdown4: "Tyveri",
    optionRefundReasonPublicTransportType1: "Tog",
    optionRefundReasonPublicTransportType2: "Bus",
    optionRefundReasonPublicTransportType3: "Sporvogn",
    optionRefundReasonPublicTransportType4: "Båd",
    optionRefundReasonDocumentType1: "Pas",
    optionRefundReasonDocumentType2: "Rejsetilladelse",
    optionRefundReasonDocumentType3: "Nationalt ID",
    optionRefundReasonDocumentType4: "Billetter",
    optionRefundReasonWorkRelocationType1: "Midlertidig",
    optionRefundReasonWorkRelocationType2: "Permanent",
    optionRefundReasonWorkRelocationTemporary1: "0-3 måneder",
    optionRefundReasonWorkRelocationTemporary2: "3-6 måneder",
    optionRefundReasonWorkRelocationTemporary3: "Over 6 måneder",
    optionRefundReasonBookingRelationshipType1: "Nogen i reservationen",
    optionRefundReasonBookingRelationshipType2: "Nærmeste pårørende til en person i reservationen",
    optionEmploymentType1: "Fuldtid",
    optionEmploymentType2: "Deltid",
    optionEmploymentType3: "Kontrakt",
    optionEmploymentLengthType1: "0-1 år",
    optionEmploymentLengthType2: "1-2 år",
    optionEmploymentLengthType3: "mere end 2 år",
    textRefundEvidenceHeading: "Giv føgende bevis:",
    textRefundEvidence1: "Bekræftelse af flyselskab",
    textRefundEvidence2: "Eventuel passende dokumentation",
    textRefundEvidence3: "Bekræftelse af udkald for nedbrudstjeneste",
    textRefundEvidence4: "Dødsattest",
    textRefundEvidence5: "Bekræftelse annullering fra flyselskab",
    textRefundEvidence6: "Vejrudsigt",
    textRefundEvidence7: "Korrespondance fra bookingagent, der bekræfter, at der ikke er mulighed for at genudstede billetter",
    textRefundEvidence8: "Brev fra retten",
    textRefundEvidence9: "Lægeerklæring / Lægeerklæring",
    textRefundEvidenceDescription9: "Bekræftelse af oplysninger om sygdommen eller skaden, den dato, hvor den først opstod, at det forhindrer dig i at deltage i reservationen.",
    textRefundEvidence10: "Bekræftelse af arbejdsgiverbrev",
    textRefundEvidence11: "Korrespondance om forsikringskrav",
    textRefundEvidence12: "Brev fra arbejdsgiver",
    textRefundEvidence13: "Bekræftelse af den nationale regerings websted",
    textRefundEvidence14: "Meddelelse om ændring af eksamen",
    textRefundEvidence15: "Politianmeldelse",
    textRefundEvidence16: "Politirapport og referencenummer",
    textRefundEvidence17: "Bekræftelse af transportudbyder",
    textRefundEvidence18: "Rejseadvarsel",
    textRefundEvidence20: "COVID-19-testcertifikat",
    textRefundEvidence24: "Meddelelse om regeringens isolation",
    textRefundEvidence25: "Brev fra kommanderende officer",
    textRefundEvidence26: "Brev fra forsikringsselskaber",
    textRefundEvidence27: "Bevis for adresse",
    textRefundEvidence28: "Bevis for forholdet til den afdøde",
    textRefundEvidence29: "Bevis for forholdet til den tilskadekomne/syge person",
    textRefundEvidence30: "Lønseddel",
    tooltipBookingReference: "For at identificere din reservationsreference skal du tjekke din reservationsbekræftelse og se afsnittet om vores udvidede service.",
    tooltipCustomerName: "For- og efternavn, der blev brugt til at foretage reservationen.",
    tooltipTypeOfBooking: "Den type reservation, du gerne vil have refunderet.",
    tooltipEventName: "Navnet på begivenheden.",
    tooltipVenueName: "Navnet på mødestedet.",
    tooltipFlightNumber: "Flynummeret.",
    tooltipOriginAirport: "Afgangslufthavnen.",
    tooltipHotelName: "Indkvarteringens navn.",
    tooltipHotelLocation: "Placeringen af ​​boligen.",
    tooltipCurrency: "Den valuta, der blev brugt til at betale for reservationen.",
    tooltipBookingValue: "Den samlede pris, du har betalt for reservationen.",
    tooltipDateOfPurchase: "The date that the booking was made.",
    tooltipEmailAddress: "Brug en gyldig e-mail-adresse. Vi bruger denne e-mail til korrespondance under refusionsansøgningen.",
    tooltipConfirmEmailAddress: "Brug den samme e-mail-adresse som ovenfor, dette er til validering.",
    tooltipHomeCountry: "Dette vil hjælpe os med at få de korrekte bankoplysninger til det sidste trin.",
    tooltipContactNumberCountryCode: "Kontaktnummer landekode.",
    tooltipContactNumber: "Brug et gyldigt nummer. Vi bruger dette til korrespondance under refusionsansøgningen.",
    tooltipAddressLine1: "Bemærk, at din adresse vil blive brugt, når du validerer din bankkonto.",
    tooltipAddressLine2: "Anden adresselinje.",
    tooltipCity: "Din hjemby, detaljer kan bruges til validering af bankoplysninger.",
    tooltipZipCode: "Postnummer / postnummer.",
    tooltipRegion: "Region i din hjemby, detaljer kan bruges til validering af bankoplysninger.",
    tooltipNumberOfTicketsToRefund: "Antallet af billetter, du gerne vil have refunderet.",
    tooltipTotalRefundAmount: "Dette kan ikke være mere end den samlede reservationsværdi, du kan ikke indsende ansøgningen, hvis den gør det.",
    tooltipBookingConfirmationFiles: "Skærmbillede / vedhæftning af din fulde reservationsbekræftelse, som normalt kan findes i dine e-mails.",
    tooltipRefundReasonCategory: "Vælg din grund til refusion.",
    tooltipDateOfIncident: "Datoen for hvilken hændelsen, der påvirkede din reservation, fandt sted.",
    tooltipRefundExplanation: "Giv så mange detaljer som muligt, der understøtter kravet om refusion.",
    tooltipUnableToNotifyWithinTimeLimit: "Kan ikke give besked inden for 60 dage.",
    tooltipReasonForNotificationOutsideTimeLimit: "Meddelelse Uden for tidsbegrænsning Forklaring.",
    tooltipRefundReasonFiles: "Upload venligst alle de påkrævede dokumenter.",
    tooltipEventTypeDate: "Dato for den aktuelle begivenhed / reservation.",
    tooltipEventTypeTour: "Dato for den aktuelle tur.",
    tooltipEventTypeFlight: "Dato for den faktiske flyvning.",
    tooltipEventTypeCheckIn: "Dato for den faktiske indtjekning.",
    tooltipEventTypeTransfer: "Dato for den faktiske overførsel.",
    tooltipEventTypeParking: "Dato for den faktiske parkering.",
    tooltipEventTypeOtherTravel: "Startdato for den aktuelle rejse."
};