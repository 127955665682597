export default {
    dividerBankingDetails: "Banko informacija",
    dividerBookingDetails: "Užsakymo informacija",
    labelSavedApplicationReference: "Išsaugota programos nuoroda:",
    labelLanguage: "Kalba",
    messageWarningBookingHeading1: "Užsakymas atšauktas:",
    messageWarningBookingHeading2: "Užsakymas atidėtas / perplanuotas:",
    messageWarningBookingBody1: "Jei organizatorius arba oro transporto bendrovė atšaukė jūsų užsakymą, ši komanda negalės jums padėti grąžinti pinigų. Turėsite tiesiogiai susisiekti su savo klientų aptarnavimo komanda; Kontaktinę informaciją rasite savo užsakymo patvirtinime arba svetainėje.",
    messageWarningBookingBody2: "Jei jūsų užsakymas buvo atidėtas, pratęstos grąžinimo sąlygos bus perkeltos į naują datą. Jei turite pagrįstą priežastį grąžinti pinigus pagal mūsų sąlygas, užpildykite formą su teisingais patvirtinamaisiais įrodymais.",
    messageWarningBookingBody3: "Atkreipkite dėmesį: ši paslauga gali suteikti pinigų grąžinimą tik dėl priežasčių, nurodytų mūsų formuluotėje, kurią galite rasti jūsų užsakymo patvirtinimo el. Laiške.",
    messageWarningBookingBody4: "Jei negalite atlikti užsakymo dėl priežasčių, nepriklausančių nuo mūsų sąlygų, mes negalėsime padėti. Turėsite tiesiogiai susisiekti su savo klientų aptarnavimo komanda, nes jie gali padėti; Kontaktinę informaciją rasite savo užsakymo patvirtinime arba svetainėje.",
    linkWarningBooking: "čia",
    formSectionNavigatorStep1: "1 žingsnis",
    formSectionNavigatorStep2: "2 žingsnis",
    formSectionNavigatorStep3: "3 žingsnis",
    formSectionNavigatorStep1Text: "Kontaktai ir užsakymo detalės",
    formSectionNavigatorStep2Text: "Grąžinimo priežastis",
    formSectionNavigatorStep3Text: "Mokėjimo informacija",
    messageBookingMatchedAndFound: "Jei jūsų užsakymo nuoroda yra susieta su esama programa, atsiųsime jums el. laišką su nuoroda į jūsų paraišką",
    messageBookingMatchedAndFoundCompleted: "Jūsų prašymas grąžinti pinigus jau buvo patvirtintas! Jei kyla problemų dėl mokėjimo, susisiekite su:",
    applicationDeclinedMessage: "Jūsų paraiška buvo atmesta ir dabar yra uždaryta.",
    messageBookingNotFound1: "Mes negalime rasti užsakymo su šia nuoroda. Patikrinkite savo užsakymo patvirtinimo el. laišką ir įveskite iš naujo.",
    messageBookingNotFound2: "Nesijaudinkite, jei jūsų užsakymo nuoroda neveikia, galite pasirinkti Rasti užsakymą ir įvesti savo duomenis. Pildydami formą įkelkite savo užsakymo patvirtinimo el. laiško kopiją, kad padėtumėte mums rasti jūsų užsakymo duomenis.",
    messageLocatingBooking: "Jūsų užsakymo suradimas...",
    messageLoadingBank: "Banko informacijos įkėlimas...",
    messageFailCurrencyLookup: "Klaida gaunant valiutą",
    messageSavingApplication: "Programos išsaugojimas...",
    messageSavedApplication: "Grąžinimo paraiška sėkmingai išsaugota",
    messageFailSavedApplication: "Nepavyko išsaugoti lėšų grąžinimo paraiškos",
    messageSendingEmailReminder: "Perdirbimo...",
    messageErrorLocationDetails: "Klaida gaunant išsamią vietos informaciją",
    messageFailFormSubmission: "Formos pateikti nepavyko",
    messageApplicationCannotBeFound: "Nepavyko rasti programos",
    messageApplicationRetrievalError: "Klaida gaunant programą",
    messageSubmittingApplication: "Paraiškos pateikimas...",
    messageEventDateLimitError: "Pranešimo ne laiku priežastis",
    messageAllTicketsAssigned: "Visi bilietai buvo priskirti kitai pinigų grąžinimo paraiškai.",
    selectPrefix: "Žymėti",
    selectPostfix: "",
    fileUpload: "Spustelėkite čia, kad įkeltumėte",
    fileUploadAcceptedFilesMessage: "Priimame tik PDF failus ir .png, .jpeg, .heic, .webp PDF failus ir vaizdų formatus",
    fileUploadNoFiles: "0 Pasirinkti failai",
    fileUploadPreviouslyUploaded: "Anksčiau įkelta:",
    fileUploadSelectedUploaded: "Atrinktieji jau įkelti:",
    buttonNext: "Kitą",
    buttonPrevious: "Ankstesnis",
    buttonSave: "Taupyti",
    buttonCancel: "Atšaukti",
    buttonSubmit: "Pateikti",
    buttonConfirm: "Patvirtinti",
    buttonYes: "Taip",
    buttonNo: "Ne",
    buttonClose: "Uždaryti",
    linkPrivacyStatement: "Pareiškimas apie privatumo apsaugą",
    linkComplaintsPolicy: "Skundų politika",
    validationRequired: "Reikalingas",
    validationInvalid: "Neleistinas",
    labelUploadPrefix: "Nusiuntimas",
    labelUploadPostfix: "",
    labelChange: "Keisti",
    labelView: "Rodinys",
    browserNavigationMessage: "Neišsaugoti pakeitimai bus prarasti",
};