export default {
    nonAutoApprovedLabelThankYou: "感謝您的退款申請。",
    nonAutoApprovedLabelReview: "我們的團隊將在接下來的兩個工作日內審核您的申請，並回復您。",
    nonAutoApprovedLabelExperience: "我們是怎麼做到的？",
    nonAutoApprovedLabelExperienceIntroduction: "我們非常感謝您花時間給我們評分並留下評論，因為我們重視客戶的意見。",
    feedbackExcellent: "非常好",
    feedbackGreat: "偉大",
    feedbackAverage: "平均",
    feedbackPoor: "窮",
    feedbackBad: "壞",
    labelThankYouForFeedback: "感謝您的反饋。",
    autoApprovedMessage1: "我們很高興",
    autoApprovedMessage2: "批准",
    autoApprovedMessage3: "您的退款！",
    autoApprovedPayment1Day: "您的付款已經過處理，只需 1 個工作日即可送達您的帳戶。",
    autoApprovedPayment2to4Days: "您的付款已經過處理，應該在2-4個工作日內進入您的帳戶。",
    autoApprovedPayment7to10Days: "您的付款已經過處理，應該在7-10個工作日內進入您的帳戶。",
    autoApprovedLabelExperience: "我們是怎麼做到的？",
    autoApprovedLabelExperienceIntroduction: "我們非常感謝您花時間給我們評分並留下評論，因為我們重視客戶的意見。",
    modalLabelNegative: "您的反饋對我們非常重要，如果您還希望我們與您聯繫，請告訴我們。",
    placeholderFeedback: "反饋",
    messageFeedbackSubmitted: "已提交反饋",
    reviewInstructionMessage: "5星非常好，4星很棒，3星是平均水準，2星很差，1星很糟糕。"
}