import Axios from 'axios'

const urlRoot = '/api/';

export const createEmailQuery = async (queryNote: IQueryNote) => {
    return await Axios.post(`${urlRoot}refund/email-query`, queryNote)
}

export const createEmailQueryNote = async (applicationReference: string, email: string, queryNote: IQueryNote) => {
    return await Axios.post(`${urlRoot}refund/email-query-note`,
        queryNote,
        {
            params: {
                applicationReference,
                email
            }
        }
    );
}