export default {
    messageQuestionService: "Эта услуга предназначена для использования только в том случае, если у вас возникли проблемы с заполнением формы заявки, мы не можем здесь ответить ни на какие другие вопросы.",
    labelName: "Имя",
    labelReference: "Номер брони",
    labelPhone: "Телефонный номер",
    labelEmail: "Электронное письмо",
    labelQuery: "Ваш вопрос",
    labelAskQuestion: "Отправьте нам вопрос здесь ...",
    buttonOpenModal: "Вопрос",
    title: "Задайте вопрос",
    modalTitle: "4. Возникли технические проблемы с формой?",
    toastSuccess: "Мы получили ваш вопрос и свяжемся с вами в течение 24 часов.",
    toastError: "Что-то пошло не так с вашим запросом, попробуйте еще раз",
    nameValidation: "Имя обязательно",
    referenceValidation: "Ссылка обязательна",
    questionValidation: "Ваш вопрос обязателен"
}