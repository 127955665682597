export default {
    nonAutoApprovedLabelThankYou: "感谢您的退款申请。",
    nonAutoApprovedLabelReview: "我们的团队将在接下来的两个工作日内审核您的申请，并回复您。",
    nonAutoApprovedLabelExperience: "我们是怎么做到的？",
    nonAutoApprovedLabelExperienceIntroduction: "我们非常感谢您花时间给我们评分并留下评论，因为我们重视客户的意见。",
    feedbackExcellent: "非常好",
    feedbackGreat: "伟大",
    feedbackAverage: "平均",
    feedbackPoor: "穷",
    feedbackBad: "坏",
    labelThankYouForFeedback: "感谢您的反馈。",
    autoApprovedMessage1: "我们很高兴",
    autoApprovedMessage2: "批准",
    autoApprovedMessage3: "您的退款！",
    autoApprovedPayment1Day: "您的付款已经过处理，只需 1 个工作日即可送达您的帐户。",
    autoApprovedPayment2to4Days: "您的付款已经过处理，应该在2-4个工作日内进入您的帐户。",
    autoApprovedPayment7to10Days: "您的付款已经过处理，应该在7-10个工作日内进入您的帐户。",
    autoApprovedLabelExperience: "我们是怎么做到的？",
    autoApprovedLabelExperienceIntroduction: "我们非常感谢您花时间给我们评分并留下评论，因为我们重视客户的意见。",
    modalLabelNegative: "您的反馈对我们非常重要，如果您还希望我们与您联系，请告诉我们。",
    placeholderFeedback: "反馈",
    messageFeedbackSubmitted: "已提交反馈",
    reviewInstructionMessage: "5星非常好，4星很棒，3星是平均水平，2星很差，1星很糟糕。"
}