import * as React from 'react';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface StepsTextThemeProps {
    text: string;
}

export const StepsTextTheme = (props: StepsTextThemeProps) => {

    const {
        text
    } = props;

    const theme = useTheme();
    const isSmAndAbove = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            {!isSmAndAbove &&
                null
            }

            {isSmAndAbove &&
                <Box sx={{pb: 1}}>
                    <b>{text}</b>
                </Box>
            }
        </>
    );
}