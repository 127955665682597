export default {
    labelSavedApplicationReference: "Ruj Aplikasi yang Disimpan:",
    labelLanguage: "Bahasa",
    messageWarningBookingHeading1: "Tempahan Dibatalkan:",
    messageWarningBookingHeading2: "Tempahan Ditunda / Dijadualkan Semula:",
    messageWarningBookingBody1: "Sekiranya tempahan anda telah dibatalkan oleh pihak penganjur atau syarikat penerbangan, pasukan ini tidak akan dapat membantu anda dengan bayaran balik. Anda perlu menghubungi pasukan khidmat pelanggan anda secara langsung; lihat pengesahan tempahan atau laman web anda untuk maklumat hubungan.",
    messageWarningBookingBody2: "Sekiranya tempahan anda ditangguhkan, syarat pembayaran balik anda yang dilanjutkan akan dibawa ke tarikh baru. Sekiranya anda mempunyai alasan pengembalian wang yang sah mengikut syarat kami, sila lengkapkan borang dengan bukti sokongan yang betul.",
    messageWarningBookingBody3: "Sila ambil perhatian: Perkhidmatan ini hanya boleh memberikan bayaran balik atas sebab yang disertakan dalam kata-kata kami, tersedia dalam e-mel pengesahan tempahan anda.",
    messageWarningBookingBody4: "Jika anda tidak boleh membuat tempahan atas sebab di luar syarat kami, kami tidak akan dapat membantu. Anda perlu menghubungi terus pasukan perkhidmatan pelanggan anda kerana mereka mungkin boleh membantu; lihat pengesahan tempahan atau tapak web anda untuk butiran hubungan.",
    linkWarningBooking: "di sini",
    formSectionNavigatorStep1: "Langkah 1",
    formSectionNavigatorStep2: "Langkah 2",
    formSectionNavigatorStep3: "Langkah 3",
    formSectionNavigatorStep1Text: "Hubungi dan Butiran Tempahan",
    formSectionNavigatorStep2Text: "Sebab Bayaran Balik",
    formSectionNavigatorStep3Text: "Butiran Pembayaran",
    messageBookingMatchedAndFound: "Jika rujukan tempahan anda dikaitkan dengan aplikasi sedia ada, kami akan menghantar e-mel kepada anda yang mengandungi pautan untuk mengakses aplikasi anda",
    messageBookingMatchedAndFoundCompleted: "Permohonan bayaran balik anda telah diluluskan! Sekiranya anda mempunyai masalah dengan pembayaran anda, sila hubungi:",
    applicationDeclinedMessage: "Permohonan anda telah ditolak dan kini ditutup.",
    messageBookingNotFound1: "Kami tidak menemui tempahan dengan rujukan tersebut. Sila periksa e-mel pengesahan tempahan anda dan masukkan semula.",
    messageBookingNotFound2: "Jangan risau, jika rujukan tempahan anda tidak berfungsi, anda boleh memilih 'Cari Pemesanan' dan masukkan maklumat anda. Semasa mengisi borang sila muat naik salinan e-mel pengesahan tempahan anda untuk membantu kami mencari maklumat tempahan anda.",
    messageLocatingBooking: "Mencari tempahan anda ...",
    messageLoadingBank: "Memuat maklumat bank ... ",
    messageFailCurrencyLookup: "Ralat semasa mendapatkan mata wang",
    messageSavingApplication: "Menyimpan aplikasi anda ...",
    messageSavedApplication: "Permohonan Bayaran Balik berjaya disimpan",
    messageFailSavedApplication: "Permohonan Bayaran Balik gagal disimpan",
    messageSendingEmailReminder: "Memproses...",
    messageErrorLocationDetails: "Ralat semasa mendapatkan maklumat lokasi",
    messageFailFormSubmission: "Penghantaran Borang Gagal",
    messageApplicationCannotBeFound: "Permohonan tidak dijumpai",
    messageApplicationRetrievalError: "Ralat semasa mengambil aplikasi",
    messageSubmittingApplication: "Mengemukakan permohonan ...",
    messageEventDateLimitError: "Sebab pemberitahuan di luar had masa",
    messageAllTicketsAssigned: "Semua tiket telah diberikan kepada permohonan bayaran balik yang lain.",
    selectPrefix: "Pilih",
    selectPostfix: "",
    fileUpload: "klik di sini untuk memuat naik",
    fileUploadAcceptedFilesMessage: "Kami hanya menerima PDF dan format gambar .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 Fail Dipilih",
    fileUploadPreviouslyUploaded: "Dimuat naik sebelum ini:",
    fileUploadSelectedUploaded: "Yang dipilih telah dimuat naik:",
    buttonNext: "Seterusnya",
    buttonPrevious: "Sebelumnya",
    buttonSave: "Jimat",
    buttonCancel: "Batal",
    buttonSubmit: "Hantar",
    buttonConfirm: "Sahkan",
    buttonYes: "Ya",
    buttonNo: "Tidak",
    buttonClose: "Tutup",
    linkPrivacyStatement: "Kenyataan privasi",
    linkComplaintsPolicy: "Dasar Aduan",
    validationRequired: "Dikehendaki",
    validationInvalid: "Tidak Sah",
    labelUploadPrefix: "Muat naik",
    labelUploadPostfix: "",
    labelChange: "Menukar",
    labelView: "Lihat",
    browserNavigationMessage: "Perubahan yang tidak disimpan akan hilang"
};