export default {
    messageUnableToMakePaymentToCurrency: "Lamentablemente, no podemos recuperar los datos bancarios de la moneda seleccionada. Asegúrese de que la moneda y el país de origen estén seleccionados en",
    messageOnlyBankAccountsAccepted: "Tenga en cuenta: solo podemos realizar pagos a cuentas bancarias en este momento (no se aceptan tarjetas de crédito y débito)",
    labelAccountName: "Nombre del titular de la cuenta",
    labelBankAccountName: "Nombre completo del titular de la cuenta",
    labelBankCardHolderName: "Nombre del titular de la tarjeta",
    validationBankAccountName: "Se requiere el nombre completo del titular de la cuenta",
    labelBankCountry: "País del banco",
    labelBankName: "Nombre del banco",
    labelPaymentType: "Tipo de pago",
    labelInstitutionNumber: "Número de institución",
    labelBankAddress: "Dirección del banco",
    labelBankAddress2: "Dirección del banco",
    labelBankCity: "Ciudad del banco",
    labelBankState: "Estado del banco",
    labelBankAccountNumber: "Número de cuenta bancaria",
    labelBankAccountNumberCBU: "Número de cuenta CBU",
    labelBankAccountNumberCCI: "Número de cuenta CCI",
    labelBankAccountNumberZAR: "Número de cuenta bancaria (7-11 dígitos)",
    labelCardNumber: "Número de tarjeta",
    labelUnionpay: "Número de tarjeta UnionPay",
    labelAlipay: "ID de Alipay (número de teléfono móvil o correo electrónico)",
    labelBankAccountType: "Tipo de cuenta",
    optionBankAccountTypeCURRENT: "Cuenta actual",
    optionBankAccountTypeCHECKING: "Cuenta de cheques",
    optionBankAccountTypeSAVING: "Cuenta de ahorros",
    optionBankAccountTypeSAVINGS: "Cuenta de ahorros",
    optionBankAccountTypeCUENTA_VISTA: "Cuenta de maestra",
    optionBankAccountType1: "Cuenta de cheques",
    optionBankAccountType2: "Cuenta de ahorros",
    optionBankAccountType3: "Cuenta de maestra",
    optionBankAccountType4: "Cuenta Vista",
    optionBankAccountType5: "Cuenta RUT",
    labelSortCode: "Código de clasificación",
    labelRoutingCode: "Código de enrutamiento",
    labelBankRoutingCodeNationalID: "Código de ruta bancaria / ID nacional",
    labelBankTransitCodeSwift: "Número de Tránsito",
    labelCNAPSCode: "Código de ruta / Código CNAPS (Código del sistema nacional de pago avanzado de China)",
    labelABAFedwireRoutingCode: "Número de ruta ACH",
    labelIFSCCode: "Código IFSC",
    labelBankCode: "Codigo bancario",
    labelBIKCode: "Código BIK",
    labelBranchCode: "Código de sucursal",
    labelBranchCodeUniversal: "Código de sucursal universal de 6 dígitos",
    labelRemittanceLine3: "Nombre de contacto del beneficiario y número de teléfono",
    labelBeneficiaryContactNameandTelephoneNumber: "Nombre de contacto y número de teléfono del beneficiario",
    labelBeneficiaryDateofBirtandPlaceofBirth: "Fecha de nacimiento y lugar de nacimiento del beneficiario",
    labelRemittanceLine4: "Número fiscal individual CUIL (9-12 dígitos)",
    labelCUILNumber: "Número de identificación fiscal: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Identificación fiscal RFC 13 dígitos o CURP 18 dígitos",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "CIF individual CPF Cadastro de Pessoa Física (11-14 dígitos), código de sucursal",
    labelCFPNumber: "Número CPF (11-14 dígitos)",
    labelBranchNameorBranchCode7digits: "Nombre de sucursal o código de sucursal (7 dígitos)",
    labelCedulaJuridica910digitsTaxID: "Cedula Juridica (número de identificación fiscal de 9 a 10 dígitos)",
    labelTaxIDRUT89digits: "Identificación fiscal RUT (8-9 dígitos)",
    labelJob: "Título profesional",
    labelDateOfBirth: "Fecha de nacimiento",
    labelNationality: "Nacionalidad",
    labelDocumentType: "Tipo de Documento",
    labelDocumentNumber: "Número del Documento",
    labelRutNumber: "Número de RUT (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cédula (CIF Individual 8-11 dígitos)",
    labelCIIndividualTaxIDBranchLocation: "CI (número de identificación fiscal individual), ubicación de la sucursal",
    labelTaxIDRUCorCI: "Número de identificación fiscal (RUC o CI)",
    labelSortCodeHelp: "¿Ayudar?",
    labelBranchCodeHelp: "¿Ayudar?",
    labelIBAN: "IBAN",
    labelIBANHelp: "¿Ayudar?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "¿Ayudar?",
    labelRoutingCodeBSB: "Código de enrutamiento / BSB",
    labelBSB: "Sucursal del banco estatal (BSB)",
    labelSKNCode: "Código SKN",
    labelAcceptanceFlights: "Acepto/aceptamos que si la solicitud de reembolso tiene éxito, la reserva, incluidos todos los vuelos y los productos complementarios, se cancelará.",
    labelAcceptanceTickets: "Acepto/aceptamos que si la solicitud de reembolso tiene éxito, se cancelará la reserva, incluidas todas las entradas y los productos complementarios.",
    labelTermsAndConditions1: "He / hemos aceptado el",
    labelTermsAndConditions2: "Términos y condiciones",
    labelFraudWarning1: "Creo que los datos proporcionados en este formulario de solicitud de reembolso son genuinos y verdaderos. Entiendo que cualquier información o evidencia falsa se puede considerar un acto criminal y se puede pasar a la policía u otras agencias de aplicación de la ley para que la persigan.",
    labelFraudWarning2: "Cuando sospechemos fraude en relación con la documentación de respaldo, este asunto se remitirá de inmediato a las autoridades policiales locales. Acepto y entiendo que mis datos se pasarán a estas autoridades si mi solicitud se considera fraudulenta y eso puede convertirse en una investigación criminal.",
    validationTermsAndConditions: "Los términos y condiciones deben ser acordados",
    messageFailbankName: "El nombre del banco no puede estar en blanco",
    messageFailcustomerName: "El nombre del cliente no puede estar en blanco",
    messageFailbankAccountNumber: "Campo obligatorio",
    messageFailbankAccountNumberFormat: "Debe ser formato IBAN",
    messageFailsortCode: "Campo obligatorio",
    messageFailbankSwiftBic: "El SWIFT BIC del beneficiario no puede estar en blanco",
    messageFailbankSwiftBicFormat: "Debe ser formato SWIFT",
    messageFailbeneficiaryAddress1: "La dirección 1 no puede estar en blanco",
    messageFailbeneficiaryCity: "La ciudad no puede estar en blanco",
    messageFailbeneficiaryPostCode: "El código postal no puede estar en blanco",
    messageFailremittanceLine3: "Campo obligatorio",
    messageFailremittanceLine4: "Campo obligatorio",
    modalHeading: "Confirmar datos bancarios",
    modalLabelPleaseConfirmYourDetails: "Compruebe sus datos bancarios y confirme",
    modalValidationPleaseConfirm: "Confirme",
    modalValidationMustMatch: "Debe coincidir con la entrada anterior",
    tooltipBankAccountName: "Su nombre tal como aparece en su cuenta bancaria / extractos bancarios / tarjeta bancaria.",
    tooltipBankAccountNameRecipient: "El nombre y apellido del destinatario. Tanto el nombre como el apellido deben tener más de un carácter.",
    tooltipBankCountry: "El país en el que se encuentra su banco.",
    tooltipBankName: "El nombre de su banco.",
    tooltipPaymentType: "The type of payment you require.",
    tooltipInstitutionNumber: "Estos son 3 dígitos y generalmente se encuentran en su estado de cuenta mensual o tarjeta bancaria, o si aún no está seguro, pregunte a su banco.",
    tooltipBankAccountNumber: "Su número de cuenta individual. Por lo general, se encuentra en su estado de cuenta mensual o tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipBankAccountNumberCBU: "Un número de 22 dígitos que se utiliza para identificar su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipBankAccountNumberCCI: "Un número de 20 dígitos que se utiliza para identificar su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipBankAccountNumberKRW: "Un número de 11 a 16 dígitos que se utiliza para identificar su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o en su tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipCardNumber: "Su número de tarjeta. Por lo general, se encuentra en su estado de cuenta mensual o tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipUnionpay: "Su número de tarjeta UnionPay.",
    tooltipAlipay: "Su número de teléfono móvil o dirección de correo electrónico asociado con su cuenta de Alipay.",
    tooltipBankAccountType: "Esto es cuenta corriente o ahorros.",
    tooltipSortCode: "Esto identifica la sucursal de su banco donde se encuentra su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o en su tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipRoutingCode: "Esto identifica la sucursal de su banco donde se encuentra su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o en su tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipBeneficiarySWIFTBIC: "Consta de 8-11 caracteres que se utilizan para identificar un banco en una transacción internacional. Por lo general, se encuentra en su estado de cuenta mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBSB: "Un número de sucursal bancaria estatal (BSB) es un código de clasificación australiano que tiene 6 dígitos. Por lo general, se encuentra en su estado de cuenta mensual o, si aún no está seguro, consulte con su banco.",
    tooltipRoutingCodeBSB: "Esto identifica la sucursal de su banco donde se encuentra su cuenta. Por lo general, se encuentra en su estado de cuenta mensual o en su tarjeta bancaria o, si aún no está seguro, consulte con su banco.",
    tooltipABAFedwireRoutingCode: "Un número de 9 dígitos que se utiliza para identificar a los bancos en Estados Unidos. Por lo general, se encuentra en su estado de cuenta mensual o, si aún no está seguro, consulte con su banco.",
    tooltipIFSCCode: "Por lo general, este código se puede encontrar en su estado de cuenta mensual o en su libreta de ahorros o, si aún no está seguro, consulte con su banco.",
    tooltipTermsAndConditions: "Al aceptar los términos y condiciones, confirma que ha leído y está de acuerdo con nuestros Términos y condiciones.",
    tooltipIBAN: "Su número de cuenta bancaria internacional, que puede utilizar al realizar o recibir pagos internacionales. Por lo general, se encuentra en su estado de cuenta mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Nombre de contacto del beneficiario y número de teléfono.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Fecha de nacimiento del beneficiario y lugar de nacimiento.",
    tooltipBankRoutingCodeNationalID: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankTransitCodeSwift: "Tiene 5 dígitos y, por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankAddress: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankAddress2: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankCity: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankState: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBranchName: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBankCode: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBIKCode: "9 dígitos para identificar su banco.",
    tooltipBranchCode: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBranchCodeUniversal: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipCNAPSCode: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipRemittanceLine4: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipCUILNumber: "Este es un número de 11 dígitos, que consta de dos dígitos, un guión, nueve dígitos y una suma de verificación de un dígito.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipCFPNumber: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipBranchNameorBranchCode7digits: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipCedulaJuridica910digitsTaxID: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipTaxIDRUT89digits: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipJob: "Tu ocupación actual.",
    tooltipDateOfBirth: "Tu fecha de nacimiento.",
    tooltipNationality: "Tu nacionalidad.",
    tooltipDocumentType: "El tipo de documento requerido.",
    tooltipDocumentNumber: "Este es el número de documento asociado con su selección de tipo de documento.",
    tooltipRutNumber: "Tu número de RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipCIIndividualTaxIDBranchLocation: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    tooltipTaxIDRUCorCI: "Por lo general, se puede encontrar en su extracto bancario mensual o, si aún no está seguro, consulte con su banco.",
    errorCPFCode: "Por el momento sólo aceptamos CPF como clave PIX."
};