import * as React from 'react'
import {
    Box,
    TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    Field,
    FieldProps,
    FormikProps
} from 'formik';
import {
    BorderRadiusInputField,
    BorderRadiusSmall,
    MinWidthInputField
} from '../utils/constants-styling';
import {useState} from 'react';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import {nameof} from 'ts-simple-nameof';
import {isInvalid} from '../utils/validationHelpers';
import {useRecoilValue} from 'recoil';
import {loadingShimState} from '../../state/activity';
import {BookingTermsModal} from '../modals/BookingTermsModal';
import {BookingMatchedAndFoundAlert} from '../alerts/BookingMatchedAndFoundAlert';
import {BookingNotFoundAlert} from '../alerts/BookingNotFoundAlert';
import {ApplicationDeclinedAlert} from '../alerts/ApplicationDeclinedAlert';
import {AllTicketsAssignedAlert} from '../alerts/AllTicketsAssignedAlert';
import {SearchButton} from '../buttons/SearchButton';
import {ReminderButton} from '../buttons/ReminderButton';

export interface HomeFormProps {
    formProps: FormikProps<IFormValues>;
    memberId: string | null;
    url: string;
}

export const HomeSection = (props: HomeFormProps) => {

    const {
        formProps,
        memberId,
        url
    } = props;

    const {
        allTicketsAssigned,
        applicationEmail,
        bookingCounter,
        fetchBookingData,
        onApplicationEmailChange,
        sendEmailReminder
    } = useFormContext();

    const {t} = useTranslation();
    const loadingState = useRecoilValue(loadingShimState);

    const [textInputValue, setTextInputValue] = useState(null);

    const handleOnBlur = (fieldProps: FieldProps<IFormValues>, controlId: string) => (event: React.FocusEvent<HTMLInputElement>) => {
        void fieldProps.form.setFieldValue(controlId, event.target.value);
        void fieldProps.form.setTouched({...fieldProps.form.touched, [controlId]: true}, false);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputValue(event.target.value);
    }

    const handleClick = () => {
        void fetchBookingData(formProps);
    }

    return (
        <>
            {bookingCounter <= 1 &&
                <>
                    <BookingMatchedAndFoundAlert formProps={formProps}/>

                    <BookingNotFoundAlert/>

                    <ApplicationDeclinedAlert/>

                    <AllTicketsAssignedAlert/>

                    <Grid container columnSpacing={2} justifyContent='center'
                          sx={{
                              pt: {
                                  xs: 0,
                                  sm: 4,
                                  md: 4
                              }
                          }}>
                        <Grid size={{md: 4}} pb={2}>
                            <Box sx={{
                                border: '1px solid lightgrey',
                                borderRadius: BorderRadiusSmall,
                                backgroundColor: '#FBFBFB',
                                p: 3
                            }}>
                                <Box pb={3}>
                                    <h5>{t('form:labelNewRefundHeading')}</h5>
                                </Box>
                                <Box><b>{t('form:labelNewRefundText')}</b></Box>

                                <Field name={nameof<IFormValues>(x => x.bookingReference)}>
                                    {(fieldProps: FieldProps<IFormValues>) => (
                                        <Box pt={1} pb={2}>
                                            <TextField
                                                variant='outlined'
                                                size='small'
                                                name={nameof<IFormValues>(x => x.bookingReference)}
                                                placeholder={t('form:placeholderBookingReference')}
                                                value={textInputValue ?? fieldProps.form.getFieldProps(nameof<IFormValues>(x => x.bookingReference)).value}
                                                error={isInvalid(fieldProps)}
                                                fullWidth={true}
                                                onChange={handleOnChange}
                                                onBlur={handleOnBlur(fieldProps, nameof<IFormValues>(x => x.bookingReference))}
                                                helperText={fieldProps.meta.touched ? fieldProps.meta.error : ''}
                                                sx={{
                                                    'fieldset': {
                                                        borderRadius: BorderRadiusInputField,
                                                        minWidth: MinWidthInputField
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Field>

                                <Grid container justifyContent='flex-end'>
                                    <SearchButton
                                        buttonText={t('form:buttonFindBooking')}
                                        disabled={allTicketsAssigned || loadingState.open}
                                        handleOnClick={handleClick}
                                    />
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid size={{md: 4}}>
                            <Box sx={{
                                border: '1px solid lightgrey',
                                borderRadius: BorderRadiusSmall,
                                p: 3
                            }}>
                                <Box pb={3}>
                                    <h5>{t('form:labelEmailHeading')}</h5>
                                </Box>
                                <Box><b>{t('form:labelEmailText')}</b></Box>

                                <Box pt={1} pb={2}>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        name='application-email-reminder'
                                        onChange={onApplicationEmailChange}
                                        value={applicationEmail}
                                        placeholder={t('form:placeholderEmail')}
                                        fullWidth={true}
                                        sx={{
                                            'fieldset': {
                                                borderRadius: BorderRadiusInputField,
                                                minWidth: MinWidthInputField
                                            }
                                        }}
                                    />
                                </Box>

                                <Grid container justifyContent='flex-end'>
                                    <ReminderButton
                                        buttonText={t('form:buttonEmailReminder')}
                                        disabled={!applicationEmail}
                                        handleOnClick={() => sendEmailReminder(applicationEmail, url)}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <BookingTermsModal/>

                </>
            }
        </>
    );
}
