export default {
    messageQuestionService: "Ця послуга доступна лише у випадку, якщо у вас виникли проблеми із заповненням форми заявки, ми не можемо відповісти на будь-які інші запитання тут.",
    labelName: "Ім'я",
    labelReference: "Код бронювання",
    labelPhone: "Телефонний номер",
    labelEmail: "Електронна пошта",
    labelQuery: "Твоє запитання",
    labelAskQuestion: "Надішліть нам питання сюди...",
    buttonOpenModal: "Питання",
    title: "Задайте питання",
    modalTitle: "4. Виникли технічні проблеми з формою?",
    toastSuccess: "Ми отримали ваше запитання і зв’яжемося з вами протягом 24 годин",
    toastError: "З вашим запитом сталася помилка. Повторіть спробу",
    nameValidation:"Укажіть ім’я",
    referenceValidation:"Довідка потрібна",
    questionValidation: "Ваше запитання є обов'язковим"
}