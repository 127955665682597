import {
    EventDateState,
    ProductCodeState
} from '../state/activity';
import {useRecoilValue} from 'recoil';
import {useGetCFARResponse} from './queries';

export default() => {
    const productCode = useRecoilValue(ProductCodeState);
    const eventDate = useRecoilValue(EventDateState);

    const {cfarResponse} = useGetCFARResponse(productCode, eventDate);

    const applicationIsValid = !cfarResponse.isCfar || cfarResponse.validEventDate;
    
    return {
        applicationIsValid,
        cfarResponse
    };
}