export default {
    messageUnableToMakePaymentToCurrency: "На жаль, ми не можемо отримати банківські реквізити для вибраної валюти. Будь ласка, переконайтеся, що вибрано валюту та країну проживання",
    messageOnlyBankAccountsAccepted: "Зверніть увагу: наразі ми можемо здійснювати платежі лише на банківські рахунки (кредитні та дебетові картки не приймаються)",
    labelAccountName: "Ім'я власника рахунку",
    labelBankAccountName: "Повне ім'я власника рахунку",
    labelBankCardHolderName: "Ім'я власника картки",
    validationBankAccountName: "Потрібне повне ім’я власника рахунку",
    labelBankCountry: "Країна банку",
    labelBankName: "Назва банку",
    labelPaymentType: "Тип оплати",
    labelInstitutionNumber: "Номер установи",
    labelBankAddress: "Адреса банку",
    labelBankAddress2: "Адреса банку",
    labelBankCity: "Банк Місто",
    labelBankState: "Держава банку",
    labelBankAccountNumber: "Номер рахунку в банку",
    labelBankAccountNumberCBU: "Номер рахунку ЦБУ",
    labelBankAccountNumberCCI: "Номер рахунку ТПП",
    labelBankAccountNumberZAR: "Номер банківського рахунку (7-11 цифр)",
    labelCardNumber: "Номер картки",
    labelUnionpay: "Номер картки UnionPay",
    labelAlipay: "Ідентифікатор Alipay (номер мобільного телефону або електронна адреса)",
    labelBankAccountType: "Тип рахунку",
    optionBankAccountTypeCURRENT: "Поточний рахунок",
    optionBankAccountTypeCHECKING: "Перевірка рахунку",
    optionBankAccountTypeSAVING: "Накопичувальний рахунок",
    optionBankAccountTypeSAVINGS: "Накопичувальний рахунок",
    optionBankAccountTypeCUENTA_VISTA: "Головний рахунок",
    optionBankAccountType1: "Перевірка рахунку",
    optionBankAccountType2: "Накопичувальний рахунок",
    optionBankAccountType3: "Головний рахунок",
    optionBankAccountType4: "Обліковий запис Vista",
    optionBankAccountType5: "Обліковий запис RUT",
    labelSortCode: "Код сортування",
    labelRoutingCode: "Код маршрутизації",
    labelBankRoutingCodeNationalID: "Маршрутний код банку / національний ідентифікатор",
    labelBankTransitCodeSwift: "Транзитний номер",
    labelCNAPSCode: "Код маршрутизації/код CNAPS (код китайської національної передової платіжної системи)",
    labelABAFedwireRoutingCode: "Номер маршрутизації ACH",
    labelIFSCCode: "Код IFSC",
    labelBankCode: "Код банку",
    labelBIKCode: "Код BIK",
    labelBranchCode: "Код філії",
    labelBranchCodeUniversal: "Універсальний 6-значний код філії",
    labelRemittanceLine3: "Контактне ім'я та номер телефону бенефіціара",
    labelBeneficiaryContactNameandTelephoneNumber: "Контактне ім'я та номер телефону бенефіціара",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Бенефіціар Дата народження та місце народження",
    labelRemittanceLine4: "Індивідуальний податковий номер CUIL (9-12 цифр)",
    labelCUILNumber: "Податковий номер: CUIL / COOKED",
    labelTaxIDRFC13DigitsORCURP18digits: "Ідентифікатор податків RFC 13 цифр або CURP 18 цифр",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Індивідуальний податковий номер CPF Реєстрація фізичної особи платника податків (11-14 цифр), код філії",
    labelCFPNumber: "Номер CPF (11-14 цифр)",
    labelBranchNameorBranchCode7digits: "Назва філії або код філії (7 цифр)",
    labelCedulaJuridica910digitsTaxID: "Юридична довідка (9-10 цифр податкового номера)",
    labelTaxIDRUT89digits: "Податковий номер RUT (8-9 цифр)",
    labelJob: "Назва посади",
    labelDateOfBirth: "Дата народження",
    labelNationality: "Національність",
    labelDocumentType: "Тип документа",
    labelDocumentNumber: "Номер документа",
    labelRutNumber: "Номер RUT (унікальна податкова роль)",
    labelCedulaTaxIDIndividual811digits: "Cedula (індивідуальний податковий номер 8-11 цифр)",
    labelCIIndividualTaxIDBranchLocation: "CI (індивідуальний податковий номер), місцезнаходження філії",
    labelTaxIDRUCorCI: "Ідентифікатор податку (RUC або CI)",
    labelSortCodeHelp: "Допоможіть?",
    labelBranchCodeHelp: "Допоможіть?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Допоможіть?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Допоможіть?",
    labelRoutingCodeBSB: "Код маршрутизації/BSB",
    labelBSB: "Державне відділення банку (BSB)",
    labelSKNCode: "Код SKN",
    labelAcceptanceFlights: "Я/ми приймаю, що якщо заявка на відшкодування буде успішною, бронювання, включаючи всі рейси та додаткові продукти, буде скасовано.",
    labelAcceptanceTickets: "Я/ми приймаю, що якщо заявку на відшкодування буде успішно виконано, бронювання, включаючи всі квитки та додаткові продукти, буде скасовано.",
    labelTermsAndConditions1: "Я/ми погодилися на",
    labelTermsAndConditions2: "Правила та умови",
    labelFraudWarning1: "Я вважаю, що факти, наведені в цій формі заявки на відшкодування, є справжніми та правдивими. Я розумію, що будь-яка неправдива інформація чи докази можуть бути визнані кримінальним діянням і передані поліції чи іншим правоохоронним органам для переслідування.",
    labelFraudWarning2: "Якщо ми підозрюємо шахрайство щодо підтверджуючої документації, це питання буде негайно передано до місцевих органів поліції. Я погоджуюсь і розумію, що мої дані будуть передані цим органам, якщо моя заявка буде визнана шахрайською і це може стати кримінальним розслідуванням.",
    validationTermsAndConditions: "Положення та умови мають бути погоджені",
    messageFailbankName: "Назва банку не може бути пустою",
    messageFailcustomerName: "Ім’я клієнта не може бути порожнім",
    messageFailbankAccountNumber: "Обов'язкове поле",
    messageFailbankAccountNumberFormat: "Повинен бути формат IBAN",
    messageFailsortCode: "Обов'язкове поле",
    messageFailbankSwiftBic: "SWIFT BIC для бенефіціара не може бути пустим",
    messageFailbankSwiftBicFormat: "Має бути формат SWIFT",
    messageFailbeneficiaryAddress1: "Адреса 1 не може бути пустою",
    messageFailbeneficiaryCity: "Місто не може бути порожнім",
    messageFailbeneficiaryPostCode: "Поштовий індекс не може бути пустим",
    messageFailremittanceLine3: "Обов'язкове поле",
    messageFailremittanceLine4: "Обов'язкове поле",
    modalHeading: "Підтвердьте деталі",
    modalLabelPleaseConfirmYourDetails: "Будь ласка, підтвердьте свої дані",
    modalValidationPleaseConfirm: "Будь-ласка підтвердіть",
    modalValidationMustMatch: "Повинен відповідати попередньому запису",
    tooltipBankAccountName: "Ваше ім’я, як воно вказано на вашому банківському рахунку/банківській виписці/банківській картці.",
    tooltipBankAccountNameRecipient: "Ім’я та прізвище одержувача. І ім’я, і прізвище мають містити більше одного символу.",
    tooltipBankCountry: "Країна, в якій розташований ваш банк.",
    tooltipBankName: "Назва вашого банку.",
    tooltipPaymentType: "Потрібний вам вид оплати.",
    tooltipInstitutionNumber: "Це 3 цифри, які зазвичай містяться у вашій щомісячній виписці чи банківській картці. Якщо ви все ще не впевнені, запитайте у банку.",
    tooltipBankAccountNumber: "Ваш індивідуальний номер рахунку. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankAccountNumberCBU: "22-значний номер, який використовується для ідентифікації вашого облікового запису. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankAccountNumberCCI: "20-значний номер, який використовується для ідентифікації вашого облікового запису. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankAccountNumberKRW: "11–16-значний номер, який використовується для ідентифікації вашого облікового запису. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCardNumber: "Номер вашої картки. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipUnionpay: "Номер вашої картки UnionPay.",
    tooltipAlipay: "Ваш номер мобільного телефону або адреса електронної пошти, пов’язані з вашим обліковим записом Alipay.",
    tooltipBankAccountType: "Це або перевірка, або економія.",
    tooltipSortCode: "Це визначає філію вашого банку, де розташовано ваш рахунок. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipRoutingCode: "Це визначає філію вашого банку, де розташовано ваш рахунок. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBeneficiarySWIFTBIC: "Складається з 8-11 символів, які використовуються для ідентифікації банку в міжнародній транзакції. Зазвичай він міститься у вашій щомісячній виписці або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBSB: "Номер відділення банку (BSB) — це австралійський код сортування, який складається з 6 цифр. Зазвичай він міститься у вашій щомісячній виписці або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipRoutingCodeBSB: "Це визначає філію вашого банку, де розташовано ваш рахунок. Зазвичай він міститься у вашій щомісячній виписці або банківській картці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipABAFedwireRoutingCode: "9-значний номер, який використовується для ідентифікації банків в Америці. Зазвичай він міститься у вашій щомісячній виписці або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipIFSCCode: "Зазвичай цей код можна знайти у вашій щомісячній виписці або у вашій книжці, або якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipTermsAndConditions: "Приймаючи умови використання, ви підтверджуєте, що прочитали та погоджуєтеся з нашими Загальними положеннями та умовами.",
    tooltipIBAN: "Номер вашого міжнародного банківського рахунку, який ви можете використовувати під час здійснення або отримання міжнародних платежів. Зазвичай він міститься у вашій щомісячній виписці або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Контактне ім'я та номер телефону бенефіціара.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Бенефіціар Дата народження та місце народження.",
    tooltipBankRoutingCodeNationalID: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankTransitCodeSwift: "Це 5 цифр і зазвичай їх можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankAddress: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankAddress2: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankCity: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankState: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBranchName: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBankCode: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBIKCode: "9 цифр для ідентифікації вашого банку.",
    tooltipBranchCode: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBranchCodeUniversal: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCNAPSCode: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipRemittanceLine4: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCUILNumber: "Це 11-значне число, яке складається з двох цифр, дефісу, дев'яти цифр і однозначної контрольної суми.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCFPNumber: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipBranchNameorBranchCode7digits: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCedulaJuridica910digitsTaxID: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipTaxIDRUT89digits: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipJob: "Ваша поточна професія.",
    tooltipDateOfBirth: "Ваша дата народження.",
    tooltipNationality: "Ваша національність.",
    tooltipDocumentType: "Потрібний тип документа.",
    tooltipDocumentNumber: "Це номер документа, пов’язаний з вибором типу документа.",
    tooltipRutNumber: "Ваш номер RUT (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipCIIndividualTaxIDBranchLocation: "Зазвичай можна знайти у вашій щомісячній виписці з банківського рахунку або, якщо ви все ще не впевнені, запитайте свій банк.",
    tooltipTaxIDRUCorCI: "Зазвичай можна знайти у вашій щомісячній виписці з банку або, якщо ви все ще не впевнені, запитайте свій банк.",
    errorCPFCode: "На даний момент ми приймаємо лише CPF як ключ PIX."
};