import * as React from 'react';
import {
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import {IProductItem} from '../Interfaces/IProductItem';
import {ProductModalCheckItem} from './ProductModalCheckItem';
import * as getSymbolFromCurrency from 'currency-symbol-map';
import {useTranslation} from 'react-i18next';

interface ProductModalBodyContentProps {
    checkedState: boolean[];
    disableTicketChange: boolean;
    handleOnChange: (position: number) => void;
    handleToggleAllOnClick: () => void;
    productItems: IProductItem[];
}

export const ProductModalBodyContent = (props: ProductModalBodyContentProps) => {

    const {
        checkedState,
        disableTicketChange,
        handleOnChange,
        handleToggleAllOnClick,
        productItems
    } = props;

    const {t} = useTranslation();

    const showDescription = (productItems: IProductItem[]) => {
        return productItems.every(x => x.description);
    }

    const getCurrencySymbol = (currencyCode: string) => {
        const currencySymbol = getSymbolFromCurrency(currencyCode);
        return currencySymbol !== undefined ? currencySymbol : null
    }

    return (
        <>
            {!disableTicketChange &&
                <span>{t('productModal:labelRequiredTickets')}</span>
            }

            {disableTicketChange &&
                <span>{t('productModal:labelTicketsSubmitted')}</span>
            }

            <Box sx={{pt: '5px', pb: '10px'}}>
                <Box sx={{textAlign: 'center'}}>
                    <b>{t('productModal:labelTickets')}</b>
                    {productItems.every(x => !x.disabled) && !disableTicketChange &&
                        <Box sx={{textDecoration: 'underline', cursor: 'pointer', color: 'blue'}}
                             onClick={handleToggleAllOnClick}>
                            {checkedState.every(x => x === true) ?
                                `${t('productModal:linkUnselectAll')}` :
                                `${t('productModal:linkSelectAll')}`}
                        </Box>}
                </Box>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t('productModal:tableHeaderReference')}</b></TableCell>
                            {showDescription(productItems) &&
                                <TableCell><b>{t('productModal:tableHeaderDescription')}</b></TableCell>
                            }
                            <TableCell><b>{t('productModal:tableHeaderAmount')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productItems.map((productItem, index) => {
                            return (<TableRow key={productItem.productId}>
                                <TableCell>
                                    <ProductModalCheckItem
                                        key={productItem.productId}
                                        productItem={productItem}
                                        disabled={productItem.disabled}
                                        checkedState={checkedState}
                                        index={index}
                                        handleOnChange={handleOnChange}
                                        disableTicketChange={disableTicketChange}/>
                                </TableCell>
                                {showDescription(productItems) &&
                                    <TableCell>
                                        {productItem.description}
                                    </TableCell>
                                }
                                <TableCell>
                                    {getCurrencySymbol(productItem.currencyCode)}{productItem.totalValue} {`(${productItem.currencyCode})`}
                                </TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}