import * as React from "react";
import {Box} from '@mui/material';
import {BooleanInput} from "../formInputs";
import {nameof} from "ts-simple-nameof";
import {IFormValues} from "../Interfaces/IFormValues";
import {useFormContext} from "../providers/FormProvider";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useField} from "formik";
import {useGetMemberTermsUrl} from "../utils/helpers";
import {CloseButton} from '../buttons/CloseButton';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {ModalHolder} from './ModalHolder';

export interface TermsAndConditionsModalProps {
    memberId: number;
}

export const TermsAndConditionsModal = (props: TermsAndConditionsModalProps) => {

    const {
        memberId
    } = props;

    const {
        isFlightHidden,
        validCurrency
    } = useFormContext();

    const {t} = useTranslation();
    const memberTermsUrl = useGetMemberTermsUrl(memberId);

    const [hasAgreedTermsAndConditionsField, hasAgreedTermsAndConditionsMeta, hasAgreedTermsAndConditionsHelper]
        = useField(nameof<IFormValues>(x => x.hasAgreedTermsAndConditions));
    const [showFraudModal, setShowFraudModal] = useState<boolean>(false);

    const showModal = (event: any) => {
        if (event.target.checked) {
            setShowFraudModal(true);
        }
    }

    const handler = (unselectTerms: boolean) => {
        if (unselectTerms) {
            void hasAgreedTermsAndConditionsHelper.setValue(false);
        }
        setShowFraudModal(false);
    }

    const closeModalCancel = () => {
        handler(true);
    }

    const closeModalConfirm = () => {
        handler(false);
    }

    const termsAndConditionsLabel = () => {
        if (isFlightHidden) {
            return `${t('payment:labelAcceptanceTickets')} ${t('payment:labelTermsAndConditions1')}`;
        }
        return `${t('payment:labelAcceptanceFlights')} ${t('payment:labelTermsAndConditions1')}`;
    }

    const Content = () => {
        return (<>
                <Box pb={2}>
                    {t("payment:labelFraudWarning1")}
                    <hr/>
                    {t("payment:labelFraudWarning2")}
                </Box>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonCancel')}
                        handleOnClick={closeModalCancel}
                    />

                    <ConfirmButton
                        isSubmitButton={false}
                        buttonText={t('common:buttonConfirm')}
                        handleOnClick={closeModalConfirm}
                    />
                </Box>
            </>
        );
    }

    return (
        <>
            <Box sx={{pt: 2}}>
                <BooleanInput
                    key={nameof<IFormValues>(x => x.hasAgreedTermsAndConditions)}
                    label={termsAndConditionsLabel()}
                    controlId={nameof<IFormValues>(x => x.hasAgreedTermsAndConditions)}
                    hyperlink={memberTermsUrl}
                    hyperlinkText={t('payment:labelTermsAndConditions2')}
                    hidden={!validCurrency}
                    tooltip={t('payment:tooltipTermsAndConditions')}
                    onClick={showModal}
                />
            </Box>

            <ModalHolder
                showModal={showFraudModal}
                content={<Content/>}
                handleClose={closeModalCancel}
                width='sm'
            />
        </>
    );
}