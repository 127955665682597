import * as React from 'react';
import {
    Button,
    ThemeProvider
} from '@mui/material';
import {buttonTheme} from '../themes/ButtonThemeProvider';

interface ConfirmButtonProps {
    buttonText: string;
    disabled?: boolean;
    handleOnClick?: () => void;
    isSubmitButton: boolean;
}

export const ConfirmButton = (props: ConfirmButtonProps) => {

    const {
        buttonText,
        disabled,
        handleOnClick,
        isSubmitButton
    } = props;

    return (
        <ThemeProvider theme={buttonTheme}>
            <Button
                type={!isSubmitButton ? 'button' : 'submit'}
                sx={{ml: 2}}
                disabled={disabled}
                onClick={handleOnClick}>
                {buttonText}
            </Button>
        </ThemeProvider>
    );
}