export default {
    headingAdverseWeather: "Du opplever alvorlige ugunstige værforhold som gjør det utrygt å reise til arrangementet, og dette har blitt pålagt av et myndighetsorgan (f.eks. lokale myndigheter, politi osv.). Og du har bevis for å bevise dette?",
    headingAdverseWeatherDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    tooltipRefundReasonConfirm: "Ja eller nei",
    labelBookingCancelledByOperator: "Billettene ble kansellert av operatøren/arrangøren.",
    labelBookingRescheduledByOperator: "Datoen ble endret av operatøren/arrangøren og jeg kan ikke delta på de nye datoene.",
    labelBookedWrongDate: "Jeg eller et medlem av bestillingen, gjorde en feil ved kjøp av billetter og bestilte feil dato.",
    labelBookedWrongTickets: "Jeg eller et medlem av bestillingen, gjorde en feil ved kjøp av billetter og bestilte feil billetter.",
    labelBookedTooManyTickets: "Jeg eller et medlem av bestillingen, gjorde en feil ved kjøp av billetter og bestilte for mange billetter.",
    labelCOVID19TravelRestrictions: "Jeg eller et medlem av bestillingen, kan ikke delta på grunn av et COVID-19 reiseforbud.",
    labelADWrongSelection: "Du kan ha valgt feil årsak for refusjon. Se gjennom rullegardinlisten Refusjonsårsak på nytt.",
    labelAdverseWeather1: "Hvorfor gjorde dårlig vær at du ikke kunne delta på arrangementet?",
    tooltipAdverseWeather1: "Vennligst detaljer",
    labelAdverseWeather2: "Dato for hendelse",
    tooltipAdverseWeather2: "Velg",
    headingArmedForces: "Du blir bedt av arbeidsgiveren din, som er Forsvaret eller nødetatene, om å komme på jobb når du ikke opprinnelig var planlagt å gjøre det, og dette hindrer deg i å delta på arrangementet?",
    headingArmedForcesDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelArmedForces1: "Hvilke krefter eller nødetater er du ansatt i?",
    tooltipArmedForces1: "Velg",
    labelArmedForcesOther1: "Vennligst spesifiser",
    tooltipArmedForcesOther1: "Vennligst spesifiser",
    labelArmedForces2: "Dato for hendelse",
    tooltipArmedForces2: "Velg",
    headingChangesToExam: "Datoene for eksamen din har blitt endret av skolen / høyskolen din, etc? Dette er ikke en ikke bestått eksamen som du må ta på nytt?",
    headingChangesToExamDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelExam1: "Hva var den opprinnelige undersøkelsesdatoen?",
    tooltipExam1: "Velg",
    labelExam2: "Hva er den nye eksamensdatoen?",
    tooltipExam2: "Velg",
    labelExam3: "Hvorfor ble eksamensdatoen endret?",
    tooltipExam3: "Vennligst skriv inn detaljer",
    labelExam4: "Strøk du på forrige eksamen?",
    tooltipExam4: "Ja eller nei",
    headingCourtSummons: "– Du er innkalt til å møte som vitne i retten på de samme datoene som hendelsen?",
    headingCourtSummonsDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelCourtSummons1: "Hva er bakgrunnen for din rettsinnkalling?",
    tooltipCourtSummons1: "Velg",
    labelCourtSummonsOther1: "Vennligst spesifiser",
    tooltipCourtSummonsOther1: "Vennligst spesifiser",
    labelCourtSummons2: "Når ble rettsinnkallingen utstedt?",
    tooltipCourtSummons2: "Velg",
    headingCOVID19Test: "Jeg, et medlem av min husstand eller et medlem av gruppen som deltar på arrangementet har blitt diagnostisert med COVID-19?",
    headingCOVID19TestDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelCOVID19Test1: "Hvem har covid-19?",
    tooltipCOVID19Test1: "Velg",
    labelCOVID19TestOther1a: "Vennligst spesifiser",
    labelCOVID19TestOther1b: "Angi navn",
    tooltipCOVID19TestOther1a: "Vennligst spesifiser",
    tooltipCOVID19TestOther1b: "Angi navn",
    labelCOVID19Test2: "Hva slags COVID-19-test hadde du?",
    tooltipCOVID19Test2: "Velg",
    labelCOVID19TestOther2: "Vennligst spesifiser",
    tooltipCOVID19TestOther2: "Vennligst spesifiser",
    labelCOVID19Test3: "Hva var datoen for COVID-19-testen din?",
    tooltipCOVID19Test3: "Velg",
    headingDeath: "Det har vært et dødsfall av et nært medlem av familien min, og det hindrer meg i å delta på arrangementet?",
    headingDeathDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelDeath1: "Hvilket medlem av familien din er død?",
    tooltipDeath1: "Velg",
    labelDeathOther1: "Vennligst spesifiser",
    tooltipDeathOther1: "Vennligst spesifiser",
    labelDeath2: "Når var dødsdatoen?",
    tooltipDeath2: "Velg",
    labelDeath3: "Navn på avdøde",
    tooltipDeath3: "Vennligst spesifiser",
    headingEmergency: "Jeg har hatt en ekte nødsituasjon som er utenfor min kontroll og uforutsett som jeg kan gi bevis for?",
    headingEmergencyDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelEmergency1: "Vennligst forklar hvilken type nødsituasjon?",
    tooltipEmergency1: "Vennligst detaljer",
    labelEmergency2: "Når skjedde dette?",
    tooltipEmergency2: "Velg",
    labelEmergency3: "Hvorfor hindrer denne situasjonen deg i å delta på arrangementet?",
    tooltipEmergency3: "Vennligst detaljer",
    labelEmergency4: "Har du bevis for denne nødsituasjonen?",
    tooltipEmergency4: "Ja eller nei",
    headingHomeEmergency: "Jeg har et hjem nødsituasjon som er ran, flom, brann, hærverk, vold i hjemmet som hindrer meg fra å delta på arrangementet?",
    headingHomeEmergencyDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelHomeEmergency1: "Hva er hjemmenødsituasjonen?",
    tooltipHomeEmergency1: "Velg",
    labelHomeEmergencyOther1: "Vennligst spesifiser",
    tooltipHomeEmergencyOther1: "Vennligst spesifiser",
    labelHomeEmergency2: "Når skjedde dette?",
    tooltipHomeEmergency2: "Velg",
    labelHomeEmergency3: "Hvorfor stoppet det deg fra å delta på arrangementet?",
    tooltipHomeEmergency3: "Vennligst detaljer",
    headingInjury: "Jeg, et medlem av min husstand eller et medlem av gruppen som deltar på arrangementet har blitt diagnostisert med en medisinsk tilstand eller skade som nå hindrer oss i å gå til arrangementet?",
    headingInjuryDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelInjury1: "Hva er arten av sykdommen/skaden/ulykken?",
    tooltipInjury1: "Vennligst detaljer",
    labelInjury2: "Når skjedde dette?",
    tooltipInjury2: "Velg",
    labelInjury3: "Har du deltatt på et medisinsk anlegg for denne sykdommen / skaden?",
    tooltipInjury3: "Ja eller nei",
    labelInjury4: "Var dette en eksisterende medisinsk tilstand?",
    tooltipInjury4: "Ja eller nei",
    labelInjury5: "Når ble dette først diagnostisert?",
    tooltipInjury5: "Velg",
    labelInjury6: "Hvorfor betyr dette at du ikke kan delta på arrangementet?",
    tooltipInjury6: "Vennligst detaljer",
    labelInjury7: "Hvem har lidd sykdommen/skaden?",
    tooltipInjury7: "Velg",
    headingJuryService: "Jeg eller et medlem av bookingen har blitt kalt til jurytjeneste?",
    headingJuryServiceDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelJuryService1: "Når fikk du beskjed om jurytjenesten?",
    tooltipJuryService1: "Velg",
    headingMechanicalBreakdown: "Kjøretøyet vårt har hatt et mekanisk sammenbrudd eller vært i en ulykke, og dette hindrer oss i å delta på arrangementet?",
    headingMechanicalBreakdownDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelMechanicalBreakdown1: "Hva skjedde med kjøretøyet?",
    tooltipMechanicalBreakdown1: "Velg",
    labelMechanicalBreakdown2: "Når skjedde dette?",
    tooltipMechanicalBreakdown2: "Velg",
    labelMechanicalBreakdown3: "Hvorfor kunne dere ikke ta alternativ transport?",
    tooltipMechanicalBreakdown3: "Vennligst detaljer",
    headingPregnancyComplication: "Jeg eller et medlem av bookingen har graviditetskomplikasjoner som har blitt diagnostisert av en medisinsk fagperson og derfor hindrer deltakelse på arrangementet?",
    headingPregnancyComplicationDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelPregnancyComplication1: "Hva er arten av graviditetskomplikasjonen?",
    tooltipPregnancyComplication1: "Vennligst detaljer",
    labelPregnancyComplication2: "Når skjedde dette?",
    tooltipPregnancyComplication2: "Velg",
    labelPregnancyComplication3: "Oppsøkte du lege?",
    tooltipPregnancyComplication3: "Ja eller nei",
    labelPregnancyComplication4: "Hvorfor betyr dette at du ikke kan delta på arrangementet?",
    tooltipPregnancyComplication4: "Vennligst detaljer",
    headingPublicTransportFailure: "Metoden for offentlig transport (tog, buss, trikk, båt, etc) mislyktes uventet, noe som betyr at det ikke var noen annen måte jeg kunne reise til dette arrangementet?",
    headingPublicTransportFailureDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelPublicTransportFailure1: "Hvilken type offentlig transport planla du å bruke?",
    tooltipPublicTransportFailure1: "Velg",
    labelPublicTransportFailure2: "Når planla du å reise?",
    tooltipPublicTransportFailure2: "Velg",
    labelPublicTransportFailure3: "Hva har skjedd?",
    tooltipPublicTransportFailure3: "Vennligst detaljer",
    labelPublicTransportFailure4: "Hvorfor kunne dere ikke ta alternativ transport?",
    tooltipPublicTransportFailure4: "Vennligst detaljer",
    headingScheduledAirlineFailure: "Mitt forhåndsbestilte fly kansellerte uventet, og jeg klarte ikke å finne et passende alternativ, noe som betyr at jeg ikke kunne delta på arrangementet?",
    headingScheduledAirlineFailureDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelScheduledAirlineFailure1: "Navn på flyselskap",
    tooltipScheduledAirlineFailure1: "Navn på flyselskap",
    labelScheduledAirlineFailure2: "Flight nummer",
    tooltipScheduledAirlineFailure2: "Flight nummer",
    labelScheduledAirlineFailure3: "Reiser fra",
    tooltipScheduledAirlineFailure3: "Reiser fra",
    labelScheduledAirlineFailure4: "Reiser til",
    tooltipScheduledAirlineFailure4: "Reiser til",
    labelScheduledAirlineFailure5: "Dato for hendelsen?",
    tooltipScheduledAirlineFailure5: "Velg",
    labelScheduledAirlineFailure6: "Hvorfor kunne du ikke ta et alternativt fly?",
    tooltipScheduledAirlineFailure6: "Vennligst detaljer",
    headingTheftOfDocuments: "Dokumentene mine ble stjålet, og det var ikke mulig å ordne erstatning (er) innen tidsrammen som trengs, slik at jeg ikke kan delta på arrangementet",
    headingTheftOfDocumentsDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelTheftOfDocuments1: "Hvilken type dokument ble stjålet?",
    tooltipTheftOfDocuments1: "Velg",
    labelTheftOfDocuments2: "Når skjedde dette?",
    tooltipTheftOfDocuments2: "Velg",
    labelTheftOfDocuments3: "Forsøkte du å få dokumentene utstedt på nytt?",
    tooltipTheftOfDocuments3: "Ja eller nei",
    headingWorkRelocation: "Min arbeidsgiver har bedt meg om å endre plasseringen av arbeidet mitt i minst tre måneder, og avstanden er betydelig, noe som betyr at jeg nå ikke kan delta på arrangementet?",
    headingWorkRelocationDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelWorkRelocation1: "Er dette en midlertidig eller permanent flytting av arbeidet ditt?",
    tooltipWorkRelocation1: "Velg",
    labelWorkRelocation2: "Hvor lenge vil du være på den nye adressen?",
    tooltipWorkRelocation2: "Vennligst detaljer",
    labelWorkRelocation3: "Hva er din nåværende arbeidsadresse?",
    tooltipWorkRelocation3: "Vennligst detaljer",
    labelWorkRelocation4: "Hva er din nye jobbadresse?",
    tooltipWorkRelocation4: "Vennligst detaljer",
    labelWorkRelocation5: "Når fikk dere beskjed om denne flyttingen?",
    tooltipWorkRelocation5: "Velg",
    headingRedundancy: "Jeg eller et medlem av bestillingen har blitt overflødig av arbeidsgiveren deres, noe som betyr at jeg ikke lenger kan delta på bestillingen?",
    headingRedundancyDecline: "Du bør ikke fortsette med denne søknaden, da refusjonen mest sannsynlig vil bli avvist.",
    labelRedundancy1: "Type arbeidsforhold?",
    tooltipRedundancy1: "Velg",
    labelRedundancy2: "Hvor lenge har du vært ansatt i dette selskapet?",
    tooltipRedundancy2: "Velg",
    labelRedundancy3: "Var oppsigelsen frivillig?",
    tooltipRedundancy3: "Velg",
    labelRedundancy4: "Når ble du varslet om oppsigelsen?",
    tooltipRedundancy4: "Velg",
    labelTestOption: "Velg Ja for å fortsette",
}