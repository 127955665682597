import * as React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import StarIcon from '@mui/icons-material/Star';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {IFeedbackStatus} from '../Interfaces/IFeedbackStatus';

interface ConfirmationStarControlProps {
    isAutoApproved: boolean;
    negativeFeedbackModalHandler: (feedbackStatus: string) => void;
    positiveFeedbackHandlerSubmit: (reviewSelected: boolean, isAutoApproved: boolean, feedbackStatus: string) => void;
    reviewSelected: boolean;
}

export const ConfirmationStarControl = (props: ConfirmationStarControlProps) => {

    const {
        isAutoApproved,
        negativeFeedbackModalHandler,
        positiveFeedbackHandlerSubmit,
        reviewSelected
    } = props;

    const {t} = useTranslation();

    const feedbackStatus: IFeedbackStatus = {
        feedbackBad: t('confirmation:feedbackBad'),
        feedbackPoor: t('confirmation:feedbackPoor'),
        feedbackAverage: t('confirmation:feedbackAverage'),
        feedbackGreat: t('confirmation:feedbackGreat'),
        feedbackExcellent: t('confirmation:feedbackExcellent')
    };

    return (
        <>
            <Grid container justifyContent='center' textAlign='center' pt={4}>
                <Grid size={{xs: 12, sm: 8, md: 5}} container justifyContent='center' textAlign='center'>
                    <Grid sx={{color: 'grey'}} size={{xs: 2, sm: 2, md: 2}}>
                        <StarIcon
                            fontSize='large'
                            onClick={() =>
                                negativeFeedbackModalHandler(nameof<IFeedbackStatus>(x => x.feedbackBad))}
                        />
                        <Box>{feedbackStatus.feedbackBad}</Box>
                    </Grid>
                    <Grid sx={{color: 'grey'}} size={{xs: 2, sm: 2, md: 2}}>
                        <StarIcon
                            fontSize='large'
                            onClick={() =>
                                negativeFeedbackModalHandler(nameof<IFeedbackStatus>(x => x.feedbackPoor))}
                        />
                        <Box>{feedbackStatus.feedbackPoor}</Box>
                    </Grid>
                    <Grid sx={{color: 'grey'}} size={{xs: 2, sm: 2, md: 2}}>
                        <StarIcon
                            fontSize='large'
                            onClick={() =>
                                negativeFeedbackModalHandler(nameof<IFeedbackStatus>(x => x.feedbackAverage))}
                        />
                        <Box>{feedbackStatus.feedbackAverage}</Box>
                    </Grid>
                    <Grid sx={{color: 'grey'}} size={{xs: 2, sm: 2, md: 2}}>
                        <StarIcon
                            fontSize='large'
                            onClick={() =>
                                positiveFeedbackHandlerSubmit(
                                    reviewSelected,
                                    isAutoApproved,
                                    nameof<IFeedbackStatus>(x => x.feedbackGreat))}
                        />
                        <Box>{feedbackStatus.feedbackGreat}</Box>
                    </Grid>
                    <Grid sx={{color: 'grey'}} size={{xs: 2, sm: 2, md: 2}}>
                        <StarIcon
                            fontSize='large'
                            onClick={() =>
                                positiveFeedbackHandlerSubmit(
                                    reviewSelected,
                                    isAutoApproved,
                                    nameof<IFeedbackStatus>(x => x.feedbackExcellent))}
                        />
                        <Box>{feedbackStatus.feedbackExcellent}</Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}