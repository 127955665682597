import * as React from 'react';
import Dialog, {
    DialogProps
} from '@mui/material/Dialog';
import {
    Box,
    SelectChangeEvent
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {
    useEffect,
    useState
} from 'react';
import {
    DateInput,
    SelectInput,
    TextInput
} from "../formInputs";
import {useTranslation} from "react-i18next";
import {nameof} from "ts-simple-nameof";
import {FormikProps} from "formik";
import {useFormContext} from "../providers/FormProvider";
import {IFormValues} from "../Interfaces/IFormValues";
import {RefundStatus} from "../enums/refundStatus";
import {EventType} from '../enums/eventType';
import {CloseButton} from '../buttons/CloseButton';
import {SearchButton} from '../buttons/SearchButton';

export interface CustomerNameSearchModalProps {
    formProps: FormikProps<IFormValues>;
}

export const CustomerNameSearchModal = (props: CustomerNameSearchModalProps) => {

    const {
        formProps
    } = props;

    const {
        bookingCounter,
        bookingMatched,
        cancel,
        dateOfEventLabel,
        dateOfEventTooltip,
        fetchBookingBasedOnCustomerName,
        showBookingTypeFields
    } = useFormContext();

    const {t} = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [maxWidth, _] = React.useState<DialogProps['maxWidth']>('lg');

    const closeModal = () => {
        setShowModal(false);
    }

    const closeModalAndCancel = () => {
        setShowModal(false);
        cancel(formProps);
    }

    useEffect(() => {
        if (bookingMatched) {
            return;
        }

        if (bookingCounter === 2 && formProps.values.status === RefundStatus.None) {
            setShowModal(true);
        }
    }, [bookingCounter, formProps.values.status]);

    const bookingReferenceSearch = async () => {
        try {
            const isSuccess = await fetchBookingBasedOnCustomerName(formProps);
            if (isSuccess) {
                closeModal();
            }
        } catch {
            closeModal();
        }
    }

    const bookingTypeHandler = (event: SelectChangeEvent) => {
        showBookingTypeFields(+event.target.value);
    }

    const Content = () => {

        return (<>
                <Box pb={1}>
                    <h4>{t('findBookingModal:headerFindBooking')}</h4>

                    <Box sx={{pb: 2}}>{t('findBookingModal:labelBookingReferenceNotFound')}</Box>

                    <TextInput
                        key={nameof<IFormValues>(x => x.customerName)}
                        placeholder={t('findBookingModal:labelNameOfBookingPerson')}
                        label={t('findBookingModal:labelNameOfBookingPerson')}
                        controlId={nameof<IFormValues>(x => x.customerName)}
                        hidden={false}
                        tooltip={t('findBookingModal:tooltipNameOfBookingPerson')}
                        customErrorMessage={t('findBookingModal:validationNameOfBookingPerson')}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.typeOfBooking)}
                        label={t('form:labelTypeOfBooking')}
                        controlId={nameof<IFormValues>(x => x.typeOfBooking)}
                        hidden={false}
                        prefixKey={'form:optionTypeOfBooking'}
                        datasourceUrl='/api/referencedata/eventTypes'
                        onChange={bookingTypeHandler}
                        tooltip={t('form:tooltipTypeOfBooking')}
                        required
                    />

                    <DateInput
                        key={nameof<IFormValues>(x => x.dateOfEvent)}
                        label={dateOfEventLabel}
                        controlId={nameof<IFormValues>(x => x.dateOfEvent)}
                        hidden={formProps.values.typeOfBooking === EventType.None}
                        disabled={bookingMatched}
                        tooltip={dateOfEventTooltip}
                        required
                    />
                </Box>
            </>
        );
    }

    return (
        <Dialog
            open={showModal}
            disableEnforceFocus
            scroll='body'
            maxWidth={maxWidth}
            fullWidth
        >
            <DialogContent>
                <Content/>
                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonClose')}
                        handleOnClick={closeModalAndCancel}
                    />

                    <Box component='span' sx={{ml: 2}}>
                        <SearchButton
                            buttonText={t('form:buttonFindBooking')}
                            handleOnClick={bookingReferenceSearch}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}