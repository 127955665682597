export default {
    dividerBankingDetails: "Τραπεζικά Στοιχεία",
    dividerBookingDetails: "Στοιχεία κράτησης",
    labelSavedApplicationReference: "Αποθηκευμένη Αναφ. Εφαρμογής:",
    labelLanguage: "Γλώσσα",
    messageWarningBookingHeading1: "Η κράτηση ακυρώθηκε:",
    messageWarningBookingHeading2: "Η κράτηση αναβλήθηκε / επαναπρογραμματίστηκε:",
    messageWarningBookingBody1: "Εάν η κράτησή σας έχει ακυρωθεί από τον διοργανωτή ή την αεροπορική εταιρεία, αυτή η ομάδα δεν θα μπορεί να σας βοηθήσει με την επιστροφή χρημάτων. Θα χρειαστεί να επικοινωνήσετε απευθείας με την ομάδα εξυπηρέτησης πελατών. δείτε την επιβεβαίωση κράτησης ή τον ιστότοπό σας για στοιχεία επικοινωνίας.",
    messageWarningBookingBody2: "Εάν η κράτησή σας έχει αναβληθεί, οι εκτεταμένοι όροι με δυνατότητα επιστροφής χρημάτων θα μεταφερθούν στη νέα ημερομηνία. Εάν έχετε βάσιμο λόγο για επιστροφή χρημάτων σύμφωνα με τους όρους μας, συμπληρώστε τη φόρμα με τα σωστά αποδεικτικά στοιχεία.",
    messageWarningBookingBody3: "Σημείωση: Αυτή η υπηρεσία μπορεί να παρέχει επιστροφές χρημάτων μόνο για τους λόγους που περιλαμβάνονται στη διατύπωσή μας, που διατίθεται στο email επιβεβαίωσης της κράτησής σας.",
    messageWarningBookingBody4: "Εάν δεν μπορείτε να κάνετε την κράτηση για λόγους εκτός των όρων μας, δεν θα μπορέσουμε να σας βοηθήσουμε. Θα χρειαστεί να επικοινωνήσετε απευθείας με την ομάδα εξυπηρέτησης πελατών, καθώς μπορεί να είναι σε θέση να σας βοηθήσει. δείτε την επιβεβαίωση κράτησης ή τον ιστότοπό σας για στοιχεία επικοινωνίας.",
    linkWarningBooking: "εδώ",
    formSectionNavigatorStep1: "Βήμα 1",
    formSectionNavigatorStep2: "Βήμα 2",
    formSectionNavigatorStep3: "Βήμα 3",
    formSectionNavigatorStep1Text: "Στοιχεία επικοινωνίας και κράτησης",
    formSectionNavigatorStep2Text: "Λόγος επιστροφής χρημάτων",
    formSectionNavigatorStep3Text: "Στοιχεία πληρωμής",
    messageBookingMatchedAndFound: "Εάν η αναφορά κράτησής σας σχετίζεται με μια υπάρχουσα εφαρμογή, θα σας στείλουμε ένα email που θα περιέχει έναν σύνδεσμο για πρόσβαση στην αίτησή σας",
    messageBookingMatchedAndFoundCompleted: "Η αίτηση επιστροφής χρημάτων σας έχει ήδη εγκριθεί! Εάν αντιμετωπίζετε προβλήματα με την πληρωμή σας, επικοινωνήστε με:",
    applicationDeclinedMessage: "Η αίτησή σας έχει απορριφθεί και είναι πλέον κλειστή.",
    messageBookingNotFound1: "Δεν μπορούμε να βρούμε κράτηση με αυτήν την αναφορά. Ελέγξτε το email επιβεβαίωσης της κράτησής σας και καταχωρήστε ξανά.",
    messageBookingNotFound2: "Μην ανησυχείτε, εάν η αναφορά κράτησής σας δεν λειτουργεί, μπορείτε να επιλέξετε «Εύρεση κράτησης» και να εισαγάγετε τα στοιχεία σας. Κατά τη συμπλήρωση της φόρμας, μεταφορτώστε ένα αντίγραφο του email επιβεβαίωσης της κράτησής σας για να μας βοηθήσετε να εντοπίσουμε τα στοιχεία της κράτησής σας.",
    messageLocatingBooking: "Εντοπισμός της κράτησής σας...",
    messageLoadingBank: "Φόρτωση τραπεζικών στοιχείων...",
    messageFailCurrencyLookup: "Σφάλμα κατά την ανάκτηση νομίσματος",
    messageSavingApplication: "Αποθήκευση της αίτησής σας...",
    messageSavedApplication: "Η εφαρμογή επιστροφής χρημάτων αποθηκεύτηκε με επιτυχία",
    messageFailSavedApplication: "Η αποθήκευση της εφαρμογής επιστροφής χρημάτων απέτυχε",
    messageSendingEmailReminder: "Επεξεργασία...",
    messageErrorLocationDetails: "Σφάλμα κατά την ανάκτηση των λεπτομερειών τοποθεσίας",
    messageFailFormSubmission: "Η υποβολή της φόρμας απέτυχε",
    messageApplicationCannotBeFound: "Δεν ήταν δυνατή η εύρεση της εφαρμογής",
    messageApplicationRetrievalError: "Σφάλμα κατά την ανάκτηση της εφαρμογής",
    messageSubmittingApplication: "Υποβολή αίτησης...",
    messageEventDateLimitError: "Λόγος ειδοποίησης εκτός προθεσμίας",
    messageAllTicketsAssigned: "Όλα τα εισιτήρια έχουν αντιστοιχιστεί σε άλλη αίτηση επιστροφής χρημάτων.",
    selectPrefix: "Επιλέγω",
    selectPostfix: "",
    fileUpload: "κάντε κλικ εδώ για να ανεβάσετε",
    fileUploadAcceptedFilesMessage: "Δεχόμαστε μόνο PDF και μορφές εικόνας .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 Επιλέχθηκαν αρχεία",
    fileUploadPreviouslyUploaded: "Ανέβηκε προηγουμένως:",
    fileUploadSelectedUploaded: "Τα επιλεγμένα έχουν ήδη μεταφορτωθεί:",
    buttonNext: "Επόμενο",
    buttonPrevious: "Προηγούμενος",
    buttonSave: "Αποθηκεύσετε",
    buttonCancel: "Ματαίωση",
    buttonSubmit: "υποβάλλουν",
    buttonConfirm: "Επιβεβαιώνω",
    buttonYes: "Ναί",
    buttonNo: "Οχι",
    buttonClose: "Κλείσε",
    linkPrivacyStatement: "Δήλωση προστασίας προσωπικών δεδομένων",
    linkComplaintsPolicy: "Πολιτική παραπόνων",
    validationRequired: "Απαιτείται",
    validationInvalid: "Μη έγκυρο",
    labelUploadPrefix: "Μεταφόρτωση",
    labelUploadPostfix: "",
    labelChange: "Αλλαγή",
    labelView: "Άποψη",
    browserNavigationMessage: "Οι μη αποθηκευμένες αλλαγές θα χαθούν"
};