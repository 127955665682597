export default {
    nonAutoApprovedLabelThankYou: "Bedankt voor uw terugbetalingsaanvraag.",
    nonAutoApprovedLabelReview: "Ons team zal uw aanvraag binnen de komende twee werkdagen beoordelen en contact met u opnemen met een reactie.",
    nonAutoApprovedLabelExperience: "Hoe hebben we het gedaan?",
    nonAutoApprovedLabelExperienceIntroduction: "We stellen het erg op prijs dat u de tijd neemt om ons te beoordelen en een beoordeling achter te laten, omdat we de mening van onze klanten waarderen.",
    feedbackExcellent: "Uitstekend",
    feedbackGreat: "Groot",
    feedbackAverage: "Gemiddeld",
    feedbackPoor: "Arm",
    feedbackBad: "Slecht",
    labelThankYouForFeedback: "Bedankt voor uw feedback.",
    autoApprovedMessage1: "We zijn verheugd om",
    autoApprovedMessage2: "Goedkeuren",
    autoApprovedMessage3: "uw terugbetaling!",
    autoApprovedPayment1Day: "Uw betaling is al verwerkt en zou binnen 1 werkdag op uw rekening moeten staan.",
    autoApprovedPayment2to4Days: "Uw betaling is al verwerkt en zou binnen 2-4 werkdagen op uw rekening moeten staan.",
    autoApprovedPayment7to10Days: "Uw betaling is al verwerkt en zou binnen 7-10 werkdagen op uw rekening moeten staan.",
    autoApprovedLabelExperience: "Hoe hebben we het gedaan?",
    autoApprovedLabelExperienceIntroduction: "We stellen het erg op prijs dat u de tijd neemt om ons te beoordelen en een beoordeling achter te laten, omdat we de mening van onze klanten waarderen.",
    modalLabelNegative: "Uw feedback is erg belangrijk voor ons, laat het ons weten als u ook wilt dat wij contact met u opnemen.",
    placeholderFeedback: "Terugkoppeling",
    messageFeedbackSubmitted: "Feedback ingediend",
    reviewInstructionMessage: "5 sterren is uitstekend, 4 sterren is geweldig, 3 sterren is gemiddeld, 2 sterren is slecht, 1 ster is slecht."
}