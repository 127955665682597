export default {
    dividerBankingDetails: "Bankacılık Detayları",
    dividerBookingDetails: "Rezervasyon Detayları",
    labelSavedApplicationReference: "Kaydedilmiş Uygulama Referansı:",
    labelLanguage: "Dil",
    messageWarningBookingHeading1: "Rezervasyon İptal Edildi:",
    messageWarningBookingHeading2: "Rezervasyon Ertelendi / Yeniden Planlandı:",
    messageWarningBookingBody1: "Rezervasyonunuz organizatör veya havayolu tarafından iptal edildiyse, bu ekip size para iadesi konusunda yardımcı olamayacaktır. Doğrudan müşteri hizmetleri ekibinizle iletişime geçmeniz gerekecektir; iletişim bilgileri için rezervasyon onayınıza veya web sitenize bakın.",
    messageWarningBookingBody2: "Rezervasyonunuz ertelendiyse, uzatılmış iade edilebilir koşullarınız yeni tarihe taşınacaktır. Koşullarımıza göre geri ödeme için geçerli bir nedeniniz varsa, lütfen formu doğru destekleyici kanıtlarla doldurun.",
    messageWarningBookingBody3: "Lütfen dikkat: Bu hizmet, yalnızca rezervasyon onayı e-postanızda bulunan ifademizde yer alan nedenlerle geri ödeme sağlayabilir.",
    messageWarningBookingBody4: "Şartlarımız dışındaki sebeplerden dolayı rezervasyonu yapamazsanız size yardımcı olamayız. Yardımcı olabilecekleri için doğrudan müşteri hizmetleri ekibinizle iletişime geçmeniz gerekecektir; iletişim bilgileri için rezervasyon onayınıza veya web sitesine bakın.",
    linkWarningBooking: "buraya",
    formSectionNavigatorStep1: "Adım 1",
    formSectionNavigatorStep2: "Adım 2",
    formSectionNavigatorStep3: "Adım 3",
    formSectionNavigatorStep1Text: "İletişim ve Rezervasyon Detayları",
    formSectionNavigatorStep2Text: "İade Nedeni",
    formSectionNavigatorStep3Text: "Ödeme Detayları",
    messageBookingMatchedAndFound: "Rezervasyon referansınız mevcut bir başvuruyla ilişkiliyse size başvurunuza erişmenizi sağlayacak bağlantıyı içeren bir e-posta göndereceğiz",
    messageBookingMatchedAndFoundCompleted: "Geri ödeme başvurunuz zaten onaylandı! Ödemenizle ilgili herhangi bir sorun yaşarsanız lütfen iletişime geçin:",
    applicationDeclinedMessage: "Başvurunuz reddedildi ve şu anda kapalı.",
    messageBookingNotFound1: "Bu referans numarasıyla bir bilet bulamıyoruz. Lütfen onay e-postanızı kontrol edin ve tekrar girin.",
    messageBookingNotFound2: "Bilet referans numaranız bulunamadıysa endişelenmeyin, “Biletimi Bul” butonuyla bilgilerinizi  başvuru formuna girebilirsiniz. Formu doldururken, bilet detaylarınızı bulmamıza yardımcı olması için lütfen biletinizin onay e-postasının ekran görüntüsünü yükleyin.",
    messageLocatingBooking: "Rezervasyonunuzun yeri ...",
    messageLoadingBank: "Banka bilgileri yükleniyor ...",
    messageFailCurrencyLookup: "Para birimi alınırken hata oluştu",
    messageSavingApplication: "Uygulamanız kaydediliyor ...",
    messageSavedApplication: "Geri ödeme isteği başarıyla kaydedildi",
    messageFailSavedApplication: "Geri ödeme isteği kaydedilemedi",
    messageSendingEmailReminder: "İşleme...",
    messageErrorLocationDetails: "Konum ayrıntıları alınırken hata oluştu",
    messageFailFormSubmission: "Form gönderimi başarısız oldu",
    messageApplicationCannotBeFound: "Uygulama bulunamadı",
    messageApplicationRetrievalError: "Uygulama alınırken hata oluştu",
    messageSubmittingApplication: "Başvuru gönderiliyor...",
    messageEventDateLimitError: "Zaman sınırı dışında bildirim nedeni",
    messageAllTicketsAssigned: "Tüm biletler başka bir iade uygulamasına atanmıştır.",
    selectPrefix: "",
    selectPostfix: "Seç",
    fileUpload: "indirmek için burayı tıklayın",
    fileUploadAcceptedFilesMessage: "Yalnızca .png, .jpeg, .heic, .webp PDF'lerini ve resim biçimlerini kabul ediyoruz.",
    fileUploadNoFiles: "0 dosya seçildi",
    fileUploadPreviouslyUploaded: "Önceden yüklenmiş:",
    fileUploadSelectedUploaded: "Seçilenler zaten yüklendi:",
    buttonNext: "Sonraki",
    buttonPrevious: "Önceki",
    buttonSave: "Kaydet",
    buttonCancel: "İptal",
    buttonSubmit: "Gönder",
    buttonConfirm: "Onayla",
    buttonYes: "Evet",
    buttonNo: "Hayır",
    buttonClose: "Kapat",
    linkPrivacyStatement: "Gizlilik Bildirimi",
    linkComplaintsPolicy: "Şikayet Politikası",
    validationRequired: "Gereklidir",
    validationInvalid: "Geçersiz",
    labelUploadPrefix: "",
    labelUploadPostfix: "Yükleyin",
    labelChange: "Değiştir",
    labelView: "Görünüm",
    browserNavigationMessage: "Kaydedilmemiş değişiklikler kaybolacak"
};