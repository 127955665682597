import * as React from 'react';
import {
    Badge,
    Button,
    Box
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
    Field,
    FieldProps,
    FormikProps
} from 'formik';
import {
    useState,
    useEffect
} from 'react';
import {
    RecoilState,
    useRecoilValue,
    useSetRecoilState
} from 'recoil';
import {
    BorderRadiusInputField,
    BorderRadiusSmall
} from '../utils/constants-styling';
import {IFormValues} from '../Interfaces/IFormValues';
import {RefundFile} from '../Interfaces/IRefundFile';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from '../ui/ErrorMessage';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {DuplicateFilesModal} from '../modals/DuplicateFilesModal';

interface FileImportProps {
    clearFiles: boolean;
    controlId: string;
    formProps: FormikProps<IFormValues>;
    hidden: boolean;
    label: string;
    previouslyUploaded: string[];
    required?: boolean;
    setClearFiles: (clear: boolean) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    state: RecoilState<File[]>;
    tooltip?: string;
}

export const FileInput = (props: FileImportProps) => {

    const {
        clearFiles,
        controlId,
        formProps,
        hidden,
        label,
        previouslyUploaded,
        required,
        setClearFiles,
        setFieldValue,
        state,
        tooltip
    } = props;

    const {t} = useTranslation();

    const acceptedFiles = '.png,.jpeg,.jpg,.heic,.pdf,.webp';

    const getBase64 = async (file: Blob): Promise<string | undefined> => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result as any);
            reader.onerror = (error) => reject(error);
        });
    }

    const uploadFilesState = useRecoilValue(state);
    const setUploadFilesState = useSetRecoilState(state);
    const [duplicateFiles, setDuplicateFiles] = useState<string[]>([]);
    const [showValidationModal, setShowValidationModal] = useState<boolean>(false);

    useEffect(() => {
        if (clearFiles) {
            setUploadFilesState([]);
            setClearFiles(false);
        }
    }, [clearFiles])

    useEffect(() => {
        if (duplicateFiles.length > 0) {
            setShowValidationModal(true);
        }
    }, [duplicateFiles]);

    const {getRootProps, getInputProps} = useDropzone({

        onDrop: async acceptedFiles => {

            const filesToProc = [...uploadFilesState, ...acceptedFiles];
            const currentFileNames = uploadFilesState.map(x => x.name);
            const acceptedFileNames = acceptedFiles.map(x => x.name);
            const duplicateFileNames = currentFileNames.filter(x => acceptedFileNames.includes(x));
            const previousDuplicateFileNames = previouslyUploaded.filter(x => acceptedFileNames.includes(x));

            if (duplicateFileNames.length > 0 || previousDuplicateFileNames.length > 0) {
                setDuplicateFiles(duplicateFileNames.concat(previousDuplicateFileNames));
                return;
            }

            setUploadFilesState(filesToProc);
            updateFormState(filesToProc);
        },
        accept: {
            'image/jpeg': ['.heic', '.jpg', '.jpeg', '.png', '.webp'],
            'application/pdf': ['.pdf']
        }
    });

    const removeFile = (file: File, fileIndex: number) => () => {
        const updatedFiles = [...uploadFilesState.slice(0, fileIndex), ...uploadFilesState.slice(fileIndex + 1)];
        setUploadFilesState(updatedFiles);
        updateFormState(updatedFiles);
    }

    const files = uploadFilesState.map((file, index) => (
        <Badge key={file.name}
               sx={{
                   pl: '10px',
                   ml: '5px',
                   mb: '5px',
                   backgroundColor: 'grey',
                   color: 'white',
                   borderRadius: BorderRadiusSmall
               }}>
            {file.name}
            <Button
                size='small'
                sx={{
                    color: '#71D9FE',
                    pl: '10px',
                    pt: '2px',
                    pb: 0,
                    pr: '10px'
                }}
                onClick={removeFile(file, index)}>Remove
            </Button>
        </Badge>
    ));

    const oldFiles = previouslyUploaded.map(fileName => (
        <Badge
            key={fileName}
            sx={{
                pl: '10px',
                pr: '10px',
                ml: '5px',
                mb: '5px',
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: BorderRadiusSmall
            }}>{fileName}
        </Badge>
    ));

    const {...rootProps} = getRootProps({className: 'dropzone'});

    const closeValidationModal = () => {
        setShowValidationModal(false);
    }

    function updateFormState(files: File[]) {

        const refundFiles: RefundFile[] = [];

        files.map((x: File) => {
            const fileName = x.name;
            return getBase64(x).then(x => refundFiles.push({base64Content: x, fileName: fileName}));
        });

        validate(files);
        setFieldValue(controlId, refundFiles);
    }

    function validate(files: File[]) {

        formProps.validateField(controlId);

        if (files?.length > 0) {
            void formProps.setFieldTouched(controlId, false, true);
            return;
        }

        void formProps.setFieldTouched(controlId);
    }

    return (<>
            {!hidden &&
                <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
                    <FormLabelHolder
                        label={label}
                        required={required}
                        tooltip={tooltip}
                    />
                    <Grid container size={{xs: 6, md: 5}}>
                        <Box sx={{
                            border: '1px dashed #A8A8A8',
                            borderWidth: '2px',
                            borderRadius: BorderRadiusInputField,
                            width: '100%',
                            p: {
                                xs: '10px',
                                sm: '40px',
                                md: '40px'
                            }
                        }}>
                            <Box justifyContent='flex-start' display='flex'
                                 sx={{cursor: 'pointer'}}
                                 {...rootProps}>
                                <UploadFileIcon sx={{
                                    color: '#A8A8A8',
                                    width: 60,
                                    height: 60
                                }}/>
                                <Field name={controlId}>
                                    {(fieldProps: FieldProps<IFormValues>) => (
                                        <>
                                            <input type='file'
                                                   accept={acceptedFiles}
                                                   {...getInputProps()}
                                                   name={controlId}/>
                                            <Box sx={{
                                                pl: '5px',
                                                pr: '5px'
                                            }}>
                                                <ErrorMessage fieldProps={fieldProps}/>
                                            </Box>
                                        </>
                                    )}
                                </Field>
                                <Box>
                                    <em>{t('common:fileUpload')}<br/>{t('common:fileUploadAcceptedFilesMessage')}</em>
                                </Box>
                            </Box>
                            <Box sx={{pt: 1}}>
                                {files}
                                {oldFiles.length > 0 &&
                                    <Box>{t('common:fileUploadPreviouslyUploaded')} {oldFiles}</Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }

            <DuplicateFilesModal
                duplicateFiles={duplicateFiles}
                showValidationModal={showValidationModal}
                close={closeValidationModal}
            />

        </>
    );
}