import * as React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2'
import {FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {IFormValues} from '../Interfaces/IFormValues';
import {useFormContext} from '../providers/FormProvider';
import {ConfirmationFeedbackControl} from '../controls/ConfirmationFeedbackControl';
import {useRecoilValue} from 'recoil';
import {ShowReviewSectionState} from '../../state/activity';

interface ConfirmationSectionProps {
    formProps: FormikProps<IFormValues>;
}

export const ConfirmationSection = (props: ConfirmationSectionProps) => {

    const {
        formProps
    } = props;

    const {
        createApplicationResponse
    } = useFormContext();

    const {t} = useTranslation();
    const showReviewSectionState = useRecoilValue(ShowReviewSectionState);

    const iframe = `<iframe class='iframe-review-holder' src='${createApplicationResponse.trustpilotReviewUrl}' allowfullscreen='false'></iframe>`;

    function Iframe(props: any) {
        return (<div dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ''}}/>);
    }

    const AutoApprovedClearingDaysMessage = () => {

        switch (createApplicationResponse.clearingTimeDays) {
            case 0:
                return t('confirmation:autoApprovedPayment1Day');
            case 1:
                return t('confirmation:autoApprovedPayment2to4Days');
            default:
                return t('confirmation:autoApprovedPayment7to10Days');
        }
    }

    return (
        <>
            <Box sx={{
                pt: {
                    xs: 0,
                    sm: 4,
                    md: 4
                }
            }}>
                <Box hidden={!showReviewSectionState}><Iframe iframe={iframe}/></Box>

                {!createApplicationResponse.isAutoApproved &&
                    <>
                        <Grid container justifyContent='center' textAlign='center'>
                            <Grid size={{md: 6}} sx={{pb: '100px'}}>
                                <h3>{t('confirmation:nonAutoApprovedLabelThankYou')}</h3>
                                <h5>{t('confirmation:nonAutoApprovedLabelReview')}</h5>
                            </Grid>
                        </Grid>

                        <ConfirmationFeedbackControl
                            experienceText={t('confirmation:nonAutoApprovedLabelExperience')}
                            experienceIntroductionText={t('confirmation:nonAutoApprovedLabelExperienceIntroduction')}
                            formProps={formProps}
                            reviewSelected={false}
                            isAutoApproved={false}
                        />
                    </>
                }

                {createApplicationResponse.isAutoApproved && !showReviewSectionState &&
                    <>
                        <Grid container justifyContent='center' textAlign='center'>
                            <Grid size={{md: 6}} sx={{pb: '100px'}}>
                                <h4>
                                    {t('confirmation:autoApprovedMessage1')}
                                    <Box component='span' sx={{
                                        color: '#22B77E',
                                        pl: '5px',
                                        pr: '5px',
                                    }}>
                                        {t('confirmation:autoApprovedMessage2')}
                                    </Box>
                                    {t('confirmation:autoApprovedMessage3')}
                                </h4>
                                <h4>{AutoApprovedClearingDaysMessage()}</h4>
                            </Grid>
                        </Grid>

                        <ConfirmationFeedbackControl
                            experienceText={t('confirmation:autoApprovedLabelExperience')}
                            experienceIntroductionText={t('confirmation:autoApprovedLabelExperienceIntroduction')}
                            formProps={formProps}
                            reviewSelected
                            isAutoApproved
                        />
                    </>
                }
            </Box>
        </>
    );
}