import * as React from 'react';
import {
    OptionYes,
    RefundReasonAdverseWeather,
    RefundReasonArmedForcesAndEmergencyServicesRecall,
    RefundReasonBookedTooManyTickets,
    RefundReasonBookedWrongDate,
    RefundReasonBookedWrongTickets,
    RefundReasonBookingCancelledByOperator,
    RefundReasonBookingRescheduledByOperator,
    RefundReasonChangesToExaminationDates,
    RefundReasonCourtSummons,
    RefundReasonCOVID19PositiveTest,
    RefundReasonCOVID19TravelRestrictions,
    RefundReasonDeath,
    RefundReasonEmergencyCircumstances,
    RefundReasonHomeEmergency,
    RefundReasonIllnessInjury,
    RefundReasonJuryService,
    RefundReasonMechanicalBreakdown,
    RefundReasonPregnancyComplication,
    RefundReasonPublicTransportFailure,
    RefundReasonScheduledAirlineFailure,
    RefundReasonTheftOfDocuments,
    RefundReasonWorkRelocation,
    RefundReasonWorkplaceRedundancy,
    RefundReasonTestOption
} from "../../utils/constants";
import {useEffect} from "react";
import {IFormValues} from "../../Interfaces/IFormValues";
import {FormikProps} from "formik";
import {AdverseWeatherSection} from "./AdverseWeatherSection";
import {ArmedForcesAndEmergencyServicesRecallSection} from "./ArmedForcesAndEmergencyServicesRecallSection";
import {ChangesToExaminationDatesSection} from "./ChangesToExaminationDatesSection";
import {CourtSummonsSection} from "./CourtSummonsSection";
import {DeathSection} from "./DeathSection";
import {HomeEmergencySection} from "./HomeEmergencySection";
import {COVID19PositiveTestSection} from "./COVID19PositiveTestSection";
import {IllnessInjuryAccidentSection} from "./IllnessInjuryAccidentSection";
import {JuryServiceSection} from "./JuryServiceSection";
import {MechanicalBreakdownSection} from "./MechanicalBreakdownSection";
import {PregnancyComplicationSection} from "./PregnancyComplicationSection";
import {PublicTransportFailureSection} from "./PublicTransportFailureSection";
import {ScheduledAirlineFailureSection} from "./ScheduledAirlineFailureSection";
import {TheftOfDocumentsSection} from "./TheftOfDocumentsSection";
import {WorkRelocationSection} from "./WorkRelocationSection";
import {EmergencyCircumstancesSection} from "./EmergencyCircumstancesSection";
import {useFormContext} from "../../providers/FormProvider";
import {BookingCancelledByOperatorSection} from "./BookingCancelledByOperatorSection";
import {BookingRescheduledByOperatorSection} from "./BookingRescheduledByOperatorSection";
import {COVID19TravelRestrictionsSection} from "./COVID19TravelRestrictionsSection";
import {BookedWrongDateSection} from "./BookedWrongDateSection";
import {BookedWrongTicketsSection} from "./BookedWrongTicketsSection";
import {BookedTooManyTicketsSection} from "./BookedTooManyTicketsSection";
import {WorkplaceRedundancySection} from "./WorkplaceRedundancySection";
import {TestOptionSection} from "./TestOptionSection";

export interface FormSectionProps {
    formProps: FormikProps<IFormValues>;
}

export const RefundReasonHandlerSection = (props: FormSectionProps) => {

    const {
        formProps
    } = props;

    const {
        isExtendedTerms,
        updateRequiredEvidence
    } = useFormContext();

    useEffect(() => {
        (async function() {
            if(formProps.values.refundReasonCategory > 0 && formProps.values.refundReasonConfirm === OptionYes) {
                await updateRequiredEvidence(formProps.values.refundReasonCategory);
            }
        })();
    }, [formProps.values.refundReasonConfirm]);

    const sections : Record<number, any> = {
        [RefundReasonBookingCancelledByOperator]: <BookingCancelledByOperatorSection formProps={formProps} />,
        [RefundReasonBookingRescheduledByOperator]: <BookingRescheduledByOperatorSection formProps={formProps} />,
        [RefundReasonBookedWrongDate]: <BookedWrongDateSection formProps={formProps} />,
        [RefundReasonBookedWrongTickets]: <BookedWrongTicketsSection formProps={formProps} />,
        [RefundReasonBookedTooManyTickets]: <BookedTooManyTicketsSection formProps={formProps} />,
        [RefundReasonCOVID19TravelRestrictions]: <COVID19TravelRestrictionsSection formProps={formProps} />,
        [RefundReasonAdverseWeather]: <AdverseWeatherSection formProps={formProps}/>,
        [RefundReasonArmedForcesAndEmergencyServicesRecall]: <ArmedForcesAndEmergencyServicesRecallSection formProps={formProps}/>,
        [RefundReasonChangesToExaminationDates]: <ChangesToExaminationDatesSection formProps={formProps}/>,
        [RefundReasonCourtSummons]: <CourtSummonsSection formProps={formProps}/>,
        [RefundReasonDeath]: <DeathSection formProps={formProps}/>,
        [RefundReasonHomeEmergency]: <HomeEmergencySection formProps={formProps}/>,
        [RefundReasonCOVID19PositiveTest]: <COVID19PositiveTestSection formProps={formProps} isHidden={isExtendedTerms === false}/>,
        [RefundReasonIllnessInjury]: <IllnessInjuryAccidentSection formProps={formProps}/>,
        [RefundReasonJuryService]: <JuryServiceSection formProps={formProps}/>,
        [RefundReasonMechanicalBreakdown]: <MechanicalBreakdownSection formProps={formProps}/>,
        [RefundReasonPregnancyComplication]: <PregnancyComplicationSection formProps={formProps}/>,
        [RefundReasonPublicTransportFailure]: <PublicTransportFailureSection formProps={formProps}/>,
        [RefundReasonScheduledAirlineFailure]: <ScheduledAirlineFailureSection formProps={formProps}/>,
        [RefundReasonTheftOfDocuments]: <TheftOfDocumentsSection formProps={formProps}/>,
        [RefundReasonWorkRelocation]: <WorkRelocationSection formProps={formProps}/>,
        [RefundReasonEmergencyCircumstances]: <EmergencyCircumstancesSection formProps={formProps}/>,
        [RefundReasonWorkplaceRedundancy]: <WorkplaceRedundancySection formProps={formProps}/>,
        [RefundReasonTestOption]: <TestOptionSection formProps={formProps}/>
    }
    
    return sections[formProps.values.refundReasonCategory] || null;
}