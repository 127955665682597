export default {
    dividerBankingDetails: "Банковские реквизиты",
    dividerBookingDetails: "Детали бронирования",
    labelSavedApplicationReference: "Referensi Aplikasi Tersimpan",
    labelLanguage: "Язык",
    messageWarningBookingHeading1: "Бронирование отменено:",
    messageWarningBookingHeading2: "Бронирование отложено / перенесено:",
    messageWarningBookingBody1: "Если ваше бронирование было отменено организатором или авиакомпанией, эта команда не сможет помочь вам с возвратом средств. Вам нужно будет напрямую связаться с вашей службой поддержки клиентов; см. подтверждение бронирования или веб-сайт для получения контактной информации.",
    messageWarningBookingBody2: "Если ваше бронирование было отложено, ваши продленные условия возврата будут перенесены на новую дату. Если у вас есть уважительная причина для возврата в соответствии с нашими условиями, заполните форму, указав правильные подтверждающие доказательства.",
    messageWarningBookingBody3: "Обратите внимание: эта услуга может обеспечить возмещение только по причинам, указанным в нашей формулировке, доступной в вашем электронном письме с подтверждением бронирования.",
    messageWarningBookingBody4: "Если вы не можете сделать бронирование по причинам, не выходящим за рамки наших условий, мы не сможем вам помочь. Вам нужно будет связаться со своей службой поддержки клиентов напрямую, так как они могут помочь; см. подтверждение бронирования или веб-сайт для получения контактной информации.",
    linkWarningBooking: "здесь",
    formSectionNavigatorStep1: "Шаг 1",
    formSectionNavigatorStep2: "Шаг 2",
    formSectionNavigatorStep3: "Шаг 3",
    formSectionNavigatorStep1Text: "Контакты и детали бронирования",
    formSectionNavigatorStep2Text: "Причина возврата",
    formSectionNavigatorStep3Text: "Детали оплаты",
    messageBookingMatchedAndFound: "Если ваш номер бронирования связан с существующим приложением, мы отправим вам электронное письмо со ссылкой для доступа к вашему приложению.",
    messageBookingMatchedAndFoundCompleted: "Ваша заявка на возврат уже одобрена! Если у вас возникли проблемы с оплатой, обращайтесь:",
    applicationDeclinedMessage: "Ваша заявка была отклонена и теперь закрыта.",
    messageBookingNotFound1: "Мы не можем найти бронирование с такой ссылкой. Пожалуйста, проверьте письмо с подтверждением бронирования и введите его повторно.",
    messageBookingNotFound2: "Не волнуйтесь, если ваш номер бронирования не работает, вы можете выбрать «Найти бронирование» и ввести свои данные. При заполнении формы загрузите копию электронного письма с подтверждением бронирования, чтобы мы могли найти данные о вашем бронировании.",
    messageLocatingBooking: "Поиск вашего бронирования ...",
    messageLoadingBank: "Загрузка информации о банке ...",
    messageFailCurrencyLookup: "Ошибка при получении валюты",
    messageSavingApplication: "Сохранение приложения ...",
    messageSavedApplication: "Заявка на возврат успешно сохранена",
    messageFailSavedApplication: "Не удалось сохранить заявку на возврат",
    messageSendingEmailReminder: "Обработка...",
    messageErrorLocationDetails: "Ошибка при получении сведений о местоположении",
    messageFailFormSubmission: "Не удалось отправить форму",
    messageApplicationCannotBeFound: "Приложение не найдено",
    messageApplicationRetrievalError: "Ошибка при получении приложения",
    messageSubmittingApplication: "Подача заявки ...",
    messageEventDateLimitError: "Причина уведомления вне установленного срока",
    messageAllTicketsAssigned: "Все билеты были назначены другой заявке на возврат.",
    selectPrefix: "Выбрать",
    selectPostfix: "",
    fileUpload: "нажмите здесь, чтобы загрузить",
    fileUploadAcceptedFilesMessage: "Мы принимаем только PDF-файлы и изображения в форматах .png, .jpeg, .heic, .webp.",
    fileUploadNoFiles: "Выбрано 0 файлов",
    fileUploadPreviouslyUploaded: "Ранее загружено:",
    fileUploadSelectedUploaded: "Выбранные уже загружены:",
    buttonNext: "Далее",
    buttonPrevious: "Предыдущий",
    buttonSave: "Сохранить",
    buttonCancel: "Отменить",
    buttonSubmit: "Отправить",
    buttonConfirm: "Подтвердить",
    buttonYes: "да",
    buttonNo: "Нет",
    buttonClose: "Закрывать",
    linkPrivacyStatement: "Заявление о конфиденциальности",
    linkComplaintsPolicy: "Политика рассмотрения жалоб",
    validationRequired: "Обязательный",
    validationInvalid: "Инвалид",
    labelUploadPrefix: "Загрузить",
    labelUploadPostfix: "",
    labelChange: "Менять",
    labelView: "Вид",
    browserNavigationMessage: "Несохраненные изменения будут потеряны"
};