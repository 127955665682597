export default {
    dividerBankingDetails: "Bankuppgifter",
    dividerBookingDetails: "Bokningsdetaljer",
    labelSavedApplicationReference: "Kaydedilmiş Uygulama Referansı:",
    labelLanguage: "Språk",
    messageWarningBookingHeading1: "Bokning avbruten:",
    messageWarningBookingHeading2: "Bokning uppskjuten / omplanerad:",
    messageWarningBookingBody1: "Om din bokning har annullerats av arrangören eller flygbolaget kan detta team inte hjälpa dig med återbetalning. Du måste kontakta ditt kundtjänstteam direkt; se din bokningsbekräftelse eller webbplats för kontaktinformation.",
    messageWarningBookingBody2: "Om din bokning har skjutits upp kommer dina förlängda återbetalningsbara villkor att överföras till det nya datumet. Om du har ett giltigt skäl för återbetalning enligt våra villkor, fyll i formuläret med rätt bevis.",
    messageWarningBookingBody3: "Observera: Den här tjänsten kan endast ge återbetalningar av de skäl som ingår i vår formulering, som finns i din bokningsbekräftelse via e-post.",
    messageWarningBookingBody4: "Om du inte kan göra bokningen på grund av skäl utanför våra villkor, kommer vi inte att kunna hjälpa till. Du måste kontakta ditt kundtjänstteam direkt eftersom de kanske kan hjälpa dig; se din bokningsbekräftelse eller hemsida för kontaktuppgifter.",
    linkWarningBooking: "här",
    formSectionNavigatorStep1: "Steg 1",
    formSectionNavigatorStep2: "Steg 2",
    formSectionNavigatorStep3: "Steg 3",
    formSectionNavigatorStep1Text: "Kontakt- och bokningsdetaljer",
    formSectionNavigatorStep2Text: "Orsak till återbetalning",
    formSectionNavigatorStep3Text: "Betalningsinformation",
    messageBookingMatchedAndFound: "Om din bokningsreferens är kopplad till en befintlig applikation kommer vi att skicka ett e-postmeddelande med en länk för att komma åt din ansökan",
    messageBookingMatchedAndFoundCompleted: "Din återbetalningsansökan har redan godkänts! Om du har problem med din betalning, vänligen kontakta:",
    applicationDeclinedMessage: "Din ansökan har avvisats och är nu stängd.",
    messageBookingNotFound1: "Vi kan inte hitta en bokning med den referensen. Kontrollera din bokningsbekräftelse via e-post och ange den igen.",
    messageBookingNotFound2: "Oroa dig inte, om din bokningsreferens inte fungerar kan du välja 'Hitta bokning' och ange dina uppgifter. När du fyller i formuläret, vänligen ladda upp en kopia av din bokningsbekräftelse via e -post för att hjälpa oss hitta din bokningsinformation.",
    messageLocatingBooking: "Plats för din bokning ...",
    messageLoadingBank: "Läser in bankinformation ...",
    messageFailCurrencyLookup: "Para birimi alınırken hata oluştu",
    messageSavingApplication: "Sparar din applikation ...",
    messageSavedApplication: "Återbetalningsförfrågan har sparats framgångsrikt",
    messageFailSavedApplication: "Återbetalningsbegäran kunde inte sparas",
    messageSendingEmailReminder: "Bearbetar...",
    messageErrorLocationDetails: "Fel vid hämtning av platsinformation",
    messageFailFormSubmission: "Formell inlämning misslyckades",
    messageApplicationCannotBeFound: "Programmet kunde inte hittas",
    messageApplicationRetrievalError: "Fel vid hämtning av programmet",
    messageSubmittingApplication: "Başvuru gönderiliyor...",
    messageEventDateLimitError: "Zaman sınırı dışında bildirim nedeni",
    messageAllTicketsAssigned: "Alla biljetter har tilldelats en annan återbetalningsansökan.",
    selectPrefix: "Välj",
    selectPostfix: "",
    fileUpload: "klicka här för att ladda ner",
    fileUploadAcceptedFilesMessage: "Vi accepterar endast PDF -filer och bildformat av .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 filer valda",
    fileUploadPreviouslyUploaded: "Tidigare uppladdat:",
    fileUploadSelectedUploaded: "De valda har redan laddats upp:",
    buttonNext: "Nästa",
    buttonPrevious: "Föregående",
    buttonSave: "Spara",
    buttonCancel: "Avbryt",
    buttonSubmit: "Skicka in",
    buttonConfirm: "Bekräfta",
    buttonYes: "Ja",
    buttonNo: "Nej",
    buttonClose: "Stänga",
    linkPrivacyStatement: "Sekretesspolicy",
    linkComplaintsPolicy: "Reklamationspolicy",
    validationRequired: "Nödvändig",
    validationInvalid: "Ogiltig",
    labelUploadPrefix: "Ladda upp",
    labelUploadPostfix: "",
    labelChange: "Förändring",
    labelView: "Utsikt",
    browserNavigationMessage: "Osparade ändringar kommer att gå förlorade"
};