export default {
    labelBookingCancelledPostponedHeading: "האם ההזמנה שלך בוטלה או נדחתה? קרא עוד",
    labelBookingCancelledPostponedText1: "נְקִישָׁה",
    labelBookingCancelledPostponedText2: "למידע נוסף.",
    labelNewRefundHeading: "בקשה להחזר חדש?",
    labelNewRefundText: "הזן כאן את הפניית ההזמנה שלך ...",
    placeholderBookingReference: "הפניה להזמנה",
    validationBookingReference: "נדרשת הפניה להזמנה",
    validationEmailAsBookingReference: "כתובת דוא\"ל אינה מתקבלת כאסמכתא להזמנה",
    buttonFindBooking: "מצא הזמנה",
    labelEmailHeading: "כבר התחלת?",
    labelEmailText: "שלח דוא\"ל תזכורת כדי לאחזר את היישום שלך.",
    placeholder: "דואר אלקטרוני: 'דוא\"ל",
    buttonEmailReminder: "תזכורת בדואר אלקטרוני",
    labelCustomerName: "שם הלקוח",
    validationCustomerName: "נדרש שם לקוח",
    labelTypeOfBooking: "סוג ההזמנה",
    validationTypeOfBooking: "סוג ההזמנה נדרש",
    labelEventName: "שם האירוע",
    labelVenueName: "שם מקום",
    labelFlightNumber: "מספר טיסה",
    labelOriginAirport: "שדה תעופה מקור",
    labelHotelName: "שם מקום האירוח",
    labelHotelLocation: "מקום הלינה",
    labelCurrency: "Currency",
    validationCurrency: "נדרש מטבע",
    validationCountryCurrency: "המטבע שנבחר חייב להיות המטבע של מדינת הבית שלך",
    validationOfflinePayment: "איננו יכולים לעבד החזרים עבור בחירת המטבע והמדינה שלך, אנא בחר חלופות",
    labelBookingValue: "ערך הזמנה",
    validationBookingValue: "ערך ההזמנה נדרש",
    validationBookingValueAmount: "חייב להיות גדול מ- 0",
    labelDateOfPurchase: "תאריך הרכישה",
    validationDateOfPurchase: "נדרש תאריך רכישה",
    labelEmailAddress: "כתובת דוא\"ל",
    validationEmailAddress: "נדרשת כתובת דוא\"ל",
    validationEmailAddressFormat: "פורמט דוא\"ל שגוי",
    labelConfirmEmailAddress: "אשר את כתובת הדוא\"ל שלך",
    validationConfirmEmailAddressFormat: "תבנית דואר אלקטרוני שגויה",
    validationConfirmEmailAddress: "נדרשת כתובת דואר אלקטרוני לאישור",
    validationConfirmEmailAddressMatch: "כתובות הדואר האלקטרוני אינן תואמות",
    validationEmailDomain: 'ספק דוא"ל זה אינו מתקבל, אנא השתמש בחלופה',
    validationDateOfEvent: "נדרש תאריך האירוע",
    labelHomeCountry: "ארץ מולדת",
    validationHomeCountry: "נדרשת ארץ המוצא",
    labelContactNumberCountryCode: "מספר איש קשר קוד מדינה",
    validationContactNumberCountryCode: "נדרש קוד חיוג לארץ",
    labelContactNumber: "מספר איש קשר",
    validationContactNumber: "מספר איש הקשר נדרש",
    labelAddressLine1: "כתובת 1",
    labelAddressLine2: "שורת כתובת 2",
    labelCity: "עיר",
    labelZipCode: "מיקוד",
    labelRegion: "Region",
    validationRegion: "אזור נדרש",
    labelNumberOfTicketsToRefund: "מספר הכרטיסים להחזר",
    validationNumberOfTicketsToRefund: "נדרש מספר הכרטיסים להחזר",
    validationNumberOfTicketsToRefundValue: "חייב להיות גדול מ- 0",
    labelCostPerTicket: "עלות כל כרטיס",
    validationCostPerTicket: "העלות לכרטיס היא חובה",
    validationCostPerTicketValue: "חייב להיות גדול מ- 0",
    labelTotalRefundAmount: "סכום ההחזר הכולל",
    validationTotalRefundAmount: "סכום ההחזר הכולל נדרש",
    validationTotalRefundAmountValue: "חייב להיות גדול מ- 0",
    validationTotalRefundGreaterThanBookingValue: "סכום ההחזר הכולל לא יכול להיות גדול מערך ההזמנה לעיל",
    labelBookingConfirmationFiles: "הורד את אישור ההזמנה",
    validationBookingConfirmationFiles: "צרף קובץ אחד לפחות או לחץ על שמור וחזור מאוחר יותר כשיש לך את המסמכים",
    validationEvidenceFilesPopup: "יש להעלות עדויות לפני ההגשה. אם אין לך הוכחות כרגע אנא שמור את האפליקציה וחזר ברגע שיש לך. אנו מקבלים עותקים אלקטרוניים, עותקי סורק ותמונות.",
    validationBookingConfirmationFilesPopup: "יש להעלות אישור הזמנה לפני השליחה. אם אין לך את אישור ההזמנה כרגע, אנא שמור את הבקשה והחזר לאחר שיש לך. אנו מקבלים עותקים אלקטרוניים, עותקי סורקים ותמונות.",
    alertUploadDocuments: "אנא העלה את התיעוד הרלוונטי המפורט בדוא\"ל ולאחר מכן המשך לשלב 3.",
    labelRefundReasonCategory: "סיבה להחזר כספי",
    validationRefundReasonCategory: "נדרשת סיבה להחזר כספי",
    labelDateOfIncident: "תאריך האירוע",
    labelAdditionalComments: "הערות נוספות",
    tooltipAdditionalComments: "הערות נוספות",
    validationAdditionalComments: "נדרשות הערות נוספות",
    labelUnableToNotifyWithinTimeLimit: "לא ניתן להודיע תוך 60 יום",
    labelReasonForNotificationOutsideTimeLimit: "הודעה מחוץ למגבלת הזמן הסבר",
    validationReasonForNotificationOutsideTimeLimit: "נדרשת סיבה להודעה לאחר 60 יום",
    labelRefundReasonFiles: "הורד הוכחות",
    validationRefundReasonFiles: "צרף קובץ אחד לפחות או לחץ על שמור וחזור מאוחר יותר כשיש לך את המסמכים",
    validationTextScript:"חייב להתאים לכתב לטיני",
    optionTypeOfBooking1: "אירוע",
    optionTypeOfBooking2: "סיור",
    optionTypeOfBooking3: "טיסה",
    optionTypeOfBooking4: "לינה",
    optionTypeOfBooking5: "העברה",
    optionTypeOfBooking6: "חניה",
    optionTypeOfBooking7: "נסיעות אחרות",
    labelEventTypeDate: "תאריך האירוע",
    labelEventTypeTour: "תאריך הסיור",
    labelEventTypeFlight: "תאריך הטיסה",
    labelEventTypeCheckIn: "תאריך הגעה",
    labelEventTypeTransfer: "תאריך ההעברה",
    labelEventTypeParking: "תאריך החניה",
    labelEventTypeOtherTravel: "תאריך נסיעה",
    optionRefundReason11: "תנאי מזג אוויר שליליים",
    optionRefundReason10: "זיכרון כוחות מזוינים ושירותי חירום",
    optionRefundReason15: "שינויים במועדי בחינה",
    optionRefundReason18: "זימון לבית משפט",
    optionRefundReason17: "COVID-19 הגבלות על נסיעות",
    optionRefundReason19: "מבחן חיובי COVID-19",
    optionRefundReason20: "ההזמנה בוטלה על ידי מפעיל/מארגן",
    optionRefundReason21: "ההזמנה נקבעה מחדש על ידי המפעיל/המארגן",
    optionRefundReason22: "הזמין תאריך שגוי",
    optionRefundReason23: "הזמינו כרטיסים שגויים",
    optionRefundReason24: "הזמין יותר מדי כרטיסים",
    optionRefundReason4: "מוות",
    optionRefundReason16: "נסיבות חירום",
    optionRefundReason14: "איסור נסיעות מצד הממשלה",
    optionRefundReason9: "מצב חירום בבית",
    optionRefundReason1: "מחלה / פציעה / תאונה",
    optionRefundReason8: "שירות חבר מושבעים",
    optionRefundReason7: "תקלה מכנית",
    optionRefundReason2: "מצב רפואי קיים",
    optionRefundReason3: "סיבוך הריון",
    optionRefundReason5: "התחבורה הציבורית נכשלה",
    optionRefundReason6: "שיבוש טיסה",
    optionRefundReason13: "גניבת מסמכים",
    optionRefundReason12: "רילוקיישן לעבודה",
    optionRefundReason25: "יתירות במקום העבודה",
    optionRefundReason26: "אפשרות בדיקה",
    optionRefundReasonConfirm1: "לא",
    optionRefundReasonConfirm2: "כן",
    optionRefundReasonArmedForces1: "צבא",
    optionRefundReasonArmedForces2: "חיל הים",
    optionRefundReasonArmedForces3: "חיל אוויר",
    optionRefundReasonArmedForces4: "כוחות מיוחדים",
    optionRefundReasonArmedForces5: "הנחתים",
    optionRefundReasonArmedForces6: "אש",
    optionRefundReasonArmedForces7: "אמבולנס",
    optionRefundReasonArmedForces8: "רופא/אחות",
    optionRefundReasonArmedForces9: "רופא שיניים",
    optionRefundReasonArmedForces10: "אחר",
    optionRefundReasonCourtSummons1: "העמדה לדין פלילי",
    optionRefundReasonCourtSummons2: "תיק משפטי אזרחי",
    optionRefundReasonCourtSummons3: "עד בתביעה",
    optionRefundReasonCourtSummons4: "עד בתיק אזרחי",
    optionRefundReasonCourtSummons5: "התובע",
    optionRefundReasonCourtSummons6: "הנאשם",
    optionRefundReasonCourtSummons7: "אחר",
    optionRefundReasonDeath1: "בעל",
    optionRefundReasonDeath2: "רעיה",
    optionRefundReasonDeath3: "קול",
    optionRefundReasonDeath4: "בת",
    optionRefundReasonDeath5: "אבא",
    optionRefundReasonDeath6: "אימא",
    optionRefundReasonDeath7: "אח",
    optionRefundReasonDeath8: "אחות",
    optionRefundReasonDeath9: "סבא",
    optionRefundReasonDeath10: "סבתא",
    optionRefundReasonDeath11: "אחר",
    optionRefundReasonHomeEmergency1: "אש",
    optionRefundReasonHomeEmergency2: "שיטפון",
    optionRefundReasonHomeEmergency3: "שוד",
    optionRefundReasonHomeEmergency4: "ונדליזם",
    optionRefundReasonHomeEmergency5: "התעללות במשפחה",
    optionRefundReasonHomeEmergency6: "לחטוף",
    optionRefundReasonHomeEmergency7: "אחר",
    optionRefundReasonCOVID19Person1: "עצמי",
    optionRefundReasonCOVID19Person2: "אדם נוסף בהזמנה",
    optionRefundReasonCOVID19Person3: "אדם בביתי",
    optionRefundReasonCOVID19Person4: "אחר",
    optionRefundReasonCOVID19TestType1: "בדיקת PCR",
    optionRefundReasonCOVID19TestType2: "בדיקת RAT",
    optionRefundReasonCOVID19TestType3: "בדיקת זרימה לרוחב",
    optionRefundReasonCOVID19TestType4: "מבחן עצמי",
    optionRefundReasonCOVID19TestType5: "אין בדיקה אבל יש לי תסמינים",
    optionRefundReasonCOVID19TestType6: "אחר",
    optionRefundReasonBreakdown1: "התפלגות",
    optionRefundReasonBreakdown2: "תאונה",
    optionRefundReasonBreakdown3: "אש",
    optionRefundReasonBreakdown4: "גניבה",
    optionRefundReasonPublicTransportType1: "רכבת",
    optionRefundReasonPublicTransportType2: "אוטובוס",
    optionRefundReasonPublicTransportType3: "חשמליה",
    optionRefundReasonPublicTransportType4: "סירה",
    optionRefundReasonDocumentType1: "דרכון",
    optionRefundReasonDocumentType2: "היתר נסיעה",
    optionRefundReasonDocumentType3: "תעודת זהות לאומית",
    optionRefundReasonDocumentType4: "כרטיסים",
    optionRefundReasonWorkRelocationType1: "זמני",
    optionRefundReasonWorkRelocationType2: "קבוע",
    optionRefundReasonWorkRelocationTemporary1: "0-3 חודשים",
    optionRefundReasonWorkRelocationTemporary2: "3-6 חודשים",
    optionRefundReasonWorkRelocationTemporary3: "מעל 6 חודשים",
    optionRefundReasonBookingRelationshipType1: "מישהו בהזמנה",
    optionRefundReasonBookingRelationshipType2: "קרוב משפחה מיידי של מישהו בהזמנה",
    optionEmploymentType1: "משרה מלאה",
    optionEmploymentType2: "משרה חלקית",
    optionEmploymentType3: "חוזה",
    optionEmploymentLengthType1: "0-1 שנים",
    optionEmploymentLengthType2: "1-2 שנים",
    optionEmploymentLengthType3: "יותר משנתיים",
    textRefundEvidenceHeading: "אנא ספק את הראיות הבאות:",
    textRefundEvidence1: "אישור חברת התעופה",
    textRefundEvidence2: "כל ראיה תומכת מתאימה",
    textRefundEvidence3: "אישור קריאה לשירות תקלות",
    textRefundEvidence4: "תעודת פטירה",
    textRefundEvidence5: "ביטול אישור מחברת התעופה",
    textRefundEvidence6: "תחזית מזג האוויר",
    textRefundEvidence7: "התכתבות של סוכן ההזמנות המאשרת שאין יכולת להנפיק מחדש כרטיסים",
    textRefundEvidence8: "מכתב מבית המשפט",
    textRefundEvidence9: "תעודה רפואית / הערת רופא",
    textRefundEvidenceDescription9: "אישור פרטים על המחלה או הפציעה, התאריך שבו היא התרחשה לראשונה, שהוא מונע ממך להשתתף בהזמנה.",
    textRefundEvidence10: "אישור מכתב מעסיק",
    textRefundEvidence11: "התכתבות תביעות ביטוח",
    textRefundEvidence12: "מכתב מהמעסיק",
    textRefundEvidence13: "אישור אתר האינטרנט של הממשלה הלאומית",
    textRefundEvidence14: "הודעה על שינוי בדיקה",
    textRefundEvidence15: 'דו"ח משטרתי',
    textRefundEvidence16: 'דו"ח משטרה ומספר אסמכתא',
    textRefundEvidence17: "אישור ספק תחבורה",
    textRefundEvidence18: "אזהרת מסע",
    textRefundEvidence20: "תעודת בדיקת COVID-19",
    textRefundEvidence24: "הודעת בידוד ממשלתית",
    textRefundEvidence25: "מכתב מהמפקד",
    textRefundEvidence26: "מכתב ממבטחים",
    textRefundEvidence27: "הוכחת כתובת",
    textRefundEvidence28: "הוכחת הקשר עם המנוח",
    textRefundEvidence29: "הוכחת הקשר עם הנפגע/חולה",
    textRefundEvidence30: "תלוש שכר",
    tooltipBookingReference: "כדי לזהות את אסמכתא ההזמנה שלך, בדוק את אישור ההזמנה שלך וצפה בסעיף על השירות המורחב שלנו.",
    tooltipCustomerName: "שם פרטי ושם משפחה ששימש לביצוע ההזמנה.",
    tooltipTypeOfBooking: "סוג ההזמנה שתרצה לקבל החזר כספי.",
    tooltipEventName: "שם האירוע.",
    tooltipVenueName: "שם המקום.",
    tooltipFlightNumber: "מספר הטיסה.",
    tooltipOriginAirport: "שדה התעופה היוצא.",
    tooltipHotelName: "שם האירוח.",
    tooltipHotelLocation: "מיקום הלינה.",
    tooltipCurrency: "המטבע המשמש לתשלום ההזמנה.",
    tooltipBookingValue: "המחיר הכולל ששילמת עבור ההזמנה.",
    tooltipDateOfPurchase: "התאריך בו בוצעה ההזמנה.",
    tooltipEmailAddress: 'אנא השתמש בכתובת דוא"ל תקפה. אנו משתמשים בדוא"ל זה לצורך התכתבויות במהלך בקשת ההחזר.',
    tooltipConfirmEmailAddress: 'השתמש באותה כתובת דוא"ל כמו לעיל, זה מיועד לאימות.',
    tooltipHomeCountry: "זה יעזור לנו להביא את המידע הבנקאי הנכון בשלב האחרון.",
    tooltipContactNumberCountryCode: "קוד מדינה של מספר איש קשר.",
    tooltipContactNumber: "אנא השתמש במספר חוקי. אנו משתמשים בזה לצורך התכתבות במהלך בקשת ההחזר.",
    tooltipAddressLine1: "שים לב לכתובתך תשמש בעת אימות חשבון הבנק שלך.",
    tooltipAddressLine2: "שורת כתובת שנייה.",
    tooltipCity: "עיר מגוריך, ניתן להשתמש בפרטים לצורך אימות פרטי בנק.",
    tooltipZipCode: "מיקוד / מיקוד.",
    tooltipRegion: "באזור עירך, ניתן להשתמש בפרטים לצורך אימות פרטי בנק.",
    tooltipNumberOfTicketsToRefund: "מספר הכרטיסים שתרצה לקבל החזר כספי.",
    tooltipTotalRefundAmount: "זה לא יכול להיות יותר מערך ההזמנה הכולל, לא תוכל להגיש את הבקשה אם כן.",
    tooltipBookingConfirmationFiles: "צילום מסך / מצורף אישור ההזמנה המלא שלך אשר בדרך כלל ניתן למצוא במיילים שלך.",
    tooltipRefundReasonCategory: "אנא בחר את הסיבה להחזר.",
    tooltipDateOfIncident: "התאריך בו אירע האירוע שהשפיע על הזמנתך.",
    tooltipRefundExplanation: "נא למסור כמה שיותר פרטים התומכים בתביעת ההחזר.",
    tooltipUnableToNotifyWithinTimeLimit: "לא ניתן להודיע ​​על כך בתוך מגבלת הזמן של 60 יום.",
    tooltipReasonForNotificationOutsideTimeLimit: "הודעה הסבר על מגבלת זמן.",
    tooltipRefundReasonFiles: "אנא העלה כל אחד מהמסמכים הנדרשים.",
    tooltipEventTypeDate: "תאריך האירוע / ההזמנה בפועל.",
    tooltipEventTypeTour: "תאריך הסיור בפועל.",
    tooltipEventTypeFlight: "תאריך הטיסה בפועל.",
    tooltipEventTypeCheckIn: "תאריך הצ'ק-אין בפועל.",
    tooltipEventTypeTransfer: "תאריך ההעברה בפועל.",
    tooltipEventTypeParking: "תאריך החניה בפועל.",
    tooltipEventTypeOtherTravel: "תאריך התחלה של הטיול בפועל."
};