export default {
    headingAdverseWeather: "आप गंभीर प्रतिकूल मौसम की स्थिति का अनुभव करते हैं जो घटना की यात्रा करने के लिए असुरक्षित बनाते हैं और इसे एक सरकारी निकाय (जैसे स्थानीय प्राधिकरण, पुलिस, आदि) द्वारा अनिवार्य किया गया है। और क्या आपके पास यह साबित करने के लिए सबूत हैं?",
    headingAdverseWeatherDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    tooltipRefundReasonConfirm: "हाँ या नहीं",
    labelBookingCancelledByOperator: "ऑपरेटर/ऑर्गनाइजर द्वारा टिकट रद्द किए गए थे।",
    labelBookingRescheduledByOperator: "ऑपरेटर/ऑर्गेनाइजर द्वारा तारीख बदल दी गई थी और मैं नई तारीखों में भाग नहीं ले सकता।",
    labelBookedWrongDate: "मैंने या बुकिंग के किसी सदस्य ने टिकट खरीदते समय गलती की और गलत तारीख बुक की।",
    labelBookedWrongTickets: "मैंने या बुकिंग के किसी सदस्य ने टिकट खरीदते समय गलती की और गलत टिकट बुक किए।",
    labelBookedTooManyTickets: "मैंने या बुकिंग के किसी सदस्य ने टिकट खरीदते समय गलती की और बहुत सारे टिकट बुक किए।",
    labelCOVID19TravelRestrictions: "मैं या बुकिंग का कोई सदस्य, कोविड-19 यात्रा प्रतिबंध के कारण उपस्थित होने में असमर्थ हैं।",
    labelADWrongSelection: "हो सकता है कि आपने रिफंड के लिए गलत कारण चुना हो। कृपया धनवापसी कारण ड्रॉप डाउन सूची की फिर से समीक्षा करें.",
    labelAdverseWeather1: "प्रतिकूल मौसम का मतलब यह क्यों था कि आप इस कार्यक्रम में भाग लेने में असमर्थ थे?",
    tooltipAdverseWeather1: "कृपया विस्तार से",
    labelAdverseWeather2: "घटना की तारीख",
    tooltipAdverseWeather2: "कृपया चुनें",
    headingArmedForces: "आपसे आपके नियोक्ता द्वारा पूछा जाता है कि सशस्त्र बल या आपातकालीन सेवाएं कौन सी हैं जो काम पर आने के लिए हैं जब आप मूल रूप से ऐसा करने के लिए निर्धारित नहीं थे और यह आपको कार्यक्रम में भाग लेने से रोकता है?",
    headingArmedForcesDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelArmedForces1: "आप किन बलों या आपातकालीन सेवा के द्वारा नियोजित हैं?",
    tooltipArmedForces1: "कृपया चुनें",
    labelArmedForcesOther1: "कृपया निर्दिष्ट करें",
    tooltipArmedForcesOther1: "कृपया निर्दिष्ट करें",
    labelArmedForces2: "घटना की तारीख",
    tooltipArmedForces2: "कृपया चुनें",
    headingChangesToExam: "आपकी परीक्षा की तारीखें आपके स्कूल / कॉलेज, आदि द्वारा बदल दी गई हैं? यह एक असफल परीक्षा नहीं है जिसे आपको फिर से शुरू करना चाहिए?",
    headingChangesToExamDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelExam1: "मूल परीक्षा की तारीख क्या थी?",
    tooltipExam1: "कृपया चुनें",
    labelExam2: "नई परीक्षा की तारीख क्या है?",
    tooltipExam2: "कृपया चुनें",
    labelExam3: "परीक्षा की तारीख क्यों बदल गई?",
    tooltipExam3: "कृपया विवरण दर्ज करें",
    labelExam4: "क्या आप पिछली परीक्षा में असफल रहे थे?",
    tooltipExam4: "हाँ या नहीं",
    headingCourtSummons: "आपको घटना के रूप में एक ही तारीख को गवाह के रूप में अदालत में पेश होने के लिए समन भेजा गया है?",
    headingCourtSummonsDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelCourtSummons1: "आपके अदालत के सम्मन का क्या कारण है?",
    tooltipCourtSummons1: "कृपया चुनें",
    labelCourtSummonsOther1: "कृपया निर्दिष्ट करें",
    tooltipCourtSummonsOther1: "कृपया निर्दिष्ट करें",
    labelCourtSummons2: "अदालत के समन कब जारी किए गए थे?",
    tooltipCourtSummons2: "कृपया चुनें",
    headingCOVID19Test: "मैं, मेरे घर का एक सदस्य या कार्यक्रम में भाग लेने वाले समूह के एक सदस्य को कोविड-19 का पता चला है?",
    headingCOVID19TestDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelCOVID19Test1: "किसे है कोविड-19?",
    tooltipCOVID19Test1: "कृपया चुनें",
    labelCOVID19TestOther1a: "कृपया निर्दिष्ट करें",
    labelCOVID19TestOther1b: "नाम निर्दिष्ट करें",
    tooltipCOVID19TestOther1a: "कृपया निर्दिष्ट करें",
    tooltipCOVID19TestOther1b: "नाम निर्दिष्ट करें",
    labelCOVID19Test2: "आपने किस प्रकार का कोविड-19 परीक्षण किया था?",
    tooltipCOVID19Test2: "कृपया चुनें",
    labelCOVID19TestOther2: "कृपया निर्दिष्ट करें",
    tooltipCOVID19TestOther2: "कृपया निर्दिष्ट करें",
    labelCOVID19Test3: "आपके कोविड-19 परीक्षण की तारीख क्या थी?",
    tooltipCOVID19Test3: "कृपया चुनें",
    headingDeath: "मेरे परिवार के एक तत्काल सदस्य की मृत्यु हो गई है और यह मुझे इस कार्यक्रम में भाग लेने से रोकता है?",
    headingDeathDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelDeath1: "आपके परिवार के किस सदस्य की मृत्यु हो गई है?",
    tooltipDeath1: "कृपया चुनें",
    labelDeathOther1: "कृपया निर्दिष्ट करें",
    tooltipDeathOther1: "कृपया निर्दिष्ट करें",
    labelDeath2: "मृत्यु की तारीख कब थी?",
    tooltipDeath2: "कृपया चुनें",
    labelDeath3: "मृतक का नाम",
    tooltipDeath3: "कृपया निर्दिष्ट करें",
    headingEmergency: "मेरे पास एक वास्तविक आपातकाल है जो मेरे नियंत्रण से बाहर है और अप्रत्याशित है जिसके लिए मैं सबूत प्रदान कर सकता हूं?",
    headingEmergencyDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelEmergency1: "कृपया आपातकाल के प्रकार के बारे में बताएं?",
    tooltipEmergency1: "कृपया विस्तार से",
    labelEmergency2: "यह कब हुआ?",
    tooltipEmergency2: "कृपया चुनें",
    labelEmergency3: "यह स्थिति आपको घटना में भाग लेने से क्यों रोकती है?",
    tooltipEmergency3: "कृपया विस्तार से",
    labelEmergency4: "क्या आपके पास इस आपातकाल के सबूत हैं?",
    tooltipEmergency4: "हाँ या नहीं",
    headingHomeEmergency: "मेरे पास एक घर आपातकाल है जो डकैती, बाढ़, आग, बर्बरता, घरेलू दुर्व्यवहार है जो मुझे घटना में भाग लेने से रोकता है?",
    headingHomeEmergencyDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelHomeEmergency1: "घर आपातकाल क्या है?",
    tooltipHomeEmergency1: "कृपया चुनें",
    labelHomeEmergencyOther1: "कृपया निर्दिष्ट करें",
    tooltipHomeEmergencyOther1: "कृपया निर्दिष्ट करें",
    labelHomeEmergency2: "यह कब हुआ?",
    tooltipHomeEmergency2: "कृपया चुनें",
    labelHomeEmergency3: "इसने आपको कार्यक्रम में भाग लेने से क्यों रोक दिया?",
    tooltipHomeEmergency3: "कृपया विस्तार से",
    headingInjury: "मैं, मेरे घर के एक सदस्य या कार्यक्रम में भाग लेने वाले समूह के एक सदस्य को एक चिकित्सा स्थिति या चोट का निदान किया गया है जो अब हमें घटना में जाने से रोकता है?",
    headingInjuryDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelInjury1: "बीमारी / चोट / दुर्घटना की प्रकृति क्या है?",
    tooltipInjury1: "कृपया विस्तार से",
    labelInjury2: "यह कब हुआ?",
    tooltipInjury2: "कृपया चुनें",
    labelInjury3: "क्या आपने इस बीमारी/चोट के लिए चिकित्सा सुविधा में भाग लिया है?",
    tooltipInjury3: "हाँ या नहीं",
    labelInjury4: "क्या यह पहले से मौजूद चिकित्सा स्थिति थी?",
    tooltipInjury4: "हाँ या नहीं",
    labelInjury5: "यह पहली बार कब निदान किया गया था?",
    tooltipInjury5: "कृपया चुनें",
    labelInjury6: "इसका मतलब यह क्यों है कि आप कार्यक्रम में शामिल नहीं हो सकते हैं?",
    tooltipInjury6: "कृपया विस्तार से",
    labelInjury7: "बीमारी / चोट किसने झेली है?",
    tooltipInjury7: "कृपया चुनें",
    headingJuryService: "मैं या बुकिंग के एक सदस्य को जूरी सेवा के लिए बुलाया गया है?",
    headingJuryServiceDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelJuryService1: "आपको जूरी सेवा के बारे में कब सूचित किया गया था?",
    tooltipJuryService1: "कृपया चुनें",
    headingMechanicalBreakdown: "हमारे वाहन में एक यांत्रिक टूट गया है या एक दुर्घटना में रहा है और यह हमें घटना में भाग लेने से रोकता है?",
    headingMechanicalBreakdownDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelMechanicalBreakdown1: "वाहन के साथ क्या हुआ?",
    tooltipMechanicalBreakdown1: "कृपया चुनें",
    labelMechanicalBreakdown2: "यह कब हुआ?",
    tooltipMechanicalBreakdown2: "कृपया चुनें",
    labelMechanicalBreakdown3: "आप वैकल्पिक परिवहन लेने में असमर्थ क्यों थे?",
    tooltipMechanicalBreakdown3: "कृपया विस्तार से",
    headingPregnancyComplication: "मैं या बुकिंग के एक सदस्य को गर्भावस्था की जटिलताएं हैं जो एक चिकित्सा पेशेवर द्वारा निदान की गई हैं और इसलिए घटना में उपस्थिति को रोकती हैं?",
    headingPregnancyComplicationDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelPregnancyComplication1: "गर्भावस्था जटिलता की प्रकृति क्या है?",
    tooltipPregnancyComplication1: "कृपया विस्तार से",
    labelPregnancyComplication2: "यह कब हुआ?",
    tooltipPregnancyComplication2: "कृपया चुनें",
    labelPregnancyComplication3: "क्या आप चिकित्सा ध्यान देना चाहते हैं?",
    tooltipPregnancyComplication3: "हाँ या नहीं",
    labelPregnancyComplication4: "इसका मतलब यह क्यों है कि आप कार्यक्रम में शामिल नहीं हो सकते हैं?",
    tooltipPregnancyComplication4: "कृपया विस्तार से",
    headingPublicTransportFailure: "सार्वजनिक परिवहन की विधि (ट्रेन, बस, ट्राम, नाव, आदि) अप्रत्याशित रूप से विफल रही जिसका अर्थ है कि कोई अन्य तरीका नहीं था कि मैं इस घटना की यात्रा कर सकता था?",
    headingPublicTransportFailureDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelPublicTransportFailure1: "आपने किस प्रकार के सार्वजनिक परिवहन का उपयोग करने की योजना बनाई है?",
    tooltipPublicTransportFailure1: "कृपया चुनें",
    labelPublicTransportFailure2: "आपने यात्रा करने की योजना कब बनाई थी?",
    tooltipPublicTransportFailure2: "कृपया चुनें",
    labelPublicTransportFailure3: "क्या हुआ?",
    tooltipPublicTransportFailure3: "कृपया विस्तार से",
    labelPublicTransportFailure4: "आप वैकल्पिक परिवहन क्यों नहीं ले सकते?",
    tooltipPublicTransportFailure4: "कृपया विस्तार से",
    headingScheduledAirlineFailure: "मेरी पूर्व-बुक की गई उड़ान अप्रत्याशित रूप से रद्द कर दी गई थी और मैं एक उपयुक्त विकल्प खोजने में असमर्थ था जिसका अर्थ है कि मैं घटना में भाग लेने में असमर्थ था?",
    headingScheduledAirlineFailureDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelScheduledAirlineFailure1: "एयरलाइन का नाम",
    tooltipScheduledAirlineFailure1: "एयरलाइन का नाम",
    labelScheduledAirlineFailure2: "विमान संख्या",
    tooltipScheduledAirlineFailure2: "विमान संख्या",
    labelScheduledAirlineFailure3: "से यात्रा",
    tooltipScheduledAirlineFailure3: "से यात्रा",
    labelScheduledAirlineFailure4: "यात्रा करने के लिए",
    tooltipScheduledAirlineFailure4: "यात्रा करने के लिए",
    labelScheduledAirlineFailure5: "घटना की तारीख?",
    tooltipScheduledAirlineFailure5: "कृपया चुनें",
    labelScheduledAirlineFailure6: "आप एक वैकल्पिक उड़ान लेने में असमर्थ क्यों थे?",
    tooltipScheduledAirlineFailure6: "कृपया विस्तार से",
    headingTheftOfDocuments: "मेरे दस्तावेज चोरी हो गए थे और आवश्यक समय सीमा के भीतर प्रतिस्थापन (ओं) की व्यवस्था करना संभव नहीं था, इसलिए मैं घटना में भाग नहीं ले सकता",
    headingTheftOfDocumentsDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelTheftOfDocuments1: "किस प्रकार का दस्तावेज़ चोरी किया गया था?",
    tooltipTheftOfDocuments1: "कृपया चुनें",
    labelTheftOfDocuments2: "यह कब हुआ?",
    tooltipTheftOfDocuments2: "कृपया चुनें",
    labelTheftOfDocuments3: "क्या आपने दस्तावेजों को फिर से जारी करने का प्रयास किया है?",
    tooltipTheftOfDocuments3: "हाँ या नहीं",
    headingWorkRelocation: "मेरे नियोक्ता ने मुझसे कम से कम तीन महीने के लिए अपने काम के स्थान को बदलने का अनुरोध किया है और दूरी महत्वपूर्ण है जिसका अर्थ है कि मैं अब इस घटना में भाग लेने में असमर्थ हूं?",
    headingWorkRelocationDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को सबसे अधिक संभावना अस्वीकार कर दी जाएगी।",
    labelWorkRelocation1: "क्या यह आपके काम का अस्थायी या स्थायी स्थानांतरण है?",
    tooltipWorkRelocation1: "कृपया चुनें",
    labelWorkRelocation2: "आप कब तक नए पते पर रहेंगे?",
    tooltipWorkRelocation2: "कृपया विस्तार से",
    labelWorkRelocation3: "आपका वर्तमान कार्य पता क्या है?",
    tooltipWorkRelocation3: "कृपया विस्तार से",
    labelWorkRelocation4: "आपका नया कार्य पता क्या है?",
    tooltipWorkRelocation4: "कृपया विस्तार से",
    labelWorkRelocation5: "आपको इस स्थानांतरण के बारे में कब सूचित किया गया था?",
    tooltipWorkRelocation5: "कृपया चुनें",
    headingRedundancy: "मुझे या बुकिंग के एक सदस्य को उनके नियोक्ता द्वारा अनावश्यक बना दिया गया है, जिसका अर्थ है कि मैं अब बुकिंग में भाग नहीं ले सकता?",
    headingRedundancyDecline: "आपको इस आवेदन के साथ आगे नहीं बढ़ना चाहिए क्योंकि धनवापसी को अस्वीकार कर दिया जाएगा।",
    labelRedundancy1: "रोजगार के प्रकार?",
    tooltipRedundancy1: "कृपया चुनें",
    labelRedundancy2: "आप इस कंपनी में कब से काम कर रहे हैं?",
    tooltipRedundancy2: "कृपया चुनें",
    labelRedundancy3: "क्या अतिरेक स्वैच्छिक था?",
    tooltipRedundancy3: "कृपया चुनें",
    labelRedundancy4: "आपको अपने अतिरेक के बारे में कब सूचित किया गया था?",
    tooltipRedundancy4: "कृपया चुनें",
    labelTestOption: "आगे बढ़ने के लिए हाँ चुनें",
}