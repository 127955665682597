export default {
    messageUnableToMakePaymentToCurrency: "Valitettavasti emme voi noutaa valitun valuutan pankkitietoja. Varmista, että Valuutta ja Kotimaa on valittuna",
    messageOnlyBankAccountsAccepted: "Huomaa: Voimme suorittaa maksuja vain pankkitileille tällä hetkellä (luotto- ja pankkikortteja ei hyväksytä)",
    labelAccountName: "tilinhaltijan nimi",
    labelBankAccountName: "Tilinhaltijan koko nimi",
    labelBankCardHolderName: "Kortinhaltijan nimi",
    validationBankAccountName: "Tilinhaltijan koko nimi vaaditaan",
    labelBankCountry: "Pankin maa",
    labelBankName: "Pankin nimi",
    labelPaymentType: "Maksutapa",
    labelInstitutionNumber: "Laitoksen numero",
    labelBankAddress: "Pankkiosoite",
    labelBankAddress2: "Pankkiosoite",
    labelBankCity: "Pankin kaupunki",
    labelBankState: "Pankkivaltio",
    labelBankAccountNumber: "Pankkitilin numero",
    labelBankAccountNumberCBU: "Tilinumero CBU",
    labelBankAccountNumberCCI: "Tilinumero CCI",
    labelBankAccountNumberZAR: "Pankkitilin numero (7-11 numeroa)",
    labelCardNumber: "Kortin numero",
    labelUnionpay: "UnionPay-kortin numero",
    labelAlipay: "Alipay ID (matkapuhelinnumero tai sähköpostiosoite)",
    labelBankAccountType: "Tilin tyyppi",
    optionBankAccountTypeCURRENT: "Nykyinen tili",
    optionBankAccountTypeCHECKING: "Tarkistetaan tiliä",
    optionBankAccountTypeSAVING: "Säästötili",
    optionBankAccountTypeSAVINGS: "Säästötili",
    optionBankAccountTypeCUENTA_VISTA: "Päätili",
    optionBankAccountType1: "Tarkistetaan tiliä",
    optionBankAccountType2: "Säästötili",
    optionBankAccountType3: "Päätili",
    optionBankAccountType4: "Vista-tili",
    optionBankAccountType5: "RUT-tili",
    labelSortCode: "Lajittelukoodi",
    labelRoutingCode: "Reitityskoodi",
    labelBankRoutingCodeNationalID: "Pankin reitityskoodi / kansallinen tunnus",
    labelBankTransitCodeSwift: "Transit numero",
    labelCNAPSCode: "Reittikoodi / CNAPS-koodi (Kiinan kansallinen edistyneen maksujärjestelmän koodi)",
    labelABAFedwireRoutingCode: "ACH-reititysnumero",
    labelIFSCCode: "IFSC koodi",
    labelBankCode: "Pankkikoodi",
    labelBIKCode: "BIK koodi",
    labelBranchCode: "Sivuliikkeen koodi",
    labelBranchCodeUniversal: "Universaali 6-numeroinen haarakoodi",
    labelRemittanceLine3: "Edunsaajan yhteyshenkilön nimi ja puhelinnumero",
    labelBeneficiaryContactNameandTelephoneNumber: "Edunsaajan yhteyshenkilön nimi ja puhelinnumero",
    labelBeneficiaryDateofBirthandPlaceofBirth: "Edunsaajan syntymäaika ja syntymäpaikka",
    labelRemittanceLine4: "Henkilökohtainen veronumero CUIL (9–12 numeroa)",
    labelCUILNumber: "Verotunnus: CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "Verotunnus RFC 13 numeroa tai CURP 18 numeroa",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Henkilökohtainen verotunnus CPF Cadastro de Pessoa Física (11-14 numeroa), sivuliikkeen koodi",
    labelCFPNumber: "CPF-numero (11-14 numeroa)",
    labelBranchNameorBranchCode7digits: "Sivukonttorin nimi tai sivuliikkeen koodi (7 numeroa)",
    labelCedulaJuridica910digitsTaxID: "Lakitodistus (9-10 numeroa verotunnus)",
    labelTaxIDRUT89digits: "Verotunnus RUT (8–9 numeroa)",
    labelJob: "Työnimike",
    labelDateOfBirth: "Syntymäaika",
    labelNationality: "Kansallisuus",
    labelDocumentType: "dokumentti tyyppi",
    labelDocumentNumber: "Asiakirjan numero",
    labelRutNumber: "RUT-numero (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "Cedula (henkilökohtainen verotunnus, 8–11 numeroa)",
    labelCIIndividualTaxIDBranchLocation: "CI (Individual Tax ID), sivuliikkeen sijainti",
    labelTaxIDRUCorCI: "Verotunnus (RUC tai CI)",
    labelSortCodeHelp: "Apua?",
    labelBranchCodeHelp: "Apua?",
    labelIBAN: "IBAN",
    labelIBANHelp: "Apua?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT/BIC",
    labelBeneficiarySWIFTBICHelp: "Apua?",
    labelRoutingCodeBSB: "Reitityskoodi/BSB",
    labelBSB: "pankkivaltion sivukonttori (BSB)",
    labelSKNCode: "SKN koodi",
    labelAcceptanceFlights: "Hyväksyn/hyväksyn, että jos hyvityshakemus onnistuu, varaus, mukaan lukien kaikki lennot ja oheistuotteet, peruutetaan.",
    labelAcceptanceTickets: "Hyväksyn, että jos hyvityshakemus onnistuu, varauksen kaikki liput ja oheistuotteet perutaan.",
    labelTermsAndConditions1: "Olen hyväksynyt",
    labelTermsAndConditions2: "Käyttöehdot",
    labelFraudWarning1: "Uskon, että tässä palautushakemuslomakkeessa esitetyt tosiasiat ovat aitoja ja totta. Ymmärrän, että kaikkia vääriä tietoja tai todisteita voidaan pitää rikoksena ja ne voidaan siirtää poliisin tai muiden lainvalvontaviranomaisten tutkittavaksi.",
    labelFraudWarning2: "Jos epäilemme tositteisiin liittyvää petosta, asia viedään välittömästi paikallisten poliisiviranomaisten käsiteltäväksi. Hyväksyn ja ymmärrän, että tietoni välitetään näille viranomaisille, jos hakemustani pidetään vilpillisenä ja siitä voi tulla rikostutkinta.",
    validationTermsAndConditions: "Ehdot on hyväksyttävä",
    messageFailbankName: "Pankin nimi ei voi olla tyhjä",
    messageFailcustomerName: "Asiakkaan nimi ei voi olla tyhjä",
    messageFailbankAccountNumber: "Vaadittu kenttä",
    messageFailbankAccountNumberFormat: "On oltava IBAN-muotoa",
    messageFailsortCode: "Vaadittu kenttä",
    messageFailbankSwiftBic: "Saajan SWIFT BIC ei voi olla tyhjä",
    messageFailbankSwiftBicFormat: "On oltava SWIFT-muotoinen",
    messageFailbeneficiaryAddress1: "Osoite 1 ei voi olla tyhjä",
    messageFailbeneficiaryCity: "Kaupunki ei voi olla tyhjä",
    messageFailbeneficiaryPostCode: "Postinumero ei voi olla tyhjä",
    messageFailremittanceLine3: "Vaadittu kenttä",
    messageFailremittanceLine4: "Vaadittu kenttä",
    modalHeading: "Vahvista tiedot",
    modalLabelPleaseConfirmYourDetails: "Vahvista tietosi",
    modalValidationPleaseConfirm: "Ole hyvä ja vahvista",
    modalValidationMustMatch: "On vastattava edellistä merkintää",
    tooltipBankAccountName: "Nimesi sellaisena kuin se näkyy pankkitililläsi/tiliotteellasi/pankkikortillasi.",
    tooltipBankAccountNameRecipient: "Vastaanottajan etu- ja sukunimi. Sekä etu- että sukunimessä on oltava useampi kuin yksi merkki.",
    tooltipBankCountry: "Maa, jossa pankkisi sijaitsee.",
    tooltipBankName: "Pankkisi nimi.",
    tooltipPaymentType: "Vaadittava maksutyyppi.",
    tooltipInstitutionNumber: "Tämä on 3 numeroa ja löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAccountNumber: "Henkilökohtainen tilinumerosi. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAccountNumberCBU: "22-numeroinen numero, jota käytetään tilisi tunnistamiseen. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAccountNumberCCI: "20-numeroinen numero, jota käytetään tilisi tunnistamiseen. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAccountNumberKRW: "11–16-numeroinen numero, jota käytetään tilisi tunnistamiseen. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCardNumber: "Korttisi numero. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipUnionpay: "UnionPay-korttisi numero.",
    tooltipAlipay: "Alipay-tiliisi yhdistetty matkapuhelinnumerosi tai sähköpostiosoitteesi.",
    tooltipBankAccountType: "Tämä on joko tarkistus tai säästö.",
    tooltipSortCode: "Tämä tunnistaa pankkisi sivukonttorin, jossa tiliäsi pidetään. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi",
    tooltipRoutingCode: "Tämä tunnistaa pankkisi sivukonttorin, jossa tiliäsi pidetään. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBeneficiarySWIFTBIC: "Koostuu 8-11 merkistä, joita käytetään pankin tunnistamiseen kansainvälisessä maksutapahtumassa. Löytyy yleensä kuukausiotteeltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBSB: "Bank State Branch (BSB) -numero on australialainen lajittelukoodi, jossa on 6 numeroa. Löytyy yleensä kuukausiotteeltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipRoutingCodeBSB: "Tämä tunnistaa pankkisi sivukonttorin, jossa tiliäsi pidetään. Löytyy yleensä kuukausiotteeltasi tai pankkikortiltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipABAFedwireRoutingCode: "9-numeroinen numero, jota käytetään pankkien tunnistamiseen Amerikassa. Löytyy yleensä kuukausiotteeltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipIFSCCode: "Yleensä tämä koodi löytyy kuukausiotteeltasi tai passikirjastasi, tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipTermsAndConditions: "Hyväksymällä käyttöehdot vahvistat, että olet lukenut ja hyväksynyt käyttöehdot.",
    tooltipIBAN: "Kansainvälinen pankkitilinumerosi, jota voit käyttää suorittaessasi tai vastaanottaessasi kansainvälisiä maksuja. Löytyy yleensä kuukausiotteeltasi tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "Edunsaajan yhteyshenkilön nimi ja puhelinnumero.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "Edunsaajan syntymäaika ja syntymäpaikka.",
    tooltipBankRoutingCodeNationalID: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankTransitCodeSwift: "Tämä on 5 numeroa ja se löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAddress: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankAddress2: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankCity: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankState: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi..",
    tooltipBranchName: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBankCode: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBIKCode: "9 numeroa pankkisi tunnistamiseen.",
    tooltipBranchCode: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBranchCodeUniversal: "CLöytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCNAPSCode: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipRemittanceLine4: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCUILNumber: "Tämä on 11-numeroinen luku, joka koostuu kahdesta numerosta, tavuviivasta, yhdeksästä numerosta ja yksinumeroisesta tarkistussummasta.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCFPNumber: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipBranchNameorBranchCode7digits: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCedulaJuridica910digitsTaxID: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipTaxIDRUT89digits: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipJob: "Nykyinen ammattisi.",
    tooltipDateOfBirth: "Syntymäaikasi.",
    tooltipNationality: "Kansallisuutesi.",
    tooltipDocumentType: "Vaadittu asiakirjatyyppi.",
    tooltipDocumentNumber: "Tämä on asiakirjan tyyppivalintaasi liittyvä asiakirjanumero.",
    tooltipRutNumber: "RUT-numerosi (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipCIIndividualTaxIDBranchLocation: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    tooltipTaxIDRUCorCI: "Löytyy yleensä kuukausittaisesta tiliotteesta tai jos olet edelleen epävarma, kysy pankistasi.",
    errorCPFCode: "Tällä hetkellä hyväksymme vain CPF:n PIX-avaimena."
};