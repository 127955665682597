export default {
    nonAutoApprovedLabelThankYou: "Grazie per la tua richiesta di rimborso.",
    nonAutoApprovedLabelReview: "Il nostro team esaminerà la tua domanda entro i prossimi due giorni lavorativi e ti risponderà con una risposta.",
    nonAutoApprovedLabelExperience: "Come abbiamo fatto?",
    nonAutoApprovedLabelExperienceIntroduction: "Apprezzeremmo molto che ti prendessi il tempo di valutarci e lasciare una recensione poiché apprezziamo l'opinione dei nostri clienti.",
    feedbackExcellent: "Eccellente",
    feedbackGreat: "Benissimo",
    feedbackAverage: "Nella media",
    feedbackPoor: "Povero",
    feedbackBad: "Cattivo",
    labelThankYouForFeedback: "Grazie per il tuo feedback.",
    autoApprovedMessage1: "Siamo lieti di",
    autoApprovedMessage2: "Approvare",
    autoApprovedMessage3: "il tuo rimborso!",
    autoApprovedPayment1Day: "Il pagamento è già stato elaborato e dovrebbe essere nel tuo account in solo 1 giorno lavorativo.",
    autoApprovedPayment2to4Days: "Il pagamento è già stato elaborato e dovrebbe essere nel tuo account in soli 2-4 giorni lavorativi.",
    autoApprovedPayment7to10Days: "Il pagamento è già stato elaborato e dovrebbe essere nel tuo account in soli 7-10 giorni lavorativi.",
    autoApprovedLabelExperience: "Come abbiamo fatto?",
    autoApprovedLabelExperienceIntroduction: "Apprezzeremmo molto che ti prendessi il tempo di valutarci e lasciare una recensione poiché apprezziamo l'opinione dei nostri clienti.",
    modalLabelNegative: "Il tuo feedback è molto importante per noi, facci sapere se desideri che anche noi ci mettiamo in contatto con te.",
    placeholderFeedback: "Valutazione",
    messageFeedbackSubmitted: "Feedback inviato",
    reviewInstructionMessage: "5 stelle è eccellente, 4 stelle è grande, 3 stelle è nella media, 2 stelle è scarsa, 1 stella è cattiva."
}