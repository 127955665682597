import {
    useEffect,
    useState
} from 'react';
import {
    getAutoSaveMinutes,
    getCfarData,
    getLiveChatEnabledStatus,
    getMemberTermsSubDirectory,
    getMsClarityInjection,
    getSelectData
} from '../api/internal';
import {
    AutoSaveMinutesState,
    LiveChatEnabledState,
    MemberTermsSubDirectory,
    MsClarityInjectionState
} from '../state/activity';
import {useQuery} from 'react-query'
import {queryKeys} from '../state/refundQueryKeys';
import {useSetRecoilState} from 'recoil';
import {IDropDownItem} from '../form/Interfaces/IDropDownItem';
import {ICfarResponse} from '../form/Interfaces/ICfarResponse';

const STALE_TIME = Infinity

export function useMemberTermsSubDirectory(memberId: number) {

    const setMemberLink = useSetRecoilState(MemberTermsSubDirectory);

    const {isLoading, data, error} = useQuery([queryKeys.membertermssubdirectory, memberId],
        () => getMemberTermsSubDirectory(memberId), {
            staleTime: STALE_TIME,
            enabled: true
        });

    useEffect(() => {
        if (!isLoading && data && data.hasOwnProperty('data')) {
            setMemberLink(data.data);
        }
    }, [isLoading, data]);

    return {isLoading, error}
}

export function useMsClarityInjection() {

    const setMsClarityInjection = useSetRecoilState(MsClarityInjectionState);

    const {isLoading, data, error} = useQuery([queryKeys.msclarityinjection],
        () => getMsClarityInjection(), {
            staleTime: STALE_TIME,
            enabled: true
        });

    useEffect(() => {
        if (!isLoading && data && data.hasOwnProperty('data')) {
            setMsClarityInjection(data.data);
        }
    }, [isLoading, data]);

    return {isLoading, error}
}

export function useAutoSave() {

    const setAutoSaveMinutes = useSetRecoilState(AutoSaveMinutesState);

    const {isLoading, data, error} = useQuery([queryKeys.autosaveminutes],
        () => getAutoSaveMinutes(), {
            staleTime: STALE_TIME,
            enabled: true
        });

    useEffect(() => {
        if (!isLoading && data && data.hasOwnProperty('data')) {
            setAutoSaveMinutes(data.data);
        }
    }, [isLoading, data]);

    return {isLoading, error}
}

export function useLiveChatEnabledStatus() {

    const setLiveChatEnabledStatus = useSetRecoilState(LiveChatEnabledState);

    const {isLoading, data, error} = useQuery([queryKeys.livechatenabled],
        () => getLiveChatEnabledStatus(), {
            staleTime: STALE_TIME,
            enabled: true
        });

    useEffect(() => {
        if (!isLoading && data && data.hasOwnProperty('data')) {
            setLiveChatEnabledStatus(data.data);
        }
    }, [isLoading, data]);

    return {isLoading, error}
}

export function useGetSelectData(queryKeyId: string, dataSourceUrl: string) {

    const [selectData, setSelectData] = useState<IDropDownItem[]>(<IDropDownItem[]>[]);

    const query = useQuery([`${queryKeys.selectdataprefix}-${queryKeyId}`],
        () => getSelectData(dataSourceUrl), {
            staleTime: STALE_TIME,
            enabled: !!dataSourceUrl
        });

    useEffect(() => {
        if (!query.isLoading && query.data && query.data.hasOwnProperty('data')) {
            setSelectData(query.data.data);
        }
    }, [query.isLoading, query.data]);

    return {selectData}
}

export function useGetCFARResponse(productCode: string, eventDate: Date) {

    const [cfarResponse, setCfarResponse] = useState<ICfarResponse>(<ICfarResponse>{});

    const query = useQuery([queryKeys.cfarresponse],
        () => getCfarData(productCode, eventDate), {
            staleTime: STALE_TIME,
            enabled: !!productCode
        });

    useEffect(() => {
        if (!query.isLoading && query.data && query.data.hasOwnProperty('data')) {
            setCfarResponse(query.data.data);
        }
    }, [query.isLoading, query.data]);

    return {cfarResponse};
}