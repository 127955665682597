export default {
    headingAdverseWeather: "Du upplever svåra ogynnsamma väderförhållanden som gör det osäkert att resa till evenemanget och detta har bemyndigats av ett regeringsorgan (t.ex. lokal myndighet, polis etc.). Och du har bevis för att bevisa detta?",
    headingAdverseWeatherDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    tooltipRefundReasonConfirm: "Ja eller nej",
    labelBookingCancelledByOperator: "Biljetterna avbokades av Operatören/Arrangören.",
    labelBookingRescheduledByOperator: "Datumet ändrades av Operatören/Arrangören och jag kan inte närvara vid de nya datumen.",
    labelBookedWrongDate: "Jag eller en medlem i bokningen, gjorde ett fel vid köp av biljetterna och bokade fel datum.",
    labelBookedWrongTickets: "Jag eller en medlem i bokningen, gjorde ett fel vid köp av biljetterna och bokade fel biljetter.",
    labelBookedTooManyTickets: "Jag eller en medlem i bokningen, gjorde ett fel när jag köpte biljetterna och bokade för många biljetter.",
    labelCOVID19TravelRestrictions: "Jag eller en medlem i bokningen kan inte delta på grund av covid-19-reseförbud.",
    labelADWrongSelection: "Du kan ha valt fel anledning till återbetalning. Läs igenom rullgardinsmenyn Återbetalningsorsak igen.",
    labelAdverseWeather1: "Varför innebar dåligt väder att du inte kunde delta i evenemanget?",
    tooltipAdverseWeather1: "Vänligen detaljer",
    labelAdverseWeather2: "Datum för incident",
    tooltipAdverseWeather2: "Vänligen välj",
    headingArmedForces: "Du blir ombedd av din arbetsgivare som är Försvarsmakten eller Räddningstjänsten att komma i arbete när du inte ursprungligen var planerad att göra det och detta hindrar dig från att delta i evenemanget?",
    headingArmedForcesDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelArmedForces1: "Vilka styrkor eller räddningstjänst är du anställd av?",
    tooltipArmedForces1: "Vänligen välj",
    labelArmedForcesOther1: "Vänligen specificera",
    tooltipArmedForcesOther1: "Vänligen specificera",
    labelArmedForces2: "Datum för incident",
    tooltipArmedForces2: "Vänligen välj",
    headingChangesToExam: "Datumen för din tentamen har ändrats av din skola / högskola etc? Detta är inte en misslyckad tentamen som du måste resit?",
    headingChangesToExamDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelExam1: "Vad var det ursprungliga undersökningsdatumet?",
    tooltipExam1: "Vänligen välj",
    labelExam2: "Vilket är det nya examinationsdatumet?",
    tooltipExam2: "Vänligen välj",
    labelExam3: "Varför ändrades examinationsdatumet?",
    tooltipExam3: "Vänligen ange detaljer",
    labelExam4: "Misslyckades du med föregående tentamen?",
    tooltipExam4: "Ja eller nej",
    headingCourtSummons: "Du har kallats att inställa dig i rätten som vittne samma datum som händelsen?",
    headingCourtSummonsDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelCourtSummons1: "Vad är orsaken till din domstolskallelse?",
    tooltipCourtSummons1: "Vänligen välj",
    labelCourtSummonsOther1: "Vänligen specificera",
    tooltipCourtSummonsOther1: "Vänligen specificera",
    labelCourtSummons2: "När utfärdades domstolskallelserna?",
    tooltipCourtSummons2: "Vänligen välj",
    headingCOVID19Test: "Jag, en medlem i mitt hushåll eller en medlem i gruppen som deltar i evenemanget har diagnostiserats med COVID-19?",
    headingCOVID19TestDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelCOVID19Test1: "Vem har COVID-19?",
    tooltipCOVID19Test1: "Vänligen välj",
    labelCOVID19TestOther1a: "Vänligen specificera",
    labelCOVID19TestOther1b: "Ange namn",
    tooltipCOVID19TestOther1a: "Vänligen specificera",
    tooltipCOVID19TestOther1b: "Ange namn",
    labelCOVID19Test2: "Vilken typ av covid-19-test hade du?",
    tooltipCOVID19Test2: "Vänligen välj",
    labelCOVID19TestOther2: "Vänligen specificera",
    tooltipCOVID19TestOther2: "Vänligen specificera",
    labelCOVID19Test3: "Vad var datumet för ditt COVID-19-test?",
    tooltipCOVID19Test3: "Vänligen välj",
    headingDeath: "Det har skett en död av en omedelbar familjemedlem och det hindrar mig från att delta i evenemanget?",
    headingDeathDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelDeath1: "Vilken familjemedlem har dött?",
    tooltipDeath1: "Vänligen välj",
    labelDeathOther1: "Vänligen specificera",
    tooltipDeathOther1: "Vänligen specificera",
    labelDeath2: "När var dödsdatumet?",
    tooltipDeath2: "Vänligen välj",
    labelDeath3: "Den avlidnes namn",
    tooltipDeath3: "Vänligen specificera",
    headingEmergency: "Jag har haft en verklig nödsituation som ligger utanför min kontroll och oförutsedd som jag kan bevisa för?",
    headingEmergencyDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelEmergency1: "Förklara vilken typ av nödsituation?",
    tooltipEmergency1: "Vänligen detaljer",
    labelEmergency2: "När hände detta?",
    tooltipEmergency2: "Vänligen välj",
    labelEmergency3: "Varför hindrar den här situationen dig från att delta i evenemanget?",
    tooltipEmergency3: "Vänligen detaljer",
    labelEmergency4: "Har du bevis för denna nödsituation?",
    tooltipEmergency4: "Ja eller nej",
    headingHomeEmergency: "Jag har en nödsituation i hemmet som är rån, översvämning, brand, vandalism, våld i hemmet som hindrar mig från att delta i evenemanget?",
    headingHomeEmergencyDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelHomeEmergency1: "Vad är nödsituationen i hemmet?",
    tooltipHomeEmergency1: "Vänligen välj",
    labelHomeEmergencyOther1: "Vänligen specificera",
    tooltipHomeEmergencyOther1: "Vänligen specificera",
    labelHomeEmergency2: "När hände detta?",
    tooltipHomeEmergency2: "Vänligen välj",
    labelHomeEmergency3: "Varför stoppade det dig från att delta i evenemanget?",
    tooltipHomeEmergency3: "Vänligen detaljer",
    headingInjury: "Jag, en medlem av mitt hushåll eller en medlem i gruppen som deltar i evenemanget har diagnostiserats med ett medicinskt tillstånd eller skada som nu hindrar oss från att gå till evenemanget?",
    headingInjuryDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelInjury1: "Vilken typ av sjukdom/skada/olycka har?",
    tooltipInjury1: "Vänligen detaljer",
    labelInjury2: "När hände detta?",
    tooltipInjury2: "Vänligen välj",
    labelInjury3: "Har du besökt en sjukvårdsinrättning för denna sjukdom/skada?",
    tooltipInjury3: "Ja eller nej",
    labelInjury4: "Var detta ett redan existerande medicinskt tillstånd?",
    tooltipInjury4: "Ja eller nej",
    labelInjury5: "När diagnostiserades detta först?",
    tooltipInjury5: "Vänligen välj",
    labelInjury6: "Varför betyder det att du inte kan delta i evenemanget?",
    tooltipInjury6: "Vänligen detaljer",
    labelInjury7: "Vem har drabbats av sjukdomen/skadan?",
    tooltipInjury7: "Vänligen välj",
    headingJuryService: "Jag eller en medlem i bokningen har kallats till Jury Service?",
    headingJuryServiceDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelJuryService1: "När fick du besked om jurytjänstgöring?",
    tooltipJuryService1: "Vänligen välj",
    headingMechanicalBreakdown: "Vårt fordon har haft ett mekaniskt haveri eller varit i en olycka och detta hindrar oss från att delta i evenemanget?",
    headingMechanicalBreakdownDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelMechanicalBreakdown1: "Vad hände med fordonet?",
    tooltipMechanicalBreakdown1: "Vänligen välj",
    labelMechanicalBreakdown2: "När hände detta?",
    tooltipMechanicalBreakdown2: "Vänligen välj",
    labelMechanicalBreakdown3: "Varför kunde ni inte ta alternativa transporter?",
    tooltipMechanicalBreakdown3: "Vänligen detaljer",
    headingPregnancyComplication: "Jag eller en medlem i bokningen har graviditetskomplikationer som har diagnostiserats av en läkare och därför förhindrar närvaro vid evenemanget?",
    headingPregnancyComplicationDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelPregnancyComplication1: "Vad är arten av graviditetskomplikationen?",
    tooltipPregnancyComplication1: "Vänligen detaljer",
    labelPregnancyComplication2: "När hände detta?",
    tooltipPregnancyComplication2: "Vänligen välj",
    labelPregnancyComplication3: "Sökte du läkarvård?",
    tooltipPregnancyComplication3: "Ja eller nej",
    labelPregnancyComplication4: "Varför betyder det att du inte kan delta i evenemanget?",
    tooltipPregnancyComplication4: "Vänligen detaljer",
    headingPublicTransportFailure: "Metoden för kollektivtrafik (tåg, buss, spårvagn, båt, etc.) misslyckades oväntat vilket innebar att det inte fanns något annat sätt jag kunde resa till detta evenemang?",
    headingPublicTransportFailureDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelPublicTransportFailure1: "Vilken typ av kollektivtrafik planerade du att använda?",
    tooltipPublicTransportFailure1: "Vänligen välj",
    labelPublicTransportFailure2: "När planerade du att resa?",
    tooltipPublicTransportFailure2: "Vänligen välj",
    labelPublicTransportFailure3: "Vad hände?",
    tooltipPublicTransportFailure3: "Vänligen detaljer",
    labelPublicTransportFailure4: "Varför kunde ni inte ta alternativa transporter?",
    tooltipPublicTransportFailure4: "Vänligen detaljer",
    headingScheduledAirlineFailure: "Mitt förbokade flyg ställdes oväntat in och jag kunde inte hitta ett lämpligt alternativ vilket innebär att jag inte kunde delta i evenemanget?",
    headingScheduledAirlineFailureDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelScheduledAirlineFailure1: "Flygbolagets namn",
    tooltipScheduledAirlineFailure1: "Flygbolagets namn",
    labelScheduledAirlineFailure2: "Flygnummer",
    tooltipScheduledAirlineFailure2: "Flygnummer",
    labelScheduledAirlineFailure3: "Resa från",
    tooltipScheduledAirlineFailure3: "Resa från",
    labelScheduledAirlineFailure4: "Resa till",
    tooltipScheduledAirlineFailure4: "Resa till",
    labelScheduledAirlineFailure5: "Datum för incident?",
    tooltipScheduledAirlineFailure5: "Vänligen välj",
    labelScheduledAirlineFailure6: "Varför kunde ni inte ta ett alternativt flyg?",
    tooltipScheduledAirlineFailure6: "Vänligen detaljer",
    headingTheftOfDocuments: "Mina dokument stals och det var inte möjligt att ordna ersättning (er) inom den tidsram som behövdes så jag kan inte delta i evenemanget",
    headingTheftOfDocumentsDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelTheftOfDocuments1: "Vilken typ av dokument stals?",
    tooltipTheftOfDocuments1: "Vänligen välj",
    labelTheftOfDocuments2: "När hände detta?",
    tooltipTheftOfDocuments2: "Vänligen välj",
    labelTheftOfDocuments3: "Försökte ni få dokumenten utfärdade på nytt?",
    tooltipTheftOfDocuments3: "Ja eller nej",
    headingWorkRelocation: "Min arbetsgivare har bett mig att ändra platsen för mitt arbete i minst tre månader och avståndet är betydande vilket innebär att jag nu inte kan delta i evenemanget?",
    headingWorkRelocationDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelWorkRelocation1: "Är detta en tillfällig eller permanent flytt av ditt arbete?",
    tooltipWorkRelocation1: "Vänligen välj",
    labelWorkRelocation2: "Hur länge kommer du att vara på den nya adressen?",
    tooltipWorkRelocation2: "Vänligen detaljer",
    labelWorkRelocation3: "Vad är din nuvarande arbetsadress?",
    tooltipWorkRelocation3: "Vänligen detaljer",
    labelWorkRelocation4: "Vad är din nya arbetsadress?",
    tooltipWorkRelocation4: "Vänligen detaljer",
    labelWorkRelocation5: "När fick ni besked om flytten?",
    tooltipWorkRelocation5: "Vänligen välj",
    headingRedundancy: "Jag eller en medlem i bokningen har blivit uppsagd av sin arbetsgivare vilket innebär att jag inte längre kan delta i bokningen?",
    headingRedundancyDecline: "Du bör inte fortsätta med denna ansökan eftersom återbetalningen sannolikt kommer att avvisas.",
    labelRedundancy1: "Typ av anställning?",
    tooltipRedundancy1: "Vänligen välj",
    labelRedundancy2: "Hur länge har du varit anställd i det här företaget?",
    tooltipRedundancy2: "Vänligen välj",
    labelRedundancy3: "Var uppsägningen frivillig?",
    tooltipRedundancy3: "Vänligen välj",
    labelRedundancy4: "När fick du besked om din uppsägning?",
    tooltipRedundancy4: "Vänligen välj",
    labelTestOption: "Välj Ja för att fortsätta",
}