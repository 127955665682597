export default {
    headingAdverseWeather: "Ви відчуваєте серйозні несприятливі погодні умови, які роблять його небезпечним для поїздки на захід, і це було доручено державним органом (наприклад, місцева влада, поліція тощо). І у вас є докази, щоб довести це?",
    headingAdverseWeatherDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    tooltipRefundReasonConfirm: "Так або ні",
    labelBookingCancelledByOperator: "Квитки були анульовані Оператором/Організатором.",
    labelBookingRescheduledByOperator: "Дата була змінена Оператором/Організатором, і я не можу бути присутнім на нових датах.",
    labelBookedWrongDate: "Я або учасник бронювання, допустив помилку при купівлі квитків і забронював не ту дату.",
    labelBookedWrongTickets: "Я або учасник бронювання, допустив помилку при купівлі квитків і забронював неправильні квитки.",
    labelBookedTooManyTickets: "Я або учасник бронювання, допустив помилку при купівлі квитків і забронював занадто багато квитків.",
    labelCOVID19TravelRestrictions: "Я або учасник бронювання не можу бути присутнім через заборону на в'їзд ЧЕРЕЗ COVID-19.",
    labelADWrongSelection: "Можливо, ви вибрали неправильну причину повернення коштів. Будь ласка, перегляньте спадний список Причина повернення коштів ще раз.",
    labelAdverseWeather1: "Чому несприятлива погода означає, що ви не змогли відвідати захід?",
    tooltipAdverseWeather1: "Будь ласка, деталізуйте",
    labelAdverseWeather2: "Дата інциденту",
    tooltipAdverseWeather2: "Будь ласка, виберіть",
    headingArmedForces: "Ваш роботодавець, який є збройними силами або аварійними службами, вас просить вас прийти на роботу, коли ви спочатку не планували це зробити, і це заважає вам відвідати захід?",
    headingArmedForcesDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelArmedForces1: "Якими силами або аварійними службами ви працюєте?",
    tooltipArmedForces1: "Будь ласка, виберіть",
    labelArmedForcesOther1: "Будь ласка, вкажіть",
    tooltipArmedForcesOther1: "Будь ласка, вкажіть",
    labelArmedForces2: "Дата інциденту",
    tooltipArmedForces2: "Будь ласка, виберіть",
    headingChangesToExam: "Дати вашого іспиту були змінені вашою школою / коледжем і т.д.? Це не невдалий іспит, який ви повинні resit?",
    headingChangesToExamDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelExam1: "Якою була початкова дата експертизи?",
    tooltipExam1: "Будь ласка, виберіть",
    labelExam2: "Яка нова дата іспиту?",
    tooltipExam2: "Будь ласка, виберіть",
    labelExam3: "Чому дата іспиту змінилася?",
    tooltipExam3: "Будь ласка, введіть деталі",
    labelExam4: "Ви провалили попередню експертизу?",
    tooltipExam4: "Так або ні",
    headingCourtSummons: "Вас викликали до суду як свідка в ті ж дати, що і подія?",
    headingCourtSummonsDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelCourtSummons1: "У чому причина вашої судової повістки?",
    tooltipCourtSummons1: "Будь ласка, виберіть",
    labelCourtSummonsOther1: "Будь ласка, вкажіть",
    tooltipCourtSummonsOther1: "Будь ласка, вкажіть",
    labelCourtSummons2: "Коли була видана судова повістка?",
    tooltipCourtSummons2: "Будь ласка, виберіть",
    headingCOVID19Test: "У мене, члена моєї сім'ї або члена групи, яка була присутня на заході, діагностували COVID-19?",
    headingCOVID19TestDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelCOVID19Test1: "У кого COVID-19?",
    tooltipCOVID19Test1: "Будь ласка, виберіть",
    labelCOVID19TestOther1a: "Будь ласка, вкажіть",
    labelCOVID19TestOther1b: "Укажіть ім'я",
    tooltipCOVID19TestOther1a: "Будь ласка, вкажіть",
    tooltipCOVID19TestOther1b: "Укажіть ім'я",
    labelCOVID19Test2: "Який тип тесту на COVID-19 у вас був?",
    tooltipCOVID19Test2: "Будь ласка, виберіть",
    labelCOVID19TestOther2: "Будь ласка, вкажіть",
    tooltipCOVID19TestOther2: "Будь ласка, вкажіть",
    labelCOVID19Test3: "Якою була дата вашого тесту на COVID-19?",
    tooltipCOVID19Test3: "Будь ласка, виберіть",
    headingDeath: "Там була смерть найближчого члена моєї сім'ї, і це заважає мені бути присутнім на заході?",
    headingDeathDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelDeath1: "Хто з членів вашої родини помер?",
    tooltipDeath1: "Будь ласка, виберіть",
    labelDeathOther1: "Будь ласка, вкажіть",
    tooltipDeathOther1: "Будь ласка, вкажіть",
    labelDeath2: "Коли була дата смерті?",
    tooltipDeath2: "Будь ласка, виберіть",
    labelDeath3: "Ім'я покійного",
    tooltipDeath3: "Будь ласка, вкажіть",
    headingEmergency: "У мене була справжня надзвичайна ситуація, яка знаходиться поза моїм контролем і непередбачена, для якої я можу надати докази?",
    headingEmergencyDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelEmergency1: "Поясніть, будь ласка, тип надзвичайної ситуації?",
    tooltipEmergency1: "Будь ласка, деталізуйте",
    labelEmergency2: "Коли це сталося?",
    tooltipEmergency2: "Будь ласка, виберіть",
    labelEmergency3: "Чому ця ситуація заважає вам відвідувати захід?",
    tooltipEmergency3: "Будь ласка, деталізуйте",
    labelEmergency4: "Чи є у вас докази цієї надзвичайної ситуації?",
    tooltipEmergency4: "Так або ні",
    headingHomeEmergency: "У мене є надзвичайна ситуація вдома, яка є пограбування, повінь, вогонь, вандалізм, домашнє насильство, яке заважає мені відвідати захід?",
    headingHomeEmergencyDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelHomeEmergency1: "Що таке надзвичайна ситуація в домашніх умовах?",
    tooltipHomeEmergency1: "Будь ласка, виберіть",
    labelHomeEmergencyOther1: "Будь ласка, вкажіть",
    tooltipHomeEmergencyOther1: "Будь ласка, вкажіть",
    labelHomeEmergency2: "Коли це сталося?",
    tooltipHomeEmergency2: "Будь ласка, виберіть",
    labelHomeEmergency3: "Чому це завадило вам відвідати захід?",
    tooltipHomeEmergency3: "Будь ласка, деталізуйте",
    headingInjury: "У мене, члена моєї сім'ї або члена групи, яка була присутня на заході, був діагностований медичний стан або травма, яка тепер заважає нам їхати на захід?",
    headingInjuryDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelInjury1: "Яка природа хвороби / травми / нещасного випадку?",
    tooltipInjury1: "Будь ласка, деталізуйте",
    labelInjury2: "Коли це сталося?",
    tooltipInjury2: "Будь ласка, виберіть",
    labelInjury3: "Чи відвідували Ви медичний заклад з приводу цієї хвороби/травми?",
    tooltipInjury3: "Так або ні",
    labelInjury4: "Чи був це вже існуючий медичний стан?",
    tooltipInjury4: "Так або ні",
    labelInjury5: "Коли це було вперше діагностовано?",
    tooltipInjury5: "Будь ласка, виберіть",
    labelInjury6: "Чому це означає, що ви не можете бути присутнім на заході?",
    tooltipInjury6: "Будь ласка, деталізуйте",
    labelInjury7: "Хто переніс хворобу/травму?",
    tooltipInjury7: "Будь ласка, виберіть",
    headingJuryService: "Я або член бронювання був викликаний в службу присяжних?",
    headingJuryServiceDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelJuryService1: "Коли вас повідомили про службу присяжних?",
    tooltipJuryService1: "Будь ласка, виберіть",
    headingMechanicalBreakdown: "Наш автомобіль мав механічну поломку або потрапив в аварію, і це заважає нам відвідати захід?",
    headingMechanicalBreakdownDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelMechanicalBreakdown1: "Що сталося з автомобілем?",
    tooltipMechanicalBreakdown1: "Будь ласка, виберіть",
    labelMechanicalBreakdown2: "Коли це сталося?",
    tooltipMechanicalBreakdown2: "Будь ласка, виберіть",
    labelMechanicalBreakdown3: "Чому ви не можете скористатися альтернативним транспортом?",
    tooltipMechanicalBreakdown3: "Будь ласка, деталізуйте",
    headingPregnancyComplication: "Я або член бронювання має ускладнення вагітності, які були діагностовані медичним працівником і, отже, запобігти відвідуванню заходу?",
    headingPregnancyComplicationDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelPregnancyComplication1: "Яка природа ускладнення вагітності?",
    tooltipPregnancyComplication1: "Будь ласка, деталізуйте",
    labelPregnancyComplication2: "Коли це сталося?",
    tooltipPregnancyComplication2: "Будь ласка, виберіть",
    labelPregnancyComplication3: "Ви звернулися за медичною допомогою?",
    tooltipPregnancyComplication3: "Так або ні",
    labelPregnancyComplication4: "Чому це означає, що ви не можете бути присутнім на заході?",
    tooltipPregnancyComplication4: "Будь ласка, деталізуйте",
    headingPublicTransportFailure: "Спосіб громадського транспорту (поїзд, автобус, трамвай, човен і т.д.) несподівано провалився, що означає, що не було іншого способу, яким я міг би поїхати на цю подію?",
    headingPublicTransportFailureDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelPublicTransportFailure1: "Яким видом громадського транспорту ви планували користуватися?",
    tooltipPublicTransportFailure1: "Будь ласка, виберіть",
    labelPublicTransportFailure2: "Коли ви планували подорожувати?",
    tooltipPublicTransportFailure2: "Будь ласка, виберіть",
    labelPublicTransportFailure3: "Що сталося?",
    tooltipPublicTransportFailure3: "Будь ласка, деталізуйте",
    labelPublicTransportFailure4: "Чому не можна користуватися альтернативним транспортом?",
    tooltipPublicTransportFailure4: "Будь ласка, деталізуйте",
    headingScheduledAirlineFailure: "Мій попередньо заброньований рейс несподівано скасовано, і я не зміг знайти підходящу альтернативу, що означає, що я не зміг бути присутнім на заході?",
    headingScheduledAirlineFailureDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelScheduledAirlineFailure1: "Назва авіакомпанії",
    tooltipScheduledAirlineFailure1: "Назва авіакомпанії",
    labelScheduledAirlineFailure2: "Номер рейсу",
    tooltipScheduledAirlineFailure2: "Номер рейсу",
    labelScheduledAirlineFailure3: "Подорож з",
    tooltipScheduledAirlineFailure3: "Подорож з",
    labelScheduledAirlineFailure4: "Подорож до",
    tooltipScheduledAirlineFailure4: "Подорож до",
    labelScheduledAirlineFailure5: "Дата інциденту?",
    tooltipScheduledAirlineFailure5: "Будь ласка, виберіть",
    labelScheduledAirlineFailure6: "Чому ви не змогли здійснити альтернативний рейс?",
    tooltipScheduledAirlineFailure6: "Будь ласка, деталізуйте",
    headingTheftOfDocuments: "Мої документи були вкрадені, і не було можливості організувати заміну (и) протягом необхідних термінів, тому я не можу бути присутнім на заході",
    headingTheftOfDocumentsDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelTheftOfDocuments1: "Який тип документа був вкрадений?",
    tooltipTheftOfDocuments1: "Будь ласка, виберіть",
    labelTheftOfDocuments2: "Коли це сталося?",
    tooltipTheftOfDocuments2: "Будь ласка, виберіть",
    labelTheftOfDocuments3: "Ви намагалися переоформити документи?",
    tooltipTheftOfDocuments3: "Так або ні",
    headingWorkRelocation: "Мій роботодавець попросив мене змінити місце роботи принаймні на три місяці, і відстань має значне значення, що означає, що я тепер не можу бути присутнім на заході?",
    headingWorkRelocationDecline: "Ви не повинні продовжувати цю заявку, оскільки відшкодування, швидше за все, буде відхилено.",
    labelWorkRelocation1: "Це тимчасове або постійне перенесення вашої роботи?",
    tooltipWorkRelocation1: "Будь ласка, виберіть",
    labelWorkRelocation2: "Як довго ви будете перебувати за новою адресою?",
    tooltipWorkRelocation2: "Будь ласка, деталізуйте",
    labelWorkRelocation3: "Яка ваша поточна робоча адреса?",
    tooltipWorkRelocation3: "Будь ласка, деталізуйте",
    labelWorkRelocation4: "Яка ваша нова робоча адреса?",
    tooltipWorkRelocation4: "Будь ласка, деталізуйте",
    labelWorkRelocation5: "Коли ви були повідомлені про це переселення?",
    tooltipWorkRelocation5: "Будь ласка, виберіть",
    headingRedundancy: "Мене або учасника бронювання роботодавець зробив зайвим, тобто я більше не можу бути присутнім на бронюванні?",
    headingRedundancyDecline: "Ви не повинні продовжувати цю заявку, оскільки повернення коштів, швидше за все, буде відхилено.",
    labelRedundancy1: "Вид зайнятості?",
    tooltipRedundancy1: "Будь ласка, виберіть",
    labelRedundancy2: "Як давно ви працюєте в цій компанії?",
    tooltipRedundancy2: "Будь ласка, виберіть",
    labelRedundancy3: "Чи було звільнення добровільним?",
    tooltipRedundancy3: "Будь ласка, виберіть",
    labelRedundancy4: "Коли вас повідомили про звільнення?",
    tooltipRedundancy4: "Будь ласка, виберіть",
    labelTestOption: "Виберіть Так, щоб продовжити",
}