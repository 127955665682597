export default {
    labelBookingCancelledPostponedHeading: "Votre réservation a été annulée ou reportée ? En savoir plus",
    labelBookingCancelledPostponedText1: "Cliquez sur",
    labelBookingCancelledPostponedText2: "pour plus d'informations.",
    labelNewRefundHeading: "Nouvelle demande de remboursement?",
    labelNewRefundText: "Entrez votre référence de réservation ici ...",
    placeholderBookingReference: "Référence de réservation",
    validationBookingReference: "Une référence de réservation est requise",
    validationEmailAsBookingReference: "L'adresse e-mail n'est pas acceptée comme référence de réservation",
    buttonFindBooking: "Trouver une réservation",
    labelEmailHeading: "Déja commencé?",
    labelEmailText: "Envoyez un e-mail de rappel pour récupérer votre candidature.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Rappel par e-mail",
    labelCustomerName : "Nom du client",
    validationCustomerName: "Le nom du client est requis",
    labelTypeOfBooking: "Type de réservation",
    validationTypeOfBooking: "Le type de réservation est requis",
    labelEventName:"Nom de l'événement",
    labelVenueName:"Nom de la place",
    labelFlightNumber: "Numéro de vol",
    labelOriginAirport: "Aéroport d'origine",
    labelHotelName: "Nom de l'hébergement",
    labelHotelLocation: "Lieu d'hébergement",
    labelCurrency: "Devise",
    validationCurrency: "La devise est requise",
    validationCountryCurrency: "La devise sélectionnée doit être la devise de votre pays d'origine",
    validationOfflinePayment: "Nous ne pouvons pas traiter les remboursements pour votre sélection de devise et de pays, veuillez sélectionner des alternatives",
    labelBookingValue: "Valeur de réservation",
    validationBookingValue: "La valeur de réservation est requise",
    validationBookingValueAmount: "Doit être supérieur à 0",
    labelDateOfPurchase: "Date d'achat",
    validationDateOfPurchase: "La date d'achat est requise",
    labelEmailAddress: "Adresse électronique",
    validationEmailAddress: "Adresse e-mail est nécessaire",
    validationEmailAddressFormat: "Format d'e-mail incorrect",
    labelConfirmEmailAddress: "Confirmez votre adresse email",
    validationConfirmEmailAddressFormat: "Format d'e-mail incorrect",
    validationConfirmEmailAddress: "L'adresse e-mail de confirmation est requise",
    validationConfirmEmailAddressMatch: "les adresses e-mail ne correspondent pas",
    validationEmailDomain: "Ce fournisseur de messagerie n'est pas accepté, veuillez utiliser une alternative",
    validationDateOfEvent: "La date de l'événement est requise",
    labelHomeCountry: "Pays natal",
    validationHomeCountry: "Le pays d'origine est obligatoire",
    labelContactNumberCountryCode: "Numéro de contact Code du pays",
    validationContactNumberCountryCode: "Le code de numérotation du pays est requis",
    labelContactNumber: "Numéro de contact",
    validationContactNumber: "Le numéro de contact est requis",
    labelAddressLine1: "Adresse 1",
    labelAddressLine2: "Adresse Ligne 2",
    labelCity: "Ville",
    labelZipCode: "Code postal",
    labelRegion: "Région",
    validationRegion: "La région est requise",
    labelNumberOfTicketsToRefund: "Nombre de billets à rembourser",
    validationNumberOfTicketsToRefund: "Le nombre de billets à rembourser est requis",
    validationNumberOfTicketsToRefundValue: "Doit être supérieur à 0",
    labelCostPerTicket: "Coût de chaque billet",
    validationCostPerTicket: "Le coût par billet est obligatoire",
    validationCostPerTicketValue: "Doit être supérieur à 0",
    labelTotalRefundAmount: "Montant total du remboursement",
    validationTotalRefundAmount: "Le montant total du remboursement est requis",
    validationTotalRefundAmountValue: "Doit être supérieur à 0",
    validationTotalRefundGreaterThanBookingValue: "Le montant total du remboursement ne peut pas être supérieur à la valeur de réservation ci-dessus",
    labelBookingConfirmationFiles: "Télécharger la confirmation de réservation",
    validationBookingConfirmationFiles: "Joignez au moins un fichier ou cliquez sur Enregistrer et revenir plus tard lorsque vous avez les documents",
    validationEvidenceFilesPopup: "Les preuves doivent être téléchargées avant la soumission. Si vous n'avez pas les preuves maintenant, veuillez enregistrer la demande et la retourner une fois que vous les avez. Nous acceptons les copies électroniques, les copies numérisées et les photos.",
    validationBookingConfirmationFilesPopup: "La confirmation de réservation doit être téléchargée avant de la soumettre. Si vous n'avez pas la confirmation de réservation en ce moment, veuillez enregistrer la demande et la retourner une fois que vous l'avez. Nous acceptons les copies électroniques, les copies numérisées et les photos.",
    alertUploadDocuments: "Veuillez télécharger la documentation pertinente détaillée dans l'e-mail, puis passer à l'étape 3.",
    labelRefundReasonCategory: "Raison du remboursement",
    validationRefundReasonCategory: "La raison du remboursement est requise",
    labelDateOfIncident: "Date de l'incident",
    labelAdditionalComments: "Commentaires supplémentaires",
    tooltipAdditionalComments: "Commentaires supplémentaires",
    validationAdditionalComments: "Des commentaires supplémentaires sont requis",
    labelUnableToNotifyWithinTimeLimit: "Impossible de notifier dans les 60 jours suivant",
    labelReasonForNotificationOutsideTimeLimit: "Notification en dehors de la limite de temps Explication",
    validationReasonForNotificationOutsideTimeLimit: "Un motif de notification après 60 jours est requis",
    labelRefundReasonFiles: "Télécharger des preuves",
    validationRefundReasonFiles: "Joignez au moins un fichier ou cliquez sur Enregistrer et revenir plus tard lorsque vous avez les documents",
    validationTextScript: "Doit correspondre à l'écriture latine",
    optionTypeOfBooking1: "Un événement",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Vol",
    optionTypeOfBooking4: "Hébergement",
    optionTypeOfBooking5: "Transfert",
    optionTypeOfBooking6: "Parking",
    optionTypeOfBooking7: "Autres voyages",
    labelEventTypeDate: "Date de l'évènement",
    labelEventTypeTour: "Date du tour",
    labelEventTypeFlight: "Date du vol",
    labelEventTypeCheckIn: "Date d'arrivée",
    labelEventTypeTransfer: "Date du transfert",
    labelEventTypeParking: "Date de stationnement",
    labelEventTypeOtherTravel: "Date du voyage",
    optionRefundReason11: "Des conditions météorologiques défavorables",
    optionRefundReason10: "Rappel des forces armées et des services d'urgence",
    optionRefundReason15: "Modifications des dates d'examen",
    optionRefundReason18: "Convocation à la cour",
    optionRefundReason17: "Restrictions de voyage dans le contexte du covid-19",
    optionRefundReason19: "Test positif COVID-19",
    optionRefundReason20: "Réservation annulée par l'opérateur/l'organisateur",
    optionRefundReason21: "Réservation reportée par l'opérateur/l'organisateur",
    optionRefundReason22: "Mauvaise date de réservation",
    optionRefundReason23: "Mauvais billets réservés",
    optionRefundReason24: "J'ai réservé trop de billets",
    optionRefundReason4: "Décès",
    optionRefundReason16: "Circonstances d'urgence",
    optionRefundReason14: "Interdiction de voyager par le gouvernement",
    optionRefundReason9: "Urgence à domicile",
    optionRefundReason1: "Maladie/blessure/accident",
    optionRefundReason8: "Service du jury",
    optionRefundReason7: "Panne mécanique",
    optionRefundReason2: "Condition médicale préexistante",
    optionRefundReason3: "Complication de grossesse",
    optionRefundReason5: "Échec des transports publics",
    optionRefundReason6: "Perturbation du vol",
    optionRefundReason13: "Vol de document(s)",
    optionRefundReason12: "Relocalisation de travail",
    optionRefundReason25: "Redondance du lieu de travail",
    optionRefundReason26: "Option de test",
    optionRefundReasonConfirm1: "Non",
    optionRefundReasonConfirm2: "Oui",
    optionRefundReasonArmedForces1: "Armée",
    optionRefundReasonArmedForces2: "Marine",
    optionRefundReasonArmedForces3: "Armée de l’air",
    optionRefundReasonArmedForces4: "Forces spéciales",
    optionRefundReasonArmedForces5: "Troupes de marine",
    optionRefundReasonArmedForces6: "Feu",
    optionRefundReasonArmedForces7: "Ambulance",
    optionRefundReasonArmedForces8: "Médecin/Infirmière",
    optionRefundReasonArmedForces9: "Dentiste",
    optionRefundReasonArmedForces10: "Autre",
    optionRefundReasonCourtSummons1: "Poursuites pénales",
    optionRefundReasonCourtSummons2: "Affaire civile",
    optionRefundReasonCourtSummons3: "Témoin à charge",
    optionRefundReasonCourtSummons4: "Témoin dans une affaire civile",
    optionRefundReasonCourtSummons5: "Plaignant",
    optionRefundReasonCourtSummons6: "Accusé",
    optionRefundReasonCourtSummons7: "Autre",
    optionRefundReasonDeath1: "Mari",
    optionRefundReasonDeath2: "Femme",
    optionRefundReasonDeath3: "Son",
    optionRefundReasonDeath4: "Fille",
    optionRefundReasonDeath5: "Père",
    optionRefundReasonDeath6: "Mère",
    optionRefundReasonDeath7: "Frère",
    optionRefundReasonDeath8: "Soeur",
    optionRefundReasonDeath9: "Grand-père",
    optionRefundReasonDeath10: "Grand-mère",
    optionRefundReasonDeath11: "Autre",
    optionRefundReasonHomeEmergency1: "Feu",
    optionRefundReasonHomeEmergency2: "Inondation",
    optionRefundReasonHomeEmergency3: "Vol",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Violence conjugale",
    optionRefundReasonHomeEmergency6: "Kidnapper",
    optionRefundReasonHomeEmergency7: "Autre",
    optionRefundReasonCOVID19Person1: "Moi-même",
    optionRefundReasonCOVID19Person2: "Une autre personne dans la réservation",
    optionRefundReasonCOVID19Person3: "Une personne de mon ménage",
    optionRefundReasonCOVID19Person4: "Autre",
    optionRefundReasonCOVID19TestType1: "PCR Test",
    optionRefundReasonCOVID19TestType2: "RAT Test",
    optionRefundReasonCOVID19TestType3: "Essai d’écoulement latéral",
    optionRefundReasonCOVID19TestType4: "Test auto-administré",
    optionRefundReasonCOVID19TestType5: "Pas de test mais j’ai des symptômes",
    optionRefundReasonCOVID19TestType6: "Autre",
    optionRefundReasonBreakdown1: "Panne",
    optionRefundReasonBreakdown2: "Accident",
    optionRefundReasonBreakdown3: "Feu",
    optionRefundReasonBreakdown4: "Vol",
    optionRefundReasonPublicTransportType1: "Train",
    optionRefundReasonPublicTransportType2: "Bus",
    optionRefundReasonPublicTransportType3: "Tramway",
    optionRefundReasonPublicTransportType4: "Bateau",
    optionRefundReasonDocumentType1: "Passeport",
    optionRefundReasonDocumentType2: "Permis de voyage",
    optionRefundReasonDocumentType3: "Carte d’identité nationale",
    optionRefundReasonDocumentType4: "Billets",
    optionRefundReasonWorkRelocationType1: "Temporaire",
    optionRefundReasonWorkRelocationType2: "Permanent",
    optionRefundReasonWorkRelocationTemporary1: "0-3 mois",
    optionRefundReasonWorkRelocationTemporary2: "3-6 mois",
    optionRefundReasonWorkRelocationTemporary3: "Plus de 6 mois",
    optionRefundReasonBookingRelationshipType1: "Quelqu’un dans la réservation",
    optionRefundReasonBookingRelationshipType2: "Parent immédiat d’une personne dans la réservation",
    optionEmploymentType1: "Temps plein",
    optionEmploymentType2: "Temps partiel",
    optionEmploymentType3: "Contrat",
    optionEmploymentLengthType1: "0-1 ans",
    optionEmploymentLengthType2: "1-2 ans",
    optionEmploymentLengthType3: "Plus de 2 ans",
    textRefundEvidenceHeading: "Veuillez fournir les preuves suivantes:",
    textRefundEvidence1: "Confirmation de la compagnie aérienne",
    textRefundEvidence2: "Toute preuve à l’appui appropriée",
    textRefundEvidence3: "Confirmation d’appel de service de dépannage",
    textRefundEvidence4: "Acte de décès",
    textRefundEvidence5: "Confirmation d’annulation de la compagnie aérienne",
    textRefundEvidence6: "Météo",
    textRefundEvidence7: "Correspondance de l’agent de réservation confirmant qu’il n’est pas possible de réémettre des billets",
    textRefundEvidence8: "Lettre de la Cour",
    textRefundEvidence9: "Certificat médical / Note du médecin",
    textRefundEvidenceDescription9: "Confirmer les détails de la maladie ou de la blessure, la date à laquelle elle s’est produite pour la première fois, qu’elle vous empêche d’assister à la réservation.",
    textRefundEvidence10: "Confirmation de la lettre de l’employeur",
    textRefundEvidence11: "Correspondance de réclamation d’assurance",
    textRefundEvidence12: "Lettre de l’employeur",
    textRefundEvidence13: "Confirmation du site Web du gouvernement national",
    textRefundEvidence14: "Avis de modification d’examen",
    textRefundEvidence15: "Rapport de police",
    textRefundEvidence16: "Rapport de police et numéro de référence",
    textRefundEvidence17: "Confirmation du fournisseur de transport",
    textRefundEvidence18: "Avertissement aux voyageurs",
    textRefundEvidence20: "Certificat de test COVID-19",
    textRefundEvidence24: "Avis d’isolement du gouvernement",
    textRefundEvidence25: "Lettre du commandant",
    textRefundEvidence26: "Lettre des assureurs",
    textRefundEvidence27: "Preuve d’adresse",
    textRefundEvidence28: "Preuve de lien de parenté avec le défunt",
    textRefundEvidence29: "Preuve de relation avec la personne blessée/malade",
    textRefundEvidence30: "Fiche de paie",
    tooltipBookingReference: "Pour identifier votre référence de réservation, vérifiez votre confirmation de réservation et consultez la section relative à notre service étendu.",
    tooltipCustomerName: "Nom et prénom utilisés pour effectuer la réservation.",
    tooltipTypeOfBooking: "Type de réservation que vous souhaitez être remboursé.",
    tooltipEventName: "Le nom de l'évènement.",
    tooltipVenueName: "Le nom du lieu.",
    tooltipFlightNumber: "Le numéro de vol.",
    tooltipOriginAirport: "L'aéroport de départ.",
    tooltipHotelName: "Le nom de l'hébergement.",
    tooltipHotelLocation: "L'emplacement de l'hébergement.",
    tooltipCurrency: "La devise utilisée pour payer la réservation.",
    tooltipBookingValue: "Prix ​​total que vous avez payé pour la réservation.",
    tooltipDateOfPurchase: "La date à laquelle la réservation a été effectuée.",
    tooltipEmailAddress: "Veuillez utiliser une adresse mail valide. Nous utilisons cet e-mail pour la correspondance lors de la demande de remboursement.",
    tooltipConfirmEmailAddress: "Utilisez la même adresse e-mail que ci-dessus, c'est pour la validation.",
    tooltipHomeCountry: "Cela nous aidera à obtenir les informations bancaires correctes lors de la dernière étape.",
    tooltipContactNumberCountryCode: "Code pays du numéro de contact.",
    tooltipContactNumber: "Veuillez utiliser un numéro valide. Nous l'utilisons pour la correspondance lors de la demande de remboursement.",
    tooltipAddressLine1: "Veuillez noter que votre adresse sera utilisée lors de la validation de votre compte bancaire.",
    tooltipAddressLine2: "Deuxième ligne d'adresse.",
    tooltipCity: "Votre ville de résidence, les détails peuvent être utilisés pour valider les coordonnées bancaires.",
    tooltipZipCode: "Code postal.",
    tooltipRegion: "Région de votre ville d'origine, les détails peuvent être utilisés pour valider les coordonnées bancaires.",
    tooltipNumberOfTicketsToRefund: "Le nombre de billets que vous souhaitez être remboursé.",
    tooltipTotalRefundAmount: "Cela ne peut pas être supérieur à la valeur totale de la réservation, vous ne pourrez pas soumettre la demande si c'est le cas.",
    tooltipBookingConfirmationFiles: "Capture d'écran / pièce jointe de votre confirmation de réservation complète qui se trouve généralement dans vos e-mails.",
    tooltipRefundReasonCategory: "Veuillez sélectionner votre motif de remboursement.",
    tooltipDateOfIncident: "La date à laquelle l'incident qui a eu un impact sur votre réservation s'est produit.",
    tooltipRefundExplanation: "Veuillez donner autant de détails que possible à l'appui de la demande de remboursement.",
    tooltipUnableToNotifyWithinTimeLimit: "Impossible de notifier dans un délai de 60 jours.",
    tooltipReasonForNotificationOutsideTimeLimit: "Notification en dehors de la limite de temps Explication.",
    tooltipRefundReasonFiles: "Veuillez télécharger l'un des documents requis.",
    tooltipEventTypeDate: "Date de l'événement / de la réservation réelle.",
    tooltipEventTypeTour: "Date de la tournée réelle.",
    tooltipEventTypeFlight: "Date du vol réel.",
    tooltipEventTypeCheckIn: "Date de l'enregistrement effectif.",
    tooltipEventTypeTransfer: "Date du transfert effectif.",
    tooltipEventTypeParking: "Date du stationnement effectif.",
    tooltipEventTypeOtherTravel: "Date de début du voyage réel."
};