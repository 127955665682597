export default {
    nonAutoApprovedLabelThankYou: "Қайтару туралы өтінішіңіз үшін рахмет.",
    nonAutoApprovedLabelReview: "Біздің команда алдағы екі жұмыс күні ішінде өтінішіңізді қарап, жауаппен сізге қайта оралады.",
    nonAutoApprovedLabelExperience: "Біз қалай істедік?",
    nonAutoApprovedLabelExperienceIntroduction: "Біз сіздердің бізді бағалап, клиенттеріміздің пікірін бағалайтындықтан шолу қалдыруға уақыт алғаныңызды өте жоғары бағалаймыз.",
    feedbackExcellent: "Тамаша",
    feedbackGreat: "Үлкен",
    feedbackAverage: "Орташа",
    feedbackPoor: "Кедейлер",
    feedbackBad: "Бад",
    labelThankYouForFeedback: "Пікірлеріңіз үшін рахмет.",
    autoApprovedMessage1: "Біз қуанамыз",
    autoApprovedMessage2: "Бекіту",
    autoApprovedMessage3: "сіздің қайтарымыңыз!",
    autoApprovedPayment1Day: "Сіздің төлеміңіз өңделді және ол сіздің тіркелгіңізде небәрі 1 жұмыс күнінде болуы тиіс.",
    autoApprovedPayment2to4Days: "Сіздің төлеміңіз өңделді және 2-4 жұмыс күні ішінде тіркелгіңізде болуы тиіс.",
    autoApprovedPayment7to10Days: "Сіздің төлеміңіз өңделді және 7-10 жұмыс күні ішінде тіркелгіңізде болуы тиіс.",
    autoApprovedLabelExperience: "Біз қалай істедік?",
    autoApprovedLabelExperienceIntroduction: "Біз сіздердің бізді бағалап, клиенттеріміздің пікірін бағалайтындықтан шолу қалдыруға уақыт алғаныңызды өте жоғары бағалаймыз.",
    modalLabelNegative: "Сіздің пікірлеріңіз біз үшін шынымен де маңызды, сізбен де байланысқа шыққыңыз келсе, бізге хабарлаңыз.",
    placeholderFeedback: "Кері байланыс",
    messageFeedbackSubmitted: "Жіберілген пікірлер",
    reviewInstructionMessage: "5 жұлдыз өте жақсы, 4 жұлдызы үлкен, 3 жұлдызы орташа, 2 жұлдызы нашар, 1 жұлдызы жаман."
}