export default {
    messageUnableToMakePaymentToCurrency: "죄송하지만, 선택한 통화에 대한 은행 세부정보를 검색할 수 없습니다. 통화 및 본국이 선택되어 있는지 확인하십시오.",
    messageOnlyBankAccountsAccepted: "참고 : 현재 은행 계좌로만 환불이 진행됩니다(신용 및 직불 카드는 허용되지 않음).",
    labelAccountName: "예금주 이름",
    labelBankAccountName: "계좌 소유주 성명",
    labelBankCardHolderName: "카드 소지자 이름",
    validationBankAccountName: "계좌 소유자의 전체 이름이 필요합니다.",
    labelBankCountry: "은행 국가",
    labelBankName: " 은행명",
    labelPaymentType: "지불 방식",
    labelInstitutionNumber: "기관 번호",
    labelBankAddress: " 계좌 번호",
    labelBankAddress2: " 계좌 번호",
    labelBankCity: " 은행  도시",
    labelBankState: " 은행 주/도",
    labelBankAccountNumber: "은행 계좌 번호",
    labelBankAccountNumberCBU: "계좌 번호 CBU",
    labelBankAccountNumberCCI: "계좌 번호 CCI",
    labelBankAccountNumberZAR: "은행계좌번호(7~11자리)",
    labelCardNumber: "카드 번호",
    labelUnionpay: "유니온 페이 카드 번호",
    labelAlipay: "Alipay ID (휴대폰 번호 또는 이메일)",
    labelBankAccountType: "계정 유형",
    optionBankAccountTypeCURRENT: "당좌 계좌",
    optionBankAccountTypeCHECKING: "수표 계좌",
    optionBankAccountTypeSAVING: "예금 계좌",
    optionBankAccountTypeSAVINGS: "저축 예금 계좌",
    optionBankAccountTypeCUENTA_VISTA: "마스터 계정",
    optionBankAccountType1: "계좌 확인",
    optionBankAccountType2: "예금 계좌",
    optionBankAccountType3: "마스터 계정",
    optionBankAccountType4: "비스타 계정",
    optionBankAccountType5: "RUT 계정",
    labelSortCode: " 식별 코드",
    labelRoutingCode: " 라우팅 코드",
    labelBankRoutingCodeNationalID: " 은행 라우팅 코드 / 국가 ID",
    labelBankTransitCodeSwift: "SWIFT코드",
    labelCNAPSCode: "라우팅 코드 / CNAPS 코드 (중국 국가 선불 시스템 코드)",
    labelABAFedwireRoutingCode: "ACH 라우팅 번호",
    labelIFSCCode: "IFSC 코드",
    labelBankCode: "은행 코드",
    labelBIKCode: "빅코드",
    labelBranchCode: "지점 코드",
    labelBranchCodeUniversal: "범용 6 자리 지점 코드",
    labelRemittanceLine3: "수령인 연락처 이름 및 전화 번호",
    labelBeneficiaryContactNameandTelephoneNumber: "수령인 연락처 이름 및 전화 번호",
    labelBeneficiaryDateofBirthandPlaceofBirth: "수령인 생년월일 및 출생지",
    labelRemittanceLine4: "개인 세금 번호 CUIL (9-12 자리)",
    labelCUILNumber: "세금 ID : CUIL / CUIT",
    labelTaxIDRFC13DigitsORCURP18digits: "세금 ID RFC 13 자리 또는 CURP 18 자리",
    labelIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "개인 납세자 ID CPF 개인납세자 등록 (11-14 자리), 지점 코드",
    labelCFPNumber: "CPF 번호 (11-14 자리)",
    labelBranchNameorBranchCode7digits: "지점 이름 또는 지점 코드 (7 자리)",
    labelCedulaJuridica910digitsTaxID: "법적 증명서 (세금 ID 9 ~ 10 자리)",
    labelTaxIDRUT89digits: "세금 ID RUT (8-9 자리)",
    labelJob: "직위",
    labelDateOfBirth: "생년월일",
    labelNationality: "국적",
    labelDocumentType: "문서 유형",
    labelDocumentNumber: "문서 번호",
    labelRutNumber: "RUT 번호 (Rol Único Tributario)",
    labelCedulaTaxIDIndividual811digits: "신분증 (세금 ID 개별 8-11 자리)",
    labelCIIndividualTaxIDBranchLocation: "CI (개별 세금 ID), 지점 위치",
    labelSortCodeHelp: "도움이 필요하십니까?",
    labelBranchCodeHelp: "도움이 필요하십니까?",
    labelTaxIDRUCorCI: "세금 ID (RUC 또는 CI)",
    labelIBAN: "IBAN코드",
    labelIBANHelp: "도움이 필요하십니까?",
    labelCLABE: "CLABE",
    labelBeneficiarySWIFTBIC: "SWIFT / BIC",
    labelBeneficiarySWIFTBICHelp: "도움이 필요하십니까?",
    labelRoutingCodeBSB: "라우팅 코드 / BSB",
    labelBSB: "은행 지점(BSB)",
    labelSKNCode: "SKN 코드",
    labelAcceptanceFlights: "본인/우리는 환불 신청이 성공할 경우 모든 항공편 및 부대 상품을 포함한 예약이 취소됨을 인정합니다.",
    labelAcceptanceTickets: "본인/우리는 환불 신청이 성공할 경우 모든 티켓 및 부대 상품을 포함한 예매가 취소됨을 인정합니다.",
    labelTermsAndConditions1: "이용약관에",
    labelTermsAndConditions2: "동의합니다",
    labelFraudWarning1: "본인은 이 환불 신청서에 기재된 내용이 사실이며 사실임을 증명합니다. 본인은 허위 정보나 증거를 제출할 시, 범죄 행위로 간주되어 경찰이나 기타 법 집행 기관에 전달되어 추적할 수 있음을 이해합니다.",
    labelFraudWarning2: "증빙 서류와 관련하여 위법사항이 의심되는 경우 이 문제는 즉시 지역 경찰 당국에 회부됩니다. 본인은 내 신청서가 위법행위로 간주되어 범죄 조사가 될 수 있는 경우 나의 세부 정보가 당국에 전달될 것임을 수락하고 이해합니다.",
    validationTermsAndConditions: "이용 약관에 동의해야 합니다.",
    messageFailbankName: "은행 이름은 비워 둘 수 없습니다.",
    messageFailcustomerName: "고객 이름은 비워 둘 수 없습니다.",
    messageFailbankAccountNumber: "필수 항목입니다",
    messageFailbankAccountNumberFormat: "IBAN 형식이어야 합니다.",
    messageFailsortCode: "필수 항목입니다",
    messageFailbankSwiftBic: "수취인 SWIFT BIC는 비워 둘 수 없습니다.",
    messageFailbankSwiftBicFormat: "SWIFT 형식이어야 합니다.",
    messageFailbeneficiaryAddress1: "주소 1은 비워 둘 수 없습니다",
    messageFailbeneficiaryCity: "도시는 비워 둘 수 없습니다.",
    messageFailbeneficiaryPostCode: "우편 번호는 비워 둘 수 없습니다.",
    messageFailremittanceLine3: "필수 항목입니다",
    messageFailremittanceLine4: "필수 항목입니다",
    modalHeading: "세부사항 확인",
    modalLabelPleaseConfirmYourDetails: "아래 세부 사항을 다시 한 번 확인하십시오",
    modalValidationPleaseConfirm: "확인 하십시오",
    modalValidationMustMatch: "이전 항목과 일치해 야합니다.",
    tooltipBankAccountName: "은행 계좌 / 은행 명세서 / 은행 카드에 표시된 이름입니다.",
    tooltipBankAccountNameRecipient: "받는 사람의 이름과 성. 이름과 성에는 둘 이상의 글자가 있어야합니다.",
    tooltipInstitutionNumber: "이것은 3 자리 숫자이며 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipPaymentType: "필요한 지불 유형.",
    tooltipBankCountry: "은행이 위치한 국가입니다.",
    tooltipBankName: "은행 이름.",
    tooltipBankAccountNumber: "일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있는 귀하의 계좌번호, 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankAccountNumberCBU: "계정 식별에 사용되는 22 자리 숫자입니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankAccountNumberCCI: "계정 식별에 사용되는 20 자리 숫자입니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankAccountNumberKRW: "계정을 식별하는 데 사용되는 11 ~ 16 자리 숫자입니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCardNumber: "카드 번호. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipUnionpay: "귀하의 UnionPay 카드 번호.",
    tooltipAlipay: "Alipay 계정과 관련된 휴대폰 번호 또는 이메일 주소.",
    tooltipBankAccountType: "이것은 당좌 또는 저축 계좌입니다.",
    tooltipSortCode: "계좌가 있는 은행 지점을 식별합니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipRoutingCode: "계좌가 있는 은행 지점을 식별합니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBeneficiarySWIFTBIC: "국제 거래에서 은행을 식별하는 데 사용되는 8 ~ 11 자로 구성됩니다. 일반적으로 월별 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBSB: "은행 지점 (BSB) 번호는 6 자리 숫자로 구성된 호주 분류 코드입니다. 일반적으로 월별 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipRoutingCodeBSB: "계좌가있는 은행 지점을 식별합니다. 일반적으로 월별 명세서 또는 은행 카드청구서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipABAFedwireRoutingCode: "미국 은행을 식별하는 데 사용되는 9 자리 숫자입니다. 일반적으로 월별 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipIFSCCode: "일반적으로 이 코드는 월별 명세서 또는 통장에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipTermsAndConditions: "이용 약관에 동의하면 이용 약관을 읽고 동의하는 것입니다.",
    tooltipIBAN: "국제 결제를하거나 받을 때 사용할 수 있는 국제 은행 계좌 번호. 일반적으로 월별 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBeneficiaryContactNameandTelephoneNumber: "수령인 연락처 이름 및 전화 번호.",
    tooltipBeneficiaryDateofBirthandPlaceofBirth: "수령인 생년월일 및 출생지.",
    tooltipBankRoutingCodeNationalID: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankTransitCodeSwift: "이것은 5 자리 숫자이며 일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankAddress: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankAddress2: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankCity: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankState: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBranchName: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBankCode: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBIKCode: "은행 식별을 위한 9자리 숫자입니다.",
    tooltipBranchCode: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBranchCodeUniversal: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCNAPSCode: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipRemittanceLine4: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCUILNumber: "이것은 2 자리, 하이픈, 9 자리, 1 자리 체크섬으로 구성된 11 자리 숫자입니다.",
    tooltipTaxIDRFC13DigitsORCURP18digits: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipIndividualTaxIDCPFCadastrodePessoaFsica1114digitsBranchCode: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCFPNumber: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipBranchNameorBranchCode7digits: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCedulaJuridica910digitsTaxID: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipTaxIDRUT89digits: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipJob: "직업.",
    tooltipDateOfBirth: "생년월일.",
    tooltipNationality: "국적.",
    tooltipDocumentType: "필요한 문서 유형입니다.",
    tooltipDocumentNumber: "선택한 문서유형과 관련된 문서 번호입니다.",
    tooltipRutNumber: "RUT 번호 (Rol Único Tributario).",
    tooltipCedulaTaxIDIndividual811digits: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipCIIndividualTaxIDBranchLocation: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    tooltipTaxIDRUCorCI: "일반적으로 월별 은행 명세서에서 찾을 수 있습니다. 여전히 확실하지 않은 경우 은행에 문의하십시오.",
    errorCPFCode: "현재 우리는 CPF만 PIX 키로 허용합니다."
};