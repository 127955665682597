export default {
    dividerBankingDetails: "Detail Perbankan",
    dividerBookingDetails: "Detail Pemesanan",
    labelSavedApplicationReference: "Referensi Aplikasi Tersimpan:",
    labelLanguage: "Bahasa",
    messageWarningBookingHeading1: "Pemesanan Dibatalkan:",
    messageWarningBookingHeading2: "Pemesanan Ditunda / Dijadwalkan Ulang:",
    messageWarningBookingBody1: "Jika pemesanan Anda telah dibatalkan oleh penyelenggara atau maskapai penerbangan, tim ini tidak akan dapat membantu Anda dengan pengembalian dana. Anda perlu menghubungi tim layanan pelanggan Anda secara langsung; lihat konfirmasi pemesanan atau situs web Anda untuk detail kontak.",
    messageWarningBookingBody2: "Jika pemesanan Anda ditunda, persyaratan pengembalian uang yang diperpanjang akan dibawa ke tanggal yang baru. Jika Anda memiliki alasan yang valid untuk pengembalian dana berdasarkan persyaratan kami, harap lengkapi formulir dengan bukti pendukung yang benar.",
    messageWarningBookingBody3: "Harap diperhatikan: Layanan ini hanya dapat memberikan pengembalian uang untuk alasan yang tercantum dalam kata-kata kami, yang tersedia dalam email konfirmasi pemesanan Anda.",
    messageWarningBookingBody4: "Jika Anda tidak dapat melakukan pemesanan karena alasan di luar ketentuan kami, kami tidak akan dapat membantu. Anda perlu menghubungi tim layanan pelanggan Anda secara langsung karena mereka mungkin dapat membantu; lihat konfirmasi pemesanan atau situs web Anda untuk perincian kontak.",
    linkWarningBooking: "sini",
    formSectionNavigatorStep1: "Langkah 1",
    formSectionNavigatorStep2: "Langkah 2",
    formSectionNavigatorStep3: "Langkah 3",
    formSectionNavigatorStep1Text: "Detail Kontak dan Pemesanan",
    formSectionNavigatorStep2Text: "Alasan Pengembalian Dana",
    formSectionNavigatorStep3Text: "Detail Pembayaran",
    messageBookingMatchedAndFound: "Jika referensi pemesanan Anda dikaitkan dengan aplikasi yang sudah ada, kami akan mengirimkan email berisi link untuk mengakses aplikasi Anda",
    messageBookingMatchedAndFoundCompleted: "Aplikasi pengembalian dana Anda telah disetujui! Jika Anda memiliki masalah dengan pembayaran Anda, silakan hubungi:",
    applicationDeclinedMessage: "Permohonan Anda telah ditolak dan sekarang ditutup.",
    messageBookingNotFound1: "Kami tidak dapat menemukan pemesanan dengan referensi tersebut. Silakan periksa email konfirmasi pemesanan Anda dan masukkan kembali.",
    messageBookingNotFound2: "Jangan khawatir, jika referensi pemesanan Anda tidak berfungsi, Anda dapat memilih 'Temukan Pemesanan' dan masukkan detail Anda. Saat mengisi formulir, harap unggah salinan email konfirmasi pemesanan Anda untuk membantu kami menemukan detail pemesanan Anda.",
    messageLocatingBooking: "Menemukan pemesanan Anda ...",
    messageLoadingBank: "Memuat informasi bank ...",
    messageFailCurrencyLookup: "Kesalahan mengambil mata uang",
    messageSavingApplication: "Menyimpan aplikasi Anda ...",
    messageSavedApplication: "Aplikasi Pengembalian Dana berhasil disimpan",
    messageFailSavedApplication: "Aplikasi Pengembalian Dana gagal disimpan",
    messageSendingEmailReminder: "Pengolahan...",
    messageErrorLocationDetails: "Kesalahan mengambil detail lokasi",
    messageFailFormSubmission: "Pengiriman Formulir Gagal",
    messageApplicationCannotBeFound: "Aplikasi tidak dapat ditemukan",
    messageApplicationRetrievalError: "Kesalahan mengambil aplikasi",
    messageSubmittingApplication: "Mengirimkan aplikasi ...",
    messageEventDateLimitError: "Alasan pemberitahuan di luar batas waktu",
    messageAllTicketsAssigned: "Semua tiket telah dialihkan ke aplikasi pengembalian dana lain.",
    selectPrefix: "Pilih",
    selectPostfix: "",
    fileUpload: "klik di sini untuk mengupload",
    fileUploadAcceptedFilesMessage: "Kami hanya menerima PDF dan format gambar .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 File Dipilih",
    fileUploadPreviouslyUploaded: "Sebelumnya Diunggah:",
    fileUploadSelectedUploaded: "Yang terpilih sudah diunggah:",
    buttonNext: "lanjut",
    buttonPrevious: "Sebelumnya",
    buttonSave: "menyimpan",
    buttonCancel: "Batal",
    buttonSubmit: "Kirimkan",
    buttonConfirm: "Konfirmasi",
    buttonYes: "Iya",
    buttonNo: "Tidak",
    buttonClose: "Menutup",
    linkPrivacyStatement: "Pernyataan Privasi",
    linkComplaintsPolicy: "Kebijakan Pengaduan",
    validationRequired: "Yg dibutuhkan",
    validationInvalid: "Tidak valid",
    labelUploadPrefix: "Mengunggah",
    labelUploadPostfix: "",
    labelChange: "Ubah",
    labelView: "Melihat",
    browserNavigationMessage: "Perubahan yang belum disimpan akan hilang"
};